import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../foundations/colors/colors';
import AssetLogoRocket from '../assets/generated/AssetLogoRocket';

export interface PlaceholderProps {
  style?: React.CSSProperties;
}

export const Placeholder: React.FC<PlaceholderProps> = ({ style }) => {
  return (
    <StyledPlaceholder style={style}>
      <AssetLogoRocket />
    </StyledPlaceholder>
  );
};

const StyledPlaceholder = styled.div`
  background: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
