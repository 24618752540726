import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconTransport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.25 16.875H9m6 0h.75M6 19.501v1.874m12-1.874v1.874M2.625 10.5H4.5m15 0h1.875M4.5 6.375v10.5a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3v-10.5c0-2.25-2.7-3.75-7.5-3.75s-7.5 1.5-7.5 3.75m15 .75h-15v6.75h15z"
    />
  </svg>
);
export default SvgIconTransport;
