import { DataLoader, useDocumentQuery } from '@lessonup/client-integration';
import {
  borderRadius,
  color,
  LoaderSpinner,
  ManagedModal,
  math,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  spacing,
  styled,
  useErrorContext,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  moveUploadModalContentHeight,
  moveUploadModalContentWidth,
} from '../components/MoveUploadExplorerModal/constants';
import { MinimalExplorer } from '../components/MoveUploadExplorerModal/MinimalExplorer';
import { UploadsPagination } from '../components/UploadsPagination/UploadsPagination';
import { showCreateNewFolderModal } from '../CreateNewFolderModal';
import { useNavigationDispatcherInternal } from '../hooks/useNavigationDispatcher';
import { MyUploadsDocument } from '../Uploads.graphql.generated';
import { i18nextNamespace } from '../UploadsFeature.utils';

export interface MoveUploadModalProps {
  folderId?: string;
  disabledFolderIds?: string[];
}
export const MoveUploadModal: React.FC<MoveUploadModalProps & NiceModalHocProps> = NiceModal.create(
  ({ folderId, disabledFolderIds }: MoveUploadModalProps) => {
    const { t } = useTranslation(i18nextNamespace);
    const [navigationState, dispatchNavigation] = useNavigationDispatcherInternal({ folderId });
    const { setError } = useErrorContext();

    const { data, loading, refetch, error } = useDocumentQuery(MyUploadsDocument, {
      variables: {
        folderId: navigationState.folderId,
        limit: navigationState.perPage,
        offset: (navigationState.page - 1) * navigationState.perPage,
        sortBy: navigationState.sorting,
      },
      fetchPolicy: 'cache-and-network',
      onError: (error) => setError({ error }),
    });

    const createNewFolder = async (parentId: string | null) => {
      if (!parentId) {
        return setError({ error: new Error('Missing parent ID') });
      }

      const { folderCreated, folderId } = await showCreateNewFolderModal({ parentId });
      if (folderCreated && folderId) {
        navigateToFolder(folderId);
      }
    };

    function navigateToFolder(id?: string): void {
      if (!id) return;
      dispatchNavigation({ type: 'folderId', id });
    }

    const modal = NiceModal.useModal();
    const folderBreadcrumbs = data?.viewer.uploadFolder?.breadcrumbs;
    const parentFolderID =
      folderBreadcrumbs && folderBreadcrumbs?.length > 1 && folderBreadcrumbs[folderBreadcrumbs.length - 2]?.id;
    const folderName = data?.viewer.uploadFolder?.name;

    const onCloseClick = () => {
      modal.resolve();
      modal.hide();
    };

    const onSubmit = async () => {
      modal.resolve(navigationState.folderId ?? data?.viewer?.uploadFolder?.id);
      modal.hide();
    };

    const retry = async () => {
      await refetch();
    };
    const totalUploads = data?.viewer.uploadFolder?.childUploadFoldersAndUploads.totalCount || 0;
    const showPagination = loading || totalUploads > 0;
    const modalWidth = math(`${moveUploadModalContentWidth} + (${spacing.size24} * 2)`);

    return (
      <ManagedModal modal={modal} contentLabel={t('caption')} width={modalWidth}>
        <ModalHeaderV1
          type="headline"
          title={{ content: folderName || t('caption') }}
          onBackButtonClick={parentFolderID ? () => navigateToFolder(parentFolderID) : undefined}
          onCloseButtonClick={onCloseClick}
          showDivider={false}
        />
        <ModalBody>
          <OverflowContainer showPagination={showPagination}>
            <DataLoader
              retry={retry}
              data={data}
              loading={loading}
              error={error}
              customLoader={<LoaderSpinner type="overlay" />}
              dataRenderer={(query) => (
                <MinimalExplorer
                  uploadFolder={query.viewer.uploadFolder || null}
                  setCurrentFolderId={navigateToFolder}
                  disabledFolderIds={disabledFolderIds}
                />
              )}
            />
          </OverflowContainer>
          {showPagination && (
            <UploadsPagination
              dispatchNavigation={dispatchNavigation}
              total={totalUploads}
              perPage={navigationState.perPage}
              page={navigationState.page}
            />
          )}
        </ModalBody>
        <ModalFooter
          type="actions"
          primaryAction={{
            onClick: onSubmit,
            label: t('moveModal.submit'),
            disabled: folderId === navigationState.folderId,
          }}
          secondaryAction={{
            onClick: () => createNewFolder(navigationState.folderId),
            label: t('moveModal.createNewFolder'),
          }}
        />
      </ManagedModal>
    );
  }
);

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.size8} ${spacing.size24};
  height: ${moveUploadModalContentHeight};
`;

const OverflowContainer = styled.div<{ showPagination: boolean }>`
  width: ${moveUploadModalContentWidth};
  height: 100%;
  flex-grow: 1;
  border-radius: ${borderRadius.rounded4};
  border: 1px solid ${color.neutral.outline.background};
  overflow: hidden auto;
`;

export const showMoveUploadModal = (params: MoveUploadModalProps): Promise<string> =>
  NiceModal.show(MoveUploadModal, params);
