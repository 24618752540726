import { SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementDocument } from '../../../../../types/graphql';
import { agreementDocumentRoute } from '../../../../../utils/bridge/teacherRoutes';
import { mapGraphQLAgreementDocumentToDbAgreementDocument } from '../../../OrganizationAgreements/utils';
import { AgreementByIdQuery } from '../../AgreementPage.graphql.generated';
import { ContactDetailsForm } from '../../dataComponents/ContactDetailsForm/ContactDetailsForm';
import { ContactFormData } from '../../sharedTypes';
import { AgreementPageIntroduction } from '../AgreementPageIntroduction/AgreementPageIntroduction';
import { ForwardAgreement } from '../ForwardAgreement/ForwardAgreement';
import { SignAgreementBlock } from '../SignAgreementBlock/SignAgreementBlock';

type AgreementByIdAndSecret = NonNullable<AgreementByIdQuery['agreementByIdAndSecret']>;

type SignProposal = NonNullable<AgreementByIdAndSecret['signProposalById']>;

export interface AgreementPageProps {
  agreement: AgreementByIdAndSecret;
  currentSignProposal: SignProposal;
  secret: string;
  proposalId: string;
  documentVersion: AgreementDocument;
}

export const AgreementPage: React.FC<AgreementPageProps> = ({
  agreement,
  currentSignProposal,
  secret,
  proposalId,
  documentVersion,
}) => {
  const { t } = useTranslation('agreementsPage');

  const [contactDetailsAreSaved, setContactDetailsAreSaved] = useState(() =>
    mandatoryProposalDetailsArePresent(signProposalToContactFormData(currentSignProposal))
  );

  const agreementId = agreement.id;
  const organizationName = currentSignProposal.details?.name;
  const agreementUrl = agreementDocumentRoute.href({
    agreementId,
    secret,
    proposalId,
    documentVersion: mapGraphQLAgreementDocumentToDbAgreementDocument(agreement.document),
  });
  const signed = !!agreement.signed;

  return (
    <FeatureWrapper direction="y" spacing={spacing.size64}>
      <AgreementPageIntroduction organizationName={organizationName} agreementUrl={agreementUrl} signed={signed} />
      {!signed && (
        <ContactDetailsForm
          agreementId={agreementId}
          proposalId={proposalId}
          secret={secret}
          contactFormData={signProposalToContactFormData(currentSignProposal)}
          onSave={() => setContactDetailsAreSaved(true)}
        />
      )}
      {!signed && (
        <SignAgreementBlock
          proposalId={proposalId}
          document={documentVersion}
          agreementId={agreementId}
          secret={secret}
          enabled={contactDetailsAreSaved}
        />
      )}
      {!signed && <ForwardAgreement t={t} agreementId={agreementId} secret={secret} />}
    </FeatureWrapper>
  );
};

const signProposalToContactFormData = (signProposal: SignProposal): ContactFormData => {
  return {
    organizationName: signProposal.details?.name || '',
    address: signProposal.details?.contact?.address || '',
    schoolcode: signProposal.details?.contact?.brin || '',
    cOfCNumber: signProposal.details?.contact?.kvk || '',
    zipcode: signProposal.details?.contact?.zipcode || '',
    city: signProposal.details?.contact?.city || '',
    country: signProposal.details?.contact?.country || '',
    name: signProposal.boardMember.name || '',
    position: signProposal.boardMember.position || '',
    email: signProposal.boardMember.email || '',
    privacyContact: {
      name: signProposal.privacyContact?.name || '',
      position: signProposal.privacyContact?.position || '',
      email: signProposal.privacyContact?.email || '',
    },
  };
};

const mandatoryProposalDetailsArePresent = ({
  name,
  address,
  city,
  country,
  zipcode,
  schoolcode,
  cOfCNumber,
}: ContactFormData): boolean => {
  return !!(name && address && city && country && zipcode) && !!(schoolcode || cOfCNumber);
};

const FeatureWrapper = styled(SpaceBetween)`
  max-width: 55rem;
  margin: 0 auto;
`;
