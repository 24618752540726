import { color, IconFolder, styled, Table, TableRowData } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildUploadFolder, MyUploadFolder } from '../../Uploads.graphql';
import { getStartSlotForUpload, i18nextNamespace, isUploadFolder, Upload } from '../../UploadsFeature.utils';

export interface MoveUploadProps {
  disabledFolderIds?: string[];
  uploadFolder: MyUploadFolder | null;
  setCurrentFolderId: (id: string) => void;
}

export const MinimalExplorer: React.FC<MoveUploadProps> = ({ setCurrentFolderId, uploadFolder, disabledFolderIds }) => {
  const { t } = useTranslation(i18nextNamespace);

  if (!uploadFolder) return null;

  const rows = getMiniExplorerRows({ uploadFolder, t, setFolderId: setCurrentFolderId, disabledFolderIds });

  return <StyledTable rows={rows} caption={t('moveModal.caption')} selectedRows={[]} interactivity={false} />;
};

const StyledTable = styled(Table)`
  background: ${color.neutral.surface1.background};
  height: 100%;
  overflow: hidden;
`;

function getMiniExplorerRows({
  uploadFolder,
  t,
  setFolderId,
  disabledFolderIds,
}: {
  disabledFolderIds: MoveUploadProps['disabledFolderIds'];
  uploadFolder: MyUploadFolder;
  t: TFunction;
  setFolderId: (id: string) => void;
}): TableRowData[] {
  return uploadFolder.childUploadFoldersAndUploads.nodes.map((child) => {
    if (isUploadFolder(child)) {
      return tableRowForFolder(child, setFolderId, !!disabledFolderIds?.includes(child.id));
    } else {
      return tableRowForUpload(child, t);
    }
  });
}

function tableRowForFolder(
  folder: ChildUploadFolder,
  setFolderId: (id: string) => void,
  disabled: boolean
): TableRowData {
  return {
    id: folder.id,
    ariaLabel: folder.name,
    onClick: () => {
      setFolderId(folder.id);
    },
    disabled,
    cells: [
      {
        startSlot: <IconFolder />,
        text: folder.name,
        href: undefined,
      },
    ],
  };
}

function tableRowForUpload(upload: Upload, t: TFunction): TableRowData {
  return {
    id: upload.id,
    ariaLabel: upload.name,
    disabled: true,
    cells: [
      {
        startSlot: getStartSlotForUpload(upload, t),
        text: upload.name,
      },
    ],
  };
}
