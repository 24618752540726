import { useContext } from 'react';
import { ErrorContext, ErrorContextProps } from '../errorContext';

export const useErrorContext = (): ErrorContextProps => {
  const ctx = useContext(ErrorContext);
  if (!ctx) {
    throw new Error('ErrorContext not found');
  }
  return ctx;
};
