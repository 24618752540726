import { Size } from '@lessonup/pins-shared';
import { useEffect, useState } from 'react';
import { getImageSizeFromSource } from './ImagePinComponent.utils';

/** Hook that loads and then computes an image's aspect ratio. */
export function useOriginalAspectRatio(imageUrl: string) {
  const [originalImageSize, setOriginalImageSize] = useState<Size | null>(null);

  useEffect(() => {
    (async function () {
      const size = await getImageSizeFromSource(imageUrl);
      setOriginalImageSize(size);
    })();
  }, [imageUrl]);

  if (!originalImageSize || originalImageSize.width < 1 || originalImageSize.height < 1) {
    return null;
  }

  return originalImageSize.width / originalImageSize.height;
}
