import { Button, ButtonProps, color, css, styled } from '@lessonup/ui-components';
import React from 'react';

interface ActivatableButtonProps extends ButtonProps {
  active: boolean;
}

/** Placeholder component until we have a proper active button */
export const ActivatableButton: React.FC<ActivatableButtonProps> = (props) => {
  return <StyledButton {...props} />;
};

const StyledButton = styled(Button)<ActivatableButtonProps>`
  ${({ active }) =>
    active &&
    css`
      background-color: ${color.accent.secondaryContainer.background};
      color: ${color.accent.secondaryContainer.text};
      border-color: transparent;
    `}
`;
