import {
  Button,
  FormFieldValidation,
  InputFormField,
  SpaceBetween,
  spacing,
  useErrorContext,
  useForm,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';

export type PasswordFormProps = {
  onSubmit: (data: FormInputs) => void;
  onValidatePassword: (password: string) => boolean;
  loading: boolean;
};

export type FormInputs = {
  password: string;
};

export const PasswordForm = ({ onSubmit, onValidatePassword, loading }: PasswordFormProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'passwordStep.passwordForm');
  const { clearError: clearErrorContextError } = useErrorContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>();
  const passwordFieldProps = register('password', {
    required: { value: true, message: t('error.required') },
    validate: {
      passwordReq: (value) => onValidatePassword(value) || t('error.invalid').toString(),
    },
    onChange() {
      removeAllErrors();
    },
  });

  const validateGeneric: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  const removeAllErrors = () => {
    clearErrors();
    clearErrorContextError();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SpaceBetween spacing={spacing.size16} direction="y">
        <InputFormField
          label={t('inputLabel')}
          type="password"
          maxLength={100}
          {...passwordFieldProps}
          validation={validateGeneric('password')}
        />
        <Button resizing="fixed" type="submit" loading={loading} size="large">
          {t('submitButtonLabel')}
        </Button>
      </SpaceBetween>
    </form>
  );
};
