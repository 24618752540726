import { PinComponent } from '@lessonup/pins-shared';
import { NumericRange } from '@lessonup/utils';
import { pinSize } from '../../foundations/layout/pinDefault';
import { isBoxLayout } from '../../types';
import { ArrangedRailLayouts, RailLayout, SnapFitting } from '../../types/helpLines/helpLines.types';
import {
  boxCenterPosition,
  boxFromLine,
  boxRotatedVertices,
  horizontalExtremesFromVectors,
  isValueInRange,
  verticalExtremesFromVectors,
} from '../geometry';
import { filterRailLayouts, isHorizontalRailLayout, isVerticalRailLayout } from './rails.utils';

export const newRail = (
  positionOnAxis: number,
  orientation: 'horizontal' | 'vertical',
  snapFitting: SnapFitting,
  displayRange: NumericRange,
  isBleedingRail = false
): RailLayout => ({
  positionOnAxis,
  orientation,
  snapFitting,
  displayRange,
  isBleedingRail,
});

export const createRails = (pinComponents: PinComponent[], selectedPinComponentIds: string[]): ArrangedRailLayouts => {
  const componentsWithRails = pinComponents.filter(
    (pinComponent) => !selectedPinComponentIds.includes(pinComponent.id)
  );
  const componentRails = componentsWithRails.map(createComponentRails);
  const rails = [...borderRails, ...bleedingRails, ...canvasCenterRails, ...componentRails.flat()];
  const filteredRails = filterRailLayouts(rails);
  const horizontal = filteredRails.filter(isHorizontalRailLayout);
  const vertical = filteredRails.filter(isVerticalRailLayout);

  return { horizontal, vertical };
};

const BLEEDING_MARGIN = (32 / 960) * pinSize.width;

const borderRails: RailLayout[] = [
  newRail(0, 'horizontal', 'outer', { min: 0, max: pinSize.width }),
  newRail(0, 'vertical', 'outer', { min: 0, max: pinSize.height }),
  newRail(pinSize.height, 'horizontal', 'outer', { min: 0, max: pinSize.width }),
  newRail(pinSize.width, 'vertical', 'outer', { min: 0, max: pinSize.height }),
];

export const bleedingRails: RailLayout[] = [
  newRail(BLEEDING_MARGIN, 'horizontal', 'all', { min: 0, max: pinSize.width }, true),
  newRail(BLEEDING_MARGIN, 'vertical', 'all', { min: 0, max: pinSize.height }, true),
  newRail(pinSize.height - BLEEDING_MARGIN, 'horizontal', 'all', { min: 0, max: pinSize.width }, true),
  newRail(pinSize.width - BLEEDING_MARGIN, 'vertical', 'all', { min: 0, max: pinSize.height }, true),
];

const canvasCenterRails: RailLayout[] = [
  newRail(pinSize.height / 2, 'horizontal', 'all', { min: 0, max: pinSize.width }),
  newRail(pinSize.width / 2, 'vertical', 'all', { min: 0, max: pinSize.height }),
];

const createComponentRails = (pinComponent: PinComponent): RailLayout[] => {
  const boxLayout = isBoxLayout(pinComponent.layout) ? pinComponent.layout : boxFromLine(pinComponent.layout);
  const vertices = boxRotatedVertices(boxLayout);
  const center = boxCenterPosition(boxLayout);
  const extremes = {
    x: horizontalExtremesFromVectors(vertices),
    y: verticalExtremesFromVectors(vertices),
  };
  const horizontalPinRange = { min: 0, max: pinSize.width };
  const verticalPinRange = { min: 0, max: pinSize.height };
  const horizontalRange = { min: extremes.x.min, max: extremes.x.max };
  const verticalRange = { min: extremes.y.min, max: extremes.y.max };
  return [
    isValueInRange(extremes.y.min, verticalPinRange) && newRail(extremes.y.min, 'horizontal', 'outer', horizontalRange),
    isValueInRange(extremes.y.max, verticalPinRange) && newRail(extremes.y.max, 'horizontal', 'outer', horizontalRange),
    isValueInRange(extremes.x.min, horizontalPinRange) && newRail(extremes.x.min, 'vertical', 'outer', verticalRange),
    isValueInRange(extremes.x.max, horizontalPinRange) && newRail(extremes.x.max, 'vertical', 'outer', verticalRange),
    newRail(center.x, 'vertical', 'center', verticalRange),
    newRail(center.y, 'horizontal', 'center', horizontalRange),
  ].filter((rail): rail is RailLayout => !!rail);
};
