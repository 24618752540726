import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.51 2.914q.835.091 1.653.187a3.14 3.14 0 0 1 2.75 2.755q.092.813.179 1.642M16.51 21.086q.835-.091 1.653-.187a3.14 3.14 0 0 0 2.75-2.755q.092-.814.179-1.642M7.49 2.914q-.835.091-1.653.187a3.14 3.14 0 0 0-2.75 2.755q-.091.813-.18 1.642M7.49 21.086q-.835-.091-1.653-.187a3.14 3.14 0 0 1-2.75-2.755q-.091-.814-.18-1.642m3.99-1.182v2.292h2.25m8.398 0V15.32h-2.25m2.29-3.354h-2.25M11.9 17.591v-2.25m5.646-6.197V6.852h-2.25M6.47 10.435c.074.72.656 1.303 1.376 1.38.424.045.858.081 1.3.081.441 0 .875-.036 1.3-.081a1.56 1.56 0 0 0 1.376-1.38c.043-.421.076-.853.076-1.291 0-.439-.033-.87-.076-1.292a1.56 1.56 0 0 0-1.376-1.38 12 12 0 0 0-1.3-.08c-.442 0-.876.035-1.3.08-.72.077-1.302.66-1.376 1.38a13 13 0 0 0-.077 1.292c0 .438.034.87.077 1.291"
    />
  </svg>
);
export default SvgIconQrCode;
