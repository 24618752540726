import { useLocale } from '@lessonup/client-integration';
import {
  BodyText,
  Card,
  IconStudent,
  Introduction,
  SpaceBetween,
  spacing,
  styled,
  useTranslation,
} from '@lessonup/ui-components';
import { dateToShortLocale } from '@lessonup/utils';
import React from 'react';
import { i18nextNamespace } from '../../utils/consts';
import { Report, ReportLesson } from '../../utils/types';

export interface SummaryCardProps {
  report: Pick<Report, 'taughtAt' | 'numberOfStudents'> & { lesson: Pick<ReportLesson, 'name'> };
}

export const SummaryCard: React.FC<SummaryCardProps> = ({ report }) => {
  const locale = useLocale();
  const { t } = useTranslation(i18nextNamespace, { keyPrefix: 'summaryCard' });

  return (
    <StyledCard showBorder={false}>
      <SpaceBetween direction="y" spacing={spacing.size16}>
        <Introduction
          alignment="start"
          size="M"
          headline={report.lesson.name}
          bodyText={<BodyText>{dateToShortLocale(report.taughtAt, locale)}</BodyText>}
        />
        <StyledSpaceBetween direction="x" spacing={spacing.size8}>
          <StyledIntroduction
            icon={<IconStudent />}
            tagline={t('studentsTitle')}
            alignment="start"
            size="S"
            headline={report.numberOfStudents.toString()}
            bodyText={t('studentsDescription')}
          />
        </StyledSpaceBetween>
      </SpaceBetween>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: ${spacing.size16};
  width: 100%;
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  display: flex;
`;

const StyledIntroduction = styled(Introduction)`
  display: flex;
  flex: 1;
`;
