import { Button, IconClose, IconFullscreenExit, IconFullscreenOpen } from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../../../features/Player/hooks/usePlayerTranslation';

interface PlayerBarTopInterfaceControlsProps {
  onClose: () => void;
  toggleFullscreen: () => void;
  isFullscreen: boolean;
  showLabels: boolean;
}

export const PlayerBarTopInterfaceControls: React.FC<PlayerBarTopInterfaceControlsProps> = ({
  isFullscreen,
  onClose,
  toggleFullscreen,
  showLabels,
}) => {
  const { t } = usePlayerTranslation();
  return (
    <>
      <Button
        buttonShape="rect"
        buttonType="neutral"
        onClick={toggleFullscreen}
        aria-label={t('labels.fullscreen')}
        iconStart={isFullscreen ? <IconFullscreenExit /> : <IconFullscreenOpen />}
        buttonLabel={showLabels ? (isFullscreen ? t('labels.exitFullscreen') : t('labels.fullscreen')) : undefined}
      />
      <Button
        buttonShape="rect"
        buttonType="neutral"
        onClick={onClose}
        aria-label="Close"
        iconStart={<IconClose />}
        buttonLabel={showLabels ? t('labels.close') : undefined}
      />
    </>
  );
};
