import { isNull } from 'lodash';
import { Logger, logger } from '../../../logger/logger';
import { AudioContextHandler } from './AudioContextHandler';
import { CustomMediaRecorderError } from './MediaRecorder.utils';

export class MediaRecorderHandler {
  private mediaBlobs: Blob[] = [];

  private mediaRecorder: null | MediaRecorder = null;

  private currentMediaStream: null | MediaStream = null;

  private audioContextHandler: null | AudioContextHandler = null;
  private readonly logger: Logger = logger;

  constructor(private readonly constraints: MediaStreamConstraints) {}

  private clearMediaBlobs(): void {
    this.mediaBlobs = [];
  }

  private createMediaRecorderInstance(): void {
    if (!this.currentMediaStream) {
      return;
    }

    this.mediaRecorder = new MediaRecorder(this.currentMediaStream);

    this.registerEventListeners();
  }

  private startMediaRecorder(): void {
    this.mediaRecorder?.start();
  }

  private resetRecordingProperties(): void {
    this.mediaRecorder = null;
  }

  public getAudioContextHandler(): AudioContextHandler | null {
    return this.audioContextHandler;
  }

  public async startFeed(): Promise<MediaStream> {
    const isFeatureDetected = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;

    if (!isFeatureDetected) {
      return Promise.reject(new Error('The mediaDevices API or getUserMedia method is not supported in this browser.'));
    }

    return navigator.mediaDevices.getUserMedia(this.constraints).then((stream) => {
      if (this.constraints.audio) {
        this.audioContextHandler = new AudioContextHandler(stream);
      }

      this.currentMediaStream = stream;

      return stream;
    });
  }

  public startRecording(): Promise<MediaStream> {
    return new Promise((resolve, reject) => {
      if (isNull(this.currentMediaStream)) {
        reject(new Error(CustomMediaRecorderError.NoMediaStreamFound));
      } else {
        this.clearMediaBlobs();
        this.createMediaRecorderInstance();
        this.startMediaRecorder();
        resolve(this.currentMediaStream);
      }
    });
  }

  public stopRecording(): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      const mimeType = this.mediaRecorder?.mimeType;
      this.mediaRecorder?.addEventListener('stop', () => {
        resolve(new Blob(this.mediaBlobs, { type: mimeType }));
      });
      this.stopMediaRecorder();
      this.resetRecordingProperties();
    });
  }

  public cancelRecording(): void {
    this.abortStream();
    this.resetRecordingProperties();
  }

  private stopMediaRecorder(): void {
    this.mediaRecorder?.stop();
  }

  /**
   * Stops all the tracks in the current MediaStream.
   */
  private stopStream(): void {
    this.currentMediaStream?.getTracks().forEach((track) => track.stop());
  }

  private abortStream(): void {
    this.stopStream();
    this.stopMediaRecorder();
  }

  private registerEventListeners(): void {
    if (!this.mediaRecorder) return;

    this.mediaRecorder.addEventListener('dataavailable', (event) => {
      this.mediaBlobs.push(event.data);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.logger && this.mediaRecorder.addEventListener('error', (msg) => this.logger?.error(msg as any));
  }
}
