import React, { useEffect, useRef, useState } from 'react';

import { useBoolean, useOnClickOutside } from 'usehooks-ts';

import { Button, color, IconChevronDown, IconChevronUp, SpaceBetween, spacing, styled } from '@lessonup/ui-components';

import { EditorInputFormField } from '../EditorInputFields/EditorInputFormField';
import { DropDown, StyleDropdownOption } from './CustomSelectDropdown';
import { findNextAndPrevIndices } from './SteppedNumberInput.utils';
import { useCustomSelectScrollIntoView } from './useCustomSelectScrollIntoView';

export interface SteppedFloatInputProps {
  label: string;
  value: string | undefined;
  options: { label: string; value: string }[];
  onTextChange?: (value: string) => string;
  onChange: (value: string) => void;
  valueOnFocus?: () => string;
  valueValidation?: (value: string) => boolean;
  styleOption?: StyleDropdownOption<string>;
  onInputFocus?: () => void;
  onBlur?: () => void;
}

export function SteppedFloatInput({
  value,
  options: options,
  onChange,
  valueValidation,
  styleOption,
  onInputFocus,
  onTextChange,
  onBlur,
  ...rest
}: SteppedFloatInputProps) {
  const isOpen = useBoolean(false);
  const selectRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLInputElement | null>(null);
  const [tempInputValue, setTemp] = useState(value || '');

  const customScroll = useCustomSelectScrollIntoView();

  const handleClose = () => {
    isOpen.setFalse();
    customScroll.resetScrollIntoView();
  };

  useEffect(() => {
    setTemp(value ?? '');
  }, [value]);

  // TODO: TECH-180: fix the ref type issue
  useOnClickOutside(containerRef as never, () => {
    handleClose();
  });

  const handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (valueValidation && !valueValidation(event.target.value)) return;
    if (event.target.value === '') {
      setTemp('0');
      return;
    }

    setTemp(onTextChange ? onTextChange(event.target.value) : event.target.value);
  };

  const handlePickOption = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
    event.preventDefault();
    setTemp(value);
    onChange(value);
    handleClose();
  };

  const handleBlur = () => {
    onChange(tempInputValue);
    onBlur?.();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    event.preventDefault();
    if (isOpen.value) {
      handleClose();
    } else {
      isOpen.setTrue();
    }
    if (selectRef.current) {
      onInputFocus?.();
      selectRef.current.focus();
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      isOpen.toggle();
      selectRef.current?.blur();
    }
  };

  const handleCaretClick = (dir: Direction) => {
    const number = toValidNumber(tempInputValue);
    const [prev, next] = findNextAndPrevIndices(
      options.map((option) => ({ value: parseFloat(option.value) })),
      number
    );
    if (dir === 'down' && prev !== -1) {
      onChange(options[prev].value);
    }
    if (dir === 'up' && next !== -1) {
      onChange(options[next].value);
    }
  };

  return (
    <SelectContainer ref={containerRef} {...rest}>
      <InputField
        ref={selectRef}
        type="text"
        onChange={handleOnTextChange}
        onBlur={handleBlur}
        formFieldSize="small"
        value={String(tempInputValue)}
        onMouseDown={toggleDropdown}
        onTouchStart={toggleDropdown}
        onKeyDown={handleEnter}
        label=""
      />
      <UpDownButtonSpaceBetween direction="y" spacing={spacing.size0}>
        <Caret direction="up" onClick={handleCaretClick} handleClose={handleClose} />
        <Caret direction="down" onClick={handleCaretClick} handleClose={handleClose} />
      </UpDownButtonSpaceBetween>
      {isOpen.value && DropDown<string>(customScroll, options, handlePickOption, value, styleOption)}
    </SelectContainer>
  );
}

const toValidNumber = (value: string | undefined): number => {
  if (!value) {
    return 0;
  }
  return parseFloat(value);
};

const SelectContainer = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const InputField = styled(EditorInputFormField)<React.InputHTMLAttributes<HTMLInputElement>>`
  max-width: 100px;
  cursor: pointer;
  appearance: none;
  flex-grow: 1;
`;

const UpDownButtonSpaceBetween = styled(SpaceBetween)`
  position: absolute;
  max-height: 50px;
  right: 10px;
`;

type Direction = 'up' | 'down';
const Caret: React.FC<{ onClick: (dir: Direction) => void; direction: Direction; handleClose: () => void }> = ({
  onClick,
  direction,
  handleClose,
}) => {
  const handleEvent = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    handleClose();
    event.preventDefault();
    onClick(direction);
  };
  return (
    <CaretButton
      buttonType="neutral"
      onMouseDown={(e) => handleEvent(e)}
      onTouchStart={(e) => handleEvent(e)}
      iconStart={direction === 'up' ? <IconChevronUp /> : <IconChevronDown />}
    />
  );
};

const CaretButton = styled(Button)`
  color: ${color.accent.secondary.background};
  max-height: 17px;
  min-height: 17px;
  background: none;
`;
