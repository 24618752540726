import {
  Button,
  FormFieldValidation,
  IconSchool,
  IconWarning,
  InputFormField,
  SpaceBetween,
  spacing,
  useErrorContext,
  useForm,
  useWatch,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { isString } from 'lodash';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { PERSONAL_EMAIL_DOMAIN_REGEX, STUDENT_EMAIL_REGEX } from '../../../utils/registration/registraton.utils';

export type EmailFormProps = {
  t: TFunction;
  onSubmit: (data: EmailFormInputs) => void;
  redirectToStudent: string;
  loading: boolean;
  initialEmail?: string;
};

export type EmailFormInputs = {
  email: string;
  confirmEmail: string;
};

const VALID_EMAIL_REGEXP = '[^@]+@[^@.]+\\.[^@]+';

export const EmailForm = (props: EmailFormProps) => {
  const { t, onSubmit, redirectToStudent, loading } = props;
  const [hasPersonalEmail, setHasPersonalEmail] = useState(false);
  const [hasStudentEmail, setHasStudentEmail] = useState(false);
  const { clearError: clearErrorContextError } = useErrorContext();
  const [emailValidation, setEmailValidation] = useState<FormFieldValidation | undefined>(undefined);
  const [confirmValidation, setConfirmValidation] = useState<FormFieldValidation | undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    control,
    clearErrors,
  } = useForm<EmailFormInputs>({ defaultValues: { email: props.initialEmail } });

  const email = useWatch({ control, name: 'email' });
  const confirmEmail = useWatch({ control, name: 'confirmEmail' });

  const emailProps = register('email', {
    required: { value: true, message: t('credentialsStep.email.error.required') },
    onChange() {
      removeAllErrors();
    },
  });

  const confirmEmailProps = register('confirmEmail', {
    required: { value: true, message: t('credentialsStep.email.error.required') },
    onChange() {
      removeAllErrors();
    },
    validate: confirmEmailValidation,
  });

  function confirmEmailValidation(value: string, { email }: { email: string }): string | undefined {
    if (value !== email) {
      return t('credentialsStep.email.error.confirmEmailMismatch');
    }
  }

  function confirmEmailFormFieldValidation(
    value: string,
    formValues: { email: string }
  ): FormFieldValidation | undefined {
    const validationResult = confirmEmailValidation(value, formValues);
    if (isString(validationResult)) {
      return {
        state: 'error',
        message: validationResult,
      };
    }
  }

  const validateGeneric: (inputName: keyof EmailFormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  useEffect(() => {
    setHasStudentEmail(false);
    setHasPersonalEmail(false);
    if (PERSONAL_EMAIL_DOMAIN_REGEX.test(email)) {
      setHasPersonalEmail(true);
    } else if (STUDENT_EMAIL_REGEX.test(email)) {
      setHasStudentEmail(true);
    }
  }, [email]);

  const onInvalidEmail: FormEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    setEmailValidation({
      message: t('credentialsStep.email.error.invalid'),
      state: 'error',
    });
  };

  const onInvalidConfirmEmail: FormEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    setConfirmValidation(confirmEmailFormFieldValidation(confirmEmail, { email }));
  };

  const removeAllErrors = () => {
    clearErrors();
    clearErrorContextError();
    setEmailValidation(undefined);
    setConfirmValidation(undefined);
  };
  const getEmailValidation = (): FormFieldValidation | undefined => {
    const genericValidation = validateGeneric('email');
    if (genericValidation) return genericValidation;
    if (hasPersonalEmail)
      return {
        state: 'neutral',
        message: t('credentialsStep.email.error.teacherCheck.message'),
        icon: <IconSchool />,
      };
    if (hasStudentEmail)
      return {
        state: 'warning',
        message: (
          <span>
            {t('credentialsStep.email.error.studentCheck.message')}{' '}
            <a href={redirectToStudent}>{t('credentialsStep.email.error.studentCheck.messageLink')}</a>
          </span>
        ),
        icon: <IconWarning />,
      };
    return;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="email-form-form">
      <SpaceBetween spacing={spacing.size16} direction="y">
        <InputFormField
          label={t('credentialsStep.email.fieldLabel')}
          placeholder={t('credentialsStep.email.fieldPlaceholder')}
          type="email"
          id="email"
          autoComplete="email"
          required
          {...emailProps}
          validation={emailValidation || getEmailValidation()}
          //TODO: DEFECT-1411 - This will be temporarily added to deal with some cases of email validation. It will be reviewed further on with some other issues.
          pattern={VALID_EMAIL_REGEXP}
          onCopy={(event) => event.preventDefault()}
          onInvalid={onInvalidEmail}
        />
        <InputFormField
          label={t('credentialsStep.confirmEmail.fieldLabel')}
          placeholder={t('credentialsStep.confirmEmail.fieldPlaceholder')}
          type="email"
          required
          {...confirmEmailProps}
          id="confirm-email"
          autoComplete="confirm-email"
          validation={
            isValid
              ? { state: 'success', message: t('credentialsStep.confirmEmail.emailConfirmed') }
              : confirmValidation ||
                (isSubmitted &&
                  confirmEmailFormFieldValidation(confirmEmail, {
                    email,
                  })) ||
                undefined
          }
          //TODO: DEFECT-1411 - This will be temporarily added to deal with some cases of email validation. It will be reviewed further on with some other issues.
          pattern={VALID_EMAIL_REGEXP}
          onInvalid={onInvalidConfirmEmail}
        />
        <Button resizing="fixed" type="submit" loading={loading} size="large">
          {t('credentialsStep.signUpButton')}
        </Button>
      </SpaceBetween>
    </form>
  );
};
