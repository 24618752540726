import { rem, styled } from '@lessonup/ui-components';
import React, { CSSProperties, useEffect, useState } from 'react';

export interface SpinnerDiscProps {
  /** The titles to display on the segments. */
  segmentTitles: string[];

  /** Rotate the spinner such that the selected index is positioned at the top. */
  selectedIndex?: number;

  /**
   * The number of times this spinner has been spun.
   *
   * This ensures the spinning animation is shown even if a re-spin resulted in
   * the same index.
   */
  spinCount?: number;

  /** Signals when the spinning animation has started or stopped. */
  onAnimationEnd?: (isSpinning: boolean) => void;
}

export function SpinnerDisc(props: SpinnerDiscProps) {
  const titles = props.segmentTitles.length ? props.segmentTitles : [''];
  const count = titles.length;
  const { selectedIndex = 0, spinCount = 0, onAnimationEnd } = props;

  const [spinIndex, setSpinIndex] = useState(0);

  useEffect(() => {
    // We update the spin index every time one of the dependent values changes,
    // triggering a new animation.
    setSpinIndex(spinCount * count * 3 + selectedIndex);
  }, [count, selectedIndex, spinCount]);

  const gradients = titles.map((_, index) => Segment.getGradientCss(count, index));
  const spinnerStyle = {
    background: `conic-gradient(${gradients.join(', ')})`,
    // Rotate by half a segment to align with its center.
    transform: `rotate(${(-0.5 - spinIndex) / count}turn)`,
    transition: 'transform 2.5s',
  };

  return (
    <StyledSpinner style={spinnerStyle} onTransitionEnd={() => onAnimationEnd && onAnimationEnd(false)}>
      {titles.map((title, index) => (
        <SegmentTitle key={index} style={Segment.getTitleStyle(count, index)}>
          {title}
        </SegmentTitle>
      ))}
    </StyledSpinner>
  );
}

const Segment = {
  colors: ['#0D46F2', '#0B3FDA', '#0A38C2'],

  /** How wide the text title should be, as a fraction of the radius. */
  titleWidthInRadii: 0.75,

  /** Indexes Segment.colors, wrapping around for higher indices. */
  getColor(count: number, index: number): string {
    if (index === count - 1 && index % Segment.colors.length === 0) {
      // Because the first and last segments are adjacent, we want to avoid
      // them having the same colour.
      // If the last segment would have colour index 0, use the next colour (1)
      // instead.
      return Segment.colors[1];
    }

    return Segment.colors[index % Segment.colors.length];
  },

  getGradientCss(count: number, index: number): string {
    const color = Segment.getColor(count, index);
    const start = index / count;
    const end = (index + 1) / count;
    return `${color} ${start}turn ${end}turn`;
  },

  getTitleStyle(count: number, index: number): CSSProperties {
    // Compute how much to translate the element to the right to push it against
    // the edge of the disc. Expressed as a percentage of the title width
    // itself.
    const pushToEdge = `translate(${Math.round(
      ((1 - Segment.titleWidthInRadii) / Segment.titleWidthInRadii) * 100
    )}%, 0)`;

    return {
      transform: [
        `rotate(${(index + 0.5) / count}turn)`, // account for the index
        `rotate(-0.25turn)`, // start at the top instead of the right
        pushToEdge,
        // rotate around title center:
        'translate(50%, 0)',
        'rotate(0.5turn)',
        'translate(-50%, -50%)',
      ].join(' '),
    };
  },
};

const StyledSpinner = styled.div`
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

const SegmentTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${Math.round((Segment.titleWidthInRadii / 2) * 100)}%;
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  transform-origin: top left;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 0) 100%);
  font-size: ${rem('24px')};
`;
