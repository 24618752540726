const localStorageValueForIntroductoryStepperSkipped = 'skipped';

export const setIntroductoryStepperSkippedFlag = (localStoragekey: string) => {
  localStorage.setItem(localStoragekey, localStorageValueForIntroductoryStepperSkipped);
};

export const checkIfIntroductoryStepperWasSkipped = (localStoragekey: string) => {
  const skipped: string | null = localStorage.getItem(localStoragekey);
  if (skipped && skipped === localStorageValueForIntroductoryStepperSkipped) {
    return true;
  }
  return false;
};

export const clearIntroductoryStepperSkippedFlag = (localStorageKey: string) => {
  localStorage.removeItem(localStorageKey);
};
