import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconResizeMin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.375 2.625 13.5 10.5m0 0h3.75m-3.75 0V6.75M2.625 19.875a1.5 1.5 0 0 0 1.5 1.5m7.125-1.5a1.5 1.5 0 0 1-1.5 1.5M11.25 15a1.5 1.5 0 0 0-1.5-1.5M2.625 15a1.5 1.5 0 0 1 1.5-1.5m17.25-3v9.375a1.5 1.5 0 0 1-1.5 1.5H14.72M13.5 2.625H4.125c-.828 0-1.5.733-1.5 1.638V9.89"
    />
  </svg>
);
export default SvgIconResizeMin;
