import { tracker } from '@lessonup/analytics';
import { useDocumentMutation } from '@lessonup/client-integration';
import {
  ManagedModal,
  ModalFooter,
  ModalFooterAction,
  ModalFooterActionAsync,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  rem,
  showConfirmModal,
  useForm,
} from '@lessonup/ui-components';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratePollQuestionDocument } from '../ChatGPTGeneratorFeature.graphql.generated';
import { HelpButton } from '../components/HelpButton';
import { ChatGPTWizard, ChatGPTWizardSteps } from '../components/Wizard/ChatGPTWizard';
import { FormInputs, InteractivePromptStep } from '../components/Wizard/InteractivePromptStep/InteractivePromptStep';
import { ReviewStep } from '../components/Wizard/ReviewStep/ReviewStep';
import { useHandleGeneratorError } from '../hooks/useHandleGeneratorError';
import { PollResponse, ResponseSelection } from '../model';
import { mapGraphQLtoPollResponse } from './utils';

export interface GeneratePollModalProps {
  onGenerate: (results: PollResponse) => void;
  prompt?: string;
}

export const GeneratePollModal: React.FC<GeneratePollModalProps & NiceModalHocProps> = NiceModal.create(
  ({ onGenerate, prompt }) => {
    const modal = NiceModal.useModal();
    const { register, handleSubmit, reset, watch } = useForm<FormInputs>({ defaultValues: { prompt } });
    const [generatePollMutation] = useDocumentMutation(GeneratePollQuestionDocument);
    const [currentStep, setCurrentStep] = useState<ChatGPTWizardSteps>('prompt');

    // Conversion to [number] and then to an array again is necessary to get .filter() working later on
    const [pollResponse, setPollResponse] = useState<ResponseSelection<PollResponse>>([]);
    const lastGeneratedPrompt = useRef('');

    const [selection, setSelection] = useState<string[]>([]);
    const { t } = useTranslation('chatGPT');
    const showErrorAndGoToPrompt = useHandleGeneratorError('poll', () => setCurrentStep('prompt'), t);

    const onCloseClick = async () => {
      if (currentStep === 'prompt') {
        modal.hide();
        //Clear input fields
        reset();
      } else {
        const isConfirmed = await showConfirmModal({
          title: t('discardPrompt'),
          ariaLabel: t('discardPrompt'),
          description: t('discardPromptDescription', { type: 'poll' }),
          primaryAction: { label: t('discardPromptPrimaryActionLabel'), buttonType: 'negative' },
          secondaryAction: { label: t('discardPromptSecondaryActionLabel') },
          canClose: false,
          width: '400px',
        });

        if (isConfirmed) {
          modal.hide();
          tracker.events.experimentAiGenerationPromptDiscarded({
            pinType: 'poll',
            openAiPrompt: lastGeneratedPrompt.current,
          });
          setSelection([]);
          //Clear input fields
          reset();
          setCurrentStep('prompt');
        }
      }
    };

    const onGeneratePoll = async (inputData: FormInputs) => {
      setCurrentStep('loading');
      tracker.events.experimentAiGenerationStarted();

      try {
        const { data, errors } = await generatePollMutation({
          variables: {
            input: {
              prompt: inputData.prompt,
              numberOfAnswers: inputData.pollAnswerOptions || 3,
              numberOfPolls: inputData.slidesToGenerate,
              language: inputData.language,
            },
          },
        });

        if (!data || errors?.length) {
          showErrorAndGoToPrompt();
        } else {
          lastGeneratedPrompt.current = inputData.prompt;

          tracker.events.experimentAiGenerationSuccess({
            pinCount: data.generatePoll.polls.length,
            pinType: 'poll',
          });

          const response = mapGraphQLtoPollResponse(data.generatePoll);
          setPollResponse(response.list);
          setCurrentStep('review');
        }
      } catch (error: unknown) {
        showErrorAndGoToPrompt(error, pollResponse.length);
      }
    };

    const finishReview = () => {
      const list = pollResponse.filter((question) => selection.includes(question.tempId));
      tracker.events.experimentAiGenerationPromptSuccess({
        pinCount: list.length,
        pinType: 'poll',
        openAiPrompt: lastGeneratedPrompt.current,
      });
      onGenerate({ type: 'poll', list });
      modal.hide();
      setSelection([]);
      setCurrentStep('prompt');
    };

    const confirmChangePrompt = async () => {
      const isConfirmed = await showConfirmModal({
        title: t('reviewSlides.changePromptTitle'),
        ariaLabel: t('reviewSlides.changePromptTitle'),
        description: t('reviewSlides.changePromptDescription', { type: 'poll' }),
        primaryAction: { label: t('discardPromptPrimaryActionLabel'), buttonType: 'negative' },
        secondaryAction: { label: t('discardPromptSecondaryActionLabel') },
        canClose: false,
        width: '400px',
      });

      if (isConfirmed) {
        tracker.events.experimentAiGenerationPromptDiscarded({
          pinType: 'poll',
          openAiPrompt: lastGeneratedPrompt.current,
        });
        setCurrentStep('prompt');
      }
    };

    const promptStep = <InteractivePromptStep register={register} watch={watch} type="poll" />;
    const reviewStep = (
      <ReviewStep
        generatedResults={pollResponse.map((question) => {
          return {
            tempId: question.tempId,
            content: {
              type: 'poll',
              answers: question.options,
              question: question.statement,
            },
          };
        })}
        setSelection={setSelection}
        selection={selection}
        isCompleted={true}
      />
    );
    const promptAction: ModalFooterActionAsync = {
      onClick: handleSubmit(onGeneratePoll),
      type: 'submit',
      label: t('generate'),
      async: true,
      disabled: watch('prompt')?.length > 0 ? false : true,
    };
    const reviewAction: ModalFooterAction = {
      onClick: finishReview,
      type: 'submit',
      label: t('reviewSlides.createSlides'),
      disabled: !selection.length,
    };
    const changePrompt: ModalFooterActionAsync = {
      onClick: confirmChangePrompt,
      type: 'reset',
      label: t('reviewSlides.changePrompt'),
      async: true,
    };

    return (
      <ManagedModal modal={modal} contentLabel="label" width={rem('560px')} height={rem('720px')}>
        <ModalHeaderV1
          type="headline"
          title={{
            content: t('generatePoll'),
          }}
          onCloseButtonClick={onCloseClick}
          endSlot={<HelpButton />}
          showDivider
        />
        <ChatGPTWizard promptStep={promptStep} currentStep={currentStep} reviewStep={reviewStep} />

        {currentStep !== 'loading' && (
          <ModalFooter
            type="actions"
            text={t('stepXOf', { current: currentStep === 'prompt' ? '1' : '2', totalSteps: '2' })}
            primaryAction={currentStep === 'review' ? reviewAction : promptAction}
            secondaryAction={currentStep === 'review' ? changePrompt : undefined}
            showDivider
          />
        )}
      </ManagedModal>
    );
  }
);
