import { Button, IconRobot, NiceModal, spacing, styled, Tag } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { showIntroductoryStepperModal } from '../../components/IntroductoryStepperModal/IntroductoryStepperModal';
import { GeneratePollModal, GeneratePollModalProps } from './dataComponents/GeneratePollModal';
import { GenerateQuizModal, GenerateQuizModalProps } from './dataComponents/GenerateQuizModal';
import { pollSteps, quizSteps } from './dataComponents/stepsRoutes';
import { LessonResponse, PollResponse, QuizResponse } from './model';

export type ChatGPTQuestionType = 'quiz' | 'poll';
export type ChatGPTGeneratorResponse = QuizResponse | PollResponse | LessonResponse;

interface ChatGPTGeneratorFeatureProps {
  subject?: string; // TODO: Remove this
  ageRange?: number[]; // TODO: Remove this
  type: ChatGPTQuestionType;
  onGenerate: (results: ChatGPTGeneratorResponse) => void;
  prompt?: string;
}

export const ChatGPTGeneratorFeature: React.FC<ChatGPTGeneratorFeatureProps> = ({ type, onGenerate, prompt }) => {
  const { t } = useTranslation('chatGPT');
  const afterIntroAction = () => {
    type === 'quiz' && showQuizModal({ onGenerate, prompt: prompt });
    type === 'poll' && showPollModal({ onGenerate });
  };

  const handleClick = () => {
    type === 'quiz' &&
      showIntroductoryStepperModal({
        ariaLabel: 'Quiz Introductory',
        steps: quizSteps,
        introductoryStepperKey: 'quizzGenIntroModal',
        postStartAction: () => afterIntroAction(),
      });
    type === 'poll' &&
      showIntroductoryStepperModal({
        ariaLabel: 'Poll Introductory',
        steps: pollSteps,
        introductoryStepperKey: 'pollGenIntroModal',
        postStartAction: () => afterIntroAction(),
      });
  };

  return (
    <Button size="medium" buttonType="maia" onClick={() => handleClick()} iconStart={<IconRobot />}>
      {t('askMaia')}
      <StyledTag priority="high">{'Beta'}</StyledTag>
    </Button>
  );
};

export const showQuizModal = (props: GenerateQuizModalProps) => NiceModal.show(GenerateQuizModal, props);
export const showPollModal = (props: GeneratePollModalProps) => NiceModal.show(GeneratePollModal, props);

const StyledTag = styled(Tag)`
  margin-left: ${spacing.size8};
`;
