import { SimpleModal, spacing, styled, TextElement } from '@lessonup/ui-components';
import React, { useId } from 'react';
import { SpinnerDisc } from './SpinnerDisc';
import { SpinnerHighlight } from './SpinnerHighlight';

export interface SpinnerProps {
  segmentTitles: string[];
  segmentCount?: number;
  selectedIndex?: number;
  spinCount?: number;
  winnerTitle?: string;
  size: number;
  spinningHandler?: (isSpinning: boolean) => void;
}

export function Spinner(props: SpinnerProps) {
  const { size, spinningHandler } = props;
  const titles = getPaddedTitles(props.segmentTitles, props.segmentCount);
  const spinnerWrapperId = useId();

  // The fraction of a full circle turn that corresponds to a single segment.
  const segmentTurn = 1 / titles.length;

  return (
    <div style={{ width: size, height: size, position: 'relative' }} id={spinnerWrapperId}>
      <SimpleModal
        isOpen={!!props.winnerTitle}
        contentLabel="Spinner result"
        parentSelector={() => document.getElementById(spinnerWrapperId) as HTMLElement}
      >
        <WinnerText textStyle="display">{props.winnerTitle}</WinnerText>
      </SimpleModal>
      <SpinnerDisc
        segmentTitles={titles}
        selectedIndex={props.selectedIndex}
        spinCount={props.spinCount}
        onAnimationEnd={spinningHandler}
      />
      <SpinnerHighlight size={size} sweepTurn={segmentTurn} />
    </div>
  );
}

function getPaddedTitles(titles: string[], count?: number) {
  // Make sure there is at least one title element.
  const nonEmptyTitles = titles && titles.length ? titles : [''];

  // Use explicit count if provided, or otherwise number of titles.
  const finalCount = clampCount(count ?? titles.length);

  // If necessary, pad titles so that we have the required number.
  return cycleElements(nonEmptyTitles, finalCount);
}

/**
 * Safeguard so that the browser doesn't crash when the user inputs unreasonable
 * numbers of segments.
 */
function clampCount(count: number) {
  return Math.max(1, Math.min(64, count));
}

/**
 * Cycles elements (or truncates them) so that we have the specified number of
 * elements.
 */
function cycleElements(elements: string[], count: number) {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(elements[i % elements.length]);
  }
  return result;
}

const WinnerText = styled(TextElement)`
  text-align: center;
  padding: ${spacing.size24};
`;
