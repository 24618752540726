import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { spinKeyframe } from '../../foundations/animations/keyframes';
import { color } from '../../foundations/colors/colors';
import { IconLoader } from '../icons';

export interface LoaderSpinnerProps {
  type?: 'default' | 'overlay' | 'transparentOverlay';
  className?: string;
}

export const LoaderSpinner: React.FC<LoaderSpinnerProps> = ({ type = 'default', className }) => {
  return type !== 'default' ? (
    <StyledLoaderSpinnerBackground className={className} transparentOverlay={type === 'transparentOverlay'}>
      <StyledOverlayLoaderSpinner />
    </StyledLoaderSpinnerBackground>
  ) : (
    <StyledLoaderSpinner className={className} />
  );
};

const animationStyle = css`
  animation: ${spinKeyframe} 1s linear infinite;
`;

const StyledOverlayLoaderSpinner = styled(IconLoader)`
  ${animationStyle}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
`;

const StyledLoaderSpinner = styled(IconLoader)`
  ${animationStyle}
`;

const StyledLoaderSpinnerBackground = styled.div<{ transparentOverlay?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => (props.transparentOverlay ? 'transparent' : color.additional.disabled.text)};
  display: flex;
`;
