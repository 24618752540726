import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFullscreenExit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.625 20.75c-.198-2.164-1.96-3.928-4.123-4.136m12.873 4.136c.198-2.164 1.96-3.928 4.123-4.136M16.375 3.25c.198 2.164 1.96 3.928 4.123 4.136M7.625 3.25c-.198 2.164-1.96 3.928-4.123 4.136"
    />
  </svg>
);
export default SvgIconFullscreenExit;
