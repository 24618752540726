import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { spacing } from '../../../foundations';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';
import { SvgComponent } from '../../icons';

export type HeaderAlignment = 'start' | 'middle' | 'end';

export interface TableSlotHeaderProps {
  text?: string;
  endSvgIcon?: SvgComponent;
  alignment?: HeaderAlignment;
  onClick?: () => void;
}

export function TableSlotHeader(props: TableSlotHeaderProps) {
  const { onClick = undefined } = props;
  return (
    <StyledHeader as="button" direction="x" spacing={spacing.size8} alignItems="center" onClick={onClick} {...props}>
      <BodyText size="small" weight="bold" ellipsis={true} className={textClassName}>
        {props.text}
      </BodyText>
      {props.endSvgIcon && <props.endSvgIcon />}
    </StyledHeader>
  );
}

const textClassName = 'headerText';

const StyledHeader = styled(SpaceBetween)<TableSlotHeaderProps>`
  text-align: left;
  width: 100%;

  height: ${rem('32px')};
  ${(props) => {
    if (!props.onClick) {
      return css`
        cursor: default !important;
      `;
    }
  }}

  .${textClassName} {
    ${(props) => {
      switch (props.alignment) {
        case 'end':
          return css`
            flex: 1;
            text-align: right;
          `;
        case 'middle':
          return css`
            flex: 1;
            text-align: center;
          `;
      }
    }}
  }

  svg {
    width: ${rem('16px')};
    height: ${rem('16px')};
  }
`;
