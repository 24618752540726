import React from 'react';
import { Button } from '../../Button/Button';
import { IconCheckbox, IconCheckboxChecked, IconCheckboxIndeterminate } from '../../icons';
import { StyledRow } from '../TableRow/TableRow';
import { TableSlotHeader, TableSlotHeaderProps } from '../TableSlotHeader/TableSlotHeader';
import { TableRowInteractivity, TableSelectedRowsDispatch } from '../types';
import { computeInteractivity } from '../utils';

export interface TableCellsHeader {
  cells: TableSlotHeaderProps[];
}

interface TableHeaderProps {
  header: TableCellsHeader;
  rowIds: string[];
  selectedRows?: string[];
  setSelectedRows?: TableSelectedRowsDispatch;
  interactivity?: TableRowInteractivity;
}

export function TableHeader(props: TableHeaderProps) {
  const { interactivity, selectedRows = [], setSelectedRows } = props;
  const { isSelectable, isDraggable } = computeInteractivity(interactivity);
  const { cells } = props.header;
  const allRowsSelected = selectedRows.length === props.rowIds.length;
  const someRowsSelected = selectedRows.length > 0;
  const buttonIcon = allRowsSelected ? (
    <IconCheckboxChecked />
  ) : someRowsSelected ? (
    <IconCheckboxIndeterminate />
  ) : (
    <IconCheckbox />
  );

  const toggleAllSelected = () => {
    setSelectedRows?.(someRowsSelected ? [] : props.rowIds);
  };

  return (
    <StyledRow isSelectable={isSelectable} isDraggable={isDraggable}>
      {isSelectable && (
        <th scope="col">
          <Button
            size="small"
            buttonType="neutral"
            role="checkbox"
            aria-checked={allRowsSelected}
            onClick={toggleAllSelected}
            iconStart={buttonIcon}
          />
        </th>
      )}

      {cells.map((cell, index) => (
        <th key={index} scope="col">
          <TableSlotHeader {...cell} />
        </th>
      ))}
    </StyledRow>
  );
}
