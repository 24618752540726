import { Observable } from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';

export const MAX_NETWORK_RETRY_COUNT = 1;
export const RETRY_DELAY_MS = 500;

export const onErrorFunction: ErrorLink.ErrorHandler = ({ networkError, operation, forward }) => {
  if (MAX_NETWORK_RETRY_COUNT <= 0 || !networkError) {
    return;
  }
  operation.setContext({ retryCount: 1 });

  // Return a new Observable that will retry the request after a delay and up to a maximum number of retries
  return new Observable((observer) => {
    const retryOperation = () => {
      const handle = setTimeout(() => {
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: (error) => {
            const retryCount = operation.getContext().retryCount;
            if (retryCount < MAX_NETWORK_RETRY_COUNT) {
              // This handles any additional retries after the first one. It will never execute if MAX_NETWORK_RETRY_COUNT is 1 or less
              operation.setContext({ retryCount: retryCount + 1 });
              retryOperation();
            } else {
              observer.error(error);
            }
          },
          complete: observer.complete.bind(observer),
        });
      }, RETRY_DELAY_MS);

      return () => {
        clearTimeout(handle);
      };
    };
    retryOperation();
  });
};

export const retryLink = onError(onErrorFunction);
