import React, { useEffect, useRef, useState } from 'react';
import YouTubePlayer from 'youtube-player';
import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import { useEndTimeHandlerInterval } from '../hooks/useEndTimeHandler';
import { useVideoPlayerCurrentTimeRequest } from '../hooks/useVideoPlayerHandle';
import { YouTubePlayerProps } from './videoPlayerTypes';

export const YouTubePlayerComponent: React.FC<YouTubePlayerProps> = ({
  sourceId,
  compId,
  startTime,
  endTime,
  scaledWidth,
  scaledHeight,
}) => {
  const playerRef = useRef<ReturnType<typeof YouTubePlayer> | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const getCurrentTime = async () => {
    if (playerRef.current) {
      return await playerRef.current.getCurrentTime();
    }
    return 0;
  };

  useVideoPlayerCurrentTimeRequest(compId, getCurrentTime);

  useEndTimeHandlerInterval(
    getCurrentTime,
    endTime,
    () => {
      playerRef.current?.pauseVideo();
    },
    isPlaying,
    150
  );

  useEffect(() => {
    if (containerRef.current && !playerRef.current) {
      playerRef.current = YouTubePlayer(containerRef.current, {
        videoId: sourceId,
        playerVars: {
          controls: 1,
          start: startTime || 0,
        },
      });

      playerRef.current.setSize(scaledWidth, scaledHeight);
    }
  }, [sourceId, startTime, scaledWidth, scaledHeight]);

  useEffect(() => {
    const playerInstance = playerRef.current;

    if (!playerInstance) return;

    if ('cueVideoById' in playerInstance && sourceId) {
      const youtubePlayer = playerInstance;

      youtubePlayer.cueVideoById({
        videoId: sourceId,
        startSeconds: startTime || 0,
      });
    }

    playerInstance.on('stateChange', (event) => {
      if (event.data === PlayerStates.PLAYING) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    });
  }, [sourceId, startTime]);

  return <div ref={containerRef} className="player-container" />;
};
