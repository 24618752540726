import { getLocale } from '@lessonup/client-integration';
import {
  BodyText,
  Button,
  ButtonAnchor,
  ButtonProps,
  IconHelp,
  PageSection,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { localeToIsoLocaleString } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { helpCenterArticleUrl } from '../../../utils';

export interface OptOutDeletionBodyProps {
  onKeepAccountClick: ButtonProps['onClick'];
  scheduledAt: Date;
  ticketNumber: string;
  loading: boolean;
}

export const OptOutDeletionBody = ({
  ticketNumber,
  loading,
  scheduledAt,
  onKeepAccountClick,
}: OptOutDeletionBodyProps) => {
  const { t: tWithoutPrefix } = useTranslation('accountDeletion');
  const t = (key: string, options?: Record<string, unknown>) => {
    return tWithoutPrefix(`optOutDeletionBody.${key}`, options);
  };
  const locale = getLocale();

  return (
    <SpaceBetween direction="y" spacing={spacing.size16}>
      {/* Using plain h2 to match styling in 1.0 profile page */}
      <h2 className="account-subtitle">
        {'⚠️ '}
        {t('heading')}
      </h2>
      <PageSection>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <div>
            <InlineBodyText>
              {t('deletionScheduledAt', {
                scheduledAt: scheduledAt.toLocaleDateString(localeToIsoLocaleString(locale), {}),
              })}
              {':'}
            </InlineBodyText>{' '}
            <InlineBodyText weight="bold"> {ticketNumber}</InlineBodyText>
            <BodyText>{t('retainAccount')}</BodyText>
          </div>

          <SpaceBetween direction="x" spacing={spacing.size8}>
            <Button resizing="hug" onClick={onKeepAccountClick} disabled={loading}>
              {t('button.label')}
            </Button>
            <ButtonAnchor
              buttonType="neutral"
              href={helpCenterArticleUrl(locale)}
              target="_blank"
              iconStart={<IconHelp />}
            >
              {t('learnMore')}
            </ButtonAnchor>
          </SpaceBetween>
        </SpaceBetween>
      </PageSection>
    </SpaceBetween>
  );
};

const InlineBodyText = styled(BodyText)`
  display: inline-block;
`;
