import { defaultGroup } from '@lessonup/pins-shared';
import { findKey } from 'lodash';

const learningPhaseNames = ['preparation', 'instruction', 'practice', 'evaluation', 'differentiate'] as const;
const learningPhaseOrderGroups = ['g01', 'g02', 'g03', 'g04', 'g05'] as const;
export type LearningPhaseName = (typeof learningPhaseNames)[number];
export type LearningPhaseOrder = (typeof learningPhaseOrderGroups)[number];
export type NoLearningPhaseOrder = 'g00';
export type LearningPhaseOrNoPhaseOrder = LearningPhaseOrder | NoLearningPhaseOrder;

export const learningPhaseOrderMap: Record<LearningPhaseName, LearningPhaseOrder> = {
  preparation: 'g01',
  instruction: 'g02',
  practice: 'g03',
  evaluation: 'g04',
  differentiate: 'g05',
};

export function isLearningPhaseName(learningPhase: string): learningPhase is LearningPhaseName {
  return learningPhaseNames.includes(learningPhase as LearningPhaseName);
}

export function getLearningPhaseNameFromOrder(order: string): LearningPhaseName {
  const learningPhase = findKey(learningPhaseOrderMap, (learningPhaseOrder) => learningPhaseOrder === order);

  if (!learningPhase || !isLearningPhaseName(learningPhase)) {
    throw new Error('Invalid learning phase');
  }

  return learningPhase;
}

export const noPhaseNumber: NoLearningPhaseOrder = defaultGroup;

export const learningPhasesI18NNS = 'learningPhases';

export function isLearningPhaseOrder(value: string): value is LearningPhaseOrder {
  return (
    typeof value === 'string' &&
    Object.values(learningPhaseOrderMap).some((learningPhaseOrder) => value.startsWith(learningPhaseOrder))
  );
}

export function getPreviousPhase(value: LearningPhaseOrNoPhaseOrder): LearningPhaseOrder {
  const phase = parseInt(value.substring(1, 3), 10);
  if (phase === 0) {
    throw new Error('No previous phase');
  }
  return `g${(phase + 1).toString().padStart(2, '0')}` as LearningPhaseOrder; // @TODO fix this
}

export const isFirstPhase = (phase: LearningPhaseOrder): boolean => phase === learningPhaseOrderMap.preparation;
export const isLastPhase = (phase: LearningPhaseOrder): boolean => phase === learningPhaseOrderMap.differentiate;
