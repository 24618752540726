import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { orderableCompareFn, QuizPinLayoutTemplate, TeacherPin, useUpdatePin } from '@lessonup/pin-renderer';
import { rem, styled } from '@lessonup/ui-components';
import { groupBy } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useShowContextMenu } from '../../../../components/ContextMenuProvider/useShowContextMenu';
import { useEditorContext } from '../../context/EditorContext/EditorContext';
import {
  useActivePinId,
  useCollaborators,
  usePins,
  useSelectedPinIds,
} from '../../context/EditorContext/hooks/useEditorState';
import { generateMaiaQuiz } from '../../utils/maia/maia.utils';
import { useEditorYjs } from '../../utils/yjs/EditorYjs/YjsContextEditor';
import { PinRailContextMenu } from '../EditorContextMenus/PinRailContextMenu/PinRailContextMenu';
import { PinRailContextMenuItemProps } from '../EditorContextMenus/PinRailContextMenu/pinRailContextMenu.types';
import { PIN_RAIL_CONTEXT_MENU_ID } from '../EditorContextMenus/PinRailContextMenu/pinRailContextMenu.utils';
import { PinRailList } from './components/PinRailList';
import { DragOverlayPinRailThumb } from './components/PinRailThumb';
import { PinRailListContextProvider } from './context/PinRailListContextProvider';
import { useCustomRestrictToParentDndModifier } from './hooks/useCustomRestrictToParentDndModifier';
import { getSortAfterIdForActiveAndOverPin, pinsAndPhases } from './PinRail.utils';

export const PinRail: React.FC = () => {
  const collaborators = useCollaborators();
  const pinRailRef = useRef<HTMLDivElement>(null);
  const dispatch = useUpdatePin();
  const activePinId = useActivePinId();
  const dbPins = usePins();
  const selectedPinIds = useSelectedPinIds();
  const { setActivePinId, setSelectedPinIds, deletePins, pastePins, lessonId, resetSelection } = useEditorContext();
  const { yDoc } = useEditorYjs();
  const [sortingPinId, setSortingPinId] = useState<string | null>(null);
  const [handleContextMenu] = useShowContextMenu<PinRailContextMenuItemProps>();
  const onAddNewPins = useCallback(
    (pinIds: string[]) => {
      setActivePinId(pinIds[pinIds.length - 1]);
    },
    [setActivePinId]
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );
  const showPhases = false; //TODO: When implementing phases toggle get this from the lesson data.

  // TODO: ED-119 - This is a hack to get the pins to update instantly
  // Waiting for minimongo is too slow, better solution should be found
  const pinsAndPhasesList = useMemo(
    () => pinsAndPhases(dbPins, showPhases, sortingPinId, selectedPinIds),
    [dbPins, showPhases, sortingPinId, selectedPinIds]
  );
  const sortedPins = useMemo(() => [...dbPins].sort(orderableCompareFn), [dbPins]);
  const collaboratorsDict = useMemo(() => groupBy(collaborators, 'pinId'), [collaborators]);
  const isDragOverlayStacked = useMemo(
    () => (sortingPinId ? selectedPinIds.includes(sortingPinId) && selectedPinIds.length > 1 : false),
    [sortingPinId, selectedPinIds]
  );

  const handleOnMouseDown = useCallback(
    (event: React.MouseEvent, pinId: string) => {
      const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
      const isShiftPressed = event.shiftKey;

      if (isCtrlOrCmdPressed) {
        if (selectedPinIds.includes(pinId)) {
          setSelectedPinIds(selectedPinIds.filter((id) => id !== pinId));
        } else {
          setSelectedPinIds([...selectedPinIds, pinId]);
        }
      } else if (isShiftPressed && activePinId) {
        const sortedPinIds = sortedPins.map((pin) => pin.id);
        const startIndex = sortedPinIds.indexOf(activePinId);
        const endIndex = sortedPinIds.indexOf(pinId);

        if (startIndex > -1 && endIndex > -1) {
          const range = sortedPinIds.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
          setSelectedPinIds([...new Set([...selectedPinIds, ...range])]);
        }
      } else {
        resetSelection();
        setActivePinId(pinId);
      }
    },
    [activePinId, selectedPinIds, setSelectedPinIds, sortedPins, resetSelection, setActivePinId]
  );

  const contextMenuHandler = useCallback(
    (event: React.MouseEvent, pin: TeacherPin) => {
      handleContextMenu(PIN_RAIL_CONTEXT_MENU_ID, event, {
        yDoc,
        pin,
      });
    },
    [handleContextMenu, yDoc]
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;
    setSortingPinId(active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const sortData = getSortAfterIdForActiveAndOverPin(pinsAndPhasesList, active.id, over?.id);
      const pinIds = selectedPinIds.includes(active.id) ? selectedPinIds : [active.id];

      if (active.id !== over?.id) {
        dispatch({
          type: 'reorderPins',
          ...sortData,
          pinIds,
        });
      }
      setSortingPinId(null);
    },
    [pinsAndPhasesList, dispatch, selectedPinIds]
  );

  const startGenerateMaiaQuiz = useCallback(
    (afterPinId: string, layout: QuizPinLayoutTemplate, prompt?: string) => {
      generateMaiaQuiz(afterPinId, layout, dispatch, onAddNewPins, lessonId, prompt);
    },
    [dispatch, onAddNewPins, lessonId]
  );

  const customRestrictToParentDndModifier = useCustomRestrictToParentDndModifier(isDragOverlayStacked, selectedPinIds);

  const activeDragPinIndex = sortingPinId ? pinsAndPhasesList.findIndex((pin) => pin.id === sortingPinId) : -1;
  // can never be a phase
  const activeDragPin = (activeDragPinIndex > -1 ? pinsAndPhasesList[activeDragPinIndex] : undefined) as
    | TeacherPin
    | undefined;

  const inPinSelectionMode = selectedPinIds.length > 0;

  return (
    <PinRailListContextProvider
      collaboratorsDict={collaboratorsDict}
      activePinId={activePinId}
      selectedPinIds={selectedPinIds}
      inPinSelectionMode={inPinSelectionMode}
      dbPins={sortedPins}
      setActivePinId={setActivePinId}
      setSelectedPinIds={setSelectedPinIds}
      contextMenuHandler={contextMenuHandler}
      handleOnMouseDown={handleOnMouseDown}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis, customRestrictToParentDndModifier, restrictToFirstScrollableAncestor]}
      >
        <PinRailContainer ref={pinRailRef}>
          <SortableContext items={pinsAndPhasesList} strategy={verticalListSortingStrategy}>
            <PinRailList items={pinsAndPhasesList} activePinId={activePinId} />
          </SortableContext>
          <DragOverlay>
            {activeDragPin ? (
              <DragOverlayPinRailThumb
                pin={activeDragPin}
                index={sortedPins.findIndex((pin) => pin.id === activeDragPin.id)}
                stackedPinsCount={isDragOverlayStacked ? selectedPinIds.length : undefined}
                collaboratorsDict={collaboratorsDict}
              />
            ) : null}
          </DragOverlay>
          <PinRailContextMenu
            deletePins={deletePins}
            pastePins={pastePins}
            startGenerateMaiaQuiz={startGenerateMaiaQuiz}
          />
        </PinRailContainer>
      </DndContext>
    </PinRailListContextProvider>
  );
};

const PinRailContainer = styled.div`
  height: 100%;
  width: ${rem('192px')};
`;
