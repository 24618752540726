import { parseStringWithOptions } from '@lessonup/utils';
import { WordCloudView } from '../../playerStore.types';

export function normalizeAnswer(answer: string): string {
  return parseStringWithOptions(answer, { asLowercase: true, normalizeDiacritics: true });
}

export const areAllWordCloudAnswersRevealed = (currentView: WordCloudView) => {
  if (currentView.answers.length > currentView.revealedAnswers.length) return false;

  const revealedAnswersSet = new Set(currentView.revealedAnswers);
  return currentView.answers.every((answer) => revealedAnswersSet.has(answer.text));
};
