import {
  BodyText,
  borderRadius,
  Checkbox,
  color,
  css,
  Divider,
  SpaceBetween,
  spacing,
  styled,
  Tag,
  TextListItem,
  TextListItemContentSlot,
} from '@lessonup/ui-components';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BlinkingCursor } from '../../features/ChatGPTGenerator/components/Wizard/ContextValidationStep/BlinkingCursor';
import { LearningPhaseOrNoPhaseNumber } from '../../utils/learningPhases/LearningPhase';
import { GeneratedAnswer, GeneratedAnswerProps, Position } from './GeneratedAnswer/GeneratedAnswer';

interface QuizGeneratedResultProps {
  type: 'quiz';
  question: string;
  answers: GeneratedAnswerProps['answer'][];
  correctAnswers?: number[];
  note?: string;
}

interface PollGeneratedResultProps {
  type: 'poll';
  question: string;
  answers: GeneratedAnswerProps['answer'][];
  note?: string;
}

export interface SlideGeneratedResultProps {
  type: 'slide';
  header: string;
  layout: 'text' | 'cover';
  text: string | React.JSX.Element; // React.JSX.Element is used for bullet points
  note?: string;
}

interface OpenQuestionGeneratedResultProps {
  type: 'openQuestion';
  question: string;
  note?: string;
}

interface WordwebGeneratedResultProps {
  type: 'wordweb';
  word: string;
  note?: string;
}

export type GeneratedResultContent =
  | QuizGeneratedResultProps
  | PollGeneratedResultProps
  | SlideGeneratedResultProps
  | OpenQuestionGeneratedResultProps
  | WordwebGeneratedResultProps;

export type GeneratedResultProps<T extends GeneratedResultContent = GeneratedResultContent> = {
  selected: boolean;
  tempId: string;
  content: T;
  phaseNumber?: LearningPhaseOrNoPhaseNumber;
  isCompleted?: boolean;
  isLast?: boolean;
};

export type GeneratedResultType = GeneratedResultContent['type'];

export const GeneratedResult = forwardRef<HTMLInputElement, GeneratedResultProps>(
  // phaseNumber is only destructed so it's not included in the rest props.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ tempId: id, selected, content, isCompleted, isLast, phaseNumber, ...rest }, forwardedRef) => {
    const { t } = useTranslation('chatGPT');
    const positions: Position[] = ['A', 'B', 'C', 'D'];

    const headerText =
      content.type === 'slide' ? content.header : content.type === 'wordweb' ? content.word : content.question;

    const tagTranslationKey =
      content.type === 'slide' ? (content.layout === 'cover' ? 'cover' : 'slide') : content.type;

    return (
      <StyledGeneratedResult selected={selected}>
        <StyledContentWrapper direction="y" spacing={spacing.size8}>
          <StyledHeader>
            <BodyText weight="bold">{headerText}</BodyText>
            <Tag>{t(tagTranslationKey)}</Tag>
          </StyledHeader>
          {(content.type === 'quiz' || content.type === 'poll') && (
            <StyledAnswers direction="y" spacing={spacing.size8} as="ol">
              {content.type === 'quiz' &&
                content.answers.map((answer, index) => (
                  <GeneratedAnswer
                    key={index}
                    // correctAnswers is 1-based, also in Teacher Old '{{#if or (equals item.custom.correctAnswer 1)' is used
                    correct={content.correctAnswers?.includes(index + 1) ?? false}
                    position={positions[index]}
                    answer={answer}
                  />
                ))}
              {content.type === 'poll' &&
                content.answers?.map((answer, index) => (
                  <TextListItem
                    key={index}
                    fontSize="small"
                    startSlot={<TextListItemContentSlot type="number" number={index + 1} />}
                    text={answer}
                  />
                ))}
            </StyledAnswers>
          )}
          {content.type === 'slide' && content.text && (
            <SpaceBetween direction="y" spacing={spacing.size8}>
              <BodyText>{content.text}</BodyText>
            </SpaceBetween>
          )}
          {content.note && (
            <SpaceBetween direction="y" spacing={spacing.size8}>
              <StyledNotes size="small">{content.note}</StyledNotes>
            </SpaceBetween>
          )}
          {isLast && !isCompleted && <BlinkingCursor />}
        </StyledContentWrapper>
        <div>
          <Divider orientation="horizontal" noSpacer={true} />
          <StyledFooterBody>
            <Checkbox
              id={`checkbox-for-${id}`}
              label={t('checkboxUseInLesson')}
              ref={forwardedRef}
              value={id}
              checked={selected}
              disabled={!isCompleted}
              {...rest}
            />
          </StyledFooterBody>
        </div>
      </StyledGeneratedResult>
    );
  }
);

GeneratedResult.displayName = 'GeneratedResult';

const StyledGeneratedResult = styled.li<Pick<GeneratedResultProps, 'selected'>>`
  background: ${(props) =>
    props.selected ? color.accent.secondaryContainer.background : color.neutral.surface.background};
  border-radius: ${borderRadius.rounded4};
  list-style: none;

  border: ${(props) =>
    props.selected
      ? css`1px solid ${color.accent.secondary.background}`
      : css`1px solid ${color.neutral.outline.background}`};
  outline: ${(props) =>
    props.selected
      ? css`1px solid ${color.accent.secondary.background}`
      : css`none`}; // using the outline doesn't give a 'jump' in the interface when selecting/deselecting
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.size8};

  span {
    align-self: flex-start;
  }
`;

const StyledAnswers = styled(SpaceBetween)`
  flex: 1;
  margin: 0;
  padding: 0;
`;

const StyledContentWrapper = styled(SpaceBetween)`
  background: ${color.neutral.surface.background};
  padding: ${spacing.size12} ${spacing.size16};
  border-radius: ${borderRadius.rounded4};
`;

const StyledNotes = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;

const StyledFooterBody = styled.div`
  padding: ${spacing.size12} ${spacing.size16};
`;
