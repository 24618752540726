import { asError } from '@lessonup/utils';
import { useState } from 'react';
import { useErrorContext } from '../../utils/Error/hooks/useErrorContext';

interface UseAsyncLoadingStateOptions {
  errorHandler?: (error: unknown) => void;
}
/** This hook provides a loading state and an async wrapper to handle async functions. */
export const useAsyncLoadingState = (options: UseAsyncLoadingStateOptions = {}) => {
  const { setError } = useErrorContext();
  const [loading, setLoading] = useState(false);

  const asyncWrapper = async (handler: () => Promise<void>) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await handler();
    } catch (error: unknown) {
      if (options.errorHandler) {
        options.errorHandler(error);
      } else {
        setError({ error: asError(error), logError: true });
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, asyncWrapper };
};
