export const triggerDownloadFromFetchResponse = async (fetchResponseForUploadUrl: Response, uploadName: string) => {
  const uploadBlob = await fetchResponseForUploadUrl.blob();
  const url = window.URL.createObjectURL(uploadBlob);
  const anchorElement = document.createElement('a');
  anchorElement.setAttribute('href', url);
  anchorElement.setAttribute('download', uploadName);
  anchorElement.setAttribute('target', '_blank');
  document.body.appendChild(anchorElement);
  anchorElement.click();
  anchorElement && anchorElement.parentNode && anchorElement.parentNode.removeChild(anchorElement);
};
