import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconWand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.866 10.135 2.854 21.147M19.59 4.41l-1.715 1.714m-8.779-.758 1.715 1.715m2.647-4.227.485 2.375m4.69 9.674-1.715-1.714m4.227-2.648-2.375-.485"
    />
  </svg>
);
export default SvgIconWand;
