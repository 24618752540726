import { isNull } from 'lodash';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { usePlayerStore } from '../context/PlayerStoreContext';
import { AnyView, ViewTypeToView } from '../playerStore.types';

export const useIsPlayerLoading = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.isPlayerLoading);
};

export const useAssignment = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.assignment);
};

export const useNonNullAssignment = () => {
  const assignment = useAssignment();

  if (isNull(assignment)) {
    throw new Error('Null assignment is not valid.');
  }

  return assignment;
};

export const useAssignmentId = () => {
  return useNonNullAssignment()?._id;
};

export const useCurrentPinId = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.currentPinId);
};

export const useCurrentPin = () => {
  const store = usePlayerStore();
  useCurrentPinId();
  useCurrentView();
  useSyncExternalStore(store.subscribeAssignment, store.componentState);
  return store.currentPin();
};

export const useNextPin = () => {
  const store = usePlayerStore();
  useCurrentPinId();
  useCurrentView();
  useSyncExternalStore(store.subscribeAssignment, store.componentState);
  return store.nextPin();
};

export const useCurrentPinState = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeEntries, store.currentPinState);
};

export const useAssignmentPins = () => {
  const store = usePlayerStore();
  const assignment = useNonNullAssignment();
  const pinIds = useSyncExternalStore(store.subscribeAssignment, store.getPinIds);

  return pinIds.map((pinId) => assignment.pins[pinId]);
};

export const useAssignmentEntries = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeEntries, store.assignmentEntries);
};

export const usePlayerDispatcher = () => {
  return usePlayerStore().dispatch;
};

export const useCurrentView = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.currentView);
};

export const useFocusMode = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.focusMode);
};

export const useCurrentNavigationState = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeAssignment, store.currentNavigationState);
};

export const useCurrentViewByType = <T extends AnyView['type']>(type: T): ViewTypeToView<T> | null => {
  const currentView = useCurrentView();
  if (currentView?.type !== type) {
    return null;
  }

  return currentView as ViewTypeToView<T>;
};

export const useRemainingStudentCount = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeEntries, store.getRemainingStudentCount);
};

export const useCurrentStudents = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeEntries, store.getCurrentStudents);
};

export const useBackgroundAction = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeBackgroundAction, store.getBackgroundActionState);
};

export const useSelectedPinComponentId = () => {
  const store = usePlayerStore();
  return useSyncExternalStore(store.subscribeSelectedPinComponentId, store.getSelectedPinComponentId);
};
