import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconHeadphones = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.27 11.87c-.479-3.022.21-8.933 6.761-8.933 6.56 0 7.489 5.926 6.764 8.944m-3.929 2.668a3 3 0 0 1 3.25-2.727l.166.015a3 3 0 0 1 2.727 3.25l-.283 3.237a3 3 0 0 1-3.25 2.727l-.165-.015a3 3 0 0 1-2.728-3.25zm-11.875.538a3 3 0 0 1 2.727-3.25l.165-.015a3 3 0 0 1 3.25 2.727l.284 3.237a3 3 0 0 1-2.727 3.25l-.166.015a3 3 0 0 1-3.25-2.727z"
    />
  </svg>
);
export default SvgIconHeadphones;
