import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12h18M3 12c2.05-2.526 3.364-3.84 5.994-5.994M3 12c2.05 2.526 3.364 3.84 5.994 5.994"
    />
  </svg>
);
export default SvgIconArrowLeft;
