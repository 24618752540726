import {
  AsyncButton,
  AsyncMouseEventHandler,
  BodyText,
  breakpoints,
  Button,
  ButtonAnchor,
  ButtonAnchorProps,
  ButtonProps,
  color,
  css,
  ModalFooterAction,
  ModalFooterActionAnchor,
  ModalFooterActionAsync,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';

export type FooterAction = Omit<ButtonProps, 'children'> & { async?: boolean } & {
  label: string | React.JSX.Element;
};

export type FooterActionAsync = FooterAction & { async: true } & {
  onClick: AsyncMouseEventHandler;
};

export type AnchorFooterAction = Omit<ButtonAnchorProps, 'children'> & {
  label: string | React.JSX.Element;
};

export interface FullPageFlowFooterProps {
  primaryAction: FooterAction | FooterActionAsync | AnchorFooterAction;
  secondaryAction?: FooterAction | AnchorFooterAction;
  text?: string;
}

const spacingBetween = spacing.size8;

const isAsyncAction = (
  action: ModalFooterAction | ModalFooterActionAsync | ModalFooterActionAnchor
): action is ModalFooterActionAsync => {
  return !!(action as ModalFooterActionAsync).async;
};

const isAnchorAction = (
  action: ModalFooterAction | ModalFooterActionAsync | ModalFooterActionAnchor
): action is ModalFooterActionAnchor => {
  return !!(action as ModalFooterActionAnchor).href;
};

export function FullPageFlowFooter(props: FullPageFlowFooterProps) {
  const { primaryAction, secondaryAction, text } = props;

  const sharedPrimaryButtonProps = {
    buttonType: primaryAction.buttonType ?? 'primary',
  };

  return (
    <StyledFooter>
      <StyledFooterBody direction="x" spacing={spacingBetween} alignItems="center" justifyContent="end">
        {text && <StyledBodyText>{text}</StyledBodyText>}
        {isAsyncAction(primaryAction) ? (
          <StyledAsyncPrimaryButton {...sharedPrimaryButtonProps} {...primaryAction}>
            {primaryAction.label}
          </StyledAsyncPrimaryButton>
        ) : isAnchorAction(primaryAction) ? (
          <StyledPrimaryButtonAnchor {...sharedPrimaryButtonProps} {...primaryAction}>
            {primaryAction.label}
          </StyledPrimaryButtonAnchor>
        ) : (
          <StyledPrimaryButton {...sharedPrimaryButtonProps} {...primaryAction}>
            {primaryAction.label}
          </StyledPrimaryButton>
        )}
        {secondaryAction &&
          (isAnchorAction(secondaryAction) ? (
            <ButtonAnchor
              buttonType={secondaryAction.buttonType ?? 'neutral'}
              {...secondaryAction}
              showStroke={secondaryAction.buttonType === undefined}
            >
              {secondaryAction.label}
            </ButtonAnchor>
          ) : (
            <Button
              buttonType={secondaryAction.buttonType ?? 'neutral'}
              {...secondaryAction}
              showStroke={secondaryAction.buttonType === undefined}
            >
              {secondaryAction.label}
            </Button>
          ))}
      </StyledFooterBody>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: ${color.neutral.surface.background};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 2;
`;

const StyledFooterBody = styled(SpaceBetween)`
  padding: ${spacing.size16};
  max-width: ${breakpoints.maxWidthText};
  margin: 0 auto;
`;

const StyledBodyText = styled(BodyText)`
  color: ${color.additional.disabled.text};
  flex-grow: 1;
`;

const sharedPrimaryButtonStyling = css`
  order: 2;
  margin-left: ${spacingBetween};
`;

const StyledPrimaryButton = styled(Button)`
  ${sharedPrimaryButtonStyling}
`;

const StyledAsyncPrimaryButton = styled(AsyncButton)`
  ${sharedPrimaryButtonStyling}
`;

const StyledPrimaryButtonAnchor = styled(ButtonAnchor)`
  ${sharedPrimaryButtonStyling};
`;
