import { newSize, Size } from '@lessonup/pins-shared';

export function getImageSizeFromSource(imgUrl: string): Promise<Size> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve(newSize(img.width, img.height));
    };
    img.src = imgUrl;
  });
}
