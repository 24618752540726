import { BodyText, Divider, Headline, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_NAMESPACE } from '../consts';
import { EmailForm, EmailFormInputs, EmailFormProps } from '../EmailForm/EmailForm';
import { SSOSignup, SSOSignupProps } from '../SSOSignup/SSOSignup';

type BaseProps = Pick<EmailFormProps, 'redirectToStudent' | 'loading' | 'initialEmail'> &
  Pick<SSOSignupProps, 'handleRegisterSSO'>;

export interface RegistrationCredentialsProps extends BaseProps {
  submitRegistration: (params: EmailFormInputs) => Promise<void>;
  redirectToLogin: string;
}

export const RegistrationCredentials = (props: RegistrationCredentialsProps) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const { submitRegistration, redirectToStudent, redirectToLogin, handleRegisterSSO, ...emailFormProps } = props;

  return (
    <CredentialsWrapper>
      <SpaceBetween spacing={spacing.size32} direction="y" alignItems="center">
        <SpaceBetween spacing={spacing.size16} direction="y" alignItems="center">
          <Headline size="large">{t('credentialsStep.title')}</Headline>
          <BodyText>
            {t('credentialsStep.secondaryTitle')}&nbsp;
            <a href={redirectToStudent}>{t('credentialsStep.secondaryTitleLink')}</a>
          </BodyText>
        </SpaceBetween>

        <StyledSpaceBetween spacing={spacing.size16} direction="y">
          <SSOSignup t={t} handleRegisterSSO={handleRegisterSSO} />
          <div>
            <Divider orientation="horizontal" text={t('credentialsStep.divider')} />
          </div>
          <EmailForm
            t={t}
            onSubmit={(data) => submitRegistration(data)}
            redirectToStudent={redirectToStudent}
            {...emailFormProps}
          />
          <LoginMessage>
            {t('credentialsStep.logInLabel')}&nbsp;
            <StyledLoggedIn href={redirectToLogin}>{t('credentialsStep.logInAction')}</StyledLoggedIn>
          </LoginMessage>
        </StyledSpaceBetween>
      </SpaceBetween>
    </CredentialsWrapper>
  );
};

const StyledLoggedIn = styled.a`
  font-weight: 700;
  text-decoration: none;
`;

const CredentialsWrapper = styled.div`
  width: ${rem('340px')};
`;

const LoginMessage = styled(BodyText)`
  display: flex;
  justify-content: center;
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  width: 100%;
`;
