import { color, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { useEffect, useRef } from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import { useFeatureFlags } from '../../utils/featureFlags/useFeatureFlags';
import { EditorContextSidebar } from './components/EditorContextSidebar/EditorContextSidebar';
import { EditorTopBar } from './components/EditorTopBar/EditorTopBar';
import { PinEditor } from './components/PinEditor/PinEditor';
import { PinRail } from './components/PinRail/PinRail';
import { SlideCanvasEmptyState } from './components/SlideCanvasEmptyState/SlideCanvasEmptyState';
import { WarningBanner } from './components/WarningBanner/WarningBanner';
import { useActivePin, usePins } from './context/EditorContext/hooks/useEditorState';
import { EditorLessonTopBar, LessonBarApi } from './dataComponents/EditorLessonTopBar/EditorLessonTopBar';
import { usePinPaste } from './hooks/usePinPaste';
import { useResetSelectionEffect } from './hooks/useResetSelectionEffect';
import { useSetActivePinCallbackEffect } from './hooks/useSetActivePinCallbackEffect';
import { useSetLocationEffect } from './hooks/useSetLocationEffect';
import { HocusPocusApi } from './utils/yjs/yjs.types';

export interface EditorProps {
  onContentChanged?: () => void;
  lessonBarApi: LessonBarApi;
  hocusPocusApi: HocusPocusApi;
}

export const Editor: React.FC<EditorProps> = ({ onContentChanged, lessonBarApi, hocusPocusApi }) => {
  useDocumentTitle(`LessonUp - ${lessonBarApi.lessonName}`);
  const pin = useActivePin();
  const pins = usePins();
  useSetActivePinCallbackEffect();
  useSetLocationEffect();
  useResetSelectionEffect();
  usePinPaste();
  const isFirstRender = useRef(true);
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    return onContentChanged?.();
  }, [pins, onContentChanged]);

  return (
    <StyledEditor>
      {isFeatureEnabled('editorWarningBanner') && <WarningBanner />}
      <EditorLessonTopBar lessonBarApi={lessonBarApi} hocusPocusApi={hocusPocusApi} />
      <EditorTopBar />
      <StyledMainSection spacing={spacing.size8} direction="x" padding={spacing.size8}>
        <PinRail />
        <StyledPinEditorCanvas>
          <StyledPinEditorCanvasInner>
            {pin && <PinEditor />}
            {!pin && <SlideCanvasEmptyState />}
          </StyledPinEditorCanvasInner>
        </StyledPinEditorCanvas>
        {pin && <EditorContextSidebar />}
      </StyledMainSection>
    </StyledEditor>
  );
};

const StyledEditor = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const StyledMainSection = styled(SpaceBetween)`
  flex: 1;
  overflow: hidden;
`;

const StyledPinEditorCanvas = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const StyledPinEditorCanvasInner = styled.div`
  background: ${color.additional.disabled.background};
  border-radius: ${spacing.size16};
  height: 100%;
  position: relative;
`;
