import { TableRowInteractivity, TableRowInteractivityObject } from './types';

export const computeInteractivity = (
  interactivity: TableRowInteractivity | boolean | undefined
): Required<TableRowInteractivityObject> => {
  if (typeof interactivity === 'boolean') {
    return {
      isDraggable: interactivity,
      isSelectable: interactivity,
      isSelectableIfDisabled: !interactivity,
    };
  }

  return {
    isDraggable: interactivity?.isDraggable ?? true,
    isSelectable: interactivity?.isSelectable ?? true,
    isSelectableIfDisabled: interactivity?.isSelectableIfDisabled ?? false,
  };
};
