import { cx } from '@emotion/css';
import React, { PropsWithChildren } from 'react';

type Visibility = 'always-topbar' | 'desktop-only' | 'default' | 'mobile-only';

export interface Props {
  /** indicates how an item will be visible in the topbar or full navigation, defaults to `always` */
  visibility?: Visibility;
  className?: string;
}

export const topBarVisibilityClassNames = {
  default: 'tbv_default',
  alwaysTopbar: 'tbv_always-topbar',
  onlyDesktop: 'tbv_desktop-only',
  onlyMobile: 'tbv_mobile-only',
};

export function TopBarVisibility(props: PropsWithChildren<Props>) {
  return (
    <div
      className={cx(
        props.className,
        props.visibility === 'always-topbar'
          ? topBarVisibilityClassNames.alwaysTopbar
          : props.visibility === 'desktop-only'
          ? topBarVisibilityClassNames.onlyDesktop
          : props.visibility === 'mobile-only'
          ? topBarVisibilityClassNames.onlyMobile
          : topBarVisibilityClassNames.default
      )}
    >
      {props.children}
    </div>
  );
}
