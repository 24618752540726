import { InputFormField, InputFormFieldProps } from '@lessonup/ui-components';
import React, { forwardRef } from 'react';
import { useEditorInputKeybindController } from './hooks/useEditorInputKeybindController';

export const EditorInputFormField = forwardRef<HTMLInputElement, InputFormFieldProps>(function EditorInputFormField(
  props,
  forwardedRef
) {
  const { onBlur, onFocus } = useEditorInputKeybindController();

  return <InputFormField onBlur={onBlur} onFocus={onFocus} formFieldSize="small" {...props} ref={forwardedRef} />;
});
