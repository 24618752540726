import { HocuspocusProvider, WebSocketStatus } from '@hocuspocus/provider';
import { ConnectionLibStatus } from '@lessonup/client-integration';
import { decodeFromYjs, hashPins, PinHashes } from '@lessonup/editor-shared';
import _ from 'lodash';
import { HocusPocusApi, HocusPocusConnectionStatus } from './yjs.types';

export const getClientYDocOrigin = (clientId: number | undefined) => `client-${clientId}`;

export const dummyHocusPocusApi: HocusPocusApi = {
  connectionState: WebSocketStatus.Connected,
  saveState: 'saved',
};

export const mapHocusPocusConnectionStatusToConnectionLibStatus = (
  status: HocusPocusConnectionStatus
): ConnectionLibStatus => {
  switch (status) {
    case 'connected':
      return 'online';
    case 'disconnected':
      return 'offline';
    case 'connecting':
      return 'syncing';
    default:
      return 'limbo';
  }
};

export function checkHashes(serverPinHashes: PinHashes, provider: HocuspocusProvider) {
  const lesson = decodeFromYjs(provider.document);
  // The server hash data from the graphql query has a __typename field that we need to remove
  const formattedServerPinHashes = serverPinHashes.map((pinHash) => ({
    id: pinHash.id,
    hash: pinHash.hash,
  }));

  function idComparator(a: { id: string }, b: { id: string }) {
    return a.id.localeCompare(b.id);
  }

  return _.isEqual(hashPins(lesson.pins).sort(idComparator), formattedServerPinHashes.sort(idComparator));
}
