import { tipTapContentToText } from '@lessonup/editor-shared';
import {
  isInteractiveQuestionPinTextPinComponent,
  Pin,
  PinComponent,
  QuizTeacherPin,
  QuizTeacherPinSettings,
  TextPinComponent,
  toJSONContent,
} from '@lessonup/pins-shared';
import { isEqual } from '@lessonup/utils';
import { UpdatePinAction } from '../updatePinContext/updatePinContext';

export const ANSWER_MAX_HEIGHT = '7.5rem'; // max-height is calculated based on that the line-height is 1.5rem and that the answer should be max 5 lines high

export const toggleCorrectAnswer = (
  pin: QuizTeacherPin,
  id: string,
  dispatch: React.Dispatch<UpdatePinAction<'MULTIPLE_CHOICE'>>
) => {
  const correctAnswers = [...pin.teacherSettings.correctAnswers];
  if (correctAnswers.includes(id)) {
    correctAnswers.splice(correctAnswers.indexOf(id), 1);
  } else {
    correctAnswers.push(id);
  }
  dispatch({ type: 'updatePinTeacherSettings', settings: { correctAnswers: correctAnswers } });
};

export const isCorrectQuizAnswer = (teacherSettings: QuizTeacherPinSettings, id: string) => {
  return teacherSettings.correctAnswers.includes(id);
};

export const deleteQuizAnswer = (
  pin: QuizTeacherPin,
  id: string,
  dispatch: React.Dispatch<UpdatePinAction<'MULTIPLE_CHOICE'>>
) => {
  const updatedAnswers = pin.settings.answers.filter((answer) => answer.id !== id);

  const updatedCorrectAnswers = pin.teacherSettings.correctAnswers.filter((answerId) => answerId !== id);

  dispatch({ type: 'updatePinSettings', settings: { answers: updatedAnswers } });
  if (!isEqual(pin.teacherSettings.correctAnswers, updatedCorrectAnswers))
    dispatch({ type: 'updatePinTeacherSettings', settings: { correctAnswers: updatedCorrectAnswers } });
};

export const quizAnswerIndex = (pin: QuizTeacherPin, id: string) => {
  return pin.settings.answers.findIndex((answer) => answer.id === id);
};

const charCodeIndexBuffer = 65;
/**
 * Returns the letter [ABCD] for the given index.
 */
export function quizAnswerLetterForIndex(index: number) {
  return String.fromCharCode(charCodeIndexBuffer + index);
}

export const findQuestionComponent = (pin: Pin, pinComponents: PinComponent[]) => {
  return pinComponents.find((pinComponent): pinComponent is TextPinComponent =>
    isInteractiveQuestionPinTextPinComponent(pin, pinComponent)
  );
};

export const convertQuestionToString = (tipTapContent: object) => {
  // TODO ED-624: This is a workaround as we needed to store the content as string to stay within the maximum depth limit in Firestore
  // Remove once we retrieve the JSON from GraphQL
  const content = typeof tipTapContent === 'string' ? toJSONContent(tipTapContent) : tipTapContent;
  if (!content) return '';
  return tipTapContentToText(content);
};
