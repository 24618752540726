import { getVimeoThumbnail, getYoutubeThumbnail } from '@lessonup/editor-shared';
import { VideoSettings } from '@lessonup/pins-shared';

export async function getThumbnailUrl({
  source,
  sourceId,
}: Pick<VideoSettings, 'source' | 'sourceId'>): Promise<string | undefined> {
  if (!source || !sourceId) return undefined;

  switch (source) {
    case 'YOUTUBE':
      return getYoutubeThumbnail(sourceId);
    case 'VIMEO':
      return await getVimeoThumbnail(sourceId);
    default:
      return undefined;
  }
}
