import { Deg, newVector, Vector } from '@lessonup/pins-shared';
import { degreesToRadians, radiansToDegrees } from './math';

export function addVectors(v1: Vector, v2: Vector): Vector {
  return newVector(v1.x + v2.x, v1.y + v2.y);
}

export function subtractVectors(v1: Vector, v2: Vector): Vector {
  return newVector(v1.x - v2.x, v1.y - v2.y);
}

export function scaleVector(v: Vector, scalar: number): Vector {
  return newVector(v.x * scalar, v.y * scalar);
}

/** Divides each component of a vector by a scalar value */
export function divideVector(v: Vector, scalar: number): Vector {
  return newVector(v.x / scalar, v.y / scalar);
}

export function rotateVector(v: Vector, theta: Deg): Vector {
  const radians = degreesToRadians(theta);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  return newVector(v.x * cos - v.y * sin, v.x * sin + v.y * cos);
}

export function vectorMagnitude(v: Vector): number {
  return Math.sqrt(v.x * v.x + v.y * v.y);
}

export function vectorDirection(v: Vector): Deg {
  return radiansToDegrees(Math.atan2(v.y, v.x));
}

export function vectorNormalize(v: Vector): Vector {
  return divideVector(v, vectorMagnitude(v));
}
