import { Divider, LoaderSkeleton, rem, spacing } from '@lessonup/ui-components';
import React from 'react';
import { PlayerBarBottomSection, PlayerBarBottomWrapper } from './components/PlayerBarBottomModules';

export interface PlayerBarBottomProps {
  minimized: boolean;
  mirrored: boolean;
}

const ButtonPlaceholder = () => <LoaderSkeleton height={rem('30px')} width={rem('40px')} />;

export const PlayerBarBottomPlaceholder: React.FC<Pick<PlayerBarBottomProps, 'minimized' | 'mirrored'>> = ({
  minimized,
  mirrored,
}) => (
  <PlayerBarBottomWrapper minimized={minimized} mirrored={!!mirrored}>
    <PlayerBarBottomSection mirrored={false}>
      <ButtonPlaceholder />
      <Divider orientation="vertical" spacing={spacing.size16} />
      <ButtonPlaceholder />
    </PlayerBarBottomSection>
    <PlayerBarBottomSection mirrored={false}>
      <ButtonPlaceholder />
      <ButtonPlaceholder />
      <ButtonPlaceholder />
    </PlayerBarBottomSection>
    <PlayerBarBottomSection mirrored={false}>
      <ButtonPlaceholder />
    </PlayerBarBottomSection>
    <PlayerBarBottomSection gapped mirrored={mirrored}>
      <ButtonPlaceholder />
    </PlayerBarBottomSection>
  </PlayerBarBottomWrapper>
);

export const PlayerBarBottom: React.FC<React.PropsWithChildren<PlayerBarBottomProps>> = ({
  minimized,
  mirrored,
  children,
}) => {
  return (
    <PlayerBarBottomWrapper minimized={minimized} mirrored={mirrored}>
      {children}
    </PlayerBarBottomWrapper>
  );
};
