import { TeacherPin } from '@lessonup/pin-renderer';
import { first } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useStep } from 'usehooks-ts';
import { findPreviewTimelineEntryIndexByPinID, mapPinsToPreviewTimeline } from '../PreviewTimeline';

export function useTimeline(pins: TeacherPin[], activePinId?: string) {
  const previewTimeline = useMemo(() => {
    return mapPinsToPreviewTimeline(pins);
  }, [pins]);

  const [currentTimelineStep, { goToNextStep, goToPrevStep, setStep }] = useStep(previewTimeline.length);
  const currentTimelineIndex = currentTimelineStep - 1;

  const toPin = useCallback(
    (pinId?: string) => {
      setStep(findPreviewTimelineEntryIndexByPinID(previewTimeline, pinId) + 1);
    },
    [previewTimeline, setStep]
  );

  useEffect(() => {
    toPin(activePinId || first(pins)?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentPin: previewTimeline[currentTimelineIndex].pin,
    currentPinStep: previewTimeline[currentTimelineIndex].step,
    currentIndex: currentTimelineIndex,
    total: previewTimeline.length,
    toNext: goToNextStep,
    toPrev: goToPrevStep,
    toPin,
  };
}
