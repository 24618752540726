import styled from '@emotion/styled';
import React, { AudioHTMLAttributes, ImgHTMLAttributes, SourceHTMLAttributes, VideoHTMLAttributes } from 'react';
import { color } from '../../foundations/colors/colors';

export type FileType = 'audio' | 'image' | 'document' | 'video' | 'slideDeck';

export interface AudioFilePreviewProps extends AudioHTMLAttributes<HTMLAudioElement> {
  type: 'audio';
  sources: SourceHTMLAttributes<HTMLSourceElement>[];
}

export interface ImageFilePreviewProps extends ImgHTMLAttributes<HTMLImageElement> {
  type: 'image';
  alt: string;
}

export interface DocumentFilePreviewProps extends Omit<ImageFilePreviewProps, 'type'> {
  type: 'document';
}

export interface VideoFilePreviewProps extends VideoHTMLAttributes<HTMLVideoElement> {
  type: 'video';
  sources: SourceHTMLAttributes<HTMLSourceElement>[];
}

export interface SlideDeckFilePreviewProps extends Omit<DocumentFilePreviewProps, 'type'> {
  type: 'slideDeck';
}

export type FilePreviewProps =
  | AudioFilePreviewProps
  | ImageFilePreviewProps
  | DocumentFilePreviewProps
  | VideoFilePreviewProps
  | SlideDeckFilePreviewProps;

export type SharedFilePreviewProps = {
  outlined?: boolean;
};

export function FilePreview(props: FilePreviewProps & SharedFilePreviewProps) {
  const { type, className, outlined } = props;

  return (
    <StyledFilePreview className={className} outlined={outlined}>
      {type === 'audio' && (
        <StyledAudio controls>
          {props.sources.map((sourceProps, index) => (
            <source {...sourceProps} key={`audio-source-${index}`} />
          ))}
        </StyledAudio>
      )}
      {(type === 'image' || type === 'document' || type === 'slideDeck') && (props.src || props.srcSet) && (
        <StyledImage {...props} loading="lazy" />
      )}
      {type === 'video' && (
        <StyledVideo controls>
          {props.sources.map((sourceProps, index) => (
            <source {...sourceProps} key={`video-source-${index}`} />
          ))}
        </StyledVideo>
      )}
    </StyledFilePreview>
  );
}

const StyledFilePreview = styled.figure<{ outlined?: boolean }>`
  aspect-ratio: 16 / 9;
  background: ${color.neutral.surface1.background};
  position: relative;
  display: flex;
  align-items: center;
  border: ${(props) => (props.outlined ? `1px solid ${color.neutral.outline.background}` : '')};

  // Fallback for browsers not supporting CSS prop 'aspect-ratio', can be removed when there is enough browsersupport for it
  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%; // 9 / 16 * 100%
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;

const StyledImage = styled.img`
  object-fit: scale-down;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledAudio = styled.audio`
  width: 100%;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`;
