import { TeacherPin } from '@lessonup/pins-shared';
import { isNull } from 'lodash';
import { useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { ActivePinId } from '../EditorContext.types';
import { editorStateStore } from '../store/editorStateStore';

export function useActivePinId() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('activePinId'),
    editorStateStore.getActivePinIdSnapshot,
    editorStateStore.getActivePinIdSnapshot
  );
}

export function useActivePin() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('activePin'),
    editorStateStore.getActivePinSnapshot,
    editorStateStore.getActivePinSnapshot
  );
}

export function useNonNullActivePin() {
  const activePin = useActivePin();
  if (isNull(activePin)) throw new Error('null pin');

  return activePin;
}

export function usePins() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('pins'),
    editorStateStore.getPinsSnapshot,
    editorStateStore.getPinsSnapshot
  );
}

export function useLessonMeta() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('lesson'),
    editorStateStore.getLessonMeta,
    editorStateStore.getLessonMeta
  );
}

export function useSelectedPinIds() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('selectedPinIds'),
    editorStateStore.getSelectedPinIdsSnapshot,
    editorStateStore.getSelectedPinIdsSnapshot
  );
}

export function useEditMode() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('editMode'),
    editorStateStore.getEditModeSnapshot,
    editorStateStore.getEditModeSnapshot
  );
}

export function useSelectedPinComponentIds() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('selectedPinComponentIds'),
    editorStateStore.getSelectedPinComponentIdsSnapshot,
    editorStateStore.getSelectedPinComponentIdsSnapshot
  );
}

export function useSelectedPinComponent() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('selectedPinComponent'),
    editorStateStore.getSelectedPinComponentSnapshot,
    editorStateStore.getSelectedPinComponentSnapshot
  );
}

export function useSubSelectionValue() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('subSelectionValue'),
    editorStateStore.getSubSelectionValueSnapshot,
    editorStateStore.getSubSelectionValueSnapshot
  );
}

export function useCollaborators() {
  return useSyncExternalStore(
    editorStateStore.subscribeForType('collaborators'),
    editorStateStore.getCollaboratorsSnapshot,
    editorStateStore.getCollaboratorsSnapshot
  );
}

/**
 * @description Returns an array of pins containing either the activePin or the activePin plus the selectedPins.
 **/

export function useActivePinSelection() {
  const pins = usePins();
  const activePin = useActivePin();
  const selectedPinIds = useSelectedPinIds();

  if (isNull(activePin)) return [];

  const selectedPins = pins.filter((pin) => selectedPinIds.includes(pin.id));
  return selectedPins.includes(activePin) ? selectedPins : activePin ? [activePin] : [];
}

export function useSetInitialState(activePinId: ActivePinId, pins: TeacherPin[]) {
  // The update should be called immediately during initial render, so we use a ref instead of useEffect
  const firstRun = useRef(true);
  if (!firstRun.current) return;
  firstRun.current = false;
  editorStateStore.setInitialState(activePinId, pins);
}
