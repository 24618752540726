import { SpaceBetween, spacing, styled, Tooltip } from '@lessonup/ui-components';
import React from 'react';
import { Collaborator } from '../../../utils/yjs/yjs.types';

interface PinRailCollaboratorsProps {
  collaborators?: Collaborator[];
}

export const PinRailCollaborators: React.FC<PinRailCollaboratorsProps> = ({ collaborators }) => {
  if (!collaborators || collaborators.length === 0) return null;
  return (
    <CollaboratorIndicatorWrapper direction="x" spacing={spacing.size8}>
      {collaborators.map((collaborator) => (
        <PinRailCollaboratorIndicator collaborator={collaborator} key={collaborator.clientId} />
      ))}
    </CollaboratorIndicatorWrapper>
  );
};

interface PinRailCollaboratorIndicatorProps {
  collaborator: Collaborator;
}
// TODO styling, this is not final or made by a designer
export const PinRailCollaboratorIndicator: React.FC<PinRailCollaboratorIndicatorProps> = ({ collaborator }) => {
  return (
    <Tooltip content={collaborator.name} direction="right">
      <CollaboratorIndicator color={collaborator.color}>{collaborator.initial}</CollaboratorIndicator>
    </Tooltip>
  );
};

const CollaboratorIndicatorWrapper = styled(SpaceBetween)`
  position: absolute;
  top: -10px;
  height: 10px;
`;

const CollaboratorIndicator = styled.div<{ color: string }>`
  width: 20px;
  height: 10px;
  border-radius: 10px 10px 0 0;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  justify-content: center;
  align-items: start;
  font-size: 8px;
`;
