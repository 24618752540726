/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-namespace */
import React, { createContext, ReactNode, useContext } from 'react';

// we have the complexity that we want to use different kind of links throughout the applications (next/link vs custom one for meteor routing)
// but we still want to be able to wrap components with links as well in this library
// besides that, each application should be able to decide on it's own LinkProps

type CustomLink = (props: UIComponents.LinkProps) => React.JSX.Element;

const LinkContext = createContext<CustomLink>(({ children }) => <>{children}</>);

export const LinkProvider = LinkContext.Provider;

/**
 * Link which should used throughout the application and ui components
 * This will load a link which can be set via the `LinkProvider`
 */
export const Link = ({ children, ...args }: UIComponents.LinkProps) => {
  const CustomLink = useContext(LinkContext);
  return <CustomLink {...args}>{children}</CustomLink>;
};

// we use the namespace decoration so each package using this library is allowed to extend the props in
// `LinkProps` by overriden `CustomLinkProps`
// this is inspired by https://www.graphql-modules.com/docs/essentials/type-safety
declare global {
  namespace UIComponents {
    // this should only contain fields which are required for UI components to work
    // the rest should be added by the applications via the `CustomLinkProps` override
    export type LinkProps = {
      href: string;
      onClick?: (e: React.MouseEvent) => void;
      children: ReactNode;
      passHref?: boolean;
      external?: boolean; // React Router dom Search implementation otherwise routes internally
    } & CustomLinkProps;

    export interface CustomLinkProps {}
  }
}
