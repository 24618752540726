import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPageAccount = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21.375a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75m0 0c2.08 0 4.001-.677 5.556-1.823a5.749 5.749 0 0 0-11.113 0A9.33 9.33 0 0 0 12 21.375m0-8.505a3.28 3.28 0 1 0 0-6.56 3.28 3.28 0 0 0 0 6.56"
    />
  </svg>
);
export default SvgIconPageAccount;
