import {
  createLessonUpTemplate,
  FontFamily,
  FontSize,
  ShadowSettingKey,
  shadowSizes,
  TextPinComponent,
  TypographyType,
  useTextEditorState,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import {
  BodyText,
  IconAlignHorizontalCenter,
  IconAlignHorizontalLeft,
  IconAlignHorizontalRight,
  IconAlignVerticalBottom,
  IconAlignVerticalCenter,
  IconAlignVerticalTop,
  IconFormatListBullet,
  IconFormatListNumbered,
  IconFormatStrikethrough,
  IconFormatSubscript,
  IconFormatSuperscript,
  IconFormatUnderlined,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { capitalizeFirstCharacter } from '@lessonup/utils';
import { isUndefined } from 'lodash';
import React from 'react';
import {
  ContextSidebarCollapsibleGroup,
  ContextSidebarGroup,
} from '../../../../../../../../components/ContextSidebar/ContextSidebarGroup';
import { useFeatureFlags } from '../../../../../../../../utils/featureFlags/useFeatureFlags';
import { useEditMode } from '../../../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../../../hooks/useEditorTranslation';
import {
  createFontFamilyOptions,
  createFontShadowOptions,
  createFontWeightStyleOptions,
  createHandleSetHorizontalTextAlignChange,
  createHandleSetVerticalTextAlignChange,
  createTypographyOptions,
  FontWeightStyle,
  getFontWeightStyleValue,
  onFontWeightStyleChange,
  useFontSizePickerHelper,
} from '../../../../../../utils/pinComponentSettings/textPinComponent';
import { teacherTextEditorStore } from '../../../../../../utils/yjs/EditorYjs/teacherTextEditorStore';
import { SettingsToggle } from '../../../../../SettingsToggle/SettingsToggle';
import { NoFocusPicker, NoFocusPickerProps } from '../../../CustomSelect/NoFocusPicker';
import { SteppedNumberInput, SteppedNumberInputProps } from '../../../CustomSelect/SteppedNumberInput';
import { ColorPickerSetting } from '../../../SharedSettings/ColorPickerSetting';
import { BackgroundColorSetting } from '../../sharedComponentSettings/BackgroundColorSetting';
import { BorderColorSetting } from '../../sharedComponentSettings/BorderColorSetting';
import { BorderRadiusSetting } from '../../sharedComponentSettings/BorderRadiusSetting';
import { BorderSizeSetting } from '../../sharedComponentSettings/BorderSizeSetting';
import { LayoutPositionSettingGroup } from '../../sharedComponentSettings/LayoutPositionSetting';
import { ShadowSetting } from '../../sharedComponentSettings/ShadowSetting';
import { PinComponentSettingsProps } from '../../types';
import { LineHeightSetting } from './LineHeightSetting';
import { PaddingSetting } from './PaddingSetting';
import { ParagraphSetting } from './ParagraphSetting';

export const TextPinComponentSettings: React.FC<PinComponentSettingsProps<TextPinComponent>> = ({ pinComponent }) => {
  const { t } = useEditorTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const editMode = useEditMode();
  const { textHandler: textEditorHandler } = useTextEditorState(teacherTextEditorStore);
  const dispatch = useUpdatePin();

  const isTextEditMode = editMode.type === 'textEdit';

  const shouldUpdateInline = () => textEditorHandler.updateShouldTargetChild(isTextEditMode);

  const fontFamCursorValue = textEditorHandler.fontFamily.get('');
  const fontFamValue = isTextEditMode
    ? fontFamCursorValue
      ? (fontFamCursorValue as FontFamily)
      : (pinComponent.settings.fontFamily as FontFamily)
    : (pinComponent.settings.fontFamily as FontFamily);

  const fontFamilyPicker: NoFocusPickerProps<FontFamily> = {
    value: fontFamValue,
    options: createFontFamilyOptions(),
    styleOption: (option) => ({ fontFamily: `"${option?.value}"` }),
    onChange: (value) => {
      if (shouldUpdateInline()) {
        textEditorHandler.fontFamily.set(value);
      } else {
        dispatch({
          type: 'updatePinComponentSettings',
          pinComponentId: pinComponent.id,
          settings: {
            fontFamily: value,
          },
        });
        textEditorHandler.fontFamily.unsetAll();
      }
    },
  };

  const fontShadowValue: ShadowSettingKey = pinComponent.settings.fontShadow ?? 'NONE';
  const fontShadowPicker: NoFocusPickerProps<ShadowSettingKey> = {
    value: fontShadowValue,
    valueLabel: t(`contextSidebar.settings.shadows.${fontShadowValue.toLowerCase()}`),
    options: createFontShadowOptions(t),
    styleOption: (option) => ({ textShadow: option?.value && shadowSizes[option.value] }),
    onChange: (value) => {
      dispatch({
        type: 'updatePinComponentSettings',
        pinComponentId: pinComponent.id,
        settings: {
          fontShadow: value,
        },
      });
    },
  };

  const weightValue = getFontWeightStyleValue(textEditorHandler);
  const fontWeightStylePicker: NoFocusPickerProps<FontWeightStyle> = {
    value: weightValue,
    valueLabel: t(`contextSidebar.text.fontWeightStyle.${weightValue}`),
    options: createFontWeightStyleOptions(t),
    styleOption: (option) => ({
      fontFamily: fontFamValue,
      fontWeight: option?.value === 'bold' || option?.value === 'bold-italic' ? 'bold' : 'normal',
      fontStyle: option?.value === 'regular-italic' || option?.value === 'bold-italic' ? 'italic' : 'normal',
    }),
    onChange: (value) => {
      onFontWeightStyleChange(textEditorHandler, value);
    },
  };

  const typographyType = pinComponent.settings.typographyType;
  const typographyTypePicker: NoFocusPickerProps<TypographyType> = {
    value: typographyType as TypographyType,
    valueLabel: typographyType ? capitalizeFirstCharacter(typographyType) : undefined,
    options: createTypographyOptions(),
    styleOption: (option) => styleForTypographyValue(option?.value),
    onChange: (value) => {
      const styleSet = createLessonUpTemplate().styleSets.find((style) => style.id === value);
      if (!styleSet) {
        console.warn('Could not find style set with id', value);
        return;
      }
      const fontFamily = styleSet.styles[0].fontFamily as FontFamily;
      const fontSize = styleSet.styles[0].fontSize as FontSize;
      dispatch({
        type: 'updatePinComponentSettings',
        pinComponentId: pinComponent.id,
        settings: {
          fontFamily,
          fontSize,
          typographyType: value,
        },
      });
      textEditorHandler.fontFamily.unsetAll();
      textEditorHandler.fontSize.unsetAll();
    },
  };

  const fontSizePicker: SteppedNumberInputProps = useFontSizePickerHelper(
    textEditorHandler,
    pinComponent,
    dispatch,
    isTextEditMode
  );

  const fontColorCursorValue = textEditorHandler.color.get('');
  const fontColorValue =
    (isTextEditMode
      ? fontColorCursorValue
        ? fontColorCursorValue
        : pinComponent.settings.fontColor
      : pinComponent.settings.fontColor) ?? '#001343';

  return (
    <>
      <ContextSidebarGroup title={t('contextSidebar.text.font')}>
        <SpaceBetween direction="y" spacing={spacing.size12}>
          {isFeatureEnabled('editorShowTypographyTypePicker') && <NoFocusPicker {...typographyTypePicker} />}
          <NoFocusPicker {...fontFamilyPicker} />
          <SpaceBetween direction="x" spacing={spacing.size12}>
            <NoFocusPicker {...fontWeightStylePicker} />
            <SteppedNumberInput {...fontSizePicker} />
          </SpaceBetween>
          <ColorPickerSetting
            selectedColor={fontColorValue}
            text={t('contextSidebar.settings.textColor')}
            ariaLabel={t('contextSidebar.settings.textColor')}
            hideTransparentOption
            onChange={(color) => {
              if (shouldUpdateInline()) {
                textEditorHandler.color.set(color);
              } else {
                dispatch({
                  type: 'updatePinComponentSettings',
                  pinComponentId: pinComponent.id,
                  settings: {
                    fontColor: color,
                  },
                  debounce: 1000,
                });
                textEditorHandler.color.unsetAll();
              }
            }}
          />
        </SpaceBetween>
      </ContextSidebarGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textExtraOptionsSettings"
        title={t('contextSidebar.text.styling')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <SpaceBetween direction="x" spacing={spacing.size12} justifyContent="space-between" alignItems="center">
          <BodyText>{t('contextSidebar.text.decoration')}</BodyText>
          <StyledSettingWrapper>
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.underline', {
                enabled: textEditorHandler.underline.get(),
              })}
              icon={<IconFormatUnderlined />}
              checked={textEditorHandler.underline.get()}
              onChange={textEditorHandler.underline.toggle}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.strikethrough', {
                enabled: textEditorHandler.strikethrough.get(),
              })}
              icon={<IconFormatStrikethrough />}
              checked={textEditorHandler.strikethrough.get()}
              onChange={textEditorHandler.strikethrough.toggle}
            />
          </StyledSettingWrapper>
        </SpaceBetween>
        <SpaceBetween direction="x" spacing={spacing.size12} justifyContent="space-between" alignItems="center">
          <BodyText>{t('contextSidebar.text.listStyle')}</BodyText>
          <StyledSettingWrapper>
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.unorderedList', {
                enabled: textEditorHandler.bulletList.get(),
              })}
              icon={<IconFormatListBullet />}
              checked={textEditorHandler.bulletList.get()}
              onChange={textEditorHandler.bulletList.toggle}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.orderedList', {
                enabled: textEditorHandler.orderedList.get(),
              })}
              icon={<IconFormatListNumbered />}
              checked={textEditorHandler.orderedList.get()}
              onChange={textEditorHandler.orderedList.toggle}
            />
          </StyledSettingWrapper>
        </SpaceBetween>
        <SpaceBetween direction="x" spacing={spacing.size12} justifyContent="space-between" alignItems="center">
          <BodyText>{t('contextSidebar.text.other')}</BodyText>
          <StyledSettingWrapper>
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.superscript', {
                enabled: textEditorHandler.superscript.get(),
              })}
              icon={<IconFormatSuperscript />}
              checked={textEditorHandler.superscript.get()}
              onChange={textEditorHandler.superscript.toggle}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.subscript', {
                enabled: textEditorHandler.subscript.get(),
              })}
              icon={<IconFormatSubscript />}
              checked={textEditorHandler.subscript.get()}
              onChange={textEditorHandler.subscript.toggle}
            />
          </StyledSettingWrapper>
        </SpaceBetween>
        <BackgroundColorSetting pinComponent={pinComponent} text={t('contextSidebar.settings.backgroundColor')} />
      </ContextSidebarCollapsibleGroup>
      <LayoutPositionSettingGroup />
      <ContextSidebarCollapsibleGroup
        persistId="textAlignmentSettings"
        title={t('contextSidebar.text.textAlignment.title')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <SpaceBetween direction="x" spacing={spacing.size12} justifyContent="space-between" alignItems="center">
          <StyledSettingWrapper>
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.left', {
                enabled: pinComponent.settings.horizontalAlignment === 'left',
              })}
              icon={<IconAlignHorizontalLeft />}
              checked={
                pinComponent.settings.horizontalAlignment === 'left' ||
                isUndefined(pinComponent.settings.horizontalAlignment)
              }
              onChange={createHandleSetHorizontalTextAlignChange('left', pinComponent.id, dispatch)}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.center', {
                enabled: pinComponent.settings.horizontalAlignment === 'center',
              })}
              icon={<IconAlignHorizontalCenter />}
              checked={pinComponent.settings.horizontalAlignment === 'center'}
              onChange={createHandleSetHorizontalTextAlignChange('center', pinComponent.id, dispatch)}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.right', {
                enabled: pinComponent.settings.horizontalAlignment === 'right',
              })}
              icon={<IconAlignHorizontalRight />}
              checked={pinComponent.settings.horizontalAlignment === 'right'}
              onChange={createHandleSetHorizontalTextAlignChange('right', pinComponent.id, dispatch)}
            />
          </StyledSettingWrapper>
          <StyledSettingWrapper>
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.baseline', {
                enabled: pinComponent.settings.verticalAlignment === 'baseline',
              })}
              icon={<IconAlignVerticalTop />}
              checked={
                pinComponent.settings.verticalAlignment === 'baseline' ||
                isUndefined(pinComponent.settings.verticalAlignment)
              }
              onChange={createHandleSetVerticalTextAlignChange('baseline', pinComponent.id, dispatch)}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.verticalCenter', {
                enabled: pinComponent.settings.verticalAlignment === 'center',
              })}
              icon={<IconAlignVerticalCenter />}
              checked={pinComponent.settings.verticalAlignment === 'center'}
              onChange={createHandleSetVerticalTextAlignChange('center', pinComponent.id, dispatch)}
            />
            <SettingsToggle
              type="checkbox"
              toggleVariant="icon-only"
              ariaLabel={t('contextSidebar.text.textAlignment.bottom', {
                enabled: pinComponent.settings.verticalAlignment === 'end',
              })}
              icon={<IconAlignVerticalBottom />}
              checked={pinComponent.settings.verticalAlignment === 'end'}
              onChange={createHandleSetVerticalTextAlignChange('end', pinComponent.id, dispatch)}
            />
          </StyledSettingWrapper>
        </SpaceBetween>
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textSpacingSettings"
        title={t('contextSidebar.settings.spacing.title')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <LineHeightSetting pinComponent={pinComponent} key={`${pinComponent.id}-text-line-height-setting`} />
        <ParagraphSetting pinComponent={pinComponent} key={`${pinComponent.id}-text-paragraph-setting`} />
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textTextFittingSettings"
        title={t('contextSidebar.settings.padding.title')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <PaddingSetting pinComponent={pinComponent} key={`${pinComponent.id}-text-fitting-setting`} />
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textBorderSettings"
        title={t('contextSidebar.settings.border')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <BorderSizeSetting pinComponent={pinComponent} key={`${pinComponent.id}-border-size-setting`} />
        <BorderColorSetting
          pinComponent={pinComponent}
          key={`${pinComponent.id}-border-color-setting`}
          text={t('contextSidebar.settings.borderColor')}
        />
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textCornerRadiusSettings"
        title={t('contextSidebar.settings.cornerRadius')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <BorderRadiusSetting pinComponent={pinComponent} key={`${pinComponent.id}-border-radius-setting`} />
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="textEffectsSettings"
        title={t('contextSidebar.settings.effects')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <SpaceBetween direction="y" spacing={spacing.size8}>
          {isFeatureEnabled('editorTextShadow') && <NoFocusPicker {...fontShadowPicker} />}
          <ShadowSetting pinComponent={pinComponent} key={`${pinComponent.id}-image-shadow-setting`} />
        </SpaceBetween>
      </ContextSidebarCollapsibleGroup>
    </>
  );
};

const StyledSettingWrapper = styled.div`
  display: inline-flex;
`;

export function styleForTypographyValue(value: TypographyType | undefined): React.CSSProperties | undefined {
  if (!value) {
    return;
  }
  const styleSet = createLessonUpTemplate().styleSets.find((style) => style.id === value);
  if (!styleSet) {
    console.warn('Could not find style set with id', value);
    return;
  }
  const fontFamily = styleSet.styles[0].fontFamily;

  return {
    fontFamily,
  };
}
