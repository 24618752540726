import { Pin } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { pinSize } from '../../foundations/layout/pinDefault';
import { useSortPinComponents } from '../../hooks/useSortPinComponents';
import { PinComponentSwitch } from '../PinComponentSwitch/PinComponentSwitch';
import { PinSwitchStatic } from './components/PinSwitchStatic';

export interface PinViewerStaticProps {
  pin: Pin;
  isThumb?: boolean;
  width?: number;
  pinComponentsAreStatic?: boolean;
}

export const PinViewerStatic: React.FC<PinViewerStaticProps> = ({
  pin,
  isThumb,
  width = 200,
  pinComponentsAreStatic = true,
}) => {
  const pinComponents = useSortPinComponents(pin.pinComponents);
  const scale = width / pinSize.width;

  return (
    <StyledPinWrapper style={{ width: `${width}px`, height: `${width * (pinSize.height / pinSize.width)}px` }}>
      <StyledPin style={{ transform: `scale(${scale})` }}>
        <PinSwitchStatic pin={pin} isThumb={isThumb}>
          {pinComponents.map((pinComponent) => {
            return (
              <PinComponentSwitch
                key={pinComponent.id}
                pinComponent={pinComponent}
                layoutKey={pin.settings.layout}
                editable={false}
                pinId={pin.id}
                pinType={pin.type}
                active={false}
                isThumb={isThumb}
                isStatic={pinComponentsAreStatic}
                scale={scale}
              />
            );
          })}
        </PinSwitchStatic>
      </StyledPin>
    </StyledPinWrapper>
  );
};

const StyledPin = styled.div`
  width: ${pinSize.width}px;
  height: ${pinSize.height}px;
  transform-origin: left top;
  position: relative;
  overflow: hidden;
`;

const StyledPinWrapper = styled.div`
  pointer-events: none;
  position: relative;
`;
