import {
  Box,
  isBoxLayout,
  isPinComponentLocked,
  layoutHasLockedAspectRatio,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import {
  HotspotPinComponent,
  isHotspotPinComponent,
  isLinePinComponent,
  LinePinComponent,
  PinComponent,
} from '@lessonup/pins-shared';
import { BodyText, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useFeatureFlags } from '../../../../../../../utils/featureFlags/useFeatureFlags';
import { useEditorTranslation } from '../../../../../hooks/useEditorTranslation';
import {
  createHandleUpdateKeepRatio,
  createHandleUpdateSize,
} from '../../../../../utils/pinComponentSettings/sharedPinComponentSettings';
import { EditorInputFormField } from '../../EditorInputFields/EditorInputFormField';
import { ListItemSwitchSetting } from '../../ListItemSwitchSetting/ListItemSwitchSetting';

const SIZE_INCREMENT_STEP = 5;

export interface LayoutSizeSettingProps {
  pinComponent: PinComponent;
}

export const LayoutSizeSetting: React.FC<LayoutSizeSettingProps> = ({ pinComponent }) => {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  if (!isFeatureEnabled('editorSidebarLayoutSize')) return null;
  if (!isBoxLayout(pinComponent.layout) || isLinePinComponent(pinComponent)) return null;
  const handleUpdateComponentKeepRatio = createHandleUpdateKeepRatio(pinComponent, dispatch);

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      {isHotspotPinComponent(pinComponent) ? (
        <PinComponentSizeSingleInput pinComponent={pinComponent} layout={pinComponent.layout} />
      ) : (
        <PinComponentSizeDoubleInputs pinComponent={pinComponent} layout={pinComponent.layout} />
      )}
      {!isHotspotPinComponent(pinComponent) && (
        <ListItemSwitchSetting
          key={`${pinComponent.id}-keep-ratio-setting`}
          id={`${pinComponent.id}-keep-ratio-setting`}
          ariaLabel={t('contextSidebar.settings.layout.keepRatio.description')}
          text={t('contextSidebar.settings.layout.keepRatio.title')}
          secondLine={t('contextSidebar.settings.layout.keepRatio.description')}
          defaultChecked={layoutHasLockedAspectRatio(pinComponent.layout)}
          onChange={handleUpdateComponentKeepRatio}
        />
      )}
    </SpaceBetween>
  );
};

interface PinComponentSizeDoubleInputsProps {
  pinComponent: Exclude<PinComponent, LinePinComponent | HotspotPinComponent>;
  layout: Box;
}

const PinComponentSizeDoubleInputs: React.FC<PinComponentSizeDoubleInputsProps> = ({ pinComponent, layout }) => {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const handleUpdateComponentWidth = createHandleUpdateSize(pinComponent, dispatch, 'width');
  const handleUpdateComponentHeight = createHandleUpdateSize(pinComponent, dispatch, 'height');
  const isLocked = isPinComponentLocked(pinComponent);

  return (
    <SpaceBetween direction="x" spacing={spacing.size4}>
      <StyledBodyText>{t('contextSidebar.settings.layout.width')}</StyledBodyText>
      <StyledInputFormField
        id="w"
        type="number"
        label=""
        hideLabel={true}
        value={layout.size.width}
        min={0}
        max={1920}
        step={SIZE_INCREMENT_STEP}
        onChange={handleUpdateComponentWidth}
        formFieldSize="small"
        disabled={isLocked}
      />
      <StyledBodyText>{t('contextSidebar.settings.layout.height')}</StyledBodyText>
      <StyledInputFormField
        id="h"
        type="number"
        label=""
        hideLabel={true}
        value={layout.size.height}
        min={0}
        max={1920}
        step={SIZE_INCREMENT_STEP}
        onChange={handleUpdateComponentHeight}
        formFieldSize="small"
        disabled={isLocked}
      />
    </SpaceBetween>
  );
};

interface PinComponentSizeSingleInputProps {
  pinComponent: HotspotPinComponent;
  layout: Box;
}

const PinComponentSizeSingleInput: React.FC<PinComponentSizeSingleInputProps> = ({ pinComponent, layout }) => {
  const dispatch = useUpdatePin();
  const handleUpdateComponentSize = createHandleUpdateSize(pinComponent, dispatch, 'both');
  const isLocked = isPinComponentLocked(pinComponent);

  return (
    <SpaceBetween direction="x" spacing={spacing.size4}>
      <StyledInputFormField
        id="w"
        type="number"
        label=""
        hideLabel={true}
        value={layout.size.width}
        min={0}
        max={1920}
        step={SIZE_INCREMENT_STEP}
        onChange={handleUpdateComponentSize}
        formFieldSize="small"
        disabled={isLocked}
      />
    </SpaceBetween>
  );
};

const StyledBodyText = styled(BodyText)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInputFormField = styled(EditorInputFormField)`
  flex-grow: 1;
`;
