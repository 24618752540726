import {
  boxFromLayout,
  ImagePinComponent,
  ImagePinComponentRenderer,
  isPlaceholderImage,
  LU_PIN_DIMENSIONS,
  scaleSize,
} from '@lessonup/pin-renderer';
import { usePlayerDispatcher } from '@lessonup/players-modern';
import { css, styled, useDetectSimpleClick } from '@lessonup/ui-components';
import React, { useCallback } from 'react';

export interface ImagePinComponentRealtimePlayerProps {
  pinComponent: ImagePinComponent;
}

export const ImagePinComponentRealtimePlayer: React.FC<ImagePinComponentRealtimePlayerProps> = ({ pinComponent }) => {
  const box = boxFromLayout(pinComponent.layout);
  const dispatch = usePlayerDispatcher();
  const allowZoom =
    pinComponent.settings.zoomableInInteractiveViewer !== false && !isPlaceholderImage(pinComponent.settings);

  const onClick = useCallback(() => {
    const scaleFactor = Math.min(LU_PIN_DIMENSIONS.width / box.size.width, LU_PIN_DIMENSIONS.height / box.size.height);
    const size = scaleSize(box.size, scaleFactor);

    dispatch({
      type: 'setFocusMode',
      focusMode: {
        type: 'IMAGE',
        pinComponent: {
          ...pinComponent,
          layout: {
            ...box,
            size,
          },
        },
      },
    });
  }, [box, dispatch, pinComponent]);

  const { handleMouseDown, handleMouseUp } = useDetectSimpleClick(onClick);

  return (
    <StyledImagePinComponentWrapper allowZoom={allowZoom}>
      <ImagePinComponentRenderer
        settings={pinComponent.settings}
        layout={box}
        onMouseDown={allowZoom ? handleMouseDown : undefined}
        onMouseUp={allowZoom ? handleMouseUp : undefined}
      />
    </StyledImagePinComponentWrapper>
  );
};

const StyledImagePinComponentWrapper = styled.div<{ allowZoom: boolean }>`
  ${(props) =>
    props.allowZoom &&
    css`
      cursor: zoom-in;
    `}
  width: 100%;
  height: 100%;
`;
