import { Button, Introduction, rem, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '../../../components/Block/Block';

export interface ReferralRegistrationWelcomeFeatureProps {
  next: () => void;
  referrerName?: string;
  isInvalidLink?: boolean;
}

export const ReferralRegistrationWelcomeFeature = ({
  next,
  referrerName,
  isInvalidLink,
}: ReferralRegistrationWelcomeFeatureProps) => {
  const { t } = useTranslation('referralRegistrationWelcome');

  return (
    <Block>
      <Wrapper>
        {isInvalidLink ? (
          <Introduction
            headline={t('invalid.heading')}
            bodyText={<LongMessageBody>{t('invalid.body')}</LongMessageBody>}
            alignment="center"
            size="L"
            buttonPrimary={
              // Primary button of type secondary because of design
              <Button buttonType="secondary" onClick={next}>
                {t('invalid.button.primary')}
              </Button>
            }
          />
        ) : (
          <Introduction
            headline={referrerName || t('main.headingFallback')}
            bodyText={t('main.body')}
            alignment="center"
            size="L"
            buttonPrimary={
              // Primary button of type secondary because of design
              <Button buttonType="secondary" onClick={next}>
                {t('main.button.primary')}
              </Button>
            }
          />
        )}
      </Wrapper>
    </Block>
  );
};

const Wrapper = styled.div`
  padding: ${spacing.size64};
`;

const LongMessageBody = styled.div`
  max-width: ${rem('350px')};
`;
