import { isNilOrEmpty } from '@lessonup/utils';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { dismissToast, toast } from '../../components/Toast/toastHandler';
import { ErrorDisplayMessage, ErrorDisplayMode, ErrorToErrorDisplayMessage, SetErrorProps } from './errorBaseTypes';
import { ErrorContext } from './errorContext';

export interface ErrorProviderProps {
  children: React.ReactNode;
  errorParsers: ErrorToErrorDisplayMessage[];
  defaultDisplayMode?: ErrorDisplayMode;
  logger?: (error: Error | string) => void | undefined;
}

/**
 * Handles showing a ErrorDisplayMessage in a toast or a component like an ErrorBanner
 */
export const ErrorProvider: React.FC<ErrorProviderProps> = ({
  children,
  errorParsers,
  defaultDisplayMode = 'toast',
  logger,
}) => {
  const parseErrorToErrorDisplayMessage = useParseError(errorParsers);
  const [errorDisplayMessage, setErrorDisplayMessage] = useState<ErrorDisplayMessage | null>(null);

  const setError = useCallback(
    ({
      error,
      customErrorDisplayMessage,
      displayMode = defaultDisplayMode,
      customErrorParser,
      logError = false,
    }: SetErrorProps) => {
      if (displayMode === 'toast') {
        if (!error && !customErrorDisplayMessage) {
          return;
        }

        const errorDisplayMessage = parseErrorToErrorDisplayMessage(
          error,
          customErrorDisplayMessage,
          customErrorParser
        );

        const { title, description } = errorDisplayMessage;
        // Prevent empty toast messages to show
        if (isNilOrEmpty(title) && isNilOrEmpty(description)) {
          console.warn('Toast error invoked with empty title and message. Ignoring for now...', error);
          return;
        }

        toast({
          title: errorDisplayMessage.title,
          message: errorDisplayMessage.description,
          button: errorDisplayMessage.button,
          type: 'error',
          options: {
            position: 'top-right',
            autoClose: false,
          },
        });
      } else {
        setErrorDisplayMessage((current) => {
          const newError = error ? parseErrorToErrorDisplayMessage(error, customErrorDisplayMessage) : null;
          if (isEqual(newError, current)) return current;
          return newError;
        });
      }

      if (logError && error && logger) {
        logger(error);
      } else if (logError && !logger) {
        console.warn('No logger provided to ErrorProvider, but logError was set to true');
      }
    },
    [defaultDisplayMode, parseErrorToErrorDisplayMessage, logger]
  );

  const clearError = useCallback(() => {
    setErrorDisplayMessage(null);
    dismissToast();
  }, []);

  const ctx = useMemo(
    () => ({
      errorDisplayMessage,
      setError,
      clearError,
      parseErrorToErrorDisplayMessage,
    }),
    [setError, clearError, parseErrorToErrorDisplayMessage, errorDisplayMessage]
  );
  return <ErrorContext.Provider value={ctx}>{children}</ErrorContext.Provider>;
};

/**
 * Returns a function that can be used to parse an Error object and return an ErrorDisplayMessage.
 */
const useParseError = (
  errorParsers: ErrorToErrorDisplayMessage[]
): ((
  error: Error | null,
  customErrorDisplayMessage: ErrorDisplayMessage | undefined | null | string,
  customErrorParser?: ErrorToErrorDisplayMessage
) => ErrorDisplayMessage) => {
  return useCallback(
    (
      error: Error | null,
      customErrorDisplayMessage: ErrorDisplayMessage | undefined | null | string,
      customErrorParser?: ErrorToErrorDisplayMessage
    ) => {
      if (customErrorDisplayMessage) {
        if (typeof customErrorDisplayMessage === 'string') {
          return {
            title: '',
            description: customErrorDisplayMessage,
          };
        }

        return customErrorDisplayMessage;
      }

      // should never happen
      if (!error) {
        return { title: 'Unknown error', description: 'Unknown error' };
      }

      const parserQueue = customErrorParser ? [customErrorParser, ...errorParsers] : errorParsers;
      for (const parser of parserQueue) {
        try {
          const msg = parser(error);
          if (msg) return msg;
        } catch (innerError) {
          console.warn('Error parsing error', innerError, error);
        }
      }
      // should never happen
      return { title: 'Unknown error', description: 'Unknown error' };
    },
    [errorParsers]
  );
};
