import { tracker } from '@lessonup/analytics';
import { useRouter } from '@lessonup/client-integration';
import { PlayerStoreDispatcher } from '@lessonup/players-modern';
import {
  AsyncSwitch,
  ButtonAnchor,
  createModal,
  Divider,
  IconOpenInNew,
  ListItem,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopOverParentSelector,
  spacing,
  styled,
  Switch,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import type { useBoolean } from 'usehooks-ts';
import { playerv2PresenterRoute, RealtimePlayerRoute } from '../../../../utils/bridge/teacherRoutes';
import { usePlayerTranslation } from '../../hooks/usePlayerTranslation';
import { openPresenterModeInNewWindow } from '../../PresenterPlayer/presenterPlayer.utils';

type UseBooleanOutput = ReturnType<typeof useBoolean>;
export interface PlayerBarBottomSettingsProps {
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: ModalPopOverParentSelector;
  assignmentId: string;
  allowNewPlayers?: boolean;
  mirrored: UseBooleanOutput;
  dispatcher: PlayerStoreDispatcher;
  visibleSettings?: {
    openPresenterMode?: boolean;
    allowNewPlayers?: boolean;
    mirrored?: boolean;
  };
}

export const PlayerBarBottomSettings = createModal(
  ({
    allowNewPlayers,
    direction,
    modalAnchor,
    parentSelector,
    mirrored,
    dispatcher,
    visibleSettings = {
      openPresenterMode: true,
      allowNewPlayers: true,
      mirrored: true,
    },
    assignmentId,
  }: PlayerBarBottomSettingsProps) => {
    const modal = useModal();
    const { t } = usePlayerTranslation();
    const router = useRouter<RealtimePlayerRoute>();

    const handleSwitchMirrored = (event: React.ChangeEvent<HTMLInputElement>) => {
      mirrored.setValue(event.target.checked);
      modal.hide();
    };

    const handleChangeSettings = async (settings: { allowNewPlayers: boolean }) => {
      await dispatcher({
        type: 'changeSettings',
        settings,
      });
    };

    const openPresenterMode = () => {
      const { pinId, lessonId } = router.route.params;
      const presenterModeUrl = playerv2PresenterRoute.href({
        lessonId,
        pinId,
        assignmentId,
      });
      openPresenterModeInNewWindow(presenterModeUrl);
      tracker.events.lessonPresenterMode({ lessonId, assignmentId, context: 'teacher_player' });
      modal.hide();
    };

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="Player settings"
      >
        <ModalPopoverBody>
          {visibleSettings.openPresenterMode && (
            <>
              <ListItem
                text={t('settings.openPresenterMode.Title')}
                secondLine={t('settings.openPresenterMode.SecondLine')}
                endSlot={
                  <ButtonAnchor
                    buttonType="neutral"
                    buttonShape="rect"
                    showStroke
                    iconEnd={<IconOpenInNew />}
                    onClick={openPresenterMode}
                    aria-label={t('settings.openPresenterMode.AriaLabel')}
                  >
                    {t('settings.openPresenterMode.Button')}
                  </ButtonAnchor>
                }
              />
              <Divider orientation="horizontal" />
            </>
          )}
          {visibleSettings.allowNewPlayers && (
            <ListItem
              text={t('labels.allowNewParticipants')}
              secondLine={t('labels.allowNewParticipantsSecondLine')}
              endSlot={
                <AsyncSwitch
                  id="showPincodeSwitch"
                  ariaLabel={t('labels.allowNewParticipants')}
                  defaultChecked={allowNewPlayers}
                  onChange={(event) => handleChangeSettings({ allowNewPlayers: event.target.checked })}
                  checked={allowNewPlayers}
                />
              }
            />
          )}
          {visibleSettings.mirrored && (
            <ListItem
              text={t('labels.mirrorControls')}
              secondLine={t('labels.mirrorControlsSecondLine')}
              endSlot={
                <Switch
                  id="mirroredSwitch"
                  ariaLabel={t('mirrored')}
                  defaultChecked={mirrored.value}
                  onChange={handleSwitchMirrored}
                  checked={mirrored.value}
                />
              }
            />
          )}
        </ModalPopoverBody>
      </ModalPopover>
    );
  }
);

const ModalPopoverBody = styled.div`
  min-width: 200px;
  max-width: 256px;
  padding: ${spacing.size12};
`;
