import { cloneDeepWithOptions, omitUndefined } from '@lessonup/utils';
import _ from 'lodash';
import { Component, DiffLabel, DragQuestion, Link, Map, Poll, QuizQuestion, Slide, Video, Wordweb } from '../common';
import { OpenQuestion } from '../common/open-question/OpenQuestion';
import { Pin, PinItem } from '../common/Pin';
import { LessonContent, LessonPin } from '../lesson';
import { AssignmentMeta } from './AssignmentMeta';

export type TypeOfContent<P> = P extends AssignmentPin<infer C> ? C : never;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssignmentPin<T extends AssignmentPinContent = AssignmentPinContent> extends Pin<T> {
  diff?: DiffLabel;
}

export type AnyAssignmentPin =
  | AssignmentPin.Slide
  | AssignmentPin.Video
  | AssignmentPin.Quiz
  | AssignmentPin.OpenQuestion
  | AssignmentPin.DragQuestion
  | AssignmentPin.Wordweb
  | AssignmentPin.Link
  | AssignmentPin.Map
  | AssignmentPin.Poll;

export type AssignmentPinItem = PinItem<AssignmentPinContent>;

export type AssignmentPinContent =
  | Slide.Content
  | Video.Content
  | QuizQuestion.AssignmentContent
  | OpenQuestion.AssignmentContent
  | DragQuestion.AssignmentContent
  | Wordweb.Content
  | Link.Content
  | Map.Content
  | Poll.Content;

export namespace AssignmentPin {
  export type Slide = AssignmentPin<Slide.Content>;
  export type Video = AssignmentPin<Video.Content>;
  export type Quiz = AssignmentPin<QuizQuestion.AssignmentContent>;
  export type OpenQuestion = AssignmentPin<OpenQuestion.AssignmentContent>;
  export type DragQuestion = AssignmentPin<DragQuestion.AssignmentContent>;
  export type Wordweb = AssignmentPin<Wordweb.Content>;
  export type Link = AssignmentPin<Link.Content>;
  export type Map = AssignmentPin<Map.Content>;
  export type Poll = AssignmentPin<Poll.Content>;

  export type UpdateData = Partial<Pick<AssignmentPinItem, 'testPoints'> & Pick<AssignmentPin, 'order'>>;

  export function isSlide(pin: AssignmentPin): pin is Slide {
    return pin.item.type === 'slide';
  }

  export function isVideo(pin: AssignmentPin): pin is Video {
    return pin.item.type === 'video';
  }

  export function isQuiz(pin: AssignmentPin): pin is Quiz {
    return pin.item.type === 'question';
  }

  export function isOpenQuestion(pin: AssignmentPin): pin is OpenQuestion {
    return pin.item.type === 'openQuestion';
  }

  export function isDragQuestion(pin: AssignmentPin): pin is DragQuestion {
    return pin.item.type === 'dragQuestion';
  }

  export function isWordweb(pin: AssignmentPin): pin is Wordweb {
    return pin.item.type === 'wordweb';
  }

  export function isLink(pin: AssignmentPin): pin is Link {
    return pin.item.type === 'link';
  }

  export function isMap(pin: AssignmentPin): pin is Map {
    return pin.item.type === 'map';
  }

  export function isPoll(pin: AssignmentPin): pin is Poll {
    return pin.item.type === 'poll';
  }

  /** creates a copy where the content has been removed */
  export function pinWithoutContent(pin: AssignmentPin): AssignmentPin {
    const cleanedItem = _.pick(pin.item, ['custom.color', 'name', 'source', 'type']);

    _.set(cleanedItem, 'custom.answers', []);
    _.set(cleanedItem, 'components', []);

    return {
      ...pin,
      item: {
        ...cleanedItem,
        components: [],
      } as any,
    };
  }

  export function assignmentPinFromLessonPin<T extends AssignmentPinContent = AssignmentPinContent>(
    lessonPin: LessonPin,
    isTest: boolean,
    useThumbnailForComponentType?: Component.Type[]
  ): AssignmentPin<T> {
    return assignmentPinFromLessonPinItem(
      lessonPin.item,
      isTest,
      lessonPin._id,
      lessonPin.order,
      lessonPin.timer,
      LessonPin.differentiation(lessonPin),
      lessonPin.videoQuestion,
      useThumbnailForComponentType
    );
  }

  export function assignmentPinFromLessonPinItem<T extends AssignmentPinContent = AssignmentPinContent>(
    item: PinItem<LessonContent>,
    isTest: boolean,
    pinId: string,
    order: number,
    timer?: number,
    diff?: DiffLabel,
    videoQuestion?: string,
    useThumbnailForComponentType?: Component.Type[]
  ): AssignmentPin<T> {
    return {
      _id: pinId,
      item: omitUndefined({
        custom: assignmentPinContentFromLessonPinItem(item, timer),
        name: item.name,
        testPoints: isTest ? LessonPin.testPointsForPinItem(item) : undefined,
        type: item.type,
        components: assignmentPinComponentsFromLessonPinItem(item, useThumbnailForComponentType),
        image: item.image,
        maxresImage: item.maxresImage,
        image2: item.image2,
        source: item.source,
        uploadId: item.uploadId,
        url: item.url,
        videoItems: item.videoItems,
        exclusions: item.exclusions,
        notes: item.notes,
        shuffleMap: item.shuffleMap,
        settings: item.settings,
        invalidVideo: item.invalidVideo,
      }),
      order,
      diff,
      videoQuestion,
    };
  }

  export function lessonPinFromAssignmentPin(assignmentPin: AssignmentPin, meta: AssignmentMeta): LessonPin {
    const now = new Date();

    return {
      _id: assignmentPin._id,
      differentiation: assignmentPin.diff,
      order: assignmentPin.order || 0,
      timer: assignmentPin.timer,
      videoQuestion: assignmentPin.videoQuestion,
      item: lessonPinItemFromAssignmentContent(assignmentPin, meta),
      creationDate: now,
      isPublic: true,
      lesson: meta.lessonId,
      shouldIndex: now,
    };
  }

  export function lessonPinItemFromAssignmentContent<T extends LessonContent = LessonContent>(
    pin: AssignmentPin,
    meta: AssignmentMeta
  ): PinItem<T> {
    const pinId = pin._id;

    const pinItem: PinItem<any> = _.cloneDeep(pin.item);

    switch (pinItem.type) {
      case 'openQuestion':
        const pinContent = Pin.getContent(pin) as OpenQuestion.AssignmentContent;
        pinItem['custom'] = _.omit(OpenQuestion.LessonContent.fromAssignmentContent(pinId, pinContent, meta), [
          'hasAnswer',
          'maximumNumberOfUploads',
        ]);
        break;
      case 'question':
        Object.assign(
          pinItem.custom,
          QuizQuestion.Answer.convertAssignmentPinAnswersToLessonPinAnswers(
            pinId,
            Pin.getContent(pin) as QuizQuestion.AssignmentContent,
            meta
          )
        );
        pinItem.custom = _.omit(pinItem.custom, ['answerData', 'answers', 'hasAnswer']);
        break;
      default:
        break;
    }

    const lessonPinItem = {
      ...pinItem,
      components: pinItem.components,
    } as PinItem<T>;

    if (pinItem.type === 'dragQuestion' && pinItem.components?.length) {
      const correctAnswers = AssignmentMeta.correctAnswerForPin<DragQuestion.CorrectAnswer>(meta, pinId);
      const updatedComponents = DragQuestion.Components.lessonComponentsFromAssignmentComponents(
        lessonPinItem.components || [],
        correctAnswers
      );
      lessonPinItem.components = updatedComponents;
    }
    return lessonPinItem;
  }

  // TODO: it would be better to now copy the values, but the pick those one at a time (for compile safe reasons)
  function assignmentPinContentFromLessonPinItem<T extends AssignmentPinContent = AssignmentPinContent>(
    item: PinItem<LessonContent>,
    timer: number | undefined
  ): T {
    let content: any = _.cloneDeep(item.custom);

    switch (item.type) {
      case 'openQuestion':
        _.set(content, 'maximumNumberOfUploads', OpenQuestion.LessonContent.maximumImageUploads(content));
        _.set(content, 'hasAnswer', !!content.answer);
        break;
      case 'question':
        content = convertQuizQuestionContentItem(item as PinItem<QuizQuestion.LessonContent>, timer); // TODO Avoid cast somehow?
        break;
      case 'dragQuestion':
        _.set(content, 'hasAnswer', LessonPin.itemContainsCorrectAnswer(item));
        break;
      default:
        break;
    }

    // We always want to clear the answer
    content = _.omit(content, ['answer', 'correctAnswer']);

    return content;
  }

  function assignmentPinComponentsFromLessonPinItem(
    item: PinItem<LessonContent>,
    useThumbnailForComponentType?: Component.Type[]
  ): Component.All[] {
    let components = (cloneDeepWithOptions(item.components, { replaceDateWithTimestamps: true }) ||
      []) as Component.All[];
    if (item.type === 'dragQuestion') {
      components = DragQuestion.Components.componentsWithoutCorrectAnswer(components);
    }
    return components.map((c) => {
      if (c.type === 'table') {
        // tabledata is a multidimensional array, firebase does not support those
        const tableData = JSON.stringify(c.settings.tableData);
        c.settings = {
          ...c.settings,
          tableData,
        };
      }
      return { ...c, useThumbnailForComponentType };
    });
  }

  function convertPinAnswers(content: QuizQuestion.LessonContent): QuizQuestion.AnyAssignmentAnswerType[] {
    const { answer1, answer2, answer3, answer4 } = content;
    const contentAnswerData = content.answerData;

    const list = [answer1, answer2, answer3, answer4].map((answer, index) => {
      const answerData = _.get(contentAnswerData, 'answer' + (index + 1));

      const answerType = !answerData && _.isEmpty(answer) ? 'empty' : (answerData && answerData.type) || 'text';

      const answerNumber = index + 1;
      return Object.assign({}, answerData, {
        value: answerNumber,
        type: answerType,
        text: answer || undefined,
      });
    });

    // remove empty starting from end
    for (let i = 3; i > 0; i--) {
      if (list[i].type === 'empty') {
        list.pop();
      } else {
        break;
      }
    }

    return list;
  }

  function convertQuizQuestionContentItem(
    item: PinItem<QuizQuestion.LessonContent>,
    timer: number | undefined
  ): QuizQuestion.AssignmentContent {
    const content: QuizQuestion.LessonContent = item.custom;

    return {
      ..._.omit(content, ['answer1', 'answer2', 'answer3', 'answer4', 'correctAnswer', 'answerData', 'timer']),
      hasAnswer: LessonPin.itemContainsCorrectAnswer(item),
      answers: convertPinAnswers(content),
      timer: timer === 0 ? undefined : timer,
      question: content.question,
    };
  }

  function convertQuizQuestionContent(
    lessonPin: LessonPin<QuizQuestion.LessonContent>
  ): QuizQuestion.AssignmentContent {
    return convertQuizQuestionContentItem(lessonPin.item, lessonPin.timer);
  }

  export function timerForPin(pin: AssignmentPin): number | undefined {
    const timer = _.get(pin.item.custom, 'timer');
    if (_.isNumber(timer)) {
      return timer;
    }
    return undefined;
  }

  export function hasTestPoints(pin: AssignmentPin): boolean {
    return !!pin.item.testPoints;
  }
}
