import { useDocumentMutation } from '@lessonup/client-integration';
import { useModal } from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import { MoveUploadModal } from '../dataComponents/MoveUploadModal';
import { MoveUploadsAndFoldersDocument, MyUploadsDocument } from '../Uploads.graphql.generated';

type OnMove = (destinationFolderId: string) => void;

type OnError = (error: Error) => void;

interface MoveUploadsParams {
  uploadIds: string[];
  folderIds: string[];
  destinationFolderId: string;
}

export const useMoveUploads = ({ onMove, onError }: { onMove?: OnMove; onError: OnError }) => {
  const modal = useModal(MoveUploadModal);

  const [moveUploadsMutation] = useDocumentMutation(MoveUploadsAndFoldersDocument, {
    refetchQueries: [MyUploadsDocument],
  });

  const handleMoveUploads = async (params: MoveUploadsParams) => {
    const { uploadIds, folderIds, destinationFolderId } = params;
    try {
      validateMove(params);
      await moveUploadsMutation({
        variables: {
          input: { uploadIds: uploadIds, folderIds: folderIds, destinationFolderId: destinationFolderId },
        },
      });
      onMove?.(destinationFolderId);
    } catch (error) {
      onError(asError(error));
    } finally {
      modal.hide();
    }
  };

  return handleMoveUploads;
};

function validateMove(params: MoveUploadsParams): void {
  const allToBeMovedItems = params.uploadIds.concat(params.folderIds);

  if (allToBeMovedItems.includes(params.destinationFolderId)) {
    throw new Error('Cannot move folder to itself');
  }

  if (!allToBeMovedItems.length) {
    throw new Error('There is nothing to move');
  }
}
