import { UpdatePinActionWithMetadata } from '@lessonup/pin-renderer';

const NUMBER_OF_ACTIONS_TO_LOG = 5;

type UndoManagerAction = 'undo' | 'redo';
export type ReducerLog = { action: UpdatePinActionWithMetadata } | { undoManagerAction: UndoManagerAction };

const createReducerLogger = () => {
  const log: ReducerLog[] = [];
  const normalizeLogSize = () => {
    log.splice(0, log.length - NUMBER_OF_ACTIONS_TO_LOG);
  };
  return {
    logDispatcherAction: (action: UpdatePinActionWithMetadata) => {
      log.push({
        action,
      });
      normalizeLogSize();
    },
    logUndoManagerAction: (undoManagerAction: UndoManagerAction) => {
      log.push({ undoManagerAction });
      normalizeLogSize();
    },
    get: () => [...log],
  };
};

export const reducerLogger = createReducerLogger();
