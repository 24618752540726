import { useUpdatePin } from '@lessonup/pin-renderer';
import { BodyText, Button, IconAdd, SpaceBetween, spacing, styled, useModal } from '@lessonup/ui-components';
import React from 'react';
import { useEditorContext } from '../../context/EditorContext/EditorContext';
import { useEditorTranslation } from '../../hooks/useEditorTranslation';
import { TempAddPinModal } from '../EditorModals/AddPinModal/AddPinModal';

export const SlideCanvasEmptyState: React.FC = () => {
  const { setActivePinId } = useEditorContext();
  const { t } = useEditorTranslation();
  const modal = useModal(TempAddPinModal);
  const dispatch = useUpdatePin();

  return (
    <StyledWrapper>
      <SpaceBetween direction="y" spacing={spacing.size16} alignItems="center">
        <BodyText>{t('emptyState.body')}</BodyText>
        <Button buttonType="primary" iconStart={<IconAdd />} onClick={() => modal.show({ dispatch, setActivePinId })}>
          {t('emptyState.addButton')}
        </Button>
      </SpaceBetween>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
