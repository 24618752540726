import { HotspotExpandDirection, HotspotPinComponent, Size, toJSONContent, Vector } from '@lessonup/pins-shared';
import { BodyText, borderRadius, color, styled } from '@lessonup/ui-components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TipTapComponent } from '../../components/Tiptap/Tiptap.utils';
import { TiptapViewer } from '../../components/Tiptap/TipTapViewer';
import {
  calculateContentHolderPadding,
  calculateContentHolderTranslateX,
  calculateContentHolderTranslateY,
  calculateFlipX,
  calculateFlipY,
} from './hotspotContentHolder.utils';

export interface HotspotContentHolderProps {
  hotspotSize: Size;
  hotspotPosition: Vector;
  expandDirection: HotspotExpandDirection;
  showBody?: boolean;
  bodyText?: HotspotPinComponent['settings']['body'];
  title?: HotspotPinComponent['settings']['title'];
  backgroundColor?: string;
  textColor?: string;
  componentId: string;
  pinId: string;
  Renderer?: TipTapComponent;
}

const MAX_CONTENT_HOLDER_WIDTH = 320;
const MAX_CONTENT_HOLDER_HEIGHT = 256;

export const HotspotContentHolder: React.FC<HotspotContentHolderProps> = ({
  hotspotSize,
  hotspotPosition,
  expandDirection,
  showBody = false,
  bodyText,
  title,
  backgroundColor,
  textColor,
  componentId,
  pinId,
  Renderer,
}) => {
  const [isContentEmpty, setIsContentEmpty] = useState(true);
  const contentHolderRef = useRef<HTMLDivElement>(null);

  // TODO ED-624: This is a workaround as we needed to store the content as string to stay within the maximum depth limit in Firestore
  // Remove once we retrieve the JSON from GraphQL
  const titleContent = typeof title === 'string' ? toJSONContent(title) : title;
  const bodyContent = typeof bodyText === 'string' ? toJSONContent(bodyText) : bodyText;

  const isXFlipped = calculateFlipX(expandDirection, hotspotSize, hotspotPosition);
  const isYFlipped = calculateFlipY(expandDirection, hotspotSize, hotspotPosition);

  const checkIfContentIsEmpty = useCallback(() => {
    const innerText = contentHolderRef.current?.innerText.trim() || '';
    setIsContentEmpty(innerText === '');
  }, []);

  // This observer is a temporary solution to check if the content is empty, we should find a better solution to detect changes to the TipTapContent (ED-1135)
  useEffect(() => {
    checkIfContentIsEmpty();
    const observer = new MutationObserver(() => {
      checkIfContentIsEmpty();
    });

    if (contentHolderRef.current) {
      observer.observe(contentHolderRef.current, { childList: true, subtree: true, characterData: true });
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (titleContent === null) {
    console.log('Error parsing JSON content', title);
    return null;
  }
  if (bodyContent === null) {
    console.log('Error parsing JSON content', bodyText);
    return null;
  }

  return (
    <StyledWrapper
      ref={contentHolderRef}
      style={{
        backgroundColor: backgroundColor ?? color.neutral.outline.background,
        color: textColor ?? '#000000',
        padding: calculateContentHolderPadding(isXFlipped, hotspotSize),
        transform: `translate(${calculateContentHolderTranslateX(
          isXFlipped,
          hotspotSize
        )}, ${calculateContentHolderTranslateY(isYFlipped, hotspotSize)})`,
        display: isContentEmpty ? 'none' : 'inline-block',
      }}
    >
      <BodyText weight="bold">
        {Renderer ? (
          <Renderer pinId={pinId} pinComponentId={componentId} fragmentKey="title" editable={false} active={false} />
        ) : (
          <TiptapViewer content={titleContent} />
        )}
      </BodyText>
      {showBody && (
        <BodyText>
          {Renderer ? (
            <Renderer pinId={pinId} pinComponentId={componentId} fragmentKey="body" editable={false} active={false} />
          ) : (
            <TiptapViewer content={bodyContent} />
          )}
        </BodyText>
      )}
    </StyledWrapper>
  );
};

/**
 * We have to disable user-select because of TipTapComponent
 */
const StyledWrapper = styled.div`
  overflow-y: auto;
  max-height: ${MAX_CONTENT_HOLDER_HEIGHT}px;
  max-width: ${MAX_CONTENT_HOLDER_WIDTH}px;
  width: max-content;
  position: relative;
  word-wrap: break-word;
  border-radius: ${borderRadius.rounded4};
  user-select: none;
`;
