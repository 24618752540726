import { color, styled, Tag } from '@lessonup/ui-components';
import React from 'react';

export interface LearningPhaseProps {
  learningPhase: string;
}

export const LearningPhase: React.FC<LearningPhaseProps> = ({ learningPhase }) => {
  return (
    <StyledLearningPhase>
      <StyledLine />
      <Tag priority="medium">{learningPhase}</Tag>
    </StyledLearningPhase>
  );
};

const StyledLearningPhase = styled.li`
  list-style: none;
  display: flex;
`;

const StyledLine = styled.div`
  align-self: center;
  width: 100%;
  height: 0px;
  border: 2px solid ${color.accent.tertiary.background};

  :before {
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 10px;
    transform: translate(-20%, -50%);
    float: left;
    background: ${color.accent.tertiary.background};
  }
`;
