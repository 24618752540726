import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RefereeTrialGiftQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RefereeTrialGiftQuery = { __typename?: 'Query', activeRefereeTrialGift?: { __typename?: 'TrialGift', amountOfDays: number, name: string } | null };


export const RefereeTrialGiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RefereeTrialGift"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeRefereeTrialGift"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amountOfDays"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<RefereeTrialGiftQuery, RefereeTrialGiftQueryVariables>;