import {
  BodyText,
  borderRadius,
  color,
  Divider,
  List,
  ListItemProps,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MIN_MODAL_HEIGHT, MODAL_HEIGHT } from '../model';

interface CreateLessonAsideProps {
  classicEditorListItems: ListItemProps[];
  newEditorlistItems: ListItemProps[];
}

export const CreateLessonAside: React.FC<CreateLessonAsideProps> = ({ classicEditorListItems, newEditorlistItems }) => {
  const { t } = useTranslation('createLessonModal');
  return (
    <StyledAside>
      <StyledAsideSpaceBetween direction="y" spacing={spacing.size8}>
        <AsideContentBlock>
          <AsideHeader type="classic">
            <BodyText size="medium" weight="bold">
              {t('classicEditor')}
            </BodyText>
          </AsideHeader>
          <ListWrapper>
            <StyledList items={classicEditorListItems} gap={spacing.size4} />
          </ListWrapper>
        </AsideContentBlock>
        <AsideContentBlock>
          <AsideHeader type="new">
            <BodyText size="medium" weight="bold">
              {t('newEditor.title')}
            </BodyText>
          </AsideHeader>
          <ListWrapper>
            <StyledList items={newEditorlistItems} gap={spacing.size4} />
          </ListWrapper>
        </AsideContentBlock>
      </StyledAsideSpaceBetween>
      <StyledDividerWrapper>
        <Divider orientation="vertical" noSpacer />
      </StyledDividerWrapper>
    </StyledAside>
  );
};

const AsideHeader = styled.div<{ type: 'classic' | 'new' }>`
  ${(props) =>
    props.type === 'classic'
      ? `
    background-color: ${color.message.warning.fill};
    color: ${color.message.warning.on};
    `
      : `
    background-color: ${color.message.positive.fill};
    color: ${color.message.positive.on}; `}
  border-top-right-radius: ${borderRadius.rounded16};
  border-top-left-radius: ${borderRadius.rounded16};
  width: 100%;
  padding: ${spacing.size16} ${spacing.size16} ${spacing.size24};
`;

const AsideContentBlock = styled.div``;

const StyledAsideSpaceBetween = styled(SpaceBetween)`
  padding: ${spacing.size8};
  width: 100%;
  height: 100%;
`;

const ListWrapper = styled.div`
  border-top-right-radius: ${borderRadius.rounded16};
  border-top-left-radius: ${borderRadius.rounded16};
  padding: ${spacing.size16} ${spacing.size8};
  margin-top: -${spacing.size16};
  background: ${color.neutral.surface.background};
`;

const StyledDividerWrapper = styled.div`
  width: 1px;
  height: 100%;
`;

const StyledList = styled(List)`
  width: 100%;
  padding: ${spacing.size8};
  margin: ${spacing.size8};
  border-top-right-radius: ${borderRadius.rounded16};
  border-top-left-radius: ${borderRadius.rounded16};
`;

const StyledAside = styled.div`
  max-height: ${MODAL_HEIGHT};
  min-height: ${MIN_MODAL_HEIGHT};
  overflow-y: scroll;
  width: 240px;
  overflow: hidden;
  flex-direction: row;
  display: flex;
`;
