import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { isValidEditorJsonContent, parseHtmlClipboardData } from '../../../hooks/useCopyPaste.utils';

const pluginName = 'pasteEventHandler';

export const PasteHandler = Extension.create({
  name: pluginName,
  addProseMirrorPlugins(this) {
    return [
      new Plugin({
        key: new PluginKey(pluginName),
        props: {
          /**
           * @description Should return whether or not the event has been handled, in order to stop it's propagation.
           * true = stop propagation
           * false | undefined = continue
           */
          handlePaste(view, event) {
            try {
              if (!event.clipboardData) return false;
              const data =
                parseHtmlClipboardData(event.clipboardData.getData('text/html')) ?? event.clipboardData.getData('text');
              if (isValidEditorJsonContent(data)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          },
        },
      }),
    ];
  },
});
