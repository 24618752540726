import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconStudent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.974 9c-2.814 0-5.486 2.024-5.15 4.818q.04.325.094.687c.108.721.736 1.245 1.465 1.245h.833l.608 3.774a2.201 2.201 0 0 0 4.346 0l.608-3.774h.844c.724 0 1.351-.517 1.462-1.232q.052-.342.09-.652C17.52 11.052 14.81 9 11.975 9M12 8.625a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
  </svg>
);
export default SvgIconStudent;
