import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconReport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.509 6.902h2.607M7.51 10.91h4.49m5.204 1.96L14.7 16.525l-3.557-1.733-2.313 2.864m-5.891-.753c.231 2.16 1.969 3.899 4.129 4.14 1.604.178 3.252.332 4.931.332s3.327-.154 4.93-.332c2.161-.241 3.899-1.98 4.13-4.14.17-1.595.315-3.233.315-4.903s-.144-3.308-.315-4.903c-.231-2.16-1.969-3.899-4.13-4.14-1.603-.178-3.25-.332-4.93-.332s-3.327.154-4.931.332c-2.16.241-3.898 1.98-4.13 4.14-.17 1.595-.314 3.233-.314 4.903s.144 3.307.315 4.903"
    />
  </svg>
);
export default SvgIconReport;
