import { Box, Line, newLine, newVector, Vector } from '@lessonup/pins-shared';
import { lineEndPoint } from '../../types';
import { isCounterClockWise } from './math';
import { addVectors, divideVector, scaleVector, subtractVectors } from './vector';

export function updateLine(line: Line, updatedLine: Partial<Line>): Line {
  return {
    ...line,
    ...updatedLine,
  };
}

export function moveLine(line: Line, displacement: Vector): Line {
  return {
    ...line,
    start: addVectors(line.start, displacement),
    end: addVectors(line.end, displacement),
  };
}

export function resizeLine(line: Line, displacement: Vector, endPoint: lineEndPoint): Line {
  if (endPoint === 'start') {
    return {
      ...line,
      start: addVectors(line.start, displacement),
    };
  }
  return {
    ...line,
    end: addVectors(line.end, displacement),
  };
}

export function multiplyLineVectors(line: Line, scalar: number): Line {
  return newLine(scaleVector(line.start, scalar), scaleVector(line.end, scalar));
}

export function lineCenter(line: Line): Vector {
  return newVector((line.start.x + line.end.x) / 2, (line.start.y + line.end.y) / 2);
}

/**
 *This algorithm uses a method where it checks if triangles between the line endpoints
 *are counterclockwise to determine if the line segments intersect.
 */
export function doLineSegmentsIntersect(lineSegment1: Line, lineSegment2: Line): boolean {
  return (
    isCounterClockWise(lineSegment1.start, lineSegment2.start, lineSegment2.end) !=
      isCounterClockWise(lineSegment1.end, lineSegment2.start, lineSegment2.end) &&
    isCounterClockWise(lineSegment1.start, lineSegment1.end, lineSegment2.start) !=
      isCounterClockWise(lineSegment1.start, lineSegment1.end, lineSegment2.end)
  );
}

export function scaleLineInGroup(line: Line, groupBox: Box, resizedGroupBox: Box, scalar: number): Line {
  const localStart = subtractVectors(line.start, groupBox.position);
  const localEnd = subtractVectors(line.end, groupBox.position);
  const start = newVector(
    resizedGroupBox.position.x + localStart.x * scalar,
    resizedGroupBox.position.y + localStart.y * scalar
  );
  const end = newVector(
    resizedGroupBox.position.x + localEnd.x * scalar,
    resizedGroupBox.position.y + localEnd.y * scalar
  );

  return newLine(start, end);
}

export function lineCenterPosition(line: Line): Vector {
  return divideVector(addVectors(line.start, line.end), 2);
}
