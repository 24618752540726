import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9.152v3.75m0 3.375v.375m8.618.72c.26.53.466 1.124-.062 1.545 0 0-1.805 1.333-8.555 1.333-6.751 0-8.556-1.333-8.556-1.333-.529-.422-.324-1.01-.062-1.545L9.306 5.278c1.098-2.24 4.291-2.24 5.389 0z"
    />
  </svg>
);
export default SvgIconWarning;
