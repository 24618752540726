import { Identifiable, Modifiable, Schedulable } from '@lessonup/utils';
import _ from 'lodash';
import {
  Assignment,
  AssignmentMeta,
  AssignmentPin,
  AssignmentPinContent,
  AssignmentSettings,
  AssignmentType,
  AssignmentUtils,
} from '../assignment';
import { Grade, Progress, QuizQuestion, TestPhase, UserReference } from '../common';
import { SeedShuffle } from '../common/SeedShuffle';
import { MigrationTarget } from '../migrations/MigrationTarget';
import { AssignmentEntryUpdateData } from './AssignmentEntryUpdateData';
import { AnyAnswer, AnyAnswerResult, AnyPinEntry, PinEntry } from './PinEntry';

export type AssignmentEntryStatus = 'kicked' | 'default' | 'left';
/**
 * This object contains all the answers for the given assignment (for a single user)
 */
export interface AssignmentEntry extends Identifiable, MigrationTarget, Modifiable, Schedulable {
  userId: string;
  createdAt: number;
  assignmentId: string;
  type: AssignmentType;
  displayName?: string;
  familyName?: string;
  entries: { [pinId: string]: AnyPinEntry | null | undefined };
  totalPins: number;
  status: AssignmentEntryStatus;
  testData?: AssignmentEntry.TestData;
  seenAt?: number;
  activePinId?: string; // used in linear mode to determine the validity of the current pin.
  raiseHand?: AssignmentEntry.RaiseHand;
  logins?: { ts: number }[];
}

export namespace AssignmentEntry {
  export const excludedComparisonPaths: string[] = ['createdAt', 'modifiedAt', 'migratedAt'];
  export const maxRegisteredLoginCount = 50;
  export interface TestData {
    phase: TestPhase;
    grade?: Grade;
  }
  export interface RaiseHand {
    question: string;
    timeStamp: number;
    pinId: string;
  }

  export interface RaiseHandCompleted extends RaisedHandWitUserData {
    result: 'done' | 'cleared';
  }

  export interface ShuffleMap {
    questions: {
      [pinId: string]: QuizQuestion.AnswerValue[];
    };
    pinOrder: string[];
  }

  export function createIds(assignmentIds: string[], userIds: string[]): string[] {
    return _.flatMap(assignmentIds, (assignmentId) => userIds.map((userId) => createId(assignmentId, userId)));
  }

  export function createId(assignmentId: string, userId: string): string {
    return `${assignmentId}-${userId}`;
  }

  export function splitIdIntoComponents(entryId: string): { assignmentId: string; userId: string } {
    const [assignmentId, userId] = entryId.split('-');
    return { assignmentId, userId };
  }

  export function create(meta: AssignmentMeta, user: UserReference, schedulable?: Schedulable): AssignmentEntry {
    const testData: AssignmentEntry.TestData | undefined = AssignmentMeta.isTest(meta)
      ? {
          phase: 'created',
        }
      : undefined;

    const now = Date.now();

    return {
      _id: createId(meta._id, user._id),
      createdAt: now,
      type: meta.type,
      modifiedAt: now,
      assignmentId: meta._id,
      userId: user._id,
      displayName: user.displayName,
      familyName: user.familyName,
      totalPins: meta.assignmentInfo.numberOfPins,
      entries: {},
      status: 'default',
      testData,
      ...schedulable,
    };
  }

  /** creates a clone of the given entry and applies the given update logic so we can use it for e.g. comparisons */
  export function update(assignmentEntry: AssignmentEntry, updates: AssignmentEntryUpdateData): AssignmentEntry {
    const cloned = _.cloneDeep(assignmentEntry);
    const updateFields = AssignmentEntryUpdateData.updateFields(updates);
    _.forEach(updateFields, (value, field) => _.set(cloned, field, value));
    return cloned;
  }

  export function createForUsers(meta: AssignmentMeta, users: UserReference[]): AssignmentEntry[] {
    return _.map(users, (user) => AssignmentEntry.create(meta, user));
  }

  export function progress(entry: AssignmentEntry): Progress {
    const done = doneCount(entry);
    return Progress.calc(done, entry.totalPins);
  }

  export function doneCount(entry: AssignmentEntry, onlyInteractive = false): number {
    return _.values(entry.entries)
      .filter((pinEntry) => (onlyInteractive ? PinEntry.isInterActive(pinEntry) : true))
      .filter((pinEntry) => pinEntry && pinEntry.done === true).length;
  }

  export function pinEntries(assignmentEntry: AssignmentEntry): AnyPinEntry[] {
    return _.compact(_.values(assignmentEntry.entries));
  }

  export function pinEntryFromEntries(entries: AssignmentEntry[], pinId: string | undefined): AnyPinEntry[] {
    if (_.isNil(pinId)) {
      return [];
    }
    return _.compact(_.map(entries, (value) => AssignmentEntry.entryForPin(value, pinId)));
  }

  export function pinEntriesWithDisplayNameFromEntries(
    entries: AssignmentEntry[],
    pinId: string | undefined
  ): PinEntry.WithDisplayName[] {
    if (_.isNil(pinId)) {
      return [];
    }

    return _.compact(
      entries.map((value) => {
        const entry = entryForPin(value, pinId);
        if (!entry) return undefined;

        return {
          ...entry,
          displayName: value.displayName,
        };
      })
    );
  }

  export function entryForPin<T extends AnyPinEntry = AnyPinEntry>(
    entry: AssignmentEntry | undefined,
    pinId: string | undefined
  ): T | undefined {
    return pinId ? (_.get(entry && entry.entries, pinId) as T) : undefined;
  }

  export function answerForPinEntry<T extends AnyAnswer = AnyAnswer>(pinEntry: AnyPinEntry | undefined) {
    if (!pinEntry) {
      return undefined;
    }
    return pinEntry.answer as T;
  }

  export function answerForPin<T extends AnyAnswer = AnyAnswer>(
    entry: AssignmentEntry | undefined,
    pinId: string | undefined
  ): T | undefined {
    const pinEntry = entryForPin(entry, pinId);
    if (!pinEntry) {
      return undefined;
    }
    return pinEntry.answer as T | undefined;
  }

  export function resultForPin<T extends AnyAnswerResult = AnyAnswerResult>(
    entry: AssignmentEntry | undefined,
    pinId: string | undefined
  ): T | undefined {
    const pinEntry = entryForPin(entry, pinId);
    if (!pinEntry) {
      return undefined;
    }
    return pinEntry.result as T;
  }

  export function hasStatus(entry: AssignmentEntry | undefined, status: AssignmentEntryStatus): boolean {
    return entry ? entry.status == status : false;
  }

  /** returns entries that match one of the given statusses */
  export function filterByStatus(
    entries: AssignmentEntry[],
    status: AssignmentEntryStatus | AssignmentEntryStatus[]
  ): AssignmentEntry[] {
    const values = _.isArray(status) ? status : [status];
    return entries.filter((entry) => values.indexOf(entry.status) != -1);
  }

  export function isAnyPinSeen(entry: AssignmentEntry): boolean {
    return Object.values(entry.entries).some((e) => !!(e && e.seen));
  }

  export function countPinSeen(entry: AssignmentEntry): number {
    return Object.values(entry.entries).filter((e) => e && e.seen).length;
  }

  export function calcPercentagePinsDone(entry: AssignmentEntry): number {
    const total = entry.totalPins;
    const count = Object.values(entry.entries).filter((e) => e && e.done).length;
    if (total == 0) return 0;
    return (count / total) * 100;
  }

  export function timeSpentOnPin(entry: AssignmentEntry | undefined, pinId: string): number {
    const pinEntry = entryForPin(entry, pinId);
    return (pinEntry && pinEntry.timeSpent) || 0;
  }

  export function isTestPhase(entry: AssignmentEntry, phase: TestPhase): boolean {
    if (entry.testData) {
      return entry.testData.phase === phase;
    }
    return false;
  }

  export function unansweredInteractivePinCount(entry: AssignmentEntry, assignment: Assignment): number {
    const pinsWithPoints = AssignmentUtils.pinsWithTestPoints(assignment);

    return _.filter(pinsWithPoints, (p) => {
      if (!AssignmentUtils.pinIsInAssignment(p, assignment)) {
        return false;
      }

      const pinEntry = AssignmentEntry.entryForPin(entry, p._id);
      return !pinEntry || !pinEntry.done;
    }).length;
  }

  export function getGrade(entry: AssignmentEntry): undefined | string | number {
    const grade = entry.testData && entry.testData.grade;
    if (grade == undefined) return undefined;
    if (_.isNumber(grade)) return grade;
    const num = Number(grade);
    return _.isFinite(num) ? num : grade;
  }
  export function isOneOfTestPhases(entry: AssignmentEntry, phases: TestPhase[]) {
    return entry.testData && phases.includes(entry.testData.phase);
  }

  export function countPhases(entries: AssignmentEntry[]): TestPhase.TotalCount {
    const phases = _.compact(
      entries.map((entry) => {
        return entry.status === 'default' && entry.testData && entry.testData.phase;
      })
    );
    return TestPhase.countPhases(phases);
  }

  export function hasSeenPin(entry: AssignmentEntry, pinId: string): boolean {
    const pinEntry = entryForPin(entry, pinId);
    return !_.isNil(pinEntry) && pinEntry?.seen === true;
  }

  export function hasUnseenComments(entry: AssignmentEntry) {
    return (
      entry.entries &&
      _.some(Object.values(entry.entries), (pinEntry) => pinEntry && PinEntry.hasUnseenComment(pinEntry))
    );
  }
  export function hasUnseenCommentForPin(entry: AssignmentEntry, pinId: string) {
    const pinEntry = entryForPin(entry, pinId);
    return !!pinEntry && PinEntry.hasUnseenComment(pinEntry);
  }

  export function highestTestPhase(entries: AssignmentEntry[]): TestPhase | undefined {
    const highestPhaseEntry = _.maxBy(entries, (entry: AssignmentEntry) => {
      return entry.testData ? TestPhase.orderIndex(entry.testData.phase) : 0;
    });

    return highestPhaseEntry && highestPhaseEntry.testData && highestPhaseEntry.testData.phase;
  }

  export function containsAnyTestPhase(entries: AssignmentEntry[], testPhases: TestPhase[]): boolean {
    return _.some(entries, (entry) => _.some(testPhases, (testPhase) => AssignmentEntry.isTestPhase(entry, testPhase)));
  }

  export function isKicked(entry: AssignmentEntry): boolean {
    return hasStatus(entry, 'kicked');
  }

  export function hasDisplayName(entry: AssignmentEntry): boolean {
    return Boolean(entry.displayName && entry.displayName.length);
  }

  export function isActiveEntry(entry: AssignmentEntry): boolean {
    return hasStatus(entry, 'default');
  }

  export function shouldHaveBookmark(entry: AssignmentEntry): boolean {
    // Any entry should have a bookmark, except when the match the following criteria.
    return !isKicked(entry);
  }

  export function shuffleMapForQuestion(
    pin: AssignmentPin<QuizQuestion.AssignmentContent>,
    userId: string
  ): QuizQuestion.AnswerValue[] {
    const options = pin.item.custom.answers.map((a) => a.value);
    const map = SeedShuffle.shuffle(options, `${userId}-${pin._id}`);
    return map;
  }

  function orderedTestPins(entry: AssignmentEntry, assignment: Assignment): AssignmentPin<AssignmentPinContent>[] {
    const pinsForAssignmentType = AssignmentUtils.pinsByAssignmentType(assignment);
    return AssignmentUtils.pinsOrShuffledPinsForAssignment(assignment, pinsForAssignmentType, entry.userId);
  }

  export function pinIsInFuture(entry: AssignmentEntry, assignment: Assignment, pinId: string): boolean | undefined {
    if (!AssignmentSettings.assignmentIsLinear(assignment.settings)) {
      return undefined;
    }
    const assignmentPins = orderedTestPins(entry, assignment);
    if (!assignmentPins.length) return false;
    const activePinIndex = assignmentPins.findIndex((pin) => pin._id === entry.activePinId);
    const futurePinIndex = assignmentPins.findIndex((pin) => pin._id === pinId);
    return activePinIndex === -1 || activePinIndex < futurePinIndex;
  }

  export function pinIsInThePast(entry: AssignmentEntry, assignment: Assignment, pinId: string): boolean {
    if (!AssignmentSettings.assignmentIsLinear(assignment.settings)) {
      return false;
    }
    const assignmentPins = orderedTestPins(entry, assignment);
    if (!assignmentPins.length) return false;
    const activePinIndex = assignmentPins.findIndex((pin) => pin._id === entry.activePinId);
    const pastPin = assignmentPins.findIndex((pin) => pin._id === pinId);
    return pastPin < activePinIndex;
  }

  export type RaisedHandWitUserData = RaiseHand & {
    user: string;
    displayName: string;
  };
  export function raisedHandsWithName(entries: AssignmentEntry[]): RaisedHandWitUserData[] {
    const list: RaisedHandWitUserData[] = _.compact(
      entries.map((entry) => {
        if (!entry.raiseHand) return;
        return {
          ...entry.raiseHand,
          user: entry.userId,
          displayName: entry.displayName || '',
        };
      })
    );
    return _.sortBy(list, 'timeStamp');
  }

  export function loginCountDuringTest(entry: AssignmentEntry): number {
    return entry?.logins?.length || 0;
  }
}
