import { useLocale } from '@lessonup/client-integration';
import {
  AnchorLink,
  Banner,
  BodyText,
  Button,
  IconCheck,
  IconSettings,
  SpaceBetween,
  spacing,
  styled,
  TextList,
  TextListProps,
} from '@lessonup/ui-components';
import React from 'react';
import { slideDeckImportArticle } from '../../../../../../utils/bridge/siteRoutes';
import { useEditorTranslation } from '../../../../hooks/useEditorTranslation';

export interface SlideDeckImportExplanationProps {
  onEditClick: () => void;
}

export const SlideDeckImportExplanation = ({ onEditClick }: SlideDeckImportExplanationProps) => {
  const { language } = useLocale();
  const { t } = useEditorTranslation();

  const listItems: TextListProps['items'] = [
    {
      text: t(`contextSidebar.panes.slidedeckImport.add`),
    },
    { text: t(`contextSidebar.panes.slidedeckImport.move`) },
    { text: t(`contextSidebar.panes.slidedeckImport.editTexts`) },
    { text: t(`contextSidebar.panes.slidedeckImport.editBackgrounds`) },
    { text: t(`contextSidebar.panes.slidedeckImport.changeLayout`) },
  ];

  return (
    <div>
      <Banner priority="success" paragraph={t(`contextSidebar.panes.slidedeckImport.banner`)} icon={<IconCheck />} />
      <TextWrapper>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <BodyText>{t(`contextSidebar.panes.slidedeckImport.easilyEdit`)}</BodyText>
          <TextList items={listItems} />
          <div>
            <BodyText weight="bold" as="span">
              {t(`contextSidebar.panes.slidedeckImport.note`)}
            </BodyText>{' '}
            <BodyText as="span">
              {t(`contextSidebar.panes.slidedeckImport.stillInBeta`)}{' '}
              <AnchorLink href={slideDeckImportArticle(language)} target="_blank">
                {t('contextSidebar.panes.slidedeckImport.helpCenterArticle')}
              </AnchorLink>
              {'.'}
            </BodyText>
          </div>
          <div>
            <Button
              buttonType="neutral"
              iconStart={<IconSettings />}
              onClick={onEditClick}
              showStroke
              resizing="hug"
              buttonShape="rect"
            >
              {t('contextSidebar.panes.slidedeckImport.editLesson')}
            </Button>
          </div>
        </SpaceBetween>
      </TextWrapper>
    </div>
  );
};

const TextWrapper = styled.div`
  padding: ${spacing.size16};
`;
