import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { uniqueId } from '@lessonup/utils';
import type * as CSS from 'csstype';
import React, { Children, cloneElement, forwardRef, isValidElement, useMemo } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { Resize, StyledShortcut, StyledText, TooltipChildProps, TooltipDirection, TooltipProps } from './tooltip.utils';

export const Tooltip: React.FC<TooltipProps> = forwardRef<HTMLDivElement, TooltipProps>(
  ({ mode = 'hover', className, ...props }, ref) => {
    const randomId = useMemo(() => uniqueId(`tooltip-`), []);
    const id = props.id || randomId;

    const hoverableElements = Children.map(props.children, (child) => {
      if (!isValidElement<TooltipChildProps>(child)) return child;
      return cloneElement(child, {
        ['data-tip']: id,
        id,
      });
    });

    return (
      <StyledContainer ref={ref} className={className} displayCss={props.display} hide={props.hide} mode={mode}>
        {hoverableElements}
        {props.content && (
          <StyledReactTooltip
            data-for={id}
            directionCss={props.direction || 'bottom'}
            role="tooltip"
            resize={props.resize}
            zIndexOverride={props.zIndexOverride}
            mode={mode}
            hide={props.hide}
          >
            <SpaceBetween direction="x" spacing={spacing.size16} alignItems="flex-start">
              {typeof props.content === 'string' ? (
                <StyledText size="small">{props.content}</StyledText>
              ) : (
                props.content
              )}
              {props.shortcut && <StyledShortcut size="small">{props.shortcut}</StyledShortcut>}
            </SpaceBetween>
          </StyledReactTooltip>
        )}
      </StyledContainer>
    );
  }
);

Tooltip.displayName = 'Tooltip';

const StyledContainer = styled.div<{
  displayCss?: CSS.Property.Display;
  hide?: boolean;
  mode?: 'hover' | 'trigger';
}>`
  position: relative;
  display: ${(props) => props.displayCss};

  ${(props) =>
    props.mode === 'trigger'
      ? ``
      : `
          &:hover [role='tooltip'],
          [data-tip]:focus-visible + [role='tooltip'] {
            opacity: 1;
            display: ${props.hide === true ? 'none' : 'block'};
          }
        `}
`;

const StyledReactTooltip = styled.div<{
  directionCss: TooltipDirection;
  resize?: Resize;
  zIndexOverride?: number;
  mode?: 'hover' | 'trigger';
  hide?: boolean;
}>`
  border-radius: ${borderRadius.rounded4};
  width: max-content;
  padding: ${spacing.size8};
  background-color: ${color.highContrast.surface.background};
  position: absolute;
  isolation: isolate;
  max-width: ${(props) => props.resize === 'fixed' && '240px'};
  margin: auto;
  display: ${(props) => (props.mode === 'trigger' && !props.hide ? 'block' : 'none')};
  opacity: ${(props) => (props.mode === 'trigger' ? 1 : 0)};
  z-index: ${(props) => props.zIndexOverride ?? '1000'};

  ${(props) => styleForDirection(props.directionCss)}
`;

function styleForDirection(direction: TooltipDirection): SerializedStyles {
  switch (direction) {
    case 'bottom':
      return css`
        margin-top: ${spacing.size8};
        left: 50%;
        transform: translate(-50%);
      `;
    case 'right':
      return css`
        left: calc(100% + ${spacing.size8});
        top: 50%;
        transform: translate(0, -50%);
      `;
    case 'top':
      return css`
        left: 50%;
        bottom: calc(100% + ${spacing.size8});
        transform: translate(-50%);
      `;
    case 'left':
      return css`
        right: calc(100% + ${spacing.size8});
        top: 50%;
        transform: translate(0, -50%);
      `;
    case 'bottom-left':
      return css`
        right: 0;
        top: calc(100% + ${spacing.size8});
      `;
    case 'bottom-right':
      return css`
        left: 0;
        top: calc(100% + ${spacing.size8});
      `;
    case 'top-left':
      return css`
        right: 0;
        bottom: calc(100% + ${spacing.size8});
      `;
    case 'top-right':
      return css`
        left: 0;
        bottom: calc(100% + ${spacing.size8});
      `;
  }
}
