/* eslint-disable @typescript-eslint/no-explicit-any */ // we disable it here because we need this to match the apollo types
/* eslint-disable-next-line no-restricted-imports */
import { OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from '@apollo/client';
import { CleanedResult } from '../cleanedResultOf';

/**
 * Wrapper around `useQuery` from the `@apollo/client` which will normalize the data returned from the server which is cleaned by the `cleanDataLink`
 * Only other change compared to apollo is that we set the `errorPolicy` to `all` as a default so we always get partial data. This can be overridden.
 */
export function useDocumentQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<CleanedResult<TData>, TVariables>
): QueryResult<CleanedResult<TData>, TVariables> {
  // actual transform of the data is being done in the `cleanDataLink`
  return useQuery(document, {
    errorPolicy: 'all',
    ...(options ?? {}),
  }) as any;
}
