import { WordCloudPinPhases, WordCloudView } from '../../playerStore.types';
import { createOrderHandler } from '../../utils/orderHandler/orderHandler';
import { PlayerPinReducer } from '../reducers.types';
import { nextPhase } from '../reducers.utils';
import { updateAnswersInView } from './wordCloudReducer.utils';

export const wordCloudPinPhaseOrder: WordCloudPinPhases[] = ['input', 'results'];
export type WordCloudReducer = PlayerPinReducer<WordCloudView>;

export const wordCloudReducer: WordCloudReducer = {
  phaseAmount: wordCloudPinPhaseOrder.length,
  getPhaseNumber: (phase) => wordCloudPinPhaseOrder.indexOf(phase) + 1,
  initial: (state, history) => {
    const orderHandler = createOrderHandler();
    const answers = history?.answers ?? updateAnswersInView(state, history, orderHandler);

    return {
      type: 'WORD_CLOUD',
      phase: 'input',
      answers,
      revealedAnswers: history?.revealedAnswers ?? [],
      lastOrder: orderHandler.generateNext(),
    };
  },
  hasNextView: (state, currentView, direction) => {
    return nextPhase(currentView.phase, wordCloudPinPhaseOrder, direction) !== null;
  },
  handleNextView: (state, prev, direction) => {
    if (!state.currentView) {
      return false;
    }

    const next = nextPhase(prev.phase, wordCloudPinPhaseOrder, direction);
    if (next) {
      return {
        ...prev,
        type: 'WORD_CLOUD',
        phase: next,
      };
    }
    return false;
  },
  onEntriesUpdate: (state, currentView) => {
    const orderHandler = createOrderHandler();
    const answers = updateAnswersInView(state, currentView, orderHandler);
    return {
      ...currentView,
      answers,
      lastOrder: orderHandler.getOrder(),
    };
  },
};
