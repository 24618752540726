import { BodyText, color, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export type Position = 'A' | 'B' | 'C' | 'D';

export interface GeneratedAnswerProps {
  correct: boolean;
  position: Position;
  answer: string;
  className?: string;
}

export function GeneratedAnswer(props: GeneratedAnswerProps) {
  return (
    <SpaceBetween direction="x" spacing={spacing.size8} as="li" className={props.className}>
      <StyledIndicator size="small" weight="bold" correct={props.correct}>
        {props.position}
      </StyledIndicator>
      <StyledAnswer size="small" correct={props.correct}>
        {props.answer}
      </StyledAnswer>
    </SpaceBetween>
  );
}

const StyledIndicator = styled(BodyText)<Pick<GeneratedAnswerProps, 'correct'>>`
  width: ${rem('16px')};
  height: ${rem('16px')};
  border-radius: 100%;
  text-align: center;
  flex-shrink: 0;
  color: ${(props) => (props.correct ? color.additional.successContainer.text : color.additional.disabled.text)};
  background-color: ${(props) =>
    props.correct ? color.additional.successContainer.background : color.additional.disabled.background};
`;

const StyledAnswer = styled(BodyText)<Pick<GeneratedAnswerProps, 'correct'>>`
  color: ${(props) => (props.correct ? color.additional.success.background : color.neutral.surface.text)};
  flex: 1;
`;
