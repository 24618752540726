import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.858 16.5c1.804-1.478 2.784-2.43 4.114-4.03a.74.74 0 0 0 0-.94c-1.33-1.6-2.31-2.552-4.114-4.03"
    />
  </svg>
);
export default SvgIconChevronRight;
