import {
  Button,
  color,
  elevationShadow,
  IndicatorSelected,
  spacing,
  styled,
  TextElement,
} from '@lessonup/ui-components';
import React from 'react';
import { CustomSelectScrollIntoViewDropDownApi } from './useCustomSelectScrollIntoView';

export type StyleDropdownOption<T> = (
  option: { label: string; value: T } | undefined
) => React.CSSProperties | undefined;

export function DropDown<T>(
  customScroll: CustomSelectScrollIntoViewDropDownApi,
  options: { label: string; value: T }[],
  handlePickOption: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: T) => void,
  currentVal?: T,
  styleOption?: StyleDropdownOption<T>
): React.ReactNode {
  return (
    <StyledDropdown ref={customScroll.setDropdownRef}>
      {options.map((option) => {
        const isCurrentOption = currentVal === option.value;
        const button = (
          <StyledButton
            ref={isCurrentOption ? customScroll.setInitialOptionRef : undefined}
            buttonType="neutral"
            buttonShape="rect"
            resizing="fixed"
            onMouseDown={(event) => handlePickOption(event, option.value)}
            // for keyboard navigation
            onClick={(event) => handlePickOption(event, option.value)}
          >
            <TextElement style={styleOption?.(option)} textStyle="label">
              {option.label}
            </TextElement>
          </StyledButton>
        );

        return isCurrentOption ? (
          <SelectedRow key={`option-${option.value}`}>
            <IndicatorSelected type="line" verticalOffset={spacing.size4} />
            {button}
          </SelectedRow>
        ) : (
          <UnSelectedRow key={`option-${option.value}`}>{button}</UnSelectedRow>
        );
      })}
    </StyledDropdown>
  );
}

const SelectedRow = styled.div`
  position: relative;
  padding-left: ${spacing.size12};
  width: 100%;
  align-items: center;
  background-color: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
`;

const UnSelectedRow = styled.div`
  position: relative;
  width: 100%;
  padding-left: ${spacing.size12};
  align-items: center;
`;

const StyledDropdown = styled.div`
  position: absolute;
  width: fit-content;
  top: 100%;
  left: 0;
  margin-top: ${spacing.size8};
  padding: ${spacing.size8};
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  z-index: 1;
  gap: ${spacing.size8};
  background-color: ${color.neutral.surface.background};
  border: 1px solid ${color.neutral.outline.background};
  box-shadow: ${elevationShadow.e300};
`;

const StyledButton = styled(Button)`
  justify-content: start;
  white-space: nowrap;
  min-height: 24px;
  padding: 0 ${spacing.size8} 0 0;
  :not(:hover) {
    background: none !important;
  }

  & > span {
    width: 100%;
    justify-content: start;
    padding: 0 ${spacing.size8} 0 0;
  }

  & div {
    overflow: visible;
  }
`;
