import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { LiHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { BodyText } from '../../BodyText/BodyText';

interface TopBarItemProps extends LiHTMLAttributes<HTMLLIElement> {
  active?: boolean;
}

export function TopBarItem({ children, className, ...props }: PropsWithChildren<TopBarItemProps>) {
  return (
    <ListItem className={cx(className)} {...props}>
      {children}
    </ListItem>
  );
}

interface TopBarItemListProps {
  children?: ReactNode;
}

export function TopBarItemList(props: TopBarItemListProps) {
  return (
    <BodyText size="medium" weight="bold">
      <List className={topBarItemListClassName}>{props.children}</List>
    </BodyText>
  );
}

export const topBarItemListClassName = 'TopBarItemList';

const ListItem = styled.li<TopBarItemProps>`
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  white-space: nowrap;
  /* height: '36px'; */
  border-radius: ${borderRadius.rounded4};

  /* Ensures the entire child is clickable.  */
  & > * {
    display: block;
    line-height: 36px;
    padding: 0 ${spacing.size16};
    border: 2px solid transparent;
    border-radius: ${borderRadius.rounded4};
  }
  *:focus-visible {
    outline: none;
    border: 2px solid ${color.additional.focus.background};
  }

  a {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
  ${(props) => props.active && activeCss}
`;

const activeCss = css`
  background-color: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
`;

const List = styled.ul`
  display: flex;
  margin: 0; // reset
  padding: 0; // reset
`;
