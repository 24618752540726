import { AppError, AppErrorCode, LogDetails } from '@lessonup/utils';
import { clearCookie, getCookieValueByName, setCookie } from '../cookies/cookieUtils';
import { logger } from '../logger/logger';

export class Debugger {
  public static translationModeCookieName = 'translationModeEnabled';
  private static panelEnabledCookieName = 'debugPanelEnabled';
  private static panelId = 'debugger-panel';

  constructor(public readonly versionTagName: string) {
    (window as Window & typeof globalThis & { debug: Debugger }).debug = this;
    if (getCookieValueByName(Debugger.panelEnabledCookieName) === 'true') {
      this.showPanel();
    }
  }

  public throwError(message = 'This is an error from the debugger') {
    throw new Error(message);
  }

  public throwAppError(code: AppErrorCode, message = 'This is an app error from the debugger', details: LogDetails) {
    throw new AppError(code, message, details);
  }

  public log(level: 'debug' | 'info' | 'warn' | 'error', message: string, details: LogDetails) {
    logger[level](message, details);
  }

  public static isTranslationModeEnabled(): boolean {
    return getCookieValueByName(this.translationModeCookieName) === 'true';
  }

  public enableTranslationMode() {
    setCookie({ name: Debugger.translationModeCookieName, value: 'true' });
    location.reload();
  }

  public disableTranslationMode() {
    clearCookie(Debugger.translationModeCookieName);
    location.reload();
  }

  public hidePanel() {
    clearCookie(Debugger.panelEnabledCookieName);
    document.querySelector(`#${Debugger.panelId}`)?.remove();
  }

  public showPanel() {
    setCookie({ name: Debugger.panelEnabledCookieName, value: 'true' });
    const panel = document.createElement('div');
    panel.setAttribute('id', 'debugger-panel');
    setStyle(panel, {
      position: 'fixed',
      bottom: '0',
      left: '0',
      zIndex: '9999999999',
      background: 'yellow',
      border: '3px dashed black',
      padding: '1rem',
    });

    panel.appendChild(
      createButton({
        text: 'Throw error',
        style: { background: 'red' },
        onClick: () => this.throwError('This is an error from the error debugging button'),
      })
    );
    panel.appendChild(
      createButton({
        text: Debugger.isTranslationModeEnabled() ? '❌ Disable translation mode' : '✅ Enable translation mode',
        onClick: () => {
          Debugger.isTranslationModeEnabled() ? this.disableTranslationMode() : this.enableTranslationMode();
        },
      })
    );
    panel.appendChild(
      createButton({
        text: 'Close panel',
        onClick: () => this.hidePanel(),
      })
    );
    document.body.appendChild(panel);
  }
}

const createButton = ({
  text,
  style,
  onClick,
}: {
  text: string;
  style?: Partial<CSSStyleDeclaration>;
  onClick: () => void;
}): HTMLButtonElement => {
  const button = document.createElement('button');
  button.textContent = text;
  button.type = 'button';

  setStyle(button, {
    padding: '0.5rem',
    margin: '0.5rem',
    borderRadius: '4px',
    background: 'lightgrey',
    ...style,
  });

  button.addEventListener('click', onClick);
  return button;
};

const setStyle = (element: HTMLElement, style: Partial<CSSStyleDeclaration>) => {
  for (const styleKey in style) {
    const styleValue = style[styleKey];
    if (!Object.prototype.hasOwnProperty.call(style, styleKey) || !styleValue) continue;
    element.style[styleKey] = styleValue;
  }
};
