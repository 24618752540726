/* eslint-disable @typescript-eslint/no-explicit-any */ // we disable it here because we need this to match the apollo types
import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  /* eslint-disable-next-line no-restricted-imports */
  useMutation,
} from '@apollo/client';
import { CleanedResult } from '../cleanedResultOf';

/**
 * Wrapper around `useMutation` from the `@apollo/client` which will normalize the data returned from the server which is cleaned by the `cleanDataLink`
 */
export function useDocumentMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<CleanedResult<TData>, TVariables, TContext>
): MutationTuple<CleanedResult<TData>, TVariables, TContext, TCache> {
  // actual transform of the data is being done in the `cleanDataLink`
  return useMutation(mutation, options as any) as any;
}
