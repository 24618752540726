import { capitalizeFirstCharacter } from '@lessonup/utils';
import _, { escapeRegExp, trim } from 'lodash';
import { Lesson } from '../../lesson';
import { EduSystem } from '../eduSystems';
import { anySuggestor } from './eduSystemShared';
declare const i18n, Lessons;

// aliasses for easy reference
type EditData = EduSystem.EditData;

type EditDataId = EduSystem.EditDataId;

type SystemDataField = EduSystem.SystemDataField;

type EditDataIdWithCustom = EduSystem.EditDataIdWithReference;

type SystemData<S extends string> = EduSystem.SystemData<S>;

type SystemDataDict<S extends string> = EduSystem.SystemDataDict<S>;

type YearDict = EduSystem.YearDict;

export abstract class EduSystemBase<SchoolType extends string> {
  public abstract key: string;
  public abstract country(): string;
  /**
   * List of the edit data fields, use the constructors in eduSystemshared
   * If you have multiple implementations of the same thing (eg levels/years), use reference to
   * safely group stuff
   */
  public abstract EDIT_DATA(): EditData[];
  protected abstract _SYSTEM_DATA(): SystemDataDict<SchoolType>;
  protected defaultSchoolType: SchoolType;
  protected readonly SYSTEM_DATA_DICT: SystemDataDict<SchoolType>;
  protected readonly SYSTEM_DATA: SystemData<SchoolType>[];

  public constructor(defaultSchoolType: SchoolType) {
    this.defaultSchoolType = defaultSchoolType;
    this.SYSTEM_DATA_DICT = this._SYSTEM_DATA();
    const country = this.country();
    _.forEach(this.SYSTEM_DATA_DICT, (system) => {
      const index = system.fields.findIndex((f) => f.id === 'subject');
      // eslint-disable-next-line no-param-reassign
      system.fields[index] = subjectFieldGenerator(country, system.id);
    });
    this.SYSTEM_DATA = Object.values(this.SYSTEM_DATA_DICT);
  }

  public getSystemDataField({
    schoolType,
    fieldName,
  }: {
    schoolType: string | undefined;
    fieldName: EduSystem.EditDataId;
  }): SystemDataField | undefined {
    const type = schoolType || this.defaultSchoolType;
    return this.getSystemDataFieldTypeSafe(type as SchoolType, fieldName);
  }

  protected getSystemDataFieldTypeSafe(
    schoolType: SchoolType,
    field: EduSystem.EditDataId
  ): SystemDataField | undefined {
    return this.SYSTEM_DATA_DICT[schoolType]?.fields.find((f) => f.lessonProp === field);
  }

  protected systemDataFields(schoolTypes: SchoolType[], field: EduSystem.EditDataId): SystemDataField[] {
    return _.compact(schoolTypes.map((st) => this.getSystemDataFieldTypeSafe(st, field)));
  }

  protected uniqSystemDataFieldOptions(
    schoolTypes: SchoolType[],
    field: EduSystem.EditDataId
  ): EduSystem.FieldOption[] {
    const fields = this.systemDataFields(schoolTypes, field);
    const flattened = _.flatMap(fields, (sf) => sf.options || []);
    return _.sortBy(_.uniqBy(flattened, 'id'), 'id');
  }

  protected schoolSystem<T extends SchoolType>(schoolType: T | string): SystemData<T> | undefined {
    return this.SYSTEM_DATA_DICT[schoolType as T];
  }

  protected optionsForEditDataAndSchoolType(
    schoolTypes: SchoolType[],
    editData: EduSystem.EditData
  ): EduSystem.FieldOption[] {
    const key = editData.id;
    const reference = editData.reference;
    if (reference) {
      return this.uniqSystemDataFieldOptionsByReference(schoolTypes, reference);
    } else {
      return this.uniqSystemDataFieldOptions(schoolTypes, key);
    }
  }

  protected uniqSystemDataFieldOptionsByReference(
    schoolTypes: SchoolType[],
    field: EduSystem.EditDataIdWithReference
  ): EduSystem.FieldOption[] {
    const fields = this.systemDataFieldsByReference(schoolTypes, field);
    const flattened = _.flatMap(fields, (sf) => sf.options || []);
    const uniqIdValues = _.uniqBy(flattened, 'id');

    return fields.length === 1 && fields[0].optionOrderIsSet ? uniqIdValues : _.sortBy(uniqIdValues, 'id');
  }

  protected systemDataFieldsByReference(
    schoolTypes: SchoolType[],
    field: EduSystem.EditDataIdWithReference
  ): SystemDataField[] {
    return _.compact(schoolTypes.map((st) => this.systemDataFieldByRefence(st, field)));
  }

  protected systemDataFieldByRefence(
    schoolType: SchoolType,
    field: EduSystem.EditDataIdWithReference
  ): SystemDataField | undefined {
    return this.SYSTEM_DATA_DICT[schoolType]?.fields.find((f) => f.reference === field);
  }

  public editDataByReference(reference: EditDataIdWithCustom): EduSystem.EditData | undefined {
    return this.EDIT_DATA().find((e) => e.reference === reference);
  }

  public editDatasByType(type: EditDataId): EduSystem.EditData[] {
    return this.EDIT_DATA().filter((e) => e.id === type);
  }

  protected getSchoolTypeTS(schoolType?: SchoolType, lesson?: EduSystem.MetaDataParams): SchoolType {
    const lessonSchoolType = lesson?.schoolType?.[0] as SchoolType | undefined;
    return schoolType || lessonSchoolType || this.defaultSchoolType;
  }

  protected getSchoolTypesTS(schoolType?: SchoolType, lesson?: EduSystem.MetaDataParams): SchoolType[] {
    const paramsSchoolType = schoolType ? [schoolType] : undefined;
    const lessonSchoolType = lesson?.schoolType as SchoolType[] | undefined;
    return paramsSchoolType || lessonSchoolType || [this.defaultSchoolType];
  }

  protected sortById(options: EduSystem.FieldOption[]): EduSystem.FieldOption[] {
    return _.sortBy(options, 'id');
  }

  /**
   * We should depricate this overloaded monster, but too much of the teacher still depends on this
   * @deprecated use systemData() | schoolSystem() | getSystemDataField()
   */
  public getSystemData(params: { schoolType?: undefined; fieldName?: undefined }): SystemData<SchoolType>[];
  public getSystemData(params: { schoolType: string; fieldName?: undefined }): SystemData<SchoolType> | undefined;
  public getSystemData(params: { schoolType: string; fieldName: EditDataId }): SystemDataField | undefined;
  public getSystemData({
    schoolType,
    fieldName,
  }: {
    schoolType?: string;
    fieldName?: EditDataId;
  }): SystemData<SchoolType>[] | SystemData<SchoolType> | SystemDataField | undefined {
    if (!schoolType && !fieldName) return this.systemData();
    if (schoolType) {
      const system = this.schoolSystem(schoolType);
      if (system) {
        if (!fieldName) return system;
        return this.getSystemDataField({ schoolType, fieldName });
      }
    }
  }

  public systemData(): SystemData<SchoolType>[] {
    return this.SYSTEM_DATA;
  }

  public getSchoolTypes(): {
    id: string;
    short: string;
    label: string;
    prefix: string | undefined;
    value: string;
  }[] {
    return this.SYSTEM_DATA.map((system) => {
      const { id, short, label, prefix } = system;
      return { id, short, label, prefix, value: id };
    });
  }

  // these are the logical suggestions presented, with some optional specific odd ones
  public getFieldSuggestions({
    lesson,
    schoolType,
    fieldName,
  }: EduSystem.FieldSuggestionParams<SchoolType>): EduSystem.FieldOption[] | undefined {
    const schoolTypes = this.getSchoolTypesTS(schoolType, lesson);
    const editData = this.editDataByReference(fieldName);
    if (!editData) return;
    const specificSuggestion = this.specificSuggestion({
      lesson,
      schoolType,
      fieldName,
    });
    if (specificSuggestion) return specificSuggestion;
    switch (fieldName) {
      case 'durationInMin':
        return [
          { id: '15', value: i18n.__('15 min') },
          { id: '30', value: i18n.__('30 min') },
          { id: '45', value: i18n.__('45 min') },
          { id: '50', value: i18n.__('50 min') },
          { id: '60', value: i18n.__('1 uur') },
          { id: '120', value: i18n.__('2 uur') },
        ];
      case 'schoolType':
        return this.SYSTEM_DATA.map((system) => {
          return { id: system.id, value: system.label, short: system.short };
        });
      default:
        return this.optionsForEditDataAndSchoolType(schoolTypes, editData);
    }
  }

  /**
   * override
   */
  protected specificSuggestion({
    lesson,
    schoolType,
    fieldName,
  }: EduSystem.FieldSuggestionParams<SchoolType>): EduSystem.FieldOption[] | undefined {
    return;
  }

  /**
   * when a system is removed manually, only allow valid values
   */
  public async cleanUnusedFields(lesson: Lesson) {
    if (!lesson) return false;
    // prepare for full cleanup of years and lessons
    const { years, levels, shouldUpdate } = this.getAllowedLevelsAndYears(lesson);
    if (shouldUpdate) {
      await Lessons.updateAsync({ _id: lesson._id }, { $set: { levels, years } });
    }
  }

  public fieldLabel(
    schoolType: SchoolType | string,
    fieldName: EditDataId,
    value: number | string
  ): string | undefined {
    const system = this.getSystemData({ schoolType, fieldName });

    if (!system || !system.options) return;
    const option = system.options.find((o) => o.id.toString() === value.toString());
    if (!option) {
      return;
    }

    const label = typeof system.label === 'function' ? system.label() : system.label;

    return system.labeler === 'LabelValue'
      ? `${label} ${option.value}`
      : system.labeler === 'ValueLabel'
      ? `${option.value} ${label}`
      : option.value;
  }

  /**
   * Allowed list of levels and years on lesson
   * split up from cleanUnusedFields for testing purposes
   */
  public getAllowedLevelsAndYears(lesson: Lesson): { years: number[]; levels: string[]; shouldUpdate: boolean } {
    const yearsDict: Record<string, number> = {};
    const levelsDict: Record<string, number> = {};
    const currentYears = lesson.years || [];
    const currentLevels = lesson.levels || [];

    // if no schoolType is present, clean all
    if (lesson.schoolType && lesson.schoolType.length) {
      const schoolTypes = this.getSchoolTypesTS(undefined, lesson);
      if (currentYears.length) {
        const yearFields = this.editDatasByType('years');
        yearFields.forEach((editData) => {
          const options = this.optionsForEditDataAndSchoolType(schoolTypes, editData).map((sug) => sug.id);
          _.intersection(options, currentYears).forEach((year) => {
            yearsDict[year] = 1;
          });
        });
      }

      if (currentLevels.length) {
        const levelFields = this.editDatasByType('levels');
        levelFields.forEach((editData) => {
          const options = this.optionsForEditDataAndSchoolType(schoolTypes, editData).map((sug) => sug.id);
          _.intersection(options, currentLevels).forEach((level) => {
            levelsDict[level] = 1;
          });
        });
      }
    }
    const years = Object.keys(yearsDict).map((key) => parseInt(key));
    const levels = Object.keys(levelsDict);
    const shouldUpdate = !!(_.difference(currentYears, years).length || _.difference(currentLevels, levels).length);
    return { years, levels, shouldUpdate };
  }

  /**
   * When to show a field in the editor
   */
  public showField({ lesson, fieldName }: { lesson: Lesson; fieldName: EditDataIdWithCustom }): boolean {
    const editData = this.editDataByReference(fieldName);
    if (!editData) return false;
    const always: EditDataIdWithCustom[] = ['durationInMin', 'schoolType', 'subjects'];
    const optionalFieldWithoutOptions: EditDataIdWithCustom[] = ['themes'];
    if (always.includes(fieldName)) return true;
    const schoolTypes = this.getSchoolTypesTS(undefined, lesson);
    const options = this.optionsForEditDataAndSchoolType(schoolTypes, editData);

    return optionalFieldWithoutOptions.includes(fieldName)
      ? !!schoolTypes.find((schoolType) => schoolType === 'vso')
      : !!options?.length;
  }

  /**
   * labels for search results
   */
  public abstract labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags;

  // gets the summary of the given field name, which may result in a summary
  public getFieldSummary({
    lesson,
    schoolType,
    fieldName,
    maxLength,
    excludeUnits,
    defaultValue,
  }: EduSystem.FieldSummaryParams<SchoolType>): string {
    const schoolTypes = this.getSchoolTypesTS(schoolType, lesson);
    const editData = this.editDataByReference(fieldName);
    if (!editData) return '';
    const key = editData.id;
    const value = lesson?.[key];
    if (!value || value === undefined || (Array.isArray(value) && !value.length)) {
      return defaultValue ? i18n.__(defaultValue) : '';
    }
    let options = this.optionsForEditDataAndSchoolType(schoolTypes, editData);
    if (fieldName === 'schoolType') {
      options = this.getSchoolTypes();
    }
    let str = !editData.suggestor
      ? anySuggestor(value)
      : editData.suggestor({
          value,
          fieldOptions: options,
          excludeUnits,
          maxLength,
          defaultValue: '',
        });
    if (str === '' && defaultValue) str = i18n.__(defaultValue);

    // cut off at maxLength
    if (maxLength && str.length > maxLength) str = str.substr(0, maxLength) + '&hellip;';
    return str;
  }

  /**
   * should override if needs to be more specific (for curricula)
   */
  public getYearDict(): YearDict {
    const yearDict: YearDict = {};
    for (let age = 6; age < 24; age++) {
      yearDict[age] = {
        label: 'Age ' + age,
      };
    }
    return yearDict;
  }

  public getEditData() {
    return this.EDIT_DATA();
  }
}

declare const Meteor, Subjects;

function subjectFieldGenerator(country: string, schoolType: string): EduSystem.SystemDataField {
  return {
    id: 'subject',
    lessonProp: 'subjects',
    type: 'inputSelect',
    label: () => i18n.__('Vak'),
    placeholder: () => i18n.__('Kies je vak'),
    schoolType,
    subscribe() {
      Meteor.subscribe('subjects', { country, system: schoolType });
    },
    query(q: string) {
      const query = { system: schoolType, country, name: new RegExp('.*' + escapeRegExp(q) + '.*', 'i') };
      return Subjects.find(query, { fields: { _id: 1, name: 1 }, sort: { name: 1 } });
    },
    async save({ target, field, value, multiple, shortList }) {
      if (!target?._id || !value) return;

      const subjectsToInsert = Array.isArray(value)
        ? value.map((subject) => cleanSubject(subject))
        : cleanSubject(value);

      // find a case-insensitive version of the specified subject and select that one
      const existing = await Subjects.findOneAsync({
        name: new RegExp('^' + escapedSubjects(subjectsToInsert) + '$', 'i'),
      });
      const subject = existing?.name || subjectsToInsert;

      // when saved in a multi-select editor, add to set
      if (multiple) {
        await Lessons.updateAsync({ _id: target._id }, { $addToSet: { subjects: subject } });
      } else {
        // otherwise save only one
        await Lessons.updateAsync({ _id: target._id }, { $set: { subjects: [subject] } });
      }

      // if this subject is new to us, send to server to check if really new
      if (!existing && subject) {
        const params = {
          name: subject,
          country,
          system: schoolType,
        };
        Meteor.call('addSubjectIfNew', params, function (error, result) {
          if (error) {
            throw error;
          }
        });
      }
    },
  };
}

function escapedSubjects(input: string | string[]): string | string[] {
  if (!Array.isArray(input)) {
    return escapeRegExp(input);
  }
  return input.map((value) => {
    return escapeRegExp(value);
  });
}

export function cleanSubject(subject: string): string {
  return capitalizeFirstCharacter(trim(subject));
}
