import {
  isInteractiveQuestionPinTextPinComponent,
  TextPinComponent,
  useTextEditorState,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import {
  color,
  IconFormatBold,
  IconFormatItalic,
  IconFormatStrikethrough,
  IconLinkAdd,
  spacing,
  styled,
  Tag,
} from '@lessonup/ui-components';
import React from 'react';
import { useEditMode, useNonNullActivePin } from '../../../../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../../../../hooks/useEditorTranslation';
import { useFontSizePickerHelper } from '../../../../../../../utils/pinComponentSettings/textPinComponent';
import { teacherTextEditorStore } from '../../../../../../../utils/yjs/EditorYjs/teacherTextEditorStore';
import { SteppedNumberInput } from '../../../../../../EditorContextSidebar/components/CustomSelect/SteppedNumberInput';
import { SettingsToggle } from '../../../../../../SettingsToggle/SettingsToggle';
import { ColorPickerSubmenu } from '../../../ColorPickerSubmenu';
import { PinEditorFAMSubmenu } from '../../../PinEditorFAMSubmenu';
import { PinComponentSettingsProps } from '../../types';
import { LinkSubmenu } from './components/LinkSubmenu';

export const TextPinComponentSettings: React.FC<PinComponentSettingsProps<TextPinComponent>> = (props) => {
  const pin = useNonNullActivePin();
  const { t } = useEditorTranslation();
  const dispatch = useUpdatePin();
  const editMode = useEditMode();

  const { textHandler: textEditorHandler } = useTextEditorState(teacherTextEditorStore);

  const isInteractiveQuestion =
    props.pinComponent !== null ? isInteractiveQuestionPinTextPinComponent(pin, props.pinComponent) : false;

  const isTextEditMode = editMode.type === 'textEdit';
  const fontSizePicker = useFontSizePickerHelper(textEditorHandler, props.pinComponent, dispatch, isTextEditMode);

  const shouldUpdateInline = () => textEditorHandler.updateShouldTargetChild(isTextEditMode);

  const fontColorCursorValue = textEditorHandler.color.get('');
  const fontColorValue =
    (isTextEditMode
      ? fontColorCursorValue
        ? fontColorCursorValue
        : props.pinComponent.settings.fontColor
      : props.pinComponent.settings.fontColor) ?? '#001343';

  const handleFontColorChange = (color: string) => {
    if (shouldUpdateInline()) {
      textEditorHandler.color.set(color);
    } else {
      dispatch({
        type: 'updatePinComponentSettings',
        pinComponentId: props.pinComponent.id,
        settings: {
          fontColor: color,
        },
        debounce: 500,
      });
      textEditorHandler.color.unsetAll();
    }
  };

  return (
    <>
      {isInteractiveQuestion && (
        <StyledTag size="small" priority="high">
          {t('floatingActionMenu.tags.question')}
        </StyledTag>
      )}

      <SteppedNumberInput {...fontSizePicker} data-testid="fam-text-font-size" />
      <ColorPickerSubmenu
        ariaLabel={t('floatingActionMenu.settings.textColorAriaLabel')}
        selectedColor={fontColorValue}
        onChange={handleFontColorChange}
      />
      <SettingsToggle
        type="checkbox"
        toggleVariant="icon-only"
        ariaLabel={t('floatingActionMenu.boldText', { enabled: textEditorHandler.bold.get() })}
        icon={<IconFormatBold />}
        checked={textEditorHandler.bold.get()}
        onChange={() => {
          textEditorHandler.bold.toggle();
        }}
      />
      <SettingsToggle
        type="checkbox"
        toggleVariant="icon-only"
        ariaLabel={t('floatingActionMenu.italicText', { enabled: textEditorHandler.italic.get() })}
        icon={<IconFormatItalic />}
        checked={textEditorHandler.italic.get()}
        onChange={() => {
          textEditorHandler.italic.toggle();
        }}
      />
      <SettingsToggle
        type="checkbox"
        toggleVariant="icon-only"
        ariaLabel={t('floatingActionMenu.strikeText', { enabled: textEditorHandler.strikethrough.get() })}
        icon={<IconFormatStrikethrough />}
        checked={textEditorHandler.strikethrough.get()}
        onChange={textEditorHandler.strikethrough.toggle}
      />
      <PinEditorFAMSubmenu
        iconStart={<IconLinkAdd />}
        aria-label={t('floatingActionMenu.hyperlink')}
        modalConfig={{
          contentLabel: `${t('floatingActionMenu.hyperlink')} submenu`,
          modalBody: (onClose) => <LinkSubmenu onClose={onClose} />,
          disableKeybindsWhileOpen: true,
        }}
      />
    </>
  );
};

const StyledTag = styled(Tag)`
  position: absolute;
  top: -${spacing.size24};
  left: ${spacing.size4};
  border: ${color.accent.primary.hover} solid 1px;
`;
