import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCloudUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.715 8.817c1.784 0 3.379 1.176 3.882 2.25m7.737-.861a.35.35 0 0 1-.25-.284 5.43 5.43 0 0 0-1.556-3.32A5.63 5.63 0 0 0 13.19 4.98a5.68 5.68 0 0 0-3.638.794 5.5 5.5 0 0 0-2.32 2.859.33.33 0 0 1-.302.18 4.7 4.7 0 0 0-1.738.46 4.6 4.6 0 0 0-1.427 1.077 4.47 4.47 0 0 0-1.13 3.28 4.5 4.5 0 0 0 1.567 3.102c3.464 2.969 12.37 3.196 15.864.117a3.812 3.812 0 0 0 .59-5.104 3.95 3.95 0 0 0-2.322-1.54"
    />
  </svg>
);
export default SvgIconCloudUpload;
