import { color, styled } from '@lessonup/ui-components';
import React from 'react';

export interface FullPageFlowProps {
  children: React.ReactNode;
}

export const FullPageFlow: React.FC<FullPageFlowProps> = ({ children }) => {
  return <MainPage>{children}</MainPage>;
};

const MainPage = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${color.neutral.surface.background};
`;
