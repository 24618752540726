import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.912 19a7.048 7.048 0 0 1 1.134-14A7.05 7.05 0 0 1 19 10.907m-4.7 7.816A7 7 0 0 0 15.814 18m2.909-3.7a7 7 0 0 1-.727 1.52"
    />
  </svg>
);
export default SvgIconLoader;
