import { BodyText, Introduction, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodyAsideLayout } from '../../../../../components/FullPageFlow/FullPageBodyAsideLayout';
import {
  FullPageBodySection,
  FullPageBodySectionTransparent,
} from '../../../../../components/FullPageFlow/FullPageBodySection';
import { FullPageBodyAside } from '../../../../../components/FullPageFlow/FullPageFlowAside';
import { GeneratedResultProps } from '../../../../../components/GeneratedResult/GeneratedResult';
import { LessonOutline } from '../ReviewStep/LessonOutline';
import { ReviewStep } from '../ReviewStep/ReviewStep';
import { createLessonOutline } from '../ReviewStep/reviewStepUtils';

export type GeneratedResultItem = Omit<GeneratedResultProps, 'selected'>;

export interface LessonReviewStepProps {
  generatedResults: GeneratedResultItem[];
  selection: string[];
  setSelection: React.Dispatch<React.SetStateAction<string[]>>;
  lessonName?: string;
  optOut?: boolean;
  showLearningPhases?: boolean;
  isCompleted?: boolean;
}

export const LessonReviewStep = ({
  generatedResults,
  selection,
  setSelection,
  optOut,
  lessonName,
  showLearningPhases,
  isCompleted,
}: LessonReviewStepProps) => {
  const { t } = useTranslation('chatGPT');

  const lessonOutline = lessonName ? createLessonOutline({ selection, generatedResults, t }) : undefined;

  return (
    <>
      <FullPageBodySectionTransparent>
        <Introduction
          alignment="center"
          size="M"
          headline={t('reviewSlides.title')}
          bodyText={t('reviewSlides.subTitle')}
        />
      </FullPageBodySectionTransparent>
      <FullPageBodyAsideLayout
        mainLabel={t('reviewSlides.reviewMainLabel')}
        asideLabel={t('reviewSlides.lessonOverview')}
      >
        <StyledMainContentSection>
          <StyledBodyText weight="bold">{t('reviewSlides.reviewMainLabel')}</StyledBodyText>
          <ReviewStep
            generatedResults={generatedResults}
            selection={selection}
            setSelection={setSelection}
            optOut={optOut}
            showLearningPhases={showLearningPhases}
            isCompleted={isCompleted}
          />
        </StyledMainContentSection>

        <FullPageBodyAside>
          <BodyText weight="bold">{t('reviewSlides.lessonOverview')}</BodyText>
          {lessonOutline && <LessonOutline lessonOutline={lessonOutline} />}
        </FullPageBodyAside>
      </FullPageBodyAsideLayout>
    </>
  );
};

const StyledBodyText = styled(BodyText)`
  align-self: start;
`;

const StyledMainContentSection = styled(FullPageBodySection)`
  align-items: stretch;
`;
