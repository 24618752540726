import { useUpdatePin } from '@lessonup/pin-renderer';
import {
  color,
  css,
  IconAdd,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  rem,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React, { useCallback, useRef } from 'react';
import { SetActivePinId } from '../../../context/EditorContext/EditorContext.types';
import {
  isLastPhase,
  isLearningPhaseOrder,
  LearningPhaseOrNoPhaseOrder,
} from '../../../utils/learningPhases/LearningPhase';
import { TempAddPinModalPopover } from '../../EditorModals/AddPinModal/AddPinModal';

export interface PinRailAddProps {
  phaseNumber: LearningPhaseOrNoPhaseOrder;
  lastPinId: string;
  className?: string;
  setActivePinId: SetActivePinId;
}

export const PinRailAdd: React.FC<PinRailAddProps> = ({ phaseNumber, className, lastPinId, setActivePinId }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useUpdatePin();
  const modal = useModal(TempAddPinModalPopover);
  const direction: ModalPopoverDirection = 'right';
  const modalAnchor: ModalPopoverAnchor = 'center';

  const parentSelector = useCallback(() => buttonRef.current, []);

  const onClick = useCallback(
    () =>
      modal.show({
        direction,
        modalAnchor,
        parentSelector,
        dispatch,
        setActivePinId,
        addBehindPinOrPhaseNumber: isLearningPhaseOrder(phaseNumber) ? phaseNumber : lastPinId,
      }),
    [dispatch, modal, parentSelector, phaseNumber, setActivePinId, lastPinId]
  );

  return (
    <StyledButton
      isLastPhase={phaseNumber === 'g00' || isLastPhase(phaseNumber)}
      ref={buttonRef}
      onClick={onClick}
      className={className}
    >
      <IconAdd />
    </StyledButton>
  );
};
const StyledButton = styled.button<{ isLastPhase: boolean }>`
  width: ${rem('132px')};
  height: ${rem('24px')};
  margin-left: ${rem('40px')};
  background-color: ${color.neutral.surface.background};
  border: 1px dashed ${color.neutral.outline.background};
  border-radius: ${spacing.size4};
  margin-top: ${rem('8px')};
  ${(props) =>
    !props.isLastPhase &&
    css`
      margin-bottom: ${spacing.size8};
    `}
  color: ${color.additional.disabled.text};

  svg {
    width: ${spacing.size16};
  }
`;
