import { ManagedModal, NiceModal, NiceModalHocProps } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddWhitelistedEmailModalContent,
  AddWhitelistedEmailModalContentProps,
} from './content/AddWhitelistedEmailModalContent';

type AddWhitelistedEmailModalProps = Pick<AddWhitelistedEmailModalContentProps, 'onSubmit'>;

export const AddWhitelistedEmailModal: React.FC<AddWhitelistedEmailModalProps & NiceModalHocProps> = NiceModal.create(
  ({ onSubmit: onSubmitProp }: AddWhitelistedEmailModalProps) => {
    const modal = NiceModal.useModal();
    const { t } = useTranslation('addWhitelistedEmailModal');

    const onSubmit = async (email: string) => {
      const success = await onSubmitProp(email);
      if (!success) return false;
      modal.resolve(true);
      modal.hide();
      return true;
    };

    const onCancel = () => {
      modal.resolve(false);
      modal.hide();
    };
    return (
      <ManagedModal modal={modal} contentLabel={t(`modalLabel`)}>
        <AddWhitelistedEmailModalContent onSubmit={onSubmit} onCancel={onCancel} />
      </ManagedModal>
    );
  }
);
