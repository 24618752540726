import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconComponentAudio = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.57 9.929c.504.457.858 1.162.858 2.071s-.354 1.614-.857 2.071m2.506-8.344c1.546 1.37 2.638 3.505 2.638 6.273s-1.092 4.902-2.638 6.273M1.537 9.354c-.144.83-.252 1.754-.252 2.646s.108 1.815.253 2.646c.278 1.594 1.481 2.924 3.084 3.152.396.057.857.105 1.401.14 1.296.084 2.467.809 3.165 1.904.274.429.563.848.861 1.245 1.343 1.785 3.436.754 3.528-1.478.076-1.863.137-4.368.137-7.61 0-3.24-.061-5.745-.138-7.608-.09-2.233-2.184-3.264-3.527-1.478-.298.397-.587.816-.86 1.245-.699 1.095-1.87 1.82-3.166 1.903-.544.036-1.005.084-1.401.14-1.603.229-2.806 1.558-3.084 3.153"
    />
  </svg>
);
export default SvgIconComponentAudio;
