import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../BodyText/BodyText';
import { ColorInput } from './ColorInput';
import { colorHexCodes, colorpickeri18NNS, Colors, isSameColorString } from './Colorpicker.utils';

// @TODO TECH-28 move this to forms once previous colorpicker gets deleted

export interface ColorpickerProps {
  selectedColor?: string;
  onChange: (color: string) => void;
  children?: React.ReactNode;
}

export const Colorpicker: React.FC<ColorpickerProps> = ({ onChange, selectedColor, children }) => {
  return (
    <div>
      {children}
      <ColorpickerColorGroup
        name="defaultcolors"
        colors={colorHexCodes}
        selectedColor={selectedColor}
        onChange={onChange}
      />
    </div>
  );
};

export interface ColorpickerColorGroupProps {
  title?: string;
  name: string;
  colors: (string | Colors)[] | readonly Colors[];
  selectedColor?: string;
  onChange: (color: string) => void;
}

export const ColorpickerColorGroup: React.FC<ColorpickerColorGroupProps> = ({
  title = '',
  name,
  colors,
  onChange,
  selectedColor,
}) => {
  const { t } = useTranslation(colorpickeri18NNS);

  return (
    <div>
      <StyledColorpickerColorGroupTitle size="small" weight="bold">
        {title}
      </StyledColorpickerColorGroupTitle>
      <StyledColorsWrapper>
        {colors.map((color, index) => {
          const isSelected = isSameColorString(color, selectedColor);
          return (
            <ColorInput
              color={color}
              name={name}
              key={`${name}-${color}-${index}`}
              onClick={() => onChange(color)}
              selected={isSelected}
              disabled={isSelected}
              ariaLabel={t('colorHexCode', { color })}
            />
          );
        })}
      </StyledColorsWrapper>
    </div>
  );
};

const StyledColorpickerColorGroupTitle = styled(BodyText)`
  padding: 8px 4px;
`;

const StyledColorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
