import React, { ReactNode } from 'react';
import { Id, toast as toastify, ToastContentProps, ToastOptions } from 'react-toastify';
import { ToastInner, ToastInnerProps } from './ToastInner';

export type ToastData = {
  message: string | ReactNode;
  type?: 'neutral' | 'error';
  title?: string | ReactNode;
  button?: ReactNode;
};
export type ToastParams = ToastData & {
  options?: Pick<
    ToastOptions<ToastInnerProps>,
    'autoClose' | 'position' | 'onOpen' | 'onClose' | 'className' | 'toastId' | 'closeOnClick' | 'hideProgressBar'
  >;
};

export const toast = (params: ToastParams): Id => {
  const { options, ...rest } = params;
  const isAutoClosing = options?.autoClose !== false;
  const toastInnerProps: ToastInnerProps = {
    ...rest,
    showCloseButton: !isAutoClosing,
  };

  const toastOptions: ToastOptions<ToastInnerProps> = {
    ...options,
    autoClose: options?.autoClose ?? 5000,
    position: options?.position ?? 'top-right',
    closeOnClick: options?.closeOnClick ?? false,
    type: 'default',
    closeButton: false,
    data: toastInnerProps,
  };

  return toastify((props: ToastContentProps<unknown>) => {
    if (isToastInnerProps(props.data)) {
      return <ToastInner {...props} data={props.data} />;
    }
    return null;
  }, toastOptions);
};

export const dismissToast = toastify.dismiss;

const isToastInnerProps = (data: unknown): data is ToastInnerProps => {
  return (data as ToastInnerProps).message !== undefined;
};
