import {
  BodyText,
  Button,
  IconDownload,
  IconEdit,
  IconFolderMoveTo,
  IconRemove,
  SpaceBetween,
  spacing,
  toast,
  useErrorContext,
} from '@lessonup/ui-components';
import { MimeTypes } from '@lessonup/utils/';
import { TFunction } from 'i18next';
import { map, partition } from 'lodash';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextHeader } from '../../../../components/ContextHeader/ContextHeader';
import { CreateNewFolder } from '../../CreateNewFolder';
import { showMoveUploadModal } from '../../dataComponents/MoveUploadModal';
import { useDeleteUploads } from '../../hooks/useDeleteUploads';
import { useDownloadUpload } from '../../hooks/useDownloadUpload';
import { useMoveUploads } from '../../hooks/useMoveUploads';
import { useRenameUpload } from '../../hooks/useRenameUpload';
import { UploadFile } from '../../UploadFile';
import { ChildUploadFolder, MyUploadFolder } from '../../Uploads.graphql';
import { UploadsContext } from '../../uploadsContext';
import { i18nextNamespace, isUploadFolder, refetchQueriesForUploads } from '../../UploadsFeature.utils';
import { SearchUploadsField } from './SearchUploadsField/SearchUploadsField';
import { listOfButtonsToShowForSelection } from './UploadsContextHeader.utils';

interface UploadsContextHeaderProps {
  uploads: ChildUploadFolder[];
  uploadFolderId?: string;
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  searchQuery?: string | null;
  acceptedMimeTypes: MimeTypes;
}

export const UploadsContextHeader: React.FC<UploadsContextHeaderProps> = ({
  uploads,
  uploadFolderId,
  selectedRows,
  setSelectedRows,
  searchQuery,
  acceptedMimeTypes,
}) => {
  const selectedRowCount = selectedRows.length;
  const { dispatchNavigation } = useContext(UploadsContext);

  function handleSearchInputChange(value: string | undefined) {
    dispatchNavigation({ type: 'search', query: value || null });
  }

  return (
    <ContextHeader
      startSlot={
        <SpaceBetween direction="x" spacing={spacing.size16} alignItems="center" style={{ paddingLeft: spacing.size8 }}>
          <SelectedCounter selectedRowCount={selectedRowCount} />
          <ContextHeaderButtons
            uploads={uploads}
            uploadFolderId={uploadFolderId}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            acceptedMimeTypes={acceptedMimeTypes}
            isSearch={!!searchQuery}
          />
        </SpaceBetween>
      }
      endSlot={<SearchUploadsField onChange={handleSearchInputChange} currentValue={searchQuery || undefined} />}
    ></ContextHeader>
  );
};

const SelectedCounter = ({ selectedRowCount }: { selectedRowCount: number }) => {
  const { t } = useTranslation(i18nextNamespace);

  return (
    <>{selectedRowCount ? <BodyText>{t('contextHeader.selected', { count: selectedRowCount })}</BodyText> : null}</>
  );
};

type ContextHeaderButtonsProps = Omit<UploadsContextHeaderProps, 'searchQuery'> & { isSearch: boolean };

const ContextHeaderButtons: React.FC<ContextHeaderButtonsProps> = ({
  uploadFolderId,
  uploads,
  selectedRows,
  setSelectedRows,
  acceptedMimeTypes,
  isSearch,
}) => {
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();
  const onError = (error: Error) => setError({ error });
  const refetchQueries = refetchQueriesForUploads(isSearch);
  const handleRenameUpload = useRenameUpload({ onError, refetchQueries });
  const handleDeleteUpload = useDeleteUploads({ onError, refetchQueries });
  const selectedUploadsAndFolders = selectedRowsWithTypes(uploads, selectedRows);
  const buttonsListToShow = listOfButtonsToShowForSelection(selectedUploadsAndFolders);

  const onOpenRenameModal = () => {
    const node = uploads.find((node) => node.id === selectedRows[0]);
    if (!node) return;
    const { id, name } = node;
    const isFolder = isUploadFolder(node);
    handleRenameUpload(id, name, isFolder);
  };

  const handleDownloadUpload = useDownloadUpload();

  const downloadUploadAction = () => {
    const name = uploads.find((node) => node.id === selectedRows[0])?.name;
    handleDownloadUpload(selectedRows[0], name ?? '');
  };

  if (!selectedRows.length) {
    return (
      <>
        <UploadFile folderId={uploadFolderId} acceptedMimeTypes={acceptedMimeTypes} />
        {uploadFolderId && <CreateNewFolder parentId={uploadFolderId} />}
      </>
    );
  }

  async function handleDelete() {
    const { deleted } = await handleDeleteUpload({
      uploads: selectedUploadsAndFolders.map((upload) => ({
        id: upload.id,
        type: isUploadFolder(upload) ? 'folder' : 'upload',
      })),
    });

    if (deleted) setSelectedRows([]);
  }
  return (
    <>
      {buttonsListToShow.includes('move') && (
        <MoveUploadButton
          toBeMovedNodes={selectedUploadsAndFolders}
          setSelectedRows={setSelectedRows}
          currentFolderId={uploadFolderId}
        />
      )}
      {buttonsListToShow.includes('rename') && (
        <Button onClick={() => onOpenRenameModal()} buttonType="neutral" iconStart={<IconEdit />}>
          {t('contextHeaderButtons.rename')}
        </Button>
      )}
      {buttonsListToShow.includes('download') && (
        <Button onClick={() => downloadUploadAction()} buttonType="neutral" iconStart={<IconDownload />}>
          {t('contextHeaderButtons.download')}
        </Button>
      )}
      <Button onClick={() => handleDelete()} buttonType="negative" iconStart={<IconRemove />}>
        {t('contextHeaderButtons.delete')}
      </Button>
    </>
  );
};

interface MoveUploadButtonProps {
  currentFolderId?: string;
  toBeMovedNodes: MyUploadFolder['childUploadFoldersAndUploads']['nodes'];
  setSelectedRows: UploadsContextHeaderProps['setSelectedRows'];
}

const MoveUploadButton: React.FC<MoveUploadButtonProps> = ({ currentFolderId, toBeMovedNodes, setSelectedRows }) => {
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();
  const { dispatchNavigation } = useContext(UploadsContext);

  function goToFolder(id: string) {
    dispatchNavigation({ type: 'folderId', id });
  }

  const moveUploads = useMoveUploads({
    onMove: createOnMoveHandler(t, setSelectedRows, goToFolder),
    onError: (error: Error) => setError({ error }),
  });

  async function handleClick() {
    const [folders, uploads] = partition(toBeMovedNodes, isUploadFolder);
    const uploadIds = map(uploads, 'id');
    const folderIds = map(folders, 'id');

    const destinationFolderId = await showMoveUploadModal({
      folderId: currentFolderId,
      disabledFolderIds: folderIds,
    });

    if (!destinationFolderId) {
      return;
    }

    return moveUploads({
      uploadIds,
      folderIds,
      destinationFolderId,
    });
  }

  return (
    <Button onClick={handleClick} buttonType="neutral" iconStart={<IconFolderMoveTo />}>
      {t('moveUploads.move')}
    </Button>
  );
};

const selectedRowsWithTypes = (
  uploads: ChildUploadFolder[],
  selectedRows: UploadsContextHeaderProps['selectedRows']
) => {
  return uploads.filter((upload) => selectedRows.includes(upload.id));
};

function createOnMoveHandler(
  t: TFunction,
  setSelectedRows: Dispatch<SetStateAction<string[]>>,
  setFolderId: (folderId: string) => void
) {
  return (destinationFolderId: string) => {
    toast({
      message: t('moveUploads.confirmMoveMessage'),
      type: 'neutral',
      button: (
        <Button buttonType="neutral" onClick={() => setFolderId(destinationFolderId)}>
          {t('moveUploads.confirmMoveCTA')}
        </Button>
      ),
      options: {
        closeOnClick: true,
      },
    });
    setSelectedRows([]);
  };
}
