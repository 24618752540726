import { AudioContextHandler } from '@lessonup/client-integration';
import { Meter } from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';

export interface MeterHandlerProps {
  handler: AudioContextHandler | null;
}

export const MeterWrapper: React.FC<MeterHandlerProps> = ({ handler }) => {
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    handler?.setOnRecordingTick(setVolume);

    return () => {
      handler?.resetOnRecordingTick();
    };
  }, [handler]);

  return <Meter percentage={volume} />;
};
