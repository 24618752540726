import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPresent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 2.598c6 0 9.375 3.375 9.375 9.375S18 21.348 12 21.348s-9.375-3.375-9.375-9.375S6 2.598 12 2.598m0 0v18.804m-4.5-4.929 4.5-3.75 4.5 3.75m-7.463-7.6c1.36.798 2.735 3.129 2.969 4.028-2.835.179-4.501-.413-4.965-.629-1.447-.85-1.034-2.131-.701-2.698.332-.566 1.25-1.551 2.697-.702m5.943 0c-1.36.798-2.735 3.13-2.969 4.029 2.836.178 4.502-.413 4.965-.63 1.447-.85 1.035-2.13.702-2.697s-1.25-1.552-2.698-.702"
    />
  </svg>
);
export default SvgIconPresent;
