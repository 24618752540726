import { Introduction, spacing, styled, useTranslation } from '@lessonup/ui-components';
import React from 'react';
import { i18nextNamespace } from '../../../utils/consts';

export const ReportsEmptyState = () => {
  const { t } = useTranslation(i18nextNamespace, { keyPrefix: 'modal.emptyState' });

  return (
    <StyledIntroduction
      alignment="center"
      size="S"
      headline={t('headline')}
      bodyText={t('bodyText')}
    ></StyledIntroduction>
  );
};

const StyledIntroduction = styled(Introduction)`
  padding: ${spacing.size24} ${spacing.size16};
`;
