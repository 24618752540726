import { useUpdatingProxyNiceModal } from '@lessonup/client-integration';
import { FontLoader } from '@lessonup/pin-renderer';
import {
  useCurrentPin,
  useCurrentStudents,
  useIsPlayerLoading,
  useNonNullAssignment,
  usePlayerDispatcher,
  usePlayerStore,
} from '@lessonup/players-modern';
import { color, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { PlayerBarBottomPlaceholder } from '../../../components/PlayerBar/PlayerBarBottom/PlayerBarBottom';
import { RealtimePlayerBarBottomHandler } from '../dataComponents/PlayerBarBottomHandler/RealtimePlayerBarBottomHandler';
import { usePlayerTranslation } from '../hooks/usePlayerTranslation';
import { PinViewerRealtimePlayer } from './dataComponents/PinViewerRealtimePlayer/PinViewerRealtimePlayer';
import { StudentJoinScreenModal } from './dataComponents/playerScreens/StudentJoinScreen/StudentJoinScreenModal';
import { useRealtimePlayerKeyboardEvents } from './hooks/useRealtimePlayerKeyboardEvents';
import { createHandleAllowNewPlayers, createHandleRemoveStudent } from './utils/playerDispatcher.utils';

export interface RealtimePlayerProps {
  onClose: () => void;
  withPresenterMode: boolean;
}

export const InitializedRealtimePlayer: React.FC<RealtimePlayerProps> = ({ onClose, withPresenterMode }) => {
  const { t } = usePlayerTranslation();
  const assignment = useNonNullAssignment();
  const currentPin = useCurrentPin();
  const currentStudents = useCurrentStudents();
  const { setPlayerNavigationLock, closePlayer } = usePlayerStore();
  const playerDispatcher = usePlayerDispatcher();

  useRealtimePlayerKeyboardEvents(onClose);

  const handleClose = () => {
    closePlayer();
    onClose();
  };

  const handleAllowNewPlayers = createHandleAllowNewPlayers(playerDispatcher);
  const handleRemoveStudent = createHandleRemoveStudent(playerDispatcher);

  const allowNewPlayers = assignment.settings.status === 'open' && assignment.settings.playerSettings.allowNewPlayers; // Is this correct?
  const pincode = assignment.pincode ?? '';

  const studentModal = useUpdatingProxyNiceModal(
    StudentJoinScreenModal,
    {
      joinedStudents: currentStudents,
      pincode,
      allowNewPlayers,
      handleAllowNewPlayers,
      handleRemoveStudent,
      onClose: () => {
        setPlayerNavigationLock(false);
      },
      t,
    },
    [currentStudents, allowNewPlayers]
  );

  const handleShowJoinScreenModal = () => {
    setPlayerNavigationLock(true);
    studentModal.show();
  };

  return (
    <StyledFullscreenPlayerWrapper>
      <FontLoader />
      <StyledRealtimePlayerWrapper spacing={spacing.size8} direction="y">
        <StyledPinViewerWrapper>
          {currentPin && <PinViewerRealtimePlayer pin={currentPin} withFAM />}
        </StyledPinViewerWrapper>
        <RealtimePlayerBarBottomHandler
          showLabels={true}
          handleShowJoinScreenModal={handleShowJoinScreenModal}
          onClose={handleClose}
          withPresenterMode={withPresenterMode}
          // TODO show label based on player settings
        />
      </StyledRealtimePlayerWrapper>
    </StyledFullscreenPlayerWrapper>
  );
};

export const RealtimePlayer: React.FC<RealtimePlayerProps> = ({ onClose, withPresenterMode }) => {
  const loading = useIsPlayerLoading();

  if (loading) {
    return (
      <StyledFullscreenPlayerWrapper>
        <StyledRealtimePlayerWrapper spacing={spacing.size8} direction="y">
          <StyledPinViewerWrapper />
          <PlayerBarBottomPlaceholder minimized={true} mirrored={false} />
        </StyledRealtimePlayerWrapper>
      </StyledFullscreenPlayerWrapper>
    );
  }

  return <InitializedRealtimePlayer onClose={onClose} withPresenterMode={withPresenterMode} />;
};

/**
 * @description This wrapper is required for fullscreen to maintain a white background (like the site body has) due to our colors being rgba.
 */
const StyledFullscreenPlayerWrapper = styled.div`
  background: ${color.neutral.surface.background};
`;

const StyledRealtimePlayerWrapper = styled(SpaceBetween)`
  height: 100vh;
  padding: ${spacing.size8};
  display: flex;
  flex-direction: column;
  background: ${color.primitives.black};
  overflow: hidden;
`;

// The default of flex items is min-width: auto and min-height: auto.
// The resize observer in the PinPreview component will report the wrong bounding box with resize auto
const StyledPinViewerWrapper = styled.div`
  flex: 1;
  min-width: 0; //⬆
  min-height: 0; //⬆
`;
