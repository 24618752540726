import { useLocale } from '@lessonup/client-integration';
import {
  BodyText,
  color,
  Grid,
  SelectFormField,
  SpaceBetween,
  spacing,
  styled,
  TextAreaFormField,
  UseFormRegister,
  UseFormWatch,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../../../types/graphql/types.generated';
import { helpArticleLink } from '../../../../../utils/bridge/helpArticleLink';
import type { ChatGPTQuestionType } from '../../../ChatGPTGeneratorFeature';
import { getLanguageOptions, giveSelectionOptions } from '../../../dataComponents/utils';

export interface FormInputs {
  prompt: string;
  slidesToGenerate: number;
  language: Language;
  pollAnswerOptions?: number;
}

interface InteractivePromptStepProps {
  type: ChatGPTQuestionType;
  register: UseFormRegister<FormInputs>;
  watch: UseFormWatch<FormInputs>;
}

export const InteractivePromptStep = ({ type, register, watch }: InteractivePromptStepProps) => {
  const { t } = useTranslation('chatGPT');
  const language = useLocale().language;
  const languageOptions = getLanguageOptions(t);
  const promptCharacterLimit = 8000;
  const promptCharacterLimitForWarning = promptCharacterLimit - 500;
  const promptInputLength = watch('prompt')?.length;

  return (
    <StyledForm>
      <SpaceBetween direction="y" spacing={spacing.size24}>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <div>
            <BodyText weight="bold">{t('interactivePromptStep.generateAbout', { type })}</BodyText>
            <StyledSubText size="small">{t('interactivePromptStep.promptInteractiveHelp', { type })}</StyledSubText>
            <StyledSubText size="small">
              <a href={helpArticleLink(language, 'openAIPrompt')} target="_blank" rel="noreferrer">
                {t('interactivePromptStep.promptArticle')}
              </a>
            </StyledSubText>
          </div>

          <TextAreaFormField
            label={t('interactivePromptStep.promptInput')}
            hideLabel
            placeholder={
              type === 'quiz'
                ? t('interactivePromptStep.quizPromptExample')
                : t('interactivePromptStep.pollPromptExample')
            }
            {...register('prompt')}
            validation={{
              state: 'neutral',
              message:
                promptInputLength >= promptCharacterLimitForWarning
                  ? t('promptCharactersWarning', { promptInputLength, promptCharacterLimit })
                  : '',
            }}
            maxLength={promptCharacterLimit}
          />
          <Grid cols={2} gap={spacing.size16}>
            <SelectFormField
              orientation="vertical"
              label={t('languageOption')}
              {...register('language')}
              options={languageOptions}
              defaultValue={language.toUpperCase()}
            />
            {
              <SelectFormField
                orientation="vertical"
                label={t('interactivePromptStep.resultCount')}
                {...register('slidesToGenerate')}
                options={giveSelectionOptions(5)}
                defaultValue={3}
              />
            }

            {type === 'poll' && (
              <SelectFormField
                orientation="vertical"
                label={t('interactivePromptStep.answerCount')}
                {...register('pollAnswerOptions')}
                options={giveSelectionOptions(10, 2)}
                defaultValue={4}
              />
            )}
          </Grid>
        </SpaceBetween>
      </SpaceBetween>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  padding: ${spacing.size12} ${spacing.size24};
`;

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
