import { InternalRefetchQueriesInclude, logger } from '@lessonup/client-integration';
import { FilePreview, FileType, IconComponentAudio } from '@lessonup/ui-components';
import {
  differenceInHours,
  Locale,
  localeToIsoLocaleString,
  sizeInBytesToHumanReadableSI,
  transformImageUrl,
} from '@lessonup/utils';
import { TFunction } from 'i18next';
import React from 'react';
import { AudioUpload, DocumentUpload, ImageUpload, SlideDeckUpload, VideoUpload } from '../../types/graphql';
import { ChildUploadFolder, MyUpload, MyUploadsData, SearchUploadsData, UploadStatusData } from './Uploads.graphql';
import {
  MyUploadsDocument,
  SearchUploadsDocument,
  UploadFragment_DocumentUpload_Fragment,
  UploadFragment_ImageUpload_Fragment,
  UploadFragment_VideoUpload_Fragment,
} from './Uploads.graphql.generated';

export const i18nextNamespace = 'uploads';
export const maxLengthName = 255;
export const perPage = 25;
export const loadingStateDebounceMilliseconds = 250;
export const maxUploadTimeInHours = 2;

export type Upload = AudioUpload | DocumentUpload | VideoUpload | ImageUpload | SlideDeckUpload;
export type UploadType = NonNullable<Upload['__typename']>;

export interface UploadQueryData<T extends MyUploadsData | SearchUploadsData | UploadStatusData> {
  data: T | undefined;
  loading: boolean;
  error?: Error;
  retry: () => Promise<void>;
}

export function isUploadFolder(upload: ChildUploadFolder): upload is ChildUploadFolder {
  return upload.__typename === 'ChildUploadFolder';
}

export function isUpload(upload: ChildUploadFolder): upload is Upload {
  return upload.__typename !== 'ChildUploadFolder';
}

export function getStartSlotForUpload(upload: MyUpload, t: TFunction): React.JSX.Element {
  let imageUrl: string | undefined = undefined;

  switch (upload.__typename) {
    case 'AudioUpload':
      return <IconComponentAudio />;
    case 'DocumentUpload':
      imageUrl = (upload as UploadFragment_DocumentUpload_Fragment)?.thumbnail?.url;
      break;
    case 'SlideDeckUpload':
      // TODO: https://lessonup.atlassian.net/browse/BEE-263
      logger.debug("We don't receive slide deck thumbnails from the backend (yet)", { uploadId: upload.id });
      imageUrl =
        'https://lh3.googleusercontent.com/nILrr76_nLCLSccnj7myFpwazipFBjILklLpyb4ga6ghN7smJ1N688XJYLQcX5cp_TGCM43qJSd2cn2MUuBdReASmzF9VYcKGM-GzR4';
      break;
    case 'VideoUpload':
      imageUrl = (upload as UploadFragment_VideoUpload_Fragment)?.thumbnail?.url;
      break;
    case 'ImageUpload':
    default:
      imageUrl = (upload as UploadFragment_ImageUpload_Fragment)?.thumbnail?.url;
      break;
  }

  return (
    <FilePreview
      type="image"
      src={imageUrl ? transformImageUrl(imageUrl, 64) : undefined}
      alt={t('altUploadThumbnail', { fileName: upload.name })}
    />
  );
}

export const fileTypeMapForEvent: Record<UploadType, FileType> = {
  AudioUpload: 'audio',
  DocumentUpload: 'document',
  SlideDeckUpload: 'slideDeck',
  ImageUpload: 'image',
  VideoUpload: 'video',
};

/**
 * @description Factory that returns a function to find out whether an upload is still uploading, but only counts uploads that started later than sinceTimeAgoInHours ago
 */
export function isUploadingSince(sinceTimeAgoInHours: number) {
  return (upload: Pick<Upload, 'status' | 'progressPercentage' | 'updatedAt'>): boolean => {
    const hasLoadingState = ['PROCESSING', 'UPLOADING'].includes(upload.status) && upload.progressPercentage < 100;

    const isRecentUpload = Math.abs(differenceInHours(new Date(upload.updatedAt), new Date())) < sinceTimeAgoInHours;

    return hasLoadingState && isRecentUpload;
  };
}

/**
 *
 * @description This method can be used to detemine whether uploads with an uploading state should still be shown as loading.
 * Since the uploading of a file is a 2 step process, uploads could be stuck in 'processing' or 'uploading' forever.
 */
export function isUploading(upload: Pick<Upload, 'status' | 'progressPercentage' | 'updatedAt'>): boolean {
  return isUploadingSince(maxUploadTimeInHours)(upload);
}

/**
 * @description Locale dependant file size to be used across Uploads app.
 * As described in https://lessonup.atlassian.net/browse/UP-167
 */
export const humandReadableFileSize = (sizeInBytes: number, locale: Locale): string => {
  const fileSizeFormatter = new Intl.NumberFormat(localeToIsoLocaleString(locale), {
    maximumFractionDigits: sizeInBytes > 1e6 ? 1 : 0,
  });
  return sizeInBytesToHumanReadableSI(sizeInBytes, fileSizeFormatter);
};

/**
 * @description Upload finished processing completely (registered in Mongo and file is in bucket)
 */
export function isReady(upload: Pick<Upload, 'status'>): boolean {
  return upload.status === 'READY';
}

/**
 * @description Upload is stuck in processing for more than amount of hours given
 */
export function isStuckIfMoreThan(stuckIfMoreThanHours: number) {
  return (upload: Pick<Upload, 'status' | 'progressPercentage' | 'updatedAt'>): boolean => {
    return !isUploadingSince(stuckIfMoreThanHours)(upload) && !isReady(upload);
  };
}

export function refetchQueriesForUploads(isSearch: boolean): InternalRefetchQueriesInclude {
  return isSearch ? [SearchUploadsDocument] : [MyUploadsDocument];
}
