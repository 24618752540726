import React, { useEffect, useRef, useState } from 'react';

import { toNumber } from 'lodash';
import { useBoolean, useOnClickOutside } from 'usehooks-ts';

import {
  borderRadius,
  borderRadiusScaled,
  color,
  colorSets,
  IconChevronDown,
  IconChevronUp,
  lineHeightMap,
  SpaceBetween,
  spacing,
  spacingScaled,
  styled,
} from '@lessonup/ui-components';

import { EditorInputFormField } from '../EditorInputFields/EditorInputFormField';
import { DropDown, StyleDropdownOption } from './CustomSelectDropdown';
import { findNextAndPrevIndices } from './SteppedNumberInput.utils';
import { useCustomSelectScrollIntoView } from './useCustomSelectScrollIntoView';

export interface SteppedNumberInputProps {
  label: string;
  value: number | undefined;
  options: { label: string; value: number }[];
  onChange: (value: number) => void;
  valueOnFocus?: () => number;
  onTextChange?: (value: number) => void;
  valueValidation?: (value: string) => boolean;
  styleOption?: StyleDropdownOption<number>;
  onInputFocus?: () => void;
  onBlur?: () => void;
}

export function SteppedNumberInput({
  value,
  options: options,
  onChange,
  onTextChange,
  valueValidation,
  styleOption,
  onInputFocus,
  onBlur,
  ...rest
}: SteppedNumberInputProps) {
  const isOpen = useBoolean(false);
  const selectRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLInputElement | null>(null);
  const [tempInputValue, setTemp] = useState(value || '');

  const customScroll = useCustomSelectScrollIntoView();

  const handleClose = () => {
    isOpen.setFalse();
    customScroll.resetScrollIntoView();
  };

  useEffect(() => {
    setTemp(value || 0);
  }, [value]);

  // TODO: TECH-180: fix the ref type issue
  useOnClickOutside(containerRef as never, () => {
    handleClose();
  });

  const handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (valueValidation && !valueValidation(event.target.value)) return;
    if (event.target.value === '') {
      setTemp('');
      return;
    }

    setTemp(parseInt(event.target.value));
  };

  const handlePickOption = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: number) => {
    event.preventDefault();
    setTemp(value);
    onChange(value);
    handleClose();
  };

  const handleBlur = () => {
    if (tempInputValue !== '' && typeof tempInputValue === 'number' && tempInputValue !== value) {
      onChange(tempInputValue);
    }
    onBlur?.();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    event.preventDefault();
    if (isOpen.value) {
      handleClose();
    } else {
      isOpen.setTrue();
    }
    if (selectRef.current) {
      onInputFocus?.();
      selectRef.current.focus();
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      isOpen.toggle();
      selectRef.current?.blur();
    }
  };

  const handleCaretClick = (dir: Direction) => {
    const number = toValidNumber(tempInputValue);
    const [prev, next] = findNextAndPrevIndices(options, number);
    if (dir === 'down' && prev !== -1) {
      onChange(options[prev].value);
    }
    if (dir === 'up' && next !== -1) {
      onChange(options[next].value);
    }
  };

  return (
    <SelectContainer ref={containerRef} {...rest}>
      <InputField
        ref={selectRef}
        type="text"
        readOnly={!onTextChange}
        onChange={handleOnTextChange}
        onBlur={handleBlur}
        formFieldSize="small"
        value={String(tempInputValue)}
        onMouseDown={toggleDropdown}
        onTouchStart={toggleDropdown}
        onKeyDown={handleEnter}
        label=""
      />
      <UpDownButtonSpaceBetween direction="y" spacing={spacing.size0}>
        <Caret direction="up" onClick={handleCaretClick} handleClose={handleClose} />
        <Caret direction="down" onClick={handleCaretClick} handleClose={handleClose} />
      </UpDownButtonSpaceBetween>
      {isOpen.value && DropDown<number>(customScroll, options, handlePickOption, value, styleOption)}
    </SelectContainer>
  );
}

const toValidNumber = (value: string | number | undefined): number => {
  if (!value) {
    return 0;
  }
  return toNumber(value);
};

const SelectContainer = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const InputField = styled(EditorInputFormField)<React.InputHTMLAttributes<HTMLInputElement>>`
  max-width: 100px;
  cursor: pointer;
  appearance: none;
  flex-grow: 1;
`;

const UpDownButtonSpaceBetween = styled(SpaceBetween)`
  position: absolute;
  max-height: 50px;
  right: 10px;
`;

type Direction = 'up' | 'down';
const Caret: React.FC<{ onClick: (dir: Direction) => void; direction: Direction; handleClose: () => void }> = ({
  onClick,
  direction,
  handleClose,
}) => {
  const handleEvent = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    handleClose();
    event.preventDefault();
    onClick(direction);
  };
  return (
    <CaretButton onMouseDown={(e) => handleEvent(e)} onTouchStart={(e) => handleEvent(e)}>
      {direction === 'up' ? <IconChevronUp /> : <IconChevronDown />}
    </CaretButton>
  );
};

const size = 'medium';

const CaretButton = styled.button`
  color: ${color.accent.secondary.background};
  max-height: 17px;
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacingScaled(spacing.size4, size)};
  border-radius: ${borderRadiusScaled(borderRadius.rounded32, size)};
  > svg {
    flex-shrink: 0;
    width: ${lineHeightMap.label[size]};
    height: ${lineHeightMap.label[size]};
  }

  :hover {
    background: ${colorSets.neutral.fillFeedback};
  }
`;
