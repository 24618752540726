import { intercomIsAvailable } from '../utils/intercomisAvailable';

export function openChatWindow() {
  if (!intercomIsAvailable()) return;
  Intercom('show');
}

export function hideChatIcon() {
  if (!intercomIsAvailable()) return;

  Intercom('update', {
    hide_default_launcher: true,
  });
}

export function showChatIcon() {
  if (!intercomIsAvailable()) return;

  Intercom('update', {
    hide_default_launcher: false,
  });
}
