import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconMap = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.431 20.228c.552-.105 1.118-.331 1.722-.538.887-.303 1.773-.645 2.594-.87.234-.064.48-.06.715.001l5.439 1.408c.917.214 1.827-.191 2.845-.539.627-.214 1.255-.449 1.86-.647.217-.072.415-.275.58-.51.434-.62.58-1.392.715-2.136.614-3.382.62-6.876.005-10.259-.139-.767-.25-1.603-.799-2.157-.156-.158-.337-.265-.535-.246-.732.068-1.513.27-2.07.423-.649.179-1.392.544-2.117.89a1.47 1.47 0 0 1-1.003.095L9.104 3.778c-.917-.215-2.328.14-3.195.38-.542.15-1.15.429-1.758.72-.219.104-.405.373-.546.646-.243.472-.344 1.001-.437 1.524l-.074.417a29.5 29.5 0 0 0 0 10.355c.143.8.236 1.696.844 2.235.146.13.313.208.493.173"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.99 20.226 9.33 18.81a1.4 1.4 0 0 0-.325-.042v-14.8q0-.095.011-.188l5.511 1.38q.236.057.473.035V20.05q0 .09-.01.177"
    />
  </svg>
);
export default SvgIconMap;
