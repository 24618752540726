import { HotspotIcon, HotspotSettings } from '@lessonup/pins-shared';
import {
  IconAdd,
  IconApps,
  IconArrowBendDownRight,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBadge,
  IconBook,
  IconBrain,
  IconCalendar,
  IconCamera,
  IconCart,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconClose,
  IconCloudUpload,
  IconColor,
  IconComponentAudio,
  IconComponentImage,
  IconComponentTraffic,
  IconComponentVideo,
  IconCookie,
  IconCroissant,
  IconCut,
  IconDesktop,
  IconEdit,
  IconEmail,
  IconEuro,
  IconFeedback,
  IconFile,
  IconFileText,
  IconFlag,
  IconFolder,
  IconHeadphones,
  IconHeart,
  IconHelp,
  IconHome,
  IconInfinity,
  IconInfo,
  IconLanguage,
  IconLaptop,
  IconLinkAdd,
  IconLockClosed,
  IconLockOpen,
  IconMap,
  IconMicrophone,
  IconMinus,
  IconNote,
  IconNotificationOn,
  IconPageAccount,
  IconPageAssessments,
  IconPageLibrary,
  IconPayment,
  IconPension,
  IconPeople,
  IconPlay,
  IconPresent,
  IconPresenter,
  IconPrint,
  IconPrivacy,
  IconRemove,
  IconReport,
  IconRobot,
  IconRocket,
  IconSchool,
  IconSearch,
  IconSend,
  IconShareAlt,
  IconShoppingBag,
  IconStar,
  IconStudent,
  IconStudentQuestion,
  IconSun,
  IconSupport,
  IconTeachingSessions,
  IconTransport,
  IconUnmarked,
  IconVisibilityOn,
  IconVoucher,
  IconWand,
  IconWarning,
} from '@lessonup/ui-components';
import React from 'react';

type HotspotIconType = {
  icon: React.JSX.Element;
  translateKey: string;
};

type HotspotIconRecordEntry = {
  icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
  translateKey: string;
};

// Some icons have gotten a new name, but the key is still the old one, the order was changed to match the new name
export const hotspotIconMapper: Record<HotspotIcon, HotspotIconRecordEntry> = {
  ACCOUNT: { icon: IconPageAccount, translateKey: 'iconPageAccount' },
  ADD: { icon: IconAdd, translateKey: 'iconAdd' },
  APPS: { icon: IconApps, translateKey: 'iconApps' },
  ARROW_BACK: { icon: IconArrowLeft, translateKey: 'iconArrowLeft' },
  ARROW_BEND_DOWN_RIGHT: { icon: IconArrowBendDownRight, translateKey: 'iconArrowBendDownRight' },
  ARROW_DOWN: { icon: IconArrowDown, translateKey: 'iconArrowDown' },
  ARROW_FORWARD: { icon: IconArrowRight, translateKey: 'iconArrowRight' },
  ARROW_UP: { icon: IconArrowUp, translateKey: 'iconArrowUp' },
  AUDIO: { icon: IconComponentAudio, translateKey: 'iconComponentAudio' },
  BADGE: { icon: IconBadge, translateKey: 'iconBadge' },
  BOOK: { icon: IconBook, translateKey: 'iconBook' },
  ACTIVITY: { icon: IconBrain, translateKey: 'iconBrain' }, // Name was changed
  CALENDAR: { icon: IconCalendar, translateKey: 'iconCalendar' },
  CAMERA: { icon: IconCamera, translateKey: 'iconCamera' },
  CART: { icon: IconCart, translateKey: 'iconCart' },
  CHEVRON_DOWN: { icon: IconChevronDown, translateKey: 'iconChevronDown' },
  CHEVRON_LEFT: { icon: IconChevronLeft, translateKey: 'iconChevronLeft' },
  CHEVRON_RIGHT: { icon: IconChevronRight, translateKey: 'iconChevronRight' },
  CHEVRON_UP: { icon: IconChevronUp, translateKey: 'iconChevronUp' },
  CLOSE: { icon: IconClose, translateKey: 'iconClose' },
  CLOUD: { icon: IconCloudUpload, translateKey: 'iconCloudUpload' },
  COINS: { icon: IconTeachingSessions, translateKey: 'iconTeachingSessions' },
  COLOR: { icon: IconColor, translateKey: 'iconColor' },
  COOKIE: { icon: IconCookie, translateKey: 'iconCookie' },
  LUNCH: { icon: IconCroissant, translateKey: 'iconCroissant' }, // Name was changed
  CUT: { icon: IconCut, translateKey: 'iconCut' },
  DELETE: { icon: IconRemove, translateKey: 'iconRemove' },
  DESKTOP: { icon: IconDesktop, translateKey: 'iconDesktop' },
  EDIT: { icon: IconEdit, translateKey: 'iconEdit' },
  EMAIL: { icon: IconEmail, translateKey: 'iconEmail' },
  EURO: { icon: IconEuro, translateKey: 'iconEuro' },
  EYE: { icon: IconVisibilityOn, translateKey: 'iconVisibilityOn' },
  FEEDBACK: { icon: IconFeedback, translateKey: 'iconFeedback' },
  FILE: { icon: IconFile, translateKey: 'iconFile' },
  FILE_TEXT: { icon: IconFileText, translateKey: 'iconFileText' },
  FLAG: { icon: IconFlag, translateKey: 'iconFlag' },
  FOLDER: { icon: IconFolder, translateKey: 'iconFolder' },
  STUDENT_HAT: { icon: IconStudent, translateKey: 'iconGraduationCap' }, // Name was changed
  HAND_RAISED: { icon: IconStudentQuestion, translateKey: 'iconStudentQuestion' },
  HEADPHONES: { icon: IconHeadphones, translateKey: 'iconHeadphones' },
  HEART_OUTLINE: { icon: IconHeart, translateKey: 'iconHeart' },
  HELP: { icon: IconHelp, translateKey: 'iconHelp' },
  HOME: { icon: IconHome, translateKey: 'iconHome' },
  IMAGE: { icon: IconComponentImage, translateKey: 'iconComponentImage' },
  INFINITY: { icon: IconInfinity, translateKey: 'iconInfinity' },
  INFO: { icon: IconInfo, translateKey: 'iconInfo' },
  LANGUAGE: { icon: IconLanguage, translateKey: 'iconLanguage' },
  LAPTOP: { icon: IconLaptop, translateKey: 'iconLaptop' },
  MEDIA_LIBRARY: { icon: IconPageLibrary, translateKey: 'iconPageLibrary' }, // Name was changed
  LINK: { icon: IconLinkAdd, translateKey: 'iconLinkAdd' },
  LOCK: { icon: IconLockClosed, translateKey: 'iconLockClosed' },
  LOCK_OPEN: { icon: IconLockOpen, translateKey: 'iconLockOpen' },
  MAP: { icon: IconMap, translateKey: 'iconMap' },
  MICROPHONE: { icon: IconMicrophone, translateKey: 'iconMicrophone' },
  MINUS: { icon: IconMinus, translateKey: 'iconMinus' },
  NOTE: { icon: IconNote, translateKey: 'iconNote' },
  NOTIFICATIONS: { icon: IconNotificationOn, translateKey: 'iconNotificationOn' },
  PAYMENT: { icon: IconPayment, translateKey: 'iconPayment' },
  PENSION: { icon: IconPension, translateKey: 'iconPension' },
  PEOPLE: { icon: IconPeople, translateKey: 'iconPeople' },
  PLAY: { icon: IconPlay, translateKey: 'iconPlay' },
  PRESENT: { icon: IconPresent, translateKey: 'iconPresent' },
  PRESENTER: { icon: IconPresenter, translateKey: 'iconPresenter' },
  PRINT: { icon: IconPrint, translateKey: 'iconPrint' },
  PRIVACY: { icon: IconPrivacy, translateKey: 'iconPrivacy' },
  QUESTION: { icon: IconUnmarked, translateKey: 'iconUnmarked' },
  REPORT: { icon: IconReport, translateKey: 'iconReport' },
  ROBOT: { icon: IconRobot, translateKey: 'iconRobot' },
  ROCKET: { icon: IconRocket, translateKey: 'iconRocket' },
  SCHOOL: { icon: IconSchool, translateKey: 'iconSchool' },
  SEARCH: { icon: IconSearch, translateKey: 'iconSearch' },
  SEND: { icon: IconSend, translateKey: 'iconSend' },
  SHARE_ALT: { icon: IconShareAlt, translateKey: 'iconShareAlt' },
  SHOPPING_BAG: { icon: IconShoppingBag, translateKey: 'iconShoppingBag' },
  STAR_OUTLINE: { icon: IconStar, translateKey: 'iconStar' },
  STUDENT: { icon: IconStudent, translateKey: 'iconStudent' },
  SUN: { icon: IconSun, translateKey: 'iconSun' },
  SUPPORT: { icon: IconSupport, translateKey: 'iconSupport' },
  TEST: { icon: IconPageAssessments, translateKey: 'iconPageAssessments' },
  TRAFFIC: { icon: IconComponentTraffic, translateKey: 'iconComponentTraffic' },
  TRANSPORT: { icon: IconTransport, translateKey: 'iconTransport' },
  VIDEO: { icon: IconComponentVideo, translateKey: 'iconComponentVideo' },
  VOUCHER: { icon: IconVoucher, translateKey: 'iconVoucher' },
  WAND: { icon: IconWand, translateKey: 'iconWand' },
  WARNING: { icon: IconWarning, translateKey: 'iconWarning' },
};

export const hotspotIconList = Object.keys(hotspotIconMapper) as HotspotIcon[];

export const transformHotspotIconRecordEntryToType = (t: HotspotIconRecordEntry): HotspotIconType => {
  return { icon: <t.icon />, translateKey: t.translateKey };
};

export const getHotspotIcon = (iconName: HotspotSettings['iconFill']): HotspotIconType => {
  return transformHotspotIconRecordEntryToType(hotspotIconMapper[iconName] || hotspotIconMapper.ROCKET);
};
