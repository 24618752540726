import { useLocale, useRouter } from '@lessonup/client-integration';
import {
  color,
  ModalHeaderV1,
  styled,
  Table,
  TableCellsHeader,
  TableRowData,
  useTranslation,
} from '@lessonup/ui-components';
import { dateToShortLocale } from '@lessonup/utils';
import { default as React, useEffect, useState } from 'react';
import { reportV2Route } from '../../../../utils/bridge/teacherRoutes';
import { i18nextNamespace } from '../../utils/consts';
import { Report } from '../../utils/types';
import { ReportsEmptyState } from './EmptyState/ReportsModalEmptyState';

export interface ReportsListModalContentProps {
  reports: Pick<Report, 'id' | 'taughtAt' | 'numberOfStudents' | 'lesson'>[];
  lessonName: string;
  onClose: () => void;
  className?: string;
}

export const ReportsListModalContent = (props: ReportsListModalContentProps) => {
  const { reports, onClose, lessonName } = props;

  const [rows, setRows] = useState<TableRowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const locale = useLocale();
  const { t } = useTranslation(i18nextNamespace, { keyPrefix: 'modal' });
  const router = useRouter();

  const header: TableCellsHeader = {
    cells: [
      {
        text: t('table.header.date'),
      },
      {
        text: t('table.header.numberOfStudents'),
      },
    ],
  };

  useEffect(() => {
    const getReportsRows = (reports: ReportsListModalContentProps['reports']): TableRowData[] => {
      return reports.map((report) => {
        const formattedDate = dateToShortLocale(report.taughtAt, locale);

        return {
          id: report.id,
          ariaLabel: report.lesson.name + ' ' + formattedDate,
          cells: [
            {
              text: formattedDate,
            },
            {
              text: report.numberOfStudents.toString(),
            },
          ],
          onClick: () => {
            router.go(reportV2Route.href({ reportId: report.id }));
            onClose();
          },
        };
      });
    };

    setRows(getReportsRows(reports));
  }, [reports, locale, router, onClose]);

  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('title', { lessonName }) }}
        onCloseButtonClick={onClose}
        showDivider={true}
      />
      {reports.length === 0 ? (
        <ReportsEmptyState />
      ) : (
        <TableContainer>
          <Table
            caption={t('table.caption')}
            header={header}
            rows={rows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            interactivity={false}
          />
        </TableContainer>
      )}
    </>
  );
};

const TableContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;

  thead,
  th {
    position: sticky;
    top: 0;
  }

  tr {
    background: ${color.neutral.surface.background};
  }

  table {
    max-height: 100%;
  }
`;
