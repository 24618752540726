/* eslint-disable @typescript-eslint/no-explicit-any */ // we disable it here because we need this to match the apollo types
/* eslint-disable no-restricted-imports */
import {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { CleanedResult } from '../cleanedResultOf';

/**
 * Wrapper around `useLazyQuery` from the `@apollo/client` which will normalize the data returned from the server which is cleaned by the `cleanDataLink`
 * Only other change compared to apollo is that we set the `errorPolicy` to `all` as a default so we always get partial data. This can be overridden.
 */
export function useLazyDocumentQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<CleanedResult<TData>, TVariables>
): LazyQueryResultTuple<CleanedResult<TData>, TVariables> {
  return useLazyQuery(document, {
    errorPolicy: 'all',
    ...(options ?? {}),
  });
}
