import { breakpoints, SpaceBetween, spacing, styled, Tab, TabList } from '@lessonup/ui-components';
import React, { useState } from 'react';

interface FullPageBodyWidthAsideProps {
  children: React.ReactNode;
  mainLabel: string;
  asideLabel: string;
}

export const FullPageBodyAsideLayout: React.FC<FullPageBodyWidthAsideProps> = ({ children, mainLabel, asideLabel }) => {
  const [asideVisibleOnMobile, setAsideVisibleOnMobile] = useState(false);

  return (
    <>
      <MobileTabs
        asideVisibleOnMobile={asideVisibleOnMobile}
        mainLabel={mainLabel}
        asideLabel={asideLabel}
        setAsideVisible={setAsideVisibleOnMobile}
      />
      <StyledSpaceBetween
        direction="x"
        spacing={asideVisibleOnMobile ? spacing.size0 : spacing.size16}
        asideVisibleOnMobile={asideVisibleOnMobile}
      >
        {children}
      </StyledSpaceBetween>
    </>
  );
};

interface MobileTabsProps {
  asideVisibleOnMobile: boolean;
  mainLabel: string;
  asideLabel: string;
  setAsideVisible: (value: boolean) => void;
}

interface AsideMobileProps {
  asideVisibleOnMobile: boolean;
}

const StyledSpaceBetween = styled(SpaceBetween)<AsideMobileProps>`
  max-width: ${breakpoints.maxWidthText};
  width: 100%;
  margin: 0 auto;
  @media (max-width: ${breakpoints.maxWidthText}) {
    ${({ asideVisibleOnMobile }) => asideVisibleOnMobile && `> :first-child { display: none; }`}
    ${({ asideVisibleOnMobile }) => !asideVisibleOnMobile && `> :last-child {  display: none; }`}
    justify-content: center;
  }
`;

const MobileTabs: React.FC<MobileTabsProps> = ({ asideVisibleOnMobile, mainLabel, asideLabel, setAsideVisible }) => {
  return (
    <StyledTabList>
      <TabList resizing="hug">
        <Tab as="button" isActive={!asideVisibleOnMobile} onClick={() => setAsideVisible(false)} content={mainLabel} />
        <Tab as="button" isActive={asideVisibleOnMobile} onClick={() => setAsideVisible(true)} content={asideLabel} />
      </TabList>
    </StyledTabList>
  );
};

const StyledTabList = styled.div`
  position: relative;
  display: none;
  @media (max-width: ${breakpoints.maxWidthText}) {
    display: flex;
  }
  justify-content: center;
  z-index: 1;
`;
