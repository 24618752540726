import { IconCheck, IconClose, IconUnmarked, IndicatorMark, styled } from '@lessonup/ui-components';
import React from 'react';
import { quizAnswerLetterForIndex } from '../../../../utils';

export interface QuizPinAnswerIndicatorsProps {
  index: number;
  answerStatus: 'correct' | 'incorrect' | 'neutral';
}

export const QuizPinAnswerIndicators: React.FC<QuizPinAnswerIndicatorsProps> = ({ index, answerStatus }) => {
  const letterIndex = quizAnswerLetterForIndex(index);
  const answerStatusIcon =
    answerStatus === 'correct' ? <IconCheck /> : answerStatus === 'neutral' ? <IconUnmarked /> : <IconClose />;
  const answerStatusType =
    answerStatus === 'correct' ? 'positive' : answerStatus === 'incorrect' ? 'negative' : 'neutral';
  const answerStatusDashed = answerStatus === 'neutral';
  return (
    <StyledIndicatorContainer>
      <IndicatorMark content={letterIndex} />
      <IndicatorMark indicatorTheme={answerStatusType} content={answerStatusIcon} dashedBorder={answerStatusDashed} />
    </StyledIndicatorContainer>
  );
};

const StyledIndicatorContainer = styled.div`
  display: flex;
  user-select: none;
  z-index: 0;

  > :nth-of-type(2) {
    transform: translateX(-4px);
    z-index: -1;
  }
`;
