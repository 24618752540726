import { SelectFormFieldOption } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import {
  GeneratedLessonDocument,
  GeneratedLessonDocumentPartial,
  GenerateMultipleChoiceQuestionsPayload,
  GeneratePollPayload,
  Language,
} from '../../../types/graphql/types.generated';
import { noPhaseNumber } from '../../../utils/learningPhases/LearningPhase';

import { MaiaLessonType, textListToHTMLList } from '../ChatGPTGeneratorFeature.utils';
import { GeneratedResultItem } from '../components/Wizard/ReviewStep/reviewStepUtils';
import {
  LessonResponse,
  OpenQuestionResponse,
  OptionalLessonSettings,
  PollResponse,
  QuizResponse,
  ResponseSelection,
  SlideResponse,
  WordWebResponse,
} from '../model';

const singleSlideOption = (count: number) => {
  return { label: `${count}`, value: count };
};

export const giveSelectionOptions = (selectCount: number, startCount = 1): SelectFormFieldOption<number>[] => {
  const slideOptions = [];
  for (let i = startCount; i <= selectCount; i++) {
    slideOptions.push(singleSlideOption(i));
  }
  return slideOptions;
};

export const getLanguageOptions = (t: TFunction): SelectFormFieldOption<Language>[] => [
  { label: t('en'), value: 'EN' },
  { label: t('fr'), value: 'FR' },
  { label: t('nl'), value: 'NL' },
  { label: t('de'), value: 'DE' },
  { label: t('es'), value: 'ES' },
];

export const generateTempResultId = (string: string) => Buffer.from(string).toString('base64');

export function mapGraphQLtoQuizResponse(document: GenerateMultipleChoiceQuestionsPayload): QuizResponse {
  const list = mapQuizResponse(document);
  return {
    type: 'quiz',
    list: compact(list),
  };
}

export function mapGraphQLtoPollResponse(document: GeneratePollPayload): PollResponse {
  const list = mapPollResponse(document);
  return {
    type: 'poll',
    list: compact(list),
  };
}

export function mapGraphQLtoLessonResponse(
  document: GeneratedLessonDocument | GeneratedLessonDocumentPartial | undefined,
  optionalSettings: OptionalLessonSettings,
  t: TFunction,
  outputLanguage: Language
): LessonResponse {
  const list = document ? mapLessonResponse(document, optionalSettings, t, outputLanguage) : [];

  return {
    type: 'lesson',
    name: document?.name ?? '',
    list: compact(list),
    settings: optionalSettings,
  };
}

export const mapLessonResponseToGeneratedResultList = (
  list: LessonResponse['list'],
  lessonType: MaiaLessonType
): GeneratedResultItem[] => {
  return compact(
    list.map((item) => {
      if (item.type === 'slide') {
        const slide: GeneratedResultItem = {
          tempId: item.tempId,
          phaseNumber: item.phaseNumber,
          content: {
            type: 'slide',
            header: item.header,
            layout: item.layout,
            text: lessonType === 'contextLesson' ? textListToHTMLList(item.text) : item.text,
            note: item.note,
          },
        };
        return slide;
      }
      if (item.type === 'openQuestion') {
        const openQuestion: GeneratedResultItem = {
          tempId: item.tempId,
          phaseNumber: item.phaseNumber,
          content: {
            type: 'openQuestion',
            question: item.question,
            note: item.note,
          },
        };
        return openQuestion;
      }
      if (item.type === 'wordweb') {
        const wordweb: GeneratedResultItem = {
          tempId: item.tempId,
          phaseNumber: item.phaseNumber,
          content: {
            type: 'wordweb',
            word: item.word,
          },
        };
        return wordweb;
      }
      console.warn('unknown type', item);
    })
  );
};

const mapLessonResponse = (
  document: GeneratedLessonDocument | GeneratedLessonDocumentPartial,
  optionalSettings: OptionalLessonSettings,
  t: TFunction,
  outputLanguage: Language
): ResponseSelection<LessonResponse> | undefined => {
  const mappedSlides = compact(
    document.slides?.map((item, index) => {
      if (item.__typename === 'GeneratedSlide') {
        const slide: SlideResponse = {
          type: 'slide',
          tempId: `slide-${index}-${simpleIdentifierForTitle(item.title)}`,
          layout: 'text',
          header: item.title,
          text: item.body,
          note: optionalSettings.enableTeacherNotes && item.notes ? item.notes : '',
          phaseNumber: 2000,
        };
        return slide;
      } else {
        console.warn('unknown type', item.__typename);
        return null;
      }
    })
  );

  const [objectiveSlide, ...slides] = mappedSlides;
  if (objectiveSlide) {
    objectiveSlide.phaseNumber = 1000;
  }

  const lessonResponseWithPhaseNumbers: ResponseSelection<LessonResponse> = compact([
    coverSlide(document?.name ?? undefined),
    objectiveSlide,
    optionalSettings.enablePreviousKnowledge
      ? activatePreviousKnowledgeSlide(document?.introQuestion ?? undefined)
      : null,
    ...slides,
    ...(optionalSettings.enableExitTicket
      ? getExitTicketSlideData(t, optionalSettings.enableTeacherNotes, outputLanguage)
      : []),
  ]);

  return optionalSettings.enableLearningPhase
    ? lessonResponseWithPhaseNumbers
    : setAllPhaseNumbersToZero(lessonResponseWithPhaseNumbers);
};

const mapPollResponse = (document: GeneratePollPayload): ResponseSelection<PollResponse> => {
  return document.polls.map((question, index) => ({
    tempId: `poll-question-${index}-${simpleIdentifierForTitle(question.statement)}`,
    statement: question.statement,
    options: question.answers,
  }));
};

const mapQuizResponse = (document: GenerateMultipleChoiceQuestionsPayload): ResponseSelection<QuizResponse> => {
  return document.questions.map((question, index) => ({
    tempId: `quiz-question-${index}-${simpleIdentifierForTitle(question.question)}`,
    question: question.question,
    answers: question.answers,
    correctAnswer: question.correctAnswers,
  }));
};

const activatePreviousKnowledgeSlide = (word?: string): WordWebResponse => ({
  tempId: 'activate-previous-knowledge-ticket-1',
  type: 'wordweb',
  word: word ?? '',
  phaseNumber: 1000,
});

const coverSlide = (name?: string): SlideResponse => ({
  tempId: 'cover-slide',
  type: 'slide',
  header: name ?? '',
  layout: 'cover',
  text: '',
  note: '',
  phaseNumber: 1000,
});

const getExitTicketSlideData = (
  t: TFunction,
  notesEnabled: boolean,
  outputLanguage: Language
): OpenQuestionResponse[] => [
  {
    tempId: 'exit-ticket-1',
    type: 'openQuestion',
    question:
      outputLanguage === 'NL'
        ? exitTicketTranslations.dutch.question1
        : outputLanguage === 'EN'
        ? exitTicketTranslations.english.question1
        : outputLanguage === 'FR'
        ? exitTicketTranslations.french.question1
        : outputLanguage === 'DE'
        ? exitTicketTranslations.german.question1
        : outputLanguage === 'ES'
        ? exitTicketTranslations.spanish.question1
        : t('exitTicketQuestions.question1'),
    note: !notesEnabled
      ? ''
      : outputLanguage === 'NL'
      ? exitTicketTranslations.dutch.question1Note
      : outputLanguage === 'EN'
      ? exitTicketTranslations.english.question1Note
      : outputLanguage === 'FR'
      ? exitTicketTranslations.french.question1Note
      : outputLanguage === 'DE'
      ? exitTicketTranslations.german.question1Note
      : outputLanguage === 'ES'
      ? exitTicketTranslations.spanish.question1Note
      : t('exitTicketQuestions.question1Note'),
    phaseNumber: 4000,
  },
  {
    tempId: 'exit-ticket-2',
    type: 'openQuestion',
    question:
      outputLanguage === 'NL'
        ? exitTicketTranslations.dutch.question2
        : outputLanguage === 'EN'
        ? exitTicketTranslations.english.question2
        : outputLanguage === 'FR'
        ? exitTicketTranslations.french.question2
        : outputLanguage === 'DE'
        ? exitTicketTranslations.german.question2
        : outputLanguage === 'ES'
        ? exitTicketTranslations.spanish.question2
        : t('exitTicketQuestions.question2'),
    note: !notesEnabled
      ? ''
      : outputLanguage === 'NL'
      ? exitTicketTranslations.dutch.question2Note
      : outputLanguage === 'EN'
      ? exitTicketTranslations.english.question2Note
      : outputLanguage === 'FR'
      ? exitTicketTranslations.french.question2Note
      : outputLanguage === 'DE'
      ? exitTicketTranslations.german.question2Note
      : outputLanguage === 'ES'
      ? exitTicketTranslations.spanish.question2Note
      : t('exitTicketQuestions.question2Note'),
    phaseNumber: 4000,
  },
  {
    tempId: 'exit-ticket-3',
    type: 'openQuestion',
    question:
      outputLanguage === 'NL'
        ? exitTicketTranslations.dutch.question3
        : outputLanguage === 'EN'
        ? exitTicketTranslations.english.question3
        : outputLanguage === 'FR'
        ? exitTicketTranslations.french.question3
        : outputLanguage === 'DE'
        ? exitTicketTranslations.german.question3
        : outputLanguage === 'ES'
        ? exitTicketTranslations.spanish.question3
        : t('exitTicketQuestions.question3'),
    note: !notesEnabled
      ? ''
      : outputLanguage === 'NL'
      ? exitTicketTranslations.dutch.question3Note
      : outputLanguage === 'EN'
      ? exitTicketTranslations.english.question3Note
      : outputLanguage === 'FR'
      ? exitTicketTranslations.french.question3Note
      : outputLanguage === 'DE'
      ? exitTicketTranslations.german.question3Note
      : outputLanguage === 'ES'
      ? exitTicketTranslations.spanish.question3Note
      : t('exitTicketQuestions.question3Note'),
    phaseNumber: 4000,
  },
];

const setAllPhaseNumbersToZero = (list: ResponseSelection<LessonResponse>): ResponseSelection<LessonResponse> => {
  return list.map((item) => ({
    ...item,
    phaseNumber: noPhaseNumber,
  }));
};

const exitTicketTranslations = {
  english: {
    question1: 'Write down 3 things you learned in this lesson.',
    question1Note:
      'Have students enter three things they learned in this lesson. With this they can indicate their own learning efficiency of this lesson.',
    question2: 'Write down 2 things you want to know more about.',
    question2Note:
      'Here, students enter two things they would like to know more about. This not only increases involvement, but also gives them more ownership.',
    question3: "Ask 1 question about something you haven't quite understood yet.",
    question3Note:
      'The students indicate here (in question form) with which part of the material they still have difficulty. For the teacher, this not only provides insight into the extent to which the students understand/master the material, but also a good starting point for the next lesson.',
  },
  dutch: {
    question1: 'Schrijf 3 dingen op die je deze les hebt geleerd.',
    question1Note:
      'De leerlingen voeren hier drie dingen in die ze in deze les hebben geleerd. Hiermee geven ze aan wat hun eigen leerrendement van deze les is.',
    question2: 'Schrijf 2 dingen op waarover je meer wilt weten.',
    question2Note:
      'De leerlingen voeren hier twee dingen in waarover ze meer zouden willen weten. Hiermee vergroot je niet alleen betrokkenheid, maar geef je hen ook meer eigenaarschap.',
    question3: 'Stel 1 vraag over iets dat je nog niet zo goed hebt begrepen.',
    question3Note:
      'De leerlingen geven hier (in vraagvorm) aan met welk onderdeel van de stof ze nog moeite. Voor de docent biedt dit niet alleen inzicht in de mate waarin de stof de leerlingen begrijpen/beheersen, maar ook een goed startpunt voor een volgende les.',
  },
  german: {
    question1: 'Schreibe 3 Dinge auf, die Du in dieser Lektion gelernt hast.',
    question1Note:
      'Die Schüler geben hier drei Dinge ein, die sie in dieser Lektion gelernt haben. Damit geben sie an, was ihr eigenes Lernergebnis dieser Lektion ist.',
    question2: 'Schreibe 2 Dinge auf, über die Du mehr wissen möchtest.',
    question2Note:
      'Die Schüler geben hier zwei Dinge ein, über die sie mehr wissen möchten. Damit erhöhen Sie nicht nur das Engagement, sondern geben Sie den Schülern auch mehr Eigenverantwortung.',
    question3: 'Stelle Dir 1 Frage zu etwas, das Du noch nicht so gut verstanden hast.',
    question3Note:
      'Die Schüler geben hier (in Form einer Frage) an, mit welchem Teil des Materials sie noch Schwierigkeiten haben. Für den Lehrer bietet dies nicht nur Einblick in das Verständnis/Niveau der Schüler, sondern auch einen guten Ausgangspunkt für die nächste Lektion.',
  },
  french: {
    question1: 'Écrivez 3 choses que vous avez apprises lors de cette leçon.',
    question1Note:
      "Les élèves écrivent trois choses qu'ils ont apprises au cours de cette leçon. Cela leur permet de signaler leur propre rendement d'apprentissage de cette leçon.",
    question2: 'Écrivez 2 choses sur lesquelles vous souhaitez en savoir plus.',
    question2Note:
      'Les élèves écrivent deux choses sur lesquelles ils aimeraient en savoir plus. Cela non seulement augmente leur engagement, mais leur donne également plus de responsabilité.',
    question3: "Posez 1 question sur quelque chose que vous n'avez pas bien compris.",
    question3Note:
      "Les élèves indiquent (sous forme de question) la partie de la matière qui leur pose encore des difficultés. Pour l'enseignant, cela offre non seulement un aperçu du niveau de compréhension/maîtrise des élèves de la matière, mais aussi un bon point de départ pour la prochaine leçon.",
  },
  spanish: {
    question1: 'Escribe 3 cosas que hayas aprendido en esta lección',
    question1Note:
      'Los alumnos escriben aquí 3 cosas que han aprendido en esta lección. Al hacerlo, indican su propio aprendizaje de esta lección.',
    question2: 'Escribe 2 cosas sobre las que te gustaría aprender más.',
    question2Note:
      'Aquí los alumnos introducen dos cosas sobre las que les gustaría saber más. Esto no sólo aumenta el compromiso, sino también el nivel de involucración.',
    question3: 'Haz 1 pregunta sobre algo que aún no has entendido muy bien',
    question3Note:
      'Aquí los alumnos indican (en forma de pregunta) con qué parte del material aún tienen dificultades. Para el profesor, esto no sólo proporciona una idea de hasta qué punto los alumnos comprenden/manejan el material, sino que es también un buen punto de partida para una lección posterior.',
  },
};

function simpleIdentifierForTitle(title: string) {
  return title.toLowerCase().substring(0, 15).replace(/\s/, '_');
}

interface PassPromptBetweenMaiaModesParams {
  lessonType: MaiaLessonType;
  setLessonType: React.Dispatch<React.SetStateAction<MaiaLessonType | undefined>>;
  prompt: string | undefined;
  setTempSavedPrompt: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function passPromptBetweenMaiaModes({
  prompt,
  lessonType,
  setLessonType,
  setTempSavedPrompt,
}: PassPromptBetweenMaiaModesParams) {
  setLessonType(lessonType);
  setTempSavedPrompt(prompt);
}
