import { Timer } from '@lessonup/ui-components';
import React, { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';

export interface TimerWrapperProps {
  isRecording: boolean;
}

export const TimerWrapper: React.FC<TimerWrapperProps> = ({ isRecording }) => {
  const [timerSecond, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: 1,
    intervalMs: 1000,
    isIncrement: true,
  });

  useEffect(() => {
    if (isRecording) {
      startCountdown();
    } else {
      stopCountdown();
      resetCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  return <Timer seconds={timerSecond - 1} />;
};
