import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Button } from '../../Button/Button';
import { IconChevronDown, IconChevronUp } from '../../icons';
import { ModalHeaderV1 } from '../../Modal/ModalHeader/v1/ModalHeader';

export type ModalStickyBottomContentProps = {
  children: ReactNode;
  collapsed?: boolean;
  onCollapse?: () => void;
  onClose?: () => void;
  label: string;
} & Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, 'aria-live' | 'aria-label'>;

export const ModalStickyBottomContent = ({
  collapsed,
  onCollapse,
  onClose,
  children,
  label,
  'aria-live': ariaLive,
  'aria-label': ariaLabel,
}: ModalStickyBottomContentProps) => {
  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: label }}
        showDivider={!collapsed}
        onCloseButtonClick={onClose}
        endSlot={
          onCollapse && (
            <Button
              buttonType="neutral"
              iconStart={collapsed ? <IconChevronUp /> : <IconChevronDown />}
              onClick={onCollapse}
              size="small"
            />
          )
        }
      />

      {!collapsed && (
        <Content aria-live={ariaLive || 'assertive'} aria-label={ariaLabel}>
          {children}
        </Content>
      )}
    </>
  );
};

const Content = styled.section``;
