import { Button, IconLogoGoogle, IconLogoOffice365 } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import * as React from 'react';

export type SSOSignupProps = {
  t: TFunction;
  handleRegisterSSO: (system: string) => void;
};

export const SSOSignup = (props: SSOSignupProps) => {
  const { t, handleRegisterSSO } = props;

  return (
    <React.Fragment>
      <Button
        buttonType="neutral"
        showStroke
        resizing="fixed"
        iconStart={<IconLogoGoogle />}
        onClick={() => handleRegisterSSO('google')}
      >
        {t('credentialsStep.googleLogin')}
      </Button>
      <Button
        buttonType="neutral"
        showStroke
        resizing="fixed"
        iconStart={<IconLogoOffice365 />}
        onClick={() => handleRegisterSSO('office365')}
      >
        {t('credentialsStep.office365Login')}
      </Button>
    </React.Fragment>
  );
};
