import { NiceModalAction, NiceModalStore, reducer } from '@ebay/nice-modal-react';

let store: NiceModalStore = {};

type Listener = () => void;

let listeners: Listener[] = [];

export const modalStore = {
  subscribe(listener: Listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return store;
  },
  dispatch(action: NiceModalAction) {
    store = reducer(store, action);
    emitChange();
  },
};

function emitChange() {
  for (const listener of listeners) {
    listener();
  }
}
