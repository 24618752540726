import { WebSocketStatus } from '@hocuspocus/provider';
import { Button, IconCloudConnectionLost, IconCloudUploadDone, IconWarning, styled } from '@lessonup/ui-components';
import React, { useRef } from 'react';
import { useEditorTranslation } from '../../../../hooks/useEditorTranslation';
import { HocusPocusApi } from '../../../../utils/yjs/yjs.types';
import { useSaveIndicatorTooltip } from './useSaveIndicatorTooltip';

export interface SaveIndicatorProps {
  hocusPocusApi: HocusPocusApi;
  teachAfterSaving: boolean;
}

export const SaveIndicator: React.FC<SaveIndicatorProps> = ({ hocusPocusApi, teachAfterSaving }) => {
  const { t } = useEditorTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const parentSelector = () => buttonRef.current;

  const { saveState, connectionState } = hocusPocusApi;
  const { showModal, hideModal } = useSaveIndicatorTooltip(
    parentSelector,
    teachAfterSaving,
    saveState,
    connectionState
  );

  const icon =
    saveState !== 'limbo' ? (
      connectionState !== WebSocketStatus.Connected ? (
        <IconCloudConnectionLost />
      ) : (
        <IconCloudUploadDone />
      )
    ) : (
      <IconWarning />
    );
  const showLoading = saveState === 'unsaved' && connectionState === WebSocketStatus.Connected;

  return (
    <StyledButtonContainer onMouseEnter={showModal} onMouseLeave={hideModal} onFocus={showModal} onBlur={hideModal}>
      <StyledButton
        ref={buttonRef}
        onClick={hideModal}
        buttonType="neutral"
        iconStart={icon}
        loading={showLoading}
        aria-label={t('topBar.saveIndicator.ariaLabel')}
      />
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled.div`
  width: fit-content;
`;

const StyledButton = styled(Button)`
  background: none !important;
`;
