import { spacing, styled, useErrorContext } from '@lessonup/ui-components';
import { MimeTypes } from '@lessonup/utils';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFolder } from '../../types/graphql';
import { UploadsContextHeader } from './components/UploadsContextHeader/UploadsContextHeader';
import { UploadsPaginationWithDispatch } from './components/UploadsPagination/UploadsPagination';
import { NavigationState } from './hooks/useNavigationDispatcher';
import { useUploadsStatusPollingProps, useUploadStatusPolling } from './hooks/useUploadStatusPolling';
import { ChildUploadFolder } from './Uploads.graphql';
import { UploadsBreadcrumbList } from './UploadsBreadcrumbList';
import { UploadsExplorer } from './UploadsExplorer';
import { i18nextNamespace, isUpload, isUploading } from './UploadsFeature.utils';

export interface UploadsProps {
  breadcrumbs?: UploadFolder['breadcrumbs'];
  navigationState: NavigationState;
  totalCount: number;
  uploads: ChildUploadFolder[];
  uploadFolderId?: string;
  uploadFolderName?: string;
  acceptedMimeTypes: MimeTypes;
}

export function Uploads(props: UploadsProps) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { t } = useTranslation(i18nextNamespace);

  useEffect(() => {
    if (selectedRows.length) {
      setSelectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uploadFolderId, props.navigationState.page]);
  const { setError } = useErrorContext();
  const onPollingError: useUploadsStatusPollingProps['onError'] = (error) => setError({ error });
  const { startPollingForIds } = useUploadStatusPolling({ onError: onPollingError });
  const uploadIdsInProgress = map(props.uploads.filter(isUpload).filter(isUploading), 'id');

  const sortedUploadsIdsInProgressString: string = uploadIdsInProgress.sort().toString();
  useEffect(() => {
    startPollingForIds(uploadIdsInProgress);
    // Make sure useEffect only refreshes when uploadIdsInProgress actually gets different values.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedUploadsIdsInProgressString]);

  const totalUploads = props.totalCount || 0;
  const isSearch = props.navigationState.isSearch;

  // We don't allow sorting when searching.
  const sortBy = isSearch ? undefined : props.navigationState.sorting;

  return (
    <StyledUploadsFeature>
      <HeaderContainer>
        <UploadsBreadcrumbList
          breadcrumbs={props.breadcrumbs || []}
          title={isSearch ? t('searchResults') : t('rootFolder')}
        />
      </HeaderContainer>
      <UploadsContextHeader
        uploads={props.uploads || []}
        uploadFolderId={props.uploadFolderId}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchQuery={props.navigationState.search?.query}
        acceptedMimeTypes={props.acceptedMimeTypes}
      />
      <ExplorerContainer>
        <UploadsExplorer
          uploads={props.uploads || []}
          folderId={props.navigationState.folderId || undefined}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          sortBy={sortBy}
          isSearch={isSearch}
          acceptedMimeTypes={props.acceptedMimeTypes}
        />
      </ExplorerContainer>
      {totalUploads > 0 && (
        <FooterContainer>
          <UploadsPaginationWithDispatch
            total={totalUploads}
            perPage={props.navigationState.perPage}
            page={props.navigationState.page}
          />
        </FooterContainer>
      )}
    </StyledUploadsFeature>
  );
}

const StyledUploadsFeature = styled.div`
  padding: ${spacing.size16};
  position: relative;
  width: 100%;
  max-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderContainer = styled.div`
  flex-shrink: 0;
`;

const ExplorerContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const FooterContainer = styled.div`
  flex-shrink: 0;
`;
