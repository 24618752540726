/* eslint-disable @typescript-eslint/no-explicit-any */ // we disable it here because we need this to match the apollo types
import { useState } from 'react';
import { useDocumentQuery } from './useDocumentQuery';

/**
 * Wrapper around the `useDocumentQuery` hook to make it returnin the previous query data while loading a new one.
 */
export const useStaggeredDocumentQuery: typeof useDocumentQuery = (document, options) => {
  const [dataInstance, setDataInstance] = useState<any>();
  const computedOptions = {
    ...(options ?? {}),
    onCompleted: (data: any) => {
      setDataInstance(data);
      options?.onCompleted && options.onCompleted(data);
    },
  };

  const queryResults = useDocumentQuery(document, {
    errorPolicy: 'all',
    ...computedOptions,
  });

  return { ...queryResults, data: dataInstance };
};
