import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { ToastContentProps } from 'react-toastify';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../BodyText/BodyText';
import { Button } from '../Button/Button';
import { Headline } from '../Headline/Headline';
import { IconClose } from '../icons';
import type { ToastData } from './toastHandler';

export interface ToastInnerProps extends ToastData {
  showCloseButton: boolean;
}

export const ToastInner = ({ closeToast, data }: ToastContentProps<ToastInnerProps>) => {
  if (!data) return null;
  const withTitle = data.title != undefined;
  return (
    <Wrapper type={data.type ?? 'neutral'}>
      <ToastBodySpaceBetween direction="y" spacing={spacing.size8}>
        {withTitle && (
          <ToastHeadline size="small" addPadding={data.showCloseButton}>
            {data.title}
          </ToastHeadline>
        )}
        <ToastBodyText size="medium" addPadding={!withTitle && data.showCloseButton}>
          {data.message}
        </ToastBodyText>
        {data.button && <ToastButtons>{data.button}</ToastButtons>}
      </ToastBodySpaceBetween>
      {data.showCloseButton && (
        <CloseButtonWrapper>
          <Button onClick={closeToast} buttonType="neutral" size="small" iconStart={<IconClose />} />
        </CloseButtonWrapper>
      )}
    </Wrapper>
  );
};

type WrapperData = Pick<ToastData, 'type'>;
const Wrapper = styled.div<WrapperData>`
  min-height: var(--toastify-toast-min-height);
  padding: ${spacing.size16};
  ${(props) => {
    switch (props.type) {
      case 'error':
        return css`
          background-color: ${color.additional.errorContainer.background};
          color: ${color.additional.errorContainer.text};
        `;
      case 'neutral':
        return css`
          background-color: ${color.neutral.surface.background};
          color: ${color.neutral.surface.text};
        `;
    }
  }}
`;

const ToastButtons = styled.div`
  align-self: flex-start;
`;

// the 35px is based on the size of the Toast in the package react-toastify
const ToastBodySpaceBetween = styled(SpaceBetween)`
  min-height: 35px;
  justify-content: center;
`;

const optionalPadding = ({ addPadding }: { addPadding: boolean }) =>
  addPadding
    ? css`
        padding-right: 30px;
      `
    : undefined;

const ToastHeadline = styled(Headline)<{ addPadding: boolean }>`
  ${optionalPadding};
`;

const ToastBodyText = styled(BodyText)<{ addPadding: boolean }>`
  ${optionalPadding};
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: ${spacing.size16};
  top: ${spacing.size16};
`;
