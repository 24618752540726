import { boxFromLayout, ImagePinComponentRenderer } from '@lessonup/pin-renderer';
import { FocusMode } from '@lessonup/players-modern';
import { styled } from '@lessonup/ui-components';
import { assertNever } from '@lessonup/utils';
import React from 'react';
import { playerScreenSize } from '../../../../dataComponents/PlayerScreens/playerScreens.utils';
import { FocusModeSpinner } from './FocusModeSpinner';

export const FocusModeScreen: React.FC<{ focusMode: FocusMode }> = ({ focusMode }) => {
  return (
    <div
      style={{
        width: playerScreenSize.width,
        height: playerScreenSize.height,
        maxHeight: playerScreenSize.height,
      }}
    >
      <FocusModeWrapper>
        <FocusModeSwitch focusMode={focusMode} />
      </FocusModeWrapper>
    </div>
  );
};

export interface FocusModeSwitchProps {
  focusMode: FocusMode;
}
export const FocusModeSwitch: React.FC<FocusModeSwitchProps> = ({ focusMode }) => {
  switch (focusMode.type) {
    case 'SPINNER': {
      return <FocusModeSpinner focusMode={focusMode} />;
    }
    case 'IMAGE': {
      const box = boxFromLayout(focusMode.pinComponent.layout);
      return (
        <div style={{ height: box.size.height, width: box.size.width }}>
          <ImagePinComponentRenderer settings={focusMode.pinComponent.settings} layout={box} />
        </div>
      );
    }
    default: {
      assertNever(focusMode, `Unhandled FocusMode type ${focusMode}`);
    }
  }
};

const FocusModeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: black;
`;
