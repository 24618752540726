import { create, show, useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../../foundations/layout/breakpoints';
import { spacing } from '../../../foundations/spacing/spacing';
import { List } from '../../List/List';
import { ListItemProps } from '../../List/ListItem/ListItem';
import { ModalPopover } from '../../ModalPopover/ModalPopover';
import { ModalPopOverParentSelector } from '../../ModalPopover/types/modalPopover.types';

type MenuItems = ListItemProps[];

export interface ProfileLinkMenuProps {
  parentSelector: ModalPopOverParentSelector;
  menuItems: MenuItems;
  contentLabel?: string;
}

const ProfileLinkMenu = ({ parentSelector, menuItems, contentLabel }: ProfileLinkMenuProps) => {
  const { t } = useTranslation('profileLinkMenu');
  const modal = useModal();

  const itemsWithOnClickClose = menuItems.map((item) => ({
    ...item,
    indentation: true,
    onClick: () => {
      item.onClick?.();
      modal.hide();
    },
  }));

  return (
    <StyledModalPopover
      modal={modal}
      contentLabel={contentLabel ?? t('ariaLabel')}
      parentSelector={parentSelector}
      direction="bottom"
      modalAnchor="end"
      spaceBetweenContainer={spacing.size16}
      offset={8}
    >
      <StyledModalContent>
        <List items={itemsWithOnClickClose}></List>
      </StyledModalContent>
    </StyledModalPopover>
  );
};

const StyledModalPopover = styled(ModalPopover)`
  border: none;
`;

const StyledModalContent = styled.div`
  width: 100vw;
  max-width: calc(100vw - (${spacing.size16} * 2));
  border-collapse: collapse;

  @media (min-width: ${breakpoints.xs}) {
    max-width: 320px;
  }
  padding: ${spacing.size8} 0;
`;

export const ProfileLinkModal = create(ProfileLinkMenu);

export const showProfileLinkMenu = (props: ProfileLinkMenuProps): Promise<boolean> => show(ProfileLinkModal, props);
