import { AnswerAreaPinComponentWrapper } from '@lessonup/pin-renderer';
import { AnswerAreaPinComponent, Pin } from '@lessonup/pins-shared';
import { OpenQuestionPinAnswer, usePlayerDispatcher } from '@lessonup/players-modern';
import { Card, Chip, spacing, styled } from '@lessonup/ui-components';
import { isEqual } from 'lodash';
import React from 'react';
import { AnswerCardContent } from '../../../../../components/AnswerCardContent/AnswerCardContent';
import { useAnswerAreaValues } from '../../../../hooks/useAnswerAreaValues';
import { maximizedLayout } from '../../../../hooks/useMaximiseAnswerAreaComponent';
import { limitStudentNameLength } from '../../../../utils/limitStudentNameLength';

export interface AnswerAreaPinComponentRealtimePlayerProps {
  pinComponent: AnswerAreaPinComponent;
  layoutKey?: Pin['settings']['layout']; //Todo this should have better typing
}

export const AnswerAreaPinComponentRealtimePlayer: React.FC<AnswerAreaPinComponentRealtimePlayerProps> = ({
  pinComponent,
  layoutKey,
}) => {
  const answers = useAnswerAreaValues();
  const dispatcher = usePlayerDispatcher();
  const isMaximized = isEqual(pinComponent.layout, maximizedLayout);
  const columns = layoutKey === 'FIFTY_FIFTY' && !isMaximized ? 2 : 3;

  const handleReveal = (e: React.MouseEvent, answer: OpenQuestionPinAnswer) => {
    e.stopPropagation();
    dispatcher({
      type: 'pinTypeAction',
      data: {
        type: 'toggleAnswer',
        answerId: answer.entryId,
        reveal: !answer.revealed,
      },
    });
  };

  return (
    <AnswerAreaPinComponentWrapper settings={pinComponent.settings} active>
      {Array.from({ length: columns }).map((_, i) => (
        <StyledAnswerAreaColumn key={`column-${i}`}>
          {answers.map((answer, j) => {
            if (j % columns !== i || !answer) return null;

            return (
              <Card key={`row-${i}-${j}`} cardTheme="neutral" resizing="fixed" showBoxShadow showBorder={false}>
                <AnswerCardContent
                  answerText={answer.answer}
                  authorSlot={<Chip text={limitStudentNameLength(answer.name)} />}
                  revealed={answer.revealed}
                  handleReveal={(e) => handleReveal(e, answer)}
                />
              </Card>
            );
          })}
        </StyledAnswerAreaColumn>
      ))}
    </AnswerAreaPinComponentWrapper>
  );
};

const StyledAnswerAreaColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${spacing.size16};
  margin-bottom: auto;
`;
