import { Box, PinComponentLayout, Vector } from '@lessonup/pins-shared';
import { assertNever } from '@lessonup/utils';
import { isBoxLayout, isLineLayout } from '../../types';
import { boxCenterPosition, boxFromLine, moveBox } from './box';
import { lineCenterPosition, moveLine } from './line';

export function boxFromLayout(layout: PinComponentLayout): Box {
  return isLineLayout(layout) ? boxFromLine(layout) : layout;
}

export const layoutHasLockedAspectRatio = (layout: PinComponentLayout): boolean =>
  isBoxLayout(layout) && layout.lockAspectRatio === true;

export function getLayoutCenterPosition(layout: PinComponentLayout): Vector {
  return isBoxLayout(layout) ? boxCenterPosition(layout) : lineCenterPosition(layout);
}
export function moveLayout(layout: PinComponentLayout, displacement: Vector): PinComponentLayout {
  if (isLineLayout(layout)) {
    return moveLine(layout, displacement);
  }

  if (isBoxLayout(layout)) {
    return moveBox(layout, displacement);
  }

  assertNever(layout, 'handleMovePinComponents not implemented for layout type');
}
