import { useLocale } from '@lessonup/client-integration';
import {
  Checkbox,
  color,
  DefinitionList,
  DefinitionListProps,
  Divider,
  FilePreview,
  IconDownload,
  IconEdit,
  IconRemove,
  IconWarning,
  List,
  ModalFooter,
  ModalFooterAction,
  ModalHeaderV1,
  rem,
  SpaceBetween,
  spacing,
  styled,
  TablePreviewSelectOptions,
  Thumb,
} from '@lessonup/ui-components';
import { localeToIsoLocaleString, secondsToPrettyTime } from '@lessonup/utils';

import { xor } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mapPreviewDataToUploadsData, UploadPreviewData } from '../../dataComponents/UploadPreviewModal.utils';
import { useDownloadUpload } from '../../hooks/useDownloadUpload';
import { UseUploadData } from '../../model';
import { humandReadableFileSize, i18nextNamespace } from '../../UploadsFeature.utils';
import { IntroductionMessage } from '../IntroductionMessage/IntroductionMessage';

export const previewModalWidth = rem('960px');
export const previewModalHeight = rem('700px');
export const minimumUploadsPreviewModalSpacing = rem('32px');
const thumbSize = rem('116px');

export type UploadModalPreviewAction = {
  Icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
  text: string;
  onClick: (upload: UseUploadData) => void;
};

export type UploadModalPreviewActions = Partial<
  Record<'primary' | 'secondary', Omit<ModalFooterAction, 'onClick'> & { onClick: (upload: UseUploadData) => void }>
>;

export interface UploadPreviewModalContentProps {
  upload: UploadPreviewData;
  handleDelete: () => void;
  onClose?: () => void;
  onRenameButtonClick: () => void;
  imageResizer?: (url: string, size: number) => string;
  previewActions?: UploadModalPreviewActions;
  options?: TablePreviewSelectOptions;
  isFileMissing?: boolean;
}

export const UploadPreviewModalContent = (props: UploadPreviewModalContentProps) => {
  const { isFileMissing = false, upload, onRenameButtonClick, handleDelete, previewActions } = props;
  const { t } = useTranslation(i18nextNamespace);
  const locale = useLocale();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  const dateFormatter = new Intl.DateTimeFormat(localeToIsoLocaleString(locale), {
    dateStyle: 'short',
  });

  const sharedDefinitionListAttributes: Omit<DefinitionListProps, 'items'> = {
    orientation: 'horizontal',
    priority: 'low',
  };

  const uploadHasDuration = upload.type === 'video' || upload.type === 'audio';

  const handleDownloadUpload = useDownloadUpload();

  const handleDownloadAction = () => {
    handleDownloadUpload(props.upload.id, props.upload.name);
  };

  const getSrcSetFunction = props.imageResizer && srcSetFormatter(props.imageResizer);

  const toggleSelectedPage = (pageNumber: number) => {
    setSelectedPages((selectedPages) => xor(selectedPages, [pageNumber]));
  };

  const pageSelectionEnabled = Boolean(props.options?.allowMultiple && !props.options.asAttachment);

  function selectAllPages() {
    if (upload.type !== 'document') return;

    setSelectedPages(upload.pages.map((_, index) => index));
  }

  return (
    <UploadPreviewModalContentWrapper>
      <ModalHeaderV1 type="headline" title={{ content: upload.name }} onCloseButtonClick={props.onClose} />
      <UploadPreviewModalContentBody>
        {upload.type === 'document' && !isFileMissing && (
          <LeftSideNavigation>
            <SpaceBetween direction="y" spacing={spacing.size16}>
              {pageSelectionEnabled && (
                <Checkbox
                  id={`select-all-${upload.id}`}
                  label={t('previewModal.selectAll')}
                  checked={selectedPages.length === upload.pages.length}
                  onChange={(event) => {
                    event.currentTarget.checked ? selectAllPages() : setSelectedPages([]);
                  }}
                />
              )}
              {upload.pages.map(({ url }, pageIndex) => (
                <Thumb
                  thumbType="preview"
                  number={pageIndex + 1}
                  isActive={pageIndex === currentPage}
                  onClick={() => setCurrentPage(pageIndex)}
                  key={`thumb-page-${pageIndex}`}
                  overwriteCheckboxVisibility={pageSelectionEnabled}
                  checkboxSelection={{
                    enabled: pageSelectionEnabled,
                    checked: selectedPages.includes(pageIndex),
                    onChange: () => toggleSelectedPage(pageIndex),
                  }}
                >
                  <StyledThumbFilePreview
                    {...upload}
                    src={props.imageResizer ? props.imageResizer(url, 90) : url}
                    {...(getSrcSetFunction ? { srcSet: getSrcSetFunction(url, [90, 180, 320]) } : undefined)}
                  />
                </Thumb>
              ))}
            </SpaceBetween>
          </LeftSideNavigation>
        )}
        {isFileMissing ? (
          <PreviewWrapper>
            <IntroductionMessage translationKeyPrefix="previewModal.error.missingFile" icon={<IconWarning />} />
          </PreviewWrapper>
        ) : (
          <PreviewWrapper>
            {upload.type === 'document' ? (
              <StyledFilePreview
                {...upload}
                src={upload.pages[currentPage].url}
                {...(getSrcSetFunction
                  ? { srcSet: getSrcSetFunction(upload.pages[currentPage].url, [400, 800, 1600]) }
                  : undefined)}
              ></StyledFilePreview>
            ) : (
              <StyledFilePreview {...upload}></StyledFilePreview>
            )}
          </PreviewWrapper>
        )}
        <RightSideNavigation>
          <Divider orientation="vertical" noSpacer={true}>
            <NavigationContent>
              <Name>
                <DefinitionList
                  items={[
                    { term: t('previewModal.fileName'), description: upload.name, descriptionOverflow: 'ellipsis' },
                  ]}
                  {...sharedDefinitionListAttributes}
                />
              </Name>
              <Divider orientation="horizontal" />
              <DefinitionList
                items={[
                  ...(uploadHasDuration
                    ? [
                        {
                          term: t('previewModal.duration'),
                          description: secondsToPrettyTime(upload.durationInSeconds),
                        },
                      ]
                    : []),
                  {
                    term: t('previewModal.size'),
                    description: humandReadableFileSize(upload.sizeInBytes, locale),
                  },
                  { term: t('previewModal.type'), description: upload.fileExtension },
                  { term: t('previewModal.uploadDate'), description: dateFormatter.format(upload.createdAt) },
                  ...(upload.type === 'document'
                    ? [
                        {
                          term: t('previewModal.pages'),
                          description: upload.pages.length.toString(),
                        },
                      ]
                    : []),
                ]}
                {...sharedDefinitionListAttributes}
              />
              {!isFileMissing && (
                <>
                  <Divider orientation="horizontal"></Divider>
                  <List
                    items={[
                      {
                        indentation: true,
                        icon: <IconDownload />,
                        text: t('previewModal.download'),
                        onClick: handleDownloadAction,
                      },
                    ]}
                  ></List>
                  <Divider orientation="horizontal" />
                  <List
                    items={[
                      {
                        icon: <IconEdit />,
                        text: t('previewModal.rename'),
                        onClick: onRenameButtonClick,
                        indentation: true,
                      },
                    ]}
                  />
                </>
              )}
              <Divider orientation="horizontal" />
              <List
                items={[
                  {
                    indentation: true,
                    icon: <IconRemove />,
                    text: t('delete'),
                    type: 'destructive',
                    onClick: handleDelete,
                  },
                ]}
              />
            </NavigationContent>
          </Divider>
        </RightSideNavigation>
      </UploadPreviewModalContentBody>
      {previewActions?.primary && (
        <ModalFooter
          type="actions"
          showDivider={true}
          primaryAction={uploadModalActionToFooterAction({
            action: previewActions.primary,
            upload,
            isFileMissing,
            onClose: props.onClose,
            selectedPages: props.options?.allowMultiple ? selectedPages : [currentPage],
          })}
          secondaryAction={
            previewActions.secondary &&
            uploadModalActionToFooterAction({
              action: previewActions.secondary,
              upload,
              isFileMissing,
              onClose: props.onClose,
              selectedPages,
            })
          }
        ></ModalFooter>
      )}
    </UploadPreviewModalContentWrapper>
  );
};

interface UploadModalActionToFooterActionParams {
  action: NonNullable<UploadModalPreviewActions['primary' | 'secondary']>;
  upload: UploadPreviewData;
  isFileMissing: boolean;
  onClose?: () => void;
  selectedPages: number[];
}

function uploadModalActionToFooterAction({
  action,
  upload,
  isFileMissing,
  onClose,
  selectedPages,
}: UploadModalActionToFooterActionParams): ModalFooterAction {
  return {
    ...action,
    onClick: () => {
      const useUploadData = mapPreviewDataToUploadsData(upload, selectedPages);
      action?.onClick(useUploadData);
      onClose?.();
    },
    disabled: isFileMissing,
  };
}

function srcSetFormatter(resizer: (url: string, size: number) => string) {
  return (url: string, sizes: number[]): string => {
    return sizes.map((size, i) => `${resizer(url, size)} ${i + 1}x`).join(', ');
  };
}

const UploadPreviewModalContentWrapper = styled.div`
  width: ${previewModalWidth};
  height: ${previewModalHeight};
  max-width: ${`calc(100vw - ${minimumUploadsPreviewModalSpacing})`};
  max-height: ${`calc(100vh - ${minimumUploadsPreviewModalSpacing})`};
  display: flex;
  flex-direction: column;
`;

const UploadPreviewModalContentBody = styled.div`
  display: flex;
  flex-grow: 1;
  background: ${color.neutral.surface.background};
  overflow: hidden;
  flex-wrap: nowrap;
`;

const LeftSideNavigation = styled.nav`
  width: ${rem('176px')};
  padding: ${spacing.size16};
  max-height: 100%;
  overflow-y: auto;
`;

const PreviewWrapper = styled.div`
  background: ${color.neutral.surface1.background};
  flex-grow: 1;
  align-items: center;
  height: 100%;
  position: relative;
  padding-left: ${spacing.size32};
  padding-right: ${spacing.size32};
`;

const StyledFilePreview = styled(FilePreview)`
  top: 50%;
  transform: translate(0, -50%);
`;

const RightSideNavigation = styled.nav`
  width: ${rem('320px')};
  flex-shrink: 0;
`;

const NavigationContent = styled.div`
  width: 100%;
`;

const Name = styled.div`
  padding-top: ${spacing.size16};
  padding-bottom: ${spacing.size8};
`;

const StyledThumbFilePreview = styled(FilePreview)`
  width: ${thumbSize};
`;
