import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';
import { EduSystemBase } from './EduSystemBase';
import {
  eduSystemEditDataDuration,
  eduSystemEditDataLevel,
  eduSystemEditDataSchoolType,
  eduSystemEditDataSubject,
  eduSystemEditDataTheme,
  eduSystemEditDataYear,
  stringToNumberSuggestor,
} from './eduSystemShared';
declare const Lessons;
const i18n = globalI18nProxy();

const levelSuggestor: EduSystem.Suggestor<string[]> = ({ value, fieldOptions }) => {
  const vmbo: string[] = [];
  const levels: string[] = [];
  _.each(fieldOptions, function (sug) {
    if (value && _.includes(value, sug.id)) {
      const level = sug.value;
      // keep separate track of vmbo for shorthand notation
      if (level.indexOf('vmbo') == 0) {
        vmbo.push(level.substr(5));
      } else {
        levels.push(level);
      }
    }
  });
  // shorthand for all vmbo types
  const vmboStr = vmbo.length >= 4 ? 'vmbo' : vmbo.length ? ['vmbo', vmbo.join(', ')].join(' ') : '';
  if (vmboStr) {
    levels.unshift(vmboStr);
  }

  return levels.join(', ');
};

type SchoolType = 'po' | 'vo' | 'pr' | 'nl_so' | 'vso' | 'mbo' | 'hbo' | 'wo' | 'bo' | 'nl_isk';
export type EduSystemNLSchoolType = SchoolType;
export class EduSystemNL extends EduSystemBase<SchoolType> {
  public key = 'NL';
  public country() {
    return 'nl' as const;
  }
  public constructor() {
    super('vo');
  }
  public EDIT_DATA() {
    return [
      eduSystemEditDataDuration(() => i18n.__('Lesduur')),
      eduSystemEditDataSchoolType(() => i18n.__('School')),
      eduSystemEditDataLevel(() => i18n.__('Niveau'), levelSuggestor),
      eduSystemEditDataLevel(() => i18n.__('Leerroute'), stringToNumberSuggestor, 'learningRoute'),
      eduSystemEditDataYear(() => i18n.__('Groep'), 'groep'),
      eduSystemEditDataYear(() => i18n.__('Fase'), 'phase'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar'), 'grade'),
      eduSystemEditDataYear(() => i18n.__('Studiejaar'), 'studyYear'),
      eduSystemEditDataYear(() => i18n.__('Leeftijd'), 'age'),
      eduSystemEditDataSubject(() => i18n.__('Vak'), ['subjects', 'NLSubjects']),
      eduSystemEditDataTheme(() => i18n.__('Thema'), 'NLthemes'),
    ];
  }
  protected _SYSTEM_DATA() {
    const dict: EduSystem.SystemDataDict<SchoolType> = {
      po: {
        id: 'po',
        short: 'PO',
        label: 'Basisschool',
        prefix: 'de',
        fields: [
          {
            id: 'subject',
            // rest is extended in class
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'groep',
            type: 'toggle',
            label: () => i18n.__('Groep'),
            labeler: 'LabelValue',
            options: [
              { id: 4, value: '1' },
              { id: 5, value: '2' },
              { id: 6, value: '3' },
              { id: 7, value: '4' },
              { id: 8, value: '5' },
              { id: 9, value: '6' },
              { id: 10, value: '7' },
              { id: 11, value: '8' },
            ],
          },
        ],
      },
      vo: {
        id: 'vo',
        short: 'VO',
        label: 'Middelbare school',
        prefix: 'de',
        //defaultExpanded: true,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Niveau'),
            labeler: 'ValueOnly',
            optionOrderIsSet: true,
            options: [
              { id: 'VL', value: 'vmbo lwoo' },
              { id: 'VB', value: 'vmbo b' },
              { id: 'VK', value: 'vmbo k' },
              { id: 'VG', value: 'vmbo g' },
              { id: 'VT', value: 'vmbo t' },
              { id: 'M', value: 'mavo' },
              { id: 'H', value: 'havo' },
              { id: 'V', value: 'vwo' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 12, value: '1' },
              { id: 13, value: '2' },
              { id: 14, value: '3' },
              { id: 15, value: '4' },
              { id: 16, value: '5' },
              { id: 17, value: '6' },
            ],
          },
        ],
      },
      pr: {
        id: 'pr',
        short: 'PR',
        label: 'Praktijkonderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 12, value: '1' },
              { id: 13, value: '2' },
              { id: 14, value: '3' },
              { id: 15, value: '4' },
              { id: 16, value: '5' },
            ],
          },
        ],
      },
      nl_so: {
        id: 'nl_so',
        short: 'SO',
        label: 'Speciaal Onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'learningRoute',
            type: 'toggle',
            label: () => i18n.__('Leerroute'),
            labeler: 'ValueOnly',
            optionOrderIsSet: true,
            options: [
              { id: 1, value: '1' },
              { id: 2, value: '2' },
              { id: 3, value: '3' },
              { id: 4, value: '4' },
              { id: 5, value: '5' },
              { id: 6, value: '6' },
              { id: 7, value: '7' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'groep',
            type: 'toggle',
            label: () => i18n.__('Groep'),
            labeler: 'LabelValue',
            options: [
              { id: 4, value: '1' },
              { id: 5, value: '2' },
              { id: 6, value: '3' },
              { id: 7, value: '4' },
              { id: 8, value: '5' },
              { id: 9, value: '6' },
              { id: 10, value: '7' },
              { id: 11, value: '8' },
            ],
          },
          {
            id: 'subject',
            // rest is extended in class
          },
        ],
      },
      vso: {
        id: 'vso',
        short: 'VSO',
        label: 'Voortgezet speciaal onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'learningRoute',
            type: 'toggle',
            label: () => i18n.__('Leerroute'),
            labeler: 'ValueOnly',
            optionOrderIsSet: true,
            options: [
              { id: 1, value: '1' },
              { id: 2, value: '2' },
              { id: 3, value: '3' },
              { id: 4, value: '4' },
              { id: 5, value: '5' },
              { id: 6, value: '6' },
              { id: 7, value: '7' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'phase',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'ValueOnly',
            options: [
              { id: 12, value: 'onderbouw' },
              { id: 14, value: 'middenbouw' },
              { id: 16, value: 'bovenbouw' },
            ],
          },
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'theme',
            lessonProp: 'themes',
            reference: 'themes',
            type: 'inputSelect',
            label: () => i18n.__('Thema'),
            placeholder: () => i18n.__('Kies je thema'),
            async save({ target, field, value, multiple, shortList }) {
              // when saved in a multi-select editor, add to set
              if (multiple) {
                await Lessons.updateAsync({ _id: target._id }, { $addToSet: { themes: value } });
              } else {
                // otherwise save only one
                await Lessons.updateAsync({ _id: target._id }, { $set: { themes: [value] } });
              }
              // just save shortlist
            },
          },
        ],
      },
      mbo: {
        id: 'mbo',
        short: 'MBO',
        label: 'MBO',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Niveau'),
            options: [
              { id: 'n1', value: ' Niveau 1' },
              { id: 'n2', value: 'Niveau 2' },
              { id: 'n3', value: 'Niveau 3' },
              { id: 'n4', value: 'Niveau 4' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            type: 'toggle',
            reference: 'studyYear',
            label: () => i18n.__('Studiejaar'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
            ],
          },
        ],
      },
      hbo: {
        id: 'hbo',
        short: 'HBO',
        label: 'HBO',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            type: 'toggle',
            reference: 'studyYear',
            label: () => i18n.__('Studiejaar'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
            ],
          },
        ],
      },
      wo: {
        id: 'wo',
        short: 'WO',
        label: 'WO',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            type: 'toggle',
            reference: 'studyYear',
            label: () => i18n.__('Studiejaar'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
              { id: 22, value: '5' },
              { id: 23, value: '6' },
            ],
          },
        ],
      },
      bo: {
        id: 'bo',
        short: 'BO',
        label: 'Beroepsopleiding',
        prefix: 'de',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            type: 'toggle',
            reference: 'studyYear',
            label: () => i18n.__('Studiejaar'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
              { id: 22, value: '5' },
              { id: 23, value: '6' },
            ],
          },
        ],
      },
      nl_isk: {
        id: 'nl_isk',
        short: 'ISK',
        label: 'ISK',
        prefix: 'isk',
        fields: [
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Niveau'),
            labeler: 'ValueOnly',
            options: [
              { id: 'alfa-a', value: 'Alfa A' },
              { id: 'alfa-b', value: 'Alfa B' },
              { id: 'alfa-c', value: 'Alfa C' },
              { id: 'a1', value: 'A1' },
              { id: 'a2', value: 'A2' },
              { id: 'b1', value: 'B1' },
              { id: 'b2', value: 'B2' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'age',
            type: 'toggle',
            label: () => i18n.__('Leeftijd'),
            labeler: 'LabelValue',
            options: [
              { id: 4, value: '12' },
              { id: 5, value: '13' },
              { id: 6, value: '14' },
              { id: 7, value: '15' },
              { id: 8, value: '16' },
              { id: 9, value: '17' },
              { id: 10, value: '18' },
            ],
          },
          {
            id: 'subject',
            // rest is extended in class
          },
        ],
      },
    };
    return dict;
  }

  public labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
    const { subjects, schoolType } = metaData;
    const tags: EduSystem.MetaDataTags = {
      subjects: [],
      schoolType: [],
      levels: [],
      years: [],
    };

    tags.subjects = subjects;
    if (!schoolType) return tags;
    tags.schoolType = _.compact(schoolType.map((st) => this.SYSTEM_DATA_DICT[st]?.label));

    const { hasPO, hasVO, hasPR, hasVSO, hasISK } = hasEduSystem(metaData);
    if (hasPO) {
      const value = this.getFieldSummary({ schoolType: 'po', lesson: metaData, fieldName: 'groep' });
      if (value) {
        tags.years.push(`Groep ${value}`);
      }
    }

    if (hasVO || hasPR) {
      if (hasVO) {
        const value = this.getFieldSummary({ schoolType: 'vo', lesson: metaData, fieldName: 'levels' });
        if (value) {
          tags.levels.push(value);
        }
      }

      const value = this.getFieldSummary({ schoolType: 'vo', lesson: metaData, fieldName: 'grade' });
      if (value) {
        tags.years.push(`Leerjaar ${value}`);
      }
    }

    if (hasVSO && !(hasVO || hasPR)) {
      const value = this.getFieldSummary({ schoolType: 'vso', lesson: metaData, fieldName: 'grade' });
      if (value) {
        tags.years.push(value);
      }
    }
    const HOTypes: SchoolType[] = ['hbo', 'mbo', 'wo'];
    const HOschoolType = HOTypes.find((st) => metaData.schoolType?.includes(st));

    if (HOschoolType) {
      const value = this.getFieldSummary({ schoolType: HOschoolType, lesson: metaData, fieldName: 'studyYear' });
      if (value) {
        tags.years.push(`Studiejaar ${value}`);
      }
    }

    if (hasISK) {
      const value = this.getFieldSummary({ schoolType: 'nl_isk', lesson: metaData, fieldName: 'years' });
      if (value) {
        tags.years.push(`Leeftijd ${value}`);
      }
    }
    return addLearnRoutes(tags, metaData);
  }

  // dont know where we really use this, maybe curricula
  public getYearDict(): EduSystem.YearDict {
    const yearDict: EduSystem.YearDict = {};
    _.each(terms, function (val, key) {
      const year: any = { label: val };
      yearDict[key] = year;

      const age = parseInt(key);
      if (age < 12) {
        year.schoolType = ['PE'];
        year.schoolYearNum = age - 3;
      } else if (age >= 12 && age < 18) {
        year.schoolType = ['SE'];
        year.schoolYearNum = parseInt(val);
        year.label = 'Leerjaar ' + year.label;
      } else {
        year.schoolType = '';
      }
    });
    return yearDict;
  }
}

function hasEduSystem(metaData: EduSystem.MetaDataParams) {
  return {
    hasPO: metaData.schoolType && _.includes(metaData.schoolType, 'po'),
    hasVO: metaData.schoolType && _.includes(metaData.schoolType, 'vo'),
    hasPR: metaData.schoolType && _.includes(metaData.schoolType, 'pr'),
    hasSO: metaData.schoolType && _.includes(metaData.schoolType, 'nl_so'),
    hasVSO: metaData.schoolType && _.includes(metaData.schoolType, 'vso'),
    hasMBO: metaData.schoolType && _.includes(metaData.schoolType, 'mbo'),
    hasHO: metaData.schoolType && metaData.schoolType.some((st) => ['hbo', 'mbo', 'wo'].includes(st)),
    hasISK: metaData.schoolType && _.includes(metaData.schoolType, 'nl_isk'),
  };
}

function addLearnRoutes(tags: EduSystem.MetaDataTags, metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
  const { levels } = metaData;
  const { hasSO, hasVSO } = hasEduSystem(metaData);

  if (!levels || !(hasSO || hasVSO)) return tags;

  return {
    ...tags,
    levels: [...tags.levels, ...levels.map((level) => `${i18n.__('Leerroute')} ${level}`)],
  };
}

export const terms = {
  // Lifted from the old searchLib.js, required for edusystems
  '4': 'Groep 1',
  '5': 'Groep 2',
  '6': 'Groep 3',
  '7': 'Groep 4',
  '8': 'Groep 5',
  '9': 'Groep 6',
  '10': 'Groep 7',
  '11': 'Groep 8',
  '12': '1',
  '13': '2',
  '14': '3',
  '15': '4',
  '16': '5',
  '17': '6',
  '4,5': 'Groep 1/2',
  '6,7': 'Groep 3/4',
  '8,9': 'Groep 5/6',
  '10,11': 'Groep 7/8',
};
