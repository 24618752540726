import { ApolloError } from '@apollo/client';
import { AppError } from '@lessonup/utils';
/**
 * Map an ApolloError to an AppError, so we can show the type of error to the user.
 * Very crude mapping, could be improved a lot
 */
export const apolloErrorToAppError = (error: ApolloError): AppError => {
  const { graphQLErrors, networkError, message } = error;
  if (graphQLErrors && graphQLErrors.length > 0) {
    const { message } = graphQLErrors[0];
    return new AppError('unexpected-data', message, { error });
  }
  if (networkError) {
    return new AppError('server-unavailable', networkError.message, { error });
  }
  return new AppError('unknown', message, { error });
};
