import { LayoutPart, PinComponent, PinComponentLayout } from '@lessonup/pins-shared';
import React, { createContext, useCallback, useContext } from 'react';

interface DispatchContext {
  dispatch: UpdatePinComponentDispatchWithMetadata;
  flushPendingDispatch: () => void;
}

export type UpdatePinComponentAction =
  | {
      type: 'updateComponentLayout';
      pinComponent: PinComponent;
      updates: Record<string, PinComponentLayout>;
    }
  | {
      type: 'updateComponentLayoutPart';
      pinComponent: PinComponent;
      layoutPart: LayoutPart;
    }
  | {
      type: 'updatePinComponentSettings';
      pinComponent: PinComponent;
      debounce?: number;
      settings: Partial<PinComponent['settings']>;
    }
  | {
      type: 'clearDryRun';
    };

export type UpdatePinComponentActionWithMetadata = UpdatePinComponentAction & {
  pinId: string;
  pinComponent: PinComponent;
};

export type UpdatePinComponentActionWithMetaSingleOrMulti =
  | UpdatePinComponentActionWithMetadata
  | UpdatePinComponentActionWithMetadata[];

export type UpdatePinComponentDispatchWithMetadata = React.Dispatch<UpdatePinComponentActionWithMetaSingleOrMulti>;

const defaultImplementation: DispatchContext = {
  dispatch: () => {
    console.warn('no dispatch for updatePinContext');
  },
  flushPendingDispatch: () => {
    console.warn('no flush_pending for updatePinContext');
  },
};

const updatePinComponentContext = createContext<DispatchContext>(defaultImplementation);

interface DispatchContextProviderProps {
  value: {
    dispatch: UpdatePinComponentDispatchWithMetadata;
    flushPendingDispatch?: () => void;
  };
  children?: React.ReactNode;
}

export const UpdatePinComponentContextProvider: React.FC<DispatchContextProviderProps> = (props) => {
  const { value, children } = props;
  const newValue: DispatchContext = {
    ...value,
    flushPendingDispatch: value.flushPendingDispatch ?? defaultImplementation.flushPendingDispatch,
  };
  return <updatePinComponentContext.Provider value={newValue}> {children} </updatePinComponentContext.Provider>;
};

export const useUpdatePinComponent = (): UpdatePinComponentDispatchWithMetadata => {
  return useContext(updatePinComponentContext).dispatch;
};

export const usePinComponentFlushPendingDispatch = (): (() => void) => {
  return useContext(updatePinComponentContext).flushPendingDispatch;
};

export const useUpdatePinComponentWithMetadataDispatch = (
  dispatch: UpdatePinComponentDispatchWithMetadata
): UpdatePinComponentDispatchWithMetadata => {
  return useCallback(
    (actions) => {
      if (Array.isArray(actions)) {
        dispatch(actions.map((action) => ({ ...action, pinComponent: action.pinComponent })));
      } else {
        dispatch({ ...actions });
      }
    },
    [dispatch]
  );
};
