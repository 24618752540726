import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PartialPrefix } from '@lessonup/utils';
import type * as CSS from 'csstype';
import React, { PropsWithChildren } from 'react';
import { spacing } from '../../foundations/spacing/spacing';

interface GridProps {
  gap?: spacing;
  cols?: number;
  autoColumns?: CSS.Property.GridAutoColumns;
  alignItems?: CSS.Property.AlignItems;
  justifyItems?: CSS.Property.JustifyItems;
  className?: string;
  as?: keyof React.JSX.IntrinsicElements;
}

type GridStyledProps = PartialPrefix<GridProps, 'cols'>;

export function Grid({
  as,
  children,
  gap,
  cols,
  alignItems,
  autoColumns,
  justifyItems,
  className,
}: PropsWithChildren<GridProps>) {
  return (
    <GridStyled
      as={as}
      gap={gap}
      $cols={cols}
      alignItems={alignItems}
      autoColumns={autoColumns}
      justifyItems={justifyItems}
      className={className}
    >
      {children}
    </GridStyled>
  );
}

const GridStyled = styled.div<GridStyledProps>`
  display: grid;
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.alignItems};
  justify-items: ${(props) => props.justifyItems};

  // create equal sized columns
  ${(props) =>
    props.$cols &&
    !props.autoColumns &&
    css`
      grid-template-columns: repeat(${props.$cols}, 1fr);
    `}

  // create auto sized columns
  grid-auto-columns: ${(props) => props.autoColumns};
  ${(props) =>
    props.autoColumns &&
    props.$cols &&
    css`
      grid-template-areas: '${Array(props.$cols)
        .fill('section')
        .join(' ')}'; // create "section section" (based on the amount of columns)
    `}
`;
