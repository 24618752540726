import { globalGTAG } from './gtag';
import { trackAction } from './trackActionClient';

type OptimizeCallback = (value: string, name: string) => void;

let trackedExperiments: string[] = [];

/** Make sure this is only called once */
export function initializeOptimizeCallbacks(): void {
  const gtag = globalGTAG();
  trackedExperiments = [];
  return gtag('event', 'optimize.callback', { callback });
}

const callback: OptimizeCallback = (variantId: string, experimentId: string) => {
  if (!experimentId || !variantId || trackedExperiments.includes(experimentId)) {
    return;
  }

  trackedExperiments.push(experimentId);
  trackAction('init_ab_test', {
    experimentId,
    variantId,
  });
};
