import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { ListHeader, ListHeaderProps } from '../ListHeader/ListHeader';

export interface PageSectionProps {
  headerProps?: {
    text: ListHeaderProps['text'];
    startSlot?: ListHeaderProps['startSlot'];
    subtitle?: ListHeaderProps['subtitle'];
  };
  hideIndentation?: boolean;
  showBorder?: boolean;
}

export const PageSection: React.FC<PropsWithChildren<PageSectionProps>> = ({
  children,
  headerProps,
  hideIndentation,
  showBorder,
}) => {
  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      {headerProps && (
        <ListHeader
          priority="low"
          text={headerProps.text}
          subtitle={headerProps.subtitle}
          startSlot={headerProps.startSlot}
        />
      )}
      <StyledContentWrapper hideIndentation={hideIndentation} showBorder={showBorder}>
        {children}
      </StyledContentWrapper>
    </SpaceBetween>
  );
};

interface StyledContentWrapperProps {
  showBorder?: PageSectionProps['showBorder'];
  hideIndentation?: PageSectionProps['hideIndentation'];
}

const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  background: ${color.neutral.surface.background};
  padding: ${(props) => (props.hideIndentation ? spacing.size0 : props.showBorder ? spacing.size12 : spacing.size16)};
  border-radius: ${borderRadius.rounded8};
  border: ${(props) => (props.showBorder ? spacing.size4 : spacing.size0)} solid ${color.neutral.surface1.background};
`;
