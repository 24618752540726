import {
  PinComponentSwitch,
  PinScaleWrapper,
  pinSize,
  useLinkedPinComponents,
  usePinResizeObserver,
  useSortPinComponents,
} from '@lessonup/pin-renderer';
import { TeacherPin } from '@lessonup/pins-shared';
import { useFocusMode, useHandleMouseEventsForPlayer } from '@lessonup/players-modern';
import { color, cssBase, cx, isLeftMBClick, isRightMBClick, styled } from '@lessonup/ui-components';
import React, { useCallback, useRef } from 'react';
import { PlayerFAM } from '../../../components/PlayerFAM/PlayerFAM';
import { FocusModeScreen } from '../playerScreens/FocusMode/FocusModeScreen';
import { PinComponentSwitchRendererRealtimePlayer } from './PinComponentSwitchRealtimePlayer/PinComponentSwitchRealtimePlayer';
import { PinSwitchRealtimePlayer } from './PinSwitchRealtimePlayer/PinSwitchRealtimePlayer';

export interface PinViewerRealtimePlayerProps {
  pin: TeacherPin;
  withFAM?: boolean;
}

/**
 * This will render a complete pin for the player
 * Only action that is supported is the move action for single components
 * Should be inside a UpdatePinComponentContextProvider if you want to persist the changes
 */
export const PinViewerRealtimePlayer: React.FC<PinViewerRealtimePlayerProps> = ({ pin, withFAM }) => {
  const pinComponents = useSortPinComponents(pin.pinComponents);
  const pinWrapperRef = useRef<HTMLDivElement>(null);
  const focusMode = useFocusMode();

  const { scale, pinRendererOffset, resizeObserverInitialized } = usePinResizeObserver(pinWrapperRef);
  const { mutatingPinComponent, handleOnMouseDown, handleOnMouseUp } = useHandleMouseEventsForPlayer(
    scale,
    pinRendererOffset,
    pinComponents
  );

  const { isLinkedToInactiveHotspot } = useLinkedPinComponents(pinComponents);

  const handlePickOnMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isLeftMBClick(event)) {
        handleOnMouseDown(event, { action: 'select' });
      } else if (isRightMBClick(event)) {
        handleOnMouseDown(event, { action: 'select-pin-components', componentIds: [] });
      }
    },
    [handleOnMouseDown]
  );

  return (
    <StyledPinWrapper ref={pinWrapperRef} onMouseDown={handlePickOnMouseDown}>
      <PinScaleWrapper scale={scale} resizeObserverInitialized={resizeObserverInitialized}>
        <StyledPin className={cx(focusMode && hidePinInFocusmode)} style={{ transform: `scale(${scale})` }}>
          <PinSwitchRealtimePlayer pin={pin} pinScale={scale}>
            {pinComponents.map((pinComponent) => {
              const latestPinComponent =
                mutatingPinComponent?.id === pinComponent.id ? mutatingPinComponent : pinComponent;
              const linkedComponent = isLinkedToInactiveHotspot(pinComponent.id);

              return (
                <PinComponentSwitch
                  pinId={pin.id}
                  pinType={pin.type}
                  layoutKey={pin.settings.layout}
                  key={pinComponent.id}
                  pinComponent={latestPinComponent}
                  onMouseDown={handleOnMouseDown}
                  editable={false}
                  active={false}
                  isStatic={!pinComponent.settings.movableInInteractiveViewer}
                  isHidden={linkedComponent}
                  Renderer={PinComponentSwitchRendererRealtimePlayer}
                  scale={scale}
                />
              );
            })}
          </PinSwitchRealtimePlayer>
          {focusMode && <FocusModeScreen focusMode={focusMode} />}
        </StyledPin>
        {withFAM && (
          <PlayerFAM
            scale={scale}
            pinComponents={pin.pinComponents}
            mutatingPinComponent={mutatingPinComponent}
            onMouseUp={handleOnMouseUp}
          />
        )}
      </PinScaleWrapper>
    </StyledPinWrapper>
  );
};

const StyledPin = styled.div`
  width: ${pinSize.width}px;
  height: ${pinSize.height}px;
  transform-origin: left top;
  position: relative;
  overflow: hidden;
  outline: ${color.neutral.outline.background} solid 1px;
`;

// There is currently no better way to target the pin switch, since it does not expose a common html element
/**
 * This will hide the pin when the focus mode is active.
 * Assumption is the pin will always be the first child
 */
const hidePinInFocusmode = cssBase`
  & > *:first-child {
    visibility: hidden;
  }
`;

const StyledPinWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
