import {
  BodyText,
  color,
  Headline,
  ManagedModal,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React, { useState } from 'react';
import { IntroductoryStepperModalProps } from './IntroductoryStepperModal.types';

import { getRouteForAsset } from '@lessonup/utils';
import { useTranslation } from 'react-i18next';
import {
  checkIfIntroductoryStepperWasSkipped,
  setIntroductoryStepperSkippedFlag,
} from '../../utils/localStorage/introductoryStepper';

export const IntroductoryStepperModal: React.FC<IntroductoryStepperModalProps & NiceModalHocProps> = NiceModal.create(
  ({ ariaLabel, steps, postStartAction, introductoryStepperKey }: IntroductoryStepperModalProps) => {
    const modal = NiceModal.useModal();
    const { t } = useTranslation(introductoryStepperKey);

    const [stepperStep, setStepperStep] = useState<number>(0);

    const advanceStepper = (): void => {
      if (stepperStep < steps.length - 1) {
        setStepperStep(stepperStep + 1);
      } else if (stepperStep === steps.length - 1) {
        dismissModal();
        postStartAction();
      }
    };

    const dismissModal = (): void => {
      modal.hide();
      setIntroductoryStepperSkippedFlag(introductoryStepperKey);
    };

    const skipModal = (): void => {
      dismissModal();
      postStartAction();
    };

    const conditionSkipButton = () => {
      if (stepperStep === steps.length - 1) {
        return undefined;
      }
      return { onClick: () => skipModal(), label: t('skipButton') };
    };

    return (
      <ManagedModal modal={modal} contentLabel={ariaLabel} width="640px" height="640px">
        <ModalHeaderV1 type="headline" onCloseButtonClick={modal.hide} showDivider={false} />
        <StyledSpaceBetween direction="y" spacing={spacing.size24}>
          <SpaceBetween direction="y" spacing={spacing.size4}>
            <StyledHeadline size="medium">{t(steps[stepperStep].title)}</StyledHeadline>
            <StyledText>{t(steps[stepperStep].description)}</StyledText>
          </SpaceBetween>

          <Img src={getRouteForAsset(steps[stepperStep].imageSrc)} alt="" />
        </StyledSpaceBetween>
        <ModalFooter
          type="actions"
          text={t('stepperLabel', { step: stepperStep + 1, steps: steps.length })}
          secondaryAction={conditionSkipButton()}
          primaryAction={{
            onClick: () => advanceStepper(),
            label: t(stepperStep === steps.length - 1 ? 'startButton' : 'nextButton'),
          }}
        />
      </ManagedModal>
    );
  }
);

export const showIntroductoryStepperModal = (props: IntroductoryStepperModalProps) => {
  if (checkIfIntroductoryStepperWasSkipped(props.introductoryStepperKey)) {
    props.postStartAction();
  } else {
    NiceModal.show(IntroductoryStepperModal, props);
  }
};

const StyledSpaceBetween = styled(SpaceBetween)`
  padding-top: ${spacing.size12};
  padding-left: ${spacing.size24};
  padding-right: ${spacing.size24};
  flex: 1;
  height: 496px;
  overflow: hidden;
`;

const StyledHeadline = styled(Headline)`
  align-self: center;
  color: ${color.accent.primaryContainer.text};
`;

const StyledText = styled(BodyText)`
  color: ${color.accent.primaryContainer.text};
  text-align: center;
`;

const Img = styled.img`
  width: 100%;
`;
