import { Upload, UploadType } from '../../UploadsFeature.utils';

export const defaultTranslationPrefix = 'uploadFileFromClipboardPreviewModal';

export const modalSteps = ['preview', 'uploading'] as const;
export type ModalStep = (typeof modalSteps)[number];

export interface FilePreviewDataForUpload {
  id: string;
  type: UploadType;
  url: string | undefined;
  progressPercentage: Upload['progressPercentage'];
  status: Upload['status'];
}
