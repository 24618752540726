import _ from 'lodash';
import { PinEntry } from '../../entries';
import { Component } from '../components';

export namespace DragQuestion {
  export const type = 'dragQuestion';

  export type ComponentChecked = 'correct' | 'incorrect';
  export type ComponentResults = {
    [componentId: string]: ComponentChecked;
  };

  export interface AnswerResult {
    type: 'correct' | 'incorrect' | 'partialCorrect' | 'noAnswer' | 'opinion';
    components: ComponentResults;
    count: {
      num: number;
      total: number;
    };
  }

  export interface CorrectAnswer {
    correctDropzone: {
      [componentId: string]: Component.Id[];
    };
  }

  export interface Content {
    color?: number;
    layout?: number;
    opacity?: number;
    showImage?: boolean;
    imageSize?: 'contain' | 'cover';
    colorfg?: string;
    colorbg?: string;
  }

  export interface AssignmentContent extends Content {
    hasAnswer: boolean;
  }

  export namespace Components {
    export function componentsWithoutCorrectAnswer(components: Component.All[]): Component.All[] {
      return components.map((c) => {
        return _.omit(c, 'settings.correctDropzone') as Component.All;
      });
    }

    export function lessonComponentsFromAssignmentComponents(
      components: Component.All[],
      correctAnswer: CorrectAnswer | undefined
    ): Component.All[] {
      return components.map((c) => {
        const c2 = _.cloneDeep(c);
        if (correctAnswer && correctAnswer.correctDropzone[c._id]) {
          c2.settings.correctDropzone = correctAnswer.correctDropzone[c._id];
        }
        return c2;
      });
    }
  }
}

export namespace DragQuestion {
  export namespace Components {
    export type DragComponent = Component.BoxComponent;
    export type Dropzone = Component.Dropzone;

    export function getAllDropZones(list: Component[]): Dropzone[] {
      return list.filter((c) => c.type === 'dropzone') as Dropzone[];
    }

    export function getAllDragComponents(list: Component[]): DragComponent[] {
      return list.filter((c) => c.settings.isDraggable && c.type !== 'dropzone') as DragComponent[];
    }

    export function dragComponentWithAnswer(list: DragComponent[]): DragComponent[] {
      return list.filter((c) => c.settings.correctDropzone && c.settings.correctDropzone.length);
    }

    export function dragComponentWithoutAnswer(list: DragComponent[]): DragComponent[] {
      return list.filter((c) => !c.settings.correctDropzone || !c.settings.correctDropzone.length);
    }

    export function getNonDragQuestionComponents(list: Component[]): Component[] {
      return list.filter((c) => !c.settings.isDraggable && c.type !== 'dropzone') as Component[];
    }

    export function componentsForDropzone(dropZoneId: Component.Id, list: DragComponent[]) {
      return list.filter((c) => c.settings.correctDropzone && c.settings.correctDropzone.includes(dropZoneId));
    }

    export function order(cArr: Component[]): Component.All[] {
      const normal = getNonDragQuestionComponents(cArr);
      const dropZones = getAllDropZones(cArr);
      const draggables = getAllDragComponents(cArr);
      return [...normal, ...dropZones, ...draggables] as Component.All[];
    }
  }

  export interface Answer {
    components: Answer.Components;
    submitted?: boolean;
    modifiedAt: number;
  }

  export namespace Answer {
    export interface Component {
      position: Component.Position;
      inDropzone: Component.Id | undefined;
    }

    export interface Components {
      [componentId: string]: Component;
    }

    export function create(fields: Answer): Answer {
      return Object.assign({}, fields);
    }

    export function isCorrectAnswer(answer: any): answer is CorrectAnswer {
      return (
        answer &&
        answer.correctDropzone &&
        !_.find(
          answer.correctDropzone,
          (compAnswer) => !Array.isArray(compAnswer) || compAnswer.find((a) => typeof a !== 'string')
        )
      );
    }

    export function update(current: Answer, fields: Partial<Answer>): Answer {
      const merge = Object.assign({}, current, fields);
      merge.components = Object.assign({}, current.components, fields.components);
      return merge;
    }

    export function hasCorrectAnswer(content: AssignmentContent): boolean {
      return !!content.hasAnswer;
    }

    export function totalNumberOfCorrect(answer: CorrectAnswer | undefined): number {
      if (!answer) return 0;
      return _.filter(answer.correctDropzone, (c) => c.length > 0).length;
    }

    export function rankingPointsForResult(result?: AnswerResult): number {
      if (result && result.type === 'correct') return 1;
      return 0;
    }

    export function testPointsForResult(maxPoints: number, result?: AnswerResult): number | undefined {
      if (result && result.type === 'correct') return maxPoints;
      return undefined;
    }

    export function isSubmitted(pinEntry: PinEntry.DragQuestion | undefined) {
      if (pinEntry && pinEntry.answer && pinEntry.answer.submitted) return true;
      return false;
    }

    export function resultForAnswer(
      current: Answer | undefined,
      optionalCorrectAnswer: CorrectAnswer | undefined
    ): AnswerResult {
      const correctAnswer: CorrectAnswer = optionalCorrectAnswer || { correctDropzone: {} };
      const max = Object.values(correctAnswer.correctDropzone).filter((list) => list.length).length;
      const cur = (current && current.components) || {};
      const count = { num: 0, total: max };
      const components: _.Dictionary<ComponentChecked> = {};

      // always return complete list of components with correct/ incorrect status for rendering checkboxes
      _.forEach(correctAnswer.correctDropzone, (list, componentId) => {
        if (list.length === 0) {
          // If no correct dropzones are defined, it's an opinion question in which case every answer is correct.
          components[componentId] = cur[componentId] && count.total > 0 ? 'incorrect' : 'correct';
        } else {
          const answer = (cur[componentId] && cur[componentId].inDropzone) || '';
          const isCorrect = list.includes(answer);
          if (isCorrect) count.num++;
          components[componentId] = isCorrect ? 'correct' : 'incorrect';
        }
      });
      const type =
        count.num === count.total ? 'correct' : count.total && count.num === 0 ? 'incorrect' : 'partialCorrect';

      if (!current) {
        return { type: 'noAnswer', components, count };
      }

      return { type, components, count };
    }

    export function isDone(answer: Answer | undefined): boolean {
      return !_.isNil(answer) && !_.isEmpty(answer.components);
    }
  }
}
