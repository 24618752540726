import { color, css, Headline, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export type textOverflow = 'ellipsis' | 'wrap';

export interface ContextHeaderProps {
  textOverflow?: textOverflow;
  divider?: boolean;
  title?: string;
  startSlot?: React.JSX.Element;
  endSlot?: React.JSX.Element;
}

export const ContextHeader: React.FC<ContextHeaderProps> = (props) => {
  return (
    <StyledDiv textOverflow={props.textOverflow} divider={props.divider}>
      <SpaceBetween
        style={props.textOverflow === 'ellipsis' ? { overflow: 'hidden' } : {}}
        direction="x"
        spacing={spacing.size8}
      >
        <div>{props.startSlot}</div>
        {props.title && (
          <StyledHeadline size="small" textOverflow={props.textOverflow}>
            {props.title}
          </StyledHeadline>
        )}
      </SpaceBetween>
      {props.endSlot && <StyledSpan>{props.endSlot}</StyledSpan>}
    </StyledDiv>
  );
};

const StyledHeadline = styled(Headline)<Pick<ContextHeaderProps, 'textOverflow'>>`
  padding-top: 3px;
  padding-bottom: 3px;
  align-self: center;
  ${(props) => {
    if (props.textOverflow === 'ellipsis') {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
  }};
`;

const StyledDiv = styled.div<Pick<ContextHeaderProps, 'textOverflow' | 'divider'>>`
  padding-top: ${spacing.size4};
  padding-bottom: ${spacing.size4};
  ${(props) => {
    if (props.textOverflow === 'ellipsis') {
      return css`
        overflow: hidden;
      `;
    }
  }}

  display: flex;
  align-items: center;
  padding-top: ${spacing.size16};
  padding-bottom: ${spacing.size16};
  ${(props) =>
    props.divider &&
    css`
      border-bottom: 1px solid ${color.neutral.outline.background};
    `};
`;

const StyledSpan = styled.span`
  margin-left: auto;
`;
