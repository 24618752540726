import { newVector, Vector } from '@lessonup/pins-shared';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { pinSize } from '../foundations/layout/pinDefault';

export function usePinResizeObserver(pinWrapperRef: React.RefObject<HTMLDivElement | null>) {
  const [scale, setScale] = useState<number | null>(null);
  const [pinRendererOffset, setPinRendererOffset] = useState<Vector>(newVector(0, 0));

  const observer = useRef(
    new ResizeObserver(
      debounce((entries) => {
        const offset = pinWrapperRef.current?.getBoundingClientRect();
        const { x, y, width, height } = entries[0].contentRect; // this is the inner part of the pinWrapper, x and y are calculated by the padding of pinWrapper

        const ratioWidth = width / pinSize.width;
        const ratioHeight = height / pinSize.height;
        const ratio = Math.min(ratioWidth, ratioHeight);

        const topLeftOfPin = {
          x: x + (width - pinSize.width * ratio) / 2,
          y: y + (height - pinSize.height * ratio) / 2,
        };

        if (offset) {
          setPinRendererOffset(newVector(offset.left + topLeftOfPin.x, offset.top + topLeftOfPin.y));
        }

        setScale(ratio);
      }, 10)
    )
  );

  useEffect(() => {
    const pin = pinWrapperRef.current;
    if (!pin) {
      return;
    }
    observer.current.observe(pin);
    return () => {
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      observer.current.disconnect();
    };
  }, [pinWrapperRef]);

  return { scale: scale ?? DEFAULT_SCALE, pinRendererOffset, resizeObserverInitialized: !!scale };
}

const DEFAULT_SCALE = 1;
