import { PinComponentSwitch, pinSize, useSortPinComponents } from '@lessonup/pin-renderer';
import { Pin, TeacherPin } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PinComponentSwitchRendererEditorMemo } from '../../../PinEditor/PinComponentSwitchEditor/PinComponentSwitchEditor';
import { PinSwitchStaticPinRail } from './PinSwitchStaticPinRail';

export interface PinViewerStaticPinRailProps {
  pin: Pin;
  isThumb?: boolean;
  width?: number;
  pinComponentsAreStatic?: boolean;
}

export const PinViewerStaticPinRail: React.FC<PinViewerStaticPinRailProps> = ({
  pin,
  isThumb,
  width = 200,
  pinComponentsAreStatic = true,
}) => {
  const pinComponents = useSortPinComponents(pin.pinComponents);
  const scale = width / pinSize.width;

  return (
    <StyledPinWrapper style={{ width: `${width}px`, height: `${width * (pinSize.height / pinSize.width)}px` }}>
      <StyledPin style={{ transform: `scale(${scale})` }}>
        <PinSwitchStaticPinRail pin={pin as TeacherPin}>
          {pinComponents.map((pinComponent) => {
            return (
              <PinComponentSwitch
                key={pinComponent.id}
                pinComponent={pinComponent}
                layoutKey={pin.settings.layout}
                editable={false}
                pinId={pin.id}
                pinType={pin.type}
                active={false}
                isThumb={isThumb}
                isStatic={pinComponentsAreStatic}
                scale={scale}
                Renderer={PinComponentSwitchRendererEditorMemo}
              />
            );
          })}
        </PinSwitchStaticPinRail>
      </StyledPin>
    </StyledPinWrapper>
  );
};

const StyledPin = styled.div`
  width: ${pinSize.width}px;
  height: ${pinSize.height}px;
  transform-origin: left top;
  position: relative;
  overflow: hidden;
`;

const StyledPinWrapper = styled.div`
  position: relative;
`;
