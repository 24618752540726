import { graphQLErrorCodeFromError, useDocumentMutation } from '@lessonup/client-integration';
import {
  ErrorDisplayMessage,
  ErrorToErrorDisplayMessage,
  rem,
  spacing,
  styled,
  useErrorContext,
} from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'usehooks-ts';
import { DropUpload } from '../../../components/DropUpload/DropUpload';
import { CreateUploadForOCRData } from '../ChatGPTGeneratorFeature.graphql';
import { AnalyzeUploadsForOcrDocument, CreateUploadForOcrDocument } from '../ChatGPTGeneratorFeature.graphql.generated';
import { ContextUpload } from '../model';

type UploadFileForOCRProps = {
  setContextUploads: React.Dispatch<React.SetStateAction<ContextUpload[]>>;
};

export function UploadFileForOCR(props: UploadFileForOCRProps) {
  const { t } = useTranslation('chatGPT');
  const { setError } = useErrorContext();
  const setErrorHandler = (error: Error) => setError({ error, customErrorParser: createFileErrorParser(t) });
  const [ocrMutation] = useDocumentMutation(AnalyzeUploadsForOcrDocument);

  const sendFileToBackend = async (data: CreateUploadForOCRData, file: File) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', file.type);
    myHeaders.append('x-goog-content-length-range', `0,${file.size}`);

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };

    try {
      return fetch(data.createUploadForOCR.uploadUrl, requestOptions);
    } catch (error) {
      handleError(error);
    }
  };

  async function handleOcr(id: string): Promise<string | undefined> {
    try {
      const { data, errors } = await ocrMutation({
        variables: {
          input: {
            uploadIds: [id],
          },
        },
      });

      if (data) {
        return data.analyzeUploadsForOCR.texts[0];
      }

      if (errors) {
        handleError(errors[0]);
        removeUploadFromContextUploads(id);
      }

      return undefined;
    } catch (error) {
      handleError(error);
      removeUploadFromContextUploads(id);
      return undefined;
    }
  }

  const [createUploadForOCR, { loading }] = useDocumentMutation(CreateUploadForOcrDocument);

  const [isLoadingDebounced] = useDebounceValue(loading, 250);

  const handleUploadFiles = async (files: File[]) => {
    if (!files.length) return;

    await Promise.all(
      Array.from(files).map(async (file) => {
        try {
          return await registerSingleFileForUpload(file);
        } catch (error) {
          handleError(error);
        }
      })
    );
  };

  const handleError = (error: unknown) => {
    setErrorHandler(asError(error));
  };

  async function registerSingleFileForUpload(file: File): Promise<string | undefined> {
    const { data, errors } = await createUploadForOCR({
      variables: {
        input: {
          contentType: file.type,
          name: file.name,
          sizeInBytes: file.size,
        },
      },
    });

    if (!data || errors?.length) {
      handleError(errors?.length ? errors[0] : new Error('Unknown error'));
      return;
    }

    const response = await sendFileToBackend(data, file);

    if (!response) {
      return;
    }

    const { id, name } = data.createUploadForOCR.upload;

    props.setContextUploads((prevContextUploads) => [
      ...prevContextUploads,
      { id: id, fileName: name, textFromUpload: '', isLoading: true },
    ]);

    const textFromUpload = await handleOcr(id);

    if (textFromUpload) {
      props.setContextUploads((prevContextUploads) => {
        return prevContextUploads.map((prevContextUpload) => {
          if (prevContextUpload.id === id) {
            return { ...prevContextUpload, textFromUpload, isLoading: false };
          }
          return prevContextUpload;
        });
      });
    }

    return id;
  }

  const removeUploadFromContextUploads = (id: string) => {
    props.setContextUploads((prevContextUploads) => {
      return prevContextUploads.filter((prevContextUpload) => prevContextUpload.id !== id);
    });
  };

  return (
    <StyledDropUpload>
      <DropUpload
        acceptedMimeTypes={{
          audioTypes: [],
          documentTypes: [],
          imageTypes: ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/tiff', 'image/webp'],
          videoTypes: [],
        }}
        isLoading={isLoadingDebounced}
        onChangeFileInput={handleUploadFiles}
        buttonText={t('uploadStep.btnUpload')}
        dropImageText={t('uploadStep.dropImageText')}
        belowDropText={t('uploadStep.belowDropText')}
      />
    </StyledDropUpload>
  );
}

const createFileErrorParser: (t: TFunction) => ErrorToErrorDisplayMessage = (t: TFunction) => {
  return (error): ErrorDisplayMessage => {
    const errorCode = graphQLErrorCodeFromError(error);

    if (errorCode === 'NO_TEXT_FOUND_BY_OCR') {
      return {
        title: t('serviceReturnedNoTextFoundByOcrErrorToastTitle'),
        description: t('serviceReturnedNoTextFoundByOcrErrorToastMessage'),
      };
    }

    return { title: '', description: t('somethingWentWrong') };
  };
};

const StyledDropUpload = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40vh;
  margin-top: ${spacing.size16};
  max-height: ${rem('385px')};
`;
