import { styled } from '@lessonup/ui-components';
import { addImageSizing } from '@lessonup/utils';
import React from 'react';

export interface PinBackgroundProps {
  isThumb?: boolean;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundTransparency?: number;
}

export const PinBackground: React.FC<PinBackgroundProps> = ({
  isThumb,
  backgroundColor,
  backgroundImage,
  backgroundTransparency = 100,
}) => {
  const imageUrl = backgroundImage && addImageSizing({ url: backgroundImage, isThumb: isThumb, size: 'xl' });

  return (
    <PinBackgroundWrapper style={{ backgroundColor: backgroundColor }}>
      {backgroundImage && (
        <PinBackgroundImageContainer
          style={{ backgroundImage: `url(${imageUrl})`, opacity: `${backgroundTransparency}%` }}
        />
      )}
    </PinBackgroundWrapper>
  );
};

const PinBackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: no-repeat;
  background-size: cover;
`;

const PinBackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
