import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.989 13.54c0-6 2.383-10.835 9-10.835 6.605 0 9 4.835 9 10.835m-.003.044s.4 5.225-2.02 6.735-5.412.819-5.412.819M2.989 13.753c0-3.3 2.286-4.124 3.429-4.124 2.057 0 2.571 1.65 2.571 2.475v3.3c0 1.98-1.714 2.474-2.571 2.474-1.143 0-3.429-.825-3.429-4.124m18 0c0-3.3-2.286-4.124-3.429-4.124-2.057 0-2.571 1.65-2.571 2.475v3.3c0 1.98 1.714 2.474 2.571 2.474 1.143 0 3.429-.825 3.429-4.124"
    />
  </svg>
);
export default SvgIconSupport;
