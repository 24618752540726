import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFeedback = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.719 9.75h7.312m-7.312 4.5h5.531M6.311 3.02c1.954-.221 4.098-.395 6.064-.395s4.112.174 6.067.395a2.89 2.89 0 0 1 2.548 2.565c.212 1.944.385 4.085.385 6.04s-.173 4.1-.385 6.047a2.89 2.89 0 0 1-2.548 2.563c-1.955.22-4.1.39-6.067.39-1.715 0-3.565-.13-5.306-.308l-4.444 1.058 1.03-4.738c-.161-1.654-.28-3.398-.28-5.012 0-1.955.174-4.096.387-6.04a2.894 2.894 0 0 1 2.55-2.565"
    />
  </svg>
);
export default SvgIconFeedback;
