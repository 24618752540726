import { CropArea, ImageSettings, Size } from '@lessonup/pins-shared';
import { Placeholder, styled } from '@lessonup/ui-components';
import { addImageSizing } from '@lessonup/utils';
import React, { CSSProperties } from 'react';
import { computeOpacity } from '../../utils';
import { computeShadowFilter } from '../../utils/effects/shadow/shadow';
import { isPlaceholderImage } from '../../utils/pinComponents/imagePinComponent.utils';
import { computeBackdrop, getViewportBackgroundStyle } from './CropArea.utils';
import { boxAfterRotation } from './geometry';
import { ImagePinComponentProps } from './ImagePinComponent.types';
import { useOriginalAspectRatio } from './useOriginalAspectRatio';

export const ImagePinComponent = (props: ImagePinComponentProps) => {
  const imageUrl = addImageSizing({ url: props.settings.url, isThumb: props.isThumb, size: 'xl' });
  const fullImageRatio = useOriginalAspectRatio(imageUrl);

  if (isPlaceholderImage(props.settings)) {
    // We don't allow cropping for placeholders, so we don't need to apply crop
    // styling here.
    // An example of a placeholder image is when you create a new pin from one
    // of the image templates, such as "image left" or "image right", before
    // setting the image's source.
    return (
      <Placeholder
        style={{
          ...ImageStyles.forWrapper(props.settings),
          ...ImageStyles.forBorder(props.settings, props.layout.size),
        }}
      />
    );
  }

  const cropArea = props.settings.cropArea || CropArea.cover;

  if (cropArea.scale > 1) {
    // If the scale is greater than 1, we can show a cropped image using pure
    // CSS, without first having to load the image's aspect ratio. This gives a
    // better user experience because we don't have to briefly show an empty box
    // while the image is loading.

    return (
      <Wrapper
        style={ImageStyles.forWrapper(props.settings)}
        onDoubleClick={props.onDoubleClick}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
      >
        <Layer
          style={{ ...ImageStyles.forCropArea(cropArea), backgroundImage: `url(${imageUrl})` }}
          {...props.settings}
        />
        <Layer style={ImageStyles.forBorder(props.settings, props.layout.size)} />
      </Wrapper>
    );
  }

  if (fullImageRatio === null) {
    // We need the image's aspect ratio to compute the cropped image's CSS, but
    // it hasn't loaded yet.
    return null;
  }

  const viewport = boxAfterRotation(props.layout);
  const backdrop = computeBackdrop({ viewport, cropArea, fullImageRatio });
  const backgroundStyle = getViewportBackgroundStyle(viewport, backdrop, 1);

  return (
    <Wrapper
      onDoubleClick={props.onDoubleClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      style={{
        ...ImageStyles.forWrapper(props.settings),
        ...ImageStyles.forBorder(props.settings, props.layout.size),
        ...backgroundStyle,
        backgroundImage: `url(${imageUrl})`,
      }}
    ></Wrapper>
  );
};

/** Helper functions for the various aspects of the image component styling. */
const ImageStyles = {
  forWrapper(settings: ImageSettings): CSSProperties {
    return {
      opacity: computeOpacity(settings.transparency),
      boxShadow: computeShadowFilter(settings.shadow),
      // Note that we set borderRadius again in `forBorder` to account for both
      // ways of rendering the image pin component.
      borderRadius: `${settings.borderRadius ?? 0}px`,
    };
  },

  forBorder(settings: ImageSettings, layoutSize: Size): CSSProperties {
    // Limit the outline width to half the pin component's size to avoid a
    // rendering bug in Chrome. The bug occurs when a large negative outline
    // offset pushes the outline outside the components's box model and appears as
    // visual artifacts when the user drags the pin component.
    const outlineWidth = Math.min(settings.borderSize ?? 0, layoutSize.width / 2, layoutSize.height / 2);

    return {
      outlineWidth: `${outlineWidth}px`,
      outlineOffset: `-${outlineWidth}px`,
      outlineStyle: 'solid',
      outlineColor: `${settings.borderColor ?? '#000000'}`,
      borderRadius: `${settings.borderRadius ?? 0}px`,
    };
  },

  /**
   * Returns the CSS styles for the given crop area with scale > 1.
   *
   * As a basis, we use `background-size: cover`. Then we scale up the background
   * by scaling up the entire element (as there is no way to combine `cover` with
   * universally scaling up both dimensions of a background). The combination of
   * setting both `background-position` and `transform-origin` to the focus causes
   * the scaling up to happen around that point, achieving the desired result.
   *
   * This method doesn't work reliably when scale <= 1.
   */
  forCropArea({ focus, scale }: CropArea): CSSProperties {
    return {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: `${focus.x * 100}% ${focus.y * 100}%`,
      transformOrigin: `${focus.x * 100}% ${focus.y * 100}%`,
      transform: `scale(${scale})`,
    };
  },
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transform-origin: top left;
`;

const Layer = styled.div`
  position: absolute;
  inset: 0;
`;
