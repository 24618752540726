import {
  BodyText,
  Card,
  IconArrowRight,
  IconComponentImage,
  IconText,
  ModalFooter,
  NiceModalHandler,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratorInputType } from '../../../../ChatGPTGenerator/MaiaGenerateLesson';
import { LessonModalContent, LessonModalMainWrapper } from './LayoutComponents';
import { UnderContructionBanner } from './UnderConstructionBanner';

interface GenerateLessonProps {
  modal: NiceModalHandler<Record<string, unknown>>;
  editorType: 'v1' | 'v2';
}

export const GenerateLesson: React.FC<GenerateLessonProps> = ({ modal, editorType }) => {
  const [lessonType, setLessonType] = useState<GeneratorInputType | undefined>('text');
  const { t } = useTranslation('createLessonModal');

  const handleStartMaia = () => {
    if (lessonType === 'text') {
      modal.resolve({ type: 'maia', generatorType: 'text', version: editorType });
      modal.hide();
    }
    if (lessonType === 'upload') {
      modal.resolve({ type: 'maia', generatorType: 'upload', version: editorType });
      modal.hide();
    }
  };

  const handleCardClick = (type: GeneratorInputType) => {
    setLessonType(type);
  };

  return (
    <LessonModalMainWrapper>
      <LessonModalContent>
        <StyledSpaceBetween direction="x" spacing={spacing.size8}>
          <Card resizing="fixed" active={lessonType === 'text'} onClick={() => handleCardClick('text')}>
            <StyledCardSpacing>
              <SpaceBetween direction="y" spacing={spacing.size8}>
                <SpaceBetween direction="x" spacing={spacing.size4}>
                  <IconText />
                  <BodyText size="small" weight="bold" style={{ margin: 'auto' }}>
                    {t('generateLesson.maiaTextTagline')}
                  </BodyText>
                </SpaceBetween>
                <BodyText size="large" weight="bold">
                  {t('generateLesson.maiaTextTitle')}
                </BodyText>
                <BodyText size="medium">{t('generateLesson.maiaTextContent')}</BodyText>
              </SpaceBetween>
            </StyledCardSpacing>
          </Card>
          <Card resizing="fixed" active={lessonType === 'upload'} onClick={() => handleCardClick('upload')}>
            <StyledCardSpacing>
              <SpaceBetween direction="y" spacing={spacing.size8}>
                <SpaceBetween direction="x" spacing={spacing.size4}>
                  <IconComponentImage />
                  <BodyText size="small" weight="bold" style={{ margin: 'auto' }}>
                    {t('generateLesson.maiaImageTagline')}
                  </BodyText>
                </SpaceBetween>
                <BodyText size="large" weight="bold">
                  {t('generateLesson.maiaImageTitle')}
                </BodyText>
                <BodyText size="medium">{t('generateLesson.maiaImageContent')}</BodyText>
              </SpaceBetween>
            </StyledCardSpacing>
          </Card>
        </StyledSpaceBetween>
        <StyledBannerWrapper>
          <UnderContructionBanner />
        </StyledBannerWrapper>
      </LessonModalContent>
      <ModalFooter
        type="actions"
        showDivider
        isSticky
        primaryAction={{
          label: t('generateLesson.askMaia'),
          onClick: () => {
            handleStartMaia();
          },
          buttonType: 'maia',
          iconEnd: <IconArrowRight />,
        }}
        secondaryAction={{
          onClick: () => modal.hide(),
          label: t('cancel'),
          buttonType: 'neutral',
          showStroke: false,
        }}
      />
    </LessonModalMainWrapper>
  );
};

const StyledCardSpacing = styled.div`
  height: 100%;
  padding: ${spacing.size16};
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1;
  }
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;

  > * {
    flex: 1;
  }
`;

const StyledBannerWrapper = styled.div`
  padding-bottom: ${spacing.size16};
`;
