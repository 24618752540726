import { tracker } from '@lessonup/analytics';
import {
  BodyText,
  color,
  IconArrowBendDownRight,
  IconImport,
  InputFormField,
  ModalFooter,
  NiceModalHandler,
  SpaceBetween,
  spacing,
  styled,
  TextElement,
  Truncate,
  UploadFileInput,
  useForm,
  useWatch,
} from '@lessonup/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonModalContent, LessonModalMainWrapper } from './LayoutComponents';
import { UnderContructionBanner } from './UnderConstructionBanner';

interface ImportLessonProps {
  modal: NiceModalHandler<Record<string, unknown>>;
  loading: boolean;
  onCreateLessonFromSlideDeck: (name: string, file: File) => Promise<void>;
  invalidFileError: string | undefined;
  onSelectFile: (file: File) => void;
}

export const ImportLesson: React.FC<ImportLessonProps> = ({
  modal,
  loading,
  onCreateLessonFromSlideDeck,
  invalidFileError,
  onSelectFile,
}) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ lessonTitle: string }>();

  const { t } = useTranslation('createLessonModal');

  const lessonTitle = useWatch({
    control,
    name: 'lessonTitle',
    defaultValue: '',
  });

  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileUpload = async (files: File[]) => {
    if (files.length > 1) {
      throw new Error('Only one file can be uploaded at a time');
    }
    onSelectFile(files[0]);
    setFile(files[0]);
    setValue('lessonTitle', files[0]?.name || '', { shouldValidate: true });
  };

  const handleImportLesson = () => {
    if (!lessonTitle || !file || loading) return;
    tracker.events.lessonPowerpointStartConversion({ fileSizeInBytes: file.size.toString() });
    const trimmedLessonTitle = lessonTitle.trim();
    return onCreateLessonFromSlideDeck(trimmedLessonTitle, file);
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleImportLesson)}>
      <LessonModalMainWrapper>
        <LessonModalContent>
          <StyledFileSelectorElement direction="x" spacing={spacing.size8} alignItems="center">
            <UploadFileInput
              onUpload={handleFileUpload}
              disabled={loading}
              buttonType="neutral"
              buttonStroke
              icon={<IconImport />}
              multiple={false}
              acceptedMimeTypes={{
                documentTypes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
                audioTypes: [],
                imageTypes: [],
                videoTypes: [],
              }}
              useCustomErrorHandling={true}
            >
              {t('slideDeck.importButton.label')}
            </UploadFileInput>
            {file && (
              <BodyText>
                <Truncate as="span" maxLines={2}>
                  {file.name}
                </Truncate>
              </BodyText>
            )}
          </StyledFileSelectorElement>
          {invalidFileError && <ErrorText textStyle="bodyText">{invalidFileError}</ErrorText>}
          <InputFormField
            type="text"
            label={t('lessonTitle')}
            disabled={!lessonTitle && !file}
            {...register('lessonTitle', {
              required: { value: true, message: t('errors.lessonTitleFieldIsRequired') },
              maxLength: { value: 300, message: t('errors.maxLengthExceeded') },
              setValueAs: (value) => (value && value.trim()) || undefined,
              onChange: (e) => setValue('lessonTitle', e.target.value),
            })}
            maxLength={300}
            {...(errors['lessonTitle']?.message
              ? { validation: { state: 'error', message: errors['lessonTitle'].message } }
              : {
                  validation: {
                    icon: <IconArrowBendDownRight />,
                    state: 'neutral',
                    message: t('slideDeck.formValidation'),
                  },
                })}
          />
          <UnderContructionBanner />
        </LessonModalContent>
        <ModalFooter
          type="actions"
          showDivider
          isSticky
          primaryAction={{
            async: true,
            onClick: handleSubmit(handleImportLesson),
            label: t('importLesson'),
            iconEnd: <IconImport />,
            type: 'submit',
            disabled: loading,
          }}
          secondaryAction={{
            onClick: () => modal.hide(),
            label: t('cancel'),
            buttonType: 'neutral',
            showStroke: false,
          }}
        />
      </LessonModalMainWrapper>
    </StyledForm>
  );
};

const StyledFileSelectorElement = styled(SpaceBetween)`
  max-width: 100%;
  overflow: hidden;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ErrorText = styled(TextElement)`
  color: ${color.additional.error.background};
  margin-top: ${spacing.size8};
`;
