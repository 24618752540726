import { useDocumentMutation } from '@lessonup/client-integration';
import {
  createModal,
  FormFieldValidation,
  InputFormField,
  ManagedModal,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  rem,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateFolderDocument, MyUploadsDocument } from './Uploads.graphql.generated';
import { i18nextNamespace, maxLengthName } from './UploadsFeature.utils';

interface CreateNewFolderModalProps {
  parentId: string;
}

type CreateNewFolderModalResponse = { folderCreated: boolean; folderId?: string };

export const CreateNewFolderModal = createModal((props: CreateNewFolderModalProps) => {
  const { parentId } = props;
  const [error, setError] = useState<string | undefined>(undefined);

  const [createFolderMutation, { loading }] = useDocumentMutation(CreateFolderDocument, {
    refetchQueries: [MyUploadsDocument],
  });
  const modal = useModal();
  const { t } = useTranslation(i18nextNamespace);

  type FormInputs = {
    folderName: string;
  };

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
    setFocus,
  } = useForm<FormInputs>();

  useEffect(() => {
    setTimeout(() => {
      setFocus('folderName');
    }, 1);
  }, [setFocus, parentId, modal]);

  const onSubmit = async (inputs: FormInputs) => {
    setError(undefined);
    const { folderName } = inputs;
    const trimmedFolderName = folderName.trim();

    try {
      const { data } = await createFolderMutation({
        variables: { input: { name: trimmedFolderName, parentFolderId: parentId } },
      });
      data?.createUploadFolder.folder.id;
      resetForm();
      modal.resolve({ folderCreated: true, folderId: data?.createUploadFolder.folder.id });
      modal.hide();
    } catch (error) {
      setError(t('createNewFolderModal.error.generic'));
    }
  };

  const validation: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];

    if (inputError) {
      const message: string =
        inputError?.type === 'minLengthValue'
          ? t('createNewFolderModal.error.minLengthValue')
          : inputError?.message || t('createNewFolderModal.error.generic');

      return {
        state: 'error',
        message,
      };
    } else if (error) {
      return {
        state: 'error',
        message: error,
      };
    }
  };

  function onClose() {
    modal.resolve({ folderCreated: false });
    modal.hide();
  }
  return (
    <ManagedModal modal={modal} contentLabel={t('createNewFolderModal.newFolder')}>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('createNewFolderModal.newFolder') }}
        onCloseButtonClick={onClose}
        showDivider={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputsWrapper>
          <StyledInputFormField
            type="text"
            label={t('createNewFolderModal.folderName')}
            hideLabel
            {...register('folderName', {
              required: { value: true, message: t('createNewFolderModal.error.fieldIsRequired') },
              validate: { minLengthValue: (value) => value.trim().length > 0 },
              minLength: 1,
              maxLength: {
                value: maxLengthName,
                message: t('createNewFolderModal.error.maxLength', { maxLengthName }),
              },
            })}
            validation={validation('folderName')}
          />
        </FormInputsWrapper>
        <ModalFooter
          type="actions"
          primaryAction={{
            type: 'submit',
            label: t('createNewFolderModal.button.create'),
            loading: !loading ? undefined : true,
          }}
        />
      </form>
    </ManagedModal>
  );
});

const FormInputsWrapper = styled.div`
  padding: ${spacing.size16} ${spacing.size24};
`;

const StyledInputFormField = styled(InputFormField)`
  width: ${rem('272px')};
`;

export const showCreateNewFolderModal = (props: CreateNewFolderModalProps): Promise<CreateNewFolderModalResponse> =>
  NiceModal.show(CreateNewFolderModal, props);
