import { DataLoader, useDocumentQuery } from '@lessonup/client-integration';
import { createModal, ManagedModal, NiceModal, styled, useModal, useTranslation } from '@lessonup/ui-components';
import { compact } from 'lodash';
import React, { useCallback } from 'react';
import {
  ReportsListModalContent,
  ReportsListModalContentProps,
} from './components/ReportsListModal/ReportsListModalContent';
import { ReportListDocument } from './ReportsListModalFeature.graphql.generated';
import { i18nextNamespace } from './utils/consts';
import { ReportLesson } from './utils/types';

export type ReportsListModalFeatureProps = {
  lesson: ReportLesson;
};
export const ReportsListModalFeature = createModal(({ lesson }: ReportsListModalFeatureProps) => {
  const modal = useModal();
  const { data, loading, error, refetch } = useDocumentQuery(ReportListDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      lessonId: lesson.id,
    },
  });
  const { t } = useTranslation(i18nextNamespace, { keyPrefix: 'modal' });

  const fetchReports = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ManagedModal modal={modal} contentLabel={t('contentLabel')}>
      <DataLoader
        retry={fetchReports}
        data={data}
        loading={loading}
        error={error}
        dataRenderer={(data) => {
          const reports: ReportsListModalContentProps['reports'] = (compact(data.viewer.lesson?.reports) || []).map(
            (fromBackend) => ({
              id: fromBackend.id,
              taughtAt: new Date(fromBackend.taughtAt),
              numberOfStudents: fromBackend.studentReports.length,
              lesson,
            })
          );
          return (
            <ModalContentWrapper>
              <ReportsListModalContent reports={reports} lessonName={lesson.name} onClose={modal.hide} />
            </ModalContentWrapper>
          );
        }}
      />
    </ManagedModal>
  );
});

const ModalContentWrapper = styled.div`
  max-width: 30rem;
`;

export const showReportsListModal = (lesson: ReportLesson): Promise<void> =>
  NiceModal.show(ReportsListModalFeature, { lesson });
