import styled from '@emotion/styled';
import React, { KeyboardEvent, useState } from 'react';
import { ScreenReaderOnly } from '../../utils/ScreenReaderOnly/ScreenReaderOnly';
import { TableCellsHeader, TableHeader } from './TableHeader/TableHeader';
import { TableRow, TableRowData } from './TableRow/TableRow';
import { TableRowInteractivity, TableSelectedRowsDispatch } from './types';

export interface TableProps {
  caption: string;
  header?: TableCellsHeader;
  rows: TableRowData[];
  selectedRows?: string[];
  setSelectedRows?: TableSelectedRowsDispatch;
  interactivity?: TableRowInteractivity;
  hideLastBorder?: boolean;
  className?: string;
}

export function Table(props: TableProps) {
  const [keyboardSelectedRow, setKeyboardSelectedRow] = useState<null | number>(null);
  const { selectedRows, setSelectedRows } = props;

  const handleKeyUp = (event: KeyboardEvent<HTMLElement>) => {
    // keyboard navigate through list via arrow keys
    switch (event.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        if (keyboardSelectedRow === null) {
          setKeyboardSelectedRowIfAllowed(0);
        } else {
          if (keyboardSelectedRow < props.rows.length - 1) {
            setKeyboardSelectedRowIfAllowed(keyboardSelectedRow + 1);
          }
        }
        break;
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        if (keyboardSelectedRow === null) {
          setKeyboardSelectedRowIfAllowed(0);
        } else {
          if (keyboardSelectedRow !== 0) {
            setKeyboardSelectedRowIfAllowed(keyboardSelectedRow - 1);
          }
        }
        break;
    }
  };

  // Prevents scrolling down immediately, when selecting the first item in the list.
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (['Down', 'ArrowDown'].includes(event.key) && !keyboardSelectedRow) {
      event.preventDefault();
    }
  };

  function setKeyboardSelectedRowIfAllowed(index: number): void {
    const rowToBeSelected = props.rows[index];
    if (rowToBeSelected.onClick || setSelectedRows) {
      setKeyboardSelectedRow(index);
    }
  }

  const rowIds = props.rows.map((row) => row.id);

  return (
    <StyledTable className={props.className}>
      <ScreenReaderOnly as="caption">{props.caption}</ScreenReaderOnly>
      {props.header && (
        <thead>
          <TableHeader
            header={props.header}
            rowIds={rowIds}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            interactivity={props.interactivity}
          />
        </thead>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <tbody onKeyUp={handleKeyUp} onKeyDown={handleKeyDown}>
        {props.rows.map((row, index) => (
          <TableRow
            key={index}
            row={row}
            rowIndex={index}
            isKeyboardSelected={keyboardSelectedRow === index}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            interactivity={props.interactivity}
            className={row.className}
            hideLastBorder={props.hideLastBorder}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
`;
