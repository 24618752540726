import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../foundations/colors/colors';
import { Placeholder } from '../Placeholder/Placeholder';

export type ProfileImageSize = 'M' | 'S' | 'XS';
export interface ProfileImageProps {
  imgSrc?: string;
  size?: ProfileImageSize;
}

export function ProfileImage({ imgSrc, size = 'M' }: ProfileImageProps) {
  return (
    <StyledProfileImage size={size}>
      {imgSrc ? <Img src={imgSrc} alt="" loading="lazy" /> : <Placeholder />}
    </StyledProfileImage>
  );
}

const StyledProfileImage = styled.div<{ size: ProfileImageSize }>`
  border: 1px solid ${color.additional.disabled.background};
  border-radius: 100px;
  background: ${color.neutral.surface.background};
  position: relative;
  overflow: hidden;
  ${(props) => sizeCss(props.size)}
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const sizes: Record<ProfileImageSize, [imageSize: number, svgSize: number]> = {
  XS: [16, 8],
  S: [24, 10],
  M: [32, 12],
};
const sizeCss = (size: ProfileImageSize) => {
  const [imageSize, svgSize] = sizes[size];
  return css`
    width: ${imageSize}px;
    height: ${imageSize}px;
    flex-shrink: 0;
    svg {
      width: ${svgSize}px;
      height: ${svgSize}px;
    }
  `;
};
