import { Box } from '@lessonup/pins-shared';
import { GrippyPosition, lineEndPoint } from './pinComponentLayouts';

export interface PinActionSelect {
  action: 'select';
}

export interface PinComponentActionHover {
  action: 'hover';
  componentId: string;
}

export interface PinComponentActionMove {
  action: 'move';
  componentId: string;
}

export interface PinComponentActionResizeBox {
  action: 'resize-box';
  grippy: GrippyPosition;
  componentId: string;
}

export interface PinComponentActionResizeLine {
  action: 'resize-line';
  endPoint: lineEndPoint;
  componentId: string;
}

export interface PinComponentActionRotateBox {
  action: 'rotate-box';
  componentId: string;
}

export interface PinComponentGroupActionMove {
  action: 'move-group';
  groupBox: Box;
}

export interface PinComponentGroupActionResize {
  action: 'resize-group';
  grippy: GrippyPosition;
  groupBox: Box;
}

export type PinActionSelectComponents = {
  action: 'select-pin-components';
  componentIds: string[];
};

export type PinComponentTransformAction =
  | PinComponentActionMove
  | PinComponentActionResizeBox
  | PinComponentActionResizeLine
  | PinComponentActionRotateBox;

export type PinComponentMoveAction = PinComponentActionMove | PinComponentGroupActionMove;

export type PinComponentGroupTransformAction = PinComponentGroupActionMove | PinComponentGroupActionResize;

export type PinTransformAction =
  | PinActionSelect
  | PinComponentTransformAction
  | PinComponentGroupTransformAction
  | PinActionSelectComponents;

export type PinHoverAction = PinComponentActionHover;

// Type helpers
export const isSelectAction = (action: PinTransformAction): action is PinActionSelect => action.action === 'select';

export const isSelectPinComponentAction = (action: PinTransformAction): action is PinActionSelectComponents =>
  action.action === 'select-pin-components';

export const isComponentTransformAction = (action: PinTransformAction): action is PinComponentTransformAction =>
  action.action === 'move' ||
  action.action === 'resize-box' ||
  action.action === 'resize-line' ||
  action.action === 'rotate-box';

export const isComponentMoveAction = (action: PinTransformAction): action is PinComponentMoveAction =>
  action.action === 'move' || action.action === 'move-group';

export const isComponentGroupTransformAction = (
  action: PinTransformAction
): action is PinComponentGroupTransformAction => action.action === 'move-group' || action.action === 'resize-group';
