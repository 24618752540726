import { AnyPhase, AnyView, InitializedPlayerStoreState } from '../playerStore.types';
import { Direction } from './reducers.types';

export function nextPhase<P extends AnyPhase>(phase: AnyPhase, phaseOrder: P[], direction: Direction): P | null {
  const index = phaseOrder.indexOf(phase as unknown as P);
  if (index === -1) {
    return null;
  }
  return phaseOrder[index + direction] || null;
}

export function applyViewToState(state: InitializedPlayerStoreState, pinId: string, nextView: AnyView) {
  state.pinState[pinId].view = nextView;

  return {
    ...state,
    currentView: nextView,
  };
}
