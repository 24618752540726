import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPrivacy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 2.669c-2.538 0-7.703 2.296-7.703 2.296s-1.602.909-1.602 6.619c0 6.525 6.297 8.932 9.399 9.747M12 2.67c2.538 0 7.703 2.296 7.703 2.296s1.602.909 1.602 6.619c0 6.525-6.11 8.932-9.211 9.747M12 2.67v18.638l.094.024"
    />
  </svg>
);
export default SvgIconPrivacy;
