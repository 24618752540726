import { useDocumentMutation } from '@lessonup/client-integration';
import {
  BodyText,
  Button,
  FormFieldValidation,
  Headline,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  useForm,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React, { useRef } from 'react';
import { ForwardAgreementDocument } from '../../AgreementPage.graphql.generated';

interface FormInputs {
  name: string;
  position: string;
  email: string;
}

interface ForwardAgreementProps {
  t: TFunction;
  agreementId: string;
  secret: string;
}

export const ForwardAgreement: React.FC<ForwardAgreementProps> = ({ t, agreementId, secret }) => {
  const [forwardAgreementMutation, { loading, called }] = useDocumentMutation(ForwardAgreementDocument);

  const onSubmit = (data: FormInputs) => {
    const { name, position, email } = data;

    if (!name || !position || !email) return;

    forwardAgreementMutation({
      variables: {
        agreementId,
        secret,
        contact: {
          name,
          position,
          email,
        },
      },
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm<FormInputs>();

  const nameRef = useRef<HTMLInputElement | null>(null);
  const positionRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);

  const { ref: registerNameRef, ...nameRest } = register('name', {
    required: { value: true, message: t('requiredName') },
    onBlur(event) {
      setValue('name', event.target.value.trim());
    },
    onChange() {
      clearErrors('name');
    },
  });

  const { ref: registerPositionRef, ...positionRest } = register('position', {
    required: { value: true, message: t('requiredPosition') },
    onBlur(event) {
      setValue('position', event.target.value.trim());
    },
    onChange() {
      clearErrors('position');
    },
  });

  const { ref: registerEmailRef, ...emailRest } = register('email', {
    required: { value: true, message: t('requiredEmail') },
    onBlur(event) {
      setValue('email', event.target.value.trim());
    },
    onChange() {
      clearErrors('email');
    },
  });

  const validateGeneric: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  return (
    <SpaceBetween direction="y" spacing={spacing.size12}>
      <Headline as="h3" size="medium">
        {t('forwardAgreementTitle')}
      </Headline>
      <BodyText>{t('forwardAgreementDescription')}</BodyText>
      <FormInputsWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SpaceBetween direction="y" spacing={spacing.size12}>
            <InputFormField
              label={t('name')}
              type="text"
              {...nameRest}
              ref={(name) => {
                nameRef.current = name;
                registerNameRef(name);
              }}
              validation={validateGeneric('name')}
            ></InputFormField>
            <InputFormField
              label={t('position')}
              type="text"
              {...positionRest}
              ref={(position) => {
                positionRef.current = position;
                registerPositionRef(position);
              }}
              validation={validateGeneric('position')}
            ></InputFormField>
            <InputFormField
              label={t('email')}
              type="email"
              {...emailRest}
              ref={(email) => {
                emailRef.current = email;
                registerEmailRef(email);
              }}
              validation={validateGeneric('email')}
            ></InputFormField>
            <Button type="submit" loading={loading}>
              {t('send')}
            </Button>
            {!loading && called && <BodyText>{t('forwardAgreementSuccess')}</BodyText>}
          </SpaceBetween>
        </form>
      </FormInputsWrapper>
    </SpaceBetween>
  );
};

const FormInputsWrapper = styled.div`
  max-width: 30rem;
`;
