import { isQuizTeacherPin, Pin, PinSwitchStatic } from '@lessonup/pin-renderer';
import React from 'react';
import { QuizPinEditor } from '../../../PinEditor/PinSwitchEditor/pins/QuizPinEditor';

interface PinSwitchStaticPinRailProps {
  pin: Pin;
  children: React.ReactNode;
}
export const PinSwitchStaticPinRail: React.FC<PinSwitchStaticPinRailProps> = ({ pin, children }) => {
  switch (pin.type) {
    case 'MULTIPLE_CHOICE':
      if (!isQuizTeacherPin(pin)) {
        return null;
      }
      return (
        <QuizPinEditor pinSettings={pin.settings} pin={pin} isThumb>
          {children}
        </QuizPinEditor>
      );
    default:
      return PinSwitchStatic({ pin, children });
  }
};
