import _ from 'lodash';

export type Grade = number | string;

export interface UserGrade {
  userId: string;
  grade: Grade;
}

export namespace Grade {
  export function isGrade(grade: any): grade is Grade {
    return (_.isString(grade) && !_.isEmpty(grade)) || _.isNumber(grade);
  }
}
