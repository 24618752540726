import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import {
  calculateTooltipPosition,
  PortalTooltipProps,
  Resize,
  StyledShortcut,
  StyledText,
  TooltipDirection,
} from './tooltip.utils';

/**
 * @description
 * `PortalTooltip` renders a tooltip that appears above all other elements using React's `createPortal`.
 * It is designed to overcome stacking and positioning issues, to handle more complex UIs. While still maintaining
 * the original usability for the component in React with React.JSX.
 * Example use case would be a tooltip that goes out of bounds in a container with an overflow hidden or scroll.
 */

export const PortalTooltip: React.FC<PortalTooltipProps> = ({
  children,
  direction = 'bottom',
  content,
  shortcut,
  resize,
  zIndexOverride,
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: -100, left: -100 });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  useEffect(() => {
    if (visible && wrapperRef.current && tooltipRef.current) {
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      setTooltipPosition(calculateTooltipPosition(wrapperRect, tooltipRect, direction));
    }
  }, [visible, direction]);

  return (
    <>
      <div ref={wrapperRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {visible &&
        createPortal(
          <StyledReactTooltip
            ref={tooltipRef}
            style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
            directionCss={direction}
            resize={resize}
            zIndexOverride={zIndexOverride}
          >
            <SpaceBetween direction="x" spacing={spacing.size16} alignItems="flex-start">
              {typeof content === 'string' ? <StyledText size="small">{content}</StyledText> : content}
              {shortcut && <StyledShortcut size="small">{shortcut}</StyledShortcut>}
            </SpaceBetween>
          </StyledReactTooltip>,
          document.body
        )}
    </>
  );
};

const StyledReactTooltip = styled.div<{
  directionCss: TooltipDirection;
  resize?: Resize;
  zIndexOverride?: number;
}>`
  border-radius: ${borderRadius.rounded4};
  width: max-content;
  padding: ${spacing.size8};
  background-color: ${color.highContrast.surface.background};
  position: absolute;
  isolation: isolate;
  max-width: ${(props) => (props.resize === 'fixed' ? '240px' : 'none')};
  z-index: ${(props) => props.zIndexOverride ?? '1000'};
`;
