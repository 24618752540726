import styled from '@emotion/styled';
import React from 'react';
import { Colorset, ComponentColorTheme, getColorset } from '../../../foundations';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { ComponentSize } from '../../../foundations/size/size.types';
import { spacing, spacingScaled } from '../../../foundations/spacing/spacing';

export interface IndicatorMarkProps {
  content: string | React.ReactNode;
  indicatorTheme?: ComponentColorTheme;
  size?: ComponentSize;
  subjacent?: boolean;
  dashedBorder?: boolean;
}

// WIP component while we are researching the use cases and discussing design. Will just be used for answer cards atm.

export const IndicatorMark: React.FC<IndicatorMarkProps> = ({
  content,
  indicatorTheme = 'neutral',
  size = 'small',
  subjacent = false,
  dashedBorder = false,
}) => {
  const colorset = getColorset(indicatorTheme);
  return (
    <StyledIndicatorMark colorset={colorset} size={size} subjacent={subjacent} dashedBorder={dashedBorder}>
      {content}
    </StyledIndicatorMark>
  );
};

type StyledIndicatorMarkProps = Pick<IndicatorMarkProps, 'size' | 'subjacent' | 'dashedBorder'> & {
  colorset: Colorset;
};

const StyledIndicatorMark = styled.div<StyledIndicatorMarkProps>`
  color: ${({ subjacent, colorset }) => (subjacent ? colorset.onSubjacent : colorset.on)};
  background-color: ${({ subjacent, colorset }) => (subjacent ? colorset.fillSubjacent : colorset.fill)};
  border: ${({ subjacent, dashedBorder, size, colorset }) =>
    `${spacingScaled(spacing.size2, size)} ${dashedBorder ? 'dashed' : 'solid'} ${
      subjacent ? colorset.strokeSubjacent : colorset.stroke
    }`};
  border-radius: ${borderRadius.roundedFull};
  padding: ${({ size }) => spacingScaled(spacing.size2, size)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => spacingScaled(spacing.size24, size)};
  height: ${({ size }) => spacingScaled(spacing.size24, size)};
  flex-shrink: 0;
`;
