import { isClickInContextMenu, isClickInElementWithClass } from '@lessonup/client-integration';
import { Box, PinComponent, PinComponentId, Vector } from '@lessonup/pins-shared';
import { compact, throttle } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { isComponentMoveAction, PinHoverAction, PinTransformAction } from '../../types';
import { ArrangedRailLayouts, RailLayout } from '../../types/helpLines/helpLines.types';
import { createRails } from '../../utils/helpLines/createRails.utils';
import { isRailEligibleAction } from '../../utils/helpLines/rails.utils';
import { useUpdatePin } from '../../utils/updatePinContext/updatePinContext';
import {
  MouseEventState,
  OnMouseDownHandler,
  OnMouseEnterHandler,
  OnMouseEventHandler,
  OnMouseLeaveHandler,
} from './useHandleMouseEvents.types';
import {
  createMouseEventStateForMouseDown,
  createNewPinComponentLayout,
  createSelectionBox,
  determineSelectedPinComponents,
  isClickEventModified,
  selectionBoxIsIntersectingWithLayout,
} from './useHandleMouseEvents.utils';

export const useHandleMouseEvents = (
  pinScale: number,
  pinRendererOffset: Vector,
  pinComponents: PinComponent[],
  selectedPinComponentIds: string[],
  setModeSelecting: React.Dispatch<React.SetStateAction<string[]>>,
  setSubSelectionValue: React.Dispatch<React.SetStateAction<string | null>>,
  setSelectedPinIds: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const mouseEventState = useRef<MouseEventState | null>(null);
  const [mutatingPinComponents, setMutatingPinComponents] = useState<PinComponent[]>([]);
  const [selectionBox, setSelectionBox] = useState<Box | null>(null);
  const [railLayouts, setRailLayouts] = useState<ArrangedRailLayouts>({ horizontal: [], vertical: [] });
  const [visibleRails, setVisibleRails] = useState<RailLayout[]>([]);
  const [isMoveActionInProgress, setIsMoveActionInProgress] = useState<boolean>(false);
  const [hoveredPinComponentId, setHoveredPinComponentId] = useState<PinComponentId | null>(null);
  const updatePinComponent = useUpdatePin();
  const releaseMutationTimeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null);
  const originalSelectionIds = useRef<string[]>([]);

  // ToDo: ED-1296 - classname is hardcoded here, this needs a better solution but that should be carefully considered
  const famClassName = 'floating-action-menu';

  const handleOnMouseDown: OnMouseDownHandler = useMemo(
    () =>
      throttle(
        (event: React.MouseEvent<HTMLDivElement>, action: PinTransformAction) => {
          const determinedSelectedPinComponentIds = determineSelectedPinComponents(action, selectedPinComponentIds);
          if (isClickInContextMenu(event) || isClickInElementWithClass(event, famClassName)) return;

          mouseEventState.current = createMouseEventStateForMouseDown(
            event,
            action,
            pinRendererOffset,
            pinScale,
            determinedSelectedPinComponentIds,
            // filter the locked components to not be included in the selection
            pinComponents.filter((pinComponent) => !pinComponent.settings.lockLayout)
          );

          originalSelectionIds.current = selectedPinComponentIds;
          setSelectedPinIds([]);
          setModeSelecting((selectedPinComponentIds) => {
            const mergeSelectedPinComponentIds = isClickEventModified(event.nativeEvent);
            const selectedComponents = pinComponents.filter((comp) => {
              if (mergeSelectedPinComponentIds && comp.settings.lockLayout) return false;
              if (mergeSelectedPinComponentIds && selectedPinComponentIds.includes(comp.id)) return true;

              return determinedSelectedPinComponentIds.includes(comp.id);
            });

            return compact(selectedComponents.map((comp) => comp.id));
          });

          setSubSelectionValue(null);
          if (isRailEligibleAction(action)) {
            setRailLayouts(createRails(pinComponents, determinedSelectedPinComponentIds));
          }
        },
        10,
        { leading: true, trailing: false }
      ),
    [
      selectedPinComponentIds,
      pinRendererOffset,
      pinScale,
      pinComponents,
      setSelectedPinIds,
      setModeSelecting,
      setSubSelectionValue,
    ]
  );

  const handleOnMouseMove: OnMouseEventHandler = useCallback(
    (event) => {
      if (!mouseEventState.current) return;
      const createdSelectionBox = createSelectionBox(event, mouseEventState.current, pinRendererOffset, pinScale);
      const mutatedPinComponents = createNewPinComponentLayout(
        event,
        mouseEventState.current,
        railLayouts,
        setVisibleRails,
        pinRendererOffset,
        pinScale
      );

      if (isComponentMoveAction(mouseEventState.current.action)) {
        setIsMoveActionInProgress(true);
      }

      // TODO: check if we can add this back in. handleOnMouseUp needs to be called after requestAnimationFrame is finished
      // requestAnimationFrame(() => {
      if (mutatedPinComponents) {
        clearMutationTimeout();
        setMutatingPinComponents(mutatedPinComponents);
      }

      if (createdSelectionBox) {
        throttledSetSelectedPinComponents(
          createdSelectionBox,
          mouseEventState.current.pinComponentsStart,
          setModeSelecting,
          originalSelectionIds.current,
          isClickEventModified(event)
        );
        setSelectionBox(createdSelectionBox);
      }
      // });
    },
    [pinRendererOffset, pinScale, railLayouts, setModeSelecting, setIsMoveActionInProgress]
  );

  const handleOnMouseUp: OnMouseEventHandler = useCallback(() => {
    setIsMoveActionInProgress(false);

    if (mutatingPinComponents.length) {
      updatePinComponent({
        type: 'updateComponentLayout',
        updates: mutatingPinComponents.reduce((acc, comp) => {
          if (comp.settings.lockLayout) {
            return acc;
          }

          return { ...acc, [comp.id]: comp.layout };
        }, {}),
      });
    }

    setSelectionBox(null);
    mouseEventState.current = null;
    setRailLayouts({ horizontal: [], vertical: [] });
    setVisibleRails([]);

    clearMutationTimeout();
    releaseMutationTimeoutHandler.current = setTimeout(() => {
      // TODO: ED-119 the old teacher application needs to be able to apply the updates to mini mongo
      // and update the react tree. This timeout is to ensure that the updates are available
      setMutatingPinComponents([]);
    }, 200);
  }, [mutatingPinComponents, updatePinComponent, setIsMoveActionInProgress]);

  const handleOnMouseEnter: OnMouseEnterHandler = useCallback(
    (event: React.MouseEvent, action: PinHoverAction) => {
      setHoveredPinComponentId(action.componentId);
    },
    [setHoveredPinComponentId]
  );

  const handleOnMouseLeave: OnMouseLeaveHandler = useCallback(() => {
    setHoveredPinComponentId(null);
  }, [setHoveredPinComponentId]);

  function clearMutationTimeout() {
    if (!releaseMutationTimeoutHandler.current) return;
    clearTimeout(releaseMutationTimeoutHandler.current);
  }

  useEventListener('mousemove', handleOnMouseMove);
  useEventListener('mouseup', handleOnMouseUp);

  return {
    mutatingPinComponents,
    selectionBox,
    visibleRails,
    handleOnMouseDown,
    handleOnMouseEnter,
    handleOnMouseLeave,
    isMoveActionInProgress,
    hoveredPinComponentId,
  };
};

const throttledSetSelectedPinComponents = throttle(
  (
    selectionBox: Box,
    pinComponents: PinComponent[],
    setModeSelecting: React.Dispatch<React.SetStateAction<string[]>>,
    originalSelectionIds: string[],
    mergeWithOriginalSelection: boolean
  ) => {
    const selectedComponents = pinComponents.filter((comp) => {
      if (comp.settings.lockLayout) return false;
      if (mergeWithOriginalSelection && originalSelectionIds.includes(comp.id)) return true;

      return selectionBoxIsIntersectingWithLayout(selectionBox, comp.layout);
    });

    setModeSelecting(() => {
      return compact(selectedComponents.map((comp) => comp.id));
    });
  },
  100
);
