import {
  BodyText,
  borderRadius,
  color,
  createModal,
  elevationShadow,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  rem,
  SpaceBetween,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React, { PropsWithChildren } from 'react';

/* ToDo: we need to allign the existing Tooltip component with this one, 
  updating it's styling and deciding if we want to use the modal popover for
  this or to seprate the popover logic and make that reusable.

  This should also probably be moved to the ui-components library.
*/

export interface TooltipPopoverProps {
  direction?: ModalPopoverDirection;
  modalAnchor?: ModalPopoverAnchor;
  parentSelector: () => HTMLLIElement | HTMLButtonElement | null;
  content: string;
  contentLabel?: string;
  EndSlot?: React.JSX.Element;
  allignment?: 'horizontal' | 'vertical';
  maxContentWidth?: number;
  restrainSize?: boolean;
}

export const TooltipPopover = createModal(
  ({
    direction = 'bottom',
    modalAnchor = 'center',
    parentSelector,
    content,
    contentLabel,
    EndSlot,
    allignment = 'horizontal',
    maxContentWidth,
    restrainSize = true,
  }: PropsWithChildren<TooltipPopoverProps>) => {
    const modal = useModal();
    return (
      <StyledModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel={contentLabel || content}
        restrainSize={restrainSize}
        shouldFocusAfterRender={false}
      >
        <StyledContent
          maxWidth={maxContentWidth || 268}
          direction={allignment === 'horizontal' ? 'x' : 'y'}
          spacing={spacing.size4}
          padding={spacing.size4}
        >
          <StyledMainContent>
            <BodyText size="small">{content}</BodyText>
          </StyledMainContent>
          {EndSlot}
        </StyledContent>
      </StyledModalPopover>
    );
  }
);

const StyledModalPopover = styled(ModalPopover)`
  border: none;
  border-radius: ${borderRadius.rounded6};
  background-color: ${color.primitives.black};
  color: ${color.highContrast.surface.text};
  box-shadow: ${elevationShadow.e100};
`;

const StyledContent = styled(SpaceBetween)<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => rem(maxWidth)};
`;

const StyledMainContent = styled.div`
  padding: 0 ${spacing.size2};
  text-align: center;
  word-break: break-word;
`;
