import { spacing, styled, Tag } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LearningPhaseName, learningPhasesI18NNS } from '../../../../../utils/learningPhases/LearningPhase';

export const PinRailHeader: React.FC<{ phaseName: LearningPhaseName }> = ({ phaseName }) => {
  const { t } = useTranslation(learningPhasesI18NNS);

  return (
    <PinRailHeaderWrapper>
      <Tag priority="medium">{t(phaseName)}</Tag>
    </PinRailHeaderWrapper>
  );
};

const PinRailHeaderWrapper = styled.div`
  margin-left: ${spacing.size32};
`;
