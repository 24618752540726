import {
  boxFromLayout,
  HotspotPinComponentRenderer,
  ImagePinComponentLoadingPlaceholder,
  ImagePinComponentRenderer,
  PinComponent,
  PinComponentSwitchDefaultRendererMemo,
  PinComponentSwitchRendererProps,
  TextPinComponentRenderer,
} from '@lessonup/pin-renderer';
import React, { memo } from 'react';
import { useEditorTranslation } from '../../../hooks/useEditorTranslation';
import { TipTapCollaborative } from '../../../utils/yjs/TipTapCollaborative';
import { getPlaceholderTranslation } from './utils/placeholder';

export type PinComponentLoadingSwitchRendererEditorProps = {
  pinComponent: PinComponent;
};

export const PinComponentLoadingSwitchRendererEditor: React.FC<PinComponentLoadingSwitchRendererEditorProps> = ({
  pinComponent,
}) => {
  switch (pinComponent.type) {
    case 'IMAGE':
      return <ImagePinComponentLoadingPlaceholder />;
    default:
      return null;
  }
};

export const PinComponentLoadingSwitchRendererEditorMemo = memo(PinComponentLoadingSwitchRendererEditor);

const PinComponentSwitchRendererEditor: React.FC<PinComponentSwitchRendererProps> = (props) => {
  const { pinComponent } = props;
  const { t } = useEditorTranslation();

  const box = boxFromLayout(pinComponent.layout);

  switch (pinComponent.type) {
    case 'IMAGE':
      return (
        <ImagePinComponentRenderer
          settings={pinComponent.settings}
          layout={box}
          isThumb={props.isThumb}
          onDoubleClick={() => props.setModeCropping && props.setModeCropping(pinComponent)}
        />
      );
    case 'TEXT':
      return (
        <TextPinComponentRenderer
          componentId={pinComponent.id}
          settings={pinComponent.settings}
          editable={props.editable}
          pinId={props.pinId}
          active={props.active}
          Renderer={TipTapCollaborative}
          isTextEditMode={props.isTextEditMode}
          setModeTextEdit={props.setModeTextEdit}
          placeholder={t(getPlaceholderTranslation(props.pinType, pinComponent.id, props.layoutKey, props.layoutParts))}
        />
      );
    case 'HOTSPOT':
      return (
        <HotspotPinComponentRenderer
          pinComponent={pinComponent}
          active={props.active}
          pinId={props.pinId}
          Renderer={TipTapCollaborative}
        />
      );
  }

  return <PinComponentSwitchDefaultRendererMemo {...props} />;
};

export const PinComponentSwitchRendererEditorMemo = memo(PinComponentSwitchRendererEditor);
