import { getHotspotIcon, HotspotIcon, hotspotIconList, HotspotSettings } from '@lessonup/pin-renderer';
import {
  borderRadius,
  color,
  elevationShadow,
  IconChevronLeft,
  SharedFormFieldProps,
  sharedFormFieldStyle,
  sharedInputSelectStyle,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { isNull } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsToggle } from '../../../../../SettingsToggle/SettingsToggle';

interface IconFillPickerProps {
  selectedIcon: HotspotIcon | null;
  setSelectedIcon: (iconName: HotspotSettings['iconFill']) => void;
}

export const IconFillPicker: React.FC<IconFillPickerProps> = ({ selectedIcon, setSelectedIcon }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation('iconPicker');
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        eventTargetIsNode(event.target) &&
        !selectRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleIconSelect = (icon: HotspotIcon) => {
    setSelectedIcon(icon);
    setIsDropdownOpen(false);
  };

  return (
    <SelectContainer>
      <InputField
        ref={selectRef}
        type="text"
        readOnly
        value={selectedIcon ? `${t(getHotspotIcon(selectedIcon).translateKey)} ` : ''}
        placeholder={t('selectIcon')}
        onClick={toggleDropdown}
        selectedIcon={selectedIcon}
        label=""
        formFieldSize="small"
      />
      <IconChevronLeft />
      {selectedIcon && getHotspotIcon(selectedIcon).icon}
      {isDropdownOpen && (
        <Dropdown ref={dropdownRef}>
          {hotspotIconList.map((icon, index) => (
            <SettingsToggle
              key={index}
              type="radio"
              toggleVariant="icon-only"
              ariaLabel={t('selectHotSpotIcon', { icon: index })}
              icon={getHotspotIcon(icon).icon}
              checked={icon === selectedIcon}
              onChange={() => handleIconSelect(icon)}
            />
          ))}
        </Dropdown>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const InputField = styled.input<Pick<IconFillPickerProps, 'selectedIcon'> & SharedFormFieldProps>`
  width: 100%;
  cursor: pointer;
  appearance: none;
  flex-grow: 1;
  ${sharedInputSelectStyle}
  ${sharedFormFieldStyle}
  ${(props) =>
    props.selectedIcon
      ? `padding-left: calc(${spacing.size32} + ${spacing.size8})`
      : ''}; // icon width + icon spacing + padding from icon

  & + svg {
    position: absolute;
    right: ${spacing.size12};
    color: ${color.accent.secondaryContainer.text};
    font-size: ${spacing.size16};
    transform: rotate(270deg);
    pointer-events: none;
  }

  & + svg ~ svg {
    position: absolute;
    left: ${spacing.size8};
    color: ${color.accent.secondaryContainer.text};
    height: ${spacing.size16};
    pointer-events: none;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: ${spacing.size8};
  padding: ${spacing.size8};
  display: grid;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  grid-template-columns: repeat(6, 1fr);
  gap: ${spacing.size8};
  background-color: ${color.neutral.surface.background};
  border: 1px solid ${color.neutral.outline.background};
  border-radius: ${borderRadius.rounded24};
  box-shadow: ${elevationShadow.e300};
`;

//TODO move this somewhere more generic?
function eventTargetIsNode(target: EventTarget | null): target is Node {
  return !isNull(target) && target instanceof Node;
}
