import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { TextElement } from '../../foundations/typography/TextElement/TextElement';
import { fontSize } from '../../foundations/typography/typography.utils';

export type TagSize = 'small' | 'medium';
export type TagPriority = 'low' | 'medium' | 'high' | 'error' | 'success' | 'warning';
export type TagVariant = 'default' | 'icon-start' | 'icon-end';

export interface TagProps {
  size?: TagSize;
  priority?: TagPriority;
  variant?: TagVariant;
  className?: string;
  children?: React.ReactNode;
}

export const Tag: React.FC<TagProps> = ({ variant, children, priority = 'low', size = 'medium', className }) => {
  return (
    <TagWrapper priority={priority} size={size} variant={variant} className={className}>
      <InnerBodyText textStyle="caption">{children}</InnerBodyText>
    </TagWrapper>
  );
};

const InnerBodyText = styled(TextElement)`
  display: flex;
  white-space: nowrap;
  align-items: center;
`;

const TagWrapper = styled.span<Pick<TagProps, 'priority' | 'size' | 'variant'>>`
  display: inline-flex;
  align-items: center;
  padding: ${({ size }) =>
    size === 'small' ? `${spacing.size0} ${spacing.size4}` : `${spacing.size0} ${spacing.size8}`};
  border-radius: 2px;
  svg {
    height: ${fontSize.bodyTextSizeSmall};
    width: ${fontSize.bodyTextSizeSmall};
  }

  ${(props) => {
    switch (props.variant) {
      case 'icon-start':
        return css`
          svg {
            margin-right: ${spacing.size4};
          }
        `;
      case 'icon-end':
        return css`
          svg {
            margin-left: ${spacing.size4};
          }
        `;
    }
  }}

  // ToDo: use new colors after design documentation is updated
  ${(props) => {
    switch (props.priority) {
      case 'low':
        return css`
          background-color: ${color.neutral.surface2.background};
          color: ${color.neutral.surface.text};
        `;
      case 'medium':
        return css`
          background-color: ${color.accent.tertiary.background};
          color: ${color.accent.tertiary.text};
        `;
      case 'high':
        return css`
          background-color: ${color.accent.primary.background};
          color: ${color.accent.primary.text};
        `;
      case 'warning':
        return css`
          background-color: ${color.additional.warningContainer.background};
          color: ${color.additional.warningContainer.text};
        `;
      case 'success':
        return css`
          background-color: ${color.additional.successContainer.background};
          color: ${color.additional.successContainer.text};
        `;
      case 'error':
        return css`
          background-color: ${color.additional.errorContainer.background};
          color: ${color.additional.errorContainer.text};
        `;
    }
  }}
`;
