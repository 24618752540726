import { useSortable } from '@dnd-kit/sortable';
import { TeacherPin } from '@lessonup/pin-renderer';
import { Badge, color, rem, spacing, styled, Thumb, ThumbCheckboxSelection } from '@lessonup/ui-components';
import React, { HTMLAttributes, memo, useCallback, useMemo } from 'react';
import { SelectedPinIds, SetSelectedPinIds } from '../../../context/EditorContext/EditorContext.types';
import { Collaborator } from '../../../utils/yjs/yjs.types';
import { PinRailCollaborators } from './PinRailCollaboratorIndicator';
import { PinViewerStaticPinRail } from './PinViewerStaticPinRail/PinViewerStaticPinRail';

interface PinRailThumbProps {
  pin: TeacherPin;
  index: number;
  overwriteCheckboxVisibility: boolean;
  setSelectedPinIds: SetSelectedPinIds;
  selectedPinIds: SelectedPinIds;
  isActive: boolean;
  setActivePinId?: (pinId: string) => void;
  collaborators?: Collaborator[];
  handleOnMouseDown: (event: React.MouseEvent, pinId: string) => void;
  isSelected?: boolean;
  handleOnContextMenu?: (event: React.MouseEvent, pin: TeacherPin) => void;
}

interface PinRailThumbAsButtonProps extends PinRailThumbProps, HTMLAttributes<HTMLButtonElement> {}

const pinWidth = 130;

export const PinRailThumbComponent: React.FC<PinRailThumbAsButtonProps> = ({
  pin,
  index,
  isActive,
  overwriteCheckboxVisibility,
  setSelectedPinIds,
  setActivePinId,
  collaborators,
  isSelected = false,
  ...buttonProps
}) => {
  const handleCheckboxChange = useCallback(() => {
    setSelectedPinIds((prev) => {
      if (prev.includes(pin.id)) {
        return prev.filter((id) => id !== pin.id);
      }
      return [...prev, pin.id];
    });
  }, [pin.id, setSelectedPinIds]);

  const checkboxSelection: ThumbCheckboxSelection = useMemo(() => {
    return { enabled: true, checked: isSelected, onChange: handleCheckboxChange };
  }, [handleCheckboxChange, isSelected]);

  const onClick = useCallback(() => {
    setActivePinId?.(pin.id);
  }, [pin.id, setActivePinId]);

  return (
    <Thumb
      {...buttonProps}
      resetFocus
      number={index + 1}
      key={pin.id}
      thumbType="editor"
      onClick={onClick}
      checkboxSelection={checkboxSelection}
      isActive={isActive}
      overwriteCheckboxVisibility={overwriteCheckboxVisibility}
    >
      <ThumbPaneWrapper>
        <PinViewerStaticPinRail pin={pin} width={pinWidth} isThumb={true} pinComponentsAreStatic={false} />
        <PinRailCollaborators collaborators={collaborators} />
        <ThumbPane />
      </ThumbPaneWrapper>
    </Thumb>
  );
};

export const PinRailThumb = memo(PinRailThumbComponent);

interface DragOverlayPinRailThumbProps {
  pin: PinRailThumbProps['pin'];
  index: PinRailThumbProps['index'];
  stackedPinsCount?: number;
  collaboratorsDict: Record<string, Collaborator[]>;
}

export const DragOverlayPinRailThumb: React.FC<DragOverlayPinRailThumbProps> = ({
  pin,
  index,
  stackedPinsCount,
  collaboratorsDict,
}) => {
  return (
    <Thumb number={index + 1} key={pin.id} thumbType="editorDragOverlay">
      <ThumbPaneWrapper>
        {stackedPinsCount && <StackedThumbUnderlay />}
        <PinViewerStaticPinRail pin={pin} width={pinWidth} isThumb={true} />
        <PinRailCollaborators collaborators={collaboratorsDict[pin.id]} />
        <ThumbPane />
        {stackedPinsCount && <Badge badgeContent={stackedPinsCount} />}
      </ThumbPaneWrapper>
    </Thumb>
  );
};

export const SortablePinRailThumb: React.FC<PinRailThumbProps> = (props) => {
  const { attributes, listeners } = useSortable({
    id: props.pin.id,
  });

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    props.handleOnContextMenu?.(event, props.pin);
  };

  return (
    <PinRailThumbWrapper
      onContextMenu={onContextMenu}
      onClick={(event) => props.handleOnMouseDown(event, props.pin.id)}
    >
      <PinRailThumb {...props} {...attributes} {...listeners} />
    </PinRailThumbWrapper>
  );
};

const PinRailThumbWrapper = styled.div`
  padding-top: ${spacing.size10}; // to make room for the collaborator indicator, this design is temporary
`;

const ThumbPaneWrapper = styled.div`
  position: relative;
`;

const ThumbPane = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const StackedThumbUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${rem('1px')} solid ${color.neutral.outline.background};
  background-color: ${color.neutralNew.level1.fill};
  transform: translate(2%, 6%) rotate(-1deg);
  z-index: -1;
`;
