import jsCookies from 'js-cookie';

// Including date in cookieName to retrigger cookiePopup when we added Facebook pixel
// note, that is also a reference in search/src/server/controllers/action.ts . also update that until that file can be removed
const COOKIE_PREFERENCES_NAME = 'cookiePreferences-13_04_2022';
const COOKIE_DURATION = 365; // 1 year

export enum CookieSetting {
  ANALYTIC = 'analytic',
  MARKETING = 'marketing',
}

export interface CookieSettings {
  analytic: boolean;
  marketing: boolean;
}

export function getCookieSettings(): CookieSettings | undefined {
  const cookie = jsCookies.get(COOKIE_PREFERENCES_NAME);
  if (!cookie) return undefined;

  try {
    const value = JSON.parse(decodeURIComponent(cookie));
    if (!Array.isArray(value)) return undefined;

    return {
      analytic: value.includes(CookieSetting.ANALYTIC),
      marketing: value.includes(CookieSetting.MARKETING),
    };
  } catch (error) {
    console.error(`Failed to parse cookie with error: ${error}, ${COOKIE_PREFERENCES_NAME}`);
    return undefined;
  }
}

export function setCookieSettings(settings: CookieSettings): void {
  const { analytic, marketing } = settings;
  const cookies: string[] = [];

  if (analytic) cookies.push(CookieSetting.ANALYTIC);
  if (marketing) cookies.push(CookieSetting.MARKETING);

  jsCookies.set(COOKIE_PREFERENCES_NAME, JSON.stringify(cookies), {
    expires: COOKIE_DURATION,
    path: '/',
  });
}
