import React, { createContext, MutableRefObject, useContext, useEffect, useId, useMemo, useRef } from 'react';

/**
 * Allows subcomponents of the editor to temporarily disable editor keybinds.
 *
 * For example, a modal dialog might disable keybinds while it is open.
 */
export interface KeybindController {
  /**
   * Temporarily disables keybinds in the editor.
   *
   * Keybinds can be re-enabled by calling `enableKeybinds`, and are
   * automatically re-enabled when the calling component unmounts.
   */
  disableKeybinds(): void;

  /** Re-enable keybinds. */
  enableKeybinds(): void;

  /**
   * Returns whether keybinds are currently enabled.
   *
   * Keybinds are enabled if and only if no component has disabled them.
   *
   * Note: this function should not be used to make rendering decisions as it is
   * not a reactive value. It may only safely be used in event handlers.
   */
  canUseKeybinds(): boolean;
}

/** The IDs of those components that have temporarily disabled keybindings. */
const KeybindControllerContext = createContext<MutableRefObject<Set<string>> | null>(null);

/** React context that allows subcomponents to temporarily disable editor keybinds. */
export function KeybindControllerProvider({ children }: React.PropsWithChildren) {
  const keybindsDisabledBy = useRef(new Set<string>());
  return <KeybindControllerContext.Provider value={keybindsDisabledBy}>{children}</KeybindControllerContext.Provider>;
}

/** Provides access to the `KeybindController` from subcomponents of the editor. */
export function useKeybindController(): KeybindController {
  const keybindsDisabledByRef = useContext(KeybindControllerContext);

  if (keybindsDisabledByRef === null) {
    throw new Error('useKeybindController must be used within a KeybindControllerProvider');
  }

  // Use a per-component id to track which components have disabled keybinds.
  const id = useId();

  const controller = useMemo<KeybindController>(() => {
    return {
      enableKeybinds() {
        keybindsDisabledByRef.current.delete(id);
      },
      disableKeybinds() {
        keybindsDisabledByRef.current.add(id);
      },
      canUseKeybinds() {
        return keybindsDisabledByRef.current.size === 0;
      },
    };
  }, [id, keybindsDisabledByRef]);

  useEffect(() => {
    // Make sure the component re-enables keybinds when it unmounts.
    return controller.enableKeybinds;
  }, [controller.enableKeybinds]);

  return controller;
}
