import { AppError, isAccessibleForUser } from '@lessonup/utils';
import _ from 'lodash';
import { AssignmentType } from '.';
import { ExclusionType, PinUtils } from '../common';
import { SeedShuffle } from '../common/SeedShuffle';
import { AssignmentEntry } from '../entries';
import { Assignment } from './Assignment';
import { AssignmentContent } from './AssignmentContent';
import { AssignmentMeta } from './AssignmentMeta';
import { AssignmentPin } from './AssignmentPin';
import { AssignmentSettings } from './AssignmentSettings';

declare const i18n;
/**
 * @deprecated slowly move this to other namespaces
 */

export interface AssignmentPinDict {
  [key: string]: AssignmentPin;
}

export class AssignmentUtils {
  public static filterGameMode(items: Assignment[] | undefined, type: AssignmentType): Assignment[] {
    return _.filter(items, (item) => AssignmentUtils.isMode(item, type));
  }

  public static isMode(assignment: Assignment | undefined, type: AssignmentType): boolean {
    return (assignment && assignment.settings && assignment.settings.type === type) || false;
  }

  public static pin(assignment: AssignmentContent | undefined, id: string | undefined): AssignmentPin | undefined {
    return (assignment && id && assignment.pins[id]) || undefined;
  }

  public static pinDictForIds(assignment: AssignmentContent | undefined, ids: string[]): AssignmentPinDict | undefined {
    return (assignment && ids && _.pick(assignment.pins, ids)) || undefined;
  }

  public static pins(assignment: AssignmentContent | undefined): AssignmentPin[] {
    if (!assignment || !assignment.pins) return [];
    return Object.values(assignment.pins).sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  public static interactivePins(assignment: Assignment | undefined): AssignmentPin[] {
    if (!assignment || !assignment.pins) return [];
    return Object.values(assignment.pins).filter((p) => PinUtils.isInteractive(p));
  }

  public static pinCount(assignment: Assignment | undefined): number | undefined {
    if (!assignment) return undefined;
    return _.size(assignment.pins);
  }

  public static unorderedPins(assignment: AssignmentContent | undefined): AssignmentPin[] {
    if (!assignment || !assignment.pins) return [];
    return Object.values(assignment.pins);
  }

  public static firstPin(assignment: AssignmentContent): AssignmentPin | undefined {
    return _.first(this.pins(assignment));
  }

  public static isVisibilityState(state: any): state is AssignmentSettings.Visibility {
    return ['all', 'studentOnly'].includes(state);
  }

  public static isVisibleToAll(assignment: Assignment): boolean {
    return assignment.visibilityState === 'all';
  }

  public static isVisibleToStudentsOnly(assignment: Assignment): boolean {
    return assignment.visibilityState === 'studentOnly';
  }

  public static isUserOwner(assignment: Assignment, userId: string) {
    return assignment.ownerId === userId;
  }

  public static isUserAllowedToModify(assignment: AssignmentMeta, userId: string) {
    return isAccessibleForUser(assignment, userId); // TODO Add group member auth logic, see "getAssignmentIfAllowed"
  }

  /** throws an error when the assignment isn't joinable */
  public static validateJoinable(settings: AssignmentSettings, requiredType: [AssignmentType]): void {
    if (!requiredType.find((t) => t == settings.type)) {
      throw new AppError('not-allowed');
    }

    if (settings.status == 'closed') {
      throw new AppError('closed');
    }

    if (settings.type === 'realtime' && !settings.playerSettings.allowNewPlayers) {
      throw new AppError('closed');
    }
  }

  // return all pins that have testPoints
  public static pinsWithTestPoints(assignment: AssignmentContent): AssignmentPin[] {
    return AssignmentUtils.unorderedPins(assignment).filter((pin) => _.gt(pin.item.testPoints, 0));
  }

  public static totalTestPoints(assignment: AssignmentContent): number {
    return _.sumBy(AssignmentUtils.unorderedPins(assignment), (pin) => pin.item.testPoints || 0);
  }

  public static getPinShuffleList(pins: AssignmentPin[], userId: string) {
    let pinOrder: string[] = [];
    let pinsToShuffle: string[] = [];

    pins.forEach((pin) => {
      if (PinUtils.isInteractive(pin) && !pin.videoQuestion) {
        pinsToShuffle.push(pin._id);
      } else {
        if (pinsToShuffle.length) {
          pinOrder = pinOrder.concat(SeedShuffle.shuffle(pinsToShuffle, userId));
          pinsToShuffle = [];
        }
        pinOrder.push(pin._id);
      }
    });
    if (pinsToShuffle.length) {
      pinOrder = pinOrder.concat(SeedShuffle.shuffle(pinsToShuffle, userId));
    }
    return pinOrder;
  }

  public static pinsExcluding(pins: AssignmentPin[], exclusion: ExclusionType): AssignmentPin[] {
    return pins.filter((pin) => !pin.item.exclusions || !_.includes(pin.item.exclusions, exclusion));
  }

  /** returns all student pins without exclusions based on the assignment type */
  public static pinsByAssignmentType(assignment: AssignmentContent): AssignmentPin[] {
    const exclusion: ExclusionType = assignment.type === 'realtime' ? 'lesson' : 'student';
    return this.pinsExcluding(AssignmentUtils.pins(assignment), exclusion);
  }

  public static pinIsInAssignment(pin: AssignmentPin, assignment: Assignment): boolean {
    const type = assignment.type === 'realtime' ? 'lesson' : 'student';
    return !_.includes(pin.item.exclusions, type);
  }

  public static mapPinsToShuffleMap(pins: AssignmentPin[], userId: string): AssignmentPin[] {
    const pinOrder = this.getPinShuffleList(pins, userId);
    const newPins: AssignmentPin[] = [];
    pinOrder.length &&
      pinOrder.forEach((pinId) => {
        const pin = pins.find((p) => p._id == pinId);
        if (!pin) return;
        newPins.push(pin);
      });

    return newPins;
  }

  public static pinsOrShuffledPinsForAssignment(assignment: Assignment, pins: AssignmentPin[], userId: string) {
    return Assignment.isTest(assignment) && assignment.settings.randomiseQuestions
      ? this.mapPinsToShuffleMap(pins, userId)
      : pins;
  }

  // TODO: type this
  public static getShuffledQuizAnswers(shuffledIndices: any, quizAnswers: any) {
    if (shuffledIndices.length != quizAnswers.length) {
      throw new AppError('invalid-params', 'Arrays are not in sync');
    }

    return shuffledIndices.map((index: any) => quizAnswers[index]);
  }

  public static getAssignmentTitle(assignment: Assignment) {
    switch (assignment.type) {
      case 'test':
        return i18n.__('toets');
      case 'homework':
        return i18n.__('gedeelde les');
      case 'realtime':
        return i18n.__('klassikale les');
      default:
        return i18n.__('gedeelde les');
    }
  }

  public static disallowForEntryIfLinear(assignment: Assignment, entry: AssignmentEntry): boolean {
    return AssignmentSettings.assignmentIsLinear(assignment.settings) && !AssignmentEntry.isTestPhase(entry, 'review');
  }

  public static isV2Assignment(assignment: Assignment): boolean {
    return assignment.version === 'v2';
  }
}
