import { GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { authTokenStorage } from '../../auth/authTokenStorage';

export const bearerTokenLink = setContext(
  (operation: GraphQLRequest, { headers }: { headers?: Record<string, unknown> }) => {
    const token = authTokenStorage.authAccessToken;
    if (!token) return { headers };

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }
);
