import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconResizeMax = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.5 10.5 21 3m-3.375-.375h3.75v3.75m-18.75 3v-1.5M9 2.625H7.5m6.75 0h-1.5m1.875 18.75h1.5M21.375 15v1.5m0-6.75v1.5M4.12 2.625l-.143.016c-.7.078-1.262.64-1.337 1.34l-.015.14m17.25 17.25.142-.016c.7-.078 1.263-.641 1.338-1.341l.014-.139m-18.744 0v-5.25a1.5 1.5 0 0 1 1.5-1.5h5.25a1.5 1.5 0 0 1 1.5 1.5v5.25a1.5 1.5 0 0 1-1.5 1.5h-5.25a1.5 1.5 0 0 1-1.5-1.5"
    />
  </svg>
);
export default SvgIconResizeMax;
