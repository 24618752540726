import { useStaggeredDocumentQuery } from '@lessonup/client-integration';
import { useState } from 'react';
import { MyUploadsData } from '../Uploads.graphql';
import { MyUploadsDocument, SearchUploadsDocument } from '../Uploads.graphql.generated';
import { UploadQueryData } from '../UploadsFeature.utils';
import { NavigationState } from './useNavigationDispatcher';

interface UseMyUploadsQueryProps {
  onCompleted: (totalCount?: number) => void;
  navigationState: NavigationState;
}

export const useMyUploadsQuery = ({
  onCompleted,
  navigationState,
}: UseMyUploadsQueryProps): UploadQueryData<MyUploadsData> => {
  const limit = navigationState.perPage;
  const offset = (navigationState.page - 1) * navigationState.perPage;
  const [previousData, setPreviousData] = useState<MyUploadsData>();

  const MyUploadsDocumentQuery = useStaggeredDocumentQuery(MyUploadsDocument, {
    variables: {
      limit,
      offset,
      folderId: navigationState.folderId,
      sortBy: navigationState.sorting,
    },
    skip: navigationState.isSearch,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setPreviousData(data);
      return onCompleted(data?.viewer.uploadFolder?.childUploadFoldersAndUploads.totalCount);
    },
  });

  const MyUploadsSearchDocumentQuery = useStaggeredDocumentQuery(SearchUploadsDocument, {
    variables: {
      query: navigationState.search?.query || '',
      limit,
      offset,
    },
    skip: !navigationState.isSearch,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setPreviousData(data);
      return onCompleted(data?.viewer.searchUploads.totalCount);
    },
  });

  const { loading, error, refetch } = navigationState.isSearch ? MyUploadsSearchDocumentQuery : MyUploadsDocumentQuery;

  const retry = async () => {
    await refetch();
  };

  return {
    data: previousData,
    loading,
    error,
    retry,
  };
};
