type Params<P> = P & {
  hash: string;
  query: Record<string, string | undefined>;
};

export interface CurrentRoute<P = unknown> {
  name: string;
  params: Params<P>;
}

type RouteBuildFunc<P> = (props: P) => string;

export class Route<P = unknown> {
  public constructor(
    public readonly name: string,
    private readonly routerVars: P,
    private readonly build: RouteBuildFunc<P>
  ) {}

  public href(props: P) {
    return this.build(props);
  }

  public routerPath() {
    return this.build(this.routerVars);
  }
}
