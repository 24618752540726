import { useKeybindController } from '@lessonup/pin-renderer';
import { useCallback } from 'react';

export function useEditorInputKeybindController() {
  const { disableKeybinds, enableKeybinds } = useKeybindController();
  const onBlur = useCallback(() => {
    enableKeybinds();
  }, [enableKeybinds]);

  const onFocus = useCallback(() => {
    disableKeybinds();
  }, [disableKeybinds]);

  return {
    onBlur,
    onFocus,
  };
}
