import { fontFamilies } from '@lessonup/pins-shared';
import React from 'react';

type FontFamily = (typeof fontFamilies)[number];

const nonGoogleFonts: FontFamily[] = [
  'Arial',
  'Georgia',
  'Impact',
  'Lucida Grande',
  'Palatino',
  'Trebuchet MS',
  'Verdana',
  'Odin Rounded',
  'OpenDyslexic',
  'OpenDyslexicMono',
];

export const FontLoader = () => {
  const googleFonts = fontFamilies.filter((fontFamily) => !nonGoogleFonts.includes(fontFamily));

  return (
    <>
      {googleFonts.map((fontFamily) => (
        <link
          href={`https://fonts.googleapis.com/css2?family=${fontFamily}:wght@400;700`}
          rel="stylesheet"
          key={fontFamily}
        />
      ))}
    </>
  );
};
