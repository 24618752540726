import {
  getBoxForGroupAction,
  GrippyPosition,
  isPinComponentLocked,
  OnMouseDownHandler,
  PinComponent,
  usePinEditorContextMenu,
} from '@lessonup/pin-renderer';
import { isLeftMBClick, styled } from '@lessonup/ui-components';
import React, { useState } from 'react';
import { HorizontalBorder, VerticalBorder } from '../Borders/Borders';
import { Grippy } from '../Grippy/Grippy';

export interface ActiveComponentBoxProps {
  active: PinComponent[];
  scale: number;
  onMouseDown: OnMouseDownHandler;
  isMoveActionInProgress: boolean;
}

export const MultiSelectPinComponentBox: React.FC<ActiveComponentBoxProps> = ({
  active,
  scale,
  onMouseDown,
  isMoveActionInProgress,
}) => {
  const { handleOpenContextMenu } = usePinEditorContextMenu();
  const [isBorderHovered, setIsBorderHovered] = useState(false);
  const box = getBoxForGroupAction(active);

  const handleGrippyResize = (event: React.MouseEvent<HTMLDivElement>, grippy: GrippyPosition) => {
    event.stopPropagation();
    if (isLeftMBClick(event)) onMouseDown(event, { action: 'resize-group', grippy, groupBox: box });
  };

  const isAnyActiveComponentLocked = active.some((pinComponent) => isPinComponentLocked(pinComponent));

  return (
    <ActiveBoxOuter
      style={{
        width: box.size.width * scale,
        height: box.size.height * scale,
        transform: `translate(${box.position.x * scale}px, ${box.position.y * scale}px)`,
        ...(isMoveActionInProgress ? { cursor: 'move' } : {}),
      }}
      onMouseEnter={() => {
        setIsBorderHovered(true);
      }}
      onMouseLeave={() => {
        setIsBorderHovered(false);
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
        isLeftMBClick(event) && onMouseDown(event, { action: 'move-group', groupBox: box });
      }}
      onContextMenu={(event) => {
        event.stopPropagation();
        handleOpenContextMenu?.(
          event,
          'pin-component',
          active.map((c) => c.id)
        );
      }}
    >
      {!isAnyActiveComponentLocked && (
        <>
          <HorizontalBorder side="top" data-y mode={isBorderHovered ? 'hover' : null} />
          <HorizontalBorder side="bottom" mode={isBorderHovered ? 'hover' : null} />
          <VerticalBorder side="left" mode={isBorderHovered ? 'hover' : null} />
          <VerticalBorder side="right" mode={isBorderHovered ? 'hover' : null} />
          <Grippy
            type="top-left"
            style={{ cursor: 'nwse-resize' }}
            onMouseDown={(event) => handleGrippyResize(event, 'top-left')}
          />
          <Grippy
            type="top-right"
            style={{ cursor: 'nesw-resize' }}
            onMouseDown={(event) => handleGrippyResize(event, 'top-right')}
          />
          <Grippy
            type="bottom-right"
            style={{ cursor: 'nwse-resize' }}
            onMouseDown={(event) => handleGrippyResize(event, 'bottom-right')}
          />
          <Grippy
            type="bottom-left"
            style={{ cursor: 'nesw-resize' }}
            onMouseDown={(event) => handleGrippyResize(event, 'bottom-left')}
          />
        </>
      )}
    </ActiveBoxOuter>
  );
};

const ActiveBoxOuter = styled.div`
  pointer-events: all;
  position: absolute;
  outline: 1px solid blue;
`;
