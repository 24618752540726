import {
  getImageSizeFromSource,
  ImagePinComponent,
  newImagePinComponent,
  newSize,
  newVector,
  Pin,
  scaleSizeToTargetWidth,
  Size,
  UpdatePinAction,
  UpdatePinDispatch,
} from '@lessonup/pin-renderer';
import { last } from 'lodash';
import { calculateInitialPosition } from '../pinComponent/position';
import { calculateImageSizeWithRatio, keepImageBetweenBounds } from './imagePinComponentSettings.utils';

export const defaultImagePinComponentWidth = 175;

export async function computeImageSizeFromUrl(
  imageUrl: string,
  targetWidth: number = defaultImagePinComponentWidth
): Promise<Size> {
  const originalImageSize = await getImageSizeFromSource(imageUrl);
  return convertToTagetSize(originalImageSize, targetWidth);
}

export async function computeImageSizeFromFile(
  file: File,
  targetWidth: number = defaultImagePinComponentWidth
): Promise<Size> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        resolve(convertToTagetSize({ width: img.width, height: img.height }, targetWidth));
      };
      img.onerror = reject;
      img.src = typeof event.target?.result === 'string' ? event.target?.result : '';
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getImageFileNameFromUrl(imageUrl: string): string {
  return last(imageUrl.split('/')) || '';
}

function convertToTagetSize(originalSize: Size, targetWidth: number): Size {
  const newSizes = scaleSizeToTargetWidth(originalSize, targetWidth);
  return newSize(newSizes.width, newSizes.height);
}

export async function resetImageProportions(pinComponent: ImagePinComponent, dispatch: UpdatePinDispatch) {
  if (pinComponent.layout.type !== 'BOX') return;
  const originalSize = await getImageSizeFromSource(pinComponent.settings.url);
  const ratio = originalSize.width / originalSize.height;
  const updatedSize = calculateImageSizeWithRatio(pinComponent.layout.size, ratio);
  const updatedPosition = keepImageBetweenBounds(pinComponent.layout, updatedSize);
  dispatch([
    {
      type: 'updateComponentLayout',
      updates: {
        [pinComponent.id]: {
          ...pinComponent.layout,
          size: updatedSize,
          position: updatedPosition,
        },
      },
    },
    {
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: { cropArea: undefined },
    },
  ]);
}

export function createHandleZoomableInInteractiveViewerChange(pinComponentId: string, dispatch: UpdatePinDispatch) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId,
      settings: { zoomableInInteractiveViewer: event.target.checked },
    });
  };
}

export function createImageComponent(pin: Pin, url: string, size: Size) {
  return newImagePinComponent({
    position: calculateInitialPosition(newVector(0, 0), pin?.pinComponents),
    url,
    size,
  });
}

export function createHandleUseAsBackgroundImage(
  pin: Pin,
  pinComponent: ImagePinComponent,
  selectedPinComponentIds: string[],
  dispatch: UpdatePinDispatch,
  setModeSelecting: React.Dispatch<React.SetStateAction<string[]>>
) {
  return async () => {
    const updatePinBackgroundAction: UpdatePinAction = {
      type: 'updatePinSettings',
      settings: { backgroundImage: pinComponent.settings.url },
    };
    const deletePinComponentAction: UpdatePinAction = {
      type: 'deletePinComponents',
      pinComponentIds: selectedPinComponentIds,
    };

    if (pin.settings.backgroundImage) {
      const url = pin.settings.backgroundImage;
      const newComponentInstance = createImageComponent(pin, url, await computeImageSizeFromUrl(url));

      return dispatch([
        {
          type: 'addPinComponents',
          pinComponents: [newComponentInstance],
          onComplete: () => setModeSelecting([newComponentInstance.id]),
        },
        updatePinBackgroundAction,
        deletePinComponentAction,
      ]);
    }

    dispatch([updatePinBackgroundAction, deletePinComponentAction]);
  };
}
