import { useRouter } from '@lessonup/client-integration';
import {
  BodyText,
  ManagedModal,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  rem,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LicenseStatus } from '../../types/graphql';
import { mySubscriptionRoute } from '../../utils/bridge/teacherRoutes';
import { TransWithProIcon } from '../../utils/translations/components/TransWithSvgIcons';
import { UpsellFeature } from './UpsellFeature';

type UpsellFeatureWithDefault = UpsellFeature | 'default';

type ModalOptions<T extends UpsellFeatureWithDefault> = T extends 'teachingCredits'
  ? { currentLicense: LicenseStatus }
  : undefined;

export interface FeatureUpsellModalProps {
  feature: UpsellFeature;
  options?: ModalOptions<UpsellFeature>;
}

interface FeatureUpsellModalResponse {
  redirected: boolean;
}

const TRANSLATION_NS = 'featureUpsellModal';

export const FeatureUpsellModal: React.FC<FeatureUpsellModalProps & NiceModalHocProps> = NiceModal.create(
  ({ feature, options }: FeatureUpsellModalProps) => {
    const modal = NiceModal.useModal();

    const { t } = useTranslation(TRANSLATION_NS);

    function closeModal({ redirected }: FeatureUpsellModalResponse) {
      modal.resolve({ redirected });
      modal.hide();
    }
    return (
      <ManagedModal modal={modal} contentLabel={t(`modalLabel`)}>
        <FeatureUpsellModalInner feature={feature} hideModal={closeModal} options={options} />
      </ManagedModal>
    );
  }
);

interface InnerProps extends FeatureUpsellModalProps {
  hideModal: (response: FeatureUpsellModalResponse) => void;
}
export const FeatureUpsellModalInner: React.FC<InnerProps> = ({ feature, hideModal, options }) => {
  const { t, i18n } = useTranslation(TRANSLATION_NS);
  const router = useRouter();

  const onClickViewSubscriptions = () => {
    router.go(mySubscriptionRoute.href({}));
    hideModal({ redirected: true });
  };

  const titleTrans = map[feature]?.title as TransFunction<UpsellFeature> | undefined;
  const title = titleTrans
    ? titleTrans(t, feature, options)
    : i18n.exists(`title.${feature}`, { ns: TRANSLATION_NS })
      ? transComponent('title')(t, feature, options)
      : transComponentWithPro('title')(t, 'default', options);

  return (
    <>
      <ModalHeaderV1 type="headline" title={{ content: title }} showDivider={false} />
      <FeatureUpsellModalContent>
        <SpaceBetween direction="y" spacing={spacing.size8}>
          <FeatureDescription feature={feature} options={options} />
        </SpaceBetween>
      </FeatureUpsellModalContent>
      <ModalFooter
        type="actions"
        primaryAction={{
          onClick: onClickViewSubscriptions,
          label: t('button.viewSubscriptions'),
        }}
        secondaryAction={{
          onClick: () => hideModal({ redirected: false }),
          label: t('button.cancel'),
        }}
      ></ModalFooter>
    </>
  );
};

type TransFunction<T extends UpsellFeatureWithDefault> = (
  t: TFunction,
  feature: T,
  options: ModalOptions<T>
) => React.JSX.Element;

type TransFunctionGenerator<T extends UpsellFeatureWithDefault> = (
  prefix: 'title' | 'description' | 'description2'
) => TransFunction<T>;

interface FeatureUpsellModalDefinition<T extends UpsellFeatureWithDefault> {
  description?: TransFunction<T>;
  title?: TransFunction<T>;
  description2?: TransFunction<T>;
}

const transComponent: TransFunctionGenerator<UpsellFeatureWithDefault> = (prefix) =>
  function transComponent(t, feature) {
    return <Trans i18nKey={`${prefix}.${feature}`} t={t}></Trans>;
  };

const transComponentWithPro: TransFunctionGenerator<UpsellFeatureWithDefault> = (prefix) =>
  function transComponentWithPro(t, feature) {
    return <TransWithProIcon i18nKey={`${prefix}.${feature}`} t={t} />;
  };

const map: { [key in UpsellFeature]?: FeatureUpsellModalDefinition<key> } = {
  teachingCredits: {
    description: (t, feature, options) => {
      const isStart = options?.currentLicense === 'START';
      return <Trans i18nKey={`description.teachingCredits${isStart ? 'Start' : 'Free'}`} t={t}></Trans>;
    },
    description2: transComponentWithPro('description2'),
  },
  unspecifiedFeature: {
    description: transComponentWithPro('description'),
  },
};

const FeatureUpsellModalContent = styled(BodyText)`
  padding: 0 ${spacing.size24};
  max-width: ${rem('480px')};
`;

interface FeatureDescriptionProps {
  feature: UpsellFeature;
  options: ModalOptions<UpsellFeature> | undefined;
}

const FeatureDescription = ({ feature, options }: FeatureDescriptionProps): React.JSX.Element => {
  const { t, i18n } = useTranslation(TRANSLATION_NS);

  const definition = map[feature] as FeatureUpsellModalDefinition<UpsellFeature> | undefined;

  const description = definition?.description
    ? definition.description(t, feature, options)
    : transComponent('description')(t, feature, options);

  const learnMoreLink = i18n.exists(`readMoreLink.${feature}`, { ns: TRANSLATION_NS })
    ? t(`readMoreLink.${feature}`)
    : undefined;

  // TODO: urls should not be in the translation file
  const learnMore = learnMoreLink ? (
    <a href={learnMoreLink} target="_blank" rel="noreferrer">
      {t(`learnMore`)}
    </a>
  ) : null;
  // TODO: image urls should not be in the translation file
  const imageRef = i18n.exists(`image.${feature}`, { ns: TRANSLATION_NS }) ? t(`image.${feature}`) : undefined;
  const image = imageRef ? <img alt="" src={imageRef} width="100%" /> : '';

  const description2Trans = definition?.description2;

  const description2 = description2Trans
    ? description2Trans(t, feature, options)
    : i18n.exists(`description2.${feature}`, { ns: TRANSLATION_NS })
      ? transComponent('description2')(t, feature, options)
      : transComponentWithPro('description2')(t, 'default', undefined);

  return (
    <>
      {description}
      {image}
      <span>
        {description2} {learnMore}
      </span>
    </>
  );
};

export const useFeatureUpsellModal = () => {
  const show = useCallback((feature: UpsellFeature) => NiceModal.show(FeatureUpsellModal, { feature }), []);
  return show;
};

export function showFeatureUpsellModal<T extends UpsellFeature>(feature: T, options?: ModalOptions<T>) {
  return NiceModal.show(FeatureUpsellModal, { feature, options });
}
