import {
  deleteQuizAnswer,
  isCorrectQuizAnswer,
  QuizTeacherPin,
  toggleCorrectAnswer,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import { MINIMAL_QUIZ_ANSWERS } from '@lessonup/pins-shared';
import { BodyText, Button, IconRemove, spacing, styled, Switch } from '@lessonup/ui-components';
import React from 'react';
import { useEditorContext } from '../../../../../context/EditorContext/EditorContext';
import { useSubSelectionValue } from '../../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../../hooks/useEditorTranslation';
import { PinSettingsProps } from '../types';

export const QuizPinSubSettings: React.FC<PinSettingsProps<QuizTeacherPin>> = ({ pin }) => {
  const dispatch = useUpdatePin<'MULTIPLE_CHOICE'>();
  const subSelectionValue = useSubSelectionValue() ?? '';
  const isCorrect = isCorrectQuizAnswer(pin.teacherSettings, subSelectionValue);
  const { resetSelection } = useEditorContext();
  const { t } = useEditorTranslation();

  const canDeleteAnswers = pin.settings.answers.length > MINIMAL_QUIZ_ANSWERS;

  const handleDeleteQuizAnswer = () => {
    deleteQuizAnswer(pin, subSelectionValue, dispatch);
    resetSelection();
  };

  return (
    <>
      <StyledCorrectAnswer>
        <BodyText>{t(`contextSidebar.quizAnswer.correctAnswer`)}</BodyText>
        <Switch
          id={subSelectionValue.toString()}
          ariaLabel={`Toggle correct answer ${subSelectionValue}`}
          defaultChecked={isCorrect}
          checked={isCorrect}
          onChange={() => {
            toggleCorrectAnswer(pin, subSelectionValue, dispatch);
          }}
        ></Switch>
      </StyledCorrectAnswer>
      {canDeleteAnswers && (
        <StyledButton buttonType="negative" onClick={handleDeleteQuizAnswer} iconStart={<IconRemove />}>
          {t('contextSidebar.quizAnswer.deleteAnswer')}
        </StyledButton>
      )}
    </>
  );
};

const StyledCorrectAnswer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.size8};
`;
