import { newSize, Size } from '@lessonup/pins-shared';

export function scaleSize(startSize: Size, scalar: number): Size {
  return newSize(startSize.width * scalar, startSize.height * scalar);
}

export function getAspectRatioFromSize(size: Size): number {
  return size.width / size.height;
}
/**
 * Enforces a minimum size on a new size object, optionally maintaining the aspect ratio of the original size.
 */
export function enforceMinimumSize(originalSize: Size, newSize: Size, minSize: Size, keepAspectRatio: boolean) {
  if (keepAspectRatio) {
    const aspectRatio = getAspectRatioFromSize(originalSize);
    if (originalSize.width > originalSize.height) {
      newSize.width = minSize.width;
      newSize.height = minSize.width / aspectRatio;
    } else {
      newSize.width = minSize.height * aspectRatio;
      newSize.height = minSize.height;
    }

    if (newSize.width < minSize.width) {
      newSize.width = minSize.width;
      newSize.height = newSize.width / aspectRatio;
    }
    if (newSize.height < minSize.height) {
      newSize.height = minSize.height;
      newSize.width = newSize.height * aspectRatio;
    }
  } else {
    newSize.width = Math.max(newSize.width, minSize.width);
    newSize.height = Math.max(newSize.height, minSize.height);
  }

  return newSize;
}

/**
 * Adjusts the size of an object based on the specified key (width, height, or both), value, and whether to keep the aspect ratio.
 * Optionally enforces a minimum size on the new size object.
 */
export function adjustSizeByValue(
  size: Size,
  key: 'width' | 'height' | 'both',
  value: number,
  keepAspectRatio: boolean,
  minSize?: Size
): Size {
  // This is here to prevent division by zero
  if (value <= 0) {
    return size;
  }
  const aspectRatio = getAspectRatioFromSize(size);
  let newSize: Size;

  switch (key) {
    case 'width': {
      if (keepAspectRatio) {
        newSize = { width: value, height: value / aspectRatio };
      } else {
        newSize = { width: value, height: size.height };
      }
      break;
    }
    case 'height': {
      if (keepAspectRatio) {
        newSize = { width: value * aspectRatio, height: value };
      } else {
        newSize = { width: size.width, height: value };
      }
      break;
    }
    case 'both': {
      newSize = { width: value, height: value };
      break;
    }
  }

  if (minSize && (newSize.width < minSize.width || newSize.height < minSize.height)) {
    return enforceMinimumSize(size, newSize, minSize, keepAspectRatio);
  }

  return newSize;
}

export function scaleSizeToTargetWidth(originalDimensions: Size, targetWidth: number): Size {
  return {
    width: targetWidth,
    height: targetWidth * (originalDimensions.height / originalDimensions.width),
  };
}

export function scaleSizeToTargetHeight(originalDimensions: Size, targetHeight: number): Size {
  return {
    width: targetHeight * (originalDimensions.width / originalDimensions.height),
    height: targetHeight,
  };
}
