import { Lesson } from '@lessonup/teaching-core';
import { ActionsWithOrigin, TrackerParams, WithOptional } from '@lessonup/utils';
import { TrackActionConfig } from './ActionTracker';
import { trackAction } from './trackActionClient';

export type TrackParamsWithOptionalOriginParams<K extends ActionsWithOrigin> = WithOptional<
  TrackerParams<K>,
  'originChannel' | 'originId'
>;

export function trackLessonActionWithOrigin<K extends ActionsWithOrigin>(
  action: K,
  params: TrackParamsWithOptionalOriginParams<K> & TrackActionConfig,
  lesson?: Lesson
): void {
  const originInfo = Lesson.originInfo(lesson);
  trackAction(action, { ...params, ...originInfo } as TrackerParams<K> & TrackActionConfig);
}
