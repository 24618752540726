import React from 'react';
import { OpenQuestionPinComponent, OpenQuestionPinProps } from '../../../../pins/OpenQuestionPin/OpenQuestionPin';

export const OpenQuestionPinStatic: React.FC<OpenQuestionPinProps> = (props) => {
  return (
    <OpenQuestionPinComponent pinSettings={props.pinSettings} isThumb={props.isThumb}>
      {props.children}
    </OpenQuestionPinComponent>
  );
};
