import { ImagePinComponent, TextPinComponent, useUpdatePin } from '@lessonup/pin-renderer';
import {
  color,
  IconCorner1,
  IconCorner2,
  IconCorner3,
  IconCorner4,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { useEditorTranslation } from '../../../../../hooks/useEditorTranslation';
import { createHandleBorderRadiusChange } from '../../../../../utils/pinComponentSettings/sharedPinComponentSettings';
import { SettingsToggle } from '../../../../SettingsToggle/SettingsToggle';
import { EditorInputFormField } from '../../EditorInputFields/EditorInputFormField';

const cornerValue = {
  none: 0,
  small: 8,
  medium: 16,
  large: 32,
};

interface BorderRadiusSettingProps {
  pinComponent: TextPinComponent | ImagePinComponent;
}

export const BorderRadiusSetting: React.FC<BorderRadiusSettingProps> = ({ pinComponent }) => {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const handleBorderRadiusChange = createHandleBorderRadiusChange(pinComponent.id, dispatch);
  const currentValue = pinComponent.settings.borderRadius ?? 0;

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center" justifyContent="space-around">
        <SettingsToggle
          type="checkbox"
          toggleVariant="caption"
          caption={t('contextSidebar.settings.size.none')}
          value={cornerValue.none}
          onChange={() => handleBorderRadiusChange(cornerValue.none)}
          checked={currentValue === cornerValue.none}
          icon={<IconCorner1 />}
        />
        <SettingsToggle
          type="checkbox"
          toggleVariant="caption"
          caption={t('contextSidebar.settings.size.small')}
          value={cornerValue.small}
          onChange={() => handleBorderRadiusChange(cornerValue.small)}
          checked={currentValue === cornerValue.small}
          icon={<IconCorner2 />}
        />
        <SettingsToggle
          type="checkbox"
          toggleVariant="caption"
          caption={t('contextSidebar.settings.size.medium')}
          value={cornerValue.medium}
          onChange={() => handleBorderRadiusChange(cornerValue.medium)}
          checked={currentValue === cornerValue.medium}
          icon={<IconCorner3 />}
        />
        <SettingsToggle
          type="checkbox"
          toggleVariant="caption"
          caption={t('contextSidebar.settings.size.large')}
          value={cornerValue.large}
          onChange={() => handleBorderRadiusChange(cornerValue.large)}
          checked={currentValue === cornerValue.large}
          icon={<IconCorner4 />}
        />
      </SpaceBetween>
      <StyledFormWrapper>
        <EditorInputFormField
          id={pinComponent.id}
          type="number"
          orientation="horizontal"
          horizontalLabelSpacing={spacing.size4}
          horizontalLabelAutoWidth={true}
          label={t('contextSidebar.settings.custom')}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleBorderRadiusChange(Number(e.currentTarget.value));
          }}
          value={currentValue}
          min={0}
          max={999}
        />
        {<StyledCustomUnit>{'px'}</StyledCustomUnit>}
      </StyledFormWrapper>
    </SpaceBetween>
  );
};

const StyledCustomUnit = styled.span`
  color: ${color.neutral.surface.text};
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.size4};
`;
