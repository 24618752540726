export const font = {
  heading: {
    name: 'Plus Jakarta Sans',
    cssValue: '"Plus Jakarta Sans", sans-serif',
  },
  body: {
    name: 'DM Sans',
    cssValue: '"DM Sans", ui-sans-serif, system-ui',
  },
  mono: {
    name: 'DM Mono',
    cssValue: '"DM Mono", ui-monospace, monospace',
  },
};

export const googleFontsUrl =
  'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Plus+Jakarta+Sans:wght@800&family=DM+Mono:wght@400&display=swap';

export const conditionalFonts = [
  {
    name: 'impact', // Impact is a standard font. However, it's not available on Android devices. Therefore we add Anton as a fallback.
    families: ['Anton: 400,700'],
    cssValue: 'Impact, Anton, sans-serif',
    google: true,
  },
  {
    name: 'pangolin',
    families: ['Pangolin'],
    cssValue: '"Pangolin", cursive',
    google: true,
  },
  {
    name: 'andada',
    families: ['Andada'],
    cssValue: '"Andada", serif',
    google: true,
  },
  {
    name: 'dosis',
    families: ['Dosis:400,700'],
    cssValue: '"Dosis", "Lucida Console", sans-serif',
    google: true,
  },
  {
    name: 'roboto',
    families: ['Roboto:400,700'],
    cssValue: '"Roboto", sans-serif',
    google: true,
  },
  {
    name: 'varelaround',
    families: ['Varela Round'],
    cssValue: '"Varela Round", Arial, sans-serif',
    google: true,
  },
  {
    name: 'kreon',
    families: ['Kreon'],
    cssValue: '"Kreon", Arial, sans-serif',
    google: true,
  },
];

export function editorExtraGoogleFontsHead() {
  return `<link href="https://fonts.googleapis.com/css?family=${conditionalFonts
    .map((c) => c.families)
    .join('|')}&display=fallback" rel="stylesheet" />`;
}
