import { color, IconBorder1, IconBorder2, IconBorder3, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useEditorTranslation } from '../../../../../hooks/useEditorTranslation';
import { SettingsToggle } from '../../../../SettingsToggle/SettingsToggle';
import { EditorInputFormField } from '../../EditorInputFields/EditorInputFormField';

type ThicknessKeys = 'none' | 'small' | 'medium' | 'large';

type CommonThicknessSettingProps = {
  pinComponentId: string;
  onChangeThickness: (thickness: number) => void;
  thicknessValues: Record<ThicknessKeys, number>;
  tranlationKey: string;
  currentValue: number;
  showNone?: boolean;
};

export const CommonThicknessSetting: React.FC<CommonThicknessSettingProps> = ({
  pinComponentId,
  onChangeThickness,
  thicknessValues,
  tranlationKey,
  currentValue,
  showNone = true,
}) => {
  const { t } = useEditorTranslation();

  const sanitizeCustomThickness = (value: number) => {
    const minValue = showNone ? 0 : 1;
    if (value < minValue) {
      return;
    }
    onChangeThickness(value);
  };

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center" justifyContent="space-around">
        {showNone && (
          <SettingsToggle
            value={thicknessValues.none}
            type="checkbox"
            onChange={() => onChangeThickness(thicknessValues.none)}
            caption={t(`${tranlationKey}.size.none`)}
            toggleVariant="caption"
            checked={currentValue === thicknessValues.none}
          />
        )}
        <SettingsToggle
          value={thicknessValues.small}
          type="checkbox"
          onChange={() => onChangeThickness(thicknessValues.small)}
          caption={t(`${tranlationKey}.size.small`)}
          toggleVariant="caption"
          checked={currentValue === thicknessValues.small}
          icon={<IconBorder1 />}
        />
        <SettingsToggle
          value={thicknessValues.medium}
          type="checkbox"
          onChange={() => onChangeThickness(thicknessValues.medium)}
          caption={t(`${tranlationKey}.size.medium`)}
          toggleVariant="caption"
          checked={currentValue === thicknessValues.medium}
          icon={<IconBorder2 />}
        />
        <SettingsToggle
          value={thicknessValues.large}
          icon={<IconBorder3 />}
          caption={t(`${tranlationKey}.size.large`)}
          onChange={() => onChangeThickness(thicknessValues.large)}
          type="checkbox"
          toggleVariant="caption"
          checked={currentValue === thicknessValues.large}
        />
      </SpaceBetween>
      <StyledFormWrapper>
        <EditorInputFormField
          id={pinComponentId}
          type="number"
          orientation="horizontal"
          horizontalLabelSpacing={spacing.size4}
          horizontalLabelAutoWidth={true}
          label={t(`${tranlationKey}.custom`)}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            sanitizeCustomThickness(Number(e.currentTarget.value));
          }}
          value={currentValue}
          min={showNone ? 0 : 1}
          max={999}
        />
        {<StyledCustomUnit>{'px'}</StyledCustomUnit>}
      </StyledFormWrapper>
    </SpaceBetween>
  );
};

const StyledCustomUnit = styled.span`
  color: ${color.neutral.surface.text};
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.size4};
`;
