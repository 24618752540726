import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8.25 12.938 2.727 2.812c1.284-3.687 2.352-5.304 4.773-7.5M4.269 16.184a4.01 4.01 0 0 0 3.523 3.532C9.162 19.87 10.567 20 12 20s2.839-.131 4.208-.284a4.01 4.01 0 0 0 3.523-3.532c.146-1.362.269-2.76.269-4.184s-.123-2.822-.269-4.184a4.01 4.01 0 0 0-3.523-3.532C14.838 4.13 13.433 4 12 4s-2.839.131-4.208.284A4.01 4.01 0 0 0 4.27 7.816C4.123 9.178 4 10.576 4 12s.123 2.822.269 4.184"
    />
  </svg>
);
export default SvgIconCheckboxChecked;
