import { qs } from '../queryString';

interface UtmParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content?: string;
  utm_term?: string;
}

export function urlWithUtm(url: string, params?: UtmParams): string {
  const [urlPath, queryParams, anchor] = url.split(/\?|#/);
  const parsedQueryParams = qs.parse(queryParams);

  const queryParamsWithUtm = qs.stringify({ ...parsedQueryParams, ...params }, { addQueryPrefix: true });

  return urlPath + queryParamsWithUtm + (anchor ? '#' + anchor : '');
}
