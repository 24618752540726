import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type * as CSS from 'csstype';
import React, { PropsWithChildren } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { ListHeader } from '../ListHeader/ListHeader';
import { ListItem, ListItemProps } from './ListItem/ListItem';

export interface ListProps {
  items: ListItemProps[];
  orderedList?: boolean;
  listStyle?: CSS.Property.ListStyleType;
  gap?: spacing;
  border?: boolean;
  limitToParentWidth?: boolean;
}

export function List({
  items,
  orderedList = false,
  listStyle,
  gap = spacing.size0,
  border = false,
  limitToParentWidth = false,
}: PropsWithChildren<ListProps>) {
  const mappedListItems = (
    <>
      {items.map((itemProps) => {
        if (itemProps.header) {
          return (
            <ListHeader {...itemProps} key={itemProps.key || `header-${itemProps.text}`} priority={itemProps.header} />
          );
        }

        return (
          <ListItem
            {...itemProps}
            orderedList={orderedList}
            key={itemProps.key || `definition-${itemProps.text}`}
            limitToParentWidth={limitToParentWidth}
          />
        );
      })}
    </>
  );

  if (orderedList) {
    return (
      <StyledOrderedList
        listStyle={listStyle}
        direction="y"
        as="ol"
        spacing={gap}
        border={border}
        limitToParentWidth={limitToParentWidth}
      >
        {mappedListItems}
      </StyledOrderedList>
    );
  } else {
    return (
      <StyledUnorderedList
        listStyle={listStyle}
        direction="y"
        as="ul"
        spacing={gap}
        border={border}
        limitToParentWidth={limitToParentWidth}
      >
        {mappedListItems}
      </StyledUnorderedList>
    );
  }
}

type StyledListProps = Pick<ListProps, 'listStyle' | 'border' | 'limitToParentWidth'>;

const sharedListStyle = ({ listStyle, border, limitToParentWidth }: StyledListProps) => css`
  padding-left: 0;
  margin: 0;
  text-align: left;
  ${listStyle &&
  css`
    list-style: ${listStyle};
  `}
  ${border &&
  css`
    border-radius: ${borderRadius.rounded8};
    border: 1px solid ${color.neutral.outline.background};
  `}

  ${limitToParentWidth &&
  css`
    max-width: 100%;
    overflow: hidden;
  `}
`;

const StyledUnorderedList = styled(SpaceBetween)<StyledListProps>`
  ${sharedListStyle}
`;

const StyledOrderedList = styled(SpaceBetween)<StyledListProps>`
  ${sharedListStyle}
`;
