import { createModal, ManagedModal, NiceModal, rem, useModal } from '@lessonup/ui-components';
import React, { useCallback } from 'react';
import { NoticeModalContent, NoticeModalContentProps } from '../NoticeModalContent/NoticeModalContent';

export type NoticeModalProps = Omit<NoticeModalContentProps, 'onClose'> & { onDismiss: () => void };

export const noticeModalWidth = 480;

export const NoticeModal = createModal(({ onDismiss, ...props }: NoticeModalProps) => {
  const modal = useModal();

  const onClose = useCallback(() => {
    onDismiss();
    modal.hide();
  }, [modal, onDismiss]);

  return (
    <ManagedModal
      modal={modal}
      contentLabel={props.title}
      width={rem(`${noticeModalWidth}px`)}
      onBeforeClose={onDismiss}
    >
      <NoticeModalContent onClose={onClose} {...props} />
    </ManagedModal>
  );
});

export const showNoticeModal = (props: NoticeModalProps) => NiceModal.show(NoticeModal, props);
