import { Introduction, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nextNamespace } from '../../UploadsFeature.utils';

type IntroductionProps = {
  translationKeyPrefix: string;
  icon: React.ReactElement;
  action?: React.ReactElement;
  secondary?: React.ReactElement;
};

export const IntroductionMessage = (props: IntroductionProps) => {
  const { t } = useTranslation(i18nextNamespace);
  const { translationKeyPrefix, icon, action, secondary } = props;
  return (
    <StyledIntroduction
      alignment="center"
      headline={t(`${translationKeyPrefix}.header`)}
      bodyText={t(`${translationKeyPrefix}.body`)}
      icon={icon}
      size="M"
      buttonPrimary={action}
      buttonSecondary={secondary}
    />
  );
};

const StyledIntroduction = styled(Introduction)`
  padding-top: ${spacing.size32};
`;
