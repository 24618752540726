import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 20.361c3.628-1.916 10.625-5.928 10.625-8.361S13.628 5.555 10 3.639c-.978-.517-2.126.2-2.126 1.306v14.11c0 1.106 1.148 1.823 2.126 1.306"
    />
  </svg>
);
export default SvgIconPlay;
