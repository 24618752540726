import { BodyText, borderRadius, color, Headline, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export interface QuestionnaireProps {
  onClick: () => void;
  headingText: string;
  bodyText: string;
  note?: string;
  icon?: React.JSX.Element;
}

export function Questionnaire({ onClick, headingText, bodyText, note, icon }: QuestionnaireProps) {
  return (
    <StyledQuestionnaire onClick={onClick}>
      <StyledInner direction="y" spacing={spacing.size8} alignItems="center" justifyContent="space-between">
        <SpaceBetween direction="y" spacing={spacing.size8}>
          {icon && <StyledIcon>{icon} </StyledIcon>}

          <Headline size="small">{headingText}</Headline>
          <StyledParagraphText>{bodyText}</StyledParagraphText>
        </SpaceBetween>
        {note && <BodyText size="small">{note}</BodyText>}
      </StyledInner>
    </StyledQuestionnaire>
  );
}

const StyledQuestionnaire = styled.button`
  background: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded16};
  color: inherit;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const StyledParagraphText = styled(BodyText)`
  flex: 1 0 0;
  align-self: stretch;
`;

const StyledInner = styled(SpaceBetween)`
  width: 100%;
  height: 100%;
  min-height: ${rem('240px')};
  padding: ${spacing.size16} ${spacing.size32};
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled.div`
  height: 24px; // Standard Icon size
`;
