import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCookie = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m11.54 17.156-.223.223m4.634-1.966-.224.224M12 21.375c6.375 0 9.375-4.197 9.375-9.375-2.33 1.085-4.725-.144-4.943-2.608 0 0-2.522.288-4.057-1.142C10.125 6 12 2.625 12 2.625c-6.375 0-9.375 4.197-9.375 9.375s3 9.375 9.375 9.375m-5.946-9.653c0 1.243.954 2.25 2.196 2.25a2.25 2.25 0 0 0 0-4.5c-1.242 0-2.196 1.008-2.196 2.25"
    />
  </svg>
);
export default SvgIconCookie;
