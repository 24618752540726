import { Box, OnMouseDownHandler, PinComponent, PinComponentId, RailLayout } from '@lessonup/pin-renderer';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { useEditorContext } from '../../../context/EditorContext/EditorContext';
import { useEditMode } from '../../../context/EditorContext/hooks/useEditorState';
import { ActivePinComponentBox } from './ActivePinComponentBox/ActivePinComponentBox';
import { VisibleRails } from './HelpLines/VisibleRails';
import { ImageCroppingLayer } from './ImageCroppingLayer/ImageCroppingLayer';
import { LinkedComponentLines } from './LinkedComponentLines/LinkedComponentLines';
import { MultiSelectPinComponentBox } from './MultiSelectPinComponentBox/MultiSelectPinComponentBox';
import { SelectionBox } from './SelectionBox/SelectionBox';

interface ControlLayerProps {
  scale: number;
  active: PinComponent[];
  pinComponents: PinComponent[];
  selectionBox: Box | null;
  visibleRails: RailLayout[];
  onMouseDown: OnMouseDownHandler;
  mutationInProgress: boolean;
  isMoveActionInProgress: boolean;
  hoveredPinComponentId: PinComponentId | null;
}

export const ControlLayer: React.FC<ControlLayerProps> = ({
  scale,
  active,
  onMouseDown,
  selectionBox,
  pinComponents,
  mutationInProgress,
  visibleRails,
  isMoveActionInProgress,
  hoveredPinComponentId,
}) => {
  const editMode = useEditMode();
  const { setModeSelecting } = useEditorContext();

  return (
    <StyledControlLayer>
      {editMode.type !== 'cropping' && (
        <>
          {selectionBox && <SelectionBox scale={scale} box={selectionBox} />}
          {active.length === 1 && (
            <ActivePinComponentBox
              active={active[0]}
              scale={scale}
              onMouseDown={onMouseDown}
              isPinComponentHovered={hoveredPinComponentId === active[0].id}
            />
          )}
          {active.length > 1 && (
            <MultiSelectPinComponentBox
              active={active}
              scale={scale}
              onMouseDown={onMouseDown}
              isMoveActionInProgress={isMoveActionInProgress}
            />
          )}
        </>
      )}
      {editMode.type === 'cropping' && (
        <ImageCroppingLayer
          pinComponent={editMode.selectedPinComponent}
          pinScale={scale}
          setModeSelecting={() => setModeSelecting && setModeSelecting(editMode.selectedPinComponentIds)}
        />
      )}

      <LinkedComponentLines
        pinComponents={pinComponents}
        scale={scale}
        active={active}
        mutationInProgress={mutationInProgress}
      />
      <VisibleRails scale={scale} visibleRails={visibleRails} />
    </StyledControlLayer>
  );
};

const StyledControlLayer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;
