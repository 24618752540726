import { useLocale } from '@lessonup/client-integration';
import { SelectFormField, SelectFormFieldProps } from '@lessonup/ui-components';
import { CountryCode } from '@lessonup/utils';
import React, { ForwardedRef, forwardRef } from 'react';
import { countryMapForCountryCode } from './countryMap';

type CountryPickerProps = Omit<SelectFormFieldProps<CountryCode>, 'options' | 'type'> & {
  label: string;
  ref: ForwardedRef<HTMLSelectElement>;
};

export const CountryPicker = forwardRef<HTMLSelectElement, CountryPickerProps>(({ label, ...otherProps }, ref) => {
  const locale = useLocale();

  const countryMap = countryMapForCountryCode(locale.language);

  const countryOptions = Object.entries(countryMap).map(([k, v]) => {
    return { value: k, label: v };
  });

  return (
    <SelectFormField
      label={label}
      defaultValue="nl"
      {...otherProps}
      options={countryOptions}
      ref={ref}
    ></SelectFormField>
  );
});

CountryPicker.displayName = 'CountryPicker';
