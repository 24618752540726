import type { ImagePinComponent as ImagePinComponentType } from '@lessonup/pins-shared';
import { Box, LayoutPartDict, Line, Pin, PinComponent } from '@lessonup/pins-shared';
import { css, isLeftMBClick, isRightMBClick, styled } from '@lessonup/ui-components';
import { assertNever } from '@lessonup/utils';
import React, { memo, useCallback } from 'react';
import { colorSets } from '../../foundations/colorSets/colorSets';
import {
  OnMouseDownHandler,
  OnMouseEnterHandler,
  OnMouseLeaveHandler,
} from '../../hooks/useHandleMouseEvents/useHandleMouseEvents.types';
import { AnswerAreaPinComponent } from '../../pinComponents/AnswerAreaPinComponent/AnswerAreaPinComponent';
import { HotspotPinComponent } from '../../pinComponents/HotspotPinComponent/HotspotPinComponent';
import { ImagePinComponent } from '../../pinComponents/ImagePinComponent/ImagePinComponent';
import { LinePinComponent } from '../../pinComponents/LinePinComponent/LinePinComponent';
import { ShapePinComponent } from '../../pinComponents/ShapePinComponent/ShapePinComponent';
import { SpinnerPinComponent } from '../../pinComponents/SpinnerPinComponent/SpinnerPinComponent';
import { TextPinComponent } from '../../pinComponents/TextPinComponent/TextPinComponent';
import { VideoPinComponent } from '../../pinComponents/VideoPinComponent/VideoPinComponent';
import { isLineLayout } from '../../types/pinComponents/pinComponentLayouts';
import { usePinEditorContextMenu } from '../../utils/contextMenu/PinEditorContextMenuContext';
import { boxFromLayout, getBoxForGroupAction } from '../../utils/geometry';
import { normalizeLayoutParts } from './PinComponentSwitch.utils';

export interface PinComponentSwitchProps {
  pinComponent: PinComponent;
  pinId: string;
  pinType: Pin['type'];
  layoutParts?: LayoutPartDict;
  editable: boolean;
  active: boolean;
  scale: number;
  activePinComponents?: PinComponent[];
  layoutKey?: Pin['settings']['layout'];
  isStatic?: boolean;
  isThumb?: boolean;
  onMouseDown?: OnMouseDownHandler;
  onMouseEnter?: OnMouseEnterHandler;
  onMouseLeave?: OnMouseLeaveHandler;
  isHidden?: boolean;
  Renderer?: React.FC<PinComponentSwitchRendererProps>;
  isTextEditMode?: boolean;
  setModeTextEdit?(textPinComponentId: string): void;
  setModeCropping?(imagePinComponent: ImagePinComponentType): void;
  isMoveActionInProgress?: boolean;
}

export const PinComponentSwitch: React.FC<PinComponentSwitchProps> = ({
  pinComponent,
  editable,
  pinId,
  pinType,
  isHidden,
  active,
  activePinComponents,
  layoutKey,
  layoutParts,
  isStatic = true,
  isThumb,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  Renderer = PinComponentSwitchDefaultRendererMemo,
  scale,
  isTextEditMode,
  setModeTextEdit,
  setModeCropping,
  isMoveActionInProgress,
}) => {
  const isPartOfGroup =
    (activePinComponents?.length ?? 0) > 1 && activePinComponents?.some((c) => c.id === pinComponent.id);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      isLeftMBClick(event) && !isPartOfGroup
        ? onMouseDown?.(event, { action: 'move', componentId: pinComponent.id })
        : isLeftMBClick(event) &&
          onMouseDown?.(event, { action: 'move-group', groupBox: getBoxForGroupAction(activePinComponents || []) });
      isRightMBClick(event) &&
        onMouseDown?.(event, { action: 'select-pin-components', componentIds: [pinComponent.id] });
    },
    [onMouseDown, pinComponent.id, activePinComponents, isPartOfGroup]
  );
  const { handleOpenContextMenu } = usePinEditorContextMenu();
  const handleOnContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      handleOpenContextMenu?.(event, 'pin-component', [pinComponent.id], [pinComponent]);
    },
    [handleOpenContextMenu, pinComponent]
  );

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onMouseEnter?.(event, { action: 'hover', componentId: pinComponent.id });
    },
    [onMouseEnter, pinComponent.id]
  );

  const handleMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      onMouseLeave?.(event, { action: 'hover', componentId: pinComponent.id });
    },
    [onMouseLeave, pinComponent.id]
  );

  const box = boxFromLayout(pinComponent.layout);

  // Some data props primarily for QA automation
  // Should not be used for styling or logic
  // Should be removed if we have TECH-1 running on test
  const dataProps = {
    [`data-testid`]: pinComponent.type.toLowerCase(),
    ['data-comp-id']: pinComponent.id,
  };

  return (
    <ComponentWrapper
      isLineComponent={isLineLayout(pinComponent.layout)}
      editable={editable}
      style={componentWrapperStyle(isStatic, box, !isHidden, isMoveActionInProgress)}
      onMouseDown={handleMouseDown}
      onContextMenu={handleOnContextMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      active={active}
      {...dataProps}
    >
      <Renderer
        editable={editable}
        pinId={pinId}
        active={active}
        isThumb={isThumb}
        pinComponent={pinComponent}
        layoutKey={layoutKey}
        layoutParts={normalizeLayoutParts(layoutParts)}
        scale={scale}
        isTextEditMode={isTextEditMode}
        setModeTextEdit={setModeTextEdit}
        setModeCropping={setModeCropping}
        pinType={pinType}
      />
    </ComponentWrapper>
  );
};

export type PinComponentSwitchRendererProps = {
  pinComponent: PinComponent;
  editable: boolean;
  pinId: string;
  pinType: Pin['type'];
  active: boolean;
  scale: number;
  layoutKey?: Pin['settings']['layout'];
  layoutParts?: LayoutPartDict;
  isThumb?: boolean;
  isTextEditMode?: boolean;
  setModeTextEdit?(textPinComponentId: string): void;
  setModeCropping?(pinComponent: ImagePinComponentType): void;
};

export const PinComponentSwitchDefaultRenderer: React.FC<PinComponentSwitchRendererProps> = (props) => {
  const { pinComponent, editable, pinId, active, isThumb, scale, setModeCropping, layoutKey } = props;

  const box = boxFromLayout(pinComponent.layout);

  const type = pinComponent.type;
  switch (type) {
    case 'SHAPE':
    case 'UNEDITABLE_SHAPE':
      return <ShapePinComponent settings={pinComponent.settings} width={box.size.width} height={box.size.height} />;
    case 'HOTSPOT':
      return <HotspotPinComponent pinComponent={pinComponent} active={active} pinId={pinId} />;
    case 'IMAGE':
      return (
        <ImagePinComponent
          settings={pinComponent.settings}
          layout={box}
          isThumb={isThumb}
          onDoubleClick={() => setModeCropping && setModeCropping(pinComponent)}
        />
      );
    case 'LINE':
      return (
        <LinePinComponent
          componentId={pinComponent.id}
          settings={pinComponent.settings}
          layout={pinComponent.layout as Line}
        />
      );
    case 'TEXT':
      return (
        <TextPinComponent
          componentId={pinComponent.id}
          settings={pinComponent.settings}
          editable={editable}
          pinId={pinId}
          active={active}
        />
      );
    case 'ANSWER_AREA':
      return (
        <AnswerAreaPinComponent
          layoutKey={layoutKey}
          settings={pinComponent.settings}
          showPlaceholderCards={editable}
          active={active}
        />
      );
    case 'VIDEO':
      return (
        <VideoPinComponent
          settings={pinComponent.settings}
          componentId={pinComponent.id}
          scale={scale}
          width={box.size.width}
          height={box.size.height}
          editable={editable}
          active={active}
          isThumb={isThumb}
        />
      );
    case 'SPINNER':
      return <SpinnerPinComponent />;
    default:
      assertNever(type, 'Unhandled type in PinComponentSwitchDefaultRenderer');
  }
};

export const PinComponentSwitchDefaultRendererMemo = memo(PinComponentSwitchDefaultRenderer);

const ComponentWrapper = styled.div<{ isLineComponent: boolean; editable: boolean; active: boolean }>`
  position: absolute;
  ${(props) =>
    !props.editable &&
    css`
      user-select: none;
    `}
  pointer-events: ${(props) => (props.isLineComponent ? 'none' : 'initial')};
  ${(props) =>
    !props.isLineComponent &&
    props.editable &&
    !props.active &&
    css`
      :hover {
        outline: 2px solid ${colorSets.disabled.strokeSubjacent};
      }
    `}

  ${(props) =>
    props.active &&
    css`
      &::before {
        content: ' ';
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        z-index: -1;
      }
    `};
`;
/**
 * The pseudo element above is to increase the interaction radius by 10px on each side of the pin component
 * 10px = current grippy size
 */

function componentWrapperStyle(isStatic: boolean, box: Box, visible: boolean, isMoving?: boolean): React.CSSProperties {
  const { position, size, rotation } = box;
  // Non-static components are positioned with transform instead of top/left to avoid layout thrashing while moving
  const transform = [];
  !isStatic && transform.push(`translate(${position.x}px, ${position.y}px)`);
  rotation !== 0 && transform.push(`rotate(${rotation}deg)`);
  const inset = isStatic ? `${position.y}px 0px 0px ${position.x}px` : undefined;

  return {
    transform: transform.join(' '),
    inset,
    width: size.width,
    height: size.height,
    visibility: visible ? 'visible' : 'hidden',
    ...(isMoving ? { cursor: 'move' } : {}),
  };
}
