import { BodyText, color, SpaceBetween, spacing, styled, useForm } from '@lessonup/ui-components';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratedResult } from '../../../../../components/GeneratedResult/GeneratedResult';
import { LearningPhase } from '../../../../../components/GeneratedResult/LearningPhase/LearningPhase';
import {
  LearningPhaseName,
  learningPhaseNameMap,
  LearningPhaseOrNoPhaseNumber,
  learningPhasesI18NNS,
} from '../../../../../utils/learningPhases/LearningPhase';
import { BlinkingCursor } from '../ContextValidationStep/BlinkingCursor';
import { generatedResultIsEmpty, GeneratedResultItem } from './reviewStepUtils';

type FormInputs = {
  questions: string[];
};

export interface ReviewStepProps {
  generatedResults: GeneratedResultItem[];
  selection: string[];
  setSelection: React.Dispatch<React.SetStateAction<string[]>>;
  optOut?: boolean;
  showLearningPhases?: boolean;
  isCompleted?: boolean;
}

export const ReviewStep = ({
  generatedResults,
  selection,
  setSelection,
  optOut,
  showLearningPhases,
  isCompleted,
}: ReviewStepProps) => {
  const { t } = useTranslation(['chatGPT', learningPhasesI18NNS]);
  const { register, watch } = useForm<FormInputs>({
    defaultValues: {
      questions: optOut ? generatedResults.map((result) => result.tempId) : [],
    },
  });

  const selectedQuestions = watch('questions');

  const learningPhasesRendered: { [key in LearningPhaseName]?: boolean } = {};

  const renderLearningPhase = (phaseNumber: LearningPhaseOrNoPhaseNumber | undefined) => {
    if (!showLearningPhases || !phaseNumber) return null;
    const phaseName = learningPhaseNameMap[phaseNumber];
    const firstOccurrence = phaseName && !learningPhasesRendered[phaseName];
    if (firstOccurrence) {
      learningPhasesRendered[phaseName] = true;
      return <LearningPhase learningPhase={t(phaseName, { ns: learningPhasesI18NNS })}></LearningPhase>;
    }
  };

  useEffect(() => {
    // Checking for setting makes it work in Storybook as well. Hooks don't work in Storybook...
    if (!isEqual(selection, selectedQuestions)) {
      setSelection(selectedQuestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestions]);

  return (
    <StyledReviewStep direction="y" spacing={spacing.size16}>
      {!optOut && (
        <div>
          <BodyText weight="bold">{t('reviewSlides.keepQuestionsYouLike')}</BodyText>
          <StyledReviewDescription size="small">
            {t('reviewSlides.explanationKeepContentYouLike')}
          </StyledReviewDescription>
        </div>
      )}

      <StyledList direction="y" spacing={spacing.size16} as="ul">
        {!generatedResults.length ? <BlinkingCursor /> : null}
        {generatedResults.map((slide, index) => {
          if (generatedResultIsEmpty(slide) && index > 0) return null;
          const isLast = index === generatedResults.length - 1;

          return (
            <React.Fragment key={slide.tempId}>
              {renderLearningPhase(slide.phaseNumber)}
              <GeneratedResult
                {...slide}
                selected={selection.includes(slide.tempId)}
                {...register('questions')}
                isCompleted={isCompleted}
                isLast={isLast}
              />
            </React.Fragment>
          );
        })}
      </StyledList>
    </StyledReviewStep>
  );
};

const StyledReviewStep = styled(SpaceBetween)`
  padding: ${spacing.size12} ${spacing.size24};
`;

const StyledList = styled(SpaceBetween)`
  padding: 0;
`;

const StyledReviewDescription = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
