import { ANSWER_MAX_HEIGHT, QuizPinAnswerIndicators, QuizPinAnswerIndicatorsProps } from '@lessonup/pin-renderer';
import { BodyText, IconPeople, ProgressBar, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { PropsWithChildren } from 'react';
import { ButtonWithPopover } from '../../../../components/ButtonWithPopover/ButtonWithPopover';
import { usePlayerTranslation } from '../../hooks/usePlayerTranslation';
import { StudentNamesList } from '../StudentNamesList';

export interface QuizAnswerCardContentProps {
  index: number;
  answerStatus: QuizPinAnswerIndicatorsProps['answerStatus'];
  showDistribution: boolean;
  studentNames?: string[];
  amountChosen?: number;
  amountMostChosen?: number;
}

export const QuizAnswerCardContent: React.FC<PropsWithChildren<QuizAnswerCardContentProps>> = ({
  index,
  answerStatus,
  showDistribution,
  amountChosen,
  amountMostChosen,
  studentNames,
  children,
}) => {
  const { t } = usePlayerTranslation();

  return (
    <StyledAnswerContent direction="y" spacing={spacing.size2} padding={spacing.size8}>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="flex-start">
        <QuizPinAnswerIndicators index={index} answerStatus={answerStatus} />
        <StyledAnswerText size="medium">{children}</StyledAnswerText>
      </SpaceBetween>
      {showDistribution && (
        <SpaceBetween direction="x" spacing={spacing.size2} alignItems="center">
          <StyledDistribution>
            <ProgressBar
              value={amountChosen || 0}
              max={amountMostChosen || 0}
              ariaLabel={t('quizPin.distributionBar')}
            />
          </StyledDistribution>
          <ButtonWithPopover
            iconStart={<IconPeople />}
            showStroke
            disabled={amountChosen === 0}
            buttonShape="rect"
            buttonType="neutral"
            size="small"
            modalConfig={{
              contentLabel: t('quizPin.studentNames'),
              modalDirection: 'top',
              modalBody: () => <StudentNamesList studentNames={studentNames || []} />,
            }}
          >
            {amountChosen || 0}
          </ButtonWithPopover>
        </SpaceBetween>
      )}
    </StyledAnswerContent>
  );
};

const StyledAnswerContent = styled(SpaceBetween)`
  height: 100%;
`;

const StyledAnswerText = styled(BodyText)`
  padding: ${spacing.size0} ${spacing.size4};
  max-height: ${ANSWER_MAX_HEIGHT};
  overflow: hidden;
  word-break: break-word;
`;

const StyledDistribution = styled.div`
  padding: 0 ${spacing.size4};
  width: 100%;
`;
