import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.042 14.55q.008.683.008 1.37m-.033-7.257A69 69 0 0 0 4 11.387m.371-5.907c.134-.5.313-.894.55-1.156s.57-.478.974-.656m2.72-.623c.742-.078 1.54-.117 2.358-.128m2.847.052q.603.026 1.18.06m4.89 15.254a86 86 0 0 0 .093-6.356 2.83 2.83 0 0 0-.49-1.54c-.891-1.303-1.63-2.16-2.764-3.132a2.74 2.74 0 0 0-1.739-.65 61 61 0 0 0-1.122-.009c-1.259 0-2.315.034-3.362.1a2.846 2.846 0 0 0-2.66 2.7 86 86 0 0 0-.114 4.444c0 1.518.04 3.003.114 4.443a2.846 2.846 0 0 0 2.66 2.7c1.047.066 2.103.1 3.362.1s2.315-.034 3.362-.1a2.846 2.846 0 0 0 2.66-2.7"
    />
  </svg>
);
export default SvgIconCopy;
