import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { HTMLAttributes } from 'react';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { fontSize } from '../../../foundations/typography/typography.utils';
import { Link } from '../../../utils/Link/Link';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';
import { ICON_SIZE } from '../constants';

export interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  icon?: React.JSX.Element;
  iconDimensions?: {
    width?: string;
    height?: string;
  };
  href?: string;
  onClick?: () => void; // can be provided if you want a button instead of a hyperlink
  maxWidth: number;
  hasHoverHighlight?: boolean;
}

const StyledBreadcrumb = styled.div<
  Pick<BreadcrumbProps, 'maxWidth' | 'iconDimensions'> & { hasHoverHighlight: boolean }
>`
  .icon-label-container {
    padding: ${spacing.size8};
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  svg {
    width: ${({ iconDimensions }) =>
      iconDimensions ? (iconDimensions.width ? iconDimensions.width : null) : rem(`${ICON_SIZE}px`)};
    height: ${({ iconDimensions }) =>
      iconDimensions ? (iconDimensions.height ? iconDimensions.height : null) : rem(`${ICON_SIZE}px`)};

    &:not(:last-child) {
      margin-right: ${spacing.size8};
    }
  }

  .label {
    display: inline-block;
    max-width: ${({ maxWidth }) => maxWidth}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${fontSize.bodyTextSizeMedium};
  }

  a {
    text-decoration: none;
  }

  a,
  button {
    &:hover {
      .icon-label-container {
        background-color: ${({ hasHoverHighlight }) => (hasHoverHighlight ? color.neutral.surface.hover : undefined)};
      }
    }
  }
`;

export function Breadcrumb(props: BreadcrumbProps): React.JSX.Element {
  const { label, icon, href, onClick, hasHoverHighlight = true } = props;
  const breadcrumb = (
    <SpaceBetween spacing={spacing.size16} direction="x" className="icon-label-container">
      {icon && icon}
      {label && (
        <BodyText title={label} size="medium" className="label">
          {label}
        </BodyText>
      )}
    </SpaceBetween>
  );

  return (
    <StyledBreadcrumb
      maxWidth={props.maxWidth}
      iconDimensions={props.iconDimensions}
      hasHoverHighlight={hasHoverHighlight}
    >
      {href && (
        <Link href={href} passHref>
          <a>{breadcrumb}</a>
        </Link>
      )}
      {!href && onClick && <button onClick={onClick}>{breadcrumb}</button>}
      {!href && !onClick && breadcrumb}
    </StyledBreadcrumb>
  );
}
