import { SlidePinSettings } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PinBackground } from '../components/PinBackground';

export interface SlidePinProps {
  pinSettings: SlidePinSettings;
  isThumb?: boolean;
  children?: React.ReactNode;
}

export const SlidePinComponent: React.FC<SlidePinProps> = ({ isThumb, pinSettings, children }) => {
  return (
    <SlideWrapper>
      <PinBackground
        isThumb={isThumb}
        backgroundColor={pinSettings.backgroundColor || '#FFFFFF'}
        backgroundImage={pinSettings.backgroundImage ?? undefined}
        backgroundTransparency={pinSettings.backgroundTransparency}
      />
      {children}
    </SlideWrapper>
  );
};

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
