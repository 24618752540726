import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { Headline } from '../Headline/Headline';

export interface LessonCodeProps {
  code: string;
}

export const LessonCode: React.FC<LessonCodeProps> = ({ code }) => {
  const codeArray = code.split('');
  return (
    <SpaceBetween direction="x" spacing={spacing.size4}>
      {codeArray.map((pin, index) => (
        <StyledCodeBlock key={index}>
          <Headline size="small">{pin}</Headline>
        </StyledCodeBlock>
      ))}
    </SpaceBetween>
  );
};

const StyledCodeBlock = styled.div`
  background: ${color.highContrast.surface.background};
  color: ${color.highContrast.surface.text};
  width: ${rem('50px')};
  height: ${rem('48px')};
  border-radius: ${borderRadius.rounded4};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
