import { borderRadius, color, styled } from '@lessonup/ui-components';
import React from 'react';

export interface FullPageBackgroundProps {
  children: React.ReactNode;
}

export const FullPageBackground: React.FC<FullPageBackgroundProps> = ({ children }) => {
  return <MainBackground>{children}</MainBackground>;
};

const MainBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background: linear-gradient(
    135deg,
    ${color.accent.tertiary.background} 0%,
    ${color.accent.tertiaryContainer.background} 100%
  );
  border-top-right-radius: ${borderRadius.rounded16};
  border-top-left-radius: ${borderRadius.rounded16};
  padding-bottom: 100px;
`;
