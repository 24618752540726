import { ApolloLink } from '@apollo/client';
import { omitDeepNullFromArrays } from '@lessonup/utils';

/**
 * This link will transform the data to a more usable format.
 * It will remove nulls from arrays
 */
export const cleanDataLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(({ data, ...args }) => {
    return {
      // we only remove null's from arrays, which are the most anoying to handle.
      // sadly we can't remove null properties from objects during this transform. Apollo requires these and will throw errors because of it.
      // Alternative would be to wrap the useMutation and useQuery functions and override all possible points which return the data, and transform it there
      // in that case we we would also have to override all the possible options like onCompleted, onQueryUpdated, etc
      // this is error prone and it might be less performanned as well, because it would be very hard to transform the data only once
      data: omitDeepNullFromArrays(data),
      ...args,
    };
  });
});
