import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6.275C7.47.717 2.688 4.665 2.625 8.923c0 6.337 7.583 11.539 9.375 11.539s9.375-5.202 9.375-11.54C21.312 4.667 16.53.718 12 6.276"
    />
  </svg>
);
export default SvgIconHeart;
