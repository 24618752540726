import { Student } from '@lessonup/players-modern';
import {
  AsyncSwitch,
  BodyText,
  Button,
  Chip,
  color,
  IconClose,
  IconGlobe,
  IconQrCode,
  IconStudent,
  LessonCode,
  PageSection,
  PortalTooltip,
  rem,
  SpaceBetween,
  spacing,
  styled,
  useAsyncLoadingState,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { QRCode } from '../../../../../components/QRCode';
import { limitStudentNameLength } from '../../../RealtimePlayer/utils/limitStudentNameLength';
import { playerScreenSize } from '../playerScreens.utils';

export interface StudentJoinScreenProps {
  id: string;
  pincode?: string;
  joinedStudents: Student[];
  allowNewPlayers: boolean;
  handleRemoveStudent: (studentId: string) => Promise<void>;
  handleAllowNewPlayers: (allowNewPlayers: boolean) => Promise<void>;
  showCloseWindowButton?: boolean;
  handleCloseWindow?: () => void;
  t: TFunction;
  studentUrl: string;
}

export const StudentJoinScreen: React.FC<StudentJoinScreenProps> = ({
  id,
  pincode,
  joinedStudents,
  allowNewPlayers,
  handleRemoveStudent,
  handleAllowNewPlayers,
  showCloseWindowButton,
  handleCloseWindow,
  t,
  studentUrl,
}) => {
  const showPinCode = allowNewPlayers && pincode;
  const showFooter = pincode;
  return (
    <StyledWraper
      direction="y"
      spacing={spacing.size24}
      style={{
        width: playerScreenSize.width,
        height: playerScreenSize.height,
        maxHeight: playerScreenSize.height,
      }}
    >
      <SpaceBetween direction="x" spacing={spacing.size24}>
        <SpaceBetween direction="y" spacing={spacing.size24} style={{ flex: 1 }}>
          {showPinCode && <LessonCodeSection code={pincode} t={t} />}
          <JoinedStudentsSectionList
            joinedStudents={joinedStudents}
            fullHeight={!showPinCode}
            t={t}
            handleRemoveStudent={handleRemoveStudent}
          />
        </SpaceBetween>
        {showPinCode && <QRCodeSection code={pincode} t={t} studentUrl={studentUrl} />}
      </SpaceBetween>
      {showFooter && (
        <FooterSection
          id={id}
          allowNewPlayers={allowNewPlayers}
          handleAllowNewPlayers={handleAllowNewPlayers}
          showCloseWindowButton={showCloseWindowButton}
          handleCloseWindow={handleCloseWindow}
          t={t}
        />
      )}
    </StyledWraper>
  );
};

interface LessonCodeSectionProps {
  code: string;
  t: TFunction;
}

const LessonCodeSection: React.FC<LessonCodeSectionProps> = ({ code, t }) => (
  <PageSection
    showBorder
    headerProps={{
      text: t('studentJoinScreen.studentAppUrl'),
      subtitle: t('studentJoinScreen.studentAppUrlsubtitle'),
      startSlot: <IconGlobe />,
    }}
  >
    <LessonCode code={code} />
  </PageSection>
);

interface JoinScreenSectionProps {
  joinedStudents: Student[];
  handleRemoveStudent: (studentId: string) => Promise<void>;
  fullHeight: boolean;
  t: TFunction;
}

const JoinedStudentsSectionList: React.FC<JoinScreenSectionProps> = ({
  joinedStudents,
  handleRemoveStudent,
  fullHeight,
  t,
}) => (
  <PageSection
    showBorder
    hideIndentation
    headerProps={{
      text: t('studentJoinScreen.studentAttendace'),
      subtitle: t('studentJoinScreen.studentAttendaceSubtitle', { count: joinedStudents.length }),
      startSlot: <IconStudent />,
    }}
  >
    <StyledStudentSectionContent
      style={{
        height: fullHeight ? rem('380px') : rem('220px'),
      }}
    >
      {joinedStudents.map((student, index) => (
        <StudentChip student={student} handleRemoveStudent={handleRemoveStudent} key={`student-${index}`} t={t} />
      ))}
    </StyledStudentSectionContent>
  </PageSection>
);

const StudentChip: React.FC<{
  student: Student;
  handleRemoveStudent: (studentId: string) => Promise<void>;
  t: TFunction;
}> = ({ student, handleRemoveStudent, t }) => {
  const { loading, asyncWrapper } = useAsyncLoadingState();

  return (
    <PortalTooltip content={t('studentJoinScreen.removeStudent')} direction="top">
      <Chip
        text={limitStudentNameLength(student.name)}
        dismissable
        onClick={() => asyncWrapper(() => handleRemoveStudent(student.userId))}
        $loading={loading}
        disabled={loading}
      />
    </PortalTooltip>
  );
};

interface QRCodeSectionProps {
  studentUrl: string;
  code: string;
  t: TFunction;
}

const QRCodeSection: React.FC<QRCodeSectionProps> = ({ t, code, studentUrl }) => {
  const joinUrl = `${studentUrl}?code=${code}&qr=true`;
  const QRSize = 400; // the size of the code doesn't match up with the svg size and there is no design for this, so this value is just chosen by eyeballing it
  return (
    <SpaceBetween direction="y" spacing={spacing.size0} style={{ flex: 1 }}>
      <PageSection
        hideIndentation
        showBorder
        headerProps={{
          text: t('studentJoinScreen.joinQRCode'),
          subtitle: t('studentJoinScreen.joinQRCodeSubtitle'),
          startSlot: <IconQrCode />,
        }}
      >
        <StyledQRWrapper>
          <QRCode value={joinUrl} size={QRSize} data-testid="qr-code" />
        </StyledQRWrapper>
      </PageSection>
    </SpaceBetween>
  );
};

interface FooterSectionProps {
  id: string;
  allowNewPlayers: boolean;
  handleAllowNewPlayers: (allowNewPlayers: boolean) => Promise<void>;
  showCloseWindowButton?: boolean;
  handleCloseWindow?: () => void;
  t: TFunction;
}

const FooterSection: React.FC<FooterSectionProps> = ({
  id,
  allowNewPlayers,
  handleAllowNewPlayers,
  showCloseWindowButton,
  handleCloseWindow,
  t,
}) => (
  <StyledFooter
    direction="x"
    spacing={spacing.size0}
    alignItems="center"
    justifyContent={showCloseWindowButton ? 'center' : 'flex-end'}
  >
    {showCloseWindowButton && (
      <Button buttonType="primary" iconEnd={<IconClose />} onClick={handleCloseWindow}>
        {t('studentJoinScreen.closeWindow')}
      </Button>
    )}
    <SpaceBetween
      direction="x"
      spacing={spacing.size8}
      alignItems="center"
      padding={spacing.size4}
      style={
        showCloseWindowButton
          ? {
              position: 'absolute',
              right: 0,
            }
          : {}
      }
    >
      <BodyText>{t('studentJoinScreen.allowNewParticipants')}</BodyText>
      <AsyncSwitch
        id={`${id}-allowNewParticipants`}
        defaultChecked={allowNewPlayers}
        checked={allowNewPlayers}
        onChange={(e) => handleAllowNewPlayers(e.target.checked)}
        aria-label={t('studentJoinScreen.allowNewParticipantsSwitch')}
      />
    </SpaceBetween>
  </StyledFooter>
);

const StyledWraper = styled(SpaceBetween)`
  background: ${color.neutral.surface.background};
  padding: ${spacing.size24};
`;

const StyledFooter = styled(SpaceBetween)`
  position: relative;
`;

const StyledStudentSectionContent = styled.div`
  display: flex;
  padding: ${spacing.size12};
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing.size8};
  overflow-y: auto;
  position: relative;
  z-index: 1;
  align-content: flex-start;
`;

const StyledQRWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  max-height: 380px;
  max-width: 436px;
`;
