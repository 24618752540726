import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v1m1 7h-1.36a.5.5 0 0 1-.485-.621L12 12h-1m1 9.375a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75"
    />
  </svg>
);
export default SvgIconInfo;
