import { color, rem, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export interface LessonTopBarProps {
  children: React.ReactNode;
}

export const LessonTopBar: React.FC<LessonTopBarProps> = ({ children }) => {
  return <StyledLessonTopBar>{children}</StyledLessonTopBar>;
};

const StyledLessonTopBar = styled.div`
  border-top: 4px solid ${color.accent.tertiary.background};
  background: ${color.neutral.surface.background};
  height: ${rem('62px')};
  padding: 0 ${spacing.size16};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
