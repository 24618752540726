import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconArrowBendDownRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 3v4.829c0 2.461 2.015 4.457 4.5 4.457h9.1M16.24 7.57C18.33 9.266 19.372 10.3 21 12.286c-1.628 1.986-2.671 3.02-4.76 4.714"
    />
  </svg>
);
export default SvgIconArrowBendDownRight;
