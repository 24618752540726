import { rem } from 'polished';
import { ComponentSize } from '../size/size.types';
import { font } from './fonts';
import { FontWeight, TextStyle } from './typography.types';

// the 'rem' unit is used to be able to respect the user's browser font size settings
// Will eventually be deprecated
export const fontSize = {
  displaySizeLarge: rem('57px'),
  displaySizeMedium: rem('45px'),
  displaySizeSmall: rem('36px'),

  headlineSizeLarge: rem('32px'),
  headlineSizeMedium: rem('28px'),
  headlineSizeSmall: rem('24px'),

  bodyTextSizeLarge: rem('24px'),
  bodyTextSizeMedium: rem('16px'),
  bodyTextSizeSmall: rem('12px'),

  textElementButton: rem('16px'),
  textElementCaption: rem('12px'),
  textElementLabel: rem('12px'),
  textElementTag: rem('12px'),
  textElementBadge: rem('12px'),

  listItem: rem('12px'),
};

export const fontSizeMap: Record<TextStyle, Record<ComponentSize, string>> = {
  display: {
    small: rem('48px'),
    medium: rem('60px'),
    large: rem('72px'),
  },
  heading: {
    small: rem('32px'),
    medium: rem('40px'),
    large: rem('48px'),
  },
  subheading: {
    small: rem('24px'),
    medium: rem('30px'),
    large: rem('36px'),
  },
  title: {
    small: rem('24px'),
    medium: rem('30px'),
    large: rem('36px'),
  },
  bodyTextLarge: {
    small: rem('24px'),
    medium: rem('30px'),
    large: rem('36px'),
  },
  bodyText: {
    small: rem('16px'),
    medium: rem('20px'),
    large: rem('24px'),
  },
  caption: {
    small: rem('13px'),
    medium: rem('16.25px'),
    large: rem('19.5px'),
  },
  label: {
    small: rem('13px'),
    medium: rem('16.25px'),
    large: rem('19.5px'),
  },
  code: {
    small: rem('10px'),
    medium: rem('12.5px'),
    large: rem('15px'),
  },
};

// Will eventually be deprecated
export const lineHeight = {
  displaySizeLarge: rem('64px'),
  displaySizeMedium: rem('52px'),
  displaySizeSmall: rem('44px'),

  headlineSizeLarge: rem('40px'),
  headlineSizeMedium: rem('36px'),
  headlineSizeSmall: rem('32px'),

  bodyTextSizeLarge: rem('36px'),
  bodyTextSizeMedium: rem('24px'),
  bodyTextSizeSmall: rem('16px'),
  textElementButton: rem('24px'),
  textElementCaption: rem('16px'),
  textElementLabel: rem('24px'),
  textElementTag: rem('16px'),
  textElementBadge: rem('12px'),
  listItem: rem('16px'),
};

export const lineHeightMap: Record<TextStyle, Record<ComponentSize, string>> = {
  display: {
    small: rem('56px'),
    medium: rem('70px'),
    large: rem('84px'),
  },
  heading: {
    small: rem('44px'),
    medium: rem('55px'),
    large: rem('66px'),
  },
  subheading: {
    small: rem('32px'),
    medium: rem('40px'),
    large: rem('48px'),
  },
  title: {
    small: rem('32px'),
    medium: rem('40px'),
    large: rem('48px'),
  },
  bodyTextLarge: {
    small: rem('32px'),
    medium: rem('40px'),
    large: rem('48px'),
  },
  bodyText: {
    small: rem('24px'),
    medium: rem('30px'),
    large: rem('36px'),
  },
  caption: {
    small: rem('16px'),
    medium: rem('20px'),
    large: rem('24px'),
  },
  label: {
    small: rem('16px'),
    medium: rem('20px'),
    large: rem('24px'),
  },
  code: {
    small: rem('16px'),
    medium: rem('20px'),
    large: rem('24px'),
  },
};

export const fontWeight: Record<FontWeight, number> = {
  regular: 400,
  bold: 700,
  extraBold: 800,
};

export const fontWeightMap: Record<TextStyle, FontWeight> = {
  display: 'extraBold',
  heading: 'extraBold',
  subheading: 'extraBold',
  title: 'extraBold',
  bodyTextLarge: 'regular',
  bodyText: 'regular',
  caption: 'regular',
  label: 'regular',
  code: 'regular',
};

export const fontFamilyMap: Record<TextStyle, string> = {
  display: font.heading.cssValue,
  heading: font.heading.cssValue,
  subheading: font.heading.cssValue,
  title: font.heading.cssValue,
  bodyTextLarge: font.body.cssValue,
  bodyText: font.body.cssValue,
  caption: font.body.cssValue,
  label: font.body.cssValue,
  code: font.mono.cssValue,
};

export const htmlTagMap: Record<TextStyle, keyof React.JSX.IntrinsicElements> = {
  display: 'h1',
  heading: 'h2',
  subheading: 'h3',
  title: 'h4',
  bodyTextLarge: 'p',
  bodyText: 'p',
  caption: 'p',
  label: 'span',
  code: 'code',
};
