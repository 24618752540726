import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconShoppingBag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.25 7.875v-1.5a3.75 3.75 0 0 1 7.5 0v1.5m-12.75 9c0-3.107.559-5.6 1.194-7.911A1.49 1.49 0 0 1 5.63 7.875H18.37c.67 0 1.26.443 1.437 1.089.635 2.312 1.194 4.804 1.194 7.911 0 2.25-1.46 3.78-3.75 4.14-1.333.21-3 .36-5.25.36s-3.917-.15-5.25-.36c-2.29-.36-3.75-1.89-3.75-4.14"
    />
  </svg>
);
export default SvgIconShoppingBag;
