import { SpinnerDefaultOptions, SpinnerFocusMode, usePlayerDispatcher } from '@lessonup/players-modern';
import { spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { usePlayerTranslation } from '../../../../hooks/usePlayerTranslation';

export type FocusModeSpinnerProps = {
  focusMode: SpinnerFocusMode;
};

export const FocusModeSpinner: React.FC<FocusModeSpinnerProps> = ({ focusMode }) => {
  const dispatch = usePlayerDispatcher();
  const translatedOptions = useTranslatedOptions(focusMode.spinOptions);

  const updateSpinningHandler = (isSpinning: boolean) => {
    dispatch({
      type: 'setFocusMode',
      focusMode: {
        ...focusMode,
        isSpinning,
      },
    });
  };

  return (
    <StyledWrapper>
      <Spinner
        size={516}
        segmentTitles={translatedOptions}
        selectedIndex={focusMode.selectedIndex}
        spinCount={focusMode.spinCount}
        spinningHandler={updateSpinningHandler}
        winnerTitle={
          focusMode.spinCount > 0 && focusMode.previousDrawnOption && !focusMode.isSpinning
            ? translatedOptions[focusMode.selectedIndex]
            : undefined
        }
      />
    </StyledWrapper>
  );
};

function useTranslatedOptions(options: string[]): string[] {
  const { t } = usePlayerTranslation();

  const translateOption = (option: string) => {
    if (option === SpinnerDefaultOptions.YES || option === SpinnerDefaultOptions.NO) {
      return t(option);
    }

    return option;
  };

  return options.map(translateOption);
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: ${spacing.size12};
`;
