import { useLocale } from '@lessonup/client-integration';
import {
  BodyText,
  ButtonAnchor,
  Checkbox,
  Headline,
  IconHelp,
  InputFormField,
  Link,
  ListHeader,
  PageSection,
  rem,
  SpaceBetween,
  spacing,
  styled,
  useForm,
} from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import { keys, pickBy } from 'lodash';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FullPageBackground } from '../../../../../../components/FullPageFlow/FullPageBackground';
import { FullPageFlow } from '../../../../../../components/FullPageFlow/FullPageFlow';
import { FullPageFlowFooter } from '../../../../../../components/FullPageFlow/FullPageFlowFooter';
import { FullPageFlowTopBar } from '../../../../../../components/FullPageFlow/FullPageFlowTopBar';
import { profileSettingsRoute } from '../../../../../../utils/bridge/teacherRoutes';
import { ReasonType } from '../../../../AccountDeletion.graphql';
import { helpCenterArticleUrl } from '../../../../utils';

export interface RequestPageBodyProps {
  onDelete: (reasons: ReasonType[], otherReasonDescription?: string) => Promise<void>;
  loading: boolean;
  called: boolean;
  reasons: ReasonType[];
  teacherName?: string;
}

type FormInputs = Record<ReasonType, boolean> & { otherReasonDescription: string; confirmation: boolean };

export const RequestPageBody = ({ onDelete, loading, called, reasons, teacherName }: RequestPageBodyProps) => {
  const { t: tWithoutPrefix } = useTranslation('accountDeletion');
  const t = (key: string, options?: Record<string, unknown>) => {
    return tWithoutPrefix(`requestPageBody.${key}`, options);
  };
  const locale = useLocale();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<FormInputs>();

  const checkboxes = () =>
    reasons.map((reason) => (
      <Checkbox id={`reason-${reason}`} key={`reasons-${reason}`} label={t(`form.${reason}`)} {...register(reason)} />
    ));

  const onSubmit = ({ otherReasonDescription, confirmation, ...reasonsData }: FormInputs) => {
    if (!confirmation)
      throw new AppError(
        'unexpected-data',
        'Confirmation is required, this situation should never occur because the button should have been disabled'
      );
    const reasons = keys(pickBy(reasonsData, Boolean)) as ReasonType[];
    return onDelete(reasons, otherReasonDescription);
  };

  const showOtherReasonDescriptionInput = watch('OTHER_REASON');

  const LinkToChangeSubscription = () => {
    return (
      <Link href={profileSettingsRoute.href({ page: 'subscription' })} passHref>
        <a>{t('changeSubscription')}</a>
      </Link>
    );
  };

  return (
    <FullPageFlow>
      <FullPageFlowTopBar
        title={t('topBar.title')}
        backButton={{
          href: profileSettingsRoute.href({ page: 'profile' }),
          label: t('topBar.backButtonText'),
          labelTablet: t('topBar.backButtonText'),
        }}
      />
      <FullPageBackground>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageWrapper>
            <ContentWrapper direction="y" spacing={spacing.size8}>
              <Headline size="medium" as="h1">
                {teacherName ? t('pageHeading', { teacherName }) : t('pageHeadingWithoutName')}
              </Headline>
              <SpaceBetween direction="y" spacing={spacing.size32}>
                <BodyText>
                  <Trans t={tWithoutPrefix} i18nKey="requestPageBody.didYouKnow">
                    {'Did you know you can also take a break from LessonUp? You can always '}
                    <LinkToChangeSubscription />
                    {' instead of deleting your account permanently.'}
                  </Trans>
                </BodyText>
                <SpaceBetween direction="y" spacing={spacing.size8}>
                  <ListHeader text={t('whyDoYouWantToDelete')} priority="medium" />
                  <PageSection>
                    <SpaceBetween direction="y" spacing={spacing.size4}>
                      {checkboxes()}
                      {showOtherReasonDescriptionInput && (
                        <InputFormField
                          type="text"
                          label={t('form.otherReasonDescriptionLabel')}
                          placeholder={t('form.otherReasonDescriptionPlaceholder')}
                          hideLabel={true}
                          {...register('otherReasonDescription', {
                            required: watch('OTHER_REASON'),
                            onBlur(event) {
                              setValue('otherReasonDescription', event.target.value.trim());
                            },
                          })}
                        />
                      )}
                    </SpaceBetween>
                  </PageSection>
                </SpaceBetween>
                <SpaceBetween direction="y" spacing={spacing.size8}>
                  <ListHeader priority="medium" text={t('confirmCancellation')} />
                  <PageSection>
                    <SpaceBetween direction="y" spacing={spacing.size8}>
                      <Checkbox
                        id="confirmation"
                        label={t(`iUnderstand`)}
                        {...register('confirmation', { required: true })}
                      />
                      <div>
                        <ButtonAnchor
                          buttonType="neutral"
                          href={helpCenterArticleUrl(locale)}
                          resizing="hug"
                          target="_blank"
                          iconStart={<IconHelp />}
                        >
                          {t('learnMore')}
                        </ButtonAnchor>
                      </div>
                    </SpaceBetween>
                  </PageSection>
                </SpaceBetween>
              </SpaceBetween>
            </ContentWrapper>
          </PageWrapper>
          <FullPageFlowFooter
            primaryAction={{
              type: 'submit',
              buttonType: 'negative',
              label: t('footer.button.label'),
              disabled: !isValid || loading || called,
            }}
            secondaryAction={{
              href: profileSettingsRoute.href({ page: 'profile' }),
              buttonType: 'neutral',
              label: t('footer.backButton.label'),
            }}
          />
        </form>
      </FullPageBackground>
    </FullPageFlow>
  );
};

const PageWrapper = styled.div`
  padding: ${spacing.size16};
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled(SpaceBetween)`
  padding-top: ${spacing.size16};
  padding-bottom: ${spacing.size16};
  max-width: ${rem('688px')};
`;
