import {
  FormFieldValidation,
  InputFormField,
  ModalFooter,
  ModalHeaderV1,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { i18nextNamespace, maxLengthName } from '../../UploadsFeature.utils';

export interface RenameModalContentProps {
  currentName: string;
  handleRename: (name: string) => Promise<void>;
  onClose: () => void;
}

interface FormInputs {
  name: string;
}

export function RenameModalContent(props: RenameModalContentProps) {
  const { currentName, handleRename, onClose } = props;
  const { t } = useTranslation(i18nextNamespace);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setFocus,
  } = useForm<FormInputs>();

  useEffect(() => {
    setTimeout(() => {
      setFocus('name');
    }, 1);
  }, [setFocus]);

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    const trimmedName = data.name.trim();
    await handleRename(trimmedName);
    setLoading(false);
  };

  const validation: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = formErrors[inputName];
    if (!inputError) return;

    const message: string =
      inputError.type === 'minLengthValue'
        ? t('renameModal.error.minLengthValue')
        : inputError.message || t('renameModal.error.generic');

    return {
      state: 'error',
      message,
    };
  };

  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('renameModal.title') }}
        onCloseButtonClick={onClose}
        showDivider={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInputsWrapper>
          <InputFormField
            type="text"
            label={t('renameModal.label')}
            hideLabel
            {...register('name', {
              value: currentName,
              required: { value: true, message: t('renameModal.error.fieldIsRequired') },
              validate: {
                minLengthValue: (value: string) => value.trim().length > 0,
              },
              maxLength: { value: maxLengthName, message: t('renameModal.error.maxLength', { maxLengthName }) },
            })}
            validation={validation('name')}
          />
        </FormInputsWrapper>
        <ModalFooter
          type="actions"
          primaryAction={{
            type: 'submit',
            label: t('renameModal.button.rename'),
            loading,
          }}
        />
      </form>
    </>
  );
}

const FormInputsWrapper = styled.div`
  padding: ${spacing.size16} ${spacing.size24};
`;
