import VimeoPlayer from '@vimeo/player';
import React, { useCallback, useEffect, useRef } from 'react';
import { useEndTimeHandlerEvent } from '../hooks/useEndTimeHandler';
import { useVideoPlayerCurrentTimeRequest } from '../hooks/useVideoPlayerHandle';
import { VimeoPlayerProps } from './videoPlayerTypes';

export const VimeoPlayerComponent: React.FC<VimeoPlayerProps> = ({ sourceId, compId, startTime, endTime }) => {
  const playerRef = useRef<VimeoPlayer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getCurrentTime = async () => {
    if (playerRef.current) {
      return await playerRef.current.getCurrentTime();
    }
    return 0;
  };

  const initializePlayer = useCallback(
    (node: HTMLDivElement) => {
      if (playerRef.current) {
        return;
      }

      const videoIdNumeric = parseInt(sourceId, 10);

      const config = {
        autoplay: false,
        byline: false,
        title: false,
        loop: false,
        controls: true,
      } as const;

      let playerInstance: VimeoPlayer;

      if (!isNaN(videoIdNumeric) && !sourceId.includes('/')) {
        playerInstance = new VimeoPlayer(node, {
          ...config,
          id: videoIdNumeric,
        });
      } else {
        //For unlisted videos (that include a /) we need to pass the full url
        playerInstance = new VimeoPlayer(node, {
          ...config,
          url: `https://vimeo.com/${sourceId}`,
        });
      }

      playerInstance.on('error', (error) => {
        console.warn('Vimeo Player error:', error.name, error.message);
      });

      playerRef.current = playerInstance;
    },
    [sourceId]
  );

  useEffect(() => {
    const playerInstance = playerRef.current;

    if (!playerInstance) return;

    if (startTime !== undefined) {
      playerInstance.setCurrentTime(startTime);
    }
  }, [startTime]);

  useVideoPlayerCurrentTimeRequest(compId, getCurrentTime);

  useEndTimeHandlerEvent(
    endTime,
    () => {
      playerRef.current?.pause();
    },
    (handler) => {
      const timeUpdateHandler = (data: { seconds: number }) => {
        handler(data.seconds);
      };

      playerRef.current?.on('timeupdate', timeUpdateHandler);

      return () => {
        playerRef.current?.off('timeupdate', timeUpdateHandler);
      };
    }
  );

  const handleContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        initializePlayer(node);
        containerRef.current = node;
      }
    },
    [initializePlayer]
  );

  return <div ref={handleContainerRef} className="player-container" />;
};
