import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const pluginName = 'newLineDisabler';

export const NewLineDisabler = Extension.create({
  name: pluginName,
  addProseMirrorPlugins(this) {
    return [
      new Plugin({
        key: new PluginKey(pluginName),
        props: {
          /**
           * @description Should return whether or not the event has been handled, in order to stop it's propagation.
           * true = stop propagation
           * false | undefined = continue
           */
          handleKeyDown: (view, event) => {
            if (event.key === 'Enter') {
              return true;
            }

            return false;
          },
          handlePaste: (view, event) => {
            //TODO better regex before 2030
            if ((event.clipboardData?.getData('text/html').match(/<p/g) || []).length > 1) {
              return true;
            }

            return false;
          },
        },
      }),
    ];
  },
});
