import { tracker } from '@lessonup/analytics';
import { decodeYjsPins, tipTapContentToText } from '@lessonup/editor-shared';
import { isBoxLayout, UpdatePinDispatch } from '@lessonup/pin-renderer';
import {
  isTextPinComponent,
  newOpenQuestionPin,
  newPin,
  newQuizPin,
  newSlidePin,
  newTextPinComponent,
  newVector,
  newWordCloudPin,
  Pin,
  PinComponent,
  PinCreationParams,
  QuizPinAnswerProps,
  QuizPinLayoutTemplate,
  QuizTeacherPin,
  stringToProsemirror,
  TeacherPin,
} from '@lessonup/pins-shared';
import { Doc as YDoc } from 'yjs';
import { showQuizModal } from '../../../ChatGPTGenerator/ChatGPTGeneratorFeature';
import { LessonResponse, QuizResponse } from '../../../ChatGPTGenerator/model';
import { getQuestionComponentInInteractivePin } from '../pin/pin.utils';
import { createTranslatedTemplate } from '../template/createTranslatedTemplate';

export const maiaCreateV2QuizPin = (
  quiz: QuizResponse['list'][0],
  layout: QuizPinLayoutTemplate
): PinCreationParams<QuizTeacherPin> => {
  const answers = maiaV2QuizResponseAnswersToPinAnswers(quiz.answers);
  const correctAnswers = maiaV2QuizResponseCorrectAnswersToCorrectPinAnswers(quiz.correctAnswer, answers);
  const pin = newQuizPin(layout, correctAnswers, answers);
  const questionComponent = getQuestionComponentInInteractivePin(pin);
  questionComponent.settings.tipTapContent = maiaQuestionToTipTapContent(quiz.question);
  return pin;
};

const maiaV2QuizResponseAnswersToPinAnswers = (answers: string[]): QuizPinAnswerProps[] => {
  return answers.map((answer, i) => ({ type: 'TEXT', id: answer + i, text: answer }));
};

const maiaV2QuizResponseCorrectAnswersToCorrectPinAnswers = (
  correctAnswers: number[],
  answers: QuizPinAnswerProps[]
): string[] => {
  return correctAnswers.map((correctAnswer) => answers[correctAnswer - 1].id);
};

const maiaQuestionToTipTapContent = (question: string) =>
  stringToProsemirror(question, { fontSize: '32px', fontFamily: 'Plus Jakarta Sans', bold: true });

export const pinsContentToString = (yDoc: YDoc, pins: Pin[]): string => {
  const decodedPins = decodeYjsPins(yDoc, pins);
  const pinComponents = decodedPins
    .map((pin) => pin.pinComponents)
    .flat()
    .filter((c) => c.type === 'TEXT');

  const textPinComponents = pinComponents.filter(isTextPinComponent);
  return textPinComponents
    .map((c) => {
      const content = c.settings.tipTapContent ? tipTapContentToText(c.settings.tipTapContent) : '';
      return content;
    })
    .join(' ');
};

export const generateMaiaQuiz = (
  afterPinId: string | undefined,
  layout: QuizPinLayoutTemplate,
  dispatch: UpdatePinDispatch,
  onAddNewPins: (pinIds: string[]) => void,
  lessonId: string,
  prompt?: string
) => {
  showQuizModal({
    onGenerate: (res) => {
      res.list.reverse().map((quiz) => {
        //Current pin creation methods don't support unique id's for object reference so we dispatch for each pin we create rather then batch them
        const pin = maiaCreateV2QuizPin(quiz, layout);
        dispatch({
          type: 'addPins',
          afterPinId: afterPinId,
          pins: [pin],
          onComplete: onAddNewPins,
        });
        tracker.events.pinCreate({
          lessonId,
          pinType: 'quiz',
          pinCreationMethod: 'generate_with_ai',
          editorVersion: 'v2',
        });
      });
    },
    prompt,
  });
};

export const handleMaiaResponse = (res: LessonResponse): TeacherPin[] => {
  const template = createTranslatedTemplate();
  const slideLayouts = template.layouts.slidePinLayouts;
  const openQuestionLayout = template.layouts.openQuestionLayouts.find((layout) => layout.key === 'TITLE_ONLY');
  const wordCloudLayout = template.layouts.wordCloudLayouts.find((layout) => layout.key === 'TITLE_ONLY');
  const coverLayout = slideLayouts.find((slide) => slide.key === 'COVER');
  const slideLayout = slideLayouts.find((slide) => slide.key === 'TITLE_TOP_WITH_BODYTEXT');

  const pins: TeacherPin[] = [];
  res.list.forEach((item) => {
    let newPinObj;

    switch (item.type) {
      case 'slide':
        if (item.layout === 'cover' && coverLayout) {
          newPinObj = newPin(
            newSlidePin({
              key: coverLayout.key,
              backgroundColor: coverLayout.backgroundColor,
              layoutParts: {
                ...coverLayout.layoutParts,
                TITLE: createMaiaTextPinComponent({
                  tempId: item.tempId,
                  text: item.header,
                  bold: true,
                  layoutTextPinComponent: coverLayout.layoutParts.TITLE,
                }),
              },
              pinComponents: [],
            })
          );
        } else if (item.layout === 'text' && slideLayout) {
          newPinObj = newPin(
            newSlidePin({
              key: slideLayout.key,
              backgroundColor: slideLayout.backgroundColor,
              layoutParts: {
                ...slideLayout.layoutParts,
                TITLE: createMaiaTextPinComponent({
                  tempId: item.tempId,
                  text: item.header,
                  bold: true,
                  layoutTextPinComponent: slideLayout.layoutParts.TITLE,
                }),
                BODYTEXT1: createMaiaTextPinComponent({
                  tempId: `${item.tempId}1`,
                  text: item.text,
                  layoutTextPinComponent: slideLayout.layoutParts.BODYTEXT1,
                }),
              },
              pinComponents: [],
            })
          );
        }
        break;
      case 'openQuestion':
        if (openQuestionLayout) {
          newPinObj = newPin(
            newOpenQuestionPin({
              key: openQuestionLayout.key,
              backgroundColor: openQuestionLayout.backgroundColor,
              backgroundImage: openQuestionLayout.backgroundImage,
              backgroundTransparency: openQuestionLayout.backgroundTransparency,
              pinComponents: [],
              layoutParts: {
                ...openQuestionLayout.layoutParts,
                TITLE: createMaiaTextPinComponent({
                  bold: true,
                  tempId: item.tempId,
                  text: item.question,
                  layoutTextPinComponent: openQuestionLayout.layoutParts.TITLE,
                }),
              },
            })
          );
        }
        break;
      case 'wordweb':
        if (wordCloudLayout) {
          newPinObj = newPin(
            newWordCloudPin({
              key: wordCloudLayout.key,
              backgroundColor: wordCloudLayout.backgroundColor,
              backgroundImage: wordCloudLayout.backgroundImage,
              backgroundTransparency: wordCloudLayout.backgroundTransparency,
              pinComponents: [],
              layoutParts: {
                ...wordCloudLayout.layoutParts,
                TITLE: createMaiaTextPinComponent({
                  tempId: item.tempId,
                  text: item.word,
                  bold: true,
                  layoutTextPinComponent: wordCloudLayout.layoutParts.TITLE,
                }),
              },
            })
          );
        }
        break;
      default:
        console.warn('Unknown item type', item);
        newPinObj =
          slideLayout &&
          newPin(
            newSlidePin({
              key: slideLayout.key,
              backgroundColor: slideLayout.backgroundColor,
              layoutParts: { ...slideLayout.layoutParts },
              pinComponents: [],
            })
          );
    }

    if (newPinObj) {
      pins.push(newPinObj);
    }
  });

  return pins;
};

interface MaiaTextPinComponentProps {
  text: string;
  tempId: string;
  layoutTextPinComponent?: PinComponent;
  position?: { x: number; y: number };
  bold?: boolean;
}

const createMaiaTextPinComponent = ({
  text,
  layoutTextPinComponent,
  tempId,
  position,
  bold,
}: MaiaTextPinComponentProps) => {
  if (layoutTextPinComponent && isTextPinComponent(layoutTextPinComponent)) {
    const newTextPinComponent = {
      ...layoutTextPinComponent,
      id: tempId,
      settings: {
        ...layoutTextPinComponent.settings,
        tipTapContent: bold ? stringToProsemirror(text, { bold }) : stringToProsemirror(text),
      },
    };
    if (position && isBoxLayout(newTextPinComponent.layout)) {
      newTextPinComponent.layout = { ...newTextPinComponent.layout, position: newVector(position.x, position.y) };
    }
    return newTextPinComponent;
  } else {
    return newTextPinComponent({ content: stringToProsemirror(text) });
  }
};
