import { Pin, PinComponent, PinSettings, PinType } from '@lessonup/pins-shared';

export function overridePinComponents(
  allPinComponentOnPin: PinComponent[],
  updatedPinComponents: PinComponent[]
): PinComponent[] {
  return allPinComponentOnPin.map((pinComponent) => {
    const mutatedPinComponent = updatedPinComponents.find(
      (mutatedPinComponent) => mutatedPinComponent.id === pinComponent.id
    );

    return mutatedPinComponent ?? pinComponent;
  });
}

export function updatePinSettings<P extends Pin>(pin: P, updatedSettings: Partial<PinSettings<PinType>>) {
  const newSettings = {
    ...pin.settings,
    ...updatedSettings,
  };

  return {
    ...pin,
    settings: newSettings,
  } as P; // Cast is needed because it refuses to merge the union of pins
}
