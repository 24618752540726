import { LoaderSkeleton, rem } from '@lessonup/ui-components';
import React from 'react';
import {
  PlayerBarTopEndSection,
  PlayerBarTopMiddleSection,
  PlayerBarTopStartSection,
  PlayerBarTopWrapper,
} from './components/PlayerBarTopModules';

export const PlayerBarTop: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <PlayerBarTopWrapper>{children}</PlayerBarTopWrapper>;
};

export const PlayerBarTopPlaceholder: React.FC = () => {
  return (
    <PlayerBarTopWrapper>
      <PlayerBarTopStartSection>
        <LoaderSkeleton width="100%" height={rem('16px')} />
      </PlayerBarTopStartSection>
      <PlayerBarTopMiddleSection>
        <LoaderSkeleton width="100%" height={rem('16px')} />
      </PlayerBarTopMiddleSection>
      <PlayerBarTopEndSection>
        <LoaderSkeleton width="100%" height={rem('16px')} />
      </PlayerBarTopEndSection>
    </PlayerBarTopWrapper>
  );
};
