import {
  AsyncMouseEventHandler,
  BodyText,
  breakpointsRem,
  Button,
  ButtonAnchor,
  ButtonAnchorProps,
  ButtonProps,
  color,
  IconArrowLeft,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';

export type TopBarAction = Omit<ButtonProps, 'children' | 'variant' | 'buttonType' | 'style'> & { async?: boolean } & {
  label: string | React.JSX.Element;
  labelTablet: string | React.JSX.Element;
};

export type TopBarActionAsync = TopBarAction & { async: true } & {
  onClick: AsyncMouseEventHandler;
};

export type AnchorTopBarAction = Omit<ButtonAnchorProps, 'children' | 'variant' | 'buttonType' | 'style'> & {
  label: string | React.JSX.Element;
  labelTablet: string | React.JSX.Element;
};

const isAsyncAction = (action: TopBarAction | TopBarActionAsync | AnchorTopBarAction): action is TopBarActionAsync => {
  return !!(action as TopBarActionAsync).async;
};

const isAnchorAction = (
  action: TopBarAction | TopBarActionAsync | AnchorTopBarAction
): action is AnchorTopBarAction => {
  return !!(action as AnchorTopBarAction).href;
};

export interface FullPageFlowHeaderProps {
  title: string;
  backButton: TopBarAction | TopBarActionAsync | AnchorTopBarAction;
}

export const FullPageFlowTopBar: React.FC<FullPageFlowHeaderProps> = ({ title, backButton }) => {
  return (
    <Wrapper direction="x" spacing={spacing.size8}>
      <StyleButtonWrapper>
        {isAsyncAction(backButton) ? (
          <Button buttonType="neutral" onClick={backButton.onClick} style={{ flex: '1' }} iconStart={<IconArrowLeft />}>
            <TabletText>{backButton.labelTablet}</TabletText>
            <DesktopText>{backButton.label}</DesktopText>
          </Button>
        ) : isAnchorAction(backButton) ? (
          <ButtonAnchor buttonType="neutral" href={backButton.href} style={{ flex: '1' }} iconStart={<IconArrowLeft />}>
            <TabletText>{backButton.labelTablet}</TabletText>
            <DesktopText>{backButton.label}</DesktopText>
          </ButtonAnchor>
        ) : (
          <Button buttonType="neutral" onClick={backButton.onClick} style={{ flex: '1' }} iconStart={<IconArrowLeft />}>
            <TabletText>{backButton.labelTablet}</TabletText>
            <DesktopText>{backButton.label}</DesktopText>
          </Button>
        )}
      </StyleButtonWrapper>
      <Title size="small">{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled(SpaceBetween)`
  position: relative;
  padding: ${spacing.size12};
  align-items: center;
  background-color: ${color.neutral.surface.background};
  justify-content: flex-start;
  ::after {
    content: '';
    flex: 1;
  }
`;

const Title = styled(BodyText)`
  color: ${color.accent.secondary.background};
  margin-right: auto;
`;

const StyleButtonWrapper = styled.div`
  flex: 1;
`;

const TabletText = styled.span`
  display: none;
  @media (max-width: ${breakpointsRem.tablet}) {
    display: inline;
  }
`;

const DesktopText = styled.span`
  display: inline;
  @media (max-width: ${breakpointsRem.tablet}) {
    display: none;
  }
`;
