export namespace SeedShuffle {
  export function shuffle<T>(arr: T[], seedStr: string | number): T[] {
    const seed = getSeed(seedStr);
    if (!seed) return arr;
    const shuff = arr.slice(0);
    const size = shuff.length;
    const map = genMap(size, seed);

    for (let i = size - 1; i > 0; i--) {
      shuff[i] = shuff.splice(map[size - 1 - i], 1, shuff[i])[0];
    }
    return shuff;
  }

  export function unshuffle<T>(arr: T[], seedStr: string | number) {
    const seed = getSeed(seedStr);
    if (!seed) return arr;
    const shuff = arr.slice(0);
    const size = shuff.length;
    const map = genMap(size, seed);
    for (let i = 1; i < size; i++) {
      shuff[i] = shuff.splice(map[size - 1 - i], 1, shuff[i])[0];
    }
    return shuff;
  }

  function genMap(size: number, seed: number) {
    const map = new Array(size);
    for (let x = 0; x < size; x++) {
      //Don't change these numbers.
      map[x] = (((seed = (seed * 9301 + 49297) % 233280) / 233280.0) * size) | 0;
    }
    return map;
  }

  function getSeed(seedStr: string | number): number {
    if (isNaN(Number(seedStr))) {
      return Number(
        String(seedStr)
          .split('')
          .map(function (x) {
            return x.charCodeAt(0);
          })
          .join('')
      );
    }
    return Number(seedStr);
  }
}
