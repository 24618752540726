import { dispatchLocalStorageUpdatedEvent } from '../browser/utils/localStorage/localStorageEvents';

const keys = {
  meteorUserId: 'Meteor.userId',
  meteorLoginToken: 'Meteor.loginToken',
  authAccessToken: 'auth.accessToken',
  authUserId: 'auth.userId',
  impersonatorMeteorUserId: 'impersonator.meteor.userId',
  impersonatorMeteorLoginToken: 'impersonator.meteor.loginToken',
  impersonating: 'impersonator.impersonating', // WARNING: if you change this key, also change this key in the old applications!
};

export class AuthTokenStorage {
  public readonly keys = keys;

  public get meteorLoginToken(): string | null {
    return localStorage.getItem(keys.meteorLoginToken);
  }

  public set meteorLoginToken(value: string | null) {
    this.setOrClear(keys.meteorLoginToken, value);
  }

  public get meteorUserId(): string | null {
    return localStorage.getItem(keys.meteorUserId);
  }

  public set meteorUserId(value: string | null) {
    this.setOrClear(keys.meteorUserId, value);
  }

  public get authAccessToken(): string | null {
    return localStorage.getItem(keys.authAccessToken);
  }

  public set authAccessToken(value: string | null) {
    this.setOrClear(keys.authAccessToken, value);
  }

  public get authUserId(): string | null {
    return localStorage.getItem(keys.authUserId);
  }

  public set authUserId(value: string | null) {
    this.setOrClear(keys.authUserId, value);
  }

  public get impersonatorMeteorLoginToken(): string | null {
    return localStorage.getItem(keys.impersonatorMeteorLoginToken);
  }

  public set impersonatorMeteorLoginToken(value: string | null) {
    this.setOrClear(keys.impersonatorMeteorLoginToken, value);
  }

  public get impersonatorMeteorUserId(): string | null {
    return localStorage.getItem(keys.impersonatorMeteorUserId);
  }

  public set impersonatorMeteorUserId(value: string | null) {
    this.setOrClear(keys.impersonatorMeteorUserId, value);
  }

  public get impersonating(): boolean | null {
    return localStorage.getItem(keys.impersonating) === 'true';
  }

  public set impersonating(value: boolean | null) {
    this.setOrClear(keys.impersonating, value ? 'true' : null);
  }

  private setOrClear(key: string, value: string | null) {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
    dispatchLocalStorageUpdatedEvent();
  }
}

export const authTokenStorage = new AuthTokenStorage();
