import {
  Button,
  IconReset,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  TextElement,
  Tooltip,
  ValidationMessage,
} from '@lessonup/ui-components';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatSecondsToTime, parseTimeToSeconds } from '../../utils/time/time.utils';

export type TimeInputError = { state: 'error'; message: string } | null;

export interface TimeInputFieldProps {
  label: string;
  id: string;
  initialValue?: number;
  onValidChange: (newTime: number | undefined) => void;
  externalError?: TimeInputError;
  onBlur?: (time?: number) => void;
  onReset?: () => void;
}

export const TimeInputField: React.FC<TimeInputFieldProps> = ({
  label,
  id,
  initialValue,
  onValidChange,
  externalError,
  onBlur,
  onReset,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    initialValue !== undefined ? formatSecondsToTime(initialValue) : ''
  );
  const [internalError, setInternalError] = useState<TimeInputError>(null);
  const isUserTypingRef = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isUserTypingRef.current) {
      setInputValue(initialValue !== undefined ? formatSecondsToTime(initialValue) : '');
    }
  }, [initialValue]);

  const debouncedOnValidChange = useMemo(
    () =>
      debounce((value: string) => {
        const numericValue = parseTimeToSeconds(value);
        if (numericValue !== undefined) {
          onValidChange(numericValue);
        } else {
          return;
        }
      }, 100),
    [onValidChange]
  );

  useEffect(() => {
    return () => {
      debouncedOnValidChange.cancel();
    };
  }, [debouncedOnValidChange]);

  const handleInputChange = (value: string) => {
    isUserTypingRef.current = true;
    setInputValue(value);
    debouncedOnValidChange(value);
  };

  const handleBlur = () => {
    isUserTypingRef.current = false;
    const numericValue = parseTimeToSeconds(inputValue);

    if (inputValue.trim() === '') {
      setInternalError(null);
      onValidChange(undefined);
    } else if (numericValue === undefined) {
      setInternalError({ state: 'error', message: t('timeInput.invalidTimeFormat') });
      onValidChange(undefined);
    } else {
      const formattedValue = formatSecondsToTime(numericValue);
      setInputValue(formattedValue);
      setInternalError(null);
      onValidChange(numericValue);
    }

    if (onBlur) {
      onBlur(numericValue);
    }
  };

  const handleReset = () => {
    isUserTypingRef.current = false;
    const formattedValue = initialValue !== undefined ? formatSecondsToTime(initialValue) : '';
    setInputValue(formattedValue);
    setInternalError(null);
    onValidChange(initialValue);
    if (onReset) {
      onReset();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const errorToDisplay = internalError?.state === 'error' ? internalError : externalError;

  return (
    <SpaceBetween direction="y" spacing={spacing.size2}>
      <TextElement textStyle="label" weight="bold">
        {label}
      </TextElement>
      <SpaceBetween direction="x" spacing={spacing.size2}>
        <StyledInputFormField
          id={id}
          type="text"
          label=""
          hideLabel
          value={inputValue}
          onChange={(e) => handleInputChange(e.currentTarget.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder="HH:MM:SS.ss"
        />
        {onReset && (
          <StyledTooltip direction="top" resize="fixed" content={t('reset')}>
            <Button type="button" buttonType="neutral" iconStart={<IconReset />} onClick={handleReset} />
          </StyledTooltip>
        )}
      </SpaceBetween>
      {errorToDisplay && errorToDisplay.state === 'error' && (
        <ValidationMessage id={`${id}-validation`} validation={errorToDisplay} />
      )}
    </SpaceBetween>
  );
};

const StyledTooltip = styled(Tooltip)`
  align-self: center;
`;

const StyledInputFormField = styled(InputFormField)`
  width: 100%;
`;
