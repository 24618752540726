import {
  getPinComponentContentBackgroundColor,
  getPinComponentContentTextColor,
  getPinComponentInnerColor,
  HotspotExpandDirection,
  HotspotFillType,
  HotspotImage,
  HotspotPinComponent,
  HotspotSettings,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import {
  Button,
  IconComponentImage,
  IconStar,
  IconText,
  SpaceBetween,
  spacing,
  styled,
  Tab,
  TabList,
  TextElement,
} from '@lessonup/ui-components';
import React, { useRef } from 'react';
import {
  ContextSidebarCollapsibleGroup,
  ContextSidebarGroup,
} from '../../../../../../../../components/ContextSidebar/ContextSidebarGroup';
import { useNonNullActivePin } from '../../../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../../../hooks/useEditorTranslation';
import {
  createHandleContentBackgroundColorChange,
  createHandleContentTextColorChange,
  createHandleInnerColorChange,
} from '../../../../../../utils/pinComponentSettings/hotspotPinComponentSettings';
import { getLinkablePinComponents } from '../../../../EditorContextSidebar.utils';
import { EditorTiptapFormField } from '../../../EditorInputFields/EditorTiptapFormField';
import { ColorPickerSetting } from '../../../SharedSettings/ColorPickerSetting';
import { BackgroundColorSetting } from '../../sharedComponentSettings/BackgroundColorSetting';
import { BorderColorSetting } from '../../sharedComponentSettings/BorderColorSetting';
import { LayoutPositionSettingGroup } from '../../sharedComponentSettings/LayoutPositionSetting';
import { ShadowSetting } from '../../sharedComponentSettings/ShadowSetting';
import { TransparencySetting } from '../../sharedComponentSettings/TransparencySetting';
import { PinComponentSettingsProps } from '../../types';
import { useHandleCreateImageAttach } from './hooks/useHandleCreateImageAttach';
import { IconFillPicker } from './IconFillPicker';
import { ImageFillPicker } from './ImageFillPicker';
import { PresentationSettings } from './PresentationSettings';
import { TextFillPicker } from './TextFillPicker';
import { ThumbnailPinComponent } from './ThumbnailPinComponent';

export const HotspotPinComponentSettings: React.FC<PinComponentSettingsProps<HotspotPinComponent>> = ({
  pinComponent,
}) => {
  const pin = useNonNullActivePin();
  const { t } = useEditorTranslation();
  const dispatch = useUpdatePin();
  const attachImageButtonRef = useRef<HTMLButtonElement>(null);
  const handleCreateImageAttach = useHandleCreateImageAttach(pinComponent, attachImageButtonRef);

  const linkablePinComponents = getLinkablePinComponents(pin);

  const handleLinkPinComponent = (pinComponentId: string) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: {
        linkedPinComponent: pinComponentId,
      },
    });
  };

  const handleUnlinkPinComponent = () => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: {
        linkedPinComponent: undefined,
      },
    });
  };

  const handleIconChange = (iconName: HotspotSettings['iconFill']) => {
    dispatch({ type: 'updatePinComponentSettings', pinComponentId: pinComponent.id, settings: { iconFill: iconName } });
  };

  const handleTextFillChange = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: {
        textFill: e.currentTarget.value,
      },
    });
  };

  const handleImageChange = (image: HotspotImage) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: { imageFill: { ...image } },
    });
  };

  const handleFillTabChange = (tab: HotspotFillType) => {
    switch (tab) {
      case 'ICON':
        return dispatch({
          type: 'updatePinComponentSettings',
          pinComponentId: pinComponent.id,
          settings: { fillType: 'ICON' },
        });
      case 'IMAGE':
        return dispatch({
          type: 'updatePinComponentSettings',
          pinComponentId: pinComponent.id,
          settings: { fillType: 'IMAGE' },
        });
      case 'TEXT':
        return dispatch({
          type: 'updatePinComponentSettings',
          pinComponentId: pinComponent.id,
          settings: { fillType: 'TEXT' },
        });
    }
  };

  const handleExpandSetting = (expandSetting: HotspotExpandDirection) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      settings: { expandDirection: expandSetting },
    });
  };

  const handleInnerColorChange = createHandleInnerColorChange(pinComponent.id, dispatch);
  const handleContentBackgroundColorChange = createHandleContentBackgroundColorChange(pinComponent.id, dispatch);
  const handleContextTextColorChange = createHandleContentTextColorChange(pinComponent.id, dispatch);

  return (
    <>
      <ContextSidebarGroup>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <TabList resizing="fixed">
            <Tab
              isActive={pinComponent.settings.fillType == 'ICON'}
              as="button"
              onClick={() => handleFillTabChange('ICON')}
              content={<IconStar width={16} height={16} />}
              size="large"
            />
            <Tab
              isActive={pinComponent.settings.fillType === 'IMAGE'}
              as="button"
              onClick={() => handleFillTabChange('IMAGE')}
              content={<IconComponentImage width={16} height={16} />}
              size="large"
            />
            <Tab
              isActive={pinComponent.settings.fillType === 'TEXT'}
              as="button"
              onClick={() => handleFillTabChange('TEXT')}
              content={<IconText width={16} height={16} />}
              size="large"
            />
          </TabList>
          {pinComponent.settings.fillType === 'ICON' && (
            <IconFillPicker selectedIcon={pinComponent.settings.iconFill} setSelectedIcon={handleIconChange} />
          )}
          {pinComponent.settings.fillType === 'IMAGE' && (
            <ImageFillPicker selectedImage={pinComponent.settings.imageFill} setSelectedImage={handleImageChange} />
          )}
          {pinComponent.settings.fillType === 'TEXT' && (
            <TextFillPicker text={pinComponent.settings.textFill} setTextFill={handleTextFillChange} />
          )}
          <div>
            <ColorPickerSetting
              selectedColor={getPinComponentInnerColor(pinComponent)}
              text={t('contextSidebar.settings.textColor')}
              ariaLabel={t('contextSidebar.settings.textColor')}
              onChange={handleInnerColorChange}
              hideTransparentOption
            />
            <BackgroundColorSetting
              pinComponent={pinComponent}
              text={t('contextSidebar.settings.backgroundColor')}
              hideTransparentOption
            />
            <BorderColorSetting pinComponent={pinComponent} text={t('contextSidebar.settings.borderColor')} />
          </div>
        </SpaceBetween>
      </ContextSidebarGroup>
      <LayoutPositionSettingGroup />
      <ContextSidebarGroup title={t('contextSidebar.settings.hotspotContent')}>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <EditorTiptapFormField
            pinId={pin.id}
            pinComponentId={pinComponent.id}
            fragmentKey="title"
            label={t('hotspotPinComponent.title')}
          />
          <EditorTiptapFormField
            pinId={pin.id}
            pinComponentId={pinComponent.id}
            fragmentKey="body"
            label={t('hotspotPinComponent.body')}
            isTextArea
          />
          <SpaceBetween direction="y" spacing={spacing.size12}>
            <div>
              <ColorPickerSetting
                selectedColor={getPinComponentContentBackgroundColor(pinComponent)}
                text={t('contextSidebar.settings.backgroundColor')}
                ariaLabel={t('contextSidebar.settings.backgroundColor')}
                hideTransparentOption
                onChange={handleContentBackgroundColorChange}
              />
              <ColorPickerSetting
                selectedColor={getPinComponentContentTextColor(pinComponent)}
                text={t('contextSidebar.settings.textColor')}
                ariaLabel={t('contextSidebar.settings.textColor')}
                onChange={handleContextTextColorChange}
                hideTransparentOption
              />
            </div>
            <TextElement textStyle="label" weight="bold">
              {t('hotspotPinComponent.attachImage')}
            </TextElement>
            {linkablePinComponents && (
              <StyledWrapper>
                {linkablePinComponents.map((component, i) => (
                  <ThumbnailPinComponent
                    index={i}
                    key={component.id}
                    component={component}
                    isActive={component.id === pinComponent.settings.linkedPinComponent}
                    onActivate={handleLinkPinComponent}
                    onDeactivate={handleUnlinkPinComponent}
                  />
                ))}
              </StyledWrapper>
            )}
            <Button ref={attachImageButtonRef} onClick={handleCreateImageAttach}>
              {t('hotspotPinComponent.attachImageButton')}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </ContextSidebarGroup>
      <ContextSidebarCollapsibleGroup
        persistId="hotspotEffectSettings"
        title={t('contextSidebar.settings.effects')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <ShadowSetting pinComponent={pinComponent} key={`${pinComponent.id}-hotspot-shadow-setting`} />
        <TransparencySetting pinComponent={pinComponent} key={`${pinComponent.id}-transparency-shadow-setting`} />
      </ContextSidebarCollapsibleGroup>
      <ContextSidebarCollapsibleGroup
        persistId="hotspotTeachSettings"
        title={t('contextSidebar.settings.teachSettings')}
        isCollapsed={true}
        openLabel={t('contextSidebar.openLabel')}
        closeLabel={t('contextSidebar.closeLabel')}
      >
        <PresentationSettings
          expandPosition={pinComponent.settings.expandDirection}
          setExpandPosition={handleExpandSetting}
        />
      </ContextSidebarCollapsibleGroup>
    </>
  );
};

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${spacing.size8};
`;
