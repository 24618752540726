/* eslint-disable react/jsx-key */
import { AssetLicenseStatusPro } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { InlineSvgText } from '../../../components/InlineSvgText/InlineSvgText';

interface Props {
  i18nKey: string;
  t: TFunction;
}

export const TransWithProIcon: React.FC<Props> = ({ i18nKey, t }) => {
  return (
    <InlineSvgText>
      <Trans i18nKey={i18nKey} t={t} components={[<AssetLicenseStatusPro />]} />
    </InlineSvgText>
  );
};
