import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { HTMLAttributes } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { IconClose } from '../icons';
import { LoaderSpinner } from '../LoaderSpinner/LoaderSpinner';

export interface ChipContainerProps extends HTMLAttributes<HTMLButtonElement> {
  as?: keyof React.JSX.IntrinsicElements;
  active?: boolean;
  disabled?: boolean;
}

export interface ChipProps extends ChipContainerProps {
  icon?: React.JSX.Element;
  dismissable?: boolean;
  text: string;
  $loading?: boolean;
}

export const Chip: React.FC<ChipProps> = (props) => {
  return (
    <StyledChipContainer {...props}>
      {!props.dismissable && props.icon}
      <StyledContainer>{props.text}</StyledContainer>
      {props.dismissable ? (
        props.$loading ? (
          <StyledLoadingContainer>
            <LoaderSpinner />
          </StyledLoadingContainer>
        ) : (
          <IconClose width="14px" height="14px" />
        )
      ) : null}
    </StyledChipContainer>
  );
};

const StyledContainer = styled.div`
  padding: 0 ${spacing.size4};
`;

const StyledChipContainer = styled.button<ChipContainerProps>`
  border-radius: ${borderRadius.roundedFull};
  width: fit-content;
  position: relative;

  padding: calc(${spacing.size8} - 2px);
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => {
    const colorScheme = props.disabled
      ? color.additional.disabled
      : props.active
        ? color.accent.secondary
        : color.accent.secondaryContainer;
    return css`
      color: ${colorScheme.text};
      background: ${colorScheme.background};
      border: 2px solid transparent;
    `;
  }};

  /** 
   * In order to avoid white pixels between the outline and the rounded corners
   * a pseudo element is created with the height and width of the chip plus the size of the outline.
   */
  &::after {
    position: absolute;
    top: -${spacing.size2};
    left: -${spacing.size2};
    height: calc(100% + ${spacing.size4});
    width: calc(100% + ${spacing.size4});
    border-radius: inherit;
    background: inherit;
    z-index: -1;
  }

  &:focus::after {
    content: ' ';
  }
`;

const StyledLoadingContainer = styled.div`
  width: ${rem('14px')};
  height: ${rem('14px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
