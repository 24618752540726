import { colorSets, LoaderSpinner, styled } from '@lessonup/ui-components';
import React from 'react';

export const ImagePinComponentLoadingPlaceholder: React.FC = () => {
  return (
    <StyledImagePinComponentLoadingPlaceholder>
      <LoaderSpinner />
    </StyledImagePinComponentLoadingPlaceholder>
  );
};

const StyledImagePinComponentLoadingPlaceholder = styled.div`
  background: #f0f1f5;
  color: ${colorSets.neutral.onSubjacent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed ${colorSets.neutral.strokeSubjacent};
`;
