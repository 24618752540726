import { getDataRoot, getFragmentsRoot } from '@lessonup/editor-shared';
import { PartialRecord } from '@lessonup/utils';
import { useCallback, useEffect, useRef } from 'react';
import { ySyncPluginKey } from 'y-prosemirror';
import { AbstractType, Array as YArray, ContentAny, Doc, Map as YMap, UndoManager, YEvent } from 'yjs';
import { editorStateStore } from '../../context/EditorContext/store/editorStateStore';
import { getClientYDocOrigin } from './yjs.utils';

// UndoManager does not export several types that we need, so we have to extract them from the type definition indirectly
// and base our types on them and the source code itself (yjs/src/utils/UndoManager.js)

type ChangedParentTypes = Map<
  AbstractType<YEvent<YArray<unknown> | YMap<unknown>>>,
  YEvent<YArray<unknown> | YMap<unknown>>[]
>;

type UndoManagerEvents = 'stack-item-added' | 'stack-item-popped' | 'stack-cleared' | 'stack-item-updated';

type UndoStackType = UndoManager['undoStack'];

type StackItem = UndoStackType[number];

type StackEvent = {
  stackItem: StackItem;
  changedParentTypes: ChangedParentTypes;
};

type UndoManagerEventCallback = (event: StackEvent) => void;

type UndoManagerReturnType = {
  undoManager: UndoManager | null;
  setActivePinCallback: (callback: ((pinId: string) => void) | undefined) => void;
};

type StackItemCallbacksType = PartialRecord<UndoManagerEvents, UndoManagerEventCallback>;

const undoManagerEventsArray: UndoManagerEvents[] = [
  'stack-item-added',
  'stack-item-popped',
  // 'stack-cleared',
  // 'stack-item-updated',
];

export const useUndoManager = (yDoc: Doc): UndoManagerReturnType => {
  const clientOrigin = getClientYDocOrigin(yDoc.clientID);

  // Refs are used because we need to ensure that a single instance of the undo manager is used throughout the lifetime of a yDoc/clientId
  const undoManagerRef = useRef<UndoManager | null>(null);
  const stackItemCallbacks = useRef<StackItemCallbacksType>({});
  const activePinCallbackRef = useRef<((pinId: string) => void) | undefined>(null);

  useEffect(() => {
    const oldUndoManager = undoManagerRef.current;
    const oldCallbacks = stackItemCallbacks.current;

    const newUndoManager = new UndoManager([getFragmentsRoot(yDoc), getDataRoot(yDoc)], {
      doc: yDoc,
      trackedOrigins: new Set([clientOrigin, ySyncPluginKey]),
      captureTimeout: 300,
    });

    undoManagerRef.current = newUndoManager;

    stackItemCallbacks.current['stack-item-added'] = (event) => {
      event.stackItem.meta.set('pin', editorStateStore.getActivePinIdSnapshot());
    };

    stackItemCallbacks.current['stack-item-popped'] = (event) => {
      const pinId = getPinId(event);
      if (typeof pinId === 'string') {
        activePinCallbackRef.current?.(pinId);
      }
    };

    for (const undoManagerEvent of undoManagerEventsArray) {
      const callback = stackItemCallbacks.current[undoManagerEvent];
      callback && newUndoManager?.on(undoManagerEvent, callback);
    }

    const newCallbacks = stackItemCallbacks.current;

    destroyUndoManager(oldUndoManager, oldCallbacks);
    return () => {
      destroyUndoManager(newUndoManager, newCallbacks);
    };
  }, [clientOrigin, yDoc]);

  const setActivePinCallback = useCallback((callback: ((pinId: string) => void) | undefined) => {
    activePinCallbackRef.current = callback;
  }, []);

  return {
    undoManager: undoManagerRef.current,
    setActivePinCallback,
  };
};

const destroyUndoManager = (undoManager: UndoManager | null, callbacks: StackItemCallbacksType): void => {
  if (undoManager) {
    if (callbacks) {
      for (const undoManagerEvent of undoManagerEventsArray) {
        const callback = callbacks[undoManagerEvent];
        callback && undoManager?.off(undoManagerEvent, callback);
      }
    }
    undoManager.clear();
    undoManager.destroy();
  }
};

/**
 * @description If no pinId exists in changedParentTypes, this will fallback to meta data.
 */
function getPinId(event: StackEvent) {
  return getFirstPinIdFromChangedParentTypes(event.changedParentTypes) ?? event.stackItem.meta.get('pin');
}

/**
 * @description Crawls through the entries in changedParentTypes and grabs the first valid pinId match.
 * This is done this way due to limitations with the library.
 * @see https://github.com/yjs/yjs/issues/611
 */
function getFirstPinIdFromChangedParentTypes(changedParentTypes: ChangedParentTypes): string | undefined {
  for (const changedEntries of changedParentTypes) {
    const possiblePinIdEntry = (changedEntries[0]?._map?.get('id')?.content as ContentAny)?.arr;
    if (possiblePinIdEntry && editorStateStore.isValidPinId(possiblePinIdEntry[0])) {
      return possiblePinIdEntry[0];
    }
  }

  return;
}
