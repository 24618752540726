import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCroissant = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="M7.018 6.271c-3.087 1.9-4.71 5.504-4.341 8.953.22 2.061.976 3.67 1.814 4.836.712.992 1.997.33 2.201-.875.288-1.688 1.049-3.533 2.935-4.367m7.355-8.547c3.087 1.9 4.71 5.504 4.341 8.953-.22 2.061-.976 3.67-1.814 4.836-.712.992-1.997.33-2.202-.875-.287-1.688-1.048-3.533-2.934-4.367M3 11.661l5.082 4.28M21 11.66l-5.077 4.28M6.982 6.18l2.427 7.907c.282.919 1.63 1.37 2.591 1.37s2.309-.451 2.59-1.37l2.428-7.906c.143-.465.02-.976-.377-1.257A8.2 8.2 0 0 0 12 3.458a8.2 8.2 0 0 0-4.641 1.467c-.397.281-.52.792-.377 1.257"
    />
  </svg>
);
export default SvgIconCroissant;
