import { BodyText, borderRadius, color, Headline, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementDocument } from '../../../../../types/graphql';
import { mapGraphQLAgreementDocumentToReadableTitle } from '../../utils';
import { RequestAgreementForm, RequestAgreementFormInputs } from '../RequestAgreementForm/RequestAgreementForm';

interface NewAgreementAvailableBlockProps {
  onRequestAgreementSubmit: (data: RequestAgreementFormInputs) => void;
  latestAvailableDocument?: AgreementDocument;
}

export const NewAgreementAvailableBlock: React.FC<NewAgreementAvailableBlockProps> = ({
  onRequestAgreementSubmit,
  latestAvailableDocument,
}) => {
  const { t } = useTranslation('organizationAgreements');

  return (
    <Container>
      <FormInputsWrapper direction="y" spacing={spacing.size12}>
        {latestAvailableDocument && (
          <Headline as="h3" size="small">
            {mapGraphQLAgreementDocumentToReadableTitle(latestAvailableDocument)}
          </Headline>
        )}
        <BodyText>{t('fillInContactDetails')}</BodyText>
        <RequestAgreementForm onSubmit={onRequestAgreementSubmit} hasBeenSentBefore={false} />
      </FormInputsWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded8};
  padding: ${spacing.size24};
`;

const FormInputsWrapper = styled(SpaceBetween)`
  max-width: 30rem;
`;
