import { styled } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { VideoComponentSourceInput } from './VideoComponentSourceInput';

interface VideoComponentModalPopoverContentProps {
  contentOrientation: 'horizontal' | 'vertical';
  handleAddVideo: (url: string) => string | void;
  t: TFunction;
}

export const VideoComponentModalPopoverContent: React.FC<VideoComponentModalPopoverContentProps> = ({
  contentOrientation,
  handleAddVideo,
  t,
}) => {
  return (
    <StyledModalContent contentOrientation={contentOrientation}>
      <VideoComponentSourceInput handleAddVideo={handleAddVideo} t={t} />
    </StyledModalContent>
  );
};

const StyledModalContent = styled.div<{ contentOrientation: 'horizontal' | 'vertical' }>`
  display: flex;
  ${({ contentOrientation }) =>
    contentOrientation === 'horizontal'
      ? `
    flex-direction: row;
  `
      : `
    flex-direction: column;
  `}
`;
