import {
  Button,
  colorSets,
  InputFormField,
  rem,
  SpaceBetween,
  spacing,
  styled,
  TextElement,
  useForm,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React, { useState } from 'react';

interface AddVideoFromLinkFormInput {
  url: string;
}

interface VideoComponentSourceInputProps {
  handleAddVideo: (url: string) => string | void;
  t: TFunction;
}

export const VideoComponentSourceInput: React.FC<VideoComponentSourceInputProps> = ({ handleAddVideo, t }) => {
  const { register, watch } = useForm<AddVideoFromLinkFormInput>();
  const [urlValidationState, setUrlValidationState] = useState<string | null>(null);
  const urlInput = watch('url');

  const addVideoHandler = (url: string) => {
    const result = handleAddVideo(url);
    if (result) {
      setUrlValidationState(result);
    }
  };

  return (
    <StyledVideoComponentSourceInput>
      <SpaceBetween spacing={spacing.size8} direction="y" padding={spacing.size16}>
        <SpaceBetween direction="x" spacing={spacing.size8} alignItems="flex-end">
          <StyledInputField
            {...register('url')}
            type="text"
            placeholder={t('videoPicker.urlInputPlaceholder')}
            label={t('videoPicker.linkLabel')}
            autoComplete="off"
            formFieldSize="small"
          />
          <Button
            buttonType="neutral"
            showStroke
            onClick={() => addVideoHandler(urlInput)}
            type="submit"
            disabled={!urlInput}
          >
            {t('videoPicker.addButton')}
          </Button>
        </SpaceBetween>
        {urlValidationState && (
          <StyledTextElement color="error" textStyle="caption">
            {urlValidationState}
          </StyledTextElement>
        )}
      </SpaceBetween>
    </StyledVideoComponentSourceInput>
  );
};

const StyledVideoComponentSourceInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem('300px')};
`;

const StyledInputField = styled(InputFormField)`
  flex: 1;
`;

const StyledTextElement = styled(TextElement)`
  color: ${colorSets.negative.on};
`;
