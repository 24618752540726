import { HocuspocusProvider, WebSocketStatus } from '@hocuspocus/provider';
import { authTokenStorage, logger, refreshTokens, useLazyDocumentQuery } from '@lessonup/client-integration';
import { parseStatelessMessage } from '@lessonup/editor-shared';
import { SetErrorProps, useErrorContext } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import { compact } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTeacherModernConfig } from '../../../../utils/config/teacherModernConfig';
import { editorStateStore } from '../../context/EditorContext/store/editorStateStore';
import { GetTeacherPinHashesByIdDocument } from '../../Editor.graphql.generated';
import { CollaborationCursor, Collaborator, HocusPocusConnectionStatus, HocusPocusSaveStatus } from './yjs.types';
import { checkHashes } from './yjs.utils';

const DEFAULT_SAVE_INTERVAL = 2000;
const LIMBO_SAVE_INTERVAL = 10000;
const MAX_LIMBO_RETRIES = 30; // 30 * default_interval (60 seconds) should be enough to trigger onDisconnect before this limit is reached
const SAVE_AFTER = 4000;

const useHocusPocusProvider = (documentId: string, onAuthError: () => void) => {
  const { setError } = useErrorContext();
  const { hocuspocusUrl } = useTeacherModernConfig();
  const [providerInit, setInitialized] = useState(false);
  const [provider, setProvider] = useState<HocuspocusProvider | undefined>(undefined);
  const [authAccessToken, setToken] = useState(authTokenStorage.authAccessToken);
  const [connectionState, setConnectionState] = useState<HocusPocusConnectionStatus>(WebSocketStatus.Connected);
  const connectionStateRef = useRef(connectionState);
  const [saveState, setSaveState] = useState<HocusPocusSaveStatus>('saved');
  const saveStateRef = useRef(saveState);
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const saveTimeoutCounter = useRef(0);
  const lastUnsyncedChangesTimestampRef = useRef(0);
  const isForceSaving = useRef(false);
  const onUnsyncedChangesInitialized = useRef(false);
  const disconnectedAtTimestampRef = useRef<string>(null);
  const whenSavedRef = useRef<(() => void)[]>([]);

  if (!hocuspocusUrl) throw new Error("Missing config 'hocuspocusUrl'");

  const [GetTeacherPinHashesById] = useLazyDocumentQuery(GetTeacherPinHashesByIdDocument, {
    variables: {
      input: documentId,
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      logger.error('Failed to get teacher pin hashes', { error });
    },
  });

  // set error has a small bug, it does not memoize
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localSetError = useCallback((props: SetErrorProps) => setError(props), []);

  /**
   * Only logged in users can access the editor, so we only need to refresh the token if the user is expired.
   */
  const refreshAuth = useRef(async () => {
    try {
      await refreshTokens();
      setToken(authTokenStorage.authAccessToken);
    } catch (error) {
      localSetError({ error: new AppError('not-allowed', 'No access to lesson') });
      onAuthError();
    }
  });

  saveStateRef.current = saveState;
  connectionStateRef.current = connectionState;

  const getSavedState = useCallback((): HocusPocusSaveStatus => {
    return saveStateRef.current;
  }, []);

  const whenSaved = useCallback(
    (callback: () => void) => {
      if (getSavedState() === 'saved') {
        callback();
        return;
      }

      whenSavedRef.current.push(callback);
      return () => whenSavedRef.current.filter((c) => c === callback);
    },
    [getSavedState]
  );

  // TODO: The saved state in this file is a bit of a mess, we should clean it up after customer launch
  // This is a workaround to make sure we return a promise instead of a callback
  const waitOnSave = useCallback(() => {
    return new Promise<void>((resolve) => {
      whenSaved(() => {
        resolve();
      });
    });
  }, [whenSaved]);

  const setSaveStateWrapper = (nextState: HocusPocusSaveStatus) => {
    setSaveState(nextState);
    saveStateRef.current = nextState;

    if (nextState === 'saved') {
      whenSavedRef.current?.map((l) => {
        l();
      });
      whenSavedRef.current = [];
    }
  };

  const setConnectionStateWrapper = (nextState: HocusPocusConnectionStatus) => {
    setConnectionState(nextState);
    connectionStateRef.current = nextState;
  };

  function cleanupSaveTimeout() {
    saveTimeoutRef.current && clearTimeout(saveTimeoutRef.current);
  }

  useEffect(() => {
    /**
     * A self invoking timeout that tries to save the document every second, in case the stateless save message is not received or fails.
     * If this keeps failing while the connection is still open, the save state will eventually be set to limbo.
     */
    function setSaveTimeout() {
      const interval = saveStateRef.current === 'limbo' ? LIMBO_SAVE_INTERVAL : DEFAULT_SAVE_INTERVAL;

      saveTimeoutRef.current = setTimeout(async () => {
        const shouldTryToSave = Date.now() > lastUnsyncedChangesTimestampRef.current + SAVE_AFTER;

        if (
          !shouldTryToSave ||
          saveStateRef.current === 'saved' ||
          connectionStateRef.current !== WebSocketStatus.Connected
        ) {
          setSaveTimeout();
          saveTimeoutCounter.current = 0;
          return;
        }

        try {
          const { data } = await GetTeacherPinHashesById({ variables: { input: documentId } });
          const serverHashes = data?.viewer.lesson?.teacherPinHashes;

          if (serverHashes && checkHashes(serverHashes, provider)) {
            setSaveStateWrapper('saved');
          } else {
            saveTimeoutCounter.current++;
            // When the hashes don't get in sync after a longer period of time, it could be that the server persisted a different state
            // This can happen because of race conditions between multiple servers or simply because the last state persistence failed
            // In this case we trigger a force save by setting a random property to a new unique value to make the server do a reattempt
            if (saveTimeoutCounter.current % 10 === 0) {
              provider.document.getMap('forceSave').set('forceSave', Date.now());
              isForceSaving.current = true;
            }
          }
        } catch {
          saveTimeoutCounter.current++;
        }

        if (saveTimeoutCounter.current > MAX_LIMBO_RETRIES) {
          setSaveStateWrapper('limbo');
          logger.warn(`Save state set to limbo after ${MAX_LIMBO_RETRIES} retries`);
        }

        setSaveTimeout();
      }, interval);
    }

    if (!authAccessToken) {
      refreshAuth.current();
    }

    const provider = new HocuspocusProvider({
      url: hocuspocusUrl,
      name: documentId,
      token: authAccessToken,
      onAuthenticationFailed: () => {
        refreshAuth.current();
      },
      onSynced({ state }) {
        if (state) {
          setInitialized(true);
        }
      },
      onAwarenessChange: ({ states }) => {
        editorStateStore.setCollaborators(
          compact(states.map(CollaborationCursorToCollaborator)).filter(
            (c) => c?.clientId !== provider.awareness?.clientID
          )
        );
      },
      onConnect: () => {
        if (connectionStateRef.current === WebSocketStatus.Disconnected) {
          logger.info(`Reconnected to Hocuspocus`, {
            disconnectedAt: disconnectedAtTimestampRef.current,
            connectedAt: new Date().toISOString(),
          });
        }
        setConnectionStateWrapper(WebSocketStatus.Connected);
      },
      onDisconnect: () => {
        disconnectedAtTimestampRef.current = new Date().toISOString();
        logger.trace(
          'connection',
          `Disconnected from Hocuspocus: Disconnected At ${disconnectedAtTimestampRef.current}`
        );

        setConnectionStateWrapper(WebSocketStatus.Disconnected);
      },
      onStateless: ({ payload }) => {
        const message = parseStatelessMessage(payload);
        if (message.type === 'savedHashes' && checkHashes(message.value, provider)) {
          setSaveStateWrapper('saved');
        }
        // We deliberately don't handle the case where the hashes don't match (falling back to the timeout) as it could be that the user has made changes since the last save
      },
    });

    provider.on('unsyncedChanges', (count: number) => {
      // We need to wait for the initial unsynced changes to be processed before we start tracking them
      // This might be a bug in the provider, but it's not clear how to fix it
      if (!onUnsyncedChangesInitialized.current) {
        if (count === 0) {
          onUnsyncedChangesInitialized.current = true;
        }
        return;
      }

      if (isForceSaving.current) {
        // We skip resetting lastUnsyncedChangesTimestampRef a single interval if isForceSaving as it should continue to limbo state if no user interaction happens
        isForceSaving.current = false;
      } else {
        setSaveStateWrapper('unsaved');
        lastUnsyncedChangesTimestampRef.current = Date.now();
      }
    });

    setProvider(provider);
    setSaveTimeout();

    // The provider will stay connected until it is destroyed, even after the editor is unmounted.
    // So we need to make sure to destroy the provider when the editor is unmounted.
    return () => {
      provider?.destroy();
      cleanupSaveTimeout();
    };
  }, [authAccessToken, documentId, hocuspocusUrl, GetTeacherPinHashesById]);

  const initialized = providerInit && !!provider;

  return { provider, initialized, connectionState, saveState, getSavedState, whenSaved, waitOnSave };
};

const CollaborationCursorToCollaborator = (cursor: CollaborationCursor): Collaborator | undefined => {
  if (!cursor.user) return;
  return {
    clientId: cursor.clientId,
    id: cursor.user.id,
    name: cursor.user.name,
    initial: cursor.user.name[0],
    color: cursor.user.color,
    pinId: cursor.location?.pinId || null,
    componentId: cursor.location?.componentId || [],
    uploads: cursor.uploads || [],
  };
};

export const useEditorHocusPocusProvider = (documentId: string, onAuthError: () => void) => {
  return useHocusPocusProvider(documentId, onAuthError);
};

export const usePlayerHocusPocusProvider = (documentId: string, onAuthError: () => void) => {
  return useHocusPocusProvider(`player:${documentId}`, onAuthError);
};
