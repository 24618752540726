import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21a9 9 0 0 0 9-9m-9 9a9 9 0 0 1-9-9m9 9c2.08-2.557 3.461-4.671 3.461-9 0-4.589-1.382-6.443-3.461-9m0 18c-2.08-2.557-3.462-4.671-3.462-9 0-4.541 1.383-6.443 3.462-9m9 9a9 9 0 0 0-9-9m9 9H3m9-9a9 9 0 0 0-9 9"
    />
  </svg>
);
export default SvgIconGlobe;
