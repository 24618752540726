import { ApolloError, useLazyDocumentQuery } from '@lessonup/client-integration';
import { useCallback, useEffect, useId } from 'react';
import { useCounter } from 'usehooks-ts';
import { UploadStatusDocument } from '../Uploads.graphql.generated';
import { StatusPollingController } from './useUploadStatusPolling.utils';

const STOP_ERROR_LIMIT = 25;

export interface UseUploadsStatusPolling {
  startPollingForIds: (ids: string[]) => void;
  stopPolling: (ids?: string[]) => void;
}

export interface useUploadsStatusPollingProps {
  onError: (error: Error) => void;
}

/**
 * Singleton used by all instances of useUploadsStatusPolling-hook,
 * to make sure that only one polling sequence is running at a time, so we don't overload the backend.
 */
const statusPolling = new StatusPollingController();

/**
 * @description Prepares a way to refresh the uploads by poll interval.
 * By fetching the items, the upload overview should automatically refresh because of how Apollo updates the cache.
 */
export function useUploadStatusPolling({ onError }: useUploadsStatusPollingProps): UseUploadsStatusPolling {
  // We use setTimeout to ensure that the next fetch is only executed after the completion of the previous one.

  const id = useId();
  const errorCount = useCounter(0);

  const [fetchUploadStatus, { data }] = useLazyDocumentQuery(UploadStatusDocument, {
    fetchPolicy: 'network-only',
    onError: handleError,
  });

  function handleError(error: ApolloError) {
    if (error.message === 'AbortError' || error.name === 'AbortError') {
      // We can ignore this error. see: https://github.com/apollographql/apollo-client/issues/10520
      return;
    }
    if (error.networkError) {
      errorCount.increment();
      const wasLimitReached = errorCount.count >= STOP_ERROR_LIMIT;

      if (wasLimitReached) {
        statusPolling.unregister(id);
        onError(error);
      }
      return;
    }
    onError(error);
  }

  // Cleanup useEffect on unmount.
  useEffect(() => {
    return () => statusPolling.unregister(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Poll for status of uploads which are not yet completed.
   * Stop polling of uploads which errored or completed.
   */
  useEffect(() => {
    if (data) {
      const uploadsFromData = data.viewer.uploadsByIds;
      const finishedUploads = uploadsFromData.filter((upload) => ['ERROR', 'READY'].includes(upload.status));
      const unfinishedUploads = uploadsFromData.filter((upload) => !['ERROR', 'READY'].includes(upload.status));
      if (finishedUploads.length > 0) {
        statusPolling.stop(finishedUploads.map((upload) => upload.id));
      }
      if (unfinishedUploads.length > 0) {
        statusPolling.start({
          hookId: id,
          fetchUploadStatus,
          uploadIds: unfinishedUploads.map((upload) => upload.id),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Initiate polling for the given upload ids.
   */
  const startPollingForIds = useCallback((newIds: string[]) => {
    statusPolling.start({
      hookId: id,
      fetchUploadStatus,
      uploadIds: newIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopPolling = useCallback((pollingIds?: string[]) => {
    statusPolling.stop(pollingIds);
  }, []);

  return {
    startPollingForIds,
    stopPolling,
  };
}
