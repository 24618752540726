import { BodyText, color, List, ListItemProps, SpaceBetween, spacing, styled, Switch } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionalLessonSettings } from '../model';

interface OptionalLessonSettingsListProps {
  currentSettings: OptionalLessonSettings;
  setOptionalLessonSettings: React.Dispatch<React.SetStateAction<OptionalLessonSettings>>;
  showSetting?: OptionalLessonSettings;
}

export const OptionalLessonSettingsList: React.FC<OptionalLessonSettingsListProps> = ({
  currentSettings,
  setOptionalLessonSettings,
  showSetting = {
    enableTeacherNotes: false,
    enablePreviousKnowledge: true,
    enableExitTicket: true,
    enableLearningPhase: false,
  },
}) => {
  const { t } = useTranslation('chatGPT');
  const updatedSettings = { ...currentSettings };

  const handleLearningPhase = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatedSettings.enableLearningPhase = event.target.checked;
    setOptionalLessonSettings(updatedSettings);
  };

  const handleTeacherNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatedSettings.enableTeacherNotes = event.target.checked;
    setOptionalLessonSettings(updatedSettings);
  };

  const handlePreviousKnowledge = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatedSettings.enablePreviousKnowledge = event.target.checked;
    setOptionalLessonSettings(updatedSettings);
  };

  const handleExitTicket = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatedSettings.enableExitTicket = event.target.checked;
    setOptionalLessonSettings(updatedSettings);
  };

  const teacherNotesSetting: ListItemProps = {
    text: t('teacherNotes'),
    secondLine: t('teacherNotesOption'),
    endSlot: (
      <Switch
        onChange={handleTeacherNotes}
        id="teacher-notes"
        ariaLabel="Toggle teacher notes option"
        defaultChecked={currentSettings.enableTeacherNotes}
      ></Switch>
    ),
  };

  const learningPhaseSetting: ListItemProps = {
    text: t('learningPhases'),
    secondLine: t('learningPhasesOption'),
    endSlot: (
      <Switch
        onChange={handleLearningPhase}
        id="learning-phases"
        ariaLabel="Toggle learning phases option"
        defaultChecked={currentSettings.enableLearningPhase}
      ></Switch>
    ),
  };

  const previousKnowledgeSetting: ListItemProps = {
    text: t('previousKnowledge'),
    secondLine: t('previousKnowledgeOption'),
    endSlot: (
      <Switch
        onChange={handlePreviousKnowledge}
        id="previous-knowledge"
        ariaLabel="Toggle previous knowledge option"
        defaultChecked={currentSettings.enablePreviousKnowledge}
      ></Switch>
    ),
  };

  const exitTicketSetting: ListItemProps = {
    text: t('exitTicket'),
    secondLine: t('exitTicketOption'),
    endSlot: (
      <Switch
        onChange={handleExitTicket}
        id="exit-ticket"
        ariaLabel="Toggle exit ticket option"
        defaultChecked={currentSettings.enableExitTicket}
      ></Switch>
    ),
  };

  const listItems: ListItemProps[] = [];

  if (showSetting.enableTeacherNotes) {
    listItems.push(teacherNotesSetting);
  }

  if (showSetting.enableLearningPhase) {
    listItems.push(learningPhaseSetting);
  }

  if (showSetting.enablePreviousKnowledge) {
    listItems.push(previousKnowledgeSetting);
  }

  if (showSetting.enableExitTicket) {
    listItems.push(exitTicketSetting);
  }

  return (
    <SpaceBetween direction="y" spacing={spacing.size16}>
      <div>
        <BodyText weight="bold">{t('optionalSettings')}</BodyText>
        <StyledSubText size="small">{t('optionalSettingsSubText')}</StyledSubText>
      </div>
      <List items={listItems}></List>
    </SpaceBetween>
  );
};

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
