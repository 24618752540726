import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconNote = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.782 17.034a4.096 4.096 0 1 1-8.193 0 4.096 4.096 0 0 1 8.193 0m0 0V2.87c.587 0 1.17.02 1.748.052 3.616.205 6.667 3.2 6.846 6.817q.034.667.034 1.341"
    />
  </svg>
);
export default SvgIconNote;
