import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { AssetLogoRocket } from '../assets';
import { BodyText } from '../BodyText/BodyText';

export interface LoadingRocketProps {
  description?: string;
}

export function LoadingRocket(props: LoadingRocketProps) {
  return (
    <StyledWrapper direction="y" spacing={spacing.size8} alignItems="center">
      <StyledRocketBackground>
        <StyledRocket />
      </StyledRocketBackground>
      {props.description && <StyledDescription size="small">{props.description}</StyledDescription>}
    </StyledWrapper>
  );
}

const StyledWrapper = styled(SpaceBetween)`
  color: ${color.additional.disabled.text};
`;

const StyledRocketBackground = styled.div`
  background: ${color.additional.disabled.background};

  border-radius: ${rem('80px')};
  width: ${rem('120px')};
  height: ${rem('160px')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledDescription = styled(BodyText)`
  width: ${rem('200px')};
  text-align: center;
`;

const launchKeyframe = keyframes`
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(175%, -175%);
  }

  50% {
    transform: translate(-175%, -175%);
  }
  66% {
    transform: translate(-175%, 175%);
  }

  100% {
    transform: translate(0, 0);
  }
`;

const StyledRocket = styled(AssetLogoRocket)`
  animation: ${launchKeyframe} 2s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite;
`;
