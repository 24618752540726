import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.595 12.013a3.598 3.598 0 0 0 1.278-6.963M13.511 20.5h5.456c2.115 0 3.303-2.26 1.543-3.433a10 10 0 0 0-1.543-.841M9.541 11.3a3.9 3.9 0 1 0 0-7.801 3.9 3.9 0 0 0 0 7.8m5.974 5.48c1.908 1.27.62 3.721-1.672 3.721H5.234c-2.292 0-3.58-2.45-1.673-3.721a10.75 10.75 0 0 1 5.977-1.804c2.21 0 4.266.664 5.977 1.804"
    />
  </svg>
);
export default SvgIconPeople;
