/**
 * Strips HTML tags from a given string using regex.
 * Don't use this for sanitization purposes!
 */
export function naiveStripHTML(html: string, options?: { keepNewLines: boolean }): string {
  const string = options?.keepNewLines ? replaceBreaksByNewlines(html) : html;
  return string
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
}

function replaceBreaksByNewlines(html: string): string {
  // eslint-disable-next-line no-useless-escape
  return html.replace(/<\s*\/?br\s*[\/]?>/gi, '\n');
}
