import type { WebAppName, WebAppToUrlMap } from '@lessonup/config-environment-shared';
import { BuildInfoConfigSchema } from '@lessonup/config-shared';
import { App as ModelApp, checkMeteorApp, Environment as ModelEnvironment } from '@lessonup/utils-support';
import { z } from 'zod';
import { stripLeadingSlash, stripTrailingSlash } from '../string/StripSlash';
import { PartialRecord } from '../typescript';
import { versionFromReleaseTag } from './version';

// proxy types to avoid larger refactoring
export type App = ModelApp;
export type Environment = ModelEnvironment;
export type Urls = WebAppToUrlMap;

// TODO: infer this instead?
export type BuildInfo = z.infer<typeof BuildInfoConfigSchema>;

interface AppAssetsRoute extends Record<string, string> {
  teacher: string;
}

const appAssetsRoute: PartialRecord<Environment | 'default', AppAssetsRoute> = {
  default: {
    teacher: '/img/teacher-modern/assets/',
  },
};

export type ConfigParams = {
  app: App;
  env: Environment;
  shadowMode?: boolean;
  urls: Urls;
  runsOnLocalhost?: boolean;
  build: BuildInfo;
};

type UrlResolutionParams = {
  app: App;
};

export class Config {
  public readonly app: App;
  public readonly env: Environment;
  public readonly shadowMode: boolean;
  // Indicates the app is being served from localhost. Doesn't necessarily mean we are connected to the local env.
  public readonly runsOnLocalhost?: boolean;
  public readonly urls: Urls;

  // If true, we are connected to a local backend.
  public readonly isLocal: boolean;
  public readonly isDev: boolean;
  public readonly isLive: boolean;
  public readonly isTest: boolean;

  public readonly isTeacher: boolean;
  public readonly isStudent: boolean;
  public readonly isSearch: boolean;
  public readonly isMeteorApp: boolean;

  public readonly teacherUrl: string;
  public readonly studentUrl: string;
  public readonly adminUrl: string;
  public readonly apiUrl: string;
  public readonly searchUrl: string;
  public readonly searchApiUrl: string;
  public readonly siteUrl: string;

  public readonly versionTagName: string;
  public readonly version: string;
  private readonly _teacherCookieDomain: string;
  private static _instance: Config;

  public constructor(settings: ConfigParams, meteorAbsoluteUrl?: string) {
    this.app = settings.app;
    this.env = settings.env;
    this.shadowMode = !!settings.shadowMode;
    this.urls = settings.urls;

    const isLive = ['live', 'staging', 'production'].includes(this.env);
    const isTest = this.env === 'test';
    const isLocal = this.env === 'local';

    const isDev = !isLive && !isTest && !isLocal;

    this.isLocal = isLocal;
    this.isDev = isDev;
    this.isLive = isLive;
    this.isTest = isTest;
    this.runsOnLocalhost = settings.runsOnLocalhost;

    this.isTeacher = this.app === 'teacher';
    this.isStudent = this.app === 'student';
    this.isSearch = this.app === 'search';
    this.isMeteorApp = checkMeteorApp(this.app);

    function absoluteUrlOrThrow() {
      if (!meteorAbsoluteUrl) {
        throw new Error('meteorAbsoluteUrl is not provided and needed for Meteor applications');
      }
      return meteorAbsoluteUrl.toString();
    }

    this.teacherUrl = this.app === 'teacher' ? absoluteUrlOrThrow() : this.urls.teacher;
    this.studentUrl = this.app === 'student' ? absoluteUrlOrThrow() : this.urls.student;
    this.adminUrl = this.app === 'admin' ? absoluteUrlOrThrow() : this.urls.admin;

    this.searchUrl = this.urls.search;
    this.apiUrl = this.urls.api;
    this.searchApiUrl = `${this.urls.search}search/api/`;
    this.siteUrl = this.urls.site;

    this.versionTagName = settings.build.tag;
    this.version = versionFromReleaseTag(this.versionTagName);
    this._teacherCookieDomain = ''; // Leaving the domain empty will automatically set it to the current domain and avoid subdomains

    console.log(`EnvConfig: Started APP: ${this.app} with ENV: ${this.env}, version: ${this.versionTagName}`);
    Config._instance = this;
  }

  public static init(publicSettings: ConfigParams, meteorAbsoluteUrl?: string, force = false): Config {
    if (!Config._instance || force) {
      Config._instance = new Config(publicSettings, meteorAbsoluteUrl);
    }
    return Config._instance;
  }

  public static forceInit(publicSettings: ConfigParams, meteorAbsoluteUrl?: string): Config {
    return Config.init(publicSettings, meteorAbsoluteUrl, true);
  }

  public static get instance(): Config {
    if (!Config._instance) {
      throw new Error('Config not initialized');
    }
    return Config._instance;
  }

  public appUrl(app: WebAppName, path: string): string {
    return `${this.urls[app]}${stripLeadingSlash(path)}`;
  }

  public assetUrl(path: string, options: UrlResolutionParams = { app: this.app }): string {
    const app = options.app as WebAppName;
    if (!(app in this.urls)) {
      throw new Error(`App ${app} not in urls`);
    }

    const assetsRoute = this.getAssetRoute(options.app);
    const assetsPath = `${stripTrailingSlash(assetsRoute)}/${stripLeadingSlash(path)}`;
    if (options.app === this.app) {
      return assetsPath;
    }

    return `${stripTrailingSlash(this.urls[app])}/${stripLeadingSlash(assetsPath)}`;
  }

  public teacherCookieDomain() {
    return this._teacherCookieDomain;
  }

  public secureCookies() {
    return this.env !== 'local';
  }

  private getAssetRoute(app: App): string {
    const envSettings = appAssetsRoute[this.env];
    const defaultSettings = appAssetsRoute.default;
    if (!envSettings && !defaultSettings) {
      throw new Error(`No asset route settings for ${app} in ${this.env}`);
    }
    const route = (envSettings && envSettings[app]) ?? (defaultSettings && defaultSettings[app]);
    if (!route) {
      throw new Error(`No asset route defined for ${app} in ${this.env}`);
    }
    return route;
  }
}
