import { tracker } from '@lessonup/analytics';
import {
  copyTextToClipboard,
  copyToClipboard,
  readBlobFromClipboardAsText,
  readTextFromClipboard,
} from '@lessonup/client-integration';
import { decodeYjsPinComponents, decodeYjsPins } from '@lessonup/editor-shared';
import {
  isAnswerAreaPinComponent,
  Pin,
  PinComponent,
  PinComponentSchema,
  PinId,
  PinSchema,
  TeacherPin,
  UpdatePinDispatch,
} from '@lessonup/pin-renderer';
import { clonePins } from '@lessonup/pins-shared';
import { groupBy, unescape } from 'lodash';
import { Doc as YDoc } from 'yjs';
import { pinTypeToTrackerType } from '../utils/pin/pinList.utils';
import { clonePinComponentsAndStagger } from '../utils/pinComponent/clone';
import { trackPinComponentCreate } from '../utils/pinComponent/pinComponent.utils';

type PinAllowedValidator = (pins: Pin[]) => boolean;

export function pinsAreValid(pins: Pin[] | undefined, validator?: PinAllowedValidator): boolean {
  if (!pins) return false;

  try {
    pins.map((pin: Pin) => PinSchema.parse(pin));
    if (validator && !validator(pins)) {
      return false;
    }
  } catch {
    return false;
  }
  return true;
}

type PinComponentAllowedValidator = (pins: PinComponent[]) => boolean;

function pinComponentsAreValid(
  pinComponents: PinComponent[] | undefined,
  validator?: PinComponentAllowedValidator
): boolean {
  if (!pinComponents) return false;

  try {
    pinComponents.map((pinComponent: PinComponent) => PinComponentSchema.parse(pinComponent));
    if (validator && !validator(pinComponents)) {
      return false;
    }
  } catch {
    return false;
  }
  return true;
}

export const canCopyPastePinComponents: PinComponentAllowedValidator = (pinComponents) => {
  return pinComponents.some((pinComponent) => !isAnswerAreaPinComponent(pinComponent));
};

export function isValidEditorJsonContent(data: string): boolean {
  try {
    const parsedData = JSON.parse(data);
    if (pinsAreValid(parsedData.pins) || pinComponentsAreValid(parsedData.pinComponents)) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

function copyToClipboardWithFallback(copyString: string): Promise<void> {
  try {
    return copyToClipboard('text/html', copyString);
  } catch (e) {
    return copyTextToClipboard(copyString);
  }
}

export async function copyYjsPinComponentsToClipboard(
  yDoc: YDoc,
  pinId: PinId,
  pinComponents?: PinComponent[]
): Promise<void> {
  if (!pinComponents || !canCopyPastePinComponents(pinComponents)) {
    return;
  }

  return copyPinComponentsToClipboard(decodeYjsPinComponents(yDoc, pinId, pinComponents));
}

export async function copyPinComponentsToClipboard(pinComponents?: PinComponent[]): Promise<void> {
  if (!pinComponents || !canCopyPastePinComponents(pinComponents)) {
    return;
  }

  return copyToClipboardWithFallback(JSON.stringify({ pinComponents }));
}

export async function copyYjsPinsToClipboard(yDoc: YDoc, pins?: Pin[]): Promise<void> {
  if (!pins) {
    return;
  }

  return copyPinsToClipboard(decodeYjsPins(yDoc, pins));
}

export async function copyPinsToClipboard(pins?: Pin[]): Promise<void> {
  if (!pins) {
    return;
  }

  return copyToClipboardWithFallback(JSON.stringify({ pins }));
}

type JsonParseReviver = Parameters<typeof JSON.parse>[1];

const textPinComponentReviver: JsonParseReviver = (key, value) =>
  key === 'text' && typeof value === 'string' ? unescape(value) : value;

interface pastePinsOrPinComponentsFromClipboardProps {
  dispatch: UpdatePinDispatch;
  afterPinId?: string;
  lessonId?: string;
  pin?: Pin;
  afterPaste?: (pinIds: string[]) => void;
}

//TODO  ED-690 - cleanup prop dependencies here so we don't need to pass lessonId, pin, and afterPinId (and afterpaste?)
export async function pastePinsOrPinComponentsFromClipboard({
  afterPinId,
  dispatch,
  lessonId,
  pin,
  afterPaste,
}: pastePinsOrPinComponentsFromClipboardProps) {
  const data = await readFromClipboardWithFallback();
  try {
    const parsedData = JSON.parse(data, textPinComponentReviver);
    if (lessonId && pinsAreValid(parsedData.pins)) {
      const clonedPins = clonePins(parsedData.pins);
      pastePinsFromClipboard(dispatch, afterPinId, lessonId, clonedPins, afterPaste);
    } else if (pin && pinComponentsAreValid(parsedData.pinComponents, canCopyPastePinComponents)) {
      const clonedPinComponents = clonePinComponentsAndStagger(parsedData.pinComponents, pin);
      pastePinComponentsFromClipboard(dispatch, clonedPinComponents, afterPaste);
    }
  } catch {
    return;
  }
}

export async function pastePinsFromClipboard(
  dispatch: UpdatePinDispatch,
  afterPinId: string | undefined,
  lessonId: string,
  clonedPins: TeacherPin[],
  afterPaste?: (pinIds: string[]) => void
) {
  // This could send a lot of single events, we think that for pasting pins this is fine for now
  clonedPins.forEach((pin) => {
    tracker.events.pinCreate({
      lessonId,
      pinType: pinTypeToTrackerType(pin.type),
      pinCreationMethod: 'paste_slide',
      editorVersion: 'v2',
    });
  });

  dispatch({
    type: 'addPins',
    pins: clonedPins,
    afterPinId,
    onComplete: (pinIds) => afterPaste?.(pinIds),
    source: 'pastePins',
  });
}

export function pastePinComponentsFromClipboard(
  dispatch: UpdatePinDispatch,
  clonedPinComponents: PinComponent[],
  afterPaste?: (newPinComponentIds: string[]) => void
) {
  const groupedPinComponents = groupBy(clonedPinComponents, 'type');
  Object.entries(groupedPinComponents).forEach(([pinComponentType, components]) => {
    trackPinComponentCreate(pinComponentType, 'paste_component', components.length);
  });

  dispatch({
    type: 'addPinComponents',
    pinComponents: clonedPinComponents,
    source: 'pastePinComponents',
  });
  afterPaste?.(clonedPinComponents.map((pinComponent) => pinComponent.id));
}

export async function readFromClipboardWithFallback(): Promise<string> {
  try {
    const data = await readBlobFromClipboardAsText('text/html');
    return parseHtmlClipboardData(data);
  } catch {
    return await readTextFromClipboard();
  }
}

export function parseHtmlClipboardData(data: string) {
  return RegExp(/\{(.)*\}/).exec(data)?.[0] ?? '';
}
