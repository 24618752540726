import { Box, newBox, newVector, Size, Vector } from '@lessonup/pins-shared';
import { addVectors, rotateVector, subtractVectors } from '../../utils';

export interface Rectangle {
  position: Vector;
  size: Size;
}

/**
 * Adjusts the box's position to represent the top-left corner after rotation.
 *
 * In the standard box representation, rotation occurs around the center of the
 * box, and the `position` property denotes the position of the top-left corner
 * before rotation. As a result, all corners -- including the top-left -- move
 * with the rotation, and the `position` property may no longer represent one of
 * the final corner positions. This makes it harder to map the box's position to
 * different coordinate spaces, which is important for image cropping.
 *
 * The returned box has the same visual representation (location, rotation) in
 * the canvas, but its `position` property now represents the top-left corner
 * after rotation.
 */
export function boxAfterRotation(box: Box): Box {
  // The position of the top-left corner for a box centered around the origin
  // (ignoring the box's `position` property).
  const topLeft = newVector(-box.size.width / 2, -box.size.height / 2);

  // The position of the top-left corner after rotation.
  const rotated = rotateVector(topLeft, box.rotation);

  // The position of the top-left corner at the box's actual position in the
  // canvas, after rotation.
  const adjusted = addVectors(subtractVectors(box.position, topLeft), rotated);

  return newBox(adjusted, box.size, box.rotation);
}

/**
 * Converts a box back to its standard representation, undoing the effects of
 * `boxAfterRotation`.
 */
export function boxBeforeRotation(box: Box): Box {
  // The position of the top-left corner for a box centered around the origin.
  const topLeft = newVector(-box.size.width / 2, -box.size.height / 2);

  // The position of the top-left corner after rotation.
  const rotated = rotateVector(topLeft, box.rotation);

  // The position of the top-left corner at the box's actual position in the
  // canvas, before rotation.
  const original = addVectors(subtractVectors(box.position, rotated), topLeft);

  return newBox(original, box.size, box.rotation);
}
