import { DataLoader } from '@lessonup/client-integration';
import {
  createModal,
  LoadingRocket,
  ManagedModal,
  NiceModal,
  rem,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralModalContent } from '../components/ReferralModalContent/ReferralModalContent';
import { useReferral } from '../hooks/useReferral';

type ReferralModalProps = {
  baseURL: string;
};

const TRANSLATION_NAMESPACE = 'teacherReferralModal';

export const ReferralModal = createModal((props: ReferralModalProps) => {
  const modal = useModal();
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const { data, loading, error } = useReferral({ baseUrl: props.baseURL });

  return (
    <ManagedModal modal={modal} contentLabel={t('title')} width={rem('380px')}>
      <DataLoader
        data={data}
        error={error}
        loading={loading}
        customLoader={
          <LoadingRocketWrapper>
            <LoadingRocket />
          </LoadingRocketWrapper>
        }
        dataRenderer={(data) => (
          <ReferralModalContent
            amountOfExtraTrialDays={data.amountOfExtraTrialDays}
            link={data.link}
            onClose={modal.hide}
            token={data.token}
          />
        )}
      ></DataLoader>
    </ManagedModal>
  );
});

const LoadingRocketWrapper = styled.div`
  margin-top: ${spacing.size64};
  margin-bottom: ${spacing.size64};
`;

export const showTeacherReferralModal = (props: ReferralModalProps) => NiceModal.show(ReferralModal, props);
