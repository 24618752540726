import { BodyText, FilePreview, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { getImageFileNameFromUrl } from '../../../../utils/pinComponentSettings/imagePinComponentSettings';

interface ImagePreviewProps {
  url: string;
  name?: string;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ url, name }) => {
  const previewName = name || getImageFileNameFromUrl(url);
  return (
    <StyledImagePreviewContainer>
      <FilePreview type="image" src={url} alt="image preview" outlined={true} />
      <StyledImageName size="small">{previewName}</StyledImageName>
    </StyledImagePreviewContainer>
  );
};

const StyledImagePreviewContainer = styled.div`
  display: flex;
  gap: ${spacing.size8};

  figure {
    position: relative;
    width: 32px;
    flex-shrink: 0;
  }
`;

const StyledImageName = styled(BodyText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
