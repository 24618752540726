import { mostEncounteredValue } from '@lessonup/utils';
import _ from 'lodash';
import { PinEntry } from '../../entries';
import { UserReference, UserReferenceAndPinEntry } from '../UserReference';
import { Wordweb } from './Wordweb';

export interface WordSummary {
  count: number;
  word: string;
  pretty: string;
  users: UserReference[];
  variationCount: number;
}

export interface WordwebResults {
  words: WordSummary[];
}

export namespace WordwebResults {
  export function fromUserReferenceAndAnswer(entries: UserReferenceAndPinEntry<PinEntry.Wordweb>[]): WordwebResults {
    const answerValues = _.flatMap(entries, (entry, index) => {
      return Wordweb.Answer.words(entry && entry.pinEntry && entry.pinEntry.answer).map((answer) => ({
        ...answer,
        user: entry.user, // TODO get real user id here
      }));
    });

    // group all values by the normalized values
    const groupedByStripped = _.groupBy(answerValues, (value) => value.normalized);

    const words = _.map(groupedByStripped, (values, key) => {
      const mostGivenAnswer = mostEncounteredValue(_.map(values, (value) => value.source));

      return {
        count: values.length,
        word: key,
        pretty: mostGivenAnswer || '',
        users: values.map((value) => value.user),
        variationCount: _.size(_.groupBy(values)),
      };
    });
    return { words };
  }
}
