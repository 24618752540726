import { Box, Deg, Line, newBox, newLine, newSize, newVector, PinComponent, Size, Vector } from '@lessonup/pins-shared';
import { GrippyPosition, isLineLayout } from '../../types';
import { doLineSegmentsIntersect } from './line';
import { perpendicularIntersectionBetweenLineAndPoint } from './math';
import { enforceMinimumSize, scaleSize } from './size';
import { addVectors, divideVector, rotateVector, subtractVectors, vectorDirection } from './vector';

export function updateBox(box: Box, updatedBox: Partial<Box>): Box {
  return {
    ...box,
    ...updatedBox,
  };
}

/** Create a minumum bounding that encloses every given point */
export function boundingBoxFromPoints(points: Vector[]): Box {
  if (points.length === 0) {
    return newBox(newVector(0, 0), newSize(0, 0));
  }

  const allPointsX = points.map((point) => point.x);
  const allPointsY = points.map((point) => point.y);
  const minX = Math.min(...allPointsX);
  const maxX = Math.max(...allPointsX);
  const minY = Math.min(...allPointsY);
  const maxY = Math.max(...allPointsY);
  const position = newVector(minX, minY);
  const size = newSize(maxX - minX, maxY - minY);
  return newBox(position, size);
}

/**
 * Given a Line it will create a Box layout.
 * With the position and size of the box being the minimum bounding box that encloses the line.
 */
export function boxFromLine(line: Line): Box {
  const position = newVector(Math.min(line.start.x, line.end.x), Math.min(line.start.y, line.end.y));
  const size = newSize(Math.abs(line.start.x - line.end.x), Math.abs(line.start.y - line.end.y));
  return newBox(position, size);
}

export function moveBox(box: Box, displacement: Vector): Box {
  const newPosition = addVectors(box.position, displacement);
  return updateBox(box, { position: newPosition });
}

export function resizeBox(
  box: Box,
  deltaMouse: Vector,
  grippy: GrippyPosition,
  keepAspectRatio = false,
  minSize: Size
): Box {
  const displacement = calculateGrippyDisplacement(box, deltaMouse, grippy, keepAspectRatio);
  return applyGrippyDisplacement(box, displacement, grippy, minSize, keepAspectRatio);
}

/**
 * This function calculates the displacement of the grippy relative towards its resize direction.
 * When resizing from the sides, we only want to resize in one direction.
 * When resizing with keepAspectRatio, we want to resize in the direction of the corner grippy.
 */
export function calculateGrippyDisplacement(
  box: Box,
  deltaMouse: Vector,
  grippy: GrippyPosition,
  keepAspectRatio: boolean
): Vector {
  // Note that this function currently only supports scaling with keepAspectRatio using the corner grippies.
  if (grippy === 'top-left' || grippy === 'bottom-left' || grippy === 'top-right' || grippy === 'bottom-right') {
    if (keepAspectRatio) {
      const vertices = boxRotatedVertices(box);
      const centerPoint = boxCenterPosition(box);
      const resizeDirection =
        grippy === 'top-left'
          ? subtractVectors(vertices[0], centerPoint)
          : grippy === 'top-right'
          ? subtractVectors(vertices[1], centerPoint)
          : grippy === 'bottom-right'
          ? subtractVectors(vertices[2], centerPoint)
          : subtractVectors(vertices[3], centerPoint);
      return perpendicularIntersectionBetweenLineAndPoint(resizeDirection, deltaMouse);
    }
    return deltaMouse;
  }
  const resizeDirection =
    grippy === 'top'
      ? rotateVector(newVector(0, -1), box.rotation)
      : grippy === 'right'
      ? rotateVector(newVector(1, 0), box.rotation)
      : grippy === 'bottom'
      ? rotateVector(newVector(0, 1), box.rotation)
      : rotateVector(newVector(-1, 0), box.rotation);
  return perpendicularIntersectionBetweenLineAndPoint(resizeDirection, deltaMouse);
}

/**
 * This function calculates the new layout of the box when the grippy is moved.
 */
function applyGrippyDisplacement(
  box: Box,
  displacement: Vector,
  grippy: GrippyPosition,
  minSize: Size,
  keepAspectRatio: boolean
): Box {
  const xDirection = grippy === 'top-left' || grippy === 'bottom-left' || grippy === 'left' ? -1 : 1;
  const yDirection = grippy === 'top-left' || grippy === 'top-right' || grippy === 'top' ? -1 : 1;
  // Unrotating the displacement vector allows us to calculate the new width and height of the box.
  let unrotatedDisplacement = rotateVector(displacement, -box.rotation);

  const width =
    grippy === 'top' || grippy === 'bottom' ? box.size.width : box.size.width + xDirection * unrotatedDisplacement.x;
  const height =
    grippy === 'left' || grippy === 'right' ? box.size.height : box.size.height + yDirection * unrotatedDisplacement.y;
  let size = newSize(width, height);

  // If the new width or height is smaller than the minimum size, we need to adjust the displacement vector.
  if (width < minSize.width || height < minSize.height) {
    size = enforceMinimumSize(box.size, size, minSize, keepAspectRatio);
    unrotatedDisplacement = newVector(
      xDirection * (size.width - box.size.width),
      yDirection * (size.height - box.size.height)
    );
    displacement = rotateVector(unrotatedDisplacement, box.rotation);
  }
  // The new center of the box is the old center plus half the displacement vector.
  const newCenter = addVectors(boxCenterPosition(box), divideVector(displacement, 2));
  // The new position of the box is the new center minus half the size of the box.
  const position = newVector(newCenter.x - size.width / 2, newCenter.y - size.height / 2);
  return updateBox(box, { position: position, size: size });
}

export function rotateBox(box: Box, theta: Deg, rotateInStepsOf?: number | undefined): Box {
  return updateBox(box, {
    rotation: rotateInStepsOf ? Math.round(theta / rotateInStepsOf) * rotateInStepsOf : theta,
  });
}

export function calculateRotationAngle(box: Box, mousePosition: Vector): Deg {
  const grippyAngleOffset = 90;
  const centerPoint = boxCenterPosition(box);
  const angleInDeg = vectorDirection(subtractVectors(mousePosition, centerPoint));
  return angleInDeg + grippyAngleOffset;
}

export function boxCenterPosition({ position, size }: Box): Vector {
  return newVector(position.x + size.width / 2, position.y + size.height / 2);
}

export function boxIntersectionWithLineSegment(box: Box, lineSegment: Line): boolean {
  if (isPointInBox(box, lineSegment.start)) return true;
  if (isPointInBox(box, lineSegment.end)) return true;
  const vertices = boxRotatedVertices(box);

  return (
    doLineSegmentsIntersect(lineSegment, newLine(vertices[0], vertices[1])) ||
    doLineSegmentsIntersect(lineSegment, newLine(vertices[1], vertices[2])) ||
    doLineSegmentsIntersect(lineSegment, newLine(vertices[2], vertices[3])) ||
    doLineSegmentsIntersect(lineSegment, newLine(vertices[3], vertices[0]))
  );
}

export function boxIntersectionWithBox(box1: Box, box2: Box): boolean {
  const vertices = boxRotatedVertices(box2);
  const boxLineSegments: Line[] = [
    newLine(vertices[0], vertices[1]),
    newLine(vertices[1], vertices[2]),
    newLine(vertices[2], vertices[3]),
    newLine(vertices[3], vertices[0]),
  ];

  return boxLineSegments.some((lineSegment) => boxIntersectionWithLineSegment(box1, lineSegment));
}

export function isPointInBox(box: Box, point: Vector): boolean {
  const { position, size } = box;
  if (
    point.x >= position.x &&
    point.x <= position.x + size.width &&
    point.y >= position.y &&
    point.y <= position.y + size.height
  ) {
    return true;
  } else {
    return false;
  }
}

export function scaleBoxInGroup(box: Box, groupBox: Box, resizedGroupBox: Box, scalar: number): Box {
  const size = scaleSize(box.size, scalar);
  const localPositionX = box.position.x - groupBox.position.x;
  const localPositionY = box.position.y - groupBox.position.y;
  const position = newVector(
    resizedGroupBox.position.x + localPositionX * scalar,
    resizedGroupBox.position.y + localPositionY * scalar
  );
  return updateBox(box, { position, size });
}

/**
 * A vertex represents a corner of a box.
 * This function returns the vertices of a box, taking into account the rotation of the box.
 * The order of the returned vertices is clockwise, starting from the top left.
 */
export function boxRotatedVertices(box: Box): Vector[] {
  return box.rotation === 0
    ? boxUnrotatedVertices(box)
    : boxUnrotatedVertices(box).map((vertex) => {
        const center = boxCenterPosition(box);
        const localVertice = subtractVectors(vertex, center);
        const rotatedVertice = rotateVector(localVertice, box.rotation);
        return addVectors(rotatedVertice, center);
      });
}

/**
 * A vertex represents a corner of a box.
 * This function returns the vertices of a box without taking into account the rotation of the box.
 * The order of the returned vertices is clockwise, starting from the top left.
 */
export function boxUnrotatedVertices(box: Box): Vector[] {
  return [
    box.position,
    newVector(box.position.x + box.size.width, box.position.y),
    newVector(box.position.x + box.size.width, box.position.y + box.size.height),
    newVector(box.position.x, box.position.y + box.size.height),
  ];
}

export function grippyPositionToBoxVertexIndexes(grippy: GrippyPosition): number[] {
  switch (grippy) {
    case 'top-left':
      return [0];
    case 'top':
      return [0, 1];
    case 'top-right':
      return [1];
    case 'right':
      return [1, 2];
    case 'bottom-right':
      return [2];
    case 'bottom':
      return [2, 3];
    case 'bottom-left':
      return [3];
    case 'left':
      return [3, 0];
  }
}

export function isSideGrippy(grippy: GrippyPosition): boolean {
  return ['top', 'right', 'bottom', 'left'].includes(grippy);
}

export function isCornerGrippy(grippy: GrippyPosition): boolean {
  return ['top-left', 'top-right', 'bottom-right', 'bottom-left'].includes(grippy);
}

export function getBoxForGroupAction(active: PinComponent[]): Box {
  const componentVertices = active.map((component) => {
    if (isLineLayout(component.layout)) {
      const box = boxFromLine(component.layout);
      return boxRotatedVertices(box);
    }
    return boxRotatedVertices(component.layout);
  });
  return boundingBoxFromPoints(componentVertices.flat());
}
