import React, { useCallback, useRef, useState } from 'react';

import { without } from 'lodash';

import { isHotspotPinComponent, Pin } from '@lessonup/pins-shared';
import { borderRadius, color, styled } from '@lessonup/ui-components';

import { pinSize } from '../../foundations/layout/pinDefault';
import { determineSelectedPinComponents } from '../../hooks/useHandleMouseEvents/useHandleMouseEvents.utils';
import {
  OnMouseDownHandler,
  useHandleMouseEventsForViewer,
} from '../../hooks/useHandleMouseEvents/useHandleMouseEventsForViewer';
import { useLinkedPinComponents } from '../../hooks/useLinkedPinComponents';
import { usePinResizeObserver } from '../../hooks/usePinResizeObserver';
import { PinComponentSwitch } from '../PinComponentSwitch/PinComponentSwitch';
import { PinScaleWrapper } from '../PinScaleWrapper/PinScaleWrapper';
import { PinSwitchPreview } from './PinSwitchPreview/PinSwitchPreview';

export interface PinPreviewProps {
  pin: Pin;
  className?: string;
}

export const PinPreview: React.FC<PinPreviewProps> = ({ pin, className }) => {
  const pinComponents = pin.pinComponents;
  const pinWrapperRef = useRef<HTMLDivElement>(null);
  const [selectedHotspotIds, setSelectedHotspotIds] = useState<string[]>([]);

  // TODO: TECH-180: fix the ref type issue
  const { scale, pinRendererOffset, resizeObserverInitialized } = usePinResizeObserver(pinWrapperRef as never);
  const { mutatingPinComponent, handleOnMouseMove, handleOnMouseUp } = useHandleMouseEventsForViewer(
    pin.id,
    1,
    pinRendererOffset,
    pinComponents
  );

  const handleOnMouseDown: OnMouseDownHandler = useCallback(
    (event, action) => {
      const selectedComponentIds = determineSelectedPinComponents(action, []);
      const clickedComponent = pinComponents.find((pinComponent) => selectedComponentIds.includes(pinComponent.id));

      if (clickedComponent && isHotspotPinComponent(clickedComponent)) {
        setSelectedHotspotIds((prevSelectedHotspotIds) => {
          return prevSelectedHotspotIds.includes(clickedComponent.id)
            ? without(prevSelectedHotspotIds, clickedComponent.id)
            : [...prevSelectedHotspotIds, clickedComponent.id];
        });
      }
    },
    [pinComponents]
  );

  const { isLinkedToInactiveHotspot } = useLinkedPinComponents(pinComponents, selectedHotspotIds);

  return (
    <StyledPinWrapper
      ref={pinWrapperRef}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      className={className}
    >
      <PinScaleWrapper scale={scale} resizeObserverInitialized={resizeObserverInitialized}>
        <StyledPin style={{ transform: `scale(${scale})` }}>
          <PinSwitchPreview pin={pin}>
            {pinComponents.map((pinComponent) => {
              const latestPinComponent = mutatingPinComponent || pinComponent;
              const linkedComponent = isLinkedToInactiveHotspot(pinComponent.id);

              return (
                <PinComponentSwitch
                  key={pinComponent.id}
                  pinComponent={latestPinComponent}
                  editable={false}
                  active={selectedHotspotIds.includes(pinComponent.id)}
                  onMouseDown={handleOnMouseDown}
                  pinId={pin.id}
                  pinType={pin.type}
                  isHidden={linkedComponent}
                  isStatic={!pinComponent.settings.movableInInteractiveViewer}
                  scale={scale}
                />
              );
            })}
          </PinSwitchPreview>
        </StyledPin>
      </PinScaleWrapper>
    </StyledPinWrapper>
  );
};

const StyledPin = styled.div`
  width: ${pinSize.width}px;
  height: ${pinSize.height}px;
  transform-origin: left top;
  position: relative;
  overflow: hidden;
  border-radius: ${borderRadius.rounded2};
  outline: ${color.neutral.outline.background} solid 1px;
`;

const StyledPinWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
