import {
  Button,
  color,
  css,
  IconLessonSession,
  IconWarning,
  spacing,
  styled,
  Tag,
  TagProps,
  Tooltip,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShowAsType } from '../TeachingCreditsIndicatorFeature.utils';
import { buttonType, getPriority, shouldWarn, tooltipText } from './TeachingCreditsIndicator.utils';

export interface TeachingCreditsIndicatorProps {
  onClick?: () => void;
  credits: number;
  loading?: boolean;
  showAs?: ShowAsType;
}

export const TeachingCreditsIndicator: React.FC<TeachingCreditsIndicatorProps> = ({
  credits,
  onClick,
  loading,
  showAs,
}) => {
  const { t } = useTranslation('TeachingCreditsIndicator');

  const getIndicator = () => {
    switch (showAs) {
      case 'tag':
        return (
          <Tooltip
            content={tooltipText(t, credits, loading, showAs)}
            resize="fixed"
            display="inline-block"
            direction="bottom-left"
          >
            <StyledTag size="small" priority={getPriority(credits, loading)} variant="icon-start">
              <IconLessonSession />
              <IndicatorContent showAs={showAs}>
                {!loading && credits}
                {shouldWarn(credits) && <IconWarning />}
              </IndicatorContent>
            </StyledTag>
          </Tooltip>
        );
      case 'button':
      default:
        return (
          <Tooltip
            content={tooltipText(t, credits, loading, showAs)}
            resize="fixed"
            display="inline-block"
            direction="bottom-left"
          >
            <IndicatorButton
              buttonType={buttonType(credits)}
              onClick={onClick}
              disabled={loading}
              iconStart={<IconLessonSession />}
            >
              <IndicatorContent>
                {!loading && credits}
                {shouldWarn(credits) && <IconWarning />}
              </IndicatorContent>
            </IndicatorButton>
          </Tooltip>
        );
    }
  };

  return getIndicator();
};

const IndicatorButton = styled(Button)`
  justify-content: start;
`;

const IndicatorContent = styled.div<{ showAs?: ShowAsType }>`
  ${(props) => {
    if (props.showAs !== 'tag') {
      return css`
        min-width: 2.1rem;
      `;
    }
  }}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.size2};
  svg {
    margin-right: 0;
    path {
      stroke-width: 3;
    }
  }
`;

const StyledTag = styled(Tag)<TagProps>`
  ${(props) => {
    switch (props.priority) {
      case 'medium':
        return css`
          background: ${color.accent.secondary.background};
          color: ${color.accent.secondary.text};
        `;
    }
  }}
`;
