import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReportListQueryVariables = Types.Exact<{
  lessonId: Types.Scalars['ID'];
}>;


export type ReportListQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', lesson?: { __typename?: 'Lesson', id: string, reports: Array<{ __typename?: 'Report', id: string, taughtAt: string, studentReports: Array<{ __typename?: 'ReportStudent', id: string }> }> } | null } };


export const ReportListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ReportList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"lessonId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lesson"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"lessonId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reports"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"taughtAt"}},{"kind":"Field","name":{"kind":"Name","value":"studentReports"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<ReportListQuery, ReportListQueryVariables>;