import { assertNever } from '@lessonup/utils';
import { spacing } from '../../../foundations/spacing/spacing';
import {
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopoverPosition,
  ModalPopoverSize,
} from '../types/modalPopover.types';

/**
 * Calculates the shifted position of the modal for if it overflows the container.
 */
export const calculateShiftedPosition = (
  position: ModalPopoverPosition,
  direction: ModalPopoverDirection,
  containerPosition: ModalPopoverPosition,
  spaceBetweenContainer: spacing,
  overflow: ModalPopoverSize
): ModalPopoverPosition => {
  const spaceBetweenContainerValue = spacingToInteger(spaceBetweenContainer);

  if (direction === 'top' || direction === 'bottom') {
    return {
      left: Math.max(containerPosition.left + spaceBetweenContainerValue, position.left - overflow.width),
      top: position.top,
    };
  } else {
    return {
      left: position.left,
      top: Math.max(containerPosition.top + spaceBetweenContainerValue, position.top - overflow.height),
    };
  }
};

/**
 * Calculates the maximum size of the modal. One of the dimensions will be the modal size, the other will be the container size to prevent double scrollbars.
 */
export const calculateMaxSize = (
  direction: ModalPopoverDirection,
  modalSize: ModalPopoverSize,
  containerSize: ModalPopoverSize,
  spaceBetweenContainer: spacing
): ModalPopoverSize => {
  const spaceBetweenContainerValue = spacingToInteger(spaceBetweenContainer);

  return {
    width:
      direction === 'top' || direction === 'bottom'
        ? containerSize.width - spaceBetweenContainerValue * 2
        : modalSize.width,
    height:
      direction === 'top' || direction === 'bottom'
        ? modalSize.height
        : containerSize.height - spaceBetweenContainerValue * 2,
  };
};

/**
 * Calculates the contained size of the modal based on the overflow and the modal size.
 */
export const calculateSize = (
  direction: ModalPopoverDirection,
  modalSize: ModalPopoverSize,
  containerSize: ModalPopoverSize,
  spaceBetweenContainer: spacing,
  overflow: ModalPopoverSize
): ModalPopoverSize => {
  const spaceBetweenContainerValue = spacingToInteger(spaceBetweenContainer);

  return {
    width:
      direction === 'top' || direction === 'bottom'
        ? Math.min(modalSize.width, containerSize.width - spaceBetweenContainerValue * 2)
        : modalSize.width - overflow.width,
    height:
      direction === 'top' || direction === 'bottom'
        ? modalSize.height - overflow.height
        : Math.min(modalSize.height, containerSize.height - spaceBetweenContainerValue * 2),
  };
};

/**
 * Calculates the overflow of the modal based on the modal size, the modal position and the container.
 */
export const calculateModalOverflow = (
  modalSize: ModalPopoverSize,
  modalPosition: ModalPopoverPosition,
  container: { position: ModalPopoverPosition; size: ModalPopoverSize },
  spaceBetweenContainer: spacing
): ModalPopoverSize => {
  const spaceBetweenContainerValue = spacingToInteger(spaceBetweenContainer);

  return {
    width: Math.max(
      0,
      spaceBetweenContainerValue + container.position.left - modalPosition.left,
      modalPosition.left +
        modalSize.width -
        (container.position.left + container.size.width - spaceBetweenContainerValue)
    ),
    height: Math.max(
      0,
      spaceBetweenContainerValue + container.position.top - modalPosition.top,
      modalPosition.top +
        modalSize.height -
        (container.position.top + container.size.height - spaceBetweenContainerValue)
    ),
  };
};

/**
 * Calculates the position of the modal based on the parent anchor, the modal anchor, the direction, the modal size and the offset.
 */
export const calculatePosition = (
  parentAnchorPosition: ModalPopoverPosition,
  modalAnchor: ModalPopoverAnchor,
  direction: ModalPopoverDirection,
  modalSize: ModalPopoverSize,
  offset: number,
  spaceBetweenParent: spacing
): ModalPopoverPosition => {
  const horizontalAnchorOffset =
    modalAnchor === 'center' ? modalSize.width / 2 : modalAnchor === 'end' ? modalSize.width : 0;
  const verticalAnchorOffset =
    modalAnchor === 'center' ? modalSize.height / 2 : modalAnchor === 'end' ? modalSize.height : 0;
  const spaceBetweenParentValue = spacingToInteger(spaceBetweenParent);

  switch (direction) {
    case 'top':
      return {
        left: parentAnchorPosition.left + offset - horizontalAnchorOffset,
        top: parentAnchorPosition.top - modalSize.height - spaceBetweenParentValue,
      };
    case 'bottom':
      return {
        left: parentAnchorPosition.left + offset - horizontalAnchorOffset,
        top: parentAnchorPosition.top + spaceBetweenParentValue,
      };
    case 'left':
      return {
        left: parentAnchorPosition.left - modalSize.width - spaceBetweenParentValue,
        top: parentAnchorPosition.top + offset - verticalAnchorOffset,
      };
    case 'right':
      return {
        left: parentAnchorPosition.left + spaceBetweenParentValue,
        top: parentAnchorPosition.top + offset - verticalAnchorOffset,
      };
  }
};

export const calculateSpaceBetweenButtonAndContainer = (
  direction: ModalPopoverDirection,
  parentAnchorPosition: ModalPopoverPosition,
  container: { position: ModalPopoverPosition; size: ModalPopoverSize },
  spaceBetweenContainer: spacing,
  spaceBetweenParent: spacing
): number => {
  const spaceBetweenContainerValue = spacingToInteger(spaceBetweenContainer);
  const spaceBetweenParentValue = spacingToInteger(spaceBetweenParent);

  switch (direction) {
    case 'top':
      return parentAnchorPosition.top - (container.position.top + spaceBetweenContainerValue + spaceBetweenParentValue);
    case 'bottom':
      return (
        container.position.top +
        container.size.height -
        (parentAnchorPosition.top + spaceBetweenContainerValue + spaceBetweenParentValue)
      );
    case 'left':
      return (
        parentAnchorPosition.left - (container.position.left + spaceBetweenContainerValue + spaceBetweenParentValue)
      );
    case 'right':
      return (
        container.position.left +
        container.size.width -
        (parentAnchorPosition.left + spaceBetweenContainerValue + spaceBetweenParentValue)
      );
  }
};

/**
 * Calculates the direction of the modal. If the desired direction is not available, it will try to use the opposite direction.
 * If that is not available either, it will use the first available direction.
 */
export const calculatePopoverDirection = (
  desiredDirection: ModalPopoverDirection,
  spaceBetweenButtonAndContainer: { top: number; bottom: number; left: number; right: number },
  modalSize: ModalPopoverSize
): ModalPopoverDirection => {
  const availableDirections: ModalPopoverDirection[] = [];

  if (spaceBetweenButtonAndContainer.bottom >= modalSize.height) {
    availableDirections.push('bottom');
  }
  if (spaceBetweenButtonAndContainer.right >= modalSize.width) {
    availableDirections.push('right');
  }
  if (spaceBetweenButtonAndContainer.top >= modalSize.height) {
    availableDirections.push('top');
  }
  if (spaceBetweenButtonAndContainer.left >= modalSize.width) {
    availableDirections.push('left');
  }

  if (availableDirections.includes(desiredDirection) || availableDirections.length === 0) {
    return desiredDirection;
  } else {
    const oppositeDirection = getOppositeDirection(desiredDirection);
    const oppositeIndex = availableDirections.indexOf(oppositeDirection);
    if (oppositeIndex !== -1) {
      return oppositeDirection;
    }
    return availableDirections[0];
  }
};

export const getOppositeDirection = (direction: ModalPopoverDirection): ModalPopoverDirection => {
  switch (direction) {
    case 'top':
      return 'bottom';
    case 'bottom':
      return 'top';
    case 'left':
      return 'right';
    case 'right':
      return 'left';
    default:
      assertNever(direction, 'Invalid direction');
  }
};

/**
 * Calculates the parent anchor position based on the direction and the parent bounding rect.
 */
export const calculateParentAnchor = (
  direction: ModalPopoverDirection,
  modalAnchor: ModalPopoverAnchor,
  parentBoundingRect: DOMRect,
  containerPosition: ModalPopoverPosition
): ModalPopoverPosition => {
  const parentCenter = {
    left: parentBoundingRect.left + parentBoundingRect.width / 2,
    top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height / 2,
  };
  switch (direction) {
    case 'top':
      switch (modalAnchor) {
        case 'start':
          return { left: parentBoundingRect.left, top: parentBoundingRect.top + containerPosition.top };
        case 'center':
          return { left: parentCenter.left, top: parentBoundingRect.top + containerPosition.top };
        case 'end':
          return {
            left: parentBoundingRect.left + parentBoundingRect.width,
            top: parentBoundingRect.top + containerPosition.top,
          };
      }
    // eslint-disable-next-line no-fallthrough
    case 'bottom':
      switch (modalAnchor) {
        case 'start':
          return {
            left: parentBoundingRect.left,
            top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height,
          };
        case 'center':
          return {
            left: parentCenter.left,
            top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height,
          };
        case 'end':
          return {
            left: parentBoundingRect.left + parentBoundingRect.width,
            top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height,
          };
      }
    // eslint-disable-next-line no-fallthrough
    case 'left':
      switch (modalAnchor) {
        case 'start':
          return { left: parentBoundingRect.left, top: parentBoundingRect.top + containerPosition.top };
        case 'center':
          return { left: parentBoundingRect.left, top: parentCenter.top };
        case 'end':
          return {
            left: parentBoundingRect.left,
            top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height,
          };
      }
    // eslint-disable-next-line no-fallthrough
    case 'right':
      switch (modalAnchor) {
        case 'start':
          return {
            left: parentBoundingRect.left + parentBoundingRect.width,
            top: parentBoundingRect.top + containerPosition.top,
          };
        case 'center':
          return { left: parentBoundingRect.left + parentBoundingRect.width, top: parentCenter.top };
        case 'end':
          return {
            left: parentBoundingRect.left + parentBoundingRect.width,
            top: parentBoundingRect.top + containerPosition.top + parentBoundingRect.height,
          };
      }
  }
};

export const getParentAnchors = (
  modalAnchor: ModalPopoverAnchor,
  parentBoundingRect: DOMRect,
  containerPosition: ModalPopoverPosition
) => {
  return {
    top: calculateParentAnchor('top', modalAnchor, parentBoundingRect, containerPosition),
    bottom: calculateParentAnchor('bottom', modalAnchor, parentBoundingRect, containerPosition),
    left: calculateParentAnchor('left', modalAnchor, parentBoundingRect, containerPosition),
    right: calculateParentAnchor('right', modalAnchor, parentBoundingRect, containerPosition),
  };
};

export const getSpaceBetweenButtonAndContainer = (
  buttonAnchors: {
    top: ModalPopoverPosition;
    bottom: ModalPopoverPosition;
    left: ModalPopoverPosition;
    right: ModalPopoverPosition;
  },
  container: { position: ModalPopoverPosition; size: ModalPopoverSize },
  spaceBetweenContainer: spacing,
  spaceBetweenParent: spacing
) => {
  return {
    top: calculateSpaceBetweenButtonAndContainer(
      'top',
      buttonAnchors.top,
      container,
      spaceBetweenContainer,
      spaceBetweenParent
    ),
    bottom: calculateSpaceBetweenButtonAndContainer(
      'bottom',
      buttonAnchors.bottom,
      container,
      spaceBetweenContainer,
      spaceBetweenParent
    ),
    left: calculateSpaceBetweenButtonAndContainer(
      'left',
      buttonAnchors.left,
      container,
      spaceBetweenContainer,
      spaceBetweenParent
    ),
    right: calculateSpaceBetweenButtonAndContainer(
      'right',
      buttonAnchors.right,
      container,
      spaceBetweenContainer,
      spaceBetweenParent
    ),
  };
};

function spacingToInteger(spacingValue: spacing): number {
  return parseInt(spacingValue.replace('px', ''), 10);
}
