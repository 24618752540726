import { useContext } from 'react';
import { PinRailListContext } from './PinRailListContext';

export const usePinRailListContext = () => {
  const context = useContext(PinRailListContext);
  if (context === undefined) {
    throw new Error('usePinRailListContext must be used within a PinRailListContextProvider');
  }
  return context;
};
