/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Binder, Snapshot, UpdateFn } from '@lessonup/editor-shared';
import { useDebugValue, useEffect, useMemo, useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

export function useImmerYjs<State extends Snapshot, Selection>(
  binder: Binder<State>,
  selector: (state: State) => Selection,
  isEqual?: (a: Selection, b: Selection) => boolean
): [Selection, (fn: UpdateFn<State>) => void] {
  const selection = useSyncExternalStoreWithSelector(binder.subscribe, binder.get, binder.get, selector, isEqual);
  return [selection, binder.update];
}

// This Shim is temporary until lessonup is updated to support React 18.
// Copied from use-sync-external-store package
function useSyncExternalStoreWithSelector<Snapshot, Selection>(
  subscribe: (onStoreChange: () => void) => () => void,
  getSnapshot: () => Snapshot,
  getServerSnapshot: undefined | null | (() => Snapshot),
  selector: (snapshot: Snapshot) => Selection,
  isEqual?: (a: Selection, b: Selection) => boolean
): Selection {
  // Use this to track the rendered snapshot.
  const instRef = useRef(null);
  let inst: any;

  if (instRef.current === null) {
    inst = {
      hasValue: false,
      value: null,
    };
    instRef.current = inst;
  } else {
    inst = instRef.current;
  }

  const _useMemo = useMemo(
      function () {
        // Track the memoized state using closure variables that are local to this
        // memoized instance of a getSnapshot function. Intentionally not using a
        // useRef hook, because that state would be shared across all concurrent
        // copies of the hook/component.
        let hasMemo = false;
        let memoizedSnapshot: any;
        let memoizedSelection: any;

        const memoizedSelector = function (nextSnapshot: any) {
          if (!hasMemo) {
            // The first time the hook is called, there is no memoized result.
            hasMemo = true;
            memoizedSnapshot = nextSnapshot;

            const _nextSelection = selector(nextSnapshot);

            if (isEqual !== undefined) {
              // Even if the selector has changed, the currently rendered selection
              // may be equal to the new selection. We should attempt to reuse the
              // current value if possible, to preserve downstream memoizations.
              if (inst.hasValue) {
                const currentSelection = inst.value;

                if (isEqual(currentSelection, _nextSelection)) {
                  memoizedSelection = currentSelection;
                  return currentSelection;
                }
              }
            }

            memoizedSelection = _nextSelection;
            return _nextSelection;
          } // We may be able to reuse the previous invocation's result.

          // We may be able to reuse the previous invocation's result.
          const prevSnapshot = memoizedSnapshot;
          const prevSelection = memoizedSelection;

          if (objectIs(prevSnapshot, nextSnapshot)) {
            // The snapshot is the same as last time. Reuse the previous selection.
            return prevSelection;
          } // The snapshot has changed, so we need to compute a new selection.

          // The snapshot has changed, so we need to compute a new selection.
          const nextSelection = selector(nextSnapshot); // If a custom isEqual function is provided, use that to check if the data
          // has changed. If it hasn't, return the previous selection. That signals
          // to React that the selections are conceptually equal, and we can bail
          // out of rendering.

          // If a custom isEqual function is provided, use that to check if the data
          // has changed. If it hasn't, return the previous selection. That signals
          // to React that the selections are conceptually equal, and we can bail
          // out of rendering.
          if (isEqual !== undefined && isEqual(prevSelection, nextSelection)) {
            return prevSelection;
          }

          memoizedSnapshot = nextSnapshot;
          memoizedSelection = nextSelection;
          return nextSelection;
        }; // Assigning this to a constant so that Flow knows it can't change.

        // Assigning this to a constant so that Flow knows it can't change.
        const maybeGetServerSnapshot = getServerSnapshot === undefined ? null : getServerSnapshot;

        const getSnapshotWithSelector = function () {
          return memoizedSelector(getSnapshot());
        };

        const getServerSnapshotWithSelector =
          maybeGetServerSnapshot === null
            ? undefined
            : function () {
                return memoizedSelector(maybeGetServerSnapshot());
              };
        return [getSnapshotWithSelector, getServerSnapshotWithSelector];
      },
      [getSnapshot, getServerSnapshot, selector, isEqual]
    ),
    getSelection: any = _useMemo[0],
    getServerSelection = _useMemo[1];

  const value = useSyncExternalStore(subscribe, getSelection, getServerSelection);
  useEffect(
    function () {
      inst.hasValue = true;
      inst.value = value;
    },
    [value]
  );
  useDebugValue(value);
  return value;
}

function is(x: any, y: any) {
  return (
    (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y) // eslint-disable-line no-self-compare
  );
}

const objectIs = typeof Object.is === 'function' ? Object.is : is;
