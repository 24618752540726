import { AssetSpinnerFullColor, styled } from '@lessonup/ui-components';
import React from 'react';

export interface SpinnerPinComponentProps {
  onClick?: () => void;
}

export const SpinnerPinComponent: React.FC<SpinnerPinComponentProps> = ({ onClick }) => {
  return <StyledSpinnerFullColor width="100%" height="100%" onClick={onClick} />;
};

const StyledSpinnerFullColor = styled(AssetSpinnerFullColor)<SpinnerPinComponentProps>`
  cursor: ${(props) => (props.onClick !== undefined ? 'pointer' : 'inherit')};
`;
