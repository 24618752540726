import {
  LayoutPart,
  LayoutPartDict,
  OpenQuestionPinLayoutKey,
  Pin,
  PinComponent,
  PinComponentId,
  PinComponentLayout,
  PinCreationParams,
  PinId,
  PinSettings,
  PinTeacherSettings,
  PinType,
  QuizPinLayoutKey,
  SlidePinLayoutKey,
} from '@lessonup/pins-shared';
import React, { createContext, useCallback, useContext, useRef } from 'react';

interface DispatchContext {
  dispatch: UpdatePinDispatch;
  flushPendingDispatch: () => void;
}

type UpdatePinActionUnion<T extends PinType = PinType> =
  | {
      type: 'addPinComponents';
      dryRun?: boolean;
      pinComponents: PinComponent[];
      onComplete?: (pinComponentIds: PinComponentId[]) => void;
    }
  | {
      type: 'duplicatePinComponents';
      dryRun?: boolean;
      pinComponents: PinComponent[];
      onComplete?: (pinComponentIds: PinComponentId[]) => void;
    }
  | {
      type: 'deletePinComponents';
      pinComponentIds: PinComponentId[];
      onComplete?: (deletedPinComponents: PinComponent[]) => void;
    }
  | {
      type: 'overridePinComponents';
      pinComponents: PinComponent[];
    }
  | {
      type: 'updateComponentLayout';
      debounce?: number;
      updates: Record<string, PinComponentLayout>;
    }
  | {
      type: 'updateComponentLayoutPart';
      pinComponentId: string;
      layoutPart: LayoutPart;
    }
  | {
      type: 'updatePinComponentSettings';
      pinComponentId: string;
      debounce?: number;
      settings: Partial<PinComponent['settings']>;
    }
  | {
      type: 'updatePinSettings';
      debounce?: number;
      settings: Partial<PinSettings<T>>;
      onComplete?: () => void;
    }
  | {
      type: 'updatePinTeacherSettings';
      debounce?: number;
      settings: Partial<PinTeacherSettings<T>>;
    }
  | {
      type: 'addPins';
      afterPinId?: string;
      endOfPhase?: string;
      pins: PinCreationParams[];
      onComplete?: (pinIds: PinId[]) => void;
    }
  | {
      type: 'duplicatePins';
      pinIds: PinId[];
      onComplete?: (pinIds: PinId[]) => void;
    }
  | {
      type: 'deletePins';
      pinIds: PinId[];
      onComplete?: (deletedPins: Pin[]) => void;
    }
  | {
      type: 'reorderPins';
      afterPinId: string | null;
      startOfPhase: string | null;
      pinIds: PinId[];
    }
  | {
      type: 'updatePinLayout';
      toBeAddedPinComponents: PinComponent[];
      toBeRemovedPinComponentIds: string[];
      updatedPinComponents: PinComponent[];
      newLayoutDict: LayoutPartDict;
      layout: SlidePinLayoutKey | QuizPinLayoutKey | OpenQuestionPinLayoutKey;
    }
  | {
      type: 'updatePinComponentOrder';
      moveAction: 'forward' | 'backward' | 'toFront' | 'toBack';
      pinComponentIds: PinComponentId[];
    }
  | {
      type: 'clearDryRun';
    };

export type UpdatePinAction<T extends PinType = PinType> = UpdatePinActionUnion<T> & { source?: string };

export type UpdatePinActionWithMetadata = UpdatePinAction & {
  pinId: string | null;
  source?: string;
};

export type UpdatePinActionSingleOrMulti<T extends PinType = PinType> = UpdatePinAction<T> | UpdatePinAction<T>[];
export type UpdatePinActionWithMetaSingleOrMulti = UpdatePinActionWithMetadata | UpdatePinActionWithMetadata[];

export type UpdatePinDispatch<T extends PinType = PinType> = React.Dispatch<UpdatePinActionSingleOrMulti<T>>;
export type UpdatePinDispatchWithMetadata = (
  params: UpdatePinActionWithMetaSingleOrMulti,
  ignoreOrigin?: boolean
) => void;

const defaultImplementation: DispatchContext = {
  dispatch: () => {
    console.warn('no dispatch for updatePinContext');
  },
  flushPendingDispatch: () => {
    console.warn('no flush_pending for updatePinContext');
  },
};

const updatePinContext = createContext<DispatchContext>(defaultImplementation);

interface DispatchContextProviderProps {
  value: {
    dispatch: UpdatePinDispatch;
    flushPendingDispatch?: () => void;
  };
  children?: React.ReactNode;
}

export const UpdatePinContextProvider: React.FC<DispatchContextProviderProps> = (props) => {
  const { value, children } = props;
  const newValue: DispatchContext = {
    ...value,
    flushPendingDispatch: value.flushPendingDispatch ?? defaultImplementation.flushPendingDispatch,
  };
  return <updatePinContext.Provider value={newValue}> {children} </updatePinContext.Provider>;
};

export const useUpdatePin = <T extends PinType = PinType>(): UpdatePinDispatch<T> => {
  return useContext(updatePinContext).dispatch;
};

export const useFlushPendingDispatch = (): (() => void) => {
  return useContext(updatePinContext).flushPendingDispatch;
};

/**
 * This hook is used to create a dispatch function that will dispatch including the pinId
 * So all the lower level components can use the same dispatch function without having to know about the pinId
 */
export const useUpdatePinWithMetadataDispatch = (
  dispatch: UpdatePinDispatchWithMetadata,
  pinId: string | null
): UpdatePinDispatch => {
  // We do not want to update the callback, since it will trigger an update of every component that uses it
  const pinIdRef = useRef(pinId);
  pinIdRef.current = pinId;

  return useCallback(
    (actions) => {
      if (Array.isArray(actions)) {
        dispatch(actions.map((action) => ({ ...action, pinId: pinIdRef.current })));
      } else {
        dispatch({ ...actions, pinId: pinIdRef.current });
      }
    },
    [dispatch]
  );
};
