import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconComponentVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.209 15.126c.252 2.153 2.042 3.849 4.2 4.045 1.495.136 3.029.25 4.591.25s3.095-.114 4.59-.25c2.16-.196 3.95-1.892 4.201-4.045A27 27 0 0 0 21 12c0-1.06-.089-2.103-.209-3.126-.252-2.153-2.042-3.85-4.2-4.045-1.496-.136-3.029-.25-4.591-.25s-3.096.114-4.59.25C5.25 5.025 3.46 6.72 3.209 8.874A27 27 0 0 0 3 12c0 1.06.089 2.103.209 3.126"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.675 15.39c1.836-.984 4.217-2.442 4.217-3.39s-2.38-2.406-4.217-3.39c-.964-.516-2.09.198-2.09 1.292v4.196c0 1.094 1.126 1.808 2.09 1.292"
    />
  </svg>
);
export default SvgIconComponentVideo;
