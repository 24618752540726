import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAssetLogoRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" viewBox="0 0 64 64" {...props}>
    <path
      fill="currentColor"
      d="M16.2 51.2c.5-1.6.2-2.5-.4-3-.5-.6-1.4-.9-3-.4-5.2 1.5-5.8 8-5.9 9.3 1.3-.1 7.8-.7 9.3-5.9M16 26.7l2.8-4.9C15 21.5 8 22.3 0 30.5l3 3s6.3-2.1 10.5-1.2a70 70 0 0 1 2.6-5.6ZM37.3 48l-5.6 2.5c1 4.2-1.2 10.5-1.2 10.5l3 3c8.2-8 9-15 8.7-18.8-1.6 1-3.3 1.9-5 2.7Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M44.3 19.5a1.5 1.5 0 0 1-2.1 0 1.5 1.5 0 1 1 2.1 0m2.5-2.3a5 5 0 0 0-7.2 0 5 5 0 1 0 7.2 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M48.8 26.4a7.9 7.9 0 0 1-11.2 0 8 8 0 1 1 11.2 0M62 2c-1.4-1.3-3.6-2-6.4-2A43 43 0 0 0 23 20.4a78 78 0 0 0-9 18.9 15.7 15.7 0 0 0 1 2.9c.8 1.4 1.7 2.7 2.8 3.9a16.6 16.6 0 0 0 7.1 4 59.7 59.7 0 0 0 11-4.7 78 78 0 0 0 14.2-9.5C63.2 22.9 66.7 6.8 62 2"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAssetLogoRocket;
