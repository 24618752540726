import { color, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useEditorContext } from '../../context/EditorContext/EditorContext';
import { useActivePinId } from '../../context/EditorContext/hooks/useEditorState';
import { AddPinButton } from '../AddPinButton/AddPinButton';
import { UndoRedoButton } from '../UndoRedoButton/UndoRedoButton';
import { EditorToolbelt } from './dataComponents/EditorToolbelt';

export const EditorTopBar: React.FC = () => {
  const activePinId = useActivePinId();
  const { lessonId, setActivePinId } = useEditorContext();

  return (
    <StyledEditorTopBar spacing={spacing.size0} direction="x" alignItems="flex-start" justifyContent="space-between">
      <SpaceBetween spacing={spacing.size0} direction="x" alignItems="center">
        <AddPinButton setActivePinId={setActivePinId} addAfterPinId={activePinId || undefined} lessonId={lessonId} />
        {<UndoRedoButton />}
      </SpaceBetween>
      <EditorToolbelt />
      <Spacer />
    </StyledEditorTopBar>
  );
};

const StyledEditorTopBar = styled(SpaceBetween)`
  background: ${color.neutralNew.level1.fill};
  height: ${rem('58px')};
`;

const Spacer = styled.div`
  width: ${rem('268px')};
`;
