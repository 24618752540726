import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { assertNever } from '@lessonup/utils';
import React from 'react';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { AsyncButton, AsyncMouseEventHandler } from '../../AsyncButton/AsyncButton';
import { BodyText } from '../../BodyText/BodyText';
import { Button, ButtonAnchor, ButtonAnchorProps, ButtonProps } from '../../Button/Button';
import { Divider } from '../../Divider/Divider';
import { Switch } from '../../FormField/Switch/Switch';
import { IconChevronLeft, IconChevronRight } from '../../icons';

type ModalFooterType = 'actions' | 'carousel' | 'switch';

interface BaseProps {
  type: ModalFooterType;
  isSticky?: boolean;
  showDivider?: boolean;
}

export interface ModalFooterActionProps extends BaseProps {
  type: 'actions';
  text?: string;
  primaryAction: ModalFooterAction | ModalFooterActionAsync | ModalFooterActionAnchor;
  secondaryAction?: ModalFooterAction | ModalFooterActionAnchor;
}

export interface ModalFooterCarouselProps extends BaseProps {
  type: 'carousel';
  countLabel: string;
  countDivider: string;
  current: number;
  total: number;
  nextButtonProps: {
    ariaLabel: string;
    onClick: () => void;
    disabled: boolean;
  };
  previousButtonProps: {
    ariaLabel: string;
    onClick: () => void;
    disabled: boolean;
  };
}

export interface ModalFooterSwitchProps extends BaseProps {
  type: 'switch';
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  label: string;
  checked: boolean;
}

export type ModalFooterProps = ModalFooterActionProps | ModalFooterCarouselProps | ModalFooterSwitchProps;

export type ModalFooterAction = Omit<ButtonProps, 'children'> & {
  label: string | React.JSX.Element;
};

export type ModalFooterActionAsync = ModalFooterAction & { async: true } & {
  onClick: AsyncMouseEventHandler;
};

export type ModalFooterActionAnchor = Omit<ButtonAnchorProps, 'children'> & {
  label: string | React.JSX.Element;
};

const spacingBetween = spacing.size8;

export function ModalFooter(props: ModalFooterProps) {
  function modalFooterBody() {
    const type = props.type;

    switch (type) {
      case 'actions':
        return <ActionBody {...props} />;
      case 'carousel':
        return <CarouselBody {...props} />;
      case 'switch':
        return <SwitchBody {...props} />;
      default:
        return assertNever(type, 'Unknown ModalFooter type');
    }
  }
  return (
    <StyledModalFooter isSticky={props.isSticky}>
      {props.showDivider && <Divider orientation="horizontal" noSpacer />}
      {modalFooterBody()}
    </StyledModalFooter>
  );
}

function CarouselBody(props: ModalFooterCarouselProps) {
  return (
    <StyledModalFooterBody direction="x" spacing={spacingBetween} alignItems="center" justifyContent="space-between">
      <StyledBodyText>{[props.countLabel, props.current, props.countDivider, props.total].join(' ')}</StyledBodyText>
      <SpaceBetween direction="x" spacing={spacing.size2}>
        <Button size="large" buttonType="neutral" iconStart={<IconChevronLeft />} {...props.previousButtonProps} />
        <Button size="large" buttonType="neutral" iconStart={<IconChevronRight />} {...props.nextButtonProps} />
      </SpaceBetween>
    </StyledModalFooterBody>
  );
}

function ActionBody({ primaryAction, secondaryAction, text }: ModalFooterActionProps) {
  const sharedPrimaryButtonProps = {
    buttonType: primaryAction.buttonType ?? 'primary',
  };

  function getSecondaryAction() {
    if (!secondaryAction) return;

    return isAnchorAction(secondaryAction) ? (
      <ButtonAnchor
        size="large"
        buttonType={secondaryAction.buttonType ?? 'neutral'}
        showStroke={!secondaryAction.buttonType}
        {...secondaryAction}
      >
        {secondaryAction.label}
      </ButtonAnchor>
    ) : (
      <Button
        size="large"
        buttonType={secondaryAction.buttonType ?? 'neutral'}
        showStroke={!secondaryAction.buttonType}
        {...secondaryAction}
      >
        {secondaryAction.label}
      </Button>
    );
  }

  return (
    <StyledModalFooterBody direction="x" spacing={spacingBetween} alignItems="center" justifyContent="end">
      {text && <StyledBodyText>{text}</StyledBodyText>}
      {isAsyncAction(primaryAction) ? (
        <StyledAsyncPrimaryButton size="large" {...sharedPrimaryButtonProps} {...primaryAction}>
          {primaryAction.label}
        </StyledAsyncPrimaryButton>
      ) : isAnchorAction(primaryAction) ? (
        <StyledPrimaryButtonAnchor size="large" {...sharedPrimaryButtonProps} {...primaryAction}>
          {primaryAction.label}
        </StyledPrimaryButtonAnchor>
      ) : (
        <StyledPrimaryButton size="large" {...sharedPrimaryButtonProps} {...primaryAction}>
          {primaryAction.label}
        </StyledPrimaryButton>
      )}
      {getSecondaryAction()}
    </StyledModalFooterBody>
  );
}

function SwitchBody(props: ModalFooterSwitchProps) {
  return (
    <StyledModalFooterBody direction="x" spacing={spacingBetween} alignItems="center" justifyContent="end">
      <Switch id="modal-footer-switch" onChange={props.onChange} label={props.label} checked={props.checked} />
    </StyledModalFooterBody>
  );
}

const isAsyncAction = (
  action: ModalFooterAction | ModalFooterActionAsync | ModalFooterActionAnchor
): action is ModalFooterActionAsync => {
  return !!(action as ModalFooterActionAsync).async;
};

const isAnchorAction = (
  action: ModalFooterAction | ModalFooterActionAsync | ModalFooterActionAnchor
): action is ModalFooterActionAnchor => {
  return !!(action as ModalFooterActionAnchor).href;
};

const StyledModalFooter = styled.footer<{ isSticky?: boolean }>`
  background: ${color.neutral.surface.background};
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      bottom: 0;
    `}
`;

const StyledModalFooterBody = styled(SpaceBetween)`
  padding: ${spacing.size12} ${spacing.size16};
`;

const StyledBodyText = styled(BodyText)`
  color: ${color.additional.disabled.text};
  flex-grow: 1;
`;

const sharedPrimaryButtonStyling = css`
  order: 2;
  margin-left: ${spacingBetween};
`;

const StyledPrimaryButton = styled(Button)`
  ${sharedPrimaryButtonStyling}
`;

const StyledAsyncPrimaryButton = styled(AsyncButton)`
  ${sharedPrimaryButtonStyling}
`;

const StyledPrimaryButtonAnchor = styled(ButtonAnchor)`
  ${sharedPrimaryButtonStyling};
`;
