import { isLayoutPinComponentChanged } from '@lessonup/editor-shared';
import {
  BaseTemplateLayout,
  LayoutPart,
  LayoutPartDict,
  OpenQuestionPinLayoutTemplate,
  Pin,
  PinComponent,
  QuizPinLayoutTemplate,
  randomClientId,
  SlidePinLayoutTemplate,
  TemplateLayouts,
  WordCloudPinLayoutTemplate,
} from '@lessonup/pin-renderer';
import { cloneDeep } from '@lessonup/utils';
import { Doc } from 'yjs';

// TODO: when we have REDO/UNDO we probably need to rewrite this function to be able to undo the changes
// TODO: write tests for all these functions afterwards
export function determinePinComponentsUpdatesForLayoutChange(
  pinId: string,
  layoutDict: LayoutPartDict,
  pinComponents: PinComponent[],
  newLayout: BaseTemplateLayout,
  existingLayout: BaseTemplateLayout,
  yDoc: Doc
): {
  updatedPinComponents: PinComponent[];
  toBeAddedPinComponents: PinComponent[];
  toBeRemovedPinComponentIds: string[];
  newLayoutDict: LayoutPartDict;
} {
  const reversedLayoutDict = reverseDict(layoutDict);
  const layoutComponents = toEntries(pinComponents, reversedLayoutDict);

  const { changedPinComponents, unchangedPinComponents, pinComponentsNotInLayout } =
    getPinComponentsUserModifiedOnLayout(pinId, layoutComponents, newLayout, existingLayout, yDoc);

  const changedPinComponentEntries = toEntries(changedPinComponents, reversedLayoutDict);

  const updatedPinComponentsEntries = getUpdatedPinComponents(changedPinComponentEntries, newLayout);
  const toBeAddedPinComponentEntries = getToBeAddedPinComponents(changedPinComponentEntries, newLayout);

  const newLayoutDict: LayoutPartDict = {};
  updatedPinComponentsEntries.forEach(([part, component]) => {
    newLayoutDict[part] = component.id;
  });
  toBeAddedPinComponentEntries.forEach(([part, component]) => {
    newLayoutDict[part] = component.id;
  });

  return {
    updatedPinComponents: updatedPinComponentsEntries.map(([, component]) => component),
    toBeAddedPinComponents: toBeAddedPinComponentEntries.map(([, component]) => component),
    toBeRemovedPinComponentIds: [...pinComponentsNotInLayout, ...unchangedPinComponents].map(
      (pinComponent) => pinComponent.id
    ),
    newLayoutDict: newLayoutDict,
  };
}

export function getPinComponentsUserModifiedOnLayout(
  pinId: string,
  layoutComponents: LayoutPartComponentEntry[],
  newLayout: BaseTemplateLayout,
  originalLayout: BaseTemplateLayout,
  yDoc: Doc
): {
  changedPinComponents: PinComponent[];
  unchangedPinComponents: PinComponent[];
  pinComponentsNotInLayout: PinComponent[];
} {
  const changedPinComponents: PinComponent[] = [];
  const unChangedPinComponents: PinComponent[] = [];
  const pinComponentsNotInLayout: PinComponent[] = [];

  layoutComponents.forEach(([part, component]) => {
    const pinComponentOnLayout = originalLayout.layoutParts[part];
    const isInNewLayout = !!newLayout.layoutParts[part];

    if (pinComponentOnLayout && isInNewLayout) {
      if (isLayoutPinComponentChanged(yDoc, pinId, pinComponentOnLayout, component)) {
        changedPinComponents.push(component);
      } else {
        unChangedPinComponents.push(component);
      }
    } else {
      pinComponentsNotInLayout.push(component); // This part will never be reached, we should look into this.
    }
  });

  return {
    changedPinComponents: changedPinComponents,
    unchangedPinComponents: unChangedPinComponents,
    pinComponentsNotInLayout: pinComponentsNotInLayout,
  };
}

// Todo: add this type to the pin-shared package
type AnyPinLayoutTemplate =
  | QuizPinLayoutTemplate
  | SlidePinLayoutTemplate
  | OpenQuestionPinLayoutTemplate
  | WordCloudPinLayoutTemplate;

export function getLayoutForKey(
  layouts: AnyPinLayoutTemplate[],
  layoutKey?: string | null
): BaseTemplateLayout | undefined {
  if (!layoutKey) return undefined;

  return layouts.find((layout) => layout.key === layoutKey);
}

export function getLayoutsByPinType(layouts: TemplateLayouts, pinType: Pin['type']): AnyPinLayoutTemplate[] {
  switch (pinType) {
    case 'MULTIPLE_CHOICE':
      return layouts.quizPinLayouts;
    case 'SLIDE':
      return layouts.slidePinLayouts;
    case 'OPEN_QUESTION':
      return layouts.openQuestionLayouts;
    case 'WORD_CLOUD':
      return layouts.wordCloudLayouts;
    default:
      return [];
  }
}

export function getUpdatedPinComponents(
  pinComponentsWithExistingLayout: LayoutPartComponentEntry[],
  layout: BaseTemplateLayout
): LayoutPartComponentEntry[] {
  return pinComponentsWithExistingLayout.map(([part, pinComponent]) => {
    const pinComponentOnLayout = layout.layoutParts[part];

    if (pinComponentOnLayout) {
      return [
        part,
        {
          ...pinComponent,
          layout: pinComponentOnLayout.layout,
        },
      ];
    } else {
      return [part, pinComponent];
    }
  });
}

function getToBeAddedPinComponents(
  pinComponentsWithExistingLayout: LayoutPartComponentEntry[],
  layout: BaseTemplateLayout
): LayoutPartComponentEntry[] {
  const missingLayoutComponents = Object.entries(layout.layoutParts).filter(
    ([partOnLayout]) => !pinComponentsWithExistingLayout.find(([part]) => part === partOnLayout)
  );
  return missingLayoutComponents.map(([key, comp]) => [key as LayoutPart, cloneWithNewId(comp)]);
}

function cloneWithNewId<T extends { id: string }>(obj: T): T {
  return {
    ...cloneDeep(obj),
    id: randomClientId(),
  };
}

type LayoutPartDictReversed = Record<string, LayoutPart>;
function reverseDict(dict: LayoutPartDict): LayoutPartDictReversed {
  return Object.entries(dict).reduce<LayoutPartDictReversed>((acc, [key, value]) => {
    if (value) acc[value] = key as LayoutPart;
    return acc;
  }, {});
}

type LayoutPartComponentEntry = [LayoutPart, PinComponent];

function toEntries(components: PinComponent[], dict: LayoutPartDictReversed): LayoutPartComponentEntry[] {
  return components
    .map((component) => [dict[component.id], component])
    .filter((componentDict): componentDict is LayoutPartComponentEntry => {
      const [part] = componentDict;
      return !!part;
    });
}
