import { getPinComponentFragment } from '@lessonup/editor-shared';
import {
  tipTapBaseExtensions,
  TipTapComponentProps,
  TipTapEditorContent,
  useTipTapEditor,
} from '@lessonup/pin-renderer';
import { Collaboration } from '@tiptap/extension-collaboration';
import { compact, debounce, isNull } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { XmlFragment as YXmlFragment } from 'yjs';
import { teacherTextEditorStore } from './EditorYjs/teacherTextEditorStore';
import { PasteHandler } from './extensions/PasteHandler';
// import { Placeholder } from './extensions/Placeholder';
import { useAnyYjs } from './YjsContext';

export const TipTapCollaborative: React.FC<TipTapComponentProps> = (props) => {
  const { pinId, pinComponentId, fragmentKey = 'f0' } = props;
  const { yDoc } = useAnyYjs();
  const fragment = useMemo(
    () => getPinComponentFragment(yDoc, pinId, pinComponentId, fragmentKey),
    [yDoc, pinId, pinComponentId, fragmentKey]
  );
  if (isNull(fragment)) return null;

  return <TipTapCollaborativeInner fragment={fragment} {...props} />;
};

interface TipTapComponentInnerProps extends TipTapComponentProps {
  fragment: YXmlFragment;
}

export const TipTapCollaborativeInner: React.FC<TipTapComponentInnerProps> = ({
  pinId,
  pinComponentId,
  active,
  editable,
  onBlur,
  onFocus,
  isTextEditMode,
  fragment,
  registerEditor,
}) => {
  const { yDoc, collaborationCursor } = useAnyYjs();
  const wasTextEditMode = useRef(isTextEditMode);
  const debouncedUpdate = debounce(() => {
    teacherTextEditorStore.forceEditorUpdate();
  }, 50);

  const handleMouseDown = (event: React.MouseEvent) => {
    // do not move the component when in detail mode
    if (isTextEditMode) event.stopPropagation();
  };

  const editor = useTipTapEditor(
    {
      immediatelyRender: false,
      editable: false,
      onBlur: () => {
        onBlur?.();
      },
      onFocus: () => {
        onFocus?.();
      },
      onTransaction: () => {
        debouncedUpdate();
      },
      extensions: compact([
        ...tipTapBaseExtensions,
        PasteHandler,
        Collaboration.extend({
          // We have to override undo and redo from tiptap, since we already have the undo manager directly integrated in our editor
          addCommands() {
            return {
              undo: () => () => {
                return false;
              },
              redo: () => () => {
                return false;
              },
            };
          },
        }).configure({
          document: yDoc,
          fragment: fragment,
        }),
        // editable && Placeholder, /* ED-42 disabled until this ticket is finished */
        editable && collaborationCursor,
      ]),
    },
    [pinId, pinComponentId, collaborationCursor, editable, fragment]
  );

  useEffect(() => {
    if (editor) {
      // @TODO remove setTimeout whenever we are free from the shackles of Meteor
      // poor mans deselect
      setTimeout(() => editor?.chain().selectAll().blur().run(), 0);
      registerEditor?.(editor);
    }
  }, [editor, pinComponentId, registerEditor]);

  useEffect(() => {
    if (!editable) return;
    if (active) {
      teacherTextEditorStore.setEditor(editor);
      editor?.setEditable(true);
    } else {
      editor?.setEditable(false);
    }
  }, [editable, editor, active, pinComponentId]);

  useEffect(() => {
    if (!isTextEditMode && wasTextEditMode.current) {
      wasTextEditMode.current = false;
      // poor mans deselect
      editor?.chain().selectAll().blur().run();
    }
    if (isTextEditMode) {
      wasTextEditMode.current = true;
    }
  }, [editor, isTextEditMode, pinComponentId]);

  return <TipTapEditorContent className="tiptap-root" editor={editor} onMouseDown={handleMouseDown} />;
};
