import { spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { LESSON_TOP_BAR_INNER_HEIGHT } from '../LessonTopBar.utils';

export const LessonBarEnd: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledLessonBarEnd>{children}</StyledLessonBarEnd>;
};

const StyledLessonBarEnd = styled.section`
  height: ${LESSON_TOP_BAR_INNER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: ${spacing.size8};
`;
