import { AppError } from '@lessonup/utils-support';
import { EnvContext } from '../context';

const absoluteUrlRegex = /^([a-zA-Z0-9+.-]+):(.+)/i;

function internalResolveAssetUrl(path: string, app: EnvContext.App = 'teacher'): string {
  return EnvContext.Config.instance.assetUrl(path, { app: app });
}

export const getRouteForAsset = (path: string, app: EnvContext.App = 'teacher'): string => {
  const match = path.match(absoluteUrlRegex);
  if (!match) {
    // Plain relative path (e.g. /image.png)
    return internalResolveAssetUrl(path, app);
  }

  const protocol = match[1];
  if (protocol !== 'lessonup') {
    // Absolute url, not lessonup:// (e.g. https://example.com/image.png)
    return path;
  }

  const parts = match[2].split('/').filter(Boolean);
  const [kind, ...rest] = parts;
  if (kind === 'image') {
    // lessonup://image/... urls
    return internalResolveAssetUrl('img/' + rest.join('/'), app);
  }
  throw new AppError('unexpected-data', "We don't support this kind of lessonup:// asset-url yet", { path, kind });
};
