import { newVector } from '@lessonup/pins-shared';
import { NumericRange } from '@lessonup/utils';
import { PinTransformAction, RailLayout } from '../../types';

export function isVerticalRailLayout(railLayout: RailLayout): boolean {
  return railLayout.orientation === 'vertical';
}

export function isHorizontalRailLayout(railLayout: RailLayout): boolean {
  return railLayout.orientation === 'horizontal';
}

export function getRailLayoutPosition(railLayout: RailLayout) {
  return isHorizontalRailLayout(railLayout)
    ? newVector(railLayout.displayRange.min, railLayout.positionOnAxis)
    : newVector(railLayout.positionOnAxis, railLayout.displayRange.min);
}

export function isRailEligibleAction(action: PinTransformAction) {
  return (
    action.action === 'move' ||
    action.action === 'move-group' ||
    action.action === 'resize-box' ||
    action.action === 'resize-line' ||
    action.action === 'resize-group'
  );
}

/**
 * Filter out duplicate rails and merge the display ranges of duplicate rails
 */
export function filterRailLayouts(railLayouts: RailLayout[]): RailLayout[] {
  const filteredRails = new Map<string, RailLayout>();
  railLayouts.forEach((rail) => {
    const key = `${rail.positionOnAxis}-${rail.orientation}-${rail.snapFitting}-${rail.isBleedingRail}`;

    filteredRails.set(key, {
      ...rail,
      displayRange: mergeDisplayRanges(rail.displayRange, filteredRails.get(key)?.displayRange),
    });
  });

  return Array.from(filteredRails.values());
}

export function mergeDisplayRanges(rangeA: NumericRange, rangeB?: NumericRange): NumericRange {
  if (!rangeB) return rangeA;

  return {
    min: Math.min(rangeA.min, rangeB.min),
    max: Math.max(rangeA.max, rangeB.max),
  };
}
