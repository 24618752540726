import { CookieSettings } from '../../cookies/analytic-cookies';
import { TrackerDestination } from './TrackerDestination';

export type HSQ = unknown[][];

export interface HubspotDestinationOptions {
  hsq: HSQ;
}

/**
 * @link https://developers.hubspot.com/docs/api/events/tracking-code
 */
export class HubspotDestination implements TrackerDestination {
  private isConfigLoaded: boolean = false;
  private isPaused = false;
  private readonly hsq: HSQ;
  private readonly hubspotId = '26747756'; // same for all environments

  public constructor(options: HubspotDestinationOptions) {
    this.hsq = options.hsq;
  }

  private loadConfig(): void {
    if (this.isConfigLoaded) return;

    // we load the script like this instead of pasting the script tag in the html
    // because we only want to load this after the cookie settings have been loaded
    // hubspot doesn't have a way to track the user anonymized.
    // also we changed this script to load async to prevent blocking the page load
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.id = 'hs-script-loader';
    el.async = true;
    el.defer = true;
    el.src = `https://js.hs-scripts.com/${this.hubspotId}.js`;

    document.body.appendChild(el);

    this.isConfigLoaded = true;
  }

  public init(cookieSettings: CookieSettings | undefined): void {
    if (cookieSettings?.marketing === true) {
      this.hsq.push(['setPath', document.location.pathname + (document.location.search ?? '')]);
      this.loadConfig();
    }
  }

  public pause(paused: boolean): void {
    this.isPaused = paused;
  }

  public trackPageView(): void {
    // hubspot tracks the initial page load automatically after it's been loaded. Because of this we don't track pages before that moment
    if (!this.isConfigLoaded || this.isPaused) return;

    this.hsq.push(['setPath', document.location.pathname + (document.location.search ?? '')]);
    this.hsq.push(['trackPageView']);
  }

  public logEvent(event: string, data: object): void {
    // we don't log events to hubspot
  }

  public trackError(message: string, error: Error | undefined): void {
    // we don't send errors to hubspot
  }

  public setUserId(userId: string | undefined | null): void {
    this.hsq.push([
      'identify',
      {
        id: userId,
      },
    ]);
  }

  public logout(): void {
    this.hsq.push([
      'identity',
      {
        id: null,
      },
    ]);
  }

  public updateConsent(cookieSettings: CookieSettings): void {
    // if the config wasn't loaded before because we we're missing cookies, than we load it when the user gives consent
    // note that consent must be set before loading the config
    if (cookieSettings.marketing === true) {
      this.loadConfig();
    }
  }
}
