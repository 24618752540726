import { logger } from '@lessonup/client-integration';
import {
  BodyText,
  FormFieldValidation,
  InputFormField,
  ModalFooter,
  SpaceBetween,
  spacing,
  styled,
  useForm,
} from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import { size } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FormInputs {
  email: string;
}

export interface AddWhitelistedEmailModalContentProps {
  onCancel: () => void;

  /**
   *
   * @param the new email address that the user expects to be whitelisted
   * @returns whether new email is in whitelist or not
   */
  onSubmit: (email: string) => Promise<boolean>;
}

export const AddWhitelistedEmailModalContent = ({
  onSubmit: onSubmitProp,
  onCancel: onCancel,
}: AddWhitelistedEmailModalContentProps) => {
  const { t } = useTranslation('addWhitelistedEmailModal');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setError,
  } = useForm<FormInputs>();

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);

    const { email } = data;

    try {
      const success = await onSubmitProp(email);

      if (!success) {
        setError('email', { message: t('error.alsoNotWhitelisted') });
      }
      return success;
    } catch (error: unknown) {
      logger.error(asError(error));
      setError('email', { message: t('error.generic') });
      return;
    } finally {
      setLoading(false);
    }
  };

  const validation: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = formErrors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalContent direction="y" spacing={spacing.size16}>
        <BodyText>{t('schoolOnlyAccepts')}</BodyText>
        <BodyText size="large">{t('doYouWantSchoolEmail')}</BodyText>
        <InputFormField
          type="email"
          label={t('email')}
          placeholder={t('schoolEmailExample')}
          {...register('email', {
            required: { value: true, message: t('error.fieldIsRequired') },
          })}
          validation={validation('email')}
        />
      </ModalContent>
      <ModalFooter
        type="actions"
        primaryAction={{ type: 'submit', label: t('primary'), loading, disabled: size(formErrors) > 0 }}
        secondaryAction={{ onClick: onCancel, label: t('cancel') }}
        showDivider={false}
      />
    </form>
  );
};

const ModalContent = styled(SpaceBetween)`
  padding: ${spacing.size24};
`;
