import {
  BodyText,
  IconCheck,
  List,
  ListItemProps,
  ManagedModal,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { range } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TRANSLATION_NS = 'trialExpiredModal';

const ModalContent = styled(SpaceBetween)`
  padding: 0 ${spacing.size24};
  width: 480px;
  max-width: 100%;
`;

export const TrialExpiredModal: React.FC<NiceModalHocProps> = NiceModal.create(() => {
  const modal = NiceModal.useModal();
  const { t } = useTranslation(TRANSLATION_NS);

  const onConfirmClick = () => {
    modal.resolve(true);
    modal.hide();
  };

  const onPostponeClick = () => {
    modal.resolve(false);
    modal.hide();
  };

  const onCloseClick = () => {
    modal.resolve(false);
    modal.hide();
  };

  return (
    <ManagedModal modal={modal} contentLabel={t('ariaLabel')}>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('title') }}
        onCloseButtonClick={onCloseClick}
        showDivider={false}
      />
      <ModalContent direction="y" spacing={spacing.size24}>
        <BodyText>{t('description')}</BodyText>
        <List items={listItems(t)}></List>
      </ModalContent>
      <ModalFooter
        type="actions"
        primaryAction={{ onClick: onConfirmClick, label: t('accept') }}
        secondaryAction={{ onClick: onPostponeClick, label: t('postpone') }}
      />
    </ManagedModal>
  );
});

function listItems(t: TFunction): ListItemProps[] {
  return range(1, 5).map((number) => ({
    text: t(number.toString()),
    size: 'medium',
    type: 'default',
    indentation: false,
    icon: <IconCheck />,
  }));
}

export const showTrialExpiredModal = () => NiceModal.show(TrialExpiredModal);
