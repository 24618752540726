import React, { HTMLAttributes, useCallback, useRef } from 'react';

import { getValueAndUnit, math } from 'polished';
import { useResizeObserver } from 'usehooks-ts';

import styled from '@emotion/styled';

import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { Headline } from '../Headline/Headline';
import { IconChevronRight, IconMoreHorizontal } from '../icons';
import { Breadcrumb as BreadcrumbElement, BreadcrumbProps } from './Breadcrumb/Breadcrumb';
import { ICON_SIZE } from './constants';

export interface Breadcrumb extends Omit<BreadcrumbProps, 'maxWidth'> {
  label: BreadcrumbProps['label'];
  href?: BreadcrumbProps['href'];
  onClick?: BreadcrumbProps['onClick'];
}

interface BreadcrumbListProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  maxBreadcrumbWidth?: number;
  breadcrumbs: Breadcrumb[];
  separator?: 'chevron' | 'slash';
  hasHoverHighlight?: boolean;
}

const StyledTitleWrapper = styled.div`
  padding: ${math(`${spacing.size8} - 1px`)};
`;

export function BreadcrumbList(props: BreadcrumbListProps) {
  const { title, breadcrumbs, maxBreadcrumbWidth = 150, separator = 'chevron', hasHoverHighlight } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver({
    // TODO: TECH-180: fix the ref type issue
    ref: ref as never,
    box: 'border-box',
  });

  const breadcrumbForIndex = useCallback(
    (breadcrumbProps: Breadcrumb, index: number): React.JSX.Element | undefined => {
      const { href, label, onClick } = breadcrumbProps;

      const isHiddenMiddleBreadcrumb = getIsHiddenMiddleBreadcrumb({
        maxBreadcrumbWidth,
        amountOfBreadcrumbs: breadcrumbs.length,
        elementWidth: width ?? 0,
        spaceBetween: getValueAndUnit(spacing.size16)[0],
        index,
      });
      const isFirstHiddenBreadcrumb = isHiddenMiddleBreadcrumb && index === 1;

      if (isFirstHiddenBreadcrumb) {
        return (
          <React.Fragment key="breadcrumb-more">
            <BreadcrumbElement
              icon={<IconMoreHorizontal />}
              maxWidth={maxBreadcrumbWidth}
              hasHoverHighlight={hasHoverHighlight}
            />
            {separator === 'slash' ? '/' : <IconChevronRight />}
          </React.Fragment>
        );
      }

      if (isHiddenMiddleBreadcrumb) {
        return undefined;
      }

      const isFirstItem = index === 0;
      const isLastItem = index === breadcrumbs.length - 1;

      const finalProps: BreadcrumbProps = {
        ...breadcrumbProps,
        label: isFirstItem ? undefined : label,
        href: isLastItem ? undefined : href,
        onClick: isLastItem ? undefined : onClick,
        maxWidth: maxBreadcrumbWidth,
        hasHoverHighlight: hasHoverHighlight,
      };

      return (
        <React.Fragment key={`breadcrumb-${index}`}>
          <BreadcrumbElement {...finalProps} />
          {!isLastItem && (separator === 'slash' ? '/' : <IconChevronRight />)}
        </React.Fragment>
      );
    },
    [maxBreadcrumbWidth, breadcrumbs.length, width, separator, hasHoverHighlight]
  );

  return (
    <div {...props} ref={ref}>
      {breadcrumbs.length <= 1 ? (
        <StyledTitleWrapper>
          <Headline size="small">{title}</Headline>
        </StyledTitleWrapper>
      ) : (
        <SpaceBetween direction="x" spacing={spacing.size0} alignItems="center">
          {breadcrumbs.map(breadcrumbForIndex)}
        </SpaceBetween>
      )}
    </div>
  );
}

const getIsHiddenMiddleBreadcrumb = ({
  maxBreadcrumbWidth,
  amountOfBreadcrumbs,
  elementWidth,
  spaceBetween,
  index,
}: {
  maxBreadcrumbWidth: number;
  amountOfBreadcrumbs: number;
  elementWidth: number;
  spaceBetween: number;
  index: number;
}) => {
  const isFirstBreadcrumb = index === 0;
  const isLastBreadcrumb = index === amountOfBreadcrumbs - 1;

  if (isFirstBreadcrumb || isLastBreadcrumb) return false;

  const widthOfFirstAndLastBreadcrumb = 2 * maxBreadcrumbWidth;
  const allSpacesBetweenBreadcrumbs = amountOfBreadcrumbs - 1 * spaceBetween;
  const indexFromEnd = amountOfBreadcrumbs - 1 - index;
  const widthOfEllipsisCrumb = getValueAndUnit(math(`${ICON_SIZE}px + 2 * ${spacing.size16}`))[0];
  const availableSpace =
    elementWidth - widthOfFirstAndLastBreadcrumb - allSpacesBetweenBreadcrumbs - widthOfEllipsisCrumb;
  return indexFromEnd * maxBreadcrumbWidth > availableSpace;
};
