import _ from 'lodash';
import { AssignmentPin } from '../../assignment';
import { PinEntry } from '../../entries';
import { UserReference, UserReferenceAndPinEntry } from '../UserReference';
import { Poll } from './Poll';

export namespace PollResults {
  export type AnyResults = ScaleResults | EmojiResults | MultipleChoiceResults;
  type UserRefAndEntry = UserReferenceAndPinEntry<PinEntry.Poll>;
  interface Results {
    users: UserReference[];
  }

  interface ScaleResults extends Results {
    type: 'scale';
    average: number;
  }

  interface EmojiResults extends Results {
    type: 'emoji';
    distribution: {
      key: number;
      text: string;
      users: string[];
    }[];
  }

  interface MultipleChoiceResults extends Results {
    type: 'multipleChoice';
    distribution: {
      key: number;
      users: string[];
      url?: string;
      backgroundSize?: 'contain' | 'cover';
      text?: string;
    }[];
  }

  type Result<T extends Poll.PollType> = T extends 'scale'
    ? ScaleResults
    : T extends 'multipleChoice'
    ? MultipleChoiceResults
    : EmojiResults;

  export function fromUserReferenceAndEntries(
    entries: UserReferenceAndPinEntry<PinEntry.Poll>[],
    pin: AssignmentPin.Poll
  ) {
    return pollLessonResults(entries, pin);
  }

  export function pollLessonResults(entries: UserRefAndEntry[], pin: AssignmentPin.Poll) {
    const base = {
      users: entries.map((entry) => entry.user),
    };

    const distribuutionDataMethods = {
      scale: scaleLessonResults,
      multipleChoice: multipleChoiceLessonResults,
      emoji: emojiLessonResults,
    };
    const results = distribuutionDataMethods[pin.item.custom.answerType](entries, pin as any);

    return {
      _id: pin._id,
      ...base,
      ...results,
    };
  }

  function multipleChoiceLessonResults(
    entries: UserRefAndEntry[],
    pin: AssignmentPin<Poll.MultipleChoice>
  ): Omit<MultipleChoiceResults, 'users'> {
    const groupedEntries = _.groupBy(entries, (entry) => entry.pinEntry?.answer?.value);

    const distribution = pin.item.custom.options.map((option, index) => {
      const displayValue =
        option.type === 'image' ? { url: option.url, backgroundSize: option.backgroundSize } : { text: option.text };

      return {
        key: option.value,
        ...displayValue,
        users: groupedEntries[index]?.map((entry) => entry.user._id) || [],
      };
    });

    return {
      type: 'multipleChoice' as const,
      distribution,
    };
  }

  function emojiLessonResults(entries: UserRefAndEntry[], pin: AssignmentPin<Poll.Emoji>): Omit<EmojiResults, 'users'> {
    const groupedEntries = _.groupBy(entries, (entry) => entry.pinEntry?.answer?.value);

    const distribution = pin.item.custom.options.map((option, index) => ({
      key: index,
      text: option,
      users: groupedEntries[index]?.map((entry) => entry.user._id) || [],
    }));

    return {
      type: 'emoji' as const,
      distribution,
    };
  }

  export function scaleLessonResults(entries: UserRefAndEntry[], pin: AssignmentPin<Poll.Scale>) {
    const entriesWithAnswer = entries.filter((e) => e.pinEntry?.answer);

    return {
      type: 'scale' as const,
      average: averageForEntries(_.compact(entriesWithAnswer.map((entry) => entry.pinEntry))),
    };
  }
}

export function entriesPerPercentileForScale(entries: PinEntry.Poll[], scaleSize: number) {
  return _.groupBy(entries, (entry) => {
    const value = (entry.answer!.value / scaleSize) * 100;

    const percentile = Math.ceil((value + 1) / 10) * 10;
    return percentile;
  });
}

export function averageForEntries(pinEntries: PinEntry.Poll[]): number {
  const entriesWithAnswer = _.filter(pinEntries, (entry) => entry && typeof entry.answer?.value !== 'undefined');

  return entriesWithAnswer.map((entry) => entry.answer).reduce((a, b) => a + b!.value, 0) / entriesWithAnswer.length;
}
