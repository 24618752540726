import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 16.837v4.27m-3.89 0h7.78m-6.368-9.591c.252.329 1.09.987 2.428.987s2.084-.658 2.29-.987M8.953 8.558v-.735m6.094.735v-.735M12 2.893c-2.425 0-4.963 0-7.073.405a2.42 2.42 0 0 0-1.878 1.809C2.7 6.517 2.7 7.567 2.7 9.869s0 3.35.349 4.761a2.42 2.42 0 0 0 1.878 1.81c2.11.404 4.648.404 7.073.404s4.963 0 7.073-.404a2.42 2.42 0 0 0 1.878-1.81c.349-1.41.349-2.46.349-4.761s0-3.351-.349-4.762a2.42 2.42 0 0 0-1.878-1.81c-2.11-.403-4.648-.403-7.073-.403"
    />
  </svg>
);
export default SvgIconDesktop;
