import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AccountDeletionRequestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountDeletionRequestQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, accountDeletionRequest?: { __typename?: 'AccountDeletionRequest', id: string, scheduledAt: string } | null } };

export type DeleteAccountConfirmationPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteAccountConfirmationPageQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, name?: string | null } };

export type RequestUserAccountDeletionMutationVariables = Types.Exact<{
  input: Types.RequestUserAccountDeletionInput;
}>;


export type RequestUserAccountDeletionMutation = { __typename?: 'Mutation', requestUserAccountDeletion: { __typename?: 'RequestUserAccountDeletionPayload', userAccountDeletionRequest: { __typename?: 'AccountDeletionRequest', id: string } } };

export type CancelAccountDeletionRequestMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CancelAccountDeletionRequestMutation = { __typename?: 'Mutation', cancelAccountDeletionRequest: { __typename?: 'CancelAccountDeletionPayload', canceledAccountDeletionRequest: string } };


export const AccountDeletionRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AccountDeletionRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"accountDeletionRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scheduledAt"}}]}}]}}]}}]} as unknown as DocumentNode<AccountDeletionRequestQuery, AccountDeletionRequestQueryVariables>;
export const DeleteAccountConfirmationPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DeleteAccountConfirmationPage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<DeleteAccountConfirmationPageQuery, DeleteAccountConfirmationPageQueryVariables>;
export const RequestUserAccountDeletionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestUserAccountDeletion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestUserAccountDeletionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestUserAccountDeletion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userAccountDeletionRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<RequestUserAccountDeletionMutation, RequestUserAccountDeletionMutationVariables>;
export const CancelAccountDeletionRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelAccountDeletionRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelAccountDeletionRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canceledAccountDeletionRequest"}}]}}]}}]} as unknown as DocumentNode<CancelAccountDeletionRequestMutation, CancelAccountDeletionRequestMutationVariables>;