import {
  isAnswerAreaPinComponent,
  isInteractiveQuestionPinTextPinComponent,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import { BodyText, Button, color, IconRemove, styled } from '@lessonup/ui-components';
import React from 'react';
import { ContextSidebarGroup } from '../../../../../../../components/ContextSidebar/ContextSidebarGroup';
import { useEditorContext } from '../../../../../context/EditorContext/EditorContext';
import {
  useNonNullActivePin,
  useSelectedPinComponent,
  useSelectedPinComponentIds,
} from '../../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../../hooks/useEditorTranslation';
import { disabledDelete } from '../../../../../utils/disabledActions.utils';

export const RemovePinComponentSection: React.FC = () => {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const selectedPinComponentIds = useSelectedPinComponentIds();
  const { resetSelection } = useEditorContext();
  const pinComponent = useSelectedPinComponent();
  const pin = useNonNullActivePin();

  const deletePinComponents = () => {
    dispatch({
      type: 'deletePinComponents',
      pinComponentIds: selectedPinComponentIds,
      onComplete: () => {
        resetSelection();
      },
    });
  };

  function disabledDeleteContext() {
    if (!pinComponent) return '';

    if (selectedPinComponentIds.length === 1) {
      if (isAnswerAreaPinComponent(pinComponent)) {
        return t('contextSidebar.removeDisabledContextAnswerArea');
      }

      if (isInteractiveQuestionPinTextPinComponent(pin, pinComponent)) {
        return t('contextSidebar.removeDisabledContextInteractiveQuestion');
      }
    }

    return t('contextSidebar.removeDisabledMultiSelection');
  }

  return (
    <ContextSidebarGroup>
      {!disabledDelete() ? (
        <Button buttonType="negative" resizing="hug" iconStart={<IconRemove />} onClick={deletePinComponents}>
          {t('contextSidebar.remove')}
        </Button>
      ) : (
        <StyledDisabledRemoveSection>
          <Button buttonType="negative" resizing="hug" iconStart={<IconRemove />} disabled={true}>
            {t('contextSidebar.remove')}
          </Button>
          <BodyText size="small">{disabledDeleteContext()}</BodyText>
        </StyledDisabledRemoveSection>
      )}
    </ContextSidebarGroup>
  );
};

const StyledDisabledRemoveSection = styled.div`
  color: ${color.additional.disabled.text};
`;
