import { SlideDeckUpload } from '../../../types/graphql';
import { ChildUploadFolder } from '../../Uploads/Uploads.graphql';

export interface BaseConversion {
  uploadId: string;
  name: string;
  createdAt: Date;
}

export type ProgressConversion = BaseConversion & { status: 'UPLOADING' | 'PROCESSING' };
export type ErrorConversion = BaseConversion & { status: 'ERROR' };
export type ReadyConversion = BaseConversion & { status: 'READY'; lessonId: string };
export type Conversion = ProgressConversion | ErrorConversion | ReadyConversion;
export type CompletedConversion = ReadyConversion | ErrorConversion;

export const isProgressConversion = (conversion: unknown): conversion is ProgressConversion =>
  ['UPLOADING', 'PROCESSING'].includes((conversion as Conversion).status);

export const isReadyConversion = (conversion: unknown): conversion is ReadyConversion =>
  (conversion as ReadyConversion).status === 'READY';

export const isErrorConversion = (conversion: unknown): conversion is ErrorConversion =>
  (conversion as ErrorConversion).status === 'ERROR';

export const isCompletedConversion = (conversion: unknown): conversion is CompletedConversion =>
  isReadyConversion(conversion) || isErrorConversion(conversion);

export const isSlideDeckUpload = (upload: Pick<ChildUploadFolder, '__typename'>): upload is SlideDeckUpload =>
  upload.__typename === 'SlideDeckUpload';
