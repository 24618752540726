import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';
import { EduSystemBase } from './EduSystemBase';
import {
  eduSystemEditDataDuration,
  eduSystemEditDataSchoolType,
  eduSystemEditDataSubject,
  eduSystemEditDataYear,
} from './eduSystemShared';

const i18n = globalI18nProxy();

type SchoolType = 'pe' | 'se' | 'te' | 've' | 'unv' | 'sp';
export type EduSystemIntSchoolType = SchoolType;
export class EduSystemINT extends EduSystemBase<SchoolType> {
  public key = 'INT';
  public country() {
    return 'int' as const;
  }
  public constructor() {
    super('se');
  }
  public EDIT_DATA() {
    return [
      eduSystemEditDataDuration(() => i18n.__('Lesduur')),
      eduSystemEditDataSchoolType(() => i18n.__('School')),
      eduSystemEditDataYear(() => i18n.__('Leeftijd')),
      eduSystemEditDataSubject(() => i18n.__('Vak'), ['subjects', 'INTSubjects']),
    ];
  }
  protected _SYSTEM_DATA() {
    const dict: EduSystem.SystemDataDict<SchoolType> = {
      pe: {
        id: 'pe',
        short: 'PE',
        label: 'Primary Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'years',
            type: 'toggle',
            label: 'Age',
            labeler: 'LabelValue',
            options: [
              { id: 4, value: '4' },
              { id: 5, value: '5' },
              { id: 6, value: '6' },
              { id: 7, value: '7' },
              { id: 8, value: '8' },
              { id: 9, value: '9' },
              { id: 10, value: '10' },
              { id: 11, value: '11' },
              { id: 12, value: '12' },
              { id: 13, value: '13' },
            ],
          },
        ],
      },

      se: {
        id: 'se',
        short: 'SE',
        label: 'Secondary Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'years',
            type: 'toggle',
            label: 'Age',
            labeler: 'LabelValue',
            options: [
              { id: 11, value: '11' },
              { id: 12, value: '12' },
              { id: 13, value: '13' },
              { id: 14, value: '14' },
              { id: 15, value: '15' },
              { id: 16, value: '16' },
              { id: 17, value: '17' },
              { id: 18, value: '18' },
              { id: 19, value: '19' },
            ],
          },
        ],
      },

      te: {
        id: 'te',
        short: 'TE',
        label: 'Tertiary Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
        ],
      },

      ve: {
        id: 've',
        short: 'VE',
        label: 'Vocational Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
        ],
      },

      unv: {
        id: 'unv',
        short: 'UNV',
        label: 'University',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
        ],
      },

      sp: {
        id: 'sp',
        short: 'SP',
        label: 'Special Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
        ],
      },
    };
    return dict;
  }

  public labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
    const { subjects, schoolType } = metaData;
    const tags: EduSystem.MetaDataTags = {
      subjects,
      schoolType: _.compact(schoolType?.map((st) => this.SYSTEM_DATA_DICT[st]?.label)),
      levels: [],
      years: [],
    };
    const value = this.getFieldSummary({ schoolType: 'pe', lesson: metaData, fieldName: 'years' });
    if (value) {
      tags.years.push(`Age ${value}`);
    }
    return tags;
  }
}
