import { NiceModalHandler, ReactModal, zIndex } from '@lessonup/ui-components';
import React from 'react';

interface PlayerModalProps {
  contentLabel: string;
  modal: NiceModalHandler;
  onClose?: () => void;
  children: React.ReactNode;
}

export const PlayerModal: React.FC<PlayerModalProps> = ({ contentLabel, modal, onClose, children }) => {
  return (
    <ReactModal
      contentLabel={contentLabel}
      isOpen={modal.visible}
      shouldCloseOnEsc={true}
      onAfterClose={() => modal.remove()}
      onRequestClose={() => {
        onClose?.();
        modal.hide();
      }}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          zIndex: zIndex.modal,
          overflowY: 'auto',
        },
        content: {
          border: 0,
          margin: 'auto',
          position: 'relative',
          width: '100vw',
          height: '100vh',
          display: 'flex', // needed when having a 'height', this makes it possible to stretch child elements
          flexDirection: 'column' as const,
          padding: 0,
          inset: 0,
        },
      }}
    >
      {children}
    </ReactModal>
  );
};
