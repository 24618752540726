import {
  breakpoints,
  FilePreview,
  Grid,
  Headline,
  math,
  SpaceBetween,
  spacing,
  styled,
  Thumb,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaiaExample } from '../../../types/graphql';
import { MaiaLessonType } from '../ChatGPTGeneratorFeature.utils';

interface MaiaExamplesProps {
  exampleType: MaiaLessonType;
  maiaExamples: MaiaExample[];
  onExamplesClick: (examplePrompt: string) => void;
  selectedUploadExample?: string;
}

export const MaiaExamples: React.FC<MaiaExamplesProps> = ({
  exampleType,
  maiaExamples,
  onExamplesClick,
  selectedUploadExample,
}) => {
  const { t } = useTranslation('chatGPT');

  return (
    <FullWidth direction="y" spacing={spacing.size16}>
      <Headline size="small">
        {exampleType === 'lesson' ? t('maiaExamples.headingPrompt') : t('maiaExamples.headingContext')}
      </Headline>
      <StyledGrid gap={spacing.size16} autoColumns="auto" justifyItems="center">
        {maiaExamples.map((example, i) => (
          <Thumb
            key={`thumb-${i + example.title}`}
            thumbType="caption"
            number={i + 1}
            caption={example.title}
            onClick={() => onExamplesClick(example.text)}
            isActive={selectedUploadExample === example.text}
          >
            <StyledFilePreview key={i + example.title} type="image" src={example.image} alt={example.image} />
          </Thumb>
        ))}
      </StyledGrid>
    </FullWidth>
  );
};

const StyledFilePreview = styled(FilePreview)`
  height: 97.5px;
`;

const StyledGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fill, minmax(${math(`${breakpoints.tablet} / 3`)}, 1fr));
  }
`;

const FullWidth = styled(SpaceBetween)`
  width: 100%;
`;
