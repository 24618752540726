import { isFunction } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useBoolean, useEventListener } from 'usehooks-ts';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitEnterFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

function requestFullscreen(el: HTMLElement) {
  return (
    el.requestFullscreen?.() ||
    el.msRequestFullscreen?.() ||
    el.webkitEnterFullscreen?.() ||
    el.webkitRequestFullscreen?.() ||
    el.mozRequestFullscreen?.()
  );
}

function cancelFullscreen() {
  if (isFunction(document.exitFullscreen)) return document.exitFullscreen();
  if (isFunction(document.msExitFullscreen)) return document.msExitFullscreen();
  if (isFunction(document.webkitExitFullscreen)) return document.webkitExitFullscreen();
  if (isFunction(document.mozCancelFullScreen)) return document.mozCancelFullScreen();
}

function isFullscreen() {
  return (
    document.msFullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.fullscreenElement
  );
}

export function useFullscreenToggle(): [boolean, () => void] {
  const fullscreen = useBoolean(false);

  const toggleFullscreen = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (!isFullscreen()) {
        requestFullscreen(document.documentElement);
        fullscreen.setTrue();
      } else {
        cancelFullscreen();
        fullscreen.setFalse();
      }
    });
  }, [fullscreen]);

  useEffect(() => {
    return () => {
      if (isFullscreen()) {
        cancelFullscreen();
      }
    };
  }, []);

  const documentRef = useRef(document);
  useEventListener(
    'fullscreenchange',
    () => {
      const newValue = !!isFullscreen();
      if (fullscreen.value !== newValue) {
        fullscreen.setValue(newValue);
      }
    },
    documentRef
  );

  return [fullscreen.value, toggleFullscreen];
}
