import { BodyText, color, IconBook, IconFile, List, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaiaLessonType } from '../ChatGPTGeneratorFeature.utils';

interface SidebarChooseMaiaModeProps {
  activeLessonType: MaiaLessonType;
  handleMaiaModeSwitch: () => void;
}

export const SidebarChooseMaiaMode = ({ activeLessonType, handleMaiaModeSwitch }: SidebarChooseMaiaModeProps) => {
  const { t } = useTranslation('chatGPT');

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      <div>
        <BodyText size="medium" weight="bold">
          {t('sidebarChooseMaiaMode.title')}
        </BodyText>
        <StyledSubText size="small">{t('sidebarChooseMaiaMode.subTitle')}</StyledSubText>
      </div>

      <List
        border
        items={[
          {
            text: t('chooseMaiaMode.promptTitle'),
            secondLine: t('sidebarChooseMaiaMode.promptSubTitle'),
            roundedCorners: true,
            onClick: handleMaiaModeSwitch,
            type: activeLessonType === 'lesson' ? 'active' : 'default',
            icon: <IconFile />,
            indentation: true,
          },
          {
            text: t('chooseMaiaMode.textTitle'),
            secondLine: t('sidebarChooseMaiaMode.textSubTitle'),
            roundedCorners: true,
            onClick: handleMaiaModeSwitch,
            type: activeLessonType === 'contextLesson' ? 'active' : 'default',
            icon: <IconBook />,
            indentation: true,
          },
        ]}
      />
    </SpaceBetween>
  );
};

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
