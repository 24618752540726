import { Route } from '@lessonup/client-integration';
import { Language } from '@lessonup/utils';

interface SearchRouteParams {
  language: string;
  country?: string;
}

export const searchRoute = new Route<SearchRouteParams>(
  'searchRoute',
  { language: ':language', country: undefined },
  ({ language, country = '' }) => {
    return `/${language}/lessons/${country}`;
  }
);

interface LoginRouteParams {
  language: Language | ':language';
}
export const loginRoute = new Route<LoginRouteParams>('loginRoute', { language: ':language' }, ({ language }) => {
  return `/${language}/login`;
});

interface MySchoolRouteParams {
  language: string;
  schoolChannelSlug: string;
}
export const mySchoolRoute = new Route<MySchoolRouteParams>(
  'schoolChannelRoute',
  { language: ':language', schoolChannelSlug: ':slug' },
  ({ language, schoolChannelSlug }) => `/${language}/channel/${schoolChannelSlug}`
);

interface ChannelsRouteParams {
  language: string;
}

export const channelsRoute = new Route<ChannelsRouteParams>(
  'channelsRoute',
  { language: ':language' },
  ({ language }) => `/${language}/channels/`
);

interface ChannelRouteParams {
  language: string;
  slug: string;
}

export const channelRoute = new Route<ChannelRouteParams>(
  'channelRoute',
  { language: ':language', slug: ':slug' },
  ({ language, slug }) => `/${language}/channel/${slug}`
);

export interface ReferralRouteParams {
  token: string;
  language: string;
  step?: string;
  organizationId?: string;
}

export const referralRoute = new Route<ReferralRouteParams>(
  'referralRoute',
  { token: ':token', language: ':language', step: ':step?', organizationId: ':organizationId?' },
  ({ token, language, step, organizationId }) => {
    const path = [language, 'referral', token, step, step ? organizationId : undefined].filter(Boolean).join('/');
    return `/${path}`;
  }
);

export interface VoucherRouteParams {
  language?: Language | ':language';
  redirectUrl?: string;
}

export const voucherRoute = new Route<VoucherRouteParams>(
  'voucherRoute',
  { language: ':language', redirectUrl: undefined },
  ({ language, redirectUrl }) => {
    return `/voucher/${language}${redirectUrl ? `?redirect-url=${encodeURIComponent(redirectUrl)}` : ''}`;
  }
);
