import { KeysOfUnion, randomId } from '@lessonup/utils';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Fonts } from '../fonts';
import { Pin, PinItem } from '../Pin';
import { UserReference } from '../UserReference';
import { AudioComponent } from './audio/AudioComponent';
import { DropzoneComponent } from './dropzone/DropzoneComponent';
import { FormulaComponent } from './formula/FormulaComponent';
import { HotspotComponent } from './hotspot/HotspotComponent';
import { ImageComponent } from './image/ImageComponent';
import { SpinnerComponent } from './spinner/SpinnerComponent';
import { SymbolComponent } from './symbol/SymbolComponent';
import { TableComponent } from './table/TableComponent';
import { TextComponent } from './text/TextComponent';
import { TimerComponent } from './timer/TimerComponent';
import { TrafficLightComponent } from './trafficLight/TrafficLightComponent';
import { VideoComponent } from './video/VideoComponent';

export interface Component {
  _id: Component.Id;
  type: Component.Type;
  position: Component.PositionSetting;
  rotation?: number;
  settings: Component.Settings;
  scale: {
    x: number;
    y: number;
  };
  scaleProps?: {
    [key: string]: any; // TODO: identify all scaleProps for said component types
  };
  html?: string; // TODO: only relevant for formulas atm?
  [key: string]: any; // TODO: implement all components in typescript, so this fallback can be removed
}

export namespace Component {
  export type Id = string;

  export interface ComponentForType {
    [AudioComponent.type]: AudioComponent;
    [FormulaComponent.type]: FormulaComponent;
    [HotspotComponent.type]: HotspotComponent;
    [ImageComponent.type]: ImageComponent;
    [SpinnerComponent.type]: SpinnerComponent;
    [TextComponent.type]: TextComponent;
    [TimerComponent.type]: TimerComponent;
    [TrafficLightComponent.type]: TrafficLightComponent;
    [VideoComponent.type]: VideoComponent;
    [DropzoneComponent.type]: DropzoneComponent;
    [SymbolComponent.type]: SymbolComponent;
    [TableComponent.type]: TableComponent;
  }

  export type Type = keyof ComponentForType;
  export type All = ComponentForType[Type];

  export type Audio = AudioComponent;
  export type Formula = FormulaComponent;
  export type Hotspot = HotspotComponent;
  export type Image = ImageComponent;
  export type Spinner = SpinnerComponent;
  export type Text = TextComponent;
  export type Timer = TimerComponent;
  export type TrafficLight = TrafficLightComponent;
  export type Video = VideoComponent;
  export type Dropzone = DropzoneComponent;
  // `Symbol` is reserved because it's a built-in function
  export type SymbolComp = SymbolComponent;
  export type Table = TableComponent;

  export interface BoxComponent extends Component {
    position: Box;
  }

  type AllPossibleSettings = KeysOfUnion<All['settings']>;

  export function getSetting(comp: Partial<Component.All>, setting: AllPossibleSettings): any | undefined {
    const settings = comp.settings;
    if (settings && settings[setting] !== undefined) return settings[setting];
  }

  export interface Settings {
    correctDropzone?: Id[];
    isDraggable?: boolean;
    dropzoneSnapback?: boolean;
    presentationMovable?: boolean;
    borderRadius?: '0' | '0.4em' | '100%' | undefined;
  }

  export namespace Settings {
    export type VerticalAlign = 'vertical-align-top' | 'vertical-align-center' | 'vertical-align-bottom';
    export type TextAlign = 'align-left' | 'align-center' | 'align-right';
    export type imageSize = 'contain' | 'cover';
  }

  export interface Position {
    top: number;
    left: number;
  }

  export interface Box extends Position {
    bottom: number;
    right: number;
  }

  export type BoxBottomRightOrigin = 'top-left' | 'bottom-right';

  export interface BBox extends Box {
    width: number;
    height: number;
    center: {
      x: number;
      y: number;
    };
  }

  export interface PlayerData {
    students: UserReference[];
  }

  // TODO: playerdata should always have students
  export function playerData(data: Partial<PlayerData>): PlayerData {
    return {
      students: data.students && data.students.length ? data.students : [],
    };
  }

  export function fromPin(pin: Pin, componentId: string | undefined) {
    const components = pin.item.components || [];
    return components.find((c) => c._id === componentId);
  }

  export function index(pin: Pin, componentId: string | undefined) {
    const components = pin.item.components || [];
    return components.findIndex((c) => c._id === componentId);
  }

  export function playerDataStream(obs: Observable<Partial<PlayerData>>): Observable<PlayerData> {
    return obs.pipe(
      map((data) => playerData(data)),
      distinctUntilChanged(_.isEqual)
    );
  }

  export type PositionSetting = Position | Box;

  export function isBox(position: PositionSetting): position is Box {
    return 'bottom' in position && 'right' in position;
  }

  export function isPortraitInDom(bbox: BBox) {
    return bbox.width * Pin.aspectRatio.width > bbox.height * Pin.aspectRatio.height;
  }

  export function getBBox(position: Box): BBox {
    const { left, right, top, bottom } = position;
    const width = 100 - left - right;
    const height = 100 - top - bottom;
    return {
      left,
      right,
      top,
      bottom,
      height,
      width,
      center: {
        x: left + width / 2,
        y: top + height / 2,
      },
    };
  }

  export function isAudio(comp: Component): comp is Audio {
    return comp.type === 'audio';
  }

  export function isImage(comp: Component): comp is Image {
    return comp.type === 'image';
  }

  export function isVideo(comp: Component): comp is Video {
    return comp.type === 'video';
  }

  export function isText(comp: Component): comp is Text {
    return comp.type === 'text';
  }

  export function isTimer(comp: Component): comp is Timer {
    return comp.type === 'timer';
  }

  export function isHotspot(comp: Component): comp is Hotspot {
    return comp.type === 'hotspot';
  }

  export function isSymbol(comp: Component): comp is SymbolComp {
    return comp.type === 'symbol';
  }

  export function isTable(comp: Component): comp is Table {
    return comp.type === 'table';
  }

  export function isDropzone(comp: Component): comp is Dropzone {
    return comp.type === 'dropzone';
  }

  export function isFormula(comp: Component): comp is Dropzone {
    return comp.type === 'formula';
  }

  export function fontsForComponent(component: Component): Fonts.Key[] {
    if (isText(component)) {
      return [component.settings.fontFamily];
    }

    if (isTable(component)) {
      return [component.settings.fontFamily];
    }
    return [];
  }

  export function firstText(components: Component[]): string | undefined {
    const TextComponents = components.filter(Component.isText);
    for (const comp of TextComponents) {
      if (comp.settings.text) {
        return comp.settings.text;
      }
    }
  }

  const componentsWithAdjustableRatio: Component.Type[] = ['text', 'image', 'video', 'dropzone'];
  export function isFixedAspectRatio(component: Component.All): boolean {
    if (component.type === 'symbol') {
      return SymbolComponent.symbolForName(component.settings.symbol).startWithFixedAspectRatio;
    }
    return !componentsWithAdjustableRatio.includes(component.type);
  }

  export function isAdjustableAspectRatio(component: Component.All): boolean {
    return !isFixedAspectRatio(component);
  }

  const rotatable: Component.Type[] = ['text', 'image', 'symbol'];
  export function canRotate(component: Component.All): boolean {
    return rotatable.includes(component.type);
  }

  export function canOnlyScaleHorizontally(component: Component.All): boolean {
    return ['table'].includes(component.type);
  }

  export function isPartOfDragQuestion(component: Component.All): boolean {
    return !!component.settings?.isDraggable;
  }

  export function cloneWithRelations(item: PinItem<any>) {
    if (!item || !item.components || !item.components.length) return [];
    const { components } = item;
    const newIds = {};
    // generate clones from old list.
    const newComponents: Component.All[] = components.map((c) => {
      // new Ids
      const id = randomId();
      // create mapping from old ids to new
      newIds[c._id] = id;
      const c2 = _.cloneDeep(c);
      c2._id = id;
      return c2;
    });

    // fix for media triggers
    newComponents.forEach((c) => {
      if (c.type == 'hotspot' && c.settings.mediaTrigger) {
        c.settings.mediaTrigger = newIds[c.settings.mediaTrigger];
      }
    });

    // fix for dragquestions
    newComponents
      .filter((c) => c.settings && c.settings.correctDropzone && c.settings.correctDropzone.length)
      .forEach((c) => {
        if (!c.settings.correctDropzone) return;
        c.settings.correctDropzone = c.settings.correctDropzone
          .map((dzId) => newIds[dzId])
          // just to be safe
          .filter((dzId) => dzId);
      });

    return newComponents;
  }
}
