import { List, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

interface StudentNamesListProps {
  studentNames: string[];
}
export const StudentNamesList: React.FC<StudentNamesListProps> = ({ studentNames }) => {
  return (
    <StyledListWrapper>
      <List
        items={studentNames.map((name) => {
          return { text: name };
        })}
      />
    </StyledListWrapper>
  );
};

const StyledListWrapper = styled.div`
  padding: ${spacing.size12};
  word-break: break-word;
  width: 256px;
  max-height: 320px;
  height: auto;
  overflow-y: auto;
`;
