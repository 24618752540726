import { useLocale } from '@lessonup/client-integration';
import {
  AssetLogoLessonUp,
  BodyText,
  borderRadius,
  BreadcrumbList,
  ButtonAnchor,
  color,
  IconLockClosed,
  IconOpenInNew,
  IconVoucher,
  Introduction,
  rem,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { channelRoute, loginRoute, voucherRoute } from '../../utils/bridge/searchRoutes';

export interface EmbedErrorPageProps {
  channel?: {
    title: string;
    slug: string;
  };
  style?: React.CSSProperties;
}

const logoSize = {
  width: rem('72px'),
  height: rem('12px'),
};

/**
 * Error page for embedded lessons. Note: only usable for a 403 error at the moment (someone is not logged in or did not redeem the right voucher)
 */
export const EmbedErrorPage = ({ channel, style }: EmbedErrorPageProps) => {
  const { t } = useTranslation('embedErrorPage');
  const locale = useLocale();

  return (
    <Background style={style}>
      <Main direction="y" spacing={spacing.size16}>
        <Header direction="x" spacing={spacing.size4}>
          <div>
            {channel ? (
              <BreadcrumbList
                title={t('noAccess')}
                breadcrumbs={[
                  {
                    label: 'LessonUp',
                    icon: <AssetLogoLessonUp />,
                    iconDimensions: logoSize,
                    href: '/',
                  },
                  {
                    label: channel.title,
                    href: channelRoute.href({ language: locale.language, slug: channel.slug }),
                  },
                ]}
              />
            ) : (
              <ButtonHome href="/" buttonType="neutral" iconStart={<AssetLogoLessonUp />} />
            )}
          </div>
          <ButtonAnchor
            buttonType="neutral"
            iconEnd={<IconOpenInNew />}
            href={loginRoute.href({ language: locale.language })}
            target="_blank"
            rel="noopener"
          >
            {t('login')}
          </ButtonAnchor>
        </Header>
        <Body>
          <TopGroup direction="x" spacing={spacing.size16}>
            <TopSection>
              <SpaceBetween direction="y" spacing={spacing.size16}>
                <Introduction
                  size="M"
                  alignment="start"
                  icon={<IconLockClosed />}
                  tagline={t('noAccess')}
                  headline={t('needPermissions')}
                  bodyText={t('explanation')}
                  buttons={{
                    primary: {
                      href: voucherRoute.href({ language: locale.language }),
                      target: '_blank',
                      rel: 'noopener',
                      iconStart: <IconVoucher />,
                      children: t('redeemVoucher'),
                    },
                  }}
                />
                <BodyText size="small">
                  {t('alreadyEnteredAVoucher')}{' '}
                  <a href={loginRoute.href({ language: locale.language })} target="_blank" rel="noopener noreferrer">
                    {t('logInTo')}
                  </a>
                </BodyText>
              </SpaceBetween>
            </TopSection>
          </TopGroup>
          {channel && (
            <BottomGroup direction="y" spacing={spacing.size16}>
              <div>
                <ButtonAnchor
                  href={channelRoute.href({ language: locale.language, slug: channel.slug })}
                  target="_blank"
                  rel="noopener"
                  buttonType="neutral"
                  iconEnd={<IconOpenInNew />}
                >
                  {t('visitChannel')}
                </ButtonAnchor>
              </div>
            </BottomGroup>
          )}
        </Body>
        <Footer direction="x" spacing={spacing.size12} justifyContent="space-between">
          <BodyText size="small">{t('makeEveryClass')}</BodyText>
          <BodyText size="small">
            <a href="/" target="_blank" rel="noopener">
              {'lessonup.com'}
            </a>
          </BodyText>
        </Footer>
      </Main>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${color.neutral.surface1.background};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: ${spacing.size24} ${spacing.size16};
`;

const Main = styled(SpaceBetween)`
  width: 100%;
  max-width: ${rem('640px')};
`;

const Header = styled(SpaceBetween)`
  justify-content: space-between;
`;

const Body = styled.div`
  border-radius: ${borderRadius.rounded16};
  background-color: ${color.neutral.surface2.background};
`;

const TopGroup = styled(SpaceBetween)`
  border-radius: ${borderRadius.rounded16};
  background-color: ${color.neutral.surface.background};
  padding: ${spacing.size24};
`;

const TopSection = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const BottomGroup = styled(SpaceBetween)`
  padding: ${spacing.size24};
`;

const ButtonHome = styled(ButtonAnchor)`
  svg {
    height: ${logoSize.height};
    width: ${logoSize.width};
  }
`;

const Footer = styled(SpaceBetween)``;
