import _ from 'lodash';
import { AssignmentMeta } from '.';
import { Grade, TestPhase } from '../common';
import { AssignmentEntry } from '../entries';

export interface AssignmentSummary {
  modifiedAt: number;
  numberOfUsers: number;
  numberOfUsersSeen: number;
  percentagePinsDone: number;

  phaseCounts?: TestPhase.TotalCount;
  gradedCount: number; // number of entries with a grade
  isActive?: boolean; // if the test is currently active

  // TODO for tests
  submittedCount: number;

  // caching & invalidation properties
  isDirty: boolean;
  dirtyDate?: number; // first time this summary became dirty
}

export namespace AssignmentSummary {
  export function create(meta: AssignmentMeta, allEntries: AssignmentEntry[]): AssignmentSummary {
    const entries = AssignmentEntry.filterByStatus(allEntries, ['default', 'left']);

    // calculate summary
    var countSeen = 0,
      percentage = 0,
      submittedCount = 0,
      gradedCount = 0;

    // calculate the calculated fields.
    entries.forEach((entry) => {
      if (_.gt(entry.seenAt, 0) || AssignmentEntry.isAnyPinSeen(entry)) countSeen++;
      if (AssignmentEntry.isTestPhase(entry, 'done')) submittedCount++;
      if (Grade.isGrade(entry.testData && entry.testData.grade)) gradedCount++;

      percentage += AssignmentEntry.calcPercentagePinsDone(entry);
    });

    const phaseCounts = AssignmentEntry.countPhases(entries);
    const earliestPhase = TestPhase.earliestTestPhase(phaseCounts);
    const numberOfUsers = entries.length;

    const summary: AssignmentSummary = {
      ...(meta.summary || AssignmentSummary.empty()),
      isActive: TestPhase.isLive(earliestPhase),
      phaseCounts: phaseCounts,
      isDirty: false,
      gradedCount,
      numberOfUsersSeen: countSeen,
      submittedCount: submittedCount,
      numberOfUsers,
      percentagePinsDone: numberOfUsers === 0 ? 0 : Math.round(percentage / numberOfUsers),
    };
    return summary;
  }

  export function empty(): AssignmentSummary {
    // why 2015???????
    const d = new Date('2015-01-01 01:00:00');

    return {
      modifiedAt: Date.now(),
      numberOfUsers: 0,
      numberOfUsersSeen: 0,
      percentagePinsDone: 0,
      submittedCount: 0,
      gradedCount: 0,
      isDirty: true, // default, this summary is dirty, upon request is can will be updated
      dirtyDate: d.getTime(),

      isActive: true,
    };
  }
}
