import styled from '@emotion/styled';
import { color } from '../../foundations/colors/colors';
import { BodyText } from '../BodyText/BodyText';

export type TooltipDirection =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-left'
  | 'top-right';

export type Resize = 'fixed' | 'hug';

export interface TooltipPosition {
  top: number;
  left: number;
}

export interface SharedTooltipProps {
  children: React.ReactNode;
  direction?: TooltipDirection;
  content?: string | React.ReactNode;
  shortcut?: string;
  resize?: Resize;
  zIndexOverride?: number;
}

export interface TooltipProps extends SharedTooltipProps {
  children: React.ReactNode;
  id?: string;
  display?: React.CSSProperties['display'];
  hide?: boolean;
  mode?: 'hover' | 'trigger';
  className?: string;
}

export interface TooltipChildProps extends React.HTMLAttributes<HTMLElement> {
  'data-tip'?: string;
  id?: string;
}

export type PortalTooltipProps = SharedTooltipProps;

export const StyledText = styled(BodyText)`
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  color: ${color.highContrast.surface.text};
  flex-shrink: 0;
  flex: 1 0;
`;

export const StyledShortcut = styled(BodyText)`
  color: ${color.highContrast.disabled.text};
  flex-shrink: 0;
`;

export const calculateHorizontalCenter = (wrapperRect: DOMRect, tooltipRect: DOMRect): number => {
  return wrapperRect.left + (wrapperRect.width - tooltipRect.width) / 2;
};

export const calculateVerticalCenter = (wrapperRect: DOMRect, tooltipRect: DOMRect): number => {
  return wrapperRect.top + (wrapperRect.height - tooltipRect.height) / 2;
};

export const calculateVerticalPosition = (
  wrapperRect: DOMRect,
  tooltipRect: DOMRect,
  elementDistance: number,
  isTop: boolean
): number => {
  return isTop ? wrapperRect.top - tooltipRect.height - elementDistance : wrapperRect.bottom + elementDistance;
};

export const calculateHorizontalPosition = (
  wrapperRect: DOMRect,
  tooltipRect: DOMRect,
  elementDistance: number,
  isLeft: boolean
): number => {
  return isLeft ? wrapperRect.left - tooltipRect.width - elementDistance : wrapperRect.right + elementDistance;
};

export const calculateTooltipPosition = (
  wrapperRect: DOMRect,
  tooltipRect: DOMRect,
  direction: TooltipDirection,
  elementDistance = 8 // default distance
): TooltipPosition => {
  switch (direction) {
    case 'top':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, true),
        left: calculateHorizontalCenter(wrapperRect, tooltipRect),
      };
    case 'bottom':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, false),
        left: calculateHorizontalCenter(wrapperRect, tooltipRect),
      };
    case 'right':
      return {
        top: calculateVerticalCenter(wrapperRect, tooltipRect),
        left: calculateHorizontalPosition(wrapperRect, tooltipRect, elementDistance, false),
      };
    case 'left':
      return {
        top: calculateVerticalCenter(wrapperRect, tooltipRect),
        left: calculateHorizontalPosition(wrapperRect, tooltipRect, elementDistance, true),
      };
    case 'bottom-left':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, false),
        left: wrapperRect.left,
      };
    case 'bottom-right':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, false),
        left: wrapperRect.right - tooltipRect.width,
      };
    case 'top-left':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, true),
        left: wrapperRect.left,
      };
    case 'top-right':
      return {
        top: calculateVerticalPosition(wrapperRect, tooltipRect, elementDistance, true),
        left: wrapperRect.right - tooltipRect.width,
      };
    default:
      throw new Error(`Unsupported direction: ${direction}`);
  }
};
