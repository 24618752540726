import { useDocumentQuery } from '@lessonup/client-integration';
import { createModal, NiceModal, useModal } from '@lessonup/ui-components';
import React from 'react';
import { PlayerModal } from '../components/PlayerModal';
import { GetLessonByIdDocument } from '../Player.graphql.generated';
import { PreviewFeature } from './Preview';

interface PreviewPlayerModalProps {
  lessonId: string;
  activePinId?: string;
  onClose: () => void;
}

export const PreviewPlayerModal = createModal((props: PreviewPlayerModalProps) => {
  const modal = useModal();

  const { data } = useDocumentQuery(GetLessonByIdDocument, {
    variables: {
      input: props.lessonId,
    },
    fetchPolicy: 'network-only',
    onError: (e) => alert(e),
  });

  if (!data?.viewer.lesson?.teacherPins) {
    return null;
  }

  return (
    <PlayerModal modal={modal} contentLabel="preview player">
      <PreviewFeature
        pins={data?.viewer.lesson?.teacherPins}
        activePinId={props.activePinId}
        onClose={() => {
          props.onClose?.();
          modal.hide();
        }}
      />
    </PlayerModal>
  );
});

export const showPreviewPlayerModal = (props: PreviewPlayerModalProps) => NiceModal.show(PreviewPlayerModal, props);
