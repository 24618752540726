import { ShadowSettingKey, ShapeSettings } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { computeShadowFilter } from '../../utils/effects/shadow/shadow';
import { trianglePoints } from '../../utils/pinComponents/shapePinComponent.utils';

interface ShapePinComponentProps {
  settings: ShapeSettings;
  width: number;
  height: number;
}

enum PolygonShapes {
  ELLIPSE = 'ELLIPSE',
  TRIANGLE = 'TRIANGLE',
}

export const ShapePinComponent = (props: ShapePinComponentProps) => {
  const { settings, width, height } = props;

  const getPolygonShape = (shapeType: ShapeSettings['shapeType']) => {
    switch (shapeType) {
      case 'TRIANGLE':
        return PolygonShapes.TRIANGLE;
      case 'ELLIPSE':
      default:
        return PolygonShapes.ELLIPSE;
    }
  };

  const renderShape = (shape: PolygonShapes, settings: ShapeSettings) => {
    switch (shape) {
      case PolygonShapes.ELLIPSE:
        return (
          <ellipse
            cx={width / 2}
            cy={height / 2}
            rx={width / 2}
            ry={height / 2}
            fill={settings.fillColor ?? 'transparent'}
            stroke={settings.strokeColor}
            strokeWidth={settings.strokeThickness ?? 1}
          />
        );
      case PolygonShapes.TRIANGLE:
        return (
          <polygon
            points={trianglePoints(width, height)}
            fill={settings.fillColor ?? 'transparent'}
            stroke={settings.strokeColor}
            strokeWidth={settings.strokeThickness ?? 1}
          />
        );
    }
  };

  return (
    <StyledSvg shadow={settings.shadow} width={width} height={height}>
      {renderShape(getPolygonShape(settings.shapeType), settings)}
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ shadow?: ShadowSettingKey }>`
  position: absolute;
  overflow: visible;
  ${(props) => props.shadow && `filter: drop-shadow(${computeShadowFilter(props.shadow)});`}
`;
