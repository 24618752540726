import { breakpoints, color, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

interface FullPageBodyProps {
  children: React.ReactNode;
}

export const FullPageBodyAside: React.FC<FullPageBodyProps> = (props) => {
  return (
    <div>
      <StyledAsideInner>{props.children}</StyledAsideInner>
    </div>
  );
};

const StyledAsideInner = styled.div`
  z-index: 2;
  border-radius: ${spacing.size16};
  background-color: ${color.neutral.surface.background};
  padding: ${spacing.size16};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${spacing.size4};
  padding: ${spacing.size16};
  margin-top: ${spacing.size16};
  @media (min-width: ${breakpoints.maxWidthText}) {
    max-width: 312px;
    min-width: 312px;
    margin: ${spacing.size16} auto 0 auto;
  }
`;
