export type StorageType = 'localStorage' | 'sessionStorage';

/**
 * Detects whether the storage type is defined and is available for use.
 * This is important to test, as functionality like localStorage may be disabled by the browser.
 * For example, when "Block third-party cookies" in Chrome is enabled, which is the default in incognito mode.
 *
 * Based on an example function from MDN and converted to TS.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 * @param type The storage type for which to check availability.
 * @returns Boolean indicating whether the specified storage type is available for use.
 */
export function storageAvailable(type: StorageType): boolean {
  let storage: Storage | undefined;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage?.length !== 0
    );
  }
}
