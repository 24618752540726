import styled from '@emotion/styled';
import { assertNever } from '@lessonup/utils';
import React from 'react';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';
import { IconCheck, IconClose } from '../../icons';
import { FormFieldValidation } from '../model';

export interface ValidationMessageProps {
  validation?: FormFieldValidation;
  id: string;
}

export const ValidationMessage: React.FC<ValidationMessageProps> = ({ id, validation }) => {
  if (!validation) {
    return null;
  }

  const iconSelector = () => {
    if (validation.icon) return validation.icon;
    if (validation.state === 'error') return <IconClose />;
    if (validation.state === 'success') return <IconCheck />;
  };

  return (
    <StyledValidationMessageWrapper direction="x" alignItems="center" spacing={spacing.size4} validation={validation}>
      {iconSelector()}
      <BodyText size="small" id={id} role={validation?.state === 'error' ? 'alert' : undefined}>
        {validation.message}
      </BodyText>
    </StyledValidationMessageWrapper>
  );
};

const StyledValidationMessageWrapper = styled(SpaceBetween)<Pick<ValidationMessageProps, 'validation'>>`
  color: ${(props) => getValidationMessageFontColor(props)};
  svg {
    min-height: ${spacing.size12};
    min-width: ${spacing.size12};
    height: ${spacing.size12};
    width: ${spacing.size12};
  }
  align-items: start;
`;

const getValidationMessageFontColor = ({ validation }: Pick<ValidationMessageProps, 'validation'>): string => {
  const { state } = validation || { state: 'neutral' };
  switch (state) {
    case 'error':
      return color.additional.error.background;
    case 'success':
      return color.additional.success.background;
    case 'neutral':
      return color.accent.secondary.background;
    case 'warning':
      return color.additional.warningContainer.text;
    default:
      assertNever(state, 'Unknown validation state');
  }
};
