import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { Swatch } from '../Swatch/Swatch';

export interface ColorInputProps {
  color: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
  name: string;
  ariaLabel: string;
  className?: string;
}

export const ColorInput: React.FC<ColorInputProps> = ({
  onClick,
  color,
  selected,
  disabled,
  name,
  ariaLabel,
  className,
}) => (
  <StyledColorLabel htmlFor={color} className={className}>
    <StyledSelectionIndicator selected={selected} disabled={!!disabled}>
      <input
        id={color}
        name={name}
        type="radio"
        onClick={onClick}
        defaultChecked={selected}
        disabled={disabled}
        aria-label={ariaLabel}
      />
      <Swatch swatchColor={color} />
    </StyledSelectionIndicator>
  </StyledColorLabel>
);

const StyledColorLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem('32px')};
  width: ${rem('32px')};
`;

const StyledSelectionIndicator = styled.div<{ disabled: boolean; selected: boolean }>`
  padding: ${rem('1px')};

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid ${color.accent.secondary.background};
      border-radius: ${borderRadius.rounded32};
    `}

  input {
    display: none;
  }

  div {
    cursor: pointer;
  }

  input:checked ~ div {
    cursor: pointer;
    ${(props) => props.disabled && 'pointer-events: none'};
  }
`;
