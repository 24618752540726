import { logger } from '@lessonup/client-integration';
import {
  AudioFilePreviewProps,
  DocumentFilePreviewProps,
  FileType,
  ImageFilePreviewProps,
  SlideDeckFilePreviewProps,
  VideoFilePreviewProps,
} from '@lessonup/ui-components';
import { assertNever } from '@lessonup/utils';
import { map, sortBy } from 'lodash';
import { BaseUseUploadData } from '../model';
import {
  UploadDetailsFragmentFragment,
  UploadDetailsFragment_AudioUpload_Fragment,
  UploadDetailsFragment_DocumentUpload_Fragment,
  UploadDetailsFragment_ImageUpload_Fragment,
  UploadDetailsFragment_SlideDeckUpload_Fragment,
  UploadDetailsFragment_VideoUpload_Fragment,
} from '../Uploads.graphql.generated';

export type UploadPreviewFileType = FileType;

export interface SharedUploadPreviewData {
  id: string;
  name: string;
  createdAt: Date;
  sizeInBytes: number;
  fileExtension: string;
  contentType: string;
}

export type ImageUploadPreviewData = SharedUploadPreviewData & ImageFilePreviewProps;
export type AudioUploadPreviewData = SharedUploadPreviewData &
  AudioFilePreviewProps & {
    durationInSeconds: number;
  };
export type VideoUploadPreviewData = SharedUploadPreviewData &
  VideoFilePreviewProps & {
    durationInSeconds: number;
    thumbnailURL?: string;
  };

export interface DocumentPage {
  url: string;
}

export type DocumentUploadPreviewData = SharedUploadPreviewData &
  Omit<DocumentFilePreviewProps, 'src'> & {
    pages: DocumentPage[];
  };

export type SlideDeckUploadPreviewData = SharedUploadPreviewData & SlideDeckFilePreviewProps;

export type UploadPreviewData =
  | ImageUploadPreviewData
  | AudioUploadPreviewData
  | VideoUploadPreviewData
  | DocumentUploadPreviewData
  | SlideDeckUploadPreviewData;

interface UploadFilePreviewData<T> {
  props: Omit<T, keyof SharedUploadPreviewData>;
  isFileMissing: boolean;
}

export const createImageUploadPreviewData = (
  upload: UploadDetailsFragment_ImageUpload_Fragment
): UploadFilePreviewData<ImageUploadPreviewData> => {
  const { image } = upload || {};
  const { url } = image || {};

  return { props: { type: 'image', src: url, alt: 'previewModal.imagePreviewAlt' }, isFileMissing: !image };
};

export const createDocumentUploadPreviewData = (
  upload: UploadDetailsFragment_DocumentUpload_Fragment
): UploadFilePreviewData<DocumentUploadPreviewData> => {
  const { thumbnail, pages } = upload;

  return {
    props: {
      type: 'document',
      pages,
      alt: 'previewModal.documentPreviewAlt',
    },
    isFileMissing: !thumbnail,
  };
};
export const createSlideDeckUploadPreviewData = (
  // TODO: https://lessonup.atlassian.net/browse/BEE-263 - Remove disable when correct eslint rule for underscored variables is added
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _upload: UploadDetailsFragment_SlideDeckUpload_Fragment
): UploadFilePreviewData<SlideDeckUploadPreviewData> => {
  // TODO: https://lessonup.atlassian.net/browse/BEE-263
  logger.debug("We don't receive slide deck thumbnails from the backend (yet)", { uploadId: _upload.id });
  return {
    props: {
      type: 'slideDeck',
      alt: 'previewModal.documentPreviewAlt',
      src: 'https://lh3.googleusercontent.com/nILrr76_nLCLSccnj7myFpwazipFBjILklLpyb4ga6ghN7smJ1N688XJYLQcX5cp_TGCM43qJSd2cn2MUuBdReASmzF9VYcKGM-GzR4',
    },
    isFileMissing: false,
  };
};

export const createAudioUploadPreviewData = (
  upload: UploadDetailsFragment_AudioUpload_Fragment
): UploadFilePreviewData<AudioUploadPreviewData> => {
  const { audio } = upload;

  const { url, durationInSeconds = 0 } = audio || {};

  return {
    props: {
      type: 'audio',
      sources: [{ src: url }],
      durationInSeconds,
    },
    isFileMissing: !audio,
  };
};

export const createVideoUploadPreviewData = (
  upload: UploadDetailsFragment_VideoUpload_Fragment
): UploadFilePreviewData<VideoUploadPreviewData> => {
  const { video } = upload;

  const { url, durationInSeconds = 0 } = video || {};

  return {
    props: {
      type: 'video',
      sources: [{ src: url }],
      durationInSeconds,
      thumbnailURL: upload.thumbnail?.url,
    },
    isFileMissing: !video,
  };
};

export const getUploadPreviewData = (upload: UploadDetailsFragmentFragment) => {
  switch (upload.__typename) {
    case 'ImageUpload':
      return createImageUploadPreviewData(upload);
    case 'DocumentUpload':
      return createDocumentUploadPreviewData(upload);
    case 'SlideDeckUpload':
      return createSlideDeckUploadPreviewData(upload);
    case 'AudioUpload':
      return createAudioUploadPreviewData(upload);
    case 'VideoUpload':
      return createVideoUploadPreviewData(upload);
    default:
      // We cast to the never type because "undefined" should never happen nor be supported.
      assertNever(upload.__typename as never, 'Unsupported upload file type');
  }
};

export function mapPreviewDataToUploadsData(upload: UploadPreviewData, selectedPages: number[]) {
  const baseData: Omit<BaseUseUploadData, 'urls'> = {
    id: upload.id,
    name: upload.name,
    type: upload.type,
    contentType: upload.contentType,
  };

  switch (upload.type) {
    case 'audio':
      return {
        ...baseData,
        urls: upload.sources[0]?.src ? [upload.sources[0].src] : [],
      };
    case 'image':
      return {
        ...baseData,
        urls: upload.src ? [upload.src] : [],
        size: upload.sizeInBytes,
      };
    case 'document':
      return {
        ...baseData,
        size: upload.sizeInBytes,
        urls: getSortedSelectedURLs(
          map(upload.pages, (page) => page.url),
          selectedPages
        ),
      };
    case 'slideDeck':
      // TODO: https://lessonup.atlassian.net/browse/BEE-263
      logger.warn("We don't receive slide deck thumbnails from the backend (yet)");
      return {
        ...baseData,
        size: upload.sizeInBytes,
        urls: [],
      };
    case 'video':
      return {
        ...baseData,
        duration: upload.durationInSeconds,
        urls: upload.sources?.[0]?.src ? [upload.sources?.[0]?.src] : [],
        thumbnailURL: upload.thumbnailURL,
      };
    default:
      assertNever(upload, 'Unknown upload type');
  }
}

function getSortedSelectedURLs(urls: string[], selectedPagesIndices: number[]) {
  return sortBy(selectedPagesIndices, (page) => page).map((page) => urls[page]);
}
