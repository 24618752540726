import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';

interface TabListProps {
  resizing: 'hug' | 'fixed';
}

export function TabList({ children, resizing }: PropsWithChildren<TabListProps>) {
  return <StyledTabList resizing={resizing}>{children}</StyledTabList>;
}

const StyledTabList = styled.div<Pick<TabListProps, 'resizing'>>`
  border-radius: ${borderRadius.rounded12};
  overflow: hidden;
  display: flex;
  width: ${(props) => (props.resizing === 'hug' ? 'fit-content' : '100%')};
`;
