import { InternalRefetchQueriesInclude, useDocumentMutation } from '@lessonup/client-integration';
import { asError } from '@lessonup/utils';
import { showRenameModal } from '../dataComponents/RenameModal';
import { UpdateUploadFolderNameDocument, UpdateUploadNameDocument } from '../Uploads.graphql.generated';

interface UseRenameUploadProps {
  onError: (error: Error) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

export const useRenameUpload = ({ onError, refetchQueries = [] }: UseRenameUploadProps) => {
  const [updateUploadNameMutation] = useDocumentMutation(UpdateUploadNameDocument, {
    refetchQueries,
  });

  const [updateUploadFolderNameMutation] = useDocumentMutation(UpdateUploadFolderNameDocument, {
    refetchQueries,
  });

  const handleRenameUpload = async (id: string, currentName: string, isFolder: boolean) => {
    try {
      const name = await showRenameModal({ currentName, handleRename: createRenameMethod(id, isFolder) });
      if (name === currentName) return;
    } catch (error) {
      onError(asError(error));
    }
  };

  const createRenameMethod =
    (id: string, isFolder: boolean) =>
    async (name: string): Promise<boolean> => {
      try {
        if (isFolder) {
          await updateUploadFolderNameMutation({ variables: { input: { folderId: id, name } } });
        } else {
          await updateUploadNameMutation({ variables: { input: { uploadId: id, name } } });
        }
        return true;
      } catch (error) {
        onError(asError(error));
        return false;
      }
    };

  return handleRenameUpload;
};
