import { ID } from '@lessonup/teaching-core';
import { AppError, IsomorphicLogger, LogDetails } from '@lessonup/utils';
import { SentryEditorScope, SentryPlayerScope } from './logger.utils';

export type TraceCategory = 'auth-firebase' | 'auth-meteor' | 'connection' | 'activity' | 'transformMethodError';

export const errorIsPassableToLogger = (error: unknown): error is ErrorMessage => {
  return error instanceof Error || error instanceof AppError || typeof error === 'string';
};

export type ErrorMessage = AppError | Error | string;

interface LoggerTransport extends IsomorphicLogger {
  debug?(message: string, details?: LogDetails): void;
  setUserId?(userId: ID | undefined): void;
  trace?(category: TraceCategory, message: string): void;
  setEditorScope?(sentryEditorScope: SentryEditorScope | null): void;
  setPlayerScope?(sentryPlayerScope: SentryPlayerScope | null): void;
}
// TODO -ED-132 LoggerTransport seem double now

export class Logger implements LoggerTransport {
  private transports: LoggerTransport[] = [];

  public registerTransport(transport: LoggerTransport): void {
    this.transports.push(transport);
  }

  public debug(message: string, details: LogDetails): void {
    this.transports.forEach((transport) => transport.debug?.(message, details));
  }

  public info(message: ErrorMessage, details?: LogDetails): void {
    this.transports.forEach((transport) => transport.info?.(message, details));
  }

  public warn(message: ErrorMessage, details?: LogDetails): void {
    this.transports.forEach((transport) => transport.warn?.(message, details));
  }

  public error(message: ErrorMessage, details?: LogDetails): void {
    this.transports.forEach((transport) => transport.error?.(message, details));
  }

  public setUserId(userId: ID | undefined): void {
    this.transports.forEach((transport) => transport.setUserId?.(userId));
  }

  public trace(category: TraceCategory, message: string): void {
    this.transports.forEach((transport) => transport.trace?.(category, message));
  }

  public setEditorScope(sentryEditorScope: SentryEditorScope | null): void {
    this.transports.forEach((transport) => transport.setEditorScope?.(sentryEditorScope));
  }

  public setPlayerScope(sentryPlayerScope: SentryPlayerScope | null): void {
    this.transports.forEach((transport) => transport.setPlayerScope?.(sentryPlayerScope));
  }
}

export const logger = new Logger();
