import { useKeyboardEvent } from '@lessonup/client-integration';
import { Student } from '@lessonup/players-modern';
import { createModal, ManagedModal, useModal } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { useTeacherModernConfig } from '../../../../../../utils/config/teacherModernConfig';
import { playerScreenSize } from '../../../../dataComponents/PlayerScreens/playerScreens.utils';
import { StudentJoinScreen } from '../../../../dataComponents/PlayerScreens/StudentJoinScreen/StudentJoinScreen';
import { usePlayerScreenToWindowScalar } from '../../../hooks/usePlayerScreenToWindowScalar';
import { PlayerScreenContentScaler } from '../PlayerScreenContentScaler';

interface StudentJoinScreenModalProps {
  pincode?: string;
  joinedStudents: Student[];
  allowNewPlayers: boolean;
  handleRemoveStudent: (studentId: string) => Promise<void>;
  handleAllowNewPlayers: (allowNewPlayers: boolean) => Promise<void>;
  onClose: () => void;
  t: TFunction;
}

export const StudentJoinScreenModal = createModal(
  ({
    pincode = '',
    joinedStudents,
    allowNewPlayers,
    handleRemoveStudent,
    handleAllowNewPlayers,
    onClose,
    t,
  }: StudentJoinScreenModalProps) => {
    const modal = useModal();
    const scale = usePlayerScreenToWindowScalar();
    const { studentUrl } = useTeacherModernConfig();

    // Arrow keys player navigation is disabled and will close the modal instead
    useKeyboardEvent('keydown', {
      ArrowLeft: (event) => {
        event.preventDefault();
        handleClose();
      },

      ArrowRight: (event) => {
        event.preventDefault();
        handleClose();
      },
    });

    const handleClose = () => {
      onClose();
      modal.hide();
    };

    const handleBeforeClose = () => {
      onClose();
    };

    return (
      <ManagedModal
        modal={modal}
        contentLabel={t('studentJoinScreen.ariaLabel')}
        width={`${playerScreenSize.width * scale}px`}
        height={`${playerScreenSize.height * scale}px`}
        overflowHidden={true}
        onBeforeClose={handleBeforeClose}
      >
        <PlayerScreenContentScaler scale={scale}>
          <StudentJoinScreen
            id="joinscreenModal"
            pincode={pincode}
            joinedStudents={joinedStudents}
            allowNewPlayers={allowNewPlayers}
            handleRemoveStudent={handleRemoveStudent}
            handleAllowNewPlayers={handleAllowNewPlayers}
            showCloseWindowButton={true}
            handleCloseWindow={handleClose}
            t={t}
            studentUrl={studentUrl}
          />
        </PlayerScreenContentScaler>
      </ManagedModal>
    );
  }
);
