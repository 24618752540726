export interface Progress {
  percent: number;
  done: number;
  total: number;
}

export namespace Progress {
  export function calc(done: number, total: number | undefined): Progress {
    if (!total) {
      return { done: 0, total: 0, percent: 0 };
    }
    const percent = done == 0 ? 0 : Math.round((done / total) * 100);
    return { done, total, percent };
  }
}
