import { BodyText, Button, IconClose, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export interface ContextSidebarHeaderProps {
  title: string;
  onClosePane: () => void;
  closeLabel: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
}

export const ContextSidebarHeader: React.FC<ContextSidebarHeaderProps> = ({
  title,
  onClosePane,
  closeLabel,
  buttonRef,
}) => (
  <StyledContextSidebarHeader>
    <BodyText weight="bold" size="medium" ellipsis>
      {title}
    </BodyText>
    <Button
      buttonType="neutral"
      size="small"
      onClick={onClosePane}
      aria-label={closeLabel}
      ref={buttonRef}
      iconStart={<IconClose />}
    />
  </StyledContextSidebarHeader>
);

const StyledContextSidebarHeader = styled.section`
  padding: ${spacing.size8} ${spacing.size16};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
