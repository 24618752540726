import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconRocket = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M7.56 17.401c.13-.46.045-.713-.11-.85-.138-.155-.39-.241-.85-.112-1.458.413-1.63 2.267-1.649 2.61.343-.02 2.197-.191 2.61-1.648M7.522 10.521q.367-.714.766-1.379c-1.059-.1-3.032.13-5.288 2.436l.855.837s1.752-.585 2.929-.324c.228-.53.475-1.058.738-1.57M13.48 16.478c-.513.263-1.04.51-1.57.738.26 1.177-.325 2.929-.325 2.929l.837.855c2.307-2.256 2.537-4.229 2.436-5.288q-.664.4-1.379.766"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.462 8.488a.423.423 0 0 1-.6 0 .424.424 0 1 1 .6 0m.711-.658a1.426 1.426 0 0 0-2.022 0 1.43 1.43 0 1 0 2.022 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.738 10.42a2.22 2.22 0 0 1-1.578.653 2.22 2.22 0 0 1-1.578-.654 2.235 2.235 0 0 1 0-3.156 2.22 2.22 0 0 1 1.578-.654c.596 0 1.157.232 1.578.654.87.87.87 2.286 0 3.156m3.69-6.849C20.052 3.193 19.422 3 18.63 3c-2.015 0-5.08 1.25-7.715 3.886-.55.55-.988 1.204-1.42 1.852a22 22 0 0 0-1.257 2.15 25 25 0 0 0-1.087 2.435q-.121.318-.23.642a4 4 0 0 0 .168.502q.084.206.185.402c.203.398.46.768.769 1.092.323.308.694.566 1.091.77a5 5 0 0 0 .825.33l.08.022q.323-.109.642-.23.334-.128.664-.266.9-.375 1.77-.822c.739-.379 1.459-.796 2.15-1.257.647-.432 1.3-.87 1.85-1.42 3.67-3.67 4.655-8.176 3.315-9.517"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconRocket;
