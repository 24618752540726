import {
  ImagePinComponent,
  isImagePinComponent,
  isTextPinComponent,
  LessonSource,
  orderableCompareFn,
  PinComponent,
  TeacherPin,
} from '@lessonup/pins-shared';
import { isNull } from 'lodash';
import { Collaborator } from '../../../utils/yjs/yjs.types';
import type { ActivePinId, SelectedPinComponentIds, SelectedPinIds, SubSelectionValue } from '../EditorContext.types';
import { getSelectedPinComponent } from '../EditorContext.utils';
import { EditorState, LessonMeta, Listener, ParamOrFunction } from './editorStateStore.types';

const editorState: EditorState = {
  pin: null,
  pins: [],
  activePinId: null,
  orderedPins: [],
  orderedActivePinIndex: null,
  editMode: {
    type: 'selecting',
    selectedPinComponent: null,
    selectedPinComponentIds: [],
  },
  selectedPinIds: [],
  subSelectionValue: null,
  lessonMeta: null,
  collaborator: [],
};

type ListenerType =
  | 'lesson'
  | 'pins'
  | 'activePinId'
  | 'activePin'
  | 'selectedPinIds'
  | 'editMode'
  | 'selectedPinComponentIds'
  | 'selectedPinComponent'
  | 'subSelectionValue'
  | 'collaborators';

const listeners: Partial<Record<ListenerType, Listener[]>> = {};

function getListenersByType(type: ListenerType): Listener[] {
  return listeners[type] ?? [];
}

function emitChangesForTypes(...listenerTypes: ListenerType[]) {
  for (const listenerType of listenerTypes) {
    for (const listener of getListenersByType(listenerType)) {
      listener();
    }
  }
}

function setModeSelecting(selectedPinComponentIds: ParamOrFunction<SelectedPinComponentIds>) {
  const ids =
    typeof selectedPinComponentIds === 'function'
      ? selectedPinComponentIds(editorState.editMode.selectedPinComponentIds)
      : selectedPinComponentIds;
  editorState.editMode = {
    type: 'selecting',
    selectedPinComponentIds: ids,
    selectedPinComponent: !isNull(editorState.pin) ? getSelectedPinComponent(editorState.pin, ids) : null,
  };
  emitChangesForTypes('selectedPinComponent', 'selectedPinComponentIds', 'editMode');
}

function setModeTextEdit(textPinComponentId: string) {
  const selectedPinComponent = !isNull(editorState.pin)
    ? getSelectedPinComponent(editorState.pin, [textPinComponentId])
    : null;

  // Ideally, the parameter would be the text pin component itself, so we don't
  // need this check here.
  if (selectedPinComponent === null || !isTextPinComponent(selectedPinComponent)) {
    return;
  }

  editorState.editMode = {
    type: 'textEdit',
    selectedPinComponentIds: [textPinComponentId],
    selectedPinComponent,
  };
  emitChangesForTypes('selectedPinComponent', 'selectedPinComponentIds', 'editMode');
}

function setModeCropping(imagePinComponent: ImagePinComponent) {
  editorState.editMode = {
    type: 'cropping',
    selectedPinComponent: imagePinComponent,
    selectedPinComponentIds: [imagePinComponent.id],
  };
  emitChangesForTypes('selectedPinComponent', 'selectedPinComponentIds', 'editMode');
}

function resetSelection() {
  setModeSelecting([]);

  editorState.subSelectionValue = null;
  emitChangesForTypes('subSelectionValue');
}

/**
 * Recovers a valid editor state after the selected pin component may have
 * disappeared, or changed type or object identity.
 */
function validateSelectedPinComponent() {
  const selectedPinComponent = !isNull(editorState.pin)
    ? getSelectedPinComponent(editorState.pin, editorState.editMode.selectedPinComponentIds)
    : null;
  if (
    editorState.editMode.type === 'cropping' &&
    selectedPinComponent !== null &&
    isImagePinComponent(selectedPinComponent)
  ) {
    setModeCropping(selectedPinComponent);
  } else {
    setModeSelecting(editorState.editMode.selectedPinComponentIds);
  }
}

export const editorStateStore = {
  /**
   * Set the initial state of the editor
   * This should not emit changes because it is called inline on editor initialization
   */
  setInitialState(activePinId: ActivePinId, pins: TeacherPin[]) {
    editorState.pins = pins;
    editorState.activePinId = activePinId;
    editorState.pin = editorState.pins.find((p) => p.id === activePinId) ?? null;
    editorState.selectedPinIds = [];
    editorState.editMode = {
      type: 'selecting',
      selectedPinComponentIds: [],
      selectedPinComponent: null,
    };
    editorState.subSelectionValue = null;
  },
  getActivePinIdSnapshot(): ActivePinId {
    return editorState.activePinId;
  },
  getActivePinSnapshot(): TeacherPin | null {
    return editorState.pin;
  },
  getPinsSnapshot(): TeacherPin[] {
    return editorState.pins;
  },
  getSelectedPinIdsSnapshot() {
    return editorState.selectedPinIds;
  },
  getEditModeSnapshot() {
    return editorState.editMode;
  },
  getSelectedPinComponentIdsSnapshot(): SelectedPinComponentIds {
    return editorState.editMode.selectedPinComponentIds;
  },
  getSelectedPinComponentSnapshot(): PinComponent | null {
    return editorState.editMode.selectedPinComponent;
  },
  getSubSelectionValueSnapshot(): SubSelectionValue {
    return editorState.subSelectionValue;
  },
  getLessonMeta(): LessonMeta | null {
    return editorState.lessonMeta;
  },
  getCollaboratorsSnapshot(): Collaborator[] {
    return editorState.collaborator;
  },
  navigatePins(direction: 'next' | 'prev') {
    handlePinNavigation(direction, editorState);
    emitChangesForTypes('activePinId', 'activePin');
  },
  setActivePinId(activePinId: ActivePinId) {
    handleActivePinIdChange(editorState, activePinId);
    resetSelection();
    emitChangesForTypes('activePinId', 'activePin');
  },
  onExternallySetActivePinId(activePinId: ActivePinId) {
    this.setActivePinId(activePinId);
  },
  setPins(pins: TeacherPin[]) {
    handlePinsChange(editorState, pins);
    removeInvalidSelectedPinIds(editorState, pins);
    validateSelectedPinComponent();
    emitChangesForTypes('pins', 'activePin', 'activePinId', 'selectedPinIds');
  },
  setLessonMeta(info: { source: LessonSource | null }) {
    editorState.lessonMeta = info;
    emitChangesForTypes('lesson');
  },
  setSelectedPinIds(selectedPinIds: ParamOrFunction<SelectedPinIds>) {
    if (typeof selectedPinIds === 'function') {
      editorState.selectedPinIds = selectedPinIds(editorState.selectedPinIds);
    } else {
      editorState.selectedPinIds = selectedPinIds;
    }

    emitChangesForTypes('selectedPinIds');
  },
  setModeSelecting(selectedPinComponentIds: ParamOrFunction<SelectedPinComponentIds>) {
    setModeSelecting(selectedPinComponentIds);
  },
  setModeTextEdit(textPinComponentId: string) {
    setModeTextEdit(textPinComponentId);
  },
  setModeCropping(imagePinComponent: ImagePinComponent) {
    setModeCropping(imagePinComponent);
  },
  setSubSelectionValue(subSelectionValue: ParamOrFunction<SubSelectionValue>) {
    const newSubSelectionValue =
      typeof subSelectionValue === 'function' ? subSelectionValue(editorState.subSelectionValue) : subSelectionValue;

    if (editorState.subSelectionValue === newSubSelectionValue) {
      return;
    }

    editorState.subSelectionValue = newSubSelectionValue;
    emitChangesForTypes('subSelectionValue');
  },
  setCollaborators(collaborators: Collaborator[]) {
    editorState.collaborator = collaborators;
    emitChangesForTypes('collaborators');
  },
  isValidPinId(id?: ActivePinId): boolean {
    if (!id || isNull(id)) return false;
    return editorState.pins.map((p) => p.id).includes(id);
  },
  resetSelection() {
    resetSelection();
  },
  subscribeForType(type: ListenerType): (listener: Listener) => () => void {
    return (listener) => {
      listeners[type] = [...getListenersByType(type), listener];

      return () => {
        listeners[type] = getListenersByType(type).filter((l) => l !== listener);
      };
    };
  },
};

function handlePinsChange(state: EditorState, nextPins: TeacherPin[]) {
  const previousOrderedPins = [...state.orderedPins];
  const previousOrderedActivePinIndex = state.orderedActivePinIndex;

  state.pins = nextPins;
  state.orderedPins = [...nextPins].sort(orderableCompareFn);

  if (state.pins.length === 0) {
    // No pins are available
    state.activePinId = null;
    state.pin = null;
    state.orderedActivePinIndex = null;
    return;
  }

  const possibleNextIndex = state.orderedPins.findIndex((pin) => pin.id === state.activePinId);
  if (possibleNextIndex > -1) {
    // Active pin ID is still valid
    state.orderedActivePinIndex = possibleNextIndex;
    state.pin = state.orderedPins[possibleNextIndex];
    return;
  }

  // Active pin ID is invalid (e.g., pin was deleted)
  let newActivePinId: string | null = null;
  if (!isNull(previousOrderedActivePinIndex) && previousOrderedActivePinIndex >= 0) {
    const currentPinIds = state.pins.map((pin) => pin.id);

    // Try to find the next pin after the deleted active pin
    const possibleNextPins = previousOrderedPins.slice(previousOrderedActivePinIndex + 1);
    newActivePinId = possibleNextPins.find((pin) => currentPinIds.includes(pin.id))?.id || null;

    // If no next pin, find the previous one
    if (!newActivePinId) {
      const prevPins = previousOrderedPins.slice(0, previousOrderedActivePinIndex).reverse();
      newActivePinId = prevPins.find((pin) => currentPinIds.includes(pin.id))?.id || null;
    }

    state.activePinId = newActivePinId;
    state.pin = state.pins.find((pin) => pin.id === newActivePinId) ?? null;
    state.orderedActivePinIndex = state.orderedPins.findIndex((pin) => pin.id === newActivePinId);
    return;
  }

  handleInvalidActivePin(state);
}

function handleActivePinIdChange(state: EditorState, nextActivePinId: ActivePinId) {
  const validPinIds = state.pins.map((p) => p.id);
  if (nextActivePinId && validPinIds.includes(nextActivePinId)) {
    const nextOrderedActivePinIndex = state.orderedPins.findIndex((pin) => pin.id === nextActivePinId);
    state.activePinId = nextActivePinId;
    state.orderedActivePinIndex = nextOrderedActivePinIndex;
    state.pin = state.orderedPins[nextOrderedActivePinIndex];
  } else if (state.pins.length > 0) {
    handleInvalidActivePin(state);
  } else {
    // No pins are available
    state.activePinId = null;
    state.orderedActivePinIndex = null;
    state.pin = null;
  }
}

function removeInvalidSelectedPinIds(state: EditorState, nextPins: TeacherPin[]) {
  const nextPinIds = nextPins.map((pin) => pin.id);
  state.selectedPinIds = state.selectedPinIds.filter((pin) => !nextPinIds.includes(pin));
}

function handleInvalidActivePin(state: EditorState) {
  state.activePinId = state.orderedPins[0].id;
  state.orderedActivePinIndex = 0;
  state.pin = state.orderedPins[0];
}

function handlePinNavigation(direction: 'next' | 'prev', state: EditorState) {
  if (state.orderedPins.length === 0 || state.orderedActivePinIndex === null) {
    return;
  }

  const currentIndex = state.orderedActivePinIndex;
  const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
  const nextPin = state.orderedPins[nextIndex];

  if (!nextPin) {
    return;
  }

  state.activePinId = nextPin.id;
  state.orderedActivePinIndex = nextIndex;
  state.pin = nextPin;
}
