import { WordCloudPinSettings } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PinBackground } from '../components/PinBackground';

export interface WordCloudPinProps {
  pinSettings: WordCloudPinSettings;
  isThumb?: boolean;
  children?: React.ReactNode;
}

export const WordCloudPinComponent: React.FC<WordCloudPinProps> = ({ pinSettings, isThumb, children }) => {
  return (
    <WordCloudWrapper>
      <PinBackground
        isThumb={isThumb}
        backgroundColor={pinSettings.backgroundColor || 'blue'}
        backgroundImage={pinSettings.backgroundImage ?? undefined}
        backgroundTransparency={pinSettings.backgroundTransparency}
      />
      {children}
    </WordCloudWrapper>
  );
};

const WordCloudWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
