import { NiceModalHandler } from '@ebay/nice-modal-react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { ModalStickyBottomContent, ModalStickyBottomContentProps } from './content/ModalStickyBottomContent';
import { overlayStyles, wrapperStyles } from './utils';

const overlayClassname = css`
  ${overlayStyles}
`;

export type ModalStickyBottomProps = {
  modal: NiceModalHandler;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
} & Omit<ModalStickyBottomContentProps, 'collapsed' | 'onCollapse'>;

export const ModalStickyBottom = ({
  modal,
  children,
  collapsed: collapsedFromProps,
  setCollapsed: setCollapsedFromProps,
  ...contentProps
}: ModalStickyBottomProps) => {
  const [collapsed, setCollapsed] = useState(collapsedFromProps || false);

  return (
    <>
      <StyledReactModal isOpen={modal.visible} overlayClassName={overlayClassname}>
        <ModalStickyBottomContent
          collapsed={Boolean(collapsedFromProps) || collapsed}
          onCollapse={() =>
            setCollapsedFromProps ? setCollapsedFromProps(!collapsedFromProps) : setCollapsed(!collapsed)
          }
          {...contentProps}
        >
          {children}
        </ModalStickyBottomContent>
      </StyledReactModal>
    </>
  );
};

const StyledReactModal = styled(ReactModal)`
  ${wrapperStyles}
`;
