import { Box, Line, PinComponentLayout, Vector } from '@lessonup/pins-shared';
import { GrippyPosition, isBoxLayout, lineEndPoint } from '../../types';
import { ArrangedSnapAnchors, SnapAnchor, SnapFitting } from '../../types/helpLines/helpLines.types';
import {
  boxCenterPosition,
  boxRotatedVertices,
  grippyPositionToBoxVertexIndexes,
  isSideGrippy,
  lineCenter,
  outerHorizontalVectors,
  outerVerticalVectors,
} from '../geometry';

export const newSnapAnchor = (position: Vector, snapsTo: SnapFitting): SnapAnchor => ({
  position,
  snapsTo,
});

export const createSnapAnchorsFromLayout = (
  pinComponentLayout: PinComponentLayout,
  endPoint?: lineEndPoint
): ArrangedSnapAnchors => {
  if (isBoxLayout(pinComponentLayout)) {
    return {
      horizontal: createSnapAnchorsFromBoxLayout(pinComponentLayout, 'x'),
      vertical: createSnapAnchorsFromBoxLayout(pinComponentLayout, 'y'),
    };
  }
  return {
    horizontal: createSnapAnchorsFromLineLayout(pinComponentLayout, endPoint),
    vertical: createSnapAnchorsFromLineLayout(pinComponentLayout, endPoint),
  };
};

export const createSnapAnchorsFromBoxLayout = (box: Box, axis?: 'x' | 'y'): SnapAnchor[] => {
  const center = newSnapAnchor(boxCenterPosition(box), 'center');
  const vertices = boxRotatedVertices(box);
  if (axis === undefined) {
    const snapAnchors = vertices.map((vertex) => newSnapAnchor(vertex, 'outer'));
    return [...snapAnchors, center];
  }
  const outerVectors = axis === 'x' ? outerHorizontalVectors(vertices) : outerVerticalVectors(vertices);
  const outernapAnchors = outerVectors.map((vector) => newSnapAnchor(vector, 'outer'));
  return [...outernapAnchors, center];
};

export const createSnapAnchorsFromLineLayout = (line: Line, endPoint?: lineEndPoint): SnapAnchor[] => {
  const center = newSnapAnchor(lineCenter(line), 'center');
  if (endPoint === 'start') {
    return [newSnapAnchor(line.start, 'outer')];
  } else if (endPoint === 'end') {
    return [newSnapAnchor(line.end, 'outer')];
  }
  return [newSnapAnchor(line.start, 'outer'), center, newSnapAnchor(line.end, 'outer')];
};

export const createSnapAnchorsForBoxResize = (vertices: Vector[], grippy: GrippyPosition): ArrangedSnapAnchors => {
  const vertexIndexes = grippyPositionToBoxVertexIndexes(grippy);
  const anchorPositions = vertexIndexes.map((index) => vertices[index]);

  if (!isSideGrippy(grippy)) {
    const anchors = anchorPositions.map((position) => newSnapAnchor(position, 'outer'));
    return {
      horizontal: anchors,
      vertical: anchors,
    };
  }

  const horizontalAnchorPositions = outerHorizontalVectors(vertices);
  const verticalAnchorPositions = outerVerticalVectors(vertices);

  return {
    horizontal: horizontalAnchorPositions
      .filter((vertex) => anchorPositions.includes(vertex))
      .map((position) => newSnapAnchor(position, 'outer')),
    vertical: verticalAnchorPositions
      .filter((vertex) => anchorPositions.includes(vertex))
      .map((position) => newSnapAnchor(position, 'outer')),
  };
};
