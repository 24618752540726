import { TeacherPin } from '@lessonup/pins-shared';
import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import { gql } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: string;
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: any;
  /** A string that cannot be passed as an empty value */
  NonEmptyString: any;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: number;
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: number;
  /** Integers that will have a value greater than 0. */
  PositiveInt: number;
  /** A teacher pin */
  TeacherPin: TeacherPin;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: string;
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: any;
};

export type AcceptTermsOrigin =
  | 'MISSING_INFO'
  | 'SEARCH';

export type AcceptUserTermsInput = {
  origin: AcceptTermsOrigin;
  userId: Scalars['ID'];
};

export type AcceptUserTermsPayload = {
  __typename?: 'AcceptUserTermsPayload';
  user: Viewer;
};

export type AcceptedMimeTypesForUploads = {
  __typename?: 'AcceptedMimeTypesForUploads';
  audioTypes: Array<Scalars['String']>;
  documentTypes: Array<Scalars['String']>;
  imageTypes: Array<Scalars['String']>;
  slideDeckTypes?: Maybe<Array<Scalars['String']>>;
  videoTypes: Array<Scalars['String']>;
};

export type AccessToAssignmentDataForLicenseInput = {
  assignmentId: Scalars['String'];
};

export type AccessToAssignmentDataForLicensePayload = {
  __typename?: 'AccessToAssignmentDataForLicensePayload';
  hasAccess: Scalars['Boolean'];
};

export type AccountDeletionReasonType =
  | 'CHANGE_EMAIL'
  | 'CHANGE_SUBSCRIPTION'
  | 'COMPLETED_CLASS'
  | 'FEATURES_TOO_COMPLICATED'
  | 'NOT_RELEVANT'
  | 'NOT_USING_OFTEN'
  | 'OTHER_REASON'
  | 'TECHNICAL_ISSUES'
  | 'TOO_EXPENSIVE'
  | 'USING_OTHER_PROGRAMS';

export type AccountDeletionRequest = Node & {
  __typename?: 'AccountDeletionRequest';
  /** Completion date of the deletion request */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** Creation date of the deletion request */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Scheduled date of the deletion request to be finished */
  scheduledAt: Scalars['DateTime'];
};

export type AddGroupMemberInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddPinsToLessonInput = {
  pinIds: Array<Scalars['ID']>;
  targetLessonId: Scalars['ID'];
};

export type AddPinsToLessonPayload = {
  __typename?: 'AddPinsToLessonPayload';
  /** On success, changes are not applied immediately and will only be visible after a short delay */
  success: Scalars['Boolean'];
};

export type AddProposalToAgreementContactInput = {
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type AddProposalToAgreementInput = {
  agreementId: Scalars['ID'];
  contact: AddProposalToAgreementContactInput;
  /** Agreement secret that is used as authorization check */
  secret: Scalars['String'];
};

export type AddProposalToAgreementPayload = {
  __typename?: 'AddProposalToAgreementPayload';
  agreement: Agreement;
};

export type AddUserToOrganizationInput = {
  /**
   * Specifies whether the organization license should be granted to the user.
   * If set to 'true', the user's role will be overridden to 'MEMBER'.
   */
  giveOrganizationLicense?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether the user will have admin privileges in the organization. */
  isOrganizationAdmin?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
  /**
   * Role of the user within the organization.
   * Note: This will be automatically set to 'MEMBER' if 'giveOrganizationLicense' is true.
   */
  userOrganizationRole?: InputMaybe<OrganizationUserRole>;
};

export type AddUserToOrganizationPayload = {
  __typename?: 'AddUserToOrganizationPayload';
  user: Viewer;
};

export type AdminChangeTeachingCreditMutation = Node & TeachingCreditMutation & {
  __typename?: 'AdminChangeTeachingCreditMutation';
  /** The creation date of the mutation. */
  createdAt: Scalars['DateTime'];
  /** The mutation in the credits balance. Is negative when credits were subtracted and positive when credits were added. */
  creditsMutation: Scalars['Int'];
  /** An optional description that can be added by support to inform why credits were subtracted or added. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the mutation. */
  id: Scalars['ID'];
};

/** Agreement type with all the available fields */
export type Agreement = BaseAgreement & Node & {
  __typename?: 'Agreement';
  /** Represents the board member of the last signed */
  boardMember?: Maybe<AgreementContact>;
  creationDate: Scalars['DateTime'];
  /** Represents the details of the last signed proposal */
  details?: Maybe<AgreementDetails>;
  document: AgreementDocument;
  id: Scalars['ID'];
  /** Language used on the agreement signing page */
  language: AgreementLanguage;
  /** URL link to the manually signed document */
  manuallySignedDocumentUrl?: Maybe<Scalars['URL']>;
  organization: Scalars['ID'];
  /** Represents the privacy contact of the last signed proposal */
  privacyContact?: Maybe<AgreementContact>;
  requestUser: Scalars['ID'];
  secret: Scalars['String'];
  /** Get specific sign proposal by ID */
  signProposalById?: Maybe<AgreementSignProposal>;
  /** An array with all proposals */
  signProposals: Array<AgreementSignProposal>;
  signed?: Maybe<Scalars['DateTime']>;
  signedProposal?: Maybe<Scalars['ID']>;
  /** Type of the agreement */
  type: AgreementType;
};


/** Agreement type with all the available fields */
export type AgreementSignProposalByIdArgs = {
  id: Scalars['ID'];
};

export type AgreementContact = {
  __typename?: 'AgreementContact';
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type AgreementDetails = {
  __typename?: 'AgreementDetails';
  contact?: Maybe<AgreementDetailsContact>;
  name: Scalars['String'];
};

export type AgreementDetailsContact = {
  __typename?: 'AgreementDetailsContact';
  address: Scalars['String'];
  /**
   * Represents the school BRIN code.
   * Also used for agreements of non-Dutch schools, due to historical reasons.
   */
  brin?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  /**
   * Represents the country code, although sometimes this is a full country name.
   * This field is nullable, because it's not always defined on older agreements.
   */
  country?: Maybe<Scalars['String']>;
  /** Represents the KvK (CofC) number (also for non-Dutch organizations) */
  kvk?: Maybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

/** These are all the documents that we accept for an agreement */
export type AgreementDocument =
  | 'GDPR_EN_32'
  | 'GDPR_EN_40'
  | 'GDPR_EN_41'
  | 'GDPR_NL_30'
  | 'GDPR_NL_31'
  | 'GDPR_NL_32'
  | 'GDPR_NL_40'
  | 'GDPR_NL_41';

export type AgreementLanguage =
  | 'EN'
  | 'FR'
  | 'NL';

export type AgreementSignProposal = {
  __typename?: 'AgreementSignProposal';
  boardMember: AgreementContact;
  date: Scalars['DateTime'];
  details?: Maybe<AgreementDetails>;
  privacyContact?: Maybe<AgreementContact>;
  proposalId: Scalars['ID'];
};

export type AgreementType =
  | 'GDPR';

export type AnalyzeUploadsForOcrInput = {
  uploadIds: Array<Scalars['ID']>;
};

export type AnalyzeUploadsForOcrPayload = {
  __typename?: 'AnalyzeUploadsForOCRPayload';
  /** complete text of the OCR */
  texts: Array<Maybe<Scalars['String']>>;
};

export type AncestorUploadFolder = {
  __typename?: 'AncestorUploadFolder';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AnswerOpenQuestionForRealtimeAssignmentInput = {
  answer: Scalars['String'];
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type AnswerOpenQuestionForRealtimeAssignmentPayload = {
  __typename?: 'AnswerOpenQuestionForRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type AnswerQuizForRealtimeAssignmentInput = {
  answerIds: Array<Scalars['ID']>;
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type AnswerQuizForRealtimeAssignmentPayload = {
  __typename?: 'AnswerQuizForRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type AnswerWordCloudForRealtimeAssignmentInput = {
  answer: Scalars['NonEmptyString'];
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type AnswerWordCloudForRealtimeAssignmentPayload = {
  __typename?: 'AnswerWordCloudForRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type ArchiveSchoolSuggestionInput = {
  globalSchoolId: Scalars['String'];
};

export type ArchiveSchoolSuggestionPayload = {
  __typename?: 'ArchiveSchoolSuggestionPayload';
  id: Scalars['String'];
};

export type Assignment = Node & {
  __typename?: 'Assignment';
  id: Scalars['ID'];
  playerSettings: RealtimePlayerSettings;
  status: AssignmentStatus;
};

export type AssignmentByLinkInput = {
  assignmentId: Scalars['String'];
};

export type AssignmentByLinkPayload = {
  __typename?: 'AssignmentByLinkPayload';
  assignment?: Maybe<Assignment>;
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AssignmentGradeModelSettings =
  | 'DEFAULT'
  | 'LINIAR'
  | 'N_TERM';

export type AssignmentGradeModelSettingsNTerm = {
  nTerm: Scalars['Int'];
};

export type AssignmentHideInput = {
  assignmentId: Scalars['String'];
};

export type AssignmentHidePayload = {
  __typename?: 'AssignmentHidePayload';
  id: Scalars['ID'];
};

export type AssignmentOpenQuestionAddAttachmentInput = {
  assignmentId: Scalars['String'];
  attachment: Attachment;
  pinId: Scalars['String'];
};

export type AssignmentOpenQuestionAddAttachmentPayload = {
  __typename?: 'AssignmentOpenQuestionAddAttachmentPayload';
  id: Scalars['ID'];
};

export type AssignmentRemoveAttachmentInput = {
  assignmentId: Scalars['String'];
  index: Scalars['Int'];
  pinId: Scalars['String'];
};

export type AssignmentRemoveAttachmentPayload = {
  __typename?: 'AssignmentRemoveAttachmentPayload';
  id: Scalars['ID'];
};

export type AssignmentSetCommentsViewedInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type AssignmentSetCommentsViewedPayload = {
  __typename?: 'AssignmentSetCommentsViewedPayload';
  id: Scalars['ID'];
};

export type AssignmentSetViewedInput = {
  assignmentId: Scalars['String'];
};

export type AssignmentSetViewedPayload = {
  __typename?: 'AssignmentSetViewedPayload';
  id: Scalars['ID'];
};

export type AssignmentSetVisibilityInput = {
  assignmentId: Scalars['String'];
  visibilityState: Visibility;
};

export type AssignmentSetVisibilityPayload = {
  __typename?: 'AssignmentSetVisibilityPayload';
  id: Scalars['ID'];
};

export type AssignmentStatus =
  | 'CLOSED'
  | 'OPEN';

export type AssignmentSubmitPollInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
  value: Scalars['Int'];
};

export type AssignmentSubmitPollPayload = {
  __typename?: 'AssignmentSubmitPollPayload';
  id: Scalars['ID'];
};

export type AssignmentType =
  | 'HOMEWORK'
  | 'REALTIME'
  | 'TEST';

export type AssignmentUpdateActivityInput = {
  assignmentId: Scalars['String'];
  timeSpent: Array<Delta>;
  type?: InputMaybe<AssignmentType>;
};

export type AssignmentUpdateActivityPayload = {
  __typename?: 'AssignmentUpdateActivityPayload';
  id: Scalars['ID'];
};

export type AttachSchoolSuggestionToOrganizationInput = {
  globalSchoolId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type AttachSchoolSuggestionToOrganizationPayload = {
  __typename?: 'AttachSchoolSuggestionToOrganizationPayload';
  id: Scalars['String'];
};

export type Attachment = {
  imageUrl: Scalars['String'];
  uploadId?: InputMaybe<Scalars['String']>;
};

export type AudioFile = File & {
  __typename?: 'AudioFile';
  /** The MIME type of the audio. */
  contentType: Scalars['String'];
  /** The duration of the audio in seconds. */
  durationInSeconds: Scalars['Float'];
  /** The file extension of the file (png, pdf, etc.). */
  fileExtension: Scalars['String'];
  /** The file size of the audio in bytes. */
  sizeInBytes: Scalars['Int'];
  /** The media server URL from which the audio can be streamed. */
  url: Scalars['URL'];
};

export type AudioFileInput = {
  contentType: Scalars['String'];
  duration: Scalars['Float'];
  extension: Scalars['String'];
  location: LocationInput;
  size: Scalars['Int'];
  url: Scalars['URL'];
};

export type AudioUpload = Node & Upload & {
  __typename?: 'AudioUpload';
  /** The audio, converted to our standard format. Can be null in case we're still processing the uploaded file. */
  audio?: Maybe<AudioFile>;
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type AuthorizationActionType =
  | 'ASSIGNMENT_DELETE'
  | 'ASSIGNMENT_READ'
  | 'ASSIGNMENT_UPDATE'
  | 'EXPLORER_READ'
  | 'EXPLORER_WRITE'
  | 'FOLDER_DELETE'
  | 'FOLDER_READ'
  | 'FOLDER_UPDATE'
  | 'LESSON_ASSIGN_TO_PRODUCT'
  | 'LESSON_COPY'
  | 'LESSON_DELETE'
  | 'LESSON_READ'
  | 'LESSON_UPDATE'
  | 'PRODUCT_USE';

export type AutoJoinInput = {
  assignmentId: Scalars['String'];
};

export type AutoJoinPayload = {
  __typename?: 'AutoJoinPayload';
  id: Scalars['ID'];
};

export type BaseAgreement = {
  /** Date of when the agreement was created */
  creationDate: Scalars['DateTime'];
  /** Represents the details of the last signed proposal with fewer details */
  details?: Maybe<AgreementDetails>;
  /** Agreement document with the format TYPE-LANGUAGE-VERSION */
  document: AgreementDocument;
  id: Scalars['ID'];
  /** The ID of the Organization for which the Agreement needs to be signed or has been signed */
  organization: Scalars['ID'];
  /** Represents the ID of the User that requested the agreement */
  requestUser: Scalars['ID'];
  secret: Scalars['String'];
  /** An array with all proposals */
  signProposals: Array<AgreementSignProposal>;
  /** Date of when the agreement was signed */
  signed?: Maybe<Scalars['DateTime']>;
  /** Represents the ID of the last signed proposal */
  signedProposal?: Maybe<Scalars['ID']>;
};

export type BaseGift = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: GiftStatus;
};

export type BlockNoticeType =
  | 'BANNER'
  | 'MODAL';

export type BlockNoticesInput = {
  /** Canonical key for the notice. Identifies notices with the same purpose. */
  key: Scalars['String'];
  /** Type of the notice */
  type: BlockNoticeType;
};

export type BlockNoticesPayload = {
  __typename?: 'BlockNoticesPayload';
  id: Scalars['ID'];
};

export type CalculateUserLicenseInput = {
  userId: Scalars['ID'];
};

export type CalculateUserLicensePayload = {
  __typename?: 'CalculateUserLicensePayload';
  /** indicate if the license of the user did change */
  changed: Scalars['Boolean'];
};

export type CanMergeUsersError = {
  __typename?: 'CanMergeUsersError';
  /** Technical reason why the merge fails. Not ment for end consumers, internal usage only. */
  reason: Scalars['String'];
};

export type CanMergeUsersInput = {
  sourceUserId: Scalars['ID'];
  targetUserId: Scalars['ID'];
};

export type CanMergeUsersPayload = {
  __typename?: 'CanMergeUsersPayload';
  /** Errors that occur during the merge. If the array is empty the merge can be performed successfully. */
  errors: Array<CanMergeUsersError>;
};

export type CancelAccountDeletionForUserInput = {
  /** The user ID to cancel the account deletion for */
  userId: Scalars['ID'];
};

export type CancelAccountDeletionForUserPayload = {
  __typename?: 'CancelAccountDeletionForUserPayload';
  /** The ID of the canceled account deletion request */
  canceledAccountDeletionRequest: Scalars['ID'];
};

export type CancelAccountDeletionPayload = {
  __typename?: 'CancelAccountDeletionPayload';
  canceledAccountDeletionRequest: Scalars['ID'];
};

/** Input for CancelStripeSubscription mutation */
export type CancelStripeSubscriptionInput = {
  userId: Scalars['ID'];
};

/** Payload for CancelStripeSubscription mutation */
export type CancelStripeSubscriptionPayload = {
  __typename?: 'CancelStripeSubscriptionPayload';
  /** The reason this subscription is cancelled */
  reason?: Maybe<Scalars['String']>;
  /** Whether this subscription is cancelled successfully */
  subscriptionCanceled: Scalars['Boolean'];
};

/** Shared interface containing the basic properties visible by all */
export type Channel = Node & {
  __typename?: 'Channel';
  id: Scalars['ID'];
  /** Slug which can be used in URL, unique over all channels */
  slug: Scalars['String'];
};

export type CheckOpenQuestionInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
  text: Scalars['String'];
};

export type CheckOpenQuestionPayload = {
  __typename?: 'CheckOpenQuestionPayload';
  answer?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
};

export type CheckOpenQuestionPinStudentAnswersInput = {
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type CheckOpenQuestionStudentAnswersPayload = {
  __typename?: 'CheckOpenQuestionStudentAnswersPayload';
  success: Scalars['Boolean'];
};

export type CheckQuizPinStudentAnswersInput = {
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type CheckQuizPinStudentAnswersPayload = {
  __typename?: 'CheckQuizPinStudentAnswersPayload';
  success: Scalars['Boolean'];
};

export type ChildUploadFolder = {
  __typename?: 'ChildUploadFolder';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ChildUploadFolderOrUpload = AudioUpload | ChildUploadFolder | DocumentUpload | ImageUpload | SlideDeckUpload | VideoUpload;

export type ClearAssignmentEntryInput = {
  assignmentId: Scalars['String'];
};

export type ClearAssignmentEntryPayload = {
  __typename?: 'ClearAssignmentEntryPayload';
  id: Scalars['ID'];
};

export type CloseAssignmentIfEmptyOrDirectCloseRouteInput = {
  assignmentId: Scalars['String'];
  source?: InputMaybe<TeachSource>;
};

export type CloseAssignmentIfEmptyOrDirectCloseRoutePayload = {
  __typename?: 'CloseAssignmentIfEmptyOrDirectCloseRoutePayload';
  id: Scalars['ID'];
};

export type CloseOpenRealtimeAssignmentsInput = {
  placeholder?: InputMaybe<Scalars['String']>;
};

export type CloseOpenRealtimeAssignmentsPayload = {
  __typename?: 'CloseOpenRealtimeAssignmentsPayload';
  id: Scalars['ID'];
};

export type CloseOpenRealtimeAssignmentsWithoutStudentsInput = {
  placeholder?: InputMaybe<Scalars['String']>;
};

export type CloseOpenRealtimeAssignmentsWithoutStudentsPayload = {
  __typename?: 'CloseOpenRealtimeAssignmentsWithoutStudentsPayload';
  id: Scalars['ID'];
};

export type CloseRaisedHandInput = {
  assignmentId: Scalars['String'];
  students: Array<Scalars['String']>;
};

export type CloseRaisedHandPayload = {
  __typename?: 'CloseRaisedHandPayload';
  id: Scalars['ID'];
};

export type Colleague = Node & User & {
  __typename?: 'Colleague';
  /** Email or username the users used to login */
  accountName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Name of the user */
  name?: Maybe<Scalars['String']>;
};

export type ComponentPosition = {
  left: Scalars['Int'];
  top: Scalars['Int'];
};

export type ComponentPositionUpdateData = {
  componentId: Scalars['String'];
  inDropzone?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<ComponentPosition>;
};

export type ConversionError = {
  __typename?: 'ConversionError';
  componentId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  pinId: Scalars['ID'];
};

export type ConvertAssignmentToLessonInput = {
  assignmentId: Scalars['String'];
};

export type ConvertAssignmentToLessonPayload = {
  __typename?: 'ConvertAssignmentToLessonPayload';
  id: Scalars['ID'];
};

export type CopyPinsToLessonInput = {
  newLessonId: Scalars['ID'];
  oldLessonId: Scalars['ID'];
};

export type CopyPinsToLessonPayload = {
  __typename?: 'CopyPinsToLessonPayload';
  copiedPins?: Maybe<Scalars['NonNegativeInt']>;
};

export type CreateAssignmentFromLessonIdInput = {
  lessonId: Scalars['String'];
};

export type CreateAssignmentFromLessonIdPayload = {
  __typename?: 'CreateAssignmentFromLessonIdPayload';
  assignmentId: Scalars['ID'];
};

/** Input for the CreateBillingPortalUrl mutation */
export type CreateBillingPortalUrlInput = {
  language?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['URL'];
};

/** Payload for the CreateBillingPortalUrl mutation */
export type CreateBillingPortalUrlPayload = {
  __typename?: 'CreateBillingPortalUrlPayload';
  /** URL to billing portal */
  url: Scalars['URL'];
};

export type CreateCheckoutUrlInput = {
  cancelUrl: Scalars['URL'];
  language?: InputMaybe<Scalars['String']>;
  priceId: Scalars['String'];
  successUrl: Scalars['URL'];
};

export type CreateCheckoutUrlPayload = {
  __typename?: 'CreateCheckoutUrlPayload';
  url: Scalars['URL'];
};

export type CreateDomainRuleInput = {
  description: Scalars['NonEmptyString'];
  effect: DomainRuleEffect;
  initialRole?: InputMaybe<DomainRuleInitialRole>;
  organizationId: Scalars['ID'];
  priority?: InputMaybe<Scalars['NonNegativeInt']>;
  whiteList: Scalars['Boolean'];
};

export type CreateDomainRulePayload = {
  __typename?: 'CreateDomainRulePayload';
  domainRule: DomainRule;
};

export type CreateDuplicateResult = {
  __typename?: 'CreateDuplicateResult';
  assignmentId: Scalars['String'];
  memberCount?: Maybe<Scalars['Int']>;
};

export type CreateGdprAgreementForOrganizationContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type CreateGdprAgreementForOrganizationInput = {
  /** Contact information to be added to the sign proposal contacts */
  contact: CreateGdprAgreementForOrganizationContactInput;
  organizationId: Scalars['ID'];
};

export type CreateGdprAgreementForOrganizationPayload = {
  __typename?: 'CreateGDPRAgreementForOrganizationPayload';
  agreement: Agreement;
};

export type CreateGroupInput = {
  name: Scalars['String'];
  studentName?: InputMaybe<Scalars['String']>;
};

export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  group: TeacherGroup;
};

export type CreateHomeworkFromClassicLessonInput = {
  afterOrder?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  lessonId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  module: Scalars['String'];
  selectedMembers?: InputMaybe<Array<Scalars['String']>>;
  skipDuplicate?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CreateHomeworkFromClassicLessonPayload = {
  __typename?: 'CreateHomeworkFromClassicLessonPayload';
  result: CreateHomeworkResult;
};

export type CreateHomeworkFromLessonInput = {
  afterOrder?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  lessonId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  module: Scalars['String'];
  selectedMembers?: InputMaybe<Array<Scalars['String']>>;
  skipDuplicate?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateHomeworkFromLessonPayload = {
  __typename?: 'CreateHomeworkFromLessonPayload';
  result: CreateHomeworkResult;
};

export type CreateHomeworkLinkFromLessonInput = {
  label?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lessonId: Scalars['String'];
  showFinalSlide?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateHomeworkLinkFromLessonPayload = {
  __typename?: 'CreateHomeworkLinkFromLessonPayload';
  result: CreateHomeworkResult;
};

export type CreateHomeworkResult = CreateDuplicateResult | CreateSuccessResult;

export type CreateLessonForUploadInput = {
  uploadId: Scalars['ID'];
};

export type CreateLessonForUploadPayload = {
  __typename?: 'CreateLessonForUploadPayload';
  lesson: Lesson;
};

export type CreateLessonInput = {
  /** We might be able to remove country but to be backwards compatible we're keeping it for now */
  country?: InputMaybe<Scalars['CountryCode']>;
  location?: InputMaybe<LessonLocation>;
  name: Scalars['String'];
  pins?: InputMaybe<Array<Scalars['TeacherPin']>>;
  source?: InputMaybe<LessonCreationSource>;
};

export type CreateLessonModuleInput = {
  groupId: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateLessonModulePayload = {
  __typename?: 'CreateLessonModulePayload';
  lessonModule: LessonModule;
};

export type CreateLessonPayload = {
  __typename?: 'CreateLessonPayload';
  lesson: Lesson;
};

export type CreateLessonV2FromV1Input = {
  id: Scalars['ID'];
};

export type CreateLessonV2FromV1Payload = {
  __typename?: 'CreateLessonV2FromV1Payload';
  errors: Array<ConversionError>;
  id: Scalars['ID'];
};

export type CreateNoticeBannerInput = {
  /** Canonical key for the notice. Designed to be unique per user except for the version. */
  key: Scalars['String'];
  /** If specified, the notice will display a link to the given URL. */
  link?: InputMaybe<NoticeInputLink>;
  /** Translation key or plain text for the message of the notice. */
  message: Scalars['String'];
  /** If specified, the notice will be deleted after this date. */
  notAfter?: InputMaybe<Scalars['DateTime']>;
  /** If specified, the notice will not be shown until this date. */
  notBefore?: InputMaybe<Scalars['DateTime']>;
  /** Dynamic parameters for the translation content. */
  params?: InputMaybe<Scalars['JSONObject']>;
  /** Translation key or plain text for the title of the notice. */
  title: Scalars['String'];
  /** User that should see the notice */
  userId: Scalars['String'];
  /** Version of the notice. If a notice with the same key and a higher version is created, the old notices will be superseded. Will automatically be incremented if not specified. */
  version?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CreateNoticeBannerPayload = {
  __typename?: 'CreateNoticeBannerPayload';
  notice?: Maybe<Notice>;
};

export type CreateNoticeModalInput = {
  /** If specified, image to display on the notice. */
  image?: InputMaybe<NoticeInputImage>;
  /** Canonical key for the notice. Designed to be unique per user except for the version. */
  key: Scalars['String'];
  /** If specified, the notice will display a link to the given URL. */
  link?: InputMaybe<NoticeInputLink>;
  /** Translation keys or plain texts for the message of the notice. */
  messages: Array<Scalars['String']>;
  /** If specified, the notice will be deleted after this date. */
  notAfter?: InputMaybe<Scalars['DateTime']>;
  /** If specified, the notice will not be shown until this date. */
  notBefore?: InputMaybe<Scalars['DateTime']>;
  /** Dynamic parameters for the translation content. */
  params?: InputMaybe<Scalars['JSONObject']>;
  /** Translation key or plain text for the title of the notice. */
  title: Scalars['String'];
  /** User that should see the notice */
  userId: Scalars['String'];
  /** Version of the notice. If a notice with the same key and a higher version is created, the old notices will be superseded. Will automatically be incremented if not specified. */
  version?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CreateNoticeModalPayload = {
  __typename?: 'CreateNoticeModalPayload';
  notice?: Maybe<Notice>;
};

export type CreateOrganizationInput = {
  slug: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  organization: Organization;
};

export type CreateRealtimeAssignmentInput = {
  lessonId: Scalars['ID'];
  startPin?: InputMaybe<Scalars['String']>;
};

export type CreateRealtimeAssignmentPayload = {
  __typename?: 'CreateRealtimeAssignmentPayload';
  assignmentId: Scalars['ID'];
};

export type CreateSchoolSuggestionFromOrganizationInput = {
  organizationId: Scalars['String'];
};

export type CreateSchoolSuggestionFromOrganizationPayload = {
  __typename?: 'CreateSchoolSuggestionFromOrganizationPayload';
  id: Scalars['ID'];
};

export type CreateSharedExplorersInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type CreateSharedExplorersPayload = {
  __typename?: 'CreateSharedExplorersPayload';
  archiveExplorer: SharedExplorer;
  contentExplorer: SharedExplorer;
  trashExplorer: SharedExplorer;
};

export type CreateSuccessResult = {
  __typename?: 'CreateSuccessResult';
  assignmentId: Scalars['String'];
  assignmentName?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateTeachingCreditsMutationInput = {
  /** The amount of teaching credits to be added or subtracted. Use a negative integer to subtract credits */
  creditsMutation: Scalars['Int'];
  /** Optional description of why the teaching credits were added or subtracted */
  description?: InputMaybe<Scalars['String']>;
  /** The user ID of the user whose teaching credits balance needs to be changed */
  userId: Scalars['ID'];
};

export type CreateTeachingCreditsMutationPayload = {
  __typename?: 'CreateTeachingCreditsMutationPayload';
  /**
   * The teaching credits mutation that was stored.
   * Is null when the user has a license without any teaching restrictions.
   */
  teachingCreditsMutation?: Maybe<TeachingCreditsMutation>;
};

export type CreateTestFromLessonInput = {
  durationInMin?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['String'];
  isLinear?: InputMaybe<Scalars['Boolean']>;
  lessonId: Scalars['String'];
  lockStudents?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  module: Scalars['ID'];
  randomiseQuestions?: InputMaybe<Scalars['Boolean']>;
  selectedMembers?: InputMaybe<Array<Scalars['String']>>;
  skipDuplicate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateTestFromLessonPayload = DuplicateResult | SuccessResult;

export type CreateTestStudentInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CreateTestStudentPayload = {
  __typename?: 'CreateTestStudentPayload';
  user: Viewer;
};

export type CreateTrialGiftInput = {
  amountOfDays: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateTrialGiftPayload = {
  __typename?: 'CreateTrialGiftPayload';
  gift: TrialGift;
};

export type CreateUploadFolderInput = {
  name: Scalars['String'];
  /** The ID of the parent folder. */
  parentFolderId: Scalars['ID'];
};

export type CreateUploadFolderPayload = {
  __typename?: 'CreateUploadFolderPayload';
  folder: UploadFolder;
};

export type CreateUploadForAssignmentInput = {
  assignmentId: Scalars['String'];
  contentType: Scalars['String'];
  /** The ID of the folder to which the upload should be added. If not specified, the upload will be added to the root folder. */
  folderId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  /** The file size of the file in bytes. */
  sizeInBytes: Scalars['Int'];
};

export type CreateUploadForAssignmentPayload = {
  __typename?: 'CreateUploadForAssignmentPayload';
  upload: Upload;
  uploadUrl: Scalars['URL'];
};

export type CreateUploadForSlideDeckImportInput = {
  contentType: Scalars['String'];
  /** The ID of the folder to which the upload should be added. If not specified, the upload will be added to the root folder. */
  folderId?: InputMaybe<Scalars['ID']>;
  /** The desired location in which the lesson will be stored. If not specified, the location will not be set. */
  lessonLocation?: InputMaybe<LessonLocation>;
  lessonName: Scalars['String'];
  name: Scalars['String'];
  /** The file size of the file in bytes. */
  sizeInBytes: Scalars['Int'];
};

export type CreateUploadInput = {
  contentType: Scalars['String'];
  /** The ID of the folder to which the upload should be added. If not specified, the upload will be added to the root folder. */
  folderId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  /** The file size of the file in bytes. */
  sizeInBytes: Scalars['Int'];
};

export type CreateUploadPayload = {
  __typename?: 'CreateUploadPayload';
  upload: Upload;
  uploadUrl: Scalars['URL'];
};

export type DeleteAgreementInput = {
  agreementId: Scalars['ID'];
};

export type DeleteAgreementPayload = {
  __typename?: 'DeleteAgreementPayload';
  agreementId: Scalars['ID'];
};

export type DeleteAssignmentInput = {
  assignmentId: Scalars['String'];
};

export type DeleteAssignmentPayload = {
  __typename?: 'DeleteAssignmentPayload';
  id: Scalars['ID'];
};

export type DeleteUploadFoldersInput = {
  folderIds: Array<Scalars['ID']>;
};

export type DeleteUploadFoldersPayload = {
  __typename?: 'DeleteUploadFoldersPayload';
  folderIds: Array<Scalars['ID']>;
};

export type DeleteUploadsInput = {
  uploadIds: Array<Scalars['ID']>;
};

export type DeleteUploadsPayload = {
  __typename?: 'DeleteUploadsPayload';
  uploadIds: Array<Scalars['ID']>;
};

export type Delta = {
  elapsedTime: Scalars['Int'];
  pinId: Scalars['String'];
};

export type DetachSchoolSuggestionFromOrganizationInput = {
  globalSchoolId: Scalars['String'];
};

export type DetachSchoolSuggestionFromOrganizationPayload = {
  __typename?: 'DetachSchoolSuggestionFromOrganizationPayload';
  id: Scalars['String'];
};

export type DetachSchoolSuggestionsOnOrganizationDeletionInput = {
  organizationId: Scalars['String'];
};

export type DetachSchoolSuggestionsOnOrganizationDeletionPayload = {
  __typename?: 'DetachSchoolSuggestionsOnOrganizationDeletionPayload';
  deletedCount: Scalars['NonNegativeInt'];
  modifiedCount: Scalars['NonNegativeInt'];
};

export type DismissNoticeInput = {
  id: Scalars['ID'];
};

export type DismissNoticePayload = {
  __typename?: 'DismissNoticePayload';
  id: Scalars['ID'];
};

export type DocumentUpload = Node & Upload & {
  __typename?: 'DocumentUpload';
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The pages of the uploaded document, each exported as an image. */
  pages: Array<ImageFile>;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The thumbnail image that can be used for the overview page. */
  thumbnail?: Maybe<ImageFile>;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type DomainRule = Node & {
  __typename?: 'DomainRule';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  domains?: Maybe<Array<Scalars['String']>>;
  effect: DomainRuleEffect;
  id: Scalars['ID'];
  initialRole: DomainRuleInitialRole;
  organizationId: Scalars['ID'];
  priority: Scalars['Int'];
  regexToMatchTeacherEmail?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userServiceFields?: Maybe<Array<DomainRuleUserServiceField>>;
  whiteList: Scalars['Boolean'];
};

export type DomainRuleEffect =
  | 'AUTO_JOIN'
  | 'NONE'
  | 'SUGGESTION';

export type DomainRuleInitialRole =
  | 'BASIC'
  | 'MEMBER'
  | 'PENDING';

export type DomainRuleUserServiceField = {
  __typename?: 'DomainRuleUserServiceField';
  fieldName: Scalars['String'];
  service: DomainRuleUserServiceFieldService;
  values?: Maybe<Array<Scalars['String']>>;
};

export type DomainRuleUserServiceFieldService =
  | 'CANVAS'
  | 'EMAIL'
  | 'GOOGLE'
  | 'OFFICE_365'
  | 'SURFCONEXT';

export type DownloadOriginalUploadInput = {
  uploadId: Scalars['ID'];
};

export type DownloadOriginalUploadPayload = {
  __typename?: 'DownloadOriginalUploadPayload';
  contentDispositionHeader: Scalars['String'];
  url: Scalars['URL'];
};

export type DuplicateResult = {
  __typename?: 'DuplicateResult';
  duplicateCreatedAt?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type EditAnswerWordCloudForRealtimeAssignmentInput = {
  answer: Scalars['NonEmptyString'];
  assignmentId: Scalars['ID'];
  originalAnswer: Scalars['NonEmptyString'];
  pinId: Scalars['ID'];
};

export type EditAnswerWordCloudForRealtimeAssignmentPayload = {
  __typename?: 'EditAnswerWordCloudForRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type Explorer = {
  authorization: ExplorerAuthPrivileges;
  createdAt: Scalars['DateTime'];
  folders: ExplorerFolderToExplorerFoldersConnection;
  id: Scalars['ID'];
  /** ID of the corresponding 'content' explorer or self-ref if type is 'content' */
  mainExplorerId: Scalars['String'];
  name: Scalars['String'];
  privacy: ExplorerPrivacy;
  type: ExplorerType;
};


export type ExplorerFoldersArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type ExplorerAuthPrivileges = {
  __typename?: 'ExplorerAuthPrivileges';
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type ExplorerFolder = {
  __typename?: 'ExplorerFolder';
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['String'];
  products?: Maybe<Array<Scalars['String']>>;
};

export type ExplorerFolderToExplorerFolderEdge = {
  __typename?: 'ExplorerFolderToExplorerFolderEdge';
  node?: Maybe<ExplorerFolder>;
};

export type ExplorerFolderToExplorerFoldersConnection = {
  __typename?: 'ExplorerFolderToExplorerFoldersConnection';
  edges: Array<ExplorerFolderToExplorerFolderEdge>;
  nodes: Array<Maybe<ExplorerFolder>>;
  pageInfo: OffsetBasedPageInfo;
  totalCount: Scalars['NonNegativeInt'];
};

export type ExplorerOrganization = {
  __typename?: 'ExplorerOrganization';
  id: Scalars['String'];
  permission?: Maybe<ExplorerOrganizationPermission>;
};

export type ExplorerOrganizationPermission =
  | 'NONE'
  | 'READ'
  | 'WRITE';

export type ExplorerPrivacy =
  | 'PRIVATE'
  | 'PROTECTED'
  | 'PUBLIC';

export type ExplorerType =
  | 'ARCHIVE'
  | 'CONTENT'
  | 'TRASH';

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  accountMergeFlag: Scalars['Boolean'];
  administerSecondaryEmail: Scalars['Boolean'];
  allEditorFocusWeekImprovements: Scalars['Boolean'];
  channelStatsVersion2: Scalars['Boolean'];
  columns: Scalars['Boolean'];
  conversionStatusInModal: Scalars['Boolean'];
  convertLessonImplicitly: Scalars['Boolean'];
  cookiePopup: Scalars['Boolean'];
  debugChannelEvents: Scalars['Boolean'];
  defaultOrganizationExplorerPermissions: Scalars['Boolean'];
  disableSessionLock: Scalars['Boolean'];
  downloadReportSpreadsheet: Scalars['Boolean'];
  editorAllPinComponents: Scalars['Boolean'];
  editorBreakEditor: Scalars['Boolean'];
  editorImageCropping: Scalars['Boolean'];
  editorLockLayoutSetting: Scalars['Boolean'];
  editorMaiaV2Lessons: Scalars['Boolean'];
  editorOpenQuestion: Scalars['Boolean'];
  editorPlayerV2: Scalars['Boolean'];
  editorPresenterMode: Scalars['Boolean'];
  editorShowTypographyTypePicker: Scalars['Boolean'];
  editorSidebarLayoutPosition: Scalars['Boolean'];
  editorSidebarLayoutSize: Scalars['Boolean'];
  editorSpinnerPinComponent: Scalars['Boolean'];
  editorTextShadow: Scalars['Boolean'];
  editorTopBarCleanup: Scalars['Boolean'];
  editorUndoRedo: Scalars['Boolean'];
  editorVideoPinComponent: Scalars['Boolean'];
  editorWarningBanner: Scalars['Boolean'];
  editorWordCloud: Scalars['Boolean'];
  emptyFirstPin: Scalars['Boolean'];
  explorerChatGptBanner: Scalars['Boolean'];
  floatSelector: Scalars['Boolean'];
  gothamFontPicker: Scalars['Boolean'];
  joinWithQr: Scalars['Boolean'];
  lessonEditorV2: Scalars['Boolean'];
  lessonModules: Scalars['Boolean'];
  lessonPdfExport: Scalars['Boolean'];
  lessonPlan: Scalars['Boolean'];
  markLessonsWithFinalSlide: Scalars['Boolean'];
  metabaseChannelStats: Scalars['Boolean'];
  mySchoolsPage: Scalars['Boolean'];
  newCharts: Scalars['Boolean'];
  newComponentModal: Scalars['Boolean'];
  newPinSearch: Scalars['Boolean'];
  openAiBeta: Scalars['Boolean'];
  playerComponentsMenu: Scalars['Boolean'];
  promoBanner: Scalars['Boolean'];
  redeemedVoucherOverview: Scalars['Boolean'];
  refactorLessonPlan: Scalars['Boolean'];
  reportsV2: Scalars['Boolean'];
  restoreLessonsFromAssignments: Scalars['Boolean'];
  schoolSuggestionPage: Scalars['Boolean'];
  showSchoolLicenseDemoteOption: Scalars['Boolean'];
  slideDeckImport: Scalars['Boolean'];
  superCsvExports: Scalars['Boolean'];
  symbolPatterns: Scalars['Boolean'];
  teacherReferral: Scalars['Boolean'];
  uploadVideo: Scalars['Boolean'];
};

export type FetchAssignmentDataForReportInput = {
  assignmentId: Scalars['String'];
};

export type FetchAssignmentDataForReportPayload = {
  __typename?: 'FetchAssignmentDataForReportPayload';
  success: Scalars['Boolean'];
};

export type File = {
  /** The MIME type of the file. */
  contentType: Scalars['String'];
  /** The file extension of the file (png, pdf, etc.). */
  fileExtension: Scalars['String'];
  /** The file size of the file in bytes. */
  sizeInBytes: Scalars['Int'];
};

export type FolderAuthPrivileges = {
  __typename?: 'FolderAuthPrivileges';
  delete: Scalars['Boolean'];
  read: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type GenerateContextualLessonInput = {
  language?: Language;
  lessonOutline: LessonOutlineInput;
};

export type GenerateContextualLessonPayload = {
  __typename?: 'GenerateContextualLessonPayload';
  lesson: GeneratedLessonDocument;
};

export type GenerateContextualLessonSubscriptionInput = {
  language?: Language;
  lessonOutline: LessonOutlineInput;
};

export type GenerateContextualLessonSubscriptionPayload = {
  __typename?: 'GenerateContextualLessonSubscriptionPayload';
  lesson: GeneratedLessonDocumentPartial;
};

export type GenerateLessonInput = {
  language?: Language;
  numberOfSlides: Scalars['PositiveInt'];
  prompt: Scalars['String'];
};

export type GenerateLessonOutlineInput = {
  language?: Language;
  prompt: Scalars['String'];
};

export type GenerateLessonOutlinePayload = {
  __typename?: 'GenerateLessonOutlinePayload';
  lessonOutline: LessonOutline;
};

export type GenerateLessonOutlineSubscriptionInput = {
  language?: Language;
  prompt: Scalars['String'];
};

export type GenerateLessonOutlineSubscriptionPayload = {
  __typename?: 'GenerateLessonOutlineSubscriptionPayload';
  lessonOutline: LessonOutlinePartial;
};

export type GenerateLessonPayload = {
  __typename?: 'GenerateLessonPayload';
  lesson: GeneratedLessonDocument;
};

export type GenerateLessonSubscriptionInput = {
  language?: Language;
  numberOfSlides: Scalars['PositiveInt'];
  prompt: Scalars['String'];
};

export type GenerateLessonSubscriptionPayload = {
  __typename?: 'GenerateLessonSubscriptionPayload';
  lesson: GeneratedLessonDocumentPartial;
};

export type GenerateMultipleChoiceQuestionsInput = {
  language?: Language;
  numberOfQuestions: Scalars['PositiveInt'];
  prompt: Scalars['String'];
};

export type GenerateMultipleChoiceQuestionsPayload = {
  __typename?: 'GenerateMultipleChoiceQuestionsPayload';
  questions: Array<GeneratedMultipleChoiceQuestion>;
};

export type GeneratePollInput = {
  language?: Language;
  numberOfAnswers: Scalars['PositiveInt'];
  numberOfPolls: Scalars['PositiveInt'];
  prompt: Scalars['String'];
};

export type GeneratePollPayload = {
  __typename?: 'GeneratePollPayload';
  polls: Array<GeneratedPoll>;
};

export type GeneratedLessonDocument = {
  __typename?: 'GeneratedLessonDocument';
  introQuestion: Scalars['String'];
  name: Scalars['String'];
  slides: Array<GeneratedLessonItem>;
};

export type GeneratedLessonDocumentPartial = {
  __typename?: 'GeneratedLessonDocumentPartial';
  introQuestion?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slides?: Maybe<Array<GeneratedLessonItem>>;
};

export type GeneratedLessonItem = GeneratedOpenQuestion | GeneratedSlide;

export type GeneratedMultipleChoiceQuestion = {
  __typename?: 'GeneratedMultipleChoiceQuestion';
  answers: Array<Scalars['String']>;
  /** 1-based index of the correct answer */
  correctAnswers: Array<Scalars['PositiveInt']>;
  question: Scalars['String'];
};

export type GeneratedOpenQuestion = {
  __typename?: 'GeneratedOpenQuestion';
  question: Scalars['String'];
};

export type GeneratedPoll = {
  __typename?: 'GeneratedPoll';
  answers: Array<Scalars['String']>;
  statement: Scalars['String'];
};

export type GeneratedSlide = {
  __typename?: 'GeneratedSlide';
  body: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GetOrCreateReferralPayload = {
  __typename?: 'GetOrCreateReferralPayload';
  referral: Referral;
};

export type GiftPurpose =
  | 'DEFAULT_REFEREE_GIFT';

export type GiftStatus =
  | 'ACTIVE'
  | 'ARCHIVED';

export type GiveGiftToUsersMutationInput = {
  giftId: Scalars['ID'];
  /** Reason why the gift was given */
  reason: Scalars['String'];
  /** Array with the IDs of the users to give the gift to */
  userIds: Array<Scalars['ID']>;
};

export type GiveGiftToUsersMutationPayload = {
  __typename?: 'GiveGiftToUsersMutationPayload';
  /** The total number of users that got the gift */
  count: Scalars['Int'];
  /** An array with the users that are not allowed to receive the gift */
  notGiftedUsers: Array<Viewer>;
};

export type Group = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HandInTestEntryInput = {
  assignmentId: Scalars['String'];
};

export type HandInTestEntryPayload = {
  __typename?: 'HandInTestEntryPayload';
  id: Scalars['ID'];
};

export type HomeworkCheckDragQuestionInput = {
  assignmentId: Scalars['String'];
  initialSubmit?: InputMaybe<Scalars['Boolean']>;
  pinId: Scalars['String'];
};

export type HomeworkCheckDragQuestionPayload = {
  __typename?: 'HomeworkCheckDragQuestionPayload';
  id: Scalars['ID'];
};

export type HomeworkResetDragQuestionInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type HomeworkResetDragQuestionPayload = {
  __typename?: 'HomeworkResetDragQuestionPayload';
  id: Scalars['ID'];
};

export type ImageFile = File & {
  __typename?: 'ImageFile';
  /** The MIME type of the thumbnail. */
  contentType: Scalars['String'];
  /** The file extension of the file (png, pdf, etc.). */
  fileExtension: Scalars['String'];
  /** The file size of the video in bytes. */
  sizeInBytes: Scalars['Int'];
  /** The image serving URL from which the thumbnail can be downloaded. */
  url: Scalars['URL'];
};

export type ImageFileInput = {
  contentType: Scalars['String'];
  extension: Scalars['String'];
  location: LocationInput;
  size: Scalars['Int'];
  url: Scalars['URL'];
};

export type ImageUpload = Node & Upload & {
  __typename?: 'ImageUpload';
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The converted image. Can be null in case we're still processing the uploaded file. */
  image?: Maybe<ImageFile>;
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The thumbnail image that can be used for the overview page. */
  thumbnail?: Maybe<ImageFile>;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  user: Scalars['String'];
};

export type JoinRealtimeAssignmentInput = {
  pincode: Scalars['String'];
};

export type JoinRealtimeAssignmentPayload = {
  __typename?: 'JoinRealtimeAssignmentPayload';
  assignment: Assignment;
  isV2Assignment: Scalars['Boolean'];
};

export type JoinRealtimeAssignmentSessionFailureResult = {
  __typename?: 'JoinRealtimeAssignmentSessionFailureResult';
  reason: JoinRealtimeAssignmentSessionFailureResultReason;
};

export type JoinRealtimeAssignmentSessionFailureResultReason =
  | 'CLOSED'
  | 'NOT_ALLOWED'
  | 'NOT_FOUND';

export type JoinRealtimeAssignmentSessionInput = {
  pincode: Scalars['String'];
};

export type JoinRealtimeAssignmentSessionPayload = JoinRealtimeAssignmentSessionFailureResult | JoinRealtimeAssignmentSessionSuccessResult;

export type JoinRealtimeAssignmentSessionSuccessResult = {
  __typename?: 'JoinRealtimeAssignmentSessionSuccessResult';
  assignment: Assignment;
  isV2Assignment: Scalars['Boolean'];
};

export type LtiPlatform =
  | 'canvas'
  | 'itslearning';

export type Language =
  | 'DE'
  | 'EN'
  | 'ES'
  | 'FR'
  | 'NL';

export type LeaveRealtimeAssignmentInput = {
  assignmentId: Scalars['ID'];
};

export type LeaveRealtimeAssignmentPayload = {
  __typename?: 'LeaveRealtimeAssignmentPayload';
  assignmentId: Scalars['ID'];
};

export type Lesson = Node & {
  __typename?: 'Lesson';
  authorization: LessonAuthPrivileges;
  createdWithSlideDeckImport: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pinCount: Scalars['Int'];
  reports: Array<Report>;
  teacherPinHashes: Array<PinHash>;
  teacherPins: Array<Scalars['TeacherPin']>;
};


export type LessonTeacherPinsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type LessonAuthPrivileges = {
  __typename?: 'LessonAuthPrivileges';
  assignToProduct: Scalars['Boolean'];
  copy: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  read: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type LessonCreationSource =
  | 'openAi'
  | 'slideDeckImport';

export type LessonLocation = {
  explorerId: Scalars['String'];
  folderId: Scalars['String'];
};

export type LessonModule = Node & {
  __typename?: 'LessonModule';
  /** Indicates that the group has been archived but still findable */
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** Link used to share the lesson module with students */
  directLink: Scalars['URL'];
  groupId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['String'];
};

export type LessonOutline = {
  __typename?: 'LessonOutline';
  condensedText: Scalars['String'];
  definitionList?: Maybe<Array<Scalars['String']>>;
  header: Scalars['String'];
  learningGoals?: Maybe<Array<Scalars['String']>>;
  mainTopics?: Maybe<Array<Scalars['String']>>;
};

export type LessonOutlineInput = {
  condensedText: Scalars['String'];
  definitionList?: InputMaybe<Array<Scalars['String']>>;
  header: Scalars['String'];
  learningGoals?: InputMaybe<Array<Scalars['String']>>;
  mainTopics?: InputMaybe<Array<Scalars['String']>>;
};

/** A partial lesson outline which can be displayed to the user while we wait for openai to generate more content */
export type LessonOutlinePartial = {
  __typename?: 'LessonOutlinePartial';
  condensedText?: Maybe<Scalars['String']>;
  definitionList?: Maybe<Array<Scalars['String']>>;
  header?: Maybe<Scalars['String']>;
  learningGoals?: Maybe<Array<Scalars['String']>>;
  mainTopics?: Maybe<Array<Scalars['String']>>;
};

export type LessonTaughtTeachingCreditMutation = Node & TeachingCreditMutation & {
  __typename?: 'LessonTaughtTeachingCreditMutation';
  /** The duration in seconds of how long the assignment was taught. */
  assignmentDurationInSeconds: Scalars['NonNegativeInt'];
  /** The ID of the assignment that was taught. */
  assignmentId: Scalars['ID'];
  /** The name of the assignment that was taught. */
  assignmentName: Scalars['String'];
  /** The creation date of the mutation. */
  createdAt: Scalars['DateTime'];
  /** The mutation in the credits balance. Is negative when credits were subtracted and positive when credits were added. */
  creditsMutation: Scalars['Int'];
  /** The ID of the mutation. */
  id: Scalars['ID'];
};

export type LicenseOrigin =
  | 'ORGANIZATION'
  | 'SUBSCRIPTION'
  | 'UNKNOWN'
  | 'VOUCHER';

export type LicenseStatus =
  | 'EXPIRED'
  | 'EXPIRING'
  | 'FREE'
  | 'PRO'
  | 'SCHOOL'
  | 'START'
  | 'TRIAL';

/** License subscription */
export type LicenseSubscription = Node & {
  __typename?: 'LicenseSubscription';
  /** Start and end date of current subscription, and whether it renews */
  currentPeriod: LicenseSubscriptionPeriod;
  /** ID of license subscription */
  id: Scalars['ID'];
  /** Subscription price info */
  price: Price;
  /** How often the subscription renews */
  recurring: LicenseSubscriptionRecurring;
  /** Status of subscription, can be: ACTIVE | INACTIVE */
  status: LicenseSubscriptionStatus;
  /** Subscription tier that user is subscribed to */
  tier?: Maybe<SubscriptionTier>;
};

/** With what interval this license subscription renews */
export type LicenseSubscriptionInterval =
  | 'MONTH'
  | 'YEAR';

/** Current period for the license subscription */
export type LicenseSubscriptionPeriod = {
  __typename?: 'LicenseSubscriptionPeriod';
  /** End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created */
  endAt: Scalars['DateTime'];
  /** Indicates that the subscription will renew if the end date is reached */
  renews: Scalars['Boolean'];
  /** Start of the current period that the subscription has been invoiced for */
  startAt: Scalars['DateTime'];
};

/** How often the license subscription renews */
export type LicenseSubscriptionRecurring = {
  __typename?: 'LicenseSubscriptionRecurring';
  /** The frequency at which a subscription is billed */
  interval: LicenseSubscriptionInterval;
  /** The number of intervals between subscription billings. For example, interval=month and interval_count=3 bills every 3 months */
  intervalCount: Scalars['Int'];
};

/** Status of license subscription */
export type LicenseSubscriptionStatus =
  /** The subscription is active and is being paid for, it will result in a license for the user */
  | 'ACTIVE'
  /** The subscription is inactive and no longer result in a license for the user */
  | 'INACTIVE';

export type LocationInput = {
  bucket: Scalars['String'];
  filename: Scalars['String'];
  path: Scalars['String'];
  projectId: Scalars['String'];
};

export type MaiaExample = {
  image: Scalars['URL'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type MaiaExampleType =
  | 'CONTEXT'
  | 'IMAGE'
  | 'PROMPT';

export type MaiaImageExample = MaiaExample & {
  __typename?: 'MaiaImageExample';
  image: Scalars['URL'];
  rawImages: Array<Scalars['URL']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

export type MaiaTextExample = MaiaExample & {
  __typename?: 'MaiaTextExample';
  image: Scalars['URL'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type MergeInfoForUser = {
  __typename?: 'MergeInfoForUser';
  hasActiveSubscription: Scalars['Boolean'];
};

export type MergeInfoForUsersInput = {
  sourceUserId: Scalars['ID'];
  targetUserId: Scalars['ID'];
};

export type MergeInfoForUsersPayload = {
  __typename?: 'MergeInfoForUsersPayload';
  sourceUserMergeInfo: MergeInfoForUser;
  targetUserMergeInfo: MergeInfoForUser;
};

export type MergeUsersInput = {
  sourceUserId: Scalars['ID'];
  targetUserId: Scalars['ID'];
};

export type MergeUsersPayload = {
  __typename?: 'MergeUsersPayload';
  merged: Scalars['Boolean'];
};

export type MoveUploadsAndFolderInput = {
  destinationFolderId: Scalars['ID'];
  folderIds: Array<Scalars['ID']>;
  uploadIds: Array<Scalars['ID']>;
};

export type MoveUploadsAndFolderPayload = {
  __typename?: 'MoveUploadsAndFolderPayload';
  folderIds: Array<Scalars['ID']>;
  uploadIds: Array<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept terms on behalf of a user */
  acceptUserTerms: AcceptUserTermsPayload;
  accessToAssignmentDataForLicense: AccessToAssignmentDataForLicensePayload;
  addGroupMember: TeacherGroup;
  addPinsToLesson: AddPinsToLessonPayload;
  /** Mutation to add a new proposal to the received agreement */
  addProposalToAgreement: AddProposalToAgreementPayload;
  addUserToOrganization: AddUserToOrganizationPayload;
  /** Perform an OCR analysis on an upload */
  analyzeUploadsForOCR: AnalyzeUploadsForOcrPayload;
  answerOpenQuestionForRealtimeAssignment: AnswerOpenQuestionForRealtimeAssignmentPayload;
  answerQuizForRealtimeAssignment: AnswerQuizForRealtimeAssignmentPayload;
  answerWordCloudForRealtimeAssignment: AnswerWordCloudForRealtimeAssignmentPayload;
  archiveSchoolSuggestion: ArchiveSchoolSuggestionPayload;
  assignmentByLink: AssignmentByLinkPayload;
  assignmentHide: AssignmentHidePayload;
  assignmentOpenQuestionAddAttachment: AssignmentOpenQuestionAddAttachmentPayload;
  assignmentRemoveAttachment: AssignmentRemoveAttachmentPayload;
  assignmentSetCommentsViewed: AssignmentSetCommentsViewedPayload;
  assignmentSetViewed: AssignmentSetViewedPayload;
  assignmentSetVisibility: AssignmentSetVisibilityPayload;
  assignmentSubmitPoll: AssignmentSubmitPollPayload;
  assignmentUpdateActivity: AssignmentUpdateActivityPayload;
  attachSchoolSuggestionToOrganization: AttachSchoolSuggestionToOrganizationPayload;
  autoJoin: AutoJoinPayload;
  blockNotices: BlockNoticesPayload;
  /** Calculates a new user license from different sources and stores it in our system */
  calculateUserLicense: CalculateUserLicensePayload;
  /** Cancel an existing account deletion request */
  cancelAccountDeletionRequest: CancelAccountDeletionPayload;
  /** Cancel an existing account deletion request for a given user if it's still before the scheduled date */
  cancelAccountDeletionRequestForUser: CancelAccountDeletionForUserPayload;
  /** Mutation to cancel Stripe subscription */
  cancelStripeSubscription: CancelStripeSubscriptionPayload;
  checkOpenQuestion: CheckOpenQuestionPayload;
  checkOpenQuestionPinStudentAnswers: CheckOpenQuestionStudentAnswersPayload;
  checkQuizPinStudentAnswers: CheckQuizPinStudentAnswersPayload;
  clearAssignmentEntry: ClearAssignmentEntryPayload;
  closeAssignmentIfEmptyOrDirectCloseRoute: CloseAssignmentIfEmptyOrDirectCloseRoutePayload;
  closeOpenRealtimeAssignments: CloseOpenRealtimeAssignmentsPayload;
  closeOpenRealtimeAssignmentsWithoutStudents: CloseOpenRealtimeAssignmentsWithoutStudentsPayload;
  closeRaisedHand: CloseRaisedHandPayload;
  convertAssignmentToLesson: ConvertAssignmentToLessonPayload;
  copyPinsToLesson: CopyPinsToLessonPayload;
  createAssignmentFromLessonId: CreateAssignmentFromLessonIdPayload;
  /** Mutation to create a new URL to the billing portal for this user */
  createBillingPortalUrl: CreateBillingPortalUrlPayload;
  createCheckoutUrl: CreateCheckoutUrlPayload;
  createDomainRule: CreateDomainRulePayload;
  /** Mutation to create a GDPR agreement for the received organization */
  createGDPRAgreementForOrganization: CreateGdprAgreementForOrganizationPayload;
  createGroup: CreateGroupPayload;
  createHomeworkFromClassicLesson: CreateHomeworkFromClassicLessonPayload;
  createHomeworkFromLesson: CreateHomeworkFromLessonPayload;
  createHomeworkLinkFromLesson: CreateHomeworkLinkFromLessonPayload;
  createLesson: CreateLessonPayload;
  createLessonForUpload: CreateLessonForUploadPayload;
  createLessonModule: CreateLessonModulePayload;
  createLessonV2FromV1: CreateLessonV2FromV1Payload;
  createNoticeBanner: CreateNoticeBannerPayload;
  createNoticeModal: CreateNoticeModalPayload;
  createOrganization: CreateOrganizationPayload;
  createRealtimeAssignment: CreateRealtimeAssignmentPayload;
  createSchoolSuggestionFromOrganization: CreateSchoolSuggestionFromOrganizationPayload;
  /**
   * Creates shared explorers within an organization, generating a main content explorer,
   * a trash explorer, and an archive explorer with specified name.
   * Returns details of each created explorer in the response.
   */
  createSharedExplorers: CreateSharedExplorersPayload;
  /**
   * Mutation to add or subtract teaching credits for a given user.
   * This mutation is intended to be used in the admin application by support.
   * Will ignore mutations for users who have a license without any teaching restrictions.
   */
  createTeachingCreditsMutation: CreateTeachingCreditsMutationPayload;
  createTestFromLesson: CreateTestFromLessonPayload;
  createTestStudent: CreateTestStudentPayload;
  createTrialGift: CreateTrialGiftPayload;
  createUpload: CreateUploadPayload;
  createUploadFolder: CreateUploadFolderPayload;
  /** Creates an upload with the intent to be used in an assignment */
  createUploadForAssignment: CreateUploadForAssignmentPayload;
  createUploadForOCR: CreateUploadPayload;
  createUploadForSlideDeckImport: CreateUploadPayload;
  /** Mutation used by the admin to delete an agreement */
  deleteAgreement: DeleteAgreementPayload;
  deleteAssignment: DeleteAssignmentPayload;
  /** recursively delete the folders and their contents, including child folders and their uploads */
  deleteUploadFolders: DeleteUploadFoldersPayload;
  /** Deletes uploads for the viewer. Won't throw an error if the uploads can't be found */
  deleteUploads: DeleteUploadsPayload;
  detachSchoolSuggestionFromOrganization: DetachSchoolSuggestionFromOrganizationPayload;
  detachSchoolSuggestionsOnOrganizationDeletion: DetachSchoolSuggestionsOnOrganizationDeletionPayload;
  dismissNotice: DismissNoticePayload;
  downloadOriginalUploadFile: DownloadOriginalUploadPayload;
  editAnswerWordCloudForRealtimeAssignment: EditAnswerWordCloudForRealtimeAssignmentPayload;
  fetchAssignmentDataForReport: FetchAssignmentDataForReportPayload;
  generateContextualLesson: GenerateContextualLessonPayload;
  generateLesson: GenerateLessonPayload;
  generateLessonOutline: GenerateLessonOutlinePayload;
  generateMultipleChoiceQuestions: GenerateMultipleChoiceQuestionsPayload;
  generatePoll: GeneratePollPayload;
  getOrCreateReferral: GetOrCreateReferralPayload;
  /**
   * Mutation to give a specific gift to a list of users.
   * This mutation is intended to be used in the admin application by support.
   */
  giveGiftToUsers: GiveGiftToUsersMutationPayload;
  handInTestEntry: HandInTestEntryPayload;
  homeworkCheckDragQuestion: HomeworkCheckDragQuestionPayload;
  homeworkResetDragQuestion: HomeworkResetDragQuestionPayload;
  /** @deprecated Use mutation joinRealtimeAssignmentSession instead. */
  joinRealtimeAssignment: JoinRealtimeAssignmentPayload;
  /** Mutation to join a user to a realtime assignment session using a pincode */
  joinRealtimeAssignmentSession: JoinRealtimeAssignmentSessionPayload;
  leaveRealtimeAssignment: LeaveRealtimeAssignmentPayload;
  /**
   * This mutation does the merge of user licenses and user uploads.
   * Old uploads/folders will point to the new users root folder.
   */
  mergeUsers: MergeUsersPayload;
  /** move both upload and folder ids to a new folder */
  moveUploadsAndFolders: MoveUploadsAndFolderPayload;
  myInternalFolderMutation?: Maybe<Scalars['String']>;
  myInternalOpenAiMutation?: Maybe<Scalars['String']>;
  myPublicTeachingCreditsMutation?: Maybe<Scalars['String']>;
  /** Mutation to publish an AMQP message */
  publishAmqpMessage: PublishAmqpMessagePayload;
  purgeV2Lessons: PurgeV2LessonsPayload;
  realtimeKeepAlive: RealtimeKeepAlivePayload;
  /** Register a new student user with LTI SSO. */
  registerStudentUserWithLtiSso: RegisterStudentUserWithLtiSsoPayload;
  /** Register a new user with email and password. */
  registerUserWithPassword: RegisterUserWithPasswordPayload;
  /** Remove Sso login service */
  removeSsoLoginService: RemoveSsoLoginServicePayload;
  removeUserFromAssignment: RemoveUserFromAssignmentPayload;
  renameGroup: RenameGroupPayload;
  reopenTestAssignment: ReopenTestAssignmentPayload;
  /** Request the deletion of an user account */
  requestAccountDeletionForUser: RequestAccountDeletionForUserPayload;
  requestAssignmentSummaryUpdate: RequestAssignmentSummaryUpdatePayload;
  requestUpgradeToMemberOfOrganization: RequestUpgradeToMemberOfOrganizationPayload;
  /** Request the deletion of own user account */
  requestUserAccountDeletion: RequestUserAccountDeletionPayload;
  resetTeachingCredits: ResetTeachingCreditsPayload;
  saveRaisedHandsToLog: SaveRaisedHandsToLogPayload;
  sendEmailVerificationEmail: SendEmailVerificationEmailPayload;
  setDisplayNameForRealtimeAssignment: SetDisplayNameForRealtimeAssignmentPayload;
  setGiftPurpose: SetGiftPurposePayload;
  setGradeSettings: SetGradeSettingsPayload;
  /** Mutation used by the QAs to set the organization address */
  setOrganizationAddress: SetOrganizationAddressPayload;
  /** Mutation used by the QAs to set the organization autoJoinWorkfolder setting */
  setOrganizationAutoJoinWorkFolderSetting: SetOrganizationAutoJoinWorkFolderSettingPayload;
  /** Mutation used by the QAs to set the organization canCreateVouchers setting */
  setOrganizationCanCreateVouchersSetting: SetOrganizationCanCreateVouchersSettingPayload;
  /** Mutation used by the QAs to set the organization city */
  setOrganizationCity: SetOrganizationCityPayload;
  /** Mutation used by the QAs to set the organization country */
  setOrganizationCountry: SetOrganizationCountryPayload;
  /** Mutation used by the QAs to set the organization inviteMailType setting */
  setOrganizationInviteMailTypeSetting: SetOrganizationInviteMailTypeSettingPayload;
  /** Mutation used by the QAs to set the organization isPublisher setting */
  setOrganizationIsPublisherSetting: SetOrganizationIsPublisherSettingPayload;
  /** Mutation used by the QAs to set the organization isSchool setting */
  setOrganizationIsSchoolSetting: SetOrganizationIsSchoolSettingPayload;
  /** Mutation used by the QAs to set the organization isSuper setting */
  setOrganizationIsSuperSetting: SetOrganizationIsSuperSettingPayload;
  /**
   * Mutation used by the QAs to set the organization license amount.
   * The license amount must be greater than or equal to the organization's current member count. Otherwise, an error will be thrown.
   */
  setOrganizationLicenseAmount: SetOrganizationLicenseAmountPayload;
  /** Mutation used by the QAs to set the organization license type */
  setOrganizationLicenseType: SetOrganizationLicenseTypePayload;
  /** Mutation used by the QAs to set the organization manageChildLicenses setting */
  setOrganizationManageChildLicensesSetting: SetOrganizationManageChildLicensesSettingPayload;
  /** Mutation used by the QAs to set the organization manageStudents setting */
  setOrganizationManageStudentsSetting: SetOrganizationManageStudentsSettingPayload;
  /** Mutation used by the QAs to set the organization multiLocation setting */
  setOrganizationMultiLocationSetting: SetOrganizationMultiLocationSettingPayload;
  setPinComment: SetPinCommentPayload;
  setPinEntryPoints: SetPinEntryPointsPayload;
  setPinsViewed: SetPinsViewedPayload;
  setRaiseHand: SetRaiseHandPayload;
  setReviewPhase: SetReviewPhasePayload;
  setReviewPhaseForStudent: SetReviewPhaseForStudentPayload;
  setSharedExplorerPermission: SetSharedExplorerPermissionPayload;
  setSubmitDragQuestion: SetSubmitDragQuestionPayload;
  showAnswerOpenQuestion: ShowAnswerOpenQuestionPayload;
  /** Mutation used by the admin to manually sign an agreement */
  signAgreementManually: SignAgreementManuallyPayload;
  /** Mutation to sign the agreement proposal */
  signAgreementProposal: SignAgreementProposalPayload;
  /**
   * Mutation to spend a teaching credit to teach the specified assignment.
   * Will only actually spend a teaching credit if all of these conditions are true:
   * - the user has a license with teaching restrictions.
   * - this assignment was not taught already by the user this month.
   * - the duration of the assignment was equal or greater than 5 minutes.
   * Otherwise no credits will be spent for teaching this assignment.
   */
  spendTeachingCreditToTeach: SpendTeachingCreditToTeachPayload;
  startTestAssignment: StartTestAssignmentPayload;
  stopTestAssignment: StopTestAssignmentPayload;
  submitDragQuestionComponentPosition: SubmitDragQuestionComponentPositionPayload;
  submitGrades: SubmitGradesPayload;
  submitQuizHomework: SubmitQuizHomeworkPayload;
  syncOrganizationDataToSchoolSuggestions: SyncOrganizationDataToSchoolSuggestionsPayload;
  teachRealtimeAssignment: TeachRealtimeAssignmentPayload;
  transferInvoices: TransferInvoicesPayload;
  /** Mutation to be used by the admin to start the user account deletion process */
  triggerAccountDeletionForUser: TriggerAccountDeletionForUserPayload;
  updateActivePin: UpdateActivePinPayload;
  /** Mutation to update the agreement proposal */
  updateAgreementProposal: UpdateAgreementProposalPayload;
  updateAssignment: UpdateAssignmentPayload;
  updateAssignmentQuizQuestionCorrectAnswer: UpdateAssignmentQuizQuestionCorrectAnswerPayload;
  updateAudioUploadAudio: UpdateAudioUploadAudioPayload;
  /**
   * Gives the user a default license, also known as the 'currentLicense'.
   * Note that the actual license is based on this value but also on the user having a stripe subscription or being part of a school license.
   */
  updateDefaultLicenseForUser: UpdateDefaultLicenseForUserPayload;
  /** Update the displayname for the student entry */
  updateDisplayName: UpdateDisplayNamePayload;
  updateDocumentUploadPages: UpdateDocumentUploadPagesPayload;
  updateImageUploadImage: UpdateImageUploadImagePayload;
  updateLesson: UpdateLessonPayload;
  updateMetaDataForSlideDeckUpload: UpdateMetaDataForSlideDeckUploadPayload;
  /** Mutation used by the admin to update the privacy contact of an organization */
  updateOrganizationPrivacyContact: UpdateOrganizationPrivacyContactPayload;
  updateRealtimeAssignmentSettings: UpdateRealtimeAssignmentSettingsPayload;
  updateSlideDeckLessonIdOnUpload: UpdateSlideDeckLessonIdOnUploadPayload;
  updateTestSchedule: UpdateTestSchedulePayload;
  updateUploadFolderName: UpdateUploadFolderNamePayload;
  updateUploadName: UpdateUploadNamePayload;
  updateUploadProgressPercentage: UpdateUploadProgressPercentagePayload;
  updateUploadStatus: UpdateUploadStatusPayload;
  /** Update the country of a user */
  updateUserCountry: UpdateUserCountryPayload;
  /** Update the language of a user */
  updateUserLanguage: UpdateUserLanguagePayload;
  /** Update user license */
  updateUserLicense: UpdateUserLicensePayload;
  /** Update the name of a user */
  updateUserName: UpdateUserNamePayload;
  updateVideoUploadThumbnail: UpdateVideoUploadThumbnailPayload;
  updateVideoUploadVideo: UpdateVideoUploadVideoPayload;
  /**
   * Mutation to register a new user.
   * It is intended to replace the 1.0 registration flow.
   */
  userDidRegister: UserDidRegisterPayload;
  validatePincode: ValidatePincodePayload;
  withdrawAnswerWordCloudForRealtimeAssignment: WithdrawAnswerWordCloudForRealtimeAssignmentPayload;
};


export type MutationAcceptUserTermsArgs = {
  input: AcceptUserTermsInput;
};


export type MutationAccessToAssignmentDataForLicenseArgs = {
  input: AccessToAssignmentDataForLicenseInput;
};


export type MutationAddGroupMemberArgs = {
  input: AddGroupMemberInput;
};


export type MutationAddPinsToLessonArgs = {
  input: AddPinsToLessonInput;
};


export type MutationAddProposalToAgreementArgs = {
  input: AddProposalToAgreementInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationAnalyzeUploadsForOcrArgs = {
  input: AnalyzeUploadsForOcrInput;
};


export type MutationAnswerOpenQuestionForRealtimeAssignmentArgs = {
  input: AnswerOpenQuestionForRealtimeAssignmentInput;
};


export type MutationAnswerQuizForRealtimeAssignmentArgs = {
  input: AnswerQuizForRealtimeAssignmentInput;
};


export type MutationAnswerWordCloudForRealtimeAssignmentArgs = {
  input: AnswerWordCloudForRealtimeAssignmentInput;
};


export type MutationArchiveSchoolSuggestionArgs = {
  input: ArchiveSchoolSuggestionInput;
};


export type MutationAssignmentByLinkArgs = {
  input: AssignmentByLinkInput;
};


export type MutationAssignmentHideArgs = {
  input: AssignmentHideInput;
};


export type MutationAssignmentOpenQuestionAddAttachmentArgs = {
  input: AssignmentOpenQuestionAddAttachmentInput;
};


export type MutationAssignmentRemoveAttachmentArgs = {
  input: AssignmentRemoveAttachmentInput;
};


export type MutationAssignmentSetCommentsViewedArgs = {
  input: Array<AssignmentSetCommentsViewedInput>;
};


export type MutationAssignmentSetViewedArgs = {
  input: AssignmentSetViewedInput;
};


export type MutationAssignmentSetVisibilityArgs = {
  input: AssignmentSetVisibilityInput;
};


export type MutationAssignmentSubmitPollArgs = {
  input: AssignmentSubmitPollInput;
};


export type MutationAssignmentUpdateActivityArgs = {
  input: AssignmentUpdateActivityInput;
};


export type MutationAttachSchoolSuggestionToOrganizationArgs = {
  input: AttachSchoolSuggestionToOrganizationInput;
};


export type MutationAutoJoinArgs = {
  input: AutoJoinInput;
};


export type MutationBlockNoticesArgs = {
  input: BlockNoticesInput;
};


export type MutationCalculateUserLicenseArgs = {
  input: CalculateUserLicenseInput;
};


export type MutationCancelAccountDeletionRequestForUserArgs = {
  input: CancelAccountDeletionForUserInput;
};


export type MutationCancelStripeSubscriptionArgs = {
  input: CancelStripeSubscriptionInput;
};


export type MutationCheckOpenQuestionArgs = {
  input: CheckOpenQuestionInput;
};


export type MutationCheckOpenQuestionPinStudentAnswersArgs = {
  input: CheckOpenQuestionPinStudentAnswersInput;
};


export type MutationCheckQuizPinStudentAnswersArgs = {
  input: CheckQuizPinStudentAnswersInput;
};


export type MutationClearAssignmentEntryArgs = {
  input: ClearAssignmentEntryInput;
};


export type MutationCloseAssignmentIfEmptyOrDirectCloseRouteArgs = {
  input: CloseAssignmentIfEmptyOrDirectCloseRouteInput;
};


export type MutationCloseOpenRealtimeAssignmentsArgs = {
  input?: InputMaybe<CloseOpenRealtimeAssignmentsInput>;
};


export type MutationCloseOpenRealtimeAssignmentsWithoutStudentsArgs = {
  input?: InputMaybe<CloseOpenRealtimeAssignmentsWithoutStudentsInput>;
};


export type MutationCloseRaisedHandArgs = {
  input: CloseRaisedHandInput;
};


export type MutationConvertAssignmentToLessonArgs = {
  input: ConvertAssignmentToLessonInput;
};


export type MutationCopyPinsToLessonArgs = {
  input: CopyPinsToLessonInput;
};


export type MutationCreateAssignmentFromLessonIdArgs = {
  input: CreateAssignmentFromLessonIdInput;
};


export type MutationCreateBillingPortalUrlArgs = {
  input: CreateBillingPortalUrlInput;
};


export type MutationCreateCheckoutUrlArgs = {
  input: CreateCheckoutUrlInput;
};


export type MutationCreateDomainRuleArgs = {
  input: CreateDomainRuleInput;
};


export type MutationCreateGdprAgreementForOrganizationArgs = {
  input: CreateGdprAgreementForOrganizationInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateHomeworkFromClassicLessonArgs = {
  input: CreateHomeworkFromClassicLessonInput;
};


export type MutationCreateHomeworkFromLessonArgs = {
  input: CreateHomeworkFromLessonInput;
};


export type MutationCreateHomeworkLinkFromLessonArgs = {
  input: CreateHomeworkLinkFromLessonInput;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateLessonForUploadArgs = {
  input: CreateLessonForUploadInput;
};


export type MutationCreateLessonModuleArgs = {
  input: CreateLessonModuleInput;
};


export type MutationCreateLessonV2FromV1Args = {
  input: CreateLessonV2FromV1Input;
};


export type MutationCreateNoticeBannerArgs = {
  input: CreateNoticeBannerInput;
};


export type MutationCreateNoticeModalArgs = {
  input: CreateNoticeModalInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateRealtimeAssignmentArgs = {
  createRealtimeAssignmentInput: CreateRealtimeAssignmentInput;
};


export type MutationCreateSchoolSuggestionFromOrganizationArgs = {
  input: CreateSchoolSuggestionFromOrganizationInput;
};


export type MutationCreateSharedExplorersArgs = {
  input: CreateSharedExplorersInput;
};


export type MutationCreateTeachingCreditsMutationArgs = {
  input: CreateTeachingCreditsMutationInput;
};


export type MutationCreateTestFromLessonArgs = {
  input: CreateTestFromLessonInput;
};


export type MutationCreateTestStudentArgs = {
  input: CreateTestStudentInput;
};


export type MutationCreateTrialGiftArgs = {
  input: CreateTrialGiftInput;
};


export type MutationCreateUploadArgs = {
  input: CreateUploadInput;
};


export type MutationCreateUploadFolderArgs = {
  input: CreateUploadFolderInput;
};


export type MutationCreateUploadForAssignmentArgs = {
  input: CreateUploadForAssignmentInput;
};


export type MutationCreateUploadForOcrArgs = {
  input: CreateUploadInput;
};


export type MutationCreateUploadForSlideDeckImportArgs = {
  input: CreateUploadForSlideDeckImportInput;
};


export type MutationDeleteAgreementArgs = {
  input: DeleteAgreementInput;
};


export type MutationDeleteAssignmentArgs = {
  input: DeleteAssignmentInput;
};


export type MutationDeleteUploadFoldersArgs = {
  input: DeleteUploadFoldersInput;
};


export type MutationDeleteUploadsArgs = {
  input: DeleteUploadsInput;
};


export type MutationDetachSchoolSuggestionFromOrganizationArgs = {
  input: DetachSchoolSuggestionFromOrganizationInput;
};


export type MutationDetachSchoolSuggestionsOnOrganizationDeletionArgs = {
  input: DetachSchoolSuggestionsOnOrganizationDeletionInput;
};


export type MutationDismissNoticeArgs = {
  input: DismissNoticeInput;
};


export type MutationDownloadOriginalUploadFileArgs = {
  input: DownloadOriginalUploadInput;
};


export type MutationEditAnswerWordCloudForRealtimeAssignmentArgs = {
  input: EditAnswerWordCloudForRealtimeAssignmentInput;
};


export type MutationFetchAssignmentDataForReportArgs = {
  input: FetchAssignmentDataForReportInput;
};


export type MutationGenerateContextualLessonArgs = {
  input: GenerateContextualLessonInput;
};


export type MutationGenerateLessonArgs = {
  input: GenerateLessonInput;
};


export type MutationGenerateLessonOutlineArgs = {
  input: GenerateLessonOutlineInput;
};


export type MutationGenerateMultipleChoiceQuestionsArgs = {
  input: GenerateMultipleChoiceQuestionsInput;
};


export type MutationGeneratePollArgs = {
  input: GeneratePollInput;
};


export type MutationGiveGiftToUsersArgs = {
  input: GiveGiftToUsersMutationInput;
};


export type MutationHandInTestEntryArgs = {
  input: HandInTestEntryInput;
};


export type MutationHomeworkCheckDragQuestionArgs = {
  input: HomeworkCheckDragQuestionInput;
};


export type MutationHomeworkResetDragQuestionArgs = {
  input: HomeworkResetDragQuestionInput;
};


export type MutationJoinRealtimeAssignmentArgs = {
  input: JoinRealtimeAssignmentInput;
};


export type MutationJoinRealtimeAssignmentSessionArgs = {
  input: JoinRealtimeAssignmentSessionInput;
};


export type MutationLeaveRealtimeAssignmentArgs = {
  input: LeaveRealtimeAssignmentInput;
};


export type MutationMergeUsersArgs = {
  input: MergeUsersInput;
};


export type MutationMoveUploadsAndFoldersArgs = {
  input: MoveUploadsAndFolderInput;
};


export type MutationPublishAmqpMessageArgs = {
  input: PublishAmqpMessageInput;
};


export type MutationPurgeV2LessonsArgs = {
  input: PurgeV2LessonsInput;
};


export type MutationRealtimeKeepAliveArgs = {
  input: RealtimeKeepAliveInput;
};


export type MutationRegisterStudentUserWithLtiSsoArgs = {
  input: RegisterStudentUserWithLtiSsoInput;
};


export type MutationRegisterUserWithPasswordArgs = {
  input: RegisterUserWithPasswordInput;
};


export type MutationRemoveSsoLoginServiceArgs = {
  input: RemoveSsoLoginServiceInput;
};


export type MutationRemoveUserFromAssignmentArgs = {
  input: RemoveUserFromAssignmentInput;
};


export type MutationRenameGroupArgs = {
  input: RenameGroupInput;
};


export type MutationReopenTestAssignmentArgs = {
  input: ReopenTestAssignmentInput;
};


export type MutationRequestAccountDeletionForUserArgs = {
  input: RequestAccountDeletionForUserInput;
};


export type MutationRequestAssignmentSummaryUpdateArgs = {
  input: RequestAssignmentSummaryUpdateInput;
};


export type MutationRequestUpgradeToMemberOfOrganizationArgs = {
  input: RequestUpgradeToMemberOfOrganizationInput;
};


export type MutationRequestUserAccountDeletionArgs = {
  input: RequestUserAccountDeletionInput;
};


export type MutationResetTeachingCreditsArgs = {
  input: ResetTeachingCreditsInput;
};


export type MutationSaveRaisedHandsToLogArgs = {
  input: SaveRaisedHandsToLogInput;
};


export type MutationSendEmailVerificationEmailArgs = {
  input: SendEmailVerificationEmailInput;
};


export type MutationSetDisplayNameForRealtimeAssignmentArgs = {
  input: SetDisplayNameForRealtimeAssignmentInput;
};


export type MutationSetGiftPurposeArgs = {
  input: SetGiftPurposeInput;
};


export type MutationSetGradeSettingsArgs = {
  input: SetGradeSettingsInput;
};


export type MutationSetOrganizationAddressArgs = {
  input: SetOrganizationAddressInput;
};


export type MutationSetOrganizationAutoJoinWorkFolderSettingArgs = {
  input: SetOrganizationAutoJoinWorkFolderSettingInput;
};


export type MutationSetOrganizationCanCreateVouchersSettingArgs = {
  input: SetOrganizationCanCreateVouchersSettingInput;
};


export type MutationSetOrganizationCityArgs = {
  input: SetOrganizationCityInput;
};


export type MutationSetOrganizationCountryArgs = {
  input: SetOrganizationCountryInput;
};


export type MutationSetOrganizationInviteMailTypeSettingArgs = {
  input: SetOrganizationInviteMailTypeSettingInput;
};


export type MutationSetOrganizationIsPublisherSettingArgs = {
  input: SetOrganizationIsPublisherSettingInput;
};


export type MutationSetOrganizationIsSchoolSettingArgs = {
  input: SetOrganizationIsSchoolSettingInput;
};


export type MutationSetOrganizationIsSuperSettingArgs = {
  input: SetOrganizationIsSuperSettingInput;
};


export type MutationSetOrganizationLicenseAmountArgs = {
  input: SetOrganizationLicenseAmountInput;
};


export type MutationSetOrganizationLicenseTypeArgs = {
  input: SetOrganizationLicenseTypeInput;
};


export type MutationSetOrganizationManageChildLicensesSettingArgs = {
  input: SetOrganizationManageChildLicensesSettingInput;
};


export type MutationSetOrganizationManageStudentsSettingArgs = {
  input: SetOrganizationManageStudentsSettingInput;
};


export type MutationSetOrganizationMultiLocationSettingArgs = {
  input: SetOrganizationMultiLocationSettingInput;
};


export type MutationSetPinCommentArgs = {
  input: SetPinCommentInput;
};


export type MutationSetPinEntryPointsArgs = {
  input: SetPinEntryPointsInput;
};


export type MutationSetPinsViewedArgs = {
  input: Array<SetPinsViewedInput>;
};


export type MutationSetRaiseHandArgs = {
  input: SetRaiseHandInput;
};


export type MutationSetReviewPhaseArgs = {
  input: SetReviewPhaseInput;
};


export type MutationSetReviewPhaseForStudentArgs = {
  input: SetReviewPhaseForStudentInput;
};


export type MutationSetSharedExplorerPermissionArgs = {
  input: SetSharedExplorerPermissionInput;
};


export type MutationSetSubmitDragQuestionArgs = {
  input: SetSubmitDragQuestionInput;
};


export type MutationShowAnswerOpenQuestionArgs = {
  input: ShowAnswerOpenQuestionInput;
};


export type MutationSignAgreementManuallyArgs = {
  input: SignAgreementManuallyInput;
};


export type MutationSignAgreementProposalArgs = {
  input: SignAgreementProposalInput;
};


export type MutationSpendTeachingCreditToTeachArgs = {
  input: SpendTeachingCreditToTeachInput;
};


export type MutationStartTestAssignmentArgs = {
  input: StartTestAssignmentInput;
};


export type MutationStopTestAssignmentArgs = {
  input: StopTestAssignmentInput;
};


export type MutationSubmitDragQuestionComponentPositionArgs = {
  input: SubmitDragQuestionComponentPositionInput;
};


export type MutationSubmitGradesArgs = {
  input: SubmitGradesInput;
};


export type MutationSubmitQuizHomeworkArgs = {
  input: SubmitQuizHomeworkInput;
};


export type MutationSyncOrganizationDataToSchoolSuggestionsArgs = {
  input: SyncOrganizationDataToSchoolSuggestionsInput;
};


export type MutationTeachRealtimeAssignmentArgs = {
  input: TeachRealtimeAssignmentInput;
};


export type MutationTransferInvoicesArgs = {
  input: TransferInvoicesInput;
};


export type MutationTriggerAccountDeletionForUserArgs = {
  input: TriggerAccountDeletionForUserInput;
};


export type MutationUpdateActivePinArgs = {
  input: UpdateActivePinInput;
};


export type MutationUpdateAgreementProposalArgs = {
  input: UpdateAgreementProposalInput;
};


export type MutationUpdateAssignmentArgs = {
  input: UpdateAssignmentInput;
};


export type MutationUpdateAssignmentQuizQuestionCorrectAnswerArgs = {
  input: UpdateAssignmentQuizQuestionCorrectAnswerInput;
};


export type MutationUpdateAudioUploadAudioArgs = {
  input: UpdateAudioUploadAudioInput;
};


export type MutationUpdateDefaultLicenseForUserArgs = {
  input: UpdateDefaultLicenseForUserInput;
};


export type MutationUpdateDisplayNameArgs = {
  input: UpdateDisplayNameInput;
};


export type MutationUpdateDocumentUploadPagesArgs = {
  input: UpdateDocumentUploadPagesInput;
};


export type MutationUpdateImageUploadImageArgs = {
  input: UpdateImageUploadImageInput;
};


export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};


export type MutationUpdateMetaDataForSlideDeckUploadArgs = {
  input: UpdateMetaDataForSlideDeckUploadInput;
};


export type MutationUpdateOrganizationPrivacyContactArgs = {
  input: UpdateOrganizationPrivacyContactInput;
};


export type MutationUpdateRealtimeAssignmentSettingsArgs = {
  input: UpdateRealtimeAssignmentSettingsInput;
};


export type MutationUpdateSlideDeckLessonIdOnUploadArgs = {
  input: UpdateSlideDeckLessonIdOnUploadInput;
};


export type MutationUpdateTestScheduleArgs = {
  input: UpdateTestScheduleInput;
};


export type MutationUpdateUploadFolderNameArgs = {
  input: UpdateUploadFolderNameInput;
};


export type MutationUpdateUploadNameArgs = {
  input: UpdateUploadNameInput;
};


export type MutationUpdateUploadProgressPercentageArgs = {
  input: UpdateUploadProgressPercentageInput;
};


export type MutationUpdateUploadStatusArgs = {
  input: UpdateUploadStatusInput;
};


export type MutationUpdateUserCountryArgs = {
  input: UpdateUserCountryInput;
};


export type MutationUpdateUserLanguageArgs = {
  input: UpdateUserLanguageInput;
};


export type MutationUpdateUserLicenseArgs = {
  input: UpdateUserLicenseInput;
};


export type MutationUpdateUserNameArgs = {
  input: UpdateUserNameInput;
};


export type MutationUpdateVideoUploadThumbnailArgs = {
  input: UpdateVideoUploadThumbnailInput;
};


export type MutationUpdateVideoUploadVideoArgs = {
  input: UpdateVideoUploadVideoInput;
};


export type MutationUserDidRegisterArgs = {
  input: UserDidRegisterInput;
};


export type MutationValidatePincodeArgs = {
  input: ValidatePincodeInput;
};


export type MutationWithdrawAnswerWordCloudForRealtimeAssignmentArgs = {
  input: WithdrawAnswerWordCloudForRealtimeAssignmentInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Notice = {
  id: Scalars['ID'];
  /** Canonical key for the notice. Designed to be unique per user except for the version. */
  key: Scalars['String'];
  /** Version of the notice. If a notice with the same key and a higher version is created, the old notices will be superseded */
  version: Scalars['NonNegativeInt'];
};

/** Data for a banner notice */
export type NoticeBanner = Node & Notice & {
  __typename?: 'NoticeBanner';
  id: Scalars['ID'];
  /** Canonical key for the notice. Designed to be unique per user except for the version. */
  key: Scalars['String'];
  /** Action link to display on the notice. */
  link?: Maybe<NoticeLink>;
  /** Translation key or plain text for the message of the notice. */
  message: Scalars['String'];
  /** Dynamic parameters for the translation content. */
  params?: Maybe<Scalars['JSONObject']>;
  /** Translation key or plain text for the title of the notice. */
  title: Scalars['String'];
  /** Version of the notice. If a notice with the same key and a higher version is created, the old notices will be superseded */
  version: Scalars['NonNegativeInt'];
};

export type NoticeImage = {
  __typename?: 'NoticeImage';
  /** Alternative text for the image */
  alt?: Maybe<Scalars['String']>;
  /** URL of the image */
  src: Scalars['URL'];
};

export type NoticeInputImage = {
  /** Alternative text for the image */
  alt?: InputMaybe<Scalars['String']>;
  /** URL of the image */
  src: Scalars['URL'];
};

export type NoticeInputLink = {
  label: Scalars['String'];
  url: Scalars['URL'];
};

export type NoticeLink = {
  __typename?: 'NoticeLink';
  label: Scalars['String'];
  url: Scalars['URL'];
};

/** Data for a modal notice */
export type NoticeModal = Node & Notice & {
  __typename?: 'NoticeModal';
  id: Scalars['ID'];
  /** Image to display on the notice. */
  image?: Maybe<NoticeImage>;
  /** Canonical key for the notice. Designed to be unique per user except for the version. */
  key: Scalars['String'];
  /** Action link to display on the notice. */
  link?: Maybe<NoticeLink>;
  /** Translation keys or plain texts for the message of the notice. */
  messages: Array<Scalars['String']>;
  /** Dynamic parameters for the translation content. */
  params?: Maybe<Scalars['JSONObject']>;
  /** Translation key or plain text for the title of the notice. */
  title: Scalars['String'];
  /** Version of the notice. If a notice with the same key and a higher version is created, the old notices will be superseded */
  version: Scalars['NonNegativeInt'];
};

export type NoticeTypeFilter =
  | 'BANNER'
  | 'MODAL';

export type OffsetBasedPageInfo = {
  __typename?: 'OffsetBasedPageInfo';
  hasNextPage: Scalars['Boolean'];
};

/** Organization which uses LessonUp as a school */
export type Organization = Node & {
  __typename?: 'Organization';
  /** First channel of the organization */
  channel?: Maybe<Channel>;
  contact?: Maybe<OrganizationContact>;
  /**
   * Whether the organization has already requested or signed the latest available GDPR agreement document version.
   * @deprecated Use 'organization.shouldRequestNewGdprAgreementDocumentVersion'
   */
  hasRequestedLatestAvailableGdprAgreementDocumentVersion: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The latest available GDPR agreement document version for the organization, based on the organization's language. */
  latestAvailableGdprAgreementDocumentVersion: AgreementDocument;
  /** Contact details of the organization admin who organization members should contact in case they have questions regarding their license. */
  licenseContact: OrganizationLicenseContact;
  /** The effective licenseType this organisation has; taking into account business rules such as parent organization license */
  licenseType?: Maybe<SchoolSubscriptionTierTypes>;
  members: OrganizationToColleaguesConnection;
  name: Scalars['String'];
  parentOrganization?: Maybe<ParentOrganization>;
  privacyContact?: Maybe<OrganizationPrivacyContact>;
  role: OrganizationRole;
  settings?: Maybe<OrganizationSettings>;
  /**
   * Indicates whether the organization should be asked to sign a new GDPR agreement document version.
   * Is true when the organization hasn't requested or signed an up-to-date GDPR agreement document version.
   * This occurs when the organization has no agreement at all or requested or signed only outdated versions.
   */
  shouldRequestNewGdprAgreementDocumentVersion: Scalars['Boolean'];
  /** unique readable value which can be used in urls */
  slug: Scalars['String'];
};


/** Organization which uses LessonUp as a school */
export type OrganizationMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OrganizationAdmin = Node & {
  __typename?: 'OrganizationAdmin';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** Agreement type that only have the relevant fields for an Organization */
export type OrganizationAgreement = BaseAgreement & Node & {
  __typename?: 'OrganizationAgreement';
  creationDate: Scalars['DateTime'];
  details?: Maybe<AgreementDetails>;
  document: AgreementDocument;
  id: Scalars['ID'];
  organization: Scalars['ID'];
  requestUser: Scalars['ID'];
  secret: Scalars['String'];
  /** An array with all proposals */
  signProposals: Array<AgreementSignProposal>;
  signed?: Maybe<Scalars['DateTime']>;
  signedProposal?: Maybe<Scalars['ID']>;
};

export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['CountryCode']>;
};

/** Contact details of the organization admin who organization members should contact in case they have questions regarding their license. */
export type OrganizationLicenseContact = {
  __typename?: 'OrganizationLicenseContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Privacy contact details of the organization GDPR agreement */
export type OrganizationPrivacyContact = {
  __typename?: 'OrganizationPrivacyContact';
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type OrganizationRole =
  /** Publisher uses LessonUp with the focus on creating their own content and exposing it to all teachers */
  | 'PUBLISHER'
  /** A school that uses LessonUp to collaborate */
  | 'SCHOOL';

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  autoJoinWorkFolder?: Maybe<Scalars['Boolean']>;
  canCreateVouchers?: Maybe<Scalars['Boolean']>;
  inviteMailType?: Maybe<OrganizationSettingsInviteMailType>;
  isPublisher?: Maybe<Scalars['Boolean']>;
  isSchool?: Maybe<Scalars['Boolean']>;
  isSuper?: Maybe<Scalars['Boolean']>;
  manageChildLicenses?: Maybe<Scalars['Boolean']>;
  manageStudents?: Maybe<Scalars['Boolean']>;
  multiLocation?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSettingsInviteMailType =
  | 'GENERIC'
  | 'NONE'
  | 'PERSONALIZED';

export type OrganizationToColleagueEdge = {
  __typename?: 'OrganizationToColleagueEdge';
  isAdmin: Scalars['Boolean'];
  node?: Maybe<Colleague>;
  role: OrganizationUserRole;
};

export type OrganizationToColleaguesConnection = {
  __typename?: 'OrganizationToColleaguesConnection';
  edges: Array<OrganizationToColleagueEdge>;
  nodes: Array<Maybe<Colleague>>;
  pageInfo: OffsetBasedPageInfo;
};

export type OrganizationUserRole =
  /** Part of the organization but with limited access */
  | 'BASIC'
  /** Full fledged member of the organization */
  | 'MEMBER'
  /** User is pending and has not been accepted in the organization yet */
  | 'PENDING';

export type OriginalFile = File & {
  __typename?: 'OriginalFile';
  /** The MIME type of the file. */
  contentType: Scalars['String'];
  /** The file extension of the file (png, pdf, etc.). */
  fileExtension: Scalars['String'];
  /** The file size of the file in bytes. */
  sizeInBytes: Scalars['Int'];
};

export type ParentOrganization = Node & {
  __typename?: 'ParentOrganization';
  /** All admins of the organization */
  admins: Array<OrganizationAdmin>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Tier of personalized school subscription tier */
export type PersonalizedSchoolSubscriptionTier = Node & {
  __typename?: 'PersonalizedSchoolSubscriptionTier';
  /** ID of school subscription tier */
  id: Scalars['ID'];
  /** Array with price information of this tier */
  prices: Array<PersonalizedSubscriptionTierPrice>;
  /** Type of school subscription, can be: START | PRO */
  type: SchoolSubscriptionTierTypes;
};

/** Tier of personalized subscription tier */
export type PersonalizedSubscriptionTier = Node & {
  __typename?: 'PersonalizedSubscriptionTier';
  /** ID of personal subscription tier */
  id: Scalars['ID'];
  /** Array with price information of this tier */
  prices: Array<PersonalizedSubscriptionTierPrice>;
  /** Type of subscription tier */
  type: SubscriptionTierType;
};

/** Price for the personalized subscription tier */
export type PersonalizedSubscriptionTierPrice = {
  __typename?: 'PersonalizedSubscriptionTierPrice';
  /** ID of personal subscription tier */
  id: Scalars['ID'];
  /** Price the user needs to pay for the tier */
  price: Price;
  /** Price calculated to what it will cost per month. If the normal price is yearly, this will be the yearly price divided by 12 */
  pricePerMonth: Price;
  /** How often the subscription renews */
  recurring: LicenseSubscriptionRecurring;
};

export type PinHash = {
  __typename?: 'PinHash';
  hash: Scalars['String'];
  id: Scalars['ID'];
};

/** Price information for a subscription */
export type Price = {
  __typename?: 'Price';
  /** amount in cents, for a year subscription this will be the price the user pays for a full year */
  amountInCents: Scalars['Int'];
  /** Currency of price, can be: EUR | GBP | USD */
  currency: Scalars['Currency'];
};

/** A Product is a document users get when redeeming a voucher */
export type Product = Node & {
  __typename?: 'Product';
  /** ID of the product */
  id: Scalars['ID'];
  /** Name of the product */
  name: Scalars['String'];
};

export type PublishAmqpMessageInput = {
  /** The message data */
  data?: InputMaybe<Scalars['JSONObject']>;
  /** The message unique type, e.g. 'user.email.updated.v1' */
  type: Scalars['String'];
};

export type PublishAmqpMessagePayload = {
  __typename?: 'PublishAmqpMessagePayload';
  /** ID of the message that was published */
  id: Scalars['String'];
};

export type PurgeV2LessonsInput = {
  /** Leave empty to purge all v2 lessons */
  lessonIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PurgeV2LessonsPayload = {
  __typename?: 'PurgeV2LessonsPayload';
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns the gift that is applied to new subscribers who signed up from a referral. */
  activeRefereeTrialGift?: Maybe<TrialGift>;
  agreementByIdAndSecret?: Maybe<Agreement>;
  assignmentById?: Maybe<Assignment>;
  canMergeUsers: CanMergeUsersPayload;
  channelById?: Maybe<Channel>;
  /** Feature flags which are enabled for all (anonymous) users */
  featureFlags: FeatureFlags;
  firebaseCustomToken: Scalars['String'];
  giftById?: Maybe<TrialGift>;
  giftsByStatus: Array<TrialGift>;
  invoices: Array<Invoice>;
  lessonById?: Maybe<Lesson>;
  maiaExamples: Array<MaiaExample>;
  mergeInfoForUsers: MergeInfoForUsersPayload;
  /** Query to be used by the admin to get all the organization agreements */
  organizationAgreements: Array<Agreement>;
  organizationById?: Maybe<Organization>;
  /** Product */
  product?: Maybe<Product>;
  referralByReferralToken?: Maybe<Referral>;
  schoolSuggestionByGlobalSchoolId?: Maybe<SchoolSuggestion>;
  schoolSuggestionById?: Maybe<SchoolSuggestion>;
  schoolSuggestionsByOrganizationId: Array<SchoolSuggestion>;
  /** Return the different subscription tiers LU offers */
  subscriptionTiers: Array<SubscriptionTier>;
  teacherGroupById: TeacherGroup;
  trialGifts: Array<TrialGift>;
  verifyEmailByToken: VerifyEmailByTokenPayload;
  /** Get the thumbnail for a video */
  videosByIds: Array<Video>;
  viewer: Viewer;
  /** A way for admins to get the data from a viewer (teacher / student) point of view */
  viewerById: Viewer;
  viewerByJwt: Viewer;
};


export type QueryAgreementByIdAndSecretArgs = {
  id: Scalars['ID'];
  secret: Scalars['String'];
};


export type QueryAssignmentByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCanMergeUsersArgs = {
  input: CanMergeUsersInput;
};


export type QueryChannelByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGiftByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGiftsByStatusArgs = {
  status: GiftStatus;
};


export type QueryLessonByIdArgs = {
  id: Scalars['ID'];
};


export type QueryMaiaExamplesArgs = {
  language: SupportedLanguage;
  type: MaiaExampleType;
};


export type QueryMergeInfoForUsersArgs = {
  input: MergeInfoForUsersInput;
};


export type QueryOrganizationAgreementsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryReferralByReferralTokenArgs = {
  referralToken: Scalars['UUID'];
};


export type QuerySchoolSuggestionByGlobalSchoolIdArgs = {
  globalSchoolId: Scalars['String'];
};


export type QuerySchoolSuggestionByIdArgs = {
  id: Scalars['String'];
};


export type QuerySchoolSuggestionsByOrganizationIdArgs = {
  organizationId: Scalars['String'];
};


export type QueryTeacherGroupByIdArgs = {
  id: Scalars['ID'];
};


export type QueryVerifyEmailByTokenArgs = {
  token: Scalars['String'];
};


export type QueryVideosByIdsArgs = {
  input: VideosByIdsInput;
};


export type QueryViewerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryViewerByJwtArgs = {
  token: Scalars['JWT'];
};

export type RaiseHand = {
  pinId: Scalars['String'];
  question: Scalars['String'];
  timeStamp: Scalars['Int'];
};

export type RaiseHandCompleted = {
  displayName: Scalars['String'];
  pinId: Scalars['String'];
  question: Scalars['String'];
  result: RaiseHandCompletedResult;
  timeStamp: Scalars['Int'];
  user: Scalars['String'];
};

export type RaiseHandCompletedResult =
  | 'CLEARED'
  | 'DONE';

export type RealtimeKeepAliveInput = {
  assignmentId: Scalars['String'];
};

export type RealtimeKeepAlivePayload = {
  __typename?: 'RealtimeKeepAlivePayload';
  id: Scalars['ID'];
};

export type RealtimePlayerSettings = {
  __typename?: 'RealtimePlayerSettings';
  allowConnectedDevices?: Maybe<Scalars['Boolean']>;
  allowNewPlayers?: Maybe<Scalars['Boolean']>;
  autoShowRank?: Maybe<Scalars['Boolean']>;
  ignoreExclusions?: Maybe<Scalars['Boolean']>;
  isPrepScreenEnabled?: Maybe<Scalars['Boolean']>;
  isRaiseHandAllowed?: Maybe<Scalars['Boolean']>;
  isScreenShared?: Maybe<Scalars['Boolean']>;
  isSoundEnabled?: Maybe<Scalars['Boolean']>;
};

export type RealtimePlayerSettingsInput = {
  allowConnectedDevices?: InputMaybe<Scalars['Boolean']>;
  allowNewPlayers?: InputMaybe<Scalars['Boolean']>;
  autoShowRank?: InputMaybe<Scalars['Boolean']>;
  ignoreExclusions?: InputMaybe<Scalars['Boolean']>;
  isPrepScreenEnabled?: InputMaybe<Scalars['Boolean']>;
  isRaiseHandAllowed?: InputMaybe<Scalars['Boolean']>;
  isScreenShared?: InputMaybe<Scalars['Boolean']>;
  isSoundEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type ReceivedBaseGift = {
  id: Scalars['ID'];
  /** The reason this gift was given */
  reason: Scalars['String'];
  /** Date the gift was redeemed by the user */
  redeemedAt?: Maybe<Scalars['DateTime']>;
  status: ReceivedGiftStatus;
};

export type ReceivedGiftStatus =
  | 'PENDING'
  | 'REDEEMED';

export type ReceivedTrialGift = Node & ReceivedBaseGift & {
  __typename?: 'ReceivedTrialGift';
  amountOfDays: Scalars['Int'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  redeemedAt?: Maybe<Scalars['DateTime']>;
  status: ReceivedGiftStatus;
};

export type Referral = Node & {
  __typename?: 'Referral';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  owner: ReferralUser;
  /** Users that have been registered through this referral token */
  referredUsers: ReferralToReferredUsersConnection;
  /** Unique referral token */
  token: Scalars['UUID'];
};

export type ReferralToReferredUserEdge = {
  __typename?: 'ReferralToReferredUserEdge';
  node: ReferredUser;
  registeredAt: Scalars['DateTime'];
};

export type ReferralToReferredUsersConnection = {
  __typename?: 'ReferralToReferredUsersConnection';
  edges: Array<ReferralToReferredUserEdge>;
  nodes: Array<ReferredUser>;
  pageInfo?: Maybe<OffsetBasedPageInfo>;
  totalCount: Scalars['NonNegativeInt'];
};

export type ReferralUser = Node & {
  __typename?: 'ReferralUser';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** User that has registered through a referral link */
export type ReferredUser = {
  __typename?: 'ReferredUser';
  id: Scalars['ID'];
};

export type RegisterStudentUserWithLtiSsoInput = {
  deploymentId: Scalars['String'];
  email: Scalars['EmailAddress'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName: Scalars['String'];
  language: SupportedLanguage;
  /** The platform ID as specified in the LTI JWT */
  platformId: Scalars['String'];
  /** The specific LTI platform, can be canvas or itslearning */
  platformName?: InputMaybe<LtiPlatform>;
};

export type RegisterStudentUserWithLtiSsoPayload = {
  __typename?: 'RegisterStudentUserWithLtiSsoPayload';
  user: Viewer;
};

export type RegisterUserWithPasswordInput = {
  email: Scalars['EmailAddress'];
  /** Indicates if a user is created for testing purposes */
  isTestUser?: Scalars['Boolean'];
  password: Scalars['String'];
};

export type RegisterUserWithPasswordPayload = {
  __typename?: 'RegisterUserWithPasswordPayload';
  user: Viewer;
};

export type RemoveSsoLoginServiceInput = {
  serviceType: SsoLoginServiceType;
  userId: Scalars['ID'];
};

export type RemoveSsoLoginServicePayload = {
  __typename?: 'RemoveSsoLoginServicePayload';
  user: Viewer;
};

export type RemoveUserFromAssignmentInput = {
  assignmentId: Scalars['String'];
  userId: Scalars['String'];
};

export type RemoveUserFromAssignmentPayload = {
  __typename?: 'RemoveUserFromAssignmentPayload';
  id: Scalars['ID'];
};

export type RenameGroupInput = {
  groupId: Scalars['ID'];
  name: Scalars['String'];
  studentDisplayName: Scalars['String'];
};

export type RenameGroupPayload = {
  __typename?: 'RenameGroupPayload';
  group: TeacherGroup;
};

export type ReopenTestAssignmentInput = {
  assignmentId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type ReopenTestAssignmentPayload = {
  __typename?: 'ReopenTestAssignmentPayload';
  id: Scalars['ID'];
};

export type Report = Node & {
  __typename?: 'Report';
  id: Scalars['ID'];
  lessonName: Scalars['String'];
  questionReports: Array<ReportQuestion>;
  studentReports: Array<ReportStudent>;
  taughtAt: Scalars['DateTime'];
};

export type ReportQuestion = Node & {
  __typename?: 'ReportQuestion';
  amountOfStudentsAnsweredCorrectly?: Maybe<Scalars['Int']>;
  amountOfStudentsAnsweredIncorrectly?: Maybe<Scalars['Int']>;
  amountOfStudentsUnanswered: Scalars['Int'];
  id: Scalars['ID'];
  percentageStudentsAnsweredCorrectly?: Maybe<Scalars['Float']>;
  percentageStudentsAnsweredIncorrectly?: Maybe<Scalars['Float']>;
  percentageStudentsUnanswered: Scalars['Float'];
  questionTitle: Scalars['String'];
  slideNumber: Scalars['Int'];
};

export type ReportStudent = Node & {
  __typename?: 'ReportStudent';
  amountOfQuestionsAnswered: Scalars['Int'];
  amountOfQuestionsAnsweredCorrectly: Scalars['Int'];
  amountOfQuestionsAnsweredIncorrectly: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  percentageOfQuestionsAnsweredCorrectly: Scalars['Float'];
  percentageOfQuestionsAnsweredIncorrectly: Scalars['Float'];
};

export type RequestAccountDeletionForUserInput = {
  /**
   * The reasons for requesting account deletion.
   * TODO: add additional reasons for requesting account deletion for this internal mutation, like requested by admin, triggered by inactivity, etc.
   */
  reasons: Array<RequestAccountDeletionForUserReasonInput>;
  /** The user IDs to request account deletion to */
  userId: Scalars['ID'];
};

export type RequestAccountDeletionForUserPayload = {
  __typename?: 'RequestAccountDeletionForUserPayload';
  userAccountDeletionRequest: AccountDeletionRequest;
};

export type RequestAccountDeletionForUserReasonInput = {
  /** This field is only required for reasons of type 'OTHER_REASON' and discarded otherwise */
  description?: InputMaybe<Scalars['String']>;
  type: AccountDeletionReasonType;
};

export type RequestAssignmentSummaryUpdateInput = {
  assignmentIds: Array<Scalars['String']>;
};

export type RequestAssignmentSummaryUpdatePayload = {
  __typename?: 'RequestAssignmentSummaryUpdatePayload';
  id: Scalars['ID'];
};

export type RequestUpgradeToMemberOfOrganizationInput = {
  organizationId: Scalars['ID'];
};

export type RequestUpgradeToMemberOfOrganizationPayload = {
  __typename?: 'RequestUpgradeToMemberOfOrganizationPayload';
  success: Scalars['Boolean'];
};

export type RequestUserAccountDeletionInput = {
  reasons: Array<RequestUserAccountDeletionReasonInput>;
};

export type RequestUserAccountDeletionPayload = {
  __typename?: 'RequestUserAccountDeletionPayload';
  userAccountDeletionRequest: AccountDeletionRequest;
};

export type RequestUserAccountDeletionReasonInput = {
  /** This field is only required for reasons of type 'OTHER_REASON' and discarded otherwise */
  description?: InputMaybe<Scalars['String']>;
  type: AccountDeletionReasonType;
};

export type ResetTeachingCreditsInput = {
  userId: Scalars['String'];
};

export type ResetTeachingCreditsPayload = {
  __typename?: 'ResetTeachingCreditsPayload';
  user: Viewer;
};

export type SaveRaisedHandsToLogInput = {
  assignmentId: Scalars['String'];
  questions: Array<RaiseHandCompleted>;
};

export type SaveRaisedHandsToLogPayload = {
  __typename?: 'SaveRaisedHandsToLogPayload';
  id: Scalars['ID'];
};

export type SchoolSubscriptionTierTypes =
  | 'BASIC'
  | 'COMPLETE'
  | 'PLUS';

export type SchoolSuggestion = Node & {
  __typename?: 'SchoolSuggestion';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['CountryCode'];
  globalSchoolId: Scalars['String'];
  id: Scalars['ID'];
  isSuper: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  schoolCode: Scalars['String'];
  schoolType?: Maybe<Scalars['String']>;
};

export type SendEmailVerificationEmailInput = {
  email: Scalars['String'];
  language: SupportedLanguage;
};

export type SendEmailVerificationEmailPayload = {
  __typename?: 'SendEmailVerificationEmailPayload';
  processed: Scalars['Boolean'];
};

export type SetDisplayNameForRealtimeAssignmentInput = {
  assignmentId: Scalars['ID'];
  preferredDisplayName: Scalars['String'];
};

export type SetDisplayNameForRealtimeAssignmentPayload = {
  __typename?: 'SetDisplayNameForRealtimeAssignmentPayload';
  displayName: Scalars['String'];
};

export type SetGiftPurposeInput = {
  giftId: Scalars['ID'];
  purpose: GiftPurpose;
};

export type SetGiftPurposePayload = {
  __typename?: 'SetGiftPurposePayload';
  gift: TrialGift;
};

export type SetGradeSettingsInput = {
  assignmentId: Scalars['String'];
  settings?: InputMaybe<AssignmentGradeModelSettingsNTerm>;
  type: AssignmentGradeModelSettings;
};

export type SetGradeSettingsPayload = {
  __typename?: 'SetGradeSettingsPayload';
  id: Scalars['ID'];
};

export type SetOrganizationAddressInput = {
  address: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationAddressPayload = {
  __typename?: 'SetOrganizationAddressPayload';
  organization: Organization;
};

export type SetOrganizationAutoJoinWorkFolderSettingInput = {
  autoJoinWorkFolder: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationAutoJoinWorkFolderSettingPayload = {
  __typename?: 'SetOrganizationAutoJoinWorkFolderSettingPayload';
  organization: Organization;
};

export type SetOrganizationCanCreateVouchersSettingInput = {
  canCreateVouchers: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationCanCreateVouchersSettingPayload = {
  __typename?: 'SetOrganizationCanCreateVouchersSettingPayload';
  organization: Organization;
};

export type SetOrganizationCityInput = {
  city: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationCityPayload = {
  __typename?: 'SetOrganizationCityPayload';
  organization: Organization;
};

export type SetOrganizationCountryInput = {
  country: Scalars['CountryCode'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationCountryPayload = {
  __typename?: 'SetOrganizationCountryPayload';
  organization: Organization;
};

export type SetOrganizationInviteMailTypeSettingInput = {
  inviteMailType: OrganizationSettingsInviteMailType;
  organizationId: Scalars['ID'];
};

export type SetOrganizationInviteMailTypeSettingPayload = {
  __typename?: 'SetOrganizationInviteMailTypeSettingPayload';
  organization: Organization;
};

export type SetOrganizationIsPublisherSettingInput = {
  isPublisher: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationIsPublisherSettingPayload = {
  __typename?: 'SetOrganizationIsPublisherSettingPayload';
  organization: Organization;
};

export type SetOrganizationIsSchoolSettingInput = {
  isSchool: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationIsSchoolSettingPayload = {
  __typename?: 'SetOrganizationIsSchoolSettingPayload';
  organization: Organization;
};

export type SetOrganizationIsSuperSettingInput = {
  isSuper: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationIsSuperSettingPayload = {
  __typename?: 'SetOrganizationIsSuperSettingPayload';
  organization: Organization;
};

export type SetOrganizationLicenseAmountInput = {
  licenseAmount: Scalars['NonNegativeInt'];
  organizationId: Scalars['String'];
};

export type SetOrganizationLicenseAmountPayload = {
  __typename?: 'SetOrganizationLicenseAmountPayload';
  organization: Organization;
};

export type SetOrganizationLicenseTypeInput = {
  licenseType: SchoolSubscriptionTierTypes;
  organizationId: Scalars['ID'];
};

export type SetOrganizationLicenseTypePayload = {
  __typename?: 'SetOrganizationLicenseTypePayload';
  organization: Organization;
};

export type SetOrganizationManageChildLicensesSettingInput = {
  manageChildLicenses: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationManageChildLicensesSettingPayload = {
  __typename?: 'SetOrganizationManageChildLicensesSettingPayload';
  organization: Organization;
};

export type SetOrganizationManageStudentsSettingInput = {
  manageStudents: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationManageStudentsSettingPayload = {
  __typename?: 'SetOrganizationManageStudentsSettingPayload';
  organization: Organization;
};

export type SetOrganizationMultiLocationSettingInput = {
  multiLocation: Scalars['Boolean'];
  organizationId: Scalars['ID'];
};

export type SetOrganizationMultiLocationSettingPayload = {
  __typename?: 'SetOrganizationMultiLocationSettingPayload';
  organization: Organization;
};

export type SetPinCommentInput = {
  assignmentId: Scalars['String'];
  comment: Scalars['String'];
  pinId: Scalars['String'];
  studentUserId: Scalars['String'];
};

export type SetPinCommentPayload = {
  __typename?: 'SetPinCommentPayload';
  id: Scalars['ID'];
};

export type SetPinEntryPointsInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
  points?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type SetPinEntryPointsPayload = {
  __typename?: 'SetPinEntryPointsPayload';
  id: Scalars['ID'];
};

export type SetPinsViewedInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type SetPinsViewedPayload = {
  __typename?: 'SetPinsViewedPayload';
  id: Scalars['ID'];
};

export type SetRaiseHandInput = {
  assignmentId: Scalars['String'];
  currentRaisedHand?: InputMaybe<RaiseHand>;
  pinId: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
};

export type SetRaiseHandPayload = {
  __typename?: 'SetRaiseHandPayload';
  id: Scalars['ID'];
};

export type SetReviewPhaseForStudentInput = {
  assignmentId: Scalars['String'];
  /** set = true == start, false == stop */
  set?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type SetReviewPhaseForStudentPayload = {
  __typename?: 'SetReviewPhaseForStudentPayload';
  id: Scalars['ID'];
};

export type SetReviewPhaseInput = {
  assignmentId: Scalars['String'];
  /** set = true == start, false == stop */
  set?: InputMaybe<Scalars['Boolean']>;
};

export type SetReviewPhasePayload = {
  __typename?: 'SetReviewPhasePayload';
  id: Scalars['ID'];
};

export type SetSharedExplorerPermissionInput = {
  explorerId: Scalars['ID'];
  organizationId: Scalars['ID'];
  permission: ExplorerOrganizationPermission;
};

export type SetSharedExplorerPermissionPayload = {
  __typename?: 'SetSharedExplorerPermissionPayload';
  sharedExplorer: SharedExplorer;
};

export type SetSubmitDragQuestionInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
  submit: Scalars['Boolean'];
};

export type SetSubmitDragQuestionPayload = {
  __typename?: 'SetSubmitDragQuestionPayload';
  id: Scalars['ID'];
};

export type SharedExplorer = Explorer & Node & {
  __typename?: 'SharedExplorer';
  authorization: ExplorerAuthPrivileges;
  createdAt: Scalars['DateTime'];
  folders: ExplorerFolderToExplorerFoldersConnection;
  id: Scalars['ID'];
  isPublish: Scalars['Boolean'];
  mainExplorerId: Scalars['String'];
  name: Scalars['String'];
  organizations?: Maybe<Array<ExplorerOrganization>>;
  privacy: ExplorerPrivacy;
  type: ExplorerType;
};


export type SharedExplorerFoldersArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type ShowAnswerOpenQuestionInput = {
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type ShowAnswerOpenQuestionPayload = {
  __typename?: 'ShowAnswerOpenQuestionPayload';
  answer?: Maybe<Scalars['String']>;
};

export type SignAgreementManuallyContactDetailsInput = {
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type SignAgreementManuallyInput = {
  agreementId: Scalars['ID'];
  contactDetails: SignAgreementManuallyContactDetailsInput;
  manuallySignedDocumentUrl: Scalars['URL'];
  signDate: Scalars['DateTime'];
};

export type SignAgreementManuallyPayload = {
  __typename?: 'SignAgreementManuallyPayload';
  agreement: Agreement;
};

export type SignAgreementProposalInput = {
  agreementId: Scalars['ID'];
  proposalId: Scalars['ID'];
  /** Agreement secret that is used as authorization check */
  secret: Scalars['String'];
};

export type SignAgreementProposalPayload = {
  __typename?: 'SignAgreementProposalPayload';
  agreement: Agreement;
};

export type SlideDeckDimensions = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type SlideDeckMetaData = {
  dimensions: SlideDeckDimensions;
  slideCount: Scalars['NonNegativeInt'];
};

export type SlideDeckUpload = Node & Upload & {
  __typename?: 'SlideDeckUpload';
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The ID for the lesson that has been created by converting this slideDeck */
  lessonId?: Maybe<Scalars['ID']>;
  /** The name of the lesson that has been created by converting this slideDeck */
  lessonName?: Maybe<Scalars['String']>;
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The number of slides in the slide deck. Can be null in case we're still processing the uploaded file. */
  slideCount?: Maybe<Scalars['NonNegativeInt']>;
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type SortingDirection =
  | 'ASC'
  | 'DESC';

export type SpendTeachingCreditToTeachInput = {
  assignmentCreatedAt: Scalars['DateTime'];
  assignmentId: Scalars['ID'];
  assignmentName: Scalars['String'];
  userId: Scalars['ID'];
};

export type SpendTeachingCreditToTeachPayload = {
  __typename?: 'SpendTeachingCreditToTeachPayload';
  /**
   * The mutation in the number of credits after teaching this lesson.
   * Is zero if any of these conditions is true:
   * - the user has a license without teaching restrictions.
   * - this assignment was already taught by the user this month.
   * - the duration of the assignment was less than 5 minutes.
   * Is a negative number if it costed credits.
   */
  creditsMutation: Scalars['NonPositiveInt'];
};

export type SsoLoginServiceType =
  | 'GOOGLE'
  | 'OFFICE365';

export type StartTestAssignmentInput = {
  assignmentId: Scalars['String'];
};

export type StartTestAssignmentPayload = {
  __typename?: 'StartTestAssignmentPayload';
  id: Scalars['ID'];
};

export type StopTestAssignmentInput = {
  assignmentId: Scalars['String'];
};

export type StopTestAssignmentPayload = {
  __typename?: 'StopTestAssignmentPayload';
  id: Scalars['ID'];
};

/** Stripe customer */
export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  /**
   * Whether the subscription will automatically cancel when the period expires
   * @deprecated Use 'subscription.cancelAtPeriodEnd'
   */
  cancelAtPeriodEnd: Scalars['Boolean'];
  /** ID of stripe customer */
  id: Scalars['ID'];
  /**
   * Status of stripe subscription
   * @deprecated Use 'subscription.status'
   */
  status: StripeSubscriptionStatus;
  /** Stripe subscription belonging to this Stripe customer */
  subscription: StripeSubscription;
};

/** Stripe subscription */
export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  /** Whether the subscription will automatically cancel when the period expires */
  cancelAtPeriodEnd: Scalars['Boolean'];
  /** Whether the subscription is a paid type */
  isPaidSubscription: Scalars['Boolean'];
  /** Status of stripe subscription */
  status: StripeSubscriptionStatus;
};

/** Status of Stripe subscription */
export type StripeSubscriptionStatus =
  | 'ACTIVE'
  | 'CANCELED'
  | 'INCOMPLETE'
  | 'INCOMPLETE_EXPIRED'
  | 'PAST_DUE'
  | 'TRIALING'
  | 'UNPAID';

/** A user who is visible as a student */
export type Student = Node & User & {
  __typename?: 'Student';
  /** Email or username the users used to login */
  accountName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  loginMethod?: Maybe<UserLoginMethod>;
  name?: Maybe<Scalars['String']>;
};

/** Group visible as a student */
export type StudentGroup = Group & Node & {
  __typename?: 'StudentGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SubmitDragQuestionComponentPositionInput = {
  assignmentId: Scalars['String'];
  components: Array<ComponentPositionUpdateData>;
  pinId: Scalars['String'];
};

export type SubmitDragQuestionComponentPositionPayload = {
  __typename?: 'SubmitDragQuestionComponentPositionPayload';
  id: Scalars['ID'];
};

export type SubmitGradesInput = {
  assignmentId: Scalars['String'];
  grades: Array<UserGrade>;
};

export type SubmitGradesPayload = {
  __typename?: 'SubmitGradesPayload';
  id: Scalars['ID'];
};

export type SubmitQuizHomeworkInput = {
  answer: Array<Scalars['String']>;
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type SubmitQuizHomeworkPayload = {
  __typename?: 'SubmitQuizHomeworkPayload';
  result: SubmitQuizHomeworkResult;
};

export type SubmitQuizHomeworkResult =
  | 'CORRECT'
  | 'INCORRECT'
  | 'NO_ANSWER'
  | 'OPINION';

export type Subscription = Node & {
  __typename?: 'Subscription';
  generateContextualLesson: GenerateContextualLessonSubscriptionPayload;
  generateLesson: GenerateLessonSubscriptionPayload;
  generateLessonOutline: GenerateLessonOutlineSubscriptionPayload;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type SubscriptionGenerateContextualLessonArgs = {
  input: GenerateContextualLessonSubscriptionInput;
};


export type SubscriptionGenerateLessonArgs = {
  input: GenerateLessonSubscriptionInput;
};


export type SubscriptionGenerateLessonOutlineArgs = {
  input: GenerateLessonOutlineSubscriptionInput;
};

/** Subscription tier */
export type SubscriptionTier = Node & {
  __typename?: 'SubscriptionTier';
  /** ID of subscription tier */
  id: Scalars['ID'];
  /** Array with price information of this tier */
  prices: Array<SubscriptionTierPrice>;
  /** Type of subscription tier */
  type: SubscriptionTierType;
};

/** Price for a subscription tier */
export type SubscriptionTierPrice = {
  __typename?: 'SubscriptionTierPrice';
  /** ID of the subscription tier */
  id: Scalars['ID'];
  /** Price the user needs to pay for the tier */
  price: Price;
  /** Price calculated to what it will cost per month. If the normal price is yearly, this will be the yearly price divided by 12 */
  pricePerMonth: Price;
  /** How often the subscription renews */
  recurring: LicenseSubscriptionRecurring;
};

/** Type of subscription tier */
export type SubscriptionTierType =
  | 'PRO'
  | 'START';

export type SuccessResult = {
  __typename?: 'SuccessResult';
  assignmentId: Scalars['String'];
  type: Scalars['String'];
};

/** Supported languages of LessonUp */
export type SupportedLanguage =
  | 'EN'
  | 'FR'
  | 'NL';

export type SyncOrganizationDataToSchoolSuggestionsInput = {
  organizationId: Scalars['String'];
};

export type SyncOrganizationDataToSchoolSuggestionsPayload = {
  __typename?: 'SyncOrganizationDataToSchoolSuggestionsPayload';
  modifiedSchoolSuggestionsCount: Scalars['NonNegativeInt'];
};

export type TeachRealtimeAssignmentInput = {
  /** Group to invite to the lesson */
  groupId?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  lessonId: Scalars['ID'];
  playerSettings?: InputMaybe<RealtimePlayerSettingsInput>;
  /** Pin to start the player on, falls back to first pin in case not found */
  startPinId?: InputMaybe<Scalars['ID']>;
};

export type TeachRealtimeAssignmentPayload = {
  __typename?: 'TeachRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type TeachSource =
  | 'CHANNEL_LESSON'
  | 'DEFAULT'
  | 'EMBED'
  | 'EXPLORER'
  | 'ONBOARDING_DEMO'
  | 'PLAN'
  | 'SEARCH'
  | 'SEARCHEMBED';

/** Group visible as the teacher */
export type TeacherGroup = Group & Node & {
  __typename?: 'TeacherGroup';
  /** Indicates that the group has been archived but still findable */
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  /** Link used to share with teachers */
  directLink: Scalars['URL'];
  id: Scalars['ID'];
  /** 6 characters which grants access to the group */
  joinCode?: Maybe<Scalars['String']>;
  /** Total number of members in the group */
  membersCount: Scalars['Int'];
  name: Scalars['String'];
  /** Group name to be shown to students */
  studentDisplayName: Scalars['String'];
  /** All users that have joined the group, these are mostly students */
  students: TeacherGroupToStudentsConnection;
  /** All users that have been added to the group as a teacher. This includes the initial creator of the group */
  teachers: TeacherGroupToColleaguesConnection;
};

/** Role of the user in the group */
export type TeacherGroupRole =
  | 'MEMBER'
  | 'OWNER';

export type TeacherGroupToColleagueEdge = {
  __typename?: 'TeacherGroupToColleagueEdge';
  node?: Maybe<Colleague>;
  role?: Maybe<TeacherGroupRole>;
};

/** relation between the group and its teachers */
export type TeacherGroupToColleaguesConnection = {
  __typename?: 'TeacherGroupToColleaguesConnection';
  edges: Array<TeacherGroupToColleagueEdge>;
  nodes: Array<Maybe<Colleague>>;
  pageInfo: OffsetBasedPageInfo;
};

export type TeacherGroupToStudentEdge = {
  __typename?: 'TeacherGroupToStudentEdge';
  node?: Maybe<Student>;
};

export type TeacherGroupToStudentsConnection = {
  __typename?: 'TeacherGroupToStudentsConnection';
  edges: Array<TeacherGroupToStudentEdge>;
  nodes: Array<Maybe<Student>>;
  pageInfo: OffsetBasedPageInfo;
};

export type TeachingCreditMutation = {
  /** The creation date of the mutation. */
  createdAt: Scalars['DateTime'];
  /** The mutation in the credits balance. Is negative when credits were subtracted and positive when credits were added. */
  creditsMutation: Scalars['Int'];
  /** The ID of the mutation. */
  id: Scalars['ID'];
};

export type TeachingCreditsMutation = {
  __typename?: 'TeachingCreditsMutation';
  /** The amount of teaching credits added or subtracted */
  creditsMutation: Scalars['Int'];
  /** Optional description of why the teaching credits were added or subtracted */
  description?: Maybe<Scalars['String']>;
  /** The ID of the mutation */
  id: Scalars['ID'];
  /** The user ID of the user whose teaching credits balance was changed */
  userId: Scalars['ID'];
};

export type TransferInvoicesInput = {
  sourceUserId: Scalars['String'];
  targetUserId: Scalars['String'];
};

export type TransferInvoicesPayload = {
  __typename?: 'TransferInvoicesPayload';
  invoicesTransferred: Scalars['Int'];
};

export type TrialGift = BaseGift & Node & {
  __typename?: 'TrialGift';
  amountOfDays: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: GiftStatus;
};

export type TriggerAccountDeletionForUserInput = {
  userId: Scalars['String'];
};

export type TriggerAccountDeletionForUserPayload = {
  __typename?: 'TriggerAccountDeletionForUserPayload';
  accountDeletionRequest: AccountDeletionRequest;
};

export type UpdateActivePinInput = {
  activePinId: Scalars['String'];
  assignmentId: Scalars['String'];
};

export type UpdateActivePinPayload = {
  __typename?: 'UpdateActivePinPayload';
  id: Scalars['ID'];
};

export type UpdateAgreementProposalDetailsContactInput = {
  address: Scalars['String'];
  /**
   * Represents the school BRIN code.
   * Also used for agreements of non-Dutch schools, due to historical reasons.
   */
  brin?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  /** Represents the CofC number (also for non-Dutch organizations) */
  kvk?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
};

export type UpdateAgreementProposalDetailsInput = {
  contact: UpdateAgreementProposalDetailsContactInput;
  name: Scalars['String'];
};

export type UpdateAgreementProposalInput = {
  agreementId: Scalars['ID'];
  details: UpdateAgreementProposalDetailsInput;
  privacyContact: UpdateAgreementProposalPrivacyContactInput;
  proposalId: Scalars['ID'];
  /** Agreement secret that is used as authorization check */
  secret: Scalars['String'];
};

export type UpdateAgreementProposalPayload = {
  __typename?: 'UpdateAgreementProposalPayload';
  agreement: Agreement;
};

export type UpdateAgreementProposalPrivacyContactInput = {
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  position: Scalars['String'];
};

export type UpdateAssignmentInput = {
  assignmentId: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
};

export type UpdateAssignmentPayload = {
  __typename?: 'UpdateAssignmentPayload';
  success: Scalars['Boolean'];
};

export type UpdateAssignmentQuizQuestionCorrectAnswerInput = {
  answer: Scalars['String'];
  assignmentId: Scalars['String'];
  pinId: Scalars['String'];
};

export type UpdateAssignmentQuizQuestionCorrectAnswerPayload = {
  __typename?: 'UpdateAssignmentQuizQuestionCorrectAnswerPayload';
  id: Scalars['ID'];
};

export type UpdateAudioUploadAudioInput = {
  audio: AudioFileInput;
  uploadId: Scalars['ID'];
};

export type UpdateAudioUploadAudioPayload = {
  __typename?: 'UpdateAudioUploadAudioPayload';
  upload?: Maybe<AudioUpload>;
};

export type UpdateDefaultLicenseForUserInput = {
  expiresAt: Scalars['DateTime'];
  licenseStatus: LicenseStatus;
  userId: Scalars['String'];
};

export type UpdateDefaultLicenseForUserPayload = {
  __typename?: 'UpdateDefaultLicenseForUserPayload';
  user: Viewer;
};

export type UpdateDisplayNameInput = {
  displayName: Scalars['String'];
  entryId: Scalars['ID'];
};

export type UpdateDisplayNamePayload = {
  __typename?: 'UpdateDisplayNamePayload';
  displayName: Scalars['String'];
};

export type UpdateDocumentUploadPagesInput = {
  pages: Array<ImageFileInput>;
  uploadId: Scalars['ID'];
};

export type UpdateDocumentUploadPagesPayload = {
  __typename?: 'UpdateDocumentUploadPagesPayload';
  upload?: Maybe<DocumentUpload>;
};

export type UpdateImageUploadImageInput = {
  image: ImageFileInput;
  uploadId: Scalars['ID'];
};

export type UpdateImageUploadImagePayload = {
  __typename?: 'UpdateImageUploadImagePayload';
  upload?: Maybe<ImageUpload>;
};

export type UpdateLessonInput = {
  id: Scalars['ID'];
  pins: UpdatePinsInput;
};

export type UpdateLessonPayload = {
  __typename?: 'UpdateLessonPayload';
  id: Scalars['ID'];
};

export type UpdateMetaDataForSlideDeckUploadInput = {
  metaData: SlideDeckMetaData;
  uploadId: Scalars['ID'];
};

export type UpdateMetaDataForSlideDeckUploadPayload = {
  __typename?: 'UpdateMetaDataForSlideDeckUploadPayload';
  upload?: Maybe<SlideDeckUpload>;
};

export type UpdateOrganizationPrivacyContactInput = {
  email: Scalars['EmailAddress'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  position: Scalars['String'];
};

export type UpdateOrganizationPrivacyContactPayload = {
  __typename?: 'UpdateOrganizationPrivacyContactPayload';
  organization: Organization;
};

export type UpdatePinsInput = {
  /** Pins that should be added to the lesson */
  added: Array<Scalars['TeacherPin']>;
  /** Pins that should be removed from the lesson */
  deletedIds: Array<Scalars['String']>;
  /** Pins that should be updated in the lesson */
  updated: Array<Scalars['TeacherPin']>;
};

export type UpdateRealtimeAssignmentSettingsInput = {
  assignmentId: Scalars['String'];
  playerSettings?: InputMaybe<RealtimePlayerSettingsInput>;
};

export type UpdateRealtimeAssignmentSettingsPayload = {
  __typename?: 'UpdateRealtimeAssignmentSettingsPayload';
  assignmentId: Scalars['String'];
};

export type UpdateSlideDeckLessonIdOnUploadInput = {
  lessonId: Scalars['ID'];
  uploadId: Scalars['ID'];
};

export type UpdateSlideDeckLessonIdOnUploadPayload = {
  __typename?: 'UpdateSlideDeckLessonIdOnUploadPayload';
  upload?: Maybe<SlideDeckUpload>;
};

export type UpdateTestScheduleInput = {
  assignmentId: Scalars['String'];
  closeDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTestSchedulePayload = {
  __typename?: 'UpdateTestSchedulePayload';
  id: Scalars['ID'];
};

export type UpdateUploadFolderNameInput = {
  folderId: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateUploadFolderNamePayload = {
  __typename?: 'UpdateUploadFolderNamePayload';
  folder: UploadFolder;
};

export type UpdateUploadNameInput = {
  name: Scalars['String'];
  uploadId: Scalars['ID'];
};

export type UpdateUploadNamePayload = {
  __typename?: 'UpdateUploadNamePayload';
  upload?: Maybe<Upload>;
};

export type UpdateUploadProgressPercentageInput = {
  progressPercentage: Scalars['Int'];
  uploadId: Scalars['ID'];
};

export type UpdateUploadProgressPercentagePayload = {
  __typename?: 'UpdateUploadProgressPercentagePayload';
  upload?: Maybe<Upload>;
};

export type UpdateUploadStatusInput = {
  status: UploadStatus;
  uploadId: Scalars['ID'];
};

export type UpdateUploadStatusPayload = {
  __typename?: 'UpdateUploadStatusPayload';
  upload?: Maybe<Upload>;
};

export type UpdateUserCountryInput = {
  country: Scalars['CountryCode'];
  userId: Scalars['ID'];
};

export type UpdateUserCountryPayload = {
  __typename?: 'UpdateUserCountryPayload';
  user: Viewer;
};

export type UpdateUserLanguageInput = {
  language: SupportedLanguage;
  userId: Scalars['ID'];
};

export type UpdateUserLanguagePayload = {
  __typename?: 'UpdateUserLanguagePayload';
  user: Viewer;
};

export type UpdateUserLicenseInput = {
  expiresAt: Scalars['DateTime'];
  license: LicenseStatus;
  userId: Scalars['ID'];
};

export type UpdateUserLicensePayload = {
  __typename?: 'UpdateUserLicensePayload';
  user: Viewer;
};

export type UpdateUserNameInput = {
  name?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdateUserNamePayload = {
  __typename?: 'UpdateUserNamePayload';
  user: Viewer;
};

export type UpdateVideoUploadThumbnailInput = {
  thumbnail: ImageFileInput;
  uploadId: Scalars['ID'];
};

export type UpdateVideoUploadThumbnailPayload = {
  __typename?: 'UpdateVideoUploadThumbnailPayload';
  upload?: Maybe<VideoUpload>;
};

export type UpdateVideoUploadVideoInput = {
  uploadId: Scalars['ID'];
  video: VideoFileInput;
};

export type UpdateVideoUploadVideoPayload = {
  __typename?: 'UpdateVideoUploadVideoPayload';
  upload?: Maybe<VideoUpload>;
};

export type Upload = {
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
};

/**
 * The error code in case the upload could not be processed.
 * TODO: define more error codes
 */
export type UploadErrorCode =
  | 'UNKNOWN';

export type UploadFolder = Node & {
  __typename?: 'UploadFolder';
  authorization: FolderAuthPrivileges;
  breadcrumbs: Array<AncestorUploadFolder>;
  childUploadFoldersAndUploads: UploadFolderToChildUploadFoldersAndUploadsConnection;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isRootFolder: Scalars['Boolean'];
  name: Scalars['String'];
  parent?: Maybe<AncestorUploadFolder>;
  upload?: Maybe<Upload>;
};


export type UploadFolderChildUploadFoldersAndUploadsArgs = {
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  sortBy?: InputMaybe<UploadsSorting>;
};


export type UploadFolderUploadArgs = {
  uploadId: Scalars['ID'];
};

export type UploadFolderToChildUploadFolderAndUploadEdge = {
  __typename?: 'UploadFolderToChildUploadFolderAndUploadEdge';
  node?: Maybe<ChildUploadFolderOrUpload>;
};

export type UploadFolderToChildUploadFoldersAndUploadsConnection = {
  __typename?: 'UploadFolderToChildUploadFoldersAndUploadsConnection';
  edges: Array<UploadFolderToChildUploadFolderAndUploadEdge>;
  nodes: Array<ChildUploadFolderOrUpload>;
  pageInfo: OffsetBasedPageInfo;
  totalCount: Scalars['NonNegativeInt'];
};

export type UploadStatus =
  | 'ERROR'
  | 'PROCESSING'
  | 'READY'
  | 'UPLOADING';

/** The field on which to sort the list of uploads and the direction in which to sort. */
export type UploadsSorting = {
  direction: SortingDirection;
  field: UploadsSortingField;
};

/** The field on which to sort the list of uploads. */
export type UploadsSortingField =
  | 'NAME'
  | 'ORIGINAL_FILE_EXTENSION'
  | 'ORIGINAL_FILE_SIZE_IN_BYTES'
  | 'UPDATED_AT';

/** Shared interface containing the basic properties visible by all */
export type User = {
  /** Email or username the users used to login */
  accountName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Name of the user */
  name?: Maybe<Scalars['String']>;
};

export type UserDidRegisterInput = {
  /** The referral token that was used to register the user. */
  referralToken?: InputMaybe<Scalars['String']>;
  /** In the future we will generate the userId inside this mutation, but for now that will come from the 1.0 */
  userId: Scalars['ID'];
};

export type UserDidRegisterPayload = {
  __typename?: 'UserDidRegisterPayload';
  /** The user that was registered */
  user: Viewer;
};

export type UserEmail = {
  __typename?: 'UserEmail';
  address: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type UserGrade = {
  grade: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserLicense = {
  __typename?: 'UserLicense';
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Same as the user id */
  id: Scalars['ID'];
  /**
   * Returns whether teaching is allowed or not
   * Is true if the license allows it or there are remaining credits
   * Is false if all the credits are used
   */
  isTeachingAllowed: Scalars['Boolean'];
  /** Date of the last time the teaching credits were refilled. */
  lastTeachingCreditsRefilledDate: Scalars['DateTime'];
  origin: LicenseOrigin;
  /**
   * Remaining teaching credits based on viewer's license.
   * Is a non-negative integer when the viewer's license has teaching restrictions.
   * Is null when the viewer's license does not have any teaching restrictions.
   */
  remainingTeachingCreditsCount?: Maybe<Scalars['NonNegativeInt']>;
  status: LicenseStatus;
  /**
   * Returns all of the teaching credit mutations (both lessons taught and credit mutations via the admin) that occured in the current calendar month for the user.
   * It will return them in chronological order (most recent one first).
   */
  teachingCreditMutations: Array<TeachingCreditMutation>;
  /** Teaching credits used during the previous month */
  teachingCreditsUsedDuringPreviousMonth: Scalars['NonNegativeInt'];
};

export type UserLoginMethod =
  | 'GOOGLE'
  | 'OFFICE_365'
  | 'PASSWORD'
  | 'SURFCONEXT';

export type ValidatePincodeInput = {
  pincode: Scalars['String'];
};

export type ValidatePincodePayload = {
  __typename?: 'ValidatePincodePayload';
  reason?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type VerifyEmailByTokenPayload = {
  __typename?: 'VerifyEmailByTokenPayload';
  email?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
};

export type Video = {
  __typename?: 'Video';
  id: VideoId;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type VideoFile = File & {
  __typename?: 'VideoFile';
  /** The MIME type of the video. */
  contentType: Scalars['String'];
  /** The duration of the video in seconds. */
  durationInSeconds: Scalars['Float'];
  /** The file extension of the file (png, pdf, etc.). */
  fileExtension: Scalars['String'];
  /** The file size of the video in bytes. */
  sizeInBytes: Scalars['Int'];
  /** The media server URL from which the video can be streamed. */
  url: Scalars['URL'];
};

export type VideoFileInput = {
  contentType: Scalars['String'];
  duration: Scalars['Float'];
  extension: Scalars['String'];
  location: LocationInput;
  size: Scalars['Int'];
  url: Scalars['URL'];
};

export type VideoId = {
  __typename?: 'VideoId';
  source: VideoSource;
  sourceId: Scalars['String'];
};

export type VideoIdInput = {
  source: VideoSource;
  sourceId: Scalars['String'];
};

export type VideoSource =
  | 'vimeo'
  | 'youtube';

export type VideoUpload = Node & Upload & {
  __typename?: 'VideoUpload';
  /** The timestamp of when this file was uploaded. */
  createdAt: Scalars['DateTime'];
  /** The error code in case the upload could not be processed. */
  errorCode?: Maybe<UploadErrorCode>;
  /** The ID of the upload. Is either a UUID (new uploads) or a Meteor ID (legacy uploads). */
  id: Scalars['ID'];
  /** The name of the upload. Defaults to the file name of the uploaded file, but can be overridden. */
  name: Scalars['String'];
  /** The original file that has been uploaded. */
  originalFile: OriginalFile;
  /** The progress percentage of the uploaded file. This shows how far along we are with processing the uploaded file. */
  progressPercentage: Scalars['Int'];
  /** The status of the uploaded file. */
  status: UploadStatus;
  /** The thumbnail image that can be used for the overview page. Can be null in case we're still processing the uploaded file or the migrated video upload never had a thumbnail. */
  thumbnail?: Maybe<ImageFile>;
  /** The timestamp of when the upload was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The video, converted to our standard format. Can be null in case we're still processing the uploaded file. */
  video?: Maybe<VideoFile>;
};

export type VideosByIdsInput = {
  ids: Array<VideoIdInput>;
};

/** The current user viewing the graph */
export type Viewer = Node & User & {
  __typename?: 'Viewer';
  /** Returns the MIME types that the user is allowed to upload. Can be used for the file picker to restrict it to only allow selecting files with these MIME types. */
  acceptedMimeTypesForUploads: AcceptedMimeTypesForUploads;
  acceptedTerms: Scalars['Boolean'];
  /** All available account deletion reason types that a user can give */
  accountDeletionReasonTypes: Array<AccountDeletionReasonType>;
  /** Information about the user's request on their account deletion */
  accountDeletionRequest?: Maybe<AccountDeletionRequest>;
  /** Email or username the users used to login */
  accountName?: Maybe<Scalars['String']>;
  agreementById?: Maybe<Agreement>;
  agreementByOrganizationId?: Maybe<Agreement>;
  assignment: Assignment;
  country?: Maybe<Scalars['CountryCode']>;
  /** Date when the user validated his email */
  emailValidatedAt?: Maybe<Scalars['DateTime']>;
  emails: Array<UserEmail>;
  /** Feature flags which are enabled for the user */
  featureFlags: FeatureFlags;
  /** Groups the user has joined as a student */
  groupsAsStudent: ViewerToStudentGroupsConnection;
  /** Groups the user has joined as a teacher */
  groupsAsTeacher: ViewerToTeacherGroupsConnection;
  /** Whether this user has products */
  hasProducts: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Hash needed for identity verification enforcement for Intercom */
  intercomHash: Scalars['String'];
  /** Checks if user has privilege to do the given action to the given entity id */
  isAllowedTo: Scalars['Boolean'];
  language?: Maybe<SupportedLanguage>;
  latestRedeemedTrialGift?: Maybe<ReceivedTrialGift>;
  lesson?: Maybe<Lesson>;
  lessonsByIds: ViewerToLessonsConnection;
  license: UserLicense;
  /** The subscription of the user which is required to buy a license */
  licenseSubscription?: Maybe<LicenseSubscription>;
  /** Returns the maximum file size in bytes that the user is allowed to upload. */
  maxFileSizeInBytesForUploads: Scalars['NonNegativeInt'];
  /** organizations where the user has role OrganizationUserRole.MEMBER */
  memberOfOrganizations: ViewerToOrganizationsConnection;
  /** Name of the user */
  name?: Maybe<Scalars['String']>;
  ocrUploadsByIds: Array<Upload>;
  /** organization by id that belongs to the user */
  organizationById?: Maybe<Organization>;
  /** Notices belonging to the user */
  pendingNotices: ViewerToNoticesConnection;
  /**
   * The school subscription tiers available for this user.
   * Prices are in the currency of the subscription that the user has or has had.
   * If the user never had a subscription, the IP geolocation header will be used instead to determine the currency.
   */
  personalizedSchoolSubscriptionTiers: Array<PersonalizedSchoolSubscriptionTier>;
  /**
   * The subscription tiers available for this user.
   * Prices are in the currency of the subscription that the user has or has had.
   * If the user never had a subscription, the IP geolocation header will be used instead to determine the currency.
   */
  personalizedSubscriptionTiers: Array<PersonalizedSubscriptionTier>;
  primaryEmail?: Maybe<Scalars['String']>;
  /** Products activated for the user through redeemedVouchers */
  products: ViewerToProductsConnection;
  /** Referral document belonging to this user */
  referral: Referral;
  /**
   * Returns all agreements relevant to the organization.
   * These are the organization's signed agreements, the parent's signed agreements and (if unsigned) the most recent document.
   */
  relevantAgreementsForOrganization: Array<OrganizationAgreement>;
  reportById?: Maybe<Report>;
  schoolSuggestions: ViewerToSchoolSuggestionsConnection;
  /** Search uploads for the viewer. The search results may not be entirely up-to-date as the search engine may experience a lag in indexing new content. */
  searchUploads: ViewerToUploadSearchResultsConnection;
  /** SSO login services */
  ssoLoginServiceTypes: Array<SsoLoginServiceType>;
  /** Stripe customer attached to this Viewer */
  stripeCustomer?: Maybe<StripeCustomer>;
  /**
   * Get the specified upload folder with its contents.
   * When folderId is null, you'll get the root upload folder of the viewer.
   */
  uploadFolder?: Maybe<UploadFolder>;
  /** Retrieves multiple uploads by their ID. Can be used for example to poll the status of uploads while they're being uploaded. */
  uploadsByIds: Array<Upload>;
};


/** The current user viewing the graph */
export type ViewerAgreementByIdArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerAgreementByOrganizationIdArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerAssignmentArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerGroupsAsStudentArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The current user viewing the graph */
export type ViewerGroupsAsTeacherArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The current user viewing the graph */
export type ViewerIsAllowedToArgs = {
  action: AuthorizationActionType;
  entityId: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerLessonArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerLessonsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


/** The current user viewing the graph */
export type ViewerMemberOfOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The current user viewing the graph */
export type ViewerOcrUploadsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


/** The current user viewing the graph */
export type ViewerOrganizationByIdArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerPendingNoticesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<NoticeTypeFilter>;
};


/** The current user viewing the graph */
export type ViewerProductsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The current user viewing the graph */
export type ViewerRelevantAgreementsForOrganizationArgs = {
  organizationId: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerReportByIdArgs = {
  id: Scalars['ID'];
};


/** The current user viewing the graph */
export type ViewerSchoolSuggestionsArgs = {
  country: Scalars['CountryCode'];
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  query: Scalars['NonEmptyString'];
};


/** The current user viewing the graph */
export type ViewerSearchUploadsArgs = {
  limit?: InputMaybe<Scalars['PositiveInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  query: Scalars['String'];
  sortBy?: InputMaybe<UploadsSorting>;
};


/** The current user viewing the graph */
export type ViewerUploadFolderArgs = {
  folderId?: InputMaybe<Scalars['ID']>;
};


/** The current user viewing the graph */
export type ViewerUploadsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type ViewerLessonPermissions = {
  __typename?: 'ViewerLessonPermissions';
  read: Scalars['Boolean'];
  write: Scalars['Boolean'];
};

export type ViewerToLessonEdge = {
  __typename?: 'ViewerToLessonEdge';
  node: Lesson;
  permission: ViewerLessonPermissions;
};

export type ViewerToLessonsConnection = {
  __typename?: 'ViewerToLessonsConnection';
  edges: Array<ViewerToLessonEdge>;
  nodes: Array<Lesson>;
};

export type ViewerToNoticeEdge = {
  __typename?: 'ViewerToNoticeEdge';
  node: Notice;
};

export type ViewerToNoticesConnection = {
  __typename?: 'ViewerToNoticesConnection';
  edges: Array<ViewerToNoticeEdge>;
  nodes: Array<Notice>;
  pageInfo: OffsetBasedPageInfo;
};

export type ViewerToOrganizationEdge = {
  __typename?: 'ViewerToOrganizationEdge';
  isAdmin: Scalars['Boolean'];
  node?: Maybe<Organization>;
  role: OrganizationUserRole;
};

/** Relationship between the viewer and his schools */
export type ViewerToOrganizationsConnection = {
  __typename?: 'ViewerToOrganizationsConnection';
  edges: Array<ViewerToOrganizationEdge>;
  /** all organization ids, including non school ones */
  nodes: Array<Maybe<Organization>>;
  pageInfo: OffsetBasedPageInfo;
};

/** Edge from viewer to product */
export type ViewerToProductEdge = {
  __typename?: 'ViewerToProductEdge';
  /** Node as part of the edge */
  node?: Maybe<Product>;
};

/** Connection from viewer to its products */
export type ViewerToProductsConnection = {
  __typename?: 'ViewerToProductsConnection';
  /** edge between viewer and product */
  edges: Array<ViewerToProductEdge>;
  /** product nodes that connect to the viewer */
  nodes: Array<Maybe<Product>>;
  /** page info like */
  pageInfo: OffsetBasedPageInfo;
};

export type ViewerToSchoolSuggestionEdge = {
  __typename?: 'ViewerToSchoolSuggestionEdge';
  node: SchoolSuggestion;
};

export type ViewerToSchoolSuggestionsConnection = {
  __typename?: 'ViewerToSchoolSuggestionsConnection';
  edges: Array<ViewerToSchoolSuggestionEdge>;
  nodes: Array<SchoolSuggestion>;
};

export type ViewerToStudentGroupEdge = {
  __typename?: 'ViewerToStudentGroupEdge';
  node?: Maybe<StudentGroup>;
};

export type ViewerToStudentGroupsConnection = {
  __typename?: 'ViewerToStudentGroupsConnection';
  edges: Array<ViewerToStudentGroupEdge>;
  nodes: Array<Maybe<StudentGroup>>;
  pageInfo: OffsetBasedPageInfo;
};

export type ViewerToTeacherGroupEdge = {
  __typename?: 'ViewerToTeacherGroupEdge';
  node?: Maybe<TeacherGroup>;
};

export type ViewerToTeacherGroupsConnection = {
  __typename?: 'ViewerToTeacherGroupsConnection';
  edges: Array<ViewerToTeacherGroupEdge>;
  nodes: Array<Maybe<TeacherGroup>>;
  pageInfo: OffsetBasedPageInfo;
};

export type ViewerToUploadSearchResultsConnection = {
  __typename?: 'ViewerToUploadSearchResultsConnection';
  edges: Array<ViewerToUploadSearchResultsEdge>;
  nodes: Array<Upload>;
  pageInfo: OffsetBasedPageInfo;
  totalCount: Scalars['NonNegativeInt'];
};

export type ViewerToUploadSearchResultsEdge = {
  __typename?: 'ViewerToUploadSearchResultsEdge';
  node?: Maybe<Upload>;
};

export type Visibility =
  | 'ALL'
  | 'STUDENT_ONLY';

export type WithdrawAnswerWordCloudForRealtimeAssignmentInput = {
  answer: Scalars['NonEmptyString'];
  assignmentId: Scalars['ID'];
  pinId: Scalars['ID'];
};

export type WithdrawAnswerWordCloudForRealtimeAssignmentPayload = {
  __typename?: 'WithdrawAnswerWordCloudForRealtimeAssignmentPayload';
  assignment: Assignment;
};

export type ThumbnailerPinsAndPinCountQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type ThumbnailerPinsAndPinCountQuery = { __typename?: 'Query', lessonById?: { __typename?: 'Lesson', teacherPins: Array<TeacherPin>, pinCount: number } | null };

export type PinsForLessonQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type PinsForLessonQuery = { __typename?: 'Query', lessonById?: { __typename?: 'Lesson', teacherPins: Array<TeacherPin> } | null };


export const ThumbnailerPinsAndPinCountDocument = gql`
    query ThumbnailerPinsAndPinCount($input: ID!) {
  lessonById(id: $input) {
    teacherPins(limit: 4)
    pinCount
  }
}
    `;
export const PinsForLessonDocument = gql`
    query PinsForLesson($input: ID!) {
  lessonById(id: $input) {
    teacherPins
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ThumbnailerPinsAndPinCount(variables: ThumbnailerPinsAndPinCountQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ThumbnailerPinsAndPinCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ThumbnailerPinsAndPinCountQuery>(ThumbnailerPinsAndPinCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ThumbnailerPinsAndPinCount', 'query');
    },
    PinsForLesson(variables: PinsForLessonQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PinsForLessonQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PinsForLessonQuery>(PinsForLessonDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PinsForLesson', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;