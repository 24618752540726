import { useKeybindController } from '@lessonup/pin-renderer';
import { ListItem, ListItemProps, rem, styled, Swatch, useModal } from '@lessonup/ui-components';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ColorpickerModal } from '../../../EditorModals/ColorpickerModal/ColorpickerModal';

export interface ColorPickerSettingProps {
  selectedColor?: string;
  text: string;
  ariaLabel: string;
  onChange?: (color: string) => void;
  size?: ListItemProps['size'];
  swatchOnly?: boolean;
  hideTransparentOption?: boolean;
  disableAlpha?: boolean;
}

export const ColorPickerSetting = forwardRef(function ColorPickerSetting(
  {
    selectedColor = '',
    text,
    ariaLabel,
    onChange,
    size,
    swatchOnly,
    hideTransparentOption,
    disableAlpha,
  }: ColorPickerSettingProps,
  ref
) {
  const containerRef = useRef(null);
  const { enableKeybinds, disableKeybinds } = useKeybindController();
  const modal = useModal(ColorpickerModal);

  const openModal = () => {
    disableKeybinds();
    modal.show({
      parentSelector: () => containerRef.current,
      selectedColor,
      onChange,
      onBeforeClose: () => {
        enableKeybinds();
      },
      hideTransparentOption,
      disableAlpha,
    });
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const handleClick = () => {
    openModal();
  };

  return (
    <div ref={containerRef}>
      {swatchOnly ? (
        <StyledInvisibleButton aria-label={ariaLabel} onClick={handleClick}>
          <Swatch swatchColor={selectedColor} />
        </StyledInvisibleButton>
      ) : (
        <ListItem
          aria-label={ariaLabel}
          size={size}
          onClick={handleClick}
          text={text}
          startSlot={<Swatch swatchColor={selectedColor} />}
        />
      )}
    </div>
  );
});

const StyledInvisibleButton = styled.button`
  width: ${rem('32px')};
  height: ${rem('32px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
