import { Button, Divider, IconLogin, IconLogout, IconResizeMax, IconResizeMin, spacing } from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../../../features/Player/hooks/usePlayerTranslation';
import { PlayerBarBottomSubGroup } from './PlayerBarBottomModules';

interface PlayerBarBottomPositioningProps {
  toggleMirror: () => void;
  toggleMinimize: () => void;
  mirrored: boolean;
  minimized: boolean;
  showLabels?: boolean;
}

export const PlayerBarBottomPositioning: React.FC<PlayerBarBottomPositioningProps> = ({
  mirrored,
  minimized,
  toggleMirror,
  toggleMinimize,
}) => {
  const { t } = usePlayerTranslation();
  return (
    <PlayerBarBottomSubGroup mirrored={mirrored}>
      <Button
        buttonShape="rect"
        buttonType="neutral"
        onClick={toggleMinimize}
        aria-label={t(minimized ? 'labels.maximize' : 'labels.minimize')}
        iconStart={minimized ? <IconResizeMax /> : <IconResizeMin />}
      />
      {minimized && (
        <PlayerBarBottomSubGroup mirrored={mirrored}>
          <Divider orientation="vertical" spacing={spacing.size8} />
          <Button
            buttonShape="rect"
            buttonType="neutral"
            onClick={toggleMirror}
            aria-label={t(mirrored ? 'labels.moveLeft' : 'labels.moveRight')}
            iconStart={mirrored ? <IconLogin /> : <IconLogout />}
          />
        </PlayerBarBottomSubGroup>
      )}
    </PlayerBarBottomSubGroup>
  );
};
