import { DataLoader, useDocumentQuery, useLocale } from '@lessonup/client-integration';
import { BodyText, Button, Headline, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import { formatDuration, getRouteForAsset, localeToDateFnsLocale } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block } from '../Block/Block';
import { RefereeTrialGiftDocument } from './DisplayGiftMessage.graphql.generated';

const TRANSLATION_NAMESPACE = 'displayGiftMessage';

export type DisplayGiftMessageProps = {
  onNext: () => void;
};

const MAX_DAYS_IN_MONTH = 31;

export const DisplayGiftMessage = (props: DisplayGiftMessageProps) => {
  const { onNext } = props;
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const locale = useLocale();

  const { data, loading, error } = useDocumentQuery(RefereeTrialGiftDocument);
  const getFormatedAmount = (days: number | undefined) => {
    if (!days) return;
    const amountOfMonths = Math.floor(days / MAX_DAYS_IN_MONTH);
    return amountOfMonths > 0
      ? formatDuration(
          {
            months: amountOfMonths,
          },
          { format: ['months'], locale: localeToDateFnsLocale(locale) }
        )
      : formatDuration(
          {
            days: days,
          },
          { format: ['days'], locale: localeToDateFnsLocale(locale) }
        );
  };

  return (
    <DataLoader
      data={data}
      loading={loading}
      error={error}
      dataRenderer={(data) => {
        return (
          <StyledBlock resizing="fixed">
            <Content padding={spacing.size32} direction="y" spacing={spacing.size8}>
              <StyledImg alt={t('imageAlt')} src={getRouteForAsset('img/displayGift/displayGiftImg.png')} />
              <SpaceBetween direction="y" spacing={spacing.size8} padding={spacing.size24}>
                <Headline size="medium">{t('title')}</Headline>
                <BodyText>
                  {t('message', {
                    name: data.activeRefereeTrialGift?.name,
                    time: getFormatedAmount(data.activeRefereeTrialGift?.amountOfDays),
                  })}
                </BodyText>
              </SpaceBetween>
              <Button onClick={onNext}>{t('actionButton')}</Button>
            </Content>
          </StyledBlock>
        );
      }}
    />
  );
};

const Content = styled(SpaceBetween)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const StyledBlock = styled(Block)`
  width: ${rem('546px')};
`;

const StyledImg = styled.img`
  margin-top: ${spacing.size24};
  width: ${rem('308px')};
  border-radius: ${spacing.size24};
`;
