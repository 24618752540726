import { IndexGenerator } from 'fractional-indexing-jittered';

export interface OrderHandler {
  generateNext: () => string;
  getOrder: () => string;
}

export interface PlayerOrderable {
  order: string;
}

export interface CreateOrderHandlerOptions {
  start?: string;
  useJitter?: boolean;
}

export const createOrderHandler = ({
  start = 'a0',
  useJitter = true,
}: CreateOrderHandlerOptions = {}): OrderHandler => {
  let lastOrder = start;
  const generator = new IndexGenerator([start], { groupIdLength: 0, useJitter });
  return {
    generateNext: () => {
      lastOrder = generator.keyEnd();
      return lastOrder;
    },
    getOrder: () => lastOrder,
  };
};

export function orderablePlayerCompareFn(a: PlayerOrderable, b: PlayerOrderable): number {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
}
