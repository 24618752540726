import { HotspotExpandDirection, Size, Vector } from '@lessonup/pins-shared';
import { spacing } from '@lessonup/ui-components';
import { pinSize } from '../../foundations/layout/pinDefault';

const HALF_EDITOR_WIDTH = pinSize.width / 2;
const HALF_EDITOR_HEIGHT = pinSize.height / 2;

export const calculateFlipX = (
  expandDirection: HotspotExpandDirection,
  hotspotSize: Size,
  hotspotPosition: Vector
): boolean => {
  const isLeftExpand = ['LEFT_DOWN', 'LEFT_UP'].includes(expandDirection);
  return isLeftExpand || (expandDirection === 'AUTO' && hotspotPosition.x > HALF_EDITOR_WIDTH - hotspotSize.width / 2);
};

export const calculateFlipY = (
  expandDirection: HotspotExpandDirection,
  hotspotSize: Size,
  hotspotPosition: Vector
): boolean => {
  const isUpExpand = ['LEFT_UP', 'RIGHT_UP'].includes(expandDirection);
  return isUpExpand || (expandDirection === 'AUTO' && hotspotPosition.y > HALF_EDITOR_HEIGHT - hotspotSize.height / 2);
};

export const calculateContentHolderTranslateX = (isXFlipped: boolean, hotspotSize: Size): string => {
  if (isXFlipped) {
    return `calc(-100% + ${hotspotSize.width / 2}px)`;
  }
  return `${hotspotSize.width / 2}px`;
};

export const calculateContentHolderTranslateY = (isYFlipped: boolean, hotspotSize: Size): string => {
  if (isYFlipped) {
    return `calc(-100% + ${hotspotSize.height}px)`;
  }
  return '0px';
};

export const calculateContentHolderPadding = (isXFlipped: boolean, hotspotSize: Size): string => {
  const extraXPadding = `calc(${hotspotSize.width / 2}px + ${spacing.size16})`;
  if (isXFlipped) {
    return `${spacing.size6} ${extraXPadding} ${spacing.size6} ${spacing.size16}`;
  }
  return `${spacing.size6} ${spacing.size16} ${spacing.size6} ${extraXPadding}`;
};
