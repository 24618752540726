import { InternalRefetchQueriesInclude, useDocumentMutation } from '@lessonup/client-integration';
import { asError } from '@lessonup/utils';
import { uploadFile } from '../../../Uploads/hooks/useUploadFile';
import { CreateSlideDeckUploadDocument } from '../../Explorer.graphql.generated';

export interface UseUploadSlideDeckProperties {
  onError?: (error: Error) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

export interface PerformSlideDeckUploadProperties {
  file: File;
  folderId?: string;
  lessonLocation?: {
    folderId: string;
    explorerId: string;
  };
  lessonName: string;
}

export type UseUploadSlideDeckOutput = [(p: PerformSlideDeckUploadProperties) => Promise<string | undefined>, boolean];

export const useUploadSlideDeckFile = ({
  onError,
  refetchQueries,
}: UseUploadSlideDeckProperties): UseUploadSlideDeckOutput => {
  const options = {
    ...(refetchQueries
      ? {
          refetchQueries,
        }
      : {}),
  };

  const [createSlideDeckUpload, { loading }] = useDocumentMutation(CreateSlideDeckUploadDocument, options);

  const performUpload = async ({ file, folderId, lessonName, lessonLocation }: PerformSlideDeckUploadProperties) => {
    try {
      const { data } = await createSlideDeckUpload({
        variables: {
          input: {
            contentType: file.type,
            name: file.name,
            lessonName,
            lessonLocation,
            sizeInBytes: file.size,
            folderId,
          },
        },
      });

      if (!data) throw new Error('No upload url.');

      await uploadFile(data.createUploadForSlideDeckImport.uploadUrl, file);

      return data.createUploadForSlideDeckImport.upload.id;
    } catch (error) {
      onError?.(asError(error));
    }
  };

  return [performUpload, loading];
};
