import { makeVar, useReactiveVar } from '@apollo/client';
import { defaultLocale, Locale } from '@lessonup/utils';

export const current = makeVar<Locale>(defaultLocale);

export function setLocale(locale: Locale) {
  current(locale);
}

export function getLocale(): Locale {
  return current();
}

export function useLocale(): Locale {
  return useReactiveVar(current);
}
