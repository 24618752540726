import { newSize, newVector } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PieSegment } from './PieSegment';
import { Path } from './svgPath';

export interface SpinnerHighlightProps {
  size: number;
  sweepTurn: number;
}

export function SpinnerHighlight({ size, sweepTurn }: SpinnerHighlightProps) {
  const halfSize = size / 2;

  return (
    <StyledSvg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
      <PieSegment
        center={newVector(halfSize, halfSize)}
        radius={halfSize - strokeWidth / 2}
        startTurn={-sweepTurn / 2}
        sweepTurn={sweepTurn}
      />
      <circle cx={halfSize} cy={halfSize} r="8" fill="white" />
      <Pointer halfSize={halfSize} />
    </StyledSvg>
  );
}

interface PointerProps {
  halfSize: number;
}

function Pointer({ halfSize }: PointerProps) {
  const path = Path.closedPath([
    Path.moveTo(newVector(halfSize, pointerSize.height + strokeWidth / 2)),
    Path.relativeLineTo(newVector(-pointerSize.width / 2, -pointerSize.height)),
    Path.relativeHorizontalLine(pointerSize.width),
  ]);
  return <path d={path} fill="white" />;
}

const pointerSize = newSize(12, 9);
const strokeWidth = 2;

const StyledSvg = styled.svg`
  position: absolute;
  top: 0;
  stroke: white;
  stroke-width: ${strokeWidth};
  fill: none;
`;
