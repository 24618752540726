import {
  FontLoader,
  isPinWithLayoutParts,
  PinComponent,
  PinComponentSwitch,
  PinScaleWrapper,
  pinSize,
  QuizPinLayoutTemplate,
  useHandleMouseEvents,
  useLinkedPinComponents,
  usePinResizeObserver,
  useSortPinComponents,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import { color, isLeftMBClick, isRightMBClick, spacing, styled } from '@lessonup/ui-components';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DropOverlay } from '../../../../components/ImageDropOverlay/DropOverlay';
import { useFeatureFlags } from '../../../../utils/featureFlags/useFeatureFlags';
import { useEditorContext } from '../../context/EditorContext/EditorContext';
import {
  useEditMode,
  useNonNullActivePin,
  useSelectedPinComponentIds,
} from '../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../hooks/useEditorTranslation';
import { useLockPinContainerScroll } from '../../hooks/useLockPinContainerScroll';
import { generateMaiaQuiz } from '../../utils/maia/maia.utils';
import { mergePinComponentsLayout } from '../../utils/pinComponent/pinComponent.utils';
import { useEditorUploadsStatus } from '../../utils/yjs/EditorUploadsYjs/useEditorUploads';
import { PinEditorContextMenu } from '../EditorContextMenus/PinEditorContextMenu/PinEditorContextMenu';
import { PinEditorContextMenuDynamicItemProps } from '../EditorContextMenus/PinEditorContextMenu/pinEditorContextMenu.types';
import { PinEditorContextMenuContextProvider } from '../EditorContextMenus/PinEditorContextMenu/PinEditorContextMenuContextProvider';
import { usePinEditorContextMenu } from '../EditorContextMenus/PinEditorContextMenu/usePinEditorContextMenu';
import { ControlLayer } from './ControlLayer/ControlLayer';
import { useEditorDragAndDrop } from './hooks/useEditorDragAndDrop';
import { usePinEditorKeyboardEvents } from './hooks/usePinEditorKeyboardEvents';
import {
  PinComponentLoadingSwitchRendererEditorMemo,
  PinComponentSwitchRendererEditorMemo,
} from './PinComponentSwitchEditor/PinComponentSwitchEditor';
import { PinEditorFAM } from './PinEditorFAM/PinEditorFAM';
import { PinEventLayer } from './PinEventLayer';
import { PinSwitchEditor } from './PinSwitchEditor/PinSwitchEditor';

/**
 * This will render a complete pin for the editor
 * Should be run in a UpdatePinComponentContextProvider if you want to persist the changes
 */
export const PinEditor: React.FC = () => {
  const pin = useNonNullActivePin();
  const selectedPinComponentIds = useSelectedPinComponentIds();
  const editMode = useEditMode();
  const isUploadPending = useEditorUploadsStatus();
  const {
    setModeSelecting,
    setSubSelectionValue,
    setActivePinId,
    setSelectedPinIds,
    lessonId,
    setModeTextEdit,
    setModeCropping,
  } = useEditorContext();
  const pinComponents = useSortPinComponents(pin.pinComponents);
  const pinWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useUpdatePin();
  const [clickedItem, setClickedItem] = useState<PinEditorContextMenuDynamicItemProps | null>(null);

  const startGenerateMaiaQuiz = useCallback(
    (afterPinId: string, layout: QuizPinLayoutTemplate, prompt?: string) => {
      const onAddNewPins = (pinIds: string[]) => {
        setActivePinId(pinIds[pinIds.length - 1]);
      };
      generateMaiaQuiz(afterPinId, layout, dispatch, onAddNewPins, lessonId, prompt);
    },
    [dispatch, lessonId, setActivePinId]
  );
  const { isFeatureEnabled } = useFeatureFlags();

  const { scale, pinRendererOffset, resizeObserverInitialized } = usePinResizeObserver(pinWrapperRef);
  const { isLinkedToInactiveHotspot, isActiveHotspot } = useLinkedPinComponents(pinComponents, selectedPinComponentIds);
  const { t } = useEditorTranslation();

  const {
    mutatingPinComponents,
    selectionBox,
    visibleRails,
    handleOnMouseDown,
    handleOnMouseEnter,
    handleOnMouseLeave,
    isMoveActionInProgress,
    hoveredPinComponentId,
  } = useHandleMouseEvents(
    scale,
    pinRendererOffset,
    pinComponents,
    selectedPinComponentIds,
    setModeSelecting,
    setSubSelectionValue,
    setSelectedPinIds
  );

  usePinEditorKeyboardEvents(mutatingPinComponents.length > 0);

  const setModeCroppingIfEnabled = isFeatureEnabled('editorImageCropping') ? setModeCropping : undefined;

  const activePinComponents = useMemo(
    () =>
      mutatingPinComponents.length
        ? mutatingPinComponents
        : pinComponents.filter((pinComponent) => selectedPinComponentIds.includes(pinComponent.id)),
    [mutatingPinComponents, pinComponents, selectedPinComponentIds]
  );

  const [isDragging, dragAndDropHandlers] = useEditorDragAndDrop();

  const { pinContainerRef } = useLockPinContainerScroll();

  const handlePickOnMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isLeftMBClick(event)) {
        handleOnMouseDown(event, { action: 'select' });
      } else if (isRightMBClick(event)) {
        handleOnMouseDown(event, { action: 'select-pin-components', componentIds: [] });
      }
    },
    [handleOnMouseDown]
  );

  const { handleOpenContextMenu, hideAllContextMenus } = usePinEditorContextMenu({
    setClickedItem,
    pin,
  });

  function isBeingCropped(pinComponent: PinComponent): boolean {
    return editMode.type === 'cropping' && pinComponent.id === editMode.selectedPinComponent.id;
  }

  return (
    <PinEditorContextMenuContextProvider
      handleOpenContextMenu={handleOpenContextMenu}
      hideAllContextMenus={hideAllContextMenus}
    >
      <PinEventLayer
        onMouseDown={handlePickOnMouseDown}
        eventHandlers={dragAndDropHandlers}
        onContextMenu={(event) => {
          event.stopPropagation();
          handleOpenContextMenu(event, 'pin', selectedPinComponentIds);
        }}
      >
        <StyledPinWrapper ref={pinWrapperRef} data-testid="pin-content-container">
          <FontLoader />
          <PinScaleWrapper scale={scale} resizeObserverInitialized={resizeObserverInitialized}>
            <StyledPin style={{ transform: `scale(${scale})` }} ref={pinContainerRef}>
              <PinSwitchEditor>
                {pinComponents
                  .filter((pinComponent) => !isBeingCropped(pinComponent))
                  .map((pinComponent) => {
                    const isLoading = isUploadPending({
                      type: 'pinComponent',
                      pinId: pin.id,
                      pinComponentId: pinComponent.id,
                    });

                    const latestPinComponent = mergePinComponentsLayout(
                      pinComponent,
                      mutatingPinComponents.find((comp) => comp.id === pinComponent.id)
                    );

                    const linkedComponent = isLinkedToInactiveHotspot(pinComponent.id);
                    const activeHotspot = isActiveHotspot(pinComponent.id);

                    const isActive =
                      (selectedPinComponentIds.length === 1 && selectedPinComponentIds[0] === pinComponent.id) ||
                      activeHotspot;
                    const layoutParts = isPinWithLayoutParts(pin) ? pin.settings.layoutParts : undefined;

                    return (
                      <PinComponentSwitch
                        pinId={pin.id}
                        pinType={pin.type}
                        layoutKey={pin.settings.layout}
                        layoutParts={layoutParts}
                        key={pinComponent.id}
                        pinComponent={latestPinComponent}
                        onMouseDown={handleOnMouseDown}
                        isHidden={linkedComponent}
                        editable={true}
                        active={isActive}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                        activePinComponents={activePinComponents}
                        isStatic={
                          // Text components in detail mode need absolute positioning to prevent a text-selection bug
                          isActive && editMode.type === 'textEdit'
                        }
                        scale={scale}
                        isTextEditMode={isActive && editMode.type === 'textEdit' ? true : undefined}
                        setModeTextEdit={setModeTextEdit}
                        setModeCropping={setModeCroppingIfEnabled}
                        isMoveActionInProgress={isActive ? isMoveActionInProgress : undefined}
                        Renderer={
                          isLoading ? PinComponentLoadingSwitchRendererEditorMemo : PinComponentSwitchRendererEditorMemo
                        }
                      />
                    );
                  })}
              </PinSwitchEditor>
            </StyledPin>
            <PinEditorFAM mutatingPinComponents={mutatingPinComponents} scale={scale} />
            <ControlLayer
              scale={scale}
              pinComponents={pinComponents}
              active={
                mutatingPinComponents.length
                  ? mutatingPinComponents
                  : pinComponents.filter((pinComponent) => selectedPinComponentIds.includes(pinComponent.id))
              }
              selectionBox={selectionBox}
              visibleRails={visibleRails}
              onMouseDown={handleOnMouseDown}
              mutationInProgress={!!mutatingPinComponents.length}
              hoveredPinComponentId={hoveredPinComponentId}
              isMoveActionInProgress={isMoveActionInProgress}
            />
            {isDragging && <DropOverlay isDragging={isDragging} dropText={t('dropImageText')} />}
          </PinScaleWrapper>
        </StyledPinWrapper>
      </PinEventLayer>
      <PinEditorContextMenu clickedItem={clickedItem} startGenerateMaiaQuiz={startGenerateMaiaQuiz} />
    </PinEditorContextMenuContextProvider>
  );
};

const StyledPin = styled.div`
  width: ${pinSize.width}px;
  height: ${pinSize.height}px;
  outline: ${color.neutral.outline.background} solid 1px;
  transform-origin: left top;
  position: relative;
  overflow: hidden;
`;

const StyledPinWrapper = styled.div`
  position: relative;
  padding: ${spacing.size32} ${spacing.size16};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;
