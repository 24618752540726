import { InitializedPlayerStoreState, OpenQuestionPinPhases, OpenQuestionView } from '../../playerStore.types';
import { PlayerPinReducer } from '../reducers.types';
import { nextPhase } from '../reducers.utils';

export const openQuestionPinPhaseOrder: OpenQuestionPinPhases[] = ['input', 'results'];
export type OpenQuestionReducer = PlayerPinReducer<OpenQuestionView>;

export const openQuestionReducer: OpenQuestionReducer = {
  phaseAmount: openQuestionPinPhaseOrder.length,
  getPhaseNumber: (phase) => openQuestionPinPhaseOrder.indexOf(phase) + 1,
  initial: (state, history) => {
    return {
      type: 'OPEN_QUESTION',
      phase: 'input',
      answerOrder: computeAnswerOrder(state, history),
      revealedAnswers: history?.revealedAnswers ?? [],
    };
  },
  hasNextView: (state, currentView, direction) => {
    return nextPhase(currentView.phase, openQuestionPinPhaseOrder, direction) !== null;
  },
  handleNextView: (state, prev, direction) => {
    if (!state.currentView) {
      return false;
    }

    const next = nextPhase(prev.phase, openQuestionPinPhaseOrder, direction);
    if (next) {
      return {
        ...prev,
        type: 'OPEN_QUESTION',
        phase: next,
      };
    }
    return false;
  },
  onNextView: async (state, prev, next, services, setBackgroundActionAction) => {
    if (prev.phase === 'input') {
      const action = () => services.apiService.openQuestion.checkPinStudentAnswers(state.currentPinId);
      setBackgroundActionAction?.(action);
      return action();
    }
    return null;
  },
  onEntriesUpdate: (state, currentView) => {
    return {
      ...currentView,
      answerOrder: computeAnswerOrder(state, currentView),
    };
  },
};

function computeAnswerOrder(state: InitializedPlayerStoreState, previousView: OpenQuestionView | null) {
  const previousOrder = previousView?.answerOrder ?? [];

  if (!state.assignmentEntries.length) return previousOrder;

  return state.assignmentEntries.reduce<string[]>(
    (newOrder, assignmentEntry) => {
      if (
        assignmentEntry.entries[state.currentPinId] &&
        assignmentEntry.entries[state.currentPinId]?.type === 'OPEN_QUESTION' &&
        !previousOrder.includes(assignmentEntry._id)
      ) {
        newOrder.push(assignmentEntry._id);
      }

      return newOrder;
    },
    [...previousOrder]
  );
}
