import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLogin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 7.693C10.11 9.241 9.166 10.185 7.693 12 9.166 13.815 10.11 14.76 12 16.307m5.223-8.605q-.037-1.169-.094-2.309c-.069-1.378-1.23-2.51-2.749-2.618a60 60 0 0 0-4.252-.15c-1.452 0-2.873.051-4.253.15-1.518.108-2.68 1.24-2.748 2.618-.106 2.13-.162 4.34-.162 6.607s.056 4.477.162 6.607c.068 1.378 1.23 2.51 2.748 2.619 1.38.098 2.801.149 4.253.149s2.873-.051 4.252-.15c1.519-.108 2.68-1.24 2.749-2.618q.058-1.14.094-2.31M21.035 12H7.741"
    />
  </svg>
);
export default SvgIconLogin;
