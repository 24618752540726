import { color, rem, spacing, styled, TextElement } from '@lessonup/ui-components';
import { uniqueId } from 'lodash';
import React, { CSSProperties, forwardRef, useMemo } from 'react';

export interface SettingsToggleProps {
  type: 'radio' | 'checkbox';
  toggleVariant: 'caption' | 'icon-only';
  value?: string | number;
  defaultValue?: string | number;
  checked?: boolean;
  parentDefaultValue?: string | number;
  hideChecked?: boolean;
  caption?: string;
  ariaLabel?: string;
  icon?: React.JSX.Element;
  customIconStyling?: CSSProperties;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const SettingsToggle = forwardRef(function SettingsToggle(
  {
    type = 'radio',
    toggleVariant = 'caption',
    value,
    defaultValue,
    checked,
    parentDefaultValue,
    caption,
    ariaLabel,
    icon,
    customIconStyling,
    name,
    onChange,
  }: SettingsToggleProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const id = useMemo(() => uniqueId(), []);
  const isChecked = checked ? true : value ? parentDefaultValue === value : false;

  return (
    <SettingsToggleWrapper>
      <input
        ref={ref}
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        checked={isChecked}
      />
      <StyledLabelWrapper htmlFor={id} aria-label={ariaLabel} tabIndex={0} toggleVariant={toggleVariant}>
        {toggleVariant === 'caption' && !icon ? <StyledIconPreview style={customIconStyling} /> : icon}
        {toggleVariant === 'caption' && (
          <StyledText size="small" textStyle="caption" className="styledText">
            {caption}
          </StyledText>
        )}
      </StyledLabelWrapper>
    </SettingsToggleWrapper>
  );
});

const SettingsToggleWrapper = styled.div`
  cursor: pointer;
  flex: 1;
  input {
    display: none;
  }

  input:checked + label {
    background-color: ${color.accent.secondaryContainer.background};

    svg {
      color: ${color.accent.secondaryContainer.text};
    }

    &:hover {
      background-color: ${color.accent.secondaryContainer.hover};
    }

    & > .styledText {
      color: ${color.accent.secondaryContainer.text};
    }
  }
`;

const StyledText = styled(TextElement)`
  color: ${color.additional.disabled.text};
`;

// When there is no icon, we use this preview component
const StyledIconPreview = styled.div`
  background-color: ${color.neutralNew.level1.fill};
  border: 1px solid ${color.neutralNew.level1.stroke};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('12px')};
  height: ${rem('12px')};
  margin: ${spacing.size2};
`;

const StyledLabelWrapper = styled.label<{ toggleVariant: 'caption' | 'icon-only' }>`
  width: ${(props) => (props.toggleVariant === 'caption' ? undefined : rem('32px'))};
  height: ${(props) => (props.toggleVariant === 'caption' ? 'auto' : rem('32px'))};
  padding: ${(props) => (props.toggleVariant === 'caption' ? spacing.size8 : spacing.size8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing.size10};
  border-radius: 4px;
  background-color: ${color.neutralNew.level1.fill};
  position: relative;

  svg {
    color: ${color.neutralNew.level1.on};
    width: ${rem('16px')};
    height: ${rem('16px')};
  }

  :focus-visible {
    outline: 2px solid ${color.additional.focus.background};
  }

  &:hover {
    background-color: ${color.neutral.surface.hover};
  }
`;
