import {
  colorSets,
  IconUpload,
  SpaceBetween,
  spacing,
  styled,
  TextElement,
  UploadFileInput,
  useErrorContext,
} from '@lessonup/ui-components';
import { MimeTypes, MimeTypesHelper } from '@lessonup/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDragAndDrop } from '../../utils/dragAndDrop/useDragAndDrop';
import { DropOverlay } from '../ImageDropOverlay/DropOverlay';

export type IntroductionAlignment = 'start' | 'center';
export type IntroductionPriority = 'low' | 'high';

export interface DropUploadProps {
  acceptedMimeTypes: MimeTypes;
  isLoading: boolean;
  onChangeFileInput: (files: File[]) => Promise<void>;
  buttonText: string;
  dropImageText: string;
  belowDropText: string;
}

export function DropUpload({
  acceptedMimeTypes,
  isLoading,
  onChangeFileInput,
  buttonText,
  dropImageText,
  belowDropText,
}: DropUploadProps) {
  const { setError } = useErrorContext();
  const { t } = useTranslation('uploads');
  const mimeTypesHelper = useMemo(() => MimeTypesHelper.fromMimeTypes(acceptedMimeTypes), [acceptedMimeTypes]);

  const [isDragging, handlers] = useDragAndDrop({
    onDrop: (files) => {
      const acceptedFiles = files.filter((file) => mimeTypesHelper.isAllowed(file.type));
      if (acceptedFiles.length > 0) {
        onChangeFileInput(acceptedFiles);
      } else if (files.length > 0) {
        setError({
          error: new Error('unsupported content type'),
          customErrorDisplayMessage: {
            title: '',
            description: t('unsupportedFileType'),
          },
        });
      }
    },
  });

  const handleUpload = async (files: File[]) => {
    const acceptedFiles = files.filter((file) => mimeTypesHelper.isAllowed(file.type));
    if (acceptedFiles.length > 0) {
      await onChangeFileInput(acceptedFiles);
    } else if (files.length > 0) {
      setError({
        error: new Error('unsupported content type'),
        customErrorDisplayMessage: {
          title: '',
          description: t('unsupportedFileType'),
        },
      });
    }
  };

  return (
    <StyledDropUpload {...handlers}>
      <DropOverlay isDragging={isDragging} dropText={dropImageText}>
        <SpaceBetween direction="x" spacing={spacing.size4}>
          <TextElement textStyle="bodyText">{belowDropText}</TextElement>
          <UploadFileInput
            multiple
            dropArea
            loading={isLoading}
            onUpload={handleUpload}
            acceptedMimeTypes={acceptedMimeTypes}
            icon={<IconUpload />}
            buttonShape="rect"
            buttonSize="small"
            buttonType="neutral"
            buttonStroke
          >
            {buttonText}
          </UploadFileInput>
        </SpaceBetween>
      </DropOverlay>
    </StyledDropUpload>
  );
}

const StyledDropUpload = styled.div`
  position: relative;
  background-color: ${colorSets.neutral.fill};
  padding: ${spacing.size32};
  border-radius: ${spacing.size16};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; // get height of parent
`;
