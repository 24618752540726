import { useModal } from '@lessonup/ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useUnmount } from 'usehooks-ts';
import { modalStore } from '../modalStore';

/**
 * Will handle that the model will be hidden on second click
 * @param modal NiceModal instance
 * @param customModalShow Custom function to show the modal, use if you want to pass custom props
 * @returns Mouse event handlers
 */
export const useTogglePopoverModal = (
  modal: ReturnType<typeof useModal>,
  configuration?: {
    customModalShow?: () => void;
    onBeforeClose?: () => void;
  }
): [handleClick: React.MouseEventHandler, handleMouseDown: React.MouseEventHandler, isVisible: boolean] => {
  // NiceModel instance.visible does not work if in proxy
  // But we should fallback if we use the hook without proxy
  const isVisibleWithProxyCheck = useCallback(() => {
    const current = modalStore.getSnapshot();
    return current[modal.id]?.visible || modal.visible;
  }, [modal.id, modal.visible]);

  const [isVisible, setIsVisible] = useState(isVisibleWithProxyCheck());

  const { customModalShow, onBeforeClose } = configuration || {};

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(isVisibleWithProxyCheck());
    };

    const unsubscribe = modalStore.subscribe(handleVisibilityChange);
    return () => {
      unsubscribe();
    };
  }, [isVisibleWithProxyCheck]);

  useEffect(() => {
    setIsVisible(modal.visible);
  }, [modal.visible]);

  useUnmount(() => {
    if (isVisibleWithProxyCheck()) {
      onBeforeClose?.();
      modal.hide();
    }
  });

  const onClick: React.MouseEventHandler = () => {
    if (isVisibleWithProxyCheck()) {
      onBeforeClose?.();
      modal.hide();
    } else {
      if (customModalShow) {
        customModalShow();
      } else {
        modal.show();
      }
    }
  };

  // Prevents the event from bubbling up to the parent and triggering the click outside
  const onMouseDown: React.MouseEventHandler = (event) => {
    if (isVisibleWithProxyCheck()) {
      event?.stopPropagation();
    }
  };

  return [onClick, onMouseDown, isVisible];
};
