import { useLocale } from '@lessonup/client-integration';
import {
  BodyText,
  Button,
  color,
  IconRemove,
  Introduction,
  List,
  ListHeader,
  LoaderSpinner,
  SelectFormField,
  SpaceBetween,
  spacing,
  styled,
  UseFormRegister,
  ValidationMessage,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodyAsideLayout } from '../../../../../components/FullPageFlow/FullPageBodyAsideLayout';
import {
  FullPageBodySection,
  FullPageBodySectionTransparent,
} from '../../../../../components/FullPageFlow/FullPageBodySection';
import { FullPageBodyAside } from '../../../../../components/FullPageFlow/FullPageFlowAside';
import { MaiaExample } from '../../../../../types/graphql';
import { UploadFileForOCR } from '../../../dataComponents/UploadFileForOCR';
import { getLanguageOptions } from '../../../dataComponents/utils';
import { ContextUpload } from '../../../model';
import { MaiaExamples } from '../../MaiaExamples';
import { ContextFormInputs } from '../LessonPromptStep/ContextLessonPromptStep';

export interface UploadStepProps {
  contextUploads: ContextUpload[];
  setContextUploads: React.Dispatch<React.SetStateAction<ContextUpload[]>>;
  promptInputLength: number;
  promptCharacterLimit: number;
  promptMinCharacterLimit: number;
  register?: UseFormRegister<ContextFormInputs>;
  maiaExamples?: MaiaExample[];
  onExamplesClick?: (examplePrompt: string) => void;
  selectedUploadExample?: string;
}

export const UploadStep = ({
  contextUploads,
  setContextUploads,
  register,
  promptInputLength,
  promptCharacterLimit,
  promptMinCharacterLimit,
  maiaExamples,
  onExamplesClick,
  selectedUploadExample,
}: UploadStepProps) => {
  const { t } = useTranslation('chatGPT');
  const language = useLocale().language;
  const languageOptions = getLanguageOptions(t);

  const removeUploadFromList = (id: string) => {
    setContextUploads((uploads) => uploads.filter((upload) => upload.id !== id));
  };

  return (
    <div>
      <FullPageBodySectionTransparent>
        <Introduction
          alignment="center"
          size="M"
          headline={t('uploadStep.title')}
          bodyText={t('uploadStep.subTitleText')}
        />
      </FullPageBodySectionTransparent>

      <FullPageBodyAsideLayout mainLabel={t('contextStep.mainLabel')} asideLabel={t('optionalSettings')}>
        <FullWidth>
          <SpaceBetween direction="y" spacing={spacing.size16}>
            <UploadFileForOCR setContextUploads={setContextUploads} />
            <FullPageBodySection>
              {contextUploads.length > 0 && (
                <FullWidth>
                  <StyledUploadWrapper direction="y" spacing={spacing.size16} padding={spacing.size4}>
                    <ListHeader text={t('uploadStep.imagesTitle')} priority="high" indentation={false} />
                    <StyledList
                      items={contextUploads.map((upload) => {
                        return {
                          icon: upload.isLoading ? <LoaderSpinner /> : undefined,
                          text: upload.fileName,
                          indentation: false,
                          endSlot: (
                            <Button
                              buttonType="negative"
                              size="small"
                              aria-label={t('uploadStep.btnDelete')}
                              onClick={() => removeUploadFromList(upload.id)}
                              iconStart={<IconRemove />}
                            />
                          ),
                        };
                      })}
                    />
                    <ValidationMessage
                      id="upload-step-validation"
                      validation={{
                        message:
                          promptInputLength < promptMinCharacterLimit && promptInputLength > 0
                            ? t('promptCharactersMinWarning', { promptInputLength, promptMinCharacterLimit })
                            : t('promptCharactersWarning', {
                                promptInputLength,
                                promptCharacterLimit,
                              }),
                        state:
                          (promptInputLength > 0 && promptInputLength < promptMinCharacterLimit) ||
                          promptInputLength > promptCharacterLimit
                            ? 'error'
                            : 'neutral',
                      }}
                    ></ValidationMessage>
                  </StyledUploadWrapper>
                </FullWidth>
              )}
              {!contextUploads.length && maiaExamples && onExamplesClick && (
                <FullWidth>
                  <MaiaExamples
                    exampleType="contextLesson"
                    maiaExamples={maiaExamples}
                    onExamplesClick={onExamplesClick}
                    selectedUploadExample={selectedUploadExample}
                  />
                </FullWidth>
              )}
            </FullPageBodySection>
          </SpaceBetween>
        </FullWidth>
        <FullPageBodyAside>
          {register && (
            <StyledPickerWrapper>
              <SpaceBetween direction="y" spacing={spacing.size8}>
                <div>
                  <BodyText size="medium" weight="bold">
                    {t('languageOption')}
                  </BodyText>
                  <StyledSubText size="small">{t('languageOptionDescription')}</StyledSubText>
                </div>
                <SelectFormField
                  orientation="vertical"
                  hideLabel
                  label={t('languageOption')}
                  {...register('language')}
                  options={languageOptions}
                  defaultValue={language.toUpperCase()}
                />
              </SpaceBetween>
            </StyledPickerWrapper>
          )}
        </FullPageBodyAside>
      </FullPageBodyAsideLayout>
    </div>
  );
};

const StyledUploadWrapper = styled(SpaceBetween)`
  width: 100%;
  height: 100%;
  ul {
    flex-grow: 1;
  }
`;

const StyledList = styled(List)`
  width: 100%;
  word-break: break-all;
`;

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;

const StyledPickerWrapper = styled.div`
  padding: ${spacing.size8} 0;
`;

const FullWidth = styled.div`
  width: 100%;
`;
