import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3.09 6.307 7.053 5.56a3 3 0 0 0 3.714 0l7.054-5.56M3.047 17.305a3.14 3.14 0 0 0 2.78 2.722c1.992.208 4.057.428 6.173.428s4.181-.22 6.174-.428a3.14 3.14 0 0 0 2.779-2.722c.213-1.715.422-3.49.422-5.305 0-1.816-.21-3.59-.422-5.305a3.14 3.14 0 0 0-2.78-2.722c-1.992-.209-4.057-.428-6.173-.428s-4.181.22-6.174.428a3.14 3.14 0 0 0-2.779 2.722c-.212 1.715-.422 3.49-.422 5.305 0 1.816.21 3.59.422 5.305"
    />
  </svg>
);
export default SvgIconEmail;
