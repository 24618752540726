import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';
import { EduSystemBase } from './EduSystemBase';
import {
  eduSystemEditDataDuration,
  eduSystemEditDataSchoolType,
  eduSystemEditDataSubject,
  eduSystemEditDataYear,
} from './eduSystemShared';

const i18n = globalI18nProxy();

type SchoolType = 'us_kin' | 'us_es' | 'us_ms' | 'us_hs' | 'us_col';
export type EduSystemUSSchoolType = SchoolType;
export class EduSystemUS extends EduSystemBase<SchoolType> {
  public key = 'US';
  public country() {
    return 'us' as const;
  }
  public constructor() {
    super('us_hs');
  }
  public EDIT_DATA() {
    return [
      eduSystemEditDataDuration(() => i18n.__('Lesduur')),
      eduSystemEditDataSchoolType(() => i18n.__('School')),
      eduSystemEditDataYear(() => i18n.__('Leeftijd'), 'age'),
      eduSystemEditDataYear(() => i18n.__('Grade'), 'grade'),
      eduSystemEditDataYear(() => i18n.__('StudieJaar'), 'studyYear'),
      eduSystemEditDataSubject(() => i18n.__('Vak'), ['subjects', 'USSubjects']),
    ];
  }
  protected _SYSTEM_DATA() {
    const dict: EduSystem.SystemDataDict<SchoolType> = {
      us_kin: {
        id: 'us_kin',
        short: 'KIN',
        label: 'Kindergarten',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'age',
            type: 'toggle',
            label: () => i18n.__('Age'),
            labeler: 'LabelValue',
            options: [
              { id: 3, value: '3' },
              { id: 4, value: '4' },
              { id: 5, value: '5' },
            ],
          },
        ],
      },
      us_es: {
        id: 'us_es',
        short: 'ES',
        label: 'Elementary school',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            type: 'toggle',
            reference: 'grade',
            label: () => i18n.__('Grade'),
            labeler: 'ValueLabel',
            options: [
              { id: 6, value: '1st' },
              { id: 7, value: '2nd' },
              { id: 8, value: '3rd' },
              { id: 9, value: '4th ' },
              { id: 10, value: '5th' },
              { id: 11, value: '6th' },
            ],
          },
        ],
      },

      us_ms: {
        id: 'us_ms',
        short: 'MS',
        label: 'Middle School',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Grade'),
            labeler: 'ValueLabel',
            options: [
              { id: 11, value: '6th' },
              { id: 12, value: '7th' },
              { id: 13, value: '8th' },
              { id: 14, value: '9th' },
            ],
          },
        ],
      },

      us_hs: {
        id: 'us_hs',
        short: 'HS',
        label: 'High School',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Grade'),
            labeler: 'ValueLabel',
            options: [
              { id: 14, value: '9th' },
              { id: 15, value: '10th' },
              { id: 16, value: '11th' },
              { id: 17, value: '12th' },
            ],
          },
        ],
      },

      us_col: {
        id: 'us_col',
        short: 'COL',
        label: 'College/University',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'studyYear',
            type: 'toggle',
            label: () => i18n.__('Year'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
            ],
          },
        ],
      },
    };
    return dict;
  }

  // TODO: fill
  public labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
    const { subjects, schoolType } = metaData;
    const tags: EduSystem.MetaDataTags = {
      subjects: [],
      schoolType: [],
      levels: [],
      years: [],
    };
    tags.subjects = subjects;
    if (!schoolType) return tags;
    tags.schoolType = _.compact(schoolType.map((st) => this._SYSTEM_DATA[st] && this.SYSTEM_DATA_DICT[st].label));
    const { hasUSKin, hasUSEs, hasUSMs, hasUSHs, hasUSCol } = hasEduSystem(metaData);

    if (hasUSKin) {
      const value = this.getFieldSummary({ schoolType: 'us_kin', lesson: metaData, fieldName: 'age' });
      if (value) {
        tags.years.push(`Age ${value}`);
      }
    }

    if (hasUSEs) {
      const value = this.getFieldSummary({ schoolType: 'us_es', lesson: metaData, fieldName: 'grade' });
      if (value) {
        tags.years.push(`${value} Grade`);
      }
    }

    if (hasUSMs) {
      const value = this.getFieldSummary({ schoolType: 'us_ms', lesson: metaData, fieldName: 'grade' });
      if (value) {
        tags.years.push(`${value} Grade`);
      }
    }

    if (hasUSHs) {
      const valueGrade = this.getFieldSummary({ schoolType: 'us_hs', lesson: metaData, fieldName: 'grade' });
      if (valueGrade) {
        tags.years.push(`${valueGrade} Grade`);
      }
    }

    if (hasUSCol) {
      const value = this.getFieldSummary({ schoolType: 'us_col', lesson: metaData, fieldName: 'studyYear' });
      if (value) {
        tags.years.push(`Year ${value}`);
      }
    }
    return tags;
  }
}

function hasEduSystem(metaData: EduSystem.MetaDataParams) {
  return {
    hasUSKin: !!metaData.schoolType && _.includes(metaData.schoolType, 'us_kin'),
    hasUSEs: !!metaData.schoolType && _.includes(metaData.schoolType, 'us_es'),
    hasUSMs: !!metaData.schoolType && _.includes(metaData.schoolType, 'us_ms'),
    hasUSHs: !!metaData.schoolType && _.includes(metaData.schoolType, 'us_hs'),
    hasUSCol: !!metaData.schoolType && _.includes(metaData.schoolType, 'us_col'),
  };
}
