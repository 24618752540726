import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.63 2.625v3.809m6.73-3.81v3.81M3.28 9.75h17.53M3.139 17.287c.242 2.16 1.997 3.874 4.167 3.978 1.5.072 3.032.11 4.695.11s3.195-.038 4.695-.11c2.17-.104 3.925-1.819 4.167-3.978.163-1.454.297-2.944.297-4.463 0-1.518-.134-3.009-.297-4.463-.242-2.16-1.997-3.873-4.167-3.978-1.5-.072-3.032-.11-4.695-.11s-3.195.038-4.695.11c-2.17.105-3.925 1.819-4.167 3.978-.163 1.454-.297 2.945-.297 4.463s.134 3.01.297 4.463"
    />
  </svg>
);
export default SvgIconCalendar;
