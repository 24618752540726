import {
  KeybindControllerProvider,
  LessonSource,
  Pin,
  TeacherPin,
  UpdatePinContextProvider,
  useUpdatePinWithMetadataDispatch,
} from '@lessonup/pin-renderer';
import { asError } from '@lessonup/utils';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { editorContextSidebarStore } from '../../components/EditorContextSidebar/store/editorContextSidebarStore';
import { useClipboardErrorHandler } from '../../hooks/useClipboardErrorHandler';
import { pastePinsOrPinComponentsFromClipboard } from '../../hooks/useCopyPaste.utils';
import { UploadDispatch } from '../../utils/yjs/EditorUploadsYjs/EditorUploadsYjs.types';
import { useEditorUploads } from '../../utils/yjs/EditorUploadsYjs/useEditorUploads';
import { useEditorUploadsYjsReducer } from '../../utils/yjs/EditorUploadsYjs/useEditorUploadsYjsReducer';
import { teacherTextEditorStore } from '../../utils/yjs/EditorYjs/teacherTextEditorStore';
import { useEditorYjsReducer } from '../../utils/yjs/EditorYjs/useEditorYjsReducer';
import {
  DeletePins,
  NavigatePins,
  PastePins,
  ResetSelection,
  SetActivePinId,
  SetModeCropping,
  SetModeSelecting,
  SetModeTextEdit,
  SetSelectedPinIds,
  SetSubSelectionValue,
} from './EditorContext.types';

import { useSetInitialState } from './hooks/useEditorState';
import { editorStateStore } from './store/editorStateStore';

export interface EditorContextProps {
  lessonId: string;
  setActivePinId: SetActivePinId;
  deletePins: DeletePins;
  navigatePins: NavigatePins;
  setSelectedPinIds: SetSelectedPinIds;
  setModeSelecting: SetModeSelecting;
  setModeTextEdit: SetModeTextEdit;
  setModeCropping: SetModeCropping;
  setSubSelectionValue: SetSubSelectionValue;
  resetSelection: ResetSelection;
  toggleSidebar: () => void;
  pastePins: PastePins;
  uploadDispatch: UploadDispatch;
}

export const EditorContext = createContext<EditorContextProps | undefined>(undefined);

export const useEditorContext = (): EditorContextProps => {
  const ctx = useContext(EditorContext);
  if (!ctx) {
    throw new Error('EditorContext not found');
  }
  return ctx;
};

export interface EditorContextProviderProps {
  initialPins: TeacherPin[];
  activePinId: string | null;
  lessonId: string;
  lessonName?: string;
  lessonSource?: LessonSource;
  setActivePinId: SetActivePinId;
  children: React.ReactNode;
}

export const EditorContextProvider: React.FC<EditorContextProviderProps> = ({
  initialPins,
  activePinId,
  lessonId,
  setActivePinId,
  children,
  lessonName,
  lessonSource,
}) => {
  const [pins, topLevelDispatch, flushPendingDispatch] = useEditorYjsReducer(initialPins, lessonName);
  const { handleClipboardError } = useClipboardErrorHandler();

  const dispatch = useUpdatePinWithMetadataDispatch(topLevelDispatch, activePinId);

  useSetInitialState(activePinId, pins);

  const { uploadDispatch } = useEditorUploadsYjsReducer();

  useEditorUploads(topLevelDispatch);

  useEffect(() => {
    editorStateStore.setLessonMeta({ source: lessonSource || null });
  }, [lessonSource]);

  useEffect(() => {
    teacherTextEditorStore.clear();
  }, []);

  useEffect(() => {
    editorStateStore.setPins(pins);
  }, [pins]);

  useEffect(() => {
    editorStateStore.setActivePinId(activePinId);
  }, [activePinId]);

  useEffect(() => {
    const handleActivePinIdChange = () => {
      const newActivePinId = editorStateStore.getActivePinIdSnapshot();
      if (newActivePinId !== activePinId) {
        setActivePinId(newActivePinId);
      }
    };

    const unsubscribe = editorStateStore.subscribeForType('activePinId')(handleActivePinIdChange);
    handleActivePinIdChange();

    return () => {
      unsubscribe();
    };
  }, [activePinId, setActivePinId]);

  const deletePins = useCallback(
    (pinIds: string[], onComplete?: (deletedPins: Pin[]) => void) => {
      dispatch({
        type: 'deletePins',
        pinIds,
        onComplete: (deletedPins) => {
          onComplete?.(deletedPins);
        },
      });
    },
    [dispatch]
  );

  const pastePins = useCallback(
    async (afterPinId: string | undefined) => {
      try {
        await pastePinsOrPinComponentsFromClipboard({
          dispatch,
          afterPinId,
          lessonId,
          afterPaste: (pastedPinIds) => {
            if (!pastedPinIds.length) {
              return;
            }
            const newActivePinId = pastedPinIds[pastedPinIds.length - 1];
            setActivePinId(newActivePinId);
          },
        });
      } catch (e) {
        handleClipboardError(asError(e));
      }
    },
    [dispatch, setActivePinId, lessonId, handleClipboardError]
  );

  const contextValue: EditorContextProps = useMemo(() => {
    return {
      lessonId,
      setActivePinId,
      deletePins,
      navigatePins: editorStateStore.navigatePins,
      setSelectedPinIds: editorStateStore.setSelectedPinIds,
      setModeSelecting: editorStateStore.setModeSelecting,
      setModeTextEdit: editorStateStore.setModeTextEdit,
      setModeCropping: editorStateStore.setModeCropping,
      resetSelection: editorStateStore.resetSelection,
      setSubSelectionValue: editorStateStore.setSubSelectionValue,
      toggleSidebar: () => editorContextSidebarStore.toggle('settings'),
      pastePins,
      uploadDispatch,
    };
  }, [lessonId, setActivePinId, deletePins, pastePins, uploadDispatch]);

  return (
    <KeybindControllerProvider>
      <EditorContext.Provider value={contextValue}>
        <UpdatePinContextProvider value={{ dispatch, flushPendingDispatch }}>{children}</UpdatePinContextProvider>
      </EditorContext.Provider>
    </KeybindControllerProvider>
  );
};
