import { useUpdatingProxyNiceModal } from '@lessonup/client-integration';
import { FontLoader } from '@lessonup/pin-renderer';
import {
  useCurrentPin,
  useCurrentStudents,
  useIsPlayerLoading,
  useNextPin,
  useNonNullAssignment,
  usePlayerDispatcher,
  usePlayerStore,
} from '@lessonup/players-modern';
import { color, colorSets, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { PlayerBarBottomPlaceholder } from '../../../components/PlayerBar/PlayerBarBottom/PlayerBarBottom';
import { usePlayerTranslation } from '../hooks/usePlayerTranslation';
import { PinViewerRealtimePlayer } from '../RealtimePlayer/dataComponents/PinViewerRealtimePlayer/PinViewerRealtimePlayer';
import { StudentJoinScreenModal } from '../RealtimePlayer/dataComponents/playerScreens/StudentJoinScreen/StudentJoinScreenModal';
import { useRealtimePlayerKeyboardEvents } from '../RealtimePlayer/hooks/useRealtimePlayerKeyboardEvents';
import { createHandleAllowNewPlayers, createHandleRemoveStudent } from '../RealtimePlayer/utils/playerDispatcher.utils';
import { NextPinView } from './components/NextPinView';
import { PresenterNote } from './components/PresenterNote';
import { PresenterPlayerBottomHandler } from './components/PresenterPlayerBarBottomHandler';

export interface PresenterPlayerProps {
  onClose: () => void;
}

export const InitializedPresenterPlayer: React.FC<PresenterPlayerProps> = ({ onClose }) => {
  const { t } = usePlayerTranslation();
  const assignment = useNonNullAssignment();
  const currentPin = useCurrentPin();
  const nextPin = useNextPin();
  const currentStudents = useCurrentStudents();
  const { setPlayerNavigationLock } = usePlayerStore();
  const playerDispatcher = usePlayerDispatcher();

  useRealtimePlayerKeyboardEvents(onClose);

  const handleAllowNewPlayers = createHandleAllowNewPlayers(playerDispatcher);
  const handleRemoveStudent = createHandleRemoveStudent(playerDispatcher);

  const allowNewPlayers = assignment.settings.status === 'open' && assignment.settings.playerSettings.allowNewPlayers; // Is this correct?
  const pincode = assignment.pincode ?? '';

  const studentModal = useUpdatingProxyNiceModal(
    StudentJoinScreenModal,
    {
      joinedStudents: currentStudents,
      pincode,
      allowNewPlayers,
      handleAllowNewPlayers,
      handleRemoveStudent,
      onClose: () => {
        setPlayerNavigationLock(false);
      },
      t,
    },
    [currentStudents, allowNewPlayers]
  );

  const handleShowJoinScreenModal = () => {
    setPlayerNavigationLock(true);
    studentModal.show();
  };

  return (
    <StyledPresenterPlayerWrapper direction="x" spacing={spacing.size0}>
      <StyledMainContent direction="y" spacing={spacing.size16} alignItems="center" justifyContent="space-between">
        {currentPin && (
          <SpaceBetween
            direction="y"
            spacing={spacing.size24}
            alignItems="center"
            justifyContent="flex-start"
            style={{ flex: 1 }}
          >
            <StyledPinViewerWrapper>
              <FontLoader />
              <PinViewerRealtimePlayer pin={currentPin} />
            </StyledPinViewerWrapper>
          </SpaceBetween>
        )}
        <PresenterPlayerBottomHandler showLabels={true} handleShowJoinScreenModal={handleShowJoinScreenModal} />
      </StyledMainContent>
      <StyledSideContent direction="y" spacing={spacing.size16} padding={spacing.size16} justifyContent="space-between">
        <NextPinView nextPin={nextPin} />
        <PresenterNote noteContent={currentPin?.teacherSettings.notes} />
      </StyledSideContent>
    </StyledPresenterPlayerWrapper>
  );
};

export const PresenterPlayer: React.FC<PresenterPlayerProps> = ({ onClose }) => {
  const loading = useIsPlayerLoading();

  if (loading) {
    return (
      <StyledPresenterPlayerWrapper direction="x" spacing={spacing.size32}>
        <PlayerBarBottomPlaceholder minimized={true} mirrored={false} />
      </StyledPresenterPlayerWrapper>
    );
  }

  return <InitializedPresenterPlayer onClose={onClose} />;
};

const StyledPresenterPlayerWrapper = styled(SpaceBetween)`
  height: 100vh;
  width: 100%;
`;

const StyledMainContent = styled(SpaceBetween)`
  padding: ${spacing.size8};
  background: ${color.primitives.black};
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  > * {
    width: 100%;
  }
`;

const StyledSideContent = styled(SpaceBetween)`
  background: ${colorSets.neutral.fillSubjacent};
  flex: 0 0;
  > * {
    width: 100%;
  }
`;

const StyledPinViewerWrapper = styled.div`
  width: 100%;
  flex: 80;
  min-width: 0;
  min-height: 0;
`;
