import _ from 'lodash';
import { allPinTypes, Pin, pinInteractiveTypes, PinType } from './Pin';

export class PinUtils {
  public static isPinType = (string: string | undefined): string is PinType =>
    !!string && allPinTypes.includes(string as any);

  public static isInteractive = (pin: Pin | undefined) =>
    !_.isNil(pin) && _.some(pinInteractiveTypes, (t) => t == pin.item.type);

  public static typeIsInteractive = (pinType: PinType | undefined) =>
    !!pinType && pinInteractiveTypes.includes(pinType);
  /** force casts the pin to the specified type. only use this if you are sure the pin type can't be anything else */
  public static contentOfType<T>(pin: Pin): T {
    return pin.item.custom as T;
  }

  public static hasPinsInPhases(pins: Pin[]) {
    return pins.some((pin) => (pin.order && pin.order > 1000) || false);
  }

  // @phaseNr is 1 based
  public static hasPinsForPhase(pins: Pin[], phaseNr: number) {
    const min = phaseNr * 1000;
    const max = (phaseNr + 1) * 1000;

    return pins.some((pin) => _.inRange(pin.order || 0, min, max));
  }
}
