import { CookieSettings } from '../../cookies/analytic-cookies';
import { TrackerDestination } from './TrackerDestination';

export class ClarityDestination implements TrackerDestination {
  private isConfigLoaded: boolean = false;
  private isPaused = false;
  /** same for everything */
  private projectId: string = 'qf902rbwva';

  private get clarity(): Function | undefined {
    return (window as any).clarity;
  }

  private loadConfig() {
    if (this.isConfigLoaded) return;

    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.id = 'clarity-script-loader';
    el.innerHTML = `
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${this.projectId}");
    `;

    document.head.appendChild(el);

    this.isConfigLoaded = true;
  }

  init(cookieSettings: CookieSettings | undefined) {
    // we only want to load clarity if the user has given consent
    if (cookieSettings?.analytic === true) {
      this.loadConfig();
      this.clarity?.('consent', true);
    }
  }

  pause(paused: boolean) {
    this.isPaused = paused;
  }

  trackPageView() {
    // not needed
  }

  logEvent(event: string, data: object) {
    // not needed
  }

  trackError(message: string, error: Error | undefined) {
    // Clarity doesn't support error tracking
  }

  setUserId(userId: string | undefined | null) {
    if (userId) {
      this.clarity?.('identify', userId);
    } else {
      // doens't suppport unregistering the user
    }
  }

  logout() {
    // no support
  }

  updateConsent(cookieSettings: CookieSettings) {
    if (cookieSettings.analytic === true) {
      // Load config if it wasn't loaded before due to missing cookies
      this.loadConfig();
      this.clarity?.('consent', true);
    } else {
      this.clarity?.('consent', false);
    }
  }
}
