import React from 'react';
import { WordCloudPinComponent, WordCloudPinProps } from '../../../../pins/WordCloudPin/WordCloudPin';

export const WordCloudPinStatic: React.FC<WordCloudPinProps> = (props) => {
  return (
    <WordCloudPinComponent pinSettings={props.pinSettings} isThumb={props.isThumb}>
      {props.children}
    </WordCloudPinComponent>
  );
};
