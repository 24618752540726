import { AssetLogoRocket, cssColors, styled } from '@lessonup/ui-components';
import { QRCodeSVG } from 'qrcode.react';
import React, { memo } from 'react';

export interface QRCodeProps {
  value: string;
  size?: number;
  bgColor?: string;
  fgColor?: string;
}

export const QRCode: React.FC<QRCodeProps> = memo(
  ({
    value,
    size = 256,
    bgColor = undefined,
    fgColor = cssColors.primary.text?.dark ?? undefined,
    ...rest
  }: QRCodeProps) => {
    // The actual image is set in the CenteredLogo children since src requires a (data)URL which is not available here
    // The reason why this is used instead of background + padding is to ensure that we properly "excavate" the logo
    const imageSettings = {
      src: '',
      width: Math.floor(size / 6),
      height: Math.floor(size / 6),
      excavate: true,
    };

    // Margin is required as per the QR Code spec
    return (
      <CenteredContainer fgColor={fgColor} {...rest}>
        <QRCodeSVG
          value={value}
          size={size}
          bgColor={bgColor}
          fgColor={fgColor}
          level="H"
          includeMargin={true}
          imageSettings={imageSettings}
        />
        <CenteredLogo>
          <AssetLogoRocket width={imageSettings.width} height={imageSettings.height} />
        </CenteredLogo>
      </CenteredContainer>
    );
  }
);

QRCode.displayName = 'QRCode';

const CenteredContainer = styled.div<Pick<QRCodeProps, 'fgColor'>>`
  position: relative;
  display: inline-block;
  color: ${({ fgColor }) => fgColor};
`;

const CenteredLogo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
