import { CookieSettings } from '../../cookies/analytic-cookies';
import { TrackerDestination } from './TrackerDestination';

interface UETQ {
  push(type: 'event', eventName: string, eventProperties: {}): void;
}

interface Settings {
  enabled: boolean;
}

export class MicrosoftDestination implements TrackerDestination {
  private isConfigLoaded: boolean = false;
  private readonly msAdsId = '187126753'; // same for all environments

  public constructor(private readonly settings: Settings) {}

  private get uetq(): UETQ | undefined {
    return (globalThis as any).uetq;
  }

  private loadConfig(): void {
    if (!this.settings.enabled || this.isConfigLoaded) return;

    // we load the script like this instead of pasting the script tag in the html
    // because we only want to load this after the cookie settings have been loaded
    // also we changed this script to load async to prevent blocking the page load
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.defer = true;
    el.innerHTML = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${this.msAdsId}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`;

    document.body.appendChild(el);

    this.isConfigLoaded = true;
  }

  public init(cookieSettings: CookieSettings | undefined): void {
    if (cookieSettings?.marketing === true) {
      this.loadConfig();
    }
  }

  public pause(paused: boolean): void {}

  public updateConsent(cookieSettings: CookieSettings): void {
    // if the config wasn't loaded before because we were missing cookies, then we load it when the user gives consent
    // note that consent must be set before loading the config
    if (cookieSettings.marketing === true) {
      this.loadConfig();
    }
  }

  public trackPageView(): void {
    // not needed, will happen automatically
  }

  public trackError(message: string, error: Error | undefined): void {
    // noop
  }

  public setUserId(userId: string | null | undefined): void {
    // noop
  }

  public logout(): void {
    // noop
  }

  public logEvent(eventName: string, eventProperties: Record<string, any>): void {
    if (!this.settings.enabled || !this.uetq) return;
    // https://help.ads.microsoft.com/#apex/3/en/56684/0
    this.uetq.push('event', eventName, {});
  }
}
