import * as Types from '../../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReferralQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReferralQuery = { __typename?: 'Query', activeRefereeTrialGift?: { __typename?: 'TrialGift', amountOfDays: number } | null, viewer: { __typename?: 'Viewer', id: string, referral: { __typename?: 'Referral', token: any, referredUsers: { __typename?: 'ReferralToReferredUsersConnection', totalCount: number } } } };


export const ReferralDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Referral"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeRefereeTrialGift"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amountOfDays"}}]}},{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"referral"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"referredUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalCount"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ReferralQuery, ReferralQueryVariables>;