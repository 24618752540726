import { css } from '@emotion/react';
import { rem } from 'polished';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { borderWidth } from '../../foundations/borders/borderWidth';
import { color } from '../../foundations/colors/colors';
import { elevationShadow } from '../../foundations/elevation/elevation';
import { spacing } from '../../foundations/spacing/spacing';

export const INTERCOM_BUTTON_RECT_IN_PX = {
  bottom: 20,
  right: 20,
  width: 48,
  height: 48,
};

const sharedStyles = css`
  position: fixed;
  bottom: ${rem(INTERCOM_BUTTON_RECT_IN_PX.bottom + 'px')};
  right: ${rem(
    INTERCOM_BUTTON_RECT_IN_PX.right +
      INTERCOM_BUTTON_RECT_IN_PX.width +
      parseInt(spacing.size16.replace('px', '')) +
      'px'
  )};
  width: ${rem('320px')};
`;

export const overlayStyles = css`
  ${sharedStyles}
`;

export const wrapperStyles = css`
  ${sharedStyles}
  box-shadow: ${elevationShadow.e200};
  border: ${borderWidth.width1} solid ${color.neutralNew.level1.stroke};
  border-radius: ${borderRadius.rounded8};
  overflow: hidden;
`;
