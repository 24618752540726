import { logger, useDocumentMutation } from '@lessonup/client-integration';
import {
  Banner,
  Button,
  FormFieldValidation,
  Headline,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  Switch,
  useErrorContext,
  useForm,
} from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryPicker } from '../../../../../components/CountryPicker/CountryPicker';
import { UpdateAgreementProposalDetailsInput } from '../../../../../types/graphql';
import { UpdateAgreementProposalDocument } from '../../AgreementPage.graphql.generated';
import { ContactFormData } from '../../sharedTypes';

interface ContactDetailsFormProps {
  agreementId: string;
  secret: string;
  proposalId: string;
  contactFormData: ContactFormData;
  onSave?: () => void;
}

export type FormInputs = {
  organizationName: string;
  address: string;
  schoolcode?: string;
  cOfCNumber?: string;
  zipcode: string;
  city: string;
  country: string;
  name: string;
  position: string;
  email: string;
  privacyContactName?: string;
  privacyContactPosition?: string;
  privacyContactEmail?: string;
};

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  agreementId,
  proposalId,
  contactFormData,
  onSave,
  secret,
}) => {
  const { setError } = useErrorContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm<FormInputs>();

  const { t } = useTranslation('agreementsPage');

  const [organizationIsSchool, setOrganizationIsSchool] = useState<boolean>(() => !!contactFormData.schoolcode);
  const [useNewPrivacyContact, setUseNewPrivacyContact] = useState<boolean>(
    hasNewPrivacyContact(contactFormData) && !newPrivacyContactIsSameAsBoardMember(contactFormData)
  );
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);

  const [updateAgreementProposal, { loading: updateAgreementIsLoading }] = useDocumentMutation(
    UpdateAgreementProposalDocument
  );

  const handleSubmitForm = async (data: FormInputs) => {
    const details: UpdateAgreementProposalDetailsInput = {
      contact: {
        address: data.address,
        city: data.city,
        country: data.country,
        zipcode: data.zipcode,
      },
      name: data.organizationName,
    };

    if (organizationIsSchool) {
      details.contact.brin = data.schoolcode;
    } else {
      details.contact.kvk = data.cOfCNumber;
    }

    const privacyContact =
      useNewPrivacyContact && (data.privacyContactName || data.privacyContactPosition || data.privacyContactEmail)
        ? {
            name: data.privacyContactName || '',
            position: data.privacyContactPosition || '',
            email: data.privacyContactEmail || '',
          }
        : {
            name: contactFormData.name || '',
            position: contactFormData.position || '',
            email: contactFormData.email || '',
          };

    const variables = {
      agreementId,
      proposalId,
      details,
      secret,
      privacyContact,
    };
    try {
      await updateAgreementProposal({ variables });
      setSaveSuccessful(true);
      if (onSave) onSave();
    } catch (error) {
      const finalError =
        error instanceof Error
          ? error
          : new AppError('unexpected-data', 'Unable to update agreement proposal', {
              error,
              agreementId,
              proposalId,
            });

      logger.error(finalError);
      setError({ error: finalError });
    }
  };

  const validateGeneric: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  const onUseNewPrivacyContactChange = useCallback(() => {
    setUseNewPrivacyContact(!useNewPrivacyContact);
  }, [useNewPrivacyContact]);

  return (
    <SpaceBetween direction="y" spacing={spacing.size24}>
      <Headline as="h3" size="medium">
        {t('contactFormTitle')}
      </Headline>
      <FormWrapper>
        <SpaceBetween as="form" direction="y" spacing={spacing.size24} onSubmit={handleSubmit(handleSubmitForm)}>
          <SpaceBetween direction="y" spacing={spacing.size12}>
            <Switch
              defaultChecked={organizationIsSchool}
              onChange={(e) => setOrganizationIsSchool(e.target.checked)}
              label={t('isSchool')}
              id="is-school"
              data-not-legacy
            />
            <InputFormField
              label={t(organizationIsSchool ? 'schoolName' : 'organizationName')}
              type="text"
              {...register('organizationName', {
                required: {
                  value: true,
                  message: t(organizationIsSchool ? 'requiredSchoolName' : 'requiredOrganizationName'),
                },
                value: contactFormData.organizationName,
                onBlur(event) {
                  setValue('organizationName', event.target.value.trim());
                },
                onChange() {
                  clearErrors('organizationName');
                },
              })}
              validation={validateGeneric('organizationName')}
            />
            {organizationIsSchool ? (
              <InputFormField
                label={t('schoolcode')}
                type="text"
                {...register('schoolcode', {
                  required: { value: true, message: t('requiredSchoolCode') },
                  value: contactFormData.schoolcode,
                  onBlur(event) {
                    setValue('schoolcode', event.target.value.trim());
                  },
                  onChange() {
                    clearErrors('schoolcode');
                  },
                })}
                validation={validateGeneric('schoolcode')}
              />
            ) : (
              <InputFormField
                label={t('cOfCNumber')}
                type="text"
                {...register('cOfCNumber', {
                  required: { value: true, message: t('requiredCOfCNumber') },
                  value: contactFormData.cOfCNumber,
                  onBlur(event) {
                    setValue('cOfCNumber', event.target.value.trim());
                  },
                  onChange() {
                    clearErrors('cOfCNumber');
                  },
                })}
                validation={validateGeneric('cOfCNumber')}
              />
            )}
            <InputFormField
              label={t('address')}
              type="text"
              {...register('address', {
                required: { value: true, message: t('requiredAddress') },
                value: contactFormData.address,
                onBlur(event) {
                  setValue('address', event.target.value.trim());
                },
                onChange() {
                  clearErrors('address');
                },
              })}
              validation={validateGeneric('address')}
            />
            <InputFormField
              label={t('zipcode')}
              type="text"
              {...register('zipcode', {
                required: { value: true, message: t('requiredZipcode') },
                value: contactFormData.zipcode,
                onBlur(event) {
                  setValue('zipcode', event.target.value.trim());
                },
                onChange() {
                  clearErrors('zipcode');
                },
              })}
              validation={validateGeneric('zipcode')}
            />
            <InputFormField
              label={t('city')}
              type="text"
              {...register('city', {
                required: { value: true, message: t('requiredCity') },
                value: contactFormData.city,
                onBlur(event) {
                  setValue('city', event.target.value.trim());
                },
                onChange() {
                  clearErrors('city');
                },
              })}
              validation={validateGeneric('city')}
            />

            <CountryPicker
              label={t('country')}
              {...register('country', {
                required: { value: true, message: t('requiredCountry') },
                value: contactFormData.country,
                onBlur(event) {
                  setValue('country', event.target.value.trim());
                },
                onChange() {
                  clearErrors('country');
                },
              })}
              validation={validateGeneric('country')}
            />

            <InputFormField
              label={t('name')}
              type="text"
              defaultValue={contactFormData.name}
              {...register('name', {
                required: { value: true, message: t('requiredName') },
                value: contactFormData.name,
                onBlur(event) {
                  setValue('name', event.target.value.trim());
                },
                onChange() {
                  clearErrors('name');
                },
              })}
              validation={validateGeneric('name')}
            />
            <InputFormField
              label={t('position')}
              type="text"
              {...register('position', {
                required: { value: true, message: t('requiredPosition') },
                value: contactFormData.position,
                onBlur(event) {
                  setValue('position', event.target.value.trim());
                },
                onChange() {
                  clearErrors('position');
                },
              })}
              validation={validateGeneric('position')}
            />
            <InputFormField
              label={t('email')}
              type="email"
              {...register('email', {
                required: { value: true, message: t('requiredEmail') },
                value: contactFormData.email,
                onBlur(event) {
                  setValue('email', event.target.value.trim());
                },
                onChange() {
                  clearErrors('email');
                },
              })}
              validation={validateGeneric('email')}
            />
            <Banner paragraph={t('privacyContactExplanation')} priority="medium" />
            <Switch
              defaultChecked={useNewPrivacyContact}
              onChange={() => onUseNewPrivacyContactChange()}
              label={t('useNewPrivacyContact')}
              id="use-new-privacy-contact"
            />
            {useNewPrivacyContact && (
              <>
                <InputFormField
                  label={t('privacyContactName')}
                  type="text"
                  {...register('privacyContactName', {
                    required: { value: true, message: t('requiredPrivacyContactName') },
                    value: contactFormData.privacyContact?.name,
                    onBlur(event) {
                      setValue('privacyContactName', event.target.value.trim());
                    },
                    onChange() {
                      clearErrors('privacyContactName');
                    },
                  })}
                  validation={validateGeneric('privacyContactName')}
                />
                <InputFormField
                  label={t('privacyContactPosition')}
                  type="text"
                  {...register('privacyContactPosition', {
                    required: { value: true, message: t('requiredPrivacyContactPosition') },
                    value: contactFormData.privacyContact?.position,
                    onBlur(event) {
                      setValue('privacyContactPosition', event.target.value.trim());
                    },
                    onChange() {
                      clearErrors('privacyContactPosition');
                    },
                  })}
                  validation={validateGeneric('privacyContactPosition')}
                />
                <InputFormField
                  label={t('privacyContactEmail')}
                  type="email"
                  {...register('privacyContactEmail', {
                    required: { value: true, message: t('requiredPrivacyContactEmail') },
                    value: contactFormData.privacyContact?.email,
                    onBlur(event) {
                      setValue('privacyContactEmail', event.target.value.trim());
                    },
                    onChange() {
                      clearErrors('privacyContactEmail');
                    },
                  })}
                  validation={validateGeneric('privacyContactEmail')}
                />
              </>
            )}
            <Button type="submit" loading={updateAgreementIsLoading} disabled={saveSuccessful}>
              {saveSuccessful ? t('saved') : t('save')}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </FormWrapper>
    </SpaceBetween>
  );
};

const newPrivacyContactIsSameAsBoardMember = (contactFormData: ContactFormData): boolean => {
  return (
    contactFormData.privacyContact?.name === contactFormData.name &&
    contactFormData.privacyContact?.position === contactFormData.position &&
    contactFormData.privacyContact?.email === contactFormData.email
  );
};

const hasNewPrivacyContact = (contactFormData: ContactFormData): boolean => {
  return !!(
    contactFormData.privacyContact?.name ||
    contactFormData.privacyContact?.position ||
    contactFormData.privacyContact?.email
  );
};

const FormWrapper = styled.div`
  max-width: 30rem;
`;
