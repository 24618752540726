import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21V3m0 18c-2.526-2.05-3.84-3.364-5.994-5.994M12 21c2.526-2.05 3.84-3.364 5.994-5.994"
    />
  </svg>
);
export default SvgIconArrowDown;
