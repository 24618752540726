import {
  IconLogout,
  Link,
  ProfileLink,
  ProfileLinkMenuProps,
  showProfileLinkMenu,
  styled,
  TopBarVisibility,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { mySubscriptionRoute, organizationRoute, profileSettingsRoute } from '../../utils/bridge/teacherRoutes';

const TRANSLATION_NS = 'avatar';

interface AvatarProps {
  displayName?: string;
  isLoggedIn: boolean;
  onClickSignout?: () => void;
  adminOrganizationIds: string[];
  licenseIndicator?: React.JSX.Element;
  showLinksAsExternal?: boolean;
}

export const Avatar = ({
  isLoggedIn,
  onClickSignout,
  adminOrganizationIds,
  displayName,
  licenseIndicator,
  showLinksAsExternal = false,
}: AvatarProps) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const ref = useRef(null);

  // /* If the user is logged in, we want to make sure to always show the profile link, even when we don't have the user info because of an error or loading state */
  if (!isLoggedIn) return null;

  function showMenu() {
    if (!ref.current) return;

    showProfileLinkMenu({
      parentSelector: () => ref.current,
      menuItems: profileMenuSettings({ t, onClickSignout, adminOrganizationIds, showLinksAsExternal }),
      contentLabel: t('ariaLabel'),
    });
  }

  return (
    <>
      <TopBarVisibility visibility="desktop-only">
        <Link href={profileSettingsRoute.href({ page: 'profile' })} passHref external={showLinksAsExternal}>
          <ProfileLink displayName={displayName} licenseIndicator={licenseIndicator} />
        </Link>
      </TopBarVisibility>
      <TopBarVisibility visibility="mobile-only">
        <InvisibleButton ref={ref} onClick={showMenu}>
          <ProfileLink displayName={displayName} licenseIndicator={licenseIndicator} />
        </InvisibleButton>
      </TopBarVisibility>
    </>
  );
};

function profileMenuSettings({
  t,
  onClickSignout,
  adminOrganizationIds,
  showLinksAsExternal,
}: Pick<AvatarProps, 'onClickSignout' | 'adminOrganizationIds' | 'showLinksAsExternal'> & {
  t: TFunction;
}): ProfileLinkMenuProps['menuItems'] {
  return compact([
    {
      text: t('subscription'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: mySubscriptionRoute.href({}),
      },
    },
    {
      text: t('profile'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: profileSettingsRoute.href({ page: 'profile' }),
      },
    },
    {
      text: t('schools'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: profileSettingsRoute.href({ page: 'mySchools' }),
      },
    },
    {
      text: t('settings'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: profileSettingsRoute.href({ page: 'settings' }),
      },
    },
    {
      text: t('invoices'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: profileSettingsRoute.href({ page: 'invoices' }),
      },
      divider: onClickSignout && !adminOrganizationIds.length,
    },
    adminOrganizationIds.length && {
      text: t('organization'),
      linkProps: {
        passHref: true,
        external: showLinksAsExternal,
        href: organizationRoute.href({ organizationId: adminOrganizationIds[0] }),
      },
      divider: !!onClickSignout,
    },
    onClickSignout && {
      text: t('logout'),
      icon: <IconLogout />,
      buttonSize: 'small',
      onClick: onClickSignout,
    },
  ]);
}

const InvisibleButton = styled.button`
  padding-left: 0;
  padding-right: 0;
  text-align: inherit;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0;
  display: inherit;
  width: 100%;
  height: 100%;
  position: relative;
`;
