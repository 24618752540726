import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAssetLicenseStatusExpired = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={27} fill="none" viewBox="0 0 32 27" {...props}>
    <g fillRule="evenodd">
      <path fill="#FFF" d="M13 7h6v19h-6z" />
      <path
        fill="#EC484D"
        fillRule="nonzero"
        d="M18.3 1.3A2.7 2.7 0 0 0 16 0c-1 0-1.8.5-2.3 1.3L.4 22.9a2.7 2.7 0 0 0 0 2.7c.4.9 1.3 1.4 2.3 1.4h26.6c1 0 1.9-.5 2.4-1.4a2.7 2.7 0 0 0-.1-2.7zM16 23.5c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3 1.3.6 1.3 1.3a1.3 1.3 0 0 1-1.3 1.3m1.3-5.9c0 .7-.6 1.3-1.3 1.3s-1.3-.6-1.3-1.3V8.9c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3z"
      />
    </g>
  </svg>
);
export default SvgAssetLicenseStatusExpired;
