import { AuthStatus, useAuth, useDocumentQuery } from '@lessonup/client-integration';
import { MyUser } from '../../features/TeacherTopBar/TeacherTopBar.graphql';
import { MyUserDocument } from '../../features/TeacherTopBar/TeacherTopBar.graphql.generated';

export const useMyUser = (): MyUser | undefined => {
  const auth = useAuth();

  const { data } = useDocumentQuery(MyUserDocument, {
    fetchPolicy: 'cache-first',
    skip: !auth || auth.type === AuthStatus.LOGGED_OUT, // Don't query if we aren't authenticated
  });
  return data?.viewer;
};

// TOOD - should only rerender when userId changes, now tiggers on any user change
export const useMyUserId = (): string | undefined => {
  const user = useMyUser();
  return user?.id;
};
