import { useLocale } from '@lessonup/client-integration';
import {
  IconComponentImage,
  IconFolder,
  IconSortAscending,
  IconSortDescending,
  IconWarning,
  ProgressBar,
  styled,
  SvgComponent,
  Table,
  TableCellsHeader,
  TableRowData,
  useModal,
} from '@lessonup/ui-components';
import { dateInIsoToHumanReadable, Locale } from '@lessonup/utils';
import { TFunction } from 'i18next';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AcceptedMimeTypesForUploads, UploadsSorting, UploadsSortingField } from '../../types/graphql';
import { IntroductionMessage } from './components/IntroductionMessage/IntroductionMessage';
import { UploadPreviewModal } from './dataComponents/UploadPreviewModal';
import { UploadFile } from './UploadFile';
import { ChildUploadFolder } from './Uploads.graphql';
import { UploadsContext } from './uploadsContext';
import {
  getStartSlotForUpload,
  humandReadableFileSize,
  i18nextNamespace,
  isStuckIfMoreThan,
  isUpload,
  isUploadFolder,
  isUploading,
  maxUploadTimeInHours,
  Upload,
  UploadType,
} from './UploadsFeature.utils';

interface UploadsExplorerProps {
  uploads: ChildUploadFolder[];
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
  sortBy?: UploadsSorting;
  isSearch: boolean;
  acceptedMimeTypes: AcceptedMimeTypesForUploads;
  folderId?: string;
}

export function UploadsExplorer({
  uploads,
  selectedRows,
  setSelectedRows,
  sortBy,
  isSearch,
  acceptedMimeTypes,
  folderId,
}: UploadsExplorerProps) {
  const locale = useLocale();
  const { t } = useTranslation(i18nextNamespace);
  const { dispatchNavigation, tableInteractivity, fileTypeFilter, previewActions, options } =
    useContext(UploadsContext);
  const setSortBy = (field: UploadsSortingField) => dispatchNavigation({ type: 'sortField', field });

  const previewModal = useModal(UploadPreviewModal);
  const unselectDeletedFile = (id: string) => setSelectedRows((rows) => rows.filter((row) => row !== id));

  const showPreview = (uploadId: string) =>
    previewModal.show({
      uploadId,
      onDelete: unselectDeletedFile,
      previewActions,
      fromSearchResults: isSearch,
      options,
    });

  const getEndIconForTableHeader = (field: UploadsSortingField): SvgComponent | undefined => {
    if (sortBy?.field !== field) {
      return undefined;
    }

    return sortBy.direction === 'ASC' ? IconSortAscending : IconSortDescending;
  };

  const header: TableCellsHeader = {
    cells: [
      {
        text: t('sortName'),
        onClick: () => setSortBy('NAME'),
        endSvgIcon: getEndIconForTableHeader('NAME'),
      },
      {
        text: t('sortSize'),
        onClick: () => setSortBy('ORIGINAL_FILE_SIZE_IN_BYTES'),
        endSvgIcon: getEndIconForTableHeader('ORIGINAL_FILE_SIZE_IN_BYTES'),
      },
      {
        text: t('sortType'),
        onClick: () => setSortBy('ORIGINAL_FILE_EXTENSION'),
        endSvgIcon: getEndIconForTableHeader('ORIGINAL_FILE_EXTENSION'),
      },
      {
        text: t('sortLastModified'),
        onClick: () => setSortBy('UPDATED_AT'),
        endSvgIcon: getEndIconForTableHeader('UPDATED_AT'),
      },
    ],
  };

  const rows = useGetExplorerRows(uploads, showPreview, t, fileTypeFilter, locale);

  return (
    <>
      {uploads.length ? (
        <Table
          caption={t('caption')}
          header={header}
          rows={rows}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          interactivity={tableInteractivity}
        />
      ) : (
        <IntroductionMessage
          translationKeyPrefix={isSearch ? 'emptySearch' : 'emptyState'}
          icon={isSearch ? <IconWarning /> : <IconComponentImage />}
          action={
            isSearch ? undefined : (
              <UploadFile acceptedMimeTypes={acceptedMimeTypes} type="secondary" folderId={folderId} />
            )
          }
        />
      )}
    </>
  );
}

interface TransformChildFolderProps {
  upload: ChildUploadFolder;
  setFolderId: (folderId: string) => void;
  t: TFunction;
}

const transformChildFolder = ({ upload, setFolderId, t }: TransformChildFolderProps): TableRowData => {
  return {
    id: upload.id,
    ariaLabel: t('folderLabel', { folderName: upload.name }),
    onClick: () => {
      setFolderId(upload.id);
    },
    cells: [
      {
        startSlot: <IconFolder />,
        text: upload.name,
      },
    ],
  };
};

const isFileTypeEnabled = (fileTypes: UploadType[], type: UploadType | undefined) => {
  return fileTypes.length > 0 && !(type && fileTypes.includes(type));
};

interface TransformFileProps {
  upload: Upload;
  showPreview: (uploadId: string) => void;
  t: TFunction;
  fileTypes: UploadType[];
}

const transformFile = ({ upload, showPreview, t, fileTypes }: TransformFileProps, locale: Locale): TableRowData => ({
  id: upload.id,
  ariaLabel: upload.name,
  onClick: () => showPreview(upload.id),
  cells: [
    {
      startSlot: getStartSlotForUpload(upload, t),
      text: upload.name,
    },
    {
      text: humandReadableFileSize(upload.originalFile.sizeInBytes, locale),
    },
    {
      text: upload.originalFile.fileExtension,
    },
    getEndSlotForUpload(upload),
  ],
  disabled:
    isFileTypeEnabled(fileTypes, upload.__typename) ||
    isUploading(upload) ||
    isStuckIfMoreThan(maxUploadTimeInHours)(upload),
});

function getEndSlotForUpload(upload: Upload): TableRowData['cells'][number] {
  return isUploading(upload)
    ? { endSlot: <StyledProgressBar value={upload.progressPercentage} /> }
    : { text: dateInIsoToHumanReadable(upload.updatedAt) };
}

function useGetExplorerRows(
  uploads: ChildUploadFolder[],
  showPreview: (uploadId: string) => void,
  t: TFunction,
  fileTypes: UploadType[],
  locale: Locale
): TableRowData[] {
  const { dispatchNavigation } = useContext(UploadsContext);

  const rows: TableRowData[] = [];

  const setFolderId = (id: string) => dispatchNavigation({ type: 'folderId', id });

  uploads.forEach((upload) => {
    if (isUploadFolder(upload)) {
      rows.push(transformChildFolder({ upload, setFolderId, t }));
    }
    if (isUpload(upload)) {
      rows.push(transformFile({ upload, showPreview, t, fileTypes }, locale));
    }
  });

  return rows;
}

const StyledProgressBar = styled(ProgressBar)`
  max-width: 120px;
  overflow: hidden;
`;
