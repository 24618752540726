export const addParamsToLink = (link: string, params: Record<string, string>) => {
  if (!params || !Object.keys(params).length) return link;

  const url = new URL(link);
  const searchParams = new URLSearchParams(params);
  const presentParams = new URLSearchParams(url.search);

  const combined = new URLSearchParams({
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(presentParams),
  });

  url.search = combined.toString();

  return url.href;
};
