import { styled } from '@lessonup/ui-components';
import React from 'react';

export interface PinEventLayerProps {
  onMouseDown: React.MouseEventHandler;
  onContextMenu: React.MouseEventHandler;
  eventHandlers: React.DOMAttributes<HTMLDivElement>;
  children: React.ReactNode;
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

const StyledEventListenerLayer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const PinEventLayer: React.FC<PinEventLayerProps> = ({
  eventHandlers,
  onMouseDown,
  onContextMenu,
  children,
}) => {
  return (
    <StyledWrapper {...eventHandlers}>
      {/* Captures any mouse down events that aren't already caught by pin components. */}
      <StyledEventListenerLayer onMouseDown={onMouseDown} onContextMenu={onContextMenu} />
      {children}
    </StyledWrapper>
  );
};
