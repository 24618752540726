import { useLocale } from '@lessonup/client-integration';
import {
  BodyText,
  borderRadius,
  ButtonAnchor,
  color,
  DefinitionList,
  DefinitionListItemProps,
  Divider,
  Headline,
  Link,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { localeToIsoLocaleString } from '@lessonup/utils';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementDocument } from '../../../../../types/graphql';
import { agreementDocumentRoute } from '../../../../../utils/bridge/teacherRoutes';
import { SignProposals } from '../../OrganizationAgreements.graphql';
import {
  mapGraphQLAgreementDocumentToDbAgreementDocument,
  mapGraphQLAgreementDocumentToReadableTitle,
} from '../../utils';

export interface AgreementProps {
  id: string;
  isSigned: boolean;
  creationDate: string;
  document: AgreementDocument;
  isParentAgreement: boolean;
  secret: string;
  signProposals: SignProposals | null | undefined;
  signedProposal?: string | null;
  children?: React.ReactNode;
}

export const Agreement: React.FC<AgreementProps> = ({
  id,
  isSigned,
  creationDate,
  document,
  isParentAgreement,
  secret,
  signedProposal,
  signProposals,
  children,
}) => {
  const { t } = useTranslation('organizationAgreements');
  const locale = useLocale();
  const dateFormatter = new Intl.DateTimeFormat(localeToIsoLocaleString(locale), {
    dateStyle: 'short',
  });

  const agreementUrl =
    id &&
    secret &&
    signedProposal &&
    agreementDocumentRoute.href({
      agreementId: id,
      secret,
      proposalId: signedProposal,
      documentVersion: mapGraphQLAgreementDocumentToDbAgreementDocument(document),
    });

  return (
    <StyledSpaceBetween direction="y" spacing={spacing.size24}>
      <Headline as="h3" size="medium">
        {mapGraphQLAgreementDocumentToReadableTitle(document)}
      </Headline>
      <SignedStatus isSigned={isSigned} isParentAgreement={isParentAgreement} t={t} />
      {!isSigned && (
        <div>
          <Headline as="h3" size="small">
            {t('sentProposals')}
          </Headline>
          <>
            {signProposals &&
              signProposals.map((signProposal, index) => {
                const listItems: DefinitionListItemProps[] = [
                  {
                    term: t('date'),
                    description: dateFormatter.format(new Date(creationDate)),
                  },
                  {
                    term: t('name'),
                    description: signProposal.boardMember.name,
                  },
                  {
                    term: t('position'),
                    description: signProposal.boardMember.position,
                  },
                  {
                    term: t('email'),
                    description: signProposal.boardMember.email,
                  },
                ];
                return (
                  <SpaceBetween key={index} direction="y" spacing={spacing.size24}>
                    <DefinitionList orientation="horizontal" items={listItems} />
                    {!!signProposals[index + 1] && <Divider orientation="horizontal" />}
                  </SpaceBetween>
                );
              })}
          </>
        </div>
      )}
      {children}

      {isSigned && agreementUrl && (
        <StyledButtonWrapper>
          <Link href={agreementUrl} passHref>
            <ButtonAnchor>{t('openAgreement')}</ButtonAnchor>
          </Link>
        </StyledButtonWrapper>
      )}
    </StyledSpaceBetween>
  );
};

const StyledButtonWrapper = styled('div')`
  align-self: flex-start;
`;

const SignedStatus: React.FC<{ isSigned: boolean; isParentAgreement: boolean; t: TFunction }> = ({
  isSigned,
  isParentAgreement,
  t,
}) => {
  if (isParentAgreement) {
    return (
      <BodyText size="medium">
        {isSigned ? t('parentInfo.signedAgreement') : t('parentInfo.notSignedAgreement')}
      </BodyText>
    );
  }

  return <BodyText size="medium">{isSigned ? t('signedAgreement') : t('notSignedAgreement')}</BodyText>;
};

const StyledSpaceBetween = styled(SpaceBetween)`
  background-color: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded16};
  padding: 20px;
`;
