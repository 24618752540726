// platform is deprecated, below should work for most of the browser see stackoverflow
// https://stackoverflow.com/questions/10527983

function getPlatform() {
  if (typeof navigator === 'undefined') return 'unknown';
  const modernNavigator = navigator as NavigatorUserAgentData;
  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
  if (typeof modernNavigator.userAgentData !== 'undefined' && modernNavigator.userAgentData != null) {
    return modernNavigator.userAgentData.platform;
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.userAgent !== 'undefined' && /android/.test(navigator.userAgent.toLowerCase())) {
    // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
    return 'android';
  }
  return navigator.platform;
}

type NavigatorUserAgentData = {
  userAgentData?: Record<string, string>;
};

export const isMacFromNavigator = /(Mac|iPhone|iPod|iPad)/i.test(getPlatform());
