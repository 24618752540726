import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconShare = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.961 9.158a1.56 1.56 0 0 0-1.374 1.372 39.2 39.2 0 0 0 0 9.114 1.56 1.56 0 0 0 1.374 1.372c3.087.343 8.99.343 12.078 0a1.56 1.56 0 0 0 1.374-1.372 39.2 39.2 0 0 0 0-9.114 1.56 1.56 0 0 0-1.374-1.372M12 13.898V2.727m3.533 3.031c-1.074-1.356-1.805-1.987-2.894-2.813a1.04 1.04 0 0 0-1.277 0c-1.09.826-1.82 1.457-2.894 2.813"
    />
  </svg>
);
export default SvgIconShare;
