import { ImagePinComponent } from '@lessonup/pin-renderer';
import { styled, Thumb } from '@lessonup/ui-components';
import React from 'react';

interface ThumbnailPinComponentProps {
  component: ImagePinComponent;
  isActive: boolean;
  onActivate: (pinComponentId: string) => void;
  onDeactivate: () => void;
  index: number;
}

export const ThumbnailPinComponent: React.FC<ThumbnailPinComponentProps> = ({
  component,
  isActive,
  onActivate,
  onDeactivate,
  index,
}) => {
  const handleThumbnailClick = () => {
    isActive ? onDeactivate() : onActivate(component.id);
  };

  return (
    <Thumb
      number={index + 1}
      key={`thumb-${component.id}`}
      thumbType="search"
      onClick={() => handleThumbnailClick()}
      isActive={isActive}
    >
      <StyledThumb src={component.settings.url} />
    </Thumb>
  );
};

const StyledThumb = styled.img`
  width: 64px;
`;
