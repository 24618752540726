import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { useMemo } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';

export interface MeterProps {
  percentage: number;
}

export const Meter: React.FC<MeterProps> = ({ percentage }) => {
  const validPercentage = useMemo((): number => {
    return Math.min(Math.max(0, Math.round(percentage)), 100);
  }, [percentage]);

  return (
    <StyledMeter>
      <MeterOuter role="status" aria-live="off">
        <Progress percentage={validPercentage} />
      </MeterOuter>
    </StyledMeter>
  );
};

const StyledMeter = styled.div`
  color: ${color.additional.disabled.text};
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
`;

const MeterOuter = styled.div`
  background-color: ${color.additional.disabled.background};
  border-radius: ${borderRadius.rounded4};
  flex-grow: 1;
  height: ${rem('12px')};
  position: relative;
`;

/**
 * The audioprocess event executes several times per second, so in order for the meter to render smoothly we use translate3d(0)
 * to have the GPU handle this render instead of the CPU (where applicable)
 */
const Progress = styled.div`
  background-color: ${color.additional.success.background};
  border-radius: ${borderRadius.rounded4};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${(props: { percentage: number }) => props.percentage}%;
  transform: translate3d(0);
`;
