import { InternalRefetchQueriesInclude, useDocumentMutation } from '@lessonup/client-integration';
import { showConfirmModal } from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import type { TFunction } from 'i18next';
import { partition } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DeleteUploadsAndFoldersDocument } from '../Uploads.graphql.generated';
import { i18nextNamespace } from '../UploadsFeature.utils';

export const deleteMultipleFilesTranslationKey = 'confirmDelete.descriptionFilesAndFolders';
export const deleteFilesOnlyTranslationKey = 'confirmDelete.descriptionFilesOnly';
export const deleteFoldersOnlyTranslationKey = 'confirmDelete.descriptionFoldersOnly';

interface UseDeleteUploadsParams {
  onError: (error: Error) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

interface HandleDeleteUploadParams {
  uploads: { id: string; type: 'upload' | 'folder' }[];
}

export const useDeleteUploads = ({ onError, refetchQueries }: UseDeleteUploadsParams) => {
  const { t } = useTranslation(i18nextNamespace);
  const [deleteUploadAndFolderMutation] = useDocumentMutation(DeleteUploadsAndFoldersDocument, {
    refetchQueries,
  });

  const handleDeleteUpload = async ({ uploads }: HandleDeleteUploadParams): Promise<{ deleted: boolean }> => {
    const [folders, uploadFiles] = partition(uploads, (upload) => upload.type == 'folder');

    try {
      const confirmResult = await confirmDeletion({ fileLength: uploadFiles.length, folderLength: folders.length, t });

      if (!confirmResult) return { deleted: false };

      await deleteUploadAndFolderMutation({
        variables: {
          uploadInput: { uploadIds: uploadFiles.map((u) => u.id) },
          folderInput: { folderIds: folders.map((f) => f.id) },
        },
      });

      return { deleted: true };
    } catch (error) {
      onError(asError(error));

      return { deleted: false };
    }
  };

  return handleDeleteUpload;
};

interface ConfirmDeleteParams {
  fileLength: number;
  folderLength: number;
  t: TFunction;
}

async function confirmDeletion({ fileLength, folderLength, t }: ConfirmDeleteParams) {
  return showConfirmModal({
    title: t('confirmDelete.title'),
    description: getDeleteDescription({ fileCount: fileLength, folderCount: folderLength, t }),
    ariaLabel: t('confirmDelete.ariaLabel'),
    primaryAction: {
      label: t('confirmDelete.primaryAction'),
      buttonType: 'negative',
    },
    secondaryAction: {
      label: t('confirmDelete.secondaryAction'),
    },
  });
}

export function getDeleteDescription({
  fileCount,
  folderCount,
  t,
}: {
  fileCount: number;
  folderCount: number;
  t: TFunction;
}) {
  if (fileCount && folderCount) {
    return t('confirmDelete.descriptionFilesAndFolders');
  }

  return fileCount
    ? t('confirmDelete.descriptionFilesOnly', { count: fileCount })
    : t('confirmDelete.descriptionFoldersOnly', { count: folderCount });
}
