import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.983 11.58s-.746-.831-1.955-.727-1.791.846-1.791 1.59c0 2.173 3.746.668 3.746 2.895 0 1.148-2.485 1.959-3.966.608m2.16-6.554v1.452m0 5.795v1.451m2.542-10.385 1.3-2.951c.162-.368.256-.819-.074-1.049-1.354-.94-6.608-.94-7.962 0-.33.23-.236.68-.074 1.049l1.307 2.967c-3.465.898-5.368 3.609-5.368 7.795C3.848 20.714 6.783 21 12 21s8.152-.286 8.152-5.484c0-4.212-1.927-6.931-5.433-7.811"
    />
  </svg>
);
export default SvgIconPayment;
