/**
 * Meant to replace the usage of the `querystring` package which is relatively big and not necessary now that we can use the built-in URLSearchParams
 */
export const qs = {
  stringify: (
    params: Record<string, string | number | boolean | undefined>,
    options?: { addQueryPrefix?: boolean }
  ) => {
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      // maintain compatibility with querystring.stringify
      // properties with undefined values are omitted
      // properties with null values are serialized to ''
      if (value !== undefined) {
        searchParams.append(key, `${value ?? ''}`);
      }
    }
    const serializedParams = searchParams.toString();
    return options?.addQueryPrefix && serializedParams ? '?' + serializedParams : serializedParams;
  },

  parse: (querystring: string | undefined): Record<string, string> => {
    const params = new URLSearchParams(querystring ?? '');
    const result: Record<string, string> = {};
    for (const [key, value] of params.entries()) {
      result[key] = value;
    }
    return result;
  },
};
