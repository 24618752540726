import { tracker } from '@lessonup/analytics';
import {
  breakpoints,
  Grid,
  IconBook,
  IconText,
  Introduction,
  rem,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodySectionTransparent } from '../../../components/FullPageFlow/FullPageBodySection';
import { Questionnaire } from '../../../components/Questionnaire/Questionnaire';
import { MaiaLessonType } from '../ChatGPTGeneratorFeature.utils';
import { PROMPT_CHARACTER_LIMIT as contextMaxCharacters } from '../dataComponents/GenerateContextLesson';
import { PROMPT_CHARACTER_LIMIT as promptMaxCharacters } from '../dataComponents/GenerateLesson';

interface ChooseMaiaModeProps {
  setLessonType: React.Dispatch<React.SetStateAction<MaiaLessonType | undefined>>;
}

export const ChooseMaiaMode = ({ setLessonType }: ChooseMaiaModeProps) => {
  const { t } = useTranslation('chatGPT');

  const handleMaiaModeSwitch = (lessonType: MaiaLessonType) => {
    tracker.events.aiOpened({ aiInputMethod: lessonType === 'lesson' ? 'text_short' : 'text_long' });
    setLessonType(lessonType);
  };

  return (
    <StyledFullPageSection>
      <SpaceBetween direction="y" spacing={spacing.size32}>
        <Introduction
          alignment="center"
          size="S"
          headline={t('chooseMaiaMode.title')}
          bodyText={t('chooseMaiaMode.subTitle')}
        />

        <StyledGrid gap={spacing.size24} alignItems="start">
          <Questionnaire
            onClick={() => handleMaiaModeSwitch('lesson')}
            headingText={t('chooseMaiaMode.promptTitle')}
            bodyText={t('chooseMaiaMode.promptSubTitle')}
            note={t('chooseMaiaMode.maxCharacters', { maxCharacters: promptMaxCharacters })}
            icon={<IconText />}
          />
          <Questionnaire
            onClick={() => handleMaiaModeSwitch('contextLesson')}
            headingText={t('chooseMaiaMode.textTitle')}
            bodyText={t('chooseMaiaMode.textSubTitle')}
            note={t('chooseMaiaMode.maxCharacters', { maxCharacters: contextMaxCharacters })}
            icon={<IconBook />}
          />
        </StyledGrid>
      </SpaceBetween>
    </StyledFullPageSection>
  );
};

const StyledFullPageSection = styled(FullPageBodySectionTransparent)`
  max-width: ${rem('640px')};
`;

const StyledGrid = styled(Grid)`
  align-items: stretch;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
