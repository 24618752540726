import { isToday, Location, MongoIDProvider } from '@lessonup/utils';
import _, { compact, Dictionary, first, flatten, uniq } from 'lodash';
import { Differentiation, PinItem } from '../common';
import { schooltypeHasLevels } from '../edusystem';
import { SchoolLevel, SchoolType } from '../school/School';
import { UserContent, UserContentOrigin, UserContentPrivacy } from '../UserContent';
import { LessonPin } from './LessonPin';
import { LessonTrail } from './LessonTrail';

export interface Lesson extends UserContent {
  user: string;
  name: string | undefined;
  /** @deprecated use country instead */
  region?: string[];
  country: string[];
  showPhases?: boolean;
  sharing: ShareOptions;
  schoolType: SchoolType[] | undefined;
  levels: SchoolLevel[] | undefined;
  years: number[] | undefined;
  subjects: string[];
  themes?: string[] | undefined;
  creationDate: Date;
  pinCount?: number | undefined;
  pinCountForLesson?: number | undefined;
  pinCountForStudent?: number | undefined;
  modifiedDate?: Date | undefined;
  openendDate?: Date | undefined;
  durationInMin?: number | undefined;
  /** @deprecated Use differentiation instead. */
  diff?: Differentiation[];
  differentiation?: Differentiation[];
  sharedExplorer?: string;
  isTest?: boolean;
  plan?: string;
  phases?: Lesson.Phase[];
  isStub?: boolean;
  curricula?: Lesson.CurriculaRef[];
  description?: string;
  information?: string;
  trail?: LessonTrail[];
  stats?: Dictionary<any>;
  thumbnail?: LessonThumbnail;
  attachments?: LessonAttachment[];
  productsLocked?: boolean;
  tags?: string[];
  templatePins?: PinItem<any>[];
  cover?: string;
  header?: 'lessonup' | 'coverbar';
  coverFocusY?: number;
  template?: boolean;
  version?: 'v2';
  source?: 'openAi';
}

export namespace Lesson {
  export interface Origin extends UserContentOrigin {
    lesson: string;
  }
  export function isOwner(lesson: Lesson, userId: string): boolean {
    return lesson.user == userId;
  }

  const requiredPropsForLesson = ['schoolType', 'subjects', 'levels', 'years'];

  export interface Phase {
    label: string;
    subtitle?: string;
  }

  export interface CurriculaRef {
    id: string;
    objectives: string[];
  }

  export function curriculaIds(lesson: Lesson): string[] {
    return _.map(lesson.curricula, (c) => c.id);
  }

  export function objectiveIds(lesson: Lesson): string[] {
    return _.flatMap(lesson.curricula, (c) => c.objectives).filter((id) => !_.isEmpty(id));
  }

  export function needsSharingData(lesson: Lesson): boolean {
    return !lesson.sharing?.shareDate || !lesson.sharing?.sharePublic;
  }

  export function isTest(lesson?: Lesson): boolean {
    return !!lesson?.isTest;
  }

  export function isEditorV2Lesson(lesson: Lesson | undefined): boolean {
    return lesson?.version === 'v2';
  }

  export function originInfo(lesson?: Lesson): { originId?: string; originChannel?: string } | undefined {
    if (lesson === undefined) return undefined;
    if (lesson.origin) {
      return {
        originId: lesson.origin.id,
        originChannel: lesson.origin.channel,
      };
    }
    if (lesson.trail?.length) {
      return {
        originId: lesson.trail[0].lesson,
        originChannel: lesson.trail[0].channel,
      };
    }
  }

  function getTrailValue<T extends keyof LessonTrail>(trail: LessonTrail[], key: T): LessonTrail[T] | undefined {
    return first(uniq(flatten(compact(trailParentValues(trail, key)))));
  }

  function trailParentValues<T extends keyof LessonTrail>(trail: LessonTrail[], key: T): LessonTrail[T][] {
    return trail.map((t) => t[key]);
  }

  export interface CopyOptions {
    name?: string | undefined;
    privacy?: UserContentPrivacy;
    canEditProducts?: boolean;
    plan?: string;
    trail?: LessonTrail;
    order?: string;
    canCopyAttachments?: boolean;
  }

  export function copy(
    source: Lesson,
    userId: string,
    location: Location | null,
    idProvider: MongoIDProvider,
    options?: CopyOptions
  ): { newLesson: Lesson } {
    /*
      warning, this copy function is search only for now, missing teacher business logic
    */
    let trail: any[] = [];
    if (source.trail) trail = source.trail;
    if (source.channel) {
      // we come from a channel!
      trail.push({
        channel: source.channel,
        d: new Date(),
        lesson: source._id,
      });
    }

    const newLesson: Lesson = {
      _id: idProvider.id(),
      user: userId,
      name: options?.name || source.name,
      privacy: options?.privacy || source?.privacy || 'public',
      products: source.products || [],
      order: options?.order,
      plan: options?.plan,
      location,
      sharing: {
        sharePublic: true,
        shareDate: new Date(),
      },
      creationDate: new Date(),
      openendDate: new Date(),
      modifiedDate: new Date(),
      differentiation: differentiation(source),
      trail,
      ..._.pick(source, [
        'region',
        'country',
        'levels',
        'years',
        'subjects',
        'schoolType',
        'description',
        'information',
        'durationInMin',
        'showPhases',
        'isTest',
        'diff',
        'pinCount',
        'pinCountForLesson',
        'pinCountForStudent',
        'phases',
        'tags',
      ]),
      origin: source.origin,
      thumbnail: source.thumbnail,
      attachments: options?.canCopyAttachments ? source.attachments : undefined,
      version: source.version,
    };
    if (source.products?.length && !(options && options.canEditProducts)) {
      newLesson.productsLocked = true;
    }

    return {
      newLesson,
    };
  }

  export function isPrivateOrProtected(privacy: UserContentPrivacy): boolean {
    const privacies: UserContentPrivacy[] = ['private', 'protected'];
    return privacies.includes(privacy);
  }

  export function requiresMetadata(lesson: Lesson, schoolTypes: string[] = []): boolean {
    return !!getMissingMetaDataNames(lesson, schoolTypes).length;
  }

  export function getMissingMetaDataNames(lesson: Lesson, schoolTypes: string[] = []): string[] {
    const lessonCountry = lesson.country && lesson.country[0];

    // Workaround to accommodate for mbo levels, which were added later on to ensure existing users don't need to add level data.
    const ignoreLevelsRequirementForMBO = !!lesson.schoolType?.includes('mbo') && !isToday(lesson.creationDate);

    const ignoreProps: Record<(typeof requiredPropsForLesson)[number], boolean> = {
      schoolType: _.includes(schoolTypes, 'none'),
      levels:
        !!(
          lesson.schoolType &&
          lessonCountry &&
          lesson.schoolType.some((schoolType: SchoolType) => !schooltypeHasLevels(lessonCountry, schoolType))
        ) || ignoreLevelsRequirementForMBO,
      years: !lesson.country || !lesson.country.includes('nl'),
    };

    const missingParams = compact(
      requiredPropsForLesson.map((prop) => {
        if (ignoreProps[prop]) return undefined;
        if (typeof lesson[prop] === 'object') {
          if (_.isEmpty(lesson[prop])) {
            return prop;
          }
        } else if (lesson[prop]) {
          return prop;
        }
        return undefined;
      })
    );

    return missingParams;
  }

  export interface LessonLocatedInExplorer extends Lesson {
    location: Location;
    plan: undefined;
  }

  export interface LessonLocatedInLessonPlan extends Lesson {
    plan: string;
    location: null;
  }

  export function isLocatedInExplorer(lesson: Lesson): lesson is LessonLocatedInExplorer {
    return !!lesson.location && !lesson.plan;
  }

  export function isLocatedInLessonPlan(lesson: Lesson): lesson is LessonLocatedInLessonPlan {
    return !!lesson.plan && !lesson.location;
  }

  export function differentiation(lesson: Lesson | undefined): Differentiation[] | undefined {
    return lesson?.differentiation || lesson?.diff;
  }

  export function isChannelLesson(lesson: Lesson | undefined): boolean {
    return !!lesson?.channel;
  }

  export function isProductLesson(lesson: Lesson | undefined): boolean {
    return !!lesson?.products?.length;
  }
}

interface ShareOptions {
  sharePublic: boolean;
  shareDate?: Date;
}

export type LessonPrivacy = UserContentPrivacy;
export interface LessonData {
  lesson: Lesson;
  pins: LessonPin[];
}

export interface LessonThumbnail {
  modifiedDate: Date;
  url: string;
  hash?: string;
  // Indicates which version of the thumbnailer implementation was used to create the thumbnail
  v?: number;
}

export interface LessonAttachment {
  mimetype: string;
  name: string;
  type: 'worksheet' | 'instruction';
  /** @deprecated Not set for uploads after April 2023. Use the uploadId to generate a download link. */
  url?: string;
  uploadId: string;
}
