import { tracker } from '@lessonup/analytics';
import { useDocumentMutation } from '@lessonup/client-integration';
import {
  ManagedModal,
  ModalFooter,
  ModalFooterAction,
  ModalFooterActionAsync,
  ModalHeaderV1,
  NiceModal,
  NiceModalHocProps,
  rem,
  showConfirmModal,
  useForm,
} from '@lessonup/ui-components';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateMultipleChoiceQuestionsDocument } from '../ChatGPTGeneratorFeature.graphql.generated';
import { HelpButton } from '../components/HelpButton';
import { ChatGPTWizard, ChatGPTWizardSteps } from '../components/Wizard/ChatGPTWizard';
import { FormInputs, InteractivePromptStep } from '../components/Wizard/InteractivePromptStep/InteractivePromptStep';
import { ReviewStep } from '../components/Wizard/ReviewStep/ReviewStep';
import { useHandleGeneratorError } from '../hooks/useHandleGeneratorError';
import { QuizResponse, ResponseSelection } from '../model';
import { mapGraphQLtoQuizResponse } from './utils';

export interface GenerateQuizModalProps {
  onGenerate: (results: QuizResponse) => void;
  prompt?: string;
}

export const GenerateQuizModal: React.FC<GenerateQuizModalProps & NiceModalHocProps> = NiceModal.create(
  ({ onGenerate, prompt }) => {
    const modal = NiceModal.useModal();
    const { register, handleSubmit, reset, watch } = useForm<FormInputs>({ defaultValues: { prompt } });
    const [generateMultipleChoiceMutation] = useDocumentMutation(GenerateMultipleChoiceQuestionsDocument);
    const [currentStep, setCurrentStep] = useState<ChatGPTWizardSteps>('prompt');

    // Conversion to [number] and then to an array again is necessary to get .filter() working later on
    const [quizResponse, setQuizResponse] = useState<ResponseSelection<QuizResponse>>([]);
    const lastGeneratedPrompt = useRef('');

    const [selection, setSelection] = useState<string[]>([]);
    const { t } = useTranslation('chatGPT');

    const onCloseClick = async () => {
      if (currentStep === 'prompt') {
        modal.hide();
        //Clear input fields
        reset();
      } else {
        const isConfirmed = await showConfirmModal({
          title: t('discardPrompt'),
          ariaLabel: t('discardPrompt'),
          description: t('discardPromptDescription', { type: 'quiz' }),
          primaryAction: { label: t('discardPromptPrimaryActionLabel'), buttonType: 'negative' },
          secondaryAction: { label: t('discardPromptSecondaryActionLabel') },
          canClose: false,
          width: '400px',
        });

        if (isConfirmed) {
          modal.hide();
          tracker.events.experimentAiGenerationPromptDiscarded({
            pinType: 'quiz',
            openAiPrompt: lastGeneratedPrompt.current,
          });
          setSelection([]);
          //Clear input fields
          reset();
          setCurrentStep('prompt');
        }
      }
    };

    const onGenerateQuiz = async (inputData: FormInputs) => {
      setCurrentStep('loading');
      tracker.events.experimentAiGenerationStarted();

      try {
        const { data, errors } = await generateMultipleChoiceMutation({
          variables: {
            input: {
              numberOfQuestions: inputData.slidesToGenerate,
              language: inputData.language,
              prompt: inputData.prompt,
            },
          },
        });

        if (!data || errors?.length) {
          showErrorAndGoToPrompt();
        } else {
          lastGeneratedPrompt.current = inputData.prompt;
          tracker.events.experimentAiGenerationSuccess({
            pinCount: data.generateMultipleChoiceQuestions.questions.length,
            pinType: 'quiz',
          });
          const response = mapGraphQLtoQuizResponse(data.generateMultipleChoiceQuestions);

          setQuizResponse(response.list);
          setCurrentStep('review');
        }
      } catch (error: unknown) {
        showErrorAndGoToPrompt(error, quizResponse.length);
      }
    };

    const showErrorAndGoToPrompt = useHandleGeneratorError('quiz', () => setCurrentStep('prompt'), t);

    const finishReview = () => {
      const list = quizResponse.filter((question) => selection.includes(question.tempId));
      onGenerate({ type: 'quiz', list });
      tracker.events.experimentAiGenerationPromptSuccess({
        pinCount: list.length,
        pinType: 'quiz',
        openAiPrompt: lastGeneratedPrompt.current,
      });
      modal.hide();
      setSelection([]);
      setCurrentStep('prompt');
    };

    const confirmChangePrompt = async () => {
      const isConfirmed = await showConfirmModal({
        title: t('reviewSlides.changePromptTitle'),
        ariaLabel: t('reviewSlides.changePromptTitle'),
        description: t('reviewSlides.changePromptDescription', { type: 'quiz' }),
        primaryAction: { label: t('discardPromptPrimaryActionLabel'), buttonType: 'negative' },
        secondaryAction: { label: t('discardPromptSecondaryActionLabel') },
        canClose: false,
        width: '400px',
      });

      if (isConfirmed) {
        setCurrentStep('prompt');
        tracker.events.experimentAiGenerationPromptDiscarded({
          pinType: 'quiz',
          openAiPrompt: lastGeneratedPrompt.current,
        });
      }
    };

    const promptStep = <InteractivePromptStep register={register} watch={watch} type="quiz" />;
    const reviewStep = (
      <ReviewStep
        generatedResults={quizResponse.map((question) => {
          return {
            tempId: question.tempId,
            content: {
              type: 'quiz',
              answers: question.answers,
              correctAnswers: question.correctAnswer,
              question: question.question,
            },
          };
        })}
        setSelection={setSelection}
        selection={selection}
        isCompleted={true}
      />
    );
    const promptAction: ModalFooterActionAsync = {
      onClick: handleSubmit(onGenerateQuiz),
      type: 'submit',
      label: t('generate'),
      async: true,
      disabled: watch('prompt')?.length > 0 ? false : true,
    };
    const reviewAction: ModalFooterAction = {
      onClick: finishReview,
      type: 'submit',
      label: t('reviewSlides.createSlides'),
      disabled: !selection.length,
    };
    const changePrompt: ModalFooterActionAsync = {
      onClick: confirmChangePrompt,
      type: 'reset',
      label: t('reviewSlides.changePrompt'),
      async: true,
    };

    return (
      <ManagedModal modal={modal} contentLabel="label" width={rem('560px')} height={rem('720px')}>
        <ModalHeaderV1
          type="headline"
          title={{
            content: t('generateQuiz'),
          }}
          onCloseButtonClick={onCloseClick}
          endSlot={<HelpButton />}
          showDivider
        />
        <ChatGPTWizard promptStep={promptStep} currentStep={currentStep} reviewStep={reviewStep} />

        {currentStep !== 'loading' && (
          <ModalFooter
            type="actions"
            text={t('stepXOf', { current: currentStep === 'prompt' ? '1' : '2', totalSteps: '2' })}
            primaryAction={currentStep === 'review' ? reviewAction : promptAction}
            secondaryAction={currentStep === 'review' ? changePrompt : undefined}
            showDivider
          />
        )}
      </ManagedModal>
    );
  }
);
