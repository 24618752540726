type DesignSystemCSSColors = {
  [key: string]: DesignSystemCSSColor;
};

/**
 * Represents the color sets from our design system.
 */
type DesignSystemCSSColor = {
  /**
   * The color value for text elements.
   */
  text?: DesignSystemCSSColorValues;

  /**
   * The color value for background elements.
   */
  background?: DesignSystemCSSColorValues;

  /**
   * The color value for hover state of elements.
   */
  hover?: DesignSystemCSSColorValues;

  /**
   * Border color for items in a component.
   */
  stroke?: DesignSystemCSSColorValues;

  /**
   * Border color for nested items of lesser importance in a component.
   */
  strokeSubjacent?: DesignSystemCSSColorValues;

  /**
   * Color for nested items in a component.
   */
  on?: DesignSystemCSSColorValues;

  /**
   * Color for nested items of lesser importance in a component.
   */
  onSubjacent?: DesignSystemCSSColorValues;

  /**
   * Background color for a component.
   */
  fill?: DesignSystemCSSColorValues;

  /**
   * Background color for nested items of lesser importance in a component.
   */
  fillSubjacent?: DesignSystemCSSColorValues;

  /**
   * Background color for a hovered component.
   */
  fillFeedback?: DesignSystemCSSColorValues;

  /**
   * Second color of a gradient in the color set.
   */
  fillGradientStop?: DesignSystemCSSColorValues;

  /**
   * Gradiant background for a component.
   */
  fillGradient?: DesignSystemCSSColorValues;
};

type DesignSystemCSSColorValues = {
  cssVar: string;
  light: string;
  dark: string;
};

export const cssColors: DesignSystemCSSColors = {
  primary: {
    text: {
      cssVar: '--color-primary-text',
      light: '#E5FF45',
      dark: '#001343',
    },
    background: {
      cssVar: '--color-primary-background',
      light: '#001343',
      dark: '#E5FF45',
    },
    hover: {
      cssVar: '--color-primary-hover',
      light: '#102969',
      dark: '#CEE63E',
    },
  },
  primaryContainer: {
    text: {
      cssVar: '--color-primary-container-text',
      light: '#001343',
      dark: '#E5FF45',
    },
    background: {
      cssVar: '--color-primary-container-background',
      light: '#E5FF45',
      dark: '#001343',
    },
    hover: {
      cssVar: '--color-primary-container-hover',
      light: '#CEE63E',
      dark: '##102969',
    },
  },
  secondary: {
    text: {
      cssVar: '--color-secondary-text',
      light: '#EFF4FF',
      dark: '#EFF4FF',
    },
    background: {
      cssVar: '--color-secondary-background',
      light: '#4E7FFF',
      dark: '#4E7FFF',
    },
    hover: {
      cssVar: '--color-secondary-hover',
      light: '#7a9fff',
      dark: '#3E69D9',
    },
  },
  secondaryContainer: {
    text: {
      cssVar: '--color-secondary-container-text',
      light: '#4E7FFF',
      dark: '#CAD9FF',
    },
    background: {
      cssVar: '--color-secondary-container-background',
      light: '#EFF4FF',
      dark: '#102969',
    },
    hover: {
      cssVar: '--color-secondary-container-hover',
      light: '#DFE8FF',
      dark: '#1C3A87',
    },
  },
  tertiary: {
    text: {
      cssVar: '--color-tertiary-text',
      light: '#5957C9',
      dark: '#D2D1FF',
    },
    background: {
      cssVar: '--color-tertiary-background',
      light: '#D2D1FF',
      dark: '#5957C9',
    },
  },
  tertiaryContainer: {
    text: {
      cssVar: '--color-tertiary-container-text',
      light: '#6F6EBA',
      dark: '#D2D1FF',
    },
    background: {
      cssVar: '--color-tertiary-container-background',
      light: '#F4F3FF',
      dark: '#1D1C68',
    },
  },
  surface: {
    text: {
      cssVar: '--color-surface-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background',
      light: '#FFFFFF',
      dark: '#001343',
    },
    hover: {
      cssVar: '--color-surface-hover',
      light: 'rgba(0, 19, 67, 0.04)',
      dark: 'rgba(255, 255, 255, 0.04)',
    },
  },
  surface1: {
    text: {
      cssVar: '--color-surface1-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background-1',
      light: '#F7F5F2',
      dark: 'rgba(255, 255, 255, 0.04)',
    },
  },
  surface2: {
    text: {
      cssVar: '--color-surface2-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background-2',
      light: '#EFEAE5',
      dark: 'rgba(255, 255, 255, 0.05)',
    },
  },
  surface3: {
    text: {
      cssVar: '--color-surface3-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background-3',
      light: '#E7E0D9',
      dark: 'rgba(255, 255, 255, 0.1)',
    },
  },
  surface4: {
    text: {
      cssVar: '--color-surface4-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background-4',
      light: '#DFD5CC ',
      dark: 'rgba(255, 255, 255, 0.2)',
    },
  },
  surface5: {
    text: {
      cssVar: '--color-surface5-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-surface-background-5',
      light: '#D7CBBF',
      dark: 'rgba(255, 255, 255, 0.3)',
    },
  },
  overlay: {
    text: {
      cssVar: '--color-overlay-text',
      light: '#FFFFFF',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-overlay-background',
      light: 'rgba(0, 19, 67, 0.5)',
      dark: 'rgba(255, 255, 255, 0.5)',
    },
  },
  overlaySurface: {
    text: {
      cssVar: '--color-overlay-surface-text',
      light: '#001343',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-overlay-surface-background',
      light: 'rgba(255, 255, 255, 0.9)',
      dark: 'rgba(0, 19, 67, 0.9)',
    },
  },
  outline: {
    background: {
      cssVar: '--color-outline-background',
      light: 'rgba(0, 19, 67, 0.13)',
      dark: 'rgba(255, 255, 255, 0.13)',
    },
    hover: {
      cssVar: '--color-outline-hover',
      light: 'rgba(0, 19, 67, 0.42)',
      dark: 'rgba(255, 255, 255, 0.42)',
    },
  },
  error: {
    text: {
      cssVar: '--color-error-text',
      light: '#FDEDED',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-error-background',
      light: '#EC484D',
      dark: '#EC484D',
    },
  },
  errorContainer: {
    text: {
      cssVar: '--color-error-container-text',
      light: '#EC484D',
      dark: '#EC484D',
    },
    background: {
      cssVar: '--color-error-container-background',
      light: '#FDEDED',
      dark: '#4C0000',
    },
  },
  success: {
    text: {
      cssVar: '--color-success-text',
      light: '#E4F3E5',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-success-background',
      light: '#4CAF50',
      dark: '#4CAF50',
    },
  },
  successContainer: {
    text: {
      cssVar: '--color-success-container-text',
      light: '#4CAF50',
      dark: '#4CAF50',
    },
    background: {
      cssVar: '--color-success-container-background',
      light: '#E4F3E5',
      dark: '#0A4B0A',
    },
  },
  warning: {
    text: {
      cssVar: '--color-warning-text',
      light: '#FFF5D7',
      dark: '#FFF5D7',
    },
    background: {
      cssVar: '--color-warning-background',
      light: '#C37C12',
      dark: '#C37C12',
    },
  },
  warningContainer: {
    text: {
      cssVar: '--color-warning-container-text',
      light: '#C37C12',
      dark: '#C37C12',
    },
    background: {
      cssVar: '--color-warning-container-background',
      light: '#FFF5D7',
      dark: '#FFF5D7',
    },
  },
  focus: {
    text: {
      cssVar: '--color-focus-text',
      light: '#F4EEFF',
      dark: '#F4EEFF',
    },
    background: {
      cssVar: '--color-focus-background',
      light: '#A64EFF',
      dark: '#A64EFF',
    },
  },
  disabled: {
    text: {
      cssVar: '--color-disabled-text',
      light: 'rgba(0, 19, 67, 0.6)',
      dark: 'rgba(255, 255, 255, 0.6)',
    },
    background: {
      cssVar: '--color-disabled-background',
      light: 'rgba(0, 19, 67, 0.125)',
      dark: 'rgba(255, 255, 255, 0.15)',
    },
  },
  disabledSurface: {
    text: {
      cssVar: '--color-disabled-surface-text',
      light: '#AE977F',
      dark: 'rgba(255, 255, 255, 0.6)',
    },
    background: {
      cssVar: '--color-disabled-surface-background',
      light: 'rgba(174, 151, 127, 0.1)',
      dark: 'rgba(255, 255, 255, 0.15)',
    },
  },
  highContrast: {
    text: {
      cssVar: '--color-high-contrast-text',
      light: '#fff',
      dark: '#000',
    },
    background: {
      cssVar: '--color-high-contrast-background',
      light: '#000',
      dark: '#fff',
    },
  },
  highContrastDisabled: {
    text: {
      cssVar: '--color-high-contrast-disabled-text',
      light: 'rgba(0,0,0,.5)',
      dark: 'rgba(255,255,255, .5)',
    },
    background: {
      cssVar: '--color-high-contrast-disabled-background',
      light: 'rgba(0,0,0,.05)',
      dark: 'rgba(255,255,255, .05)',
    },
  },
  maia: {
    text: {
      cssVar: '--color-maia-text',
      light: '#FFFFFF',
      dark: '#FFFFFF',
    },
    background: {
      cssVar: '--color-maia-gradient',
      light: 'linear-gradient(135deg, #4E7FFF, #A64EFF)',
      dark: 'linear-gradient(135deg, #4E7FFF, #A64EFF)',
    },
  },
  gradient1: {
    background: {
      cssVar: '--color-gradient-1',
      light: 'linear-gradient(135deg, #CAD9FF, #B9B8FF)',
      dark: 'linear-gradient(135deg, #CAD9FF, #B9B8FF)',
    },
  },
  gradient2: {
    background: {
      cssVar: '--color-gradient-2',
      light: 'linear-gradient(180deg, rgba(223, 232, 255, 100), rgba(223, 232, 255, 0))',
      dark: 'linear-gradient(180deg, rgba(223, 232, 255, 100), rgba(223, 232, 255, 0))',
    },
  },
  loaderSkeleton: {
    background: {
      cssVar: '--color-loader-skeleton-gradient-start',
      light: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 75), rgba(255, 255, 255, 0))',
      dark: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 75), rgba(255, 255, 255, 0))',
    },
  },
};

// New color sets
export const cssColorsNew: DesignSystemCSSColors = {
  level1: {
    stroke: {
      cssVar: '--color-new-level1-stroke',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    strokeSubjacent: {
      cssVar: '--color-new-level1-strokesubjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-level1-on',
      light: '#001343',
      dark: '#FFFFFF',
    },
    onSubjacent: {
      cssVar: '--color-new-level1-onSubjacent',
      light: '#00134399',
      dark: '#FFFFFF99',
    },
    fill: {
      cssVar: '--color-new-level1-fill',
      light: '#FFFFFF',
      dark: 'transparent',
    },
    fillSubjacent: {
      cssVar: '--color-new-level1-fillSubjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-level1-fillFeedback',
      light: '#0013430A',
      dark: '#FFFFFF0A',
    },
  },
  primary: {
    stroke: {
      cssVar: '--color-new-primary-stroke',
      light: 'transparent',
      dark: 'transparent',
    },
    strokeSubjacent: {
      cssVar: '--color-new-primary-stroke-subjacent',
      light: '#FFFFFF20',
      dark: '#00000026',
    },
    on: {
      cssVar: '--color-new-primary-on',
      light: '#E5FF45',
      dark: '#001343',
    },
    onSubjacent: {
      cssVar: '--color-new-primary-on-subjacent',
      light: '#FFFFFF99',
      dark: '#00000099',
    },
    fill: {
      cssVar: '--color-new-primary-fill',
      light: '#001343',
      dark: '#E5FF45',
    },
    fillSubjacent: {
      cssVar: '--color-new-primary-fill-subjacent',
      light: '#FFFFFF20',
      dark: '#00000020',
    },
    fillFeedback: {
      cssVar: '--color-new-primary-fillFeedback',
      light: '#102969',
      dark: '#CEE63E',
    },
  },
  secondary: {
    stroke: {
      cssVar: '--color-new-secondary-stroke',
      light: 'transparent',
      dark: 'transparent',
    },
    strokeSubjacent: {
      cssVar: '--color-new-secondary-stroke-subjacent',
      light: '#FFFFFF99',
      dark: '#00000026',
    },
    on: {
      cssVar: '--color-new-secondary-on',
      light: '#EFF4FF',
      dark: '#8AAEFF',
    },
    onSubjacent: {
      cssVar: '--color-new-secondary-on-subjacent',
      light: '#FFFFFF99',
      dark: '#00000099',
    },
    fill: {
      cssVar: '--color-new-secondary-fill',
      light: '#4E7FFF',
      dark: '#0040E5',
    },
    fillSubjacent: {
      cssVar: '--color-new-secondary-fill-subjacent',
      light: '#FFFFFF20',
      dark: '#00000020',
    },
    fillFeedback: {
      cssVar: '--color-new-secondary-fillFeedback',
      light: '#7A9FFF',
      dark: '#1456FF',
    },
  },
  tertiary: {
    stroke: {
      cssVar: '--color-new-tertiary-stroke',
      light: 'transparent',
      dark: 'transparent',
    },
    strokeSubjacent: {
      cssVar: '--color-new-tetirary-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-tertiary-on',
      light: '#5957C9',
      dark: '#EBEBFF',
    },
    onSubjacent: {
      cssVar: '--color-new-tetirary-on-subjacent',
      light: '#00134399',
      dark: '#FFFFFF99',
    },
    fill: {
      cssVar: '--color-new-tertiary-fill',
      light: '#D2D1FF',
      dark: '#6E6BFF',
    },
    fillSubjacent: {
      cssVar: '--color-new-tertiary-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-tertiary-fillFeedback',
      light: '#EBEBFF',
      dark: '#6E6BFF',
    },
  },
  maia: {
    stroke: {
      cssVar: '--color-new-maia-stroke',
      light: 'transparent',
      dark: 'transparent',
    },
    strokeSubjacent: {
      cssVar: '--color-new-maia-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-maia-on',
      light: '#EFF4FF',
      dark: '#8AAEFF',
    },
    onSubjacent: {
      cssVar: '--color-new-maia-on-subjacent',
      light: '#00134399',
      dark: '#FFFFFF99',
    },
    fill: {
      cssVar: '--color-new-maia-fill',
      light: '#4E7FFF',
      dark: '#0040E5',
    },
    fillSubjacent: {
      cssVar: '--color-new-maia-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-maia-fillFeedback',
      light: '#7A9FFF',
      dark: '#FFFFFF',
    },
    fillGradientStop: {
      cssVar: '--color-new-maia-fill-subjacent',
      light: '#A64EFF',
      dark: '#7300E5',
    },
    fillGradient: {
      cssVar: '--color-new-maia-fillGradient',
      light: 'linear-gradient(135deg, #4E7FFF, #A64EFF)',
      dark: 'linear-gradient(135deg, #0040E5, #7300E5)',
    },
  },
  active: {
    stroke: {
      cssVar: '--color-new-active-stroke',
      light: '#7A9FFF',
      dark: '#1456FF',
    },
    strokeSubjacent: {
      cssVar: '--color-new-active-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-active-on',
      light: '#4E7FFF',
      dark: '#0040E5',
    },
    onSubjacent: {
      cssVar: '--color-new-active-on-subjacent',
      light: '#00134399',
      dark: '#FFFFFF20',
    },
    fill: {
      cssVar: '--color-new-active-fill',
      light: '#EFF4FF',
      dark: '#8AAEFF',
    },
    fillSubjacent: {
      cssVar: '--color-new-active-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-active-fillFeedback',
      light: '#EFF4FF',
      dark: '#8AAEFF',
    },
  },
  disabled: {
    stroke: {
      cssVar: '--color-new-disabled-stroke',
      light: 'transparent',
      dark: 'transparent',
    },
    strokeSubjacent: {
      cssVar: '--color-new-disabled-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-disabled-on',
      light: '#00134399',
      dark: '#FFFFFF99',
    },
    onSubjacent: {
      cssVar: '--color-new-disabled-on-subjacent',
      light: '#00134399',
      dark: '#00000099',
    },
    fill: {
      cssVar: '--color-new-disabled-fill',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillSubjacent: {
      cssVar: '--color-new-disabled-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-active-fillFeedback',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
  },
  negative: {
    stroke: {
      cssVar: '--color-new-negative-stroke',
      light: '#EC484D',
      dark: '#FFE8ED',
    },
    strokeSubjacent: {
      cssVar: '--color-new-negative-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-negative-on',
      light: '#EC484D',
      dark: '#FFE8ED',
    },
    onSubjacent: {
      cssVar: '--color-new-negative-on-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    fill: {
      cssVar: '--color-new-negative-fill',
      light: '#FFE8ED',
      dark: '#8B0E12',
    },
    fillSubjacent: {
      cssVar: '--color-new-negative-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-negative-fillFeedback',
      light: '#FFB2C3',
      dark: '#B91318',
    },
  },
  positive: {
    stroke: {
      cssVar: '--color-new-positive-stroke',
      light: '#42A546',
      dark: '#ECFFF0',
    },
    strokeSubjacent: {
      cssVar: '--color-new-positive-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-positive-on',
      light: '#42A546',
      dark: '#ECFFF0',
    },
    onSubjacent: {
      cssVar: '--color-new-positive-on-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    fill: {
      cssVar: '--color-new-positive-fill',
      light: '#ECFFF0',
      dark: '#245B27',
    },
    fillSubjacent: {
      cssVar: '--color-new-positive-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-positive-fillFeedback',
      light: '#B8FFC7',
      dark: '#338037',
    },
  },
  warning: {
    stroke: {
      cssVar: '--color-new-warning-stroke',
      light: '#C37C12',
      dark: '#FFF5D7',
    },
    strokeSubjacent: {
      cssVar: '--color-new-warning-stroke-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    on: {
      cssVar: '--color-new-warning-on',
      light: '#C37C12',
      dark: '#FFF5D7',
    },
    onSubjacent: {
      cssVar: '--color-new-warning-on-subjacent',
      light: '#00134320',
      dark: '#FFFFFF26',
    },
    fill: {
      cssVar: '--color-new-warning-fill',
      light: '#FFF5D7',
      dark: '#674109',
    },
    fillSubjacent: {
      cssVar: '--color-new-warning-fill-subjacent',
      light: '#00134320',
      dark: '#FFFFFF20',
    },
    fillFeedback: {
      cssVar: '--color-new-warning-fillFeedback',
      light: '#FFE8A3',
      dark: '#965F0D',
    },
  },
};

export const color = {
  accent: {
    primary: {
      text: `var(${cssColors.primary.text?.cssVar})`,
      background: `var(${cssColors.primary.background?.cssVar})`,
      hover: `var(${cssColors.primary.hover?.cssVar})`,
    },
    primaryContainer: {
      text: `var(${cssColors.primaryContainer.text?.cssVar})`,
      background: `var(${cssColors.primaryContainer.background?.cssVar})`,
      hover: `var(${cssColors.primaryContainer.hover?.cssVar})`,
    },
    secondary: {
      text: `var(${cssColors.secondary.text?.cssVar})`,
      background: `var(${cssColors.secondary.background?.cssVar})`,
      hover: `var(${cssColors.secondary.hover?.cssVar})`,
    },
    secondaryContainer: {
      text: `var(${cssColors.secondaryContainer.text?.cssVar})`,
      background: `var(${cssColors.secondaryContainer.background?.cssVar})`,
      hover: `var(${cssColors.secondaryContainer.hover?.cssVar})`,
    },
    tertiary: {
      text: `var(${cssColors.tertiary.text?.cssVar})`,
      background: `var(${cssColors.tertiary.background?.cssVar})`,
    },
    tertiaryContainer: {
      text: `var(${cssColors.tertiaryContainer.text?.cssVar})`,
      background: `var(${cssColors.tertiaryContainer.background?.cssVar})`,
    },
  },

  neutral: {
    surface: {
      text: `var(${cssColors.surface.text?.cssVar})`,
      background: `var(${cssColors.surface.background?.cssVar})`,
      hover: `var(${cssColors.surface.hover?.cssVar})`,
    },
    surface1: {
      text: `var(${cssColors.surface1.text?.cssVar})`,
      background: `var(${cssColors.surface1.background?.cssVar})`,
    },
    surface2: {
      text: `var(${cssColors.surface2.text?.cssVar})`,
      background: `var(${cssColors.surface2.background?.cssVar})`,
    },
    surface3: {
      text: `var(${cssColors.surface3.text?.cssVar})`,
      background: `var(${cssColors.surface3.background?.cssVar})`,
    },
    surface4: {
      text: `var(${cssColors.surface4.text?.cssVar})`,
      background: `var(${cssColors.surface4.background?.cssVar})`,
    },
    surface5: {
      text: `var(${cssColors.surface5.text?.cssVar})`,
      background: `var(${cssColors.surface5.background?.cssVar})`,
    },
    overlay: {
      text: `var(${cssColors.overlay.text?.cssVar})`,
      background: `var(${cssColors.overlay.background?.cssVar})`,
    },
    overlaySurface: {
      text: `var(${cssColors.overlaySurface.text?.cssVar})`,
      background: `var(${cssColors.overlaySurface.background?.cssVar})`,
    },
    outline: {
      background: `var(${cssColors.outline.background?.cssVar})`,
      hover: `var(${cssColors.outline.hover?.cssVar})`,
    },
  },

  additional: {
    error: {
      text: `var(${cssColors.error.text?.cssVar})`,
      background: `var(${cssColors.error.background?.cssVar})`,
      hover: `var(${cssColors.error.hover?.cssVar})`,
    },
    errorContainer: {
      text: `var(${cssColors.errorContainer.text?.cssVar})`,
      background: `var(${cssColors.errorContainer.background?.cssVar})`,
    },
    success: {
      text: `var(${cssColors.success.text?.cssVar})`,
      background: `var(${cssColors.success.background?.cssVar})`,
    },
    successContainer: {
      text: `var(${cssColors.successContainer.text?.cssVar})`,
      background: `var(${cssColors.successContainer.background?.cssVar})`,
    },
    warning: {
      text: `var(${cssColors.warning.text?.cssVar})`,
      background: `var(${cssColors.warning.background?.cssVar})`,
    },
    warningContainer: {
      text: `var(${cssColors.warningContainer.text?.cssVar})`,
      background: `var(${cssColors.warningContainer.background?.cssVar})`,
    },
    focus: {
      text: `var(${cssColors.focus.text?.cssVar})`,
      background: `var(${cssColors.focus.background?.cssVar})`,
    },
    disabled: {
      text: `var(${cssColors.disabled.text?.cssVar})`,
      background: `var(${cssColors.disabled.background?.cssVar})`,
    },
    disabledSurface: {
      text: `var(${cssColors.disabledSurface.text?.cssVar})`,
      background: `var(${cssColors.disabledSurface.background?.cssVar})`,
    },
  },
  highContrast: {
    surface: {
      text: `var(${cssColors.highContrast.text?.cssVar})`,
      background: `var(${cssColors.highContrast.background?.cssVar})`,
    },
    disabled: {
      text: `var(${cssColors.highContrastDisabled.text?.cssVar})`,
      background: `var(${cssColors.highContrastDisabled.background?.cssVar})`,
    },
  },
  productSuite: {
    maia: {
      text: `var(${cssColors.maia.text?.cssVar})`,
      background: `var(${cssColors.maia.background?.cssVar})`,
    },
  },
  gradients: {
    gradient1: {
      background: `var(${cssColors.gradient1.background?.cssVar})`,
    },
    gradient2: {
      background: `var(${cssColors.gradient2.background?.cssVar})`,
    },
    loaderSkeleton: {
      background: `var(${cssColors.loaderSkeleton.background?.cssVar})`,
    },
  },

  // New color sets
  neutralNew: {
    level1: {
      stroke: `var(${cssColorsNew.level1.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.level1.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.level1.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.level1.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.level1.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.level1.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.level1.fillFeedback?.cssVar})`,
    },
  },
  hierarchy: {
    primary: {
      stroke: `var(${cssColorsNew.primary.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.primary.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.primary.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.primary.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.primary.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.primary.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.primary.fillFeedback?.cssVar})`,
    },
    secondary: {
      stroke: `var(${cssColorsNew.secondary.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.secondary.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.secondary.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.secondary.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.secondary.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.secondary.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.secondary.fillFeedback?.cssVar})`,
    },
    tertiary: {
      stroke: `var(${cssColorsNew.tertiary.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.tertiary.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.tertiary.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.tertiary.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.tertiary.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.tertiary.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.tertiary.fillFeedback?.cssVar})`,
    },
  },
  product: {
    maia: {
      stroke: `var(${cssColorsNew.maia.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.maia.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.maia.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.maia.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.maia.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.maia.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.maia.fillFeedback?.cssVar})`,
      fillGradientStop: `var(${cssColorsNew.maia.fillGradientStop?.cssVar})`,
      fillGradient: `var(${cssColorsNew.maia.fillGradient?.cssVar})`,
    },
  },
  setting: {
    active: {
      stroke: `var(${cssColorsNew.active.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.active.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.active.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.active.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.active.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.active.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.active.fillFeedback?.cssVar})`,
    },
    disabled: {
      stroke: `var(${cssColorsNew.disabled.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.disabled.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.disabled.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.disabled.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.disabled.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.disabled.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.disabled.fillFeedback?.cssVar})`,
    },
  },
  message: {
    negative: {
      stroke: `var(${cssColorsNew.negative.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.negative.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.negative.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.negative.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.negative.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.negative.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.negative.fillFeedback?.cssVar})`,
    },
    positive: {
      stroke: `var(${cssColorsNew.positive.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.positive.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.positive.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.positive.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.positive.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.positive.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.positive.fillFeedback?.cssVar})`,
    },
    warning: {
      stroke: `var(${cssColorsNew.warning.stroke?.cssVar})`,
      strokeSubjacent: `var(${cssColorsNew.warning.strokeSubjacent?.cssVar})`,
      on: `var(${cssColorsNew.warning.on?.cssVar})`,
      onSubjacent: `var(${cssColorsNew.warning.onSubjacent?.cssVar})`,
      fill: `var(${cssColorsNew.warning.fill?.cssVar})`,
      fillSubjacent: `var(${cssColorsNew.warning.fillSubjacent?.cssVar})`,
      fillFeedback: `var(${cssColorsNew.warning.fillFeedback?.cssVar})`,
    },
  },
  primitives: {
    black: `#000000`,
    white: `#FFFFFF`,
    focusRing: `#BF80FF`,
  },
};

export const cssClassDarkMode = 'dark-mode';

export function getGlobalDesignSystemColorsCss(): string {
  return `
    :root {
      ${getCssColorProperties(false)}
    }

    // Dark mode disabled until further notice
    // .${cssClassDarkMode} {
    //   ${getCssColorProperties(true)}
    // }

    // @media (prefers-color-scheme: dark) {
    //   :root {
    //     ${getCssColorProperties(true)}
    //   }
    // }
  `;
}
function getCssColorProperties(prefersColorSchemeDark: boolean): string {
  let css = '';

  // create CSS for every color property: --css-variable: [HEX];
  Object.values(cssColors).forEach((variable) => {
    Object.values(variable).forEach((variantsInVariable) => {
      css += `${variantsInVariable.cssVar}: ${
        prefersColorSchemeDark ? variantsInVariable.dark : variantsInVariable.light
      };`;
    });
  });

  // New color sets
  Object.values(cssColorsNew).forEach((variable) => {
    Object.values(variable).forEach((variantsInVariable) => {
      css += `${variantsInVariable.cssVar}: ${
        prefersColorSchemeDark ? variantsInVariable.dark : variantsInVariable.light
      };`;
    });
  });

  return css;
}
