import { Size, Vector } from '@lessonup/pins-shared';

/** Converts a vector to a CSS position literal. */
export function cssPosition({ x, y }: Vector) {
  return `${x}px ${y}px`;
}

/** Converts a size to a CSS size literal. */
export function cssSize({ width, height }: Size) {
  return `${width}px ${height}px`;
}

/** Converts a vector to a CSS `translate` transform. */
export function cssTranslate(v: Vector) {
  return `translate(${v.x}px, ${v.y}px)`;
}

/** Converts an angle to a CSS `rotate` transform. */
export function cssRotateDeg(angle: number) {
  return `rotate(${angle}deg)`;
}

/** Converts a number to a CSS `scale` transform. */
export function cssScale(factor: number) {
  return `scale(${factor})`;
}
