import { PaneId } from '../EditorContextSidebar.utils';

export type EditorContextSidebarAction = 'replaceImage' | 'replaceVideo' | 'setBackgroundImage' | 'setBackgroundColor';

const status: { pane: PaneId; action: EditorContextSidebarAction | null } = {
  pane: 'settings',
  action: null,
};

type Listener = () => void;

let listeners: Listener[] = [];

export const editorContextSidebarStore = {
  set(pane: PaneId) {
    status.pane = pane;
    emitChange();
  },
  toggle(pane: PaneId) {
    status.pane = status.pane === pane ? 'none' : pane;
    status.action = null;

    emitChange();
  },
  callAction(pane: PaneId, action: EditorContextSidebarAction) {
    status.pane = pane;
    status.action = action;
    emitChange();
  },
  clearAction() {
    status.action = null;
  },
  subscribe(listener: Listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot(): PaneId {
    return status.pane;
  },
  getActionSnapshot(): EditorContextSidebarAction | null {
    return status.action;
  },
};

function emitChange() {
  for (const listener of listeners) {
    listener();
  }
}
