import { AsyncButton, AsyncButtonProps, Button, ButtonProps, Tooltip, TooltipDirection } from '@lessonup/ui-components';
import React, { FC, forwardRef } from 'react';
import { ButtonWithPopover, ButtonWithPopoverProps } from '../../../ButtonWithPopover/ButtonWithPopover';

export interface PlayerBarBottomButtonProps extends ButtonProps {
  label: string;
  tipped?: boolean;
  active?: boolean;
  tooltipDirection?: TooltipDirection;
}

export const PlayerBarBottomButton = forwardRef(function PlayerBarButton(
  props: PlayerBarBottomButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { label, tipped, ...rest } = props;

  return (
    <OptionalTooltip label={label} tipped={tipped} direction={props.tooltipDirection}>
      <Button ref={ref} buttonShape="rect" buttonType="neutral" active={props.active} aria-label={label} {...rest} />
    </OptionalTooltip>
  );
});

export interface PlayerBarBottomAsyncButtonProps extends AsyncButtonProps {
  label: string;
  tipped?: boolean;
  onError?: (error: unknown) => void;
}

export const PlayerBarBottomAsyncButton = forwardRef(function PlayerBarButton(
  props: PlayerBarBottomAsyncButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { label, tipped, ...rest } = props;

  return (
    <OptionalTooltip label={label} tipped={tipped}>
      <AsyncButton
        ref={ref}
        buttonShape="rect"
        buttonType="neutral"
        aria-label={label}
        onError={props.onError}
        {...rest}
      />
    </OptionalTooltip>
  );
});

export interface PlayerBarBottomButtonWithPopoverProps
  extends Omit<ButtonWithPopoverProps, 'buttonShape' | 'buttonType' | 'aria-label'> {
  label: string;
  tipped?: boolean;
}

export function PlayerBarBottomButtonWithPopover(props: PlayerBarBottomButtonWithPopoverProps) {
  const { label, tipped, ...rest } = props;
  return (
    <OptionalTooltip label={label} tipped={tipped}>
      <ButtonWithPopover buttonShape="rect" buttonType="neutral" aria-label={label} {...rest} />
    </OptionalTooltip>
  );
}

interface WrapperProps {
  label: string;
  tipped?: boolean;
  direction?: TooltipDirection;
  children: React.ReactNode;
}

const OptionalTooltip: FC<WrapperProps> = ({ label, tipped, direction = 'top', children }) => {
  if (!tipped) return <>{children}</>;

  return (
    <Tooltip content={label} resize="fixed" display="inline-block" direction={direction}>
      {children}
    </Tooltip>
  );
};
