import { createLessonUpTemplate, Pin, QuizPinLayoutTemplate, UpdatePinDispatch } from '@lessonup/pin-renderer';
import { color, IconCopy, IconCut, IconDuplicate, IconPaste, IconRemove, IconRobot } from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import { first } from 'lodash';
import { ContextMenuItemList } from '../../../../../components/ContextMenuProvider/ContextMenuProvider.types';
import { DeletePins, SetActivePinId } from '../../../context/EditorContext/EditorContext.types';
import { copyPinsToClipboard, copyYjsPinsToClipboard } from '../../../hooks/useCopyPaste.utils';
import { pinsContentToString } from '../../../utils/maia/maia.utils';
import { PinRailContextMenuItemProps } from './pinRailContextMenu.types';

export const PIN_RAIL_CONTEXT_MENU_ID = 'PIN_RAIL_CONTEXT_MENU';

const getSelectedPinIds = (pinSelection: Pin[], itemPin: Pin): string[] => {
  return getSelectedPins(pinSelection, itemPin).map((pin) => pin.id);
};

const getSelectedPins = (pinSelection: Pin[], itemPin: Pin): Pin[] => {
  return pinSelection.some((pin) => pin.id === itemPin.id) ? pinSelection : [itemPin];
};

export const getMenuItems = (
  deletePins: DeletePins,
  pastePins: (afterPinId: string | undefined) => void,
  handleClipboardError: (error: Error) => void,
  startGenerateMaiaQuiz: (afterPinId: string, layout: QuizPinLayoutTemplate, prompt?: string) => void,
  setActivePinId: SetActivePinId,
  dispatch: UpdatePinDispatch,
  pinSelection: Pin[]
): ContextMenuItemList<PinRailContextMenuItemProps>[] => [
  {
    commandDescription: 'generateQuiz',
    svgIcon: IconRobot,
    action: (item) => {
      const pins = getSelectedPins(pinSelection, item.pin);
      const template = createLessonUpTemplate();
      const quizPinLayout = first(template.layouts.quizPinLayouts);
      const prompt = pinsContentToString(item.yDoc, pins);
      quizPinLayout && startGenerateMaiaQuiz(item.pin.id, quizPinLayout, prompt);
    },
  },
  { type: 'separator' },
  {
    commandDescription: 'cut',
    svgIcon: IconCut,
    action: async (item) => {
      const pinIds = getSelectedPinIds(pinSelection, item.pin);
      deletePins(pinIds, (deletedPins) => {
        try {
          copyPinsToClipboard(deletedPins);
        } catch (e) {
          handleClipboardError(asError(e));
        }
      });
    },
    keyboardShortcut: {
      withMeta: true,
      key: 'X',
    },
  },
  {
    commandDescription: 'copy',
    svgIcon: IconCopy,
    action: async (item) => {
      const pins = getSelectedPins(pinSelection, item.pin);
      try {
        await copyYjsPinsToClipboard(item.yDoc, pins);
      } catch (e) {
        handleClipboardError(asError(e));
      }
    },
    keyboardShortcut: {
      withMeta: true,
      key: 'C',
    },
  },
  {
    commandDescription: 'paste',
    svgIcon: IconPaste,
    action: async (item) => {
      try {
        await pastePins(item.pin.id);
      } catch (e) {
        handleClipboardError(asError(e));
      }
    },
    keyboardShortcut: {
      withMeta: true,
      key: 'V',
    },
  },
  {
    commandDescription: 'duplicate',
    svgIcon: IconDuplicate,
    action: (item) => {
      const pins = getSelectedPins(pinSelection, item.pin);
      dispatch({
        type: 'duplicatePins',
        pinIds: pins.map((p) => p.id),
      });
    },
    keyboardShortcut: {
      withMeta: true,
      key: 'D',
    },
  },
  {
    commandDescription: 'remove',
    svgIcon: IconRemove,
    action: (item) => {
      const pinIds = getSelectedPinIds(pinSelection, item.pin);
      deletePins(pinIds);
    },
    keyboardShortcut: {
      withMeta: false,
      key: '⌫',
    },
    textColor: color.additional.error.background,
  },
];
