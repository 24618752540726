import {
  boxFromLine,
  isBoxLayout,
  Line,
  moveLine,
  newVector,
  PinComponent,
  pinSize,
  subtractVectors,
  Vector,
} from '@lessonup/pin-renderer';
import { isEqual, isNull } from 'lodash';

/**
 * The stagger step used will be the same for both axis.
 */
export const axisStaggerIncrementStep = 27;
export const xAxisMaxValue = pinSize.width * 0.9;
export const yAxisMaxValue = pinSize.height * 0.9;

export function calculateInitialPosition(tentativePosition: Vector, pinComponents?: PinComponent[]): Vector {
  if (!pinComponents) {
    return tentativePosition;
  }

  return pinComponents.map(normalizePositionToBoxLayoutPosition).reduce(tryToStaggerBoxLayout, tentativePosition);
}

export function calculateInitialLinePosition(tentativeLine: Line, pinComponents?: PinComponent[]): Line {
  if (!pinComponents) {
    return tentativeLine;
  }

  const conversion = boxFromLine(tentativeLine);
  const tentativePosition = pinComponents
    .map(normalizePositionToBoxLayoutPosition)
    .reduce(tryToStaggerBoxLayout, conversion.position);

  return moveLine(tentativeLine, subtractVectors(tentativePosition, conversion.position));
}

function normalizePositionToBoxLayoutPosition(pinComponent: PinComponent): Vector | null {
  if (isBoxLayout(pinComponent.layout)) {
    return pinComponent.layout.position;
  }
  return boxFromLine(pinComponent.layout).position;
}

function tryToStaggerBoxLayout(tentativePosition: Vector, componentPosition: Vector | null): Vector {
  if (isNull(componentPosition) || !canPositionIncrement(tentativePosition)) {
    return tentativePosition;
  }

  if (isEqual(tentativePosition, componentPosition)) {
    return newVector(nextTentativeAxis(tentativePosition.x), nextTentativeAxis(tentativePosition.y));
  }

  return tentativePosition;
}

function nextTentativeAxis(previousTentativeAxis: number): number {
  return previousTentativeAxis + axisStaggerIncrementStep;
}

function canPositionIncrement(tentativePosition: Vector): boolean {
  return tentativePosition.x < xAxisMaxValue && tentativePosition.y < yAxisMaxValue;
}
