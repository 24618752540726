import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconEuro = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.875 9.75h9m-9 4.5h9M19.125 6c-.341-.707-1.692-3-5.922-3s-6.767 3-6.767 9 2.537 9 6.767 9 5.487-2.195 5.922-3"
    />
  </svg>
);
export default SvgIconEuro;
