import { bind, Binder, ErrorYjsDict, getDataRoot, getErrorsMap, YjsLesson } from '@lessonup/editor-shared';
import { Doc, Map as YMap } from 'yjs';
import { UploadsYjs } from '../EditorUploadsYjs/EditorUploadsYjs.types';
import { createYjsContext, HocusPocusContextProps, YJSBinderConstructor } from '../YjsContext';

export type EditorBinders = {
  data: Binder<YjsLesson>;
  errors: Binder<ErrorYjsDict>;
  uploads: Binder<UploadsYjs>;
};

function getUploadsMap(yDoc: Doc) {
  return yDoc.getMap<YMap<UploadsYjs>>('uploads');
}

const createBinders: YJSBinderConstructor<EditorBinders> = (yDoc: Doc) => {
  const data = bind<YjsLesson>(getDataRoot(yDoc));
  const errors = bind<ErrorYjsDict>(getErrorsMap(yDoc));
  const uploads = bind<UploadsYjs>(getUploadsMap(yDoc));

  // Set initial data
  uploads.update((uploadsState) => {
    if (!uploadsState.optimistic) {
      uploadsState.optimistic = [];
    }
    if (!uploadsState.progress) {
      uploadsState.progress = {};
    }
  });

  return [
    { data, errors, uploads },
    () => {
      data.unbind();
      errors.unbind();
      uploads.unbind();
    },
  ];
};

const [Provider, useEditorYjs] = createYjsContext<EditorBinders>(createBinders);

export const EditorYjsContextProvider: React.FC<HocusPocusContextProps> = Provider;

export { useEditorYjs };
