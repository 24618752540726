import {
  IconArrowRight,
  InputFormField,
  ModalFooter,
  NiceModalHandler,
  styled,
  useForm,
  useWatch,
} from '@lessonup/ui-components';
import { isEmpty, isString } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LessonModalContent, LessonModalMainWrapper } from './LayoutComponents';
import { UnderContructionBanner } from './UnderConstructionBanner';

interface EditorSelectionProps {
  modal: NiceModalHandler<Record<string, unknown>>;
  editorType: 'v1' | 'v2';
  createManualLesson: (name: string, version: 'v1' | 'v2') => void;
}

export const EditorSelection: React.FC<EditorSelectionProps> = ({ modal, editorType, createManualLesson }) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ lessonTitle: string }>();

  const { t } = useTranslation('createLessonModal');

  const lessonTitle = useWatch({
    control,
    name: 'lessonTitle',
  });

  const handleCreateLesson = () => {
    if (!lessonTitle) return;
    const trimmedLessonTitle = lessonTitle.trim();
    createManualLesson(trimmedLessonTitle, editorType);
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleCreateLesson)}>
      <LessonModalMainWrapper>
        <LessonModalContent>
          <InputFormField
            type="text"
            label={t('lessonTitle')}
            {...register('lessonTitle', {
              required: { value: true, message: t('errors.lessonTitleFieldIsRequired') },
              setValueAs: (value) => (isString(value) && isEmpty(value.trim()) ? undefined : value),
              onChange: (e) => setValue('lessonTitle', e.target.value),
            })}
            maxLength={300}
            {...(errors['lessonTitle']?.message
              ? { validation: { state: 'error', message: errors['lessonTitle'].message } }
              : {})}
          />
          {editorType === 'v2' && <UnderContructionBanner />}
        </LessonModalContent>
        <ModalFooter
          type="actions"
          showDivider
          isSticky
          primaryAction={{
            onClick: handleSubmit(handleCreateLesson),
            label: t('createLesson'),
            iconEnd: <IconArrowRight />,
            type: 'submit',
          }}
          secondaryAction={{
            onClick: () => modal.hide(),
            label: t('cancel'),
            buttonType: 'neutral',
            showStroke: false,
          }}
        />
      </LessonModalMainWrapper>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
