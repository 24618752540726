import { isMacFromNavigator } from '@lessonup/client-integration';
import {
  BodyText,
  color,
  cssBase as css,
  cx,
  font,
  fontSize,
  lineHeight,
  ListHeader,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { compact } from 'lodash';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Item, ItemParams, Menu, Separator, Submenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { ListItemSwitchSetting } from '../../features/Editor/components/EditorContextSidebar/components/ListItemSwitchSetting/ListItemSwitchSetting';
import { ContextMenuItemList, ContextMenuItemProps, isSwitchItem } from './ContextMenuProvider.types';
import { isMenuHeader, isMenuSeparator, isSubmenuItem } from './ContextMenuProvider.utils';

export interface ContextMenuProviderPackageProps<T> {
  id: string;
  translationKey: string | undefined;
  menuItems: ContextMenuItemList<T>[];
  onVisibilityChange?: (visible: boolean) => void;
  title?: string;
}
export const ContextMenuProvider = <T,>({
  id,
  menuItems,
  translationKey,
  onVisibilityChange,
  title,
}: PropsWithChildren<ContextMenuProviderPackageProps<T>>): React.JSX.Element => {
  const { t } = useTranslation(translationKey);
  const items = useMemo(() => {
    const handleItemClick = ({ props, event }: ItemParams, item: ContextMenuItemProps<T>) => {
      event.preventDefault();
      item.action(props, event);
    };
    /**
     * Create a menu list from the items.
     * <Item> should be a direct child of <Menu>, do not add a fragment or props wont propagate
     * So this function should not be refactored into a seperate component
     */
    function MenuList(items: ContextMenuItemList<T>[]) {
      return items
        .filter((item) => !item.disabled)
        .map((item, i) => {
          if (isMenuSeparator(item)) return <Separator key={`${id}-${i}`} />;
          if (isMenuHeader(item))
            return (
              <Item closeOnClick={false} className={itemStyles} key={`${id}-${i}`}>
                <BodyText weight="bold">{translationKey ? t(item.text) : item.text}</BodyText>
              </Item>
            );
          if (isSubmenuItem(item)) {
            const SvgIcon = item.svgIcon;
            const Tag = item.tag;
            const label = (
              <>
                {item.iconClassName && <ClassIconWrapper className={cx('icon-dark', item.iconClassName)} />}
                {SvgIcon && (
                  <SvgIconWrapper>
                    <SvgIcon />
                  </SvgIconWrapper>
                )}
                <span>{translationKey ? t(item.label) : item.label}</span>
                {Tag && <Tag />}
              </>
            );
            return (
              <Submenu label={label} disabled={item.disabled} key={`item-${i}`} className={itemStyles}>
                {MenuList(item.items)}
              </Submenu>
            );
          }
          return MenuItem(item, i);
        });
    }

    function MenuItem(item: ContextMenuItemProps<T>, index: number) {
      const commandDescription = translationKey ? t(item.commandDescription) : item.commandDescription;
      const SvgIcon = item.svgIcon;
      const Tag = item.tag;
      const textColor = item.textColor;

      if (isSwitchItem(item)) {
        return (
          <Item closeOnClick={false} className={itemStyles} key={`${id}-${index}`}>
            <ListItemSwitchSetting
              ariaLabel={item.commandDescription}
              id={item.switchId}
              text={commandDescription}
              defaultChecked={item.defaultValue}
              onChange={item.onChange}
            />
          </Item>
        );
      }
      return (
        <Item
          className={itemStyles}
          key={`${id}-${index}`}
          onClick={(menuProps) => {
            handleItemClick(menuProps, item);
          }}
        >
          {item.iconClassName && (
            <ClassIconWrapper
              className={cx(
                'icon-dark',
                item.iconClassName,
                item.iconOpacity ? `opacity-${item.iconOpacity}` : undefined
              )}
            />
          )}
          {SvgIcon && (
            <SvgIconWrapper svgColor={textColor}>
              <SvgIcon />
            </SvgIconWrapper>
          )}

          <StyledSpan textColor={textColor} size="small">
            {commandDescription} {item.afterCommandDescription}
          </StyledSpan>
          {Tag && <Tag />}

          <KeyBoardShortcut shortcut={item.keyboardShortcut} className="context-menu-item__short-cut" />
        </Item>
      );
    }
    return MenuList(menuItems);
  }, [menuItems, t, translationKey, id]);

  const onContextMenu = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  return (
    <Menu
      className={menuStyle}
      onContextMenu={onContextMenu}
      id={id}
      animation="slide"
      onVisibilityChange={onVisibilityChange}
    >
      {title && <ListHeader text={title} priority="medium" indentation />}
      {items}
    </Menu>
  );
};
const iconSize = spacing.size16;
const SvgIconWrapper = styled.div<{ svgColor?: string }>`
  width: ${iconSize};
  height: ${iconSize};
  display: flex;
  svg {
    width: ${iconSize};
    height: ${iconSize};
    color: ${({ svgColor }) => svgColor ?? color.neutral.surface1.text};
  }
`;
/** part of @deprecated class icons, do not use in the 2.0  */
const ClassIconWrapper = styled.div`
  background-size: ${iconSize};
  width: ${iconSize};
  height: ${iconSize};
  min-width: ${iconSize};
`;
const menuStyle = css`
  color: ${color.neutral.surface.text};
`;
const itemStyles = css`
  display: flex;
  align-items: center;
  font-family: ${font.body.cssValue};
  font-size: ${fontSize.listItem};
  line-height: ${lineHeight.listItem};
  height: 2rem;
  cursor: pointer;
  user-select: none;
  padding: ${spacing.size4} ${spacing.size16};
  svg {
    width: ${iconSize};
    height: ${iconSize};
  }
  .contexify_itemContent {
    width: 100%;
    padding: 0;
    gap: ${spacing.size8};
    background-color: unset !important;
    color: unset !important;
    li {
      width: 100%;
    }
  }
  &.contexify_submenu-isOpen {
    background-color: ${color.neutral.surface1.background};
    color: unset !important;
  }
  &:hover,
  &:focus {
    background-color: ${color.neutral.surface1.background};
  }
  --contexify-arrow-color: ${color.neutral.surface.text};
  --contexify-activeArrow-color: ${color.neutral.surface.text};
`;

const KeyBoardShortcut: React.FC<{
  shortcut: ContextMenuItemProps<unknown>['keyboardShortcut'];
  className: string;
}> = ({ shortcut, className }) => {
  if (!shortcut) return null;
  const content = compact([
    shortcut.withMeta && isMacFromNavigator ? '⌘' : 'CTRL',
    shortcut.withShift ? 'SHIFT' : null,
    shortcut.key,
  ]).join(' + ');

  return <div className={className}>{content}</div>;
};

const StyledSpan = styled(BodyText)<{ textColor?: string }>`
  color: ${({ textColor }) => textColor ?? color.neutral.surface.text};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
