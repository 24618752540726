import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAssetLogoLessonUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={181} height={32} fill="none" viewBox="0 0 181 32" {...props}>
    <path
      fill="currentColor"
      d="M8.1 25.6c.2-.8 0-1.3-.2-1.5-.2-.3-.7-.4-1.5-.2-2.6.7-2.9 4-3 4.6.7 0 4-.3 4.7-2.9M8 13.4l1.4-2.5c-1.9-.2-5.4.2-9.4 4.3l1.5 1.5s3.1-1 5.2-.5zM18.6 24l-2.8 1.3c.5 2-.5 5.2-.5 5.2l1.5 1.5c4-4 4.5-7.5 4.3-9.4z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.2 9.8a.8.8 0 0 1-1.1 0 .8.8 0 1 1 1 0Zm1.2-1.2a2.5 2.5 0 0 0-3.6 0 2.5 2.5 0 1 0 3.6 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.4 13.2a4 4 0 0 1-5.6 0 4 4 0 1 1 5.6 0M31 1c-.7-.7-1.8-1-3.2-1a21.5 21.5 0 0 0-16.3 10.2A39 39 0 0 0 7 19.6a7.8 7.8 0 0 0 .6 1.5 9 9 0 0 0 1.4 2 8.3 8.3 0 0 0 3.5 2 29.7 29.7 0 0 0 5.5-2.4 39 39 0 0 0 7-4.8c6.6-6.5 8.4-14.5 6-16.9"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M174.4 18.6c0-2.6-1.7-4.2-3.9-4.2s-3.8 1.6-3.8 4.2 1.7 4.2 3.8 4.2c2.2 0 4-1.6 4-4.2Zm4.5 0c0 4.8-3.3 7.8-7.6 7.8-1.9 0-3.4-.6-4.6-1.8v7h-4.4V11h4.2v1.8c1.1-1.3 2.8-2 4.8-2 4.3 0 7.6 3 7.6 7.8M159 6.5v19.7h-4.5v-2.4c-1.3 1.8-3.4 2.7-6 2.7-4.5 0-7.4-3-7.4-8.5V6.5h4.6v11.4c0 3.2 1.7 4.7 4.1 4.7 2.7 0 4.6-1.6 4.6-5V6.5h4.5Zm-21.6 11v8.7H133v-8c0-2.5-1.1-3.6-3-3.6-2.2 0-3.7 1.3-3.7 4.1v7.5H122V11h4.2v1.8c1.2-1.3 3-2 5-2 3.6 0 6.3 2.1 6.3 6.7ZM115 18.6c0-2.6-1.7-4.2-4-4.2-2.1 0-3.8 1.6-3.8 4.2s1.7 4.2 3.9 4.2 3.8-1.6 3.8-4.2Zm-12.3 0c0-4.5 3.5-7.8 8.4-7.8 4.8 0 8.3 3.3 8.3 7.8 0 4.6-3.5 7.8-8.3 7.8-4.9 0-8.4-3.2-8.4-7.8m-15.1 6.2 1.5-3.2c1.4 1 3.4 1.5 5.3 1.5 2 0 2.8-.5 2.8-1.4 0-2.5-9.2 0-9.2-6 0-2.9 2.6-4.9 7-4.9 2.1 0 4.4.5 5.9 1.3l-1.5 3.2c-1.5-.9-3-1.2-4.4-1.2-2 0-2.8.7-2.8 1.4 0 2.6 9.2.1 9.2 6.1 0 2.8-2.7 4.8-7.2 4.8a13 13 0 0 1-6.6-1.6m-15.2 0 1.5-3.2a9 9 0 0 0 5.2 1.5c2 0 2.8-.5 2.8-1.4 0-2.5-9.1 0-9.1-6 0-2.9 2.6-4.9 7-4.9 2 0 4.4.5 5.8 1.3l-1.4 3.2c-1.5-.9-3-1.2-4.4-1.2-2 0-2.8.7-2.8 1.4 0 2.6 9.1.1 9.1 6.1 0 2.8-2.6 4.8-7.1 4.8a13 13 0 0 1-6.6-1.6m-13-6.6 7.4-1.5a3.6 3.6 0 0 0-3.6-2.6c-2.2 0-3.7 1.5-3.8 4Zm8.2 3.2L70 24q-2.1 2.4-6.3 2.4c-5.2 0-8.6-3.3-8.6-7.8s3.4-7.8 8.1-7.8c4.3 0 7.8 2.8 7.8 7.7l-11.2 2.1c.7 1.5 2 2.3 4 2.3 1.7 0 2.8-.5 3.8-1.5M39.1 6.5h4.6v16h9.9v3.7H39V6.5Z"
    />
  </svg>
);
export default SvgAssetLogoLessonUp;
