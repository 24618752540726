import { spacing } from '@lessonup/ui-components';
import { pxToNumber } from '@lessonup/utils';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { playerScreenSize } from '../../dataComponents/PlayerScreens/playerScreens.utils';

/**
 * Calculate a scale factor to fit the player screen within the window.
 * This hook adjusts the scale factor to ensure the player screen fits within the window, taking into account
 * padding between the screen and the window edge.
 *
 * @returns {number} The calculated scale factor.
 */
export const usePlayerScreenToWindowScalar = () => {
  const { width, height } = useWindowSize();
  const scale = useMemo(() => {
    const targetWidth = width - pxToNumber(spacing.size16);
    const targetHeight = height - pxToNumber(spacing.size64); // This is equal to top and bottom padding of modal overlay
    const ratioWidth = targetWidth / playerScreenSize.width;
    const ratioHeight = targetHeight / playerScreenSize.height;
    return Math.min(ratioWidth, ratioHeight);
  }, [height, width]);

  return scale;
};
