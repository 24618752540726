import React, { createContext, useCallback, useContext, useState } from 'react';
import { showFeatureUpsellModal } from '../../components/FeatureUpsellModal/FeatureUpsellModal';
import { LicenseStatus } from '../../types/graphql';
import { isTeachingAllowedForCurrentLicense } from './TeachingCreditsIndicatorFeature.utils';

interface TeacherCreditsContext {
  isTeachingAllowed: boolean;
  handleNotAllowedToTeach: () => void;
  setIsTeachingAllowed: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeacherCreditsContext = createContext<TeacherCreditsContext | undefined>(undefined);

interface TeacherCreditsContextProviderProps {
  currentLicense: LicenseStatus | undefined;
  // Skip the license check and always allow teaching, used for channel lessons.
  skipCheck?: boolean;
  children: React.ReactNode;
}

export const TeacherCreditsContextProvider: React.FC<TeacherCreditsContextProviderProps> = ({
  children,
  currentLicense,
  skipCheck,
}) => {
  const [isTeachingAllowed, setIsTeachingAllowed] = useState(
    isTeachingAllowedForCurrentLicense(currentLicense, skipCheck)
  );

  const handleNotAllowedToTeach = useCallback(() => {
    if (!currentLicense) return;
    showFeatureUpsellModal('teachingCredits', { currentLicense });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLicense, isTeachingAllowed]);

  const context: TeacherCreditsContext = {
    isTeachingAllowed,
    handleNotAllowedToTeach,
    setIsTeachingAllowed,
  };
  return <TeacherCreditsContext.Provider value={context}>{children}</TeacherCreditsContext.Provider>;
};

export const useTeachingCredits = (): TeacherCreditsContext => {
  const ctx = useContext(TeacherCreditsContext);
  if (!ctx) {
    throw new Error('TeacherCreditsContext not found');
  }
  return ctx;
};
