import { intercomIsAvailable } from '../utils/intercomisAvailable';

export function initIntercom(
  appId: string,
  initialIntercomSettings: Intercom_.IntercomSettings & { [key: string]: unknown }
) {
  if (!intercomIsAvailable(true)) return;

  if (typeof window.Intercom !== 'undefined') {
    Intercom('reattach_activator');
    window.intercomSettings = settings(appId, initialIntercomSettings);
    return;
  }

  const intercom = function (...args: unknown[]) {
    intercom.c(args);
  };

  intercom.q = [] as unknown[];

  intercom.c = function (args: unknown[]) {
    intercom.q.push(args);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.Intercom = intercom as any;
  window.intercomSettings = settings(appId, initialIntercomSettings);

  const el = document.createElement('script');
  el.type = 'text/javascript';
  el.async = true;
  el.src = `https://widget.intercom.io/widget/${appId}`;

  document.body.appendChild(el);

  Intercom('boot', settings(appId, initialIntercomSettings));
}

function settings(appId: string, initialSettings: Intercom_.IntercomSettings): Intercom_.IntercomSettings {
  return {
    app_id: appId,
    ...initialSettings,
  };
}
