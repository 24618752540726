import { borderRadius, color, css, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

interface PlayerBarBottomWrapperProps {
  mirrored: boolean;
  minimized: boolean;
  children: React.ReactNode;
}

export const PlayerBarBottomWrapper: React.FC<PlayerBarBottomWrapperProps> = ({ children, mirrored, minimized }) => {
  return (
    <StyledPlayerBarBottomWrapper minimized={minimized} mirrored={mirrored}>
      {children}
    </StyledPlayerBarBottomWrapper>
  );
};

interface PlayerBarBottomSectionProps {
  mirrored?: boolean;
  gapped?: boolean;
  children: React.ReactNode;
}

export const PlayerBarBottomSection: React.FC<PlayerBarBottomSectionProps> = ({ children, mirrored, gapped }) => {
  return (
    <StyledPlayerBarBottomSection mirrored={mirrored} gapped={gapped}>
      {children}
    </StyledPlayerBarBottomSection>
  );
};

interface PlayerBarBottomSubGroupProps {
  mirrored?: boolean;
  children: React.ReactNode;
}

export const PlayerBarBottomSubGroup: React.FC<PlayerBarBottomSubGroupProps> = ({ children, mirrored }) => {
  return <StyledPlayerBarBottomSubGroup mirrored={mirrored}>{children}</StyledPlayerBarBottomSubGroup>;
};

const StyledPlayerBarBottomWrapper = styled.div<PlayerBarBottomWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.size8};
  align-items: flex-start;
  background: ${(props) => (props.minimized ? 'transparent' : color.neutral.surface.background)};
  border-radius: ${borderRadius.rounded8};
  flex-direction: ${(props) => (props.mirrored ? 'row-reverse' : 'row')};

  ${(props) => {
    if (props.minimized) {
      return css`
        justify-content: ${props.mirrored ? 'end' : 'start'};
      `;
    }

    return css`
      justify-content: space-between;
    `;
  }}
`;

const gappedMargin = (gapped: boolean | undefined, mirrored: boolean | undefined) => {
  if (!gapped) return undefined;
  if (mirrored) {
    return css`
      margin-right: auto;
    `;
  }
  return css`
    margin-left: auto;
  `;
};

const StyledPlayerBarBottomSection = styled.div<PlayerBarBottomSectionProps>`
  padding: ${spacing.size8};
  gap: ${spacing.size8};
  display: flex;
  background: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded8};
  flex-direction: ${(props) => (props.mirrored ? 'row-reverse' : 'row')};
  ${(props) => gappedMargin(props.gapped, props.mirrored)}
  align-self: center;
`;

const StyledPlayerBarBottomSubGroup = styled.div<PlayerBarBottomSubGroupProps>`
  display: flex;
  gap: ${spacing.size8};
  flex-direction: ${(props) => (props.mirrored ? 'row-reverse' : 'row')};
`;
