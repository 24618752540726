import { DateFnsLocale, enGBLocale, enINLocale, enUSLocale, frLocale, nlLocale } from '../dateFnsImports';
import { CountryCode } from './country';
import { isValidLanguage, Language } from './language';

export interface Locale {
  language: Language;
  country: CountryCode;
}

export const defaultLocale: Locale = {
  language: 'en',
  country: 'gb',
};

// Format: ISO-639-1 (language) - ISO-3166-1 alpha-2 (country)
export const localeToIsoLocaleString = ({ language, country }: Locale): string =>
  `${language}-${country.toLocaleUpperCase()}`;

/**
 * Guesses the locale (language and country) from the language.
 *
 * @param language - The language to guess the locale from.
 * @returns The guessed locale. Defaults to en-GB.
 */
export const guesstimateLocaleFromLanguage = (language: Language): Locale => {
  switch (language) {
    case 'fr':
      return { language: 'fr', country: 'fr' };
    case 'nl':
      return { language: 'nl', country: 'nl' };
    default:
      return { language: 'en', country: 'gb' };
  }
};

/**
 * Converts a locale to a DateFns locale.
 *
 * @param locale - The locale to convert to a DateFns locale.
 * @returns The DateFns locale. Defaults to en-International.
 */
export const localeToDateFnsLocale = ({ language, country }: Locale): DateFnsLocale => {
  if (language.toLocaleLowerCase() === 'nl') return nlLocale;
  if (language.toLocaleLowerCase() === 'fr') return frLocale;
  if (language.toLocaleLowerCase() === 'en') {
    if (country.toLocaleLowerCase() === 'us') return enUSLocale;
    if (country.toLocaleLowerCase() === 'gb') return enGBLocale;
  }
  return enINLocale;
};

/**
 * Converts a language to an ISO locale string (en-GB, nl-NL, fr-FR, etc.).
 *
 * @param language - The language to convert to an ISO locale string.
 * @returns The ISO locale string. Defaults to en-GB.
 */
export function languageToIsoLocaleString(language: Language): string {
  return localeToIsoLocaleString(guesstimateLocaleFromLanguage(language));
}

/**
 * Validates a language string and converts it to an ISO locale string.
 * Returns an object containing the locale string and a boolean indicating if the input language was valid.
 *
 * @param languageString - The language string to validate and convert.
 * @returns An object with the ISO locale string and a boolean indicating validity.
 */
export function getValidatedIsoLocaleString(languageString: string | undefined): {
  localeString: string;
  inputLanguageWasValid: boolean;
} {
  if (languageString && isValidLanguage(languageString)) {
    return {
      localeString: languageToIsoLocaleString(languageString),
      inputLanguageWasValid: true,
    };
  }

  // Invalid or undefined language - return default with inputLanguageWasValid=false
  return {
    localeString: localeToIsoLocaleString(defaultLocale),
    inputLanguageWasValid: false,
  };
}
