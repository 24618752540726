import type * as CSS from 'csstype';
import React, { PropsWithChildren, useMemo } from 'react';
import ReactModal from 'react-modal';
import { color } from '../../foundations/colors/colors';
import { elevationShadow } from '../../foundations/elevation/elevation';
import { zIndex } from '../../foundations/layout/z-indices';
import { spacing } from '../../foundations/spacing/spacing';

type EventHandlers = 'onAfterClose' | 'onRequestClose' | 'parentSelector';

export interface SimpleModalProps extends Pick<ReactModal.Props, EventHandlers> {
  isOpen: boolean;
  contentLabel: string;
  width?: CSS.Property.Width;
  height?: CSS.Property.Height;
  maxHeight?: CSS.Property.MaxHeight;
  overflowHidden?: boolean;
}

/** A modal dialog that relies on the caller to keep track of whether it's open or not. */
export function SimpleModal({
  isOpen,
  contentLabel,
  onAfterClose,
  onRequestClose,
  parentSelector,
  width,
  height,
  maxHeight,
  overflowHidden,
  children,
}: PropsWithChildren<SimpleModalProps>) {
  const style: ReactModal.Styles = useMemo(() => {
    return {
      overlay: {
        // If you specify a parent selector, we assume you want to show the
        // overlay inside the parent only, instead of fixed in the viewport.
        position: parentSelector ? 'absolute' : 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        zIndex: zIndex.modal,
        overflowY: 'auto',
        paddingTop: `${spacing.size32}`, // needed when modal height is larger than viewport height
        paddingBottom: `${spacing.size32}`, // needed when modal height is larger than viewport height
      },
      content: {
        ...defaultModalStyling,
        margin: 'auto',
        position: 'relative',
        width: width,
        height: height,
        maxHeight,
        overflow: overflowHidden ? 'hidden' : 'auto',
      },
    };
  }, [height, maxHeight, width, overflowHidden, parentSelector]);

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnEsc={true}
      contentLabel={contentLabel}
      style={style}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      parentSelector={parentSelector}
    >
      {children}
    </ReactModal>
  );
}

export const defaultModalStyling: CSS.Properties = {
  background: color.neutral.surface.background,
  boxShadow: elevationShadow.e300,
  borderRadius: '8px',
  display: 'flex', // needed when having a 'height', this makes it possible to stretch child elements
  flexDirection: 'column',
  padding: 0,
  inset: 0,
  maxWidth: 'calc(100% - 2 * 8px)',
};
