import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { transition } from '../../foundations/animations/transition';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { BodyText } from '../BodyText/BodyText';
import { Button } from '../Button/Button';
import { IconClose } from '../icons';
import { formatValueIndicator } from './ProgressBar.utils';

export interface ProgressBarProps {
  value: number;
  max?: number;
  valueIndicatorType?: 'percentage' | 'fraction' | 'number' | undefined;
  description?: string;
  ariaLabel?: string;
  onCancel?: () => void;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  max = 100,
  valueIndicatorType,
  description,
  ariaLabel,
  onCancel,
}) => {
  const { t } = useTranslation('uiProgressBar');

  const percentage = Math.round((value / max) * 100);

  const valueIndicator = formatValueIndicator(valueIndicatorType, value, max, percentage);

  const handleCancel: React.MouseEventHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    onCancel?.();
  };

  return (
    <>
      <StyledWrapper>
        <StyledProgressBar
          value={value}
          max={max}
          aria-label={ariaLabel}
          aria-valuenow={value}
          aria-valuemax={max}
          style={{ '--percentage': `${percentage}%` } as CSSProperties}
        />
        {valueIndicator && (
          <StyledBodyText size="small" noWrap={true}>
            {valueIndicator}
          </StyledBodyText>
        )}
        {onCancel && (
          <StyledCancelButton
            buttonType="neutral"
            onClick={handleCancel}
            size="small"
            aria-label={t('cancel')}
            iconStart={<IconClose />}
          />
        )}
      </StyledWrapper>
      {description && <StyledBodyText size="small">{description}</StyledBodyText>}
    </>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${rem('16px')};
  align-items: center;
  gap: ${spacing.size8};
`;

const StyledProgressBar = styled.progress`
  appearance: none;
  width: 100%;
  height: ${spacing.size4};
  background-color: ${color.additional.disabled.background};
  border-radius: ${spacing.size4};
  border: none;
  overflow: hidden;

  ::-webkit-progress-bar {
    background-color: ${color.additional.disabled.background};
    border-radius: ${spacing.size4};
  }

  ::-webkit-progress-value {
    background-color: ${color.accent.secondary.background};
    border-top-left-radius: ${spacing.size4};
    border-bottom-left-radius: ${spacing.size4};
    transition: width ease ${transition.duration.medium};
  }

  // Firefox doesn't support width transition, so we need to animate the padding-bottom instead. See https://snook.ca/archives/html_and_css/animating-progress for more info.
  ::-moz-progress-bar {
    background-color: ${color.accent.secondary.background};
    border-top-left-radius: ${spacing.size2};
    border-top-right-radius: ${spacing.size2};
    transition: padding-bottom ease ${transition.duration.medium};
    padding-bottom: var(--percentage);
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-${spacing.size4});
    padding-left: ${spacing.size4};
    height: 0;
  }
`;

interface StyledBodyTextProps {
  noWrap?: boolean;
}

const StyledBodyText = styled(BodyText)<StyledBodyTextProps>`
  color: ${color.additional.disabled.text};
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};
`;

const StyledCancelButton = styled(Button)`
  width: ${rem('32px')};
  color: ${color.additional.disabled.text};
`;
