import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';
import { LoaderSpinner } from '../../LoaderSpinner/LoaderSpinner';

export type SwitchProps = Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> & {
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  id: string;
  label?: string;
  ariaLabel?: string;
  loading?: boolean;
};

interface StateProps {
  checked: boolean;
  disabled: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  id,
  defaultChecked,
  checked,
  onChange,
  disabled,
  label,
  ariaLabel,
  loading,
  className,
  ...rest
}) => {
  const [checkedState, setCheckedState] = useState<boolean>(defaultChecked || false);

  useEffect(() => {
    if (typeof checked !== 'undefined') {
      setCheckedState(checked);
    }
  }, [checked]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (loading) return;
      const nextState = e?.target?.checked;
      setCheckedState(nextState);
      onChange && onChange(e);
    },
    [onChange, loading]
  );

  return (
    <label htmlFor={id} className={className}>
      <StyledLabel
        direction="x"
        aria-checked={checked}
        spacing={spacing.size8}
        aria-label={ariaLabel}
        tabIndex={-1}
        checked={checkedState}
        disabled={!!disabled}
      >
        {label ? <BodyText>{label}</BodyText> : null}
        <StyledSwitch checked={checkedState} disabled={!!disabled}>
          <StyledInput
            type="checkbox"
            role="switch"
            id={id}
            onChange={onChangeHandler}
            tabIndex={disabled ? -1 : 0}
            checked={checkedState}
            disabled={!!disabled}
            {...rest}
          />
          {loading && !disabled && <StyledLoaderSpinner checked={checkedState} disabled={!!disabled} />}
        </StyledSwitch>
      </StyledLabel>
    </label>
  );
};

function styledSwitchBackgroundColorForState({ disabled, checked }: StateProps): string {
  if (disabled || !checked) {
    return color.neutral.outline.background;
  }

  return color.accent.primary.background;
}

function styledToggleBackgroundColorForState(state: StateProps): string {
  if (state.disabled) {
    return color.additional.disabled.text;
  }

  return state.checked ? color.accent.primary.text : color.neutral.surface.background;
}

const StyledSwitch = styled.div<StateProps>`
  ${(props) => css`
    width: 48px;
    height: 24px;
    border-radius: ${borderRadius.rounded24};
    background-color: ${styledSwitchBackgroundColorForState(props)};
    display: flex;
    padding: ${spacing.size4};
    position: relative;
  `}
`;

const StyledInput = styled.input<StateProps>`
  ${(props) => css`
    appearance: none;
    height: ${spacing.size16};
    width: ${spacing.size16};
    border-radius: 100%;
    background-color: ${styledToggleBackgroundColorForState(props)};
    transition: all 0.1s ease;
    margin: 0;
    outline: none;
    border: none;
    cursor: ${props.disabled ? 'normal' : 'pointer'};

    ${props.checked &&
    css`
      margin-left: calc(100% - ${spacing.size16});
      margin-right: 0%;
    `}
  `}
`;

const StyledLoaderSpinner = styled(LoaderSpinner)<StateProps>`
  ${(props) => css`
    position: absolute;
    align-self: center;
    color: ${styledSwitchBackgroundColorForState(props)};
    width: 16px;
    height: 16px;
    margin-left: 0%;
    transition: all 0.1s ease;

    ${props.checked &&
    css`
      margin-left: 50%;
    `};
  `}
`;

const StyledLabel = styled(SpaceBetween)`
  ${(props: StateProps) => css`
    align-items: center;
    width: fit-content;
    ${!props.disabled && `cursor: pointer;`}

    :focus-visible {
      outline: ${!props.disabled && `2px solid ${color.additional.focus.background}`};
    }
  `}
`;
