import styled from '@emotion/styled';
import { PartialPrefix } from '@lessonup/utils';
import React, { HTMLAttributes } from 'react';
import { font } from '../../foundations/typography/fonts';
import { fontSize, lineHeight } from '../../foundations/typography/typography.utils';

export type HeadlineType = 'large' | 'medium' | 'small';

export interface HeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  size: HeadlineType;
  as?: keyof React.JSX.IntrinsicElements;
}

type HeadlineStyledProps = PartialPrefix<HeadlineProps, 'size'>;

// Deprecated, use TextElement instead
export const Headline: React.FC<HeadlineProps> = ({ size: type, children, ...rest }) => {
  return (
    <StyledHeadline $size={type} {...rest}>
      {children}
    </StyledHeadline>
  );
};

const StyledHeadline = styled.h4<Pick<HeadlineStyledProps, '$size'>>`
  ${(props) => {
    switch (props.$size) {
      case 'large':
        return `
          font-size: ${fontSize.headlineSizeLarge};
          line-height: ${lineHeight.headlineSizeLarge};
        `;
      case 'medium':
        return `
          font-size: ${fontSize.headlineSizeMedium};
          line-height: ${lineHeight.headlineSizeMedium};
        `;
      case 'small':
        return `
          font-size: ${fontSize.headlineSizeSmall};
          line-height: ${lineHeight.headlineSizeSmall};
        `;
    }
  }}

  font-family: ${font.heading.cssValue};
  font-style: normal;
  font-weight: 800;
  margin: 0; // reset browser styling
`;
