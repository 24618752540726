import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { showFeatureUpsellModal } from '../../components/FeatureUpsellModal/FeatureUpsellModal';
import { TeachingCreditsIndicator } from './components/TeachingCreditsIndicator';
import { useTeachingCredits } from './TeacherCreditsContext';
import { TeachingCreditsData } from './TeachingCreditsIndicatorFeature.graphql';
import { ShowAsType } from './TeachingCreditsIndicatorFeature.utils';

export const TeachingCreditsIndicatorController = (props: TeachingCreditsData & { showAs?: ShowAsType }) => {
  const { remainingTeachingCreditsCount, status } = props.viewer.license;
  const { setIsTeachingAllowed } = useTeachingCredits();

  const allowed = props.viewer.license.isTeachingAllowed ?? true;
  useEffect(() => {
    setIsTeachingAllowed(allowed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowed]);

  if (isNil(remainingTeachingCreditsCount)) {
    return <></>;
  }

  return (
    <TeachingCreditsIndicator
      credits={remainingTeachingCreditsCount}
      onClick={() => showFeatureUpsellModal('teachingCredits', { currentLicense: status })}
      showAs={props.showAs}
    />
  );
};
