import { createModal, ManagedModal, NiceModal, useModal } from '@lessonup/ui-components';
import React from 'react';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';
import { EmailTroubleshootModalContent, EmailTroubleshootModalContentProps } from './EmailTroubleshootModalContent';

type Props = Omit<EmailTroubleshootModalContentProps, 'closeModal'>;

const EmailTroubleshootModal = createModal(({ openChatWindow, ...props }: EmailTroubleshootModalContentProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'emailTroubleshootModal');
  const modal = useModal();

  return (
    <ManagedModal modal={modal} contentLabel={t('contentLabel')} width="500px">
      <EmailTroubleshootModalContent {...props} openChatWindow={openChatWindow} closeModal={modal.hide} />
    </ManagedModal>
  );
});

export const showEmailTroubleshootModal = (props: Props): Promise<void> =>
  NiceModal.show(EmailTroubleshootModal, props);
