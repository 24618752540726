import { isBoxLayout, pinSize, useUpdatePin } from '@lessonup/pin-renderer';
import { PinComponent } from '@lessonup/pins-shared';
import { BodyText, IconRotationAngle, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import {
  createHandlePositionChange,
  createHandleRotationChange,
} from '../../../../../utils/pinComponentSettings/sharedPinComponentSettings';
import { EditorInputFormField } from '../../EditorInputFields/EditorInputFormField';

const POSITION_INCREMENT_STEP = 5;

export interface BoxLayoutPositionSettingProps {
  pinComponent: PinComponent;
}

export const BoxLayoutPositionSetting: React.FC<BoxLayoutPositionSettingProps> = ({ pinComponent }) => {
  const dispatch = useUpdatePin();
  if (!isBoxLayout(pinComponent.layout)) return null;
  const isLocked = pinComponent?.settings.lockLayout ?? false;
  const handleXCoordinatePositionChange = createHandlePositionChange(pinComponent, dispatch, 'x');
  const handleYCoordinatePositionChange = createHandlePositionChange(pinComponent, dispatch, 'y');
  const handleRotationChange = createHandleRotationChange(pinComponent, dispatch);

  return (
    <>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center">
        <StyledBodyText>{'X'}</StyledBodyText>
        <StyledInputFormField
          id="x"
          type="number"
          label=""
          hideLabel={true}
          value={pinComponent.layout.position.x}
          min={0}
          max={pinSize.width}
          step={POSITION_INCREMENT_STEP}
          onChange={handleXCoordinatePositionChange}
          formFieldSize="small"
          disabled={isLocked}
        />
        <StyledBodyText>{'Y'}</StyledBodyText>
        <StyledInputFormField
          id="y"
          type="number"
          label=""
          hideLabel={true}
          value={pinComponent.layout.position.y}
          min={0}
          max={pinSize.height}
          step={POSITION_INCREMENT_STEP}
          onChange={handleYCoordinatePositionChange}
          formFieldSize="small"
          disabled={isLocked}
        />
      </SpaceBetween>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center">
        <IconRotationAngle />
        <StyledInputFormField
          id="rotation"
          type="number"
          label=""
          hideLabel={true}
          value={pinComponent.layout.rotation}
          min={-180}
          max={180}
          step={1}
          onChange={handleRotationChange}
          formFieldSize="small"
          disabled={isLocked}
        />
      </SpaceBetween>
    </>
  );
};

const StyledBodyText = styled(BodyText)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInputFormField = styled(EditorInputFormField)`
  flex-grow: 1;
`;
