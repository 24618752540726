import { pinRendererColorSets } from '@lessonup/pin-renderer';
import { css, styled } from '@lessonup/ui-components';
import React, { type HTMLAttributes } from 'react';

export type BorderMode = 'hover' | 'edit' | null;

interface BorderProps {
  isBorderHovered?: boolean;
  mode?: BorderMode;
  onMouseEnter?: HTMLAttributes<HTMLDivElement>['onMouseEnter'];
  onMouseLeave?: HTMLAttributes<HTMLDivElement>['onMouseLeave'];
  onMouseDown?: HTMLAttributes<HTMLDivElement>['onMouseDown'];
}

interface HorizontalBorderProps extends BorderProps {
  side: 'top' | 'bottom';
}

interface VerticalBorderProps extends BorderProps {
  side: 'left' | 'right';
}

export const HorizontalBorder: React.FC<HorizontalBorderProps> = ({
  isBorderHovered = false,
  side,
  mode = null,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
}) => (
  <StyledHorizontalBorder
    isBorderHovered={isBorderHovered}
    side={side}
    mode={mode}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseDown={onMouseDown}
  />
);

export const VerticalBorder: React.FC<VerticalBorderProps> = ({
  isBorderHovered,
  side,
  mode,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
}) => (
  <StyledVerticalBorder
    isBorderHovered={isBorderHovered}
    side={side}
    mode={mode}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseDown={onMouseDown}
  />
);

const StyledHorizontalBorder = styled.div<HorizontalBorderProps>`
  position: absolute;

  ${(props) => {
    if (props.mode === 'edit') {
      return css`
        pointer-events: all;
        width: calc(100% + 8px);
        height: 8px;
        background: ${props.isBorderHovered
          ? pinRendererColorSets.active.on
          : pinRendererColorSets.disabled.strokeSubjacent};
        ${props.side === 'top' ? 'top: -4px; left: -4px;' : 'bottom: -4px; right: -4px;'}
        ${props.isBorderHovered &&
        css`
          cursor: move;
        `}
      `;
    }

    if (props.mode === 'hover') {
      return css`
        width: calc(100% + 2px);
        height: 2px;
        background: ${pinRendererColorSets.active.on};
        ${props.side === 'top' ? 'top: -2px; left: -2px;' : 'bottom: -2px; right: -2px;'}
      `;
    }

    return css`
      width: calc(100% + 1px);
      height: 1px;
      background: ${pinRendererColorSets.active.on};
      ${props.side === 'top' ? 'top: -1px; left: -1px;' : 'bottom: -1px; right: -1px;'}
    `;
  }}
`;

const StyledVerticalBorder = styled.div<VerticalBorderProps>`
  position: absolute;

  ${(props) => {
    if (props.mode === 'edit') {
      return css`
        pointer-events: all;
        width: 8px;
        height: calc(100%);
        background: ${props.isBorderHovered
          ? pinRendererColorSets.active.on
          : pinRendererColorSets.disabled.strokeSubjacent};
        ${props.side === 'left' ? 'left: -4px;' : 'right: -4px;'}
        ${props.isBorderHovered &&
        css`
          cursor: move;
        `}
      `;
    }

    if (props.mode === 'hover') {
      return css`
        width: 2px;
        height: calc(100%);
        background: ${pinRendererColorSets.active.on};
        ${props.side === 'left' ? 'left: -2px;' : 'right: -2px;'}
      `;
    }

    return css`
      width: 1px;
      height: calc(100%);
      background: ${pinRendererColorSets.active.on};
      ${props.side === 'left' ? 'left: -1px;' : 'right: -1px;'}
    `;
  }}
`;
