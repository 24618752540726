import styled from '@emotion/styled';
import { uniqueId } from '@lessonup/utils';
import { rem } from 'polished';
import React, { ForwardedRef, forwardRef } from 'react';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { TextElement } from '../../foundations/typography/TextElement/TextElement';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../BodyText/BodyText';
import { IconChevronLeft, IconSearch } from '../icons';
import {
  FormFieldProps,
  FormFieldSize,
  InputFormFieldProps,
  SelectFormFieldProps,
  SelectFormFieldValue,
  SharedFormFieldProps,
  TextAreaFormFieldProps,
} from './model';
import { PasswordField } from './PasswordField/PasswordField';
import {
  getIconSpacing,
  getPaddingForFieldWithIcon,
  getSelectRightPadding,
  sharedFormFieldStyle,
  sharedInputSelectStyle,
} from './utils';
import { ValidationMessage } from './ValidationMessage/ValidationMessage';

const defaultSize: FormFieldSize = 'medium';

/**
 * Input element in LessonUp styling
 */
export const InputFormField = forwardRef<HTMLInputElement, InputFormFieldProps>(
  function InputFormField(props, forwardedRef) {
    return renderFormField<HTMLInputElement>({ ...props, forwardedRef });
  }
);

/**
 * Select element in LessonUp styling. Can be used to pick one value from a list of values
 */
export const SelectFormField = forwardRef(function SelectFormField<V extends SelectFormFieldValue>(
  props: Omit<SelectFormFieldProps<V>, 'type'> & { type?: never },
  forwardedRef: ForwardedRef<HTMLSelectElement>
) {
  return renderFormField<HTMLSelectElement, V>({ ...props, type: 'select', forwardedRef });
}) as <V extends SelectFormFieldValue>(
  props: Omit<SelectFormFieldProps<V>, 'type'> & { type?: never } & React.RefAttributes<HTMLSelectElement>
) => React.JSX.Element;
// Recover the type parameter that is otherwise lost when the component is passed to `forwardRef'
// See: https://stackoverflow.com/a/58473012

export const TextAreaFormField = forwardRef<HTMLTextAreaElement, Omit<TextAreaFormFieldProps, 'type'>>(
  function TextAreaFormField(props, forwardedRef) {
    return renderFormField<HTMLTextAreaElement>({ ...props, type: 'textarea', forwardedRef });
  }
);

const renderFormField = <
  T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
  V extends SelectFormFieldValue = undefined,
>(
  props: FormFieldProps<T, V>
) => {
  const { type, label, hideLabel, className, validation, orientation, id: idFromProps, formFieldSize } = props;
  const id = idFromProps || uniqueId(`form-field-${type}-`);
  const validationMessageId = `${id}-validation`;
  const describedById = validation?.message ? validationMessageId : undefined;

  return (
    <div className={className}>
      <SpaceBetween as="label" spacing={spacing.size2} direction={orientation === 'horizontal' ? 'x' : 'y'}>
        {!hideLabel && (
          <>
            {orientation === 'horizontal' ? (
              <StyledHorizontalLabelText
                horizontalLabelAutoWidth={props.horizontalLabelAutoWidth}
                horizontalLabelSpacing={props.horizontalLabelSpacing}
              >
                {label}
              </StyledHorizontalLabelText>
            ) : (
              <TextElement textStyle="label" weight="bold">
                {label}
              </TextElement>
            )}
          </>
        )}
        <SpaceBetween direction="y" spacing={spacing.size4}>
          {type === 'textarea' && (
            <StyledTextArea
              {...props}
              ref={props.forwardedRef}
              id={id}
              aria-label={label}
              formFieldSize={formFieldSize || defaultSize}
              resize={props.resize || 'none'}
              aria-describedby={describedById}
              aria-invalid={validation?.state === 'error'}
              rows={props.rows || 4}
              maxLength={props.maxLength}
            />
          )}
          {type === 'number' && (
            <StyledInput
              {...props}
              ref={props.forwardedRef}
              id={id}
              formFieldSize={formFieldSize || defaultSize}
              aria-label={label}
              aria-describedby={describedById}
              aria-invalid={validation?.state === 'error' && true}
            />
          )}
          {(type === 'text' || type === 'email') && (
            <StyledInput
              {...props}
              ref={props.forwardedRef}
              id={id}
              formFieldSize={formFieldSize || defaultSize}
              aria-label={label}
              aria-describedby={describedById}
              aria-invalid={validation?.state === 'error' && true}
              autoComplete={props.autoComplete}
              maxLength={props.maxLength}
            />
          )}
          {type === 'password' && (
            <PasswordField
              {...props}
              id={id}
              formFieldSize={formFieldSize || defaultSize}
              aria-label={label}
              aria-describedby={describedById}
              aria-invalid={validation?.state === 'error' && true}
            />
          )}
          {type === 'search' && (
            <StyledSearchFieldWrapper>
              <StyledSearchField
                {...props}
                ref={props.forwardedRef}
                id={id}
                formFieldSize={formFieldSize || defaultSize}
                aria-label={label}
                aria-describedby={describedById}
                aria-invalid={validation?.state === 'error' && true}
              />
              <IconSearch />
            </StyledSearchFieldWrapper>
          )}
          {type === 'select' && (
            <StyledSelectWrapper>
              <StyledSelect
                {...props}
                ref={props.forwardedRef}
                id={id}
                formFieldSize={formFieldSize || defaultSize}
                aria-label={label}
                aria-describedby={describedById}
                aria-invalid={validation?.state === 'error' && true}
              >
                {props.transitionalOption && props.value === props.transitionalOption.value && (
                  <option
                    value={props.transitionalOption.value}
                    key={`${props.transitionalOption.value}-transitional`}
                    disabled={true}
                  >
                    {props.transitionalOption.label}
                  </option>
                )}
                {props.options.map(({ label, value, disabled }, index) => (
                  <option value={value} key={`${value}-${index}`} disabled={disabled}>
                    {label}
                  </option>
                ))}
              </StyledSelect>
              <IconChevronLeft />
            </StyledSelectWrapper>
          )}
          <ValidationMessage id={validationMessageId} validation={validation} />
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
};

const StyledHorizontalLabelText = styled(BodyText)<
  Pick<SharedFormFieldProps, 'orientation' | 'horizontalLabelAutoWidth' | 'horizontalLabelSpacing'>
>`
  align-self: center;
  width: ${(props) => (props.horizontalLabelAutoWidth ? `auto` : rem('112px'))};
  padding-right: ${(props) => (props.horizontalLabelSpacing ? props.horizontalLabelSpacing : 0)};
`;

const StyledInput = styled.input<InputFormFieldProps>`
  ${sharedInputSelectStyle}
  ${sharedFormFieldStyle}
`;

const StyledSearchFieldWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledSearchField = styled.input<InputFormFieldProps>`
  ${sharedInputSelectStyle}
  ${sharedFormFieldStyle}
  padding-left: ${(props) =>
    getPaddingForFieldWithIcon({
      ...props,
      iconWidth: spacing.size16,
    })};
  flex-grow: 1;

  & + svg {
    position: absolute;
    top: 50%;
    height: ${spacing.size16};
    width: ${spacing.size16};
    transform: translateY(-50%);
    left: ${getIconSpacing};
    color: ${colorForInputFieldIcon};
  }
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const StyledSelect = styled.select<SharedFormFieldProps>`
  ${sharedInputSelectStyle}
  ${sharedFormFieldStyle}
  appearance: none;
  flex-grow: 1;
  padding-right: ${(props) => getSelectRightPadding(props)};

  max-width: 100%;

  & + svg {
    position: absolute;
    right: ${spacing.size12};
    color: ${colorForInputFieldIcon};
    font-size: ${spacing.size16};
    transform: rotate(270deg);
    pointer-events: none;
  }
`;

const StyledTextArea = styled.textarea<TextAreaFormFieldProps>`
  ${sharedFormFieldStyle}
  border-radius: ${spacing.size8};
  resize: ${(props) => `${props.resize || 'none'}`};
  flex-grow: 1;
  padding-right: ${(props) => getSelectRightPadding(props)};
`;

function colorForInputFieldIcon({ disabled, validation }: SharedFormFieldProps): string {
  if (disabled) {
    return color.additional.disabled.text;
  }

  if (validation) {
    return color.neutral.surface.text;
  }

  return color.accent.secondaryContainer.text;
}
