import { AuthStatus, DataLoader, useAuth, useDocumentQuery } from '@lessonup/client-integration';
import { styled } from '@lessonup/ui-components';
import { compact } from 'lodash';
import React from 'react';
import { ReportDetails, ReportDetailsProps } from './components/ReportDetails/ReportDetails';
import { ReportUnavailable } from './components/ReportUnavailable/ReportUnavailable';
import { ReportDocument } from './ReportPageFeature.graphql.generated';

export type ReportPageFeatureProps = {
  reportId?: string;
};

export function ReportPageFeature({ reportId }: ReportPageFeatureProps) {
  const auth = useAuth();
  const { data, loading, error } = useDocumentQuery(ReportDocument, {
    fetchPolicy: 'cache-first',
    skip: !reportId || auth?.type !== AuthStatus.LOGGED_IN,
    variables: {
      reportId: reportId || '', // `reportId` should always be defined because of the check for `skip`
    },
  });

  return (
    <StyledDataLoader
      data={data}
      error={error}
      customErrorRenderer={ReportUnavailable}
      dataRenderer={(data) => {
        const featureFlag = data.viewer.featureFlags.reportsV2;
        const reportById = data.viewer.reportById;
        if (!featureFlag || !reportById) return <ReportUnavailable />;

        const report: ReportDetailsProps['report'] = {
          id: reportById.id || '',
          taughtAt: new Date(reportById.taughtAt),
          lesson: {
            name: reportById.lessonName || '',
          },
          studentReports: reportById.studentReports.map((studentReport) => ({
            id: studentReport.id,
            name: studentReport.name,
          })),
          numberOfStudents: reportById.studentReports.length || 0,
          questionReports: compact(
            reportById.questionReports.map(
              ({
                id,
                questionTitle,
                slideNumber,
                percentageStudentsAnsweredCorrectly,
                percentageStudentsAnsweredIncorrectly,
                percentageStudentsUnanswered,
              }) => ({
                id,
                questionTitle,
                slideNumber,
                percentageStudentsAnsweredCorrectly: percentageStudentsAnsweredCorrectly ?? null,
                percentageStudentsAnsweredIncorrectly: percentageStudentsAnsweredIncorrectly ?? null,
                percentageStudentsUnanswered,
              })
            )
          ),
        };

        return <ReportDetails report={report} />;
      }}
      loading={loading}
    />
  );
}

const StyledDataLoader = styled(DataLoader)`
  width: 100%;
  height: 100%;
` as typeof DataLoader;
