// ToDo make this a global component
import { QuizPinSettings, stringToLeanProsemirror } from '@lessonup/pins-shared';
import { borderRadius, color, IconAdd, rem, styled } from '@lessonup/ui-components';
import { randomId } from '@lessonup/utils';
import React from 'react';
import { useUpdatePin } from '../../../../utils/updatePinContext/updatePinContext';

export interface QuizAddAnswerButtonProps {
  settings: QuizPinSettings;
  setSubSelectionValue: React.Dispatch<React.SetStateAction<string | null>>;
}

// TODO: This is a temporary component, there is a different solution for adding answers that's part of ED-402

export const QuizAddAnswerButton: React.FC<QuizAddAnswerButtonProps> = ({ settings, setSubSelectionValue }) => {
  const dispatch = useUpdatePin<'MULTIPLE_CHOICE'>();

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    addAnswer();
  };

  const addAnswer = () => {
    const newAnswers = [...settings.answers];
    const id = randomId();
    newAnswers.push({ type: 'TEXT', text: stringToLeanProsemirror(''), id });
    dispatch({
      type: 'updatePinSettings',
      settings: { answers: newAnswers },
      onComplete: () => {
        setSubSelectionValue?.(id);
      },
    });
  };

  return (
    <ContentWrapper onMouseDown={(event) => handleMouseDown(event)} as="button">
      <IconAdd />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  position: relative;
  border-radius: ${borderRadius.rounded8};
  border-width: 2px;
  border-style: solid;
  border-color: ${color.neutral.surface1.background};
  background-color: ${color.neutral.surface.background};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem('40px')};

  :hover {
    border-color: ${color.neutral.outline.background};
  }
`;
