import { tracker } from '@lessonup/analytics';
import { graphQLErrorCodeFromError, graphQLErrorMessage } from '@lessonup/client-integration';
import { toast } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { useCallback } from 'react';

type GeneratorTypeForErrors = 'poll' | 'quiz' | 'lesson' | 'lessonFromContext' | 'lessonFromImageContext';

export const useHandleGeneratorError = (
  generatorType: GeneratorTypeForErrors,
  goToPreviousStep: () => void,
  t: TFunction
) => {
  return useCallback(
    // PinCount is probably bullshit data, but we keep it for now to not break the tracker
    (error?: unknown, pinCount = 0) => {
      const { title, message } = generatorErrorMessage(error, t, generatorType);
      toast({
        title,
        message,
        type: 'error',
        options: {
          autoClose: false,
        },
      });

      if (generatorType === 'lesson') {
        // TODO: this should not be part of a generic error, but should be handled in the specific modal steps instead
        tracker.events.experimentAiLessonGenerationFailed();
      } else if (generatorType === 'lessonFromContext' || generatorType === 'lessonFromImageContext') {
        // don't track here, tracking is done in the modal
      } else {
        // TODO: this should not be part of a generic error, but should be handled in the specific modal steps instead
        tracker.events.experimentAiGenerationFailed({
          pinCount,
          pinType: generatorType,
        });
      }

      goToPreviousStep();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generatorType, goToPreviousStep]
  );
};

const generatorErrorMessage = (
  error: unknown,
  t: TFunction,
  generatorType: GeneratorTypeForErrors
): { title: string; message: string } => {
  const errorCode = graphQLErrorCodeFromError(error);
  const errorMessage = graphQLErrorMessage(error);

  if (errorCode === 'EXTERNAL_SERVICE_UNAVAILABLE') {
    return {
      title: t('serviceUnavailableErrorToastTitle'),
      message: t('serviceUnavailableErrorToastMessage'),
    };
  }

  if (errorCode === 'INTERNAL_SERVER_ERROR' && errorMessage?.includes('empty result')) {
    return {
      title: t('serviceReturnedEmptyResultErrorToastTitle'),
      message: t('serviceReturnedEmptyResultErrorToastMessage'),
    };
  }

  return {
    title: t('generateErrorToastTitle'),
    message:
      generatorType === 'lessonFromImageContext'
        ? t('generateErrorToastMessageForUpload')
        : t('generateErrorToastMessage'),
  };
};
