import {
  Button,
  FormFieldValidation,
  IconSend,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  useForm,
} from '@lessonup/ui-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { addUtmParamsToLink, REFERRAL_MEDIUM } from '../../TeacherReferral.utils';
import { ReferralTabsProps } from '../ReferralTabs/ReferralTabs';

export type FormInputs = {
  email: string;
};

const TRANSLATION_NAMESPACE = 'referralMailInvite';

export const ReferralMailInvite = (props: ReferralTabsProps) => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>();

  const { ref: registerEmailRef, ...emailRest } = register('email', {
    required: { value: true, message: t('error.emailRequired') },
    onChange() {
      clearErrors('email');
    },
  });

  const validateGeneric: (inputName: keyof FormInputs) => FormFieldValidation | undefined = (inputName) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  const getEmailBody = (referralLink: string) => {
    return `${t('email.greeting')}
    
${t('email.paragraph')}

${formatBullets([t('email.bullet1'), t('email.bullet2'), t('email.bullet3'), t('email.bullet4')])}

    
${t('email.preLink')} ${referralLink}`;
  };

  const submit = (data: FormInputs) => {
    const mailToLink =
      'mailto:' +
      data.email +
      `?subject=${encodeURI(t('email.subject'))}&Body=` +
      encodeURIComponent(getEmailBody(addUtmParamsToLink(props.link, REFERRAL_MEDIUM.EMAIL)));

    window.location.href = mailToLink;
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <SpaceBetween direction="y" spacing={spacing.size8} alignItems="flex-end">
        <StyledInputFormField
          type="email"
          label={t('inputLabel')}
          placeholder={t('inputPlaceholder')}
          {...emailRest}
          ref={(email) => {
            registerEmailRef(email);
            emailRef.current = email;
          }}
          validation={validateGeneric('email')}
        />
        <Button iconStart={<IconSend />} type="submit">
          {t('submitLabel')}
        </Button>
      </SpaceBetween>
    </form>
  );
};

const StyledInputFormField = styled(InputFormField)`
  min-width: 100%;
`;

function formatBullets(bullets: string[]) {
  return bullets.map((bullet) => `✔ ${bullet}`).join('\n');
}
