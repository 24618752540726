import { makeVar, useReactiveVar } from '@lessonup/client-integration';

/**
 * Configuration required by the teacher modern package
 */
export interface TeacherModernConfig {
  hocuspocusUrl: string;
  studentUrl: string;
}

const current = makeVar<TeacherModernConfig | undefined>(undefined);

/**
 * returns the config or throws an error when it's not initialized
 */
export function getTeacherModernConfig(): TeacherModernConfig {
  const value = current();
  if (!value) {
    throw new Error('config not initialized setTeacherModernConfig');
  }
  return value;
}

/**
 * returns the config or throws an error when it's not initialized
 */
export function useTeacherModernConfig() {
  const value = useReactiveVar(current);
  if (!value) {
    throw new Error('config not initialized, call setTeacherModernConfig');
  }
  return value;
}

export function setTeacherModernConfig(config: TeacherModernConfig) {
  current(config);
}
