import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPension = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.812 4.831c.381 1.02 1.105 1.792 1.843 2.339m6.19.43H8.565m7.371 2.838v1.42m1.21-7.252-.316 1.891c1.157 1.029 1.982 2.364 2.317 3.862h.54a1.5 1.5 0 0 1 1.5 1.5v3.573a1.5 1.5 0 0 1-.83 1.342l-2.366 1.183a2.625 2.625 0 0 1-5.103 1.217c-.587.121-1.199.185-1.826.185a9 9 0 0 1-2.504-.352l-.01.043a2.625 2.625 0 1 1-5.116-1.177l.393-1.706a2.6 2.6 0 0 1 .152-.463 6.96 6.96 0 0 1-1.165-3.845c0-4.142 3.693-7.5 8.25-7.5q.776 0 1.512.126c.814-1.065 2.02-1.435 3.095-1.563.92-.109 1.629.77 1.476 1.684"
    />
  </svg>
);
export default SvgIconPension;
