import { makeVar, useReactiveVar } from '@apollo/client';
import { isEqual } from 'lodash';
import { authTokenStorage } from './authTokenStorage';

/** we don't allow more than the user id. we just want to know if the user is authenticated or not */

export enum AuthStatus {
  LOGGED_IN = 'logged-in',
  LOGGED_OUT = 'logged-out',
  ANONYMOUS = 'anonymous',
}

export interface LoggedInAuth {
  type: AuthStatus.LOGGED_IN;
  userId: string;
}

export interface AnonymousAuth {
  type: AuthStatus.ANONYMOUS;
  userId: string;
}

export interface LoggedOutAuth {
  type: AuthStatus.LOGGED_OUT;
}

export type Auth = AnonymousAuth | LoggedOutAuth | LoggedInAuth;

// the actual ID should come via the root application
const current = makeVar<Auth>({
  type: AuthStatus.LOGGED_OUT,
});

export function getAuth(): Auth {
  return current();
}

export function useAuth(): Auth {
  return useReactiveVar(current);
}

/**
 * sets the logged in user
 * this method assumes the meteor tokens are already set by parent application
 * clears all data in like authentication tokens
 * this should be called when the user changes from
 * - when the user is first known
 * - logged in to logout
 * - logout to login
 * - from user a to user b (wimpersonate)
 * note: applications using this package should still clear the ApolloClient themselves
 */
export function setAuth(auth: Auth) {
  if (isEqual(auth, current())) {
    return;
  }

  const newUserId = auth.type === AuthStatus.LOGGED_OUT ? undefined : auth.userId;

  if (authTokenStorage.authUserId !== newUserId) {
    authTokenStorage.authAccessToken = null;
    authTokenStorage.authUserId = null;
  }

  if (auth.type === AuthStatus.LOGGED_OUT) {
    authTokenStorage.impersonatorMeteorLoginToken = null;
    authTokenStorage.impersonatorMeteorUserId = null;
    authTokenStorage.impersonating = false;
  }

  current(auth);
}
