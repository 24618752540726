import { NiceModalHandler } from '@ebay/nice-modal-react';
import type * as CSS from 'csstype';
import React, { PropsWithChildren, useCallback } from 'react';
import { SimpleModal } from './SimpleModal';

export type ManagedModalProps = {
  modal: NiceModalHandler;
  contentLabel: string;
  /** return false to prevent closing */
  onBeforeClose?: () => void | boolean;
  width?: CSS.Property.Width;
  height?: CSS.Property.Height;
  maxHeight?: CSS.Property.MaxHeight;
  overflowHidden?: boolean;
};

/** A modal whose state is handled by a `NiceModalHandler`. */
export function ManagedModal({
  modal,
  contentLabel,
  onBeforeClose,
  width,
  height,
  maxHeight,
  overflowHidden,
  children,
}: PropsWithChildren<ManagedModalProps>) {
  const onAfterClose = useCallback(() => {
    modal.remove();
  }, [modal]);

  const onRequestClose = useCallback(async () => {
    const resp = onBeforeClose && onBeforeClose();
    if (resp === false) {
      return;
    }
    await modal.hide();
  }, [onBeforeClose, modal]);

  return (
    <SimpleModal
      isOpen={modal.visible}
      contentLabel={contentLabel}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      width={width}
      height={height}
      maxHeight={maxHeight}
      overflowHidden={overflowHidden}
    >
      {children}
    </SimpleModal>
  );
}
