import { styled } from '@lessonup/ui-components';
import React from 'react';
import { AnswerCardPlaceholderSize } from './AnserCardPlaceholder.types';

export interface AnswerCardPlaceholderProps {
  size: AnswerCardPlaceholderSize;
}

export const AnswerCardPlaceholder: React.FC<AnswerCardPlaceholderProps> = ({ size }) => {
  return <StyledAnswerCardPlaceholder size={size} />;
};

const StyledAnswerCardPlaceholder = styled.div<AnswerCardPlaceholderProps>`
  background-color: #d9d9d9; // There is no supported color in our color system for this, but this component will get a new styling eventually.
  width: 100%;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          height: 56px;
          min-height: 56px;
        `;
      case 'medium':
        return `
          height: 80px;
          min-height: 80px;
        `;
      case 'large':
        return `
          height: 99px;
          min-height: 99px;
        `;
    }
  }}
`;
