import { FetchResult, Observable } from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';
import { refreshTokens } from '../../auth/refreshTokens';

// based on https://github.com/hsk-kr/apollo-graphql-refresh-token/blob/main/client/src/App.tsx

const UNAUTHORIZED_ERROR_CODES = ['UNAUTHENTICATED', 'UNAUTHORIZED'];

export const onErrorFunction: ErrorLink.ErrorHandler = ({ graphQLErrors, operation, forward }) => {
  const unauthenticatedError = graphQLErrors?.find(
    (error) => typeof error.extensions?.code === 'string' && UNAUTHORIZED_ERROR_CODES.includes(error.extensions.code)
  );

  if (!unauthenticatedError) return;

  return new Observable<FetchResult<Record<string, unknown>>>((observer) => {
    (async () => {
      try {
        await refreshTokens();

        // Retry the failed request
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        forward(operation).subscribe(subscriber);
      } catch (err) {
        observer.error(err);
      }
    })();
  });
};

export const reauthorizeLink = onError(onErrorFunction);
