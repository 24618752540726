import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconComponentImage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.94 16.903c.231 2.16 1.969 3.899 4.129 4.14 1.604.178 3.252.332 4.931.332m-9.06-4.472c-.17-1.595-.315-3.233-.315-4.903s.144-3.308.315-4.903c.231-2.16 1.969-3.899 4.129-4.14 1.604-.178 3.252-.332 4.931-.332s3.327.154 4.93.332c2.161.241 3.899 1.98 4.13 4.14.17 1.595.315 3.233.315 4.903s-.144 3.307-.315 4.903m-18.12 0c-.14-1.311-.263-2.65-.302-4.013q.917-.143 1.847-.14a11.04 11.04 0 0 1 10.98 8.444M12 21.375c1.68 0 3.327-.154 4.93-.332m-4.93.332a37 37 0 0 0 3.466-.18m1.465-.152c2.16-.241 3.898-1.98 4.13-4.14m-4.13 4.14q-.727.081-1.465.151m5.594-4.291q.039-.36.075-.723a11.7 11.7 0 0 0-3.15-.43 11.8 11.8 0 0 0-4.695.96v.1a11.04 11.04 0 0 1 2.176 4.384m-.841-9.944a2.625 2.625 0 1 0 0-5.25 2.625 2.625 0 0 0 0 5.25"
    />
  </svg>
);
export default SvgIconComponentImage;
