import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';

export type DefinitionListItemPriority = 'low' | 'high';
export type DefinitionListItemOrientation = 'horizontal' | 'vertical';

export interface DefinitionListItemProps {
  term: string;
  description: React.JSX.Element | string;
  priority?: DefinitionListItemPriority;
  orientation?: DefinitionListItemOrientation;
  descriptionOverflow?: 'wrap' | 'ellipsis';
}

export function DefinitionListItem({
  description,
  term,
  orientation,
  priority,
  descriptionOverflow,
}: DefinitionListItemProps) {
  return (
    <StyledDefinitionListItem
      direction={orientation === 'horizontal' ? 'x' : 'y'}
      spacing={orientation === 'horizontal' ? spacing.size16 : spacing.size0}
    >
      <StyledTerm weight="bold" size={priority === 'low' ? 'small' : 'medium'} orientation={orientation} as="dt">
        {term}
      </StyledTerm>
      <StyledDescription
        size={priority === 'low' ? 'small' : 'medium'}
        as="dd"
        descriptionOverflow={descriptionOverflow}
        {...(typeof description === 'string' && { title: description })}
      >
        {description}
      </StyledDescription>
    </StyledDefinitionListItem>
  );
}

const StyledDefinitionListItem = styled(SpaceBetween)`
  padding: ${spacing.size4} ${spacing.size16};
`;

const sharedTermAndDescriptionStyle = css`
  vertical-align: top;
`;

const StyledTerm = styled(BodyText)<Pick<DefinitionListItemProps, 'orientation'>>`
  ${sharedTermAndDescriptionStyle}
  flex-shrink: 0;

  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      display: inline-block;
      width: ${rem('144px')};
    `}
`;

const StyledDescription = styled(BodyText)<Pick<DefinitionListItemProps, 'orientation' | 'descriptionOverflow'>>`
  ${sharedTermAndDescriptionStyle}
  margin: 0;
  ${({ descriptionOverflow }) =>
    descriptionOverflow === 'ellipsis' &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      display: inline-block;
    `}
`;
