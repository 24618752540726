import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconStudentQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.08 21.167 4.714 17.59a1.885 1.885 0 0 1 1.701-3.335l2.46.912V5.792a1.875 1.875 0 0 1 3.75 0v5.666-6.791a1.875 1.875 0 0 1 3.75 0v6.75-4.5a1.875 1.875 0 0 1 3.75 0v10.5c-.027 2.209-.382 3.004-1.5 3.791m-9.74-6.041c2.129 0 3.747.75 4.528 2.452"
    />
  </svg>
);
export default SvgIconStudentQuestion;
