import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';
import { EduSystemBase } from './EduSystemBase';
import {
  eduSystemEditDataDuration,
  eduSystemEditDataLevel,
  eduSystemEditDataSchoolType,
  eduSystemEditDataSubject,
  eduSystemEditDataYear,
} from './eduSystemShared';
const i18n = globalI18nProxy();

type SchoolType = 'ko' | 'lo' | 'blo' | 'so' | 'bso' | 'ho' | 'dko';
export type EduSystemBESchoolType = SchoolType;
export class EduSystemBE extends EduSystemBase<SchoolType> {
  public key = 'BE';
  public country() {
    return 'be' as const;
  }
  public constructor() {
    super('so');
  }

  public EDIT_DATA() {
    return [
      eduSystemEditDataDuration(() => i18n.__('Lesduur')),
      eduSystemEditDataSchoolType(() => i18n.__('Onderwijsniveau')),
      eduSystemEditDataLevel(() => i18n.__('Onderwijsvorm')),
      eduSystemEditDataYear(() => i18n.__('Leeftijd'), 'age'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar KO'), 'grade-kin'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar LO'), 'grade-pe'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar SO'), 'grade-se'),
      eduSystemEditDataYear(() => i18n.__('Studiejaar'), 'studyYear'),
      eduSystemEditDataSubject(() => i18n.__('Vak'), ['subjects', 'BESubjects']),
    ];
  }
  protected _SYSTEM_DATA() {
    const dict: EduSystem.SystemDataDict<SchoolType> = {
      ko: {
        id: 'ko',
        short: 'KO',
        label: 'Kleuteronderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-kin',
            type: 'toggle',
            labeler: 'LabelValue',
            label: () => i18n.__('Leerjaar'),
            options: [
              { id: 3, value: '1' },
              { id: 4, value: '2' },
              { id: 5, value: '3' },
            ],
          },
        ],
      },
      lo: {
        id: 'lo',
        short: 'LO',
        label: 'Lager onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-pe',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 6, value: '1' },
              { id: 7, value: '2' },
              { id: 8, value: '3' },
              { id: 9, value: '4' },
              { id: 10, value: '5' },
              { id: 11, value: '6' },
            ],
          },
        ],
      },

      blo: {
        id: 'blo',
        short: 'BLO',
        label: 'Buitengewoon lager onderwijs',
        prefix: 'het',
        //defaultExpanded: true,
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-pe',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 6, value: '1' },
              { id: 7, value: '2' },
              { id: 8, value: '3' },
              { id: 9, value: '4' },
              { id: 10, value: '5' },
              { id: 11, value: '6' },
            ],
          },
        ],
      },

      so: {
        id: 'so',
        short: 'SO',
        label: 'Secundair onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            useOnlyForYears: true,
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-se',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 12, value: '1' },
              { id: 13, value: '2' },
              { id: 14, value: '3' },
              { id: 15, value: '4' },
              { id: 16, value: '5' },
              { id: 17, value: '6' },
            ],
          },
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Onderwijsvorm'),
            options: [
              { id: 'ASO', value: 'Algemeen secundair onderwijs' },
              { id: 'BSO', value: 'Beroepssecundair onderwijs' },
              { id: 'TSO', value: 'Technisch secundair onderwijs ' },
            ],
          },
        ],
      },

      bso: {
        id: 'bso',
        short: 'BSO',
        label: 'Buitengewoon secundair onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-se',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 12, value: '1' },
              { id: 13, value: '2' },
              { id: 14, value: '3' },
              { id: 15, value: '4' },
              { id: 16, value: '5' },
              { id: 17, value: '6' },
            ],
          },
          {
            id: 'subject',
            // rest is extended below static definition
          },
        ],
      },
      ho: {
        id: 'ho',
        short: 'HO',
        label: 'Hoger onderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'studyYear',
            type: 'toggle',
            label: () => i18n.__('Leerjaar'),
            labeler: 'LabelValue',
            options: [
              { id: 18, value: '1' },
              { id: 19, value: '2' },
              { id: 20, value: '3' },
              { id: 21, value: '4' },
              { id: 22, value: '5' },
              { id: 23, value: '6' },
            ],
          },
        ],
      },

      dko: {
        id: 'dko',
        short: 'DKO',
        label: 'Deeltijds kunstonderwijs',
        prefix: 'het',
        fields: [
          {
            id: 'subject',
            // rest is extended below static definition
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'age',
            type: 'toggle',
            label: () => i18n.__('Leeftijd'),
            labeler: 'LabelValue',
            options: [
              { id: 6, value: '6' },
              { id: 7, value: '7' },
              { id: 8, value: '8' },
              { id: 9, value: '9' },
              { id: 10, value: '10' },
              { id: 11, value: '11' },
              { id: 12, value: '12' },
              { id: 13, value: '13' },
              { id: 14, value: '14' },
              { id: 15, value: '15' },
              { id: 16, value: '16' },
              { id: 17, value: '17' },
            ],
          },
        ],
      },
    };
    return dict;
  }

  public labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
    const { subjects, schoolType } = metaData;
    const tags: EduSystem.MetaDataTags = {
      subjects: [],
      schoolType: [],
      levels: [],
      years: [],
    };
    tags.subjects = subjects;
    if (!schoolType) return tags;
    tags.schoolType = _.compact(schoolType.map((st) => this.SYSTEM_DATA_DICT[st] && this.SYSTEM_DATA_DICT[st].label));

    const { hasDKO, hasLO, hasSO, hasHO } = hasEduSystem(metaData);
    if (hasLO) {
      const value = this.getFieldSummary({ schoolType: 'lo', lesson: metaData, fieldName: 'years' });
      if (value) {
        const label = hasSO ? 'LO Leerjaar' : 'Leerjaar';
        tags.years.push(`${label} ${value}`);
      }
    }

    if (hasSO) {
      const label = hasLO ? 'SO Leerjaar' : 'Leerjaar';
      const value = this.getFieldSummary({ schoolType: 'so', lesson: metaData, fieldName: 'years' });
      if (value) {
        tags.years.push(`${label} ${value}`);
      }
    }

    if (hasDKO) {
      const value = this.getFieldSummary({ schoolType: 'dko', lesson: metaData, fieldName: 'years' });
      if (value) {
        tags.years.push(`Leeftijd ${value}`);
      }
    }

    if (hasHO) {
      const value = this.getFieldSummary({ schoolType: 'ho', lesson: metaData, fieldName: 'years' });
      if (value) {
        tags.years.push(`Studiejaar ${value}`);
      }
    }
    return tags;
  }
}

function hasEduSystem(metaData: EduSystem.MetaDataParams) {
  return {
    hasLO: !!(metaData.schoolType && metaData.schoolType.some((st) => ['lo', 'blo'].includes(st))),
    hasSO: !!(metaData.schoolType && metaData.schoolType.some((st) => ['so', 'bso'].includes(st))),
    hasHO: !!(metaData.schoolType && metaData.schoolType.some((st) => ['ho'].includes(st))),
    hasDKO: !!(metaData.schoolType && metaData.schoolType.some((st) => ['dko'].includes(st))),
  };
}
