import { TeacherPin } from '@lessonup/pin-renderer';

export const i18nextNamespace = 'editor';

export const findNextPinToFocus = (
  activePin: string,
  pins: TeacherPin[],
  pinIdsToDelete: string[]
): string | undefined => {
  const pinIds = pins.map((p) => p.id);
  const pinIndicesToDelete = new Set(pinIdsToDelete.map((id) => pinIds.indexOf(id)).filter((index) => index !== -1));
  const activePinIndex = pins.findIndex((p) => p.id === activePin);

  // if we don't know where we are, either return the next non-deleted pin index or the previous one
  if (activePinIndex === -1) {
    const maxDeleteIndex = Math.max(...pinIndicesToDelete);
    if (maxDeleteIndex < pinIds.length - 1) {
      return pinIds[maxDeleteIndex + 1];
    }
    const minDeleteIndex = Math.min(...pinIndicesToDelete);
    if (minDeleteIndex > 0) {
      return pinIds[minDeleteIndex - 1];
    }
    return undefined;
  }

  // if the active pin is not deleted, return it
  if (!pinIndicesToDelete.has(activePinIndex)) {
    return activePin;
  }

  // if we know where we are, return the closest non-deleted pin index
  for (let i = 1; i < pinIds.length; i++) {
    // Check the next pin
    if (activePinIndex + i < pinIds.length && !pinIndicesToDelete.has(activePinIndex + i)) {
      return pinIds[activePinIndex + i];
    }
    // Check the previous pin
    if (activePinIndex - i >= 0 && !pinIndicesToDelete.has(activePinIndex - i)) {
      return pinIds[activePinIndex - i];
    }
  }

  // Return undefined if no next or previous pin is found
  return undefined;
};
