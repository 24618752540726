import { TeacherPin, UpdatePinActionWithMetadata, UpdatePinActionWithMetaSingleOrMulti } from '@lessonup/pin-renderer';
import { isEqual } from '@lessonup/utils';

type FilterDebounceAction<T> = T extends { debounce?: number } ? T : never;

export type DebouncedPinAction = FilterDebounceAction<UpdatePinActionWithMetadata> & {
  debounce: number;
};

type FilterDryRunAction<T> = T extends { dryRun?: boolean } ? T : never;

type FilterClearAction<T> = T extends { type: 'clearDryRun' } ? T : never;

export type ClearDryRunAction = FilterClearAction<UpdatePinActionWithMetadata>;

export type EditorDryRunAction = FilterDryRunAction<UpdatePinActionWithMetadata>;

export function isEditorDebounceAction(action: UpdatePinActionWithMetaSingleOrMulti): action is DebouncedPinAction {
  if (Array.isArray(action)) {
    return action.every(isEditorDebounceAction);
  }
  return 'debounce' in action && !!action.debounce && action.debounce > 0;
}

export function isEditorDryRunAction(
  action: UpdatePinActionWithMetaSingleOrMulti
): action is EditorDryRunAction | ClearDryRunAction {
  if (Array.isArray(action)) {
    return action.every(isEditorDryRunAction);
  }
  if (action.type === 'clearDryRun') return true;
  return 'dryRun' in action;
}

export const isDifferentDebounceTarget = (current: DebouncedPinAction | null, previous: DebouncedPinAction | null) => {
  if (!current || !previous) {
    return true;
  }
  if ('settings' in current && 'settings' in previous) {
    const { settings: settingsOne, ...restOne } = current;
    const { settings: settingsTwo, ...restTwo } = previous;
    return !isEqual(restOne, restTwo) || !isEqual(Object.keys(settingsOne), Object.keys(settingsTwo));
  }

  return !isEqual(current, previous);
};

export const mergeStateWithDebouncedAction = (
  state: TeacherPin[],
  debounceAction: DebouncedPinAction | null,
  dryRunAction: EditorDryRunAction | null,
  pinIndexMap: Map<string, number>
): TeacherPin[] => {
  const anyAction = debounceAction || dryRunAction;
  if (!anyAction) {
    return state;
  }

  const pinIndex = anyAction.pinId ? pinIndexMap.get(anyAction.pinId) : undefined;

  if (pinIndex === undefined) {
    return state;
  }
  const pin = state[pinIndex];
  let newPin: TeacherPin | null = null;

  if (debounceAction) {
    newPin = debounceActionHandler(debounceAction, pin);
  }

  if (dryRunAction) {
    const newDryRunPin = dryRunHandler(dryRunAction, pin);
    // we will flush the debounced action when we have a dry run action
    // so its safe to override the potential debounced action here (should never happen)
    if (newDryRunPin) {
      newPin = newDryRunPin;
    }
  }
  if (!newPin) return state;
  const newState = [...state];
  newState[pinIndex] = newPin;
  return newState;
};

type ActionHandlerResponse = TeacherPin | null;

// I do not like the type casts in this function, but this operation is inherently not type safe
// We are updating a Pin T with a Partial<T> and we do not know if the Partial<T> is a valid update for T
// It works, because of convention that we will not update a Quiz pin with a SlidePin action
// But we can not enforce this in typescript without a lot convoluted type narrowing and run time performance cost
function debounceActionHandler(debounceAction: DebouncedPinAction, pin: TeacherPin): ActionHandlerResponse {
  switch (debounceAction.type) {
    case 'updatePinComponentSettings': {
      const component = pin.pinComponents.find((component) => component.id === debounceAction.pinComponentId);
      if (!component) {
        return null;
      }
      const newPinComponents = pin.pinComponents.map((component) =>
        component.id === debounceAction.pinComponentId
          ? {
              ...component,
              settings: { ...component.settings, ...debounceAction.settings },
            }
          : component
      );
      return { ...pin, pinComponents: newPinComponents } as TeacherPin;
    }
    case 'updateComponentLayout': {
      const updatedComponentIds = Object.keys(debounceAction.updates);
      const component = pin.pinComponents.filter((component) => updatedComponentIds.includes(component.id));
      if (!component.length) {
        return null;
      }

      const newPinComponents = pin.pinComponents.map((component) => {
        if (!updatedComponentIds.includes(component.id)) return component;

        return {
          ...component,
          layout: debounceAction.updates[component.id],
        };
      });
      return { ...pin, pinComponents: newPinComponents };
    }
    case 'updatePinSettings': {
      return { ...pin, settings: { ...pin.settings, ...debounceAction.settings } } as TeacherPin;
    }
    case 'updatePinTeacherSettings': {
      return { ...pin, teacherSettings: { ...pin.teacherSettings, ...debounceAction.settings } } as TeacherPin;
    }
    default: {
      console.warn('Unknown debounceAction action type', debounceAction);
    }
  }
  return null;
}

function dryRunHandler(dryRunAction: EditorDryRunAction, pin: TeacherPin): ActionHandlerResponse {
  switch (dryRunAction.type) {
    case 'addPinComponents': {
      return {
        ...pin,
        pinComponents: [...pin.pinComponents, ...dryRunAction.pinComponents],
      };
    }
    default: {
      console.warn('Unknown dryRunAction action type', dryRunAction);
    }
  }
  return null;
}
