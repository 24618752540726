import { minimumVideoComponentSize } from '@lessonup/pins-shared';
import { assertNever } from '@lessonup/utils';
import React from 'react';
import { VideoSource, VimeoPlayerProps, YouTubePlayerProps } from './videoPlayerTypes';
import { VimeoPlayerComponent } from './VimeoPlayerComponent';
import { YouTubePlayerComponent } from './YouTubePlayerComponent';

interface VideoPlayerComponentProps {
  source: VideoSource;
  sourceId: string;
  compId: string;
  startTime?: number;
  endTime?: number;
  scaledWidth?: number;
  scaledHeight?: number;
}

export const VideoPlayerComponent: React.FC<VideoPlayerComponentProps> = (props) => {
  const { source } = props;

  switch (source) {
    case 'YOUTUBE': {
      const youTubeProps: YouTubePlayerProps = {
        sourceId: props.sourceId,
        compId: props.compId,
        startTime: props.startTime,
        endTime: props.endTime,
        scaledWidth: props.scaledWidth || minimumVideoComponentSize.width,
        scaledHeight: props.scaledHeight || minimumVideoComponentSize.height,
      };
      return <YouTubePlayerComponent {...youTubeProps} />;
    }

    case 'VIMEO': {
      const vimeoProps: VimeoPlayerProps = {
        sourceId: props.sourceId,
        compId: props.compId,
        startTime: props.startTime,
        endTime: props.endTime,
      };
      return <VimeoPlayerComponent {...vimeoProps} />;
    }

    case 'SCHOOL_TV': {
      return <div>{'TODO: Implement SchoolTV player'}</div>;
    }

    default:
      return assertNever(source, 'Unsupported video source');
  }
};
