import { useTogglePopoverModal } from '@lessonup/client-integration';
import { Pin } from '@lessonup/pin-renderer';
import { PlayerStoreNavigationState } from '@lessonup/players-modern';
import { IconArrowLeft, IconArrowRight, useModal } from '@lessonup/ui-components';
import React, { useRef } from 'react';
import { usePlayerTranslation } from '../../../../features/Player/hooks/usePlayerTranslation';
import { PinModalPopover } from '../PinModalPopover';
import { PlayerBarBottomButton } from './PlayerBarBottomButton';
import { PlayerBarBottomSubGroup } from './PlayerBarBottomModules';

interface PlayerBarBottomNavigationProps {
  onNextClick: (e: React.MouseEvent) => Promise<void>;
  onPreviousClick: (e: React.MouseEvent) => void;
  pins: Pin[];
  onPinClick: (pinId: string) => void;
  activePinId?: string | null;
  navState: PlayerStoreNavigationState;
}

export const PlayerBarBottomNavigation: React.FC<PlayerBarBottomNavigationProps> = ({
  onPreviousClick,
  onNextClick,
  pins,
  activePinId,
  onPinClick,
  navState,
}) => {
  const { t } = usePlayerTranslation();
  const pinModal = useModal(PinModalPopover);
  const slidesButtonRef = useRef<HTMLButtonElement>(null);
  const index = pins.findIndex((pin) => pin.id === activePinId) + 1;
  const total = pins.length;

  const [slideButtonHandleClick, slideButtonHandleMouseDown, isModalVisible] = useTogglePopoverModal(pinModal, {
    customModalShow: () =>
      pins &&
      pinModal.show({
        pins,
        parentSelector: () => slidesButtonRef.current,
        activePinId,
        direction: 'top',
        modalAnchor: 'center',
        onPinClick,
      }),
  });

  return (
    <PlayerBarBottomSubGroup>
      <PlayerBarBottomButton
        label={t('labels.previous')}
        onClick={onPreviousClick}
        iconStart={<IconArrowLeft />}
        tipped
        disabled={!navState.hasPrevious}
      />
      <PlayerBarBottomButton
        label={t('labels.slides')}
        ref={slidesButtonRef}
        active={isModalVisible}
        onClick={slideButtonHandleClick}
        onMouseDown={slideButtonHandleMouseDown}
        tipped
      >
        {index}
        {' / '}
        {total}
      </PlayerBarBottomButton>
      <PlayerBarBottomButton
        label={t('labels.next')}
        onClick={onNextClick}
        iconStart={<IconArrowRight />}
        disabled={!navState.hasNext}
        tipped
      />
    </PlayerBarBottomSubGroup>
  );
};
