import { Pin, PinViewerStatic } from '@lessonup/pin-renderer';
import {
  createModal,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopOverParentSelector,
  spacing,
  styled,
  Thumb,
  useModal,
} from '@lessonup/ui-components';
import React, { ForwardedRef, forwardRef, HTMLAttributes, useRef } from 'react';

interface SlideThumbComponentProps extends HTMLAttributes<HTMLButtonElement> {
  pin: Pin;
  index: number;
  isSelected: boolean;
  onSlideThumbClick: () => void;
}

export const SlideThumbComponent = forwardRef(function SlideThumbComponent(
  { pin, onSlideThumbClick, index, isSelected, ...buttonProps }: SlideThumbComponentProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  return (
    <Thumb
      {...buttonProps}
      number={index + 1}
      key={pin.id}
      thumbType="editor"
      onClick={onSlideThumbClick}
      isActive={isSelected}
      ref={forwardedRef}
    >
      <PinViewerStatic pin={pin} width={184} isThumb />
    </Thumb>
  );
});

interface PinModalPopoverProps {
  pins: Pin[];
  activePinId?: string | null;
  onPinClick: (pinId: string) => void;
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: ModalPopOverParentSelector;
}
export const PinModalPopover = createModal(
  ({ pins, activePinId, onPinClick, direction, modalAnchor, parentSelector }: PinModalPopoverProps) => {
    const modal = useModal();

    // A bit hacky, but if we use a useEffect the ref will be null
    const didScroll = useRef(false);
    const setRefForScroll = (ref: HTMLDivElement | null) => {
      if (!didScroll.current && ref) {
        ref.scrollIntoView({ block: 'center' });
        didScroll.current = true;
      }
    };

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="Slides Modal Popover"
      >
        <ModalPopoverBody>
          {pins.map((pin, index) => (
            <SlideThumbComponent
              pin={pin}
              ref={activePinId === pin.id ? setRefForScroll : null}
              isSelected={activePinId === pin.id}
              index={index}
              key={`slides-modal-popover-${pin.id}-${index}`}
              onSlideThumbClick={() => {
                onPinClick(pin.id);
                modal.hide();
              }}
            />
          ))}
        </ModalPopoverBody>
      </ModalPopover>
    );
  }
);

const ModalPopoverBody = styled.div`
  padding: ${spacing.size12};
  max-height: 70vh;
`;
