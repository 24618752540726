import { OnMouseEventHandler, PinComponent } from '@lessonup/pin-renderer';
import React from 'react';
import { FloatingActionMenuContent } from '../../../../../components/FloatingActionMenu/Components/FloatingActionMenuContent';

interface PlayerFAMContentProps {
  selectedPinComponent?: PinComponent;
  onMouseUp: OnMouseEventHandler;
}

export const PlayerFAMContent: React.FC<PlayerFAMContentProps> = ({ selectedPinComponent, onMouseUp }) => {
  const content = PlayerFAMContentSwitch({ pinComponent: selectedPinComponent });

  if (!content) {
    return null;
  }

  return (
    <FloatingActionMenuContent onMouseUp={onMouseUp}>
      <PlayerFAMContentSwitch pinComponent={selectedPinComponent} />
    </FloatingActionMenuContent>
  );
};

interface PlayerFAMContentSwitchProps {
  pinComponent?: PinComponent;
}

export const PlayerFAMContentSwitch: React.FC<PlayerFAMContentSwitchProps> = ({ pinComponent }) => {
  switch (pinComponent?.type) {
    // ToDo: enable for ED-799 to implement image zoom
    // case 'IMAGE':
    //   return <ImagePinComponentSettings pinComponent={pinComponent} />;
    default:
      return null;
  }
};
