import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFile = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.083 8.34c.097 1.2.167 2.421.167 3.66 0 2.107-.202 4.163-.398 6.146a3.14 3.14 0 0 1-2.71 2.793c-1.663.218-3.382.436-5.142.436s-3.479-.218-5.142-.436a3.14 3.14 0 0 1-2.71-2.793c-.196-1.983-.398-4.04-.398-6.146 0-2.107.202-4.163.398-6.146a3.14 3.14 0 0 1 2.71-2.792c1.663-.22 3.382-.437 5.142-.437m8.083 5.716c-.234-2.885-2.54-5.296-5.421-5.575A28 28 0 0 0 12 2.625m8.083 5.716c.093 1.148.161 2.317.167 3.502a3 3 0 0 0-3-2.942H13.57V5.625a3 3 0 0 0-2.617-2.976q.522-.023 1.048-.024"
    />
  </svg>
);
export default SvgIconFile;
