import { getLocale } from '@lessonup/client-integration';
import { BodyText, fontSize, SpaceBetween, spacing, styled, Tab, TabList } from '@lessonup/ui-components';
import { differenceInDays, parseISO, startOfToday, wait } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LicenseSubscriptionInterval,
  PersonalizedSubscriptionTierPrice,
  SubscriptionTierType,
} from '../../../types/graphql';
import { schoolRequestQuoteUrl } from '../../../utils/bridge/externalLinks';
import { mySubscriptionRoute } from '../../../utils/bridge/teacherRoutes';
import { MySubscriptionQuery } from '../MySubscriptionFeature.graphql.generated';
import { mySubscriptionI18NNS } from '../MySubscriptionFeature.utils';
import { ToggleMonthlyYearly } from './LicenseStatus/ToggleMonthlyYearly';
import { PersonalSubscription } from './SubscriptionPage/PersonalSubscription';
import { SchoolSubscription } from './SubscriptionPage/SchoolSubscription';
import { getPersonalPricingCardData } from './SubscriptionPage/subscriptionPage.personal.utils';
import { getSchoolPricingCardData } from './SubscriptionPage/subscriptionPage.school.utils';
import { getTrialPricingCardData } from './SubscriptionPage/subscriptionPage.trial.utils';

export interface MySubscriptionProps {
  viewer: MySubscriptionQuery['viewer'];
  currency: PersonalizedSubscriptionTierPrice['price']['currency'];
  subscriptionInterval: LicenseSubscriptionInterval;
  setSubscriptionInterval: React.Dispatch<React.SetStateAction<LicenseSubscriptionInterval>>;
  onClickStartSubscription: (tierType: SubscriptionTierType) => () => Promise<void>;
  onClickManageSubscription: () => Promise<void>;
  page?: string;
  showSchoolPickerBanner: boolean;
}

export const MySubscription = ({
  viewer: {
    personalizedSubscriptionTiers,
    personalizedSchoolSubscriptionTiers,
    licenseSubscription,
    memberOfOrganizations,
    license,
  },
  subscriptionInterval,
  setSubscriptionInterval,
  onClickStartSubscription,
  onClickManageSubscription,
  page,
  showSchoolPickerBanner,
}: MySubscriptionProps) => {
  const activePage = license.status === 'SCHOOL' ? 'school' : page;
  const { t } = useTranslation(mySubscriptionI18NNS);
  const subscription = licenseSubscription ?? undefined;

  const trialSubscriptionCard = getTrialPricingCardData({
    tiers: personalizedSubscriptionTiers,
    startSubscription: onClickStartSubscription,
    subscriptionInterval: subscriptionInterval,
    expiresAt: license.expiresAt,
    t: t,
  });

  const personalSubscriptionCards = getPersonalPricingCardData({
    tiers: personalizedSubscriptionTiers,
    manageSubscription: onClickManageSubscription,
    startSubscription: onClickStartSubscription,
    status: license.status,
    expiresAt: license.expiresAt,
    subscriptionInterval: subscriptionInterval,
    currentPeriod: subscription?.currentPeriod,
    licenseOrigin: license.origin,
    isActive: subscription?.status === 'ACTIVE',
    t: t,
  });

  const schoolSubscriptionCards = getSchoolPricingCardData({
    onClick: async () => {
      location.assign(schoolRequestQuoteUrl(getLocale().language));
      // give the browser time to redirect
      await wait(2000);
    },
    tiers: personalizedSchoolSubscriptionTiers,
    t: t,
    language: getLocale().language,
    memberOfOrganizations,
  });

  const daysRemaining = license.expiresAt && differenceInDays(parseISO(license.expiresAt), startOfToday());

  return (
    <StyledMySubscriptionFeature direction="y" spacing={spacing.size16}>
      <SpaceBetween direction="y" spacing={spacing.size16}>
        <SpaceBetween direction="x" spacing={spacing.size0} justifyContent="space-between">
          {license.status !== 'SCHOOL' && (
            <TabList resizing="hug">
              <Tab
                isActive={activePage !== 'school'}
                href={mySubscriptionRoute.href({ page: 'personal' })}
                as="a"
                content={t('personal')}
                size="large"
              />
              <Tab
                isActive={activePage === 'school'}
                href={mySubscriptionRoute.href({ page: 'school' })}
                as="a"
                content={t('school')}
                size="large"
              />
            </TabList>
          )}

          {activePage !== 'school' && subscription?.status !== 'ACTIVE' && (
            <ToggleMonthlyYearly
              setSubscriptionInterval={setSubscriptionInterval}
              subscriptionInterval={subscriptionInterval}
            />
          )}
        </SpaceBetween>

        {activePage !== 'school' && !['EXPIRING', 'TRIAL'].includes(license.status) && (
          <PersonalSubscription cards={personalSubscriptionCards} licenseStatus={license.status} />
        )}

        {activePage !== 'school' && ['EXPIRING', 'TRIAL'].includes(license.status) && (
          <PersonalSubscription
            daysRemainingOnTrial={daysRemaining}
            startProSubscription={onClickStartSubscription('PRO')}
            cards={trialSubscriptionCard}
            licenseStatus={license.status}
          />
        )}

        {activePage === 'school' && (
          <SchoolSubscription
            showSchoolPickerBanner={showSchoolPickerBanner}
            licenseOrigin={license.origin}
            cards={schoolSubscriptionCards}
            memberOfOrganizations={memberOfOrganizations}
          />
        )}
      </SpaceBetween>
      <BodyText>{`* ${t('pricesIncludingVat')}`}</BodyText>
    </StyledMySubscriptionFeature>
  );
};

const StyledMySubscriptionFeature = styled(SpaceBetween)`
  width: 100%;

  // - Reset values meteor teacher global CSS sets
  font-size: ${fontSize.bodyTextSizeMedium};

  input:not([type='checkbox']) {
    -webkit-appearance: radio !important;
    margin: 0;
  }
`;
