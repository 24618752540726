import { EventEmitter } from 'events';
import { useEffect } from 'react';

/**
 * A React hook that listens for requests on an EventEmitter and responds with a handler.
 *
 * @template RequestType - The type of the request payload.
 * @template ResponseType - The type of the response payload.
 *
 * @param {EventEmitter} eventBus - The EventEmitter instance used for communication.
 * @param {string} requestEventName - The event name to listen for incoming requests.
 * @param {string} responseEventName - The event name to emit when sending responses.
 * @param {(request: RequestType) => Promise<ResponseType> | ResponseType} handler -
 *        A function to process the incoming request and return a response.
 *
 * @example
 * useEventBusResponder<MyRequest, MyResponse>(
 *   eventBus,
 *   'requestEvent',
 *   'responseEvent',
 *   async (request) => {
 *     // Process the request and return a response
 *     return { someResponseData: 'example' };
 *   }
 * );
 */

export function useEventBusResponder<RequestType, ResponseType>(
  eventBus: EventEmitter,
  requestEventName: string,
  responseEventName: string,
  handler: (request: RequestType) => Promise<ResponseType> | ResponseType
) {
  useEffect(() => {
    const handleRequest = async (request: RequestType) => {
      const response = await handler(request);
      eventBus.emit(responseEventName, response);
    };
    eventBus.on(requestEventName, handleRequest);

    return () => {
      eventBus.off(requestEventName, handleRequest);
    };
  }, [eventBus, requestEventName, responseEventName, handler]);
}
