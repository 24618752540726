import { colorSets, IconComponentVideo, styled, TextElement } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface VideoComponentPlaceholderProps {
  style?: React.CSSProperties;
}

export const VideoComponentPlaceholder: React.FC<VideoComponentPlaceholderProps> = ({ style }) => {
  const { t } = useTranslation('videoPinComponent');

  return (
    <StyledVideoComponentPlaceholder style={style}>
      <IconComponentVideo />
      <TextElement textStyle="caption">{t('addVideo')}</TextElement>
    </StyledVideoComponentPlaceholder>
  );
};

const StyledVideoComponentPlaceholder = styled.div`
  background: #f0f1f5;
  color: ${colorSets.neutral.onSubjacent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px dashed ${colorSets.neutral.strokeSubjacent};
`;
