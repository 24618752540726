import { parseStringWithOptions } from '@lessonup/utils';
import _ from 'lodash';

export namespace Wordweb {
  export const type = 'wordweb';

  export interface Content {
    word: string;
    opacity?: number;
    showImage?: boolean;
    imageSize?: string;
    color?: number;
    fontSize1?: number;
    colorfg?: string;
    colorbg?: string;
  }

  export interface Answer {
    words?: string[]; // raw words
    modifiedAt: number;
  }

  export interface Word {
    normalized: string;
    source: string;
  }

  export namespace Answer {
    export const maxWordLength = 150;

    export function numberOfWords(answer: Answer | undefined): number {
      return _.size(answer && answer.words);
    }

    export function words(answer: Answer | undefined): Word[] {
      return _.map(
        answer && answer.words,
        (raw): Word => ({
          source: raw,
          normalized: normalizeWordForComparison(raw),
        })
      );
    }

    export function normalizedWords(answer: Answer | undefined): string[] {
      return _.map(answer && answer.words, (word) => normalizeWordForComparison(word));
    }

    export function sourceWords(answer: Answer | undefined): string[] {
      return (answer && answer.words) || [];
    }

    export function containsWord(answer: Answer | undefined, word: string, caseInSensitive: boolean): boolean {
      const words = Answer.normalizedWords(answer);
      return words.includes(normalizeWordForComparison(word));
    }

    export function normalizeWordForComparison(word: string): string {
      return parseStringWithOptions(word, { asLowercase: true, normalizeDiacritics: true });
    }

    export function isDone(answer: Answer | undefined): boolean {
      return numberOfWords(answer) > 0;
    }
  }
}
