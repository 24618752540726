import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { HTMLAttributes, KeyboardEvent, useEffect, useRef } from 'react';
import { color, spacing } from '../../../foundations';
import { Button } from '../../Button/Button';
import { IconCheckbox, IconCheckboxChecked, IconDragIndicator } from '../../icons';
import { TableSlotCell, TableSlotCellProps } from '../TableSlotCell/TableSlotCell';
import { TableRowInteractivity, TableSelectedRowsDispatch } from '../types';
import { computeInteractivity } from '../utils';

export interface TableRowData {
  id: string;
  ariaLabel: string;
  cells: TableSlotCellProps[];
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  row: TableRowData;
  rowIndex: number;
  isKeyboardSelected: boolean;
  selectedRows?: string[];
  setSelectedRows?: TableSelectedRowsDispatch;
  interactivity?: TableRowInteractivity;
  hideLastBorder?: boolean;
}

export function TableRow(props: TableRowProps) {
  const ref = useRef<HTMLTableRowElement>(null);

  const { interactivity, isKeyboardSelected, selectedRows = [], setSelectedRows } = props;
  const { isSelectable, isDraggable, isSelectableIfDisabled } = computeInteractivity(interactivity);
  const { cells, ariaLabel } = props.row;
  const isSelected = selectedRows?.includes(props.row.id);
  const isDisabled = props.row.disabled;

  const toggleSelected = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation(); // make sure it doesn't trigger the onClick on the <tr>
    }

    if (!setSelectedRows) return;

    if (isSelected) {
      setSelectedRows(selectedRows.filter((id) => id !== props.row.id));
    } else {
      setSelectedRows([...selectedRows, props.row.id]);
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLElement>) => {
    if (!isKeyboardSelected) {
      return;
    }

    switch (event.key) {
      case ' ':
      case 'Space':
        toggleSelected();
        break;
      case 'Enter':
        if (props.row.onClick) {
          props.row.onClick();
        }
        break;
    }
  };

  useEffect(() => {
    // focus on row when selected by arrow keyboard navigation
    if (isKeyboardSelected && ref.current) {
      ref.current.focus();
    }
  }, [isKeyboardSelected]);

  return (
    <StyledRow
      ref={ref}
      isSelected={isSelected}
      aria-label={ariaLabel}
      tabIndex={isKeyboardSelected ? 0 : -1} // this make sure only 1 row of the table can be accessed via tabbing. This makes it easy to continue tabbing to another element (outside the table) on the page. Inside the table arrow keys can be used to switch rows.
      onKeyUp={handleKeyUp}
      onClick={props.row.onClick}
      isDisabled={isDisabled}
      isSelectable={isSelectable || (isDraggable && isSelectableIfDisabled)}
      isDraggable={isDraggable}
      {...props}
    >
      {(isSelectable || isDraggable) && (
        <td>
          {isDraggable && (
            <Button
              size="small"
              buttonType="neutral"
              className={tableDragIndicatorClassName}
              disabled={isDisabled}
              iconStart={<IconDragIndicator />}
            />
          )}

          {isSelectable && (
            <Button
              size="small"
              buttonType="neutral"
              role="checkbox"
              aria-checked={isSelected}
              className={tableRowCheckboxClassName}
              onClick={toggleSelected}
              disabled={isDisabled && !isSelectableIfDisabled}
              iconStart={isSelected ? <IconCheckboxChecked /> : <IconCheckbox />}
            />
          )}
        </td>
      )}
      {cells.map((cell, index) => (
        <td key={index}>
          <TableSlotCell {...cell} disabled={isDisabled && !isSelected} />
        </td>
      ))}
    </StyledRow>
  );
}

const tableRowCheckboxClassName = 'TableRowCheckbox';
const tableDragIndicatorClassName = 'TableRowDragIndicator';

const hoverAndKeyboardSelectedCss = ({ isDraggable }: { isDraggable: boolean }) => css`
  .${tableRowCheckboxClassName} {
    opacity: 1;
  }
  ${isDraggable &&
  css`
    .${tableDragIndicatorClassName} {
      visibility: visible;
    }
  `}
  background: ${color.neutral.surface1.background};
`;

interface StyledTableRowProps {
  isSelected?: boolean;
  isKeyboardSelected?: boolean;
  isDisabled?: boolean;
  isSelectable: boolean;
  isDraggable: boolean;
  hideLastBorder?: boolean;
}

// providing the ReturnType is a hotfix for a bug in Typescript (?)
export const StyledRow: ReturnType<typeof styled.tr<StyledTableRowProps>> = styled.tr<StyledTableRowProps>`
  border-bottom: 1px solid ${color.neutral.outline.background};
  ${({ hideLastBorder }) =>
    hideLastBorder &&
    css`
      :last-of-type {
        border-bottom: none;
      }
    `}
  display: flex;
  flex-direction: row;
  outline: 0;
  max-width: 100%;

  ${(props) =>
    props.isDisabled &&
    !props.isSelected &&
    css`
      background: ${color.additional.disabled.background};
    `}

  td,
  th {
    display: flex;
    align-items: center;
    padding-top: ${spacing.size8};
    padding-bottom: ${spacing.size8};
    min-width: ${rem('120px')};

    + td,
    + th {
      padding-left: ${spacing.size16};
    }

    ${(props) =>
      props.isSelectable
        ? css`
            &:first-of-type {
              padding-left: ${spacing.size16};
              width: ${rem('80px')}; // Space for two buttons side by side
              min-width: ${rem('80px')}; // Overrides the standard min-width.
              justify-content: right;

              + td,
              + th {
                flex: 1;
              }
            }
          `
        : css`
            &:first-of-type {
              padding-left: ${spacing.size16};
              flex: 1;
            }
          `}
    &:not(:first-of-type) {
      width: ${rem('136px')};
    }
  }

  td:last-child {
    padding-right: ${spacing.size16};
  }

  .${tableRowCheckboxClassName} {
    opacity: 0.25;
  }

  .${tableDragIndicatorClassName} {
    visibility: hidden;
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    ${(props) => hoverAndKeyboardSelectedCss(props)}
  }

  ${(props) =>
    props.isKeyboardSelected &&
    css`
      ${hoverAndKeyboardSelectedCss(props)}
    `}

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      .${tableRowCheckboxClassName} {
        pointer-events: auto;
      }
    `}

  ${(props) =>
    props.isSelected &&
    css`
      background: ${color.accent.secondaryContainer.background};

      .${tableRowCheckboxClassName} {
        opacity: 1;
      }
    `}
  ${(props) =>
    !props.isSelectable &&
    css`
      &:hover {
        background: ${color.neutral.surface1.background};
      }
    `}
`;
