import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.03 4.148a1.49 1.49 0 0 0-1.323-.972c-2.155-.098-3.455-.072-5.185.05-.722.05-1.296.62-1.38 1.34-.505 4.404-.673 8.824-.146 13.239.18 1.498 1.406 2.587 2.873 2.746 4.144.452 8.228.44 12.372-.011a3.14 3.14 0 0 0 2.767-2.735c.326-2.729.413-5.525.21-8.956A2.86 2.86 0 0 0 18.6 6.166c-2.016-.167-3.418-.176-5.897-.229a1.5 1.5 0 0 1-1.371-.977z"
    />
  </svg>
);
export default SvgIconFolder;
