import { TeacherPin } from '@lessonup/pin-renderer';
import React, { createContext } from 'react';
import { ActivePinId, SetActivePinId, SetSelectedPinIds } from '../../../context/EditorContext/EditorContext.types';
import { Collaborator } from '../../../utils/yjs/yjs.types';

export interface PinRailListContextProps {
  collaboratorsDict: Record<string, Collaborator[]>;
  activePinId: ActivePinId;
  selectedPinIds: string[];
  inPinSelectionMode: boolean;
  dbPins: TeacherPin[];
  setActivePinId: SetActivePinId;
  setSelectedPinIds: SetSelectedPinIds;
  contextMenuHandler: (event: React.MouseEvent, pin: TeacherPin) => void;
  handleOnMouseDown: (event: React.MouseEvent, pinId: string) => void;
}

export const PinRailListContext = createContext<PinRailListContextProps | undefined>(undefined);
