import {
  Box,
  isImagePinComponent,
  IsVideoPinComponent,
  Line,
  minimumVideoComponentSize,
  newSize,
  PinComponent,
  PinComponentLayout,
  Size,
  Vector,
} from '@lessonup/pins-shared';
import {
  GrippyPosition,
  isBoxLayout,
  lineEndPoint,
  PinComponentGroupTransformAction,
  PinComponentTransformAction,
} from '../../types';
import { ArrangedRailLayouts, RailLayout } from '../../types/helpLines/helpLines.types';
import {
  addVectors,
  boxRotatedVertices,
  calculateRotationAngle,
  isCornerGrippy,
  isSideGrippy,
  moveBox,
  moveLine,
  resizeBox,
  resizeLine,
  rotateBox,
  scaleBoxInGroup,
  scaleLineInGroup,
  scaleSize,
  scaleVector,
  subtractVectors,
  updateBox,
} from '../geometry';
import {
  createSnapAnchorsForBoxResize,
  createSnapAnchorsFromLayout,
  createVisibleRails,
  keepAspectRatioForSnapDisplacement,
  scaleDisplacementFromSideGrippy,
  snapDisplacement,
} from '../helpLines';

export const DEFAULT_MIN_SIZE = newSize(10, 10);

export function transformPinComponent(
  startState: PinComponent,
  deltaMouse: Vector,
  mousePosition: Vector,
  action: PinComponentTransformAction,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>,
  isClickEventModified: boolean
): PinComponent {
  return {
    ...startState,
    layout: isBoxLayout(startState.layout)
      ? transformBoxLayout(
          startState,
          startState.layout,
          deltaMouse,
          mousePosition,
          action,
          railLayouts,
          setVisibleRails,
          isClickEventModified
        )
      : transformLineLayout(startState.layout, deltaMouse, action, railLayouts, setVisibleRails),
  };
}

function transformBoxLayout(
  startState: PinComponent,
  startBox: Box,
  deltaMouse: Vector,
  mousePosition: Vector,
  action: PinComponentTransformAction,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>,
  isClickEventModified: boolean
): Box {
  switch (action.action) {
    case 'move': {
      const updatedBox = moveBox(startBox, deltaMouse);
      return snapLayoutOnMove(updatedBox, railLayouts, setVisibleRails);
    }
    case 'resize-box': {
      const shouldKeepAspectRatio = keepAspectRatio(startState, isClickEventModified, action.grippy);
      const minSize = getMinSize(startState);
      const updatedBox = resizeBox(startBox, deltaMouse, action.grippy, shouldKeepAspectRatio, minSize);
      return snapBoxLayoutOnResize(updatedBox, action.grippy, railLayouts, setVisibleRails, shouldKeepAspectRatio);
    }
    case 'rotate-box': {
      return rotateBox(startBox, calculateRotationAngle(startBox, mousePosition));
    }
    default: {
      return startBox;
    }
  }
}

function transformLineLayout(
  startLine: Line,
  deltaMouse: Vector,
  action: PinComponentTransformAction,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>
): Line {
  switch (action.action) {
    case 'move': {
      const updatedLine = moveLine(startLine, deltaMouse);
      return snapLayoutOnMove(updatedLine, railLayouts, setVisibleRails);
    }
    case 'resize-line': {
      const updatedLine = resizeLine(startLine, deltaMouse, action.endPoint);
      return snapLineLayoutOnResize(updatedLine, action.endPoint, railLayouts, setVisibleRails);
    }
    default: {
      return startLine;
    }
  }
}

export function transformComponentGroup(
  startState: PinComponent[],
  deltaMouse: Vector,
  action: PinComponentGroupTransformAction,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>
): PinComponent[] {
  switch (action.action) {
    case 'move-group': {
      const movedGroupBox = moveBox(action.groupBox, deltaMouse);
      const snapDistance = snapDistanceOnMove(movedGroupBox, railLayouts, setVisibleRails);
      const displacement = addVectors(deltaMouse, snapDistance);

      return startState.map((componentStartState) => {
        return {
          ...componentStartState,
          layout: isBoxLayout(componentStartState.layout)
            ? moveBox(componentStartState.layout, displacement)
            : moveLine(componentStartState.layout, displacement),
        };
      });
    }
    case 'resize-group': {
      // Group resizing should always respect the minimum size of the components and the groupbox should always keep its aspect ratio and should not be rotated

      // First resize like a normal box
      const resizedGroupBox = resizeBox(action.groupBox, deltaMouse, action.grippy, true, DEFAULT_MIN_SIZE);
      // Then try to snap the resized box
      const snappedGroupBox = snapBoxLayoutOnResize(resizedGroupBox, action.grippy, railLayouts, setVisibleRails, true);

      // To restrict a single component from being scaled too small, we calculate the smallest scalar that would hit the minimum size of the component
      const desiredScalar = snappedGroupBox.size.width / action.groupBox.size.width;
      const smallestAllowedScalar = calculateSmallestAllowedScalar(startState);
      const restrictedScalar = Math.max(smallestAllowedScalar, desiredScalar);

      // We then calculate the displacement that would be needed to restrict the scaling to the smallest allowed scalar
      const desiredDisplacement = subtractVectors(snappedGroupBox.position, action.groupBox.position);
      const restrictedDisplacement =
        desiredScalar === 1
          ? desiredDisplacement
          : scaleVector(desiredDisplacement, (1 - restrictedScalar) / (1 - desiredScalar));

      const restrictedGroupBox = updateBox(snappedGroupBox, {
        size: scaleSize(action.groupBox.size, restrictedScalar),
        position: addVectors(action.groupBox.position, restrictedDisplacement),
      });

      return startState.map((componentStartState) => {
        return {
          ...componentStartState,
          layout: isBoxLayout(componentStartState.layout)
            ? scaleBoxInGroup(componentStartState.layout, action.groupBox, restrictedGroupBox, restrictedScalar)
            : scaleLineInGroup(componentStartState.layout, action.groupBox, restrictedGroupBox, restrictedScalar),
        };
      });
    }
    default: {
      return startState;
    }
  }
}

const moveLayout = <L extends PinComponentLayout>(layout: L, displacement: Vector): L => {
  return (isBoxLayout(layout) ? moveBox(layout, displacement) : moveLine(layout, displacement)) as L;
};

const handleVisibleRails = (
  newLayout: PinComponentLayout,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>,
  roundingThreshold?: number
) => {
  const snapAnchorsAfterSnap = createSnapAnchorsFromLayout(newLayout);
  const visibleRails = createVisibleRails(snapAnchorsAfterSnap, railLayouts, roundingThreshold);

  setVisibleRails(visibleRails);
};

const snapLayoutOnMove = <L extends PinComponentLayout>(
  layout: L,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>
): L => {
  const displacement = calculateSnapLayoutDisplacement(layout, railLayouts);
  const newLayout = moveLayout(layout, displacement);
  handleVisibleRails(newLayout, railLayouts, setVisibleRails);

  return newLayout;
};

const snapDistanceOnMove = (
  layout: PinComponentLayout,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>
): Vector => {
  const displacement = calculateSnapLayoutDisplacement(layout, railLayouts);
  const newLayout = moveLayout(layout, displacement);
  handleVisibleRails(newLayout, railLayouts, setVisibleRails);

  return displacement;
};

const calculateSnapLayoutDisplacement = (layout: PinComponentLayout, railLayouts: ArrangedRailLayouts) => {
  const snapAnchors = createSnapAnchorsFromLayout(layout);
  return snapDisplacement(snapAnchors, railLayouts);
};

const snapBoxLayoutOnResize = (
  layout: Box,
  grippy: GrippyPosition,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>,
  keepAspectRatio = false
) => {
  const vertices = boxRotatedVertices(layout);
  const snapAnchors = createSnapAnchorsForBoxResize(vertices, grippy);
  const displacement = snapDisplacement(snapAnchors, railLayouts);
  const fixedDisplacement = isSideGrippy(grippy)
    ? scaleDisplacementFromSideGrippy(displacement, vertices, grippy)
    : keepAspectRatio
    ? keepAspectRatioForSnapDisplacement(displacement, grippy, layout)
    : displacement;
  const newLayout = resizeBox(layout, fixedDisplacement, grippy, keepAspectRatio, DEFAULT_MIN_SIZE);
  handleVisibleRails(newLayout, railLayouts, setVisibleRails, 1);

  return newLayout;
};

const snapLineLayoutOnResize = (
  layout: Line,
  endPoint: lineEndPoint,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>
) => {
  const snapAnchors = createSnapAnchorsFromLayout(layout, endPoint);
  const displacement = snapDisplacement(snapAnchors, railLayouts);
  const newLayout = resizeLine(layout, displacement, endPoint);
  handleVisibleRails(newLayout, railLayouts, setVisibleRails);

  return newLayout;
};

const calculateSmallestAllowedScalar = (startState: PinComponent[]): number => {
  return startState.reduce((minScalar, component) => {
    if (!isBoxLayout(component.layout)) return minScalar;
    const minSize = getMinSize(component);
    const scalarForMinSize = Math.max(
      minSize.width / component.layout.size.width,
      minSize.height / component.layout.size.height
    );

    return Math.max(minScalar, scalarForMinSize);
  }, 0);
};

/** Determines whether to keep the aspect ratio of the pin component based on the pin component type and whether the click event is modified. */
export function keepAspectRatio(
  pinComponent: PinComponent,
  isClickEventModified: boolean,
  grippyPosition: GrippyPosition
): boolean {
  if (isBoxLayout(pinComponent.layout) && pinComponent.layout.lockAspectRatio) return true;

  if (isImagePinComponent(pinComponent)) return isCornerGrippy(grippyPosition) && !isClickEventModified;
  return isClickEventModified;
}

export function getMinSize(pinComponent: PinComponent): Size {
  if (IsVideoPinComponent(pinComponent)) {
    return minimumVideoComponentSize;
  } else return DEFAULT_MIN_SIZE;
}
