import { borderRadius, color, css, elevationShadow, styled } from '@lessonup/ui-components';
import React, { PropsWithChildren } from 'react';

type BlockResizing = 'hug' | 'fixed';

type BlockProps = { resizing?: BlockResizing };

export const Block = ({ children, ...props }: PropsWithChildren<BlockProps>) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

const Wrapper = styled.div<Omit<BlockProps, 'children'>>`
  display: block;
  background-color: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded24};
  border: 1px solid ${color.neutral.outline.background};
  box-shadow: ${elevationShadow.e200};
  ${(props) =>
    props.resizing !== 'fixed' &&
    css`
      width: fit-content;
    `};
`;
