import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PartialPrefix } from '@lessonup/utils';
import type * as CSS from 'csstype';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { ComponentSize } from '../../foundations/size/size.types';
import { spacing, spacingScaled } from '../../foundations/spacing/spacing';

interface SpaceBetweenProps extends HTMLAttributes<HTMLDivElement> {
  direction: 'x' | 'y';
  spacing: spacing;
  size?: ComponentSize;
  alignItems?: CSS.Property.AlignItems;
  justifyContent?: CSS.Property.JustifyContent;
  as?: keyof React.JSX.IntrinsicElements;
  padding?: CSS.Property.Padding;
}

type SpaceBetweenStyledProps = PartialPrefix<SpaceBetweenProps, 'spacing' | 'direction'>;

export function SpaceBetween({
  alignItems,
  direction,
  spacing,
  size,
  justifyContent,
  padding,
  children,
  ...rest
}: PropsWithChildren<SpaceBetweenProps>) {
  return (
    <SpaceBetweenStyled
      $spacing={spacing}
      $direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      padding={padding}
      size={size}
      {...rest}
    >
      {children}
    </SpaceBetweenStyled>
  );
}

const SpaceBetweenStyled = styled.div<SpaceBetweenStyledProps>`
  ${(props) => {
    switch (props.$direction) {
      case 'x':
        return css`
          flex-direction: row;
          > * ~ * {
            margin-left: ${spacingScaled(props.$spacing, props.size || 'small')} !important;
          }
        `;
      case 'y':
        return css`
          flex-direction: column;
          > * ~ * {
            margin-top: ${spacingScaled(props.$spacing, props.size || 'small')} !important;
          }
        `;
    }
  }}
  display: flex;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  padding: ${(props) => props.padding};
`;
