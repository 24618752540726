import { makeVar, PossibleTypesMap, useReactiveVar } from '@apollo/client';

/**
 * Configuration required by the client-integration library
 * should NOT contain any application specific config
 */
export interface IntegrationConfig {
  apiHost: string;
  /**
   *  to use the option `include` for the `RequestCredentials` for `fetch` requests. Required for the internal API.
   */
  includeCredentials?: boolean;
  /**
   * needed for InMemoryCache, generate them with codegen and @graphql-codegen/fragment-matcher
   */
  possibleTypes: PossibleTypesMap;
}

const current = makeVar<IntegrationConfig | undefined>(undefined);

/**
 * returns the config or throws an error when it's not initialized
 */
export function getIntegrationConfig(): IntegrationConfig {
  const value = current();
  if (!value) {
    throw new Error('config not initialized setIntegrationConfig');
  }
  return value;
}

/**
 * returns the config or throws an error when it's not initialized
 */
export function useIntegrationConfig() {
  const value = useReactiveVar(current);
  if (!value) {
    throw new Error('config not initialized, call setIntegrationConfig');
  }
  return value;
}

export function setIntegrationConfig(config: IntegrationConfig) {
  current(config);
}
