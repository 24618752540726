import { differenceInHours } from '@lessonup/utils';
import { hasIn, isNull } from 'lodash';

export const failedUploadLimit = 100;
export const minimumHoursBeforeReset = 4;
const localStorageKey = 'uploadFileLimiter';

type UploadFileLimiterStorageObject = {
  count: number;
  date: number;
};

export const uploadFileLimiter = (): boolean => {
  const localStorageEntry = localStorage.getItem(localStorageKey);
  const currentDateValue = getCurrentDateValue();
  const localStorageEntryObject = getParsedStorageEntry(localStorageEntry);

  const hoursElapsed = differenceInHours(currentDateValue, localStorageEntryObject.date, {
    roundingMethod: 'floor',
  });
  localStorageEntryObject.date = currentDateValue;
  localStorageEntryObject.count = hoursElapsed >= minimumHoursBeforeReset ? 1 : localStorageEntryObject.count + 1;

  localStorage.setItem(localStorageKey, JSON.stringify(localStorageEntryObject));

  return localStorageEntryObject.count >= failedUploadLimit;
};

const getCurrentDateValue = () => Date.now().valueOf();

const getParsedStorageEntry = (localStorageEntry: string | null): UploadFileLimiterStorageObject => {
  if (!isNull(localStorageEntry)) {
    try {
      const localStorageEntryObject = JSON.parse(localStorageEntry);
      if (hasIn(localStorageEntryObject, 'count') && hasIn(localStorageEntryObject, 'date')) {
        return localStorageEntryObject;
      }
    } catch {
      //
    }
  }
  return { count: 0, date: 1 };
};
