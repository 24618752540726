import { WithOptionalId } from '@lessonup/utils';
import _ from 'lodash';
import { LessonPin } from '../lesson/LessonPin';
import { Component } from './components/Component';
import { Fonts } from './fonts';
import { OpenQuestion } from './open-question/OpenQuestion';
import { PinUtils } from './PinUtils';
import { Video } from './video/Video';

export interface Pin<ContentType extends object = any> {
  _id: string;
  item: PinItem<ContentType>;
  order?: number;
  videoQuestion?: string;
  timer?: number;
}

export interface PinItem<ContentType extends _.Dictionary<any>> {
  custom: ContentType;
  type: PinType;
  components?: Component.All[];
  name?: string;
  image?: string;
  maxresImage?: string;
  image2?: string;
  source?: string; // externe / interne bronnen. hier wordt ook vaak het contenttype ingevuld, dit moet ooit worden veranderd naar 'lessonup' ipv het contenttype
  url?: string;
  uploadId?: string;
  exclusions?: ExclusionType[];
  shuffleMap?: any;

  videoItems?: Video.VideoItems[] | undefined;
  notes?: Note;

  testPoints?: number | undefined;
  settings?: PinSettings;
  modifiedDate?: Date;
  invalidVideo?: true;
  interactive?: boolean;
}

export interface PinSettings {
  closeOtherHotspots: boolean | undefined;
}

export type ExclusionType = 'student' | 'lesson'; // lesson == realtime

export interface Note {
  text?: string;
}
export const allPinTypes = [
  'openQuestion',
  'question',
  'wordweb',
  'dragQuestion',
  'slide',
  'video',
  'link',
  'map',
  'poll',
] as const;

export type PinType = (typeof allPinTypes)[number];

export const pinInteractiveTypes: PinType[] = ['openQuestion', 'question', 'wordweb', 'dragQuestion', 'poll'];

export const pinCustomizableTypes: PinType[] = ['slide', 'openQuestion', 'question', 'wordweb', 'poll', 'dragQuestion'];

const pinTypeEventNames = [
  'drag_and_drop',
  'link',
  'map',
  'open_question',
  'poll',
  'quiz',
  'slide',
  'video',
  'mind_map',
] as const;

type PinTypeEventName = (typeof pinTypeEventNames)[number];

const pinTypeToPinTypeForEvent: Record<PinType, PinTypeEventName> = {
  dragQuestion: 'drag_and_drop',
  link: 'link',
  map: 'map',
  openQuestion: 'open_question',
  poll: 'poll',
  question: 'quiz',
  slide: 'slide',
  video: 'video',
  wordweb: 'mind_map',
};

interface PinTypeFilterable {
  type: PinType;
}

export namespace Pin {
  export interface AspectRatio {
    width: number;
    height: number;
  }

  export function getContent<T extends Record<string, any>>(pin: Pin<T> | undefined): T | undefined {
    return pin && pin.item.custom;
  }

  export function filterList<T extends PinTypeFilterable>(list: T[], pinFilter: PinType[]): T[] {
    return list.filter((p) => pinFilter.includes(p.type));
  }

  export function isOfType<T extends Pin>(pin: T, ...types: PinType[]): boolean {
    return types.includes(pin.item.type);
  }

  export function isItemOfType<T extends PinItem<any>>(item: T, ...types: PinType[]): boolean {
    return types.includes(item.type);
  }

  export function pinTypeToEventName(pinType: PinType): PinTypeEventName {
    return pinTypeToPinTypeForEvent[pinType];
  }

  export const aspectRatio: AspectRatio = {
    width: 1.6,
    height: 0.9,
  };

  /** returns undefined if there are no child pins possible */
  export function childrenForPin<P extends Pin>(pin: P, otherPins: P[]): P[] | undefined {
    if (!Video.isInteractiveVideo(pin) || !pin.item.videoItems) {
      return undefined;
    }

    const childIds = pin.item.videoItems.map((i) => i.pinId);
    return _.filter(otherPins, (p) => childIds.includes(p._id));
  }

  export function hasInteractiveChildren<P extends Pin>(pin: P, allPins: P[]): boolean {
    if (pin.item.type !== 'video') {
      return false;
    }

    return _.some(pin.item.videoItems, (videoItem) => {
      const childPin = allPins.find((p) => p._id === videoItem.pinId);
      return childPin && isInteractive(childPin);
    });
  }

  export function withoutChildPins<P extends Pin>(pins: P[]): P[] {
    return _.filter(pins, (p) => !isChildPin(p));
  }

  export function isChildPin<P extends Pin>(pin: P): boolean {
    return !_.isNil(pin.videoQuestion);
  }

  export function isInteractive<P extends Pin>(pin: P): boolean {
    return PinUtils.isInteractive(pin);
  }

  export function isCustomizable<P extends Pin>(pin: P): boolean {
    return pinCustomizableTypes.includes(pin.item.type);
  }

  export function onlyInteractivePins<P extends Pin>(pins: P[]): P[] {
    return _.filter(pins, (p) => isInteractive(p));
  }

  export function sorted<P extends Pin>(pins: P[]): P[] {
    return pins.sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  export function isPhotoQuestion(pin: Pin): pin is Pin<OpenQuestion.LessonContent> {
    return isOfType(pin, 'openQuestion') && 'studentImage' in pin.item.custom;
  }

  export function isInteractiveVideo(pin: Pin): pin is Video.InteractiveVideoPin {
    return Boolean(isOfType(pin, 'video') && pin.item.interactive);
  }

  export function isRestrictedPin(pin: Pin): boolean {
    if (!pin?.item?.custom) return false;
    return (
      isPhotoQuestion(pin) ||
      isOfType(pin, 'dragQuestion') ||
      isOfType(pin, 'poll') ||
      isInteractiveVideo(pin) ||
      isChildPin(pin)
    );
  }

  interface DefaultFirstPinOptions {
    type: 'slide';
    lessonId: string;
    userId: string;
    question: string;
    isPrivate?: boolean;
    preferredFont?: Fonts.Key;
  }

  /**
   * @description Creates a default first pin for a newly created lesson. Only works with slides at the moment.
   */
  export function defaultFirstPinForLesson(options: DefaultFirstPinOptions): WithOptionalId<LessonPin> {
    return {
      creationDate: new Date(),
      lesson: options.lessonId,
      item: {
        type: options.type,
        custom: {
          text1: options.question,
          type: 'slide',
          layout: 1,
          font1: options.preferredFont,
          font2: options.preferredFont,
        },
      },
      isPublic: !options.isPrivate,
      order: 1001,
      shouldIndex: undefined,
      isFirstPin: true,
    };
  }
}
