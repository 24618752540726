import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { BodyText } from '../BodyText/BodyText';

export type ListHeaderType = 'low' | 'medium' | 'high';
export interface ListHeaderProps {
  priority: ListHeaderType;
  text: string;
  indentation?: boolean;
  startSlot?: React.ReactNode;
  endSlot?: React.ReactNode;
  subtitle?: string;
}

export const ListHeader: React.FC<ListHeaderProps> = (props) => {
  const { startSlot, endSlot, text, subtitle } = props;

  return (
    <HeaderContainer {...props}>
      {startSlot && <StartSlot>{startSlot}</StartSlot>}
      <div>
        <BodyText weight="bold" size="medium">
          {text}
        </BodyText>
        {subtitle && <Subtitle size="small"> {subtitle}</Subtitle>}
      </div>
      {endSlot && <EndSlot>{endSlot}</EndSlot>}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div<ListHeaderProps>`
  display: flex;
  flex-direction: row;
  gap: ${spacing.size8};

  padding: ${(props) =>
    props.priority === 'low'
      ? `${spacing.size4} 0`
      : props.priority === 'medium'
      ? `${spacing.size8} 0`
      : `${spacing.size12} 0`};

  ${(props) =>
    props.indentation &&
    css`
      padding-left: ${spacing.size16};
      padding-right: ${spacing.size16};
    `};
`;

const EndSlot = styled.div`
  justify-content: flex-end;
  margin-left: auto;
`;

const StartSlot = styled.div`
  justify-content: flex-start;
`;

const Subtitle = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
