import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLesson = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.273 19.33c.078.72.65 1.292 1.37 1.37 2.353.258 4.818.611 7.357.611s5.004-.353 7.358-.61a1.55 1.55 0 0 0 1.369-1.37c.251-2.345.584-4.801.584-7.331s-.333-4.986-.584-7.33a1.55 1.55 0 0 0-1.37-1.37c-2.353-.258-4.818-.611-7.357-.611s-5.004.353-7.358.61c-.72.08-1.291.65-1.369 1.37C3.022 7.014 2.69 9.47 2.69 12s.333 4.986.584 7.33"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.78 12c0 .844.144 2.057.283 3.029.123.864.977 1.418 1.8 1.125 1.973-.702 3.814-1.782 5.08-3.402a1.22 1.22 0 0 0 0-1.504c-1.266-1.62-3.107-2.7-5.08-3.402-.823-.293-1.677.261-1.8 1.125-.14.972-.284 2.185-.284 3.029"
    />
  </svg>
);
export default SvgIconLesson;
