import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isUndefined } from 'lodash';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, Children, forwardRef, PropsWithChildren } from 'react';
import { Colorset, colorSets, ComponentColorTheme, getColorset } from '../../foundations';
import { borderRadius, borderRadiusScaled } from '../../foundations/borders/borderRadius';
import { ComponentSize } from '../../foundations/size/size.types';
import { spacing, spacingScaled } from '../../foundations/spacing/spacing';
import { TextElement } from '../../foundations/typography';
import { lineHeightMap } from '../../foundations/typography/typography.utils';
import { LoaderSpinner } from '../LoaderSpinner/LoaderSpinner';
import { ButtonResizing, ButtonShape } from './button.types';

export interface SharedButtonProps {
  buttonType?: ComponentColorTheme;
  active?: boolean;
  size?: ComponentSize;
  buttonShape?: ButtonShape; // Will be removed in https://lessonup.atlassian.net/browse/TECH-131
  showStroke?: boolean;
  resizing?: ButtonResizing;
  iconStart?: React.JSX.Element;
  iconEnd?: React.JSX.Element;
  loading?: boolean;
  buttonLabel?: string;
  labelDirection?: 'row' | 'column';
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, SharedButtonProps {}
export interface ButtonAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement>, SharedButtonProps {}

export interface StyledButtonProps {
  buttonColorSet: Colorset;
  buttonType: ComponentColorTheme;
  size: ComponentSize;
  buttonShape: ButtonShape;
  showStroke: boolean;
  resizing: ButtonResizing;
  $loading: boolean;
  extraSideSpacing: boolean;
  withLabel: boolean;
}

/**
 * Need an anchor element? Use the ButtonAnchor component
 *
 * Pass icons through the iconStart and iconEnd props unless you need a custom implementation.
 */
export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(props, ref) {
  return renderButton(props, 'button', ref);
});

/**
 * Pass icons through the iconStart and iconEnd props unless you need a custom implementation.
 */
export function ButtonAnchor(props: PropsWithChildren<ButtonAnchorProps>) {
  return renderButton(props, 'a');
}

const renderButton = (
  props: PropsWithChildren<ButtonProps | ButtonAnchorProps>,
  as: 'button' | 'a' | 'label',
  ref?: React.Ref<HTMLButtonElement>
) => {
  const disabled = (props as ButtonProps).disabled || props.loading;
  const active = (props as ButtonProps).active;

  const { buttonType, size, showStroke, resizing, loading, buttonShape, ...rest } = props as SharedButtonProps;

  const withLabel = !isUndefined(props.buttonLabel) && props.buttonLabel !== '';

  const buttonColorSet = getColorset(buttonType || 'primary', { disabled, active });

  const styledProps: StyledButtonProps = {
    buttonColorSet,
    buttonType: buttonType ?? 'primary',
    size: size ?? 'medium',
    buttonShape: buttonShape ?? 'pill',
    showStroke: showStroke ?? false,
    resizing: resizing ?? 'hug',
    $loading: loading ?? false,
    extraSideSpacing: Children.count(props.children) > 0,
    withLabel,
  };

  const iconStart = !props.iconStart ? null : !props.loading ? props.iconStart : <LoaderSpinner />;
  const iconEnd = !props.iconEnd ? null : !props.loading ? props.iconEnd : <LoaderSpinner />;
  const loaderAsContent = styledProps.$loading && !iconStart && !iconEnd;

  if (!withLabel) {
    return (
      <StyledButton {...(ref ? { ref } : {})} {...styledProps} {...rest} as={as} disabled={disabled}>
        {iconStart}
        {Children.count(props.children) > 0 && (
          <StyledText textStyle="label" size={styledProps.size} weight="bold">
            {loaderAsContent && <StyleLoaderSpinner type="transparentOverlay" size={styledProps.size} />}
            <TruncateWrapper hideContent={loaderAsContent}>{props.children}</TruncateWrapper>
          </StyledText>
        )}
        {iconEnd}
      </StyledButton>
    );
  }
  // Button with label, design is still incomplete so it might need to be adjusted
  return (
    <ButtonWithLabelWrapper
      {...(ref ? { ref } : {})}
      $direction={props.labelDirection || 'column'}
      {...styledProps}
      {...rest}
      as={as}
    >
      <StyledButton {...styledProps} as="div" disabled={disabled}>
        {iconStart}
        {Children.count(props.children) > 0 && (
          <StyledText textStyle="label" size={styledProps.size} weight="bold">
            {loaderAsContent && <StyleLoaderSpinner type="transparentOverlay" size={styledProps.size} />}
            <TruncateWrapper hideContent={loaderAsContent}>{props.children}</TruncateWrapper>
          </StyledText>
        )}
        {iconEnd}
      </StyledButton>
      <StyledButtonLabel textStyle="label" size={styledProps.size} buttonType={styledProps.buttonType}>
        {props.buttonLabel}
      </StyledButtonLabel>
    </ButtonWithLabelWrapper>
  );
};

const StyledButton = styled.button<StyledButtonProps>`
  ${({ withLabel }) =>
    withLabel &&
    css`
      flex: 1;
    `}
  width: ${({ resizing }) => (resizing === 'hug' ? 'auto' : '100%')};
  display: ${({ resizing }) => (resizing === 'hug' ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => spacingScaled(spacing.size4, size)};

  // When there are children, there is extra padding on the sides
  ${({ showStroke, size, extraSideSpacing }) => {
    if (showStroke) {
      return css`
        padding: ${spacingScaled(spacing.size2, size)} ${extraSideSpacing ? spacingScaled(spacing.size8, size) : ''};
      `;
    }
    return css`
      padding: ${spacingScaled(spacing.size4, size)} ${extraSideSpacing ? spacingScaled(spacing.size10, size) : ''};
    `;
  }}

  border-radius: ${(props) =>
    props.buttonShape === 'rect'
      ? borderRadiusScaled(borderRadius.rounded4, props.size)
      : borderRadiusScaled(borderRadius.rounded32, props.size)};

  color: ${({ buttonColorSet }) => buttonColorSet.on};
  background: ${({ buttonColorSet }) => buttonColorSet.fill};
  background-origin: border-box;
  border: ${({ buttonColorSet, showStroke, size }) =>
    showStroke ? `${spacingScaled(spacing.size2, size)} solid ${buttonColorSet.stroke}` : 'none'};
  text-decoration: none;

  // iconStart & iconEnd should have the same height as text
  & > svg {
    flex-shrink: 0;
    width: ${({ size }) => lineHeightMap.label[size]};
    height: ${({ size }) => lineHeightMap.label[size]};
  }

  :hover {
    background: ${({ buttonColorSet }) => buttonColorSet.fillFeedback};
  }
`;

const StyledText = styled(TextElement)<{ size: ComponentSize }>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const TruncateWrapper = styled.div<{ hideContent: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: ${({ hideContent }) => (hideContent ? 0 : 1)};
`;

const StyleLoaderSpinner = styled(LoaderSpinner)<{ size: ComponentSize }>`
  svg {
    width: ${({ size }) => lineHeightMap.label[size]};
    height: ${({ size }) => lineHeightMap.label[size]};
  }
`;

const ButtonWithLabelWrapper = styled.button<StyledButtonProps & { $direction: 'row' | 'column' }>`
  min-width: fit-content;
  flex: 1;
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: center;
  border-radius: ${({ size }) => borderRadiusScaled(borderRadius.rounded4, size)};

  :hover {
    > div:first-of-type {
      background: ${({ buttonType }) => colorSets[buttonType].fillFeedback};
    }
  }
`;

const StyledButtonLabel = styled(TextElement)<{ buttonType: ComponentColorTheme; size: ComponentSize }>`
  color: ${({ buttonType }) => colorSets[buttonType].onSubjacent};
  padding: ${({ size }) => `0 ${spacingScaled(spacing.size4, size)}`};
  width: 100%;
`;
