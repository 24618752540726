import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 12.681c0-.541.441-.873 1.219-1.393.72-.481 1.1-1.106.931-1.956a2.23 2.23 0 0 0-1.722-1.722c-1.334-.266-2.619.788-2.619 2.15M12 16.43v-.375m0 5.319a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75"
    />
  </svg>
);
export default SvgIconHelp;
