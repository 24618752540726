/* eslint-disable no-param-reassign */
import { AppError } from '@lessonup/utils';
import { Fonts } from '../../fonts';
import { Component } from '../Component';

declare const Meteor;

export interface TableComponent extends Component {
  type: 'table';
  position: Component.Box;
  settings: TableComponent.Settings;
}

interface EnableParams {
  pinId: string;
  componentType: Component.Type;
  componentId?: string;
  payload: TableComponent.TableComponentContextPayload;
}

export namespace TableComponent {
  export const type = 'table';

  export interface TableGeneratorProps {
    settings: TableComponent.Settings;
    position: Component.Box;
    view: boolean;
    pinId?: string;
    componentId?: string;
    ContentComponent?: any; // This is a React component, but we don't want to import React here
    enableHelper?: (params: EnableParams) => void;
  }

  export interface TableCellEditProps extends TableGeneratorProps {
    row: number;
    col: number;
    cell: TableComponent.Cell | undefined;
  }

  export interface Row {
    header: boolean;
    height: number;
  }
  export interface Column {
    header: boolean;
    width: number;
    alignment: string;
  }
  export interface Cell {
    content: string;
  }

  export const minCellWidth = 4;

  export interface TableComponentContextPayload {
    col: number;
    row: number;
  }

  interface Headers {
    top: boolean;
    bottom: boolean;
    left: boolean;
    right: boolean;
  }

  interface HeaderLayout {
    fillColor: string;
    color: string;
  }

  interface ContentLayout {
    fillColor: string;
    color: string;
  }

  export type TableData = Cell[][];

  // firebase does not support multidimensional arrays, so the data in firebase is serialized
  type TableDataProperty = TableData | string;

  export interface Settings extends Component.Settings {
    tableData: TableDataProperty;
    rows: Row[];
    columns: Column[];
    headerLayout: HeaderLayout;
    contentLayout: ContentLayout;
    borders: 'normal-border' | 'no-outer-border' | 'no-border';
    borderColorOverride?: 'none' | 'black' | 'white';
    fontSize: number;
    fontFamily: Fonts.Key;
  }

  // firebase does not support multidimensional arrays, so the data in firebase is serialized
  export function tableDataToArray(tableData: TableDataProperty): TableData {
    if (typeof tableData === 'string') {
      const data = JSON.parse(tableData);
      if (!Array.isArray(data)) throw new AppError('unexpected-data', 'JSON should contain a array');
      return JSON.parse(tableData);
    }
    return tableData;
  }

  export function createTableData(rowCount, columnCount): TableData {
    const tableData: TableData = Array<Cell[]>(rowCount).fill(
      Array<Cell>(columnCount).fill({
        content: '',
      })
    );
    return tableData;
  }

  export function updateTableData(
    tableData: TableData,
    pasteData: string[][],
    cellIndex: TableComponentContextPayload
  ): { data: TableData; hasReplacedContent: boolean } {
    let hasReplacedContent = false;
    const data = tableData.map((row, currentRow) => {
      const rowInPasteData = pasteData[currentRow - cellIndex.row];
      const isCurrentRowTarget = currentRow >= cellIndex.row;

      if (isCurrentRowTarget && rowInPasteData) {
        return row.map((cell, currentCell) => {
          const pasteContent = rowInPasteData[currentCell - cellIndex.col];
          const isCurrentCellTarget = currentCell >= cellIndex.col;

          if (isCurrentCellTarget && pasteContent) {
            if (cell.content !== '' && pasteContent !== '') hasReplacedContent = true;
            return { ...cell, content: pasteContent };
          }
          return { ...cell };
        });
      }
      return [...row];
    });
    return { data, hasReplacedContent };
  }

  export function getCell(tableData: TableData, row: number, col: number): Cell | undefined {
    if (tableData[row] && tableData[row][col]) return tableData[row][col];
    return undefined;
  }

  export function createRowData(rowAmount: number): Row[] {
    return Array(rowAmount).fill({ header: false, height: 10 });
  }

  export function heightPercentToEm(height: number): string {
    return height / 10 + 'em';
  }

  const defaultAlignment = 'align-left';

  export function createColumnData(colAmount: number) {
    return Array(colAmount).fill({
      header: false,
      alignment: defaultAlignment,
      width: 100 / colAmount,
    });
  }

  export function getHeaders(settings: TableComponent.Settings) {
    const lastRow = settings.rows.length - 1;
    const lastColumn = settings.columns.length - 1;
    const headers = {
      top: !!settings.rows[0].header,
      bottom: !!settings.rows[lastRow].header && lastRow !== 0,
      left: !!settings.columns[0].header,
      right: !!settings.columns[lastColumn].header && lastColumn !== 0,
    };
    return headers;
  }

  export function setRowHeaders(headers: Headers, rows: Row[]): Row[] {
    const lastRow = rows.length - 1;

    return rows.map((row, i) => {
      if ((i === 0 && headers.top) || (i === lastRow && headers.bottom)) return { ...row, header: true };
      return { ...row, header: false };
    });
  }

  export function setColumnHeaders(headers: Headers, columns: Column[]) {
    const lastColumn = columns.length - 1;

    return columns.map((column, i) => {
      if ((i === 0 && headers.left) || (i === lastColumn && headers.right)) return { ...column, header: true };
      return { ...column, header: false };
    });
  }

  export function allContent(tableData: TableDataProperty) {
    return tableDataToArray(tableData)
      .reduce<string[]>((acc, row) => {
        return acc.concat(row.map((cell) => cell.content));
      }, [])
      .join(' ');
  }

  export const fillColors = [
    'none',
    'white',
    'grey-bg',
    'brown-dark',
    'yellow',
    'pink',
    'blue',
    'green',
    'orange',
    'cyan',
  ];

  export const cellClassName = 'ct-cell';
}
