import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHover } from 'usehooks-ts';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { BodyText } from '../BodyText/BodyText';
import { FilePreviewProps } from '../FilePreview/FilePreview';
import { Checkbox } from '../FormField/Checkbox/Checkbox';
import { IconVisibilityOff } from '../icons';

export type ThumbType = 'preview' | 'editor' | 'editorDragOverlay' | 'search' | 'caption';

export type ThumbCheckboxSelection = {
  enabled: boolean;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export interface ThumbProps extends HTMLAttributes<HTMLButtonElement> {
  thumbType: ThumbType;
  number: number;
  children: ReactElement<FilePreviewProps>;
  checkboxSelection?: ThumbCheckboxSelection;
  hidden?: boolean;
  caption?: string;
  isActive?: boolean;
  resetFocus?: boolean;
  overwriteCheckboxVisibility?: boolean;
  onClick?: () => void;
}

const typesWidthNumber: ThumbType[] = ['preview', 'editor'];

export const Thumb = forwardRef(function Thumb(
  {
    number,
    children,
    isActive,
    hidden,
    thumbType,
    caption,
    checkboxSelection,
    resetFocus,
    overwriteCheckboxVisibility = false,
    onClick,
    ...buttonProps
  }: ThumbProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const { t } = useTranslation('thumb');
  const useIndicator = thumbType === 'editor';
  const useNumber = typesWidthNumber.includes(thumbType);
  const useSmallCaption = thumbType === 'caption' && caption;
  const useBigCaption = thumbType === 'search' && caption;
  const hoverRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLButtonElement>(null);
  const prevIsActiveRef = useRef<boolean | undefined>(isActive);
  // TODO: TECH-180: fix the ref type issue
  const isHovered = useHover(hoverRef as never) && thumbType !== 'editorDragOverlay';
  const isCheckboxVisible = overwriteCheckboxVisibility || checkboxSelection?.checked || isHovered;

  /* Temporary solution to clean up any focus state when using the arrow keys to navigate the pin rail in the editor 
    This will be readdressed when introducing a custom focus state for the pin rail */
  useEffect(() => {
    if (contentWrapperRef.current && isActive && resetFocus && !prevIsActiveRef.current) {
      contentWrapperRef.current.focus({ preventScroll: true });
      contentWrapperRef.current.blur();
    }
    prevIsActiveRef.current = isActive;
  }, [isActive, resetFocus]);

  return (
    <StyledWrapper ref={forwardedRef}>
      <StyledThumbWrapper thumbType={thumbType}>
        {useIndicator && <StyledIndicator isActive={isActive} thumbType={thumbType} />}
        {useNumber && (
          <SlideNumber thumbType={thumbType}>
            <BodyText size="small" title={number.toString()}>
              {number}
            </BodyText>
          </SlideNumber>
        )}
        <StyledSelectableContentWrapper ref={hoverRef}>
          <ContentWrapper
            thumbType={thumbType}
            isHovered={isHovered}
            onClick={onClick}
            isActive={isActive}
            ref={contentWrapperRef}
            {...buttonProps}
          >
            {children}
            {hidden && (
              <HiddenOverlay>
                <IconVisibilityOff />
              </HiddenOverlay>
            )}
          </ContentWrapper>
          {checkboxSelection?.enabled && (
            <SelectionBoxContainer checkboxSelection={checkboxSelection} isVisible={isCheckboxVisible}>
              <StyledCheckboxContainer isVisible={isCheckboxVisible}>
                <Checkbox
                  key={`${number}-${checkboxSelection.checked}`}
                  label={t('checkboxSelectionLabel', { number })}
                  checked={checkboxSelection.checked}
                  id={`select-side-${number}`}
                  onChange={checkboxSelection.onChange}
                  hideLabel={true}
                />
              </StyledCheckboxContainer>
            </SelectionBoxContainer>
          )}
        </StyledSelectableContentWrapper>
      </StyledThumbWrapper>

      {useSmallCaption && (
        <StyledCaption size="small" isDisabled={true}>
          {caption}
        </StyledCaption>
      )}
      {useBigCaption && (
        <StyledCaption size="medium" isDisabled={false}>
          {caption}
        </StyledCaption>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledThumbWrapper = styled.div<Pick<ThumbProps, 'thumbType'>>`
  display: flex;
  margin-left: ${(props) => (props.thumbType === 'editorDragOverlay' ? rem('40px') : undefined)};
  flex-direction: row;
  position: relative;
`;

const StyledSelectableContentWrapper = styled.div`
  position: relative;
`;

const StyledIndicator = styled.div<Pick<ThumbProps, 'isActive' | 'thumbType'>>`
  ${(props) => css`
    width: 0px;
    align-self: stretch;
    border-radius: ${borderRadius.rounded2};
    border: ${rem('1px')} solid ${props.isActive ? color.accent.secondary.background : 'transparent'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${spacing.size4};
    margin-left: ${spacing.size2};
  `};
`;

const SlideNumber = styled.div<Pick<ThumbProps, 'thumbType'>>`
  text-align: center;
  user-select: none;
  ${(props) => {
    switch (props.thumbType) {
      case 'editor':
        return css`
          width: ${rem('32px')};
          align-self: center;
        `;
      case 'preview':
        return css`
          width: ${rem('25px')};
          color: ${color.additional.disabled.text};
        `;
    }
  }}
`;

const HiddenOverlay = styled.div`
  background: ${color.neutral.surface.background};
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  svg {
    color: ${color.neutral.surface.text};
    position: absolute;
    top: ${spacing.size16};
    right: ${spacing.size16};
    width: ${rem('32px')};
    height: ${rem('32px')};
  }
`;

const SelectionBoxContainer = styled.div<Pick<ThumbProps, 'checkboxSelection'> & { isVisible: boolean }>`
  position: absolute;
  top: ${spacing.size8};
  right: ${spacing.size8};
  background-color: ${(props) =>
    props.isVisible
      ? props.checkboxSelection?.checked
        ? color.accent.secondaryContainer.background
        : color.neutral.surface.background
      : 'none;'};
  border-radius: ${borderRadius.rounded4};
`;

const StyledCheckboxContainer = styled.div<{ isVisible: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  border-radius: ${borderRadius.rounded4};
`;

const ContentWrapper = styled.button<Pick<ThumbProps, 'thumbType' | 'isActive'> & { isHovered: boolean }>`
  ${(props) => css`
    display: block;
    padding: 0;
    background: none;
    text-align: left;
    outline: ${`${rem('1px')} solid ${
      props.isActive && props.thumbType !== 'editor'
        ? color.accent.secondary.background
        : props.isHovered
          ? color.neutral.outline.hover
          : color.neutral.outline.background
    }`};
    margin: ${rem('1px')};
    flex-grow: ${props.thumbType === 'preview' ? 1 : 0};
  `};
  ${(props) =>
    props.thumbType === 'search' &&
    css`
      font-size: 0;
    `}
`;

const StyledCaption = styled(BodyText)<{ isDisabled: boolean }>`
  margin-top: ${spacing.size4};
  color: ${(props) => (props.isDisabled ? color.additional.disabled.text : color.neutral.surface.text)};
`;
