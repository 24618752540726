import { logger, useDocumentMutation } from '@lessonup/client-integration';
import { createModal, ManagedModal, NiceModal, rem, styled, useErrorContext, useModal } from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccountDeletionRequestDocument,
  CancelAccountDeletionRequestDocument,
} from '../../../AccountDeletion.graphql.generated';
import { OptOutConfirmationModalContent } from './OptOutConfirmationModalContent';

export const OptOutConfirmationModal = createModal(() => {
  const modal = useModal();
  const { t: tWithoutPrefix } = useTranslation('accountDeletion');
  const t = (key: string) => tWithoutPrefix(`optOutConfirmationModal.${key}`);
  const { setError } = useErrorContext();
  const [cancelDeletion, { loading }] = useDocumentMutation(CancelAccountDeletionRequestDocument, {
    refetchQueries: [{ query: AccountDeletionRequestDocument }],
  });

  const onConfirm = async () => {
    try {
      await cancelDeletion();
      modal.hide();
    } catch (unverifiedError) {
      const error = asError(unverifiedError);
      logger.error(error);
      setError({ error });
    }
  };

  return (
    <ManagedModal modal={modal} contentLabel={t('contentLabel')}>
      <ContentWrapper>
        <OptOutConfirmationModalContent onConfirm={onConfirm} onClose={modal.hide} loading={loading} />
      </ContentWrapper>
    </ManagedModal>
  );
});

export const showOptOutConfirmationModal = () => NiceModal.show(OptOutConfirmationModal);

/**
 * Setting the width of the content does not seem to work without a wrapping div (maybe because of the React.Fragment in the content?)
 */
const ContentWrapper = styled.div`
  width: ${rem('480px')};
`;
