import { useApolloClient, useMediaRecorder } from '@lessonup/client-integration';
import {
  BodyText,
  createModal,
  IconMicrophone,
  ManagedModal,
  ModalFooter,
  ModalFooterAction,
  ModalFooterActionAsync,
  ModalHeaderV1,
  NiceModal,
  ProgressBar,
  rem,
  spacing,
  styled,
  useErrorContext,
  useModal,
} from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import { isNull } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStep } from 'usehooks-ts';
import { useUploadFile } from '../../hooks/useUploadFile';
import { useUploadsStatus } from '../../hooks/useUploadsStatus';
import { useUploadStatusPolling } from '../../hooks/useUploadStatusPolling';
import { UploadDetailsDocument } from '../../Uploads.graphql.generated';
import { i18nextNamespace } from '../../UploadsFeature.utils';
import { MeterWrapper } from './MeterWrapper';
import { TimerWrapper } from './TimerWrapper';

enum AudioUploadSteps {
  Start = 1,
  Recording = 2,
  Preview = 3,
  Uploading = 4,
  Uploaded = 5,
}

type UploadProperties = {
  id: string;
  url: string;
  contentType: string;
};

type StepActionMapper = {
  [key: string]: {
    primaryAction: ModalFooterAction | ModalFooterActionAsync;
    secondaryAction?: ModalFooterAction | ModalFooterActionAsync;
  };
};

export interface AudioModalContentProps {
  onClose: () => void;
  onError: () => void;
  onUpload: (upload: UploadProperties) => void;
}

export const AudioModalContent = (props: AudioModalContentProps) => {
  const { onClose } = props;
  const { setError } = useErrorContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadInfo, setUploadInfo] = useState<{ id: string; folderId?: string } | null>(null);
  const { isRecording, audioContext, lastRecording, error, startRecording, stopRecording, cancelRecording } =
    useMediaRecorder(
      {
        audio: true,
      },
      'wav',
      fileInputRef
    );
  const [currentStep, { goToNextStep, reset }] = useStep(5);
  const { t } = useTranslation(i18nextNamespace);
  const [performUpload] = useUploadFile({ onError: (error: Error) => setError({ error }) });
  const client = useApolloClient();

  useEffect(() => {
    if (!isNull(error)) {
      props.onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /**
   * add folder selection etc
   * streamline uploads, isolate code from UploadFile.tsx
   */
  const uploadAudio = () => {
    if (!fileInputRef.current || isNull(fileInputRef.current?.files)) {
      return;
    }

    Array.from(fileInputRef.current.files).forEach(async (file) => {
      try {
        const uploadId = await performUpload({
          file,
        });

        if (!uploadId) {
          throw new Error('Upload unsuccesful.');
        }
        setUploadInfo({ id: uploadId });
        goToNextStep();
      } catch (error) {
        setError({ error: asError(error) });
      }
    });
  };

  const handleAfterUpload = async () => {
    if (isNull(uploadInfo)) {
      onClose();
      return;
    }

    const uploadViewer = await client.query({
      query: UploadDetailsDocument,
      variables: { uploadId: uploadInfo.id },
    });

    // We always only expect one upload.
    const upload = uploadViewer.data.viewer?.uploadsByIds?.[0];
    if (upload?.__typename === 'AudioUpload' && upload.audio?.url) {
      props.onUpload({ id: uploadInfo.id, url: upload.audio?.url, contentType: 'audio/wav' });
    }
    onClose();
  };

  const actionsMapper: StepActionMapper = {
    [AudioUploadSteps.Start]: {
      primaryAction: {
        type: 'button',
        onClick: () => {
          startRecording();
          goToNextStep();
        },
        label: t('audioModal.actions.startRecording'),
      },
      secondaryAction: {
        type: 'button',
        label: t('audioModal.actions.cancel'),
        onClick: onClose,
      },
    },
    [AudioUploadSteps.Recording]: {
      primaryAction: {
        type: 'button',
        onClick: () => {
          stopRecording();
          goToNextStep();
        },
        label: t('audioModal.actions.stopRecording'),
      },
      secondaryAction: {
        type: 'button',
        label: t('audioModal.actions.cancelRecording'),
        onClick: () => {
          cancelRecording();
          onClose();
        },
      },
    },
    [AudioUploadSteps.Preview]: {
      primaryAction: {
        type: 'button',
        onClick: uploadAudio,
        label: t('audioModal.actions.uploadRecording'),
      },
      secondaryAction: {
        type: 'button',
        onClick: () => reset(),
        label: t('audioModal.actions.tryAgain'),
      },
    },
    [AudioUploadSteps.Uploading]: {
      primaryAction: {
        type: 'button',
        label: t('audioModal.actions.useRecording'),
        disabled: true,
      },
    },
    [AudioUploadSteps.Uploaded]: {
      primaryAction: {
        type: 'button',
        onClick: handleAfterUpload,
        label: t('audioModal.actions.useRecording'),
      },
    },
  };

  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('audioModal.title') }}
        onCloseButtonClick={onClose}
        showDivider={false}
      />
      <StyledModalBody>
        {[AudioUploadSteps.Start, AudioUploadSteps.Recording].includes(currentStep) && (
          <StyledRecordingInfo>
            <TimerWrapper isRecording={isRecording} />
            <MeterWrapper handler={audioContext} />
            <IconMicrophone />
          </StyledRecordingInfo>
        )}
        {currentStep === AudioUploadSteps.Preview && lastRecording && (
          // Key is present in order to force re-render upon new source
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls key={lastRecording.src}>
            {!isNull(lastRecording) && <source src={lastRecording.src} type={lastRecording.type}></source>}
          </audio>
        )}
        {[AudioUploadSteps.Uploading, AudioUploadSteps.Uploaded].includes(currentStep) && !isNull(uploadInfo) && (
          <UploadProgress
            uploadId={uploadInfo.id}
            onCompleted={() => goToNextStep()}
            onError={(error: Error) => setError({ error })}
          />
        )}
        <StyledBodyText>{t(`audioModal.hints.${currentStep}`)}</StyledBodyText>
        <FileInput ref={fileInputRef} type="file" />
      </StyledModalBody>
      <ModalFooter type="actions" {...(actionsMapper[currentStep] ?? {})} />
    </>
  );
};

export interface AudioModalProps {
  onUpload: (upload: UploadProperties) => void;
}

export const AudioUploadModal = createModal((props: AudioModalProps) => {
  const modal = useModal();
  const { t } = useTranslation(i18nextNamespace);
  const [hasError, setHasError] = useState<boolean>(false);
  const handleClose = () => {
    modal.hide();
  };

  return (
    <ManagedModal modal={modal} contentLabel={t('audioModal.denied.title')}>
      {hasError ? (
        <AudioErrorContent handleClose={handleClose} />
      ) : (
        <AudioModalContent onUpload={props.onUpload} onClose={handleClose} onError={() => setHasError(true)} />
      )}
    </ManagedModal>
  );
});

export const showAudioUploadModal = (onUpload: (upload: UploadProperties) => void) =>
  NiceModal.show(AudioUploadModal, { onUpload });

interface AudioErrorContentProps {
  handleClose: () => void;
}

const AudioErrorContent: React.FC<AudioErrorContentProps> = ({ handleClose }) => {
  const { t } = useTranslation(i18nextNamespace);

  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: t('audioModal.denied.title') }}
        onCloseButtonClick={handleClose}
        showDivider={false}
      />
      <StyledModalBody>
        <BodyText>{t('audioModal.denied.description')}</BodyText>
      </StyledModalBody>
      <ModalFooter
        type="actions"
        primaryAction={{
          type: 'button',
          label: t('audioModal.denied.close'),
          onClick: handleClose,
        }}
      />
    </>
  );
};

const UploadProgress = ({
  uploadId,
  onCompleted,
  onError,
}: {
  uploadId: string;
  onCompleted: () => void;
  onError: (error: Error) => void;
}) => {
  const { startPollingForIds } = useUploadStatusPolling({ onError });
  const { data } = useUploadsStatus({
    ids: [uploadId],
    onAllCompleted: onCompleted,
    onQueryDocumentError: onError,
  });

  useEffect(() => {
    startPollingForIds([uploadId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ProgressBar value={data?.viewer.uploadsByIds[0].progressPercentage || 0} />;
};

const StyledModalBody = styled.section`
  padding: ${spacing.size12};
  width: 70vw;
  max-width: ${rem('500px')};
  height: ${rem('100px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledRecordingInfo = styled.section`
  display: flex;
  align-items: center;
  gap: ${spacing.size12};
  padding: ${spacing.size12};
  width: 100%;
`;

const FileInput = styled.input`
  display: none;
`;

const StyledBodyText = styled(BodyText)`
  text-align: center;
`;
