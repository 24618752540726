import {
  BodyText,
  FilePreview,
  ModalFooter,
  ModalFooterAction,
  ModalHeaderV1,
  ProgressBar,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { transformImageUrl } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nextNamespace } from '../../UploadsFeature.utils';
import { FilePreviewDataForUpload } from './UploadFromFileModal.utils';

export interface UploadFromFileModalInnerProps {
  file: File;
  upload?: FilePreviewDataForUpload;
  primaryAction: ModalFooterAction;
  secondaryAction?: ModalFooterAction;
  step: number;
  translationPrefix: string;
  goToNextStep: () => void;
  onClose: () => void;
}

export const UploadFromFileModalInner: React.FC<UploadFromFileModalInnerProps> = (props) => {
  const { t } = useTranslation(i18nextNamespace);

  return (
    <>
      <ModalHeaderV1
        type="headline"
        title={{ content: t(`${props.translationPrefix}.title`) }}
        onCloseButtonClick={props.onClose}
        showDivider={false}
      ></ModalHeaderV1>
      {getChildForStep(props)}
    </>
  );
};

function getChildForStep(props: UploadFromFileModalInnerProps): React.ReactNode {
  return {
    1: (
      <PreviewFileInput
        file={props.file}
        onClose={props.onClose}
        goToNextStep={props.goToNextStep}
        translationPrefix={props.translationPrefix}
      />
    ),
    2: (
      <Uploading
        upload={props.upload}
        primaryAction={props.primaryAction}
        secondaryAction={props.secondaryAction}
        translationPrefix={props.translationPrefix}
      />
    ),
  }[props.step];
}

const PreviewFileInput: React.FC<
  Pick<UploadFromFileModalInnerProps, 'file' | 'goToNextStep' | 'onClose' | 'translationPrefix'>
> = (props) => {
  const { t } = useTranslation(i18nextNamespace);

  return (
    <>
      <StyledSpaceBetween direction="y" spacing={spacing.size16}>
        <BodyText>{t(`${props.translationPrefix}.previewDescription`)}</BodyText>
        <PreviewWrapper>
          <FilePreview
            type="image"
            src={URL.createObjectURL(props.file)}
            alt={t(`${props.translationPrefix}.altUploadThumbnail`)}
          />
        </PreviewWrapper>
      </StyledSpaceBetween>
      <ModalFooter
        type="actions"
        primaryAction={{ label: t(`${props.translationPrefix}.uploadCTA`), onClick: props.goToNextStep }}
        secondaryAction={{ label: t(`${props.translationPrefix}.close`), onClick: props.onClose }}
      ></ModalFooter>
    </>
  );
};

const Uploading: React.FC<
  Pick<UploadFromFileModalInnerProps, 'upload' | 'primaryAction' | 'secondaryAction' | 'translationPrefix'>
> = ({ upload, primaryAction, secondaryAction, translationPrefix }) => {
  const { t } = useTranslation(i18nextNamespace);

  if (!upload) {
    return null;
  }

  const buttonIsDisabled = upload.status !== 'READY';

  return (
    <>
      <StyledSpaceBetween direction="y" spacing={spacing.size16} justifyContent="center" alignItems="center">
        {upload.status !== 'READY' ? (
          <ProgressBarWrapper>
            <ProgressBar
              value={upload.progressPercentage}
              valueIndicatorType="percentage"
              description={t(`${translationPrefix}.progressDescription`)}
            />
          </ProgressBarWrapper>
        ) : (
          <>
            <BodyText>{t(`${translationPrefix}.uploadedDescription`)}</BodyText>
            <PreviewWrapper>
              <FilePreview
                type="image"
                src={upload.url && transformImageUrl(upload.url, 500)}
                alt={t(`${translationPrefix}.altUploadThumbnail`)}
              />
            </PreviewWrapper>
          </>
        )}
      </StyledSpaceBetween>
      <ModalFooter
        type="actions"
        primaryAction={{ ...primaryAction, disabled: buttonIsDisabled }}
        secondaryAction={secondaryAction ? { ...secondaryAction, disabled: buttonIsDisabled } : undefined}
      ></ModalFooter>
    </>
  );
};

const PreviewWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  padding-left: ${spacing.size24};
  padding-right: ${spacing.size24};
`;

const ProgressBarWrapper = styled.div`
  aspect-ratio: 16/9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
