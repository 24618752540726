import { Box, Line, PinComponentLayout } from '@lessonup/pins-shared';

export const GrippyPosition = {
  values: ['top-left', 'top', 'top-right', 'right', 'bottom-right', 'bottom', 'bottom-left', 'left'] as const,
};

export type GrippyPosition = (typeof GrippyPosition.values)[number];

export type lineEndPoint = 'start' | 'end';

// Type helpers
export const isLineLayout = (layout: PinComponentLayout): layout is Line => layout.type === 'LINE';
export const isBoxLayout = (layout: PinComponentLayout): layout is Box => layout.type === 'BOX';
