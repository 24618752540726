import { openChatWindow } from '@lessonup/intercom';
import {
  AnchorLink,
  BodyText,
  Button,
  Headline,
  IconRenew,
  IconRocket,
  Link,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { Trans } from 'react-i18next';
import { helpCenter } from '../../../../utils/bridge/siteRoutes';
import { useEditorTranslation } from '../../hooks/useEditorTranslation';

export type ErrorStatusType = 'default' | '403' | '404';

export interface ErrorStatusProps {
  errorType?: ErrorStatusType;
}

export const ErrorStatus: React.FC<ErrorStatusProps> = ({ errorType = 'default' }) => {
  const { t } = useEditorTranslation();

  return (
    <SpaceBetween direction="x" justifyContent="center" spacing={spacing.size16}>
      <StyledErrorStatus direction="y" spacing={spacing.size16} alignItems="flex-start">
        <Headline size="small">{t('statusPage.title')}</Headline>
        <BodyText size="medium">{t(`statusPage.${errorType}.description`)}</BodyText>
        <BodyText size="medium">{t(`statusPage.${errorType}.message`)}</BodyText>
        {errorType === 'default' ? (
          <Button
            buttonType="neutral"
            showStroke
            buttonShape="rect"
            iconEnd={<IconRenew />}
            onClick={() => location.reload()}
          >
            {t(`statusPage.${errorType}.button`)}
          </Button>
        ) : (
          <Link href="/app/explorer/lessons" passHref>
            <Button buttonType="neutral" showStroke buttonShape="rect" iconEnd={<IconRocket />}>
              {t(`statusPage.${errorType}.button`)}
            </Button>
          </Link>
        )}
        <BodyText size="medium">
          <Trans
            t={t}
            i18nKey="statusPage.helpMessage"
            components={[<LinkToHelpPage key="help" />, <LinkToCustomerSupport key="intercom" />]}
          />
        </BodyText>
        <BodyText>
          <Trans t={t} i18nKey="statusPage.backTo" components={[<LinkToHomePage key="back" />]} />
        </BodyText>
      </StyledErrorStatus>
    </SpaceBetween>
  );
};

const StyledErrorStatus = styled(SpaceBetween)`
  margin-top: ${spacing.size16};
  margin-bottom: ${spacing.size16};
  max-width: 384px;
`;

const LinkToCustomerSupport = () => {
  const { t } = useEditorTranslation();
  return (
    <Link passHref href="_blank" onClick={openChatWindow}>
      <a>{t('statusPage.customerSupport')} </a>
    </Link>
  );
};

const LinkToHelpPage = () => {
  return (
    <AnchorLink href={helpCenter} target="_blank">
      {'help.lessonup.com'}
    </AnchorLink>
  );
};

const LinkToHomePage = () => {
  return (
    <Link href="/" passHref>
      <a>{'lessonup.com'}</a>
    </Link>
  );
};
