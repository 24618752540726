import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.158 17.259h-8.323a3 3 0 0 1-2.961-2.52L6.44 5.904a3 3 0 0 0-2.961-2.52h-.822m16.5 13.875a1.679 1.679 0 1 1 0 3.357 1.679 1.679 0 0 1 0-3.357m2.178-10.502a16.5 16.5 0 0 1-1.15 4.687 2.81 2.81 0 0 1-2.074 1.688c-.96.188-2.417.377-4.392.377-1.39 0-2.566-.094-3.507-.215-1.657-.212-2.825-1.567-3.091-3.216l-.779-4.82h13.565c.828 0 1.506.674 1.428 1.5M9.188 20.617a1.679 1.679 0 1 0 0-3.358 1.679 1.679 0 0 0 0 3.357"
    />
  </svg>
);
export default SvgIconCart;
