import { PinComponent } from '@lessonup/pins-shared';
import { createContext, useContext } from 'react';

export interface PinEditorContextMenuContext {
  handleOpenContextMenu: (
    event: React.MouseEvent,
    type: 'pin' | 'pin-component',
    selectedPinComponentsIds: string[],
    pinComponents?: PinComponent[]
  ) => void;
  hideAllContextMenus: () => void;
  children?: React.ReactNode;
}

export const pinEditorContextMenuContext: React.Context<PinEditorContextMenuContext> =
  createContext<PinEditorContextMenuContext>({
    handleOpenContextMenu: () => {
      console.warn('PinEditorContextMenuContext not implemented');
    },
    hideAllContextMenus: () => {
      console.warn('PinEditorContextMenuContext not implemented');
    },
  });

export const usePinEditorContextMenu = () => {
  const cntx = useContext(pinEditorContextMenuContext);
  if (!cntx) {
    throw new Error('PinEditorContextMenuContext not implemented');
  }
  return cntx;
};
