import React, { ReactElement, RefObject, useEffect, useRef } from 'react';

import { useElementKeyboardEvent } from '@lessonup/client-integration';
import { borderRadius, color, css, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';

import { SettingsToggle } from '../../features/Editor/components/SettingsToggle/SettingsToggle';
import { ContextSidebarHeader } from './ContextSidebarHeader';

export interface ContextSidebarPane {
  id: string;
  button: {
    icon: ReactElement;
    label: string;
  };
  title: string;
  children: React.ReactNode;
}
export interface ContextSidebarProps {
  closeLabel: string;
  margin?: string;
  panes: ContextSidebarPane[];
  selectedPane: ContextSidebarPane['id'] | 'none';
  onOpenPane: (paneId: ContextSidebarPane['id']) => void;
  onClosePane: (paneId: ContextSidebarPane['id']) => void;
}

export const ContextSidebar: React.FC<ContextSidebarProps> = ({
  onOpenPane,
  onClosePane,
  closeLabel,
  margin,
  panes,
  selectedPane,
}) => {
  const contextSidebarContainerRef = useRef<HTMLDivElement>(null);
  const toggleElementRef = useRef<HTMLInputElement | HTMLButtonElement>(null);
  const toggleFocusFlag = useRef<boolean>(false);
  useElementKeyboardEvent(
    'keydown',
    {
      Delete: (e) => e.stopPropagation(),
      Backspace: (e) => e.stopPropagation(),
    },
    // TODO: TECH-180: fix the ref type issue
    contextSidebarContainerRef as never
  );

  const onOpenClick = (id: ContextSidebarPane['id']) => {
    toggleFocusFlag.current = true;
    onOpenPane(id);
  };

  useEffect(() => {
    if (toggleFocusFlag.current) {
      toggleElementRef.current?.focus();
      toggleFocusFlag.current = false;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleElementRef.current, toggleFocusFlag.current]);

  const currentPane = panes.find((pane) => pane.id === selectedPane);

  return (
    <StyledContextSidebarContainer margin={margin} ref={contextSidebarContainerRef}>
      <SpaceBetween direction="x" spacing={spacing.size8}>
        {currentPane && (
          <StyledContextSidebar>
            <ContextSidebarHeader
              title={currentPane.title}
              onClosePane={() => onClosePane(currentPane.id)}
              closeLabel={closeLabel}
              buttonRef={toggleElementRef as RefObject<HTMLButtonElement>}
            />
            {currentPane.children}
          </StyledContextSidebar>
        )}
        {(panes.length > 1 || !currentPane) && (
          <StyledIconContainer direction="y" spacing={spacing.size8}>
            {panes.map(({ id, button }, index) => (
              <SettingsToggle
                key={index}
                ref={index === 0 ? (toggleElementRef as RefObject<HTMLInputElement>) : null}
                onChange={() => onOpenClick(id)}
                checked={selectedPane === id}
                aria-label={button.label}
                icon={button.icon}
                type="radio"
                toggleVariant="icon-only"
              />
            ))}
          </StyledIconContainer>
        )}
      </SpaceBetween>
    </StyledContextSidebarContainer>
  );
};

const StyledContextSidebar = styled.section`
  border: 1px solid ${color.neutral.outline.background};
  border-radius: ${borderRadius.rounded16};
  width: ${rem('320px')};
  overflow-y: auto;

  & > * ~ * {
    border-top: 1px solid ${color.neutral.outline.background};
  }
`;

const StyledIconContainer = styled(SpaceBetween)`
  padding: ${spacing.size8};
  display: block;
`;

const StyledContextSidebarContainer = styled.section<Pick<ContextSidebarProps, 'margin'>>`
  display: flex;

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;
