export const colorpickeri18NNS = 'colorPicker';

export const colorHexCodes = [
  '#000000',
  '#3B3B3B',
  '#5B5B5B',
  '#8E8E8E',
  '#AEAEAE',
  '#C5C5C5',
  '#D4D4D4',
  '#EDEDED',
  '#F1F1F1',
  '#FFFFFF',
  '#8D000B',
  '#FE0013',
  '#FF8D24',
  '#FFFD3A',
  '#2BFD37',
  '#15FFFE',
  '#3B7DE0',
  '#001BF8',
  '#881BF8',
  '#FB1AF9',
  '#E2AFA7',
  '#F2C5C5',
  '#FCE1C8',
  '#FFEFC8',
  '#D4E7CE',
  '#CADCDF',
  '#C1D5F5',
  '#C8DEF0',
  '#D3CCE4',
  '#E6CBD7',
  '#D87262',
  '#E68E8F',
  '#F9C395',
  '#FFE094',
  '#AFD1A1',
  '#98BCC1',
  '#98BBEF',
  '#94BEE2',
  '#AA9ECE',
  '#CE9CB4',
  '#C53826',
  '#DB5B5C',
  '#F5A866',
  '#FFD266',
  '#8ABC77',
  '#6B9BA5',
  '#5F95E4',
  '#629FD4',
  '#8172B8',
  '#B97094',
  '#9B1A0D',
  '#C4000F',
  '#E3853B',
  '#F0B93E',
  '#629D4C',
  '#3D7682',
  '#2E6FCE',
  '#327BBB',
  '#59469A',
  '#9B446C',
  '#791D12',
  '#8E000B',
  '#AB5419',
  '#B88420',
  '#346A23',
  '#134651',
  '#004EC0',
  '#004A86',
  '#2C1D67',
  '#681A3D',
  '#501007',
  '#5B0007',
  '#6D3711',
  '#745514',
  '#254418',
  '#0F2E35',
  '#153E79',
  '#053156',
  '#1C1442',
  '#42132A',
] as const;

export type Colors = (typeof colorHexCodes)[number];

/**
 * Only does simple string compare, no conversion from hex to rgba or similar
 * Does support expanding 3 digit hex to 6 */
export function isSameColorString(color1: string | undefined, color2: string | undefined) {
  if (!color1 || !color2) {
    return false;
  }
  return expand3DigitHex(color1).toLowerCase() === expand3DigitHex(color2).toLowerCase();
}

export function expand3DigitHex(hex: string) {
  if (hex.length !== 4 || hex[0] !== '#') {
    return hex;
  }
  return hex
    .split('')
    .map((char) => (char == '#' ? char : char + char))
    .join('');
}
