import { Box, newVector, Size, Vector } from '@lessonup/pins-shared';
import { pinSize } from '../../foundations/layout/pinDefault';
import { GrippyPosition } from '../../types';
import {
  ArrangedRailLayouts,
  ArrangedSnapAnchors,
  RailLayout,
  SnapAnchor,
  SnapFitting,
} from '../../types/helpLines/helpLines.types';
import {
  clampRange,
  horizontalExtremesFromVectors,
  isNotZero,
  rotateVector,
  subtractVectors,
  verticalExtremesFromVectors,
} from '../geometry';
import { bleedingRails } from './createRails.utils';
import { isHorizontalRailLayout, isVerticalRailLayout, mergeDisplayRanges } from './rails.utils';

const DEFAULT_SNAP_TRESHOLD = (5 / 960) * pinSize.width;

export const snapDisplacement = (
  snapAnchors: ArrangedSnapAnchors,
  railLayouts: ArrangedRailLayouts,
  snapTreshold = DEFAULT_SNAP_TRESHOLD
): Vector => {
  return newVector(
    calculateSnapDistance('x', snapAnchors.horizontal, railLayouts.vertical, snapTreshold),
    calculateSnapDistance('y', snapAnchors.vertical, railLayouts.horizontal, snapTreshold)
  );
};

export const calculateSnapDistance = (
  axis: 'x' | 'y',
  snapAnchors: SnapAnchor[],
  rails: RailLayout[],
  snapTreshold: number
) => {
  let closestDistance = Infinity;
  snapAnchors.forEach((snapAnchor) => {
    rails.forEach((rail) => {
      if (!anchorCanFitToRail(rail, snapAnchor)) return;
      const distance = anchorDistanceToRail(rail, snapAnchor, axis);
      if (Math.abs(distance) < Math.abs(closestDistance)) closestDistance = distance;
    });
  });
  if (Math.abs(closestDistance) <= snapTreshold) return closestDistance;
  return 0;
};

export const anchorDistanceToRail = (rail: RailLayout, snapAnchor: SnapAnchor, axis: 'x' | 'y') => {
  return axis === 'x' ? rail.positionOnAxis - snapAnchor.position.x : rail.positionOnAxis - snapAnchor.position.y;
};

export const anchorCanFitToRail = (rail: RailLayout, snapAnchor: SnapAnchor) => {
  return rail.snapFitting === 'all' || rail.snapFitting === snapAnchor.snapsTo;
};

export const createVisibleRails = (
  snapAnchors: ArrangedSnapAnchors,
  railLayouts: ArrangedRailLayouts,
  roundingTreshold = 0.001
) => {
  const anchors = snapAnchors.horizontal.concat(snapAnchors.vertical);
  const horizontalRails = calculateVisibleRails(anchors, railLayouts.horizontal, roundingTreshold);
  const verticalRails = calculateVisibleRails(anchors, railLayouts.vertical, roundingTreshold);
  return addBleedingRails(horizontalRails.concat(verticalRails));
};

const calculateVisibleRails = (anchors: SnapAnchor[], rails: RailLayout[], roundingTreshold: number) => {
  const snappedRails = findSnappedRails(anchors, rails, roundingTreshold);
  const visibleRailsFitting = determineVisibleRailsFitting(snappedRails);
  const visibleRails = filterAndMapVisibleRails(snappedRails, visibleRailsFitting);
  return modifyDisplayRange(visibleRails, anchors, pinSize);
};

export const findSnappedRails = (
  anchors: SnapAnchor[],
  rails: RailLayout[],
  roundingThreshold: number
): RailLayout[] => {
  const snappedRails = new Map<string, RailLayout>();

  anchors.forEach((anchor) => {
    rails.forEach((rail) => {
      if (!anchorCanFitToRail(rail, anchor)) return;
      const key = `${rail.positionOnAxis}-${rail.orientation}-${rail.snapFitting}-${rail.isBleedingRail}`;
      if (snappedRails.has(key)) return;

      const distance = isHorizontalRailLayout(rail)
        ? anchorDistanceToRail(rail, anchor, 'y')
        : anchorDistanceToRail(rail, anchor, 'x');

      if (Math.abs(distance) < roundingThreshold) {
        snappedRails.set(key, { ...rail });
      }
    });
  });

  return Array.from(snappedRails.values());
};

export const determineVisibleRailsFitting = (snappedRails: RailLayout[]): SnapFitting => {
  let visibleRailsFitting: SnapFitting = 'center';

  snappedRails.forEach((rail) => {
    if (visibleRailsFitting !== 'all' && rail.snapFitting === 'outer') visibleRailsFitting = 'outer';
    if (rail.snapFitting === 'all') visibleRailsFitting = 'all';
  });

  return visibleRailsFitting;
};

export const filterAndMapVisibleRails = (
  snappedRails: RailLayout[],
  visibleRailsFitting: SnapFitting
): RailLayout[] => {
  return snappedRails.filter((rail) => rail.snapFitting === visibleRailsFitting).map((rail) => ({ ...rail }));
};

export const addBleedingRails = (visibleRails: RailLayout[]): RailLayout[] => {
  if (visibleRails.some((rail) => rail.isBleedingRail)) {
    return visibleRails.filter((rail) => !rail.isBleedingRail).concat(bleedingRails);
  }

  return visibleRails;
};

export const modifyDisplayRange = (visibleRails: RailLayout[], anchors: SnapAnchor[], pinSize: Size): RailLayout[] => {
  const anchorPositions = anchors.map((anchor) => anchor.position);
  const pinComponentLayoutExtremes = {
    x: horizontalExtremesFromVectors(anchorPositions),
    y: verticalExtremesFromVectors(anchorPositions),
  };

  return visibleRails.map((rail) => {
    const pinComponentLayoutRange = isVerticalRailLayout(rail)
      ? pinComponentLayoutExtremes.y
      : pinComponentLayoutExtremes.x;
    const displayRange = mergeDisplayRanges(rail.displayRange, pinComponentLayoutRange);
    const pinSizeRange = { min: 0, max: rail.orientation === 'horizontal' ? pinSize.width : pinSize.height };

    return { ...rail, displayRange: clampRange(displayRange, pinSizeRange) };
  });
};

export const keepAspectRatioForSnapDisplacement = (
  displacement: Vector,
  grippy: GrippyPosition,
  layout: Box
): Vector => {
  const ratio = layout.size.width / layout.size.height;
  const isTopLeftOrBottomRight = grippy === 'top-left' || grippy === 'bottom-right';
  const sign = isTopLeftOrBottomRight ? 1 : -1;

  const x = isNotZero(displacement.y) ? sign * displacement.y * ratio : displacement.x;
  const y = isNotZero(displacement.x) ? (sign * displacement.x) / ratio : displacement.y;

  const fixedDisplacement = newVector(x, y);
  const rotatedDisplacement = rotateVector(fixedDisplacement, layout.rotation);

  // We need to scale the coordinate that we're not snapping to, so that the aspect ratio is kept.
  return applyScaleToDisplacement(displacement, rotatedDisplacement, fixedDisplacement);
};

const applyScaleToDisplacement = (displacement: Vector, rotatedDisplacement: Vector, fixedDisplacement: Vector) => {
  let { x, y } = fixedDisplacement;

  if (isNotZero(displacement.x) && isNotZero(rotatedDisplacement.x)) {
    const scale = displacement.x / rotatedDisplacement.x;
    y = scale * rotatedDisplacement.y;
  }

  if (isNotZero(displacement.y) && isNotZero(rotatedDisplacement.y)) {
    const scale = displacement.y / rotatedDisplacement.y;
    x = scale * rotatedDisplacement.x;
  }

  return newVector(x, y);
};

export const scaleDisplacementFromSideGrippy = (
  displacement: Vector,
  vertices: Vector[],
  sideGrippy: Omit<GrippyPosition, 'top' | 'bottom' | 'left' | 'right'>
) => {
  const extension =
    sideGrippy === 'left' || sideGrippy === 'right'
      ? subtractVectors(vertices[1], vertices[0])
      : subtractVectors(vertices[2], vertices[1]);

  const incrementRatio =
    isNotZero(displacement.x) && isNotZero(extension.x)
      ? displacement.x / extension.x
      : isNotZero(extension.y)
      ? displacement.y / extension.y
      : 0;

  return isNotZero(displacement.x)
    ? newVector(displacement.x, extension.y * incrementRatio)
    : newVector(extension.x * incrementRatio, displacement.y);
};
