import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateMultipleChoiceQuestionsMutationVariables = Types.Exact<{
  input: Types.GenerateMultipleChoiceQuestionsInput;
}>;


export type GenerateMultipleChoiceQuestionsMutation = { __typename?: 'Mutation', generateMultipleChoiceQuestions: { __typename?: 'GenerateMultipleChoiceQuestionsPayload', questions: Array<{ __typename?: 'GeneratedMultipleChoiceQuestion', answers: Array<string>, correctAnswers: Array<number>, question: string }> } };

export type GeneratePollQuestionMutationVariables = Types.Exact<{
  input: Types.GeneratePollInput;
}>;


export type GeneratePollQuestionMutation = { __typename?: 'Mutation', generatePoll: { __typename?: 'GeneratePollPayload', polls: Array<{ __typename?: 'GeneratedPoll', statement: string, answers: Array<string> }> } };

export type CreateUploadForOcrMutationVariables = Types.Exact<{
  input: Types.CreateUploadInput;
}>;


export type CreateUploadForOcrMutation = { __typename?: 'Mutation', createUploadForOCR: { __typename?: 'CreateUploadPayload', uploadUrl: string, upload: { __typename?: 'AudioUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'DocumentUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'ImageUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'SlideDeckUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'VideoUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } } };

export type AnalyzeUploadsForOcrMutationVariables = Types.Exact<{
  input: Types.AnalyzeUploadsForOcrInput;
}>;


export type AnalyzeUploadsForOcrMutation = { __typename?: 'Mutation', analyzeUploadsForOCR: { __typename?: 'AnalyzeUploadsForOCRPayload', texts: Array<string | null> } };

export type GenerateLessonOutlineSubscriptionSubscriptionVariables = Types.Exact<{
  input: Types.GenerateLessonOutlineSubscriptionInput;
}>;


export type GenerateLessonOutlineSubscriptionSubscription = { __typename?: 'Subscription', generateLessonOutline: { __typename?: 'GenerateLessonOutlineSubscriptionPayload', lessonOutline: { __typename?: 'LessonOutlinePartial', header?: string | null, learningGoals?: Array<string> | null, mainTopics?: Array<string> | null, definitionList?: Array<string> | null, condensedText?: string | null } } };

export type GenerateLessonSubscriptionSubscriptionVariables = Types.Exact<{
  input: Types.GenerateLessonSubscriptionInput;
}>;


export type GenerateLessonSubscriptionSubscription = { __typename?: 'Subscription', generateLesson: { __typename?: 'GenerateLessonSubscriptionPayload', lesson: { __typename?: 'GeneratedLessonDocumentPartial', name?: string | null, introQuestion?: string | null, slides?: Array<{ __typename?: 'GeneratedOpenQuestion', question: string } | { __typename?: 'GeneratedSlide', title: string, body: string }> | null } } };

export type GenerateContextualLessonSubscriptionSubscriptionVariables = Types.Exact<{
  input: Types.GenerateContextualLessonSubscriptionInput;
}>;


export type GenerateContextualLessonSubscriptionSubscription = { __typename?: 'Subscription', generateContextualLesson: { __typename?: 'GenerateContextualLessonSubscriptionPayload', lesson: { __typename?: 'GeneratedLessonDocumentPartial', name?: string | null, introQuestion?: string | null, slides?: Array<{ __typename?: 'GeneratedOpenQuestion', question: string } | { __typename?: 'GeneratedSlide', title: string, body: string, notes?: string | null }> | null } } };

export type GetMaiaExamplesQueryVariables = Types.Exact<{
  input: Types.MaiaExampleType;
  language: Types.SupportedLanguage;
}>;


export type GetMaiaExamplesQuery = { __typename?: 'Query', maiaExamples: Array<{ __typename?: 'MaiaImageExample', image: string, title: string, text: string } | { __typename?: 'MaiaTextExample', image: string, title: string, text: string }> };


export const GenerateMultipleChoiceQuestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateMultipleChoiceQuestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateMultipleChoiceQuestionsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateMultipleChoiceQuestions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"answers"}},{"kind":"Field","name":{"kind":"Name","value":"correctAnswers"}},{"kind":"Field","name":{"kind":"Name","value":"question"}}]}}]}}]}}]} as unknown as DocumentNode<GenerateMultipleChoiceQuestionsMutation, GenerateMultipleChoiceQuestionsMutationVariables>;
export const GeneratePollQuestionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GeneratePollQuestion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratePollInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generatePoll"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"polls"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"statement"}},{"kind":"Field","name":{"kind":"Name","value":"answers"}}]}}]}}]}}]} as unknown as DocumentNode<GeneratePollQuestionMutation, GeneratePollQuestionMutationVariables>;
export const CreateUploadForOcrDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUploadForOCR"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUploadInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUploadForOCR"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upload"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"originalFile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileExtension"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"uploadUrl"}}]}}]}}]} as unknown as DocumentNode<CreateUploadForOcrMutation, CreateUploadForOcrMutationVariables>;
export const AnalyzeUploadsForOcrDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AnalyzeUploadsForOCR"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AnalyzeUploadsForOCRInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"analyzeUploadsForOCR"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"texts"}}]}}]}}]} as unknown as DocumentNode<AnalyzeUploadsForOcrMutation, AnalyzeUploadsForOcrMutationVariables>;
export const GenerateLessonOutlineSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"GenerateLessonOutlineSubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateLessonOutlineSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateLessonOutline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lessonOutline"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"header"}},{"kind":"Field","name":{"kind":"Name","value":"learningGoals"}},{"kind":"Field","name":{"kind":"Name","value":"mainTopics"}},{"kind":"Field","name":{"kind":"Name","value":"definitionList"}},{"kind":"Field","name":{"kind":"Name","value":"condensedText"}}]}}]}}]}}]} as unknown as DocumentNode<GenerateLessonOutlineSubscriptionSubscription, GenerateLessonOutlineSubscriptionSubscriptionVariables>;
export const GenerateLessonSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"GenerateLessonSubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateLessonSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateLesson"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lesson"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"introQuestion"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratedSlide"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"body"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratedOpenQuestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"question"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GenerateLessonSubscriptionSubscription, GenerateLessonSubscriptionSubscriptionVariables>;
export const GenerateContextualLessonSubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"GenerateContextualLessonSubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateContextualLessonSubscriptionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateContextualLesson"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lesson"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"introQuestion"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratedSlide"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"body"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GeneratedOpenQuestion"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"question"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GenerateContextualLessonSubscriptionSubscription, GenerateContextualLessonSubscriptionSubscriptionVariables>;
export const GetMaiaExamplesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getMaiaExamples"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MaiaExampleType"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"language"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SupportedLanguage"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maiaExamples"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"language"},"value":{"kind":"Variable","name":{"kind":"Name","value":"language"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"image"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]}}]} as unknown as DocumentNode<GetMaiaExamplesQuery, GetMaiaExamplesQueryVariables>;