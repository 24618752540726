import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../BodyText/BodyText';
import { Button } from '../Button/Button';
import { IconArrowLeft, IconArrowRight } from '../icons';

export interface PaginationProps {
  handlePrevious: () => void;
  handleNext: () => void;
  currentRange: {
    minimum: number;
    maximum: number;
  };
  totalItems: number;
  disablePagination?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  handlePrevious,
  handleNext,
  currentRange,
  totalItems,
  disablePagination,
}) => {
  const { t } = useTranslation('uiPagination');

  return (
    <StyledContainerDiv>
      <SpaceBetween alignItems="center" direction="x" spacing={spacing.size8}>
        <Button
          disabled={currentRange.minimum === 1 || disablePagination}
          onClick={handlePrevious}
          buttonType="neutral"
          aria-label={t('previous')}
          iconStart={<IconArrowLeft />}
        />
        <BodyText>
          {t('rangeStatus', { start: currentRange.minimum, end: currentRange.maximum, total: totalItems })}
        </BodyText>
        <Button
          disabled={currentRange.maximum === totalItems || disablePagination}
          onClick={handleNext}
          buttonType="neutral"
          aria-label={t('next')}
          iconStart={<IconArrowRight />}
        />
      </SpaceBetween>
    </StyledContainerDiv>
  );
};

const StyledContainerDiv = styled('div')`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
