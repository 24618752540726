import {
  ButtonAnchor,
  color,
  IconCheck,
  IconClose,
  List,
  LoaderSpinner,
  ModalStickyBottom,
  NiceModal,
  spacing,
  styled,
  Tooltip,
  useModal,
} from '@lessonup/ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lessonEditorV2Route } from '../../../utils/bridge/teacherRoutes';
import { isErrorConversion, isReadyConversion } from './types';
import { useConversionStatus } from './useConversionStatus';

export const ConversionStatusModal = NiceModal.create(() => {
  const { conversions, completedConversions, removeCompletedConversionStatus, removeCompletedConversionStatuses } =
    useConversionStatus();
  const { t } = useTranslation('conversionStatus');
  const modal = useModal();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(false);
  }, [completedConversions]);

  const combined = [...conversions, ...completedConversions];

  if (!combined.length) return <></>;

  return (
    <ModalStickyBottom
      modal={modal}
      label={t('modalStickyBottomLabel')}
      onClose={() => {
        removeCompletedConversionStatuses();
        modal.hide();
      }}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
    >
      <ListWrapper>
        <List
          items={combined.map((conversion) => {
            const { uploadId, name } = conversion;
            return {
              key: uploadId,
              startSlot: isReadyConversion(conversion) ? (
                <Tooltip content={t('tooltip.ready')} direction="right" display="flex">
                  <IconCheck />
                </Tooltip>
              ) : isErrorConversion(conversion) ? (
                <Tooltip content={t('tooltip.error')} direction="right" display="flex">
                  <IconClose />
                </Tooltip>
              ) : (
                <Tooltip content={t('tooltip.loading')} direction="right" display="flex">
                  <LoaderSpinner />
                </Tooltip>
              ),
              text: name,
              type: isErrorConversion(conversion) ? 'destructive' : undefined,
              secondLine: isErrorConversion(conversion) ? t('conversionFailed') : undefined,
              endSlot: isReadyConversion(conversion) ? (
                <ButtonAnchor
                  buttonType="neutral"
                  showStroke={true}
                  href={lessonEditorV2Route.href({ lessonId: conversion.lessonId })}
                  onClick={() => removeCompletedConversionStatus(conversion)}
                >
                  {t('openLesson')}
                </ButtonAnchor>
              ) : undefined,
            };
          })}
          limitToParentWidth={true}
        />
      </ListWrapper>
    </ModalStickyBottom>
  );
});

export const showConversionStatusModal = () => {
  return NiceModal.show(ConversionStatusModal);
};

export const hideConversionStatusModal = () => {
  return NiceModal.hide(ConversionStatusModal);
};

const ListWrapper = styled.div`
  padding: ${spacing.size8} ${spacing.size16};
  background: ${color.neutral.surface.background};
`;
