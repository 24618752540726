import { SymbolDefinition } from '../SymbolComponent';

const NAME = 'star';
export interface StarSymbol extends SymbolDefinition {
  name: typeof NAME;
}

export const starSymbol: StarSymbol = {
  name: NAME,
  lockedAspectRatio: false,
  startWithFixedAspectRatio: true,
  hasStrokeSettings: true,
};
