
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseAgreement": [
      "Agreement",
      "OrganizationAgreement"
    ],
    "BaseGift": [
      "TrialGift"
    ],
    "ChildUploadFolderOrUpload": [
      "AudioUpload",
      "ChildUploadFolder",
      "DocumentUpload",
      "ImageUpload",
      "SlideDeckUpload",
      "VideoUpload"
    ],
    "CreateHomeworkResult": [
      "CreateDuplicateResult",
      "CreateSuccessResult"
    ],
    "CreateTestFromLessonPayload": [
      "DuplicateResult",
      "SuccessResult"
    ],
    "File": [
      "AudioFile",
      "ImageFile",
      "OriginalFile",
      "VideoFile"
    ],
    "GeneratedLessonItem": [
      "GeneratedOpenQuestion",
      "GeneratedSlide"
    ],
    "Group": [
      "StudentGroup",
      "TeacherGroup"
    ],
    "JoinRealtimeAssignmentSessionPayload": [
      "JoinRealtimeAssignmentSessionFailureResult",
      "JoinRealtimeAssignmentSessionSuccessResult"
    ],
    "MaiaExample": [
      "MaiaImageExample",
      "MaiaTextExample"
    ],
    "Node": [
      "AccountDeletionRequest",
      "Agreement",
      "Assignment",
      "AudioUpload",
      "Channel",
      "Colleague",
      "DocumentUpload",
      "ImageUpload",
      "Lesson",
      "LicenseSubscription",
      "NoticeBanner",
      "NoticeModal",
      "Organization",
      "OrganizationAdmin",
      "OrganizationAgreement",
      "ParentOrganization",
      "PersonalizedSchoolSubscriptionTier",
      "PersonalizedSubscriptionTier",
      "Product",
      "ReceivedTrialGift",
      "Referral",
      "ReferralUser",
      "Report",
      "ReportQuestion",
      "ReportStudent",
      "SchoolSuggestion",
      "SlideDeckUpload",
      "Student",
      "StudentGroup",
      "Subscription",
      "SubscriptionTier",
      "TeacherGroup",
      "TrialGift",
      "UploadFolder",
      "VideoUpload",
      "Viewer"
    ],
    "Notice": [
      "NoticeBanner",
      "NoticeModal"
    ],
    "ReceivedBaseGift": [
      "ReceivedTrialGift"
    ],
    "Upload": [
      "AudioUpload",
      "DocumentUpload",
      "ImageUpload",
      "SlideDeckUpload",
      "VideoUpload"
    ],
    "User": [
      "Colleague",
      "Student",
      "Viewer"
    ]
  }
};
      export default result;
    