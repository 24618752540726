import { SymbolDefinition } from '../SymbolComponent';

const NAME = 'rocket';
export interface RocketSymbol extends SymbolDefinition {
  name: typeof NAME;
}

export const rocketSymbol: RocketSymbol = {
  name: NAME,
  lockedAspectRatio: true,
  startWithFixedAspectRatio: true,
  hasStrokeSettings: false,
};
