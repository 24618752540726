import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8.341 16.512 2.696-8.31c.138-.427.526-.714.963-.714s.825.287.963.713l2.696 8.31m-6.342-3.007h5.366m-11.3 5.022c.121 1.082.961 1.94 2.015 2.06 2.121.243 4.331.531 6.602.531s4.48-.288 6.602-.53c1.054-.12 1.894-.979 2.014-2.06.234-2.099.502-4.283.502-6.527s-.268-4.428-.502-6.526c-.12-1.083-.96-1.94-2.014-2.06-2.121-.243-4.331-.532-6.602-.532s-4.48.29-6.602.531c-1.054.12-1.894.978-2.014 2.06C3.15 7.573 2.882 9.757 2.882 12s.268 4.428.502 6.526"
    />
  </svg>
);
export default SvgIconText;
