import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconBrain = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 7.334a3.49 3.49 0 1 0-6.961.379c-1.397.759-2.371 2.468-2.371 4.454 0 1.457.524 2.766 1.356 3.659q-.014.166-.014.336a3.995 3.995 0 0 0 7.99 0zm0 0a3.491 3.491 0 1 1 6.961.379c1.397.759 2.371 2.468 2.371 4.454 0 1.457-.524 2.766-1.356 3.659q.015.166.014.336a3.995 3.995 0 0 1-7.99 0zm-6.988.002c0 1.74 1.316 2.622 1.872 2.749m12.104-2.749c0 1.74-1.316 2.622-1.872 2.749M6.233 15.67c-.707-.001-1.573.425-2.179.96m13.713-.96c.706-.001 1.572.425 2.178.96"
    />
  </svg>
);
export default SvgIconBrain;
