import { Router, useRouter } from '@lessonup/client-integration';
import { assertNever } from '@lessonup/utils';
import React, { useCallback, useMemo, useReducer } from 'react';
import { SortingDirection, UploadsSorting, UploadsSortingField } from '../../../types/graphql';
import { perPage } from '../UploadsFeature.utils';

export const DEFAULT_SORT_FIELD: UploadsSortingField = 'UPDATED_AT';
export const DEFAULT_SORT_DIRECTION: SortingDirection = 'DESC';

type SearchState = { query: string; page: number };

export type NavigationProps = {
  folderId?: string | null;
  sorting?: Partial<UploadsSorting>;
  page?: number;
  searchPage?: number;
  query?: string;
};

export type NavigationState = (
  | {
      isSearch: true;
      search: SearchState;
    }
  | {
      isSearch: false;
      search: null;
    }
) & {
  sorting: UploadsSorting;
  page: number;
  perPage: number;
  folderId: string | null;
};

type PageDispatch = { type: 'page'; action: 'inc' | 'dec' };

type PageNumberDispatch = { type: 'pageNumber'; number: number };

type SortFieldDispatch = { type: 'sortField'; field: UploadsSortingField };

type FolderIdDispatch = { type: 'folderId'; id: string | null; page?: number };

type SearchQueryDispatch = { type: 'search'; query: string | null };

export type NavigationDispatchProps =
  | PageDispatch
  | PageNumberDispatch
  | SortFieldDispatch
  | FolderIdDispatch
  | SearchQueryDispatch;

export type NavigationDispatch = React.Dispatch<NavigationDispatchProps>;

const newDirectionForState = (state: NavigationState, newField: UploadsSortingField) =>
  newField === state.sorting.field ? (state.sorting.direction === 'ASC' ? 'DESC' : 'ASC') : state.sorting.direction;

function newPageNumberForDispatch(dispatch: PageDispatch, state: NavigationState): number {
  const currentPageNumber = currentPageForNavigationState(state);
  return dispatch.action === 'inc' ? currentPageNumber + 1 : currentPageNumber - 1;
}

function currentPageForNavigationState(state: NavigationState): number {
  return state.isSearch ? state.search.page : state.page;
}

export const navigationReducer = (state: NavigationState, action: NavigationDispatchProps): NavigationState => {
  switch (action.type) {
    case 'page':
      return state.isSearch
        ? { ...state, search: { ...state.search, page: newPageNumberForDispatch(action, state) } }
        : { ...state, page: newPageNumberForDispatch(action, state) };
    case 'pageNumber':
      return state.isSearch
        ? { ...state, search: { ...state.search, page: validPageNumber(action.number) } }
        : { ...state, page: validPageNumber(action.number) };
    case 'sortField':
      return { ...state, sorting: { field: action.field, direction: newDirectionForState(state, action.field) } };
    case 'folderId':
      return { ...state, page: action.page ? action.page : 1, folderId: action.id, search: null, isSearch: false };
    case 'search':
      if (action.query) {
        return { ...state, search: { page: 1, query: action.query }, isSearch: true };
      }
      return { ...state, search: null, isSearch: false };
    default:
      assertNever(action, 'Unknown action');
  }
};

export const callRouterWithNewState = (
  state: NavigationState,
  action: NavigationDispatchProps,
  router: Router
): void => {
  const newState = navigationReducer(state, action);
  const url = createUploadUrlFromNavigationState(newState);

  switch (action.type) {
    case 'sortField':
      router.replace(url);
      return;
    case 'pageNumber':
    case 'page':
    case 'folderId':
    case 'search':
      return router.go(url);
    default:
      assertNever(action, 'Unknown action');
  }
};

const computePropsToState = (props: NavigationProps): NavigationState => {
  const commonProps = {
    page: validPageNumber(props.page),
    perPage,
    folderId: props.folderId || null,
    sorting: {
      field: props.sorting?.field ?? DEFAULT_SORT_FIELD,
      direction: props.sorting?.direction ?? DEFAULT_SORT_DIRECTION,
    },
  };

  if (props.query) {
    return {
      ...commonProps,
      search: { query: props.query || '', page: validPageNumber(props.searchPage) },
      isSearch: true,
    };
  }

  return {
    ...commonProps,
    search: null,
    isSearch: false,
  };
};

/*
 * Ensures the page number is a finite number and never lower than 1.
 */
function validPageNumber(pageNumber: number | undefined) {
  return pageNumber && pageNumber > 0 && isFinite(pageNumber) ? pageNumber : 1;
}

export const useNavigationDispatcherInternal = (
  initialProps: NavigationProps
): [NavigationState, NavigationDispatch] => {
  return useReducer(navigationReducer, computePropsToState(initialProps));
};

export const useNavigationDispatcherRouter = (initialProps: NavigationProps): [NavigationState, NavigationDispatch] => {
  const router = useRouter();
  const navigationState = useMemo(() => computePropsToState(initialProps), [initialProps]);
  const dispatch = useCallback(
    (action: NavigationDispatchProps) => {
      callRouterWithNewState(navigationState, action, router);
    },
    [router, navigationState]
  );

  return [transformNavigationState(navigationState), dispatch];
};

export const createUploadUrlFromNavigationState = ({ folderId, ...params }: NavigationState): string => {
  const currentParams = new URLSearchParams(location.search);
  const baseURL = `/app/uploads/${folderId ?? ''}`;

  currentParams.set('page', validPageNumber(params.page).toString());
  currentParams.set('sortBy', params.sorting.field);
  currentParams.set('direction', params.sorting.direction);

  if (params.search?.query) {
    currentParams.set('query', params.search.query);
    currentParams.set('searchPage', validPageNumber(params.search.page).toString());
  } else {
    currentParams.delete('query');
    currentParams.delete('searchPage');
  }
  return `${baseURL}?${currentParams}`;
};

function transformNavigationState(navigationState: NavigationState): NavigationState {
  if (navigationState.search) {
    return {
      ...navigationState,
      page: navigationState.search.page,
      isSearch: true,
    };
  }

  return {
    ...navigationState,
    page: navigationState.page,
    isSearch: false,
  };
}
