export type LoggableTypes = string | number | boolean | undefined | null;
export type LogDetail = LoggableTypes | Record<string, LoggableTypes> | LoggableTypes[] | Error;
export type LogDetails = Record<string, LogDetail>;

/** we don't want to move our custom logger to its own application yet, so we just use nearly the same interface */
export interface AnalyticsLogger {
  info(message: string | Error, details?: LogDetails): void;
  warn(message: string | Error, details?: LogDetails): void;
  error(error: string | Error, details?: LogDetails): void;
}

let _logger: AnalyticsLogger | undefined;

export function registerAnalyticsLogger(logger: AnalyticsLogger) {
  _logger = logger;
}

/** gets the registered logger or the console  */
export function logger(): AnalyticsLogger {
  return _logger || console;
}
