import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { font } from '../../foundations/typography/fonts';
import { fontSize, lineHeight } from '../../foundations/typography/typography.utils';
import { Button } from '../Button/Button';
import { IconCheck, IconCopy } from '../icons';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipProps } from '../Tooltip/tooltip.utils';

const TRANSLATION_NAMESPACE = 'copyText';
const DELAY_BACK_TO_COPY_MS = 5000;
const MIN_WIDTH = '320px';

export type CopyTextProps = {
  text: string;
  tooltipText?: string;
  tooltipDirection?: TooltipProps['direction'];
  onCopy?: () => void;
};

export const CopyText = ({ text, tooltipText, tooltipDirection = 'bottom', onCopy }: CopyTextProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);
  const [, copy] = useCopyToClipboard();

  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const copyToClipboard = async () => {
    if (copied) return;
    if (!navigator.clipboard) {
      throw new Error("Browser doesn't have support for native clipboard.");
    }

    copy(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, DELAY_BACK_TO_COPY_MS);
    onCopy && onCopy();
  };

  useLayoutEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef]);

  return (
    <Tooltip content={tooltipText ?? t('tooltip')} direction={tooltipDirection} hide={!copied} mode="trigger">
      <StyledInputFieldWrapper onClick={copyToClipboard}>
        <StyledInput value={text} disabled buttonWidth={buttonWidth}></StyledInput>
        <ButtonWrapper>
          <Button
            ref={buttonRef}
            resizing="hug"
            buttonType="secondary"
            iconStart={copied ? <IconCheck /> : <IconCopy />}
          >
            {copied ? t('copied') : t('copy')}
          </Button>
        </ButtonWrapper>
      </StyledInputFieldWrapper>
    </Tooltip>
  );
};

function spacingToInteger(spacingValue: spacing): number {
  return parseInt(spacingValue.replace('px', ''), 10);
}

const StyledInputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-width: ${MIN_WIDTH};
`;

const StyledInput = styled.input<{ buttonWidth: number | null }>`
  border-radius: ${borderRadius.rounded24};
  padding: ${spacing.size8} ${spacing.size16};
  padding-right: ${(props) => {
    if (props.buttonWidth) {
      return css`
        ${props.buttonWidth + spacingToInteger(spacing.size16)}px;
      `;
    } else {
      return css`
        ${spacing.size16};
      `;
    }
  }};
  background-color: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
  border: none;
  font-family: ${font.body.cssValue};
  font-size: ${fontSize.bodyTextSizeMedium};
  line-height: ${lineHeight.bodyTextSizeMedium};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: ${spacing.size4};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
