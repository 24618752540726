import { TextSettings } from '@lessonup/pins-shared';
import { assertNever } from '@lessonup/utils';
import { CSSProperties } from 'react';
import { computeShadowFilter } from '../../utils';

interface CSSPropertiesWithVars extends CSSProperties, CSSPlaceholderVars {
  '--component-fontfamily'?: string;
  '--component-fontsize'?: string;
  '--component-fontcolor'?: string;
  '--component-paragraph-spacing'?: string;
  '--component-horizontal-align'?: 'left' | 'right' | 'center' | 'justify';
  '--component-vertical-align'?: 'center' | 'end' | 'baseline';
  '--component-list-align'?: 'center' | 'end' | 'start';
  '--component-list-justify'?: 'center' | 'end' | 'baseline';
}

interface CSSPlaceholderVars {
  '--component-placeholder-text'?: string;
  '--component-placeholder-left'?: CSSProperties['left'];
  '--component-placeholder-right'?: CSSProperties['right'];
  '--component-placeholder-transform'?: CSSProperties['transform'];
}

export function getFontShadowClassName(fontShadow: TextSettings['fontShadow']) {
  if (!fontShadow) return '';

  switch (fontShadow) {
    case 'HARD':
      return 'shadow-hard';
    case 'MEDIUM':
      return 'shadow-medium';
    case 'SOFT':
      return 'shadow-soft';
    case 'NONE':
      return '';
    default:
      assertNever(fontShadow, 'Invalid textShadow value');
  }
}

export function horizontalAlignmentToFlexValue(horizontalAlignment: TextSettings['horizontalAlignment']) {
  switch (horizontalAlignment) {
    case 'left':
      return 'start';
    case 'center':
      return 'center';
    case 'right':
      return 'end';
    default:
      return;
  }
}

/**
 * We want to minimise the amount of css classes generated by Emotion, so to aid that we
 * pass the settings through css variabled to then be used inside of the styled constructor.
 */
export function computeTextPinComponentStyles(settings: TextSettings, placeholder?: string): CSSPropertiesWithVars {
  return {
    ...(settings.fontFamily && {
      '--component-fontfamily': `"${settings.fontFamily}"`,
    }),
    ...(settings.fontSize && { '--component-fontsize': settings.fontSize }),
    ...(settings.fontColor ? { '--component-fontcolor': settings.fontColor } : { '--component-fontcolor': '#001343' }), // ToDo: default color should eventually be set through templating
    ...(settings.backgroundColor && { backgroundColor: settings.backgroundColor }),
    lineHeight: settings.lineHeight ?? 1.5,
    '--component-paragraph-spacing': `${settings.paragraphSpacing ?? 0}em`,
    // The horizontal and vertical alignments need an explicit default so that the tiptap child always behaves the same way
    '--component-horizontal-align': settings.horizontalAlignment ?? 'left',
    '--component-vertical-align': settings.verticalAlignment ?? 'baseline',
    '--component-list-align': horizontalAlignmentToFlexValue(settings.horizontalAlignment),
    '--component-list-justify': settings.verticalAlignment,
    boxShadow: computeShadowFilter(settings.shadow),
    borderWidth: `${settings.borderSize ?? 0}px`,
    borderStyle: 'solid',
    borderRadius: `${settings.borderRadius ?? 0}px`,
    borderColor: `${settings.borderColor ?? '#000000'}`,
    padding: computePaddingStyle(settings.padding),
    ...computePlaceholderStyle(settings, placeholder),
  };
}

export function computePaddingStyle(paddingSetting: TextSettings['padding']): string {
  if (!paddingSetting) return '4px 12px';

  return `${paddingSetting?.top || 0}px ${paddingSetting?.right || 0}px ${paddingSetting?.bottom || 0}px ${
    paddingSetting?.left || 0
  }px`;
}

function computePlaceholderStyle(settings: TextSettings, placeholder?: string): CSSPlaceholderVars {
  return {
    ...(placeholder ? { '--component-placeholder-text': `"${placeholder}"` } : {}),
    ...(settings.horizontalAlignment === 'left' ? { '--component-placeholder-left': 0 } : {}),
    ...(settings.horizontalAlignment === 'right' ? { '--component-placeholder-right': 0 } : {}),
    ...(settings.horizontalAlignment === 'center'
      ? { '--component-placeholder-left': '50%', '--component-placeholder-transform': `translateX(-50%)` }
      : {}),
  };
}
