import { BodyText, Button, Headline, SpaceBetween, spacing } from '@lessonup/ui-components';
import React from 'react';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';

export type RegistrationVerificationExpiredProps = {
  onCTAClick: () => void;
};

export const RegistrationVerificationExpired = (props: RegistrationVerificationExpiredProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'verificationLinkExpired');

  return (
    <div>
      <SpaceBetween spacing={spacing.size32} direction="y" alignItems="center">
        <Headline size="large">{t('title')}</Headline>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <BodyText>{t('body')}</BodyText>
          <Button onClick={props.onCTAClick} size="large">
            {t('cta')}
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
};
