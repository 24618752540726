import { breakpoints, color, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

interface FullPageBodyProps {
  children: React.ReactNode;
}

export const FullPageBodySectionTransparent: React.FC<FullPageBodyProps> = (props) => {
  const { children, ...rest } = props;
  return <StyledBaseSection {...rest}>{children}</StyledBaseSection>;
};

const StyledBaseSection = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  max-width: ${breakpoints.maxWidthText};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.size4};
  margin: ${spacing.size16} auto 0 auto;
  padding: ${spacing.size16};
`;

export const FullPageBodySection: React.FC<FullPageBodyProps> = ({ children, ...props }) => {
  return <StyledSection {...props}>{children}</StyledSection>;
};

const StyledSection = styled(StyledBaseSection)`
  border-radius: ${spacing.size16};
  background-color: ${color.neutral.surface.background};
  padding: ${spacing.size16};
  width: 100%;
`;
