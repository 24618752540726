import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.458 3.594H8.542L6.825 6.703q-.51.043-1.012.087a3.14 3.14 0 0 0-2.826 2.653c-.19 1.291-.362 2.62-.362 3.978 0 1.357.172 2.686.362 3.977a3.14 3.14 0 0 0 2.826 2.654c2.058.177 4.12.355 6.187.355s4.129-.178 6.187-.355a3.14 3.14 0 0 0 2.826-2.654c.19-1.29.362-2.62.362-3.977 0-1.358-.172-2.687-.362-3.978a3.14 3.14 0 0 0-2.826-2.653q-.506-.045-1.012-.087z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 16.353a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75"
    />
  </svg>
);
export default SvgIconCamera;
