import { createTextEditorHandler } from '@lessonup/pins-shared';
import { Editor } from '@tiptap/core';
import { Listener, ListenerType, TextEditorStore, TextEditorStoreState } from './textEditorStore.types';

/**
 * Store for text editors
 * Created in a function to allow for multiple instances
 */
export const createTextEditorStore = (initialState?: TextEditorStoreState): TextEditorStore => {
  const state: TextEditorStoreState = initialState || {
    editor: null,
    activeComponentId: null,
    textHandler: createTextEditorHandler(null),
  };
  const listeners: Partial<Record<ListenerType, Listener[]>> = {};

  let watchedState = { ...state };
  // You can not watch the state directly, because it is a reference and will not trigger a change
  function replaceWatchedState() {
    watchedState = { ...state };
  }

  const store: TextEditorStore = {
    getSnapshot() {
      return watchedState;
    },
    getServerSnapshot() {
      return watchedState;
    },
    getHandlerSnapshot() {
      return state.textHandler;
    },
    getServerHandlerSnapshot() {
      return state.textHandler;
    },
    forceEditorUpdate() {
      replaceWatchedState();
      emitChangesForTypes('state');
    },
    setEditor(editor: Editor | null) {
      if (state.editor === editor) {
        return;
      }
      state.editor = editor;
      state.textHandler = createTextEditorHandler(editor);
      replaceWatchedState();
      emitChangesForTypes('editor', 'state');
    },
    subscribeForType(type: ListenerType) {
      return (listener) => {
        listeners[type] = [...getListenersByType(type), listener];
        return () => {
          listeners[type] = getListenersByType(type).filter((l) => l !== listener);
        };
      };
    },
    clear() {
      state.editor = null;
      state.activeComponentId = null;
      state.textHandler = createTextEditorHandler(null);
    },
  };

  function getListenersByType(type: ListenerType): Listener[] {
    return listeners[type] ?? [];
  }

  function emitChangesForTypes(...listenerTypes: ListenerType[]) {
    for (const listenerType of listenerTypes) {
      for (const listener of getListenersByType(listenerType)) {
        listener();
      }
    }
  }

  return store;
};
