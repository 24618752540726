import { intercomIsAvailable } from '../utils/intercomisAvailable';

export type IntercomEvent = {
  (eventName: 'cancel-subscription'): void;
  (eventName: 'login-password-error', params: { count: number }): void;
};

export const trackIntercomEvent: IntercomEvent = (eventName, metaData?): void => {
  if (!intercomIsAvailable()) return;

  window.Intercom('trackEvent', eventName, metaData);
};
