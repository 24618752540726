import {
  Card,
  IconSortAscending,
  IconSortDescending,
  Introduction,
  spacing,
  styled,
  Table,
} from '@lessonup/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportStudent } from '../../utils/types';

type SortDirection = 'asc' | 'desc';

export interface StudentListCardProps {
  students: ReportStudent[];
  className?: string;
}

export const StudentListCard = ({ students, className }: StudentListCardProps) => {
  const { t } = useTranslation('reports');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  function toggleSortDirection() {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  }

  const rows = students
    .sort((a, b) => (sortDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)))
    .map((student) => ({
      id: student.id,
      cells: [{ text: student.name }],
      ariaLabel: student.name,
    }));

  return (
    <StyledCard className={className}>
      <StyledIntroduction headline={t('studentListTitle')} bodyText="" size="M" alignment="start" />
      <TableWrapper>
        <Table
          caption={t('studentListTableCaption')}
          header={{
            cells: [
              {
                text: t('studentListTableHeader'),
                onClick: toggleSortDirection,
                endSvgIcon: sortDirection === 'asc' ? IconSortAscending : IconSortDescending,
                alignment: 'start',
              },
            ],
          }}
          rows={rows}
          selectedRows={[]}
          interactivity={false}
        />
      </TableWrapper>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  overflow: hidden; // Fixes rounded corners in the bottom when scroll-bar is visible
`;

const TableWrapper = styled.div`
  overflow: auto;
`;

const StyledIntroduction = styled(Introduction)`
  padding: ${spacing.size16};
`;
