import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconBook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.004 15.644c-1.35 2.162-1.35 3.375 0 5.537M3.555 18.47c.097.736.988 2.278 3.777 2.557 3.487.349 12.924.125 13.418.125M3.206 5.945c-.279 2.534-.09 9.596.349 12.58-.726-4.939 13.15-2.876 15.078-2.876 1.93 0 2.315-1.763 2.266-2.644.02-1.375.047-4.712 0-7.06-.046-2.347-6.178-3.089-9.238-3.166-2.702 0-8.176.633-8.455 3.166"
    />
  </svg>
);
export default SvgIconBook;
