import {
  BodyText,
  Divider,
  Headline,
  Introduction,
  SpaceBetween,
  spacing,
  styled,
  TextListItem,
  TextListItemContentSlot,
  WithDividers,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodyAsideLayout } from '../../../../../components/FullPageFlow/FullPageBodyAsideLayout';
import {
  FullPageBodySection,
  FullPageBodySectionTransparent,
} from '../../../../../components/FullPageFlow/FullPageBodySection';
import { FullPageBodyAside } from '../../../../../components/FullPageFlow/FullPageFlowAside';
import { LessonOutline } from '../../../../../types/graphql';
import { OptionalLessonSettings } from '../../../model';
import { OptionalLessonSettingsList } from '../../OptionalLessonSettingsList';
import { BlinkingCursor } from './BlinkingCursor';

interface ContextValidationStepProps {
  result: LessonOutline | null;
  isCompleted: boolean;
  optionalLessonSettings: OptionalLessonSettings;
  setOptionalLessonSettings: React.Dispatch<React.SetStateAction<OptionalLessonSettings>>;
}

export const ContextValidationStep = ({
  result,
  isCompleted,
  optionalLessonSettings,
  setOptionalLessonSettings,
}: ContextValidationStepProps) => {
  const { t } = useTranslation('chatGPT');

  function hasCursor(key: keyof Omit<LessonOutline, '__typename'>) {
    if (isCompleted) return false;
    const orderedKeys: (keyof Omit<LessonOutline, '__typename'>)[] = [
      'header',
      'learningGoals',
      'mainTopics',
      'condensedText',
      'definitionList',
    ];

    const orderedResults = result && {
      header: result.header,
      learningGoals: result.learningGoals,
      mainTopics: result.mainTopics,
      condensedText: result.condensedText,
      definitionList: result.definitionList,
    };

    if (!orderedResults) return false;

    const index = Math.max(
      -1,
      Math.min(orderedKeys.findIndex((key) => !orderedResults[key]?.length) - 1, orderedKeys.length)
    );

    return index === -1 ? key === 'definitionList' : orderedKeys[index] === key;
  }

  return (
    <>
      <FullPageBodySectionTransparent>
        <Introduction
          alignment="center"
          size="M"
          headline={t('validationStep.title')}
          bodyText={t('validationStep.subTitle')}
        />
      </FullPageBodySectionTransparent>
      <FullPageBodyAsideLayout mainLabel={t('validationStep.validationMainLabel')} asideLabel={t('optionalSettings')}>
        <StyledFullPageBodySection>
          <SpaceBetween direction="y" spacing={spacing.size8} padding={spacing.size16}>
            <WithDividers divider={<Divider orientation="horizontal" />}>
              <>
                <Headline size="medium">{result?.header}</Headline>
                {!result && <BlinkingCursor />}
              </>
              {result?.learningGoals?.length && (
                <SpaceBetween direction="y" spacing={spacing.size4}>
                  <Headline size="small">{t('validationStep.learningGoals')}</Headline>
                  {result.learningGoals.map((item, i) => (
                    <TextListItem
                      startSlot={<TextListItemContentSlot type="number" number={i + 1} />}
                      key={'learninggoal-' + i}
                      text={item}
                    />
                  ))}
                  {hasCursor('learningGoals') && <BlinkingCursor />}
                </SpaceBetween>
              )}
              {result?.mainTopics?.length && (
                <SpaceBetween direction="y" spacing={spacing.size4}>
                  <Headline size="small">{t('validationStep.mainTopics')}</Headline>
                  {result.mainTopics.map((item, i) => (
                    <TextListItem
                      startSlot={<TextListItemContentSlot type="number" number={i + 1} />}
                      key={'topic-' + i}
                      text={item}
                    />
                  ))}
                  {hasCursor('mainTopics') && <BlinkingCursor />}
                </SpaceBetween>
              )}
              {result?.condensedText.length && (
                <React.Fragment>
                  <Headline size="small">{t('validationStep.textSummary')}</Headline>
                  <BodyText>{result.condensedText}</BodyText>
                  {hasCursor('condensedText') && <BlinkingCursor />}
                </React.Fragment>
              )}
              {result?.definitionList?.length ? (
                <SpaceBetween direction="y" spacing={spacing.size4}>
                  <Headline size="small">{t('validationStep.definitionList')}</Headline>
                  {result.definitionList.map((item, i) => (
                    <TextListItem
                      startSlot={<TextListItemContentSlot type="number" number={i + 1} />}
                      key={'definition-' + i}
                      text={item}
                    />
                  ))}
                  {hasCursor('definitionList') && <BlinkingCursor />}
                </SpaceBetween>
              ) : null}
            </WithDividers>
          </SpaceBetween>
        </StyledFullPageBodySection>
        <FullPageBodyAside>
          <OptionalLessonSettingsList
            currentSettings={optionalLessonSettings}
            setOptionalLessonSettings={setOptionalLessonSettings}
            showSetting={{
              enableTeacherNotes: false,
              enablePreviousKnowledge: true,
              enableExitTicket: true,
              enableLearningPhase: false,
            }}
          />
        </FullPageBodyAside>
      </FullPageBodyAsideLayout>
    </>
  );
};

const StyledFullPageBodySection = styled(FullPageBodySection)`
  align-items: stretch;
`;
