import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { ComponentSize } from '../../foundations/size/size.types';
import { spacing, spacingScaled } from '../../foundations/spacing/spacing';
import { TextElement } from '../../foundations/typography';

export interface TabProps {
  content: string | React.JSX.Element;
  disabled?: boolean;
  isActive?: boolean;
  className?: string;
  size?: ComponentSize; // Todo: This component has been updated with sizes to fit usecases in and outside the new editor, but the design is not final yet.
}

interface ButtonTab extends TabProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  as: 'button';
}

interface AnchorTab extends TabProps {
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  as: 'a';
}

type Tab = ButtonTab | AnchorTab;
export const Tab: React.FC<Tab> = (props) => {
  const href = props.as === 'a' && !props.disabled ? props.href : undefined;
  const size: ComponentSize = props.size || 'medium';

  // cast is needed to not include unnecessary checks in the event to satisfy the type checker
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTabClick = (event: React.MouseEvent<any>) => {
    if (props.disabled) return;
    if (props.onClick) props.onClick(event);
  };

  return (
    <StyledTabWrapper size={size}>
      <StyledBackground
        onClick={handleTabClick}
        isActive={props.isActive}
        disabled={props.disabled}
        as={props.as}
        href={href}
        className={props.className}
        size={size}
      >
        {typeof props.content === 'string' ? (
          <TextElement textStyle="label" weight="bold" size={props.size}>
            {props.content}
          </TextElement>
        ) : (
          <>{props.content}</>
        )}
      </StyledBackground>
    </StyledTabWrapper>
  );
};

export const StyledTabWrapper = styled.div<{ size: ComponentSize }>`
  background-color: ${color.additional.disabled.background};
  padding: ${({ size }) => `${spacingScaled(spacing.size2, size)} ${spacingScaled(spacing.size2, size)}`};
  display: flex;
  align-items: center;
  flex-grow: 1;
  &:focus-visible {
    outline: ${({ size }) => `solid ${spacingScaled(spacing.size2, size)} ${color.additional.focus.background}`};
  }
`;

const StyledBackground = styled.a<Pick<TabProps, 'isActive' | 'disabled'> & { size: ComponentSize }>`
  border-radius: ${borderRadius.rounded10};
  padding: ${({ size }) => `${spacingScaled(spacing.size2, size)} ${spacingScaled(spacing.size6, size)}`};
  cursor: pointer;
  text-decoration-line: none;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    !props.isActive && !props.disabled
      ? css`
          &:hover {
            background: ${color.neutral.surface.hover};
          }
        `
      : props.isActive && !props.disabled
        ? css`
            background: ${color.neutral.surface.background};
          `
        : props.disabled
          ? css`
              color: ${color.additional.disabled.text};
              background: ${props.isActive && color.additional.disabled.background};
              cursor: default;
            `
          : ''};
`;
