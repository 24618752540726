import { Route } from '@lessonup/client-integration';

interface PlanRouteParams {
  planId: string;
  planName?: string;
}
export const planRoute = new Route<PlanRouteParams>(
  'plan',
  { planId: ':planId', planName: ':planName?' },
  ({ planId, planName }) => `/app/plan/${planId}${planName ? `/${planName}` : ''}`
);

interface GroupsRouteParams {
  groupId?: string;
}

export const groupsRoute = new Route<GroupsRouteParams>(
  'groups',
  { groupId: ':groupId?' },
  ({ groupId }) => `/app/explorer/groups/${groupId ? groupId : ''}`
);

export const groupsArchiveRoute = new Route<GroupsRouteParams>(
  'groupsArchive',
  { groupId: ':groupId?' },
  ({ groupId }) => `/app/explorer/groups-archive/${groupId ? groupId : ''}`
);

// eslint-disable-next-line no-empty-pattern
export const modernTestRoute = new Route('modernTestRoute', {}, ({}) => `/app/teacher-modern`);

export interface ModernUploadsRouteParams {
  folderId?: string;
}

export const modernUploadsRoute = new Route<ModernUploadsRouteParams>(
  'modernUploadsRoute',
  { folderId: ':folderId?' },
  ({ folderId }) => `/app/uploads${folderId ? '/' + folderId : ''}`
);

interface MyLessonsRouteParams {
  folderId?: string;
}

export const myLessonsRoute = new Route<MyLessonsRouteParams>(
  'myLessons',
  { folderId: ':folderId?' },
  ({ folderId }) => {
    return `/app/explorer/lessons${folderId ? '/' + folderId : ''}`;
  }
);

export interface LessonEditorRoute {
  lessonId: string;
  pinId?: string;
}

export const lessonEditorRoute = new Route<LessonEditorRoute>(
  'lessonEditor',
  { lessonId: ':folderId', pinId: ':pinId' },
  ({ lessonId, pinId }) => {
    return `/app/lesson/${lessonId}${pinId ? '/' + pinId : ''}`;
  }
);

export const lessonEditorV2Route = new Route<LessonEditorRoute>(
  'lessonEditorV2',
  { lessonId: ':lessonId', pinId: ':pinId?' },
  ({ lessonId, pinId }) => `/app/lesson-v2/${lessonId}${pinId ? `/${pinId}` : ''}`
);

export const lessonEditorBrokenRoute = new Route<LessonEditorRoute>(
  'lessonEditorV2',
  { lessonId: ':lessonId', pinId: ':pinId?' },
  ({ lessonId, pinId }) => `/app/lesson-broken/${lessonId}${pinId ? `/${pinId}` : ''}`
);

export interface ReportRoute {
  reportId: string;
}
export const reportV2Route = new Route<ReportRoute>(
  'reportV2',
  { reportId: ':reportId' },
  ({ reportId }) => `/app/report-v2/${reportId}`
);

export interface RealtimePlayerRoute {
  source: string;
  lessonId: string;
  pinId?: string;
  withPresenterMode?: boolean;
}

export const realtimePlayerV2Route = new Route<RealtimePlayerRoute>(
  'realtimePlayerV2',
  { source: ':source', lessonId: ':lessonId', pinId: ':pinId?', withPresenterMode: false },
  ({ source, lessonId, pinId, withPresenterMode }) =>
    `/app/teach-v2/${source}/${lessonId}${pinId ? `/${pinId}` : ''}${withPresenterMode ? '?withPresenter=true' : ''}`
);

export interface PresenterRealtimePlayerRoute {
  assignmentId: string;
  lessonId: string;
  pinId?: string;
}

export const playerv2PresenterRoute = new Route<PresenterRealtimePlayerRoute>(
  'playerV2Presenter',
  { assignmentId: ':assignmentId', lessonId: ':lessonId', pinId: ':pinId?' },
  ({ assignmentId, lessonId, pinId }) =>
    `/app/teach-v2-presenter/${assignmentId}/${lessonId}${pinId ? `/${pinId}` : ''}`
);

interface LessonPlanRoute {
  planId: string;
  view?: boolean;
}

export const lessonPlanRoute = new Route<LessonPlanRoute>('lessonEditor', { planId: ':planId' }, ({ planId, view }) => {
  if (view) {
    return `/app/view-plan/${planId}`;
  }
  return `/app/plan/${planId}`;
});

interface AccountManagementRouteParams {
  page: 'profile' | 'settings' | 'mySchools' | 'invoices' | 'deleteAccount' | 'subscription';
}
// eslint-disable-next-line no-empty-pattern
export const profileSettingsRoute = new Route<AccountManagementRouteParams | { page: ':page' }>(
  'profileSettingsRoute',
  { page: ':page' },
  ({ page }) => `/app/account/${page}`
);

interface OrganizationSettingsRouteParams {
  organizationId: string;
  page?: string;
}

export const organizationRoute = new Route<OrganizationSettingsRouteParams>(
  'organizationSettingsRoute',
  { organizationId: ':organizationId', page: ':page?' },
  ({ organizationId, page }) => {
    return `/app/account/organization/${organizationId}${page ? '/' + page : ''}`;
  }
);

export const accountSettingsRoute = new Route('accountSettingsRoute', {}, () => `/app/account/settings`);

export const mySchoolsRoute = new Route('mySchoolsRoute', {}, () => `/app/account/mySchools`);

export interface SubscriptionRoute {
  page?: string;
}

export const mySubscriptionRoute = new Route<SubscriptionRoute>(
  'mySubscriptionRoute',
  { page: ':page?' },
  ({ page }) => {
    return `/app/account/subscription${page ? '/' + page : ''}`;
  }
);

export const mySubscriptionSuccessRoute = new Route(
  'mySubscriptionSuccessRoute',
  {},
  () => `/app/account/license/success`
);

export const referralPageRoute = new Route('referralPageRoute', {}, () => `/app/account/referral`);

// eslint-disable-next-line no-empty-pattern
export const teacherRoot = new Route('teacherRoot', {}, ({}) => `/app/explorer`);

export const lessonGeneratorRoute = new Route('lessonGenerator', {}, () => {
  return `/app/explorer/lesson-generator/`;
});

export const organizationAgreementRoute = new Route(
  'organizationAgreementRoute',
  {
    agreementId: ':agreementId',
    secret: ':secret',
    proposalId: ':proposalId',
  },
  ({ agreementId, secret, proposalId }) => {
    return `/app/agreement/GDPR/${agreementId}/${secret}/${proposalId}`;
  }
);

interface AgreementDocumentRouteParams {
  documentVersion: string;
  agreementId: string;
  secret: string;
  proposalId: string;
  inframe?: boolean;
}
export const agreementDocumentRoute = new Route<AgreementDocumentRouteParams>(
  'agreementDocumentRoute',
  {
    documentVersion: ':documentVersion',
    agreementId: ':agreementId',
    secret: ':secret',
    proposalId: ':proposalId',
    inframe: false,
  },
  ({ documentVersion, agreementId, secret, proposalId, inframe }) => {
    return `/app/agreement-documents/${documentVersion}/${agreementId}/${secret}/${proposalId}${
      inframe ? '?inframe=true' : ''
    }`;
  }
);
