import {
  Button,
  Divider,
  IconAdd,
  IconFolder,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  UploadFileInput,
  useForm,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { showUploadsModal } from '../../../../../Uploads/UploadsModalFeature';
import { HandleAddImage } from '../imagePicker.types';

interface AddImageFromLinkFormInput {
  url: string;
}

interface ImagePickerUploadSectionProps {
  handleAddImage: HandleAddImage;
  t: TFunction;
  acceptMultipleFiles?: boolean;
}

export const ImagePickerUploadSection: React.FC<ImagePickerUploadSectionProps> = ({
  handleAddImage,
  t,
  acceptMultipleFiles = false,
}) => {
  const { register, getValues, watch } = useForm<AddImageFromLinkFormInput>();

  const urlInput = watch('url');

  const handleUploadFromInput = async (files: File[]) => {
    if (!files.length) return;
    handleAddImage({ files });
  };

  const handleUploadFromModal = async () => {
    const result = await showUploadsModal({ fileTypeFilter: ['ImageUpload', 'DocumentUpload'] });
    const urls = result?.urls.filter(Boolean) || [];

    if (urls.length) {
      return handleAddImage({ urls });
    }
  };

  return (
    <>
      <SpaceBetween spacing={spacing.size8} direction="y" padding={spacing.size16}>
        <UploadFileInput
          buttonType="secondary"
          icon={<IconAdd />}
          acceptedMimeTypes={{
            audioTypes: [],
            documentTypes: [],
            imageTypes: ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/tiff', 'image/webp'],
            videoTypes: [],
          }}
          multiple={acceptMultipleFiles}
          onUpload={handleUploadFromInput}
        >
          {t('imagePicker.uploadButton')}
        </UploadFileInput>
        <Button buttonType="neutral" showStroke iconStart={<IconFolder />} onClick={handleUploadFromModal}>
          {t('imagePicker.browseUploadsButton')}
        </Button>
      </SpaceBetween>
      <Divider orientation="horizontal" text={t('imagePicker.linkDivider')} />
      <SpaceBetween spacing={spacing.size8} direction="y" padding={spacing.size16}>
        <SpaceBetween direction="x" spacing={spacing.size8} alignItems="flex-end">
          <StyledInputField
            {...register('url')}
            type="text"
            placeholder={t('imagePicker.urlInputPlaceholder')}
            label={t('imagePicker.linkLabel')}
            autoComplete="off"
            formFieldSize="small"
          />
          <Button
            buttonType="neutral"
            showStroke
            onClick={() => handleAddImage({ urls: [getValues().url] })}
            type="submit"
            disabled={!urlInput}
          >
            {t('imagePicker.addButton')}
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </>
  );
};

const StyledInputField = styled(InputFormField)`
  flex: 1;
`;
