import { ApolloError, useStaggeredDocumentQuery } from '@lessonup/client-integration';
import { UploadStatusData } from '../Uploads.graphql';
import { UploadStatusDocument } from '../Uploads.graphql.generated';
import { isReady, UploadQueryData } from '../UploadsFeature.utils';

interface Props {
  ids: string[];
  onSingleCompleted?: (data: UploadStatusData) => void;
  onAllCompleted?: (data: UploadStatusData) => void;
  onQueryDocumentError?: (error: ApolloError) => void;
  onSingleDocumentError?: (documentId: string) => void;
}

export const useUploadsStatus = ({
  ids,
  onSingleCompleted,
  onAllCompleted,
  onQueryDocumentError,
  onSingleDocumentError,
}: Props): UploadQueryData<UploadStatusData> => {
  const { data, loading, error, refetch } = useStaggeredDocumentQuery(UploadStatusDocument, {
    variables: {
      ids,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      handleErroredDocuments(data);
      onSingleCompleted?.(data);

      const allCompleted = data.viewer.uploadsByIds.every(isReady);

      if (!allCompleted) return;
      onAllCompleted?.(data);
    },
    skip: !ids.length,
    onError: onQueryDocumentError,
  });

  function handleErroredDocuments(data: UploadStatusData) {
    if (!onSingleDocumentError) return;

    const erroredDocuments = data.viewer.uploadsByIds.filter((upload) => upload.status === 'ERROR');
    erroredDocuments.forEach((upload) => onSingleDocumentError(upload.id));
  }

  const retry = async () => {
    await refetch();
  };

  return {
    data,
    loading,
    error,
    retry,
  };
};
