import type { i18n } from 'i18next';

let _instance: i18n | undefined;

export function setI18nInstance(instance: i18n) {
  _instance = instance;
}

export function getI18nInstance(): i18n | undefined {
  if (!_instance) {
    console.warn('i18n instance is not set in bridge');
  }
  return _instance;
}
