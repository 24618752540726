// Inspiration from https://stackoverflow.com/a/55266531
// Goal: get array with all values from union type
type AtLeastOne<T> = [T, ...T[]];

export const exhaustiveStringTuple =
  <T extends string>() =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  <L extends AtLeastOne<T>>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...x: L extends any ? (Exclude<T, L[number]> extends never ? L : Exclude<T, L[number]>[]) : never
  ) =>
    x;

/**
 * A generic type that adds a prefix to the keys of an object.
 * This used for Emotion, to make sure the key (attribute name) isn't added to a HTML element in the DOM
 */
type AddPrefixToObject<T, Prefix extends string = '$'> = {
  [K in keyof T as K extends string ? `${Prefix}${K}` : never]: T[K];
};

/**
 * A generic type that creates a partial type of an object with a prefix added to its keys.
 *
 * @example type SpaceBeweenStyledProps = PartialPrefix<SpaceBeweenProps, 'spacing' | 'direction'>
 */
export type PartialPrefix<T, P extends keyof T = keyof T> = AddPrefixToObject<Pick<T, P>> & Omit<T, P>;

/**
 * Converts a string with a px suffix to a number
 *
 * @param px - A string with a px suffix
 * @returns A number
 */
export const pxToNumber = (px: string): number => Number(px.replace('px', ''));

/**
 * Format a pincode to have a dash in the middle
 *
 * @param pinCode
 * @returns A formatted pincode
 */
export const formatPinCode = (pinCode: string): string => {
  return pinCode.slice(0, pinCode.length / 2) + ' - ' + pinCode.slice(pinCode.length / 2);
};
