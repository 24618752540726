import { isApolloError } from '@apollo/client';
import { ErrorDisplayMessage } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import { TFunction } from 'i18next';
import { apolloErrorToAppError } from './apolloToAppError';

const defaultTitle = 'title500';
const defaultMsg: ErrorDisplayMessage = { title: defaultTitle, description: 'message500' };

export const errorTranslationNameSpace = 'errors';

export const errorCodeToStandardizedMessage = (error: Error, t: TFunction): ErrorDisplayMessage => {
  const keys = errorTranslationKeys(error);

  return {
    title: t(keys.title, { ns: errorTranslationNameSpace }),
    description:
      typeof keys.description === 'string' ? t(keys.description, { ns: errorTranslationNameSpace }) : keys.description,
  };
};

const errorTranslationKeys = (error: Error): ErrorDisplayMessage => {
  const mappedError = isApolloError(error) ? apolloErrorToAppError(error) : error;
  const status = AppError.mapErrorToHttpStatusCode(mappedError);
  switch (status) {
    case 401:
      return { title: 'title401', description: 'message401' };
    case 403:
      return { title: 'title403', description: 'message403' };
    case 404:
      return { title: 'title404', description: 'message404' };
    case 408:
    case 503:
      return { title: defaultTitle, description: 'message503' };
    case 400:
    case 409:
    case 412:
    case 500:
    case 501:
    case 200:
      return defaultMsg;
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
      const _exhaustiveCheck: never = status;
      return defaultMsg;
  }
};
