import { DataLoader } from '@lessonup/client-integration';
import { rem, styled } from '@lessonup/ui-components';
import React from 'react';
import { PageLoadingState } from '../components/PageLoadingState/PageLoadingState';
import { ReferralPage } from '../components/ReferralPage/ReferralPage';
import { useReferral } from '../hooks/useReferral';

export interface ReferralPageFeatureProps {
  baseUrl: string;
}
export const ReferralPageFeature = ({ baseUrl }: ReferralPageFeatureProps) => {
  const { data, loading, error } = useReferral({ baseUrl });

  return (
    <DataLoader
      data={data}
      loading={loading}
      error={error}
      customLoader={<PageLoadingState />}
      dataRenderer={(props) => <StyledReferralPage {...props} />}
    ></DataLoader>
  );
};

const StyledReferralPage = styled(ReferralPage)`
  max-width: ${rem('524px')};
`;
