import { isEqual, uniqWith } from 'lodash';
import { Lesson } from '../lesson';
import { SchoolType } from '../school';
import { EduSystemBE, EduSystemBESchoolType } from './edusystemCountries/EduSystemBE';
import { EduSystemFR, EduSystemFrSchoolType } from './edusystemCountries/EduSystemFR';
import { EduSystemGB, EduSystemGBSchoolType } from './edusystemCountries/EduSystemGB';
import { EduSystemINT, EduSystemIntSchoolType } from './edusystemCountries/EduSystemINT';
import { EduSystemNL, EduSystemNLSchoolType } from './edusystemCountries/EduSystemNL';
import { EduSystemUS, EduSystemUSSchoolType } from './edusystemCountries/EduSystemUS';

export namespace EduSystem {
  export type NL = EduSystemNL;
  export const NL = new EduSystemNL();

  export type INT = EduSystemINT;
  export const INT = new EduSystemINT();

  export type BE = EduSystemBE;
  export const BE = new EduSystemBE();

  export type US = EduSystemUS;
  export const US = new EduSystemUS();

  export type GB = EduSystemGB;
  export const GB = new EduSystemGB();

  export type FR = EduSystemFR;
  export const FR = new EduSystemFR();

  export type System = NL | INT | BE | US | GB | FR;
  export type AllSchoolTypes =
    | EduSystemNLSchoolType
    | EduSystemBESchoolType
    | EduSystemIntSchoolType
    | EduSystemUSSchoolType
    | EduSystemGBSchoolType
    | EduSystemFrSchoolType;

  export function get(country: 'us' | ['us']): US;
  export function get(country: 'gb' | ['gb']): GB;
  export function get(country: 'nl' | ['nl']): NL;
  export function get(country: 'be' | ['be']): BE;
  export function get(country: 'fr' | ['fr']): FR;
  export function get(country: string | string[]): INT;
  export function get(country: string | string[]): System {
    const countryStr = country && Array.isArray(country) ? country[0] : country;
    switch (countryStr) {
      case 'nl':
        return NL;
      case 'be':
        return BE;
      case 'us':
        return US;
      case 'gb':
        return GB;
      case 'fr':
        return FR;
      default:
        return INT;
    }
  }

  export interface SuggestionOptions<Type> {
    value: Type;
    fieldOptions: FieldOption[];
    maxLength?: number;
    excludeUnits?: boolean;
    defaultValue: string;
  }

  export type Suggestor<Type> = (params: SuggestionOptions<Type>) => string;

  export interface EditData {
    label: () => string;
    id: EditDataId;
    /** use this to group fields in SystemData -> SystemDataField */
    reference: EditDataIdWithReference;
    numeric?: boolean;
    isArray?: boolean;
    pickMultiple?: boolean;
    defaultValue?: string;
    moreLabel?: string;
    cleanAfterwards?: boolean;
    fieldName?: string;
    inputSelect?: boolean;
    shortListField?: string | [string, string];
    shortListFieldLength?: number;
    suggestor: Suggestor<any>;
  }

  export interface SystemData<T extends string> {
    id: T;
    short: string;
    label: string;
    prefix: string | undefined;
    optionOrderIsSet?: boolean;
    fields: SystemDataField[];
  }

  export type MetaDataTagField = 'schoolType' | 'levels' | 'subjects' | 'years';
  export type MetaDataParams = Pick<Lesson, MetaDataTagField>;
  export type SystemDataFieldLabel = 'subject' | 'year' | 'level' | 'theme';
  export type EditDataId = MetaDataTagField | 'themes' | 'durationInMin';
  /** These are used in reference to group stuff */
  export type EditDataIdReference =
    | 'groep'
    | 'leeftijd'
    | 'age'
    | 'studyYear'
    | 'grade'
    | 'phase'
    | 'learningRoute'
    | 'grade-pe'
    | 'grade-se'
    | 'grade-lse'
    | 'grade-kin';
  export type EditDataIdWithReference = EditDataId | EditDataIdReference;

  export type MetaDataTags = {
    [key in MetaDataTagField]: string[];
  };

  export interface FieldSummaryParams<SchoolType> {
    lesson: MetaDataParams;
    schoolType?: SchoolType;
    fieldName: EditDataIdWithReference;
    maxLength?: number;
    excludeUnits?: boolean;
    defaultValue?: string | false;
  }

  export interface FieldOption {
    id: string | number;
    value: string;
    short?: string;
  }

  export interface SystemDataField {
    id: SystemDataFieldLabel;
    lessonProp?: EditDataId;
    /** use this to group fields based on EditData */
    reference?: EditDataIdWithReference;
    type?: string;
    label?: string | (() => string);
    labeler?: 'LabelValue' | 'ValueLabel' | 'ValueOnly';
    placeholder?: () => string;
    options?: FieldOption[];
    save?: (params: { target: Lesson; field: SystemDataField; value: any; multiple: boolean; shortList: any }) => any;
    useOnlyForYears?: boolean;
    optionOrderIsSet?: boolean;
    schoolType?: string;
    subscribe?: () => any;
    query?: (a: string) => any;
  }

  export type SystemDataDict<SchoolType extends string> = {
    [type in SchoolType]: SystemData<type>;
  };

  export interface FieldSuggestionParams<SchoolType> {
    lesson?: Lesson;
    schoolType?: SchoolType;
    fieldName: EditDataIdWithReference;
  }

  type SystemDataParams<SchoolType> = {
    schoolType: SchoolType;
    fieldName: EditDataId;
  };

  export type YearDict = Record<
    number,
    {
      label: string;
    }
  >;
}

export const getEduSystem = EduSystem.get;

export function formatSchoolType(edusystem: EduSystem.System, schoolTypeId: string) {
  const schoolType = edusystem.getSchoolTypes().find((schoolType) => schoolType.id === schoolTypeId);
  return schoolType ? schoolType.label : '';
}

export function formatSchoolTypeByCountry(country: string, schoolTypeId: string) {
  const edusystem = EduSystem.get(country);
  return formatSchoolType(edusystem, schoolTypeId);
}

function getFieldOptions(edusystem: EduSystem.System, fieldName: EduSystem.EditDataId, schoolType: string | undefined) {
  const fieldData = edusystem.getSystemDataField({ schoolType, fieldName });

  if (!fieldData) {
    // Not every schoolType has levels
    return [];
  }

  return fieldData.options || [];
}

export function formatLevel(
  edusystem: EduSystem.System,
  schoolTypeId: SchoolType | undefined,
  levelId: string
): string | undefined {
  if (!schoolTypeId) return;
  return edusystem.fieldLabel(schoolTypeId, 'levels', levelId);
}

export function getSchoolTypePrefix(country: string, schoolTypeId: EduSystem.AllSchoolTypes | string) {
  const edusystem = EduSystem.get(country);
  const schoolType = edusystem.getSystemData({ schoolType: schoolTypeId });
  return schoolType ? schoolType.prefix : '';
}

export function formatYears(
  edusystem: EduSystem.System,
  schoolTypeId: string | undefined,
  yearId: number | string
): string | undefined {
  if (!schoolTypeId) return;
  return edusystem.fieldLabel(schoolTypeId, 'years', yearId);
}

export function schooltypeHasLevels(country: string, schoolType: SchoolType) {
  const eduSystem = EduSystem.get(country);

  if (!eduSystem) return false;

  return !!getFieldOptions(eduSystem, 'levels', schoolType).length;
}

function allOptionsForSchoolTypes(country: string, fieldName: EduSystem.EditDataId, schoolTypes: SchoolType[]) {
  const eduSystem = EduSystem.get(country);
  if (!eduSystem) return [];
  const ages = schoolTypes.map((type) => getFieldOptions(eduSystem, fieldName, type));
  const options = uniqWith(ages.flat(), isEqual) as EduSystem.FieldOption[];
  return options;
}

export function allYearsForSchoolTypes(country: string, schoolTypes: SchoolType[]) {
  return allOptionsForSchoolTypes(country, 'years', schoolTypes);
}

export function anyToEditDataId(value: any): EduSystem.EditDataId | undefined {
  const allEditDataIds: EduSystem.EditDataId[] = [
    'schoolType',
    'levels',
    'durationInMin',
    'subjects',
    'themes',
    'years',
  ];

  if (!allEditDataIds.includes(value)) return;

  return value as EduSystem.EditDataId;
}
