import { isArray, isObject } from 'lodash';

/**
 * This function returns an object where all arrays have their `null` values removed
 * we optimized this method to do as least few loops as possible
 */
export function omitDeepNullFromArrays<T>(obj: T): NonNullArrays<T>;
export function omitDeepNullFromArrays<T>(obj: T | null): NonNullArrays<T> | null {
  if (obj === null) return null;

  if (isArray(obj)) {
    const newArray = obj.reduce<unknown[]>((acc, item) => {
      if (item !== null) acc.push(omitDeepNullFromArrays(item));
      return acc;
    }, []);

    return newArray as unknown as NonNullArrays<T>;
  }

  if (isObject(obj)) {
    const newObj = Object.entries(obj).reduce<Record<string, unknown>>((acc, entry) => {
      const [key, value] = entry;
      acc[key] = omitDeepNullFromArrays(value);
      return acc;
    }, {});

    return newObj as NonNullArrays<T>;
  }

  return obj as unknown as NonNullArrays<T>;
}

/** Type helper to recursively removes null value from arrays */
export type NonNullArrays<T> = T extends (infer Member)[]
  ? NonNullable<NonNullArrays<Member>>[]
  : T extends never
  ? any /* eslint-disable-line @typescript-eslint/no-explicit-any */ // this actually convers prop: any to prop: any, see https://stackoverflow.com/questions/61624719/how-to-conditionally-detect-the-any-type-in-typescript
  : {
      [Property in keyof T]: NonNullArrays<T[Property]>;
    };
