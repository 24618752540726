import { boxFromLine, isBoxLayout, isLineLayout, OnMouseDownHandler, PinComponent } from '@lessonup/pin-renderer';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { BoxLayoutGrippies } from '../BoxLayoutGrippies/BoxLayoutGrippies';
import { LineLayoutGrippies } from '../LineLayoutGrippies/LineLayoutGrippies';

interface ActiveComponentBoxProps {
  active: PinComponent;
  scale: number;
  onMouseDown: OnMouseDownHandler;
  isPinComponentHovered: boolean;
}

export const ActivePinComponentBox: React.FC<ActiveComponentBoxProps> = ({
  active,
  scale,
  onMouseDown,
  isPinComponentHovered,
}) => {
  const showOutline = isBoxLayout(active.layout);
  const box = isLineLayout(active.layout) ? boxFromLine(active.layout) : active.layout;
  return (
    <StyledBox
      showOutline={showOutline}
      style={{
        width: box.size.width * scale,
        height: box.size.height * scale,
        transform: `translate(${box.position.x * scale}px, ${box.position.y * scale}px)  rotate(${box.rotation}deg)`,
      }}
    >
      {!active.settings.lockLayout && isBoxLayout(active.layout) && (
        <BoxLayoutGrippies active={active} onMouseDown={onMouseDown} isPinComponentHovered={isPinComponentHovered} />
      )}
      {!active.settings.lockLayout && isLineLayout(active.layout) && (
        <LineLayoutGrippies scale={scale} active={active} onMouseDown={onMouseDown} />
      )}
    </StyledBox>
  );
};

const StyledBox = styled.div<{ showOutline: boolean }>`
  position: absolute;
`;
