import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { PlayerBarBottomSection } from '../../../../components/PlayerBar/PlayerBarBottom/components/PlayerBarBottomModules';
import { PlayerBarBottomNavigation } from '../../../../components/PlayerBar/PlayerBarBottom/components/PlayerBarBottomNavigation';
import { PlayerBarBottomPositioning } from '../../../../components/PlayerBar/PlayerBarBottom/components/PlayerBarBottomPositioning';
import { PlayerBarBottom } from '../../../../components/PlayerBar/PlayerBarBottom/PlayerBarBottom';
import { PlayerBarBottomHandlerProps } from './PlayerBarBottomHandler.utils';

export const PreviewPlayerBarBottomHandler: React.FC<PlayerBarBottomHandlerProps> = ({
  onNextClick,
  onPreviousClick,
  activePinId,
  pins,
  onPinClick,
  showLabels,
  navState,
}) => {
  const minimizedBar = useBoolean(false);
  const mirroredBar = useBoolean(false);
  const isMirrored = minimizedBar.value && mirroredBar.value;

  return (
    <PlayerBarBottom minimized={minimizedBar.value} mirrored={isMirrored}>
      <PlayerBarBottomSection mirrored={isMirrored}>
        <PlayerBarBottomNavigation
          onNextClick={onNextClick}
          onPinClick={onPinClick}
          onPreviousClick={onPreviousClick}
          activePinId={activePinId}
          pins={pins}
          navState={navState}
        />
      </PlayerBarBottomSection>
      <PlayerBarBottomSection mirrored={isMirrored}>
        <PlayerBarBottomPositioning
          mirrored={isMirrored}
          minimized={minimizedBar.value}
          toggleMirror={mirroredBar.toggle}
          toggleMinimize={minimizedBar.toggle}
          showLabels={showLabels}
        />
      </PlayerBarBottomSection>
    </PlayerBarBottom>
  );
};
