import { BodyText, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { PropsWithChildren } from 'react';
import { ANSWER_MAX_HEIGHT } from '../../../../utils';
import {
  QuizPinAnswerIndicators,
  QuizPinAnswerIndicatorsProps,
} from '../QuizPinAnswerIndicators/QuizPinAnswerIndicators';

export interface QuizPinAnswerCardStaticContentProps {
  index: number;
  answerStatus: QuizPinAnswerIndicatorsProps['answerStatus'];
}

export const QuizPinAnswerCardStaticContent: React.FC<PropsWithChildren<QuizPinAnswerCardStaticContentProps>> = ({
  index,
  answerStatus,
  children,
}) => {
  return (
    <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center" padding={spacing.size8}>
      <QuizPinAnswerIndicators index={index} answerStatus={answerStatus} />
      <StyledAnswerText size="medium">{children}</StyledAnswerText>
    </SpaceBetween>
  );
};

const StyledAnswerText = styled(BodyText)`
  padding: ${spacing.size0} ${spacing.size4};
  max-height: ${ANSWER_MAX_HEIGHT};
  overflow: hidden;
  word-break: break-word;
`;
