import { newImagePinComponent, Pin, PinComponent, Size, useUpdatePin } from '@lessonup/pin-renderer';
import { toast } from '@lessonup/ui-components';
import { slice } from 'lodash';
import { useCallback } from 'react';
import { useEditorContext } from '../context/EditorContext/EditorContext';
import { createStaggeredPinComponent } from '../utils/pin/pin.utils';
import {
  computeImageSizeFromFile,
  computeImageSizeFromUrl,
} from '../utils/pinComponentSettings/imagePinComponentSettings';
import { useEditorTranslation } from './useEditorTranslation';
import { useEditorUploadFile } from './useEditorUploadFile';

export function useCreateImagePinComponents() {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const { setModeSelecting, uploadDispatch } = useEditorContext();
  const performEditorUpload = useEditorUploadFile();

  return useCallback(
    async (pin: Pin, source: { urls: string[]; files?: undefined } | { urls?: undefined; files: File[] }) => {
      if (source.urls) {
        const pinComponents = await processPinComponentsToAdd(pin, source.urls, computeImageSizeFromUrl, (url) => url);

        dispatch({
          type: 'addPinComponents',
          pinComponents,
          onComplete: (pinComponentsIds) => {
            setModeSelecting(pinComponentsIds);
          },
        });

        return;
      }

      if (source.files) {
        const validSourceFiles = source.files.filter((file) => file.type.startsWith('image/'));
        if (validSourceFiles.length < source.files.length) {
          toast({
            message: t('uploads.invalidSource.description'),
            type: 'error',
            options: {
              autoClose: false,
            },
          });
        }

        if (validSourceFiles.length > 10) {
          toast({
            title: t('uploads.limitWarning.title'),
            message: t('uploads.limitWarning.description'),
            type: 'neutral',
            options: {
              autoClose: false,
            },
          });
        }

        const files = slice(validSourceFiles, 0, 10);

        const pinComponentsToAdd = await processPinComponentsToAdd(pin, files, computeImageSizeFromFile, () => '');

        uploadDispatch(
          pinComponentsToAdd.map((pinComponent) => ({
            action: 'CreateOptimisticUpload',
            optimistic: {
              type: 'ImagePinComponentUrl',
              pinId: pin.id,
              pinComponentId: pinComponent.id,
            },
          }))
        );

        dispatch({
          type: 'addPinComponents',
          pinComponents: pinComponentsToAdd,
          onComplete: (pinComponentsIds) => {
            setModeSelecting(pinComponentsIds);
            files.forEach(async (file, index) => {
              performEditorUpload(
                file,
                {
                  type: 'ImagePinComponentUrl',
                  pinId: pin.id,
                  pinComponentId: pinComponentsIds[index],
                },
                { skipUndoManager: true }
              );
            });
          },
        });
      }
    },
    [dispatch, performEditorUpload, setModeSelecting, t, uploadDispatch]
  );
}

async function processPinComponentsToAdd<T>(
  pin: Pin,
  sources: T[],
  computeImageSize: (s: T) => Promise<Size>,
  urlResolver: (s: T) => string
) {
  const createdPinComponents: PinComponent[] = [];

  return await Promise.all(
    sources.map(async (source) => {
      const size = await computeImageSize(source);
      const pinComponent = createStaggeredPinComponent(
        [...pin.pinComponents, ...createdPinComponents],
        newImagePinComponent,
        {
          url: urlResolver(source),
          size,
        }
      );
      createdPinComponents.push(pinComponent);
      return pinComponent;
    })
  );
}
