import { CookieSettings } from '../../cookies/analytic-cookies';
import { TrackerDestination } from './TrackerDestination';

export class TwitterDestination implements TrackerDestination {
  private isConfigLoaded: boolean = false;
  private readonly twitterId = 'o44kx'; // same for all environments

  public constructor() {}

  private loadConfig(): void {
    if (this.isConfigLoaded) return;

    // we load the script like this instead of pasting the script tag in the html
    // because we only want to load this after the cookie settings have been loaded
    // hubspot doesn't have a way to track the user anonymized.
    // also we changed this script to load async to prevent blocking the page load
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.defer = true;
    el.innerHTML = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config', '${this.twitterId}')
    `;

    document.body.appendChild(el);

    this.isConfigLoaded = true;
  }

  public init(cookieSettings: CookieSettings | undefined): void {
    if (cookieSettings?.marketing === true) {
      this.loadConfig();
    }
  }

  public pause(paused: boolean): void {}

  public updateConsent(cookieSettings: CookieSettings): void {
    // if the config wasn't loaded before because we were missing cookies, then we load it when the user gives consent
    // note that consent must be set before loading the config
    if (cookieSettings.marketing === true) {
      this.loadConfig();
    }
  }

  public trackPageView(): void {
    // not needed
  }

  public trackError(message: string, error: Error | undefined): void {
    // noop
  }

  public setUserId(userId: string | null | undefined): void {
    // noop
  }

  public logout(): void {
    // noop
  }

  public logEvent(eventName: string, eventProperties: Record<string, any>): void {
    // we don't send events to twitter
  }
}
