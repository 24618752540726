import { boxFromLayout, boxRotatedVertices, pinSize, verticalExtremesFromVectors } from '@lessonup/pin-renderer';
import { Box, PinComponent } from '@lessonup/pins-shared';

export const MENU_HEIGHT = 40;
const MENU_VERTICAL_SPACING = 35;
const MENU_HEIGHT_SPACING = MENU_HEIGHT + MENU_VERTICAL_SPACING;

export const FAM_CLASS_NAME = 'floating-action-menu';

export function calculateVerticalDirection(pinComponent: PinComponent): 'top' | 'bottom' {
  const box = boxFromLayout(pinComponent.layout);
  const { min: highestY } = verticalExtremesFromVectors(boxRotatedVertices(box));
  return highestY <= MENU_HEIGHT_SPACING ? 'bottom' : 'top';
}

export const getXPosition = (x: number, boxWidth: number, menuWidth: number, scale: number) => {
  const menuWidthSpacing = menuWidth / 2;
  const position = Math.min(
    Math.max((boxWidth / 2 + x) * scale, menuWidthSpacing),
    pinSize.width * scale - menuWidthSpacing
  );
  return position;
};

export const getYPosition = (box: Box, scale: number) => {
  const { max: lowestY, min: highestY } = verticalExtremesFromVectors(boxRotatedVertices(box));

  const yPositionAbove =
    highestY <= MENU_HEIGHT_SPACING ? { y: lowestY, positionedAbove: false } : { y: highestY, positionedAbove: true };

  const spacing =
    yPositionAbove.positionedAbove || lowestY + MENU_HEIGHT_SPACING > pinSize.height
      ? -MENU_HEIGHT_SPACING
      : MENU_VERTICAL_SPACING;

  const position = Math.min(Math.max(yPositionAbove.y, 0), pinSize.height) * scale + spacing;
  return position;
};

export const getMenuWidth = (ref: React.RefObject<HTMLDivElement | null>) => {
  return ref.current ? ref.current.offsetWidth : 0;
};
