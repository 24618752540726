import { Button, IconFolderAdd, useModal } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateNewFolderModal } from './CreateNewFolderModal';
import { i18nextNamespace } from './UploadsFeature.utils';

type CreateNewFolderProps = {
  parentId: string;
};

export function CreateNewFolder(props: CreateNewFolderProps) {
  const modal = useModal(CreateNewFolderModal);
  const { t } = useTranslation(i18nextNamespace);

  const onButtonClick = async () => {
    await modal.show(props);
  };
  return (
    <Button onClick={onButtonClick} buttonType="neutral" iconStart={<IconFolderAdd />}>
      {t('createNewFolder.newFolder')}
    </Button>
  );
}
