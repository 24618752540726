const releaseTagFormat = /\d+\.\d+(\.[\w-.]+)?/;

export const versionFromReleaseTag = (releaseTag: string): string => {
  const matchesReleaseTagFormat = releaseTag.match(releaseTagFormat);
  if (!matchesReleaseTagFormat) {
    console.warn("Release tag didn't match expected format");
    return releaseTag;
  }

  return matchesReleaseTagFormat[0];
};
