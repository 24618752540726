import { Pin, PinComponent } from '@lessonup/pins-shared';

export function getPinById(pinComponents: Pin[], pinComponentId: string): Pin | null {
  const pinComponent = pinComponents.find((pinComponent) => pinComponent.id === pinComponentId);

  if (!pinComponent) {
    return null;
  }

  return pinComponent;
}

export function getPinComponentById(pinComponents: PinComponent[], pinComponentId: string): PinComponent | null {
  const pinComponent = pinComponents.find((pinComponent) => pinComponent.id === pinComponentId);

  if (!pinComponent) {
    return null;
  }

  return pinComponent;
}

/**
 * Returns a boolean identifying if any of the pinComponentIds exist in the provided pinComponents list.
 */
export function areAnySelectedPinComponentsAvailable(
  pinComponents: PinComponent[],
  pinComponentIds: string[]
): boolean {
  return pinComponents.some((pinComponent) => pinComponentIds.includes(pinComponent.id));
}
