import { SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export const LessonModalMainWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledLessonModalMainWrapper>{children}</StyledLessonModalMainWrapper>;
};

export const LessonModalContent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <StyledLessonModalContent direction="y" spacing={spacing.size16}>
      {children}
    </StyledLessonModalContent>
  );
};

const StyledLessonModalMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const StyledLessonModalContent = styled(SpaceBetween)`
  padding: ${spacing.size0} ${spacing.size16};
  flex-grow: 1;
  height: 100%;
`;
