import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconVoucher = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.449 20.56c1.062.97 2.682 1.034 3.842.183.836-.613 1.674-1.262 2.432-2.02s1.407-1.596 2.02-2.432c.851-1.16.787-2.78-.183-3.842a115 115 0 0 0-8.696-8.57 2.8 2.8 0 0 0-1.314-.657c-1.78-.346-6.216-1.074-7.309.019s-.365 5.53-.019 7.309c.095.49.326.94.657 1.314a116 116 0 0 0 8.57 8.696"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.787 9.787a1.814 1.814 0 1 0-2.565-2.565 1.814 1.814 0 0 0 2.565 2.565"
    />
  </svg>
);
export default SvgIconVoucher;
