import { tracker } from '@lessonup/analytics';
import { logger, useDocumentQuery, useLocale } from '@lessonup/client-integration';
import {
  IconEdit,
  IconImport,
  IconRobot,
  ListItemProps,
  ManagedModal,
  ModalHeaderV1,
  NiceModal,
  SpaceBetween,
  spacing,
  styled,
  toast,
  useErrorContext,
  useModal,
} from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonLocation } from '../../../../types/graphql';
import { GeneratorInputType } from '../../../ChatGPTGenerator/MaiaGenerateLesson';
import { useConversionStatus } from '../../../SlideDeckImport/conversionStatus/useConversionStatus';
import { useUploadsStatus } from '../../../Uploads/hooks/useUploadsStatus';
import { useUploadStatusPolling } from '../../../Uploads/hooks/useUploadStatusPolling';
import { humandReadableFileSize } from '../../../Uploads/UploadsFeature.utils';
import { UploadRestrictionsDocument } from '../../Explorer.graphql.generated';
import { useUploadSlideDeckFile } from '../hooks/useUploadSlideDeckFile';
import { CreateLessonAside } from './components/CreateLessonAside';
import { EditorSelection } from './components/EditorSelection';
import { GenerateLesson } from './components/GenerateLesson';
import { ImportLesson } from './components/ImportLesson';
import { IntroScreen } from './components/IntroScreen';
import { MIN_MODAL_HEIGHT, MODAL_HEIGHT, MODAL_WIDTH } from './model';

export type EditorPreference = 'classic' | 'new' | 'generate' | 'import';

type CreateLessonModalResponse =
  | { type: 'lesson'; version: 'v1' | 'v2'; name: string }
  | { type: 'slideDeck'; name: string; lessonId: string; isWaitingOnConversion: boolean }
  | { type: 'maia'; generatorType: GeneratorInputType; version: 'v1' | 'v2' };

interface CreateLessonModalProps {
  lessonLocation?: LessonLocation;
  setEditorPreference: (preference: string) => void;
  editorPreference?: EditorPreference;
}

const CreateLessonModal = NiceModal.create(
  ({ lessonLocation, editorPreference, setEditorPreference }: CreateLessonModalProps) => {
    const modal = useModal();
    const { t } = useTranslation('createLessonModal');
    const locale = useLocale();

    // Temporary patch to set the classic editor as default, see BEE-733.
    // After the user has seen the intro screen, they will always start with the classic editor on following sessions.
    const [selectedLessonCreation, setSelectedLessonCreation] = useState<EditorPreference | undefined>(
      editorPreference ? 'classic' : undefined
    );
    const { setError: setToastError, clearError } = useErrorContext();
    const [invalidFileError, setInvalidFileError] = useState<string | undefined>();
    const [uploadInProgressId, setUploadInProgressId] = useState<string | undefined>();
    const [isConverting, setIsConverting] = useState<boolean>(false);
    const { startPollingForIds, stopPolling } = useUploadStatusPolling({
      onError: (error) => {
        setToastError({
          error,
          customErrorDisplayMessage: {
            title: t('errors.conversionFailedTitle'),
            description: t('errors.genericPolling'),
          },
        });
        logger.error(error, { context: 'CreateNewLessonModal slide deck conversion status polling' });
        setIsConverting(false);
      },
    });
    const { data: uploadRestrictions } = useDocumentQuery(UploadRestrictionsDocument, {
      fetchPolicy: 'cache-first',
    });

    const { setConversionStatus, removeConversionStatus } = useConversionStatus();

    const [performUpload] = useUploadSlideDeckFile({
      onError: (error) => setToastError({ error }),
    });

    useUploadsStatus({
      ids: uploadInProgressId ? [uploadInProgressId] : [],
      onAllCompleted: (data) => {
        setUploadInProgressId(undefined);
        setIsConverting(false);
        if (!data)
          throw new AppError('unexpected-data', 'Expected data about completed uploads', { uploadInProgressId });
        if (data.viewer.uploadsByIds.length !== 1)
          throw new AppError('unexpected-data', 'Expected exactly one upload to be completed', {
            uploadIds: data.viewer.uploadsByIds.map(({ id }) => id),
          });

        const upload = data.viewer.uploadsByIds[0];

        if (upload.__typename !== 'SlideDeckUpload') {
          throw new AppError('unexpected-data', 'Expected status of a SlideDeckUpload', { uploadId: upload.id });
        }

        tracker.events.lessonPowerpointConverted({
          lessonId: upload.lessonId || '',
          pinCount: upload.slideCount || 0,
          fileSizeInBytes: upload.originalFile.sizeInBytes.toString(),
        });

        modal.resolve({
          type: 'slideDeck',
          name: upload.name,
          isWaitingOnConversion: true,
          lessonId: upload.lessonId,
        });
        modal.hide();
      },
      onSingleDocumentError() {
        setToastError({
          error: null,
          customErrorDisplayMessage: {
            title: t('errors.conversionFailedTitle'),
            description: t('errors.singleConversionFailed'),
          },
        });
        unsetConversionStatus();
      },
    });

    const handleEditorSelection = (editor: EditorPreference) => {
      setSelectedLessonCreation(editor);
    };

    const onCreateLessonFromSlideDeck = async (name: string, file: File) => {
      setIsConverting(true);
      assertSlideDeckValidity(file);
      const uploadId = await performUpload({
        file,
        lessonName: name,
        lessonLocation,
      });
      setUploadInProgressId(uploadId);

      if (!uploadId) {
        setIsConverting(false);
        return modal.reject(
          new AppError('unexpected-data', 'No upload ID, upload not created correctly', { name, file })
        );
      }

      startPollingForIds([uploadId]);

      setConversionStatus({ uploadId, status: 'UPLOADING', name, createdAt: new Date() });
      modal.resolve({ name, type: 'slideDeck', isWaitingOnConversion: true });
      modal.hide();
    };

    const createManualLesson = (name: string, version: 'v1' | 'v2') => {
      setEditorPreference(version === 'v1' ? 'classic' : 'new');
      modal.resolve({ name, type: 'manual', version });
      modal.hide();
    };

    const assertSlideDeckValidity = (file: File) => {
      if (!uploadRestrictions) return;
      const tooBig = file.size > uploadRestrictions.viewer.maxFileSizeInBytesForUploads;

      if (tooBig) {
        setInvalidFileError(
          t('errors.fileSizeTooBig', {
            maxFileSizeInMB: humandReadableFileSize(uploadRestrictions.viewer.maxFileSizeInBytesForUploads, locale),
          })
        );
        return;
      }

      const fileTypeIsAllowed = uploadRestrictions?.viewer.acceptedMimeTypesForUploads.slideDeckTypes?.includes(
        file.type
      );

      if (!fileTypeIsAllowed) {
        setInvalidFileError(t('errors.fileTypeNotSupported'));
        return;
      }

      setInvalidFileError(undefined);
    };

    function onBeforeClose() {
      if (!isConverting) {
        return;
      }
      toast({ type: 'neutral', message: t('closeDuringConversion') });
      unsetConversionStatus();
    }

    function unsetConversionStatus() {
      if (uploadInProgressId) removeConversionStatus({ uploadId: uploadInProgressId });
      setIsConverting(false);
      stopPolling();
    }

    const classicEditorListItems: ListItemProps[] = [
      {
        text: t('blankLesson'),
        secondLine: t('blankLessonDescription'),
        icon: <IconEdit />,
        onClick: () => handleEditorSelection('classic'),
        type: selectedLessonCreation === 'classic' ? 'active' : 'default',
        roundedCorners: true,
        size: 'small',
      },
    ];

    const newEditorlistItems: ListItemProps[] = [
      {
        text: t('blankLesson'),
        secondLine: t('blankLessonDescription'),
        icon: <IconEdit />,
        onClick: () => handleEditorSelection('new'),
        type: selectedLessonCreation === 'new' ? 'active' : 'default',
        roundedCorners: true,
        size: 'small',
      },
      {
        text: t('generateLesson.title'),
        secondLine: t('generateLesson.description'),
        icon: <IconRobot />,
        onClick: () => handleEditorSelection('generate'),
        type: selectedLessonCreation === 'generate' ? 'active' : 'default',
        roundedCorners: true,
        size: 'small',
      },
    ];

    newEditorlistItems.push({
      text: t('importLesson'),
      secondLine: t('importLessonDescription'),
      icon: <IconImport />,
      onClick: () => handleEditorSelection('import'),
      type: selectedLessonCreation === 'import' ? 'active' : 'default',
      roundedCorners: true,
      size: 'small',
    });

    return (
      <ManagedModal
        modal={modal}
        contentLabel={selectedLessonCreation ? t('title') : t('intro.title')}
        onBeforeClose={onBeforeClose}
        width={MODAL_WIDTH}
        maxHeight={MODAL_HEIGHT}
        overflowHidden
      >
        {selectedLessonCreation ? (
          <SpaceBetween direction="x" spacing={spacing.size4}>
            <CreateLessonAside
              classicEditorListItems={classicEditorListItems}
              newEditorlistItems={newEditorlistItems}
            />
            <StyledSpaceBetween direction="y" spacing={spacing.size24}>
              <ModalHeaderV1
                type="headline"
                title={{ content: t('title') }}
                onCloseButtonClick={() => modal.hide()}
                showDivider={false}
                overflow="wrap"
              />
              {selectedLessonCreation === 'classic' && (
                <EditorSelection editorType="v1" modal={modal} createManualLesson={createManualLesson} />
              )}
              {selectedLessonCreation === 'new' && (
                <EditorSelection editorType="v2" modal={modal} createManualLesson={createManualLesson} />
              )}
              {selectedLessonCreation === 'import' && (
                <ImportLesson
                  modal={modal}
                  onCreateLessonFromSlideDeck={onCreateLessonFromSlideDeck}
                  invalidFileError={invalidFileError}
                  loading={isConverting}
                  onSelectFile={(file: File) => {
                    unsetConversionStatus();
                    clearError();
                    assertSlideDeckValidity(file);
                  }}
                />
              )}
              {selectedLessonCreation === 'generate' && <GenerateLesson modal={modal} editorType="v2" />}
            </StyledSpaceBetween>
          </SpaceBetween>
        ) : (
          <StyledSpaceBetween direction="y" spacing={spacing.size24}>
            <ModalHeaderV1
              type="headline"
              title={{ content: t('intro.title') }}
              onCloseButtonClick={() => modal.hide()}
              showDivider={false}
              overflow="wrap"
            />
            <IntroScreen setEditorPreference={setEditorPreference} handleEditorSelection={handleEditorSelection} />
          </StyledSpaceBetween>
        )}
      </ManagedModal>
    );
  }
);

export const showCreateLessonModal = (props: CreateLessonModalProps): Promise<CreateLessonModalResponse> => {
  return NiceModal.show(CreateLessonModal, props);
};

const StyledSpaceBetween = styled(SpaceBetween)`
  width: 100%;
  max-height: ${MODAL_HEIGHT};
  min-height: ${MIN_MODAL_HEIGHT};
  overflow-y: scroll;
  height: 80vh;
  flex: 1;
  overflow: auto;
  margin-left: 0 !important; // Override initial SpaceBetween margin
`;
