import { TeacherPin, TiptapViewer, toJSONContent } from '@lessonup/pin-renderer';
import {
  Button,
  Card,
  ComponentColorTheme,
  ComponentSize,
  IconZoomIn,
  SpaceBetween,
  spacing,
  styled,
  TextElement,
} from '@lessonup/ui-components';
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { usePlayerTranslation } from '../../hooks/usePlayerTranslation';
import { ASIDE_CONTENT_WIDTH } from '../presenterPlayer.utils';

export interface PresenterNoteProps {
  noteContent?: TeacherPin['teacherSettings']['notes'];
}

const NoteRenderer: React.FC<PresenterNoteProps> = ({ noteContent }) => {
  // TODO ED-624: This is a workaround as we needed to store the content as string to stay within the maximum depth limit in Firestore
  // Remove once we retrieve the JSON from GraphQL
  const content = typeof noteContent === 'string' ? toJSONContent(noteContent) : noteContent;
  if (content === null) {
    console.log('Error parsing JSON content', noteContent);
    return null;
  }

  return <TiptapViewer content={content} />;
};

export const PresenterNote: React.FC<PresenterNoteProps> = ({ noteContent }) => {
  const { t } = usePlayerTranslation();
  const colorSet: ComponentColorTheme = 'neutral';
  const enlargeText = useBoolean(false);
  const noteSize: ComponentSize = enlargeText.value ? 'large' : 'small';

  const toggleLargeText = () => {
    enlargeText.toggle();
  };

  return (
    <StyledCard cardTheme={colorSet} resizing="fixed" size="medium" width={ASIDE_CONTENT_WIDTH}>
      <SpaceBetween direction="y" spacing={spacing.size16} padding={spacing.size16}>
        <SpaceBetween direction="x" spacing={spacing.size16} justifyContent="space-between" alignItems="flex-start">
          <TextElement size="small" textStyle="bodyText" weight="bold">
            {t('presenterView.notes')}
          </TextElement>
          <Button
            buttonShape="rect"
            buttonType={colorSet}
            size="medium"
            iconEnd={<IconZoomIn />}
            onClick={toggleLargeText}
            active={enlargeText.value}
            showStroke
          >
            {t('presenterView.toggleLargeText')}
          </Button>
        </SpaceBetween>
        <StyledNoteContent size={noteSize} textStyle="bodyText">
          <NoteRenderer noteContent={noteContent} />
        </StyledNoteContent>
      </SpaceBetween>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  flex: 1;
  height: fit-content;
  overflow-y: auto;
`;

const StyledNoteContent = styled(TextElement)`
  overflow-y: auto;
`;
