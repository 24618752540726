import { BodyText, Button, ButtonAnchor, Headline, Link, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';

export type EmailVerificationSentProps = {
  emailAddress?: string;
  changeEmailLink: string;
  onTroubleshootClick: (emailAddress: string) => void;
};

export const EmailVerificationSent = (props: EmailVerificationSentProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'emailVerificationSentStep');

  const { emailAddress, changeEmailLink } = props;

  if (!emailAddress) {
    return (
      <div>
        <SpaceBetween direction="y" spacing={spacing.size32} alignItems="center">
          <Headline size="large">{t('error.title')}</Headline>
          <SpaceBetween direction="y" spacing={spacing.size16}>
            <BodyText>{t('error.body')}</BodyText>
            <Link href={changeEmailLink} passHref>
              <ButtonAnchor>{t('error.button')}</ButtonAnchor>
            </Link>
          </SpaceBetween>
        </SpaceBetween>
      </div>
    );
  }

  return (
    <div>
      <SpaceBetween direction="y" spacing={spacing.size32} alignItems="stretch">
        <StyledHeadline size="large">{t('title')}</StyledHeadline>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <BodyText>
            <Trans i18nKey={`${TRANSLATION_NAMESPACE}.emailVerificationSentStep.startUsing`} values={{ emailAddress }}>
              {t('startUsing', { emailAddress })}
            </Trans>
          </BodyText>
          <BodyText>{t('checkEmail')}</BodyText>
        </SpaceBetween>
        <SpaceBetween direction="y" spacing={spacing.size16}>
          <BodyText>{t('emailTroubleshootDescription')}</BodyText>
          <Button
            type="button"
            buttonType="neutral"
            showStroke={true}
            onClick={() => props.onTroubleshootClick(emailAddress)}
          >
            {t('openEmailTroubleshootModal')}
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  );
};

const StyledHeadline = styled(Headline)`
  text-align: center;
`;
