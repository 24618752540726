import { getI18nInstance } from '@lessonup/client-integration';
import { createLessonUpTemplate, TemplateTranslationDict, templateTranslationKeys } from '@lessonup/pin-renderer';

export const createTranslatedTemplate = () => {
  const i18n = getI18nInstance();

  if (!i18n) {
    throw new Error('i18n instance is not set in bridge');
  }

  const translations = templateTranslationKeys.reduce((acc, key) => {
    acc[key] = i18n?.t(`template.${key}`, { ns: 'editor' });
    return acc;
  }, {} as Record<string, string>);

  return createLessonUpTemplate(translations as TemplateTranslationDict);
};
