import { Box, CropArea, newVector, Size } from '@lessonup/pins-shared';
import { CSSProperties } from 'react';
import { cssPosition, cssSize, rotateVector, scaleSize, scaleVector, subtractVectors } from '../../utils';
import { Rectangle } from './geometry';

export interface CroppedImage {
  viewport: Box;
  cropArea: CropArea;
  fullImageRatio: number;
}

/** Computes the backdrop's position and size given the component's layout and
 * crop area. */
export function computeBackdrop(croppedImage: CroppedImage): Rectangle {
  const { viewport, fullImageRatio, cropArea } = croppedImage;
  const { focus, scale } = cropArea;

  const viewportRatio = viewport.size.width / viewport.size.height;

  // You can think of the backdrop size as the component's size multiplied by
  // `scale`, except expanded so that the result's aspect ratio matches
  // the given `fullImageRatio`.
  // This means that if the ratios don't match, only one dimension (either width
  // or height) equals the component's scaled size.
  const backdropSize: Size =
    viewportRatio > fullImageRatio
      ? // width is more constrained than height
        {
          width: viewport.size.width * scale,
          height: (viewport.size.width * scale) / fullImageRatio,
        }
      : // height is more constrained than width
        {
          width: viewport.size.height * scale * fullImageRatio,
          height: viewport.size.height * scale,
        };

  // Position of the viewport in the backdrop's coordinate system.
  const viewportPosition =
    scale > 1
      ? newVector(
          (backdropSize.width - viewport.size.width) * focus.x,
          (backdropSize.height - viewport.size.height) * focus.y
        )
      : newVector(
          backdropSize.width * focus.x - viewport.size.width / 2,
          backdropSize.height * focus.y - viewport.size.height / 2
        );

  // With the viewport position available in the coordinate systems of both the
  // backdrop and the entire canvas, we can now compute the backdrop position in
  // the canvas.
  const backdropPosition = subtractVectors(viewport.position, rotateVector(viewportPosition, viewport.rotation));

  return { position: backdropPosition, size: backdropSize };
}

/** Computes the crop area given the layouts of both the image component
 * (viewport) and backdrop. */
export function computeCropArea(viewport: Box, backdrop: Rectangle): CropArea {
  // Position of the viewport in the backdrop's coordinate system.
  const relativePosition = rotateVector(subtractVectors(viewport.position, backdrop.position), -viewport.rotation);

  const scale = Math.min(backdrop.size.width / viewport.size.width, backdrop.size.height / viewport.size.height);

  return {
    focus:
      scale > 1
        ? newVector(
            relativePosition.x / (backdrop.size.width - viewport.size.width),
            relativePosition.y / (backdrop.size.height - viewport.size.height)
          )
        : newVector(
            (relativePosition.x + viewport.size.width / 2) / backdrop.size.width,
            (relativePosition.y + viewport.size.height / 2) / backdrop.size.height
          ),
    scale,
  };
}

/**
 * Returns the CSS properties for the viewport's background, aligning with the
 * given backdrop.
 */
export function getViewportBackgroundStyle(viewport: Box, backdrop: Rectangle, pinScale: number): CSSProperties {
  const backgroundOffset = scaleVector(
    rotateVector(subtractVectors(backdrop.position, viewport.position), -viewport.rotation),
    pinScale
  );
  return {
    backgroundRepeat: 'no-repeat',
    backgroundSize: cssSize(scaleSize(backdrop.size, pinScale)),
    backgroundPosition: cssPosition(backgroundOffset),
  };
}
