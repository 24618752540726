import { intercomIsAvailable, openChatWindow } from '@lessonup/intercom';
import {
  Button,
  ErrorDisplayMode,
  fadeInKeyframe,
  IconWarning,
  Introduction,
  LoaderSpinner,
  rem,
  spacing,
  styled,
  useErrorContext,
} from '@lessonup/ui-components';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type RetryHandler = () => Promise<void>;

type DataLoaderErrorDisplayMode = 'introduction' | 'custom' | ErrorDisplayMode;

export interface DataLoaderProps<T> {
  data: T | undefined;
  dataRenderer: (data: T) => ReactNode;
  loading: boolean;
  /** overrides the default loader when data is being loaded */
  customLoader?: ReactNode;
  /** shows a loader when refetching data */
  showLoaderWhenRefetching?: boolean;
  error?: Error;
  /** display mode to be used when an error occurs and there is no data loaded yet */
  initialErrorDisplayMode?: DataLoaderErrorDisplayMode;
  /** display mode to be used when an error occurs when the data is already visible on the screen */
  errorAfterRefetchDisplayMode?: DataLoaderErrorDisplayMode;
  /** optional function which will be used to render the error when the error display mode(s) have been set to `custom`  */
  customErrorRenderer?: (error: Error, retry?: RetryHandler) => ReactNode;
  /** will be called when the user processes a retry button */
  retry?: RetryHandler;
  /** Classname is added to the first element the DataLoader returns, except when using a customLoader or customErrorRenderer. In those cases the className should be added manually. */
  className?: string;
}

/**
 * The DataLoader can be used to handle data & show loading/error states from a GraphQL query like `useDocumentQuery`.
 * The behavior can be tweaked with the props. See the variants in Storybook.
 */
export function DataLoader<T>({
  data,
  dataRenderer,
  loading,
  customLoader,
  showLoaderWhenRefetching,
  error,
  initialErrorDisplayMode = 'toast', // always show a toast as fallback on inital render, just to be sure the user always gets an error message
  errorAfterRefetchDisplayMode,
  customErrorRenderer,
  retry,
  className,
}: DataLoaderProps<T>) {
  const { setError } = useErrorContext();
  const { t } = useTranslation('dataLoader');

  useEffect(() => {
    // This could potentially be a state update, so should be wrapped in a useEffect
    if (error) {
      const displayMode = (data && errorAfterRefetchDisplayMode) || (!data && initialErrorDisplayMode);

      if (displayMode === 'toast' || displayMode === 'banner') {
        setError({ error, displayMode });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (
    data &&
    !(error && (errorAfterRefetchDisplayMode === 'custom' || errorAfterRefetchDisplayMode === 'introduction')) // also show data in some cases when having an error from refetching
  ) {
    return (
      <DataRendererWrapper className={className}>
        {dataRenderer(data)}
        {showLoaderWhenRefetching && loading && <StyledLoaderSpinner type="overlay" />}
      </DataRendererWrapper>
    );
  } else if (loading) {
    if (customLoader) return <>{customLoader}</>;
    return (
      <IntroductionAndLoaderSpinnerWrapper className={className}>
        <LoaderSpinner />
      </IntroductionAndLoaderSpinnerWrapper>
    );
  } else if (error) {
    if (initialErrorDisplayMode === 'introduction' || errorAfterRefetchDisplayMode === 'introduction') {
      return (
        <IntroductionAndLoaderSpinnerWrapper className={className}>
          <StyledIntroduction
            headline={t('errorIntroductionHeading')}
            bodyText={t('errorIntroductionBodyText')}
            alignment="center"
            size="M"
            buttonPrimary={
              retry ? (
                <Button buttonType="secondary" size="large" onClick={retry}>
                  {t('errorIntroductionButtonRetry')}
                </Button>
              ) : undefined
            }
            buttonSecondary={
              intercomIsAvailable() ? (
                <Button buttonType="neutral" showStroke size="large" onClick={() => openChatWindow()}>
                  {t('errorIntroductionButtonCustomerSupport')}
                </Button>
              ) : undefined
            }
            icon={<IconWarning />}
          />
        </IntroductionAndLoaderSpinnerWrapper>
      );
    } else if (
      (initialErrorDisplayMode === 'custom' || errorAfterRefetchDisplayMode === 'custom') &&
      customErrorRenderer
    ) {
      return <>{customErrorRenderer(error, retry)}</>;
    }
  }

  // intentionally empty
  return <></>;
}
const DataRendererWrapper = styled.div`
  position: relative;
`;

const StyledIntroduction = styled(Introduction)`
  max-width: ${rem('520px')};
`;

const IntroductionAndLoaderSpinnerWrapper = styled.div`
  padding-top: ${spacing.size32};
  padding-bottom: ${spacing.size32};
  display: flex;
  justify-content: center;
`;

const StyledLoaderSpinner = styled(LoaderSpinner)`
  opacity: 0;
  animation: 0.5s ${fadeInKeyframe} 2s forwards;
`;
