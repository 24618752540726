import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconStar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.28 4.35c.678-1.639 2.945-1.692 3.625-.055.471 1.133.83 2.3 1.192 3.825 1.6-.016 2.85.018 4.11.132 1.774.159 2.49 2.316 1.11 3.44-.781.636-1.62 1.224-2.67 1.883a.764.764 0 0 0-.321.88c.49 1.52.821 2.755 1.079 4.05.342 1.72-1.47 3.034-2.945 2.08-1.13-.73-2.157-1.571-3.42-2.771-1.252 1.152-2.273 1.97-3.39 2.696-1.483.964-3.335-.335-2.991-2.07.249-1.253.58-2.472 1.085-3.977a.765.765 0 0 0-.32-.892c-1.083-.675-1.943-1.277-2.75-1.94-1.358-1.116-.664-3.244 1.087-3.406 1.281-.118 2.56-.143 4.222-.105.437-1.488.83-2.638 1.298-3.77"
    />
  </svg>
);
export default SvgIconStar;
