import { orderableCompareFn, TeacherPin } from '@lessonup/pin-renderer';
import { clamp } from 'lodash';
import {
  getLearningPhaseNameFromOrder,
  getPreviousPhase,
  isFirstPhase,
  LearningPhaseOrder,
  learningPhaseOrderMap,
  LearningPhaseOrNoPhaseOrder,
} from '../../utils/learningPhases/LearningPhase';
import { Phase, PhaseAdd, PinAndPhase } from './model';

interface SortResponse {
  afterPinId: string | null;
  startOfPhase: string | null;
}

/**
 * Should return either the pinId that the activePin should be sorted after or the startOfPhase
 * @overId is the Id of the pin Or the phase that the activePin is being dragged over
 */
export const getSortAfterIdForActiveAndOverPin = (
  pinsAndPhases: PinAndPhase[],
  sortingPinId: string,
  overId: string | undefined
): SortResponse => {
  if (!overId) return fallBackResponse;
  const sortingPinIndex = pinsAndPhases.findIndex((pin) => pin.id === sortingPinId);
  const overIndex = pinsAndPhases.findIndex((pin) => pin.id === overId);
  let sortAfterId: string | undefined;
  if (sortingPinIndex < overIndex) {
    sortAfterId = overId;
  } else {
    sortAfterId = pinsAndPhases[overIndex - 1]?.id;
  }
  if (!sortAfterId) return fallBackResponse;
  const sortAfterItem = pinsAndPhases.find((pin) => pin.id === sortAfterId);
  if (sortAfterItem && isPhase(sortAfterItem)) {
    return {
      afterPinId: null,
      startOfPhase: sortAfterItem.number,
    };
  }
  return {
    afterPinId: sortAfterId,
    startOfPhase: null,
  };
};

export const pinsAndPhases = (
  pins: TeacherPin[],
  showPhases: boolean,
  sortingPinId: string | null,
  selectedPinIds: string[]
): PinAndPhase[] => {
  const phases: Phase[] = showPhases
    ? Object.values(learningPhaseOrderMap).map((phaseNumber) => phaseForLearningPhaseNumber(phaseNumber))
    : [];

  const sortedPinsAndPhases: PinAndPhase[] = [...pins, ...phases].sort(orderableCompareFn);
  sortedPinsAndPhases.push(phaseForAddToPhase(showPhases ? learningPhaseOrderMap.differentiate : 'g00')); //@TODO fix this

  // If we are sorting with a selected pin, we want to remove exclude selected pins from the list except the sorting pin
  const isSortingPinSelected = sortingPinId !== null && selectedPinIds.includes(sortingPinId);
  if (isSortingPinSelected) {
    const selectedWithoutSorting = selectedPinIds.filter((id) => id !== sortingPinId);
    return sortedPinsAndPhases.filter((pin) => !selectedWithoutSorting.includes(pin.id));
  }
  return sortedPinsAndPhases;
};

export const phaseForLearningPhaseNumber = (phaseNumber: LearningPhaseOrder): Phase => {
  const learningPhase = getLearningPhaseNameFromOrder(phaseNumber);
  return {
    id: learningPhase,
    type: 'Phase',
    number: phaseNumber,
    name: learningPhase,
    order: phaseNumber,
  };
};

const phaseForAddToPhase = (phaseNumber: LearningPhaseOrNoPhaseOrder): PhaseAdd => ({
  id: `add-${phaseNumber}`,
  type: 'PhaseAdd',
  number: phaseNumber,
});

export const isPhase = (pinOrPhase: PinAndPhase): pinOrPhase is Phase => pinOrPhase.type === 'Phase';
export const isPhaseAdd = (pinOrPhase: PinAndPhase): pinOrPhase is PhaseAdd => pinOrPhase.type === 'PhaseAdd';

export const phaseBefore = (phase: Phase): Phase => {
  const previousPhase = getPreviousPhase(phase.order);

  return phaseForLearningPhaseNumber(previousPhase);
};

const fallBackResponse: SortResponse = {
  afterPinId: null,
  startOfPhase: 'g00', //learningPhaseOrderMap.preparation,
};

type InitialHeightCalculation = {
  distanceFromTopInitialPin: number;
  itemsTotalHeight: number;
};

export const getInitialHeights = (items: PinAndPhase[], initialPinId: string | null): InitialHeightCalculation => {
  const index = Math.max(
    items.findIndex((item) => item.id === initialPinId),
    0
  );

  let distanceFromTopInitialPin = 0;
  let itemsTotalHeight = 0;
  for (let i = 0; i < items.length; i++) {
    if (i < index) {
      distanceFromTopInitialPin += getItemHeight(items, i);
    }
    itemsTotalHeight += getItemHeight(items, i);
  }
  return { distanceFromTopInitialPin, itemsTotalHeight };
};

const itemsTotalHeightBottomPadding = 50; // This includes the final plus button + a little extra buffer

/**
 * Get the initial scroll offset (scrollTop) for the virtual list
 * The initial offset can't be to far off otherwise the virtual list will start hiding items that are visible
 * */
export const initialScrollOffset = (
  { distanceFromTopInitialPin, itemsTotalHeight }: InitialHeightCalculation,
  pinRailDomHeight: number
): number => {
  const targetDistanceFromTop = pinRailDomHeight / 2 - 80; // Visual target is slightly above the middle of pin rail
  const idealScrollOffset = distanceFromTopInitialPin - targetDistanceFromTop;
  const maxScrollOffset = itemsTotalHeight + itemsTotalHeightBottomPadding - pinRailDomHeight;
  return clamp(idealScrollOffset, 0, maxScrollOffset);
};

export const pinItemHeight = 88;
const itemSpacing = 8;
const spacingBetweenPhaseAndAddButton = 25;
const phaseHeight = 24;
const addButtonHeightWithMargin = 32;
const collaboratorIndicatorHeight = 10; // We'll change this later when there is a design

// These are hard-coded because the height of the items is not constant,
// but we need to know the exact heights to compute the scroll positions
export const getItemHeight = (items: PinAndPhase[], index: number): number => {
  const pinOrPhase = items[index];
  let contentHeight = 70 + collaboratorIndicatorHeight;

  // Styling of phases will be changed later according to design, we'll update this later
  if (isPhase(pinOrPhase)) {
    contentHeight = isFirstPhase(pinOrPhase.number)
      ? phaseHeight
      : phaseHeight + spacingBetweenPhaseAndAddButton + addButtonHeightWithMargin;
  }

  // Height for the add pin button
  if (index === items.length - 1) {
    contentHeight = itemSpacing; // Prevents dragging beyond the last item
  }

  return contentHeight + itemSpacing;
};

export function findItemOrder(items: PinAndPhase[], activePinId: string | null): string | undefined {
  if (activePinId === null) {
    return;
  }

  const activePin = items.find((item) => item.id === activePinId);

  if (!activePin || !hasOrderKey(activePin)) {
    return;
  }

  return activePin.order;
}

export function findItemIndexByOrder(items: PinAndPhase[], activePinOrder: string | undefined): number | undefined {
  if (typeof activePinOrder === 'undefined') {
    return;
  }
  return items.findIndex((item) => 'order' in item && item.order === activePinOrder);
}

export function hasOrderKey(pin: PinAndPhase): pin is TeacherPin | Phase {
  return 'order' in pin;
}
