import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.01 15.196c0-1.255-1.072-2.206-2.733-2.206-1.66 0-2.732.951-2.732 2.206m8.66-5.083h2.268m-2.268 3.774h2.268M3.12 16.234c.11 1.438 1.282 2.553 2.723 2.628 4.18.216 8.135.216 12.314 0 1.44-.075 2.613-1.19 2.723-2.628.219-2.86.219-5.608 0-8.468-.11-1.438-1.282-2.553-2.723-2.628-4.18-.216-8.135-.216-12.314 0-1.44.075-2.613 1.19-2.723 2.628a55 55 0 0 0 0 8.468m6.156-3.244a2.173 2.173 0 1 0 0-4.346 2.173 2.173 0 0 0 0 4.346"
    />
  </svg>
);
export default SvgIconBadge;
