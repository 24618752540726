export class AudioContextHandler {
  private audioContext: AudioContext | null = null;

  private analyser: AnalyserNode | null = null;

  private microphone: MediaStreamAudioSourceNode | null;

  private scriptProcessor: ScriptProcessorNode | null;

  private onRecordingTick: null | ((volume: number) => void) = null;

  constructor(stream: MediaStream, onRecordingTick?: (volume: number) => void) {
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.microphone = this.audioContext.createMediaStreamSource(stream);
    this.scriptProcessor = this.audioContext.createScriptProcessor(2048, 1, 1); // TODO https://lessonup.atlassian.net/browse/UP-163: This method is deprecated and needs to be replaced
    this.onRecordingTick = onRecordingTick ?? null;

    this.analyser.smoothingTimeConstant = 0.8;
    this.analyser.fftSize = 1024;

    this.microphone.connect(this.analyser);
    this.analyser.connect(this.scriptProcessor);
    this.scriptProcessor.connect(this.audioContext.destination);
    this.scriptProcessor.addEventListener('audioprocess', () => {
      if (!this.analyser) {
        return;
      }
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      this.onRecordingTick?.(Math.round(average));
    });
  }

  public setOnRecordingTick(onRecordingTick: (volume: number) => void) {
    this.onRecordingTick = onRecordingTick;
  }

  public resetOnRecordingTick() {
    this.onRecordingTick = null;
  }

  destruct() {
    this.microphone?.disconnect();
    this.scriptProcessor?.disconnect();
    this.analyser?.disconnect();
  }
}
