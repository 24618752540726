import { Breadcrumb, BreadcrumbList, IconHome } from '@lessonup/ui-components';
import React, { PropsWithChildren, useContext } from 'react';
import { MyUploadFolder } from './Uploads.graphql';
import { UploadsContext } from './uploadsContext';

interface UploadsBreadcrumbsProps {
  breadcrumbs: MyUploadFolder['breadcrumbs'];
  title: string;
}

export function UploadsBreadcrumbList({ title, breadcrumbs }: PropsWithChildren<UploadsBreadcrumbsProps>) {
  const { dispatchNavigation } = useContext(UploadsContext);

  const breadcrumbList: Breadcrumb[] = breadcrumbs.map(({ id, name }, index) => ({
    label: name,
    onClick: () => dispatchNavigation({ type: 'folderId', id }),
    icon: index === 0 ? <IconHome /> : undefined,
  }));

  return <BreadcrumbList title={title} breadcrumbs={breadcrumbList} />;
}
