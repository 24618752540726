export type LearningPhaseName = 'preparation' | 'instruction' | 'practice' | 'evaluation' | 'differentiate';
export type LearningPhaseNumber = 1000 | 2000 | 3000 | 4000 | 5000;
export type NoLearningPhaseNumber = 0;
export type LearningPhaseOrNoPhaseNumber = LearningPhaseNumber | NoLearningPhaseNumber;

export const learningPhaseNumberMap: Record<LearningPhaseName, LearningPhaseNumber> = {
  preparation: 1000,
  instruction: 2000,
  practice: 3000,
  evaluation: 4000,
  differentiate: 5000,
};

export const learningPhaseNameMap: Record<LearningPhaseNumber, LearningPhaseName> = {
  1000: 'preparation',
  2000: 'instruction',
  3000: 'practice',
  4000: 'evaluation',
  5000: 'differentiate',
};

export const noPhaseNumber: NoLearningPhaseNumber = 0;

export const learningPhasesI18NNS = 'learningPhases';

export function isLearningPhaseNumber(value: LearningPhaseOrNoPhaseNumber | string): value is LearningPhaseNumber {
  return (
    typeof value === 'number' &&
    (Object.values(learningPhaseNumberMap) as LearningPhaseNumber[]).includes(value as LearningPhaseNumber)
  );
}
