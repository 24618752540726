import { assertNever, exhaustiveStringTuple, Locale } from '@lessonup/utils';
import { ReasonType } from './AccountDeletion.graphql';

export const reasons = exhaustiveStringTuple<ReasonType>()(
  'USING_OTHER_PROGRAMS',
  'FEATURES_TOO_COMPLICATED',
  'NOT_USING_OFTEN',
  'TECHNICAL_ISSUES',
  'COMPLETED_CLASS',
  'TOO_EXPENSIVE',
  'NOT_RELEVANT',
  'CHANGE_EMAIL',
  'CHANGE_SUBSCRIPTION',
  'OTHER_REASON'
);

export const helpCenterArticleUrl = ({ language }: Pick<Locale, 'language'>): string => {
  switch (language) {
    case 'nl':
      return 'https://help.lessonup.com/nl/articles/8570746';
    case 'fr':
    case 'en':
      return 'https://help.lessonup.com/en/articles/8570746';
    default:
      assertNever(language, 'No help center article about account deletion for this language');
  }
};
