import { useKeyboardEvent } from '@lessonup/client-integration';
import { FontLoader, PinPreview, TeacherPin, useInMemoryPinListReducer } from '@lessonup/pin-renderer';
import { PlayerStoreNavigationState } from '@lessonup/players-modern';
import { color, spacing, styled } from '@lessonup/ui-components';
import { isNull } from 'lodash';
import React, { useMemo } from 'react';
import { PreviewPlayerBarBottomHandler } from '../dataComponents/PlayerBarBottomHandler/PreviewPlayerBarBottomHandler';
import { PreviewPlayerBarTopHandler } from '../dataComponents/PlayerBarTopHandler/PreviewPlayerBarTopHandler';
import { useTimeline } from './hooks/useTimeline';

export interface PreviewProps {
  pins: TeacherPin[];
  activePinId?: string;
  onClose: () => void;
}

export const PreviewFeature: React.FC<PreviewProps> = ({ pins, activePinId, onClose }) => {
  return <Preview pins={pins} onClose={onClose} activePinId={activePinId} />;
};

export const Preview: React.FC<PreviewProps> = ({ pins: initialPins, activePinId, onClose }) => {
  const [pins] = useInMemoryPinListReducer(initialPins);
  const { currentIndex, currentPin, toPrev, toNext, toPin, total } = useTimeline(pins, activePinId);

  const navState: PlayerStoreNavigationState = useMemo(
    () => ({ hasNext: currentIndex < total - 1, hasPrevious: currentIndex > 0 }),
    [currentIndex, total]
  );

  const toPreviousSlide = (e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    toPrev();
  };

  const toNextSlide = async (e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    toNext();
  };

  useKeyboardEvent('keydown', {
    ArrowLeft: toPreviousSlide,
    ArrowUp: toPreviousSlide,
    PageUp: toPreviousSlide,
    ArrowRight: toNextSlide,
    ArrowDown: toNextSlide,
    PageDown: toNextSlide,
    Escape: onClose,
  });

  if (!total) {
    return null;
  }

  if (isNull(currentPin)) {
    return null;
  }

  return (
    <StyledFullscreenPlayerWrapper>
      <StyledPreviewPlayerWrapper>
        <FontLoader />
        <PreviewPlayerBarTopHandler
          currentSlideIndex={currentIndex}
          maxSlides={total}
          onClose={onClose}
          showLabels={false}
        />
        <StyledPinComponentWrapper>
          <PinPreview pin={currentPin} />
        </StyledPinComponentWrapper>
        <PreviewPlayerBarBottomHandler
          onNextClick={toNextSlide}
          onPreviousClick={toPreviousSlide}
          activePinId={currentPin.id}
          pins={pins}
          onPinClick={toPin}
          onClose={onClose}
          navState={navState}
          // TODO show label based on player settings
        />
      </StyledPreviewPlayerWrapper>
    </StyledFullscreenPlayerWrapper>
  );
};

/**
 * @description This wrapper is required for fullscreen to maintain a white background (like the site body has) due to our colors being rgba.
 */
const StyledFullscreenPlayerWrapper = styled.div`
  background: ${color.neutral.surface.background};
`;

const StyledPreviewPlayerWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${color.neutral.surface1.background};
`;

// The default of flex items is min-width: auto and min-height: auto.
// The resize observer in the PinPreview component will report the wrong bounding box with resize auto
const StyledPinComponentWrapper = styled.div`
  flex: 1;
  margin: ${spacing.size8};
  min-width: 0; //⬆
  min-height: 0; //⬆
`;
