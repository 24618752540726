import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.5 14.142c-1.478-1.804-2.43-2.784-4.03-4.114a.74.74 0 0 0-.94 0c-1.6 1.33-2.552 2.31-4.03 4.114"
    />
  </svg>
);
export default SvgIconChevronUp;
