import { Pagination } from '@lessonup/ui-components';
import React, { useContext } from 'react';
import { NavigationDispatchProps } from '../../hooks/useNavigationDispatcher';
import { UploadsContext } from '../../uploadsContext';

interface PaginationUploadsBaseProps {
  total: number;
  perPage: number;
  page: number;
}

interface PaginationUploadsProps extends PaginationUploadsBaseProps {
  dispatchNavigation: (value: NavigationDispatchProps) => void;
}

export const UploadsPagination: React.FC<PaginationUploadsProps> = ({ total, perPage, page, dispatchNavigation }) => {
  const handleNext = () => dispatchNavigation({ type: 'page', action: 'inc' });
  const handlePrevious = () => dispatchNavigation({ type: 'page', action: 'dec' });

  return (
    <Pagination
      currentRange={{ minimum: Math.min((page - 1) * perPage + 1, total), maximum: Math.min(page * perPage, total) }}
      totalItems={total}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
    />
  );
};

export const UploadsPaginationWithDispatch: React.FC<PaginationUploadsBaseProps> = (props) => {
  const { dispatchNavigation } = useContext(UploadsContext);
  return <UploadsPagination {...props} dispatchNavigation={dispatchNavigation} />;
};
