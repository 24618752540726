import { Identifiable, Modifiable, Nullable, OmitByType, omitDeepUndefined, Ownable } from '@lessonup/utils';
import _ from 'lodash';
import { ComponentRealtimeSettings } from '../common';
import { QueryData } from '../common/QueryData';
import { MigrationTarget } from '../migrations/MigrationTarget';
import { AssignmentType } from './AssignmentComplete';
import { AssignmentGradeModelSettings } from './AssignmentGradeModelSettings';

// TODO fix
interface NavigationStateProgress {
  current: number;
  total: number;
}

export type AssignmentSettingsType<T extends AssignmentType> = T extends 'realtime'
  ? AssignmentSettings.Realtime
  : T extends 'homework'
  ? AssignmentSettings.Homework
  : T extends 'test'
  ? AssignmentSettings.Test
  : AssignmentSettings;

export type AssignmentSettings = AssignmentSettings.Realtime | AssignmentSettings.Homework | AssignmentSettings.Test;

export namespace AssignmentSettings {
  export const excludedComparisonPaths: string[] = ['createdAt', 'modifiedAt', 'migratedAt'];

  export type Status = 'open' | 'closed';
  export type Visibility = 'all' | 'studentOnly';

  type CreationIgnoredTypes = Ownable & Identifiable & MigrationTarget & Modifiable;

  export type RealtimeCreationSettings = OmitByType<Realtime, CreationIgnoredTypes>;
  export type HomeworkCreationSettings = OmitByType<Homework, CreationIgnoredTypes>;
  export type TestCreationSettings = OmitByType<Test, CreationIgnoredTypes>;

  export type CreationSettings = RealtimeCreationSettings | HomeworkCreationSettings | TestCreationSettings;
  export type CreationSettingsTyped<T extends AssignmentType> = T extends 'realtime'
    ? RealtimeCreationSettings
    : T extends 'homework'
    ? HomeworkCreationSettings
    : T extends 'test'
    ? TestCreationSettings
    : CreationSettings;

  // settings for all
  interface BaseSettings extends Ownable, Identifiable, MigrationTarget, Modifiable {
    status: Status;
    visibility: Visibility;
  }

  // this is a object to make room for future settings
  export interface ShowFinalSlideSettings {
    showSlide: boolean;
  }

  export interface Homework extends BaseSettings {
    type: 'homework';
    showFinalSlide?: ShowFinalSlideSettings;
    restrictToGroup?: boolean;
    isLinear?: boolean;
  }

  export interface Test extends BaseSettings {
    type: 'test';
    lockStudents: boolean;
    randomiseQuestions: boolean;
    isLinear?: boolean;
    gradeModel?: AssignmentGradeModelSettings;
  }

  export interface Realtime extends BaseSettings {
    type: 'realtime';
    nextAssignmentId?: string;
    nextAssignmentVersion?: string;
    currentView: View;
    pincode: string | undefined;
    playerSettings: RealtimePlayerSettings;
    componentSettings?: ComponentRealtimeSettings;
  }

  export interface RealtimePlayerSettings {
    isSoundEnabled: boolean;
    allowConnectedDevices: boolean;
    isScreenShared: boolean;
    isPrepScreenEnabled: boolean;
    allowNewPlayers: boolean;
    autoShowRank: boolean;
    ignoreExclusions: boolean;
    isRaiseHandAllowed: boolean;
  }

  export interface View {
    pinId: string; // master pin
    foregroundPinId: string;
    params: QueryData | undefined;
    hasNext: boolean | undefined;
    hasPrevious: boolean | undefined;
    progress: NavigationStateProgress | undefined;
  }
}

export namespace AssignmentSettings {
  export function isHomework(
    assignmentSettings: AssignmentSettings
  ): assignmentSettings is AssignmentSettings.Homework {
    return assignmentSettings.type === 'homework';
  }

  export function isTest(assignmentSettings: AssignmentSettings): assignmentSettings is AssignmentSettings.Test {
    return assignmentSettings.type === 'test';
  }
  export function create<T extends AssignmentType = AssignmentType>(
    assignmentId: string,
    ownerId: string,
    createdAt: number,
    creationSettings: CreationSettingsTyped<T>
  ): AssignmentSettingsType<T> {
    const settings: AssignmentSettingsType<T> = {
      ...creationSettings,
      createdAt,
      modifiedAt: createdAt,
      ownerId,
      _id: assignmentId,
    } as AssignmentSettingsType<T>;
    return settings;
  }

  export function realtimeModeView(settings: AssignmentSettings | undefined): View | undefined {
    if (!settings || settings.type !== 'realtime' || _.isNil(settings.currentView)) {
      return undefined;
    }

    return settings.currentView;
  }

  export function assignmentIsLinear(settings: AssignmentSettings | undefined): boolean {
    return !!(settings && 'isLinear' in settings && settings.isLinear);
  }

  export function isRaiseHandsAllowed(settings: AssignmentSettings): boolean {
    return 'playerSettings' in settings && settings.playerSettings.isRaiseHandAllowed;
  }
}

export namespace AssignmentSettings {
  interface RealtimeUpdateData
    extends Nullable<Pick<AssignmentSettings.Realtime, 'currentView' | 'nextAssignmentId' | 'pincode' | 'status'>> {
    playerSettings?: Partial<RealtimePlayerSettings>;
    componentSettings?: Partial<ComponentRealtimeSettings>;
    params?: QueryData;
  }

  export type UpdateData = Partial<
    Pick<AssignmentSettings.Homework, 'visibility' | 'ownerId'> &
      Pick<AssignmentSettings.Test, 'gradeModel'> &
      RealtimeUpdateData
  >;

  export function updateFields(data: UpdateData): object {
    const updateFields: _.Dictionary<any> = {};

    if (!_.isNil(data.ownerId)) updateFields['ownerId'] = data.ownerId;
    if (!_.isUndefined(data.currentView)) updateFields['currentView'] = data.currentView;
    if (!_.isUndefined(data.params)) updateFields['currentView.params'] = data.params;
    if (!_.isUndefined(data.status)) updateFields['status'] = data.status;
    if (!_.isUndefined(data.pincode)) updateFields['pincode'] = data.pincode;
    if (!_.isUndefined(data.nextAssignmentId)) updateFields['nextAssignmentId'] = data.nextAssignmentId;
    if (!_.isUndefined(data.visibility)) updateFields['visibility'] = data.visibility;
    if (!_.isUndefined(data.gradeModel)) updateFields['gradeModel'] = data.gradeModel;

    if (data.playerSettings) {
      const {
        isScreenShared,
        isSoundEnabled,
        allowConnectedDevices,
        isPrepScreenEnabled,
        allowNewPlayers,
        autoShowRank,
        ignoreExclusions,
        isRaiseHandAllowed,
      } = data.playerSettings;
      if (!_.isNil(isScreenShared)) updateFields['playerSettings.isScreenShared'] = isScreenShared;
      if (!_.isNil(ignoreExclusions)) updateFields['playerSettings.ignoreExclusions'] = ignoreExclusions;
      if (!_.isNil(isSoundEnabled)) updateFields['playerSettings.isSoundEnabled'] = isSoundEnabled;
      if (!_.isNil(allowConnectedDevices)) updateFields['playerSettings.allowConnectedDevices'] = allowConnectedDevices;
      if (!_.isNil(allowNewPlayers)) updateFields['playerSettings.allowNewPlayers'] = allowNewPlayers;
      if (!_.isNil(isPrepScreenEnabled)) updateFields['playerSettings.isPrepScreenEnabled'] = isPrepScreenEnabled;
      if (!_.isNil(autoShowRank)) updateFields['playerSettings.autoShowRank'] = autoShowRank;
      if (!_.isNil(isRaiseHandAllowed)) updateFields['playerSettings.isRaiseHandAllowed'] = isRaiseHandAllowed;
    }

    if (data.componentSettings) {
      const { spinner } = data.componentSettings;
      if (spinner) {
        const { isSpinning, lastWinner } = spinner;
        if (!_.isNil(isSpinning)) updateFields['componentSettings.spinner.isSpinning'] = isSpinning;
        if (!_.isNil(lastWinner)) updateFields['componentSettings.spinner.lastWinner'] = lastWinner;
      }
    }

    const cleaned = omitDeepUndefined(updateFields);
    if (!_.isEmpty(cleaned)) {
      cleaned['modifiedAt'] = Date.now();
    }

    return cleaned;
  }
}
