import { DataLoader, useStaggeredDocumentQuery } from '@lessonup/client-integration';
import React from 'react';
import { TeachingCreditsIndicator } from './components/TeachingCreditsIndicator';
import { TeachingCreditsIndicatorController } from './TeachingCreditsIndicatorController';
import { TeachingCreditsDocument } from './TeachingCreditsIndicatorFeature.graphql.generated';
import { ShowAsType } from './TeachingCreditsIndicatorFeature.utils';

export const TeachingCreditsIndicatorFeature = (props: { showAs?: ShowAsType }) => {
  const { data, loading, error } = useStaggeredDocumentQuery(TeachingCreditsDocument, {
    fetchPolicy: 'cache-first',
  });

  return (
    <DataLoader
      data={data}
      loading={loading}
      customLoader={<TeachingCreditsIndicator loading={true} credits={-1} showAs={props.showAs} />}
      error={error}
      dataRenderer={(data) => <TeachingCreditsIndicatorController {...data} showAs={props.showAs} />}
    />
  );
};
