import { rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { PropsWithChildren } from 'react';
import { ImagePickerTab } from './imagePicker.types';

interface ImagePickerInputSectionProps {
  activeTab: ImagePickerTab;
  handleTabChange: (tab: ImagePickerTab) => void;
}

export const ImagePickerInputSection: React.FC<PropsWithChildren<ImagePickerInputSectionProps>> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activeTab,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleTabChange,
  children,
}) => {
  return (
    <StyledImagePickerInputSection>
      {/* Disabled tab section until we have the web results feature */}
      {/* <ModalHeader
        type="headline"
        tabList={[
          {
            onClick: () => handleTabChange('upload'),
            content: <IconCloudUpload width={16} height={16} />,
            isActive: activeTab === 'upload',
          },
          {
            onClick: () => handleTabChange('web'),
            content: <IconGlobe width={16} height={16} />,
            isActive: activeTab === 'web',
            disabled: true,
          },
        ]}
        showDivider={false}
      /> */}
      {/* <Divider orientation="horizontal" /> */}
      <SpaceBetween spacing={spacing.size0} direction="y">
        {children}
      </SpaceBetween>
    </StyledImagePickerInputSection>
  );
};

const StyledImagePickerInputSection = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem('300px')}; // TODO: replace when we implemented new font sizes
`;
