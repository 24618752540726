import { OnMouseEventHandler } from '@lessonup/pin-renderer';
import { borderRadius, color, elevationShadow, spacing, styled } from '@lessonup/ui-components';
import React, { useCallback } from 'react';

interface FloatingActionMenuContentProps {
  onMouseUp?: OnMouseEventHandler;
}

export const FloatingActionMenuContent: React.FC<React.PropsWithChildren<FloatingActionMenuContentProps>> = ({
  children,
  onMouseUp,
}) => {
  const handleMouseUp = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      /**
       * Due to the nature of our event implementation, and due to Firefox using onMouseUp to handle Select Input, this setTimeout is being used to avoid a race condition.
       */
      setTimeout(() => onMouseUp?.(event.nativeEvent), 0);
    },
    [onMouseUp]
  );

  return <StyledWrapper onMouseUp={handleMouseUp}>{children}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.size4};
  padding: ${spacing.size4};
  background: ${color.neutral.surface.background};
  box-shadow: ${elevationShadow.e200};
  border-radius: ${borderRadius.rounded8};
`;
