import { Pin } from '@lessonup/pins-shared';
import React from 'react';
import { PinSwitchStatic } from '../../PinViewerStatic/components/PinSwitchStatic';

export interface PinSwitchPreviewProps {
  pin: Pin;
  children?: React.ReactNode;
}

export const PinSwitchPreview: React.FC<PinSwitchPreviewProps> = ({ pin, children }) => {
  switch (pin.type) {
    default:
      return PinSwitchStatic({ pin, children });
  }
};
