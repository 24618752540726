import { PinCreateProperties, tracker } from '@lessonup/analytics';
import {
  newOpenQuestionPin,
  newPin,
  newQuizPin,
  newSlidePin,
  newWordCloudPin,
  OpenQuestionPinLayoutTemplate,
  PinViewerStatic,
  QuizPinLayoutTemplate,
  SlidePinLayoutTemplate,
  UpdatePinDispatch,
  WordCloudPinLayoutTemplate,
} from '@lessonup/pin-renderer';
import {
  BodyText,
  color,
  createModal,
  Grid,
  ManagedModal,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  NiceModalHandler,
  SpaceBetween,
  spacing,
  styled,
  Thumb,
  useModal,
} from '@lessonup/ui-components';
import { first } from 'lodash';
import React from 'react';
import { useFeatureFlags } from '../../../../../utils/featureFlags/useFeatureFlags';
import { useEditorTranslation } from '../../../hooks/useEditorTranslation';
import { isLearningPhaseOrder, LearningPhaseOrder } from '../../../utils/learningPhases/LearningPhase';
import { generateMaiaQuiz } from '../../../utils/maia/maia.utils';
import { createTranslatedTemplate } from '../../../utils/template/createTranslatedTemplate';

interface ModalProps {
  lessonId: string;
  dispatch: UpdatePinDispatch; // shortcut: got the error 'no dispatch for updatePinContext' when using useUpdatePin inside the modal. Since this is a temp solution, not needed to fix that challenge for now.
  setActivePinId: (pinId: string) => void;
  activePinId?: string | null;
  addBehindPinOrPhaseNumber: string | LearningPhaseOrder;
}

export const TempAddPinModal = createModal(
  ({ lessonId, dispatch, setActivePinId, addBehindPinOrPhaseNumber }: ModalProps) => {
    const modal = useModal();
    return (
      <ManagedModal modal={modal} contentLabel="temp" width="520px">
        <TempAddPinModalBody
          lessonId={lessonId}
          modal={modal}
          dispatch={dispatch}
          setActivePinId={setActivePinId}
          addBehindPinOrPhaseNumber={addBehindPinOrPhaseNumber}
        />
      </ManagedModal>
    );
  }
);

interface ModalPopoverProps {
  lessonId: string;
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: () => HTMLLIElement | HTMLButtonElement | null;
  dispatch: UpdatePinDispatch; // shortcut: got the error 'no dispatch for updatePinContext' when using useUpdatePin inside the modal. Since this is a temp solution, not needed to fix that challenge for now.
  setActivePinId: (pinId: string) => void;
  addBehindPinOrPhaseNumber: string | LearningPhaseOrder;
}

export const TempAddPinModalPopover = createModal(
  ({
    lessonId,
    direction,
    modalAnchor,
    parentSelector,
    dispatch,
    setActivePinId,
    addBehindPinOrPhaseNumber,
  }: ModalPopoverProps) => {
    const modal = useModal();

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="temp"
      >
        <TempAddPinModalBody
          lessonId={lessonId}
          modal={modal}
          dispatch={dispatch}
          setActivePinId={setActivePinId}
          addBehindPinOrPhaseNumber={addBehindPinOrPhaseNumber}
        />
      </ModalPopover>
    );
  }
);

interface TempAddPinModalBodyProps {
  modal: NiceModalHandler<Record<string, unknown>>;
  dispatch: UpdatePinDispatch;
  setActivePinId: (pinId: string) => void;
  addBehindPinOrPhaseNumber: string | LearningPhaseOrder;
  lessonId: string;
}

const PIN_WIDTH = 152;

export const TempAddPinModalBody = ({
  modal,
  dispatch,
  setActivePinId,
  addBehindPinOrPhaseNumber,
  lessonId,
}: TempAddPinModalBodyProps) => {
  const { t } = useEditorTranslation();
  const template = createTranslatedTemplate();
  const phaseNumber = isLearningPhaseOrder(addBehindPinOrPhaseNumber) ? addBehindPinOrPhaseNumber : undefined;
  const afterPinId = !isLearningPhaseOrder(addBehindPinOrPhaseNumber) ? addBehindPinOrPhaseNumber : undefined;
  const onAddNewPins = (pinIds: string[]) => {
    setActivePinId(pinIds[pinIds.length - 1]);
  };
  const { isFeatureEnabled } = useFeatureFlags();

  const addSlide = (layout: SlidePinLayoutTemplate) => {
    dispatch({
      type: 'addPins',
      endOfPhase: phaseNumber,
      pins: [newSlidePin(layout)],
      afterPinId: afterPinId,
      onComplete: onAddNewPins,
    });
    trackPinCreate('slide');
    modal.hide();
  };

  const addQuiz = (layout: QuizPinLayoutTemplate) => {
    dispatch({
      type: 'addPins',
      endOfPhase: phaseNumber,
      afterPinId: afterPinId,
      pins: [newQuizPin(layout)],
      onComplete: onAddNewPins,
    });
    trackPinCreate('quiz');
    modal.hide();
  };

  const startGenerateMaiaQuiz = (layout: QuizPinLayoutTemplate) => {
    generateMaiaQuiz(afterPinId, layout, dispatch, onAddNewPins, lessonId);
    modal.hide();
  };

  const maiaQuizQuestionTemplateLayout = first(template.layouts.quizPinLayouts);
  const maiaQuizQuestionLayoutPin =
    maiaQuizQuestionTemplateLayout && newPin(newQuizPin(maiaQuizQuestionTemplateLayout));

  const addOpenQuestion = (layout: OpenQuestionPinLayoutTemplate) => {
    dispatch({
      type: 'addPins',
      endOfPhase: phaseNumber,
      afterPinId: afterPinId,
      pins: [newOpenQuestionPin(layout)],
      onComplete: onAddNewPins,
    });
    trackPinCreate('open_question');
    modal.hide();
  };

  const addWordCloud = (layout: WordCloudPinLayoutTemplate) => {
    dispatch({
      type: 'addPins',
      endOfPhase: phaseNumber,
      afterPinId: afterPinId,
      pins: [newWordCloudPin(layout)],
      onComplete: onAddNewPins,
    });
    trackPinCreate('mind_map');
    modal.hide();
  };

  const wordCloudTemplateLayout = first(template.layouts.wordCloudLayouts);
  const wordCloudLayoutPin = wordCloudTemplateLayout && newPin(newWordCloudPin(wordCloudTemplateLayout));

  function trackPinCreate(pinType: PinCreateProperties['pinType']) {
    tracker.events.pinCreate({
      lessonId,
      pinType,
      pinCreationMethod: 'create_own',
      editorVersion: 'v2',
    });
  }

  return (
    <StyledModalContent direction="y" spacing={spacing.size16}>
      <BodyText size="medium" weight="bold">
        {t('layouts.informative')}
      </BodyText>

      <StyledLayoutList cols={3} gap={spacing.size16} as="ul">
        {template.layouts.slidePinLayouts.map((layout, index) => {
          if (layout.key === 'VIDEO_ONLY' && !isFeatureEnabled('editorVideoPinComponent')) {
            return;
          }
          const pin = newPin(newSlidePin(layout));

          return (
            <li key={`slide-${layout.key} ${index}`}>
              <Thumb
                thumbType="caption"
                number={index + 1}
                onClick={() => addSlide(layout)}
                caption={t(`layouts.slide.${layout.key}`)}
              >
                <PinViewerStatic pin={pin} width={PIN_WIDTH} isThumb />
              </Thumb>
            </li>
          );
        })}
      </StyledLayoutList>

      <BodyText size="medium" weight="bold">
        {t('layouts.interactive')}
      </BodyText>
      <StyledLayoutList cols={3} gap={spacing.size16} as="ul">
        {template.layouts.quizPinLayouts.map((layout, index) => {
          const pin = newPin(newQuizPin(layout));

          return (
            <li key={`quiz-${layout.key} ${index}`}>
              <Thumb
                thumbType="caption"
                number={index + 1}
                onClick={() => addQuiz(layout)}
                caption={`${t(`layouts.quiz.${layout.key}`)}`}
              >
                <PinViewerStatic pin={pin} width={PIN_WIDTH} isThumb />
              </Thumb>
            </li>
          );
        })}
        {maiaQuizQuestionLayoutPin && (
          <li key={`maia-quiz-${maiaQuizQuestionTemplateLayout.key}`}>
            <Thumb
              thumbType="caption"
              number={1}
              onClick={() => startGenerateMaiaQuiz(maiaQuizQuestionTemplateLayout)}
              caption={`${t('layouts.quiz.generateMaiaQuiz')}`}
            >
              <PinViewerStatic pin={maiaQuizQuestionLayoutPin} width={PIN_WIDTH} isThumb />
            </Thumb>
          </li>
        )}
        {template.layouts.openQuestionLayouts.map((layout, index) => {
          const pin = newPin(newOpenQuestionPin(layout));

          return (
            <li key={`open-question-${layout.key} ${index}`}>
              <Thumb
                thumbType="caption"
                number={index + 1}
                onClick={() => addOpenQuestion(layout)}
                caption={`${t(`layouts.openQuestion.${layout.key}`)}`}
              >
                <PinViewerStatic pin={pin} width={PIN_WIDTH} isThumb />
              </Thumb>
            </li>
          );
        })}
        {wordCloudLayoutPin && (
          <li key={`word-cloud-${wordCloudTemplateLayout.key}`}>
            <Thumb
              thumbType="caption"
              number={1}
              onClick={() => addWordCloud(wordCloudTemplateLayout)}
              caption={`${t(`layouts.wordCloud.${wordCloudTemplateLayout.key}`)}`}
            >
              <PinViewerStatic pin={wordCloudLayoutPin} width={PIN_WIDTH} isThumb />
            </Thumb>
          </li>
        )}
      </StyledLayoutList>
    </StyledModalContent>
  );
};

const StyledModalContent = styled(SpaceBetween)`
  background: ${color.neutral.surface1.background};
  padding: ${spacing.size16};
`;

const StyledLayoutList = styled(Grid)`
  margin: 0;
  padding: 0;
  list-style: none;
`;
