import { useLazyDocumentQuery } from '@lessonup/client-integration';
import { Exact } from '../../../types/graphql';
import { UploadStatusQuery } from '../Uploads.graphql.generated';

export const POLLING_TIME = 2000;
export const NETWORK_LIMIT_ERROR = 'network-error-count-exceeded';

type FetchVariables = Exact<{ ids: string | string[] }>;
export type FetchUploadStatus = ReturnType<typeof useLazyDocumentQuery<UploadStatusQuery, FetchVariables>>[0];
export type FetchQueryResults = ReturnType<typeof useLazyDocumentQuery<UploadStatusQuery, FetchVariables>>[1];

export class StatusPollingController {
  private fetchUploadStatusInstance: FetchUploadStatus | undefined;
  private lastPollingTimeout: ReturnType<typeof setTimeout> | undefined;
  private registeredHooks: Set<string> = new Set();
  private pollingIds: Set<string> = new Set();

  public start({
    hookId,
    fetchUploadStatus,
    uploadIds,
  }: {
    hookId: string;
    fetchUploadStatus: FetchUploadStatus;
    uploadIds: string[];
  }) {
    this.registeredHooks.add(hookId);
    uploadIds.forEach((id) => this.pollingIds.add(id));
    this.fetchUploadStatusInstance = this.fetchUploadStatusInstance || fetchUploadStatus;

    if (!this.lastPollingTimeout) this.startPolling(this.fetchUploadStatusInstance);
  }

  public stop(uploadIds?: string[]) {
    if (uploadIds) {
      uploadIds.forEach((id) => this.pollingIds.delete(id));
    } else {
      this.pollingIds.clear();
    }

    if (!this.pollingIds.size) {
      this.cleanUp();
    }
  }

  public unregister(hookId: string) {
    this.registeredHooks.delete(hookId);

    if (!this.registeredHooks.size) {
      this.cleanUp();
    }
  }

  private fetchStatusForCurrentIds(fetchUploadStatus: FetchUploadStatus) {
    return fetchUploadStatus({ variables: { ids: Array.from(this.pollingIds) } });
  }

  private startPolling(fetch: FetchUploadStatus) {
    if (!this.registeredHooks.size || !this.pollingIds.size) return this.cleanUp();
    this.fetchStatusForCurrentIds(fetch);

    this.lastPollingTimeout = setTimeout(() => {
      this.startPolling(fetch);
    }, POLLING_TIME);
  }

  private cleanUp() {
    this.lastPollingTimeout && clearTimeout(this.lastPollingTimeout);
    this.lastPollingTimeout = undefined;
    this.fetchUploadStatusInstance = undefined;
    this.registeredHooks.clear();
    this.pollingIds.clear();
  }
}
