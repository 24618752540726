import _ from 'lodash';
import {
  AnyCorrectAnswer,
  Assignment,
  AssignmentData,
  AssignmentMeta,
  AssignmentPin,
  AssignmentType,
  AssignmentUtils,
} from '../assignment';
import { DragQuestion, OpenQuestion, Pin, QuizQuestion } from '../common';
import { AnyAnswer, AnyAnswerResult, AnyPinEntry, AssignmentEntry } from '../entries';
import { CheckedAnswer } from './CheckedAnswer';
import { PinScore, Ranking, Score } from './Ranking';

export type HasNoCorrectAnswer = 'hasNoCorrectAnswer';

export class AnswerBook {
  private readonly assignment: Assignment;
  private readonly meta: AssignmentMeta;
  public readonly assignmentMode: AssignmentType;

  public constructor(data: AssignmentData) {
    this.assignment = data.assignment;
    this.meta = data.meta;
    this.assignmentMode = data.assignment.settings.type;
  }

  /** returns the correct answer for the given pin */
  public correctAnswerForPin<T extends AnyCorrectAnswer = AnyCorrectAnswer>(pinId: string): T | undefined {
    return AssignmentMeta.correctAnswerForPin(this.meta, pinId) as T;
  }

  public checkAnswer<T extends AnyAnswerResult = AnyAnswerResult>(
    pinId: string,
    answer: AnyAnswer | undefined
  ): T | undefined {
    const pin = AssignmentUtils.pin(this.assignment, pinId);
    if (!pin) return undefined;

    const getCorrectAnswer = this.correctAnswerForPin(pin._id);
    const hasCorrectAnswer = !_.isEmpty(getCorrectAnswer);

    switch (pin.item.type) {
      case 'openQuestion':
        if (!hasCorrectAnswer) return undefined;
        return OpenQuestion.Answer.resultForAnswer(
          answer as OpenQuestion.Answer | undefined,
          pin as Pin<OpenQuestion.AssignmentContent>,
          getCorrectAnswer as OpenQuestion.CorrectAnswer,
          this.assignmentMode
        ) as T;

      case 'question':
        const correctAnswer = hasCorrectAnswer
          ? (getCorrectAnswer as QuizQuestion.CorrectAnswer)
          : 'hasNoCorrectAnswer';
        return QuizQuestion.Answer.resultForAnswer(
          answer as QuizQuestion.Answer | undefined,
          correctAnswer,
          this.assignmentMode
        ) as T;

      case 'dragQuestion':
        if (!hasCorrectAnswer) return undefined;
        return DragQuestion.Answer.resultForAnswer(
          answer as DragQuestion.Answer | undefined,
          getCorrectAnswer as DragQuestion.CorrectAnswer
        ) as T;

      default:
        return undefined;
    }
  }

  // pointsForPinEntry(pin: AssignmentPin, pinEntry?: AnyPinEntry): number {
  //   if(!pin || !pinEntry) return 0;
  //   switch (pinEntry.type) {
  //     case 'openQuestion':
  //       return OpenQuestion.Answer.rankingPointsForResult(pinEntry.result);
  //     case 'question':
  //       return QuizQuestion.Answer.rankingPointsForResult(pinEntry.result);
  //     case 'dragQuestion':
  //       return DragQuestion.Answer.rankingPointsForResult(pinEntry.result);
  //     default:
  //       return 0;
  //   }
  // }

  public scoreForPinEntry(pin: AssignmentPin, pinEntry?: AnyPinEntry): PinScore {
    const noScore: PinScore = { points: 0, timeSpent: 3000 }; // time penalty?

    if (!pin || !pinEntry) return noScore;

    const seenAt = pinEntry.seenAt || 0;

    switch (pinEntry.type) {
      case 'openQuestion':
        return {
          points: OpenQuestion.Answer.rankingPointsForResult(pinEntry.result),
          timeSpent: (pinEntry.answer && pinEntry.answer.modifiedAt) || 0 - seenAt,
        };
      case 'question':
        return {
          points: QuizQuestion.Answer.rankingPointsForResult(pinEntry.result),
          timeSpent: (pinEntry.answer && pinEntry.answer.modifiedAt) || 0 - seenAt,
        };
      case 'dragQuestion':
        return {
          points: DragQuestion.Answer.rankingPointsForResult(pinEntry.result),
          timeSpent: (pinEntry.answer && pinEntry.answer.modifiedAt) || 0 - seenAt,
        };
      default:
        return noScore;
    }
  }

  public suggestedPointForEntry(pin: AssignmentPin, answer: AnyAnswer | undefined): number | undefined {
    if (!pin || !answer || !pin.item.testPoints) return 0;
    const maxPoints = pin.item.testPoints;

    switch (pin.item.type) {
      case 'openQuestion':
        return OpenQuestion.Answer.testPointsForResults(maxPoints, this.checkAnswer(pin._id, answer));
      case 'question':
        return QuizQuestion.Answer.testPointsForResult(maxPoints, this.checkAnswer(pin._id, answer));
      case 'dragQuestion':
        return DragQuestion.Answer.testPointsForResult(maxPoints, this.checkAnswer(pin._id, answer));
      default:
        return 0;
    }
  }

  // points for one entry
  public scoreForAssignmentEntry(entry: AssignmentEntry): Score {
    const pinScores = AssignmentUtils.unorderedPins(this.assignment).map((pin) => {
      const pinEntry = AssignmentEntry.entryForPin(entry, pin._id);
      return this.scoreForPinEntry(pin, pinEntry);
    });

    return {
      points: _.sumBy(pinScores, (s) => s.points),
      timeSpent: _.sumBy(pinScores, (s) => s.timeSpent),
      displayName: entry.displayName,
      userId: entry.userId,
    };
  }

  // map of studetns with scores, TODO: add time element
  public rankingForAssignmentEntries(entries: AssignmentEntry[]): Ranking {
    const scores: Score[] = entries.map((entry) => this.scoreForAssignmentEntry(entry));

    return {
      scores: _.orderBy(scores, ['points', 'timeSpent'], ['desc', 'asc']),
    };
  }

  // answers for all pins for all students
  public checkAssignmentEntries(entries: AssignmentEntry[]): CheckedAnswer[] {
    const pins = AssignmentUtils.pinsByAssignmentType(this.assignment);
    return _.flatMap(pins, (pin) => this.checkPinEntries(pin._id, entries));
  }

  // answers for all pins for one students
  public checkAssignmentEntry(entry: AssignmentEntry): CheckedAnswer[] {
    return this.checkAssignmentEntries([entry]);
  }

  // answer for one pin and all student
  public checkPinEntries(pinId: string, entries: AssignmentEntry[]): CheckedAnswer[] {
    const results = entries.map((entry) => this.checkPinEntry(pinId, entry));
    return _.compact(results);
  }

  // answer for one pin and student
  public checkPinEntry(pinId: string, entry: AssignmentEntry): CheckedAnswer | undefined {
    const pin = AssignmentUtils.pin(this.assignment, pinId);
    if (!pin) return undefined;
    const pinEntry = AssignmentEntry.entryForPin(entry, pin._id);
    const answer = AssignmentEntry.answerForPin(entry, pinId);
    const previousResult = AssignmentEntry.resultForPin(entry, pinId);
    const result = this.checkAnswer(pinId, answer);
    if (!result) {
      return undefined;
    }
    // fake complete answer
    if (pinEntry) {
      pinEntry.result = result;
    }
    const points = pin.item.testPoints ? this.suggestedPointForEntry(pin, pinEntry && pinEntry.answer) : undefined;

    return {
      answer,
      result,
      userId: entry.userId,
      previousResult,
      pinId: pin._id,
      pinType: pin.item.type,
      points,
    };
  }
}
