import {
  createModal,
  IconOpenInNew,
  IconPlayFill,
  ListItem,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopOverParentSelector,
  rem,
  spacing,
  styled,
  Tag,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { useEditorTranslation } from '../../../../hooks/useEditorTranslation';

export interface TeachDropdownProps {
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: ModalPopOverParentSelector;
  disableTeachButtons: boolean;
  handlePresenterMode: () => void;
  handleStartFromBeginning: () => void;
}

export const TeachDropdown = createModal(
  ({
    direction,
    modalAnchor,
    parentSelector,
    disableTeachButtons,
    handlePresenterMode,
    handleStartFromBeginning,
  }: TeachDropdownProps) => {
    const modal = useModal();
    const { t } = useEditorTranslation();

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="TeachDropdown"
      >
        <StyledModalContent>
          <ListItem
            icon={<IconOpenInNew />}
            text={t('teachDropdown.presenterMode')}
            secondLine={t('teachDropdown.presenterModeSecondLine')}
            disabled={disableTeachButtons}
            onClick={handlePresenterMode}
            endSlot={<Tag priority="success">{t('teachDropdown.new')}</Tag>}
          />
          <ListItem
            icon={<IconPlayFill />}
            text={t('teachDropdown.startFromBeginning')}
            disabled={disableTeachButtons}
            onClick={handleStartFromBeginning}
          />
        </StyledModalContent>
      </ModalPopover>
    );
  }
);

const StyledModalContent = styled.div`
  padding: ${spacing.size8};
  width: ${rem('256px')};
`;
