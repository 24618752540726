import { SpinnerPinComponentRenderer } from '@lessonup/pin-renderer';
import { Card, colorSets, Introduction, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../../hooks/usePlayerTranslation';

export interface PlayerComponentsMenuProps {
  openNewSpinner(): void;
}

export function PlayerComponentsMenu({ openNewSpinner }: PlayerComponentsMenuProps) {
  const { t } = usePlayerTranslation();

  return (
    <StyledPopoverBody>
      <Card onClick={openNewSpinner}>
        <SpaceBetween spacing={spacing.size8} padding={spacing.size8} direction="y">
          <StyledCutoff>
            <StyledCutoffInner>
              <SpinnerPinComponentRenderer />
            </StyledCutoffInner>
          </StyledCutoff>
          <Introduction
            headline={t('playerComponentsMenu.spinnerTitle')}
            bodyText={t('playerComponentsMenu.spinnerDescription')}
            alignment="start"
            size="S"
          ></Introduction>
        </SpaceBetween>
      </Card>
    </StyledPopoverBody>
  );
}

const StyledPopoverBody = styled.div`
  padding: ${spacing.size8};
`;

const StyledCutoff = styled.div`
  background-color: ${colorSets.neutral.fillSubjacent};
  height: 122px;
  padding: ${spacing.size16};
  overflow: hidden;
`;

const StyledCutoffInner = styled.div`
  width: 180px;
  height: 180px;
`;
