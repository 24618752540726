import { styled } from '@lessonup/ui-components';
import React from 'react';
import { pinSize } from '../../foundations/layout/pinDefault';

export interface PinCentererProps {
  scale: number;
  resizeObserverInitialized: boolean;
  children: React.ReactNode;
}

export const PinScaleWrapper: React.FC<PinCentererProps> = ({ scale, resizeObserverInitialized, children }) => {
  return (
    <StyledCenterer
      style={{
        width: pinSize.width * scale,
        height: pinSize.height * scale,
        // visibility is set to hidden until the scale is initialized to prevent flickering
        // return null was not used because it messed up some refs that need to be set first render
        visibility: resizeObserverInitialized ? 'visible' : 'hidden',
      }}
    >
      {children}
    </StyledCenterer>
  );
};

const StyledCenterer = styled.div`
  position: relative;
`;
