import { Box, newLine, newVector, PinComponent, PinComponentLayout, Vector } from '@lessonup/pins-shared';
import { pinSize } from '../../foundations/layout/pinDefault';
import { ArrangedRailLayouts, RailLayout } from '../../types/helpLines/helpLines.types';
import { isBoxLayout } from '../../types/pinComponents/pinComponentLayouts';
import {
  isComponentGroupTransformAction,
  isComponentTransformAction,
  isSelectAction,
  isSelectPinComponentAction,
  PinTransformAction,
} from '../../types/pinComponents/pinTransformActions';
import {
  boxFromLine,
  boxIntersectionWithBox,
  boxIntersectionWithLineSegment,
  divideVector,
  keepPositionBetweenBounds,
  subtractVectors,
} from '../../utils/geometry';
import { transformComponentGroup, transformPinComponent } from '../../utils/pinComponents/pinComponentActions';
import { MouseEventState } from './useHandleMouseEvents.types';

export function createMouseEventStateForMouseDown(
  event: React.MouseEvent<HTMLDivElement>,
  action: PinTransformAction,
  pinRendererOffset: Vector,
  pinScale: number,
  selectedPinComponentIds: string[],
  pinComponents: PinComponent[]
): MouseEventState {
  const startPoint = newVector(event.clientX, event.clientY);
  const selectedPinComponents = pinComponents.filter((pinComponent) =>
    selectedPinComponentIds.includes(pinComponent.id)
  );

  return {
    clickStart: startPoint,
    scaledClickStart: calculateScaledDelta(startPoint, pinRendererOffset, pinScale),
    action: action,
    pinComponentsStart: pinComponents,
    selectedPinComponentsStart: selectedPinComponents,
  };
}

export function determineSelectedPinComponents(
  action: PinTransformAction,
  selectedPinComponentIds: string[]
): string[] {
  if (isSelectAction(action)) return [];
  if (isComponentTransformAction(action)) return [action.componentId];
  if (isSelectPinComponentAction(action)) return action.componentIds;
  return selectedPinComponentIds;
}

export function createNewPinComponentLayout(
  event: MouseEvent,
  mouseEventState: MouseEventState,
  railLayouts: ArrangedRailLayouts,
  setVisibleRails: React.Dispatch<React.SetStateAction<RailLayout[]>>,
  pinRendererOffset: Vector,
  pinScale: number
): PinComponent[] | null {
  const { selectedPinComponentsStart, action } = mouseEventState;
  if (!selectedPinComponentsStart || isSelectAction(action)) return null;

  const eventPoint = newVector(event.clientX, event.clientY);
  const deltaMouse = calculateScaledDelta(eventPoint, mouseEventState.clickStart, pinScale);
  const mousePosition = calculateScaledDelta(eventPoint, pinRendererOffset, pinScale);

  if (isComponentGroupTransformAction(action) && selectedPinComponentsStart.length) {
    return transformComponentGroup(selectedPinComponentsStart, deltaMouse, action, railLayouts, setVisibleRails);
  }
  if (isComponentTransformAction(action) && selectedPinComponentsStart.length) {
    return [
      transformPinComponent(
        selectedPinComponentsStart[0],
        deltaMouse,
        mousePosition,
        action,
        railLayouts,
        setVisibleRails,
        isClickEventModified(event)
      ),
    ];
  }

  return null;
}

export function createSelectionBox(
  event: MouseEvent,
  mouseEventState: MouseEventState | null,
  pinRendererOffset: Vector,
  pinScale: number
) {
  if (!mouseEventState || !isSelectAction(mouseEventState.action)) return null;

  const containedStartPosition = keepPositionBetweenBounds(mouseEventState.scaledClickStart, pinSize);
  const eventPoint = newVector(event.clientX, event.clientY);
  const mousePosition = divideVector(subtractVectors(eventPoint, pinRendererOffset), pinScale);
  const containedMousePosition = keepPositionBetweenBounds(mousePosition, pinSize);

  return boxFromLine(newLine(containedStartPosition, containedMousePosition));
}

export function selectionBoxIsIntersectingWithLayout(selection: Box, layout: PinComponentLayout) {
  return isBoxLayout(layout)
    ? boxIntersectionWithBox(selection, layout)
    : boxIntersectionWithLineSegment(selection, layout);
}

/** Checks if a click event is modified by the user (e.g., shift key, control key, or meta key is pressed). */
export function isClickEventModified(event: MouseEvent) {
  return event.shiftKey || event.ctrlKey || event.metaKey;
}

/** Calculates the delta between two points and scales it by the pin scale. */
export function calculateScaledDelta(startPoint: Vector, offset: Vector, pinScale: number) {
  return divideVector(subtractVectors(startPoint, offset), pinScale);
}
