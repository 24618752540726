import styled from '@emotion/styled';
import React from 'react';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { color } from '../../../foundations/colors/colors';
import { ComponentSize } from '../../../foundations/size/size.types';
import { spacing, spacingScaled } from '../../../foundations/spacing/spacing';

export interface IndicatorSelectedProps {
  type: 'line' | 'chip';
  size?: ComponentSize;
  verticalOffset?: spacing;
  leftOffset?: spacing;
}

// WIP component while we are researching the use cases. Might get a rename and different color and positioning options.

export const IndicatorSelected: React.FC<IndicatorSelectedProps> = ({
  size = 'small',
  type,
  verticalOffset,
  leftOffset,
}) => {
  return <StyledIndicatorSelected size={size} type={type} verticalOffset={verticalOffset} leftOffset={leftOffset} />;
};

const StyledIndicatorSelected = styled.div<IndicatorSelectedProps & { size: ComponentSize }>`
  background-color: ${color.accent.secondaryContainer.text};
  position: absolute;
  top: 50%;
  left: ${({ leftOffset, size }) => (leftOffset ? `calc(${spacingScaled(spacing.size2, size)} * -1)` : '0')};
  transform: translateY(-50%);
  width: ${({ size, type }) =>
    type === 'line' ? `${spacingScaled(spacing.size2, size)}` : `${spacingScaled(spacing.size4, size)}`};
  height: ${({ size, verticalOffset }) =>
    verticalOffset ? `calc(100% - (${spacingScaled(verticalOffset, size)} * 2))` : '100%'};
  border-radius: ${({ type }) =>
    type === 'line'
      ? `${borderRadius.roundedFull}`
      : ` 0px ${borderRadius.roundedFull} ${borderRadius.roundedFull} 0px`};
`;
