import { getPinComponentFragment } from '@lessonup/editor-shared';
import {
  TipTapComponentProps,
  TipTapEditorContent,
  tipTapLeanExtensions,
  useKeybindController,
  useTipTapEditor,
} from '@lessonup/pin-renderer';
import { styled } from '@lessonup/ui-components';
import { Collaboration } from '@tiptap/extension-collaboration';
import { compact, debounce, isNull } from 'lodash';
import React, { CSSProperties, forwardRef, useMemo } from 'react';
import { XmlFragment as YXmlFragment } from 'yjs';
import { teacherTextEditorStore } from './EditorYjs/teacherTextEditorStore';
import { NewLineDisabler } from './extensions/NewLineDisabler';
import { PasteHandler } from './extensions/PasteHandler';
// import { Placeholder } from './extensions/Placeholder';
import { useAnyYjs } from './YjsContext';

interface CSSPropertiesWithVars extends CSSProperties {
  '--component-placeholder'?: string;
}

interface TipTapInputProps extends TipTapComponentProps {
  fragmentKey: string;
  disableNewLine?: boolean;
}

export const TipTapInput = forwardRef<HTMLDivElement, TipTapInputProps>((props, ref) => {
  const { pinId, pinComponentId, fragmentKey, disableNewLine, placeholder } = props;
  const { yDoc } = useAnyYjs();
  const fragment = useMemo(
    () => getPinComponentFragment(yDoc, pinId, pinComponentId, fragmentKey),
    [yDoc, pinId, pinComponentId, fragmentKey]
  );

  if (isNull(fragment)) return null;

  return (
    <StyledTipTapInputWrapper ref={ref} style={computeTipTapInputStyles(placeholder)}>
      <TipTapInputInner fragment={fragment} {...props} disableNewLine={!!disableNewLine} />
    </StyledTipTapInputWrapper>
  );
});

TipTapInput.displayName = 'TipTapInput';

const StyledTipTapInputWrapper = styled.div`
  .ProseMirror:focus {
    outline: none;
  }
  .ProseMirror {
    width: 100%;
  }
  .ProseMirror p.is-editor-empty:first-of-type::before {
    display: inline-block;
    position: absolute;
    color: #292e3d;
    content: var(--component-placeholder);
    pointer-events: none;
    left: 0;
  }
`;

interface TipTapComponentInnerProps extends TipTapComponentProps {
  fragment: YXmlFragment;
  disableNewLine: boolean;
}

export const TipTapInputInner: React.FC<TipTapComponentInnerProps> = ({
  pinId,
  pinComponentId,
  editable,
  onBlur,
  onFocus,
  fragment,
  disableNewLine,
}) => {
  const { yDoc, collaborationCursor } = useAnyYjs();
  const keybindController = useKeybindController();
  const debouncedUpdate = debounce(() => {
    teacherTextEditorStore.forceEditorUpdate();
  }, 100);

  const editor = useTipTapEditor(
    {
      immediatelyRender: false,
      editable: true,
      onBlur: () => {
        onBlur?.();
        keybindController.enableKeybinds();
      },
      onFocus: () => {
        onFocus?.();
        keybindController.disableKeybinds();
      },
      onTransaction: () => {
        debouncedUpdate();
      },
      extensions: compact([
        ...tipTapLeanExtensions,
        PasteHandler,
        disableNewLine ? NewLineDisabler : null,
        Collaboration.extend({
          // We have to override undo and redo from tiptap, since we already have the undo manager directly integrated in our editor
          addCommands() {
            return {
              undo: () => () => {
                return false;
              },
              redo: () => () => {
                return false;
              },
            };
          },
        }).configure({
          document: yDoc,
          fragment: fragment,
        }),
        //Placeholder, /* ED-42 disabled until this ticket is finished */
        editable && collaborationCursor,
      ]),
    },
    [pinId, pinComponentId]
  );

  return <TipTapEditorContent className="tiptap-root" editor={editor} />;
};

function computeTipTapInputStyles(placeholder?: string): CSSPropertiesWithVars {
  return {
    ...(placeholder ? { '--component-placeholder': `"${placeholder}"` } : {}),
  };
}
