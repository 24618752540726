import styled from '@emotion/styled';
import React from 'react';

type TextListItemContentSlotType = 'number' | 'disc' | 'none';

interface TextListItemProps {
  type: TextListItemContentSlotType;
  number?: number;
}

export function TextListItemContentSlot(props: TextListItemProps) {
  switch (props.type) {
    case 'number':
      return <>{props.number || ''}</>;
    case 'disc':
      return <Centered>{'•'}</Centered>;
    case 'none':
    default:
      return <></>;
  }
}

const Centered = styled.div`
  text-align: center;
  margin: auto;
`;
