import { useApolloClient } from '@lessonup/client-integration';
import { FirestoreClientCrudServiceV2, FirestoreDatabaseV2 } from '@lessonup/firebase-database';
import { once } from 'lodash';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';
import { createPlayerStore } from '../playerStore';
import { PlayerStore, PlayerStoreState, YjsPlayer } from '../playerStore.types';
import { createPlayerStoreProxy } from '../playerStoreProxy';
import { createFirestoreCrudHandlers, createFirestoreDatabaseHandlers } from '../services/firebase';
import { createRealtimeApolloCrudService } from '../services/realtime/api/apollo/realtimeApiApolloService';
import { RealtimeClientApiService } from '../services/realtime/api/realtimeApolloService.types';
import { createRealtimeTrackingService } from '../services/realtime/tracking/realtimeTrackingService';
import { transformPlayerStoreStateToAssignmentSettingsView } from '../utils/transformers/playerStoreState';

const playerStoreContext = createContext<PlayerStore | null>(null);

export const SimplePlayerStoreContextProvider = playerStoreContext.Provider;

interface InnerStoreProviderProps {
  lessonId: string;
  assignmentId: string;
  apiService: RealtimeClientApiService;
  pinId?: string;
  firestoreDatabase: FirestoreDatabaseV2;
  clientCrudService: FirestoreClientCrudServiceV2;
  onUpdate: (state: PlayerStoreState) => void;
  yjsPlayerData: YjsPlayer;
}

const InnerPlayerStoreContextProvider: React.FC<PropsWithChildren<InnerStoreProviderProps>> = ({
  lessonId,
  assignmentId,
  apiService,
  pinId,
  firestoreDatabase,
  clientCrudService,
  onUpdate,
  yjsPlayerData,
  children,
}) => {
  const trackingService = useMemo(
    () => createRealtimeTrackingService(lessonId, assignmentId),
    [assignmentId, lessonId]
  );
  const firestoreCrudHandlers = useMemo(() => {
    return createFirestoreCrudHandlers(assignmentId, clientCrudService);
  }, [assignmentId, clientCrudService]);

  const onStoreUpdate = useCallback(
    (state: PlayerStoreState) => {
      onUpdate(state);
      const assignmentSettingsView = transformPlayerStoreStateToAssignmentSettingsView(state);
      if (!assignmentSettingsView) return null;

      firestoreCrudHandlers.updateView(assignmentSettingsView);
    },
    [firestoreCrudHandlers, onUpdate]
  );

  const playerStoreInstance = useMemo(() => {
    return createPlayerStore({
      lessonId,
      assignmentId,
      pinId,
      apiService,
      trackingService,
      onUpdate: onStoreUpdate,
    });
  }, [lessonId, assignmentId, pinId, apiService, trackingService, onStoreUpdate]);

  useEffect(() => {
    const firebaseDatabaseHandlers = createFirestoreDatabaseHandlers(
      assignmentId,
      firestoreDatabase,
      once(onStoreUpdate),
      playerStoreInstance.setters
    );

    return () => {
      firebaseDatabaseHandlers.unsubscribe();
    };
  }, [assignmentId, firestoreDatabase, onStoreUpdate, playerStoreInstance.setters]);

  useEffect(() => {
    playerStoreInstance.setters.syncData(yjsPlayerData);
  }, [playerStoreInstance, yjsPlayerData]);

  return (
    <SimplePlayerStoreContextProvider value={playerStoreInstance.store}>{children}</SimplePlayerStoreContextProvider>
  );
};

interface StoreProviderProps {
  lessonId: string;
  assignmentId: string;
  pinId?: string;
  firestoreDatabase: FirestoreDatabaseV2;
  clientCrudService: FirestoreClientCrudServiceV2;
  onError: () => void;
  onUpdate: (state: PlayerStoreState) => void;
  yjsPlayerData: YjsPlayer;
}

export const PlayerStoreContextProvider: React.FC<PropsWithChildren<StoreProviderProps>> = ({
  lessonId,
  assignmentId,
  pinId,
  firestoreDatabase,
  clientCrudService,
  onUpdate,
  yjsPlayerData,
  children,
}) => {
  const apolloClient = useApolloClient();

  const apiService = useMemo(
    () => createRealtimeApolloCrudService(apolloClient, assignmentId),
    [apolloClient, assignmentId]
  );

  if (!assignmentId) {
    const playerStoreProxy = createPlayerStoreProxy();
    return <SimplePlayerStoreContextProvider value={playerStoreProxy}>{children}</SimplePlayerStoreContextProvider>;
  }

  return (
    <InnerPlayerStoreContextProvider
      lessonId={lessonId}
      assignmentId={assignmentId}
      apiService={apiService}
      firestoreDatabase={firestoreDatabase}
      clientCrudService={clientCrudService}
      pinId={pinId}
      onUpdate={onUpdate}
      yjsPlayerData={yjsPlayerData}
    >
      {children}
    </InnerPlayerStoreContextProvider>
  );
};

export const usePlayerStore = () => {
  const playerStore = useContext(playerStoreContext);
  if (!playerStore) {
    throw new Error('usePlayerStore must be used within a PlayerStoreContextProvider');
  }
  return playerStore;
};
