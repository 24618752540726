import { HotspotPinComponent as HotspotPincomponentType, newSize, newVector } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { TipTapComponent } from '../../components/Tiptap/Tiptap.utils';
import { isBoxLayout } from '../../types';
import { computeOpacity, computeShadowFilter } from '../../utils';
import { getHotspotIcon } from './hotspot.utils';
import { HotspotContentHolder } from './HotspotContentHolder';

export interface HotspotComponentProps {
  pinId: string;
  pinComponent: HotspotPincomponentType;
  active: boolean;
  Renderer?: TipTapComponent;
}

export const HotspotPinComponent: React.FC<HotspotComponentProps> = (props) => {
  const hotspotPosition = isBoxLayout(props.pinComponent.layout) ? props.pinComponent.layout.position : newVector(0, 0);
  const hotspotSize = isBoxLayout(props.pinComponent.layout) ? props.pinComponent.layout.size : newSize(0, 0);
  return (
    <StyledHotspotWrapper>
      <HotspotContentHolder
        showBody={props.pinComponent.settings.showBody || props.active}
        bodyText={props.pinComponent.settings.body || undefined}
        title={props.pinComponent.settings.title || undefined}
        hotspotSize={hotspotSize}
        hotspotPosition={hotspotPosition}
        expandDirection={props.pinComponent.settings.expandDirection}
        backgroundColor={props.pinComponent.settings.contentBackgroundColor}
        textColor={props.pinComponent.settings.contentTextColor}
        pinId={props.pinId}
        componentId={props.pinComponent.id}
        Renderer={props.Renderer}
      />
      <StyledBorder
        style={{
          backgroundColor: props.pinComponent.settings.borderColor ?? '#000000',
          opacity: computeOpacity(props.pinComponent.settings.transparency),
          boxShadow: computeShadowFilter(props.pinComponent.settings.shadow),
        }}
      ></StyledBorder>
      <StyledCore
        style={{
          backgroundColor: props.pinComponent.settings.backgroundColor ?? '#FFFFFF',
          color: props.pinComponent.settings.innerColor ?? '#000000',
          opacity: computeOpacity(props.pinComponent.settings.transparency),
        }}
      >
        {props.pinComponent.settings.fillType === 'ICON' && (
          <IconCore
            style={{
              fill: props.pinComponent.settings.innerColor ?? '#000000',
            }}
          >
            {getHotspotIcon(props.pinComponent.settings.iconFill)?.icon}
          </IconCore>
        )}
        {props.pinComponent.settings.fillType === 'IMAGE' && (
          <ImageCore style={{ backgroundImage: `url(${props.pinComponent.settings.imageFill.url})` }} />
        )}
        {props.pinComponent.settings.fillType === 'TEXT' && (
          <TextFillWrapper>
            <TextCore
              style={{
                fontSize: getFontSizeForTextFill(
                  isBoxLayout(props.pinComponent.layout) ? props.pinComponent.layout.size.width : 0
                ),
              }}
            >
              {props.pinComponent.settings.textFill}
            </TextCore>
          </TextFillWrapper>
        )}
      </StyledCore>
      <StyledBorder />
    </StyledHotspotWrapper>
  );
};

const StyledHotspotWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

const StyledCore = styled.div`
  position: absolute;
  width: 80%;
  height: 80%;
  left: 10%;
  top: 10%;
  border-radius: 100%;
  cursor: pointer;
`;

const IconCore = styled.div`
  position: absolute;
  width: 60%;
  height: 60%;
  left: 20%;
  top: 20%;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ImageCore = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;

const TextFillWrapper = styled.div`
  display: grid;
  align-content: center;
  font-weight: bold;
  height: 100%;
  border-radius: 50%;
`;

const TextCore = styled.div`
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const getFontSizeForTextFill = (boxSize?: number): string => {
  if (!boxSize) return '0px';

  return `${boxSize / 4}px`;
};
