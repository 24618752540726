import { Button, IconArrowLeft, rem, spacing, styled, Tooltip } from '@lessonup/ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { LESSON_TOP_BAR_INNER_HEIGHT } from '../../LessonTopBar.utils';
import { shouldHideTooltip } from './LessonBarStart.utils';

export interface LessonBarStartProps {
  title: string;
  onTitleClick?: () => void;
  onBackClick?: () => void;
  children?: React.ReactNode;
}

export const LessonBarStart: React.FC<LessonBarStartProps> = ({ title, children, onTitleClick, onBackClick }) => {
  return (
    <StyledLessonBarStart>
      <StyledGroup>
        <Button buttonType="neutral" onClick={onBackClick} iconStart={<IconArrowLeft />} />
        {title && <TitleHandler title={title} onTitleClick={onTitleClick} />}
      </StyledGroup>
      {children}
    </StyledLessonBarStart>
  );
};

const TitleHandler: React.FC<Pick<LessonBarStartProps, 'title' | 'onTitleClick'>> = ({ title, onTitleClick }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [hideTooltip, setHideTooltip] = useState<boolean>(true);

  useEffect(() => {
    setHideTooltip(shouldHideTooltip(titleRef.current));
  }, [titleRef, title]);

  return (
    <Tooltip content={title} direction="bottom" hide={hideTooltip} resize="fixed">
      <StyledTitleButton buttonType="neutral" buttonShape="rect" onClick={onTitleClick} resizing="hug">
        <StyledTitle ref={titleRef}>{title}</StyledTitle>
      </StyledTitleButton>
    </Tooltip>
  );
};

const StyledTitleButton = styled(Button)`
  max-width: ${rem('186px')};
`;

const StyledTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledLessonBarStart = styled.section`
  height: ${LESSON_TOP_BAR_INNER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing.size8};
`;

const StyledGroup = styled.div`
  width: ${rem('240px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.size8};
  padding: 0px ${spacing.size8};
`;
