import { DataLoader } from '@lessonup/client-integration';
import { styled, TablePreviewSelectOptions, TableRowInteractivity } from '@lessonup/ui-components';
import React from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { UploadsSorting } from '../../types/graphql';
import { UploadModalPreviewActions } from './components/UploadPreviewModal/UploadPreviewModalContent';
import { useMyUploadsQuery } from './hooks/useMyUploadsQuery';
import {
  NavigationState,
  useNavigationDispatcherInternal,
  useNavigationDispatcherRouter,
} from './hooks/useNavigationDispatcher';
import { Uploads, UploadsProps } from './Uploads';
import { MyUploadsData, SearchUploadsData } from './Uploads.graphql';
import { UploadsContext, UploadsContextProps } from './uploadsContext';
import { loadingStateDebounceMilliseconds, UploadType } from './UploadsFeature.utils';

export interface UploadListProps {
  folderId?: string | null;
  /**
   * If true, the url is not updated when changing folders. if false, the url is updated when changing folders by the router of the app, providing an updated folderId is then needed
   */
  hasInternalRouter: boolean;
  sorting?: Partial<UploadsSorting>;
  page?: number;
  searchPage?: number;
  query?: string;
  fileTypeFilter?: UploadType[];
  tableInteractivity?: TableRowInteractivity;
  options?: TablePreviewSelectOptions;
  previewActions?: UploadModalPreviewActions;
}

export const UploadsFeature: React.FC<UploadListProps> = (props) => {
  if (props.hasInternalRouter) {
    return <UploadsFeatureInternal {...props} />;
  } else {
    return <UploadsFeatureRouter {...props} />;
  }
};

/**
 * This component is used when the state of the uploads feature is managed by the router of the app.
 */
const UploadsFeatureRouter: React.FC<UploadListProps> = (props) => {
  const [navigationState, dispatchNavigation] = useNavigationDispatcherRouter(props);
  return (
    <UploadsFeatureInner
      navigationState={navigationState}
      dispatchNavigation={dispatchNavigation}
      fileTypeFilter={props.fileTypeFilter}
      tableInteractivity={props.tableInteractivity}
      previewActions={props.previewActions}
      options={props.options}
    />
  );
};

/**
 * This component is used when the state of the uploads feature is managed internally.
 */
const UploadsFeatureInternal: React.FC<UploadListProps> = (props) => {
  const [navigationState, dispatchNavigation] = useNavigationDispatcherInternal(props);
  return (
    <UploadsFeatureInner
      navigationState={navigationState}
      dispatchNavigation={dispatchNavigation}
      fileTypeFilter={props.fileTypeFilter}
      tableInteractivity={props.tableInteractivity}
      previewActions={props.previewActions}
      options={props.options}
    />
  );
};

interface UploadsFeatureInnerProps {
  navigationState: NavigationState;
  dispatchNavigation: UploadsContextProps['dispatchNavigation'];
  fileTypeFilter?: UploadsContextProps['fileTypeFilter'];
  tableInteractivity?: UploadsContextProps['tableInteractivity'];
  previewActions?: UploadsContextProps['previewActions'];
  options?: UploadsContextProps['options'];
}

function UploadsFeatureInner(props: UploadsFeatureInnerProps) {
  const { navigationState, dispatchNavigation } = props;

  const onCompleted = (totalCount?: number) => {
    const maxPages = Math.ceil((totalCount ?? 0) / navigationState.perPage);
    if (navigationState.page > maxPages) {
      dispatchNavigation({ type: 'pageNumber', number: 1 });
    }
  };

  const { data, loading, error, retry } = useMyUploadsQuery({ onCompleted, navigationState });

  const [isLoadingDebounced] = useDebounceValue(loading, loadingStateDebounceMilliseconds);

  return (
    <UploadsContext.Provider
      value={{
        dispatchNavigation,
        fileTypeFilter: props.fileTypeFilter || [],
        tableInteractivity: props.tableInteractivity ?? {
          isDraggable: false,
          isSelectable: true,
          isSelectableIfDisabled: true,
        },
        options: props.options,
        previewActions: props.previewActions,
      }}
    >
      <StyledDataLoader
        retry={retry}
        data={data}
        loading={isLoadingDebounced}
        error={error}
        showLoaderWhenRefetching={true}
        dataRenderer={({ viewer }) => {
          const isSearch = isSearchResult(viewer);
          const props = isSearch
            ? propsForSearchResults(viewer, navigationState)
            : propsForMyUploadsFolder(viewer, navigationState);

          return <Uploads {...props} />;
        }}
      />
    </UploadsContext.Provider>
  );
}

// Casting is needed as styled() does not support generics.
const StyledDataLoader = styled(DataLoader)`
  width: 100%;
  height: 100%;
` as typeof DataLoader;

function propsForSearchResults(
  { searchUploads, acceptedMimeTypesForUploads }: SearchUploadsData['viewer'],
  navigationState: NavigationState
): UploadsProps {
  return {
    uploads: searchUploads.nodes,
    totalCount: searchUploads.totalCount,
    breadcrumbs: [],
    navigationState,
    acceptedMimeTypes: acceptedMimeTypesForUploads || {
      audioTypes: [],
      videoTypes: [],
      imageTypes: [],
      documentTypes: [],
    },
  };
}

function propsForMyUploadsFolder(
  { uploadFolder, acceptedMimeTypesForUploads }: MyUploadsData['viewer'],
  navigationState: NavigationState
): UploadsProps {
  return {
    uploads: uploadFolder?.childUploadFoldersAndUploads.nodes || [],
    totalCount: uploadFolder?.childUploadFoldersAndUploads.totalCount ?? 0,
    breadcrumbs: uploadFolder?.breadcrumbs,
    uploadFolderId: uploadFolder?.id,
    uploadFolderName: uploadFolder?.name,
    navigationState,
    acceptedMimeTypes: acceptedMimeTypesForUploads || {
      audioTypes: [],
      videoTypes: [],
      imageTypes: [],
      documentTypes: [],
    },
  };
}

function isSearchResult(
  viewer: MyUploadsData['viewer'] | SearchUploadsData['viewer']
): viewer is SearchUploadsData['viewer'] {
  return 'searchUploads' in viewer;
}
