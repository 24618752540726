import { BodyText, SpaceBetween, spacing } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ReferralCountMessageProps {
  referralCount: number;
}

export const ReferralCountMessage = ({ referralCount }: ReferralCountMessageProps) => {
  const { t } = useTranslation('referralCountMessage');
  return (
    <SpaceBetween direction="y" spacing={spacing.size4}>
      <BodyText weight="bold">{t('communityIsGrowing')}</BodyText>
      <SpaceBetween direction="x" spacing={spacing.size8}>
        <BodyText weight="bold">
          {'🎉 '}
          {referralCount}
        </BodyText>
        <BodyText>{t('peopleAccepted', { count: referralCount })}</BodyText>
      </SpaceBetween>
    </SpaceBetween>
  );
};
