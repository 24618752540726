import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconShareAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.92 9.193c2.93-1.652 3.107-1.666 6.44-2.606m-6.44 8.22c2.93 1.652 3.107 1.666 6.44 2.606m-7.927-2.206a3.207 3.207 0 1 0 0-6.414 3.207 3.207 0 0 0 0 6.414M17.567 9.04a3.207 3.207 0 1 0 0-6.414 3.207 3.207 0 0 0 0 6.414m0 12.336a3.207 3.207 0 1 0 0-6.414 3.207 3.207 0 0 0 0 6.414"
    />
  </svg>
);
export default SvgIconShareAlt;
