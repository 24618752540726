import React, { useRef } from 'react';

import { useBoolean, useOnClickOutside } from 'usehooks-ts';

import {
  Button,
  color,
  fontWeight,
  IconChevronDown,
  sharedInputSelectStyle,
  styled,
  TextElement,
} from '@lessonup/ui-components';

import { DropDown, StyleDropdownOption } from './CustomSelectDropdown';
import { useCustomSelectScrollIntoView } from './useCustomSelectScrollIntoView';

export type PickerWidth = 'fit' | 'full';
export interface NoFocusPickerProps<T extends string | undefined> {
  value: T | undefined;
  valueLabel?: string;
  options: { label: string; value: T }[];
  onChange: (value: T) => void;
  styleOption?: StyleDropdownOption<T>;
}

export function NoFocusPicker<T extends string | undefined>({
  value,
  valueLabel,
  options: options,
  onChange,
  styleOption,
}: NoFocusPickerProps<T>) {
  const isOpen = useBoolean(false);
  const containerRef = useRef<HTMLInputElement | null>(null);

  const customScroll = useCustomSelectScrollIntoView();

  const handleClose = () => {
    isOpen.setFalse();
    customScroll.resetScrollIntoView();
  };

  // TODO: TECH-180: fix the ref type issue
  useOnClickOutside(containerRef as never, () => {
    handleClose();
  });

  const handlePickOption = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: T) => {
    event.preventDefault();
    onChange(value);
    handleClose();
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    event.preventDefault();
    if (isOpen.value) {
      handleClose();
    } else {
      isOpen.setTrue();
    }
  };

  return (
    <SelectContainer ref={containerRef} isOpen={isOpen.value}>
      <FakeInputFieldButton
        buttonType="secondary"
        onMouseDown={toggleDropdown}
        onTouchStart={toggleDropdown}
        iconEnd={<IconChevronDown />}
      >
        <TextElement textStyle="label">{valueLabel ? valueLabel : value}</TextElement>
      </FakeInputFieldButton>
      {isOpen.value && DropDown<T>(customScroll, options, handlePickOption, value, styleOption)}
    </SelectContainer>
  );
}

const FakeInputFieldButton = styled(Button)`
  ${sharedInputSelectStyle}
  width: 100%;
  justify-content: space-between;
  background-color: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
  & > span {
    font-weight: ${fontWeight.regular};
    width: 100%;
    justify-content: space-between;
  }
  &:hover:not(:disabled) {
    background-color: ${color.accent.secondaryContainer.background};
    color: ${color.accent.secondaryContainer.text};
  }
`;

const SelectContainer = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  & > button {
    ${(props) => (props.isOpen ? `outline: 2px solid ${color.accent.secondaryContainer.text}` : '')};
  }
`;
