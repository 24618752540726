import { QuizPinPhases, QuizView } from '../../playerStore.types';
import { PlayerPinReducer } from '../reducers.types';
import { nextPhase } from '../reducers.utils';

export const quizPinPhaseOrder: QuizPinPhases[] = ['input', 'discuss', 'results'];
export type QuizReducer = PlayerPinReducer<QuizView>;

export const quizReducer: QuizReducer = {
  phaseAmount: quizPinPhaseOrder.length,
  getPhaseNumber: (phase) => quizPinPhaseOrder.indexOf(phase) + 1,
  initial: () => {
    return {
      type: 'MULTIPLE_CHOICE',
      phase: 'input',
    };
  },
  hasNextView: (state, currentView, direction) => {
    return nextPhase(currentView.phase, quizPinPhaseOrder, direction) !== null;
  },
  handleNextView: (state, prev, direction) => {
    if (!prev) {
      return false;
    }

    const next = nextPhase(prev.phase, quizPinPhaseOrder, direction);
    if (next) {
      return {
        type: 'MULTIPLE_CHOICE',
        phase: next,
      };
    }
    return false;
  },
  onNextView: async (state, prev, next, services, setBackgroundActionAction) => {
    if (prev.phase === 'input') {
      const action = () => services.apiService.quiz.checkPinStudentAnswers(state.currentPinId);
      setBackgroundActionAction?.(action);
      return action();
    }
    return null;
  },
};
