import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconComponentTraffic = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.047 5.72c.821-.307 2.027-.128 2.528 0m-12.622 0c-.821-.307-2.027-.128-2.528 0m12.622 5.176c.821-.307 2.027-.128 2.528 0m-12.622 0c-.821-.307-2.027-.128-2.528 0m12.622 5.176c.821-.307 2.027-.128 2.528 0m-12.622 0c-.821-.307-2.027-.128-2.528 0M16.732 5.074a1.575 1.575 0 0 0-1.265-1.436C14.35 3.412 13.19 3.162 12 3.162s-2.35.25-3.468.476a1.575 1.575 0 0 0-1.264 1.436C7.132 7.29 6.953 9.61 6.953 12s.179 4.71.315 6.926c.044.707.57 1.295 1.264 1.436 1.117.226 2.278.476 3.468.476s2.35-.25 3.467-.476a1.575 1.575 0 0 0 1.265-1.436c.136-2.216.315-4.536.315-6.926s-.18-4.71-.315-6.926M12 8.211a1.484 1.484 0 1 0 0-2.967 1.484 1.484 0 0 0 0 2.967m0 10.406a1.484 1.484 0 1 0 0-2.968 1.484 1.484 0 0 0 0 2.968m0-5.203a1.484 1.484 0 1 0 0-2.967 1.484 1.484 0 0 0 0 2.967"
    />
  </svg>
);
export default SvgIconComponentTraffic;
