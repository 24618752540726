import { BehaviorSubject } from 'rxjs';
import { Pin } from '..';
import { LessonPin } from '../../lesson';

export namespace Video {
  export const type = 'video';
  export type SourceType = 'schooltv' | 'youtube' | 'vimeo' | 'npo';

  export interface Content {
    // aanvullen
    source: SourceType;
    start: number | undefined;
    end: number | undefined;
    duration: number | undefined;
    autoplay: boolean | undefined;
    id: string;
  }

  export interface VideoItems {
    pinId: string;
    opacity?: number;
    over?: boolean;
    play?: boolean;
    time: number;
    precision?: boolean;
    end?: number;
  }

  export type VideoKeys = 'NOTREADY' | 'UNSTARTED' | 'PLAY' | 'PAUSE' | 'BUFFERING' | 'STOPPED' | 'ENDED';
  export interface VideoEnum {
    NOTREADY: 'NOTREADY';
    UNSTARTED: 'UNSTARTED';
    PLAY: 'PLAY';
    PAUSE: 'PAUSE';
    BUFFERING: 'BUFFERING';
    STOPPED: 'STOPPED';
    ENDED: 'ENDED';
  }

  export const videoEnum = Object.freeze<VideoEnum>({
    NOTREADY: 'NOTREADY',
    UNSTARTED: 'UNSTARTED',
    PLAY: 'PLAY',
    PAUSE: 'PAUSE',
    BUFFERING: 'BUFFERING',
    STOPPED: 'STOPPED',
    ENDED: 'ENDED',
  });

  export type TimeSubject = BehaviorSubject<number>;

  export type InteractiveVideoPin = Pin<Video.Content> & {
    item: {
      videoItems: VideoItems[];
    };
    interactiveVideo: true;
  };

  export function isInteractiveVideo(pin: Pin<any>): pin is InteractiveVideoPin {
    return Boolean(isVideo(pin) && pin.item.videoItems?.length);
  }

  export function isVideo(pin: Pin<any>): pin is Pin<Video.Content> {
    return Boolean(pin.item.type === 'video');
  }

  export function videoIdForPin(pin: Pin<any>): string | undefined {
    return isVideo(pin) ? pin.item.custom.id : undefined;
  }

  export function youtubeEmbedUrlForId(id: string | undefined): string | undefined {
    if (!id) return undefined;
    return `//youtube-nocookie.com/embed/${id}`;
  }

  export function vimeoEmbedUrlForId(id: string | undefined, isTrackingAllowed: boolean): string | undefined {
    if (!id) return undefined;
    return isTrackingAllowed ? `https://player.vimeo.com/video/${id}` : `https://player.vimeo.com/video/${id}?dnt=1`;
  }

  export function videoEmbedUrlForPin(pin: Pin<any>, isTrackingAllowed: boolean) {
    if (!isVideo(pin)) return undefined;
    const { item } = pin;
    const {
      custom: { id },
      source,
    } = item;

    if (source === 'youtube') {
      return youtubeEmbedUrlForId(id);
    } else if (source === 'vimeo') {
      return vimeoEmbedUrlForId(id, isTrackingAllowed);
    }
  }

  export function sortVideoItems(items: VideoItems[], lessonPins?: LessonPin[]): VideoItems[] {
    return items.sort((a, b) => {
      if (b.end) {
        return a.time - b.end;
      }
      if (a.end) {
        return a.end - b.time;
      }
      return a.time - b.time;
    });
  }
}
