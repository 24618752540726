import { multiplyLineVectors, PinComponent } from '@lessonup/pin-renderer';
import { color, styled } from '@lessonup/ui-components';
import React from 'react';
import {
  connectedToActiveComponent,
  lineBetweenLinkedComponents,
  linkedComponents,
} from './LinkedComponentLines.utils';

export interface LinkedComponentsProps {
  pinComponents: PinComponent[];
  active: PinComponent[];
  scale: number;
  mutationInProgress: boolean;
}

export const LinkedComponentLines: React.FC<LinkedComponentsProps> = ({
  pinComponents,
  scale,
  active,
  mutationInProgress,
}) => {
  if (active.length !== 1 || mutationInProgress) return null;
  const linked = linkedComponents(pinComponents);
  const activeComponent = active[0];
  const linkedToActive = connectedToActiveComponent(linked, activeComponent.id);
  if (!linkedToActive.length) return null;

  return (
    <LinesOuterSvg>
      {linkedToActive.map((pair) => {
        const line = lineBetweenLinkedComponents(pair);
        const scaledLine = multiplyLineVectors(line, scale);
        return (
          <SvgDisplayLine
            key={`${pair[0].id}-${pair[1].id}`}
            x1={scaledLine.start.x}
            y1={scaledLine.start.y}
            x2={scaledLine.end.x}
            y2={scaledLine.end.y}
          />
        );
      })}
    </LinesOuterSvg>
  );
};

const LinesOuterSvg = styled.svg`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SvgDisplayLine = styled.line`
  stroke-width: 2;
  stroke: ${color.additional.focus.background};
  stroke-dasharray: 4 4;
  stroke-linecap: round;
`;
