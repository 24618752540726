import { OpenQuestionPinSettings } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PinBackground } from '../components/PinBackground';

export interface OpenQuestionPinProps {
  pinSettings: OpenQuestionPinSettings;
  isThumb?: boolean;
  children?: React.ReactNode;
}

export const OpenQuestionPinComponent: React.FC<OpenQuestionPinProps> = ({ pinSettings, isThumb, children }) => {
  return (
    <OpenQuestionWrapper>
      <PinBackground
        isThumb={isThumb}
        backgroundColor={pinSettings.backgroundColor || 'blue'}
        backgroundImage={pinSettings.backgroundImage ?? undefined}
        backgroundTransparency={pinSettings.backgroundTransparency}
      />
      {children}
    </OpenQuestionWrapper>
  );
};

const OpenQuestionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
