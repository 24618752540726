import {
  BodyText,
  Button,
  colorHexCodes,
  ColorInput,
  Colorpicker,
  ColorpickerColorGroup,
  createModal,
  cssBase,
  IconArrowLeft,
  InputFormField,
  isSameColorString,
  ModalPopover,
  ModalPopOverParentSelector,
  SpaceBetween,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HexAlphaColorPicker, HexColorPicker } from 'react-colorful';
import { useEditorTranslation } from '../../../hooks/useEditorTranslation';
import { ActivatableButton } from '../../ActivatableButton';
const templateColors: string[] = [
  '#FFFFFF',
  '#001343',
  '#E5FF45',
  '#4E7FFF',
  '#D2D1FF',
  '#24243C',
  '#AE977F',
  '#EC484D',
  '#FDEDED',
  '#4CAF50',
  '#E4F3E5',
  '#E65100',
  '#FFF3E0',
  '#F4F3FF',
];

interface BaseColorpickerProps {
  selectedColor: string;
  onChange: (color: string, isCustom: boolean, hide?: boolean) => void;
  hideTransparentOption?: boolean;
  /** includes hideTransparentOption */
  disableAlpha?: boolean;
}

interface ColorpickerModalProps extends BaseColorpickerProps {
  parentSelector: ModalPopOverParentSelector;
  onBeforeClose: () => void;
}

interface ColorpickerModalContentProps extends BaseColorpickerProps {
  close: () => void;
}

export const ColorpickerModal = createModal(
  ({
    parentSelector,
    selectedColor,
    onChange,
    hideTransparentOption,
    onBeforeClose,
    disableAlpha = false,
  }: ColorpickerModalProps) => {
    const modal = useModal();

    const close = () => {
      onBeforeClose();
      modal.hide();
    };

    return (
      <ModalPopover
        direction="left"
        modalAnchor="center"
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="temp"
        onBeforeClose={onBeforeClose}
      >
        <ColorpickerModalContent
          selectedColor={selectedColor}
          onChange={onChange}
          hideTransparentOption={hideTransparentOption || false}
          disableAlpha={disableAlpha}
          close={close}
        />
      </ModalPopover>
    );
  }
);

export const ColorpickerModalContent: React.FC<ColorpickerModalContentProps> = ({
  selectedColor,
  onChange,
  hideTransparentOption,
  disableAlpha = false,
  close,
}) => {
  const [page, setPage] = useState<'start' | 'custom'>('start');
  const [color, setColor] = useState<string>(selectedColor);
  const [isCustom, setIsCustom] = useState<boolean>(isCustomColor(selectedColor));

  const handleOnChange = useCallback(
    (color: string, isCustom: boolean, closeOnChange = true) => {
      setColor(color);
      onChange(color, isCustom);
      setIsCustom(isCustom);
      if (closeOnChange) {
        close?.();
      }
    },
    [onChange, close]
  );

  const handleSetPage = (page: 'start' | 'custom') => {
    setPage(page);
    if (page === 'start') {
      setIsCustom(isCustomColor(color));
    }
  };

  return (
    <StyledColorpickerModal>
      <StartPage
        handleOnChange={handleOnChange}
        color={color}
        setPage={handleSetPage}
        hideTransparentOption={hideTransparentOption || false}
        visible={page === 'start'}
        isCustom={isCustom}
        disableAlpha={disableAlpha}
      />
      <CustomPage
        handleOnChange={handleOnChange}
        color={color}
        setPage={handleSetPage}
        hideTransparentOption={hideTransparentOption || false}
        visible={page === 'custom'}
        disableAlpha={disableAlpha}
      />
    </StyledColorpickerModal>
  );
};

const isCustomColor = (color: string) => {
  return !templateColors.concat(colorHexCodes, 'transparent').some((c) => isSameColorString(c, color));
};

interface PageProps {
  setPage: (page: 'start' | 'custom') => void;
  handleOnChange: (color: string, isCustom: boolean, closeOnChange?: boolean) => void;
  color: string;
  hideTransparentOption: boolean;
  visible: boolean;
  disableAlpha: boolean;
}

interface StartPageProps extends PageProps {
  isCustom: boolean;
}

const StartPage = ({
  handleOnChange: outerHandleOnChange,
  hideTransparentOption,
  color: color,
  setPage,
  visible,
  isCustom,
  disableAlpha,
}: StartPageProps) => {
  const { t } = useEditorTranslation();

  const handleOnChange = (color: string) => {
    outerHandleOnChange(color, false);
  };

  return (
    <SpaceBetween direction="y" spacing={spacing.size16} style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <Colorpicker onChange={handleOnChange} selectedColor={color}>
        <ColorpickerColorGroup
          colors={templateColors}
          onChange={handleOnChange}
          title={t('colorPicker.lessonUpDefault')}
          name="template"
          selectedColor={color}
        />
      </Colorpicker>
      <SpaceBetween
        direction="x"
        spacing={spacing.size8}
        justifyContent="center"
        alignItems="center"
        className={buttonBarStyle}
      >
        {!hideTransparentOption && !disableAlpha && (
          <ActivatableButton
            buttonType="neutral"
            showStroke
            buttonShape="rect"
            onClick={() => handleOnChange('transparent')}
            iconStart={<NestedColorInput color="transparent" />}
            size="small"
            active={color.toLowerCase() === 'transparent'}
          >
            {t('colorPicker.transparent')}
          </ActivatableButton>
        )}
        <ActivatableButton
          buttonType="neutral"
          showStroke
          buttonShape="rect"
          onClick={() => setPage('custom')}
          iconStart={<NestedColorInput color={color} />}
          size="small"
          active={isCustom}
        >
          {t('colorPicker.customButton')}
        </ActivatableButton>
      </SpaceBetween>
    </SpaceBetween>
  );
};

const buttonBarStyle = cssBase`
  width: 100%;
  > * {
    flex: 1;
  }
`;

const NestedColorInput = ({ color }: { color: string }) => {
  return (
    <ColorInput
      color={color}
      /* eslint-disable @typescript-eslint/no-empty-function */
      onClick={() => {}}
      selected={false}
      name=""
      ariaLabel=""
      className={nestedColorInputStyle}
    />
  );
};

const nestedColorInputStyle = cssBase`
  pointer-events: none;
`;

const CustomPage = ({ handleOnChange, color: selectedColor, setPage, visible, disableAlpha }: PageProps) => {
  const { t } = useEditorTranslation();
  // Having the state here again makes the picker more responsive
  const [colorString, setColorString] = useState<string>(
    selectedColor.toLowerCase() === 'transparent' ? '' : selectedColor.toUpperCase()
  );
  useEffect(() => {
    if (selectedColor.toLowerCase() !== 'transparent') {
      setColorString(selectedColor.toUpperCase());
    }
  }, [selectedColor, setColorString]);

  const throttleSet = useMemo(() => throttle(handleOnChange, 200), [handleOnChange]);

  const handleOnChangePicker = (color: string) => {
    setColorString(color.toUpperCase());
    throttleSet(color, true, false);
  };

  const handleOnChangeInput: React.FormEventHandler<HTMLInputElement> = (event) => {
    const colorHex = event.currentTarget.value;
    setColorString(colorHex);
    const regex = disableAlpha ? /^#(?:[0-9a-fA-F]{3}){1,2}$/ : /^#(?:[0-9a-fA-F]{3,4}){1,2}$/;
    if (colorHex.match(regex)) {
      handleOnChange(colorHex, true, false);
    }
  };

  return (
    <CustomPageWrapper style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <SpaceBetween direction="y" spacing={spacing.size16} justifyContent="center">
        <SpaceBetween direction="x" spacing={spacing.size8} alignItems="center">
          <Button buttonType="neutral" onClick={() => setPage('start')} iconStart={<IconArrowLeft />} />
          <BodyText weight="bold">{t('colorPicker.customPageTitle')}</BodyText>
        </SpaceBetween>
        <HexWrapper>
          {disableAlpha ? (
            <HexColorPicker color={selectedColor} onChange={handleOnChangePicker} />
          ) : (
            <HexAlphaColorPicker color={selectedColor} onChange={handleOnChangePicker} />
          )}
        </HexWrapper>
        <InputFormField
          value={colorString}
          type="text"
          label={disableAlpha ? t('colorPicker.hexInputLabel') : t('colorPicker.hexInputLabelAlpha')}
          onChange={handleOnChangeInput}
          placeholder="#FFFFFF"
        />
      </SpaceBetween>
    </CustomPageWrapper>
  );
};

const padding = spacing.size16;

const HexWrapper = styled.div`
  margin: auto;
`;

const CustomPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${padding};
`;

const StyledColorpickerModal = styled.div`
  width: 352px;
  padding: ${padding};
  overflow: hidden;
`;
