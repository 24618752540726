import {
  borderRadius,
  color,
  IconArrowDown,
  SpaceBetween,
  spacing,
  strokeWidth,
  styled,
  TextElement,
} from '@lessonup/ui-components';
import React from 'react';

export interface DropOverlayProps {
  dropText: string;
  isDragging?: boolean;
  children?: React.ReactNode;
}

export const DropOverlay: React.FC<DropOverlayProps> = ({ isDragging, children, dropText }) => (
  <OuterContainer isDragging={isDragging}>
    <OuterBorder isDragging={isDragging}>
      <InnerBorder isDragging={isDragging} />
      <ContentContainer isDragging={isDragging}>
        <SpaceBetween direction="y" spacing={spacing.size12} alignItems="center">
          <IconContainer isDragging={isDragging}>
            <IconArrowDown style={{ width: '32px', height: '32px' }} />
          </IconContainer>
          <TextElement textStyle="bodyText">{dropText}</TextElement>
          {!isDragging ? children : null}
        </SpaceBetween>
      </ContentContainer>
    </OuterBorder>
  </OuterContainer>
);

const OuterContainer = styled.div<{ isDragging?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isDragging }) => (isDragging ? color.setting.active.fill : color.neutralNew.level1.fill)};
  opacity: 0.85;

  &:hover {
    background-color: ${({ isDragging }) =>
      isDragging ? color.setting.active.fill : color.neutralNew.level1.fillFeedback};
  }
`;

const OuterBorder = styled.div<{ isDragging?: boolean }>`
  border: ${strokeWidth.width2} solid
    ${({ isDragging }) => (isDragging ? color.setting.active.stroke : color.neutralNew.level1.stroke)};
  border-radius: ${borderRadius.rounded16};
  padding: ${spacing.size4};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const InnerBorder = styled.div<{ isDragging?: boolean }>`
  border: ${strokeWidth.width2} dashed
    ${({ isDragging }) => (isDragging ? color.setting.active.stroke : color.neutralNew.level1.stroke)};
  border-radius: ${borderRadius.rounded16};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const ContentContainer = styled.div<{ isDragging?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ isDragging }) => (isDragging ? color.setting.active.on : color.neutralNew.level1.on)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div<{ isDragging?: boolean }>`
  border-radius: 100%;
  background-color: ${({ isDragging }) =>
    isDragging ? color.setting.active.fillSubjacent : color.neutralNew.level1.fillSubjacent};
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
