import { storageAvailable } from '@lessonup/utils';

export function intercomIsAvailable(initialRender?: boolean): boolean {
  if (typeof window === 'undefined') return false;
  // we can't import the teacher-modern here, so we just check for the key
  // don't mark intercom as active when we are impersonating
  // also don't mark as active when localStorage is unavailable, which is the case when opening an embedded lesson on a website in Chrome incognito mode with third-party cookies disabled
  if (!storageAvailable('localStorage') || window.localStorage.getItem('impersonator.impersonating') === 'true') {
    return false;
  }

  return initialRender ? true : Object.prototype.hasOwnProperty.call(window, 'Intercom');
}
