interface FileProperties extends Required<Omit<FilePropertyBag, 'endings'>> {
  name: string;
}

export class MediaFileHandler {
  static fileFromBlob(blob: Blob, { name, ...otherProperties }: FileProperties) {
    return new File([blob], name, otherProperties);
  }

  static fileToDataTransfer(file: File) {
    const dataTransfer = new DataTransfer();

    dataTransfer.items.add(file);

    return dataTransfer;
  }
}
