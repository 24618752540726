import { borderRadius, color, Grid, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export interface QuizPinAnswersSectionProps {
  pointerEvents?: 'none' | 'auto';
}

export const QuizPinAnswersSection = ({
  pointerEvents,
  children,
}: React.PropsWithChildren<QuizPinAnswersSectionProps>) => (
  <StyledAnswersWrapper cols={2} gap={spacing.size16} pointerEvents={pointerEvents}>
    {children}
  </StyledAnswersWrapper>
);

const StyledAnswersWrapper = styled(Grid)<QuizPinAnswersSectionProps>`
  pointer-events: ${({ pointerEvents }) => pointerEvents || 'auto'};
  position: absolute;
  left: ${spacing.size24};
  right: ${spacing.size24};
  bottom: ${spacing.size24};
  padding: ${spacing.size16};
  background-color: ${color.neutral.surface.background};
  border-radius: ${borderRadius.rounded24};
`;
