import { HotspotPinComponent, isHotspotPinComponent, PinComponent } from '@lessonup/pins-shared';
import { useMemo } from 'react';

export const useLinkedPinComponents = (pinComponents: PinComponent[], selectedPinComponentIds?: string[]) => {
  const toggledOrSelectedHotspotsIds = pinComponents
    .filter(isToggledOrSelectedHotspot(selectedPinComponentIds ?? []))
    .map((pinComponent) => pinComponent.id);

  const linkedPinComponentsIds = useMemo(() => {
    return pinComponents
      .filter((pinComponent): pinComponent is HotspotPinComponent => {
        if (!isHotspotPinComponent(pinComponent)) return false;

        return !!(
          pinComponent.settings.linkedPinComponent &&
          !toggledOrSelectedHotspotsIds.includes(pinComponent.id) &&
          !toggledOrSelectedHotspotsIds.includes(pinComponent.settings.linkedPinComponent)
        );
      })
      .map((hotspotPinComponent) => hotspotPinComponent.settings.linkedPinComponent);
  }, [pinComponents, toggledOrSelectedHotspotsIds]);

  const isLinkedToInactiveHotspot = (pinComponentId: string) => {
    return linkedPinComponentsIds.includes(pinComponentId);
  };

  const isActiveHotspot = (pinComponentId: string) => {
    return toggledOrSelectedHotspotsIds.includes(pinComponentId);
  };

  return { isLinkedToInactiveHotspot, isActiveHotspot };
};

export const isToggledOrSelectedHotspot = (selectedPinComponentIds: string[]) => {
  return (pinComponent: PinComponent): pinComponent is HotspotPinComponent =>
    (isHotspotPinComponent(pinComponent) && pinComponent.settings.showBody) ||
    selectedPinComponentIds?.includes(pinComponent.id);
};
