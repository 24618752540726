import { UpdatePinDispatch } from '@lessonup/pin-renderer';
import {
  initialPinComponentPosition,
  newHotspotPinComponent,
  newImagePinComponent,
  newLinePinComponent,
  newShapePinComponent,
  newSpinnerPinComponent,
  newTextPinComponent,
  newVector,
  newVideoPinComponent,
  OpenQuestionTeacherPin,
  Pin,
  PinComponent,
  PinCreationParams,
  QuizTeacherPin,
  TextPinComponent,
} from '@lessonup/pins-shared';
import { SetModeSelecting } from '../../context/EditorContext/EditorContext.types';
import { trackPinComponentCreate } from '../pinComponent/pinComponent.utils';
import { calculateInitialPosition } from '../pinComponent/position';

export const getQuestionComponentInInteractivePin = (
  pin: OpenQuestionTeacherPin | QuizTeacherPin | PinCreationParams<QuizTeacherPin>
): TextPinComponent => {
  const index = getQuestionComponentInInteractivePinIndex(pin);
  return pin.pinComponents[index] as TextPinComponent;
};

export const getQuestionComponentInInteractivePinIndex = (
  pin: OpenQuestionTeacherPin | QuizTeacherPin | PinCreationParams<QuizTeacherPin>
) => {
  return pin.pinComponents.findIndex((pinComponent) => pinComponent.id === pin.settings.questionId);
};

type BoxLayoutPinComponentConstructorTypes =
  | typeof newImagePinComponent
  | typeof newHotspotPinComponent
  | typeof newTextPinComponent
  | typeof newVideoPinComponent
  | typeof newShapePinComponent
  | typeof newSpinnerPinComponent;

type LineLayoutPinComponentConstructorTypes = typeof newLinePinComponent;

export type PinComponentConstructorTypes =
  | BoxLayoutPinComponentConstructorTypes
  | LineLayoutPinComponentConstructorTypes;

export function createAddPinComponent(
  dispatch: UpdatePinDispatch,
  setModeSelecting: SetModeSelecting,
  pin: Pin | null
) {
  return <PinComponentConstructor extends PinComponentConstructorTypes>(
    newComp: PinComponentConstructor,
    compProps?: Parameters<PinComponentConstructor>[0],
    onComplete?: (pinComponent: PinComponent) => void
  ) => {
    const position =
      compProps && 'position' in compProps && compProps.position ? compProps.position : initialPinComponentPosition;

    const newComponentInstance = newComp({
      ...compProps,
      position: calculateInitialPosition(position, pin?.pinComponents),
    });

    dispatch({
      type: 'addPinComponents',
      pinComponents: [newComponentInstance],
      onComplete: () => {
        setModeSelecting([newComponentInstance.id]);
        onComplete?.(newComponentInstance);
      },
    });

    trackPinComponentCreate(newComponentInstance.type, 'create_own');
  };
}

export const newLinePinComponentTransformer: (
  params: Parameters<BoxLayoutPinComponentConstructorTypes>[0]
) => ReturnType<typeof newLinePinComponent> = (params) => {
  const parsedParams = {
    ...params,
    start: params.position ? newVector(params.position.x, params.position.y) : undefined,
  };

  return newLinePinComponent(parsedParams);
};

export function createStaggeredPinComponent<PinComponentConstructor extends PinComponentConstructorTypes>(
  pinComponents: PinComponent[],
  newComp: PinComponentConstructor,
  compProps?: Parameters<PinComponentConstructor>[0]
) {
  const position =
    compProps && 'position' in compProps && compProps.position ? compProps.position : initialPinComponentPosition;

  return newComp({
    ...compProps,
    position: calculateInitialPosition(position, pinComponents),
  });
}
