import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.742 4.5h18.516M2.742 12h18.516M2.742 19.5h18.516"
    />
  </svg>
);
export default SvgIconMenu;
