import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { Colorset, ComponentColorTheme, ComponentSize, getColorset } from '../../foundations';
import { borderRadiusScaled } from '../../foundations/borders/borderRadius';
import { elevationShadow } from '../../foundations/elevation/elevation';
import { spacing, spacingScaled } from '../../foundations/spacing/spacing';
import { IndicatorSelected } from '../Indicator/IndicatorSelected/IndicatorSelected';

export type CardProps = {
  cardTheme?: ComponentColorTheme;
  active?: boolean;
  size?: ComponentSize;
  resizing?: 'hug' | 'fixed';
  width?: number;
  height?: number;
  autoHeight?: boolean;
  showBorder?: boolean;
  showBoxShadow?: boolean;
  ariaLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children?: React.ReactNode;
};

export const Card: React.FC<CardProps> = ({
  cardTheme = 'neutral',
  size = 'small',
  resizing = 'hug',
  width,
  height,
  autoHeight,
  active = false,
  showBorder = true,
  showBoxShadow = false,
  ariaLabel,
  onClick,
  onMouseDown,
  className,
  children,
  ...rest
}) => {
  const colorset = getColorset(cardTheme, { active });
  const showActive = active && cardTheme === 'neutral'; // Ignore active state for other themes

  // WIP The indicator is now a part of the card component to show the active state, but this might change as we research more use cases.
  return (
    <StyledCard
      colorset={colorset}
      size={size}
      resizing={resizing}
      width={width}
      height={height}
      autoHeight={autoHeight}
      showBorder={showBorder}
      borderSize={showActive ? spacing.size2 : spacing.size1}
      showBoxShadow={showBoxShadow}
      onClick={onClick}
      onMouseDown={onMouseDown}
      as={onClick || onMouseDown ? 'button' : 'div'}
      aria-label={ariaLabel}
      className={className}
      {...rest}
    >
      {showActive && (
        <IndicatorSelected
          size={size}
          type="chip"
          verticalOffset={spacing.size8}
          leftOffset={showBorder ? spacing.size2 : undefined}
        />
      )}
      {children}
    </StyledCard>
  );
};

interface StyledCardProps {
  size: ComponentSize;
  resizing: 'hug' | 'fixed';
  width?: number;
  height?: number;
  colorset: Colorset;
  showBorder: boolean;
  borderSize: spacing;
  showBoxShadow: boolean;
}

const StyledCard = styled.button<StyledCardProps & { autoHeight?: boolean }>`
  display: ${({ resizing }) => (resizing === 'hug' ? 'inline-block' : 'block')};
  text-align: left;
  position: relative;
  border-radius: ${({ size }) => borderRadiusScaled(spacing.size8, size)};
  border: ${({ showBorder, borderSize, colorset, size }) =>
    showBorder ? `${rem(spacingScaled(borderSize, size))} solid ${colorset.stroke}` : undefined};
  background: ${({ colorset }) => colorset.fill};
  width: ${({ resizing, width }) => (resizing === 'hug' ? 'fit-content' : width ? rem(width) : '100%')};
  height: ${({ resizing, height, autoHeight }) =>
    autoHeight ? 'auto' : resizing === 'hug' ? 'fit-content' : height ? rem(height) : '100%'};
  color: ${({ colorset }) => colorset.on};
  box-shadow: ${({ showBoxShadow }) => (showBoxShadow ? elevationShadow.e100 : undefined)};
  white-space: pre-wrap;
  word-break: break-word;

  ${({ onClick }) =>
    !!onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;
