import {
  addWeeks,
  defaultLocale,
  Language,
  languages,
  languageToIsoLocaleString,
  localeToIsoLocaleString,
} from '@lessonup/utils';
import type { i18n as i18nType, InitOptions, PostProcessorModule, Resource } from 'i18next';
import { createInstance } from 'i18next';
import Locize from 'i18next-locize-backend';
import { compact, isArray } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { clearCookie, getCookieValueByName, setCookie } from '../../cookies/cookieUtils';
import { formalizeTranslation } from './formalizeTranslation';

export const FORMALIZATION_POST_PROCESSOR_NAME = 'formalizationPostProcessor';
export const FORMALIZATION_COOKIE_NAME = 'useFormalLanguage';

type SharedInitParams = {
  userLanguage: Language;
  useFormalLanguageOnLoad: boolean;
};

type ResourceInstanceParams = {
  resources: Resource;
  missingKeyHandler?: InitOptions['missingKeyHandler'];
};

export interface LocizeSettings {
  projectId: string;
  apiKey?: string;
  useSuspense?: boolean;
}

export const getUseFormalLanguageOnLoadFromCookies = () => !!getCookieValueByName(FORMALIZATION_COOKIE_NAME);

export interface CustomI18n extends i18nType {
  setFormalization: (useFormal: boolean) => void;
}

export function initI18n({
  resources,
  userLanguage,
  locize,
  useFormalLanguageOnLoad,
  missingKeyHandler,
}: {
  locize?: LocizeSettings;
} & ResourceInstanceParams &
  SharedInitParams): CustomI18n {
  const instance = locize
    ? locizeTranslationsInstance({ userLanguage, locize, useFormalLanguageOnLoad })
    : resourcesInstance({ resources, userLanguage, useFormalLanguageOnLoad, missingKeyHandler });

  function setFormalization(useFormal: boolean): void {
    const currentProcessors: string[] = compact(
      isArray(instance.options.postProcess) ? instance.options.postProcess : [instance.options.postProcess]
    );

    if (useFormal) {
      const expires = addWeeks(new Date(), 1);
      setCookie({
        name: FORMALIZATION_COOKIE_NAME,
        value: 'true',
        expires,
      });
      instance.options.postProcess = [...currentProcessors, FORMALIZATION_POST_PROCESSOR_NAME];
    } else {
      clearCookie(FORMALIZATION_COOKIE_NAME);
      instance.options.postProcess = currentProcessors.filter(
        (processor) => processor !== FORMALIZATION_POST_PROCESSOR_NAME
      );
    }

    // Rerender
    instance.changeLanguage(instance.language);
  }

  instance.setFormalization = setFormalization;

  return instance;
}

const resourcesInstance = ({
  resources,
  missingKeyHandler,
  userLanguage,
  useFormalLanguageOnLoad,
}: ResourceInstanceParams & SharedInitParams): CustomI18n => {
  const instance = createInstance() as CustomI18n;

  instance.use(initReactI18next);
  instance.use(formalizationPostProcessor);

  instance.init({
    resources,
    fallbackLng: languages.map(languageToIsoLocaleString),
    saveMissing: true,
    lng: languageToIsoLocaleString(userLanguage),

    interpolation: {
      escapeValue: false,
    },
    postProcess: useFormalLanguageOnLoad ? [FORMALIZATION_POST_PROCESSOR_NAME] : [],
    ...(missingKeyHandler ? missingKeyHandler : {}),
  });

  return instance;
};

const locizeTranslationsInstance = ({
  userLanguage,
  useFormalLanguageOnLoad,
  locize,
}: {
  locize: LocizeSettings;
} & SharedInitParams): CustomI18n => {
  const instance = createInstance() as CustomI18n;

  instance.use(Locize);
  instance.use(initReactI18next);
  instance.use(formalizationPostProcessor);

  const defaultLanguage = localeToIsoLocaleString(defaultLocale);
  instance.init({
    backend: {
      projectId: locize.projectId,
      apiKey: locize.apiKey,
      referenceLng: defaultLanguage,
      saveMissingTo: 'current',
      fallbackLng: false,
    },
    saveMissingTo: 'current',
    fallbackLng: false,
    saveMissing: true,
    lng: languageToIsoLocaleString(userLanguage),
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
      useSuspense: false,
    },
    postProcess: useFormalLanguageOnLoad ? [FORMALIZATION_POST_PROCESSOR_NAME] : [],
  });

  return instance;
};

const formalizationPostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: FORMALIZATION_POST_PROCESSOR_NAME,
  process(value) {
    return formalizeTranslation(value);
  },
};
