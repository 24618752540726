export interface MimeTypes {
  audioTypes: string[];
  videoTypes: string[];
  imageTypes: string[];
  documentTypes: string[];
}

export class MimeTypesHelper {
  public readonly mimeTypes: string[];

  constructor(mimeTypes: string[] = []) {
    this.mimeTypes = mimeTypes.map(this.normalize);
  }

  public static fromMimeTypes = (mimeTypes: MimeTypes | undefined | null): MimeTypesHelper => {
    return new MimeTypesHelper([
      ...new Set(
        [
          ...(mimeTypes?.audioTypes ?? []),
          ...(mimeTypes?.videoTypes ?? []),
          ...(mimeTypes?.imageTypes ?? []),
          ...(mimeTypes?.documentTypes ?? []),
        ].filter((t) => !!t)
      ),
    ]);
  };

  private normalize = (mimeType: string): string => mimeType.toLowerCase().trim();

  public toAcceptString = (): string => {
    return this.mimeTypes.join(', ').replace(/ /g, '');
  };

  public isAllowed = (mimeType: string): boolean => {
    return (
      this.mimeTypes.find((t) => {
        if (t === '*') {
          return true;
        }
        const normalizedMimeType = this.normalize(mimeType);
        if (t.endsWith('/*')) {
          return normalizedMimeType.startsWith(t.replace('/*', '/'));
        }
        return t === normalizedMimeType;
      }) !== undefined
    );
  };
}
