import { toast } from '@lessonup/ui-components';
import { assertNever } from '@lessonup/utils';
import { getI18n } from 'react-i18next';
import { UseUploadData } from './model';
import { i18nextNamespace, UploadType } from './UploadsFeature.utils';
import { showUploadsModal } from './UploadsModalFeature';

type FileType = 'image' | 'video' | 'audio' | 'document' | 'slideDeck';

interface ImageOptions {
  allowMultiple?: boolean;
}

interface DocumentOptions {
  asAttachment?: boolean; // This will return the entire file instead of asking users to select slides.
}

type Options<T extends FileType> = T extends 'image'
  ? ImageOptions
  : T extends 'document'
  ? DocumentOptions
  : Record<string, unknown>;

export function pickFile<T extends FileType = FileType>({
  fileType,
  ...options
}: Options<T> & { fileType: T }): Promise<UseUploadData | undefined> {
  switch (fileType) {
    case 'image':
      return requestImageUpload(options);
    case 'video':
      return requestVideoUpload();
    case 'audio':
      return requestAudioUpload();
    case 'document':
      return requestDocumentUpload(options);
    case 'slideDeck':
      return requestSlideDeckUpload(options);
    default:
      assertNever(fileType, 'FileType');
  }
}

const requestImageUpload = async ({ allowMultiple }: ImageOptions) =>
  getUploadFromModal(['ImageUpload', 'DocumentUpload'], {
    allowMultiple,
  });
const requestDocumentUpload = ({ asAttachment }: DocumentOptions) =>
  getUploadFromModal(['DocumentUpload', 'ImageUpload'], { asAttachment });
const requestSlideDeckUpload = ({ asAttachment }: DocumentOptions) =>
  getUploadFromModal(['SlideDeckUpload', 'ImageUpload'], { asAttachment });
const requestVideoUpload = () => getUploadFromModal(['VideoUpload']);
const requestAudioUpload = () => getUploadFromModal(['AudioUpload']);

async function getUploadFromModal(
  filteredFileTypes: UploadType[],
  options?: Options<FileType>
): Promise<UseUploadData | undefined> {
  try {
    const result = await showUploadsModal({ fileTypeFilter: filteredFileTypes, options });
    return result;
  } catch (error) {
    const i18n = getI18n();
    toast({
      message: i18n.t('pickFile.errorMessage', { ns: i18nextNamespace }),
      type: 'error',
      title: i18n.t('pickFile.errorTitle', { ns: i18nextNamespace }),
    });
  }
}
