import { getRailLayoutPosition, isHorizontalRailLayout, RailLayout } from '@lessonup/pin-renderer';
import { color, keyframes, styled } from '@lessonup/ui-components';
import React from 'react';

interface RailProps {
  scale: number;
  railLayout: RailLayout;
}

export const Rail: React.FC<RailProps> = ({ scale, railLayout }) => {
  const position = getRailLayoutPosition(railLayout);
  const displayRange = railLayout.displayRange.max - railLayout.displayRange.min;
  const width = isHorizontalRailLayout(railLayout) ? displayRange : 0;
  const height = isHorizontalRailLayout(railLayout) ? 0 : displayRange;

  return (
    <StyledLine
      style={{
        width: `${width * scale}px`,
        height: `${height * scale}px`,
        transform: `translate(${position.x * scale}px, ${position.y * scale}px`,
      }}
    />
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledLine = styled.div`
  position: absolute;
  outline: 1px ${color.additional.focus.background} solid;
  animation: ${fadeIn} 0.2s ease-in-out;
`;
