import { AssignmentType } from './AssignmentComplete';
import { AssignmentContent } from './AssignmentContent';
import { AssignmentSettings, AssignmentSettingsType } from './AssignmentSettings';

/**
 * Assignment bestaat uit 3 onderdelen in Firestore
 *
 * AssignmentContent - inhoudelijke content
 * AssignmentMeta - Docent informatie (antwoorden) en extra info om een preview te renderen in overview lijsten
 * AssignmentSettings - Settings voor een assignment die e.v.t. kunnen veranderen en gesynct moeten worden tussen docent en teacher. Dit zat eerst in Assignment (RT player) maar leverde high memory usage op
 *
 * Assignment: combi van AssignmentContent + AssignmentSettings
 * AssignmentData: combi van Assignment + AssignmentMeta
 */
export interface Assignment<T extends AssignmentType = AssignmentType> extends AssignmentContent {
  settings: AssignmentSettingsType<T>;
  status: AssignmentSettings.Status;
  visibilityState: AssignmentSettings.Visibility;
  pincode: string | undefined;
}

export namespace Assignment {
  export type Realtime = Assignment<'realtime'>;
  export type Homework = Assignment<'homework'>;
  export type Test = Assignment<'test'>;
}

export namespace Assignment {
  export function from(content: AssignmentContent, settings: AssignmentSettings): Assignment {
    return {
      ...content,
      settings,
      status: settings.status,
      visibilityState: settings.visibility,
      pincode: settings.type === 'realtime' ? settings.pincode : undefined,
    };
  }

  export function isRealtime(assignment: Assignment): assignment is Assignment.Realtime {
    return assignment.type == 'realtime';
  }

  export function isHomework(assignment: Assignment): assignment is Assignment.Homework {
    return assignment.type === 'homework';
  }

  export function isTest(assignment: Assignment): assignment is Assignment.Test {
    return assignment.type === 'test';
  }

  export interface AssignmentByLinkCreationResult {
    type: 'not-joined' | 'not-found' | 'already-joined' | 'no-group-access';
    assignment: Assignment | undefined;
    groupName?: string;
    groupId?: string;
  }
}
