import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPresenter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.069 21.043c-2.16-.241-3.898-1.98-4.13-4.14-.17-1.595-.314-3.233-.314-4.903s.144-3.308.315-4.903c.231-2.16 1.969-3.899 4.129-4.14 1.604-.178 3.252-.332 4.931-.332s3.327.154 4.93.332c2.161.241 3.899 1.98 4.13 4.14.17 1.595.315 3.233.315 4.903s-.144 3.307-.315 4.903c-.231 2.16-1.969 3.899-4.13 4.14m-9.861 0c1.604.178 3.252.332 4.931.332s3.327-.154 4.93-.332m-9.861 0a4.6 4.6 0 0 1-.732-.143c.273-1.28 1.012-2.54 2.025-3.367a5.75 5.75 0 0 1 9.272 3.308l-.02.073q-.333.09-.683.129M12 13.866a3.28 3.28 0 1 0 0-6.56 3.28 3.28 0 0 0 0 6.56"
    />
  </svg>
);
export default SvgIconPresenter;
