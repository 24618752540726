import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconVisibilityOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.685 6.732C8.203 5.632 10.01 4.88 12 4.88c5.178 0 9.375 5.55 9.375 7.125 0 1.002-1.51 3.54-4.082 5.307M4.055 9.267c-.906 1.129-1.43 2.182-1.43 2.738 0 1.575 4.197 7.125 9.375 7.125.657 0 1.299-.09 1.918-.249M3.375 2.63c6.086 7.796 10.037 11.705 18 18"
    />
  </svg>
);
export default SvgIconVisibilityOff;
