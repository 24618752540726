import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-sse';
import { authTokenStorage } from '../auth/authTokenStorage';
import { getIntegrationConfig } from '../config/integrationConfig';
import { bearerTokenLink } from './links/bearerTokenLink';
import { cleanDataLink } from './links/cleanDataLink';
import { errorLogLink } from './links/errorLogLink';
import { reauthorizeLink } from './links/reauthorizeLink';
import { retryLink } from './links/retryLink';

const graphQLEndpoint = '/graphql';
const graphQLSubscriptionEndpoint = '/streaming-graphql';

export function createApolloClient() {
  const config = getIntegrationConfig();
  const credentials = config.includeCredentials ? 'include' : undefined;
  const httpLink = new HttpLink({
    uri: `${config.apiHost}${graphQLEndpoint}`,
    credentials,
  });

  const sseLink = new SSELink({
    url: `${config.apiHost}${graphQLSubscriptionEndpoint}`,
    credentials,
    headers: (): Record<string, string> => {
      const token = authTokenStorage.authAccessToken;
      return token ? { Authorization: `Bearer ${token}` } : {};
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    sseLink,
    httpLink
  );

  return new ApolloClient({
    // more about link chaining can be found https://www.apollographql.com/docs/react/api/link/introduction#composing-a-link-chain
    // retryLink will trigger before errorLink when there is a network error, this way we don't log the error multiple times
    link: errorLogLink
      .concat(retryLink)
      .concat(reauthorizeLink)
      .concat(bearerTokenLink)
      .concat(cleanDataLink)
      .concat(splitLink),
    cache: new InMemoryCache({
      possibleTypes: config.possibleTypes,
    }),
  });
}

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}
