import { Button, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { forwardRef } from 'react';

export interface ToolbeltItemProps {
  onClick: () => void;
  icon: React.JSX.Element;
  text: string;
}

export const ToolbeltItem = forwardRef(function ToolbeltItem(
  { onClick, icon, text }: ToolbeltItemProps,
  ref: React.ForwardedRef<HTMLLIElement>
) {
  return (
    <li ref={ref}>
      <Button
        buttonType="neutral"
        onClick={(e) => {
          onClick();
          e.currentTarget.blur();
        }}
        iconStart={icon}
        buttonLabel={text}
      />
    </li>
  );
});

export interface ToolbeltProps {
  children: React.ReactNode;
}

export const Toolbelt: React.FC<ToolbeltProps> = ({ children }) => {
  return (
    <StyledToolbelt spacing={spacing.size0} direction="x" as="ul" justifyContent="center">
      {children}
    </StyledToolbelt>
  );
};

const StyledToolbelt = styled(SpaceBetween)`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    width: ${rem('80px')};
    display: flex;
    justify-content: center;
  }
`;
