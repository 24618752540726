import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSchool = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.3 13.966H2.7m4.84-5.088v5.088m8.875-5.088v5.088m-4.416 4.36v2.913m-4.764-2.914v2.824m9.527-2.824v2.824M12 8.562v.316m-4.466.097c-1.05.034-2.11.079-3.188.136a1.46 1.46 0 0 0-1.382 1.291 41.4 41.4 0 0 0 0 9.338 1.46 1.46 0 0 0 1.382 1.292c5.237.276 10.071.276 15.308 0a1.46 1.46 0 0 0 1.381-1.292 41.4 41.4 0 0 0 0-9.337 1.46 1.46 0 0 0-1.381-1.292 153 153 0 0 0-3.19-.136c-.026-.742-.06-1.506-.104-2.308a1.49 1.49 0 0 0-.523-1.055c-1.28-1.084-2.565-2.13-3.76-2.851-1.323.717-2.629 1.745-3.925 2.853-.306.263-.491.641-.513 1.045-.043.804-.078 1.572-.105 2.316"
    />
  </svg>
);
export default SvgIconSchool;
