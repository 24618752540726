import { ComponentSize } from '../size/size.types';

export const borderRadius = {
  rounded2: '2px',
  rounded4: '4px',
  rounded6: '6px',
  rounded8: '8px',
  rounded10: '10px',
  rounded12: '12px',
  rounded16: '16px',
  rounded24: '24px',
  rounded32: '32px',
  roundedFull: '9999px',
};

/**
 *
 * @param baseValue should be a borderRadius value
 * @param size
 * @returns
 */
export function borderRadiusScaled(baseValue: string, size: ComponentSize): string {
  if (size === 'medium') {
    return `${parseFloat(baseValue) * 1.25}px`;
  }

  if (size === 'large') {
    return `${parseFloat(baseValue) * 1.5}px`;
  }

  return baseValue;
}
