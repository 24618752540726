import { create, NiceModalHocProps, show, useModal } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import type { default as CSS } from 'csstype';
import { getValueAndUnit, rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/BodyText/BodyText';
import { ManagedModal } from '../../components/Modal/ManagedModal';
import { ModalFooter, ModalFooterAction } from '../../components/Modal/ModalFooter/ModalFooter';
import { ModalHeaderV1 } from '../../components/Modal/ModalHeader/v1/ModalHeader';
import { spacing } from '../../foundations/spacing/spacing';
import { SpaceBetween } from '../SpaceBetween/SpaceBetween';

const DEFAULT_MODAL_WIDTH = '300px';

export interface ConfirmModalProps {
  title: string | React.JSX.Element;
  ariaLabel: string;
  description: string | React.JSX.Element;
  primaryAction?: ModalFooterAction;
  secondaryAction?: ModalFooterAction;
  hideSecondary?: boolean;
  canClose?: boolean;
  // Defaults to 300px
  width?: CSS.Property.Width;
}
export const ConfirmModal: React.FC<ConfirmModalProps & NiceModalHocProps> = create(
  ({
    title,
    ariaLabel,
    description,
    primaryAction,
    secondaryAction,
    hideSecondary,
    canClose = true,
    width = DEFAULT_MODAL_WIDTH,
  }: ConfirmModalProps) => {
    const modal = useModal();
    const { t } = useTranslation('confirmModal');

    const onConfirmClick = () => {
      modal.resolve(true);
      modal.hide();
    };

    const onCancelClick = () => {
      modal.resolve(false);
      modal.hide();
    };

    const onCloseClick = () => {
      modal.resolve(false);
      modal.hide();
    };

    /** @description This runs on click outside or `ESC` key press. */
    const onBeforeClose = () => {
      modal.resolve(false);
    };

    const modalWidth = getValueAndUnit(width)?.[1] === 'px' ? rem(width) : width;

    return (
      <ManagedModal modal={modal} contentLabel={ariaLabel} width={modalWidth} onBeforeClose={onBeforeClose}>
        <ModalHeaderV1
          type="headline"
          title={{ content: title, size: 'M' }}
          onCloseButtonClick={canClose ? onCloseClick : undefined}
          showDivider={false}
          overflow="wrap"
        />
        <ModalContent direction="y" spacing={spacing.size24}>
          <BodyText>{description}</BodyText>
        </ModalContent>
        <ModalFooter
          type="actions"
          primaryAction={{
            onClick: onConfirmClick,
            label: primaryAction?.label ?? t('button.accept'),
            ...primaryAction,
          }}
          secondaryAction={
            hideSecondary
              ? undefined
              : {
                  onClick: onCancelClick,
                  label: secondaryAction?.label ?? t('button.decline'),
                  ...secondaryAction,
                }
          }
        />
      </ManagedModal>
    );
  }
);

const ModalContent = styled(SpaceBetween)`
  padding: 0 ${spacing.size24};
`;

export const showConfirmModal = (props: ConfirmModalProps): Promise<boolean> => show(ConfirmModal, props);
