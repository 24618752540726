import { TextEditorHandler } from '@lessonup/pins-shared';
import { useEffect } from 'react';
import { useKeybindController } from '../../../utils/KeybindControllerContext/KeybindControllerContext';
import { TextPinComponentProps } from '../TextPinComponent.types';

/**
 * Currently we can not use element events for this kind of actions, and we also don't want to polute the global events.
 * So with this hook we only create (and then cleanup) one global event for the selected text pin component.
 */
export function useTextPinComponentKeyboardEvents(
  props: TextPinComponentProps,
  textEditorHandlerRef: React.RefObject<TextEditorHandler | null>
) {
  const { componentId, active, isTextEditMode, setModeTextEdit } = props;
  const { canUseKeybinds } = useKeybindController();

  useEffect(() => {
    if (!active) return;
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        if (isTextEditMode || !canUseKeybinds()) return;
        setModeTextEdit?.(props.componentId);
        textEditorHandlerRef.current?.focus.end();
      }
      if (event.code === 'Tab') {
        if (isTextEditMode) {
          event.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [active, canUseKeybinds, componentId, isTextEditMode, setModeTextEdit, textEditorHandlerRef, props.componentId]);
}
