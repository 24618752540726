import { ListItemProps, ListItemSize } from '@lessonup/ui-components';
import { assertNever } from '@lessonup/utils';
import { TFunction } from 'i18next';
import { GeneratedResultProps } from '../../../../../components/GeneratedResult/GeneratedResult';

export type GeneratedResultItem = Omit<GeneratedResultProps, 'selected'>;

export const createLessonOutline = ({
  selection,
  generatedResults,
  t,
}: {
  selection: string[];
  generatedResults: GeneratedResultItem[];
  t: TFunction;
}): ListItemProps[] => {
  const itemSize: ListItemSize = 'small';
  const selectedList = generatedResults.filter((item) => selection.includes(item.tempId));
  const lessonOutline = selectedList.map((slide) => {
    const text =
      slide.content.type === 'openQuestion'
        ? slide.content.question
        : slide.content.type === 'slide'
        ? slide.content.header
        : slide.content.type === 'poll'
        ? slide.content.question
        : slide.content.type === 'quiz'
        ? slide.content.question
        : slide.content.word;

    const translationKey =
      slide.content.type === 'slide' && slide.content.layout === 'cover' ? 'cover' : slide.content.type;

    return { text: `${t(translationKey)} - ${text}`, size: itemSize };
  });
  return lessonOutline;
};

export function generatedResultIsEmpty(result: GeneratedResultItem): boolean {
  const { content } = result;
  switch (content.type) {
    case 'openQuestion':
      return !content.question;
    case 'slide':
      return !content.header && !content.text;
    case 'poll':
      return !content.question && !content.answers.length;
    case 'quiz':
      return !content.question && !content.answers.length;
    case 'wordweb':
      return !content.word;
    default:
      assertNever(content, 'Unknown content type ');
  }
}
