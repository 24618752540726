import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';

export interface SwatchProps {
  swatchColor: string;
  className?: string;
}

export const Swatch: React.FC<SwatchProps> = ({ swatchColor, className }) => {
  const isTransparent = swatchColor === 'transparent';
  return (
    <StyledSwatch
      swatchColor={isTransparent ? color.additional.disabled.background : swatchColor}
      className={className}
    >
      {isTransparent && (
        <svg width="18" height="18" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M9.18195 2.81802L2.81799 9.18198"
            stroke={color.additional.errorContainer.text}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </StyledSwatch>
  );
};

const StyledSwatch = styled.div<{ swatchColor: string }>`
  background: ${(props) => props.swatchColor};
  height: ${rem('24px')};
  width: ${rem('24px')};
  border-radius: ${borderRadius.roundedFull};
  border: 1px solid ${color.additional.disabled.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;
