import { tracker } from '@lessonup/analytics';
import {
  SpinnerFocusMode,
  SpinnerFocusModeUtils,
  usePlayerDispatcher,
  usePlayerStoreAssignmentContext,
} from '@lessonup/players-modern';
import { Button, IconComponentSpinner, IconReset, rem, styled } from '@lessonup/ui-components';
import React from 'react';
import { PlayerBarBottomSection } from '../../../../../../components/PlayerBar/PlayerBarBottom/components/PlayerBarBottomModules';
import { usePlayerTranslation } from '../../../../hooks/usePlayerTranslation';

export type PlayerBarBottomSpinnerControlsProps = {
  focusMode: SpinnerFocusMode;
};

export const PlayerBarBottomSpinnerControls: React.FC<PlayerBarBottomSpinnerControlsProps> = ({ focusMode }) => {
  const dispatch = usePlayerDispatcher();
  const { t } = usePlayerTranslation();
  const { assignmentId, lessonId } = usePlayerStoreAssignmentContext();

  const spin = () => {
    tracker.events.spinnerSpin({ lessonId, assignmentId, context: 'standard' });
    dispatch({
      type: 'setFocusMode',
      focusMode: SpinnerFocusModeUtils.spinSpinnerFocusMode(focusMode),
    });
  };

  const spinWithout = () => {
    tracker.events.spinnerSpin({ lessonId, assignmentId, context: 'without' });
    dispatch({
      type: 'setFocusMode',
      focusMode: SpinnerFocusModeUtils.spinWithoutSpinnerFocusMode(focusMode),
    });
  };

  const reset = () => {
    tracker.events.spinnerReset({ lessonId, assignmentId });
    dispatch({
      type: 'setFocusMode',
      focusMode: SpinnerFocusModeUtils.resetSpinnerFocusMode(focusMode),
    });
  };

  return (
    <PlayerBarBottomSection>
      <Button
        loading={!!focusMode.isSpinning}
        buttonType="primary"
        buttonShape="rect"
        onClick={spin}
        iconStart={<IconComponentSpinner />}
      >
        {t('focusModeControlls.spinner.spin')}
      </Button>
      {focusMode.previousDrawnOption && focusMode.baseOptions.length > 2 && focusMode.spinCount > 0 && (
        <StyledButton
          disabled={focusMode.spinOptions.length === 2}
          loading={!!focusMode.isSpinning}
          buttonType="neutral"
          buttonShape="rect"
          showStroke
          onClick={spinWithout}
        >
          {t('focusModeControlls.spinner.spinWithout', {
            option: focusMode.isSpinning
              ? focusMode.previousDrawnOption
              : focusMode.spinOptions[focusMode.selectedIndex],
          })}
        </StyledButton>
      )}
      {focusMode.spinOptions.length !== focusMode.baseOptions.length && (
        <Button
          loading={!!focusMode.isSpinning}
          buttonType="neutral"
          buttonShape="rect"
          showStroke
          onClick={reset}
          iconEnd={<IconReset />}
        >
          {t('focusModeControlls.spinner.reset')}
        </Button>
      )}
    </PlayerBarBottomSection>
  );
};

const StyledButton = styled(Button)`
  width: ${rem('200px')};
  max-width: ${rem('200px')};
`;
