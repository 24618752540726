import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m2.625 13.22 8.239-9.558a1.5 1.5 0 0 1 2.272 0l8.239 9.557M5.51 9.906l5.352-6.236a1.5 1.5 0 0 1 2.276 0l5.353 6.236.39 3.396c.22 1.907.183 3.835-.11 5.733a2.15 2.15 0 0 1-2.126 1.823h-9.29a2.15 2.15 0 0 1-2.126-1.823 21.5 21.5 0 0 1-.11-5.733z"
    />
  </svg>
);
export default SvgIconHome;
