import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFileText = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.14 12.023h5.72m-5.72 4.36h5.702M9.14 7.5h3.45m7.47 10.626a98 98 0 0 0 .157-8.65 2.87 2.87 0 0 0-.55-1.625c-1.32-1.797-2.37-2.915-4.102-4.251a2.8 2.8 0 0 0-1.662-.58A87 87 0 0 0 12 3c-2.035 0-3.677.061-5.376.178a2.884 2.884 0 0 0-2.685 2.696A98 98 0 0 0 3.75 12c0 2.1.065 4.148.19 6.126a2.884 2.884 0 0 0 2.684 2.696C8.323 20.939 9.964 21 12 21a75 75 0 0 0 5.377-.178 2.884 2.884 0 0 0 2.684-2.696"
    />
  </svg>
);
export default SvgIconFileText;
