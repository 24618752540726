import { AuthStatus, logger, useAuth, useDocumentQuery } from '@lessonup/client-integration';
import { exhaustiveStringTuple } from '@lessonup/utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
  FeatureFlagsForAnonymousDocument,
  FeatureFlagsForAnonymousQuery,
  FeatureFlagsForUserDocument,
  FeatureFlagsForUserQuery,
} from './useFeatureFlag.graphql.generated';

export type AnonymousFeatureFlag = keyof FeatureFlagsForAnonymousQuery['featureFlags'];
export type UserFeatureFlag = keyof Omit<FeatureFlagsForUserQuery['viewer']['featureFlags'], '__typename'>;
export type FeatureFlag = AnonymousFeatureFlag & UserFeatureFlag;
export const featureFlags = exhaustiveStringTuple<FeatureFlag>()(
  'editorTopBarCleanup',
  'editorAllPinComponents',
  'editorShowTypographyTypePicker',
  'editorTextShadow',
  'editorBreakEditor',
  'editorSidebarLayoutSize',
  'editorSidebarLayoutPosition',
  'editorLockLayoutSetting',
  'editorUndoRedo',
  'editorPlayerV2',
  'editorPresenterMode',
  'editorOpenQuestion',
  'editorMaiaV2Lessons',
  'editorWordCloud',
  'editorVideoPinComponent',
  'editorImageCropping',
  'editorWarningBanner',
  'reportsV2',
  'editorSpinnerPinComponent',
  'playerComponentsMenu'
);
export const isValidFeatureFlag = (possibleFeatureFlag: string): possibleFeatureFlag is FeatureFlag =>
  (featureFlags as string[]).includes(possibleFeatureFlag);

export type FeatureFlagValues = Record<FeatureFlag, boolean>;
export type IsFeatureEnabled = (featureFlag: FeatureFlag) => boolean;

export function useFeatureFlags(): {
  featureFlagsValues: FeatureFlagValues;
  isFeatureEnabled: IsFeatureEnabled;
} {
  const auth = useAuth();
  const { data: dataForAnonymous, error: errorForAnonymous } = useDocumentQuery(FeatureFlagsForAnonymousDocument, {
    skip: auth.type === AuthStatus.LOGGED_IN,
  });
  const { data: dataForUser, error: errorForUser } = useDocumentQuery(FeatureFlagsForUserDocument, {
    skip: auth.type !== AuthStatus.LOGGED_IN,
  });

  const featureFlagsValues = useMemo(() => {
    return featureFlags.reduce((acc, name) => {
      acc[name] = dataForAnonymous?.featureFlags?.[name] === true || dataForUser?.viewer.featureFlags?.[name] === true;
      return acc;
    }, {} as FeatureFlagValues);
  }, [dataForAnonymous, dataForUser]);

  useEffect(() => {
    if (errorForAnonymous) logger.error(errorForAnonymous);
  }, [errorForAnonymous]);

  useEffect(() => {
    if (errorForUser) logger.error(errorForUser);
  }, [errorForUser]);

  const isFeatureEnabled: IsFeatureEnabled = useCallback(
    (featureFlag) => {
      return featureFlagsValues[featureFlag] === true;
    },
    [featureFlagsValues]
  );

  return {
    featureFlagsValues,

    /**
     * Returns whether a feature flag is enabled or not. Works for both anonymous and authenticated users.
     */
    isFeatureEnabled,
  };
}
