let injected = false;

export function injectSvgDefinitions() {
  if (injected) return;
  const htmlString = `
  <svg width="0" height="0" display="block">
  <defs>
    <pattern preserveAspectRatio="none" id="chevron-pattern" viewBox="0 0 120 120" width="15%" height="15%">
      <polygon fill="black" fill-opacity="0.65" points="120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120"/>
    </pattern>
    <pattern preserveAspectRatio="none" id="line-pattern" viewBox="0 0 20 20" width="20%" height="20%">
      <g>
        <polygon fill="black" fill-opacity="0.65" points="20 10 10 0 0 0 20 20"/>
        <polygon fill="black" fill-opacity="0.65" points="0 10 0 20 10 20"/>
      </g>
    </pattern>
    <pattern preserveAspectRatio="none" id="block-pattern" viewBox="0 0 200 200" width="20%" height="20%">
      <g fill="black" fill-opacity="0.6">
        <rect x="100" width="100" height="100"/>
        <rect y="100" width="100" height="100"/>
      </g>
    </pattern>
  </defs>
  </svg>`;
  const node = createSvgDefinitions(htmlString);
  if (node) document.body.appendChild(node);
  injected = true;
}

function createSvgDefinitions(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild;
}
