import { QuizPinSettings } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { PinBackground } from '../components/PinBackground';

export interface QuizPinProps {
  pinSettings: QuizPinSettings;
  isThumb?: boolean;
  children?: React.ReactNode;
}

export const QuizPinComponent: React.FC<QuizPinProps> = ({ pinSettings, isThumb, children }) => {
  return (
    <QuizWrapper>
      <PinBackground
        isThumb={isThumb}
        backgroundColor={pinSettings.backgroundColor || 'blue'}
        backgroundImage={pinSettings.backgroundImage ?? undefined}
        backgroundTransparency={pinSettings.backgroundTransparency}
      />
      {children}
    </QuizWrapper>
  );
};

const QuizWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
