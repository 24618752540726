import { AppError } from '@lessonup/utils';
import _ from 'lodash';
import { AssignmentMeta, AssignmentType } from '../../assignment';
import { HasNoCorrectAnswer } from '../../results';

export namespace QuizQuestion {
  export const type = 'question';

  export type AnyAssignmentAnswerType = AnyLessonContentType | AnswerTypeText | AnswerTypeEmpty;

  export type AnyLessonContentType = AnswerTypeFormula | AnswerTypeAudio | AnswerTypeImage;

  export type AllAnswerKeys = 'answer1' | 'answer2' | 'answer3' | 'answer4';

  export type AnswerValue = 1 | 2 | 3 | 4;

  export interface AnswerTypeEmpty {
    type: 'empty';
    value: AnswerValue;
  }

  export interface AnswerTypeFormula {
    type: 'formula';
    html: string;
    katex: string;
    xml: string;
    origin: string;
    value: AnswerValue;
  }

  export interface AnswerTypeAudio {
    type: 'audio';
    url: string;
    uploadId?: string;
    color?: string;
    value: AnswerValue;
  }

  export interface AnswerTypeImage {
    type: 'image';
    url: string;
    uploadId?: string;
    value: AnswerValue;
  }

  export interface AnswerTypeText {
    type: 'text';
    text: string;
    value: AnswerValue;
  }

  export interface AssignmentContent extends Content {
    hasAnswer: boolean;
    answers: Array<QuizQuestion.AnyAssignmentAnswerType>;
    timer?: number;
  }

  export interface LessonContent extends Content {
    correctAnswer: number[] | number;
    answer1?: string;
    answer2?: string;
    answer3?: string;
    answer4?: string;

    answerData?: {
      [key in QuizQuestion.AllAnswerKeys]?: QuizQuestion.AnyLessonContentType;
    };
  }

  export interface Content {
    showImage?: boolean;
    question: string;
    file?: string;
    description?: string;

    fontSize1?: number;
    fontSize2?: number;
    align?: string;
    layout?: number;
    color?: number;
    colorbg?: string;
    colorfg?: string;
    imageSize?: 'contain' | 'cover';
    opacity?: number;
  }

  interface QuizOptions {
    id: number;
    value: string;
  }

  export class Content {
    static hasAnswer(content: Content, number: number): boolean {
      return !_.isEmpty(Content.answerByNumber(content, number));
    }

    static hasCorrectAnswerLessonPin(content: LessonContent): boolean {
      if (Array.isArray(content.correctAnswer)) return !_.isEmpty(content.correctAnswer);
      return content.correctAnswer > 0;
    }

    static getAnswerLetterByIndex(index: number): string {
      const numToChar = ['A', 'B', 'C', 'D'];
      return numToChar[index];
    }

    static getAnswerLetterByNumber(number: QuizQuestion.AnswerValue): string {
      const numToChar = ['A', 'B', 'C', 'D'];
      return numToChar[number - 1];
    }

    static getAnswerNumber(letter: string): 1 | 2 | 3 | 4 | undefined {
      const charToNum: _.Dictionary<1 | 2 | 3 | 4> = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
      };
      return charToNum[letter];
    }

    static answerByNumber(content: Content, number: number): string {
      const prop = `answer${number}`;
      const answerText = _.get(content, prop);
      const answerFormula = _.get(content, `answerData.${prop}.html`);

      return answerText || answerFormula;
    }
  }
}

// Answer
export namespace QuizQuestion {
  export interface Answer {
    values: AnswerValue[];
    modifiedAt: number;
  }

  interface FilledAnswerResult {
    type: 'correct' | 'incorrect' | 'opinion';
    usedAnswer: AnswerValue;
    correctAnswer: AnswerValue[];
  }

  interface EmptyAnswerResult {
    type: 'noAnswer';
    correctAnswer: AnswerValue[];
  }

  export type AnswerResult = FilledAnswerResult | EmptyAnswerResult;

  // also edit isCorrectAnswer after change
  export type CorrectAnswer = AnswerValue[];

  export namespace Answer {
    export function isDone(answer: Answer | undefined): boolean {
      return answer ? !_.isNil(latestAnswer(answer)) : false;
    }

    export function answerIsCorrect(answerValue: AnswerValue, correctAnswer: CorrectAnswer) {
      return _.some(correctAnswer, (value) => value == answerValue);
    }

    export function isAnswerValue(value: number): value is AnswerValue {
      if (typeof value !== 'number') return false;
      return [1, 2, 3, 4].includes(value);
    }

    export function validateAnswer(value: number): asserts value is AnswerValue {
      if (!isAnswerValue(value)) throw new AppError('invalid-params', 'is not an answer');
    }

    export function areAnswerValues(answers: any): answers is AnswerValue[] {
      return Array.isArray(answers) && answers.every((a) => isAnswerValue(a));
    }

    export function correctAfterTries(answer: Answer | undefined, correctAnswer: CorrectAnswer): number | undefined {
      const values = (answer && answer.values) || [];
      const index = values.findIndex((v) => answerIsCorrect(v, correctAnswer));
      return index > -1 ? index + 1 : undefined;
    }

    export function previousAnswer(answer: Answer | undefined): AnswerValue | undefined {
      const values = (answer && answer.values) || [];
      return values[values.length - 2];
    }

    export function latestAnswer(answer: Answer | undefined): AnswerValue | undefined {
      const values = (answer && answer.values) || [];
      return values[values.length - 1];
    }

    export function firstAnswer(answer: Answer | undefined): AnswerValue | undefined {
      const values = (answer && answer.values) || [];
      return values[0];
    }

    export function testPointsForResult(maxPoints: number, result?: AnswerResult): number {
      if (result && result.type === 'correct') return maxPoints;
      return 0;
    }

    export function rankingPointsForResult(result?: AnswerResult): number {
      if (result && result.type === 'correct') return 1;
      return 0;
    }

    export function resultForAnswer(
      current: Answer | undefined,
      correctAnswer: CorrectAnswer | HasNoCorrectAnswer,
      assignmentMode: AssignmentType
    ): AnswerResult {
      const usedAnswer = latestAnswer(current);

      if (!usedAnswer) {
        return { type: 'noAnswer', correctAnswer: correctAnswer === 'hasNoCorrectAnswer' ? [] : correctAnswer };
      }

      if (correctAnswer === 'hasNoCorrectAnswer') {
        const opinionAnswer = latestAnswer(current) as AnswerValue; // cant be empty
        return { type: 'opinion', usedAnswer: opinionAnswer, correctAnswer: [] };
      }

      const isCorrect = answerIsCorrect(usedAnswer, correctAnswer);
      return { type: isCorrect ? 'correct' : 'incorrect', usedAnswer, correctAnswer };
    }

    export function convertAssignmentPinAnswersToLessonPinAnswers(
      pinId: string,
      content: QuizQuestion.AssignmentContent,
      assignmentMeta: AssignmentMeta
    ): Partial<QuizQuestion.LessonContent> {
      const returnObj = { answerData: {} };
      content.answers.forEach((answer, index) => {
        switch (answer.type) {
          case 'empty':
            return;
          case 'text':
            returnObj[`answer${index + 1}`] = answer.text;
            break;
          default:
            returnObj.answerData[`answer${index + 1}`] = answer;
        }
      });
      if (content.hasAnswer) {
        returnObj['correctAnswer'] = AssignmentMeta.correctAnswerForPin(assignmentMeta, pinId);
      }
      return returnObj;
    }

    export function parseAnswerNumberToLetter(answer: Answer) {
      const latest = latestAnswer(answer) || undefined;
      return latest && _.isNumber(latest) && ['A', 'B', 'C', 'D'][latest - 1];
    }
  }
}
