import { useLocale } from '@lessonup/client-integration';
import {
  Banner,
  BodyText,
  Button,
  color,
  IconFile,
  Introduction,
  SelectFormField,
  SpaceBetween,
  spacing,
  styled,
  TextAreaFormField,
  UseFormRegister,
  UseFormWatch,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodyAsideLayout } from '../../../../../components/FullPageFlow/FullPageBodyAsideLayout';
import {
  FullPageBodySection,
  FullPageBodySectionTransparent,
} from '../../../../../components/FullPageFlow/FullPageBodySection';
import { FullPageBodyAside } from '../../../../../components/FullPageFlow/FullPageFlowAside';
import { Language, MaiaExample } from '../../../../../types/graphql/types.generated';
import { getLanguageOptions } from '../../../dataComponents/utils';
import { MaiaExamples } from '../../MaiaExamples';
import { SidebarChooseMaiaMode } from '../../SidebarChooseMaiaMode';

export interface ContextFormInputs {
  prompt?: string;
  language: Language;
}

interface ContextLessonPromptStepProps {
  register: UseFormRegister<ContextFormInputs>;
  watch: UseFormWatch<ContextFormInputs>;
  promptCharacterLimit: number;
  promptMinCharacterLimit: number;
  maiaExamples?: MaiaExample[];
  onExamplesClick: (examplePrompt: string) => void;
  handleMaiaModeSwitch: () => void;
}

export const ContextLessonPromptStep = ({
  register,
  watch,
  promptCharacterLimit,
  promptMinCharacterLimit,
  maiaExamples,
  onExamplesClick,
  handleMaiaModeSwitch,
}: ContextLessonPromptStepProps) => {
  const { t } = useTranslation('chatGPT');
  const language = useLocale().language;
  const languageOptions = getLanguageOptions(t);
  const promptCharacterLimitForWarning = promptCharacterLimit - 500;
  const promptInputLength = watch('prompt')?.length ?? 0;

  return (
    <div>
      <FullPageBodySectionTransparent>
        <Introduction
          alignment="center"
          size="M"
          headline={t('contextStep.title')}
          bodyText={t('contextStep.subTitle')}
        />
      </FullPageBodySectionTransparent>

      <FullPageBodyAsideLayout mainLabel={t('contextStep.mainLabel')} asideLabel={t('optionalSettings')}>
        <FullPageBodySection>
          <FullWidth>
            <SpaceBetween direction="y" spacing={spacing.size16}>
              <div>
                <BodyText size="medium" weight="bold">
                  {t('contextStep.inputTitle')}
                </BodyText>

                <StyledSubText size="small">{t('contextStep.inputSubTitle')}</StyledSubText>
              </div>
              <SpaceBetween direction="y" spacing={spacing.size32}>
                <StyledTextAreaFormField
                  label={t('contextStep.inputTitle')}
                  hideLabel
                  placeholder={t('contextStep.inputPromptExample')}
                  {...register('prompt')}
                  validation={{
                    state:
                      (promptInputLength > 0 && promptInputLength < promptMinCharacterLimit) ||
                      promptInputLength > promptCharacterLimit
                        ? 'error'
                        : 'neutral',
                    message:
                      promptInputLength < promptMinCharacterLimit && promptInputLength > 0
                        ? t('promptCharactersMinWarning', { promptInputLength, promptMinCharacterLimit })
                        : promptInputLength >= promptCharacterLimitForWarning
                        ? t('promptCharactersWarning', { promptInputLength, promptCharacterLimit })
                        : '',
                  }}
                />
                {promptInputLength < promptMinCharacterLimit && promptInputLength > 0 && (
                  <Banner
                    priority="medium"
                    orientation="vertical"
                    alignment="start"
                    actionElement={
                      <Button onClick={handleMaiaModeSwitch} buttonType="neutral" iconStart={<IconFile />}>
                        {t('contextStep.switchMaiaModeCTA')}
                      </Button>
                    }
                    paragraph={t('contextStep.switchMaiaModeWarning')}
                  />
                )}
              </SpaceBetween>
              {maiaExamples && (
                <FullWidth>
                  <MaiaExamples
                    exampleType="contextLesson"
                    maiaExamples={maiaExamples}
                    onExamplesClick={onExamplesClick}
                  />
                </FullWidth>
              )}
            </SpaceBetween>
          </FullWidth>
        </FullPageBodySection>
        <FullPageBodyAside>
          <SidebarChooseMaiaMode activeLessonType="contextLesson" handleMaiaModeSwitch={handleMaiaModeSwitch} />
          <StyledPickerWrapper>
            <SpaceBetween direction="y" spacing={spacing.size8}>
              <div>
                <BodyText size="medium" weight="bold">
                  {t('languageOption')}
                </BodyText>
                <StyledSubText size="small">{t('languageOptionDescription')}</StyledSubText>
              </div>
              <SelectFormField
                orientation="vertical"
                hideLabel
                label={t('languageOption')}
                {...register('language')}
                options={languageOptions}
                defaultValue={language.toUpperCase()}
              />
            </SpaceBetween>
          </StyledPickerWrapper>
        </FullPageBodyAside>
      </FullPageBodyAsideLayout>
    </div>
  );
};

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;

const StyledPickerWrapper = styled.div`
  padding: ${spacing.size16} 0;
`;

const StyledTextAreaFormField = styled(TextAreaFormField)`
  width: 100%;
  height: 40vh;
`;

const FullWidth = styled.div`
  width: 100%;
`;
