import { ManagedModal, ModalHeaderV1, NiceModal, NiceModalHocProps, rem, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortingDirection, UploadsSortingField } from '../../types/graphql';
import { UseUploadData } from './model';
import { UploadsFeature } from './UploadsFeature';
import { i18nextNamespace, UploadType } from './UploadsFeature.utils';

export interface UploadsCallFeatureModalProps {
  folderId?: string;
  sortBy?: UploadsSortingField;
  direction?: SortingDirection;
  translationKeyPrefix?: string;
  fileTypeFilter?: UploadType[];
  options?: {
    allowMultiple?: boolean;
    asAttachment?: boolean;
  };
}

const modalWidth = rem('960px');
const modalHeight = rem('700px');

export const UploadsModalFeature: React.FC<UploadsCallFeatureModalProps & NiceModalHocProps> = NiceModal.create(
  ({ translationKeyPrefix, folderId, sortBy, direction, fileTypeFilter, options }: UploadsCallFeatureModalProps) => {
    const modal = NiceModal.useModal();
    const { t } = useTranslation(i18nextNamespace);
    const prefix = translationKeyPrefix ?? 'default';

    const handleAdd = (upload: UseUploadData) => {
      modal.resolve(upload);
      modal.hide();
    };

    const handleClose = () => {
      modal.resolve(undefined);
      modal.hide();
    };

    return (
      <ManagedModal
        modal={modal}
        contentLabel={t(`uploadsExplorerModal.${prefix}.ariaLabel`)}
        width={modalWidth}
        height={modalHeight}
      >
        <ModalHeaderV1
          type="headline"
          title={{ content: t(`uploadsExplorerModal.${prefix}.title`) }}
          onCloseButtonClick={handleClose}
        />
        <UploadsFeatureWrapper>
          <UploadsFeature
            folderId={folderId}
            hasInternalRouter={true}
            sorting={{ field: sortBy, direction }}
            fileTypeFilter={fileTypeFilter || []}
            tableInteractivity={false}
            options={options}
            previewActions={{
              primary: {
                label: t(`uploadsExplorerModal.${prefix}.add`),
                onClick: handleAdd,
              },
            }}
          />
        </UploadsFeatureWrapper>
      </ManagedModal>
    );
  }
);

const UploadsFeatureWrapper = styled.div`
  height: 90%;
  width: ${modalWidth};
  max-width: 100%;
  display: flex;
`;

export const showUploadsModal = <T extends UseUploadData = UseUploadData>(
  props: UploadsCallFeatureModalProps
): Promise<T | undefined> => {
  return NiceModal.show(UploadsModalFeature, {
    sortBy: props.sortBy,
    direction: props.direction,
    ...props,
  });
};
