import { ModalFooter, ModalHeaderV1 } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface OptOutConfirmationModalContentProps {
  onConfirm: () => Promise<void>;
  onClose: () => void;
  loading: boolean;
}

export const OptOutConfirmationModalContent = ({
  onConfirm,
  onClose,
  loading,
}: OptOutConfirmationModalContentProps) => {
  const { t: tWithoutPrefix } = useTranslation('accountDeletion');
  const t = (key: string) => tWithoutPrefix(`optOutConfirmationModalContent.${key}`);

  return (
    <>
      <ModalHeaderV1 type="headline" title={{ content: t('title') }} onCloseButtonClick={onClose} showDivider={false} />

      <ModalFooter
        type="actions"
        primaryAction={{
          onClick: onConfirm,
          label: t('button.submit'),
          loading,
        }}
        secondaryAction={{
          onClick: onClose,
          label: t('button.cancel'),
          loading,
        }}
      />
    </>
  );
};
