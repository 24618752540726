import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAssetLicenseStatusPro = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={22} fill="none" viewBox="0 0 28 22" {...props}>
    <g fillRule="evenodd">
      <path fill="#F8C82D" d="M6 22h16l4-12-6 4-6-12-6 12-6-4z" />
      <path fill="#E0B21E" d="M14 22h8l4-12-6 4-6-12z" />
      <circle cx={26} cy={10} r={2} fill="#F8C82D" />
      <circle cx={14} cy={2} r={2} fill="#F8C82D" />
      <circle cx={2} cy={10} r={2} fill="#F8C82D" />
      <path fill="#EC484D" d="m14 12-2 3 2 3 2-3z" />
    </g>
  </svg>
);
export default SvgAssetLicenseStatusPro;
