import { tracker } from '@lessonup/analytics';
import { CopyText, styled, Tab, TabList } from '@lessonup/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode } from '../../../../components/QRCode';
import { addUtmParamsToLink, REFERRAL_MEDIUM } from '../../TeacherReferral.utils';

export interface ReferralTabsProps {
  link: string;
  token: string;
}

const TRANSLATION_NAMESPACE = 'referralTabs';

export const ReferralTabs = (props: ReferralTabsProps) => {
  const { link, token } = props;
  const [activeTab, setActiveTab] = useState('copyLink');
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  const trackCopyEvent = () => tracker.events.referralLinkCopied({ referralToken: token });

  return (
    <>
      <TabList resizing="fixed">
        {['copyLink', 'qrCode'].map((tab) => (
          <Tab
            key={`tab-${tab}`}
            as="button"
            onClick={() => setActiveTab(tab)}
            isActive={activeTab === tab}
            content={t(`tabList.${tab}`)}
            size="large"
          />
        ))}
      </TabList>

      {activeTab === 'copyLink' ? (
        <CopyText
          text={addUtmParamsToLink(link, REFERRAL_MEDIUM.SHARED_LINK)}
          tooltipText={t('tabList.copyTooltip')}
          tooltipDirection="top"
          onCopy={trackCopyEvent}
        />
      ) : (
        <QRCodeWrapper>
          <QRCode value={addUtmParamsToLink(link, REFERRAL_MEDIUM.QR_CODE)} />
        </QRCodeWrapper>
      )}
    </>
  );
};

const QRCodeWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
`;
