import { useUpdatePin } from '@lessonup/pin-renderer';
import {
  Button,
  IconAdd,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  rem,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React, { useRef } from 'react';
import { SetActivePinId } from '../../context/EditorContext/EditorContext.types';
import { useEditorTranslation } from '../../hooks/useEditorTranslation';
import { TempAddPinModalPopover } from '../EditorModals/AddPinModal/AddPinModal';

interface AddPinButtonProps {
  lessonId: string;
  setActivePinId: SetActivePinId;
  addAfterPinId?: string;
}

export const AddPinButton: React.FC<AddPinButtonProps> = ({ lessonId, setActivePinId, addAfterPinId }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useEditorTranslation();
  const modal = useModal(TempAddPinModalPopover);
  const dispatch = useUpdatePin();
  const direction: ModalPopoverDirection = 'right';
  const modalAnchor: ModalPopoverAnchor = 'start';
  const parentSelector = () => buttonRef.current;
  return (
    <StyledWrapper>
      <Button
        ref={buttonRef}
        buttonType="neutral"
        showStroke
        resizing="fixed"
        onClick={() =>
          modal.show({
            lessonId,
            direction,
            modalAnchor,
            parentSelector,
            dispatch,
            setActivePinId,
            addBehindPinOrPhaseNumber: addAfterPinId,
          })
        }
        iconStart={<IconAdd />}
      >
        {t('addPin.addButton')}
      </Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  padding: 0 ${spacing.size16};
  width: ${rem('200px')};
`;
