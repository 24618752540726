import { BodyText, Headline, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';
import { PasswordForm } from '../PasswordForm/PasswordForm';

export interface RegistrationPasswordProps {
  onSubmit: (params: { password: string }) => Promise<void>;
  onValidatePassword: (password: string) => boolean;
  loading: boolean;
}

export const RegistrationPasswordStep = ({ onSubmit, ...formProps }: RegistrationPasswordProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'passwordStep');

  return (
    <div>
      <SpaceBetween spacing={spacing.size32} direction="y" alignItems="center">
        <Headline size="large">{t('title')}</Headline>
        <BodyText>{t('paragraph')}</BodyText>
        <FormWrapper>
          <PasswordForm onSubmit={onSubmit} {...formProps} />
        </FormWrapper>
      </SpaceBetween>
    </div>
  );
};

const FormWrapper = styled.div`
  width: 100%;
  max-width: 25rem;
`;
