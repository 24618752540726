import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.386 5.393c-.05-1.378-.905-2.51-2.022-2.618a33 33 0 0 0-3.13-.15c-1.067 0-2.113.051-3.128.15-1.117.108-1.971 1.24-2.022 2.618-.078 2.13-.119 4.34-.119 6.607s.041 4.477.12 6.607c.05 1.378.904 2.51 2.021 2.619q1.524.148 3.129.149a33 33 0 0 0 3.129-.15c1.117-.108 1.971-1.24 2.022-2.618m3.342-2.165c1.89-1.548 2.834-2.492 4.307-4.306-1.473-1.815-2.417-2.76-4.307-4.307m-9.035 4.307h13.294"
    />
  </svg>
);
export default SvgIconLogout;
