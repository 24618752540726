import { ChildUploadFolder } from '../../Uploads.graphql';
import { isStuckIfMoreThan, isUpload, isUploadFolder } from '../../UploadsFeature.utils';

export interface RowData {
  folderIDs: string[];
  uploadIDs: string[];
}

type buttonNames = 'copy' | 'move' | 'rename' | 'download';

export const listOfButtonsToShowForSelection = (uploadList: ChildUploadFolder[]): buttonNames[] => {
  const folders = uploadList.filter(isUploadFolder);
  const isUploadStuck = isStuckIfMoreThan(2);
  const anyUploadIsStuck = uploadList.some((upload) => isUpload(upload) && isUploadStuck(upload));
  const shouldShowMoveButton = uploadList.length > 0 && !anyUploadIsStuck;
  const shouldShowRenameButton = uploadList.length === 1 && !anyUploadIsStuck;
  const shouldShowDownloadButton = !folders.length && uploadList.length === 1 && !anyUploadIsStuck;

  const buttons: buttonNames[] = [];

  if (shouldShowMoveButton) {
    buttons.push('move');
  }
  if (shouldShowRenameButton) {
    buttons.push('rename');
  }
  if (shouldShowDownloadButton) {
    buttons.push('download');
  }

  return buttons;
};
