import { mapValues } from 'lodash';
import { rem } from 'polished';

// all breakpoints are used with @media(max-width: $breakpoint)
export const breakpoints = {
  xs: '480px',
  renderSubscriptionCardsHorizontally: '1050px',
  topBarNavFullscreen: '1023px',
  tablet: '768px',
  maxWidthText: '912px',
  breakPoint100: '320px',
  breakPoint500: '768px',
  breakPoint900: '1280px',
};

export const breakpointsRem = mapValues(breakpoints, (val) => rem(val));
