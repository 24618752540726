import { ActionsWithParam, ActionWithoutParam, TrackerAction, TrackerParams } from '@lessonup/utils';
import { globalTracker, TrackActionConfig } from './ActionTracker';

// overload track for easy sending of events without params
/** @deprecated Use tracker from \@lessonup/analytics package instead */
export function trackAction<K extends ActionWithoutParam>(action: K, params?: TrackActionConfig): void;
/** @deprecated Use tracker from \@lessonup/analytics package instead */
export function trackAction<K extends ActionsWithParam>(action: K, params: TrackerParams<K> & TrackActionConfig): void;
/** @deprecated Use tracker from \@lessonup/analytics package instead */
export function trackAction<K extends TrackerAction>(action: K, params?: TrackerParams<K> & TrackActionConfig): void {
  return globalTracker.event(action, params);
}
