import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { assertNever, PartialPrefix } from '@lessonup/utils';
import React, { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import { font } from '../../foundations/typography/fonts';
import { fontSize, fontWeight, lineHeight } from '../../foundations/typography/typography.utils';

export type BodyTextSize = 'large' | 'medium' | 'small';
export type BodyTextWeight = 'bold' | 'semiBold' | 'regular';

export interface BodyTextProps extends HTMLAttributes<HTMLDivElement> {
  size?: BodyTextSize;
  weight?: BodyTextWeight;
  as?: keyof React.JSX.IntrinsicElements;
  strikeThrough?: boolean;
  ellipsis?: boolean;
}

type BodyTextStyledProps = PartialPrefix<BodyTextProps, 'size'>;

// Deprecated, use TextElement instead
export const BodyText = forwardRef(function BodyText(
  { size = 'medium', weight = 'regular', children, ...rest }: PropsWithChildren<BodyTextProps>,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <StyledBodyText $size={size} weight={weight} {...rest} ref={ref}>
      {children}
    </StyledBodyText>
  );
});

const StyledBodyText = styled.div<BodyTextStyledProps>`
  ${(props) => {
    switch (props.$size) {
      case 'large':
        return css`
          font-size: ${fontSize.bodyTextSizeLarge};
          line-height: ${lineHeight.bodyTextSizeLarge};
        `;
      case 'medium':
        return css`
          font-size: ${fontSize.bodyTextSizeMedium};
          line-height: ${lineHeight.bodyTextSizeMedium};
        `;
      case 'small':
        return css`
          font-size: ${fontSize.bodyTextSizeSmall};
          line-height: ${lineHeight.bodyTextSizeSmall};
        `;
      default:
        if (props.$size) assertNever(props.$size, 'Unsupported size for BodyText');
    }
  }}

  ${(props) => {
    switch (props.weight) {
      case 'bold':
        return `font-weight: ${fontWeight.extraBold};`;
      case 'semiBold':
        return `font-weight: ${fontWeight.bold};`;
      case 'regular':
        return `font-weight: ${fontWeight.regular};`;
    }
  }}

  font-family: ${font.body.cssValue};
  font-style: normal;

  :is(p),
  p {
    margin: 0; // reset browser styling, needed here because old Meteor application relies on browser p styling :(. Should be in DefaultDesignSystemStyles.tsx.
  }

  ${(props) => {
    if (props.strikeThrough) {
      return 'text-decoration: line-through;';
    }
  }}

  ${(props) => {
    if (props.ellipsis) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      `;
    }
  }}
`;
