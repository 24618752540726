import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconOpenInNew = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.364 5.636 5.636 18.364M18.364 5.636c.336 3.236.336 5.094 0 8.477m0-8.477c-3.235-.336-5.094-.336-8.477 0"
    />
  </svg>
);
export default SvgIconOpenInNew;
