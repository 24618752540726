import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPageLibrary = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21.375c-1.078 0-1.087-.784-2.016-1.453-1.615-1.164-4.098-2.534-6.075-2.802a1.443 1.443 0 0 1-1.284-1.442V4.068a1.442 1.442 0 0 1 1.644-1.429A13.4 13.4 0 0 1 12 6.851m0 14.524V6.851m0 14.524c1.078 0 1.087-.784 2.016-1.453 1.615-1.164 4.098-2.534 6.075-2.802a1.44 1.44 0 0 0 1.284-1.442V4.068a1.442 1.442 0 0 0-1.644-1.429A13.4 13.4 0 0 0 12 6.851"
    />
  </svg>
);
export default SvgIconPageLibrary;
