import {
  createModal,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  NiceModal,
  styled,
  useModal,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { ImagePickerUploadSection } from './components/ImagePickerUploadSection';
import { HandleAddImage, ImagePickerTab, ModalContentOrientation, PickedImageParams } from './imagePicker.types';
import { ImagePickerInputSection } from './ImagePickerInputSection';

interface ImagePickerModalPopoverContentProps {
  contentOrientation: 'horizontal' | 'vertical';
  handleAddImage: HandleAddImage;
  t: TFunction;
  acceptMultipleFiles?: boolean;
}

export const ImagePickerModalPopoverContent: React.FC<ImagePickerModalPopoverContentProps> = ({
  contentOrientation,
  handleAddImage,
  acceptMultipleFiles,
  t,
}) => {
  const [activeTab, setActiveTab] = useState<ImagePickerTab>('upload');

  const handleTabChange = (tab: ImagePickerTab) => {
    setActiveTab(tab);
  };

  const sideSectionContent =
    activeTab === 'upload' ? (
      <ImagePickerUploadSection acceptMultipleFiles={acceptMultipleFiles} handleAddImage={handleAddImage} t={t} />
    ) : (
      <></>
    );

  // const resultSectionContent = activeTab === 'upload' ? <ImagePickerImageHistoryPlaceholder t={t} /> : <></>;

  // const resultSection = (
  //   <ImagePickerResultSection contentOrientation={contentOrientation}>{resultSectionContent}</ImagePickerResultSection>
  // );

  return (
    <StyledModalContent contentOrientation={contentOrientation}>
      <ImagePickerInputSection activeTab={activeTab} handleTabChange={handleTabChange}>
        {sideSectionContent}
      </ImagePickerInputSection>
      {/* ToDo: This section is disabled until we have search history or web results feature */}
      {/* {contentOrientation === 'horizontal' ? (
        <Divider orientation="vertical">{resultSection}</Divider>
      ) : (
        <>
          <Divider orientation="horizontal" />
          {resultSection}
        </>
      )} */}
    </StyledModalContent>
  );
};

export interface ImagePickerModalPopoverProps {
  modalDirection: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  modalContentOrientation: ModalContentOrientation;
  parentSelector: () => HTMLLIElement | HTMLButtonElement | null;
  t: TFunction;
  acceptMultipleFiles?: boolean;
}

export const ImagePickerModalPopover = createModal(
  ({
    modalDirection,
    modalAnchor,
    modalContentOrientation,
    parentSelector,
    t,
    acceptMultipleFiles,
  }: ImagePickerModalPopoverProps): React.JSX.Element => {
    const modal = useModal();

    const handleAddImage: HandleAddImage = (params) => {
      modal.resolve(params);
      modal.hide();
    };

    return (
      <ModalPopover
        modal={modal}
        contentLabel={t('imagePicker.ariaLabel')}
        onBeforeClose={modal.reject}
        direction={modalDirection}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
      >
        <ImagePickerModalPopoverContent
          contentOrientation={modalContentOrientation}
          handleAddImage={handleAddImage}
          t={t}
          acceptMultipleFiles={acceptMultipleFiles}
        />
      </ModalPopover>
    );
  }
);

export const showImagePickerModalPopover = (props: ImagePickerModalPopoverProps): Promise<PickedImageParams> =>
  NiceModal.show(ImagePickerModalPopover, props);

const StyledModalContent = styled.div<{ contentOrientation: 'horizontal' | 'vertical' }>`
  display: flex;
  ${({ contentOrientation }) =>
    contentOrientation === 'horizontal'
      ? `
    flex-direction: row;
  `
      : `
    flex-direction: column;
  `}
`;
