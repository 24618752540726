import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 13.5 4.364 4.5C12.417 12.102 14.127 9.513 18 6"
    />
  </svg>
);
export default SvgIconCheck;
