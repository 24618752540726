import { Button, Tag } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import { ComponentProps } from 'react';
import { ShowAsType, typesForMoreInfo } from '../TeachingCreditsIndicatorFeature.utils';

export const shouldWarn = (credits: number): boolean => credits > 0 && credits < 4;

export const buttonType = (credits: number): ComponentProps<typeof Button>['buttonType'] => {
  if (credits === 0) return 'negative';
  if (shouldWarn(credits)) return 'warning';
  return 'secondary';
};

export const getPriority = (
  credits: number,
  isLoading: boolean | undefined
): ComponentProps<typeof Tag>['priority'] => {
  if (isLoading) return 'low';
  if (credits === 0) return 'error';
  if (shouldWarn(credits)) return 'warning';
  return 'medium';
};

export const tooltipText = (t: TFunction, credits: number, loading?: boolean, showAs?: ShowAsType): string => {
  if (loading) return '';

  const translationKey =
    credits === 0 ? 'tooltipTextNoCredits' : shouldWarn(credits) ? 'tooltipTextWarning' : 'tooltipText';

  const tooltipText = t(translationKey, { count: credits });

  return tooltipText + (!showAs || typesForMoreInfo.includes(showAs) ? ` ${t('moreInfoPrompt')}` : '');
};
