import { stripTrailingSlash } from '@lessonup/utils';
import _ from 'lodash';

export namespace Fonts {
  const cloudFonts = '//cloud.typography.com/6162672/684584/css/fonts.css';
  const comicNeue = '/fonts/comicNeue.css';
  const primaryFontsUrl = '/fonts/primaryFonts.css';
  const eyeFonts = '/fonts/fonts-eye.css';

  export const fontNames = {
    andada: 'Andana',
    arial: 'Arial',
    'campton-bold': 'Campton Bold',
    comicneue: 'Comic Neue',
    courier: 'Courier',
    dosis: 'Dosis',
    georgia: 'Georgia',
    gotham: 'Gotham',
    impact: 'Impact',
    lucida: 'Lucida',
    montserrat: 'Montserrat',
    kreon: 'Kreon',
    odin: 'Odin',
    'open-sans': 'Open Sans',
    palatino: 'Palatino',
    pangolin: 'Pangolin',
    roboto: 'Roboto',
    sanfrancisco: 'San Francisco',
    schulbuch: 'schulbuch',
    times: 'Times New Roman',
    trebuchet: 'Trebuchet',
    varelaround: 'Varela Round',
    verdana: 'Verdana',
    // Commented out until we find a lighter solution. https://lessonup.atlassian.net/browse/LI-374
    // 'noto-color-emoji': 'Noto Color Emoji',
  } as const;

  export type Dict = typeof fontNames;
  export type Key = keyof Dict;

  /* Commented out until we find a lighter solution. https://lessonup.atlassian.net/browse/LI-374 */
  export const primaryFontKeys: Key[] = ['campton-bold', 'montserrat', 'odin', 'sanfrancisco' /*, 'noto-color-emoji'*/];
  export const privateFontKeys: Key[] = ['gotham'];
  export const fontKeysToHideFromPicker: Key[] = ['campton-bold'];

  export interface GoogleFont {
    name: string;
    google: true;
    families: string[];
  }

  export interface CustomFont {
    name: string;
    families?: string[];
    url: string;
  }

  export const primaryFonts = {
    google: {
      families: [
        'Montserrat:400,500,600,700',
        'Open+Sans:400,600',
        'Montserrat+Alternates:400,600,700',
        'DM+Sans:400,500,700', // Used in 2.0 Design System
        'Plus+Jakarta+Sans:800', // Used in 2.0 Design System
      ],
    },
    custom: {
      /* Noto commented out until we find a lighter solution. https://lessonup.atlassian.net/browse/LI-374 */
      families: ['sanfrancisco', 'campton-bold', 'odin' /*, 'noto-color-emoji'*/],
      urls: [primaryFontsUrl],
    },
  };

  export const conditionalFonts: (GoogleFont | CustomFont)[] = [
    {
      name: 'gotham',
      families: ['Gotham Rounded SSm A', 'Gotham Rounded SSm B'],
      url: '//cloud.typography.com/6162672/684584/css/fonts.css',
    },
    {
      name: 'comicneue',
      url: '/fonts/comicNeue.css',
    },
    {
      name: 'schulbuch',
      url: '/fonts/fonts-eye.css',
    },
    {
      name: 'impact', // Impact is a standard font. However, it's not available on Android devices. Therefore we add Anton as a fallback.
      families: ['Anton: 400,700'],
      google: true,
    },
    {
      name: 'pangolin',
      families: ['Pangolin'],
      google: true,
    },
    {
      name: 'andada',
      families: ['Andada'],
      google: true,
    },
    {
      name: 'open-sans',
      families: ['Open Sans:400,700'],
      google: true,
    },
    {
      name: 'dosis',
      families: ['Dosis:400,700'],
      google: true,
    },
    {
      name: 'roboto',
      families: ['Roboto:400,700'],
      google: true,
    },
    {
      name: 'varelaround',
      families: ['Varela Round'],
      google: true,
    },
    {
      name: 'kreon',
      families: ['Kreon'],
      google: true,
    },
  ];

  export type ConditionalFont = (typeof conditionalFonts)[number];
  const conditionalFontDict = _.keyBy(conditionalFonts, 'name');

  export function generateGoogleFontsHead(fonts: string[]) {
    return `<link href="https://fonts.googleapis.com/css?family=${fonts.join(
      '|'
    )}&display=fallback" rel="stylesheet" />`;
  }

  export function generatePrimaryFontHead(assetUrlPrefix: string) {
    const primaryGoogleFonts = generateGoogleFontsHead(primaryFonts.google.families);
    const customFonts = generateCustomFontHead(assetUrlPrefix, primaryFonts.custom.urls);
    return `${primaryGoogleFonts}${customFonts}`;
  }

  export function generateCustomFontHead(assetUrlPrefix: string, urls: string[]) {
    return urls
      .map((url) => {
        // we should not prepend //cloud.typography.com
        const finalUrl = url.indexOf('//') === 0 ? url : `${stripTrailingSlash(assetUrlPrefix)}${url}`;
        return `<link href="${finalUrl}" rel="stylesheet" />`;
      })
      .join();
  }

  export function conditionalFontByKey(font: Key): ConditionalFont | undefined {
    return conditionalFontDict[font];
  }
  /**********
    block copied from fontsizelib
  */
  const fontSizes = [
    [1, 30],
    // [1.5, 35], // TODO: turn back on after all apps are updated
    [2, 40],
    [3, 50],
    [4, 60],
    [5, 80],
    [6, 100],
    [7, 140],
    [8, 200],
    [9, 300],
  ];

  export const fontSizeKeys = fontSizes.map((f) => f[0]);
  export const fontSizePerc = fontSizes.map((f) => f[1]);

  export function getFontSizes() {
    return fontSizeKeys;
  }
  export function getFontSizePrecent() {
    return fontSizePerc;
  }

  // convert decimals to percentage values
  export function fontSizePrettyToPercentage(v) {
    v = parseFloat(v);
    const f = fontSizes.find((f) => f[0] == v);
    return f && f[1]; // value 5 -> 80 percent
  }

  export function fontSizePercentageToPretty(v) {
    v = parseFloat(v);
    const f = fontSizes.find((f) => f[1] == v);
    return f && f[0];
  }

  export function getNameValuePairArray() {
    return Object.values(fontSizes).map(([value, id]) => ({
      id,
      value,
    }));
  }

  export function className(font: Key) {
    return `font-${font}`;
  }
  /**********
    end block copied from fontsizelib
  */

  export function isPrivateFont(font: Key) {
    return !!privateFontKeys.includes(font);
  }

  export function isValidKey(string: string): string is Key {
    return !!fontNames[string];
  }

  interface FontPickerFont {
    value: Fonts.Key;
    name: string;
    selected?: boolean;
  }

  export function getFontsForPicker(
    currentFont: string | undefined,
    isAllowed: (font: string) => boolean
  ): FontPickerFont[] {
    return Object.entries(fontNames)
      .filter(([value]) => {
        if (_.includes(fontKeysToHideFromPicker, value)) {
          return false;
        }

        return !isPrivateFont(value as Fonts.Key) || isAllowed(value);
      })
      .map(([value, name]) => {
        return {
          value: value as Fonts.Key,
          name,
          selected: value === currentFont,
        };
      });
  }
}
