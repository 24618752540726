import { PinViewerStatic } from '@lessonup/pin-renderer';
import { TeacherPin } from '@lessonup/pins-shared';
import { borderRadius, colorSets, rem, SpaceBetween, spacing, styled, TextElement } from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../hooks/usePlayerTranslation';
import { ASIDE_CONTENT_WIDTH, NEXT_PIN_VIEW_HEIGHT } from '../presenterPlayer.utils';

export interface NextPinViewProps {
  nextPin: TeacherPin | null;
}

export const NextPinView: React.FC<NextPinViewProps> = ({ nextPin }) => {
  const { t } = usePlayerTranslation();
  const pinViewerWidth = ASIDE_CONTENT_WIDTH - 2; // 2px border
  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      <StyledTextElement textStyle="bodyText" weight="bold">
        {t('presenterView.nextSlide')}
      </StyledTextElement>
      <StyledPreviewWrapper>
        {nextPin ? (
          <PinViewerStatic pin={nextPin} width={pinViewerWidth} />
        ) : (
          <StyledEndOfLesson>
            <TextElement textStyle="bodyTextLarge" weight="bold" size="small">
              {t('presenterView.endOfLesson')}
            </TextElement>
          </StyledEndOfLesson>
        )}
      </StyledPreviewWrapper>
    </SpaceBetween>
  );
};

const StyledTextElement = styled(TextElement)`
  color: ${colorSets.neutral.on};
`;

const StyledPreviewWrapper = styled.div`
  width: ${rem(ASIDE_CONTENT_WIDTH)};
  height: ${rem(NEXT_PIN_VIEW_HEIGHT)};
  border: 1px solid ${colorSets.neutral.stroke};
  border-radius: ${borderRadius.rounded2};
`;

const StyledEndOfLesson = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorSets.neutral.fill};
  color: ${colorSets.neutral.on};
`;
