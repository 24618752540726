// These utils are ported from the original player package
import { isNil, last } from 'lodash';
import { asyncScheduler, Observable, SchedulerLike, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen, tap } from 'rxjs/operators';

export const rxSettings = {
  timeUnit: 1000,
};

type MaxAttemptsStrategy = { type: 'sequential' | 'total'; count: number };

export interface RetryStrategyTiming {
  delayMs: number;
  backOffDisabled?: boolean;
  initialBackOffAfter?: number;
  maxAttempts?: MaxAttemptsStrategy;
}

export interface RetryStrategy extends RetryStrategyTiming {
  onError?: (error: Error) => void; // can be used for logging purposes
  /** only executes the strategy when the predicate returns true. when not set: all errors will be accepted */
  onlyForErrors?: (error: Error) => boolean;
}

export const retryWithStrategy =
  (strategy: RetryStrategy, scheduler: SchedulerLike = asyncScheduler) =>
  <T>(source: Observable<T>) => {
    let sequentialErrorCount = 0;
    let previousBackoffTiming: BackoffTiming | undefined = undefined;

    return source.pipe(
      // on successfull values we reset the sequentialErrorCount
      tap(() => {
        sequentialErrorCount = 0;
      }),
      retryWhen((errors: Observable<Error>): Observable<number> => {
        return errors.pipe(
          mergeMap((error, i) => {
            const totalErrorCount = i + 1;
            sequentialErrorCount++;

            if (strategy.onError) {
              strategy.onError(error);
            }

            // if we give a where clause, check if the error matches it
            if (strategy.onlyForErrors && !strategy.onlyForErrors(error)) {
              return throwError(error, scheduler);
            }

            if (hasHitMaxAttempts({ sequential: sequentialErrorCount, total: totalErrorCount }, strategy.maxAttempts)) {
              return throwError(error, scheduler);
            }

            let delay: number;
            if (strategy.backOffDisabled === true) {
              delay = strategy.delayMs;
              previousBackoffTiming = undefined;
            } else {
              const backOffDelay = backOffDelayTime(previousBackoffTiming, strategy.delayMs, scheduler.now());
              delay = backOffDelay.delay;
              previousBackoffTiming = backOffDelay;
            }

            // retry after
            return timer(delay, undefined, scheduler);
          })
        );
      })
    );
  };

function hasHitMaxAttempts(
  errorCount: Record<MaxAttemptsStrategy['type'], number>,
  maxAttemptsStrategy?: MaxAttemptsStrategy
): boolean {
  if (!maxAttemptsStrategy) {
    return false;
  }

  return errorCount[maxAttemptsStrategy.type] > maxAttemptsStrategy.count;
}

export interface BackoffTiming {
  delay: number;
  attempt: number;
  expectedRetryAt: number;
}

// Array with the intervals' pacing, in seconds.
const backOffTiming = [2, 5, 15, 60, 60, 120, 120, 120, 300].map((d) => d * rxSettings.timeUnit);

/** calculate the backoff delay time based on the given input */
export function backOffDelayTime(
  previous: BackoffTiming | undefined,
  initialDelay: number,
  now: number = Date.now()
): BackoffTiming {
  let attempt: number;

  // if an error occurs within 15 seconds of the expected retry, we want to continue the backoff
  if (previous && now - previous.expectedRetryAt <= rxSettings.timeUnit * 15) {
    attempt = previous.attempt + 1;
  } else {
    // otherwise we start the backoff
    attempt = 1;
  }

  const extraDelay = isNil(backOffTiming[attempt - 1]) ? backOffTiming[attempt - 1] : last(backOffTiming) || 0;
  const totalDelay = initialDelay + extraDelay;

  const timing: BackoffTiming = {
    attempt,
    delay: totalDelay,
    expectedRetryAt: now + totalDelay,
  };

  return timing;
}
