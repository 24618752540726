import { createTextEditorHandler, TextEditorHandler } from '@lessonup/pins-shared';
import { isRightMBClick } from '@lessonup/ui-components';
import { Editor } from '@tiptap/core';
import { useCallback, useEffect, useRef } from 'react';
import { useKeybindController } from '../../../utils/KeybindControllerContext/KeybindControllerContext';
import { TextPinComponentProps } from '../TextPinComponent.types';
import { useTextPinComponentKeyboardEvents } from './useTextPinComponentKeyboardEvents';

export function useTextPinComponentHandlers(props: TextPinComponentProps) {
  const { active, isTextEditMode, setModeTextEdit } = props;
  const textPinComponentRef = useRef<HTMLDivElement>(null);
  const textEditorHandlerRef = useRef<TextEditorHandler | null>(null);
  const registerEditor = useCallback((editor: Editor) => {
    textEditorHandlerRef.current = createTextEditorHandler(editor);
  }, []);
  const clickState = useRef({
    shouldTryForDetailMode: false,
    dragged: false,
  });
  const wasActive = useRef(false);
  const { enableKeybinds, disableKeybinds } = useKeybindController();

  useEffect(() => {
    if (active) {
      wasActive.current = true;
    } else {
      wasActive.current = false;
      clickState.current.dragged = false;
      clickState.current.shouldTryForDetailMode = false;
    }
  }, [active, isTextEditMode]);

  useTextPinComponentKeyboardEvents(props, textEditorHandlerRef);

  const handleTouchStart = useCallback(() => {
    // If the component wasn't active yet, we blur the editor, if it was active we blur it later if the user drags the component
    // This means that before dragging it's sometimes possible to type in the editor outside of detail mode, but right now we don't have a way to re-focus the editor on the cursor position
    if (active && !isTextEditMode) {
      clickState.current.shouldTryForDetailMode = true;
    } else {
      textEditorHandlerRef.current?.blur();
    }
  }, [active, isTextEditMode]);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      // If it's a right click we want to either:
      // - stop the contextMenu from appearing if in textEditMode
      // - return so the click doesn't count for textEditMode
      if (isRightMBClick(event)) {
        if (isTextEditMode) {
          event.preventDefault();
          event.stopPropagation();
        }

        return;
      }

      // If the component wasn't active yet, we blur the editor, if it was active we blur it later if the user drags the component
      // This means that before dragging it's sometimes possible to type in the editor outside of detail mode, but right now we don't have a way to re-focus the editor on the cursor position
      if (active && !isTextEditMode) {
        clickState.current.shouldTryForDetailMode = true;
      } else {
        textEditorHandlerRef.current?.blur();
      }
    },
    [active, isTextEditMode]
  );

  /**
   * When clicking outside of a text mark, but inside the PinComponent we want tiptap to put the focus cursor at the end.
   */
  const handleMouseUpTouchEnd = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (!active) return;

      if ((!isTextEditMode && !clickState.current.shouldTryForDetailMode) || clickState.current.dragged) {
        clickState.current.dragged = false;
        clickState.current.shouldTryForDetailMode = false;
        return;
      }

      clickState.current.shouldTryForDetailMode = false;
      setModeTextEdit?.(props.componentId);

      const onRoot = event.target instanceof HTMLElement && event.target.classList.contains('tiptap-root');
      if (!isTextEditMode && (event.target === textPinComponentRef.current || onRoot)) {
        textEditorHandlerRef.current?.focus.end();
      }
    },
    [active, isTextEditMode, setModeTextEdit, props.componentId]
  );

  const handleMouseMoveTouchMove = useCallback(() => {
    if (!active || !clickState.current.shouldTryForDetailMode) return;

    // If the user drags the component, we want to blur the editor and not go into detail mode
    clickState.current.dragged = true;
    textEditorHandlerRef.current?.blur();
  }, [active]);

  const handleOnBlur = useCallback(() => {
    enableKeybinds();
  }, [enableKeybinds]);

  const handleOnFocus = useCallback(() => {
    disableKeybinds();
  }, [disableKeybinds]);

  const handleOnContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isTextEditMode) return;
      event.stopPropagation();
    },
    [isTextEditMode]
  );

  return {
    registerEditor,
    textPinComponentRef,
    handleMouseDown,
    handleTouchStart,
    handleMouseUpTouchEnd,
    handleMouseMoveTouchMove,
    handleOnBlur,
    handleOnFocus,
    handleOnContextMenu,
  };
}
