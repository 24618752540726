import { InternalRefetchQueriesInclude, useDocumentMutation } from '@lessonup/client-integration';
import { asError } from '@lessonup/utils';
import { useMemo } from 'react';
import { CreateUploadDocument } from '../Uploads.graphql.generated';

export const uploadFile = async (uploadUrl: string, file: File) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', file.type);
  myHeaders.append('x-goog-content-length-range', `0,${file.size}`);

  const requestOptions: RequestInit = {
    method: 'PUT',
    headers: myHeaders,
    body: file,
    redirect: 'follow',
  };

  return fetch(uploadUrl, requestOptions);
};

export interface PerformUploadProperties {
  file: File;
  folderId?: string;
}

export interface UseUploadFileProperties {
  onError?: (error: Error) => void;
  refetchQueries?: InternalRefetchQueriesInclude;
}

export type UseUploadFileOutput = [(p: PerformUploadProperties) => Promise<string | undefined>, boolean];

export const useUploadFile = ({ onError, refetchQueries }: UseUploadFileProperties): UseUploadFileOutput => {
  const options = {
    ...(refetchQueries
      ? {
          refetchQueries,
        }
      : {}),
  };

  const [createUpload, { loading }] = useDocumentMutation(CreateUploadDocument, options);

  return useMemo(() => {
    const performUpload = async ({ file, folderId }: PerformUploadProperties) => {
      try {
        const { data } = await createUpload({
          variables: {
            input: {
              contentType: file.type,
              name: file.name,
              sizeInBytes: file.size,
              folderId,
            },
          },
        });

        if (!data) throw new Error('No upload url.');

        await uploadFile(data.createUpload.uploadUrl, file);

        return data.createUpload.upload.id;
      } catch (error) {
        onError?.(asError(error));
      }
    };

    return [performUpload, loading];
  }, [createUpload, loading, onError]);
};
