import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';

const i18n = globalI18nProxy();

export function isRange(arr: number[]) {
  if (arr.length < 3) return false;
  const sorted = arr.sort((a, b) => a - b),
    low = sorted[0],
    high = sorted[sorted.length - 1];
  for (let nr = low; nr < high; nr++) {
    if (!_.includes(arr, nr)) return false;
  }
  return { low, high };
}

export const stringToNumberSuggestor: EduSystem.Suggestor<string[]> = ({ value, ...other }) => {
  return numberSuggestor({ ...other, value: value.map((single) => parseInt(single)) });
};

export const numberSuggestor: EduSystem.Suggestor<number[]> = ({ value, fieldOptions }) => {
  const sortedValues: any[] = [];

  // first, sort, then map year to ages (4 - 17) to numbers (1 - ... )
  _.each(fieldOptions, function (sug) {
    if (value && _.includes(value, sug.id)) {
      const newValue = parseInt(sug.value);
      Number.isNaN(newValue) ? sortedValues.push(sug.value) : sortedValues.push(newValue);
    }
  });

  // oooooh, let's do this, see if it's a range
  const rangeInfo = isRange(sortedValues);
  if (rangeInfo) return rangeInfo.low + '-' + rangeInfo.high;

  const strings = fieldOptions.filter((option) => !!value.includes(option.id as any)).map((o) => o.value);

  return strings.join(',');
};

export const stringOptionsSuggestor: EduSystem.Suggestor<string[]> = ({ value, fieldOptions }) => {
  const strings = fieldOptions
    .filter((option) => value?.includes(option.id as any))
    .map((option) => (option.short ? option.short : option.value));
  return strings.join(',');
};

const stringSimpleSuggestor: EduSystem.Suggestor<string[] | string> = ({ value }) => {
  return Array.isArray(value) ? value.join(',') : value;
};

type AnySuggestor = (value: string | string[]) => string;

export const anySuggestor: AnySuggestor = (val) => {
  return Array.isArray(val) ? val.join(', ') : val;
};

const durationSuggestor: EduSystem.Suggestor<string> = ({ value, excludeUnits }) => {
  let str = value;
  if (!excludeUnits) str += i18n.__(' min');
  if (parseInt(value) === 60 && !excludeUnits) str = i18n.__('1 uur');
  if (parseInt(value) === 120 && !excludeUnits) str = i18n.__('2 uur');
  return str;
};

export const eduSystemEditDataDuration: (label: () => string) => EduSystem.EditData = (label) => ({
  label,
  id: 'durationInMin',
  reference: 'durationInMin',
  numeric: true,
  isArray: false,
  pickMultiple: false,
  defaultValue: i18n.__('kies'),
  moreLabel: i18n.__('anders'),
  suggestor: durationSuggestor,
});

export const eduSystemEditDataSchoolType: (label: () => string) => EduSystem.EditData = (label) => ({
  label,
  id: 'schoolType',
  reference: 'schoolType',
  numeric: false,
  isArray: true,
  pickMultiple: false,
  defaultValue: i18n.__('kies'),
  moreLabel: i18n.__('combineer'),
  cleanAfterwards: true,
  suggestor: stringOptionsSuggestor,
});

export const eduSystemEditDataLevel: (
  label: () => string,
  suggestor?: EduSystem.Suggestor<string[]>,
  reference?: EduSystem.EditDataIdWithReference
) => EduSystem.EditData = (label, suggestor, reference: EduSystem.EditDataIdWithReference = 'levels') => ({
  label,
  id: 'levels',
  reference,
  numeric: false,
  isArray: true,
  pickMultiple: true,
  defaultValue: i18n.__('kies'),
  suggestor: suggestor || stringOptionsSuggestor,
});

export const eduSystemEditDataYear: (
  label: () => string,
  reference?: EduSystem.EditDataIdWithReference,
  suggestor?: EduSystem.Suggestor<number[]>
) => EduSystem.EditData = (label, reference = 'years', suggestor) => ({
  label,
  id: 'years',
  reference,
  numeric: true,
  isArray: true,
  pickMultiple: true,
  defaultValue: i18n.__('kies'),
  moreLabel: i18n.__('meer'),
  suggestor: suggestor || numberSuggestor,
});

export const eduSystemEditDataSubject: (
  label: () => string,
  shortListField?: string | [string, string]
) => EduSystem.EditData = (label, shortListField) => ({
  label,
  id: 'subjects',
  fieldName: 'subject',
  reference: 'subjects',
  inputSelect: true,
  shortListField,
  suggestor: stringSimpleSuggestor,
});

export const eduSystemEditDataTheme: (label: () => string, shortListField?: string) => EduSystem.EditData = (
  label,
  shortListField
) => ({
  label,
  id: 'themes',
  fieldName: 'theme',
  reference: 'themes',
  inputSelect: true,
  pickMultiple: true,
  shortListField,
  shortListFieldLength: 10,
  moreLabel: i18n.__('combineer'),
  suggestor: stringSimpleSuggestor,
});
