import { useLocale } from '@lessonup/client-integration';
import {
  Banner,
  BodyText,
  Button,
  color,
  IconBook,
  Introduction,
  SelectFormField,
  SpaceBetween,
  spacing,
  styled,
  TextAreaFormField,
  UseFormRegister,
  UseFormWatch,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullPageBodyAsideLayout } from '../../../../../components/FullPageFlow/FullPageBodyAsideLayout';
import {
  FullPageBodySection,
  FullPageBodySectionTransparent,
} from '../../../../../components/FullPageFlow/FullPageBodySection';
import { FullPageBodyAside } from '../../../../../components/FullPageFlow/FullPageFlowAside';
import { Language, MaiaExample } from '../../../../../types/graphql/types.generated';
import { helpArticleLink } from '../../../../../utils/bridge/helpArticleLink';
import { getLanguageOptions, giveSelectionOptions } from '../../../dataComponents/utils';
import { OptionalLessonSettings } from '../../../model';
import { MaiaExamples } from '../../MaiaExamples';
import { OptionalLessonSettingsList } from '../../OptionalLessonSettingsList';
import { SidebarChooseMaiaMode } from '../../SidebarChooseMaiaMode';

export interface LessonPromptFormInputs {
  prompt: string;
  slidesToGenerate: number;
  language: Language;
}

interface LessonPromptStepProps {
  register: UseFormRegister<LessonPromptFormInputs>;
  watch: UseFormWatch<LessonPromptFormInputs>;
  promptCharacterLimit: number;
  optionalLessonSettings: OptionalLessonSettings;
  setOptionalLessonSettings: React.Dispatch<React.SetStateAction<OptionalLessonSettings>>;
  maiaExamples?: MaiaExample[];
  handleMaiaModeSwitch: () => void;
  onExamplesClick: (examplePrompt: string) => void;
}

export const LessonPromptStep = ({
  register,
  watch,
  promptCharacterLimit,
  optionalLessonSettings,
  setOptionalLessonSettings,
  maiaExamples,
  handleMaiaModeSwitch,
  onExamplesClick,
}: LessonPromptStepProps) => {
  const { t } = useTranslation('chatGPT');
  const language = useLocale().language;
  const languageOptions = getLanguageOptions(t);
  const promptCharacterLimitForWarning = promptCharacterLimit - 500;
  const promptInputLength = watch('prompt')?.length;

  return (
    <div>
      <FullPageBodySectionTransparent>
        <Introduction
          alignment="center"
          size="M"
          headline={t('promptStep.generateLesson')}
          bodyText={
            <>
              {t('promptStep.generateLessonAbout')}{' '}
              <a href={helpArticleLink(language, 'openAIPrompt')} target="_blank" rel="noreferrer">
                {t('promptStep.promptArticle')}
              </a>
            </>
          }
        />
      </FullPageBodySectionTransparent>

      <FullPageBodyAsideLayout mainLabel={t('promptStep.mainLabel')} asideLabel={t('optionalSettings')}>
        <FullPageBodySection>
          <FullWidth>
            <SpaceBetween direction="y" spacing={spacing.size16}>
              <div>
                <BodyText size="medium" weight="bold">
                  {t('contextStep.inputTitle')}
                </BodyText>

                <StyledSubText size="small">{t('promptStep.promptHelp')}</StyledSubText>
              </div>
              <SpaceBetween direction="y" spacing={spacing.size32}>
                <StyledTextAreaFormField
                  label={t('contextStep.inputTitle')}
                  hideLabel
                  placeholder={t('promptStep.lessonPromptExample')}
                  {...register('prompt')}
                  validation={{
                    state: promptInputLength > promptCharacterLimit ? 'error' : 'neutral',
                    message:
                      promptInputLength >= promptCharacterLimitForWarning
                        ? t('promptCharactersWarning', { promptInputLength, promptCharacterLimit })
                        : '',
                  }}
                />
                {promptInputLength > promptCharacterLimit && (
                  <Banner
                    priority="medium"
                    orientation="vertical"
                    alignment="start"
                    actionElement={
                      <Button onClick={handleMaiaModeSwitch} buttonType="neutral" iconStart={<IconBook />}>
                        {t('promptStep.switchMaiaModeCTA')}
                      </Button>
                    }
                    paragraph={t('promptStep.switchMaiaModeWarning')}
                  />
                )}
              </SpaceBetween>
              {maiaExamples && (
                <FullWidth>
                  <MaiaExamples exampleType="lesson" maiaExamples={maiaExamples} onExamplesClick={onExamplesClick} />
                </FullWidth>
              )}
            </SpaceBetween>
          </FullWidth>
        </FullPageBodySection>
        <FullPageBodyAside>
          <SpaceBetween direction="y" spacing={spacing.size8}>
            <SidebarChooseMaiaMode activeLessonType="lesson" handleMaiaModeSwitch={handleMaiaModeSwitch} />
            <StyledPickerWrapper>
              <SpaceBetween direction="y" spacing={spacing.size8}>
                <div>
                  <BodyText size="medium" weight="bold">
                    {t('languageOption')}
                  </BodyText>
                  <StyledSubText size="small">{t('languageOptionDescription')}</StyledSubText>
                </div>
                <SelectFormField
                  orientation="vertical"
                  hideLabel
                  label={t('languageOption')}
                  {...register('language')}
                  options={languageOptions}
                  defaultValue={language.toUpperCase()}
                />
              </SpaceBetween>
            </StyledPickerWrapper>
            <StyledPickerWrapper>
              <SelectFormField<number>
                orientation="vertical"
                label={t('slideCount')}
                {...register('slidesToGenerate')}
                options={giveSelectionOptions(20)}
                defaultValue={8}
              />
            </StyledPickerWrapper>
            <OptionalLessonSettingsList
              currentSettings={optionalLessonSettings}
              setOptionalLessonSettings={setOptionalLessonSettings}
              showSetting={{
                enableTeacherNotes: false,
                enablePreviousKnowledge: true,
                enableExitTicket: true,
                enableLearningPhase: false,
              }}
            />
          </SpaceBetween>
        </FullPageBodyAside>
      </FullPageBodyAsideLayout>
    </div>
  );
};

const StyledSubText = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;

const StyledPickerWrapper = styled.div`
  padding: ${spacing.size8} 0;
`;

const StyledTextAreaFormField = styled(TextAreaFormField)`
  width: 100%;
  height: 40vh;
`;

const FullWidth = styled.div`
  width: 100%;
`;
