import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TeachingCreditsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TeachingCreditsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', license: { __typename?: 'UserLicense', status: Types.LicenseStatus, remainingTeachingCreditsCount?: number | null, isTeachingAllowed: boolean } } };


export const TeachingCreditsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TeachingCredits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"license"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"remainingTeachingCreditsCount"}},{"kind":"Field","name":{"kind":"Name","value":"isTeachingAllowed"}}]}}]}}]}}]} as unknown as DocumentNode<TeachingCreditsQuery, TeachingCreditsQueryVariables>;