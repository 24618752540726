import { isHotspotPinComponent, PinComponent, Vector } from '@lessonup/pins-shared';
import { useCallback, useRef, useState } from 'react';
import { PinTransformAction } from '../../types/pinComponents/pinTransformActions';
import { useUpdatePinComponent } from '../../utils/updatePinComponentContext/updatePinComponentContext';
import { MouseEventState } from './useHandleMouseEvents.types';
import {
  createMouseEventStateForMouseDown,
  createNewPinComponentLayout,
  determineSelectedPinComponents,
} from './useHandleMouseEvents.utils';

export type OnMouseDownHandler = (event: React.MouseEvent<HTMLDivElement>, action: PinTransformAction) => void;
export type OnMouseEventHandler = (event: React.MouseEvent<HTMLDivElement>) => void;

export const useHandleMouseEventsForViewer = (
  pinId: string,
  pinScale: number,
  pinRendererOffset: Vector,
  pinComponents: PinComponent[]
) => {
  const mouseEventState = useRef<MouseEventState | null>(null);
  const [mutatingPinComponent, setMutatingPinComponent] = useState<PinComponent>();
  const updatePinComponent = useUpdatePinComponent();

  const handleOnMouseDown: OnMouseDownHandler = useCallback(
    (event, action) => {
      event.stopPropagation();

      const determinedSelectedPinComponentIds = determineSelectedPinComponents(action, []);

      mouseEventState.current = createMouseEventStateForMouseDown(
        event,
        action,
        pinRendererOffset,
        pinScale,
        determinedSelectedPinComponentIds,
        pinComponents
      );

      const clickedComponent = mouseEventState.current?.selectedPinComponentsStart?.[0];
      if (clickedComponent && isHotspotPinComponent(clickedComponent)) {
        updatePinComponent({
          type: 'updatePinComponentSettings',
          pinId,
          pinComponent: clickedComponent,
          settings: { showBody: !clickedComponent.settings.showBody },
        });
      }
    },
    [pinId, pinRendererOffset, pinScale, pinComponents, updatePinComponent]
  );

  const handleOnMouseMove: OnMouseEventHandler = useCallback(
    (event) => {
      const currentMouseEventState = mouseEventState.current;
      const firstPinComponent = currentMouseEventState?.selectedPinComponentsStart?.[0];
      if (!currentMouseEventState || !firstPinComponent?.settings.movableInInteractiveViewer) return;
      const mutatedPinComponent = createNewPinComponentLayout(
        event.nativeEvent,
        currentMouseEventState,
        { horizontal: [], vertical: [] },
        () => ({}),
        pinRendererOffset,
        pinScale
      );
      if (mutatedPinComponent) {
        setMutatingPinComponent(mutatedPinComponent[0]);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [pinRendererOffset, pinScale, mutatingPinComponent]
  );

  const handleOnMouseUp: OnMouseEventHandler = useCallback(() => {
    if (mutatingPinComponent) {
      updatePinComponent({
        type: 'updateComponentLayout',
        pinId,
        updates: { [mutatingPinComponent.id]: mutatingPinComponent.layout },
        pinComponent: mutatingPinComponent,
      });
    }
    setMutatingPinComponent(undefined);
    mouseEventState.current = null;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [mutatingPinComponent]);

  return { mutatingPinComponent, handleOnMouseDown, handleOnMouseUp, handleOnMouseMove };
};
