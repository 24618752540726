import { AnyPinEntry, OpenQuestionPinEntry, QuizPinEntry, WordCloudPinEntry } from '@lessonup/assignments-shared';

export function isWordCloudEntry(entry: AnyPinEntry | null | undefined): entry is WordCloudPinEntry {
  return entry ? entry.type === 'WORD_CLOUD' : false;
}

export function isOpenQuestionEntry(entry: AnyPinEntry | null | undefined): entry is OpenQuestionPinEntry {
  return entry ? entry.type === 'OPEN_QUESTION' : false;
}

export function isQuizEntry(entry: AnyPinEntry | null | undefined): entry is QuizPinEntry {
  return entry ? entry.type === 'MULTIPLE_CHOICE' : false;
}
