import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4.5 14.653-.638-8.518c-.06-.808.627-1.494 1.496-1.494h13.284c.87 0 1.556.686 1.496 1.494l-.638 8.518M2.625 15.807c0-.706.612-1.26 1.315-1.209 1.502.11 4.252.261 8.06.261 3.807 0 6.558-.15 8.06-.261a1.223 1.223 0 0 1 1.315 1.209c0 1.809-1.36 3.313-3.168 3.405-1.547.079-3.636.147-6.207.147s-4.66-.068-6.207-.147c-1.807-.092-3.168-1.596-3.168-3.405"
    />
  </svg>
);
export default SvgIconLaptop;
