import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconColor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.056 14.13c-2.73-2.732.458-7.615 4.287-10.857.57-.482 1.409-.419 1.937.109l6.645 6.645c.528.528.591 1.367.109 1.937-3.242 3.83-8.125 7.017-10.856 4.287zM16.466 18.804c0-1.125 1.5-2.812 2.25-3.375.75.563 2.25 2.25 2.25 3.375 0 1.688-1.125 2.25-2.25 2.25s-2.25-.562-2.25-2.25"
    />
  </svg>
);
export default SvgIconColor;
