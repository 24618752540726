import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconRobot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9.51V6.789m0 2.721c-1.892 0-3.866 0-5.544.293a2.32 2.32 0 0 0-1.855 1.807c-.234 1.086-.234 1.98-.234 3.804 0 1.822 0 2.717.234 3.803.2.923.925 1.644 1.855 1.807 1.678.293 3.652.293 5.544.293s3.866 0 5.544-.293a2.32 2.32 0 0 0 1.855-1.807c.234-1.086.234-1.98.234-3.803s0-2.718-.234-3.804a2.32 2.32 0 0 0-1.855-1.807C15.866 9.51 13.892 9.51 12 9.51m-2.75 4.278v-.82m5.5.82v-.82m-5.693 4.28.177.185a1.5 1.5 0 0 0 1.084.463h3.364a1.5 1.5 0 0 0 1.084-.463l.177-.186m-.905-12.525a2.038 2.038 0 1 1-4.077 0 2.038 2.038 0 0 1 4.077 0"
    />
  </svg>
);
export default SvgIconRobot;
