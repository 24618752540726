import { SymbolDefinition } from '../SymbolComponent';

const NAME = 'triangle';
export interface TriangleSymbol extends SymbolDefinition {
  name: typeof NAME;
}

export const triangleSymbol: TriangleSymbol = {
  name: NAME,
  lockedAspectRatio: false,
  startWithFixedAspectRatio: false,
  hasStrokeSettings: true,
};
