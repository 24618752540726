import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.625 14.836v6.658m18-5.967c-4.825 3.883-14.48-2.82-18 2.003V4.51C6.145-.055 16.17 6.127 20.243 2.505c0 0 1.132 2.639 1.132 6.419s-.75 6.602-.75 6.602"
    />
  </svg>
);
export default SvgIconFlag;
