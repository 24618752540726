import styled from '@emotion/styled';
import { t } from 'i18next';
import { rem } from 'polished';
import React, { isValidElement, ReactNode, useEffect, useRef, useState } from 'react';
import { color } from '../../foundations/colors/colors';
import { ComponentSize } from '../../foundations/size/size.types';
import { spacing } from '../../foundations/spacing/spacing';
import { fontSize, lineHeight } from '../../foundations/typography/typography.utils';
import { Link } from '../../utils/Link/Link';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { AsyncButton, AsyncMouseEventHandler } from '../AsyncButton/AsyncButton';
import { BodyText } from '../BodyText/BodyText';
import { Button, ButtonAnchor, ButtonAnchorProps, ButtonProps } from '../Button/Button';
import type { ButtonType } from '../Button/button.types';
import { Headline } from '../Headline/Headline';

export type IntroductionAlignment = 'start' | 'center';
export type IntroductionSize = 'S' | 'M' | 'L';

/**
 * @deprecated Use `size` instead
 */
export type IntroductionPriority = 'low' | 'high';

export type NormalIntroductionButton = Omit<ButtonProps, 'size'>;

export type IntroductionButtonAsync = NormalIntroductionButton & { async: true } & {
  onClick: AsyncMouseEventHandler;
};

export type IntroductionButtonAnchor = Omit<ButtonAnchorProps, 'size'>;

type IntroductionButton = NormalIntroductionButton | IntroductionButtonAsync | IntroductionButtonAnchor;

const isAsyncButton = (action: IntroductionButton): action is IntroductionButtonAsync => {
  return !!(action as IntroductionButtonAsync).async;
};

const isAnchorButton = (
  action: IntroductionButton | IntroductionButtonAsync | IntroductionButtonAnchor
): action is IntroductionButtonAnchor => {
  return !!(action as IntroductionButtonAnchor).href;
};

export interface IntroductionProps {
  alignment: IntroductionAlignment;
  size: IntroductionSize;
  icon?: React.JSX.Element;
  headline: ReactNode;
  bodyText: ReactNode;
  tagline?: ReactNode;

  buttons?: {
    primary: IntroductionButton;
    secondary?: IntroductionButton;
  };
  /**
   * @deprecated Use `button.primary` instead. This button will not be sized correctly.
   * TODO: Remove deprecated button https://lessonup.atlassian.net/browse/TECH-66
   */
  buttonPrimary?: ReactNode;

  /**
   * @deprecated Use `button.secondary` instead. This button will not be sized correctly.
   * TODO: Remove deprecated button https://lessonup.atlassian.net/browse/TECH-66
   */
  buttonSecondary?: ReactNode;

  /**
   * @deprecated Use `size` instead
   */
  priority?: IntroductionPriority;
}

const MAX_MOBILE_WIDTH = 600;

export function Introduction(props: IntroductionProps) {
  const {
    headline,
    bodyText,
    priority,
    size: sizeFromProps,
    alignment,
    buttonPrimary,
    buttonSecondary,
    buttons: button,
  } = props;
  const size = sizeFromProps || (priority === 'high' ? 'L' : 'M');
  const ref = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    const width = ref.current?.offsetWidth || 0;
    const isMobileUpdated = !!width && width < MAX_MOBILE_WIDTH;
    if (isMobileUpdated !== isMobile) setIsMobile(isMobileUpdated);
  }, [ref, isMobile]);

  const IntroductionButtonElement = ({
    introductionButtonType,
    ...buttonProps
  }: IntroductionButton & {
    introductionButtonType: 'primary' | 'secondary';
  }) => {
    const sharedProps = {
      buttonType:
        buttonProps.buttonType ?? ((introductionButtonType === 'primary' ? 'primary' : 'outlined') as ButtonType),
      size: (size === 'S' ? 'small' : 'medium') as ComponentSize,
    };

    const children =
      typeof buttonProps.children === 'string' || isValidElement(buttonProps.children)
        ? buttonProps.children
        : t(buttonProps.children as string);

    return isAsyncButton(buttonProps) ? (
      <AsyncButton {...sharedProps} {...buttonProps}>
        {children}
      </AsyncButton>
    ) : isAnchorButton(buttonProps) ? (
      <Link href={buttonProps.href || ''} passHref>
        <ButtonAnchor {...sharedProps} {...buttonProps}>
          {children}
        </ButtonAnchor>
      </Link>
    ) : (
      <Button {...sharedProps} {...buttonProps}>
        {children}
      </Button>
    );
  };
  return (
    <StyledIntroduction {...props} ref={ref}>
      <SpaceBetween direction="y" spacing={spacing.size10}>
        <SpaceBetween direction="y" spacing={spacing.size8}>
          <SpaceBetween direction="y" spacing={spacing.size4} alignItems={alignment}>
            {props.icon ||
              (props.tagline && (
                <SpaceBetween
                  alignItems={alignment}
                  direction={alignment === 'start' ? 'x' : 'y'}
                  spacing={spacing.size8}
                >
                  {props.icon && <StyledIntroductionIcon size={size}>{props.icon} </StyledIntroductionIcon>}
                  {props.tagline && (
                    <TagLine size={size === 'S' ? 'small' : 'medium'} weight="bold">
                      {props.tagline}
                    </TagLine>
                  )}
                </SpaceBetween>
              ))}
            <StyledHeadlineWrapper>
              {size === 'S' ? (
                <BodySizedHeadline size="small">{headline}</BodySizedHeadline>
              ) : (
                <Headline size={size === 'L' ? 'large' : 'small'}>{headline}</Headline>
              )}
            </StyledHeadlineWrapper>
          </SpaceBetween>
          <BodyText size={size === 'S' ? 'small' : size === 'L' ? 'large' : 'medium'}>{bodyText}</BodyText>
        </SpaceBetween>

        {button || buttonPrimary || buttonSecondary ? (
          <SpaceBetween
            direction={isMobile ? 'y' : 'x'}
            spacing={isMobile ? spacing.size12 : spacing.size8}
            justifyContent={alignment}
          >
            <div>
              {button?.primary && <IntroductionButtonElement introductionButtonType="primary" {...button?.primary} />}
              {!button?.primary && buttonPrimary && <div>{buttonPrimary}</div>}
            </div>
            {(button?.secondary || buttonSecondary) && (
              <div>
                {button?.secondary && (
                  <IntroductionButtonElement introductionButtonType="secondary" {...button?.secondary} />
                )}
                {!button?.secondary && buttonSecondary && <div>{buttonSecondary}</div>}
              </div>
            )}
          </SpaceBetween>
        ) : null}
      </SpaceBetween>
    </StyledIntroduction>
  );
}

const StyledIntroduction = styled.div<IntroductionProps>`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.alignment === 'start' ? 'left' : 'center')};
`;

const tagColor = color.additional.disabled.text;

const iconSize = ({ size }: Pick<IntroductionProps, 'size'>) => (size === 'S' ? rem('16px') : rem('24px'));

const StyledIntroductionIcon = styled.div<Pick<IntroductionProps, 'size'>>`
  height: ${iconSize};
  width: ${iconSize};
  svg {
    height: ${iconSize};
    width: ${iconSize};
  }
  color: ${tagColor};
`;

const TagLine = styled(BodyText)`
  color: ${tagColor};
`;

const BodySizedHeadline = styled(Headline)`
  font-size: ${fontSize.bodyTextSizeMedium};
  line-height: ${lineHeight.bodyTextSizeMedium};
`;

const StyledHeadlineWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
