import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { useErrorContext } from '../../../../utils/Error/hooks/useErrorContext';
import { useAsyncLoadingState } from '../../../../utils/hooks/useAsyncLoadingState';
import { Switch } from '../Switch';

export type AsyncClickEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
export interface ActionSwitchProps extends ComponentProps<typeof Switch> {
  onChange: AsyncClickEventHandler;
  customErrorHandler?: (error: unknown) => void;
}

export const AsyncSwitch = ({ onChange, customErrorHandler, ...props }: ActionSwitchProps) => {
  const { setError } = useErrorContext();
  const previousValue = useRef<boolean>(props.checked || false);
  const [isChecked, setIsChecked] = useState<boolean>(props.defaultChecked || false);

  const errorHandler = (error: unknown) => {
    setIsChecked(previousValue.current);
    if (customErrorHandler) {
      customErrorHandler(error);
      return;
    }
    setError({ error: error as Error });
  };
  const { loading, asyncWrapper } = useAsyncLoadingState({ errorHandler });

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    previousValue.current = isChecked;
    setIsChecked(event.target.checked);
    asyncWrapper(() => onChange(event));
  };

  // Update state if data from outside changes, updating previousValue makes sure the rollback on error goes to the correct value
  useEffect(() => {
    if (props.checked !== undefined) {
      previousValue.current = props.checked;
      setIsChecked(props.checked);
    }
  }, [props.checked]);

  return <Switch {...props} onChange={onChangeHandler} loading={loading || props.loading} checked={isChecked} />;
};
