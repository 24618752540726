import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { color, spacing } from '../../../foundations';
import { Link } from '../../../utils/Link/Link';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';

export type CellAlignment = 'start' | 'middle' | 'end';

export interface TableSlotCellProps {
  text?: string;
  href?: string;
  startSlot?: React.JSX.Element;
  endSlot?: React.JSX.Element;
  alignment?: CellAlignment;
  disabled?: boolean;
}

export function TableSlotCell({ startSlot, endSlot, ...props }: TableSlotCellProps) {
  return (
    <StyledCell direction="x" spacing={spacing.size8} alignItems="center" {...props}>
      {startSlot && <StartSlotWrapper>{startSlot}</StartSlotWrapper>}
      {props.href ? (
        <Link href={props.href} passHref>
          {/* This link should not be accessible via keyboard, this because there is custom keyboard behaviour for the table */}
          <a tabIndex={-1}>
            <StyledBodyText size="small" ellipsis={true} className={textClassName} disabled={props.disabled}>
              {props.text}
            </StyledBodyText>
          </a>
        </Link>
      ) : null}

      {!props.href && props.text ? (
        <StyledBodyText size="small" ellipsis={true} className={textClassName} disabled={props.disabled}>
          {props.text}
        </StyledBodyText>
      ) : null}
      {endSlot}
    </StyledCell>
  );
}
const textClassName = 'cellText';

const StyledCell = styled(SpaceBetween)<TableSlotCellProps>`
  width: 100%;
  height: ${rem('32px')};
  .textClassName + * {
    flex-shrink: 0; // make sure long texts doesn't shrink siblings
  }

  ${(props) => {
    switch (props.alignment) {
      case 'end':
        return css`
          justify-content: flex-end;
        `;
      case 'middle':
        return css`
          justify-content: center;
        `;
      case 'start':
      default:
        return css`
          justify-content: flex-start;
        `;
    }
  }}

  a {
    text-decoration: none;
  }
`;

const StartSlotWrapper = styled.span`
  width: ${spacing.size32};
  height: ${spacing.size16};
  margin-right: ${spacing.size8};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.size8};
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  > * {
    height: 100%;
  }
`;

interface StyledBodyTextProps {
  disabled?: boolean;
}

const StyledBodyText = styled(BodyText)<StyledBodyTextProps>`
  ${(props) =>
    props.disabled &&
    css`
      color: ${color.additional.disabled.text};
    `}
`;
