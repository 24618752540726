import { CountryCode, Language } from '@lessonup/utils';

// Sorted alphabetically by country name
export const countryNamesEn: Record<CountryCode, string> = {
  af: 'Afghanistan',
  ax: 'Åland',
  al: 'Albania',
  dz: 'Algeria',
  as: 'American Samoa',
  ad: 'Andorra',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctica',
  ag: 'Antigua and Barbuda',
  ar: 'Argentina',
  am: 'Armenia',
  aw: 'Aruba',
  au: 'Australia',
  at: 'Austria',
  az: 'Azerbaijan',
  bs: 'Bahamas',
  bh: 'Bahrain',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarus',
  be: 'Belgium',
  bz: 'Belize',
  bj: 'Benin',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bo: 'Bolivia',
  bq: 'Bonaire',
  ba: 'Bosnia and Herzegovina',
  bw: 'Botswana',
  bv: 'Bouvet Island',
  br: 'Brazil',
  io: 'British Indian Ocean Territory',
  vg: 'British Virgin Islands',
  bn: 'Brunei',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodia',
  cm: 'Cameroon',
  ca: 'Canada',
  cv: 'Cape Verde',
  ky: 'Cayman Islands',
  cf: 'Central African Republic',
  td: 'Chad',
  cl: 'Chile',
  cn: 'China',
  cx: 'Christmas Island',
  cc: 'Cocos [Keeling] Islands',
  co: 'Colombia',
  km: 'Comoros',
  ck: 'Cook Islands',
  cr: 'Costa Rica',
  hr: 'Croatia',
  cu: 'Cuba',
  cw: 'Curacao',
  cy: 'Cyprus',
  cz: 'Czech Republic',
  cd: 'Democratic Republic of the Congo',
  dk: 'Denmark',
  dj: 'Djibouti',
  dm: 'Dominica',
  do: 'Dominican Republic',
  tl: 'East Timor',
  ec: 'Ecuador',
  eg: 'Egypt',
  sv: 'El Salvador',
  gq: 'Equatorial Guinea',
  er: 'Eritrea',
  ee: 'Estonia',
  et: 'Ethiopia',
  fk: 'Falkland Islands',
  fo: 'Faroe Islands',
  fj: 'Fiji',
  fi: 'Finland',
  fr: 'France',
  gf: 'French Guiana',
  pf: 'French Polynesia',
  tf: 'French Southern Territories',
  ga: 'Gabon',
  gm: 'Gambia',
  ge: 'Georgia',
  de: 'Germany',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gr: 'Greece',
  gl: 'Greenland',
  gd: 'Grenada',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gg: 'Guernsey',
  gn: 'Guinea',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  ht: 'Haiti',
  hm: 'Heard Island and McDonald Islands',
  hn: 'Honduras',
  hk: 'Hong Kong',
  hu: 'Hungary',
  is: 'Iceland',
  in: 'India',
  id: 'Indonesia',
  ir: 'Iran',
  iq: 'Iraq',
  ie: 'Ireland',
  im: 'Isle of Man',
  il: 'Israel',
  it: 'Italy',
  ci: 'Ivory Coast',
  jm: 'Jamaica',
  jp: 'Japan',
  je: 'Jersey',
  jo: 'Jordan',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  ki: 'Kiribati',
  xk: 'Kosovo',
  kw: 'Kuwait',
  kg: 'Kyrgyzstan',
  la: 'Laos',
  lv: 'Latvia',
  lb: 'Lebanon',
  ls: 'Lesotho',
  lr: 'Liberia',
  ly: 'Libya',
  li: 'Liechtenstein',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mo: 'Macao',
  mk: 'Macedonia',
  mg: 'Madagascar',
  mw: 'Malawi',
  my: 'Malaysia',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malta',
  mh: 'Marshall Islands',
  mq: 'Martinique',
  mr: 'Mauritania',
  mu: 'Mauritius',
  yt: 'Mayotte',
  mx: 'Mexico',
  fm: 'Micronesia',
  md: 'Moldova',
  mc: 'Monaco',
  mn: 'Mongolia',
  me: 'Montenegro',
  ms: 'Montserrat',
  ma: 'Morocco',
  mz: 'Mozambique',
  mm: 'Myanmar [Burma]',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  nl: 'Netherlands',
  nc: 'New Caledonia',
  nz: 'New Zealand',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  nu: 'Niue',
  nf: 'Norfolk Island',
  kp: 'North Korea',
  mp: 'Northern Mariana Islands',
  no: 'Norway',
  om: 'Oman',
  pk: 'Pakistan',
  pw: 'Palau',
  ps: 'Palestine',
  pa: 'Panama',
  pg: 'Papua New Guinea',
  py: 'Paraguay',
  pe: 'Peru',
  ph: 'Philippines',
  pn: 'Pitcairn Islands',
  pl: 'Poland',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  cg: 'Republic of the Congo',
  ro: 'Romania',
  ru: 'Russia',
  rw: 'Rwanda',
  re: 'Réunion',
  bl: 'Saint Barthélemy',
  sh: 'Saint Helena',
  kn: 'Saint Kitts and Nevis',
  lc: 'Saint Lucia',
  mf: 'Saint Martin',
  pm: 'Saint Pierre and Miquelon',
  vc: 'Saint Vincent and the Grenadines',
  ws: 'Samoa',
  sm: 'San Marino',
  st: 'São Tomé and Príncipe',
  sa: 'Saudi Arabia',
  sn: 'Senegal',
  rs: 'Serbia',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sx: 'Sint Maarten',
  sk: 'Slovakia',
  si: 'Slovenia',
  sb: 'Solomon Islands',
  so: 'Somalia',
  za: 'South Africa',
  gs: 'South Georgia and the South Sandwich Islands',
  kr: 'South Korea',
  ss: 'South Sudan',
  es: 'Spain',
  lk: 'Sri Lanka',
  sd: 'Sudan',
  sr: 'Suriname',
  sj: 'Svalbard and Jan Mayen',
  sz: 'Swaziland',
  se: 'Sweden',
  ch: 'Switzerland',
  sy: 'Syria',
  tw: 'Taiwan',
  tj: 'Tajikistan',
  tz: 'Tanzania',
  th: 'Thailand',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad and Tobago',
  tn: 'Tunisia',
  tr: 'Turkey',
  tm: 'Turkmenistan',
  tc: 'Turks and Caicos Islands',
  tv: 'Tuvalu',
  um: 'U.S. Minor Outlying Islands',
  vi: 'U.S. Virgin Islands',
  ug: 'Uganda',
  ua: 'Ukraine',
  ae: 'United Arab Emirates',
  gb: 'United Kingdom',
  us: 'United States',
  uy: 'Uruguay',
  uz: 'Uzbekistan',
  vu: 'Vanuatu',
  va: 'Vatican City',
  ve: 'Venezuela',
  vn: 'Vietnam',
  wf: 'Wallis and Futuna',
  eh: 'Western Sahara',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe',
};

// Downloaded and adapted from https://stefangabos.github.io/world_countries/
// Sorted alphabetically by country name
export const countryNamesNl: Record<CountryCode, string> = {
  af: 'Afghanistan',
  ax: 'Åland',
  al: 'Albanië',
  dz: 'Algerije',
  vi: 'Amerikaanse Maagdeneilanden',
  as: 'Amerikaans-Samoa',
  ad: 'Andorra',
  ao: 'Angola',
  ai: 'Anguilla',
  aq: 'Antarctica',
  ag: 'Antigua en Barbuda',
  ar: 'Argentinië',
  am: 'Armenië',
  aw: 'Aruba',
  au: 'Australië',
  az: 'Azerbeidzjan',
  bs: "Bahama's",
  bh: 'Bahrein',
  bd: 'Bangladesh',
  bb: 'Barbados',
  be: 'België',
  bz: 'Belize',
  bj: 'Benin',
  bm: 'Bermuda',
  bt: 'Bhutan',
  bo: 'Bolivia',
  ba: 'Bosnië en Herzegovina',
  bw: 'Botswana',
  bv: 'Bouvet',
  br: 'Brazilië',
  vg: 'Britse Maagdeneilanden',
  io: 'Brits Indische Oceaanterritorium',
  bn: 'Brunei',
  bg: 'Bulgarije',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodja',
  ca: 'Canada',
  bq: 'Caribisch Nederland',
  cf: 'Centraal-Afrikaanse Republiek',
  cl: 'Chili',
  cn: 'China',
  cx: 'Christmaseiland',
  cc: 'Cocoseilanden',
  co: 'Colombia',
  km: 'Comoren',
  cg: 'Congo-Brazzaville',
  cd: 'Congo-Kinshasa',
  ck: 'Cookeilanden',
  cr: 'Costa Rica',
  cu: 'Cuba',
  cw: 'Curaçao',
  cy: 'Cyprus',
  dk: 'Denemarken',
  dj: 'Djibouti',
  dm: 'Dominica',
  do: 'Dominicaanse Republiek',
  de: 'Duitsland',
  ec: 'Ecuador',
  eg: 'Egypte',
  sv: 'El Salvador',
  gq: 'Equatoriaal-Guinea',
  er: 'Eritrea',
  ee: 'Estland',
  et: 'Ethiopië',
  fo: 'Faeröer',
  fk: 'Falklandeilanden',
  fj: 'Fiji',
  ph: 'Filipijnen',
  fi: 'Finland',
  fr: 'Frankrijk',
  tf: 'Franse Zuidelijke Gebieden',
  gf: 'Frans-Guyana',
  pf: 'Frans-Polynesië',
  ga: 'Gabon',
  gm: 'Gambia',
  ge: 'Georgië',
  gh: 'Ghana',
  gi: 'Gibraltar',
  gd: 'Grenada',
  gr: 'Griekenland',
  gl: 'Groenland',
  gp: 'Guadeloupe',
  gu: 'Guam',
  gt: 'Guatemala',
  gg: 'Guernsey',
  gn: 'Guinee',
  gw: 'Guinee-Bissau',
  gy: 'Guyana',
  ht: 'Haïti',
  hm: 'Heard en McDonaldeilanden',
  hn: 'Honduras',
  hu: 'Hongarije',
  hk: 'Hongkong',
  ie: 'Ierland',
  is: 'IJsland',
  in: 'India',
  id: 'Indonesië',
  iq: 'Irak',
  ir: 'Iran',
  il: 'Israël',
  it: 'Italië',
  ci: 'Ivoorkust',
  jm: 'Jamaica',
  jp: 'Japan',
  ye: 'Jemen',
  je: 'Jersey',
  jo: 'Jordanië',
  ky: 'Kaaimaneilanden',
  cv: 'Kaapverdië',
  cm: 'Kameroen',
  kz: 'Kazachstan',
  ke: 'Kenia',
  kg: 'Kirgizië',
  ki: 'Kiribati',
  um: 'Kleine afgelegen eilanden van de Verenigde Staten',
  kw: 'Koeweit',
  xk: 'Kosovo',
  hr: 'Kroatië',
  la: 'Laos',
  ls: 'Lesotho',
  lv: 'Letland',
  lb: 'Libanon',
  lr: 'Liberia',
  ly: 'Libië',
  li: 'Liechtenstein',
  lt: 'Litouwen',
  lu: 'Luxemburg',
  mo: 'Macau',
  mg: 'Madagaskar',
  mw: 'Malawi',
  mv: 'Malediven',
  my: 'Maleisië',
  ml: 'Mali',
  mt: 'Malta',
  im: 'Man',
  ma: 'Marokko',
  mh: 'Marshalleilanden',
  mq: 'Martinique',
  mr: 'Mauritanië',
  mu: 'Mauritius',
  yt: 'Mayotte',
  mx: 'Mexico',
  fm: 'Micronesië',
  md: 'Moldavië',
  mc: 'Monaco',
  mn: 'Mongolië',
  me: 'Montenegro',
  ms: 'Montserrat',
  mz: 'Mozambique',
  mm: 'Myanmar',
  na: 'Namibië',
  nr: 'Nauru',
  nl: 'Nederland',
  np: 'Nepal',
  ni: 'Nicaragua',
  nc: 'Nieuw-Caledonië',
  nz: 'Nieuw-Zeeland',
  ne: 'Niger',
  ng: 'Nigeria',
  nu: 'Niue',
  mp: 'Noordelijke Marianen',
  kp: 'Noord-Korea',
  mk: 'Noord-Macedonië',
  no: 'Noorwegen',
  nf: 'Norfolk',
  ug: 'Oeganda',
  ua: 'Oekraïne',
  uz: 'Oezbekistan',
  om: 'Oman',
  at: 'Oostenrijk',
  tl: 'Oost-Timor',
  pk: 'Pakistan',
  pw: 'Palau',
  ps: 'Palestina',
  pa: 'Panama',
  pg: 'Papoea-Nieuw-Guinea',
  py: 'Paraguay',
  pe: 'Peru',
  pn: 'Pitcairneilanden',
  pl: 'Polen',
  pt: 'Portugal',
  pr: 'Puerto Rico',
  qa: 'Qatar',
  re: 'Réunion',
  ro: 'Roemenië',
  ru: 'Rusland',
  rw: 'Rwanda',
  bl: 'Saint-Barthélemy',
  kn: 'Saint Kitts en Nevis',
  lc: 'Saint Lucia',
  pm: 'Saint-Pierre en Miquelon',
  vc: 'Saint Vincent en de Grenadines',
  sb: 'Salomonseilanden',
  ws: 'Samoa',
  sm: 'San Marino',
  sa: 'Saoedi-Arabië',
  st: 'Sao Tomé en Principe',
  sn: 'Senegal',
  rs: 'Servië',
  sc: 'Seychellen',
  sl: 'Sierra Leone',
  sg: 'Singapore',
  sh: 'Sint-Helena, Ascension en Tristan da Cunha',
  mf: 'Saint-Martin',
  sx: 'Sint Maarten',
  si: 'Slovenië',
  sk: 'Slowakije',
  sd: 'Soedan',
  so: 'Somalië',
  es: 'Spanje',
  sj: 'Spitsbergen en Jan Mayen',
  lk: 'Sri Lanka',
  sr: 'Suriname',
  sz: 'Swaziland',
  sy: 'Syrië',
  tj: 'Tadzjikistan',
  tw: 'Taiwan',
  tz: 'Tanzania',
  th: 'Thailand',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad en Tobago',
  td: 'Tsjaad',
  cz: 'Tsjechië',
  tn: 'Tunesië',
  tr: 'Turkije',
  tm: 'Turkmenistan',
  tc: 'Turks- en Caicoseilanden',
  tv: 'Tuvalu',
  uy: 'Uruguay',
  vu: 'Vanuatu',
  va: 'Vaticaanstad',
  ve: 'Venezuela',
  ae: 'Verenigde Arabische Emiraten',
  us: 'Verenigde Staten',
  gb: 'Verenigd Koninkrijk',
  vn: 'Vietnam',
  wf: 'Wallis en Futuna',
  eh: 'Westelijke Sahara/SADR',
  by: 'Wit-Rusland',
  zm: 'Zambia',
  zw: 'Zimbabwe',
  za: 'Zuid-Afrika',
  gs: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
  kr: 'Zuid-Korea',
  ss: 'Zuid-Soedan',
  se: 'Zweden',
  ch: 'Zwitserland',
};

export function countryMapForCountryCode(language: Language): Record<CountryCode, string> {
  return language === 'nl' ? countryNamesNl : countryNamesEn;
}
