/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * This interface is a subset of the Meteor global object.
 */
export interface Meteor {
  Error: ErrorConstructor;
  userAsync: Promise<any>;
  [key: string]: any;
}

type ErrorConstructor = new (error: string | number, reason?: string, details?: string) => MeteorError;
export interface MeteorError extends Error {
  error: string | number;
  reason?: string;
  details?: string;
  errorType?: string;
}
/**
 * Helper function to get the Meteor global object, should rarely be used in the 2.0 codebase.
 */
export function getMeteorGlobal(): Meteor | undefined {
  if (typeof window !== 'undefined') {
    return typeof (window as any).Meteor !== 'undefined' ? (window as any).Meteor : undefined;
  }
  if (typeof global !== 'undefined') {
    return typeof (global as any).Meteor !== 'undefined' ? (global as any).Meteor : undefined;
  }
}
