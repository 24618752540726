import { eventBus, useEventBusResponder } from '@lessonup/client-integration';
import { VIDEO_EVENTS } from '../video.utils';

export function useVideoPlayerCurrentTimeRequest(compId: string, getCurrentTime: () => Promise<number>) {
  useEventBusResponder<void, number>(
    eventBus,
    `${VIDEO_EVENTS.REQUEST_CURRENT_TIME}-${compId}`,
    `${VIDEO_EVENTS.RECEIVE_CURRENT_TIME}-${compId}`,
    async () => {
      const currentTime = await getCurrentTime();
      return currentTime || 0;
    }
  );
}
