import { useUpdatePin } from '@lessonup/pin-renderer';
import { PaddingSetting as TextPinComponentPaddingSetting, TextPinComponent } from '@lessonup/pins-shared';
import { color, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import { isEqual } from 'lodash';
import React from 'react';
import { useEditorTranslation } from '../../../../../../hooks/useEditorTranslation';
import { SettingsToggle } from '../../../../../SettingsToggle/SettingsToggle';
import { EditorInputFormField } from '../../../EditorInputFields/EditorInputFormField';

type PaddingPresetKeys = 'none' | 'small' | 'medium' | 'large';

type PaddingSettingProps = {
  pinComponent: TextPinComponent;
};

const paddingPresetValues: Record<PaddingPresetKeys, TextPinComponentPaddingSetting> = {
  none: { top: 0, bottom: 0, left: 0, right: 0 },
  small: { top: 2, bottom: 2, left: 10, right: 10 },
  medium: { top: 4, bottom: 4, left: 12, right: 12 },
  large: { top: 8, bottom: 8, left: 16, right: 16 },
};

export const PaddingSetting: React.FC<PaddingSettingProps> = ({ pinComponent }) => {
  const { t } = useEditorTranslation();
  const dispatch = useUpdatePin();
  const currentValue = pinComponent.settings.padding ?? paddingPresetValues['medium'];

  const handlePaddingChange = (padding: TextPinComponentPaddingSetting) => {
    dispatch({
      type: 'updatePinComponentSettings',
      pinComponentId: pinComponent.id,
      debounce: 500,
      settings: {
        padding,
      },
    });
  };

  const sanitizePadding = (value: number) => {
    if (value < 0) {
      return;
    }
    handlePaddingChange({ top: value, bottom: value, left: value, right: value });
  };

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      <SpaceBetween direction="x" spacing={spacing.size4} alignItems="center" justifyContent="space-around">
        <SettingsToggle
          value="none"
          type="checkbox"
          onChange={() => handlePaddingChange(paddingPresetValues.none)}
          caption={t(`contextSidebar.settings.padding.none`)}
          toggleVariant="caption"
          checked={isEqual(currentValue, paddingPresetValues['none'])}
        />
        <SettingsToggle
          value="small"
          type="checkbox"
          onChange={() => handlePaddingChange(paddingPresetValues.small)}
          caption={t(`contextSidebar.settings.padding.small`)}
          toggleVariant="caption"
          checked={isEqual(currentValue, paddingPresetValues['small'])}
          icon={<StyledPaddingIcon style={{ '--padding-displacement': '2px' } as React.CSSProperties} />}
        />
        <SettingsToggle
          value="medium"
          type="checkbox"
          onChange={() => handlePaddingChange(paddingPresetValues.medium)}
          caption={t(`contextSidebar.settings.padding.medium`)}
          toggleVariant="caption"
          checked={isEqual(currentValue, paddingPresetValues['medium'])}
          icon={<StyledPaddingIcon style={{ '--padding-displacement': '4px' } as React.CSSProperties} />}
        />
        <SettingsToggle
          value="large"
          caption={t(`contextSidebar.settings.padding.large`)}
          onChange={() => handlePaddingChange(paddingPresetValues['large'])}
          type="checkbox"
          toggleVariant="caption"
          checked={isEqual(currentValue, paddingPresetValues['large'])}
          icon={<StyledPaddingIcon style={{ '--padding-displacement': '8px' } as React.CSSProperties} />}
        />
      </SpaceBetween>
      <StyledFormWrapper>
        <EditorInputFormField
          id={`padding-${pinComponent.id}`}
          type="number"
          orientation="horizontal"
          horizontalLabelSpacing={spacing.size4}
          horizontalLabelAutoWidth={true}
          label={t(`contextSidebar.settings.padding.custom`)}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            sanitizePadding(Number(e.currentTarget.value));
          }}
          value={Math.max(...Object.values(currentValue))}
          min={0}
          max={999}
        />
        {<StyledCustomUnit>{'px'}</StyledCustomUnit>}
      </StyledFormWrapper>
    </SpaceBetween>
  );
};

const StyledCustomUnit = styled.span`
  color: ${color.neutral.surface.text};
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.size4};
`;

const StyledPaddingIcon = styled.div`
  background: #e0e3eb;
  position: relative;
  height: 16px;
  width: 16px;
  overflow: hidden;

  &::after {
    content: ' ';
    position: absolute;
    height: calc(100% - var(--padding-displacement));
    width: calc(100% - var(--padding-displacement));
    background: white;
    top: var(--padding-displacement);
    left: var(--padding-displacement);
  }
`;
