import { Route } from '@lessonup/client-integration';
import { BodyText, ButtonAnchor, Headline, Link, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SubscriptionRoute } from '../../utils/bridge/teacherRoutes';
import { mySubscriptionI18NNS } from './MySubscriptionFeature.utils';

const CONFETTI_VIDEO_URL = '/video/confetti.mp4';

export interface MySubscriptionSuccessFeatureProps {
  continueButtonRoute: Route<SubscriptionRoute>;
}

export const MySubscriptionSuccessFeature = ({ continueButtonRoute }: MySubscriptionSuccessFeatureProps) => {
  const { t } = useTranslation(mySubscriptionI18NNS);

  return (
    <StyledMySubscriptionSuccess>
      <SpaceBetween direction="y" spacing={spacing.size32}>
        <Headline size="medium">{t('success.heading')}</Headline>
        <StyledConfettiWrapper>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <video autoPlay loop muted webkit-playsinline playsInline width="250" height="250">
            <source src={CONFETTI_VIDEO_URL} type="video/mp4" />
          </video>
        </StyledConfettiWrapper>
        <BodyText weight="bold">{t('success.congratulations')}</BodyText>
        <div>
          <Link href={continueButtonRoute.href({})} passHref>
            <ButtonAnchor>{t('success.continue')}</ButtonAnchor>
          </Link>
        </div>
      </SpaceBetween>
    </StyledMySubscriptionSuccess>
  );
};

const StyledMySubscriptionSuccess = styled.div`
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const StyledConfettiWrapper = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;
