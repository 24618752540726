import { useLocale } from '@lessonup/client-integration';
import { BodyText, SpaceBetween, spacing } from '@lessonup/ui-components';
import { formatDuration, localeToDateFnsLocale } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ReferralExplanationProps {
  amountOfExtraTrialDays?: number;
}

const MAX_DAYS_IN_MONTH = 31;

export const ReferralExplanation = ({ amountOfExtraTrialDays = 0 }: ReferralExplanationProps) => {
  const { t } = useTranslation('referralExplanation');
  const locale = useLocale();
  const amountOfMonths = Math.floor(amountOfExtraTrialDays / MAX_DAYS_IN_MONTH);
  const extraTime =
    amountOfMonths > 0
      ? formatDuration(
          {
            months: amountOfMonths,
          },
          { format: ['months'], locale: localeToDateFnsLocale(locale) }
        )
      : formatDuration(
          {
            days: amountOfExtraTrialDays,
          },
          { format: ['days'], locale: localeToDateFnsLocale(locale) }
        );

  return (
    <SpaceBetween direction="y" spacing={spacing.size8}>
      {amountOfExtraTrialDays ? <BodyText weight="bold">{t('giveExtraTime', { extraTime })}</BodyText> : null}
      <BodyText>{t('inviteYourColleagues')}</BodyText>
    </SpaceBetween>
  );
};
