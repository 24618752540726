import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconDragIndicator = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.025 8.215h7.95M8.025 12h7.95m-7.95 3.785h7.95"
    />
  </svg>
);
export default SvgIconDragIndicator;
