import { Component } from '../Component';
import { injectSvgDefinitions } from './injectSymbolDef';
import { ArrowSymbol, arrowSymbol } from './symbolDefinitions/ArrowSymbol';
import { CircleSymbol, circleSymbol } from './symbolDefinitions/CircleSymbol';
import { HexagonSymbol, hexagonSymbol } from './symbolDefinitions/HexagonSymbol';
import { RectangleSymbol, rectangleSymbol } from './symbolDefinitions/RectangleSymbol';
import { RocketSymbol, rocketSymbol } from './symbolDefinitions/RocketSymbol';
import { starSymbol, StarSymbol } from './symbolDefinitions/StarSymbol';
import { TriangleSymbol, triangleSymbol } from './symbolDefinitions/TriangleSymbol';

export interface SymbolComponent extends Component {
  type: SymbolComponent.Type;
  position: Component.Box;
  settings: SymbolComponent.Settings;
}

export interface SVGGeneratorProps {
  position: Component.Box;
  settings: SymbolComponent.Settings;
}

export interface SymbolDefinition {
  lockedAspectRatio: boolean;
  startWithFixedAspectRatio: boolean;
  hasStrokeSettings: boolean;
}

export type AllSymbols =
  | ArrowSymbol
  | StarSymbol
  | RectangleSymbol
  | TriangleSymbol
  | CircleSymbol
  | HexagonSymbol
  | RocketSymbol;
export type Symbols = AllSymbols['name'];

const symbolOrder: Symbols[] = ['rectangle', 'triangle', 'circle', 'hexagon', 'star', 'arrow', 'rocket'];

export namespace SymbolComponent {
  export const type = 'symbol';
  export type Type = typeof type;

  export interface Settings extends Component.Settings {
    symbol: Symbols;
    color: string;
    strokeColor: string;
    strokeWidth: string;
    strokeDash: string;
    fillPattern?: string;
  }

  export function getSymbolArray() {
    return symbolOrder;
  }
  // Need a function for symbols with a fixed aspect ratio
  export function symbolForName(name: Symbols): SymbolDefinition {
    if (name === 'arrow') return arrowSymbol;
    if (name === 'star') return starSymbol;
    if (name === 'rectangle') return rectangleSymbol;
    if (name === 'triangle') return triangleSymbol;
    if (name === 'circle') return circleSymbol;
    if (name === 'hexagon') return hexagonSymbol;
    if (name === 'rocket') return rocketSymbol;
    throw Error('missing def');
  }

  export function injectDefsIfNeeded() {
    if (typeof window === 'undefined') return;
    injectSvgDefinitions();
  }

  export const fillColors = [
    'none',
    'white',
    'brown-dark',
    'yellow',
    'pink',
    'blue',
    'green',
    'orange',
    'cyan',
    'black',
  ];
}
