import { color, styled } from '@lessonup/ui-components';
import React from 'react';

export const BlinkingCursor = () => {
  return <BlinkingCursorSpan>{'|'}</BlinkingCursorSpan>;
};

const BlinkingCursorSpan = styled.span`
  font-weight: 100;
  color: ${color.neutral.surface.text};
  animation: 1s blink step-end infinite;

  @keyframes blink {
    0% {
      color: transparent;
    }
    50% {
      color: ${color.neutral.surface.text};
    }
    100% {
      color: transparent;
    }
  }
`;
