import { exhaustiveStringTuple } from '@lessonup/utils-support';

export type Language = 'nl' | 'en' | 'fr';

// These are the languages in which LessonUp is fully supported.
export type MainLanguage = 'nl' | 'en';

export const languages = exhaustiveStringTuple<Language>()('nl', 'en', 'fr');

export function isValidLanguage(string: string): string is Language {
  return languages.includes(string as Language);
}
