import { Nullable, omitDeepUndefined } from '@lessonup/utils';
import _ from 'lodash';
import { Grade } from '../common/Grade';
import { TestPhase } from '../common/TestPhase';
import { AssignmentEntry } from './AssignmentEntry';
import { PinEntryUpdateData } from './PinEntryUpdateData';

interface AssignmentEntryUpdateFields
  extends Partial<
    Pick<AssignmentEntry, 'displayName' | 'status' | 'totalPins' | 'startDate' | 'closeDate' | 'raiseHand'>
  > {
  removeEntriesPinIds?: string[];
  removeAllPinEntries?: boolean;
  phase?: TestPhase;
  grade?: Grade;
  pinEntries?: PinEntryUpdateData[];
  seenAt?: number;
  activePinId?: string;
  raiseHandQuestion?: string;
  raiseHandPinId?: string;
}
export type AssignmentEntryUpdateData = Nullable<AssignmentEntryUpdateFields>;

export type AssignmentEntryUnsetKeys = keyof Pick<
  AssignmentEntryUpdateFields,
  'displayName' | 'activePinId' | 'startDate' | 'closeDate' | 'raiseHand'
>;

export namespace AssignmentEntryUpdateData {
  export function updateFields(data: AssignmentEntryUpdateData, unsetFields?: AssignmentEntryUnsetKeys[]): object {
    const fields: _.Dictionary<any> = {};
    const now = Date.now();

    if (!_.isNil(data.status)) fields['status'] = data.status;
    if (!_.isNil(data.grade)) fields[`testData.grade`] = data.grade;
    if (!_.isNil(data.phase)) fields[`testData.phase`] = data.phase;
    if (!_.isNil(data.seenAt)) fields[`seenAt`] = data.seenAt;
    if (!_.isNil(data.totalPins)) fields[`totalPins`] = data.totalPins;
    if (!_.isNil(data.raiseHand)) fields['raiseHand'] = data.raiseHand;
    if (!_.isNil(data.raiseHandQuestion)) fields['raiseHand.question'] = data.raiseHandQuestion;
    if (!_.isNil(data.raiseHandPinId)) fields['raiseHand.pinId'] = data.raiseHandPinId;

    // nullable
    if (!_.isUndefined(data.displayName)) fields[`displayName`] = data.displayName;
    if (!_.isUndefined(data.activePinId)) fields[`activePinId`] = data.activePinId;
    if (!_.isUndefined(data.startDate)) updateFields['startDate'] = data.startDate;
    if (!_.isUndefined(data.closeDate)) updateFields['closeDate'] = data.closeDate;

    _.forEach(data.pinEntries, (pinEntry) => {
      const pinId = pinEntry.pinId;
      fields[`entries.${pinId}.pinId`] = pinEntry.pinId;
      fields[`entries.${pinId}.type`] = pinEntry.type;

      updateProperty(fields, `entries.${pinId}.seen`, pinEntry.seen);
      updateProperty(fields, `entries.${pinId}.seenAt`, pinEntry.seenAt);
      updateProperty(fields, `entries.${pinId}.done`, pinEntry.done);
      updateProperty(fields, `entries.${pinId}.result`, pinEntry.result);
      updateProperty(fields, `entries.${pinId}.timeSpent`, pinEntry.timeSpent);
      updateProperty(fields, `entries.${pinId}.answer`, pinEntry.answer);
      updateProperty(fields, `entries.${pinId}.points`, pinEntry.points);
      updateProperty(fields, `entries.${pinId}.comment`, pinEntry.comment);
      updateProperty(fields, `entries.${pinId}.commentSeenAt`, pinEntry.commentSeenAt);
      updateProperty(fields, `entries.${pinId}.commentLeftBy`, pinEntry.commentLeftBy);
      updateProperty(fields, `entries.${pinId}.resultType`, pinEntry.resultType);
    });

    if (data.removeAllPinEntries) {
      fields[`entries`] = {};
    }
    _.forEach(data.removeEntriesPinIds, (pinId) => (fields[`entries.${pinId}`] = null));

    const cleaned = omitDeepUndefined(fields);
    if (unsetFields) {
      unsetFields.forEach((key) => {
        if (key) cleaned[key] = null;
      });
    }
    if (!_.isEmpty(cleaned)) {
      cleaned['modifiedAt'] = now;
    }
    return cleaned;
  }
}

/** helper for setting the property, when the value is null. it will delete the field */
function updateProperty(updateFields: _.Dictionary<any>, field: string, value: any | null | undefined) {
  if (_.isNull(value)) {
    // eslint-disable-next-line no-param-reassign
    updateFields[field] = null;
  } else if (!_.isNil(value)) {
    // eslint-disable-next-line no-param-reassign
    updateFields[field] = value;
  }
}
