import { ComponentElevation } from './elevation.types';

const shadowColor = '#00134359';

export const elevationShadow: Record<ComponentElevation, string | undefined> = {
  e000: undefined,
  e100: `0px 1px 2px 0px ${shadowColor}`,
  e200: `0px 2px 4px 2px ${shadowColor}`,
  e300: `0px 8px 12px 2px ${shadowColor}`,
};
