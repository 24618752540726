import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLessonSession = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.49 10.52c.91-.184 1.395-.334 2.212-.683-.173-.871-.323-1.356-.684-2.212m-.492 1.627a3.797 3.797 0 1 0 1.382 4.243M3.273 19.331c.078.72.65 1.29 1.37 1.37 2.353.257 4.818.61 7.357.61s5.004-.353 7.358-.61a1.55 1.55 0 0 0 1.369-1.37c.251-2.345.584-4.801.584-7.331s-.333-4.986-.584-7.33a1.55 1.55 0 0 0-1.37-1.37c-2.353-.258-4.818-.611-7.357-.611s-5.004.353-7.358.61c-.72.08-1.291.65-1.369 1.37C3.022 7.014 2.69 9.47 2.69 12s.333 4.986.584 7.33"
    />
  </svg>
);
export default SvgIconLessonSession;
