import firebase from 'firebase/compat/app';

export async function getFirebaseIdToken() {
  // eslint-disable-next-line import/no-named-as-default-member
  const auth = firebase.app().auth();
  if (!auth) throw new Error(`No firebase instance populated`);
  const firebaseIdToken = await auth.currentUser?.getIdToken();
  if (!firebaseIdToken) throw new Error(`Failed to fetch firebase Id token`);
  return firebaseIdToken;
}
