import _ from 'lodash';
import { AssignmentPin } from '../assignment';
import { DragQuestion, OpenQuestion, PinType, PinUtils, Poll, QuizQuestion, Wordweb } from '../common';

export type AnyPinEntry =
  | PinEntry.Link
  | PinEntry.Slide
  | PinEntry.Video
  | PinEntry.Map
  | PinEntry.OpenQuestion
  | PinEntry.QuizQuestion
  | PinEntry.DragQuestion
  | PinEntry.Wordweb
  | PinEntry.Poll;

type PinEntriesByType<PinType> = {
  ['poll']: PinEntry.Poll;
};
export type AnyAnswer =
  | OpenQuestion.Answer
  | QuizQuestion.Answer
  | DragQuestion.Answer
  | Wordweb.Answer
  | Poll.Answer.StudentAnswer;

export type AnyAnswerResult = OpenQuestion.AnswerResult | QuizQuestion.AnswerResult | DragQuestion.AnswerResult;

export type AnyAnswerResultType = AnyAnswerResult['type'];

export interface PinEntry<
  A = AnyAnswer | undefined,
  R = AnyAnswerResult | undefined,
  T = AnyAnswerResultType | undefined
> {
  pinId: string;
  type: PinType;
  seen?: boolean;
  seenAt?: number; // timestamp
  done?: boolean;
  timeSpent?: number; // total time spend on pin
  answer?: A; // latest answer
  /**
   * Calculated result based on the correctAnswer in the assignmentMeta
   * if an answer is correct (for tests) depends also depends on the given points. If any points are set, that determines the isCorrect or isIncorrect
   */
  result?: R;
  points?: number; // Overwrite for default point calculation, set by teacher
  comment?: string; // Comment left by teacher on revise
  commentSeenAt?: number;
  commentLeftBy?: string;
  resultType?: T;
}

/**
 * PinEntry is a namespace containing the user answers for a single pin
 * This is part of AssignmentEntry
 */
export namespace PinEntry {
  export type WithDisplayName = {
    displayName?: string;
  } & AnyPinEntry;

  export type UpdateData = Partial<
    Pick<
      AnyPinEntry,
      | 'timeSpent'
      | 'answer'
      | 'result'
      | 'seen'
      | 'seenAt'
      | 'done'
      | 'comment'
      | 'commentSeenAt'
      | 'points'
      | 'commentLeftBy'
      | 'resultType'
    >
  >;

  export interface Map extends PinEntry {
    type: 'map';
  }

  export interface Link extends PinEntry {
    type: 'link';
  }

  export interface Slide extends PinEntry {
    type: 'slide';
  }

  export interface Video extends PinEntry {
    type: 'video';
  }

  export interface OpenQuestion extends PinEntry<OpenQuestion.Answer, OpenQuestion.AnswerResult> {
    type: 'openQuestion';
  }

  export interface QuizQuestion extends PinEntry<QuizQuestion.Answer, QuizQuestion.AnswerResult> {
    type: 'question';
  }

  export interface DragQuestion extends PinEntry<DragQuestion.Answer, DragQuestion.AnswerResult> {
    type: 'dragQuestion';
  }

  export interface Wordweb extends PinEntry<Wordweb.Answer> {
    type: 'wordweb';
  }

  export interface Poll extends PinEntry<Poll.AnswerValue> {
    type: 'poll';
  }
}

export namespace PinEntry {
  export function timeSpent(entry: AnyPinEntry): number {
    if (!_.isNil(entry.timeSpent)) return entry.timeSpent;
    // realtime
    const seenAt = entry.seenAt;
    if (seenAt && entry.answer && entry.answer.modifiedAt) {
      return entry.answer.modifiedAt - seenAt;
    }
    return 0;
  }

  export function isDone(entry: AnyPinEntry): boolean {
    return !!entry.done;
  }

  export function isOpenQuestion(entry: AnyPinEntry): entry is PinEntry.OpenQuestion {
    return entry.type == 'openQuestion';
  }

  export function areOpenQuestions(entries: AnyPinEntry[]): entries is PinEntry.OpenQuestion[] {
    return _.every(entries, (entry) => isOpenQuestion(entry));
  }

  export function isWordweb(entry: AnyPinEntry): entry is PinEntry.Wordweb {
    return entry.type == 'wordweb';
  }

  export function areWordwebs(entries: AnyPinEntry[]): entries is PinEntry.Wordweb[] {
    return _.every(entries, (entry) => isWordweb(entry));
  }

  export function isQuizQuestion(entry: AnyPinEntry): entry is PinEntry.QuizQuestion {
    return entry.type == 'question';
  }

  export function areQuizQuestions(entries: AnyPinEntry[]): entries is PinEntry.QuizQuestion[] {
    return _.every(entries, (entry) => isQuizQuestion(entry));
  }

  export function isDragQuestion(entry: AnyPinEntry): entry is PinEntry.DragQuestion {
    return entry.type == 'dragQuestion';
  }

  export function areDragQuestions(entries: AnyPinEntry[]): entries is PinEntry.DragQuestion[] {
    return _.every(entries, (entry) => isDragQuestion(entry));
  }

  export function isMap(entry: AnyPinEntry): entry is PinEntry.Map {
    return entry.type == 'map';
  }

  export function areMaps(entries: AnyPinEntry[]): entries is PinEntry.Map[] {
    return _.every(entries, (entry) => isMap(entry));
  }

  export function isSlide(entry: AnyPinEntry): entry is PinEntry.Slide {
    return entry.type == 'slide';
  }

  export function areSlides(entries: AnyPinEntry[]): entries is PinEntry.Slide[] {
    return _.every(entries, (entry) => isSlide(entry));
  }

  export function isVideo(entry: AnyPinEntry): entry is PinEntry.Video {
    return entry.type == 'video';
  }

  export function areVideos(entries: AnyPinEntry[]): entries is PinEntry.Video[] {
    return _.every(entries, (entry) => isVideo(entry));
  }

  export function isLink(entry: AnyPinEntry): entry is PinEntry.Link {
    return entry.type == 'link';
  }

  export function areLinks(entries: AnyPinEntry[]): entries is PinEntry.Link[] {
    return _.every(entries, (entry) => isLink(entry));
  }

  export function isInterActive(entry: AnyPinEntry | undefined | null): boolean {
    return !!entry && PinUtils.typeIsInteractive(entry.type);
  }

  export function create(pin: AssignmentPin): AnyPinEntry {
    return {
      pinId: pin._id,
      type: pin.item.type,
    } as AnyPinEntry;
  }

  export function commentAndSeenDateForPinEntry(
    entry: AnyPinEntry
  ): Pick<AnyPinEntry, 'comment' | 'commentSeenAt'> | undefined {
    return entry.comment ? { comment: entry.comment, commentSeenAt: entry.commentSeenAt } : undefined;
  }

  export function hasUnseenComment(entry: AnyPinEntry): boolean {
    const commentData = commentAndSeenDateForPinEntry(entry);
    return !!(commentData && commentData.comment && !commentData.commentSeenAt);
  }

  export interface ByType {
    map: PinEntry.Map[];
    link: PinEntry.Link[];
    slide: PinEntry.Slide[];
    video: PinEntry.Video[];
    openQuestion: PinEntry.OpenQuestion[];
    question: PinEntry.QuizQuestion[];
    dragQuestion: PinEntry.DragQuestion[];
    wordweb: PinEntry.Wordweb[];
  }

  export function groupByType(enties: AnyPinEntry[]): ByType {
    const entryGroupByType: ByType = {
      map: [],
      link: [],
      slide: [],
      video: [],
      openQuestion: [],
      question: [],
      dragQuestion: [],
      wordweb: [],
    };
    enties.forEach((entry) => {
      if (entry.type == 'map' && isMap(entry)) entryGroupByType.map.push(entry);
      else if (entry.type == 'link' && isLink(entry)) entryGroupByType.link.push(entry);
      else if (entry.type == 'slide' && isSlide(entry)) entryGroupByType.slide.push(entry);
      else if (entry.type == 'video' && isVideo(entry)) entryGroupByType.video.push(entry);
      else if (entry.type == 'openQuestion' && isOpenQuestion(entry)) entryGroupByType.openQuestion.push(entry);
      else if (entry.type == 'question' && isQuizQuestion(entry)) entryGroupByType.question.push(entry);
      else if (entry.type == 'dragQuestion' && isDragQuestion(entry)) entryGroupByType.dragQuestion.push(entry);
      else if (entry.type == 'wordweb' && isWordweb(entry)) entryGroupByType.wordweb.push(entry);
    });
    return entryGroupByType;
  }
}
