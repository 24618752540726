import { MongoIDProvider } from '@lessonup/utils';
import _, { compact } from 'lodash';
import { AnyCorrectAnswer, AssignmentAnswers, AssignmentType } from '../assignment';
import {
  Colors,
  Component,
  DiffLabel,
  DragQuestion,
  Link,
  Map,
  OpenQuestion,
  Poll,
  QuizQuestion,
  Slide,
  Video,
  Wordweb,
} from '../common';
import { TableComponent } from '../common/components/table/TableComponent';
import { ExclusionType, Pin, PinItem, PinType } from '../common/Pin';
import { Lesson } from './Lesson';

export interface Orderable {
  order: number;
}

export interface LessonPin<T extends LessonContent = LessonContent> extends Pin<T> {
  _id: string;
  lesson: string;
  creationDate: Date;
  isPublic: boolean | undefined;
  shouldIndex: Date | undefined;
  originalId?: string;
  order: number;
  isFirstPin?: boolean;
  timer?: number; //T extends QuizQuestion.LessonContent ? number : never // Right now only exists on quizQuestions?
  trail?: any[];
  /** @deprecated Use differentiation instead */
  diff?: DiffLabel;
  differentiation?: DiffLabel;
  setRefAndPrivacyForPin?: () => boolean;
}
export interface LessonContentForType {
  [Slide.type]: Slide.Content;
  [Wordweb.type]: Wordweb.Content;
  [DragQuestion.type]: DragQuestion.Content;
  [QuizQuestion.type]: QuizQuestion.LessonContent;
  [OpenQuestion.type]: OpenQuestion.Content;
  [Poll.type]: Poll.Content;
  [Video.type]: Video.Content;
  [Link.type]: Link.Content;
  [Map.type]: Map.Content;
}

export type LessonContentType = keyof LessonContentForType;
export type LessonContent = LessonContentForType[LessonContentType];

export namespace LessonPin {
  export type Video = LessonPin<LessonContentForType['video']>;
  export type OpenQuestion = LessonPin<LessonContentForType['openQuestion']>;
  export type Slide = LessonPin<LessonContentForType['slide']>;
  export type QuizQuestion = LessonPin<LessonContentForType['question']>;
  export type DragQuestion = LessonPin<LessonContentForType['dragQuestion']>;
  export type Wordweb = LessonPin<LessonContentForType['wordweb']>;
  export type Poll = LessonPin<LessonContentForType['poll']>;
  export type Link = LessonPin<LessonContentForType['link']>;
  export type Map = LessonPin<LessonContentForType['map']>;

  export const testPointTypes: PinType[] = ['openQuestion', 'dragQuestion', 'question'];

  export function testPointsForPin(pin: LessonPin): number {
    return testPointsForPinItem(pin.item);
  }

  export function hasPossibleTestPoints(pin: Pin) {
    return Pin.isOfType(pin, ...testPointTypes);
  }

  export function isDragQuestion(pin: LessonPin): pin is LessonPin<DragQuestion.Content> {
    return pin.item.type === 'dragQuestion';
  }

  export function isSlide(pin: LessonPin): pin is LessonPin<Slide.Content> {
    return pin.item.type === 'slide';
  }

  export function testPointsForPinItem(item: PinItem<LessonContent>): number {
    // If the pin can't have an invalid answer, return points
    if (!Pin.isItemOfType(item, ...testPointTypes)) {
      return 0;
    }

    // We're left with items that CAN have an invalid answer

    // If it's invalid question, return 0
    if (Pin.isItemOfType(item, 'question', 'dragQuestion') && !itemContainsCorrectAnswer(item)) {
      return 0;
    }

    // defaults to 1 point
    return _.isNil(item.testPoints) ? 1 : item.testPoints;
  }

  export function correctAnswersForPins(pins: LessonPin[]): AssignmentAnswers {
    const items = _.map(pins, (pin) => {
      const answers = correctAnswersForPin(pin);
      if (_.isEmpty(answers)) {
        return undefined;
      }

      return [pin._id, answers];
    });

    return _.fromPairs(_.compact(items));
  }

  /** Returns an array of correct answers for the given pin */
  export function correctAnswersForPin(pin: LessonPin): AnyCorrectAnswer | undefined {
    return correctAnswersForPinItem(pin.item);
  }

  export function correctAnswersForPinItem(item: PinItem<any>): AnyCorrectAnswer | undefined {
    switch (item.type) {
      case 'dragQuestion':
        const dqContent = item;
        const res: DragQuestion.CorrectAnswer = {
          correctDropzone: {},
        };
        (dqContent.components || [])
          .filter((c) => c.settings && c.settings.isDraggable)
          .forEach((c) => {
            const correct = c.settings.correctDropzone;
            res.correctDropzone[c._id] = correct && !_.isEmpty(correct) ? correct : [];
          });
        return res;
      case 'openQuestion':
        const oqContent = item.custom as OpenQuestion.LessonContent;
        return _.compact(_.isArray(oqContent.answer) ? oqContent.answer : [oqContent.answer]);
      case 'question':
        var qContent = item.custom as QuizQuestion.LessonContent;
        var newAnswer = _.compact(
          _.isArray(qContent.correctAnswer) ? qContent.correctAnswer : [qContent.correctAnswer]
        );
        if (QuizQuestion.Answer.areAnswerValues(newAnswer)) return newAnswer;
        return [];
      default:
        return undefined;
    }
  }

  /** true if a correct answer has been configured for the given pin */
  export function containsCorrectAnswer(pin: LessonPin): boolean {
    return !_.isEmpty(correctAnswersForPin(pin));
  }

  export function itemContainsCorrectAnswer(item: PinItem<LessonContent>): boolean {
    return !_.isEmpty(correctAnswersForPinItem(item));
  }

  export function firstPin(pins: LessonPin[]): LessonPin | undefined {
    return _.find(pins, (p) => p.isFirstPin == true) || pins[0];
  }

  export function isExcludedForExclusionType(pin: LessonPin, exclusion: ExclusionType): boolean {
    return pin.item.exclusions?.includes(exclusion) ?? false;
  }

  export function pinIsAssignmentType(pin: LessonPin, type: AssignmentType): boolean {
    const exclusion: ExclusionType = type === 'realtime' ? 'lesson' : 'student';
    return !isExcludedForExclusionType(pin, exclusion);
  }

  export function lessonPinsForAssignmentType(pins: LessonPin[], type: AssignmentType): LessonPin[] {
    return _.filter(pins, (pin) => pinIsAssignmentType(pin, type));
  }

  export function firstPinForAssignmentType(pins: LessonPin[], type: AssignmentType): LessonPin | undefined {
    const pinsForAssignment = lessonPinsForAssignmentType(pins, type);
    return firstPin(pinsForAssignment);
  }

  export function sort(pins: LessonPin[]): LessonPin[] {
    pins.sort((a, b) => {
      if (a.order == b.order) {
        if (a.creationDate < b.creationDate) return -1;
        if (a.creationDate > b.creationDate) return 1;
        return 0;
      }
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    return pins;
  }

  export function allowTestPoints(pin: Pin) {
    if (!pin || !hasPossibleTestPoints(pin)) return false;
    if (pin.item.type == 'question' && !QuizQuestion.Content.hasCorrectAnswerLessonPin(pin.item.custom)) {
      return false;
    }
    return true;
  }

  export function differentiation(pin?: LessonPin): DiffLabel | undefined {
    if (!pin) return;
    return pin.differentiation || pin.diff;
  }

  /** fixes the recursive pin bug which was created a long time ago */
  export function cleanRecursivePin<T extends LessonPin>(pin: T): T {
    const copy = _.cloneDeep(pin);
    // bug where we recursivly copied the pindata in the settings object
    copy.item.components = _.map(
      pin.item.components,
      (c) =>
        _.omit(
          c,
          'settings.item',
          'settings._id',
          'settings.lesson',
          'settings.user',
          'settings.creationDate',
          'settings.isPublic',
          'settings.shouldIndex'
        ) as Component.All
    );
    return copy;
  }

  export function sortByOrder(a: Orderable, b: Orderable): number {
    return a.order - b.order;
  }

  export function copy(sourcePin: LessonPin, idProvider: MongoIDProvider, lessonId: string) {
    const newPin = copyWithoutId(sourcePin, lessonId);
    newPin._id = idProvider.id();
    return newPin;
  }

  export function copyWithoutId(sourcePin: LessonPin, lessonId: string) {
    const newPin = _.cloneDeep(sourcePin);
    newPin.lesson = lessonId;
    newPin.item.components = Component.cloneWithRelations(newPin.item);
    newPin.creationDate = new Date();
    newPin.item.modifiedDate = new Date();
    newPin.videoQuestion = undefined;
    return newPin;
  }

  export function copyPins(
    sourcePins: LessonPin[],
    lessonId: string,
    sourceLesson: Lesson,
    idProvider: MongoIDProvider
  ): LessonPin[] {
    // map with the new pins, se we can keep references
    const newIdMap = {};
    sourcePins.forEach((p) => (newIdMap[p._id] = idProvider.id()));

    const newPins = sourcePins.map((pin) => {
      const newPin = _.cloneDeep(pin);

      newPin._id = newIdMap[pin._id];
      newPin.creationDate = new Date();
      newPin.lesson = lessonId;
      if (newPin.item) {
        newPin.item.modifiedDate = new Date();
        newPin.item.components = Component.cloneWithRelations(newPin.item);
        if (newPin.item.videoItems && newPin.item.videoItems.length) {
          newPin.item.videoItems.forEach((i) => {
            i.pinId = newIdMap[i.pinId];
          });
        }
      }
      newPin.shouldIndex = new Date();
      if (newPin.videoQuestion) {
        newPin.videoQuestion = newIdMap[newPin.videoQuestion];
      }
      const pinTrail = pin.trail || [];
      if (sourceLesson.channel) {
        pinTrail.push({
          channel: sourceLesson.channel,
          d: new Date(),
          lesson: sourceLesson._id,
          pin: pin._id,
        });
      }
      newPin.trail = pinTrail;
      return newPin;
    });
    return newPins;
  }

  export const colorsInThisLesson = (pins: LessonPin[]): string[] => {
    const colors: string[] = pins
      .map((pin) => {
        const componentColors = compact(pin.item.components?.map(colorInComponent).flat()) ?? [];
        const pinColors = compact(colorsInPin(pin));
        return [...componentColors, ...pinColors];
      })
      .flat();
    const uniqColors = Colors.uniqColors(colors);
    return uniqColors.filter((c) => !Colors.skipColorForInMyLesson(c)).sort();
  };
  const colorsInPin = (pin: LessonPin): (string | undefined)[] => {
    const colorbg = pin.item.custom?.['colorbg'] as string | undefined;
    const colorfg = pin.item.custom?.['colorfg'] as string | undefined;

    if (isSlide(pin)) {
      const text1 = Colors.colorsInString(pin.item.custom.text1);
      const text2 = Colors.colorsInString(pin.item.custom.text2);
      return [colorbg, colorfg, ...text1, ...text2];
    }

    return [colorbg, colorfg];
  };

  const colorInComponent = (comp: Component.All): (string | undefined)[] => {
    if (Component.isText(comp)) {
      const { color, backgroundColor, borderColor } = comp.settings || {};
      const text = Colors.colorsInString(comp.settings.text);
      return [color, backgroundColor, borderColor, ...text];
    }
    if (Component.isHotspot(comp)) {
      const color = comp.settings?.color;
      return [color];
    }
    if (Component.isAudio(comp)) {
      const color = comp.settings?.color;
      return [color];
    }
    if (Component.isTimer(comp)) {
      const color = comp.settings?.color;
      return [color];
    }
    if (Component.isSymbol(comp)) {
      const { color, strokeColor } = comp.settings || {};
      return [color, strokeColor];
    }
    if (Component.isTable(comp)) {
      const { contentLayout, headerLayout, tableData } = comp.settings || {};
      const content = TableComponent.allContent(tableData);
      const text = Colors.colorsInString(content);
      return [...text, contentLayout.fillColor, headerLayout.fillColor];
    }

    return [];
  };
}

export interface SavedPin extends LessonPin {
  user: string;
  hash: string;
  originalLessonId: string;
}
