import { ErrorTrackingPlugin, trackError } from '@snowplow/browser-plugin-error-tracking';
import {
  AnonymousTrackingOptions,
  BrowserTracker as SnowplowBrowserTracker,
  newTracker,
  TrackerConfiguration,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker';
import schemasProd from '../../avo/schemas-prod.generated.json';
import schemasTest from '../../avo/schemas-test.generated.json';
import { CookieSettings } from '../../cookies/analytic-cookies';
import { logger } from '../../logging/analytics-logger';
import { TrackerDestination } from './TrackerDestination';

type BrowserTracker = Omit<SnowplowBrowserTracker, 'getUserId'> & {
  // the types from snowplow are incorrect. getUserId doesn't return a void but an ID instead
  getUserId: () => string | undefined | null;
};

export interface SnowplowDestinationOptions {
  env: string;
  collectorUrl: string;
  applicationName: string;
  applicationVersion: string;
  cookieSettings?: CookieSettings;
  userId?: string;
}

/** snowplow interface is not complete, so we use this one */
export interface SnowplowTrackerInitContexts {
  webPage: boolean;
  performanceTiming?: boolean;
  gaCookies?: boolean;
  geolocation?: boolean;
  clientHints?: boolean;
}

const snowplowAnonymousTrackingOptions: AnonymousTrackingOptions = {
  withSessionTracking: true,
  withServerAnonymisation: true,
};

export class SnowplowDestination implements TrackerDestination {
  private snowplow: BrowserTracker;
  private isPaused = false;

  constructor(private readonly options: SnowplowDestinationOptions) {
    const contexts: SnowplowTrackerInitContexts = {
      webPage: true,
      gaCookies: true,
      clientHints: true,
    };

    const { applicationName, applicationVersion, collectorUrl, cookieSettings, userId } = this.options;

    const snowplowOptions: TrackerConfiguration = {
      appId: `${applicationName}-${applicationVersion}`,
      discoverRootDomain: true,
      cookieLifetime: 86400 * 365,
      cookieSameSite: 'Lax',
      postPath: '/lessonup/t',
      eventMethod: 'post',
      keepalive: true,
      contexts,
      plugins: [ErrorTrackingPlugin()],
      anonymousTracking: cookieSettings?.analytic !== true && !userId ? snowplowAnonymousTrackingOptions : false,
    };

    this.snowplow = newTracker('lessonup', collectorUrl, snowplowOptions) as BrowserTracker;
    // Workaround for https://github.com/snowplow/snowplow-javascript-tracker/issues/1221
    this.snowplow.setCollectorUrl(collectorUrl);

    this.setUserId(userId);
  }

  init(cookieSettings: CookieSettings | undefined) {}

  pause(paused: boolean) {
    this.isPaused = paused;
  }

  trackPageView() {
    try {
      if (this.isPaused) return;

      this.snowplow.trackPageView();
    } catch (error) {
      // we seen some rare cases where snowplow might not be init correctly before track events are called.
      // we think it has something to do with our meteor app. just to be safe we wrap this in a try catch
      logger().warn(error as Error, { label: 'SnowplowDestination:trackPageView' });
    }
  }

  trackError(message: string, error: Error | undefined) {
    try {
      if (this.isPaused) return;

      trackError({ message: message, error: error }, [this.snowplow.id]);
    } catch (error) {
      // we seen some rare cases where snowplow might not be init correctly before track events are called.
      // we think it has something to do with our meteor app. just to be safe we wrap this in a try catch
      logger().warn(error as Error, { label: 'SnowplowDestination:trackError' });
    }
  }

  logEvent(event: string, data: object) {
    if (this.isPaused) return;

    const schema = schemaForEvent(event, this.options.env);
    if (!schema) {
      logger().error(`snowplowDestination: missing schema for event ${event}`);
      return;
    }

    try {
      trackSelfDescribingEvent(
        {
          event: {
            schema,
            data: data as any,
          },
        },
        [this.snowplow.id]
      );
    } catch (error) {
      // just for safety, we don't want the events to throw an error when they fail
      logger().warn(error as Error, { label: 'SnowplowDestination:trackSelfDescribingEvent' });
    }
  }

  updateConsent(cookieSettings: CookieSettings) {
    this.setAnonymousTracking(!this.snowplow.getUserId() && cookieSettings.analytic !== true);
  }

  private setAnonymousTracking(enabled: boolean) {
    try {
      enabled
        ? this.snowplow.enableAnonymousTracking({
            options: snowplowAnonymousTrackingOptions,
          })
        : this.snowplow.disableAnonymousTracking();
    } catch (error) {
      logger().error(error as Error, { label: 'SnowplowDestination:setAnonymousTracking' });
    }
  }

  setUserId(userId: string | undefined | null) {
    if (userId) {
      this.setAnonymousTracking(false);
    }
    this.snowplow.setUserId(userId);
  }

  logout() {
    try {
      this.snowplow.clearUserData();
    } catch (error) {
      // we don't want to throw when this fails and it might because it could purge the queue
      console.error('SnowplowDestination:logout error', error);
    }
  }
}

export function schemaForEvent(eventName: string, env: string): string | undefined {
  const schemas: Record<string, string | undefined> = env === 'live' || env === 'staging' ? schemasProd : schemasTest;

  return schemas[eventName];
}
