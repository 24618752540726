import { SESSION_COOKIE_KEY_REDIRECT } from '@lessonup/utils';

interface CookieParams {
  name: string;
  value: string;
  maxAge?: number;
  path?: string;
  expires?: Date;
}

export type GetCookieValueByName = (name: string) => string | undefined;
export type SetCookie = (params: CookieParams) => boolean;

export const getCookieValueByName: GetCookieValueByName = (name) => {
  if (typeof window !== 'undefined' && window.document) {
    const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return b ? b[b.length - 1] : undefined;
  }
};

export const setCookie: SetCookie = (params) => {
  if (!window || typeof window === 'undefined') {
    return false;
  }
  const { name, value, expires, maxAge, path } = params;

  document.cookie = `${name}=${value}${maxAge ? `;max-age=${maxAge}` : ''}${
    expires ? `;expires=${expires.toUTCString()}` : ''
  };path=${path || '/'}`;
  return true;
};

export function clearCookie(name: string, path?: string): void {
  if (!window || typeof window === 'undefined') {
    return;
  }
  // Cookies can't really be deleted. So instead we set its expiration date to the past and let the browser take care of the cleanup. More info: https://stackoverflow.com/questions/2144386/how-to-delete-a-cookie
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=${path || '/'}`;
}

export function alreadyRedirectedToDesktop(): boolean {
  return !!getCookieValueByName(SESSION_COOKIE_KEY_REDIRECT);
}
