import styled from '@emotion/styled';
import { ToastContainer as _ToastContainer } from 'react-toastify';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { elevationShadow } from '../../foundations/elevation/elevation';

export const ToastContainer = styled(_ToastContainer)`
  .Toastify__toast {
    box-shadow: ${elevationShadow.e200};
    padding: 0;
    border-radius: ${borderRadius.rounded8};
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
    background: ${color.accent.secondary.background};
  }
`;
