import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLogoOffice365 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#C75B12"
      d="M20.342 20.333h-.002v.02L14.386 22v-.004l-10.72-3.959 10.72 1.476V5.142L7.313 6.833v9.775l-3.652 1.43-.002-12.015.027-.005L14.387 2l5.953 1.718v16.614z"
    />
  </svg>
);
export default SvgIconLogoOffice365;
