import { onError } from '@apollo/client/link/error';
import { pick } from 'lodash';
import { logger } from '../../logger/logger';

/**
 * A link to capture all errors from all request and
 * It will remove nulls from arrays
 */
export const errorLogLink = onError(({ graphQLErrors, networkError, operation }) => {
  const safeVariablesKeys = Object.keys(operation.variables).filter((key) => isAnId(key));
  const safeVariables = pick(operation.variables, safeVariablesKeys);

  const operationDetails = {
    operationName: operation.operationName,
    variables: safeVariables,
  };

  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { path, extensions } = error;
      logger.error(error, {
        path: path?.join('/'),
        ...operationDetails,
        ...extensions, // details of the error will be in here, including the error code
      });
    });
  if (networkError) {
    logger.error(networkError, {
      ...operationDetails,
    });
  }
});

/**
 * It either is "id" or ends with "Id"
 */
const isAnId = (toTest: string) => /(id|ids|.*Id)$/.test(toTest);
