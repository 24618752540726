import { Deg, newVector, NumericRange, Rad, Size, Vector } from '@lessonup/pins-shared';
import { sortBy } from 'lodash';

export function degreesToRadians(degrees: Deg): Rad {
  return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: Rad): Deg {
  return radians * (180 / Math.PI);
}

export function keepPositionBetweenBounds(position: Vector, size: Size): Vector {
  const newPosition = { ...position };
  if (position.x < 0) newPosition.x = 0;
  if (position.x > size.width) newPosition.x = size.width;
  if (position.y < 0) newPosition.y = 0;
  if (position.y > size.height) newPosition.y = size.height;

  return newPosition;
}

/** Determine if triangle ABC is counter clock wise */
export function isCounterClockWise(A: Vector, B: Vector, C: Vector): boolean {
  return (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);
}

/**
 * This function calculates where a line going from the origin in a given direction intersects with another line.
 * The second line is perpendicular to the first one and goes through the given point.
 */
export function perpendicularIntersectionBetweenLineAndPoint(
  directionOfLine: Vector,
  pointOnPerpendicularLine: Vector
): Vector {
  // If the given line is on the x or y axis, the intersection will be on this axis as well.
  if (directionOfLine.x === 0) {
    return newVector(0, pointOnPerpendicularLine.y);
  }
  if (directionOfLine.y === 0) {
    return newVector(pointOnPerpendicularLine.x, 0);
  }
  // f(x) = slope1 * x
  const slopeFx = directionOfLine.y / directionOfLine.x;
  // g(x) = slope2 * x + yIntercept
  const slopeGx = -1 / slopeFx;
  const yInterceptGx = pointOnPerpendicularLine.y - pointOnPerpendicularLine.x * slopeGx;
  // Find x for f(x) = g(x)
  const x = yInterceptGx / (slopeFx - slopeGx);

  return newVector(x, x * slopeFx);
}

function sortAndProcessVectors<OutputReturn, SortReturn>(
  vectors: Vector[],
  sortStrategy: (vector: Vector) => SortReturn,
  outputStrategy: (firstVector: Vector, lastVector: Vector) => OutputReturn
): OutputReturn {
  const sortedVectors = sortBy(vectors, sortStrategy);
  return outputStrategy(sortedVectors[0], sortedVectors[sortedVectors.length - 1]);
}

export function horizontalExtremesFromVectors(vectors: Vector[]) {
  return sortAndProcessVectors(
    vectors,
    (vector) => vector.x,
    (first, last) => ({ min: first.x, max: last.x })
  );
}

export function verticalExtremesFromVectors(vectors: Vector[]) {
  return sortAndProcessVectors(
    vectors,
    (vector) => vector.y,
    (first, last) => ({ min: first.y, max: last.y })
  );
}

export function outerHorizontalVectors(vectors: Vector[]) {
  return sortAndProcessVectors(
    vectors,
    (vector) => vector.x,
    (first, last) => [first, last]
  );
}

export function outerVerticalVectors(vectors: Vector[]) {
  return sortAndProcessVectors(
    vectors,
    (vector) => vector.y,
    (first, last) => [first, last]
  );
}

export const clampValue = (value: number, clampValues: NumericRange): number => {
  return Math.max(clampValues.min, Math.min(clampValues.max, value));
};

export function clampRange(range: NumericRange, clampValues: NumericRange): NumericRange {
  return {
    min: Math.max(range.min, clampValues.min),
    max: Math.min(range.max, clampValues.max),
  };
}

export function isValueInRange(value: number, range: NumericRange): boolean {
  return value >= range.min && value <= range.max;
}

export function isNotZero(n: number): boolean {
  return n !== 0;
}
