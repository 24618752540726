import { styled } from '@lessonup/ui-components';
import React from 'react';
import { playerScreenSize } from '../../../dataComponents/PlayerScreens/playerScreens.utils';

interface PlayerScreenContentScalerProps {
  scale: number;
  children: React.ReactNode;
}

export const PlayerScreenContentScaler: React.FC<PlayerScreenContentScalerProps> = ({ scale, children }) => {
  const { width, height } = playerScreenSize;

  return (
    <StyledScaler
      style={{
        width: width,
        height: height,
        transform: `scale(${scale})`,
      }}
    >
      {children}
    </StyledScaler>
  );
};

const StyledScaler = styled.div`
  transform-origin: top left;
`;
