import { NiceModalHandler } from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useEventListener, useWindowSize } from 'usehooks-ts';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { elevationShadow } from '../../foundations/elevation/elevation';
import { spacing } from '../../foundations/spacing/spacing';
import {
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopOverParentSelector,
  ModalPopoverPosition,
  ModalPopoverSize,
} from './types/modalPopover.types';
import {
  calculateMaxSize,
  calculateModalOverflow,
  calculatePopoverDirection,
  calculatePosition,
  calculateShiftedPosition,
  calculateSize,
  getParentAnchors,
  getSpaceBetweenButtonAndContainer,
} from './utils/modalPopover.utils';

export interface ModalPopoverProps {
  modal: NiceModalHandler;
  contentLabel: string;
  parentSelector: ModalPopOverParentSelector;
  direction?: ModalPopoverDirection;
  modalAnchor?: ModalPopoverAnchor;
  offset?: number;
  spaceBetweenParent?: spacing;
  spaceBetweenContainer?: spacing;
  containerData?: { position: ModalPopoverPosition; size: ModalPopoverSize } | undefined;
  onBeforeClose?: () => void;
  restrainSize?: boolean;
  shouldFocusAfterRender?: boolean;
  className?: string;
}

export function ModalPopover({
  modal,
  contentLabel,
  parentSelector,
  direction = 'right',
  modalAnchor = 'center',
  offset = 0,
  spaceBetweenParent = spacing.size8,
  spaceBetweenContainer = spacing.size8,
  containerData = undefined,
  onBeforeClose,
  restrainSize = true,
  shouldFocusAfterRender = true,
  className,
  children,
}: PropsWithChildren<ModalPopoverProps>) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<ModalPopoverPosition>({ top: 0, left: 0 });
  const windowSize = useWindowSize();
  const verticalScrollPosition = window.scrollY;
  const container = containerData
    ? containerData
    : { position: { top: verticalScrollPosition, left: 0 }, size: windowSize };
  const [maxSize, setMaxSize] = useState<ModalPopoverSize | undefined>(undefined);
  const [initialModalSize, setInitialModalSize] = useState<ModalPopoverSize | undefined>(undefined);

  const transformModal = () => {
    const parent = parentSelector();
    const parentBoundingRect = parent?.getBoundingClientRect();
    if (!initialModalSize || !contentRef.current || parentBoundingRect === undefined) return;

    const buttonAnchors = getParentAnchors(modalAnchor, parentBoundingRect, container.position);
    const spaceBetweenButtonAndContainer = getSpaceBetweenButtonAndContainer(
      buttonAnchors,
      container,
      spaceBetweenContainer,
      spaceBetweenParent
    );
    direction = calculatePopoverDirection(direction, spaceBetweenButtonAndContainer, initialModalSize);

    const parentAnchorPosition = buttonAnchors[direction];
    const initialPosition = calculatePosition(
      parentAnchorPosition,
      modalAnchor,
      direction,
      initialModalSize,
      offset,
      spaceBetweenParent
    );
    const overflow = calculateModalOverflow(initialModalSize, initialPosition, container, spaceBetweenContainer);
    const correctedSize = calculateSize(direction, initialModalSize, container.size, spaceBetweenContainer, overflow);
    const correctedPosition = calculateShiftedPosition(
      calculatePosition(parentAnchorPosition, modalAnchor, direction, correctedSize, offset, spaceBetweenParent),
      direction,
      container.position,
      spaceBetweenContainer,
      overflow
    );
    setPosition(correctedPosition);
    const newMaxSize = restrainSize
      ? calculateMaxSize(direction, correctedSize, container.size, spaceBetweenContainer)
      : undefined;
    setMaxSize(newMaxSize);
  };

  const handleClose = () => {
    onBeforeClose?.();
    modal.hide();
  };

  useEventListener('mousedown', (event) => {
    // this includes the modal popover and any other modals on the page
    const modals = document.getElementsByClassName('ReactModalPortal');
    for (const modal of modals) {
      if (modal.contains(event.target as Node)) {
        return;
      }
    }
    handleClose();
  });

  useEffect(() => {
    const contentBoundingRect = contentRef.current?.getBoundingClientRect();
    if (contentBoundingRect)
      setInitialModalSize({ width: contentBoundingRect.width, height: contentBoundingRect.height });
  }, [children]);

  useLayoutEffect(() => {
    transformModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, initialModalSize]);

  const sizeStyling = {
    width: 'max-content',
    height: 'fit-content',
    ...(maxSize?.width && { maxWidth: maxSize.width }),
    ...(maxSize?.height && { maxHeight: maxSize.height }),
  };

  return (
    <StyledModalPopover
      isOpen={modal.visible}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={shouldFocusAfterRender}
      contentLabel={contentLabel}
      onAfterClose={() => modal.remove()}
      onRequestClose={handleClose}
      contentRef={(node: HTMLDivElement) => {
        contentRef.current = node;
        const contentBoundingRect = contentRef.current?.getBoundingClientRect();
        if (!contentBoundingRect) return;
        setInitialModalSize({ width: contentBoundingRect.width, height: contentBoundingRect.height });
      }}
      style={{
        overlay: {
          position: 'absolute',
          zIndex: 1000,
          top: position.top,
          left: position.left,
          backgroundColor: 'transparent',
          ...sizeStyling,
        },
        content: {
          ...sizeStyling,
        },
      }}
      className={className}
    >
      {children}
    </StyledModalPopover>
  );
}

const StyledModalPopover = styled(ReactModal)`
  background-color: ${color.neutralNew.level1.fill};
  border: 1px solid ${color.neutralNew.level1.stroke};
  border-radius: ${borderRadius.rounded8};
  box-shadow: ${elevationShadow.e200};
  padding: 0;
  inset: 0;
  overflow: auto;
`;
