import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.375 21.375-3.7-3.7m-6.238 2.575a8.812 8.812 0 1 0 0-17.625 8.812 8.812 0 1 0 0 17.625"
    />
  </svg>
);
export default SvgIconSearch;
