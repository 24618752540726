import { createContext } from 'react';
import { ErrorDisplayMessage, SetErrorProps } from './errorBaseTypes';

export interface ErrorContextProps {
  errorDisplayMessage: ErrorDisplayMessage | null;
  parseErrorToErrorDisplayMessage: (error: Error | null, customMsg?: ErrorDisplayMessage | null) => ErrorDisplayMessage;
  setError: (props: SetErrorProps) => void;
  clearError: () => void;
}

export const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);
