import { LicenseStatus } from '../../types/graphql';

export function shouldShowTeacherCreditsIndicator(license: LicenseStatus | undefined) {
  return license === 'FREE' || license === 'EXPIRED' || license === 'START';
}

/**
 * Returns true if teaching is allowed for the current license or the check is skipped.
 *
 * @param license the current license
 * @param skipCheck boolean to skip the license check, used for channel lessons for example
 * @returns true if teaching is allowed for the current license
 */
export function isTeachingAllowedForCurrentLicense(license: LicenseStatus | undefined, skipCheck?: boolean) {
  return skipCheck || !shouldShowTeacherCreditsIndicator(license);
}

export type ShowAsType = 'button' | 'tag';

export const typesForMoreInfo = ['button'];
