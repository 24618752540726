import { Line, LineSettings, newVector, ShadowSettingKey } from '@lessonup/pins-shared';
import { styled } from '@lessonup/ui-components';
import React from 'react';
import { addVectors, subtractVectors } from '../../utils';
import { computeShadowFilter } from '../../utils/effects/shadow/shadow';
import { boxFromLine } from '../../utils/geometry/box';

interface LinePinComponentProps {
  componentId: string;
  layout: Line;
  settings: LineSettings;
}

const offset = 10;

export const LinePinComponent = ({ componentId, layout, settings }: LinePinComponentProps) => {
  const box = boxFromLine(layout);
  const relativeStart = subtractVectors(layout.start, box.position);
  const relativeEnd = subtractVectors(layout.end, box.position);
  const offSetStart = addVectors(relativeStart, newVector(offset, offset));
  const offSetEnd = addVectors(relativeEnd, newVector(offset, offset));

  const lineColor = settings.fillColor ?? 'black';
  const lineWidth = settings.width ?? 4;
  const tailMarker = settings.tailMarker ?? 'NONE';
  const headMarker = settings.headMarker ?? 'NONE';

  type MarkerType = { [key: string]: string | undefined };

  const arrowDefs: {
    head: MarkerType;
    tail: MarkerType;
  } = {
    head: {
      NONE: undefined,
      ARROW: `url(#${componentId}_marker_start)`,
    },
    tail: {
      NONE: undefined,
      ARROW: `url(#${componentId}_marker_end)`,
    },
  };

  return (
    <StyledSvg
      shadow={settings.shadow}
      width={box.size.width + offset * 2 + lineWidth}
      height={box.size.height + offset * 2 + lineWidth}
    >
      <defs>
        <marker
          id={`${componentId}_marker_end`}
          markerWidth="5"
          markerHeight="5"
          refX="2.5"
          refY="2.5"
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 5 2.5 L 0 5 z" fill={lineColor} />
        </marker>
        <marker id={`${componentId}_marker_start`} markerWidth="5" markerHeight="5" refX="2.5" refY="2.5" orient="auto">
          <path d="M 0 0 L 5 2.5 L 0 5 z" fill={lineColor} />
        </marker>
      </defs>
      <SvgDisplayLine
        x1={offSetStart.x}
        y1={offSetStart.y}
        x2={offSetEnd.x}
        y2={offSetEnd.y}
        stroke={lineColor}
        strokeWidth={lineWidth}
        strokeLinecap="butt"
        markerStart={arrowDefs.tail[tailMarker]}
        markerEnd={arrowDefs.head[headMarker]}
      />
      <SvgHiddenClickLine x1={offSetStart.x} y1={offSetStart.y} x2={offSetEnd.x} y2={offSetEnd.y} />
    </StyledSvg>
  );
};

const SvgDisplayLine = styled.line`
  pointer-events: all;
  stroke-linecap: round;
`;

const SvgHiddenClickLine = styled.line`
  pointer-events: all;
  stroke-width: 40;
  stroke: transparent;
`;

const StyledSvg = styled.svg<{ shadow?: ShadowSettingKey }>`
  position: absolute;
  top: -${offset}px;
  left: -${offset}px;
  bottom: -${offset}px;
  right: -${offset}px;
  overflow: visible;
  ${(props) => props.shadow && `filter: drop-shadow(${computeShadowFilter(props.shadow)});`}
`;
