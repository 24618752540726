import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconFullscreenOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.94 16.903c-.17-1.595-.315-3.233-.315-4.903s.144-3.308.315-4.903m4.129 13.946c1.604.178 3.252.332 4.931.332s3.327-.154 4.93-.332m4.13-4.14c.17-1.595.315-3.233.315-4.903s-.144-3.308-.315-4.903m-4.13-4.14c-1.603-.178-3.25-.332-4.93-.332s-3.327.154-4.931.332"
    />
  </svg>
);
export default SvgIconFullscreenOpen;
