import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.625 7.118h18.75m-13.122 0v-.749a3.744 3.744 0 0 1 7.488 0v.749m-5.99 4.01v6.207m4.492-6.207v6.207m4.493-10.217H5.258c-.218 3.934-.215 7.837.372 11.746a2.95 2.95 0 0 0 2.915 2.511h6.903a2.95 2.95 0 0 0 2.916-2.51c.586-3.91.59-7.813.372-11.747"
    />
  </svg>
);
export default SvgIconRemove;
