import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconApps = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.768 9.709a1.81 1.81 0 0 1-1.588-1.593 18 18 0 0 1-.12-1.885c0-.642.055-1.272.12-1.886.09-.831.758-1.5 1.588-1.592.617-.069 1.25-.128 1.897-.128.646 0 1.28.06 1.896.128.831.092 1.5.76 1.589 1.592.065.614.12 1.244.12 1.886s-.055 1.272-.12 1.885A1.81 1.81 0 0 1 19.56 9.71a17 17 0 0 1-1.896.128c-.646 0-1.28-.06-1.897-.128M4.439 9.709A1.81 1.81 0 0 1 2.85 8.116a18 18 0 0 1-.12-1.885c0-.642.055-1.272.12-1.886.09-.831.758-1.5 1.589-1.592a17 17 0 0 1 1.896-.128c.646 0 1.28.06 1.897.128.83.092 1.499.76 1.588 1.592.065.614.12 1.244.12 1.886s-.055 1.272-.12 1.885A1.81 1.81 0 0 1 8.232 9.71c-.617.068-1.251.128-1.897.128s-1.28-.06-1.896-.128M4.439 21.247a1.81 1.81 0 0 1-1.589-1.592 18 18 0 0 1-.12-1.886c0-.642.055-1.272.12-1.885.09-.832.758-1.5 1.589-1.593.617-.068 1.25-.127 1.896-.127s1.28.059 1.897.127c.83.093 1.499.761 1.588 1.593.065.613.12 1.243.12 1.885 0 .643-.055 1.273-.12 1.886a1.81 1.81 0 0 1-1.588 1.592c-.617.069-1.251.128-1.897.128s-1.28-.06-1.896-.128M15.768 21.247a1.81 1.81 0 0 1-1.588-1.592 18 18 0 0 1-.12-1.886c0-.642.055-1.272.12-1.885.09-.832.758-1.5 1.588-1.593.617-.068 1.25-.127 1.897-.127.646 0 1.28.059 1.896.127.831.093 1.5.761 1.589 1.593.065.613.12 1.243.12 1.885 0 .643-.055 1.273-.12 1.886a1.81 1.81 0 0 1-1.589 1.592c-.617.069-1.25.128-1.896.128s-1.28-.06-1.897-.128"
    />
  </svg>
);
export default SvgIconApps;
