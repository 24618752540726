import { tracker } from '@lessonup/analytics';
import {
  useDocumentMutation,
  useFirestoreContext,
  useLoggerPlayerScope,
  useRouter,
} from '@lessonup/client-integration';
import {
  PlayerStoreAssignmentContextProvider,
  PlayerStoreContextProvider,
  PlayerStoreState,
  usePlayerStoreAssignmentContext,
  YjsPlayer,
} from '@lessonup/players-modern';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  lessonEditorV2Route,
  myLessonsRoute,
  playerv2PresenterRoute,
  PresenterRealtimePlayerRoute,
  RealtimePlayerRoute,
  realtimePlayerV2Route,
} from '../../../utils/bridge/teacherRoutes';
import { PlayerYjsContextProvider, usePlayerYjs } from '../../Editor/utils/yjs/EditorYjs/YjsContextPlayer';
import { usePlayerHocusPocusProvider } from '../../Editor/utils/yjs/useHocusPocusProvider';
import { useImmerYjs } from '../../Editor/utils/yjs/useImmerYjs';
import { showBrokenPlayerModal } from '../components/BrokenPlayerModal';
import { usePlayerTranslation } from '../hooks/usePlayerTranslation';
import { CreateRealtimeAssignmentDocument } from '../Player.graphql.generated';
import { PresenterPlayer } from '../PresenterPlayer/PresenterPlayer';
import { openPresenterModeInNewWindow } from '../PresenterPlayer/presenterPlayer.utils';
import { RealtimePlayer } from './RealtimePlayer';
import { RealtimePlayerFeatureProps } from './realtimePlayerFeature.types';

interface InternalRealtimePlayerFeatureProps extends RealtimePlayerFeatureProps {
  type: 'default' | 'presenter';
}

export const MiddleRealtimePlayerFeature: React.FC<InternalRealtimePlayerFeatureProps> = ({
  type,
  withPresenterMode,
  searchRoute,
}) => {
  const { assignmentId, lessonId, source, pinId } = usePlayerStoreAssignmentContext();
  const { yDoc, binders } = usePlayerYjs();
  const router = useRouter<RealtimePlayerRoute>();
  const { firestoreDatabase, firestoreCrudService } = useFirestoreContext();
  const { t } = usePlayerTranslation();

  const handleOnClose = () => {
    if (source === 'editor') {
      router.go(lessonEditorV2Route.href({ lessonId, pinId }));
    }
    if (source === 'explorer') {
      router.go(myLessonsRoute.href({}));
    }
    if (source === 'search') {
      const lessonUrl = searchRoute(lessonId, pinId);
      router.go(lessonUrl);
    }
  };

  useLoggerPlayerScope({ lessonId, assignmentId });

  const onError = () => {
    showBrokenPlayerModal({
      id: 'brokenPlayerModal',
      onClose: handleOnClose,
      t,
    });
  };
  const [yjsPlayerData, update] = useImmerYjs(
    binders.data,
    (state) => {
      return {
        currentPinId: state.currentPinId,
        currentView: state.currentView,
        lockPlayerNavigation: state.lockPlayerNavigation,
        navState: state.navState,
        pinState: state.pinState,
        focusMode: state.focusMode,
      };
    },
    isEqual
  );

  const onUpdate = useCallback(
    (storeState: PlayerStoreState) => yDoc.transact(() => update(() => playerStoreStateToYjsPlayer(storeState))),
    [update, yDoc]
  );

  return (
    <PlayerStoreContextProvider
      assignmentId={assignmentId}
      firestoreDatabase={firestoreDatabase}
      clientCrudService={firestoreCrudService}
      lessonId={lessonId}
      pinId={pinId}
      onError={onError}
      onUpdate={onUpdate}
      yjsPlayerData={yjsPlayerData}
    >
      {type === 'presenter' ? (
        <PresenterPlayer onClose={handleOnClose} />
      ) : (
        <RealtimePlayer onClose={handleOnClose} withPresenterMode={withPresenterMode} />
      )}
    </PlayerStoreContextProvider>
  );
};

export const UpperRealtimePlayerFeature: React.FC<InternalRealtimePlayerFeatureProps> = ({
  type,
  withPresenterMode,
  searchRoute,
}) => {
  const { assignmentId } = usePlayerStoreAssignmentContext();
  const onAuthError = useCallback(() => () => ({}), []);
  const { provider } = usePlayerHocusPocusProvider(assignmentId, onAuthError);
  // Only use the provider if there is already an assignmentId
  const shouldUseProvider = assignmentId.length > 0 && withPresenterMode;
  return (
    <PlayerYjsContextProvider provider={shouldUseProvider ? provider : undefined}>
      <MiddleRealtimePlayerFeature type={type} withPresenterMode={withPresenterMode} searchRoute={searchRoute} />
    </PlayerYjsContextProvider>
  );
};

export const RealtimePlayerFeature: React.FC<RealtimePlayerFeatureProps> = ({ searchRoute, withPresenterMode }) => {
  const router = useRouter<RealtimePlayerRoute>();
  const { t } = usePlayerTranslation();
  const { source, pinId, lessonId, query } = router.route.params;
  const openPresenterMode = withPresenterMode && query.withPresenter === 'true';

  const handleOnClose = useCallback(() => {
    if (source === 'editor') {
      router.go(lessonEditorV2Route.href({ lessonId, pinId }));
    }
    if (source === 'explorer') {
      router.go(myLessonsRoute.href({}));
    }
    if (source === 'search') {
      const lessonUrl = searchRoute(lessonId, pinId);
      router.go(lessonUrl);
    }
  }, [lessonId, pinId, router, searchRoute, source]);
  const onError = useCallback(() => {
    showBrokenPlayerModal({
      id: 'brokenPlayerModal',
      onClose: handleOnClose,
      t,
    });
  }, [handleOnClose, t]);
  const [createRealtimeAssigment, { data }] = useDocumentMutation(CreateRealtimeAssignmentDocument, {
    onError: onError,
    onCompleted: (data) => {
      const assignmentId = data?.teachRealtimeAssignment.assignment.id ?? '';
      if (assignmentId && openPresenterMode) {
        openPresenterModeInNewWindow(playerv2PresenterRoute.href({ assignmentId, lessonId, pinId }));
        router.replace(
          realtimePlayerV2Route.href({
            source,
            lessonId,
            pinId,
            withPresenterMode: false,
          })
        );
        // after opening window, the url should remove the query to not cause issues on refresh

        tracker.events.lessonPresenterMode({ lessonId, assignmentId, context: 'editor' });
      }
    },
  });
  const mutationCalledRef = useRef(false);

  useEffect(() => {
    if (mutationCalledRef.current) return;
    mutationCalledRef.current = true;
    createRealtimeAssigment({
      variables: { lessonId, autoShowRank: false, isPrepScreenEnabled: false },
    });
  }, [createRealtimeAssigment, lessonId]);

  return (
    <PlayerStoreAssignmentContextProvider
      assignmentId={data?.teachRealtimeAssignment.assignment.id ?? ''}
      lessonId={lessonId}
      source={source}
      pinId={pinId}
    >
      <UpperRealtimePlayerFeature type="default" withPresenterMode={withPresenterMode} searchRoute={searchRoute} />
    </PlayerStoreAssignmentContextProvider>
  );
};

export const PresenterPlayerFeature: React.FC<Pick<RealtimePlayerFeatureProps, 'searchRoute'>> = ({ searchRoute }) => {
  const router = useRouter<PresenterRealtimePlayerRoute>();
  const { assignmentId, lessonId, pinId } = router.route.params;

  return (
    <PlayerStoreAssignmentContextProvider assignmentId={assignmentId} pinId={pinId} lessonId={lessonId}>
      <UpperRealtimePlayerFeature type="presenter" withPresenterMode={true} searchRoute={searchRoute} />
    </PlayerStoreAssignmentContextProvider>
  );
};

export function playerStoreStateToYjsPlayer(storeState: PlayerStoreState): YjsPlayer {
  return JSON.parse(
    JSON.stringify({
      currentPinId: storeState.currentPinId,
      currentView: storeState.currentView,
      lockPlayerNavigation: storeState.lockPlayerNavigation,
      navState: storeState.navState,
      pinState: storeState.pinState,
      focusMode: storeState.focusMode,
    })
  );
}
