import { rem, spacing, styled } from '@lessonup/ui-components';
import React from 'react';

export const PlayerBarTopWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledPlayerBarTopWrapper>{children}</StyledPlayerBarTopWrapper>;
};

export const PlayerBarTopStartSection: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledPlayerBarTopStartSection>{children}</StyledPlayerBarTopStartSection>;
};

export const PlayerBarTopMiddleSection: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledPlayerBarTopMiddleSection>{children}</StyledPlayerBarTopMiddleSection>;
};

export const PlayerBarTopEndSection: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledPlayerBarTopEndSection>{children}</StyledPlayerBarTopEndSection>;
};

const StyledPlayerBarTopWrapper = styled.div`
  display: flex;
  gap: ${spacing.size8};
  align-items: flex-start;
`;

const StyledPlayerBarTopStartSection = styled.div`
  padding: ${spacing.size8};
`;

const StyledPlayerBarTopMiddleSection = styled.div`
  flex-grow: 1;
  padding: ${rem('20px')} 0;
`;

const StyledPlayerBarTopEndSection = styled.div`
  padding: ${spacing.size8};
  display: flex;
  gap: ${spacing.size4};
`;
