import { color, css, IconChevronDown, IconChevronUp, ListHeader, rem, spacing, styled } from '@lessonup/ui-components';
import { uniqueId } from 'lodash';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';

const iconSize = rem('16px');

export interface ContextSidebarGroupProps {
  title?: string;
  children: React.ReactNode;
}

interface ContextSidebarCollapsibleSharedProps {
  title: string;
  isCollapsed: boolean;
}

export interface ContextSidebarCollapsibleGroupProps extends ContextSidebarCollapsibleSharedProps {
  closeLabel: string;
  openLabel: string;
  persistId: string;
  children: React.ReactNode;
}

interface ContextSidebarCollapsibleGroupHeaderProps extends ContextSidebarCollapsibleSharedProps {
  toggleCollapse: () => void;
  ariaLabel?: string;
  childrenWrapperId: string;
}

export const ContextSidebarGroup: React.FC<ContextSidebarGroupProps> = ({ title, children }) => (
  <StyledContextSidebarGroup hasTitle={!!title} isCollapsed={false}>
    {title && (
      <StyledHeaderWrapper isCollapsed={false}>
        <StyledListHeader text={title} priority="low" />
      </StyledHeaderWrapper>
    )}
    {children}
  </StyledContextSidebarGroup>
);

export const ContextSidebarCollapsibleGroup: React.FC<ContextSidebarCollapsibleGroupProps> = ({
  title,
  isCollapsed,
  closeLabel,
  openLabel,
  children,
  persistId,
}) => {
  const childrenWrapperId = uniqueId('context-sidebar-group');
  const [collapseStatus, setCollapseStatus] = useLocalStorage(persistId, !!isCollapsed);

  return (
    <StyledContextSidebarGroup hasTitle={true} isCollapsed={collapseStatus}>
      <ContextSidebarCollapsibleGroupHeader
        title={title}
        childrenWrapperId={childrenWrapperId}
        ariaLabel={collapseStatus ? openLabel : closeLabel}
        isCollapsed={collapseStatus}
        toggleCollapse={() => setCollapseStatus((collapseStatus) => !collapseStatus)}
      />
      {!collapseStatus && <div id={childrenWrapperId}>{children}</div>}
    </StyledContextSidebarGroup>
  );
};

const ContextSidebarCollapsibleGroupHeader: React.FC<ContextSidebarCollapsibleGroupHeaderProps> = ({
  title,
  toggleCollapse,
  ariaLabel,
  isCollapsed,
  childrenWrapperId,
}) => (
  <StyledHeaderWrapper isCollapsed={isCollapsed}>
    <StyledButton
      onClick={toggleCollapse}
      aria-label={ariaLabel}
      aria-expanded={!isCollapsed}
      aria-controls={childrenWrapperId}
    >
      <StyledListHeader
        text={title || ''}
        priority="low"
        endSlot={
          <StyledEndslotWrapper>
            {isCollapsed ? (
              <IconChevronDown height={iconSize} width={iconSize} />
            ) : (
              <IconChevronUp height={iconSize} width={iconSize} />
            )}
          </StyledEndslotWrapper>
        }
      />
    </StyledButton>
  </StyledHeaderWrapper>
);

const StyledEndslotWrapper = styled.div`
  color: ${color.neutral.surface.text};
  display: flex;
  align-items: center;
`;

const StyledListHeader = styled(ListHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledHeaderWrapper = styled.section<Pick<ContextSidebarCollapsibleSharedProps, 'isCollapsed'>>`
  ${(props) =>
    !props.isCollapsed &&
    css`
      padding-bottom: ${spacing.size16};
    `}

  ${(props) =>
    props.isCollapsed &&
    css`
      color: ${color.additional.disabled.text};
    `}
`;

const StyledButton = styled.button`
  all: unset;
  display: block;
  width: 100%;
`;

const StyledContextSidebarGroup = styled.section<
  Pick<ContextSidebarCollapsibleSharedProps, 'isCollapsed'> & { hasTitle: boolean }
>`
  padding-left: ${spacing.size12};
  padding-right: ${spacing.size12};

  ${(props) => css`
    padding-top: ${props.hasTitle ? spacing.size12 : spacing.size16};
  `}

  ${(props) => css`
    padding-bottom: ${props.isCollapsed ? spacing.size12 : spacing.size16};
  `}
`;
