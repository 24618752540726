import { GrippyPosition } from '@lessonup/pin-renderer';
import { css, styled } from '@lessonup/ui-components';
import { PartialPrefix } from '@lessonup/utils';
import React, { CSSProperties } from 'react';

type Props = {
  type: GrippyPosition | 'rotate' | 'default';
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
  mode?: 'resize' | 'crop';
};

export const Grippy: React.FC<Props> = ({ type, onMouseDown, onMouseEnter, onMouseLeave, style, mode }) => {
  return (
    <StyledGrippy
      style={style}
      $type={type}
      mode={mode}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

const grippySize = 8;
const grippyOffSetValue = `-${grippySize / 2}px`;

const StyledGrippy = styled.div<PartialPrefix<Pick<Props, 'type'>, 'type'> & Pick<Props, 'mode'>>`
  position: absolute;
  width: ${grippySize}px;
  height: ${grippySize}px;
  border: 1px solid black;

  ${(props) => {
    switch (props.mode) {
      case 'crop':
        return css`
          background-color: black;
          border-color: white;
        `;
      default:
        return css`
          background-color: white;
        `;
    }
  }}
  pointer-events: all;

  ${(props) => {
    switch (props.$type) {
      case 'top-left':
        return css`
          top: ${grippyOffSetValue};
          left: ${grippyOffSetValue};
        `;
      case 'top-right':
        return css`
          top: ${grippyOffSetValue};
          right: ${grippyOffSetValue};
        `;
      case 'bottom-right':
        return css`
          bottom: ${grippyOffSetValue};
          right: ${grippyOffSetValue};
        `;
      case 'bottom-left':
        return css`
          bottom: ${grippyOffSetValue};
          left: ${grippyOffSetValue};
        `;
      case 'top':
        return css`
          left: 50%;
          transform: translateX(-50%);
          top: ${grippyOffSetValue};
        `;
      case 'bottom':
        return css`
          left: 50%;
          transform: translateX(-50%);
          bottom: ${grippyOffSetValue};
        `;
      case 'left':
        return css`
          top: 50%;
          transform: translateY(-50%);
          left: ${grippyOffSetValue};
        `;
      case 'right':
        return css`
          top: 50%;
          transform: translateY(-50%);
          right: ${grippyOffSetValue};
        `;
      case 'rotate':
        return css`
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 100%;
          cursor: grab;

          &:active {
            cursor: grabbing;
          }

          &::before {
            content: '';
            display: block;
            height: 20px;
            width: 1px;
            background: blue;
            position: relative;
            left: calc(50% - 0.5px);
            top: 100%;
            z-index: -1;
          }
        `;
      default:
        return '';
    }
  }}
`;
