export function stripTrailingSlash(url: string) {
  return url[url.length - 1] === '/' ? url.substring(0, url.length - 1) : url;
}

export function stripTrailingSlashes(url: string) {
  let newUrl = url;
  while (newUrl[newUrl.length - 1] === '/') {
    newUrl = newUrl.substring(0, newUrl.length - 1);
  }
  return newUrl;
}

export function stripLeadingSlash(url: string) {
  return url[0] === '/' ? url.substring(1) : url;
}

export function stripDoubleSlash(url: string) {
  return url.replace(/\/+$/, '');
}
