import _ from 'lodash';
export type TestPhase = 'created' | 'started' | 'done' | 'closed' | 'review';

export namespace TestPhase {
  export type TotalCount = { [key in TestPhase]: number };

  export function isStudentAllowedtToSeeAnswers(testPhase: TestPhase): boolean {
    return testPhase === 'review';
  }

  export function isStudentAllowedToSubmitAnswers(testPhase: TestPhase): boolean {
    return testPhase === 'started';
  }

  export function isBefore(a: TestPhase, b: TestPhase): boolean {
    return orderIndex(a) < orderIndex(b);
  }

  export function isAfter(a: TestPhase, b: TestPhase): boolean {
    return orderIndex(a) > orderIndex(b);
  }

  export function orderIndex(testPhase: TestPhase): number {
    return testPhaseOrder[testPhase] || 0;
  }

  export function isLive(testPhase: TestPhase | undefined): boolean {
    return (testPhase && liveTestPhases.includes(testPhase)) || false;
  }

  export function order(phases: TestPhase[], direction: 'asc' | 'desc'): TestPhase[] {
    return _.orderBy(phases, (phase) => orderIndex(phase), direction);
  }

  export function countPhases(phases: TestPhase[]): TotalCount {
    return phases.reduce(
      (accumulator, phase) => {
        accumulator[phase] += 1;
        return accumulator;
      },
      {
        created: 0,
        started: 0,
        done: 0,
        closed: 0,
        review: 0,
      }
    );
  }

  export function numberOfUsers(total: TotalCount | undefined): number {
    return _.sum(_.values(total));
  }

  export function numberOfUsersInTestPhases(total: TotalCount | undefined, phases: TestPhase[]): number {
    return _.sumBy(phases, (phase) => (total && total[phase]) || 0);
  }

  export function isAnyUserInTestPhase(total: TotalCount | undefined, phases: TestPhase[]): boolean {
    return numberOfUsersInTestPhases(total, phases) > 0;
  }

  export function isEveryUserInTestPhase(total: TotalCount, phase: TestPhase): boolean {
    return numberOfUsers(total) === total[phase];
  }

  export function phasesWithAnyUsers(total: TotalCount): TestPhase[] {
    const entries = Object.entries(total);
    return _.compact(entries.map((kv) => (kv[1] > 0 ? (kv[0] as TestPhase) : undefined)));
  }

  /** returns the latest phase with any users */
  export function latestTestPhase(total: TotalCount): TestPhase | undefined {
    const phases = phasesWithAnyUsers(total);
    return _.first(order(phases, 'desc'));
  }

  /** returns the earliest phase with any users */
  export function earliestTestPhase(total: TotalCount): TestPhase | undefined {
    const phases = phasesWithAnyUsers(total);
    return _.first(order(phases, 'asc'));
  }

  export function isTestReopened(counts: TotalCount) {
    return isAnyUserInTestPhase(counts, ['closed', 'review']) && isAnyUserInTestPhase(counts, ['created', 'started']);
  }
}

const liveTestPhases: TestPhase[] = ['created', 'started', 'done'];

const testPhaseOrder: {
  [key in TestPhase]: number;
} = {
  created: 0,
  started: 1,
  done: 2,
  closed: 3,
  review: 4,
};
