import {
  AnyView,
  areAllWordCloudAnswersRevealed,
  isOpenQuestionView,
  isQuizView,
  isWordCloudView,
  PlayerStoreDispatcher,
  useCurrentPinId,
  usePlayerDispatcher,
} from '@lessonup/players-modern';
import { Button, IconArrowLeft, IconArrowRight, IconTune, styled, Switch } from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { PlayerBarBottomSection } from '../../../../../../components/PlayerBar/PlayerBarBottom/components/PlayerBarBottomModules';
import { usePlayerTranslation } from '../../../../hooks/usePlayerTranslation';
import { useMaximiseAnswerAreaComponent } from '../../../../RealtimePlayer/hooks/useMaximiseAnswerAreaComponent';
import { WaitingForStudents } from '../WaitingForStudents/WaitingForStudents';

interface PlayerBarBottomInteractiveStepControlsProps {
  totalStudents?: number;
  currentView: AnyView | null;
  previousStep?: (e: React.MouseEvent) => void;
  nextStep?: (e: React.MouseEvent) => Promise<void>;
}

export const PlayerBarBarBottomInteractiveStepControls: React.FC<PlayerBarBottomInteractiveStepControlsProps> = ({
  currentView,
  previousStep,
  nextStep,
}) => {
  const { t } = usePlayerTranslation();
  const answerAreaComponentHandlers = useMaximiseAnswerAreaComponent();
  const dispatcher = usePlayerDispatcher();
  const currentPinId = useCurrentPinId();

  if (!currentView || !currentPinId) return null;

  if (isQuizView(currentView) && currentView.phase === 'input') {
    return (
      <PlayerBarBottomSection>
        <Button onClick={nextStep} iconStart={<IconTune />} aria-label={t('labels.endResponses')}>
          {t('labels.endResponses')}
        </Button>
        <WaitingForStudents />
      </PlayerBarBottomSection>
    );
  }

  if (
    (isOpenQuestionView(currentView) && currentView.phase === 'input') ||
    (isWordCloudView(currentView) && currentView.phase === 'input')
  ) {
    return (
      <PlayerBarBottomSection>
        <Button onClick={nextStep} iconStart={<IconTune />} aria-label={t('labels.endResponses')}>
          {t('labels.endResponses')}
        </Button>
        <WaitingForStudents />
        {isOpenQuestionView(currentView) && (
          <StyledSwitch
            id="maximiseAnswerArea"
            onClick={(e) => {
              if (e.currentTarget.checked) {
                answerAreaComponentHandlers.maximise();
              } else {
                answerAreaComponentHandlers.minimise();
              }
            }}
            label={t('labels.maximiseAnswerArea')}
          ></StyledSwitch>
        )}
        <RevealAllAnswersButton currentView={currentView} dispatcher={dispatcher} t={t} />
      </PlayerBarBottomSection>
    );
  }

  if (
    (isQuizView(currentView) && currentView.phase === 'discuss') ||
    (isOpenQuestionView(currentView) && currentView.phase === 'results') ||
    (isWordCloudView(currentView) && currentView.phase === 'results')
  ) {
    return (
      <PlayerBarBottomSection>
        <Button
          buttonType="neutral"
          buttonShape="rect"
          onClick={previousStep}
          iconStart={<IconArrowLeft />}
          aria-label={t('labels.allowAnswers')}
        >
          {t('labels.allowAnswers')}
        </Button>
        {isQuizView(currentView) && (
          <Button onClick={nextStep} iconStart={<IconArrowRight />} aria-label={t('labels.viewResults')}>
            {t('labels.viewResults')}
          </Button>
        )}
        <RevealAllAnswersButton currentView={currentView} dispatcher={dispatcher} t={t} />
      </PlayerBarBottomSection>
    );
  }

  return null;
};

function areAllAnswersRevealed(currentView: AnyView): boolean {
  if (isWordCloudView(currentView)) return areAllWordCloudAnswersRevealed(currentView);
  if (isOpenQuestionView(currentView)) return currentView.revealedAnswers.length === currentView.answerOrder.length;
  return false;
}

function hasNoAnswers(currentView: AnyView): boolean {
  if (isWordCloudView(currentView)) return currentView.answers.length === 0;
  if (isOpenQuestionView(currentView)) return currentView.answerOrder.length === 0;
  return false;
}

const RevealAllAnswersButton: React.FC<{
  currentView: AnyView;
  dispatcher: PlayerStoreDispatcher;
  t: TFunction;
}> = ({ currentView, dispatcher, t }) => {
  if (isWordCloudView(currentView) || isOpenQuestionView(currentView)) {
    if (hasNoAnswers(currentView)) {
      return null;
    }

    const allAnswersRevealed = areAllAnswersRevealed(currentView);

    return (
      <Button
        showStroke
        onClick={(e) => {
          e.stopPropagation();
          dispatcher({
            type: 'pinTypeAction',
            data: {
              type: allAnswersRevealed ? 'hideAllAnswers' : 'revealAllAnswers',
            },
          });
        }}
        buttonType="neutral"
      >
        {t(allAnswersRevealed ? 'labels.hideAllAnswers' : 'labels.revealAllAnswers')}
      </Button>
    );
  }
  return null;
};

const StyledSwitch = styled(Switch)`
  display: flex;
`;
