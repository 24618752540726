import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.404 19.381 9.703-9.186c1.613-1.527 1.488-4.23-.275-5.964-1.722-1.692-4.348-1.809-5.865-.26l-9.439 9.634s2.091.832 3.56 2.276c1.47 1.445 2.316 3.5 2.316 3.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3.77 21.095 6.629-1.706s-.845-2.056-2.313-3.502-3.558-2.28-3.558-2.28l-1.743 6.496c-.152.566.416 1.138.984.992"
    />
  </svg>
);
export default SvgIconEdit;
