import { newVector, pinSize, Vector } from '@lessonup/pin-renderer';
import { InitializedPlayerStoreState, WordCloudView, WordCloudViewAnswer } from '../../playerStore.types';
import { isWordCloudEntry } from '../../utils/entries/entryAssertions';
import { OrderHandler } from '../../utils/orderHandler/orderHandler';
import { normalizeAnswer } from '../../utils/pins/wordCloudPlayer.utils';

export function updateAnswersInView(
  state: InitializedPlayerStoreState,
  previousView: WordCloudView | null,
  orderHandler: OrderHandler
): WordCloudViewAnswer[] {
  const previousAnswers = previousView?.answers ?? [];

  return state.assignmentEntries.reduce(
    (newAnswers, assignmentEntry) => {
      const studentPinEntry = assignmentEntry.entries[state.currentPinId];
      if (isWordCloudEntry(studentPinEntry) && studentPinEntry?.answer?.words) {
        studentPinEntry.answer.words.forEach((answer) => {
          const normalizedAnswer = normalizeAnswer(answer);
          if (!newAnswers.find((answer) => answer.text === normalizedAnswer)) {
            newAnswers.push({
              text: normalizedAnswer,
              position: findNewPosition(newAnswers),
              order: orderHandler.generateNext(),
            });
          }
        });
      }
      return newAnswers;
    },
    [...previousAnswers]
  );
}

// To following functions recreate the 1.0 behavior for the answer positioning in the word cloud, this is a temporary solution

const X_DISTANCE_PENALTY = 5; // X is more important to have distance than Y
const MINIMAL_DISTANCE_SCORE = 75;
const MAX_TRIES = 25;

export function findNewPosition(Answers: WordCloudViewAnswer[]): Vector {
  let bestPosition = newVector(0, 0);
  let bestScore = 0;
  let numTry = 0;

  while (numTry < MAX_TRIES) {
    const position = randomPositionOnPin();
    const score = positionScore(Answers, position);

    if (score === 1) return position;

    if (bestScore < score) {
      bestScore = score;
      bestPosition = position;
    }

    numTry = numTry + 1;
  }

  return bestPosition;
}

function positionScore(Answers: WordCloudViewAnswer[], positionToCheck: Vector): number {
  let nearestDistance = Infinity;
  for (const word of Answers) {
    const distance = distanceScore(word, positionToCheck);
    if (distance < nearestDistance) nearestDistance = distance;
  }

  if (nearestDistance > MINIMAL_DISTANCE_SCORE) return 1;
  return nearestDistance / MINIMAL_DISTANCE_SCORE; // so we can choose best option if there is no space
}

function distanceScore(word: WordCloudViewAnswer, positionToCheck: Vector): number {
  return Math.hypot((word.position.x - positionToCheck.x) / X_DISTANCE_PENALTY, word.position.y - positionToCheck.y);
}

function randomPositionOnPin(): Vector {
  const margin = 0.1;
  const horizontalOffset = margin * pinSize.width;
  const verticalOffset = margin * pinSize.height;
  return newVector(
    Math.random() * (pinSize.width - horizontalOffset * 2) + horizontalOffset,
    Math.random() * (pinSize.height - verticalOffset * 2) + verticalOffset
  );
}
