import { ComponentSize } from '../size/size.types';

export enum spacing {
  size0 = '0px',
  size1 = '1px',
  size2 = '2px',
  size4 = '4px',
  size6 = '6px',
  size8 = '8px',
  size10 = '10px',
  size12 = '12px',
  size16 = '16px',
  size24 = '24px',
  size32 = '32px',
  size64 = '64px',
  size128 = '128px',
}

/**
 *
 * @param baseValue
 * @param size
 * @returns
 */
export function spacingScaled(baseValue: spacing, size?: ComponentSize): string {
  if (size === 'medium') {
    return `${parseFloat(baseValue) * 1.25}px`;
  }

  if (size === 'large') {
    return `${parseFloat(baseValue) * 1.5}px`;
  }

  return baseValue;
}
