import { copyTextToClipboard } from '@lessonup/client-integration';
import {
  Button,
  IconCheck,
  IconLinkAdd,
  InputFormField,
  Introduction,
  rem,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { asError } from '@lessonup/utils';
import React, { useState } from 'react';
import { useClipboardErrorHandler } from '../../../../hooks/useClipboardErrorHandler';
import { useEditorTranslation } from '../../../../hooks/useEditorTranslation';

export interface ShareLessonProps {
  lessonShareUrl?: string;
}

// ToDo: button should be part of a formfields slot after/during TECH-142 FormField update
export const ShareLesson: React.FC<ShareLessonProps> = ({ lessonShareUrl }) => {
  const { t } = useEditorTranslation();
  const { handleClipboardError } = useClipboardErrorHandler();
  const [wasCopied, setWasCopied] = useState(false);

  const handleCopyLink = async () => {
    if (!lessonShareUrl) {
      return;
    }

    try {
      await copyTextToClipboard(lessonShareUrl);
      setWasCopied(true);
    } catch (error) {
      handleClipboardError(asError(error));
    }
  };

  return (
    <StyledShareLesson spacing={spacing.size24} direction="y" padding={spacing.size16}>
      <Introduction
        alignment="start"
        size="S"
        headline={t('shareLesson.headline')}
        bodyText={t('shareLesson.bodyText')}
      />
      <SpaceBetween spacing={spacing.size8} direction="x" alignItems="end" justifyContent="start">
        <StyledFormField label={t('shareLesson.labelForLink')} type="text" value={lessonShareUrl} disabled />
        <Button
          buttonShape="rect"
          size="large"
          buttonType="secondary"
          iconEnd={wasCopied ? <IconCheck /> : <IconLinkAdd />}
          onClick={handleCopyLink}
          disabled={!lessonShareUrl}
        >
          {wasCopied ? t('shareLesson.copied') : t('shareLesson.copyLink')}
        </Button>
      </SpaceBetween>
    </StyledShareLesson>
  );
};

// ToDo: after/during TECH-142 formfield update, change width to 384px;
const StyledShareLesson = styled(SpaceBetween)`
  width: ${rem('484px')};
`;

const StyledFormField = styled(InputFormField)`
  flex: 1;
`;
