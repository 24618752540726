import { VideoSettings } from '@lessonup/pins-shared';
import {
  createModal,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  NiceModal,
  useModal,
} from '@lessonup/ui-components';
import { TFunction } from 'i18next';
import React from 'react';
import { parseVideoUrl } from '../../../utils/pinComponentSettings/videoPinComponentSettings.utils';
import { ModalContentOrientation } from '../ImagePickerModalPopover/imagePicker.types';
import { VideoComponentModalPopoverContent } from './VideoComponentModalPopoverContent';

export interface VideoComponentSourceModalPopoverProps {
  modalDirection: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  modalContentOrientation: ModalContentOrientation;
  parentSelector: () => HTMLLIElement | HTMLButtonElement | null;
  t: TFunction;
}

export const VideoComponentSourceModalPopover = createModal(
  ({
    modalDirection,
    modalAnchor,
    modalContentOrientation,
    parentSelector,
    t,
  }: VideoComponentSourceModalPopoverProps): React.JSX.Element => {
    const modal = useModal();

    const handleAddVideo = (url: string): string | void => {
      const parsedUrl = parseVideoUrl(url);
      if (!parsedUrl.source) {
        return t('videoPicker.invalidUrl');
      } else {
        modal.resolve(parsedUrl);
        modal.hide();
      }
    };

    return (
      <ModalPopover
        modal={modal}
        contentLabel={t('imagePicker.ariaLabel')}
        onBeforeClose={modal.reject}
        direction={modalDirection}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
      >
        <VideoComponentModalPopoverContent
          contentOrientation={modalContentOrientation}
          handleAddVideo={handleAddVideo}
          t={t}
        />
      </ModalPopover>
    );
  }
);

export const showVideoComponentSourceModalPopover = (
  props: VideoComponentSourceModalPopoverProps
): Promise<Pick<VideoSettings, 'source' | 'sourceId'>> => NiceModal.show(VideoComponentSourceModalPopover, props);
