import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSortAscending = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m20.742 17.295-3.297 3.492-3.376-3.417m3.356-14.194v17.648M3.258 13.541h10.164M6.295 8.895h7.127M9.859 4.248h3.563"
    />
  </svg>
);
export default SvgIconSortAscending;
