import styled from '@emotion/styled';
import { MimeTypes, MimeTypesHelper } from '@lessonup/utils';
import { TFunction } from 'i18next';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'usehooks-ts';
import { ComponentSize } from '../../foundations/size/size.types';
import { spacing } from '../../foundations/spacing/spacing';
import { ErrorDisplayMessage, ErrorToErrorDisplayMessage } from '../../utils/Error/errorBaseTypes';
import { useErrorContext } from '../../utils/Error/hooks/useErrorContext';
import { useForwardRef } from '../../utils/hooks/useForwardRef';
import { SpaceBetween } from '../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../BodyText/BodyText';
import { Button } from '../Button/Button';
import { ButtonShape, ButtonType } from '../Button/button.types';

const ERROR_UNSUPPORTED_CONTENT_TYPE = 'unsupported content type';
const ERROR_VIDEO_NOT_ALLOWED = 'not allowed to upload video';
const ERROR_FILE_TOO_LARGE = 'file is too large';
const loadingStateDebounceMilliseconds = 250;

export type UploadFileButtonProps = {
  acceptedMimeTypes: MimeTypes;
  onUpload: (files: File[]) => Promise<void>;
  multiple: boolean;
  dropArea?: boolean;
  buttonType?: ButtonType;
  buttonShape?: ButtonShape;
  buttonStroke?: boolean;
  buttonSize?: ComponentSize;
  loading?: boolean;
  disabled?: boolean;
  belowButtonText?: string;
  icon?: React.JSX.Element;
  useCustomErrorHandling?: boolean;
};

export const UploadFileInput = forwardRef<HTMLInputElement, React.PropsWithChildren<UploadFileButtonProps>>(
  (
    {
      acceptedMimeTypes,
      onUpload,
      multiple = true,
      dropArea = false,
      buttonType = 'neutral',
      buttonShape = 'pill',
      buttonStroke = false,
      buttonSize,
      loading = false,
      disabled = false,
      belowButtonText,
      children,
      icon,
      useCustomErrorHandling,
    },
    ref
  ) => {
    const { setError } = useErrorContext();
    const { t } = useTranslation('uploads');
    const fileInputRef = useForwardRef<HTMLInputElement>(ref);
    const [isLoadingDebounced] = useDebounceValue(loading, loadingStateDebounceMilliseconds);
    const setErrorHandler = useCallback(
      (error: Error) =>
        !useCustomErrorHandling && setError({ error, customErrorParser: createUploadFileErrorParser(t) }),
      [setError, t, useCustomErrorHandling]
    );
    const mimeTypesHelper = useMemo(() => MimeTypesHelper.fromMimeTypes(acceptedMimeTypes), [acceptedMimeTypes]);

    const handleUploadFiles = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
          const files: File[] = Array.from(event.target.files || []);
          for (const file of files) {
            if (!mimeTypesHelper.isAllowed(file.type) && !useCustomErrorHandling) {
              setErrorHandler(new Error(ERROR_UNSUPPORTED_CONTENT_TYPE));
              return;
            }
          }

          await onUpload(files);
        } finally {
          // Resetting the input in order to be able to upload the same file.
          event.target.value = '';
        }
      },
      [mimeTypesHelper, onUpload, setErrorHandler, useCustomErrorHandling]
    );

    const openFileInput = () => fileInputRef.current?.click();

    return dropArea ? (
      <>
        <DropAreaFileInput
          ref={fileInputRef}
          multiple={multiple}
          type="file"
          onChange={handleUploadFiles}
          accept={mimeTypesHelper.toAcceptString()}
          title=""
        />
        <SpaceBetween direction="y" alignItems="center" spacing={spacing.size16}>
          <Button
            loading={isLoadingDebounced}
            disabled={disabled}
            onClick={openFileInput}
            size={buttonSize}
            buttonType={buttonType}
            showStroke={buttonStroke}
            buttonShape={buttonShape}
            iconStart={icon}
          >
            {children}
          </Button>
          {belowButtonText && <StyledBelowButtonText>{belowButtonText}</StyledBelowButtonText>}
        </SpaceBetween>
      </>
    ) : (
      <>
        <HiddenFileInput
          ref={fileInputRef}
          multiple={multiple}
          type="file"
          onChange={handleUploadFiles}
          accept={mimeTypesHelper.toAcceptString()}
        />
        <StyledButton
          loading={isLoadingDebounced}
          disabled={disabled}
          onClick={openFileInput}
          size={buttonSize}
          iconStart={icon}
          buttonType={buttonType}
          buttonShape={buttonShape}
          showStroke={buttonStroke}
          type="button"
        >
          {children}
        </StyledButton>
        {belowButtonText && <StyledBelowButtonText>{belowButtonText}</StyledBelowButtonText>}
      </>
    );
  }
);

UploadFileInput.displayName = 'UploadFileInput';

export const createUploadFileErrorParser: (t: TFunction) => ErrorToErrorDisplayMessage = (t: TFunction) => {
  return (error: Error): ErrorDisplayMessage => {
    const notAllowedToUploadFileType = error && error.message.includes(ERROR_VIDEO_NOT_ALLOWED);

    if (notAllowedToUploadFileType) {
      return { title: '', description: t('notAllowedToUploadVideo') };
    }

    const isIncorrectFileTypeError = error && error.message.includes(ERROR_UNSUPPORTED_CONTENT_TYPE);

    if (isIncorrectFileTypeError) {
      return { title: '', description: t('unsupportedFileType') };
    }

    const fileTooLargeError = error && error.message.includes(ERROR_FILE_TOO_LARGE);

    if (fileTooLargeError) {
      return { title: '', description: t('fileTooLarge') };
    }
    return { title: '', description: t('somethingWentWrong') };
  };
};

const DropAreaFileInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const StyledBelowButtonText = styled(BodyText)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
`;
