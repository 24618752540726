import { Student } from '@lessonup/players-modern';
import {
  createModal,
  List,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  ModalPopOverParentSelector,
  spacing,
  styled,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../../../hooks/usePlayerTranslation';

interface PinModalPopoverProps {
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: ModalPopOverParentSelector;
  students: Student[];
}
export const RemainingStudentPopover = createModal(
  ({ direction, modalAnchor, parentSelector, students }: PinModalPopoverProps) => {
    const modal = useModal();
    const { t } = usePlayerTranslation();

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel={t('labels.studentsRemaining')}
      >
        <ModalPopoverBody>
          <List
            items={[
              { text: students.length ? t('notAnswered') : t('allAnswered'), header: 'medium' },
              ...students.map((student) => ({ text: student.name })),
            ]}
          />
        </ModalPopoverBody>
      </ModalPopover>
    );
  }
);

const ModalPopoverBody = styled.div`
  padding: ${spacing.size12};
  max-height: 70vh;
`;
