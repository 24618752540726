import {
  newHotspotPinComponent,
  newImagePinComponent,
  newLinePinComponent,
  newShapePinComponent,
  newSize,
  newTextPinComponent,
  newVector,
  PinComponent,
} from '@lessonup/pins-shared';
import { JSONContent } from '@tiptap/core';

export const dummyComponents: PinComponent[] = [
  newShapePinComponent({ position: newVector(1400, 50), fillColor: 'red', rotation: 200 }),
  newShapePinComponent({ position: newVector(500, 100), fillColor: 'yellow' }),
  newShapePinComponent({ position: newVector(-50, 1000), fillColor: 'green' }),
  newHotspotPinComponent({ position: newVector(800, 400), settings: { iconFill: 'ROCKET' } }),
  newHotspotPinComponent({ position: newVector(800, 600), settings: { iconFill: 'ADD' } }),
  newImagePinComponent({
    url: 'https://images.prismic.io/lessonup/f5e93312-24ee-4265-ab12-cee6b7e3cc9f_UGO-min.png?auto=compress,format&rect=865,0,3456,3456&w=1200&h=1200',
    position: newVector(300, 40),
    size: newSize(240, 240),
  }),
  newLinePinComponent({ start: newVector(650, 550), settings: { headMarker: 'NONE', tailMarker: 'ARROW' } }),
  newLinePinComponent({
    start: newVector(650, 570),
    end: newVector(650, 670),
    settings: { headMarker: 'NONE', tailMarker: 'ARROW' },
  }),
  newLinePinComponent({ start: newVector(200, 200), end: newVector(50, 50) }),
  newTextPinComponent({ position: newVector(800, 100) }),
  newTextPinComponent({ position: newVector(1200, 100), content: bulletList() }),
];

function bulletList(): JSONContent {
  return {
    type: 'doc',
    content: [
      {
        type: 'customBulletList',
        content: [
          {
            type: 'customListItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Platform waarsssmee een docent laagdrempelig zijn lessen kan verrijken',
                  },
                ],
              },
            ],
          },
          {
            type: 'customListItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Van presentatie naar les',
                  },
                ],
              },
            ],
          },
          {
            type: 'customListItem',
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Alles in een: niet schakelen tussen applicaties',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}
