import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconMicrophone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.907 18.541v2.823M3.63 12.077c.275 3.613 3.281 6.465 8.316 6.465 4.724 0 7.856-2.86 8.426-6.465m-8.399-9.441c2.312 0 4.404 1.377 4.454 3.353.084 3.354.87 8.672-4.399 8.672-5.268 0-4.537-5.318-4.453-8.672.05-1.976 2.087-3.353 4.398-3.353"
    />
  </svg>
);
export default SvgIconMicrophone;
