import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { math, rem } from 'polished';
import React, { useState } from 'react';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { IconVisibilityOff, IconVisibilityOn } from '../../icons';
import { InputFormFieldProps, PasswordFormFieldProps } from '../model';
import { sharedFormFieldStyle, sharedInputSelectStyle } from '../utils';

const iconDim = 16;

export const PasswordField = ({ id, ...props }: PasswordFormFieldProps) => {
  const { validation, formFieldSize, disabled } = props;

  const [switchableType, setSwitchableType] = useState<PasswordFormFieldProps['type']>('password');
  const validationMessageId = `${id}-validation`;

  const togglePasswordVisibility = () => {
    if (disabled) return;
    if (switchableType === 'password') {
      setSwitchableType('text');
      return;
    }
    setSwitchableType('password');
  };

  return (
    <SpaceBetween direction="y" spacing={spacing.size4}>
      <StyledInputFieldWrapper>
        <StyledInput
          {...props}
          type={switchableType}
          ref={props.forwardedRef}
          id={id}
          aria-describedby={validation?.message ? validationMessageId : undefined}
          aria-invalid={validation?.state === 'error'}
        />
        <RevealButtonWrapper
          onClick={(event) => {
            event.preventDefault();
            togglePasswordVisibility();
          }}
          formFieldSize={formFieldSize}
          disabled={disabled}
          type="button"
        >
          {switchableType === 'password' ? (
            <IconVisibilityOff width={rem(spacing.size16)} />
          ) : (
            <IconVisibilityOn width={rem(spacing.size16)} />
          )}
        </RevealButtonWrapper>
      </StyledInputFieldWrapper>
    </SpaceBetween>
  );
};

const StyledInputFieldWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input<PasswordFormFieldProps>`
  ${sharedInputSelectStyle}
  ${sharedFormFieldStyle}
  padding-right: ${math(`${iconDim} + 2 * ${spacing.size16} `)};
  width: 100%;
`;

const RevealButtonWrapper = styled.button<Pick<InputFormFieldProps, 'formFieldSize' | 'disabled'>>`
  position: absolute;
  display: flex;
  align-items: center;
  right: ${spacing.size24};
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  color: ${(props) => {
    return props.disabled
      ? css`
          ${color.additional.disabled.text}
        `
      : css`
          ${color.accent.secondaryContainer.text}
        `;
  }};
`;
