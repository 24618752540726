import { useKeyboardEvent } from '@lessonup/client-integration';
import {
  addVectors,
  Box,
  boxAfterRotation,
  boxBeforeRotation,
  computeBackdrop,
  computeCropArea,
  CropArea,
  cssRotateDeg,
  cssTranslate,
  cursorMapForRotation,
  getViewportBackgroundStyle,
  GrippyPosition,
  ImagePinComponent,
  ImagePinComponentRenderer,
  isBoxLayout,
  Rectangle,
  scaleSize,
  scaleVector,
  useKeybindController,
  useOriginalAspectRatio,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import { styled } from '@lessonup/ui-components';
import { addImageSizing } from '@lessonup/utils';
import React, { CSSProperties, useMemo } from 'react';
import { Grippy } from '../Grippy/Grippy';
import useMouseDrag from './hooks/useMouseDrag';
import { useMoveBackdropKeybinds } from './hooks/useMoveBackdropKeybinds';
import { useResizableViewport } from './hooks/useResizableViewport';

export interface Props {
  pinComponent: ImagePinComponent;
  pinScale: number;
  setModeSelecting(): void;
}

/**
 * Control layer that enables image cropping by creating a viewport over a
 * backdrop.
 *
 * The viewport represents the visible portion of the image. Its layout
 * (position, size, rotation) matches the overall layout of the pin component
 * and displays only the cropped region intended for presentation.
 *
 * The backdrop displays the full, uncropped image positioned behind the
 * viewport. Both use the same background image, but the viewport’s background
 * is offset to align with its position on the backdrop, creating a "cutout"
 * effect.
 *
 * The backdrop has its own absolute size and location in canvas coordinate
 * space, and does not have independent rotation. There is only one
 * shared rotation angle: that of the image pin component.
 *
 * By default, pin components use `transform-origin: center`, defining their
 * coordinate system with the origin at `(50% width, 50% height)`. This control
 * layer, however, sets `transform-origin: top left` to simplify the 2D
 * transformations required for translating between coordinate spaces.
 */
export function ImageCroppingLayer({ pinComponent, pinScale, setModeSelecting }: Props) {
  const fullImageRatio = useOriginalAspectRatio(pinComponent.settings.url);

  const { canUseKeybinds } = useKeybindController();
  useKeyboardEvent('keydown', { Enter: () => setModeSelecting() }, canUseKeybinds);

  const layout = pinComponent.layout;
  if (!isBoxLayout(layout)) {
    console.log('ImageCroppingLayer: expected pinComponent.layout to be a box');
    return null;
  }

  const viewport = boxAfterRotation(layout);

  if (!fullImageRatio) {
    // Render just the viewport while we fetch the image's aspect ratio.
    const positionStyle = getViewportPositionStyle(viewport, pinScale);
    return (
      <div style={positionStyle}>
        <ImagePinComponentRenderer settings={pinComponent.settings} layout={layout} />
      </div>
    );
  }

  const cropArea = pinComponent.settings.cropArea || CropArea.cover;
  const backdrop = computeBackdrop({ viewport, cropArea, fullImageRatio });
  const imageUrl = addImageSizing({ url: pinComponent.settings.url, isThumb: false, size: 'xl' });

  return (
    <BackdropAndViewport
      pinComponentId={pinComponent.id}
      viewport={viewport}
      backdrop={backdrop}
      pinScale={pinScale}
      imageUrl={imageUrl}
    />
  );
}

interface BackdropAndViewportProps {
  /** The ID of the image pin component we are manipulating. */
  pinComponentId: string;
  /** The initial viewport layout as we start cropping. */
  viewport: Box;
  /** The initial backdrop rectangle, with position relative to the viewport. */
  backdrop: Rectangle;
  /** Scale at which the pin is rendered. */
  pinScale: number;
  /** Image URL with sizing applied. */
  imageUrl: string;
}

function BackdropAndViewport(props: BackdropAndViewportProps) {
  const { pinScale, viewport, backdrop, imageUrl, pinComponentId } = props;

  // Saving helpers for when mouse drags end.
  // We use an inline function to refer to the final positions defined below.
  const { saveImage } = useSaveImage(pinComponentId);
  const save = () => saveImage(finalViewport, finalBackdrop);

  // Drag helpers
  const resizableViewport = useResizableViewport({ viewport, pinScale, resizeEnded: save });
  const dragBackdrop = useMouseDrag({ pinScale, dragEnded: save });

  // Enable listeners for moving the backdrop with arrow keys.
  const canMoveWithKeybinds = !dragBackdrop.isDragging && !resizableViewport.dragViewport.isDragging;
  useMoveBackdropKeybinds({ pinComponentId, viewport, backdrop, canMove: canMoveWithKeybinds });

  // Final positions and sizes, after applying any pending mouse drags.
  const finalViewport = resizableViewport.finalViewport;
  const finalBackdrop: Rectangle = {
    position: addVectors(backdrop.position, dragBackdrop.offset),
    size: backdrop.size,
  };

  // CSS styles
  const viewportPositionStyle = getViewportPositionStyle(finalViewport, pinScale);
  const viewportBackgroundStyle = getViewportBackgroundStyle(finalViewport, finalBackdrop, pinScale);
  const backdropPositionStyle: CSSProperties = {
    ...scaleSize(finalBackdrop.size, pinScale),
    transform: [cssTranslate(scaleVector(finalBackdrop.position, pinScale)), cssRotateDeg(viewport.rotation)].join(' '),
  };
  const cursorMap = useMemo(() => cursorMapForRotation(viewport.rotation), [viewport.rotation]);

  return (
    <>
      <BackdropWrapper style={{ ...backdropPositionStyle }} onMouseDown={dragBackdrop.startDragging}>
        <Backdrop style={{ backgroundImage: `url(${imageUrl})` }} />
      </BackdropWrapper>
      <Viewport
        style={{ ...viewportPositionStyle, ...viewportBackgroundStyle, backgroundImage: `url(${props.imageUrl})` }}
        onMouseDown={resizableViewport.dragViewport.startDragging}
      ></Viewport>
      <GrippyWrapper style={viewportPositionStyle}>
        {GrippyPosition.values.map((grippy) => (
          <Grippy
            type={grippy}
            key={grippy}
            style={{ cursor: cursorMap[grippy] }}
            mode="crop"
            onMouseDown={resizableViewport.dragGrippies[grippy].startDragging}
          />
        ))}
      </GrippyWrapper>
    </>
  );
}

/** Creates a helper function for persisting the new pin component settings. */
function useSaveImage(pinComponentId: string) {
  {
    const updatePin = useUpdatePin();

    return {
      saveImage(finalViewport: Box, finalBackdrop: Rectangle) {
        const cropArea = computeCropArea(finalViewport, finalBackdrop);
        const layout = boxBeforeRotation(finalViewport);

        updatePin([
          {
            type: 'updateComponentLayout',
            updates: { [pinComponentId]: layout },
          },
          {
            type: 'updatePinComponentSettings',
            pinComponentId,
            settings: { cropArea },
          },
        ]);
      },
    };
  }
}

/** Returns the CSS properties for the viewport's positioning. */
function getViewportPositionStyle(viewport: Box, pinScale: number): CSSProperties {
  return {
    ...scaleSize(viewport.size, pinScale),
    transform: [cssTranslate(scaleVector(viewport.position, pinScale)), cssRotateDeg(viewport.rotation)].join(' '),
  };
}

const BackdropWrapper = styled.div`
  transform-origin: top left;
  pointer-events: all;
  position: absolute;
  background-color: white;
`;

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
`;

const Viewport = styled.div`
  transform-origin: top left;
  pointer-events: all;
  position: absolute;
  outline: 1px solid blue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const GrippyWrapper = styled.div`
  transform-origin: top left;
  position: absolute;
`;
