import { globalI18nProxy } from '@lessonup/utils';
import _ from 'lodash';
import { EduSystem } from '../eduSystems';
import { EduSystemBase } from './EduSystemBase';
import {
  eduSystemEditDataDuration,
  eduSystemEditDataLevel,
  eduSystemEditDataSchoolType,
  eduSystemEditDataSubject,
  eduSystemEditDataYear,
} from './eduSystemShared';

const i18n = globalI18nProxy();

type SchoolType = 'gb_pe' | 'gb_lse' | 'gb_use' | 'gb_fe' | 'gb_hed' | 'gb_hen' | 'gb_fd' | 'gb_sp';
export type EduSystemGBSchoolType = SchoolType;
export class EduSystemGB extends EduSystemBase<SchoolType> {
  public key = 'GB';
  public country() {
    return 'gb' as const;
  }
  public constructor() {
    super('gb_lse');
  }
  public EDIT_DATA() {
    return [
      eduSystemEditDataDuration(() => i18n.__('Lesduur')),
      eduSystemEditDataSchoolType(() => i18n.__('School')),
      eduSystemEditDataLevel(() => i18n.__('Niveau'), levelSuggestor),
      eduSystemEditDataYear(() => i18n.__('Leerjaar PE'), 'grade-pe'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar LSE'), 'grade-lse'),
      eduSystemEditDataYear(() => i18n.__('Leerjaar'), 'grade'),
      eduSystemEditDataSubject(() => i18n.__('Vak'), ['subjects', 'GBSubjects']),
    ];
  }
  protected _SYSTEM_DATA() {
    const dict: EduSystem.SystemDataDict<SchoolType> = {
      gb_pe: {
        id: 'gb_pe',
        short: 'PE',
        label: 'Primary Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-pe',
            type: 'toggle',
            label: () => i18n.__('Jaar'),
            labeler: 'ValueOnly',
            options: [
              { id: 4, value: 'Reception' },
              { id: 5, value: 'Year 1' },
              { id: 6, value: 'Year 2' },
              { id: 7, value: 'Year 3' },
              { id: 8, value: 'Year 4' },
              { id: 9, value: 'Year 5' },
              { id: 10, value: 'Year 6' },
            ],
          },
        ],
      },

      gb_lse: {
        id: 'gb_lse',
        short: 'KS3',
        label: 'Lower Secondary (Key Stage 3)',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade-lse',
            type: 'toggle',
            label: () => i18n.__('Year'),
            labeler: 'LabelValue',
            options: [
              { id: 11, value: '7' },
              { id: 12, value: '8' },
              { id: 13, value: '9' },
            ],
          },
        ],
      },

      gb_use: {
        id: 'gb_use',
        short: 'KS4',
        label: 'Upper Secondary (Key Stage 4)',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Kwalificatie'),
            labeler: 'ValueOnly',
            optionOrderIsSet: true,
            options: [
              { id: 'BTEC', value: 'BTEC' },
              { id: 'GCSE', value: 'GCSE' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Year'),
            labeler: 'LabelValue',
            options: [
              { id: 14, value: '10' },
              { id: 15, value: '11' },
            ],
          },
        ],
      },

      gb_fe: {
        id: 'gb_fe',
        short: 'KS5',
        label: 'Further Education (Key Stage 5)',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'level',
            lessonProp: 'levels',
            reference: 'levels',
            type: 'toggle',
            label: () => i18n.__('Kwalificatie'),
            labeler: 'ValueOnly',
            optionOrderIsSet: true,
            options: [
              { id: 'AS', value: 'AS Level' },
              { id: 'A', value: 'A Level' },
            ],
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            label: () => i18n.__('Year'),
            labeler: 'LabelValue',
            options: [
              { id: 16, value: '12' },
              { id: 17, value: '13' },
            ],
          },
        ],
      },

      gb_hed: {
        id: 'gb_hed',
        short: 'Degree',
        label: 'Higher Education (degree)',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            labeler: 'ValueOnly',
            options: [
              { id: 18, value: 'Undergraduate 1' },
              { id: 19, value: 'Undergraduate 2' },
              { id: 20, value: 'Undergraduate 3' },
              { id: 21, value: 'Undergraduate 4' },
              { id: 22, value: 'Postgraduate/Master 1' },
              { id: 23, value: 'Postgraduate/Master 2' },
              { id: 24, value: 'PhD 1' },
              { id: 25, value: 'PhD 2' },
              { id: 26, value: 'PhD 3' },
            ],
          },
        ],
      },

      gb_hen: {
        id: 'gb_hen',
        short: 'Non-degree',
        label: 'Higher Education (non-degree)',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            labeler: 'ValueOnly',
            options: [
              { id: 18, value: 'HNC 1' },
              { id: 19, value: 'HND 1' },
              { id: 20, value: 'HND 2' },
              { id: 21, value: 'HND 3' },
            ],
          },
        ],
      },

      gb_fd: {
        id: 'gb_fd',
        short: 'FD',
        label: 'Foundation Degree',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
          {
            id: 'year',
            lessonProp: 'years',
            reference: 'grade',
            type: 'toggle',
            labeler: 'ValueOnly',
            options: [
              { id: 18, value: 'FD 1' },
              { id: 19, value: 'FD 2' },
            ],
          },
        ],
      },

      gb_sp: {
        id: 'gb_sp',
        short: 'SP',
        label: 'Special Education',
        prefix: undefined,
        fields: [
          {
            id: 'subject',
          },
        ],
      },
    };
    return dict;
  }

  public labelsForMetaData(metaData: EduSystem.MetaDataParams): EduSystem.MetaDataTags {
    const { subjects, schoolType } = metaData;
    const tags: EduSystem.MetaDataTags = {
      subjects,
      schoolType: _.compact(schoolType?.map((st) => this.SYSTEM_DATA_DICT[st]?.label)),
      levels: [],
      years: [],
    };

    const { hasUSE } = hasEduSystem(metaData);
    if (hasUSE) {
      const value = this.getFieldSummary({ schoolType: 'gb_use', lesson: metaData, fieldName: 'levels' });
      if (value) {
        tags.levels.push(value);
      }
    }

    const value = this.getFieldSummary({ schoolType: 'gb_pe', lesson: metaData, fieldName: 'years' });
    if (value) {
      tags.years.push(`Age ${value}`);
    }
    return tags;
  }
}

function hasEduSystem(metaData: EduSystem.MetaDataParams) {
  return {
    hasPE: metaData.schoolType && _.includes(metaData.schoolType, 'gb_pe'),
    hasLSE: metaData.schoolType && _.includes(metaData.schoolType, 'gb_lse'),
    hasUSE: metaData.schoolType && _.includes(metaData.schoolType, 'gb_use'),
    hasHED: metaData.schoolType && _.includes(metaData.schoolType, 'gb_hed'),
    hasSP: metaData.schoolType && _.includes(metaData.schoolType, 'gb_sp'),
  };
}

const levelSuggestor: EduSystem.Suggestor<string[]> = ({ value, fieldOptions }) => {
  const levels: string[] = [];
  _.each(fieldOptions, function (sug) {
    if (value && _.includes(value, sug.id)) {
      const level = sug.value;
      levels.push(level);
    }
  });
  return levels.join(', ');
};
