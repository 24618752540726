import { CookieSettings } from '../../cookies/analytic-cookies';
import { TrackerDestination } from './TrackerDestination';

export interface GA4DestinationOptions {
  gtag: Function;
}

export class GA4Destination implements TrackerDestination {
  private isConfigLoaded: boolean = false;
  private isPaused = false;
  private readonly gtag: Function;

  constructor(options: GA4DestinationOptions) {
    this.gtag = options.gtag;
  }

  private loadConfig() {
    if (this.isConfigLoaded) return;
    this.gtag('config', 'G-DBSWZ6Z27F'); // our GA-4 ID
    this.gtag('config', 'AW-952526834'); // our Google Adwords Conversion ID
    this.isConfigLoaded = true;
  }

  init(cookieSettings: CookieSettings | undefined) {
    // about consent mode: https://support.google.com/analytics/answer/9976101?hl=en
    // always set the default mode before doing anything else
    this.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });

    // only update when we have any cookie set
    if (cookieSettings) {
      this.gtag('consent', 'update', {
        ad_storage: cookieSettings.marketing ? 'granted' : 'denied',
        analytics_storage: cookieSettings.analytic ? 'granted' : 'denied',
      });

      // loading the config triggers a pageview so we wait fo this until the user has accepted cookies.
      this.loadConfig();
    }
  }

  pause(paused: boolean) {
    this.isPaused = paused;
  }

  trackPageView() {
    // empty because ga4 does this for us
    // this does mean pausing doesn't work, but that doesn't really matter because it's only used by wimpersonate
  }

  logEvent(event: string, data: object) {
    if (this.isPaused) return;

    this.gtag('event', event, data);
  }

  trackError(message: string, error: Error | undefined) {
    if (this.isPaused) return;

    this.gtag('event', 'exception', { description: message });
  }

  setUserId(userId: string | undefined | null) {
    // It's fine to set the user_id later than the initial config call.
    // See https://support.google.com/analytics/answer/9213390
    this.gtag('set', 'user_id', userId);
    // crm_id is for reporting:
    // https://developers.google.com/analytics/devguides/collection/ga4/user-id?technology=gtagjs#access_the_identifier
    this.gtag('set', 'user_properties.crm_id', userId);
  }

  logout() {
    // no action needed here
  }

  updateConsent(cookieSettings: CookieSettings) {
    this.gtag('consent', 'update', {
      ad_storage: cookieSettings.marketing ? 'granted' : 'denied',
      analytics_storage: cookieSettings.analytic ? 'granted' : 'denied',
    });

    // if the config wasn't loaded before because we we're missing cookies, than we load it now
    // note that consent must be set before loading the config
    this.loadConfig();
  }
}
