import { styled } from '@lessonup/ui-components';
import { DecoratorFn } from '@storybook/react';
import React from 'react';

export const FullScreenDecorator: DecoratorFn = (Story) => {
  return (
    <FullScreen>
      <Story />
    </FullScreen>
  );
};

const FullScreen = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
`;
