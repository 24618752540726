import styled from '@emotion/styled';
import { rem } from 'polished';
import React from 'react';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { BodyText, BodyTextSize } from '../../BodyText/BodyText';

type TextListItemFontSize = 'default' | 'small';

export interface TextListItemProps {
  text: string;
  startSlot: React.JSX.Element;
  fontSize?: TextListItemFontSize;
}

export function TextListItem(props: TextListItemProps) {
  const getBodyTextSize = (fontSize?: TextListItemFontSize): BodyTextSize => {
    if (!fontSize) {
      return 'medium';
    }

    return fontSize === 'small' ? 'small' : 'medium';
  };

  return (
    <StyledLi>
      {props.startSlot ? (
        <StyledStartSlotWrapper>
          <StyledStartSlot size={getBodyTextSize(props.fontSize)}>{props.startSlot}</StyledStartSlot>
        </StyledStartSlotWrapper>
      ) : null}
      <BodyText size={getBodyTextSize(props.fontSize)}>{props.text}</BodyText>
    </StyledLi>
  );
}

const StyledLi = styled.li`
  display: flex;
`;

const StyledStartSlotWrapper = styled.div`
  width: ${rem(spacing.size32)};
  flex-shrink: 0;
  margin-right: ${spacing.size8};
`;

const StyledStartSlot = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;
