import { Pin } from '@lessonup/pins-shared';
import { assertNever } from '@lessonup/utils';
import React from 'react';
import { SlidePinComponent } from '../../../pins/SlidePin/SlidePin';
import { OpenQuestionPinStatic } from './pins/OpenQuestionPinStatic';
import { QuizPinStatic } from './pins/QuizPinStatic';
import { WordCloudPinStatic } from './pins/WordCloudPinStatic';

export interface PinSwitchStaticProps {
  pin: Pin;
  isThumb?: boolean;
  children?: React.ReactNode;
}

export const PinSwitchStatic: React.FC<PinSwitchStaticProps> = ({ pin, isThumb, children }) => {
  const type = pin.type;
  switch (type) {
    case 'SLIDE':
      return (
        <SlidePinComponent pinSettings={pin.settings} isThumb={isThumb}>
          {children}
        </SlidePinComponent>
      );
    case 'MULTIPLE_CHOICE':
      return (
        <QuizPinStatic pinSettings={pin.settings} isThumb={isThumb}>
          {children}
        </QuizPinStatic>
      );
    case 'OPEN_QUESTION':
      return (
        <OpenQuestionPinStatic pinSettings={pin.settings} isThumb={isThumb}>
          {children}
        </OpenQuestionPinStatic>
      );
    case 'WORD_CLOUD':
      return (
        <WordCloudPinStatic pinSettings={pin.settings} isThumb={isThumb}>
          {children}
        </WordCloudPinStatic>
      );
    case 'DRAG_QUESTION':
    case 'VIDEO':
    case 'LINK':
    case 'MAP':
    case 'POLL':
      return null;
    default:
      assertNever(type, 'Unhandled type in PinSwitchStatic');
  }
};
