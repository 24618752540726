import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPrint = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.96 8.885c-.347-1.838-.375-3.048-.14-4.704A1.44 1.44 0 0 1 7.1 2.963c3.657-.4 6.119-.41 9.763-.007.671.074 1.21.597 1.286 1.268.188 1.666.164 2.87-.116 4.66M5.447 18.422a3 3 0 0 1-2.822-2.995v-2.111c0-2.25.746-4.155 3.75-4.5 4.397-.504 6.854-.498 11.25 0 2.98.338 3.75 2.25 3.75 4.5v2.111a3 3 0 0 1-2.822 2.995m-.489-1.93c.363.869.554 2.04.636 3.16.057.783-.555 1.437-1.337 1.49-3.997.267-6.73.268-10.726 0-.783-.053-1.394-.707-1.337-1.49.082-1.118.273-2.287.634-3.156.36-.863 1.298-1.215 2.227-1.323 2.789-.324 4.831-.334 7.683 0 .926.108 1.861.458 2.22 1.318"
    />
  </svg>
);
export default SvgIconPrint;
