import { tracker } from '@lessonup/analytics';
import { useDocumentMutation } from '@lessonup/client-integration';
import {
  ButtonType,
  createUploadFileErrorParser,
  dismissToast,
  IconAdd,
  toast,
  UploadFileInput,
  useErrorContext,
} from '@lessonup/ui-components';
import { asError, MimeTypes } from '@lessonup/utils';
import { TFunction } from 'i18next';
import { compact, first } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadsStatus } from './hooks/useUploadsStatus';
import { useUploadStatusPolling } from './hooks/useUploadStatusPolling';
import { CreateUploadData, UploadStatusData } from './Uploads.graphql';
import { CreateUploadDocument, MyUploadsDocument } from './Uploads.graphql.generated';
import { fileTypeMapForEvent, i18nextNamespace, Upload } from './UploadsFeature.utils';
import { uploadFileLimiter } from './utils/UploadFileLimiter';

type UploadFileProps = {
  folderId?: string;
  acceptedMimeTypes: MimeTypes;
  type?: ButtonType;
};

export function UploadFile(props: UploadFileProps) {
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();
  const setErrorHandler = (error: Error) => setError({ error, customErrorParser: createUploadFileErrorParser(t) });
  const { startPollingForIds } = useUploadStatusPolling({ onError: handleFetchStatusError });
  const { notifyUploadStarted, setUploadsInProgressIds } = useProgressNotifications({ t });

  function handleFetchStatusError(error: Error) {
    setError({
      error,
      customErrorDisplayMessage: { title: '', description: t('retrievingUploadStatusFailed') },
    });
  }

  const sendFileToBackend = async (data: CreateUploadData, file: File) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', file.type);
    myHeaders.append('x-goog-content-length-range', `0,${file.size}`);

    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };

    return fetch(data.createUpload.uploadUrl, requestOptions);
  };

  const [createUpload, { loading }] = useDocumentMutation(CreateUploadDocument, {
    refetchQueries: [MyUploadsDocument],
  });

  const handleSingleFileRegistrationError = useCallback((error: unknown) => {
    if (uploadFileLimiter()) {
      setError({
        error: null,
        customErrorDisplayMessage: {
          title: t('tooManyFailedUploads.title'),
          description: t('tooManyFailedUploads.description'),
        },
      });
    } else {
      setErrorHandler(asError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerSingleFileForUpload = useCallback(
    async (file: File): Promise<string | undefined> => {
      const { data } = await createUpload({
        variables: {
          input: {
            contentType: file.type,
            name: file.name,
            sizeInBytes: file.size,
            folderId: props.folderId,
          },
        },
      });

      if (!data) return;

      await sendFileToBackend(data, file);
      const { id, __typename: type, originalFile } = data?.createUpload.upload || {};

      if (!id) return;
      startPollingForIds([id]);
      onFileUploadRegistrationCompleted(type, originalFile.fileExtension);
      return id;
    },
    [createUpload, props.folderId, startPollingForIds]
  );

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      notifyUploadStarted(files);
      const uploadResults = await Promise.all(
        files.map(async (file) => {
          try {
            return await registerSingleFileForUpload(file);
          } catch (error) {
            handleSingleFileRegistrationError(error);
          }
        })
      );
      setUploadsInProgressIds(compact(uploadResults));
    },
    [handleSingleFileRegistrationError, registerSingleFileForUpload, notifyUploadStarted, setUploadsInProgressIds]
  );

  return (
    <div>
      <UploadFileInput
        multiple
        onUpload={handleUploadFiles}
        acceptedMimeTypes={props.acceptedMimeTypes}
        loading={loading}
        buttonType={props.type ?? 'primary'}
        icon={<IconAdd />}
      >
        {t('uploadFile')}
      </UploadFileInput>
    </div>
  );
}

function onFileUploadRegistrationCompleted(type: Upload['__typename'], originalFileExtension: string) {
  if (!type) return;
  const fileType = fileTypeMapForEvent[type];
  tracker.events.upload({ fileType, fileExtension: originalFileExtension });
}

const useProgressNotifications = ({ t }: { t: TFunction }) => {
  const [uploadStartedToastId, setUploadStartedToastId] = useState<string | number | undefined>();
  const [uploadsInProgressIds, setUploadsInProgressIds] = useState<string[]>([]);
  const onAllCompleted = ({ viewer: { uploadsByIds: uploads } }: UploadStatusData) => {
    if (uploads.length) {
      dismissToast(uploadStartedToastId);
      setUploadStartedToastId(undefined);
      toast({
        message: t('fileUploadedSuccessfully', { fileName: first(uploads)?.name, count: uploads.length }),
      });
    }
    setUploadsInProgressIds([]);
  };
  useUploadsStatus({ ids: uploadsInProgressIds, onAllCompleted });

  const notifyUploadStarted = (files: File[]) =>
    setUploadStartedToastId(
      toast({
        message: t('fileBeingUploaded', { fileName: files[0]?.name, count: files.length }),
        options: { hideProgressBar: true },
      })
    );

  return {
    notifyUploadStarted,
    setUploadsInProgressIds,
  };
};
