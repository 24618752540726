import { BodyText, Button, ButtonAnchor, Headline, Link, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgreementDocument } from '../../../../../types/graphql';
import { agreementDocumentRoute } from '../../../../../utils/bridge/teacherRoutes';
import { mapGraphQLAgreementDocumentToDbAgreementDocument } from '../../../OrganizationAgreements/utils';

interface SignAgreementBlockProps {
  agreementId: string;
  secret: string;
  document: AgreementDocument;
  proposalId: string;
  enabled: boolean;
}

export const SignAgreementBlock: React.FC<SignAgreementBlockProps> = ({
  agreementId,
  secret,
  document,
  proposalId,
  enabled,
}) => {
  const { t } = useTranslation('agreementsPage');

  return (
    <SpaceBetween direction="y" spacing={spacing.size12}>
      <Headline as="h3" size="medium">
        {t('signAgreementTitle')}
      </Headline>
      <BodyText>{t('signAgreementDescription')}</BodyText>
      <ButtonWrapper>
        {enabled ? (
          <Link
            href={agreementDocumentRoute.href({
              agreementId,
              documentVersion: mapGraphQLAgreementDocumentToDbAgreementDocument(document),
              secret,
              proposalId,
              inframe: true,
            })}
            passHref
          >
            <ButtonAnchor>{t('signAgreementCTA')}</ButtonAnchor>
          </Link>
        ) : (
          <Button disabled>{t('signAgreementCTA')}</Button>
        )}
      </ButtonWrapper>
    </SpaceBetween>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  max-width: 30rem;
  a,
  button {
    width: 100%;
  }
`;
