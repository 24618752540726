import { VideoSettings } from '@lessonup/pins-shared';

export function parseVideoUrl(url: string): Pick<VideoSettings, 'source' | 'sourceId'> {
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})/;
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+(?:\/[\w-]+)?)/;

  const youtubeMatch = url.match(youtubeRegex);
  if (youtubeMatch) {
    return { source: 'YOUTUBE', sourceId: youtubeMatch[1] };
  }

  const vimeoMatch = url.match(vimeoRegex);
  if (vimeoMatch) {
    return { source: 'VIMEO', sourceId: vimeoMatch[1] };
  }

  return { source: undefined, sourceId: undefined };
}
