/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { getMeteorGlobal, MeteorError } from '../meteor/getMeteorGlobal';
import { assertNever } from '../typescript/assertNever';
import { mutable } from '../typescript/mutable';

export const appErrorCodes = [
  'realtime/pin-desync', // Used in setPinViewed when the client is behind on the server
  'entry/assignment-probably-deleted',
  'assignment/no-group-access',
  'not-authenticated',
  'authentication-expired',
  'invalid-params',
  'unexpected-data',
  'not-found',
  'closed',
  'not-allowed',
  'missing-config',
  'timed-out',
  'not-allowed/no-product',
  'SYS:GoogleLoginJoin', // special login errors
  'SYS:CanvasLoginJoin', // special login errors
  'SYS:PwLoginJoin',
  'SYS:EmailAlreadyExists',
  'vimeo:video-not-embeddable',
  'vimeo:video-not-found',
  'vimeo:unexpected',
  'deprecated',
  'server-unavailable',
  'unknown',
] as const;

export type AppErrorCode = (typeof appErrorCodes)[number];

export type FirebaseErrorCode =
  | 'permission-denied' // eg: security rules denied access to data
  | 'failed-precondition' // eg:  Multiple tabs open, persistence can only be enable in one tab at a a time.
  | 'unimplemented' // eg: no support for offline persistence;
  | FirebaseShouldRefreshTokenErrors
  | FirebaseShouldLogoutErrors;

const firebaseShouldRefreshTokenErrors = ['auth/id-token-expired', 'auth/argument-error'] as const;

type FirebaseShouldRefreshTokenErrors = (typeof firebaseShouldRefreshTokenErrors)[number];

const firebaseShouldLogoutErrors = ['auth/id-token-revoked', 'auth/session-cookie-revoked'] as const;

type FirebaseShouldLogoutErrors = (typeof firebaseShouldLogoutErrors)[number];

// workaround for babel error https://github.com/babel/babel/issues/8061
const ErrorClass = Error;

export type AppErrorStatusCodes = 200 | 400 | 401 | 403 | 404 | 408 | 409 | 412 | 500 | 501 | 503;

export class AppError extends ErrorClass {
  public readonly code: AppErrorCode;
  public readonly isAppError = true;
  public readonly details: any;

  public constructor(code: AppErrorCode, message?: string, details?: any) {
    super(message || code);
    this.name = `AppError(${code})`;
    this.code = code;
    this.details = details;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static isAppError(error: any): error is AppError {
    // We cannot instanceof here because AppErrors are passed from backend to frontend sometimes
    return typeof error === 'object' && (error as any).isAppError;
  }

  public static isError(error: Error, code: AppErrorCode | AppErrorCode[]): boolean {
    const codes = _.isArray(code) ? code : [code];
    return _.some(codes, (c) => errorHasCode(error, c));
  }

  public static detailsContain(error: Error, string: string): boolean {
    const details = (error as AppError).details;
    if (this.isMeteorClientError(error) && error.reason) {
      if (error.reason.includes(string)) return true;
    }
    if (typeof details === 'string' && details.includes(string)) {
      return true;
    }
    return false;
  }

  public static isFirebaseError(error: Error, code: FirebaseErrorCode): boolean {
    return errorHasCode(error, code);
  }

  public static isFirebaseShouldRefreshTokenError(error: Error): boolean {
    return errorHasCode(error, mutable(firebaseShouldRefreshTokenErrors));
  }

  public static isFirebaseShouldLogoutError(error: Error): boolean {
    return errorHasCode(error, mutable(firebaseShouldLogoutErrors));
  }

  public static mapErrorToHttpStatusCode(error: Error): AppErrorStatusCodes {
    const errorCode = AppError.code(error);

    if (!errorCode) {
      return 500;
    }

    try {
      switch (errorCode) {
        // The 'deprecated' error code should never be sent to the client.
        case 'deprecated':
          return 200;
        case 'invalid-params':
        case 'unexpected-data':
        case 'auth/argument-error':
        case 'vimeo:video-not-embeddable':
        case 'vimeo:video-not-found':
          return 400;
        case 'not-authenticated':
        case 'authentication-expired':
        case 'auth/id-token-expired':
        case 'auth/id-token-revoked':
        case 'auth/session-cookie-revoked':
          return 401;
        case 'closed':
        case 'not-allowed':
        case 'assignment/no-group-access':
        case 'not-allowed/no-product':
        case 'SYS:GoogleLoginJoin':
        case 'SYS:CanvasLoginJoin':
        case 'SYS:PwLoginJoin':
        case 'SYS:EmailAlreadyExists':
        case 'permission-denied':
          return 403;
        case 'not-found':
        case 'entry/assignment-probably-deleted':
          return 404;
        case 'timed-out':
          return 408;
        case 'realtime/pin-desync':
          return 409;
        case 'failed-precondition':
          return 412;
        case 'missing-config':
        case 'unimplemented':
          return 501;
        case 'vimeo:unexpected':
        case 'unknown':
          return 500;
        case 'server-unavailable':
          return 503;
        default:
          assertNever(errorCode, `unrecognized error code ${errorCode}`);
      }
    } catch {
      return 500;
    }
  }

  // the Meteor client reverts all non Meteor.Error to generic 500 errors
  // use this if you need the meteor client to receive the error
  // only works in the meteor context
  // returns the error unchanged if otherwise
  public transformToMeteorError() {
    const error = this as AppError;
    const Meteor = getMeteorGlobal();
    if (!Meteor) return error;
    if (error.code) {
      const details = {
        stack: error.stack,
        ...error.details,
      };
      return new Meteor.Error(error.code, error.message, details);
    } else {
      return error;
    }
  }

  public static isMeteorClientError(error: Error): error is MeteorError {
    return !!(error as MeteorError)['errorType'] && (error as MeteorError)['errorType'] === 'Meteor.Error';
  }

  // Meteor.error uses the error property. Firestore and AppError use the code property
  public static code(error: Error): AppErrorCode | FirebaseErrorCode | undefined {
    return _.get(error, 'code') || _.get(error, 'error');
  }
}

function errorHasCode(error: Error, code: string | string[]): boolean {
  const errorCode = AppError.code(error);
  if (!errorCode || _.isEmpty(code)) {
    return false;
  }
  const codes = Array.isArray(code) ? code : [code];
  return codes.includes(errorCode);
}
