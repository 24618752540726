import { isQuizPinAnswerText, toJSONContent } from '@lessonup/pins-shared';
import { Card } from '@lessonup/ui-components';
import React from 'react';
import { QuizPinAnswerCardStaticContent } from '../../../../pins/QuizPin/components/QuizPinAnswerCardStaticContent/QuizPinAnswerCardStaticContent';
import { QuizPinAnswersSection } from '../../../../pins/QuizPin/components/QuizPinAnswerSection/QuizPinAnswersSection';
import { QuizPinComponent, QuizPinProps } from '../../../../pins/QuizPin/QuizPin';
import { TiptapViewer } from '../../../Tiptap/TipTapViewer';

export const QuizPinStatic: React.FC<QuizPinProps> = (props) => {
  const answers = props.pinSettings.answers;

  return (
    <QuizPinComponent pinSettings={props.pinSettings} isThumb={props.isThumb}>
      {props.children}
      <QuizPinAnswersSection pointerEvents="none">
        {answers.map((answer, index) => {
          let answerContent;
          if (isQuizPinAnswerText(answer)) {
            answerContent = typeof answer.text === 'string' ? toJSONContent(answer.text) : answer.text;
            if (answerContent === null) {
              console.log('Error parsing JSON content', answer.text);
              return null;
            }
          }

          return (
            <Card key={`${index}-${answer.id}`} cardTheme="neutral" resizing="fixed" showBorder={false} showBoxShadow>
              <QuizPinAnswerCardStaticContent index={index} answerStatus="neutral">
                {isQuizPinAnswerText(answer) && <TiptapViewer content={answerContent}></TiptapViewer>}
              </QuizPinAnswerCardStaticContent>
            </Card>
          );
        })}
      </QuizPinAnswersSection>
    </QuizPinComponent>
  );
};
