import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSun = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.322 12-1.493-.013m-1.228-6.57-1.065 1.047M5.399 5.417l1.065 1.047m5.575-3.786.013 1.493m-7.881 7.83-1.493-.014m3.786 5.55-1.065 1.046m12.137-1.047 1.065 1.047m-6.549 1.246.013 1.493m-.013-4.902a4.42 4.42 0 1 0 0-8.84 4.42 4.42 0 0 0 0 8.84"
    />
  </svg>
);
export default SvgIconSun;
