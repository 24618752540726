import { minimumVideoComponentSize, VideoSettings } from '@lessonup/pins-shared';
import { color, css, styled } from '@lessonup/ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { getThumbnailUrl } from '../../utils/pinComponents/videoPinComponent.utils';
import { VideoComponentPlaceholder } from './VideoPinComponentPlaceholder';
import { VideoPlayerComponent } from './VideoPlayer/VideoPlayerComponent';

export interface VideoPinComponentProps {
  settings: VideoSettings;
  componentId: string;
  isThumb?: boolean;
  scale?: number;
  width: number;
  height: number;
  editable: boolean;
  active: boolean;
}

export const VideoPinComponent: React.FC<VideoPinComponentProps> = ({
  settings,
  componentId,
  isThumb = false,
  scale = 1,
  width,
  height,
  editable,
  active,
}) => {
  const isDraggingRef = useRef(false);
  const detailMode = useBoolean(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [thumbUrl, setThumbUrl] = useState<string | undefined>(undefined);
  const invertedScale = 1 / scale;
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;

  useEffect(() => {
    const fetchThumbnail = async () => {
      if (settings.source && settings.sourceId) {
        const url = await getThumbnailUrl(settings);
        setThumbUrl(url);
      }
    };

    fetchThumbnail();
  }, [settings]);

  const handlePointerDown = () => {
    if (active && editable && !detailMode.value) {
      isDraggingRef.current = false;
    }
  };

  const handlePointerUp = () => {
    if (!active || !editable) return;

    if (!detailMode.value && !isDraggingRef.current) {
      detailMode.setTrue();
    }

    isDraggingRef.current = false;
  };

  const handlePointerMove = () => {
    if (!active || !editable || detailMode.value) return;
    isDraggingRef.current = true;
  };

  useEffect(() => {
    if (!active) {
      detailMode.setFalse();
      isDraggingRef.current = false;
    }
  }, [active, detailMode]);

  useEffect(() => {
    if (!editable) {
      detailMode.setTrue();
    }
  }, [editable, detailMode]);

  if (!settings.source || !settings.sourceId) {
    return <VideoComponentPlaceholder />;
  }

  return (
    <StyledVideoComponent
      ref={componentRef}
      style={{ backgroundImage: `url(${thumbUrl})` }}
      onMouseDown={editable ? handlePointerDown : undefined}
      onTouchStart={editable ? handlePointerDown : undefined}
      onMouseMove={editable ? handlePointerMove : undefined}
      onTouchMove={editable ? handlePointerMove : undefined}
      onMouseUp={editable ? handlePointerUp : undefined}
      onTouchEnd={editable ? handlePointerUp : undefined}
      detailMode={detailMode.value}
      editable={editable}
      active={active}
    >
      {!isThumb && (
        <ScaledPlayerWrapper
          style={{
            transform: `scale(${invertedScale})`,
            width: scaledWidth,
            height: scaledHeight,
            transformOrigin: 'top left',
          }}
        >
          <PlayerContainer detailMode={detailMode.value} key={`${settings.source}-${settings.sourceId}`}>
            <VideoPlayerComponent
              source={settings.source}
              sourceId={settings.sourceId}
              compId={componentId}
              startTime={settings.startTime}
              endTime={settings.endTime}
              scaledWidth={scaledWidth}
              scaledHeight={scaledHeight}
            />
          </PlayerContainer>
        </ScaledPlayerWrapper>
      )}
    </StyledVideoComponent>
  );
};

const StyledVideoComponent = styled.div<{ detailMode: boolean; editable: boolean; active: boolean }>`
  width: 100%;
  height: 100%;
  min-width: ${minimumVideoComponentSize.width}px;
  min-height: ${minimumVideoComponentSize.height}px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  user-select: none;

  ${(props) =>
    props.detailMode &&
    props.editable &&
    css`
      outline: 2.5px solid ${color.accent.secondary.background};
    `}
`;

const ScaledPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const PlayerContainer = styled.div<{ detailMode: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${color.primitives.black};
  position: absolute;
  top: 0;
  left: 0;

  .player-container {
    width: 100%;
    height: 100%;
    pointer-events: ${(props) => (props.detailMode ? 'auto' : 'none')};
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
`;
