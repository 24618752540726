import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type * as CSS from 'csstype';
import { compact } from 'lodash';
import React, { LiHTMLAttributes, ReactNode } from 'react';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { Grid } from '../../../utils/Grid/Grid';
import { Link } from '../../../utils/Link/Link';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../BodyText/BodyText';
import { ListHeaderType } from '../../ListHeader/ListHeader';

export type ListItemSize = 'small' | 'medium';
export type ListItemType = 'default' | 'active' | 'destructive';
export interface ListItemInvisibleButtonProps {
  as?: keyof React.JSX.IntrinsicElements;
  htmlFor?: string;
  ref?: React.Ref<HTMLButtonElement>;
}
export interface ListItemProps extends LiHTMLAttributes<HTMLLIElement> {
  text: string;
  disabled?: boolean;
  roundedCorners?: boolean;
  endSlot?: React.JSX.Element;
  size?: ListItemSize;
  type?: ListItemType;
  indentation?: boolean;
  divider?: boolean;
  onClick?: () => void;
  startSlot?: React.JSX.Element;
  icon?: React.JSX.Element;
  buttonProps?: ListItemInvisibleButtonProps;
  linkProps?: Omit<UIComponents.LinkProps, 'children'>;
  secondLine?: string;
  orderedList?: boolean;
  href?: string;
  limitToParentWidth?: boolean;
  key?: string;
  listStyle?: CSS.Property.ListStyleType;
  header?: ListHeaderType;
  children?: ReactNode;
}

export function ListItem(props: ListItemProps) {
  const frontIcon = props.icon && <StyledIconSpan>{props.icon}</StyledIconSpan>;
  const startSlot = props.startSlot ? <StyledStartSlot>{props.startSlot}</StyledStartSlot> : (frontIcon ?? null);
  const secondLine = props.secondLine && <StyledSecondLine size="small">{props.secondLine}</StyledSecondLine>;
  const endSlot = props.endSlot && <StyledEndSlot>{props.endSlot}</StyledEndSlot>;
  const cols = compact([startSlot, props.text]).length;
  const ListItemContent = (
    <SpaceBetween direction="x" spacing={spacing.size8} justifyContent="space-between">
      <LeftPartWrapper cols={cols} autoColumns="minmax(auto, max-content)">
        {startSlot}
        <StyledBodyWrapper limitToParentWidth={props.limitToParentWidth}>
          <StyledBodyText size="medium" limitToParentWidth={props.limitToParentWidth}>
            {props.text}
          </StyledBodyText>
        </StyledBodyWrapper>
        {!!startSlot && <div></div>}
        {secondLine}
      </LeftPartWrapper>
      {endSlot}
    </SpaceBetween>
  );

  if (props.linkProps?.href) {
    return (
      <StyledListItem {...props} onClick={props.onClick} hasInteractiveElement={true} disabled={!!props.disabled}>
        <Link {...props.linkProps}>
          <a href={props.linkProps.href}>{ListItemContent}</a>
        </Link>
      </StyledListItem>
    );
  }

  if (props.onClick) {
    return (
      <StyledListItem {...props} onClick={undefined} hasInteractiveElement={!!props.onClick}>
        <InvisibleButton
          {...props.buttonProps}
          onClick={props.onClick}
          buttonSize={props.size}
          disabled={!!props.disabled}
        >
          {ListItemContent}
        </InvisibleButton>
      </StyledListItem>
    );
  }

  return <StyledListItem {...props}>{ListItemContent}</StyledListItem>;
}

const LeftPartWrapper = styled(Grid)`
  grid-gap: 0 ${spacing.size8};
`;

const StyledIconSpan = styled.div`
  height: ${spacing.size24};
  display: flex;
  align-self: start;
`;

const StyledSecondLine = styled(BodyText)`
  color: ${color.additional.disabled.text};
`;

const StyledStartSlot = styled.span`
  display: flex;
`;

const StyledEndSlot = styled.div`
  display: flex;
  align-self: center;
  margin-left: auto !important !important;
`;

interface hasInteractiveElement {
  hasInteractiveElement?: boolean;
}

const StyledListItem = styled.li<ListItemProps & hasInteractiveElement>`
  border: 2px solid transparent;

  a {
    text-decoration: none;
  }

  ${(props) => {
    return listItemHeight(props.size);
  }}

  ${(props) =>
    props.roundedCorners &&
    css`
      border-radius: ${borderRadius.rounded4};
    `}
  ${(props) => {
    switch (props.type) {
      case `active`:
        return css`
          color: ${color.accent.secondaryContainer.text};
          background-color: ${props.disabled
            ? color.additional.disabled.background
            : color.accent.secondaryContainer.background};
        `;
      case `destructive`:
        return css`
          color: ${color.additional.errorContainer.text};
        `;
      default:
        return;
    }
  }}
  ${(props) =>
    props.orderedList
      ? css`
          list-style: ${props.listStyle || 'inherit'};
          margin-left: ${spacing.size16};
          padding-left: ${spacing.size4};
        `
      : css`
          list-style: ${props.listStyle || 'none'};
        `}

  ${(props) =>
    props.indentation &&
    css`
      padding-left: ${spacing.size16};
      padding-right: ${spacing.size16};
    `}
  ${(props) =>
    props.divider &&
    css`
      border-bottom: 1px solid ${color.neutral.outline.background};
    `}
  align-items: center;
  display: list-item;

  ${(props) =>
    props.hasInteractiveElement &&
    !props.disabled &&
    css`
      cursor: pointer;
      :hover {
        color: ${props.type === 'destructive' ? color.additional.errorContainer.text : color.neutral.surface.text};
        background-color: ${props.type === 'destructive'
          ? color.additional.errorContainer.background
          : color.neutral.surface.hover};
      }
    `}

  svg {
    align-self: center;
    height: ${spacing.size16};
    width: ${spacing.size16};
    ${(props) =>
      props.disabled &&
      css`
        color: ${color.additional.disabled.text};
      `}
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${color.additional.disabled.text};
      cursor: normal;
      pointer-events: none;
    `}
  p {
    margin: 0;
  }

  /**
   * This focus-visible exists in order to make it so that
   * the styled item is the only one with a style and children don't get outlined.
   * 
   * Also a border is being used instead of an outline because part of the
   * outline will get burried under the sibling items.
   */
  &:focus-visible {
    border: 2px solid ${color.additional.focus.background};
    outline: none;

    & > * {
      outline: none;
    }
  }
`;

interface ButtonProps {
  buttonSize?: ListItemSize;
}

const InvisibleButton = styled.button<ButtonProps>`
  padding-left: 0;
  padding-right: 0;
  text-align: inherit;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0;
  display: inherit;
  width: 100%;
  height: 100%;
`;

const listItemHeight = (size?: ListItemSize) => {
  if (size === 'small') {
    return css`
      padding: ${spacing.size2} 0;
    `;
  }
  return css`
    padding: calc(${spacing.size8} - ${spacing.size2}) 0;
  `;
};

const StyledBodyWrapper = styled.div<Pick<ListItemProps, 'limitToParentWidth'>>`
  display: flex;
  flex-direction: column;
  align-items: left;
  ${({ limitToParentWidth }) =>
    limitToParentWidth &&
    css`
      flex-shrink: 1;
      white-space: nowrap;
      overflow: hidden;
    `}
`;

const StyledBodyText = styled(BodyText)<Pick<ListItemProps, 'limitToParentWidth'>>`
  ${({ limitToParentWidth }) =>
    limitToParentWidth &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;
