import { tipTapBaseExtensions } from '@lessonup/pins-shared';
import { generateHTML } from '@tiptap/html';
import { JSONContent } from '@tiptap/react';
import React, { useMemo } from 'react';

interface TiptapViewerProps {
  content?: JSONContent | null;
}

// proseMirror adds a <br> to empty paragraphs in the editor and teachers expect the same behavior in the viewer
function addBreaksToEmptyParagraphs(html: string): string {
  return html.replace(/<p><\/p>/g, '<p><br></p>');
}

export const TiptapViewer: React.FC<TiptapViewerProps> = ({ content }) => {
  const output = useMemo(() => {
    const html = content ? generateHTML(content, tipTapBaseExtensions) : '<p></p>';
    return addBreaksToEmptyParagraphs(html);
  }, [content]);

  return <div dangerouslySetInnerHTML={{ __html: output }} />;
};
