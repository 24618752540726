import { bind, Binder, getDataRoot } from '@lessonup/editor-shared';
import { YjsPlayer } from '@lessonup/players-modern';
import { Doc } from 'yjs';
import { createYjsContext, HocusPocusContextProps, YJSBinderConstructor } from '../YjsContext';

export type PlayerBinders = {
  data: Binder<YjsPlayer>;
};

const createBinders: YJSBinderConstructor<PlayerBinders> = (yDoc: Doc) => {
  const data = bind<YjsPlayer>(getDataRoot(yDoc));
  return [
    { data },
    () => {
      data.unbind();
    },
  ];
};

const [Provider, usePlayerYjs] = createYjsContext<PlayerBinders>(createBinders);

export const PlayerYjsContextProvider: React.FC<HocusPocusContextProps> = Provider;

export { usePlayerYjs };
