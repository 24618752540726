import { getLocale } from '@lessonup/client-integration';
import { Banner, BodyText, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { helpArticleLink } from '../../../../../utils/bridge/helpArticleLink';

export const UnderContructionBanner: React.FC = () => {
  const { t } = useTranslation('createLessonModal');
  const locale = getLocale().language;
  const editorHelpArticleLink = helpArticleLink(locale, 'newEditorExplained');

  return (
    <Banner
      heading={t('banner.title')}
      priority="medium"
      paragraph={
        <SpaceBetween direction="y" spacing={spacing.size8}>
          <div>
            <BodyText size="medium">{t('banner.firstMessage')}</BodyText>
            <StyledList>
              <li>
                <BodyText size="medium">{t('banner.listItem1')}</BodyText>
              </li>
              <li>
                <BodyText size="medium">{t('banner.listItem2')}</BodyText>
              </li>
              <li>
                <BodyText size="medium">{t('banner.listItem3')}</BodyText>
              </li>
            </StyledList>
          </div>

          <BodyText size="medium">
            {t('banner.footerText')}{' '}
            <StyledLink href={editorHelpArticleLink} target="_blank">
              {t('banner.footerLink')}
            </StyledLink>
          </BodyText>
        </SpaceBetween>
      }
    ></Banner>
  );
};

const StyledList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding-left: ${spacing.size16};
`;

const StyledLink = styled.a`
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
`;
