import { SymbolDefinition } from '../SymbolComponent';

const NAME = 'circle';
export interface CircleSymbol extends SymbolDefinition {
  name: typeof NAME;
}

export const circleSymbol: CircleSymbol = {
  name: NAME,
  lockedAspectRatio: false,
  startWithFixedAspectRatio: true,
  hasStrokeSettings: true,
};
