export const Apps = [
  'teacher',
  'teacher-next',
  'student',
  'student-next',
  'admin',
  'admin-next',
  'search',
  'workers',
  'thumbnailer',
  'ui',
  'api',
  'api-media',
  'actionspipeline',
  'data-sync',
  'actions-importer',
] as const;

export type App = (typeof Apps)[number];

export function checkMeteorApp(app: App) {
  const isMeteor: App[] = ['admin', 'teacher', 'student'];
  return isMeteor.includes(app);
}

export const Environments = [
  'production',
  'live',
  'staging',
  'test',
  'dev',
  'team-a',
  'team-b',
  'team-c',
  'local',
] as const;

export type Environment = (typeof Environments)[number];
