import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.375 6.105c-1.701-.642-2.661-.9-4.38-1.173-.621 1.625-.879 2.585-1.173 4.38M8.555 3.634a8.573 8.573 0 1 0 9.583 3.122l-1.133-1.816m-5.889 3.77-.03 3.35-2.098.916"
    />
  </svg>
);
export default SvgIconHistory;
