import { isEqual } from 'lodash';
import { logger } from '../logger/logger';
import { AnonymousAuth, AuthStatus, getAuth, LoggedInAuth } from './auth';
import { authTokenStorage } from './authTokenStorage';
import { getFirebaseIdToken } from './refreshTokensHelper';
import { fetchRefreshFirebaseTokens } from './rest/fetchRefreshFirebaseTokens';
import { fetchRefreshMeteorTokens, RefreshTokensPayload } from './rest/fetchRefreshMeteorTokens';

type CacheKey = [meteorToken: string, userId: string] | [firebaseIdToken: string];

interface RefreshTokenFunc {
  /** cache key which will be used to validate if a already pending promise will be reused instead of performing a new fetch */
  cacheKey: CacheKey;
  refresh: () => Promise<RefreshTokensPayload>;
}

let pendingRefreshPromise: Promise<void> | undefined;
let pendingRefreshInput: CacheKey | undefined;

/**
 * function that fetches new refresh tokens are returns an already pending promise to prevent multiple requests to fire at once
 */
export async function refreshTokens(): Promise<void> {
  const auth = getAuth();
  if (auth.type === AuthStatus.LOGGED_OUT) {
    throw new Error(`refreshTokens: can't refresh tokens for logged-out users`);
  }
  const { refresh, cacheKey } = await refreshFunctionForAuth(auth);

  // if we already have a pending promise, and that promise was mode for the same auth, then we want to reuse that one
  if (pendingRefreshPromise && isEqual(pendingRefreshInput, cacheKey)) {
    return pendingRefreshPromise;
  }

  pendingRefreshInput = cacheKey;
  pendingRefreshPromise = refresh()
    .then(({ accessToken }) => {
      authTokenStorage.authAccessToken = accessToken;
      authTokenStorage.authUserId = auth.userId; // we want to store for which user we created the access token for
    })
    .catch((error: Error) => {
      logger.error('Error refreshing the authentication tokens', { error });
    })
    .finally(() => {
      pendingRefreshPromise = undefined;
      pendingRefreshInput = undefined;
    });

  return pendingRefreshPromise;
}

async function refreshFunctionForAuth(auth: LoggedInAuth | AnonymousAuth): Promise<RefreshTokenFunc> {
  let meteorToken: string;
  let userId: string;
  let firebaseIdToken: string;
  switch (auth.type) {
    case AuthStatus.LOGGED_IN:
      meteorToken = authTokenStorage.meteorLoginToken ?? '';
      userId = authTokenStorage.meteorUserId ?? '';

      if (!meteorToken || !userId) {
        logger.info(`no meteor credentials, can't fetch a new token`);
        throw new Error(`no meteor credentials, can't fetch a new token`);
      }
      return {
        cacheKey: [meteorToken, userId],
        refresh: () => fetchRefreshMeteorTokens(userId, meteorToken),
      };
    case AuthStatus.ANONYMOUS:
      firebaseIdToken = await getFirebaseIdToken();

      return {
        cacheKey: [firebaseIdToken],
        refresh: () => fetchRefreshFirebaseTokens(firebaseIdToken),
      };
  }
}
