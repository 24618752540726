import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconNotificationOn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.016 20.187a2.25 2.25 0 0 0 3.968 0M7.894 4.326a5.807 5.807 0 0 1 9.913 4.106c0 .907.15 1.758.375 2.63q.095.294.207.558c.348.819 1.306 1.098 2.021 1.627 1.068.79.861 2.48-.026 3.137 0 0-1.434 1.241-8.384 1.241s-8.383-1.24-8.383-1.24c-.888-.657-1.094-2.348-.027-3.138.715-.53 1.673-.808 2.021-1.627.347-.816.582-1.86.582-3.188 0-1.54.612-3.017 1.7-4.106"
    />
  </svg>
);
export default SvgIconNotificationOn;
