import { useLocale } from '@lessonup/client-integration';
import { Button, IconHelp, styled } from '@lessonup/ui-components';
import React from 'react';
import { helpCenterLink } from '../../../utils/bridge/helpArticleLink';

export const HelpButton: React.FC = () => {
  const language = useLocale().language;

  return (
    <StyledAnchor href={helpCenterLink(language)} target="_blank" rel="noreferrer">
      <Button buttonType="secondary" iconStart={<IconHelp />}>
        {'Help'}
      </Button>
    </StyledAnchor>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
`;
