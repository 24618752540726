/**
 * @param imageUrl App Engine blob storage url, like http://lhx.ggpht.com/randomStringImageId
 * @param imageSize use an integer from 0–2560 representing the length, in pixels, of the image's longest side.
 * @param crop the image based on the imageSize
 * @description Resize or crop an image by updating the imageUrl
 */
export const transformImageUrl = (imageUrl: string, imageSize: number, crop?: boolean): string => {
  // Docs: https://cloud.google.com/appengine/docs/legacy/standard/java/images#transforming-images
  return `${imageUrl}=s${imageSize}${crop ? '-c' : ''}`;
};

interface ImageSizingOptions {
  url: string;
  size?: 's' | 'm' | 'l' | 'xl';
  isThumb?: boolean;
  forceWidth?: number;
}

export function addImageSizing({ url, size = 'l', isThumb = false, forceWidth }: ImageSizingOptions): string {
  if (!url) return url;

  if (url.includes('googleusercontent.')) {
    const sizeMapping = { s: 320, m: 640, l: 1280, xl: 1920 };
    let s = sizeMapping[size];

    if (forceWidth) {
      s = Math.floor(forceWidth * (window.devicePixelRatio || 1));
    } else if (isThumb) {
      s = Math.floor(250 * (window.devicePixelRatio || 1));
    }

    url += `=s${Math.min(s, 1920)}`;
  }

  return url;
}
