import { Divider } from '@lessonup/ui-components';
import React from 'react';
import { SetActivePinId } from '../../../context/EditorContext/EditorContext.types';
import { isFirstPhase } from '../../../utils/learningPhases/LearningPhase';
import { Phase } from '../model';
import { phaseBefore } from '../PinRail.utils';
import { PinRailAdd } from './PinRailAdd';
import { PinRailHeader } from './PinRailHeader';

interface PhaseTagProps {
  phase: Phase;
  setActivePinId: SetActivePinId;
  lastPinId: string;
}

/**
 * Start and end of PinRailPhase
 * Pin rail phase is not a separate component (like in figma)
 * because it would make dragging pins more complicated
 */
export const PinRailPhaseStartAndEnd: React.FC<PhaseTagProps> = ({ phase, setActivePinId, lastPinId }) => {
  // First phase is not draggable
  if (isFirstPhase(phase.number)) {
    return <PinRailHeader phaseName={phase.id} />;
  }

  const style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };

  const phaseForButton = phaseBefore(phase);

  return (
    <div style={style}>
      <PinRailAdd phaseNumber={phaseForButton.number} setActivePinId={setActivePinId} lastPinId={lastPinId} />
      <Divider orientation="horizontal" />
      <PinRailHeader phaseName={phase.id} />
    </div>
  );
};
