import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconMoreHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.708 14c1.28 0 2-.72 2-2s-.72-2-2-2-2 .72-2 2 .72 2 2 2M12 14c1.28 0 2-.72 2-2s-.72-2-2-2-2 .72-2 2 .72 2 2 2M18.292 14c1.28 0 2-.72 2-2s-.72-2-2-2-2 .72-2 2 .72 2 2 2"
    />
  </svg>
);
export default SvgIconMoreHorizontal;
