import { Box } from '@lessonup/pin-renderer';
import { styled } from '@lessonup/ui-components';
import React from 'react';

interface SelectionBoxProps {
  box: Box;
  scale: number;
}

export const SelectionBox: React.FC<SelectionBoxProps> = ({ box, scale }) => {
  return (
    <StyledSelectionBox
      style={{
        width: box.size.width * scale,
        height: box.size.height * scale,
        transform: `translate(${box.position.x * scale}px, ${box.position.y * scale}px)  rotate(${box.rotation}deg)`,
      }}
    ></StyledSelectionBox>
  );
};

const StyledSelectionBox = styled.div`
  position: absolute;
  outline: 1px dashed gray;
`;
