import { useUpdatePin } from '@lessonup/pin-renderer';
import { isMarkerType, LinePinComponent, MarkerType } from '@lessonup/pins-shared';
import { SelectFormField, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { useEditorTranslation } from '../../../../../../hooks/useEditorTranslation';
import { createHandleLineTypeChange } from '../../../../../../utils/pinComponentSettings/linePinComponentSettings';

interface LinePointsSettingsProps {
  pinComponent: LinePinComponent;
}

export const LinePointsSettings: React.FC<LinePointsSettingsProps> = ({ pinComponent }) => {
  const dispatch = useUpdatePin();
  const { t } = useEditorTranslation();
  const handleLineTypeChange = createHandleLineTypeChange(pinComponent.id, dispatch);
  const startCurrentValue = pinComponent.settings.headMarker ?? 'NONE';
  const endCurrentValue = pinComponent.settings.tailMarker ?? 'NONE';

  const onChangeMarkerType = ({ headMarker, tailMarker }: { headMarker?: string; tailMarker?: string }) => {
    if (
      (headMarker === undefined || isMarkerType(headMarker)) &&
      (tailMarker === undefined || isMarkerType(tailMarker))
    ) {
      handleLineTypeChange({
        headMarker: headMarker ?? startCurrentValue,
        tailMarker: tailMarker ?? endCurrentValue,
      });
    }
  };

  const markerOptions: { label: string; value: MarkerType }[] = [
    { label: t('contextSidebar.settings.line.linePoints.markerTypeLabels.none'), value: 'NONE' },
    { label: t('contextSidebar.settings.line.linePoints.markerTypeLabels.arrow'), value: 'ARROW' },
  ];

  return (
    <SpaceBetween direction="x" spacing={spacing.size8}>
      <StyledSelectFormField
        label={t('contextSidebar.settings.line.linePoints.startPoint')}
        options={markerOptions}
        onChange={(e) => onChangeMarkerType({ headMarker: e.currentTarget.value })}
        value={startCurrentValue}
        formFieldSize="small"
      />
      <StyledSelectFormField
        label={t('contextSidebar.settings.line.linePoints.endPoint')}
        options={markerOptions}
        onChange={(e) => onChangeMarkerType({ tailMarker: e.currentTarget.value })}
        value={endCurrentValue}
        formFieldSize="small"
      />
    </SpaceBetween>
  );
};

const StyledSelectFormField = styled(SelectFormField)`
  width: 50%;
`;
