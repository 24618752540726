import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../../../../foundations/colors/colors';
import { spacing } from '../../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../../utils/SpaceBetween/SpaceBetween';
import { BodyText } from '../../../BodyText/BodyText';
import { Button, ButtonProps } from '../../../Button/Button';
import { Divider } from '../../../Divider/Divider';
import { IconArrowLeft, IconClose, IconOpenInNew, IconRocket } from '../../../icons';
import { Tab, TabProps } from '../../../TabList/Tab';
import { TabList } from '../../../TabList/TabList';
import { modalI18NNS } from '../../ManagedModal.utils';

export type ModalHeaderOverflow = 'wrap' | 'ellipsis';

export type TabItemProps = TabProps & {
  onClick: () => void;
  content: string | React.JSX.Element;
};
export interface ModalHeaderProps {
  title?: {
    size?: 'S' | 'M';
    content: string | React.JSX.Element;
  };
  type: 'headline' | 'external' | 'breadcrumb';
  overflow?: ModalHeaderOverflow;
  showDivider?: boolean;
  tabList?: TabItemProps[];
  onBackButtonClick?: ButtonProps['onClick'];
  onCloseButtonClick?: ButtonProps['onClick'];
  onLessonUpClick?: ButtonProps['onClick'];
  onLoginClick?: ButtonProps['onClick'];
  endSlot?: React.JSX.Element;
}

export function ModalHeaderV1(props: ModalHeaderProps) {
  const {
    title,
    type,
    overflow,
    showDivider = true,
    onBackButtonClick,
    onCloseButtonClick,
    onLessonUpClick,
    onLoginClick,
    tabList,
    endSlot,
  } = props;
  const { t } = useTranslation(modalI18NNS);

  return (
    <StyledModalHeader type={type}>
      <StyledModalHeaderContent direction="y" spacing={spacing.size8}>
        {onBackButtonClick && (
          <StyledButton
            onClick={onBackButtonClick}
            buttonType="neutral"
            size="small"
            aria-label={t('back')}
            iconStart={<IconArrowLeft />}
          >
            {t('back')}
          </StyledButton>
        )}
        {title && (
          <StyledModalHeaderBody direction="x" spacing={spacing.size8}>
            <StyledSpaceBetween direction="x" spacing={spacing.size0}>
              {type === 'external' && (
                <Button
                  onClick={onLessonUpClick}
                  buttonType="neutral"
                  size="small"
                  aria-label={t('back')}
                  iconStart={<IconRocket />}
                >
                  {t('lessonup')}
                </Button>
              )}
              {type === 'headline' ? (
                <StyledText
                  size={title?.size && title.size === 'S' ? 'small' : 'medium'}
                  weight="bold"
                  ellipsis={overflow === 'ellipsis' ? true : false}
                  as="p"
                >
                  {title?.content}
                </StyledText>
              ) : (
                title?.content
              )}
            </StyledSpaceBetween>
            {endSlot}
            {onCloseButtonClick && (
              <Button
                onClick={type === 'external' ? onLoginClick : onCloseButtonClick}
                buttonType="neutral"
                size="small"
                aria-label={t('close')}
                iconEnd={type === 'external' ? <IconOpenInNew /> : <IconClose />}
              >
                {type === 'external' ? t('login') : null}
              </Button>
            )}
          </StyledModalHeaderBody>
        )}
        {tabList && (
          <StyledModalHeaderTabList>
            <TabList resizing="fixed">
              {tabList.map((tab, index) => (
                <Tab
                  as="button"
                  onClick={tab.onClick}
                  isActive={tab.isActive}
                  key={'tab_' + index}
                  disabled={tab.disabled}
                  content={tab.content}
                />
              ))}
            </TabList>
          </StyledModalHeaderTabList>
        )}
      </StyledModalHeaderContent>
      {showDivider && <Divider orientation="horizontal" noSpacer />}
    </StyledModalHeader>
  );
}

export const StyledModalHeader = styled.header<Pick<ModalHeaderProps, 'type'>>`
  ${({ type }) => {
    switch (type) {
      case 'external':
        return css`
          background: 'transparent';
        `;
      default:
        return css`
          background: ${color.neutral.surface.background};
        `;
    }
  }}
`;

const StyledModalHeaderContent = styled(SpaceBetween)`
  color: ${color.neutral.surface.text};
  display: flex;
  align-items: start;
  padding: ${spacing.size8} ${spacing.size16};
`;

const StyledModalHeaderBody = styled(SpaceBetween)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  width: 100%;
  display: flex;
`;

const StyledModalHeaderTabList = styled.div`
  width: 100%;
  padding: ${spacing.size8} 0;
`;

const StyledText = styled(BodyText)`
  flex: 1;
`;

const StyledButton = styled(Button)`
  padding: 0 ${spacing.size16};
`;
