import React, { Component, ErrorInfo, ReactNode } from 'react';
import { logger } from '../logger/logger';

interface Props {
  children: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
}

//source: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Uncaught react error:', {
      error,
      ...errorInfo,
    });
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback || <h1>{'Error appeared'}</h1>;
    }

    return this.props.children;
  }
}
