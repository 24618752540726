/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';

/**
 * Recursively removes all undefined properties
 */
export function omitDeepUndefined<T>(value: T): T {
  return omitDeep(value, [undefined]);
}

export function omitUndefined<T extends object>(value: T): T {
  return _.pickBy(value, (v) => v !== undefined) as T;
}
/**
 * Recursively removes all undefined properties
 */
export function omitDeepNil<T>(value: T): T {
  return omitDeep(value, [undefined, null]);
}

export function omitDeep<T>(value: T, omittedValues: any[]): T {
  if (_.isArray(value)) {
    const newArrayValues = _.map(value, (v) => omitDeep(v, omittedValues));
    return _.without(newArrayValues, ...omittedValues) as any;
  }

  if (_.isObject(value)) {
    const omittedValue = _.mapValues(value as any, (v) => omitDeep(v, omittedValues));
    return _.omitBy(omittedValue, (v) => omittedValues.includes(v)) as any;
  }

  return value;
}

export function cloneDeepWithOptions(
  obj: any,
  options: {
    replaceDateWithTimestamps?: boolean;
  }
) {
  return _.cloneDeepWith(obj, (value) => {
    if (options.replaceDateWithTimestamps && _.isDate(value)) {
      return value.getTime();
    }
    return undefined;
  });
}

export function itemAfter<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
  const index = _.findIndex(array, predicate);
  return array[index + 1];
}

export function itemBefore<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
  const index = _.findIndex(array, predicate);
  return array[index - 1];
}

export function insertAt<T>(array: T[], value: T, index: number): T[] {
  array.splice(index, 0, value);
  return array;
}

export function mostEncounteredValue(values: string[]): string | undefined {
  const grouped = _.groupBy(values);
  const pairs = _.toPairs(grouped);
  const sorted = _.sortBy(pairs, (pair) => pair[1].length);
  const pair = _.last(sorted);
  return pair && pair[0];
}

/** maps and removes all nulls and undefineds from the array */
export function compactMap<T, TResult>(
  collection: T[] | null | undefined,
  iteratee: (item: T) => TResult | undefined | null
): TResult[] {
  return _.compact(_.map(collection, iteratee));
}
