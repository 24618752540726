import styled from '@emotion/styled';
import React, { AnchorHTMLAttributes } from 'react';
import { breakpoints } from '../../../foundations/layout/breakpoints';
import { spacing } from '../../../foundations/spacing/spacing';
import { SpaceBetween } from '../../../utils/SpaceBetween/SpaceBetween';
import { Truncate } from '../../../utils/Truncate/Truncate';
import { BodyText } from '../../BodyText/BodyText';
import { ProfileImage, ProfileImageSize } from '../../ProfileImage/ProfileImage';

interface ProfileLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  displayName?: string;
  licenseIndicator?: React.JSX.Element;
  size?: ProfileImageSize;
}

export function ProfileLink(props: ProfileLinkProps) {
  const { licenseIndicator, displayName, size = 'M' } = props;
  return (
    <StyledProfileLink className={profileLinkClassName} {...props}>
      <SpaceBetween direction="x" spacing={spacing.size16}>
        {props.displayName && (
          <BodyText size="medium" className={displayNameClassName}>
            <SpaceBetween direction="x" spacing={spacing.size8} alignItems="center">
              {licenseIndicator}
              <StyledName as="span">{displayName}</StyledName>
            </SpaceBetween>
          </BodyText>
        )}
        <ProfileImage size={size} />
      </SpaceBetween>
    </StyledProfileLink>
  );
}

export const profileLinkClassName = 'ProfileLink';
const displayNameClassName = 'DisplayName';

const StyledProfileLink = styled.a`
  text-decoration: none;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${breakpoints.topBarNavFullscreen}) {
    .${displayNameClassName} {
      display: none;
    }
  }
`;

const StyledName = styled(Truncate)`
  max-width: 25rem;
`;
