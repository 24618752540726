import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UploadRestrictionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UploadRestrictionsQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, maxFileSizeInBytesForUploads: number, acceptedMimeTypesForUploads: { __typename?: 'AcceptedMimeTypesForUploads', slideDeckTypes?: Array<string> | null } } };

export type CreateSlideDeckUploadMutationVariables = Types.Exact<{
  input: Types.CreateUploadForSlideDeckImportInput;
}>;


export type CreateSlideDeckUploadMutation = { __typename?: 'Mutation', createUploadForSlideDeckImport: { __typename?: 'CreateUploadPayload', uploadUrl: string, upload: { __typename?: 'AudioUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'DocumentUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'ImageUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'SlideDeckUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } | { __typename?: 'VideoUpload', id: string, name: string, originalFile: { __typename?: 'OriginalFile', fileExtension: string } } } };

export type CreateLessonMutationVariables = Types.Exact<{
  input: Types.CreateLessonInput;
}>;


export type CreateLessonMutation = { __typename?: 'Mutation', createLesson: { __typename?: 'CreateLessonPayload', lesson: { __typename?: 'Lesson', id: string } } };


export const UploadRestrictionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UploadRestrictions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"maxFileSizeInBytesForUploads"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedMimeTypesForUploads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slideDeckTypes"}}]}}]}}]}}]} as unknown as DocumentNode<UploadRestrictionsQuery, UploadRestrictionsQueryVariables>;
export const CreateSlideDeckUploadDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSlideDeckUpload"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateUploadForSlideDeckImportInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUploadForSlideDeckImport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upload"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"originalFile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileExtension"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"uploadUrl"}}]}}]}}]} as unknown as DocumentNode<CreateSlideDeckUploadMutation, CreateSlideDeckUploadMutationVariables>;
export const CreateLessonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLesson"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateLessonInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLesson"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lesson"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<CreateLessonMutation, CreateLessonMutationVariables>;