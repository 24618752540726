import { Language } from '@lessonup/utils';

type ArticleName = 'openAIPrompt' | 'newEditorExplained';

type Article = { number: number; languages: Language[] };

const helpArticles: Record<ArticleName, Article> = {
  openAIPrompt: { number: 6979315, languages: ['en', 'nl'] },
  newEditorExplained: { number: 8989640, languages: ['en', 'nl'] },
};

export const helpArticleLink = (language: Language, article: ArticleName): string => {
  const articleRef = helpArticles[article];
  const languageToUse = helpArticles[article].languages.includes(language) ? language : 'en';
  return `https://help.lessonup.com/${getArticleLanguage(articleRef, languageToUse)}/articles/${articleRef.number}`;
};

export const helpCenterLink = (language: Language): string => {
  return `https://help.lessonup.com/${language}/collections/10054004-maia-ai-assistant`;
};

const getArticleLanguage = (article: Article, language: Language): string => {
  if (article.languages.includes(language)) {
    return language;
  }
  if (article.languages.includes('en')) {
    return 'en';
  }
  return article.languages[0];
};
