import React from 'react';
import {
  DefinitionListItem,
  DefinitionListItemOrientation,
  DefinitionListItemPriority,
  DefinitionListItemProps,
} from './DefinitionListItem/DefinitionListItem';

export interface DefinitionListProps {
  items: DefinitionListItemProps[];
  priority?: DefinitionListItemPriority;
  orientation?: DefinitionListItemOrientation;
}

export function DefinitionList({ items, orientation, priority }: DefinitionListProps) {
  return (
    <dl>
      {items.map((itemProps) => (
        <DefinitionListItem
          orientation={orientation}
          priority={priority}
          {...itemProps}
          key={`definition-${itemProps.term}-${itemProps.description}`}
        />
      ))}
    </dl>
  );
}
