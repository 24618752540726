import { InputFormField } from '@lessonup/ui-components';
import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nextNamespace } from '../../../UploadsFeature.utils';

interface Props {
  currentValue: string | undefined;
  onChange: (value: string | undefined) => void;
}

const SEARCH_REFRESH_TIME = 200;
const TRANSLATION_PREFIX = 'searchField';
const MIN_SEARCH_LENGTH = 2;

export const SearchUploadsField: React.FC<Props> = ({ onChange, currentValue }) => {
  const { t } = useTranslation(i18nextNamespace);
  const inputFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Clear the input field when the search is cleared.
    if (!currentValue && inputFieldRef.current?.value) {
      inputFieldRef.current.value = '';
    }
  }, [currentValue]);

  const handleChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    if (!currentValue && value?.length < MIN_SEARCH_LENGTH) return;

    if (value === currentValue) return;
    onChange(value);
  }, SEARCH_REFRESH_TIME);

  return (
    <InputFormField
      ref={inputFieldRef}
      label={t(`${TRANSLATION_PREFIX}.searchLabel`)}
      type="search"
      placeholder={t(`${TRANSLATION_PREFIX}.placeholder`)}
      hideLabel={true}
      formFieldSize="small"
      defaultValue={currentValue}
      onChange={handleChange}
    ></InputFormField>
  );
};
