import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.728 16.908v1.427a2.853 2.853 0 0 0 2.853 2.852H18.42a2.853 2.853 0 0 0 2.852-2.852v-1.427M7.044 10.485c.972 1.789 2.42 3.419 4.285 4.546.413.25.93.25 1.342 0 1.865-1.128 3.312-2.758 4.284-4.546M12 15.218V2.813"
    />
  </svg>
);
export default SvgIconDownload;
