import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { ComponentSize } from '../../size/size.types';
import { FontWeight, TextStyle } from '../typography.types';
import { fontFamilyMap, fontSizeMap, fontWeight, fontWeightMap, htmlTagMap, lineHeightMap } from '../typography.utils';

export interface TextElementProps extends HTMLAttributes<HTMLDivElement> {
  textStyle: TextStyle;
  size?: ComponentSize;
  weight?: FontWeight;
  as?: keyof React.JSX.IntrinsicElements;
  className?: string;
}

export const TextElement: React.FC<TextElementProps> = ({
  textStyle,
  size = 'small',
  weight,
  as,
  className,
  children,
  ...rest
}) => {
  const weightValue = weight || fontWeightMap[textStyle];
  const htmlElement = as || htmlTagMap[textStyle];
  return (
    <StyledText textStyle={textStyle} size={size} weight={weightValue} as={htmlElement} className={className} {...rest}>
      {children}
    </StyledText>
  );
};

type StyledTextProps = {
  textStyle: TextStyle;
  size: ComponentSize;
  weight: FontWeight;
};

const StyledText = styled.div<StyledTextProps>`
  font-size: ${({ textStyle, size }) => fontSizeMap[textStyle][size]};
  line-height: ${({ textStyle, size }) => lineHeightMap[textStyle][size]};
  font-family: ${({ textStyle }) => fontFamilyMap[textStyle]};
  font-weight: ${({ weight }) => fontWeight[weight]};

  font-style: normal;
  margin: 0;
`;
