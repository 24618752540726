import { styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalLoader } from '../ModalLoader/ModalLoader';

export type ChatGPTWizardSteps = 'prompt' | 'loading' | 'review';

export interface ChatGPTWizardProps {
  currentStep: ChatGPTWizardSteps;
  promptStep: React.JSX.Element;
  reviewStep: React.JSX.Element;
}

export const ChatGPTWizard = ({ promptStep, reviewStep, currentStep }: ChatGPTWizardProps) => {
  const { t } = useTranslation('chatGPT');

  return (
    <StyledWizard>
      {currentStep === 'prompt' && promptStep}
      {currentStep === 'loading' && <ModalLoader description={t('generating')} />}
      {currentStep === 'review' && reviewStep}
    </StyledWizard>
  );
};

const StyledWizard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;
