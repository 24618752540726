import { useErrorContext } from '@lessonup/ui-components';
import { isEqual } from '@lessonup/utils';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useUploadsStatus } from '../../Uploads/hooks/useUploadsStatus';
import { UseUploadsStatusPolling, useUploadStatusPolling } from '../../Uploads/hooks/useUploadStatusPolling';
import {
  CompletedConversion,
  Conversion,
  isCompletedConversion,
  isProgressConversion,
  isSlideDeckUpload,
  ProgressConversion,
} from './types';

const LOCAL_STORAGE_KEY = 'conversions';
const WINDOW_EVENT_NAME = 'conversionStatusChange';
const MAX_TTL_IN_MS = 1000 * 60 * 60 * 24; // 1 day

const conversionsFromLocalStorage = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]').map(
    (fromJSON: Record<string, string>) =>
      ({
        ...fromJSON,
        createdAt: new Date(fromJSON.createdAt),
      }) as Conversion
  );

export type ConversionStatusCallback = ({
  conversions,
  completedConversions,
}: {
  conversions: ProgressConversion[];
  completedConversions: CompletedConversion[];
}) => void;

class ConversionStatusController {
  private callbacks: ConversionStatusCallback[] = [];
  private completedConversions: CompletedConversion[] = [];
  private startPollingForIdsInstance: UseUploadsStatusPolling['startPollingForIds'] | undefined;
  private stopPollingInstance: UseUploadsStatusPolling['stopPolling'] | undefined;
  private cleanupInterval: NodeJS.Timeout | undefined;
  private window: Window = globalThis.window;

  constructor() {
    this.window?.addEventListener(WINDOW_EVENT_NAME, () =>
      this.callbacks.forEach((callback) =>
        callback({ conversions: this.getConversions(), completedConversions: this.getCompletedConversions() })
      )
    );
  }

  public registerHook({
    startPollingForIds,
    stopPolling,
  }: {
    startPollingForIds: UseUploadsStatusPolling['startPollingForIds'];
    stopPolling: UseUploadsStatusPolling['stopPolling'];
  }): void {
    this.startPollingForIdsInstance = this.startPollingForIdsInstance || startPollingForIds;
    this.stopPollingInstance = this.stopPollingInstance || stopPolling;
  }

  public registerListener(callback: ConversionStatusCallback): void {
    this.callbacks.push(callback);
    callback({ conversions: this.getConversions(), completedConversions: this.getCompletedConversions() });
    if (!this.cleanupInterval) this.cleanupInterval = setInterval(() => this.cleanUpOldConversions(), MAX_TTL_IN_MS);
  }

  public unregisterListener(callback: ConversionStatusCallback): void {
    this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    if (this.callbacks.length === 0 && this.cleanupInterval) {
      this.cleanUpOldConversions();
      clearInterval(this.cleanupInterval);
      this.cleanupInterval = undefined;
    }
  }

  public setConversion(conversion: ProgressConversion): void {
    const current = this.getConversions();
    const updated = [...current.filter(({ uploadId }) => uploadId !== conversion.uploadId), conversion];
    this.setConversions(updated);
  }

  public removeConversion({ uploadId: idToRemove }: Pick<ProgressConversion, 'uploadId'>): void {
    const current = this.getConversions();
    const updated = current.filter(({ uploadId }) => uploadId !== idToRemove);
    this.setConversions(updated);
  }

  /**
   * Remove some of all of the conversions
   * @param toRemove - if not provided, all conversions will be removed
   */
  public removeConversions(toRemove?: Pick<ProgressConversion, 'uploadId'>[]): void {
    if (!toRemove) return this.setConversions([]);
    const current = this.getConversions();
    const updated = current.filter(
      ({ uploadId: idFromCurrent }) => !toRemove.some(({ uploadId: idToRemove }) => idFromCurrent === idToRemove)
    );
    this.setConversions(updated);
  }

  public setConversions(newConversions: ProgressConversion[]): void {
    if (newConversions.length === 0) this.stopPolling();
    const sorted = orderBy(newConversions, ['uploadId']);
    const current = this.getConversions();
    if (isEqual(sorted, current)) return;
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sorted));
    this.window?.dispatchEvent(new Event(WINDOW_EVENT_NAME));
    this.startPollingForIds(newConversions.map(({ uploadId }) => uploadId));
  }

  public getConversions(): ProgressConversion[] {
    return conversionsFromLocalStorage();
  }

  public completeConversions(newlyCompleted: CompletedConversion[]): void {
    this.setCompletedConversions([
      ...this.completedConversions.filter(
        ({ uploadId: idFromCurrent }) => !newlyCompleted.find(({ uploadId: idFromNew }) => idFromCurrent === idFromNew)
      ),
      ...newlyCompleted,
    ]);
    this.removeConversions(newlyCompleted);
  }

  public setCompletedConversions(newCompletedConversions: CompletedConversion[]): void {
    const sorted = orderBy(newCompletedConversions, ['uploadId']);
    const current = this.getCompletedConversions();
    if (isEqual(sorted, current)) return;
    this.completedConversions = sorted;
    this.window?.dispatchEvent(new Event(WINDOW_EVENT_NAME));
  }

  public getCompletedConversions(): CompletedConversion[] {
    return this.completedConversions;
  }

  public removeCompletedConversion({ uploadId: idToRemove }: Pick<CompletedConversion, 'uploadId'>): void {
    this.setCompletedConversions(this.completedConversions.filter(({ uploadId }) => uploadId !== idToRemove));
  }

  /**
   * Remove some or all of the completed conversions
   * @param toRemove - if not provided, all completed conversions will be removed
   */
  public removeCompletedConversions(toRemove?: Pick<CompletedConversion, 'uploadId'>[]): void {
    if (!toRemove) return this.setCompletedConversions([]);
    const current = this.getCompletedConversions();
    const updated = current.filter(
      ({ uploadId: idFromCurrent }) => !toRemove.some(({ uploadId: idToRemove }) => idFromCurrent === idToRemove)
    );
    this.setCompletedConversions(updated);
  }

  private startPollingForIds(ids: string[]): void {
    if (!this.startPollingForIdsInstance) throw new Error('Register a hook before using ConversionStatusController');
    this.startPollingForIdsInstance(ids);
  }

  private stopPolling(): void {
    if (!this.stopPollingInstance) throw new Error('Register a hook before using ConversionStatusController');
    this.stopPollingInstance();
  }

  private cleanUpOldConversions(): void {
    const conversions = this.getConversions();
    const oldConversions = conversions.filter(({ createdAt }) => Date.now() - createdAt.getTime() > MAX_TTL_IN_MS);
    if (oldConversions.length) this.removeConversions(oldConversions);

    const completedConversions = this.getCompletedConversions();
    const oldCompletedConversions = completedConversions.filter(
      ({ createdAt }) => Date.now() - createdAt.getTime() > MAX_TTL_IN_MS
    );
    if (oldCompletedConversions.length) this.removeCompletedConversions(oldCompletedConversions);
  }
}

const controller = new ConversionStatusController();

export const useConversionStatusListener = (callback: ConversionStatusCallback) => {
  useEffect(() => {
    controller.registerListener(callback);
    return () => controller.unregisterListener(callback);
  }, [callback]);
};

export const useConversionStatus = () => {
  const [conversions, setConversions] = useState<ProgressConversion[]>(controller.getConversions());
  const [completedConversions, setCompletedConversions] = useState<CompletedConversion[]>([]);
  const { setError } = useErrorContext();
  const { startPollingForIds, stopPolling } = useUploadStatusPolling({
    onError: (error) => {
      setError({ error });
    },
  });

  const listenerCallback = ({
    conversions,
    completedConversions,
  }: {
    conversions: ProgressConversion[];
    completedConversions: CompletedConversion[];
  }) => {
    setConversions(conversions);
    setCompletedConversions(completedConversions);
  };

  useEffect(() => {
    controller.registerHook({ startPollingForIds, stopPolling });
    controller.registerListener(listenerCallback);
    return () => controller.unregisterListener(listenerCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useUploadsStatus({
    ids: conversions.map(({ uploadId }) => uploadId),
    onSingleCompleted: (data) => {
      const completed =
        data?.viewer.uploadsByIds
          .filter(isSlideDeckUpload)
          .map(
            ({ id, status, lessonName, lessonId, createdAt }) =>
              ({
                uploadId: id,
                status,
                name: lessonName,
                lessonId,
                createdAt: new Date(createdAt),
              }) as CompletedConversion
          )
          .filter(isCompletedConversion) || [];

      controller.completeConversions(completed);
    },
  });

  useEffect(() => {
    const uploads = data?.viewer.uploadsByIds || [];
    const current = controller.getConversions();
    const updated = current
      .map((conversion) => ({
        ...conversion,
        status: uploads.find(({ id }) => id === conversion.uploadId)?.status || conversion.status,
      }))
      .filter(isProgressConversion);
    controller.setConversions(updated);
  }, [data]);

  return {
    conversions,
    completedConversions,
    setConversionStatus: (conversion: ProgressConversion) => controller.setConversion(conversion),
    removeConversionStatus: (conversion: Pick<Conversion, 'uploadId'>) => controller.removeConversion(conversion),
    removeCompletedConversionStatus: (conversion: Pick<CompletedConversion, 'uploadId'>) =>
      controller.removeCompletedConversion(conversion),
    removeCompletedConversionStatuses: () => controller.removeCompletedConversions(),
  };
};
