import { css, SerializedStyles } from '@emotion/react';
import { math } from 'polished';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { font } from '../../foundations/typography/fonts';
import { fontSize, lineHeight } from '../../foundations/typography/typography.utils';
import { SharedFormFieldProps } from './model';

export const sharedFormFieldStyle = (props: SharedFormFieldProps) => css`
  padding: ${getPadding(props)};
  background-color: ${getBackgroundColor(props)};
  color: ${getFontColor(props)};
  border: none;
  font-family: ${font.body.cssValue};
  font-size: ${getFontSize(props)};
  line-height: ${getLineHeight(props)};
  outline: ${getOutline(props)};

  &::placeholder {
    color: ${color.additional.disabled.text};
    opacity: 1;
  }

  &:disabled {
    background-color: ${color.additional.disabled.background};
    color: ${color.additional.disabled.text};
  }
`;

export const sharedInputSelectStyle = () => css`
  border-radius: ${borderRadius.rounded24};
`;

export const getFontSize = ({ formFieldSize }: Pick<SharedFormFieldProps, 'formFieldSize'>): string => {
  switch (formFieldSize) {
    case 'small':
      return fontSize.bodyTextSizeSmall;
    default:
      return fontSize.bodyTextSizeMedium;
  }
};

const getLineHeight = ({ formFieldSize }: Pick<SharedFormFieldProps, 'formFieldSize'>): string => {
  switch (formFieldSize) {
    case 'small':
      return lineHeight.bodyTextSizeSmall;
    default:
      return lineHeight.bodyTextSizeMedium;
  }
};

export const getPadding = ({ formFieldSize }: Pick<SharedFormFieldProps, 'formFieldSize'>): SerializedStyles => {
  switch (formFieldSize) {
    case 'small':
      return css`
        ${spacing.size8} ${spacing.size12}
      `;
    case 'large':
      return css`
        ${spacing.size12} ${spacing.size16}
      `;
    default:
      return css`
        ${spacing.size8} ${spacing.size16}
      `;
  }
};

export const getSelectRightPadding = ({
  formFieldSize,
}: Pick<SharedFormFieldProps, 'formFieldSize' | 'type'>): string => {
  const chevronWidth = '24px';

  switch (formFieldSize) {
    case 'small':
      return math(`2*${spacing.size12} + ${chevronWidth}`);
    default:
      return math(`2*${spacing.size16} + ${chevronWidth}`);
  }
};

export const getPaddingForFieldWithIcon = ({
  formFieldSize,
  iconWidth,
}: Pick<SharedFormFieldProps, 'formFieldSize'> & { iconWidth: string }): string => {
  switch (formFieldSize) {
    case 'small':
      return math(`${spacing.size12} + ${iconWidth} + ${spacing.size8}`);
    default:
      return math(`${spacing.size16} + ${iconWidth} + ${spacing.size8}`);
  }
};

export const getIconSpacing = ({ formFieldSize }: Pick<SharedFormFieldProps, 'formFieldSize'>): string => {
  switch (formFieldSize) {
    case 'small':
      return spacing.size12;
    default:
      return spacing.size16;
  }
};

export const getFontColor = ({ validation }: Pick<SharedFormFieldProps, 'validation'>): string => {
  const { state } = validation || { state: 'neutral' };
  switch (state) {
    case 'error':
    case 'success':
      return color.neutral.surface.text;
    case 'warning':
      return color.additional.warningContainer.text;
    default:
      return color.accent.secondaryContainer.text;
  }
};

export const getBackgroundColor = ({ validation }: Pick<SharedFormFieldProps, 'validation'>): string => {
  const { state } = validation || { state: 'neutral' };
  switch (state) {
    case 'error':
    case 'success':
      return color.neutral.surface.background;
    case 'warning':
      return color.additional.warningContainer.background;
    default:
      return color.accent.secondaryContainer.background;
  }
};

export const getOutline = ({ validation }: Pick<SharedFormFieldProps, 'validation'>): string => {
  const { state } = validation || { state: 'neutral' };

  const outlineShared = `2px solid`;

  switch (state) {
    case 'error':
      return `${outlineShared} ${color.additional.error.background}`;
    case 'success':
      return `${outlineShared} ${color.additional.success.background}`;
    default:
      return 'none';
  }
};
