import type { ProgressBarProps } from './ProgressBar';

export const formatValueIndicator = (
  valueIndicatorType: ProgressBarProps['valueIndicatorType'],
  value: number,
  max: number,
  percentage: number
) => {
  switch (valueIndicatorType) {
    case 'percentage':
      return `${percentage}%`;
    case 'fraction':
      return `${value} / ${max}`;
    case 'number':
      return value.toString();
    default:
      return undefined;
  }
};
