import { LoadingRocket, LoadingRocketProps, styled } from '@lessonup/ui-components';
import React from 'react';

/**
 * This component takes up the space of the parent element. The parent element should have `display: flex`.
 */
export function ModalLoader(props: LoadingRocketProps) {
  return (
    <Wrapper>
      <LoadingRocket description={props.description} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;
