import { ErrorProvider, ErrorProviderProps, ErrorToErrorDisplayMessage } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from '../logger/logger';
import { errorCodeToStandardizedMessage } from './errorCodeToStandardizedMessage';

interface DefaultProps {
  children: React.ReactNode;
  defaultDisplayMode?: ErrorProviderProps['defaultDisplayMode'];
  parsers?: ErrorToErrorDisplayMessage[];
}

export const ErrorProviderWithDefaultTranslations: React.FC<DefaultProps> = ({
  children,
  defaultDisplayMode,
  parsers = [],
}) => {
  const { t } = useTranslation();
  return (
    <ErrorProvider
      defaultDisplayMode={defaultDisplayMode}
      errorParsers={[...parsers, (error) => errorCodeToStandardizedMessage(error, t)]}
      logger={logger.error}
    >
      {children}
    </ErrorProvider>
  );
};
