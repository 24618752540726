import { Divider, SpaceBetween, spacing } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralCountMessage, ReferralCountMessageProps } from '../ReferralCountMessage/ReferralCountMessage';
import { ReferralExplanation } from '../ReferralExplanation/ReferralExplanation';
import { ReferralMailInvite } from '../ReferralMailInvite/ReferralMailInvite';
import { ReferralTabs, ReferralTabsProps } from '../ReferralTabs/ReferralTabs';

export type ReferralPageProps = Pick<ReferralTabsProps, 'link' | 'token'> &
  Partial<ReferralCountMessageProps> & { amountOfExtraTrialDays?: number; className?: string };

const TRANSLATION_NAMESPACE = 'teacherReferralModal';

export const ReferralPage = ({
  className,
  referralCount,
  amountOfExtraTrialDays,
  ...referralTabsProps
}: ReferralPageProps) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <SpaceBetween direction="y" spacing={spacing.size24} className={className} padding={spacing.size2}>
      <ReferralExplanation amountOfExtraTrialDays={amountOfExtraTrialDays} />
      {!!referralCount && <ReferralCountMessage referralCount={referralCount} />}
      <ReferralMailInvite {...referralTabsProps} />

      <Divider orientation="horizontal" text={t('divider')} />
      <ReferralTabs {...referralTabsProps} />
    </SpaceBetween>
  );
};
