import _, { compact, uniq } from 'lodash';

// originally found in colorLib -> Does not include all original colorLib

export namespace Colors {
  export type Color = keyof typeof textToHexMap;

  export const textToHexMap = {
    // August 2021 styleguide: https://company-47241.frontify.com/d/bOQTrj0W7lLt/styleguide#/basics/kleuren
    'dark-grey': '#5a4d4c',
    turquoise: '#00b6bc',
    blue: '#4dc6e6',
    yellow: '#f8c82d',
    'red-new': '#ec484d',
    'pacific-blue': '#00a5c2',

    surface: '#fcfcfc',

    white: '#ffffff',
    silver: '#c3c3c3',
    mercury: '#e0e0e0',
    'wild-sand': '#f3f3f3',
    hurricane: '#8d8786',
    outline: '#e4e2e2',

    /* brampalette */
    'border-light': '#e0e0e0',
    'border-dark': '#cccccc',
    'brown-dark': '#5a4d4c',
    'default-text': '#5a4d4c', // brown-dark
    'white-2': '#f3f3f3',
    black: '#000000',
    red: '#e72138',
    'red-dark': '#a91224',
    'yellow-dark': '#d2a207',
    'blue-dark': '#1ca6ca',
    'blue-darker': '#00659d',
    'air-blue': '#008AAA',
    green: '#0aba58',
    'green-dark': '#067136',
    'green-mid': '#008287',
    pink: '#ec484d',
    grey: '#ececec',
    'grey-dark': '#574c4a',
    'grey-dark-2': '#574c4a',
    'grey-mid': '#635655',
    'grey-mid-2': '#999999',
    'grey-light': '#e9e9e9',
    'grey-light2': '#aaa',
    'grey-light3': '#cecece',
    'grey-bg': '#e0e0e0',
    orange: '#f48221',
    cyan: '#00b6bc',
    government: '#154273',
    postit: '#fffdd6',
    correct: '#2a8f27',
    'correct-light': '#65d461',
    link: '#009bff',
    purple: '#ca4cfd',
    'folder-blue': '#578dce',
    none: 'transparent',
    wildsand: '#f3f3f3',
  } as const;

  //definitions from slide-definitions.less and question-definitions.less
  const colorDefs = {
    'slide-color-1': {
      bg: textToHexMap['white'],
      fg: 'black',
      outline: true,
    },
    'slide-color-2': {
      bg: textToHexMap['yellow'],
      fg: 'black',
    },
    'slide-color-3': {
      bg: textToHexMap['pink'],
      fg: 'white',
    },
    'slide-color-4': {
      bg: textToHexMap['cyan'],
      fg: 'white',
    },
    'slide-color-5': {
      bg: textToHexMap['black'],
      fg: 'white',
    },
    'slide-color-6': {
      bg: textToHexMap['blue'],
      fg: '#0a3844',
    },
    'slide-color-7': {
      bg: textToHexMap['orange'],
      fg: '#574c4a',
    },
    'slide-color-8': {
      bg: textToHexMap['correct-light'],
      fg: '#0d2b0c',
    },
    'quiz-color-1': {
      bg: textToHexMap['blue-dark'],
      fg: 'white',
    },
    'quiz-color-9': {
      bg: textToHexMap['white'],
      fg: 'black',
    },
  };

  function hexToRgb(hex) {
    if (!hex) return;
    // taken from https://stackoverflow.com/questions/5623838
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    // eslint-disable-next-line no-param-reassign
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  export function needsDarkIcon(color: string) {
    return getContrastColor(color) == '#000000';
  }

  export function getContrastColor(color) {
    //default LU colors, custom definition
    if (_.includes(['white', 'black', 'yellow', 'pink', 'blue', 'green', 'red', 'orange', 'cyan', 'none'], color)) {
      return _.includes(['black', 'pink', 'cyan', 'red'], color) ? '#ffffff' : '#000000';
    }

    if (textToHexMap[color]) {
      // eslint-disable-next-line no-param-reassign
      color = textToHexMap[color];
    }
    const rgb = hexToRgb(color);
    if (!rgb) {
      return '#ffffff';
    }

    //taken from https://stackoverflow.com/questions/3942878
    // if ( calcContrast(rgb) > 0.179 ) {
    if (calcContrast(rgb) > 0.3) {
      return '#000000';
    } else {
      return '#ffffff';
    }
  }

  function calcContrast(rgb: { r: number; g: number; b: number }) {
    //taken from https://stackoverflow.com/questions/3942878
    const C = [rgb.r / 255, rgb.g / 255, rgb.b / 255];
    for (let i = 0; i < C.length; ++i) {
      if (C[i] <= 0.03928) {
        C[i] = C[i] / 12.92;
      } else {
        C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
      }
    }
    return 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];
  }

  export function getHexColor(color: string | undefined): string {
    if (!color) return '';
    if (color.startsWith('#')) return color;
    if (textToHexMap[color]) return textToHexMap[color];
    return '';
  }

  export function getOpenQuestionCustomColor(color: number): { bg: string; fg: string; outline?: boolean } | undefined {
    if (!color || color > 9) return undefined;
    return colorDefs[`${[1, 9].includes(color) ? 'quiz' : 'slide'}-color-${color}`];
  }

  type RGBMatchArray = [match: string, r: string, g: string, b: string];
  type HExMatchArray = [match: string, hex: string];
  interface RGB {
    r: number;
    g: number;
    b: number;
  }

  function rgbToHex(rgba: RGB) {
    return `#${((1 << 24) + (rgba.r << 16) + (rgba.g << 8) + rgba.b).toString(16).slice(1)}`;
  }

  const matchToRgb = (match: RGBMatchArray) => ({
    r: parseInt(match[1]),
    g: parseInt(match[2]),
    b: parseInt(match[3]),
  });

  export function colorsInString(input = ''): string[] {
    const hexRegex = /style="[^"]*(#[0-9a-fA-F]{6})/g;
    const rgbRegex = /style="[^"]*rgb\([\s]?(\d{1,3}),[\s]?(\d{1,3}),[\s]?(\d{1,3})\)/g;
    const rgbMatches = [...input.matchAll(rgbRegex)];
    const hexFromRgb = rgbMatches.map((match) => match && rgbToHex(matchToRgb(match as RGBMatchArray)));
    const hexMatches = [...input.matchAll(hexRegex)];
    const hexColors = hexMatches.map((match) => match && (match as HExMatchArray)[1]);
    return uniqColors(compact([...hexColors, ...hexFromRgb]));
  }
  /**
   * Map rgb and hex colors to hex and remove duplicates
   */
  export const uniqColors = (colors: string[]) => {
    const upperCaseHex = colors.map((color) => {
      if (color.startsWith('#')) {
        return color.toUpperCase();
      }
      if (color.startsWith('rgb')) {
        const [r, g, b] = color
          .replace('rgb(', '')
          .replace(')', '')
          .split(',')
          .map((c) => parseInt(c.trim()));
        if (isNaN(r) || isNaN(g) || isNaN(b)) return color;
        return rgbToHex({ r, g, b }).toUpperCase();
      }
      return color;
    });
    return uniq(upperCaseHex);
  };

  const additionalSkipMap = {
    none: true,
    transparent: true,
    '#FFFFFF': true,
    '#000000': true,
    '#FFF': true,
    '#000': true,
    '#ffffff': true,
    '#fff': true,
  };
  /**
   * Text name of our custom colors are handled incorrectly in the editor
   */
  export function skipColorForInMyLesson(color: string): boolean {
    return !!additionalSkipMap[color] || !!textToHexMap[color];
  }
}
