import { SymbolDefinition } from '../SymbolComponent';

const NAME = 'arrow';
export interface ArrowSymbol extends SymbolDefinition {
  name: typeof NAME;
}

export const arrowSymbol: ArrowSymbol = {
  name: NAME,
  lockedAspectRatio: false,
  startWithFixedAspectRatio: false,
  hasStrokeSettings: true,
};
