import { HocuspocusProvider } from '@hocuspocus/provider';
import { CollaborationCursor } from '@tiptap/extension-collaboration-cursor';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Doc, UndoManager } from 'yjs';
import { useMyUser } from '../../../../utils/user/useMyUserDocument';
import { MyUser } from '../../../TeacherTopBar/TeacherTopBar.graphql';
import { useUndoManager } from './useUndoManager';

export interface AwarenessAPI {
  setLocation: (pinId: string | null, componentId: string[]) => void;
  setUploadId: (uploadId: string) => void;
  removeUploadId: (uploadId: string) => void;
}

export interface HocusPocusContext<B> {
  provider?: HocuspocusProvider;
  binders: B;
  yDoc: Doc;
  collaborationCursor?: typeof CollaborationCursor;
  awarenessAPI: AwarenessAPI;
  undoManager: UndoManager | null;
  setActivePinCallback: (callback: ((pinId: string) => void) | undefined) => void;
}

/**
 * A function that binds a Yjs document to a set of binders
 * The cleanup function is important to add to not leak memory
 * @param yDoc The Yjs document to bind
 * @returns A tuple of the binders and a cleanup function
 */
export type YJSBinderConstructor<B> = (yDoc: Doc) => [binders: B, cleanup: () => void];

export interface HocusPocusContextProps {
  provider?: HocuspocusProvider;
  children: React.ReactNode;
}

const YjsContext = createContext<HocusPocusContext<unknown> | null>(null);

export function createYjsContext<B>(
  bindDocument: YJSBinderConstructor<B>
): [React.FC<HocusPocusContextProps>, UseYjs<B>] {
  const YjsContextProvider: React.FC<HocusPocusContextProps> = ({ children, provider }: HocusPocusContextProps) => {
    const newYDoc = useMemo(() => new Doc(), []);
    const [binders, setBinders] = useState<B | null>(null);
    const yDoc = provider?.document ?? newYDoc;
    const user = useMyUser();
    const { undoManager, setActivePinCallback } = useUndoManager(yDoc);

    const collaborationCursor = useMemo(() => {
      if (!provider) return undefined;
      return CollaborationCursor.configure({
        provider: provider,
        user: userToAwareness(user, provider.awareness?.clientID),
      });
    }, [provider, user]);

    useEffect(() => {
      provider?.setAwarenessField('user', userToAwareness(user, provider.awareness?.clientID));
    }, [user, provider]);

    useEffect(() => {
      const [newBinders, cleanup] = bindDocument(yDoc);
      setBinders(newBinders);
      return () => {
        cleanup();
      };
    }, [yDoc]);

    const awarenessAPI: AwarenessAPI = useMemo(() => {
      return {
        setLocation: (pinId: string | null, componentId: string[]) => {
          provider?.setAwarenessField('location', {
            pinId,
            componentId,
          });
        },
        setUploadId: (uploadId: string) => {
          const uploads: string[] = provider?.awareness?.getLocalState()?.uploads || [];
          uploads.push(uploadId);
          provider?.setAwarenessField('uploads', uploads);
        },
        removeUploadId: (uploadId: string) => {
          const uploads: string[] = provider?.awareness?.getLocalState()?.uploads || [];
          provider?.setAwarenessField(
            'uploads',
            uploads.filter((upload) => upload !== uploadId)
          );
        },
      };
    }, [provider]);

    const value: HocusPocusContext<B> | null = useMemo(() => {
      // We short circuit the provider if the binders have not been created yet
      if (!binders) return null;

      return {
        provider,
        collaborationCursor,
        yDoc,
        awarenessAPI,
        binders,
        undoManager,
        setActivePinCallback,
      };
    }, [binders, provider, collaborationCursor, yDoc, awarenessAPI, undoManager, setActivePinCallback]);

    if (!value) return null;

    return <YjsContext.Provider value={value}>{children}</YjsContext.Provider>;
  };

  return [YjsContextProvider, useYjs as () => HocusPocusContext<B>];
}

type UseYjs<B> = () => HocusPocusContext<B>;
function useYjs<B>(): HocusPocusContext<B> {
  const ctx = useContext<HocusPocusContext<B> | null>(YjsContext as React.Context<HocusPocusContext<B> | null>);
  if (!ctx) {
    throw new Error('useYJS must be used within a YjsContextProvider');
  }
  return ctx;
}

/** Should only be used by shared components, that do not care about the binders */
export const useAnyYjs = useYjs as UseYjs<unknown>;

const userToAwareness = (user: MyUser | undefined, clientId: number | undefined) => {
  if (!user) return undefined;
  return {
    id: user.id,
    name: user.name || 'anonymous',
    color: stringToColor(clientId || Math.round(Math.random() * 1000)),
  };
};

// Temporary function to generate a color from a user
const stringToColor = (clientId: number) => {
  return darkColors[clientId % darkColors.length];
};

const darkColors = [
  '#000080',
  '#228B22',
  '#8B0000',
  '#2F4F4F',
  '#D2691E',
  '#191970',
  '#556B2F',
  '#800080',
  '#800000',
  '#708090',
  '#A0522D',
  '#4B0082',
  '#6B8E23',
  '#696969',
  '#DC143C',
  '#9932CC',
  '#B22222',
  '#008B8B',
  '#483D8B',
  '#8B4513',
];
