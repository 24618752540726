import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconInfinity = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.016 16.3a3.94 3.94 0 0 0 4.085-2.591c.365-1.016.365-2.402 0-3.418a3.94 3.94 0 0 0-4.085-2.59c-3.941 0-6.09 8.598-10.032 8.598A3.94 3.94 0 0 1 2.9 13.71c-.365-1.016-.365-2.402 0-3.418a3.94 3.94 0 0 1 4.085-2.59c3.941 0 6.09 8.598 10.032 8.598"
    />
  </svg>
);
export default SvgIconInfinity;
