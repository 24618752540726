import { createContext, useContext, useMemo } from 'react';
import { CurrentRoute } from './route';

type BasicRouteFunc = (url: string) => void;

type ReplaceRouteFunc = (url: string) => void;

/**
 * the current route is part of the router, just like in next
 */
export interface Router<P = unknown> {
  go: BasicRouteFunc;
  replace: ReplaceRouteFunc;
  route: CurrentRoute<P>;
}

const RouterContext = createContext<Router<unknown> | undefined>(undefined);

export const RouterProvider = RouterContext.Provider;

export function useRouter<P>(): Router<P> {
  const router = useContext(RouterContext);
  if (!router) throw new Error('expected router to be set via the RouterProvider');
  return router as Router<P>;
}

export type RouterWithoutData = Omit<Router, 'route'>;

export function useRoutingOnlyRouter(): RouterWithoutData {
  const router = useContext(RouterContext);
  if (!router) throw new Error('expected router to be set via the RouterProvider');
  // Since we are not using the route, we can safely ignore the dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => router, []);
}
