import { PinComponentId, PinId } from '@lessonup/pins-shared';
import {
  BodyText,
  borderRadius,
  color,
  font,
  fontSize,
  lineHeight,
  SharedFormFieldProps,
  sharedFormFieldStyle,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React, { CSSProperties } from 'react';
import { TipTapInput } from '../../../../utils/yjs/TipTapInput';

export interface EditorTiptapFormFieldProps {
  pinId: PinId;
  pinComponentId: PinComponentId;
  fragmentKey: string;
  isTextArea?: boolean;
  label?: string;
  height?: string;
}

interface InputWrapperCssProperties extends CSSProperties {
  '--tiptap-formfield-height': CSSProperties['height'];
}

export const EditorTiptapFormField: React.FC<EditorTiptapFormFieldProps> = ({
  pinId,
  pinComponentId,
  fragmentKey,
  isTextArea,
  label,
  height,
}) => {
  const style: InputWrapperCssProperties = {
    '--tiptap-formfield-height': height ? height : `${isTextArea ? TEXTAREA_HEIGHT : INPUT_HEIGHT}px`,
  };
  return (
    <>
      {label && (
        <BodyText weight="bold" size="small">
          {label}
        </BodyText>
      )}
      <InputWrapperStyle style={style} type="text" label={label ?? ''} isTextArea={isTextArea}>
        <TipTapInput
          pinId={pinId}
          pinComponentId={pinComponentId}
          fragmentKey={fragmentKey}
          disableNewLine={!isTextArea}
        />
      </InputWrapperStyle>
    </>
  );
};

const TEXTAREA_HEIGHT = 96;
const INPUT_HEIGHT = 24;

const InputWrapperStyle = styled.div<{ isTextArea?: boolean } & SharedFormFieldProps>`
  ${sharedFormFieldStyle};
  border-radius: ${(props) => (props.isTextArea ? borderRadius.rounded8 : borderRadius.rounded24)};
  padding: ${spacing.size8} ${spacing.size12};
  background-color: ${color.accent.secondaryContainer.background};
  color: ${color.accent.secondaryContainer.text};
  border: none;
  font-family: ${font.body.cssValue};
  font-size: ${fontSize.bodyTextSizeMedium};
  line-height: ${lineHeight.bodyTextSizeMedium};
  overflow: auto;

  .ProseMirror {
    height: var(--tiptap-formfield-height);
  }

  p {
    margin: 0;
    padding: 0;
  }
`;
