import {
  ANSWER_MAX_HEIGHT,
  deleteQuizAnswer,
  PinId,
  QuizAddAnswerButton,
  quizAnswerLetterForIndex,
  QuizPinAnswerIndicators,
  QuizPinAnswersSection,
  QuizPinComponent,
  QuizPinProps,
  QuizTeacherPin,
  toggleCorrectAnswer,
  useUpdatePin,
} from '@lessonup/pin-renderer';
import { MAXIMAL_QUIZ_ANSWERS, MINIMAL_QUIZ_ANSWERS } from '@lessonup/pins-shared';
import {
  BodyText,
  borderRadius,
  Button,
  Card,
  color,
  Divider,
  font,
  fontSize,
  IconRemove,
  lineHeight,
  rem,
  SpaceBetween,
  spacing,
  styled,
  Switch,
} from '@lessonup/ui-components';
import React, { useCallback, useRef } from 'react';
import { useEditorContext } from '../../../../context/EditorContext/EditorContext';
import { useSubSelectionValue } from '../../../../context/EditorContext/hooks/useEditorState';
import { useEditorTranslation } from '../../../../hooks/useEditorTranslation';
import { TipTapInput } from '../../../../utils/yjs/TipTapInput';
import { useEditorInputKeybindController } from '../../../EditorContextSidebar/components/EditorInputFields/hooks/useEditorInputKeybindController';

type QuizPinEditorProps = Omit<QuizPinProps, 'editable'> & {
  pin: QuizTeacherPin;
};

export const QuizPinEditor: React.FC<QuizPinEditorProps> = ({ pin, isThumb, ...props }) => {
  const { id: pinId, teacherSettings } = pin;
  const { t } = useEditorTranslation();
  const subSelectionValue = useSubSelectionValue() ?? '';
  const { setSubSelectionValue, resetSelection } = useEditorContext();
  const dispatch = useUpdatePin();

  const answers = props.pinSettings.answers;
  const showAddAnswerButton = answers.length < MAXIMAL_QUIZ_ANSWERS;
  const canDeleteAnswers = answers.length > MINIMAL_QUIZ_ANSWERS;
  const selectedAnswerIsCorrect = teacherSettings.correctAnswers.includes(subSelectionValue);

  const handleSelectAnswer = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.stopPropagation();
      resetSelection();
      setSubSelectionValue(id);
    },
    [setSubSelectionValue, resetSelection]
  );

  const handleDeleteQuizAnswer = () => {
    resetSelection();
    deleteQuizAnswer(pin, subSelectionValue, dispatch);
  };

  return (
    <QuizPinComponent pinSettings={props.pinSettings}>
      {props.children}
      <div
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <QuizPinAnswersSection>
          {!isThumb && subSelectionValue && (
            <StyledSubSelectionControls onMouseDown={(event) => event.stopPropagation()}>
              <BodyText size="medium" weight="regular">
                {t('floatingActionMenu.correct')}
              </BodyText>
              <Switch
                id={`subselection-toggle-${subSelectionValue}`}
                ariaLabel={t('quizPin.ariaLabelToggleAnswerCorrect', { index: subSelectionValue })}
                defaultChecked={selectedAnswerIsCorrect}
                checked={selectedAnswerIsCorrect}
                onChange={() => toggleCorrectAnswer(pin, subSelectionValue, dispatch)}
                key={`subselection-${subSelectionValue}`}
              />
              {canDeleteAnswers && (
                <>
                  <Divider orientation="vertical" />
                  <Button
                    buttonType="negative"
                    buttonShape="rect"
                    size="small"
                    onClick={handleDeleteQuizAnswer}
                    iconStart={<IconRemove />}
                    aria-label={t('quizPin.ariaLabelDeleteAnswer', { index: subSelectionValue })}
                  />
                </>
              )}
            </StyledSubSelectionControls>
          )}
          {answers.map((answer, index) => {
            const isSelected = !isThumb && subSelectionValue === answer.id;
            const isCorrect = !isThumb && teacherSettings.correctAnswers.includes(answer.id);
            return (
              <Card
                key={`${index}-${answer.id}`}
                cardTheme="neutral"
                resizing="fixed"
                active={isSelected}
                onMouseDown={!isThumb ? (event) => handleSelectAnswer(event, answer.id) : undefined} // We check this because the editor pin is used in the pinRail, the pinRail should eventually get its own pin view
                ariaLabel={t('quizPin.ariaLabelAnswer', { index: quizAnswerLetterForIndex(index) })}
                showBorder={false}
                showBoxShadow
                data-testid="editor-quiz-answer"
              >
                <AnswerCardQuizEditorContent index={index} pinId={pinId} answerId={answer.id} isCorrect={isCorrect} />
              </Card>
            );
          })}
          {showAddAnswerButton && (
            <QuizAddAnswerButton settings={props.pinSettings} setSubSelectionValue={setSubSelectionValue} />
          )}
        </QuizPinAnswersSection>
      </div>
    </QuizPinComponent>
  );
};

interface AnswerCardQuizEditorContentProps {
  index: number;
  isCorrect?: boolean;
  pinId: PinId;
  answerId: string;
}

const AnswerCardQuizEditorContent: React.FC<AnswerCardQuizEditorContentProps> = ({
  index,
  isCorrect,
  pinId,
  answerId,
}) => {
  const { t } = useEditorTranslation();
  const { onBlur, onFocus } = useEditorInputKeybindController();
  const ref = useRef<HTMLDivElement>(null);
  const answerStatus = isCorrect ? 'correct' : 'neutral';

  const handleBlur = () => {
    onBlur();
    const child = ref.current?.querySelector('.ProseMirror');
    child?.scrollTo({ top: 0 });
  };

  return (
    <StyledAnswerContent direction="x" spacing={spacing.size4} alignItems="flex-start" padding={spacing.size8}>
      <QuizPinAnswerIndicators index={index} answerStatus={answerStatus} />
      <StyledInput onBlur={handleBlur} onFocus={onFocus}>
        <TipTapInput
          key="selected"
          pinId={pinId}
          pinComponentId="quizAnswer"
          fragmentKey={answerId}
          ref={ref}
          placeholder={t('quiz.answer.placeholder')}
        />
      </StyledInput>
    </StyledAnswerContent>
  );
};

// Top is 1px smaller because sometimes it shows a subpixel line gap
const StyledSubSelectionControls = styled.div`
  position: absolute;
  top: ${rem('-47px')};
  left: 0;
  right: 0;
  width: fit-content;
  height: ${rem('48px')};
  border-radius: ${borderRadius.rounded16} ${borderRadius.rounded16} 0 0;
  background-color: ${color.neutralNew.level1.fill};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.size8};
  padding: ${spacing.size16};
`;

const StyledAnswerContent = styled(SpaceBetween)`
  height: 100%;
`;

const StyledInput = styled.div`
  resize: none;
  font-family: ${font.body.cssValue};
  font-size: ${fontSize.bodyTextSizeMedium};
  line-height: ${lineHeight.bodyTextSizeMedium};
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: ${spacing.size0} ${spacing.size4};
  overflow: hidden;
  border: none;
  cursor: text;

  & > *,
  .tiptap-root,
  .ProseMirror {
    height: 100%;
  }

  .ProseMirror {
    max-height: ${ANSWER_MAX_HEIGHT};
    overflow: hidden;
    word-break: break-word;
  }

  &:hover:not(:focus-within) {
    outline: 1px solid ${color.neutral.outline.background};
  }

  &:focus-within {
    outline: 2px solid ${color.additional.focus.background};
  }

  p {
    margin: 0;
    padding: 0;
  }
`;
