import styled from '@emotion/styled';
import React from 'react';

export interface TruncateProps {
  as?: 'a' | 'span' | 'label' | 'div';
  maxLines?: number;
  wordBreak?: 'break-all' | 'break-word';
}

export const Truncate = ({
  as,
  maxLines = 1,
  wordBreak = 'break-word',
  children,
  ...rest
}: React.PropsWithChildren<TruncateProps>) => {
  return (
    <StyledOuterElement as={as} maxLines={maxLines} wordBreak={wordBreak} {...rest}>
      {children}
    </StyledOuterElement>
  );
};

const StyledOuterElement = styled.div<Pick<TruncateProps, 'maxLines' | 'wordBreak'>>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  display: block;
  word-break: ${({ wordBreak }) => wordBreak};

  ${({ maxLines }) => `
    display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    line-clamp: ${maxLines};
  `}
`;
