import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconPageAssessments = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.778 5.163q-.794.1-1.568.198a1.516 1.516 0 0 0-1.338 1.386 90 90 0 0 0 0 12.913c.051.722.62 1.3 1.34 1.36 3.972.333 7.604.333 11.576 0a1.47 1.47 0 0 0 1.34-1.36 90 90 0 0 0 0-12.913 1.516 1.516 0 0 0-1.337-1.386q-.776-.098-1.569-.198m-7.081 6.215h5.718m-5.718 4.36h3.45M9.985 2.73h4.032c1.23 0 2.226.996 2.226 2.226v.001c0 1.23-.997 2.226-2.226 2.226H9.984a2.226 2.226 0 0 1-2.226-2.226v-.001c0-1.23.997-2.226 2.226-2.226"
    />
  </svg>
);
export default SvgIconPageAssessments;
