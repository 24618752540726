import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAssetSpinnerFullColor = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="31 31 182 182" {...props}>
    <g filter="url(#a)">
      <mask id="b" fill="#fff">
        <path d="M98.706 35.067a90 90 0 0 1 46.569-.005L122 122z" />
      </mask>
      <path
        fill="#0D46F2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M98.706 35.067a90 90 0 0 1 46.569-.005L122 122z"
        mask="url(#b)"
      />
      <mask id="c" fill="#fff">
        <path d="M145.294 35.067a90 90 0 0 1 40.332 23.28L122 122z" />
      </mask>
      <path
        fill="#0B3FDA"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M145.294 35.067a90 90 0 0 1 40.332 23.28L122 122z"
        mask="url(#c)"
      />
      <mask id="d" fill="#fff">
        <path d="M185.639 58.36a90 90 0 0 1 23.289 40.328L122 122z" />
      </mask>
      <path
        fill="#0A38C2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M185.639 58.36a90 90 0 0 1 23.289 40.328L122 122z"
        mask="url(#d)"
      />
      <mask id="e" fill="#fff">
        <path d="M208.933 98.706a90 90 0 0 1 .005 46.569L122 122z" />
      </mask>
      <path
        fill="#0D46F2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M208.933 98.706a90 90 0 0 1 .005 46.569L122 122z"
        mask="url(#e)"
      />
      <mask id="f" fill="#fff">
        <path d="M208.933 145.294a90 90 0 0 1-23.28 40.332L122 122z" />
      </mask>
      <path
        fill="#0B3FDA"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M208.933 145.294a90 90 0 0 1-23.28 40.332L122 122z"
        mask="url(#f)"
      />
      <mask id="g" fill="#fff">
        <path d="M185.639 185.64a90 90 0 0 1-40.327 23.288L122 122z" />
      </mask>
      <path
        fill="#0A38C2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M185.639 185.64a90 90 0 0 1-40.327 23.288L122 122z"
        mask="url(#g)"
      />
      <mask id="h" fill="#fff">
        <path d="M145.293 208.933a90 90 0 0 1-46.569.005L122 122z" />
      </mask>
      <path
        fill="#0D46F2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M145.293 208.933a90 90 0 0 1-46.569.005L122 122z"
        mask="url(#h)"
      />
      <mask id="i" fill="#fff">
        <path d="M98.706 208.933a90 90 0 0 1-40.332-23.28L122 122z" />
      </mask>
      <path
        fill="#0B3FDA"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M98.706 208.933a90 90 0 0 1-40.332-23.28L122 122z"
        mask="url(#i)"
      />
      <mask id="j" fill="#fff">
        <path d="M58.36 185.639a90 90 0 0 1-23.289-40.327L122 122z" />
      </mask>
      <path
        fill="#0A38C2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M58.36 185.639a90 90 0 0 1-23.289-40.327L122 122z"
        mask="url(#j)"
      />
      <mask id="k" fill="#fff">
        <path d="M35.066 145.293a90 90 0 0 1-.004-46.569L122 122z" />
      </mask>
      <path
        fill="#0D46F2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M35.066 145.293a90 90 0 0 1-.004-46.569L122 122z"
        mask="url(#k)"
      />
      <mask id="l" fill="#fff">
        <path d="M35.067 98.706a90 90 0 0 1 23.28-40.333L122 122z" />
      </mask>
      <path
        fill="#0B3FDA"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M35.067 98.706a90 90 0 0 1 23.28-40.333L122 122z"
        mask="url(#l)"
      />
      <mask id="m" fill="#fff">
        <path d="M58.36 58.36a90 90 0 0 1 40.328-23.289L122 122z" />
      </mask>
      <path
        fill="#0A38C2"
        stroke="#14171F"
        strokeOpacity={0.2}
        strokeWidth={2}
        d="M58.36 58.36a90 90 0 0 1 40.328-23.289L122 122z"
        mask="url(#m)"
      />
      <g filter="url(#n)">
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M98.706 35.067a90 90 0 0 1 46.588 0L122 122z"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#o)">
        <circle cx={122} cy={122} r={8} fill="#fff" />
      </g>
      <g filter="url(#p)">
        <path fill="#fff" d="m122 44-8-12h16z" />
      </g>
    </g>
    <defs>
      <filter
        id="a"
        width={253.885}
        height={253.885}
        x={-4.943}
        y={-0.943}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1683_35593" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1683_35593" result="shape" />
      </filter>
      <filter
        id="n"
        width={56.588}
        height={100}
        x={93.706}
        y={31}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1683_35593" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1683_35593" result="shape" />
      </filter>
      <filter
        id="o"
        width={24}
        height={24}
        x={110}
        y={114}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1683_35593" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1683_35593" result="shape" />
      </filter>
      <filter
        id="p"
        width={24}
        height={20}
        x={110}
        y={32}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1683_35593" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1683_35593" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default SvgAssetSpinnerFullColor;
