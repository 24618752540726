import { toJSONContent } from '@lessonup/pins-shared';
import { color, cx, elevationShadow, styled } from '@lessonup/ui-components';
import React from 'react';
import { TiptapViewer } from '../../components/Tiptap/TipTapViewer';
import { useTextPinComponentHandlers } from './hooks/useTextPinComponentHandlers';
import { TextPinComponentProps } from './TextPinComponent.types';
import { computeTextPinComponentStyles, getFontShadowClassName } from './TextPinComponent.utils';

export const TextPinComponentWithRenderer: React.FC<TextPinComponentProps> = (props) => {
  const { componentId, settings, pinId, editable, active, isTextEditMode, Renderer, placeholder } = props;
  const {
    registerEditor,
    textPinComponentRef,
    handleMouseDown,
    handleTouchStart,
    handleMouseUpTouchEnd,
    handleMouseMoveTouchMove,
    handleOnBlur,
    handleOnFocus,
    handleOnContextMenu,
  } = useTextPinComponentHandlers(props);
  const styles = computeTextPinComponentStyles(settings, placeholder);

  // TODO ED-624: This is a workaround as we needed to store the content as string to stay within the maximum depth limit in Firestore
  // Remove once we retrieve the JSON from GraphQL
  const content =
    typeof settings.tipTapContent === 'string' ? toJSONContent(settings.tipTapContent) : settings.tipTapContent;
  if (content === null) {
    console.log('Error parsing JSON content', settings.tipTapContent);
    return null;
  }

  return (
    <StyledTextComponent
      onTouchStart={handleTouchStart}
      ref={textPinComponentRef}
      onMouseDown={editable ? handleMouseDown : undefined}
      onMouseMove={editable ? handleMouseMoveTouchMove : undefined}
      onTouchMove={editable ? handleMouseMoveTouchMove : undefined}
      onMouseUp={editable ? handleMouseUpTouchEnd : undefined}
      onTouchEnd={editable ? handleMouseUpTouchEnd : undefined}
      style={styles}
      onContextMenu={handleOnContextMenu}
      className={cx(
        getFontShadowClassName(settings.fontShadow),
        active ? 'active' : undefined,
        isTextEditMode ? 'edit' : undefined
      )}
    >
      {Renderer ? (
        <Renderer
          pinId={pinId}
          pinComponentId={componentId}
          content={settings.tipTapContent}
          onBlur={isTextEditMode ? undefined : handleOnBlur}
          onFocus={handleOnFocus}
          registerEditor={registerEditor}
          active={active}
          isTextEditMode={isTextEditMode}
          editable={editable}
          fragmentKey="f0"
        />
      ) : (
        <TiptapViewer content={content} />
      )}
    </StyledTextComponent>
  );
};

export const TextPinComponent: React.FC<TextPinComponentProps> = (props) => {
  const { settings, active, isTextEditMode, placeholder } = props;
  const styles = computeTextPinComponentStyles(settings, placeholder);

  // TODO ED-624: This is a workaround as we needed to store the content as string to stay within the maximum depth limit in Firestore
  // Remove once we retrieve the JSON from GraphQL
  const content =
    typeof settings.tipTapContent === 'string' ? toJSONContent(settings.tipTapContent) : settings.tipTapContent;
  if (content === null) {
    console.log('Error parsing JSON content', settings.tipTapContent);
    return null;
  }

  return (
    <StyledTextComponent
      style={styles}
      className={cx(
        getFontShadowClassName(settings.fontShadow),
        active ? 'active' : undefined,
        isTextEditMode ? 'edit' : undefined
      )}
    >
      <TiptapViewer content={content} />
    </StyledTextComponent>
  );
};

// The text and items alignment needs an explicit default so that the tiptap child always behaves the same way
const StyledTextComponent = styled.div`
  text-align: var(--component-horizontal-align);
  align-items: var(--component-vertical-align);
  width: 100%;
  height: 100%;
  display: grid;
  word-break: break-word;
  white-space: break-spaces;
  font-family: var(--component-fontfamily);
  font-size: var(--component-fontsize);
  color: var(--component-fontcolor);

  sup {
    vertical-align: top;
    font-size: 0.6em;
  }

  sub {
    vertical-align: bottom;
    font-size: 0.6em;
  }

  &.edit {
    *:focus .selected-text {
      background-color: initial;
    }
    .selected-text {
      background-color: ${color.additional.disabled.background};
    }
  }

  &.active {
    a {
      pointer-events: none;
    }
  }

  &:not(.edit) {
    cursor: inherit;
    caret-color: transparent;
    span::selection {
      background-color: transparent;
      color: inherit;
    }
  }

  user-select: none;

  .ProseMirror:focus {
    outline: none;
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    align-items: var(--component-list-align);
    justify-content: var(--component-list-justify);
    margin: 0;
    padding: 0;

    li {
      margin-left: var(--tiptap-listitem-marker-fontsize, var(--component-fontsize, 24px));
      font-variant-numeric: tabular-nums;

      &::marker {
        color: var(--tiptap-listitem-marker-color, var(--component-fontcolor));
        font-size: var(--tiptap-listitem-marker-fontsize, var(--component-fontsize));
        font-family: var(--tiptap-listitem-marker-fontfamily, var(--component-fontfamily));
        font-weight: var(--tiptap-listitem-marker-fontweight, var(--component-fontweight));
        font-style: var(--tiptap-listitem-marker-fontstyle, var(--component-fontstyle));
      }
    }
  }

  &.shadow-hard {
    text-shadow: ${elevationShadow.e100};
  }
  &.shadow-medium {
    text-shadow: ${elevationShadow.e200};
  }
  &.shadow-soft {
    text-shadow: ${elevationShadow.e300};
  }

  p {
    margin: 0;
    padding: 0;

    &:not(:first-of-type) {
      padding-top: var(--component-paragraph-spacing);
    }
  }

  .ProseMirror p.is-editor-empty:first-of-type::before {
    display: inline-block;
    position: absolute;
    color: #292e3d;
    content: var(--component-placeholder-text);
    font-weight: var(--component-fontweight);
    pointer-events: none;
    left: var(--component-placeholder-left);
    right: var(--component-placeholder-right);
    transform: var(--component-placeholder-transform);
  }

  .collaboration-cursor__caret {
    border-left: 0.5px solid #0d0d0d;
    border-right: 0.5px solid #0d0d0d;
    margin-left: -0.5px;
    margin-right: -0.5px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 1.5px 1.5px 1.5px 0;
    color: #ffffff;
    font-size: 6px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -0.7em;
    user-select: none;
    white-space: nowrap;
  }
`;
