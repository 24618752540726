import {
  AppError,
  Identifiable,
  Modifiable,
  Nullable,
  omitDeepUndefined,
  Ownable,
  Schedulable,
  UserAccessRulable,
} from '@lessonup/utils';
import _ from 'lodash';
import { DragQuestion, OpenQuestion, QuizQuestion, TestPhase } from '../common';
import { FractionalIndex } from '../common/FractionalIndex';
import { AssignmentEntry } from '../entries';
import { LessonData, LessonPin } from '../lesson';
import { MigrationTarget } from '../migrations/MigrationTarget';
import { AssignmentBasicInfo } from './AssignmentBasicInfo';
import { AssignmentType } from './AssignmentComplete';
import { AssignmentContent } from './AssignmentContent';
import { AssignmentSettings } from './AssignmentSettings';
import { AssignmentSummary } from './AssignmentSummary';

/** Dit is het loading voor de teacher voor assignments waarin alle antwoorden staan en waar de queries op uitgevoerd worden. */
/** Assignment wordt enkel opgehaald waar nodig */
export interface AssignmentMeta<T extends AssignmentType = AssignmentType>
  extends UserAccessRulable,
    Ownable,
    Identifiable,
    MigrationTarget,
    Modifiable {
  type: T;
  version?: string;
  answers?: AssignmentAnswers; // correct answers for the assignment
  lessonId: string;
  products?: string[];
  assignmentInfo: AssignmentBasicInfo;
  summary: AssignmentSummary;
  teacherActivityAt?: number; // activity date for the realtime games
  label: string | undefined;
  status: AssignmentSettings.Status;
  groupId: string | undefined;
  module: string | undefined;
  order: FractionalIndex.OrderKey | undefined;
  legacy?: boolean;
  scheduled?: AssignmentMeta.Scheduled;
  raisedHandLog?: AssignmentEntry.RaiseHandCompleted[];
  channel?: string;
  usersWithAccess: string[]; // TODO Needed?
}

export interface AssignmentAnswers {
  [pinId: string]: AnyCorrectAnswer;
}

export type AnyCorrectAnswer = OpenQuestion.CorrectAnswer | QuizQuestion.CorrectAnswer | DragQuestion.CorrectAnswer;

export namespace AssignmentMeta {
  export interface WithSettings {
    meta: AssignmentMeta;
    settings: AssignmentSettings;
  }

  export type WithModule<T extends AssignmentType = AssignmentType> = AssignmentMeta<T> & {
    groupId: NonNullable<AssignmentMeta['groupId']>;
    module: NonNullable<AssignmentMeta['module']>;
    order: NonNullable<AssignmentMeta['order']>;
  };

  export type Scheduled = {
    startDate?: number;
    closeDate?: number;
    started?: boolean;
    closed?: boolean;
    startedDate?: number;
    closedDate?: number;
    duration?: number;
  };

  export type Test = AssignmentMeta<'test'>;
  export type Homework = AssignmentMeta<'homework'>;
  export type Realtime = AssignmentMeta<'realtime'>;

  export const excludedComparisonPaths: string[] = ['createdAt', 'modifiedAt', 'summary.modifiedAt', 'migratedAt'];

  export function scheduledFromOptions(options: AssignmentContent.TimeOptions): Scheduled | undefined {
    const startDate = options.startDate ? new Date(options.startDate).getTime() : undefined;
    const closeDate = options.closeDate ? new Date(options.closeDate).getTime() : undefined;
    const duration = options.duration;
    if (!startDate && !closeDate && !duration) return undefined;
    if (startDate && startDate < Date.now()) {
      throw new AppError('invalid-params', "start time can't be in the past");
    }
    return {
      startDate,
      closeDate,
      started: startDate ? false : undefined,
      closed: closeDate ? false : undefined,
      duration: options.duration,
    };
  }

  export function createFromLesson(
    assignmentId: string,
    type: AssignmentType,
    ownerId: string,
    now: number,
    teachers: string[],
    assignmentInfo: AssignmentBasicInfo,
    lessonData: LessonData,
    options: AssignmentContent.Options
  ): AssignmentMeta {
    const { label, groupId, legacy, module, order } = options;
    const meta: AssignmentMeta = {
      _id: assignmentId,
      status: 'open',
      type,
      groupId,
      module,
      order,
      label,
      lessonId: lessonData.lesson._id,
      products: lessonData.lesson.products || [],
      ownerId,
      createdAt: now,
      modifiedAt: now,
      answers: LessonPin.correctAnswersForPins(lessonData.pins),
      usersWithAccess: teachers,
      assignmentInfo,
      summary: AssignmentSummary.empty(),
      teacherActivityAt: now,
      scheduled: scheduledFromOptions(options),
      legacy,
      channel: lessonData.lesson.channel,
    };

    return meta;
  }

  /** creates an in memory clone with the updated data */
  // would be nice if we can merge this with the update fields in the future
  export function update(meta: AssignmentMeta, updates: AssignmentMeta.UpdateData): AssignmentMeta {
    const cloned = _.cloneDeep(meta);
    const updateFields = AssignmentMeta.updateFields(updates);
    _.forEach(updateFields, (value, field) => _.set(cloned, field, value));

    return cloned;
  }

  export function newest(metas: AssignmentMeta[]): AssignmentMeta | undefined {
    return _.first(orderByDate(metas));
  }

  export function orderByDate(metas: AssignmentMeta[]): AssignmentMeta[] {
    return _.orderBy(metas, (meta) => meta.createdAt);
  }

  export function isTest(meta: AssignmentMeta): meta is AssignmentMeta.Test {
    return meta.type === 'test';
  }

  export function isHomework(meta: AssignmentMeta): meta is AssignmentMeta.Homework {
    return meta.type === 'homework';
  }

  export function isRealtime(meta: AssignmentMeta): meta is AssignmentMeta.Realtime {
    return meta.type === 'realtime';
  }

  export function isGroupAssignment(meta: AssignmentMeta): boolean {
    return !!meta.groupId;
  }

  export function hasProgress(meta: AssignmentMeta): boolean {
    return (meta.summary && meta.summary.percentagePinsDone > 0) || false;
  }

  export function isAnyUserInTestPhase(meta: AssignmentMeta, phases: TestPhase[]): boolean {
    return (meta.summary.phaseCounts && TestPhase.isAnyUserInTestPhase(meta.summary.phaseCounts, phases)) || false;
  }

  export function isLiveTest(meta: AssignmentMeta): boolean {
    const earliestPhase = meta.summary.phaseCounts && TestPhase.earliestTestPhase(meta.summary.phaseCounts);
    return TestPhase.isLive(earliestPhase) || false;
  }

  export function earliestPhase(meta: AssignmentMeta): TestPhase | undefined {
    return meta.summary.phaseCounts && TestPhase.earliestTestPhase(meta.summary.phaseCounts);
  }

  export function answer(meta: AssignmentMeta, pinId: string): AnyCorrectAnswer | undefined {
    return meta.answers && meta.answers[pinId];
  }

  export function hasCorrectAnswer(meta: AssignmentMeta, pinId: string): boolean | undefined {
    return meta.answers && !_.isNil(meta.answers[pinId]);
  }

  export function scheduledStartDate(meta: AssignmentMeta | undefined): Date | undefined {
    if (!meta || !meta.scheduled || !meta.scheduled.startDate || meta.scheduled.started) return;
    return new Date(meta.scheduled.startDate);
  }

  export function scheduledCloseDate(meta: AssignmentMeta | undefined): Date | undefined {
    if (!meta || !isTest(meta) || !meta.scheduled || !meta.scheduled.closeDate || meta.scheduled.closed) return;
    return new Date(meta.scheduled.closeDate);
  }
  export function scheduledDuration(meta: AssignmentMeta | undefined): number | undefined {
    if (!meta || !isTest(meta)) return;
    return meta.scheduled?.duration;
  }

  export function schedulable(meta: AssignmentMeta): Schedulable {
    return {
      startDate: AssignmentMeta.scheduledStartDate(meta)?.getTime(),
      closeDate: AssignmentMeta.scheduledCloseDate(meta)?.getTime(),
    };
  }

  export function correctAnswerForPin<T extends AnyCorrectAnswer>(
    meta: AssignmentMeta | undefined,
    pinId: string
  ): T | undefined {
    return meta && (_.get(meta.answers, pinId) as T);
  }

  export function isChannelBased(meta: AssignmentMeta | undefined): boolean {
    return !!meta?.channel;
  }

  export function isProductBased(meta: AssignmentMeta | undefined): boolean {
    return !!meta?.products?.length;
  }
}

export namespace AssignmentMeta {
  type UpdateFields = Partial<
    Pick<
      AssignmentMeta,
      'usersWithAccess' | 'summary' | 'teacherActivityAt' | 'ownerId' | 'raisedHandLog' | 'module' | 'order'
    >
  > &
    Partial<AssignmentMeta.Scheduled> & {
      status?: AssignmentSettings.Status;
      pinAnswer?: {
        pinId: string;
        answer: AnyCorrectAnswer;
      };
    };

  export type UpdateData = Nullable<UpdateFields>;

  export function updateFields(data: AssignmentMeta.UpdateData): object {
    const updateFields: _.Dictionary<any> = {};

    if (!_.isNil(data.ownerId)) updateFields['ownerId'] = data.ownerId;
    if (!_.isNil(data.summary)) updateFields['summary'] = data.summary;
    if (!_.isNil(data.status)) updateFields['status'] = data.status;
    if (!_.isNil(data.usersWithAccess)) updateFields['usersWithAccess'] = data.usersWithAccess;
    if (!_.isNil(data.teacherActivityAt)) updateFields['teacherActivityAt'] = data.teacherActivityAt;
    if (!_.isUndefined(data.startDate)) updateFields['scheduled.startDate'] = data.startDate;
    if (!_.isUndefined(data.started)) updateFields['scheduled.started'] = data.started;
    if (!_.isUndefined(data.startedDate)) updateFields['scheduled.startedDate'] = data.startedDate;
    if (!_.isUndefined(data.closeDate)) updateFields['scheduled.closeDate'] = data.closeDate;
    if (!_.isUndefined(data.closed)) updateFields['scheduled.closed'] = data.closed;
    if (!_.isUndefined(data.closedDate)) updateFields['scheduled.closedDate'] = data.closedDate;
    if (!_.isUndefined(data.duration)) updateFields['scheduled.duration'] = data.duration;
    if (!_.isUndefined(data.raisedHandLog)) updateFields['raisedHandLog'] = data.raisedHandLog;
    if (!_.isNil(data.module)) updateFields['module'] = data.module;
    if (!_.isNil(data.order)) updateFields['order'] = data.order;

    if (!_.isNil(data.pinAnswer) && data.pinAnswer.pinId) {
      updateFields[`answers.${data.pinAnswer.pinId}`] = data.pinAnswer.answer;
    }

    const cleaned = omitDeepUndefined(updateFields);
    if (!_.isEmpty(cleaned)) {
      cleaned['modifiedAt'] = Date.now();
    }
    return cleaned;
  }
}
