import { createModal, ManagedModal, NiceModal, styled, useModal } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RenameModalContent } from '../components/RenameModalContent/RenameModalContent';
import { i18nextNamespace } from '../UploadsFeature.utils';

export interface RenameModalProps {
  currentName: string;
  handleRename: (newName: string) => Promise<boolean>;
}

export const RenameModal = createModal((props: RenameModalProps) => {
  const { currentName, handleRename } = props;
  const modal = useModal();
  const { t } = useTranslation(i18nextNamespace);

  const onNewName = async (name: string) => {
    const res = await handleRename(name);
    if (res) {
      modal.hide();
    }
  };

  return (
    <ManagedModal modal={modal} contentLabel={t('previewModal.contentLabel')}>
      <ContentWrapper>
        <RenameModalContent handleRename={onNewName} onClose={modal.hide} currentName={currentName} />
      </ContentWrapper>
    </ManagedModal>
  );
});

export const showRenameModal = (props: RenameModalProps): Promise<string> => NiceModal.show(RenameModal, props);

/**
 * Setting the width of the content does not seem to work without a wrapping div (maybe because of the React.Fragment in the content?)
 */
const ContentWrapper = styled.div`
  width: 320px;
`;
