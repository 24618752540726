import { ShadowSettingKey } from '@lessonup/pins-shared';

type NonOptionalShadowSettingKey = Exclude<ShadowSettingKey, undefined>;

// These are to be used on pin components
// Note that if you add a 4th spread px value, the effect won't work with drop shadows
export const shadowSizes: Record<NonOptionalShadowSettingKey, string> = {
  NONE: '',
  HARD: '0px 1px 1px #00134359',
  MEDIUM: '0px 4px 4px #00134359',
  SOFT: '0px 8px 8px #00134359',
};

// These are to be used on the shadow setting previews in the sidebar
export const shadowPreviewSizes: Record<NonOptionalShadowSettingKey, string> = {
  NONE: '',
  HARD: '0px 0.5px 0.5px #00134359',
  MEDIUM: '0px 2px 2px #00134359',
  SOFT: '0px 4px 4px #00134359',
};

export function computeShadowFilter(shadow?: ShadowSettingKey) {
  return (shadow && shadowSizes[shadow]) ?? '';
}
