import { logger, useDocumentMutation } from '@lessonup/client-integration';
import { useErrorContext } from '@lessonup/ui-components';
import { asError, triggerDownloadFromFetchResponse } from '@lessonup/utils';
import { useTranslation } from 'react-i18next';
import { DownloadOriginalUploadFileDocument } from '../Uploads.graphql.generated';
import { i18nextNamespace } from '../UploadsFeature.utils';

export const useDownloadUpload = () => {
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();

  const [downloadUpload] = useDocumentMutation(DownloadOriginalUploadFileDocument);

  const handleDownloadUpload = async (uploadId: string, uploadName: string) => {
    try {
      const { data } = await downloadUpload({
        variables: { input: { uploadId } },
      });

      if (!data) {
        throw new Error('could not find data when downloading upload');
      }

      const downloadUrl = data.downloadOriginalUploadFile.url;
      const contentDispositionHeader = data.downloadOriginalUploadFile.contentDispositionHeader;
      const uploadFile = await fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'Content-Disposition': contentDispositionHeader,
        },
      });
      await triggerDownloadFromFetchResponse(uploadFile, uploadName);
    } catch (error: unknown) {
      logger.error('error while downloading file', { error: asError(error) });
      // @TODO remove casting on https://lessonup.atlassian.net/browse/UP-133
      setError({
        error: asError(error),
        customErrorDisplayMessage: { title: '', description: t('somethingWentWrongWhileDownloadingUpload') },
      });
    }
  };

  return handleDownloadUpload;
};
