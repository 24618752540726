import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLogoGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#4285F4"
      fillRule="evenodd"
      d="M21.8 12.222c0-.707-.064-1.39-.183-2.044H12.2v3.868h5.382a4.6 4.6 0 0 1-1.996 3.018v2.51h3.231c1.891-1.742 2.982-4.305 2.982-7.35z"
      clipRule="evenodd"
    />
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M12.203 22c2.7 0 4.963-.896 6.618-2.422l-3.233-2.511c-.895.6-2.04.955-3.385.955-2.605 0-4.809-1.76-5.596-4.123h-3.34v2.591A10 10 0 0 0 12.202 22"
      clipRule="evenodd"
    />
    <path
      fill="#FBBC05"
      fillRule="evenodd"
      d="M6.604 13.902c-.2-.6-.313-1.24-.313-1.9s.113-1.3.313-1.9v-2.59H3.263a10.005 10.005 0 0 0 0 8.982z"
      clipRule="evenodd"
    />
    <path
      fill="#EA4335"
      fillRule="evenodd"
      d="M12.203 5.978c1.468 0 2.787.504 3.822 1.494l2.869-2.866C17.16 2.99 14.897 2 12.202 2a10 10 0 0 0-8.936 5.509L6.607 10.1c.787-2.363 2.991-4.122 5.596-4.122"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconLogoGoogle;
