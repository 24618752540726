import {
  ANONYMOUS_COOKIE_KEY,
  randomIdWithPrefix,
  SESSION_COOKIE_KEY,
  TrackerAction,
  TrackerParams,
  TrackingUserIds,
  UserDimensions,
} from '@lessonup/utils';
import { isEqual } from 'lodash';
import { getCookieValueByName, setCookie } from '../cookies/cookieUtils';

export interface TrackActionConfig {
  tracker?: string;
}

export interface PageParams {
  path: string;
}

export interface TrackerConsentSettings {
  analytic: boolean;
  marketing: boolean;
}

export interface ActionTrackerTransport {
  setUserId(userId: string | undefined): void;
  setCustomDimensions(params: UserDimensions): void;

  event<K extends TrackerAction>(
    action: K,
    // copied from the old trackActionClient, can probably be simplified */
    params: (TrackerParams<K> & TrackActionConfig) | undefined,
    userIds: Partial<TrackingUserIds>
  ): void;
}

export class ActionTracker {
  private readonly transports: ActionTrackerTransport[] = [];
  private lastEvent?: { params: TrackerParams<TrackerAction>; action: TrackerAction } = undefined;
  private consent: TrackerConsentSettings | undefined;

  public addTransport(transport: ActionTrackerTransport): void {
    this.transports.push(transport);
  }

  public setCustomDimensions(params: UserDimensions): void {
    this.transports.forEach((t) => t.setCustomDimensions(params));
  }

  public setConsent(consent: TrackerConsentSettings | undefined): void {
    this.consent = consent;
  }

  public event<K extends TrackerAction>(action: K, params: (TrackerParams<K> & TrackActionConfig) | undefined): void {
    if (isEqual({ action, params }, this.lastEvent)) return;
    this.lastEvent = { action, params };

    const userIds = this.userIdsFromCookies();

    this.transports.forEach((t) => t.event(action, params, userIds));
  }

  public setUserId(userId: string | undefined): void {
    this.transports.forEach((t) => t.setUserId(userId));
  }

  private userIdsFromCookies(): Partial<TrackingUserIds> {
    const allowTracking = this.consent?.analytic === true;
    const sId = getOrCreateSessionCookie(allowTracking);
    const aId = getOrCreateAnonymousUserCookie(allowTracking);

    return { sId, aId };
  }
}

export const globalTracker = new ActionTracker();

function getOrCreateAnonymousUserCookie(allowTracking: boolean) {
  if (!allowTracking) return undefined;

  const cookie = getCookieValueByName(ANONYMOUS_COOKIE_KEY);
  if (cookie) return cookie;

  const value = randomIdWithPrefix(ANONYMOUS_COOKIE_KEY);
  const tenYears = 10 * 365 * 24 * 60 * 60;
  setCookie({ name: ANONYMOUS_COOKIE_KEY, value, maxAge: tenYears, path: '/' });
  return value;
}

function getOrCreateSessionCookie(allowTracking: boolean) {
  if (!allowTracking) return undefined;

  const cookie = getCookieValueByName(SESSION_COOKIE_KEY);
  if (cookie) {
    return cookie;
  }
  const value = randomIdWithPrefix(SESSION_COOKIE_KEY);
  setCookie({ name: SESSION_COOKIE_KEY, value, path: '/' });
  return value;
}
