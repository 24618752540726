import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, styled } from '@lessonup/ui-components';
import React, { CSSProperties, memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { usePinRailListContext } from '../context/usePinRailListContext';
import { PinAndPhase } from '../model';
import { isPhase, isPhaseAdd } from '../PinRail.utils';
import { PinRailAdd } from './PinRailAdd';
import { PinRailPhaseStartAndEnd } from './PinRailPhaseStartAndEnd';
import { SortablePinRailThumb } from './PinRailThumb';

interface SortableItemProps {
  children: React.ReactNode;
  phase: PinAndPhase;
  style: CSSProperties;
  sortable?: boolean | undefined;
}
const SortableItem: React.FC<SortableItemProps> = ({ children, phase, style, sortable }) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: phase.id,
    disabled: !sortable,
  });

  return (
    <SortableItemWrapper
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
      transition={transition}
      transform={CSS.Transform.toString(transform)}
    >
      {children}
    </SortableItemWrapper>
  );
};

const SortableItemWrapper = styled.div<{
  isDragging: boolean;
  transition: string | null | undefined;
  transform: string | null | undefined;
}>`
  ${(props) => css`
    opacity: ${props.isDragging ? 0 : 1};
    transition: ${props.transition ?? undefined};
    transform: ${props.transform ?? undefined};
  `};
`;

export const PinRailListItem = memo(({ data, index, style }: ListChildComponentProps<PinAndPhase[]>) => {
  const {
    setActivePinId,
    setSelectedPinIds,
    selectedPinIds,
    dbPins,
    activePinId,
    inPinSelectionMode,
    collaboratorsDict,
    contextMenuHandler,
    handleOnMouseDown,
  } = usePinRailListContext();

  const pinOrPhase = data[index];

  if (isPhaseAdd(pinOrPhase)) {
    return (
      <SortableItem style={style} key={pinOrPhase.id} phase={pinOrPhase}>
        <PinRailAdd
          phaseNumber={pinOrPhase.number}
          lastPinId={dbPins[dbPins.length - 1]?.id}
          setActivePinId={setActivePinId}
        />
      </SortableItem>
    );
  }

  if (isPhase(pinOrPhase)) {
    return (
      <SortableItem style={style} key={pinOrPhase.id} phase={pinOrPhase}>
        <PinRailPhaseStartAndEnd
          phase={pinOrPhase}
          setActivePinId={setActivePinId}
          lastPinId={dbPins[dbPins.length - 1]?.id}
        />
      </SortableItem>
    );
  }

  return (
    <SortableItem style={style} key={pinOrPhase.id} phase={pinOrPhase} sortable={true}>
      <SortablePinRailThumb
        pin={pinOrPhase}
        index={dbPins.findIndex((pin) => pin.id === pinOrPhase.id)}
        isActive={pinOrPhase.id === activePinId}
        handleOnContextMenu={contextMenuHandler}
        overwriteCheckboxVisibility={inPinSelectionMode}
        selectedPinIds={selectedPinIds}
        setSelectedPinIds={setSelectedPinIds}
        collaborators={collaboratorsDict[pinOrPhase.id]}
        isSelected={selectedPinIds.includes(pinOrPhase.id)}
        handleOnMouseDown={handleOnMouseDown}
      />
    </SortableItem>
  );
}, areEqual);
PinRailListItem.displayName = 'PinRailListItem';
