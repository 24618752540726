import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconVisibilityOn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.375 12c0 1.575-4.197 7.125-9.375 7.125S2.625 13.575 2.625 12 6.822 4.875 12 4.875s9.375 5.55 9.375 7.125"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
    />
  </svg>
);
export default SvgIconVisibilityOn;
