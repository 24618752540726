import * as Types from '../../types/graphql/types.generated';

import { TeacherPin } from '@lessonup/pins-shared';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type IntercomUserHashQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IntercomUserHashQuery = { __typename?: 'Query', viewer: { __typename?: 'Viewer', id: string, intercomHash: string } };


export const IntercomUserHashDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IntercomUserHash"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"intercomHash"}}]}}]}}]} as unknown as DocumentNode<IntercomUserHashQuery, IntercomUserHashQueryVariables>;