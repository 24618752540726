import { RefObject, useEffect, useRef, useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ErrorDisplayMessage, ErrorToErrorDisplayMessage, useErrorContext } from '@lessonup/ui-components';
import { asError, assertNever } from '@lessonup/utils';

import { AudioContextHandler } from '../AudioContextHandler';
import { BlobConverter, SourceDataObject } from '../BlobConverter';
import { MediaFileHandler } from '../MediaFileHandler';
import { CustomMediaRecorderError } from '../MediaRecorder.utils';
import { MediaRecorderHandler } from '../MediaRecorderHandler';

const i18nextNamespace = 'mediaRecorder';

type LastRecording = {
  sourceDataObject: SourceDataObject;
  dataTransfer: DataTransfer;
};

type UseMediaRecorderOutput = {
  isRecording: boolean;
  audioContext: AudioContextHandler | null;
  lastRecording: SourceDataObject | null;
  stream: MediaStream | null;
  error: Error | null;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  cancelRecording: () => void;
};

type MediaFileOutput = 'wav';

export const useMediaRecorder = (
  constraints: MediaStreamConstraints,
  output: MediaFileOutput,
  fileInputRef?: RefObject<HTMLInputElement | null>
): UseMediaRecorderOutput => {
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();
  const mediaRecorderHandlerRef = useRef(new MediaRecorderHandler(constraints));
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContextHandler | null>(null);
  const [lastRecording, setLastRecording] = useState<LastRecording | null>(null);
  const [errorState, setErrorState] = useState<Error | null>(null);
  const customErrorParser = createMediaRecorderErrorParser(t);

  useEffect(() => {
    mediaRecorderHandlerRef.current
      .startFeed()
      .then((stream) => {
        setStream(stream);
        if (constraints.audio) {
          setAudioContext(mediaRecorderHandlerRef.current.getAudioContextHandler());
        }
      })
      .catch((error) => {
        const verifiedError = asError(error);

        // create custom error parser for legacy / permissions / other
        // if no permission we should create an article with the relevant links depending on browser.
        setError({ error: verifiedError, customErrorParser });
        setErrorState(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => mediaRecorderHandlerRef.current.cancelRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startRecording = async () => {
    try {
      await mediaRecorderHandlerRef.current.startRecording();
      setIsRecording(true);
    } catch (error) {
      const verifiedError = asError(error);

      if (verifiedError.message !== CustomMediaRecorderError.NoMediaStreamFound) {
        setError({ error: verifiedError, customErrorParser });
      }

      setErrorState(verifiedError);
      setIsRecording(false);
    }
  };

  const stopRecording = async () => {
    try {
      const blob = await mediaRecorderHandlerRef.current.stopRecording();
      const { dataTransfer, sourceDataObject } = await createFile(blob, output);

      setLastRecording({ dataTransfer, sourceDataObject });
      if (fileInputRef) {
        setFileInputAsDataTransfer(fileInputRef, dataTransfer);
      }
    } catch (error) {
      setError({ error: error as Error, customErrorParser });
    } finally {
      setIsRecording(false);
    }
  };

  const cancelRecording = () => {
    try {
      mediaRecorderHandlerRef.current.cancelRecording();
    } catch (error) {
      setError({ error: error as Error, customErrorParser });
    } finally {
      setLastRecording(null);
      setIsRecording(false);
    }
  };

  return {
    isRecording,
    audioContext,
    lastRecording: lastRecording ? lastRecording.sourceDataObject : null,
    stream,
    error: errorState,
    startRecording,
    stopRecording,
    cancelRecording,
  };
};

const createFile = (blob: Blob, output: MediaFileOutput) => {
  switch (output) {
    case 'wav':
      return createAudioFileFromBlob(blob);
    default:
      assertNever(output, 'Unsupported output type');
  }
};

const createAudioFileFromBlob = async (blob: Blob) => {
  const processedBlob = await new BlobConverter().convertToWave(blob);

  const timestamp = new Date().getTime();
  const file = MediaFileHandler.fileFromBlob(blob, {
    name: `audio_recording_${timestamp}.wav`,
    type: 'audio/wav',
    lastModified: timestamp,
  });

  const dataTransfer = MediaFileHandler.fileToDataTransfer(file);

  const sourceDataObject = await new BlobConverter().toSourceDataObject(processedBlob);

  return { dataTransfer, sourceDataObject };
};

const setFileInputAsDataTransfer = (fileInputRef: RefObject<HTMLInputElement | null>, dataTransfer: DataTransfer) => {
  if (!fileInputRef.current) return;

  fileInputRef.current.files = dataTransfer.files;

  /**
   * Help Safari out
   * @see https://pqina.nl/blog/set-value-to-file-input/
   */
  if (fileInputRef.current.webkitEntries.length) {
    fileInputRef.current.dataset.file = `${dataTransfer.files[0].name}`;
  }
};

const createMediaRecorderErrorParser: (t: TFunction) => ErrorToErrorDisplayMessage = (t: TFunction) => {
  return (error: Error): ErrorDisplayMessage => {
    const isFeatureUnsupported = error.message.includes('not supported in this browser.');

    if (isFeatureUnsupported) {
      return { title: '', description: t('legacyBrowser') };
    }

    const isPermissionDenied = error.message.includes('Permission denied');

    if (isPermissionDenied) {
      return { title: '', description: t('permissionDenied') };
    }

    return { title: '', description: t('somethingWentWrong') };
  };
};
