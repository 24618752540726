import React, { ComponentProps, forwardRef } from 'react';
import { useAsyncLoadingState } from '../../utils/hooks';

import { Button } from '../Button/Button';

export type AsyncMouseEventHandler = (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
export interface AsyncButtonProps extends ComponentProps<typeof Button> {
  onClick: AsyncMouseEventHandler;
  customErrorHandler?: (error: unknown) => void;
}

export const AsyncButton = forwardRef<HTMLButtonElement, AsyncButtonProps>(
  ({ onClick, customErrorHandler, ...props }, ref) => {
    const { loading, asyncWrapper } = useAsyncLoadingState({ errorHandler: customErrorHandler });

    return (
      <Button
        {...props}
        ref={ref}
        onClick={(event) => asyncWrapper(() => onClick(event))}
        loading={loading || props.loading}
      />
    );
  }
);

AsyncButton.displayName = 'AsyncButton';
