import { Grid, rem, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { Report, ReportLesson } from '../../utils/types';
import { QuestionsListCard } from '../QuestionsListCard/QuestionsListCard';
import { StudentListCard } from '../StudentListCard/StudentListCard';
import { SummaryCard } from '../SummaryCard/SummaryCard';

export interface ReportDetailsProps {
  report: Omit<Report, 'lesson'> & { lesson: Pick<ReportLesson, 'name'> };
}

export const ReportDetails: React.FC<ReportDetailsProps> = ({ report }) => {
  return (
    <ReportsWrapper cols={2} gap={spacing.size16} justifyItems="stretch" alignItems="stretch">
      <LeftGroup direction="y" spacing={spacing.size16}>
        <SummaryCard report={report} />
        <StyledStudentListCard students={report.studentReports} />
      </LeftGroup>
      <RightGroup direction="y" spacing={spacing.size16}>
        <StyledQuestionsListCard questions={report.questionReports} />
      </RightGroup>
    </ReportsWrapper>
  );
};

const ReportsWrapper = styled(Grid)`
  position: relative;
  height: 100%;
  width: 100%;
  grid-template-columns: ${rem('320px')} 1fr;
  grid-template-rows: 1fr;
`;

const LeftGroup = styled(SpaceBetween)`
  min-height: 0; // Fixes overflow problem for student card
`;

const RightGroup = styled(SpaceBetween)`
  min-height: 0; // Fixes overflow problem for questions card
`;

const StyledStudentListCard = styled(StudentListCard)`
  display: flex;
  flex-direction: column;
`;

const StyledQuestionsListCard = styled(QuestionsListCard)`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;
