import { addParamsToLink } from '../../utils/links/links.utils';

export enum REFERRAL_MEDIUM {
  QR_CODE = 'qr_code',
  EMAIL = 'email',
  SHARED_LINK = 'shared_link',
}

const REFERRAL_SOURCE = 'teacher';

const REFERRAL_CAMPAIGN = 'referral';

export const addUtmParamsToLink = (link: string, medium: REFERRAL_MEDIUM) => {
  const params: Record<string, string> = {
    utm_source: REFERRAL_SOURCE,
    utm_medium: medium,
    utm_campaign: REFERRAL_CAMPAIGN,
  };

  return addParamsToLink(link, params);
};
