import { isQuizPin, TeacherPin } from '@lessonup/pin-renderer';
import { flatMap } from 'lodash';

export type PreviewTimelineEntry = {
  pin: TeacherPin;
  step: number;
};

/**
 * Temporary example on how to introduce multiple steps on a pin type
 */
export function mapPinsToPreviewTimeline(pins: TeacherPin[]): PreviewTimelineEntry[] {
  return flatMap<TeacherPin, PreviewTimelineEntry>(pins, (pin) => {
    if (isQuizPin(pin)) {
      return [{ pin, step: 1 }];
    }
    return { pin, step: 1 };
  });
}

export function findPreviewTimelineEntryIndexByPinID(timeline: PreviewTimelineEntry[], pinId?: string) {
  if (!pinId) {
    return 0;
  }
  return timeline.findIndex((timelineEntry) => timelineEntry.pin.id === pinId);
}
