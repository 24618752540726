import { useDocumentQuery, useLocale } from '@lessonup/client-integration';
import { useCallback } from 'react';
import { referralRoute } from '../../../utils/bridge/searchRoutes';
import { ReferralDocument } from './Referral.graphql.generated';

export interface UseReferralLinkProps {
  baseUrl: string;
}

export const useReferral = ({ baseUrl }: UseReferralLinkProps) => {
  const { language } = useLocale();
  const { loading, error, data } = useDocumentQuery(ReferralDocument, {
    fetchPolicy: 'cache-and-network',
  });

  const prepareReturnData = useCallback(() => {
    if ((loading && !data) || error) return undefined;
    const constructRegistrationUrl = (token: string) => {
      return new URL(referralRoute.href({ language, token }), baseUrl);
    };

    const { token, referredUsers } = data?.viewer?.referral || {};
    const { amountOfDays } = data?.activeRefereeTrialGift || {};

    return {
      link: token ? constructRegistrationUrl(token).toString() : '',
      token,
      referralCount: referredUsers?.totalCount,
      amountOfExtraTrialDays: amountOfDays,
    };
  }, [loading, data, error, language, baseUrl]);
  return { data: prepareReturnData(), loading, error };
};
