import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconTeachingSessions = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.597 8.711a6.553 6.553 0 1 0 8.693 8.69m-1.455.145a7.381 7.381 0 1 0 0-14.762 7.381 7.381 0 0 0 0 14.762"
    />
  </svg>
);
export default SvgIconTeachingSessions;
