import {
  BodyText,
  Button,
  Card,
  color,
  IconArrowRight,
  IconPlaceholder,
  List,
  ListItemProps,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { getRouteForAsset } from '@lessonup/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { EditorPreference } from '../CreateLessonModal';
import { LessonModalContent, LessonModalMainWrapper } from './LayoutComponents';

interface IntroScreenProps {
  setEditorPreference: (preference: string) => void;
  handleEditorSelection: (editor: EditorPreference) => void;
}

export const IntroScreen: React.FC<IntroScreenProps> = ({ setEditorPreference, handleEditorSelection }) => {
  const { t } = useTranslation('createLessonModal');

  const handleIntroSelection = (editor: EditorPreference) => {
    setEditorPreference(editor);
    handleEditorSelection(editor);
  };

  const classicListItems: ListItemProps[] = [
    { text: t('intro.classicEditorListItem1'), indentation: true },
    { text: t('intro.classicEditorListItem2'), indentation: true },
    { text: t('intro.classicEditorListItem3'), indentation: true },
  ];
  const newListItems: ListItemProps[] = [
    { text: t('intro.newEditorListItem1'), indentation: true },
    { text: t('intro.newEditorListItem2'), indentation: true },
    { text: t('intro.newEditorListItem3'), indentation: true },
  ];

  return (
    <LessonModalMainWrapper>
      <LessonModalContent>
        <StyledSpaceBetween direction="x" spacing={spacing.size16} style={{ paddingBottom: spacing.size16 }}>
          <Card resizing="fixed" autoHeight>
            <StyledSpaceBetween direction="y" spacing={spacing.size4}>
              <Img src={getRouteForAsset('img/editorInstructions/classicEditor.png')} alt="" />
              <StyledInnerCardSpacing direction="y" spacing={spacing.size0}>
                <BodyText size="medium" weight="bold">
                  {t('intro.introClassic')}
                </BodyText>
                <List orderedList items={classicListItems} listStyle="disc" />
                <StyledButton
                  onClick={() => handleIntroSelection('classic')}
                  iconStart={<IconPlaceholder color={color.message.warning.on} />}
                  buttonType="neutral"
                  showStroke
                  buttonShape="rect"
                  iconEnd={<IconArrowRight />}
                  size="medium"
                >
                  {t('intro.classicEditorAction')}
                </StyledButton>
              </StyledInnerCardSpacing>
            </StyledSpaceBetween>
          </Card>
          <Card resizing="fixed" autoHeight>
            <StyledSpaceBetween direction="y" spacing={spacing.size4}>
              <Img src={getRouteForAsset('img/editorInstructions/newEditor.png')} alt="" />
              <StyledInnerCardSpacing direction="y" spacing={spacing.size0}>
                <BodyText size="medium" weight="bold">
                  {t('intro.introNew')}
                </BodyText>
                <List orderedList items={newListItems} listStyle="disc" />
                <StyledButton
                  onClick={() => handleIntroSelection('new')}
                  iconStart={<IconPlaceholder color={color.message.positive.on} />}
                  buttonType="neutral"
                  showStroke
                  buttonShape="rect"
                  iconEnd={<IconArrowRight />}
                  size="medium"
                >
                  {t('intro.newEditorAction')}
                </StyledButton>
              </StyledInnerCardSpacing>
            </StyledSpaceBetween>
          </Card>
        </StyledSpaceBetween>
      </LessonModalContent>
    </LessonModalMainWrapper>
  );
};

const StyledInnerCardSpacing = styled(SpaceBetween)`
  height: 100%;
  flex-grow: 1;
  display: flex;
  padding: ${spacing.size16};
`;

const StyledSpaceBetween = styled(SpaceBetween)`
  height: 100%;
  flex-grow: 1;
  display: flex;
`;

const Img = styled.img`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: auto !important;
`;
