import { LayoutPartDict, Pin, PinComponentId } from '@lessonup/pins-shared';
import { camelCase, findKey } from 'lodash';

const basePlaceholderTranslationKey = 'text.placeholder';

export function getPlaceholderTranslation(
  pinType: Pin['type'],
  pinComponentId: PinComponentId,
  layoutKey: Pin['settings']['layout'],
  layoutParts?: LayoutPartDict
) {
  if (layoutKey) {
    const layoutPartName = findKey(layoutParts, (value) => value === pinComponentId);
    if (layoutPartName) {
      return `${basePlaceholderTranslationKey}.${camelCase(pinType)}.${camelCase(layoutKey)}.${camelCase(
        layoutPartName
      ).replace(/[0-9]/, '')}`;
    }
  }

  return `${basePlaceholderTranslationKey}.default`;
}
