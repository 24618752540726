import { useFullscreenToggle } from '@lessonup/client-integration';
import { ProgressBar } from '@lessonup/ui-components';
import React from 'react';
import { PlayerBarTopInterfaceControls } from '../../../../components/PlayerBar/PlayerBarTop/components/PlayerBarTopInterfaceControls';
import {
  PlayerBarTopEndSection,
  PlayerBarTopMiddleSection,
  PlayerBarTopStartSection,
} from '../../../../components/PlayerBar/PlayerBarTop/components/PlayerBarTopModules';
import { PlayerBarTop } from '../../../../components/PlayerBar/PlayerBarTop/PlayerBarTop';
import { PlayerBarTopHandlerProps } from './PlayerBarTopHandler.utils';

export const PreviewPlayerBarTopHandler: React.FC<PlayerBarTopHandlerProps> = ({
  currentSlideIndex,
  maxSlides,
  onClose,
  showLabels,
}) => {
  const [isFullscreen, toggleFullscreen] = useFullscreenToggle();

  return (
    <PlayerBarTop>
      <PlayerBarTopStartSection></PlayerBarTopStartSection>
      <PlayerBarTopMiddleSection>
        <ProgressBar value={currentSlideIndex + 1} max={maxSlides} valueIndicatorType="fraction" />
      </PlayerBarTopMiddleSection>
      <PlayerBarTopEndSection>
        <PlayerBarTopInterfaceControls
          isFullscreen={isFullscreen}
          onClose={onClose}
          toggleFullscreen={toggleFullscreen}
          showLabels={showLabels}
        />
      </PlayerBarTopEndSection>
    </PlayerBarTop>
  );
};
