import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLinkAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.793 9.207 9.22 14.78m.065-3.023a2.44 2.44 0 0 0-3.14-.264l-.421.3A10.1 10.1 0 0 0 3.38 14.12a3.03 3.03 0 0 0 .315 3.91l2.276 2.275a3.03 3.03 0 0 0 3.909.315c.9-.648 1.687-1.44 2.329-2.345l.329-.463a2.38 2.38 0 0 0-.258-3.062zM20.305 5.97l-2.276-2.276a3.03 3.03 0 0 0-3.909-.315 10.1 10.1 0 0 0-2.329 2.345l-.3.422a2.44 2.44 0 0 0 .265 3.139l2.994 2.994a2.38 2.38 0 0 0 3.062.258l.463-.33A10.1 10.1 0 0 0 20.62 9.88a3.026 3.026 0 0 0-.316-3.91"
    />
  </svg>
);
export default SvgIconLinkAdd;
