import { parseStringWithOptions } from '@lessonup/utils';
import _ from 'lodash';
import { AssignmentMeta, AssignmentType } from '../../assignment';
import { PinEntry } from '../../entries';
import { Pin } from '../Pin';

// Content
export namespace OpenQuestion {
  export const type = 'openQuestion';

  export interface AssignmentContent extends Content {
    hasAnswer: boolean;
    hideNames?: boolean;
    explanation?: string; // only filled when there is no answer
    maximumNumberOfUploads: 0 | 1 | 5;
  }

  export interface LessonContent extends Content {
    studentImage: 'may' | 'may5' | undefined;
  }
  export interface Content {
    checkAnswer: boolean;
    showImage: boolean;
    caseSens: boolean;
    diacriticSens: boolean;

    question: string;
    description: string;
    file?: any;
    answer?: string | string[];
    layout?: number;

    align: number | undefined;
    fontSize1?: number;
    color?: number;
    colorbg?: string;
    colorfg?: string;
    imageSize?: 'contain' | 'cover';
    opacity?: number;
  }

  export class LessonContent {
    public static fromAssignmentContent(
      pinId: string,
      content: AssignmentContent,
      meta: AssignmentMeta
    ): LessonContent {
      const custom = _.cloneDeep(content);

      const correctAnswer = AssignmentMeta.correctAnswerForPin<OpenQuestion.CorrectAnswer>(meta, pinId);

      return {
        ...custom,
        studentImage: this.studentImageFromMaximumImageUploads(content),
        answer: correctAnswer ? correctAnswer[0] : undefined,
        checkAnswer: custom.hasAnswer,
      };
    }
    public static maximumImageUploads(content: LessonContent): number {
      if (content.studentImage == 'may') {
        return 1;
      } else if (content.studentImage == 'may5') {
        return 5;
      }
      return 0;
    }

    public static studentImageFromMaximumImageUploads(
      content: OpenQuestion.AssignmentContent
    ): LessonContent['studentImage'] {
      return (
        content.maximumNumberOfUploads ? `may${content.maximumNumberOfUploads}` : undefined
      ) as LessonContent['studentImage'];
    }
  }
}

// Answer
export namespace OpenQuestion {
  export interface Answer {
    modifiedAt: number; // timestamp
    texts?: string[];
    attachments?: Answer.Attachment[];
    giveUpAfterTries?: number; // number of tries before giving up in homework mode
    firstTimeOpenHint?: number; // number of tries before opening hint in homework mode
  }

  export interface FilledAnswer extends Answer {
    texts: string[];
    attachments: Answer.Attachment[];
  }

  export type CorrectAnswer = string[];

  export interface AnswerResult {
    type: 'correct' | 'incorrect' | 'noAnswer';
    giveUpAfterTries?: number; // for homework
    correctAfterTries?: number; // for homework
  }

  export namespace Answer {
    export const maxLength = 5000;

    export interface Attachment {
      imageUrl: string;
      uploadId?: string;
    }

    export function texts(answer: Answer | undefined): string[] {
      return (answer && answer.texts) || [];
    }

    export function isCorrectAnswer(answer: any): boolean {
      return !!Array.isArray(answer) && !answer.find((a) => typeof a !== 'string');
    }

    export function numberOfAnswers(answer: Answer | undefined): number {
      return (answer && answer.texts && answer.texts.length) || 0;
    }

    export function latestText(answer: Answer | undefined): string | undefined {
      return _.last(answer && answer.texts);
    }

    export function attachments(answer: Answer | undefined): Answer.Attachment[] {
      return (answer && answer.attachments) || [];
    }

    export function isAtAttachmentLimit(answer: Answer | undefined, content: AssignmentContent): boolean {
      return attachments(answer).length >= content.maximumNumberOfUploads;
    }

    export function update(current: Answer, fields: Partial<Answer>): Answer {
      return Object.assign({}, current, fields);
    }

    export function create(fields: Partial<Answer>): Answer {
      return Object.assign({ modifiedAt: Date.now() }, fields);
    }

    export function isDone(answer: Answer | undefined): boolean {
      if (!answer || (_.isEmpty(answer.texts) && _.isEmpty(answer.attachments))) {
        return false;
      }
      return true;
    }

    export function correctAnswerProvided(assignmentContent: AssignmentContent) {
      return assignmentContent.checkAnswer && !!assignmentContent.hasAnswer;
    }

    export function explanationProvided(assignmentContent: AssignmentContent): boolean {
      return !!assignmentContent.explanation;
    }

    export function getAnswers(assignmentMeta: AssignmentMeta, pinId: string): CorrectAnswer | undefined {
      if (assignmentMeta.answers && assignmentMeta.answers[pinId]) {
        return assignmentMeta.answers[pinId] as CorrectAnswer;
      }
      return undefined;
    }

    export function getAnswerBeforeHint(answer: Answer): string | undefined {
      if (answer.firstTimeOpenHint && answer.texts) {
        return answer.texts[answer.firstTimeOpenHint];
      }
      return undefined;
    }

    export function getAnswerifHasTextsOrAttachment(
      pinEntry: PinEntry.OpenQuestion | undefined
    ): FilledAnswer | undefined {
      const answer = pinEntry && pinEntry.answer;
      if (!answer || (_.isEmpty(answer.texts) && _.isEmpty(answer.attachments))) {
        return undefined;
      }
      return {
        ...answer,
        texts: answer.texts || [],
        attachments: answer.attachments || [],
      };
    }

    export function rankingPointsForResult(result?: AnswerResult): number {
      if (result && result.type === 'correct') return 1;
      return 0;
    }

    export function testPointsForResults(maxPoints: number, result?: AnswerResult): number | undefined {
      if (result && result.type === 'correct') return maxPoints;
      return undefined;
    }

    export function isStringCorrect(
      text: string,
      correctAnswer: CorrectAnswer,
      custom: OpenQuestion.AssignmentContent
    ): boolean {
      const options = { asLowercase: !custom.caseSens, normalizeDiacritics: !custom.diacriticSens };
      return _.some(
        correctAnswer,
        (value) => parseStringWithOptions(text, options) == parseStringWithOptions(value, options)
      );
    }

    export function resultForAnswer(
      current: Answer | undefined,
      pin: Pin<AssignmentContent>,
      correctAnswer: CorrectAnswer,
      type: AssignmentType
    ): AnswerResult {
      const latest = latestText(current);
      if (!current || !current.texts || _.isNil(latest) || _.isEmpty(latest)) {
        return { type: 'noAnswer' };
      }
      return {
        type: isStringCorrect(latest, correctAnswer, pin.item.custom) ? 'correct' : 'incorrect',
      };
    }
  }
}
