import { color } from './colors';
import { Colorset, ColorSetKey, ComponentColorTheme } from './colors.types';

export const colorSets: Record<ColorSetKey, Colorset> = {
  neutral: {
    ...color.neutralNew.level1,
  },
  active: {
    ...color.setting.active,
  },
  disabled: {
    ...color.setting.disabled,
  },
  primary: {
    ...color.hierarchy.primary,
  },
  secondary: {
    ...color.hierarchy.secondary,
  },
  tertiary: {
    ...color.hierarchy.tertiary,
  },
  maia: {
    ...color.product.maia,
    fill: color.product.maia.fillGradient, // For maia we probably want the gradient as fill default
  },
  negative: {
    ...color.message.negative,
  },
  positive: {
    ...color.message.positive,
  },
  warning: {
    ...color.message.warning,
  },
};

interface GetColorsetOptions {
  disabled?: boolean;
  active?: boolean;
}

/**
 * Returns the appropriate color set based on the component's theme, elevation, active state, and disabled state.
 */
export function getColorset(componentTheme: ComponentColorTheme, options: GetColorsetOptions = {}): Colorset {
  const { disabled, active } = options;

  if (disabled) {
    return colorSets.disabled;
  }

  if (componentTheme === 'neutral') {
    if (active) {
      return colorSets.active;
    }

    return colorSets.neutral;
  }

  return colorSets[componentTheme];
}
