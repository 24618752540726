import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PartialPrefix } from '@lessonup/utils';
import React, { useState } from 'react';
import { borderRadius } from '../../foundations/borders/borderRadius';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { BodyText } from '../BodyText/BodyText';
import { Button } from '../Button/Button';
import { IconClose } from '../icons';

export type Orientation = 'horizontal' | 'vertical';
export type Alignment = 'start' | 'center';
export type Priority = 'high' | 'medium' | 'low' | 'error' | 'success';

export interface BannerProps {
  paragraph?: string | React.JSX.Element;
  heading?: string;
  orientation?: Orientation;
  alignment?: Alignment;
  priority?: Priority;
  isCloseable?: boolean;
  actionElement?: React.JSX.Element;
  icon?: React.JSX.Element;
  onClose?: () => void;
  className?: string;
  roundedCorners?: boolean;
}

type BannerStyledProps = PartialPrefix<BannerProps, 'orientation'>;

export const Banner: React.FC<BannerProps> = ({
  icon,
  orientation = 'horizontal',
  priority = 'low',
  heading,
  paragraph,
  actionElement,
  alignment = 'start',
  isCloseable = false,
  onClose,
  className,
  roundedCorners = true,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return <></>;

  const handleClose = () => {
    setIsOpen(false);

    if (!onClose) return;

    onClose();
  };

  const iconElement = icon && <IconDiv>{icon}</IconDiv>;

  const textElements = (
    <PaddedWrapper alignment={alignment}>
      {heading && <BodyText weight="bold">{heading}</BodyText>}
      <BodyText>{paragraph}</BodyText>
    </PaddedWrapper>
  );

  const actionElementWrapper = actionElement && (
    <PaddedWrapper alignment={alignment} $orientation={orientation}>
      {actionElement}
    </PaddedWrapper>
  );

  const closeElement = isCloseable && (
    <CloseDiv>
      <Button
        size="small"
        iconStart={<IconClose />}
        buttonType="neutral"
        resizing="hug"
        buttonShape="rect"
        onClick={handleClose}
      />
    </CloseDiv>
  );

  return (
    <StyledDiv priority={priority} className={className} $orientation={orientation} roundedCorners={roundedCorners}>
      {orientation === 'horizontal' && (
        <>
          {iconElement}
          <BodyWrapper $orientation={orientation} alignment={alignment}>
            {textElements}
          </BodyWrapper>
          {actionElementWrapper}
          {closeElement}
        </>
      )}

      {orientation === 'vertical' && (
        <>
          <HeaderWrapper>
            {iconElement} {closeElement}
          </HeaderWrapper>
          <BodyWrapper $orientation={orientation} alignment={alignment}>
            {textElements}
          </BodyWrapper>
          {actionElementWrapper}
        </>
      )}
    </StyledDiv>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BodyWrapper = styled.div<Pick<BannerStyledProps, 'alignment' | '$orientation'>>`
  display: flex;
  padding: 0;
  flex: 1 1 auto;
  flex-direction: column;

  ${(props) => {
    if (props.alignment === 'center') {
      return css`
        align-content: center;
      `;
    } else {
      return css`
        text-align: left;
      `;
    }
  }}
`;

const PaddedWrapper = styled.div<Pick<BannerStyledProps, 'alignment' | '$orientation'>>`
  padding: ${spacing.size4} 0;

  ${(props) => {
    if (props.alignment === 'center') {
      return css`
        text-align: center;
      `;
    }
  }}
`;

const IconDiv = styled.div`
  height: ${spacing.size24};
  text-align: center;
  padding: ${spacing.size4} 0;
`;

const CloseDiv = styled.div`
  height: ${spacing.size32};
  width: ${spacing.size32};
  margin-left: auto;
  align-content: center;

  svg {
    height: ${spacing.size16};
    width: ${spacing.size16};
  }
`;

const StyledDiv = styled.div<Pick<BannerStyledProps, 'priority' | '$orientation' | 'roundedCorners'>>`
  padding: ${spacing.size16};
  display: flex;
  width: 100%;

  ${(props) => {
    if (props.roundedCorners) {
      return css`
        border-radius: ${borderRadius.rounded8};
      `;
    }
  }}

  ${(props) => {
    if (props.$orientation === 'horizontal') {
      return css`
        gap: ${spacing.size16};
        justify-content: space-between;
      `;
    }

    if (props.$orientation === 'vertical') {
      return css`
        flex-direction: column;
      `;
    }
  }}

  ${(props) => {
    switch (props.priority) {
      case 'high':
        return css`
          background-color: ${color.accent.primary.background};
          color: ${color.accent.primary.text};
        `;
      case 'medium':
        return css`
          background-color: ${color.accent.tertiary.background};
          color: ${color.accent.tertiary.text};
        `;
      case 'error':
        return css`
          background-color: ${color.additional.errorContainer.background};
          color: ${color.additional.errorContainer.text};
        `;
      case 'success':
        return css`
          background-color: ${color.message.positive.fill};
          color: ${color.message.positive.stroke};
        `;
      default:
        return css`
          background-color: ${color.accent.secondaryContainer.background};
          color: ${color.accent.secondaryContainer.text};
        `;
    }
  }}
`;
