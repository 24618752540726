import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { math } from 'polished';
import React, { forwardRef, PropsWithChildren, ReactNode, useImperativeHandle, useState } from 'react';
import { color } from '../../foundations/colors/colors';
import { breakpoints } from '../../foundations/layout/breakpoints';
import { spacing } from '../../foundations/spacing/spacing';
import { Link } from '../../utils/Link/Link';
import { AssetLogoLessonUp } from '../assets';
import { Button } from '../Button/Button';
import { IconClose, IconMenu } from '../icons';
import { topBarItemListClassName } from './TopBarItemList/TopBarItemList';
import { topBarVisibilityClassNames } from './TopBarVisibility/TopBarVisibility';

export type TopBarTheme = 'default' | 'secondary';

export interface TopBarProps {
  theme: TopBarTheme;
  logoUrl: string;
  className?: string;
  logoUrlExternal?: boolean;
}

export type SetOpenHandle = {
  setOpenValue: (value: boolean) => void;
};

export const TopBar = forwardRef<SetOpenHandle, PropsWithChildren<TopBarProps>>(function TopBar(
  { children, theme, logoUrl, className, logoUrlExternal },
  ref
) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  useImperativeHandle(ref, () => ({
    setOpenValue(value: boolean) {
      setIsOpen(value);
    },
  }));

  return (
    <Wrapper className={className}>
      <NavContainer theme={theme}>
        <Link href={logoUrl} passHref external={logoUrlExternal}>
          <LogoAnchor>
            <Logo />
          </LogoAnchor>
        </Link>

        <ChildrenWrapper>{children}</ChildrenWrapper>

        <ButtonNavFullscreenWrapper>
          <Button
            buttonType={theme === 'secondary' ? 'secondary' : 'neutral'}
            onClick={handleMenuToggle}
            iconStart={isOpen ? <IconClose /> : <IconMenu />}
          />
        </ButtonNavFullscreenWrapper>
      </NavContainer>
      {isOpen && <NavFullscreen>{children}</NavFullscreen>}
    </Wrapper>
  );
});

interface TopBarRightProps {
  children?: ReactNode;
}

export function TopBarRight(props: TopBarRightProps) {
  return <StyledTopBarRight className={topBarRightClassName}>{props.children}</StyledTopBarRight>;
}

const topBarRightClassName = 'TopBarRight';

export const StyledTopBarRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  > * ~ * {
    margin-left: ${spacing.size16};
  }
`;

export const topBarHeight = '64px';

const Wrapper = styled.div`
  position: relative;
  @media print {
    display: none;
  }
`;

const NavContainer = styled.nav`
  height: ${topBarHeight};
  padding-left: ${spacing.size16};
  padding-right: ${spacing.size16};
  display: flex;
  align-items: center;
  ${(props) => (props.theme === 'secondary' ? secondaryCss : defaultCss)}

  @media (max-width: ${breakpoints.topBarNavFullscreen}) {
    .${topBarVisibilityClassNames.default}, .${topBarVisibilityClassNames.onlyDesktop} {
      display: none;
    }
  }

  @media (min-width: ${math(`${breakpoints.topBarNavFullscreen} + 1px`)}) {
    .${topBarVisibilityClassNames.onlyMobile} {
      display: none;
    }
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const LogoAnchor = styled.a`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: ${spacing.size32};
`;

const Logo = styled(AssetLogoLessonUp)`
  fill: currentColor;
  width: auto;
  height: 20px;
`;

const ButtonNavFullscreenWrapper = styled.div`
  margin-left: ${spacing.size8};
  display: none;

  @media (max-width: ${breakpoints.topBarNavFullscreen}) {
    display: block;
  }
`;

const NavFullscreen = styled.div`
  background: ${color.neutral.surface.background};
  position: absolute;
  z-index: 10000; // sadly needed to be visible above the normal styling in LU
  top: ${topBarHeight};
  left: 0;
  width: 100%;
  height: 100vh; // won't fill out the whole page when scrolling down, which seems acceptable
  padding: ${spacing.size16};
  align-items: center;

  .${topBarItemListClassName}, .${topBarRightClassName} {
    align-items: center;
    flex-direction: column;
  }

  .${topBarVisibilityClassNames.alwaysTopbar}, .${topBarVisibilityClassNames.onlyDesktop} {
    display: none;
  }

  .${topBarRightClassName} {
    > * ~ * {
      margin-left: 0;
      margin-top: ${spacing.size16};
    }
  }
  @media (min-width: ${breakpoints.topBarNavFullscreen}) {
    display: none;
  }
`;

const defaultCss = css`
  background-color: ${color.neutral.surface.background};
  color: ${color.neutral.surface.text};
`;

const secondaryCss = css`
  background-color: ${color.accent.secondary.background};
  color: ${color.accent.secondary.text};
`;
