import { getPinComponentById, PinComponent, TeacherPin } from '@lessonup/pin-renderer';
import { SelectedPinComponentIds } from './EditorContext.types';

export function getSelectedPinComponent(
  pin: TeacherPin,
  selectedPinComponentIds: SelectedPinComponentIds
): PinComponent | null {
  return pin && pin.pinComponents && selectedPinComponentIds.length > 0
    ? getPinComponentById(pin.pinComponents, selectedPinComponentIds[0])
    : null;
}
