import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PartialPrefix } from '@lessonup/utils';
import React, { ReactNode } from 'react';
import { color } from '../../foundations/colors/colors';
import { spacing } from '../../foundations/spacing/spacing';
import { BodyText } from '../BodyText/BodyText';

interface SharedDividerProps {
  noSpacer?: boolean;
  spacing?: spacing;
  margin?: spacing;
}

interface HrOrientation {
  orientation: 'horizontal' | 'vertical';
}

interface HorizontalDividerProps extends SharedDividerProps {
  orientation: 'horizontal';
  text?: string;
}

interface VerticalDividerProps extends SharedDividerProps {
  orientation: 'vertical';
  children?: ReactNode;
}

export type DividerProps = HorizontalDividerProps | VerticalDividerProps;

export const Divider = (props: DividerProps) => {
  if (props.orientation === 'vertical') {
    return (
      <StyledDividerWrapper $orientation={props.orientation} $spacing={props.spacing} $margin={props.margin}>
        <StyledHr $orientation={props.orientation} $noSpacer={props.noSpacer} />
        {props.children}
      </StyledDividerWrapper>
    );
  }

  if (!props.text) {
    return (
      <StyledDividerWrapper $orientation={props.orientation} $spacing={props.spacing} $margin={props.margin}>
        <StyledHr $orientation={props.orientation} $noSpacer={props.noSpacer}></StyledHr>
      </StyledDividerWrapper>
    );
  }

  return (
    <StyledDividerWrapper $orientation={props.orientation} $spacing={props.spacing} $margin={props.margin}>
      <StyledDiv>
        <StyledHr $orientation={props.orientation} $noSpacer={props.noSpacer}></StyledHr>
        <TextDiv>
          <BodyText>{props.text}</BodyText>
        </TextDiv>
        <StyledHr $orientation={props.orientation} $noSpacer={props.noSpacer}></StyledHr>
      </StyledDiv>
    </StyledDividerWrapper>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: ${color.setting.disabled.on};
`;

type StyledHrProps = PartialPrefix<HrOrientation & SharedDividerProps, 'noSpacer' | 'orientation' | 'margin'>;

const StyledHr = styled.hr<StyledHrProps>`
  ${({ $noSpacer }) =>
    $noSpacer &&
    css`
      margin: 0;
    `}
  ${(props) => {
    if (props.$orientation === 'vertical') {
      return css`
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
      `;
    } else {
      return css`
        width: 100%;
      `;
    }
  }}
  border: 1px solid ${color.neutral.outline.background};
  border-bottom: 0;
  border-right: 0;
`;

type DividerWrapperProps = PartialPrefix<
  Pick<SharedDividerProps, 'spacing' | 'margin'> & HrOrientation,
  'orientation' | 'spacing' | 'margin'
>;

const StyledDividerWrapper = styled.div<DividerWrapperProps>`
  ${(props) => {
    if (!props.$spacing) {
      return;
    }

    if (props.$orientation === 'vertical') {
      return css`
        margin-left: ${props.$spacing};
        margin-right: ${props.$spacing};
      `;
    }

    return css`
      margin-top: ${props.$spacing};
      margin-bottom: ${props.$spacing};
    `;
  }}

  ${(props) =>
    props.$orientation === 'vertical' &&
    css`
      display: flex;
      height: ${!props.$margin || props.$margin === spacing.size0
        ? '100%'
        : `calc(100% - ${props.$margin} - ${props.$margin})`};
      width: 100%;
    `}
`;

const TextDiv = styled.div`
  display: flex;
  margin-left: ${spacing.size8};
  margin-right: ${spacing.size8};
`;
