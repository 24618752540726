import { Pin, PinComponent } from '@lessonup/pins-shared';
import { useReducer } from 'react';
import { UpdatePinActionWithMetadata, UpdatePinDispatchWithMetadata } from './updatePinContext';
import { overridePinComponents, updatePinSettings } from './useInMemoryPinListReducer.utils';

type State<P extends Pin> = P[];

type UpdateFunc<P extends Pin> = (statePin: P) => P;

const updatePin = <P extends Pin>(state: State<P>, pinId: string | null, pinUpdate: UpdateFunc<P>): State<P> => {
  if (!pinId) return state;
  const pin = state.find((p) => p.id === pinId);
  if (!pin) return state;
  const updatedPin = pinUpdate(pin);
  return state.map((p) => {
    if (p.id === pin?.id) {
      return updatedPin;
    }
    return p;
  });
};

// This is a very simple reducer that only supports view related updates
const pinListReducer = <P extends Pin>(
  state: State<P>,
  action: UpdatePinActionWithMetadata | UpdatePinActionWithMetadata[]
): State<P> => {
  const actionReducer = () => (state: State<P>, action: UpdatePinActionWithMetadata) => {
    switch (action.type) {
      case 'overridePinComponents':
        return updatePin(state, action.pinId, (state) => ({
          ...state,
          pinComponents: overridePinComponents(state.pinComponents, action.pinComponents),
        }));
      case 'updatePinComponentSettings':
        return updatePin(state, action.pinId, (state) => ({
          ...state,
          pinComponents: state.pinComponents.map((component) => {
            if (component.id === action.pinComponentId) {
              return {
                ...component,
                settings: {
                  ...component.settings,
                  ...action.settings,
                },
              } as PinComponent; // Cast is needed because it refuses to merge the union of pins
            }
            return component;
          }),
        }));
      case 'updatePinSettings':
        return updatePin<P>(state, action.pinId, (state) => {
          return updatePinSettings(state, action.settings);
        });
      default:
        return state;
    }
  };
  const actions = Array.isArray(action) ? action : [action];
  return actions.reduce(actionReducer(), state);
};

/**
 * Dev only in memory reducer for pin lists
 * TODO 2024-01-01: move this to storybook utils after deleting the teacher next dependency
 */
export const useInMemoryPinListReducer = <P extends Pin>(initialPins: P[]): [P[], UpdatePinDispatchWithMetadata] => {
  return useReducer(
    (state: State<P>, action: UpdatePinActionWithMetadata | UpdatePinActionWithMetadata[]) =>
      pinListReducer<P>(state, action),
    initialPins
  );
};
