import styled from '@emotion/styled';
import { secondsToMinutes } from '@lessonup/utils';
import React from 'react';

export interface TimerProps {
  seconds: number;
}

export const Timer: React.FC<TimerProps> = ({ seconds }) => {
  const parsedMinutes = secondsToMinutes(seconds);
  const parsedSeconds = seconds - parsedMinutes * 60;
  const paddedParsedSeconds = parsedSeconds > 9 ? parsedSeconds : `0${parsedSeconds}`;

  return (
    <StyledTimer>
      {parsedMinutes}
      {':'}
      {paddedParsedSeconds}
    </StyledTimer>
  );
};

const StyledTimer = styled.section`
  width: 6ch;
  text-align: end;
`;
