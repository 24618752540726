import { useEffect } from 'react';

type TimeUpdateHandler = (currentTime: number) => void;

type UnsubscribeFunction = () => void;

type SubscribeToTimeUpdates = (handler: TimeUpdateHandler) => UnsubscribeFunction;

type EndReachedCallback = () => void;

export function useEndTimeHandlerInterval(
  getCurrentTime: () => Promise<number>,
  endTime?: number,
  onEndReached?: EndReachedCallback,
  isPlaying = false,
  intervalDuration = 200
) {
  useEffect(() => {
    if (endTime === undefined || !isPlaying) {
      return;
    }

    let isMounted = true;

    const intervalId = window.setInterval(async () => {
      if (!isMounted) return;

      const currentTime = await getCurrentTime();
      if (currentTime >= endTime) {
        onEndReached?.();
      }
    }, intervalDuration);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [getCurrentTime, endTime, onEndReached, isPlaying, intervalDuration]);
}

export function useEndTimeHandlerEvent(
  endTime?: number,
  onEndReached?: EndReachedCallback,
  onTimeUpdate?: SubscribeToTimeUpdates
) {
  useEffect(() => {
    if (endTime === undefined || !onTimeUpdate) {
      return;
    }

    const handler = (currentTime: number) => {
      if (currentTime >= endTime) {
        onEndReached?.();
      }
    };

    const cleanup = onTimeUpdate(handler);

    return () => {
      cleanup?.();
    };
  }, [endTime, onEndReached, onTimeUpdate]);
}
