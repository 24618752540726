import {
  createModal,
  IconHistory,
  ManagedModal,
  ModalFooter,
  ModalHeaderV1,
  NiceModal,
  rem,
  SpaceBetween,
  spacing,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { useEditorTranslation } from '../../hooks/useEditorTranslation';

export interface LessonBrokenModalProps {
  lessonName: string | undefined;
}

export const LessonBrokenModal = createModal(({ lessonName }: LessonBrokenModalProps) => {
  const modal = useModal();
  const { t } = useEditorTranslation();

  const onBeforeClose = () => {
    // we do not allow using click outside to close the modal
    return false;
  };

  const handleConfirm = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <ManagedModal
      modal={modal}
      contentLabel={t('lessonBrokenModal.title')}
      width={rem('380px')}
      onBeforeClose={onBeforeClose}
    >
      <ModalHeaderV1 type="headline" title={{ content: t('lessonBrokenModal.title') }} showDivider={false} />
      <SpaceBetween
        direction="y"
        spacing={spacing.size16}
        padding={`${spacing.size8} ${spacing.size24} ${spacing.size24}`}
      >
        {t('lessonBrokenModal.description', { lessonName })}
      </SpaceBetween>
      <ModalFooter
        type="actions"
        primaryAction={{
          onClick: handleConfirm,
          iconEnd: <IconHistory />,
          label: t('lessonBrokenModal.button.goBack'),
        }}
      ></ModalFooter>
    </ManagedModal>
  );
});

export function showLessonBrokenModal(props: LessonBrokenModalProps) {
  return NiceModal.show(LessonBrokenModal, props);
}
