import { Divider, ModalHeaderV1, SpaceBetween, spacing } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralExplanation } from '../ReferralExplanation/ReferralExplanation';
import { ReferralMailInvite } from '../ReferralMailInvite/ReferralMailInvite';
import { ReferralTabs, ReferralTabsProps } from '../ReferralTabs/ReferralTabs';

type ReferralModalContentProps = ReferralTabsProps & {
  onClose: () => void;
  amountOfExtraTrialDays?: number;
};

const TRANSLATION_NAMESPACE = 'teacherReferralModal';

export const ReferralModalContent = ({
  onClose,
  amountOfExtraTrialDays,
  ...referralTabsProps
}: ReferralModalContentProps) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <React.Fragment>
      <ModalHeaderV1 type="headline" title={{ content: t('title') }} onCloseButtonClick={onClose} showDivider={false} />
      <SpaceBetween
        direction="y"
        spacing={spacing.size16}
        padding={`${spacing.size8} ${spacing.size24} ${spacing.size24}`}
      >
        <ReferralExplanation amountOfExtraTrialDays={amountOfExtraTrialDays} />

        <ReferralMailInvite {...referralTabsProps} />

        <Divider orientation="horizontal" text={t('divider')} />

        <ReferralTabs {...referralTabsProps} />
      </SpaceBetween>
    </React.Fragment>
  );
};
