import { BodyText, color, Headline, ModalFooter, SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import { getRouteForAsset } from '@lessonup/utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type NoticeModalContentProps = {
  title: string;
  messages: string[];
  image?: {
    src: string;
    alt?: string;
  };
  link?: {
    label: string;
    url: string;
  };
  params?: Record<string, number | boolean | string | Date>;
  onClose: () => void;
  onDismissAll: () => void;
};

const translationNamespace = 'notice';
export const NoticeModalContent = ({
  image,
  title,
  messages,
  link,
  params = {},
  onClose,
  onDismissAll,
}: NoticeModalContentProps) => {
  const { t, i18n } = useTranslation(translationNamespace);

  const tIfExists = useCallback(
    (key: string) =>
      key && i18n.exists(`${translationNamespace}:${key}`, { ...params, nsSeparator: ':' }) ? t(key, params) : key,
    [t, i18n, params]
  );

  return (
    <>
      <Wrapper direction="y" spacing={spacing.size16}>
        {image && (
          <ImageWrapper>
            <Image src={getRouteForAsset(image.src)} alt={tIfExists(image.alt ?? title)} />
          </ImageWrapper>
        )}

        <SpaceBetween direction="y" spacing={spacing.size24}>
          <Headline size="small">{tIfExists(title)}</Headline>
          <SpaceBetween direction="y" spacing={spacing.size4}>
            {messages.map((message, index) => (
              <BodyText key={'noticeModalMessage' + index}>{tIfExists(message)}</BodyText>
            ))}
          </SpaceBetween>
        </SpaceBetween>
      </Wrapper>

      <ModalFooter
        type="actions"
        primaryAction={
          link ? { label: tIfExists(link.label), href: link.url } : { label: t('modal.close'), onClick: onClose }
        }
        secondaryAction={{ label: t('modal.permanentDismiss'), onClick: onDismissAll }}
      />
    </>
  );
};

const Wrapper = styled(SpaceBetween)`
  padding: ${spacing.size24};
  background: ${color.neutral.surface.background};
`;
const ImageWrapper = styled.div`
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: ${spacing.size24};
`;
