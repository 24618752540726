import { isEqual } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';

interface LessonData {
  type: 'lesson' | 'lessonV2';
  user: string;
  id: string;
  pinId?: string;
}

interface LessonPlanData {
  type: 'plan';
  user: string;
  id: string;
}

export type ActiveDocumentTab = LessonData | LessonPlanData;

const localStorageKeyForActiveDocumentTab = 'myActiveDocumentTab';

export const setMyActiveDocumentTab = (data: ActiveDocumentTab) => {
  // This function is used in the old teacher application to update the activeTabs in localStorage.
  // Ideally we would have used the setter hook from uselocalStorage, but its being used in a route
  // and we don't have access to React at that exact moment.
  const current = getActiveDocumentTabs(localStorageKeyForActiveDocumentTab) || [];
  const nextTabs = filterMyOwnDocumentTabs(data, current);
  if (isEqual(nextTabs, current)) return;

  setActiveDocumentTabs(nextTabs);
};

export const useMyActiveDocumentTab = (userId: string | undefined): [ActiveDocumentTab | undefined] => {
  const [getActiveTabs] = useLocalStorage<ActiveDocumentTab[]>(localStorageKeyForActiveDocumentTab, []);
  return [getActiveDocumentForUser(getActiveTabs, userId)];
};

const filterMyOwnDocumentTabs = (newTab: ActiveDocumentTab, previousTabs: ActiveDocumentTab[]) => {
  /* For users that share a computer we want to be able return their own activeTab.
   We keep the active tab from the latest 5 users to keep the amount of used memory acceptable */
  const activeTabsFromOtherUsers = previousTabs.filter(({ user }) => user !== newTab.user);
  return [newTab, ...activeTabsFromOtherUsers].slice(0, 5);
};

const getActiveDocumentForUser = (
  activeDocuments: ActiveDocumentTab[],
  userId: string | undefined
): ActiveDocumentTab | undefined => {
  if (!userId) return;

  return activeDocuments.find((activeDocument) => activeDocument.user === userId);
};

const getActiveDocumentTabs = (key: string) => {
  try {
    const stringValue = localStorage.getItem(key);
    return stringValue ? JSON.parse(stringValue) : stringValue;
  } catch (error: unknown) {
    warnMalformedJSON(key, error);
  }
};

const setActiveDocumentTabs = (data: ActiveDocumentTab[]) => {
  localStorage.setItem(localStorageKeyForActiveDocumentTab, JSON.stringify(data));
};

const warnMalformedJSON = (key: string, error: unknown) => {
  console.warn('storage data error (is the JSON valid?)', { key }, error);
};
