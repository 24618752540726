/**
 * Convert number to string with correct unit using IEC standard
 *
 * See: https://en.wikipedia.org/wiki/Binary_prefix
 * @param sizeInBytes
 * @param numberFormatter
 * @returns
 */
export const sizeInBytesToHumanReadableIEC = (sizeInBytes: number, numberFormatter: Intl.NumberFormat): string => {
  const units = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  return sizeInBytesToHumanReadable({ sizeInBytes, numberFormatter, units, step: 1024 });
};

/**
 * Convert number to string with correct unit using SI standard
 *
 * See: https://en.wikipedia.org/wiki/Binary_prefix
 * @param sizeInBytes
 * @param numberFormatter
 * @returns
 */
export const sizeInBytesToHumanReadableSI = (sizeInBytes: number, numberFormatter: Intl.NumberFormat): string => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return sizeInBytesToHumanReadable({ sizeInBytes, numberFormatter, units, step: 1000 });
};

const sizeInBytesToHumanReadable = ({
  sizeInBytes,
  numberFormatter,
  units,
  step,
}: {
  sizeInBytes: number;
  numberFormatter: Intl.NumberFormat;
  units: string[];
  step: number;
}): string => {
  let currentUnit = 0;
  let remainingSizeInBytes = sizeInBytes;

  while (remainingSizeInBytes >= step && units[currentUnit + 1]) {
    remainingSizeInBytes /= step;
    ++currentUnit;
  }
  return `${numberFormatter.format(remainingSizeInBytes)} ${units[currentUnit]}`;
};
