import { useLazyDocumentQuery } from '@lessonup/client-integration';
import {
  ManagedModal,
  ModalFooterAction,
  NiceModal,
  NiceModalHocProps,
  useErrorContext,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStep } from 'usehooks-ts';
import {
  defaultTranslationPrefix,
  FilePreviewDataForUpload,
  modalSteps,
} from '../components/UploadFromFileModalInner/UploadFromFileModal.utils';
import { UploadFromFileModalInner } from '../components/UploadFromFileModalInner/UploadFromFileModalInner';
import { useUploadFile } from '../hooks/useUploadFile';
import { useUploadStatusPolling } from '../hooks/useUploadStatusPolling';
import { UseUploadData } from '../model';
import { UploadDetailsDocument, UploadDetailsFragmentFragment } from '../Uploads.graphql.generated';
import { fileTypeMapForEvent, i18nextNamespace } from '../UploadsFeature.utils';

export type UploadFromFileModalAction = Omit<ModalFooterAction, 'onClick'> & {
  onClick: (useUploadData?: UploadFromFileReturnData) => void;
};

export interface UploadFromFileModalProps {
  file: File;
  primaryAction: UploadFromFileModalAction;
  secondaryAction?: UploadFromFileModalAction;
  translationPrefix?: string;
}

export type UploadFromFileReturnData = Pick<UseUploadData, 'id' | 'type' | 'urls'>;

export const UploadFromFileModal: React.FC<UploadFromFileModalProps & NiceModalHocProps> = NiceModal.create((props) => {
  const modal = useModal();
  const [step, { goToNextStep }] = useStep(modalSteps.length);
  const [performUpload] = useUploadFile({
    onError: (error) => setError({ error }),
  });
  const { t } = useTranslation(i18nextNamespace);
  const { startPollingForIds } = useUploadStatusPolling({ onError: (error) => setError({ error }) });
  const { setError } = useErrorContext();

  const [fetchUploadStatus, { data }] = useLazyDocumentQuery(UploadDetailsDocument, {
    fetchPolicy: 'cache-first',
  });

  const translationPrefix = props.translationPrefix ?? defaultTranslationPrefix;

  async function handleNext() {
    switch (step) {
      case 1:
        await uploadFile();
        return goToNextStep();
      case 2:
        return modal.hide();
    }
  }

  async function uploadFile() {
    try {
      const uploadId = await performUpload({ file: props.file });
      if (!uploadId) return; // Error handled by useUploadHook

      await fetchUploadStatus({ variables: { uploadId } });
      startPollingForIds([uploadId]);
    } catch (error) {
      if (error instanceof Error) {
        setError({ error });
      }
    }
  }
  const upload = data?.viewer.uploadsByIds?.[0];

  return (
    <ManagedModal modal={modal} contentLabel={t(`${translationPrefix}.modalAriaLabel`)}>
      <UploadFromFileModalInner
        file={props.file}
        step={step}
        goToNextStep={handleNext}
        onClose={modal.hide}
        upload={getUploadDetailsForPreview(upload)}
        primaryAction={modalFooterActionWithOnClick(props.primaryAction, upload, modal.hide)}
        secondaryAction={
          props.secondaryAction && modalFooterActionWithOnClick(props.secondaryAction, upload, modal.hide)
        }
        translationPrefix={translationPrefix}
      />
    </ManagedModal>
  );
});

export const showUploadFromFileModal = (data: UploadFromFileModalProps): Promise<UploadFromFileReturnData> =>
  NiceModal.show(UploadFromFileModal, data);

function modalFooterActionWithOnClick(
  action: UploadFromFileModalAction,
  upload: UploadDetailsFragmentFragment | undefined,
  onClose: () => void
): ModalFooterAction {
  return {
    ...action,
    onClick: () => {
      const useUploadData = upload && mapUploadDetailsToUseUploadData(upload);
      action.onClick(useUploadData);
      onClose();
    },
  };
}

function getUploadDetailsForPreview(
  upload: UploadDetailsFragmentFragment | undefined
): FilePreviewDataForUpload | undefined {
  if (!upload?.__typename) return;
  return {
    id: upload.id,
    type: upload.__typename,
    progressPercentage: upload?.progressPercentage || 0,
    status: upload.status,
    url: urlsFromUploadDetails(upload),
  };
}

function mapUploadDetailsToUseUploadData(upload?: UploadDetailsFragmentFragment): UploadFromFileReturnData | undefined {
  if (!upload?.__typename) return;
  const url = urlsFromUploadDetails(upload);
  const urls = url ? [url] : undefined;
  if (!urls) return;

  return {
    id: upload.id,
    type: fileTypeMapForEvent[upload?.__typename],
    urls,
  };
}

function urlsFromUploadDetails(upload: UploadDetailsFragmentFragment): string | undefined {
  switch (upload.__typename) {
    case 'ImageUpload':
      return upload.image?.url;
    case 'VideoUpload':
      return upload.video?.url;
    case 'AudioUpload':
      return upload.audio?.url;
    case 'DocumentUpload':
    case 'SlideDeckUpload':
      return undefined;
  }
}
