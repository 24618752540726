import { useLocale } from '@lessonup/client-integration';
import {
  Card,
  IconSortDescending,
  Introduction,
  spacing,
  styled,
  Table,
  TableCellsHeader,
  TableRowData,
  useTranslation,
} from '@lessonup/ui-components';
import { localeToIsoLocaleString } from '@lessonup/utils';
import { isNumber, sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { i18nextNamespace } from '../../utils/consts';
import { ReportQuestion } from '../../utils/types';

export type QuestionListCardProps = {
  questions: ReportQuestion[];
  className?: string;
};

export const QuestionsListCard = ({ questions, className }: QuestionListCardProps): React.JSX.Element => {
  const { t } = useTranslation(i18nextNamespace, { keyPrefix: 'questionsListCard' });
  const locale = useLocale();

  /**
   * Format the percentage we get from the server to a locale percentage string with no decimal places
   *
   * @input percentage - a number between 0 and 100
   * @output a string representing the percentage with no decimal places
   */
  const percentageFormatter = useCallback(
    (percentage: number): string =>
      new Intl.NumberFormat(localeToIsoLocaleString(locale), {
        style: 'percent',
        maximumFractionDigits: 0,
      }).format(percentage / 100),
    [locale]
  );

  const header: TableCellsHeader = {
    cells: [
      {
        text: t('table.header.question'),
      },
      {
        text: t('table.header.slide'),
      },
      {
        text: t('table.header.correct'),
      },
      {
        text: t('table.header.incorrect'),
      },
      {
        text: t('table.header.unanswered'),
        endSvgIcon: IconSortDescending,
      },
    ],
  };
  const rows: TableRowData[] = sortBy(questions, ['percentageStudentsUnanswered'])
    .reverse()
    .map(
      ({
        id,
        questionTitle,
        slideNumber,
        percentageStudentsAnsweredCorrectly,
        percentageStudentsAnsweredIncorrectly,
        percentageStudentsUnanswered,
      }) => ({
        id,
        ariaLabel: t('table.ariaRow', { questionTitle }),
        cells: [
          {
            text: questionTitle || t('N/A'),
          },
          {
            text: slideNumber.toLocaleString(),
            alignment: 'end',
          },
          {
            text: isNumber(percentageStudentsAnsweredCorrectly)
              ? percentageFormatter(percentageStudentsAnsweredCorrectly)
              : t('N/A'),
            alignment: 'end',
          },
          {
            text: isNumber(percentageStudentsAnsweredIncorrectly)
              ? percentageFormatter(percentageStudentsAnsweredIncorrectly)
              : t('N/A'),
            alignment: 'end',
          },
          {
            text: isNumber(percentageStudentsUnanswered) ? percentageFormatter(percentageStudentsUnanswered) : t('N/A'),
            alignment: 'end',
          },
        ],
      })
    );

  return (
    <Card showBorder={false} className={className}>
      <IntroductionWrapper>
        <Introduction alignment="start" size="M" headline={t('headline')} bodyText={t('bodyText')} />
      </IntroductionWrapper>
      <TableWrapper>
        <Table caption={t('caption')} header={header} rows={rows} interactivity={false} hideLastBorder />
      </TableWrapper>
    </Card>
  );
};

const IntroductionWrapper = styled.div`
  padding: ${spacing.size16};
`;

const TableWrapper = styled.div`
  overflow: auto;
`;
