import { isString } from 'lodash';
import { getIntegrationConfig } from '../../config/integrationConfig';

export interface RefreshTokensPayload {
  accessToken: string;
}

export async function fetchRefreshMeteorTokens(userId: string, meteorToken: string): Promise<RefreshTokensPayload> {
  const { apiHost, includeCredentials } = getIntegrationConfig();

  const response = await fetch(`${apiHost}/auth/meteor/refresh-token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials: includeCredentials ? 'include' : undefined,
    body: JSON.stringify({ meteorToken, userId }),
  });

  if (!response.ok) {
    const payload = await response.text();
    throw new Error("Refresh request wasn't successful.", { cause: new Error(payload) });
  }
  const data = await response.json();

  const { accessToken } = data;

  if (!isString(accessToken)) {
    throw new Error('refresh-token: expected accessToken to be returned from the API');
  }

  return {
    accessToken,
  };
}
