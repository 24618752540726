const audio16Bit = 16;

export type SourceDataObject = { src: string | undefined; type: string };

export class BlobConverter {
  public async convertToWave(blob: Blob) {
    const audioBuffer = await this.decodeAudioData(blob);

    return this.createWaveFile(audioBuffer);
  }

  private async decodeAudioData(blob: Blob) {
    const arrayBuffer = await blob.arrayBuffer();
    const audioContext = new AudioContext();

    return audioContext.decodeAudioData(arrayBuffer);
  }

  private createWaveFile(audioBuffer: AudioBuffer) {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const blockAlign = (numberOfChannels * audio16Bit) / 8;
    const byteRate = sampleRate * blockAlign;

    const audioData = audioBuffer.getChannelData(0);
    const buffer = new ArrayBuffer(44 + audioData.length * 2);
    const view = new DataView(buffer);

    // 'RIFF' chunk descriptor
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + audioData.length * 2, true);
    writeString(view, 8, 'WAVE');

    // 'fmt ' sub-chunk
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, audio16Bit, true);

    // 'data' sub-chunk
    writeString(view, 36, 'data');
    view.setUint32(40, audioData.length * 2, true);
    writeData(view, 44, audioData);

    return new Blob([view], { type: 'audio/wav' });
  }

  public toSourceDataObject(blob: Blob): Promise<SourceDataObject> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const base64URL = e?.target?.result;

        if (typeof base64URL !== 'string') {
          reject();
          return;
        }

        const BlobType = blob.type.includes(';') ? blob.type.substr(0, blob.type.indexOf(';')) : blob.type;

        resolve({
          src: base64URL,
          type: BlobType,
        });
      };

      reader.readAsDataURL(blob);
    });
  }
}

function writeString(view: DataView, offset: number, string: string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function writeData(view: DataView, offset: number, data: Float32Array) {
  for (let i = 0; i < data.length; i++, offset += 2) {
    const sample = data[i];
    view.setInt16(offset, sample * 0x7fff, true);
  }
}
