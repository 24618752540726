import { defaultLocale, Language } from '@lessonup/utils';
import { intercomIsAvailable } from '../utils/intercomisAvailable';

const supportedLanguagesForIntercom: Language[] = ['en', 'nl'];

interface UserData extends Record<string, unknown> {
  id: string;
  language: Language;
  name?: string;
  hash: string;
}

export function setUser(user: UserData | undefined) {
  if (!intercomIsAvailable(true)) return;

  if (!user) {
    window.Intercom('update', {
      user_id: undefined,
      name: undefined,
      user_hash: undefined,
    });
    return;
  }

  window.Intercom('update', {
    user_id: user.id,
    language_override: user?.language,
    name: user.name,
    user_hash: user.hash,
  });
}

export function logout() {
  if (!intercomIsAvailable(true)) return;
  window.Intercom('shutdown');
}

export function logoutAndReinit({ appId, language }: { appId: string; language: Language }) {
  if (!intercomIsAvailable(true)) return;

  Intercom('shutdown');

  window.Intercom('boot', {
    app_id: appId,
    language_override: language,
  });
}

export function updateLanguage(language: Language) {
  const languageOrFallback = supportedLanguagesForIntercom.includes(language) ? language : defaultLocale.language;
  Intercom('update', {
    language_override: languageOrFallback,
  });
}
