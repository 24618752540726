/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { sanitizeHtml } from '@lessonup/utils';
import { rem } from 'polished';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { borderRadius } from '../../../foundations/borders/borderRadius';
import { color } from '../../../foundations/colors/colors';
import { spacing } from '../../../foundations/spacing/spacing';
import { BodyText } from '../../BodyText/BodyText';
import { IconCheckbox, IconCheckboxChecked } from '../../icons';
import { FormFieldValidation } from '../model';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';

type CheckboxBaseProps = React.HTMLAttributes<HTMLInputElement> & {
  forwardedRef?: React.ForwardedRef<HTMLInputElement>;
};

export interface CheckboxProps extends CheckboxBaseProps {
  defaultChecked?: boolean;
  checked?: boolean;
  id: string;
  label: string | React.JSX.Element;
  hideLabel?: boolean;
  disabled?: boolean;
  required?: boolean;
  value?: React.AllHTMLAttributes<HTMLInputElement>['value'];
  validation?: FormFieldValidation;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

export const Checkbox = forwardRef(function CheckboxFormField(
  props: CheckboxProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) {
  return <CheckboxField {...props} forwardedRef={forwardedRef} />;
});

const CheckboxField: React.FC<CheckboxProps> = ({
  id,
  label,
  hideLabel,
  defaultChecked,
  checked,
  onChange,
  value,
  validation,
  forwardedRef,
  ...props
}) => {
  const validationMessageId = `${id}-validation`;
  const [checkedState, setCheckedState] = useState<boolean>(defaultChecked || false);
  useEffect(() => {
    if (typeof checked !== 'undefined') {
      setCheckedState(checked);
    }
  }, [checked]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextState = e?.target?.checked;
      setCheckedState(nextState);
      onChange && onChange(e);
    },
    [onChange]
  );

  const getLabelInPlainText = (label: string | React.JSX.Element): string => {
    if (typeof label === 'string') return label;
    const htmlString = renderToStaticMarkup(label);
    return sanitizeHtml(htmlString, { allowedTags: [], allowedAttributes: {} });
  };

  const innerCheckboxContent = () => {
    return (
      <>
        <input
          type="checkbox"
          id={id}
          value={value}
          checked={checkedState}
          onChange={onChangeHandler}
          {...props}
          ref={forwardedRef}
        />
        <label
          htmlFor={id}
          aria-label={getLabelInPlainText(label)}
          aria-describedby={validation?.message ? validationMessageId : undefined}
          aria-invalid={validation?.state === 'error'}
          className={props.className}
        >
          <IconCheckbox />
          <IconCheckboxChecked data-checked />
          <StyledLabelText hideLabel={!!hideLabel}>{label}</StyledLabelText>
        </label>
        <ValidationMessage id={validationMessageId} validation={validation} />
      </>
    );
  };

  return hideLabel ? (
    <HiddenLabelStyledCheckboxContainer>{innerCheckboxContent()}</HiddenLabelStyledCheckboxContainer>
  ) : (
    <StyledCheckboxContainer>{innerCheckboxContent()}</StyledCheckboxContainer>
  );
};

const StyledLabelText = styled(BodyText)<{ hideLabel: boolean }>`
  padding-left: ${spacing.size8};
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      display: none;
    `}
`;

const sharedStyling = css`
  position: relative;
  border-radius: ${borderRadius.rounded4};

  :hover {
    background: ${color.neutral.surface.hover};
  }

  label {
    cursor: pointer;
    display: flex;

    /**
     * flex-shrink:
     * So the svg doesn't adjust size when the text grows, since the parent uses display-flex.
    */
    & > svg {
      width: ${rem('16px')};
      height: ${rem('16px')};
      flex-shrink: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input:disabled ~ label {
    color: ${color.additional.disabled.text};
    pointer-events: none;
  }

  /**
   * When the input is checked hide the svg that isn't checked,
   * and when the input is not checked hide the svg that is checked.
   */
  input:checked ~ label > svg:not([data-checked]),
  input:not(:checked) ~ label > svg[data-checked] {
    display: none;
  }

  input:checked ~ label > svg {
    color: ${color.accent.secondary.background};
  }

  input:focus-visible ~ label {
    outline: 2px solid ${color.additional.focus.background};
  }
`;

const StyledCheckboxContainer = styled.div`
  ${sharedStyling}

  label {
    cursor: pointer;
    display: flex;
    height: 100%;

    /**
     * margin-top:
     * Svg needs a margin top for alignment in order to be possible 
     * to have it align with the first sentence when there's multiple lines (flex-start on parent).
     * 
    */
    & > svg {
      margin-top: ${spacing.size4};
    }
  }
`;

const HiddenLabelStyledCheckboxContainer = styled.div`
  ${sharedStyling}
  display: flex;
  width: ${spacing.size32};
  height: ${spacing.size32};
  align-content: center;
  justify-content: center;
  cursor: pointer;

  label {
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;
