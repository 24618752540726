import {
  getLayoutParts,
  isOpenQuestionPin,
  isQuizPin,
  isSlidePin,
  newOpenQuestionPin,
  newPin,
  newQuizPin,
  newSlidePin,
  OpenQuestionPinLayoutKey,
  Pin,
  PinViewerStatic,
  QuizPinLayoutKey,
  SlidePinLayoutKey,
  TeacherPin,
  UpdatePinDispatch,
} from '@lessonup/pin-renderer';
import {
  color,
  createModal,
  Grid,
  ModalPopover,
  ModalPopoverAnchor,
  ModalPopoverDirection,
  SpaceBetween,
  spacing,
  styled,
  Thumb,
  useModal,
} from '@lessonup/ui-components';
import React from 'react';
import { Doc } from 'yjs';
import { useEditorTranslation } from '../../../hooks/useEditorTranslation';
import { createTranslatedTemplate } from '../../../utils/template/createTranslatedTemplate';
import {
  determinePinComponentsUpdatesForLayoutChange,
  getLayoutForKey,
  getLayoutsByPinType,
} from '../../../utils/templateLayout';

interface ModalProps {
  direction: ModalPopoverDirection;
  modalAnchor: ModalPopoverAnchor;
  parentSelector: () => HTMLLIElement | HTMLButtonElement | null;
  pin: Pin;
  dispatch: UpdatePinDispatch; // shortcut: got the error 'no dispatch for updatePinContext' when using useUpdatePin inside the modal. Since this is a temp solution, not needed to fix that challenge for now.
  yDoc: Doc;
}

const PIN_WIDTH = 152;

export const SwitchLayoutModal = createModal(
  ({ direction, modalAnchor, parentSelector, dispatch, pin, yDoc }: ModalProps) => {
    const { t } = useEditorTranslation();
    const modal = useModal();
    const template = createTranslatedTemplate();

    const switchLayout = (layoutKey: SlidePinLayoutKey | QuizPinLayoutKey | OpenQuestionPinLayoutKey) => {
      const template = createTranslatedTemplate();
      const layoutsForPinType = getLayoutsByPinType(template.layouts, pin.type);
      const existingLayout = getLayoutForKey(layoutsForPinType, pin.settings.layout);
      const newLayout = getLayoutForKey(layoutsForPinType, layoutKey);

      if (!existingLayout || !newLayout) {
        return;
      }

      const layoutParts = getLayoutParts(pin);

      const { updatedPinComponents, toBeAddedPinComponents, toBeRemovedPinComponentIds, newLayoutDict } =
        determinePinComponentsUpdatesForLayoutChange(
          pin.id,
          layoutParts,
          pin.pinComponents,
          newLayout,
          existingLayout,
          yDoc
        );

      dispatch({
        type: 'updatePinLayout',
        toBeAddedPinComponents,
        toBeRemovedPinComponentIds,
        updatedPinComponents,
        layout: layoutKey,
        newLayoutDict,
      });

      modal.hide();
    };

    return (
      <ModalPopover
        direction={direction}
        modalAnchor={modalAnchor}
        parentSelector={parentSelector}
        modal={modal}
        contentLabel="temp"
      >
        <StyledModalContent direction="y" spacing={spacing.size16}>
          <StyledLayoutList cols={3} gap={spacing.size16} as="ul">
            {isSlidePin(pin) &&
              template.layouts.slidePinLayouts.map((layout, index) => {
                const pinForThumb = newPin(newSlidePin(layout) as TeacherPin);

                return (
                  <li key={layout.key}>
                    <Thumb
                      thumbType="caption"
                      number={index + 1}
                      onClick={() => switchLayout(layout.key)}
                      isActive={pin.settings.layout === layout.key}
                      caption={t(`layouts.slide.${layout.key}`)}
                    >
                      <PinViewerStatic pin={pinForThumb} width={PIN_WIDTH} isThumb />
                    </Thumb>
                  </li>
                );
              })}

            {isQuizPin(pin) &&
              template.layouts.quizPinLayouts.map((layout, index) => {
                const pinForThumb = newPin(newQuizPin(layout));

                return (
                  <li key={layout.key}>
                    <Thumb
                      thumbType="caption"
                      number={index + 1}
                      onClick={() => alert(`switch to ${layout}`)}
                      caption={`${t(`layouts.quiz.${layout.key}`)}`}
                    >
                      <PinViewerStatic pin={pinForThumb} width={PIN_WIDTH} isThumb />
                    </Thumb>
                  </li>
                );
              })}

            {isOpenQuestionPin(pin) &&
              template.layouts.openQuestionLayouts.map((layout, index) => {
                const pinForThumb = newPin(newOpenQuestionPin(layout));

                return (
                  <li key={layout.key}>
                    <Thumb
                      thumbType="caption"
                      number={index + 1}
                      onClick={() => switchLayout(layout.key)}
                      caption={`${t(`layouts.openQuestion.${layout.key}`)}`}
                    >
                      <PinViewerStatic pin={pinForThumb} width={PIN_WIDTH} isThumb />
                    </Thumb>
                  </li>
                );
              })}
          </StyledLayoutList>
        </StyledModalContent>
      </ModalPopover>
    );
  }
);

const StyledModalContent = styled(SpaceBetween)`
  background: ${color.neutral.surface1.background};
  padding: ${spacing.size16};
`;

const StyledLayoutList = styled(Grid)`
  margin: 0;
  padding: 0;
  list-style: none;
`;
