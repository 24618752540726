import { AnswerAreaSettings, Pin } from '@lessonup/pins-shared';
import { SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React from 'react';
import { computeOpacity } from '../../utils';
import { AnswerCardPlaceholderSize } from './AnswerCardPlaceholder/AnserCardPlaceholder.types';
import { AnswerCardPlaceholder } from './AnswerCardPlaceholder/AnswerCardPlaceholder';

const placeHolderCards: AnswerCardPlaceholderSize[] = [
  'small',
  'small',
  'small',
  'small',
  'large',
  'large',
  'large',
  'small',
  'medium',
  'medium',
  'small',
  'small',
  'small',
  'large',
  'medium',
];

export interface AnswerAreaPinComponentWrapperProps {
  settings: AnswerAreaSettings;
  active?: boolean;
  children?: React.ReactNode;
}

export const AnswerAreaPinComponentWrapper: React.FC<AnswerAreaPinComponentWrapperProps> = ({
  settings,
  active = false,
  children,
}) => {
  return (
    <StyledAnswerAreaWrapper>
      <StyledAnswerAreaBackground
        style={{
          background: settings.backgroundColor,
          opacity: computeOpacity(settings.transparency),
        }}
      ></StyledAnswerAreaBackground>
      <StyledAnswerAreaPinContent
        direction="x"
        spacing={spacing.size8}
        padding={spacing.size16}
        justifyContent="space-between"
        style={{
          overflowY: active ? 'auto' : 'hidden',
        }}
      >
        {children}
      </StyledAnswerAreaPinContent>
    </StyledAnswerAreaWrapper>
  );
};

export interface AnswerAreaPinComponentProps {
  settings: AnswerAreaSettings;
  layoutKey?: Pin['settings']['layout']; //Todo this should have better typing
  showPlaceholderCards?: boolean;
  active?: boolean;
}

export const AnswerAreaPinComponent: React.FC<AnswerAreaPinComponentProps> = ({
  settings,
  layoutKey = 'DEFAULT',
  showPlaceholderCards,
  active = false,
}) => {
  const columns = layoutKey === 'FIFTY_FIFTY' ? 2 : 3;
  return (
    <AnswerAreaPinComponentWrapper settings={settings} active={active}>
      {Array.from({ length: columns }).map((_, i) => (
        <StyledAnswerAreaColumn key={`column-${i}`}>
          {showPlaceholderCards &&
            placeHolderCards.map(
              (size, j) => j % columns === i && <AnswerCardPlaceholder key={`card-${j}`} size={size} />
            )}
        </StyledAnswerAreaColumn>
      ))}
    </AnswerAreaPinComponentWrapper>
  );
};

const StyledAnswerAreaWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledAnswerAreaBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const StyledAnswerAreaPinContent = styled(SpaceBetween)`
  width: 100%;
  height: 100%;
  scrollbar-gutter: stable;
  backdrop-filter: blur(16px);
`;

const StyledAnswerAreaColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${spacing.size8};
  margin-bottom: auto;
`;
