import { BodyText, ModalFooter, ModalHeaderV1, SpaceBetween, spacing } from '@lessonup/ui-components';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslationThroughKey } from '../../../utils/translations/useTranslationThroughKey';
import { TRANSLATION_NAMESPACE } from '../consts';

export interface EmailTroubleshootModalContentProps {
  openChatWindow: () => void;
  changeEmailLink: string;
  emailAddress: string;
  closeModal: () => void;
}

export const EmailTroubleshootModalContent = ({
  openChatWindow,
  changeEmailLink,
  emailAddress,
  closeModal,
}: EmailTroubleshootModalContentProps) => {
  const t = useTranslationThroughKey(TRANSLATION_NAMESPACE, 'emailTroubleshootModal');

  function onContactSupportClick() {
    closeModal();
    openChatWindow();
  }

  return (
    <>
      <ModalHeaderV1 type="headline" title={{ size: 'M', content: t('title') }} onCloseButtonClick={closeModal} />
      <SpaceBetween direction="y" spacing={spacing.size16} padding={spacing.size16}>
        <BodyText>
          <Trans i18nKey={`${TRANSLATION_NAMESPACE}.emailVerificationSentStep.description`} values={{ emailAddress }}>
            {t('description', { emailAddress })}
          </Trans>
        </BodyText>
        <BodyText>{t('descriptionContactSupport')}</BodyText>
      </SpaceBetween>
      <ModalFooter
        type="actions"
        primaryAction={{
          onClick: onContactSupportClick,
          label: t('contactSupport'),
        }}
        secondaryAction={{
          href: changeEmailLink,
          onClick: closeModal,
          label: t('changeEmail'),
        }}
      />
    </>
  );
};
