import { boxFromLayout, PinComponentLayout } from '@lessonup/pin-renderer';
import { SpaceBetween, spacing, styled } from '@lessonup/ui-components';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { FAM_CLASS_NAME, getMenuWidth, getXPosition, getYPosition, MENU_HEIGHT } from './FloatingActionMenu.utils';

export interface FloatingActionMenuProps {
  layout: PinComponentLayout;
  scale: number;
  children?: React.ReactNode;
}

export const FloatingActionMenu: React.FC<FloatingActionMenuProps> = ({ children, layout, scale }) => {
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const boxLayout = boxFromLayout(layout);

  useLayoutEffect(() => {
    if (ref.current) {
      setMenuWidth(getMenuWidth(ref));
    }
  }, []);

  return (
    <StyledMenuContainer
      className={FAM_CLASS_NAME}
      style={{
        transform: `translate(calc(${getXPosition(
          boxLayout.position.x,
          boxLayout.size.width,
          menuWidth,
          scale
        )}px - 50%), ${getYPosition(boxLayout, scale)}px)`,
      }}
      ref={ref}
    >
      <StyledFloatingActionMenu direction="x" spacing={spacing.size4}>
        {children}
      </StyledFloatingActionMenu>
    </StyledMenuContainer>
  );
};

const StyledMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: initial;
`;

const StyledFloatingActionMenu = styled(SpaceBetween)`
  height: ${MENU_HEIGHT}px;
  user-select: none;
`;
