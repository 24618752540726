import { DataLoader, useDocumentQuery } from '@lessonup/client-integration';
import {
  createModal,
  LoaderSpinner,
  ManagedModal,
  styled,
  TablePreviewSelectOptions,
  useErrorContext,
  useModal,
} from '@lessonup/ui-components';
import { transformImageUrl } from '@lessonup/utils';
import { first } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  minimumUploadsPreviewModalSpacing,
  previewModalHeight,
  previewModalWidth,
  UploadModalPreviewActions,
  UploadPreviewModalContent,
} from '../components/UploadPreviewModal/UploadPreviewModalContent';
import { useDeleteUploads } from '../hooks/useDeleteUploads';
import { useRenameUpload } from '../hooks/useRenameUpload';
import { UploadDetailsDocument } from '../Uploads.graphql.generated';
import { i18nextNamespace, refetchQueriesForUploads } from '../UploadsFeature.utils';
import { getUploadPreviewData } from './UploadPreviewModal.utils';

export interface UploadPreviewModalProps {
  uploadId: string;
  onDelete?: (id: string) => void;
  previewActions?: UploadModalPreviewActions;
  fromSearchResults: boolean;
  options?: TablePreviewSelectOptions;
}

export const UploadPreviewModal = createModal((props: UploadPreviewModalProps) => {
  const modal = useModal();
  const { t } = useTranslation(i18nextNamespace);
  const { setError } = useErrorContext();

  const refetchQueries = refetchQueriesForUploads(props.fromSearchResults);
  const handleDeleteUpload = useDeleteUploads({ onError: (error) => setError({ error }), refetchQueries });
  const handleRenameUpload = useRenameUpload({ onError: (error) => setError({ error }), refetchQueries });

  const { data, loading, error, refetch } = useDocumentQuery(UploadDetailsDocument, {
    fetchPolicy: 'cache-first',
    variables: props,
    onError: () => {
      setError({
        error: null,
        customErrorDisplayMessage: { title: '', description: t('previewModal.error.somethingWentWrong') },
      });
    },
    onCompleted: (data) => {
      const upload = first(data.viewer.uploadsByIds);
      if (typeof upload?.__typename === 'undefined') {
        setError({
          error: null,
          customErrorDisplayMessage: { title: '', description: t('previewModal.error.missingType') },
        });
      }
    },
  });

  const retry = async () => {
    await refetch();
  };

  const handleDeleteAction = async () => {
    const { deleted } = await handleDeleteUpload({ uploads: [{ id: props.uploadId, type: 'upload' }] });
    if (!deleted) return;
    modal.hide();
    props.onDelete?.(props.uploadId);
  };

  return (
    <ManagedModal modal={modal} contentLabel={t('previewModal.contentLabel')}>
      <DataLoader
        retry={retry}
        data={data}
        loading={loading}
        error={error}
        customLoader={
          <LoaderWrapper>
            <LoaderSpinner />
          </LoaderWrapper>
        }
        dataRenderer={({ viewer: { uploadsByIds } }) => {
          const upload = first(uploadsByIds);
          if (!upload) {
            return;
          }
          const uploadPreviewData = getUploadPreviewData(upload);

          const { id, name, createdAt, status, originalFile } = upload;
          const { sizeInBytes, fileExtension, contentType } = originalFile;

          return (
            <UploadPreviewModalContent
              upload={{
                id: props.uploadId,
                name,
                createdAt: new Date(createdAt),
                sizeInBytes,
                fileExtension,
                contentType,
                ...uploadPreviewData.props,
              }}
              isFileMissing={uploadPreviewData.isFileMissing || status === 'ERROR'}
              handleDelete={handleDeleteAction}
              onClose={modal.hide}
              onRenameButtonClick={() => handleRenameUpload(id, name, false)}
              imageResizer={transformImageUrl}
              previewActions={props.previewActions}
              options={props.options}
            />
          );
        }}
      />
    </ManagedModal>
  );
});

const LoaderWrapper = styled.div`
  width: ${previewModalWidth};
  height: ${previewModalHeight};
  max-width: ${`calc(100vw - ${minimumUploadsPreviewModalSpacing})`};
  max-height: ${`calc(100vh - ${minimumUploadsPreviewModalSpacing})`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
