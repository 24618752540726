import React from 'react';

import { useEventListener } from 'usehooks-ts';

import { isMacFromNavigator } from '../browser/navigator';

const supportedCodes = [
  'ArrowRight',
  'ArrowLeft',
  'ArrowDown',
  'ArrowUp',
  'BracketLeft',
  'BracketRight',
  'PageUp',
  'PageDown',
  'KeyC',
  'KeyD',
  'KeyV',
  'KeyZ',
  'KeyY',
  'KeyU',
  'KeyI',
  'Period',
  'Delete',
  'Escape',
  'Tab',
  'Backspace',
  'Enter',
] as const;

type SupportedCodes = (typeof supportedCodes)[number];

const supportedModifiers = ['Alt', 'Ctrl', 'Alt + Ctrl', 'Shift', 'AltGraph', 'Ctrl + Shift'] as const;

type SupportedModifiers = (typeof supportedModifiers)[number];

type EventKeyExceptionMap = {
  keyup: '';
  keydown: '';
  keypress: 'Delete' | 'PageUp' | 'PageDown';
};

type SupportedEvents = keyof EventKeyExceptionMap;

type ComputedCodes<T extends SupportedEvents> = Exclude<SupportedCodes, EventKeyExceptionMap[T]>;

type SupportedEventCodes<T extends SupportedEvents> = `${SupportedModifiers} + ${ComputedCodes<T>}` | ComputedCodes<T>;

export const useKeyboardEvent = <E extends SupportedEvents, T extends HTMLElement = HTMLDivElement>(
  eventName: E,
  keyEvents: Partial<Record<SupportedEventCodes<E>, (event: React.KeyboardEvent<T> | KeyboardEvent) => void>>,
  allowKeybind?: () => boolean
) => {
  useEventListener(eventName, (event: KeyboardEvent) => {
    if (allowKeybind && !allowKeybind()) return;

    keyEvents[`${getKeyboardModifier(event)}${event.code as ComputedCodes<E>}`]?.(event);
  });
};

export const useElementKeyboardEvent = <E extends SupportedEvents, T extends HTMLElement = HTMLDivElement>(
  eventName: E,
  keyEvents: Partial<Record<SupportedEventCodes<E>, (event: React.KeyboardEvent<T> | KeyboardEvent) => void>>,
  element: React.RefObject<T> | null
) => {
  useEventListener(
    eventName,
    (event: KeyboardEvent) => {
      keyEvents[`${getKeyboardModifier(event)}${event.code as ComputedCodes<E>}`]?.(event);
    },
    // TODO: TECH-180: fix the ref type issue
    element as never
  );
};

function getKeyboardModifier(event: KeyboardEvent) {
  switch (true) {
    case event.getModifierState('Alt') && isControlModifier(event):
      return 'Alt + Ctrl + ';
    case event.getModifierState('Shift') && isControlModifier(event):
      return 'Ctrl + Shift + ';
    case event.getModifierState('Alt'):
      return 'Alt + ';
    case isControlModifier(event):
      return 'Ctrl + ';
    case event.getModifierState('Shift'):
      return 'Shift + ';
    case event.getModifierState('AltGraph'):
      return 'AltGraph + ';
    default:
      return '';
  }
}

function isControlModifier(event: KeyboardEvent) {
  return isMacFromNavigator ? event.metaKey : event.getModifierState('Control');
}
