import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconSend = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.424 3.362c-.985-.985-2.881-1.19-10.06 1.758-5.003 2.055-7.893 3.271-7.387 6.402m17.447-8.16c.985.985 1.19 2.881-1.758 10.06-2.055 5.003-3.271 7.893-6.402 7.387-.653-.106-1.472-.48-2.333-1.014L8.714 20.9c-.963.876-2.509.192-2.509-1.11v-3.085c-1.59-1.698-3.005-3.809-3.228-5.184m17.447-8.16q.07.07.137.148L5.803 16.264c-1.42-1.61-2.622-3.484-2.826-4.742"
    />
  </svg>
);
export default SvgIconSend;
