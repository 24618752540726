import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconCut = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5.063 14.986c5.521-4.865 9.09-6.915 16.312-9.404M5.063 9.014c5.521 4.865 9.09 6.915 16.312 9.404M5.87 9.115a3.245 3.245 0 1 0 0-6.49 3.245 3.245 0 0 0 0 6.49m0 12.26a3.245 3.245 0 1 0 0-6.49 3.245 3.245 0 0 0 0 6.49"
    />
  </svg>
);
export default SvgIconCut;
