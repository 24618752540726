import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconImport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.067 2.857h-4.875a4.5 4.5 0 0 0-4.5 4.5v13.387m4.76-4.36c-1.711 2.088-2.754 3.131-4.76 4.76-2.005-1.629-3.048-2.672-4.76-4.76"
    />
  </svg>
);
export default SvgIconImport;
