import { ApolloError, isApolloError } from '@apollo/client';

/** 
 Based on https://github.com/lessonup/lessonup-2.0/blob/main/packages/graphql-shared/src/shared.errors.ts
 Since we don't have access to the graphql-shared package, we need to copy the error codes here.
*/
const list = [
  'INTERNAL_SERVER_ERROR',
  'INVALID_PARAMS',
  'FEATURE_DISABLED',
  'FORBIDDEN',
  'UNAUTHORIZED',
  'ENTITY_NOT_FOUND',
  'NOT_SUPPORTED',
  'EXTERNAL_SERVICE_UNAVAILABLE',
  'NO_TEXT_FOUND_BY_OCR',
] as const;
export type GraphQLErrorCode = (typeof list)[number];

/**
 * Extracts the first GraphQLErrorCode from an ApolloError.
 * Returns undefined if the error is not an ApolloError or if the code is unknown.
 */
export const graphQLErrorCodeFromError = (error: unknown): GraphQLErrorCode | undefined => {
  const firstCode =
    isValidApolloError(error) && (error.graphQLErrors[0]?.extensions.code as GraphQLErrorCode | undefined);
  if (firstCode && list.includes(firstCode)) return firstCode;
};

export const graphQLErrorMessage = (error: unknown): string | undefined => {
  return (isValidApolloError(error) && error.graphQLErrors[0]?.message) || undefined;
};

const isValidApolloError = (error: unknown): error is ApolloError => {
  return error instanceof Error && isApolloError(error);
};
