import { styled } from '@lessonup/ui-components';
import React from 'react';

export type MaiaLessonWizardSteps = 'text' | 'upload' | 'validation' | 'review';

export interface MaiaLessonWizardProps {
  currentStep: MaiaLessonWizardSteps;
  uploadStep?: React.JSX.Element;
  textStep: React.JSX.Element;
  validationStep?: React.JSX.Element;
  reviewStep: React.JSX.Element;
}

export const MaiaLessonWizard = ({
  uploadStep,
  textStep,
  reviewStep,
  validationStep,
  currentStep,
}: MaiaLessonWizardProps) => {
  return (
    <StyledWizard>
      {currentStep === 'text' && textStep}
      {currentStep === 'upload' && uploadStep}
      {currentStep === 'validation' && validationStep}
      {currentStep === 'review' && reviewStep}
    </StyledWizard>
  );
};

const StyledWizard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;
