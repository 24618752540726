import type { AvoInspector } from 'avo-inspector';
import type { AvoInspectorEnvValueType } from 'avo-inspector/dist/AvoInspectorEnv';
import { isEqual, mapValues } from 'lodash';
import avo, { AvoEnv, avoInspectorApiKey } from '../avo/avo.generated';
import { AvoEvents } from '../avo/AvoEvents';
import { CookieSettings, getCookieSettings, setCookieSettings } from '../cookies/analytic-cookies';
import { logger } from '../logging/analytics-logger';
import {
  ClarityDestination,
  GA4Destination,
  HubspotDestination,
  MicrosoftDestination,
  SnowplowDestination,
  TrackerDestination,
  TwitterDestination,
} from './destinations';
import { HSQ } from './destinations/hubspot-destination';

const AVO_DEBUG_MODE = false;

export interface TrackerInitOptions {
  env: string;
  snowplowCollectorUrl: string;
  applicationName: string;
  applicationVersion: string;
  enableTwitterDestination?: boolean;
  enableMicrosoftDestination?: boolean;
  onCookieConsentChange?: (settings: CookieSettings | undefined) => void;
}

export interface TrackerWindow {
  gtag?: Function;
  fbq?: Function;
  _hsq?: HSQ;
  location: Window['location'];
  addEventListener: Window['addEventListener'];
}

export class Tracker {
  public readonly events: AvoEvents = { ...avo };
  private readonly destinations: TrackerDestination[] = [];
  private pageViewIntervalHandle: any;
  private initOptions?: TrackerInitOptions;
  private lastTrackedError?: any;
  private lastTrackedErrorTimestamp?: number;

  constructor(private window: TrackerWindow = globalThis.window) {
    this.events = mapValues(this.events, (event) => {
      return (...args: unknown[]) => {
        try {
          (event as (...args: unknown[]) => void)(...args);
        } catch (error: unknown) {
          logger().error('Error while tracking event', { 'event-name': event.name, error: error as Error });
        }
      };
    });
  }

  init(options: TrackerInitOptions) {
    try {
      if (typeof this.window === undefined) {
        logger().warn('tracker.init should only be called in the browser');
        return;
      }

      // bit hacky, but not all applications import our libraries (like our website)
      const currentUserId = window.localStorage?.getItem('Meteor.userId') || undefined;

      this.window._hsq = this.window._hsq || [];

      this.initOptions = options;

      const cookieSettings = getCookieSettings();

      const snowplowDestination = new SnowplowDestination({
        env: options.env,
        applicationName: options.applicationName,
        applicationVersion: options.applicationVersion,
        collectorUrl: options.snowplowCollectorUrl,
        cookieSettings,
        userId: currentUserId,
      });
      this.destinations.push(snowplowDestination);

      if (!this.window.gtag) {
        logger().error('tracker.init gtag is not initialized. make sure the gtag script is loaded in the head.');
      }

      if (!this.window._hsq) {
        throw new Error(`window._hsq is not initialized`);
      }

      const ga4Destination = new GA4Destination({
        gtag: this.window.gtag ?? new Function(),
      });
      this.destinations.push(ga4Destination);

      const hubspotDestination = new HubspotDestination({
        hsq: this.window._hsq,
      });
      this.destinations.push(hubspotDestination);

      if (options.enableTwitterDestination) {
        const twitterDestination = new TwitterDestination();
        this.destinations.push(twitterDestination);
      }

      const microsoftDestination = new MicrosoftDestination({ enabled: options.enableMicrosoftDestination ?? false });
      this.destinations.push(microsoftDestination);

      this.destinations.push(new ClarityDestination());

      this.destinations.forEach((destination) => destination.init(getCookieSettings()));

      /** we need to require this package because it does things in the window once it's loaded, and that breaks in the nextjs backend */
      const { AvoInspector } = require('avo-inspector');

      const inspector: AvoInspector = new AvoInspector({
        apiKey: avoInspectorApiKey,
        env: avoInspectorEnv(options.env),
        appName: options.applicationName,
        version: options.applicationVersion,
      });

      inspector.enableLogging(options.env !== 'live');
      inspector.enableLogging(false);

      avo.initAvo(
        {
          env: avoEnv(options.env),
          reportFailureAs: 'error',
          inspector,
          webDebugger: AVO_DEBUG_MODE,
        },
        undefined,
        snowplowDestination,
        ga4Destination,
        microsoftDestination
      );
    } catch (error) {
      // we might have an init error somewhere and we don't want it to affect our applications if it does
      // we catch the error with a log message so we can track it in sentry
      console.error('Tracker:init', error);
    }
  }

  /** should be called when the users logs out */
  logout() {
    this.destinations.forEach((destination) => destination.logout());
  }

  pause(paused: boolean) {
    this.destinations.forEach((d) => d.pause(paused));
  }

  startTrackingPageViews() {
    if (typeof this.window === undefined) {
      logger().error('startTrackingPageViews should only be called in the browser');
      return;
    }

    if (this.pageViewIntervalHandle) {
      logger().warn('startTrackingPageViews was called while pagesviews were already being tracked');
      return;
    }

    if (this.destinations.length === 0) {
      logger().error(`startTrackingPageViews called but the tracker isn't initialized yet.`);
    }

    let lastTrackedPath: string | undefined;
    this.pageViewIntervalHandle = setInterval(() => {
      if (this.window.location.pathname !== lastTrackedPath) {
        this.destinations.forEach((destination) => destination.trackPageView());
        lastTrackedPath = this.window.location.pathname;
      }
    }, 200);
  }

  stopTrackingPageviews() {
    clearInterval(this.pageViewIntervalHandle);
    this.pageViewIntervalHandle = undefined;
  }

  /** start tracking window.error and window.unhandledrejection errors */
  startTrackingErrors() {
    if (typeof this.window === undefined) {
      logger().error('startTrackingErrors should only be called in the browser');
      return;
    }
    this.window.addEventListener('error', (error) => this.trackError(error.error));
    this.window.addEventListener('unhandledrejection', (error) => this.trackError(error.reason));
  }

  trackError(error: any) {
    if (!error) return;

    // to be safe and prevent spam, we only track if it isn't a duplicate unless it was over a minute ago
    if (isEqual(this.lastTrackedError, error) && Date.now() - (this.lastTrackedErrorTimestamp ?? 0) < 60 * 1000) {
      return;
    }

    this.lastTrackedErrorTimestamp = Date.now();
    this.lastTrackedError = error;

    if (error instanceof Error) {
      this.destinations.forEach((destination) => destination.trackError(error.message, error));
    } else if (typeof error === 'string' && error) {
      this.destinations.forEach((destination) => destination.trackError(error, undefined));
    } else if (error && typeof error.message === 'string' && error.message) {
      this.destinations.forEach((destination) => destination.trackError(error.message, undefined));
    }
  }

  setUserId(userId?: string) {
    this.destinations.forEach((destination) => destination.setUserId(userId));
  }

  setCookieSettings(settings: CookieSettings) {
    setCookieSettings(settings);
    this.destinations.forEach((destination) => destination.updateConsent(settings));
    this.initOptions?.onCookieConsentChange?.(settings);
  }

  /**
   * retrieves the cookie settings for the user. Returns undefined if no cookies are set
   */
  cookieSettings(): CookieSettings | undefined {
    return getCookieSettings();
  }

  /**
   * Indicates if the user should give his cookie consent
   */
  requiresCookieConsent(): boolean {
    return !getCookieSettings();
  }
}

export const tracker = new Tracker();

export function avoEnv(env: string): AvoEnv {
  switch (env) {
    case 'staging':
    case 'live':
      return AvoEnv.Prod;
    default:
      return AvoEnv.Dev;
  }
}

export function avoInspectorEnv(env: string): AvoInspectorEnvValueType {
  switch (env) {
    case 'staging':
      return 'staging';
    case 'live':
      return 'prod';
    default:
      return 'dev';
  }
}
