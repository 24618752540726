import type { SVGProps } from 'react';
import * as React from 'react';
const SvgIconLockOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.094 3.906A4.375 4.375 0 0 0 7.624 7v3.6M12 14.376v1.5m6.25-.75a6.25 6.25 0 1 1-12.5 0 6.25 6.25 0 0 1 12.5 0"
    />
  </svg>
);
export default SvgIconLockOpen;
