import {
  BodyText,
  Button,
  FormFieldValidation,
  InputFormField,
  SpaceBetween,
  spacing,
  styled,
  useForm,
} from '@lessonup/ui-components';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type RequestAgreementFormInputs = {
  name: string;
  position: string;
  email: string;
};

export type RequestAgreementFormProps = {
  onSubmit: (data: RequestAgreementFormInputs) => void;
  hasBeenSentBefore: boolean;
};

export const RequestAgreementForm: React.FC<RequestAgreementFormProps> = ({ onSubmit, hasBeenSentBefore }) => {
  const { t } = useTranslation('organizationAgreements');

  const [step, setStep] = useState<'form' | 'requestSent' | 'reRequest'>('form');
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm<RequestAgreementFormInputs>();

  const nameRef = useRef<HTMLInputElement | null>(null);
  const positionRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);

  const { ref: registerNameRef, ...nameRest } = register('name', {
    required: { value: true, message: t('requiredName') },
    onBlur(event) {
      setValue('name', event.target.value.trim());
    },
    onChange() {
      clearErrors('name');
    },
  });

  const { ref: registerPositionRef, ...positionRest } = register('position', {
    required: { value: true, message: t('requiredPosition') },
    onBlur(event) {
      setValue('position', event.target.value.trim());
    },
    onChange() {
      clearErrors('position');
    },
  });

  const { ref: registerEmailRef, ...emailRest } = register('email', {
    required: { value: true, message: t('requiredEmail') },
    onBlur(event) {
      setValue('email', event.target.value.trim());
    },
    onChange() {
      clearErrors('email');
    },
  });

  const validateGeneric: (inputName: keyof RequestAgreementFormInputs) => FormFieldValidation | undefined = (
    inputName
  ) => {
    const inputError = errors[inputName];
    if (!inputError) return;

    const message: string = inputError.message || t('error.generic');

    return {
      state: 'error',
      message,
    };
  };

  function submit() {
    if (!nameRef.current || !positionRef.current || !emailRef.current) return;
    onSubmit({
      name: nameRef.current.value,
      position: positionRef.current.value,
      email: emailRef.current.value,
    });
    setStep('requestSent');
  }

  const showRequestForm = (!hasBeenSentBefore && step === 'form') || step === 'reRequest';

  return (
    <SpaceBetween direction="y" spacing={spacing.size12}>
      {hasBeenSentBefore && step !== 'reRequest' && <RequestAgain onRequestAgain={() => setStep('reRequest')} />}
      {step === 'requestSent' && <BodyText>{t('requestSent')}</BodyText>}
      {showRequestForm && (
        <form onSubmit={handleSubmit(submit)}>
          <FormWrapper direction="y" spacing={spacing.size12}>
            <div>
              <InputFormField
                label={t('name')}
                type="text"
                {...nameRest}
                ref={(name) => {
                  registerNameRef(name);
                  nameRef.current = name;
                }}
                validation={validateGeneric('name')}
              ></InputFormField>
              <InputFormField
                label={t('position')}
                type="text"
                {...positionRest}
                ref={(position) => {
                  registerPositionRef(position);
                  positionRef.current = position;
                }}
                validation={validateGeneric('position')}
              ></InputFormField>
              <InputFormField
                label={t('email')}
                type="email"
                {...emailRest}
                ref={(email) => {
                  registerEmailRef(email);
                  emailRef.current = email;
                }}
                validation={validateGeneric('email')}
              ></InputFormField>
            </div>
            <Button type="submit">{t('request')}</Button>
          </FormWrapper>
        </form>
      )}
    </SpaceBetween>
  );
};

const RequestAgain = ({ onRequestAgain }: { onRequestAgain: () => void }) => {
  const { t } = useTranslation('organizationAgreements');

  return (
    <>
      <StyledButton resizing="hug" onClick={onRequestAgain}>
        {t('requestAgainCTA')}
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

const FormWrapper = styled(SpaceBetween)`
  max-width: 25rem;
`;
