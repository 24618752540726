import { TablePreviewSelectOptions, TableRowInteractivity } from '@lessonup/ui-components';
import React, { createContext } from 'react';
import { UploadModalPreviewActions } from './components/UploadPreviewModal/UploadPreviewModalContent';
import { NavigationDispatch } from './hooks/useNavigationDispatcher';
import { UploadType } from './UploadsFeature.utils';

export interface UploadsContextProps {
  fileTypeFilter: UploadType[];
  tableInteractivity: TableRowInteractivity;
  dispatchNavigation: NavigationDispatch;
  previewActions?: UploadModalPreviewActions;
  options?: TablePreviewSelectOptions;
}

export const UploadsContext: React.Context<UploadsContextProps> = createContext<UploadsContextProps>({
  fileTypeFilter: [],
  tableInteractivity: {
    isDraggable: false,
    isSelectable: true,
  },
  dispatchNavigation: () => console.warn('No uploads provider'),
});
