import {
  BodyText,
  borderRadius,
  colorSets,
  css,
  IconVisibilityOn,
  SpaceBetween,
  spacing,
  styled,
} from '@lessonup/ui-components';
import React from 'react';
import { usePlayerTranslation } from '../../hooks/usePlayerTranslation';

export interface AnswerCardContentProps {
  answerText: string;
  revealed: boolean;
  authorSlot?: React.ReactNode;
  handleReveal?: (e: React.MouseEvent) => void;
}

// This composition is meant to be used with the OpenQuestionPin and the WordCloudPin

export const AnswerCardContent: React.FC<AnswerCardContentProps> = ({
  answerText,
  revealed,
  authorSlot,
  handleReveal,
}) => {
  const { t } = usePlayerTranslation();
  return (
    <StyledContent direction="y" spacing={spacing.size8} padding={spacing.size8}>
      <StyledAnswerWrapper
        aria-label={revealed ? t('labels.hideAnswer') : t('labels.revealAnswer')}
        revealed={revealed}
        onClick={handleReveal}
      >
        {!revealed && <StyledIconEye width={16} height={16} />}
        <StyledAnswerText size="medium" revealed={revealed}>
          {answerText}
        </StyledAnswerText>
      </StyledAnswerWrapper>
      {authorSlot && (
        <SpaceBetween direction="x" spacing={spacing.size4}>
          {authorSlot}
        </SpaceBetween>
      )}
    </StyledContent>
  );
};

const StyledContent = styled(SpaceBetween)`
  word-break: break-word;
`;

const StyledAnswerWrapper = styled.button<{ revealed: boolean }>`
  cursor: pointer;
  text-align: left;
  border-radius: ${borderRadius.rounded2};
  padding: ${spacing.size0} ${spacing.size4};
  ${({ revealed }) =>
    !revealed
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${colorSets.active.fill};
          color: ${colorSets.active.on};
          border: 1px solid ${colorSets.active.stroke};
          &:hover {
            background: ${colorSets.active.fillFeedback};
          }
        `
      : css`
          border: 1px dashed ${colorSets.active.stroke};
          &:hover {
            background: ${colorSets['neutral'].fillFeedback};
          }
        `}
`;

const StyledIconEye = styled(IconVisibilityOn)`
  position: absolute;
  user-select: none;
`;

const StyledAnswerText = styled(BodyText)<{ revealed: boolean }>`
  opacity: ${({ revealed }) => (revealed ? 1 : 0)};
  user-select: none;
  white-space: pre-wrap;
`;
